import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../index';
import Notify from '../Notify';

export const updateRate = (request) => {
    return dispatch => {
        let requestParams = { monthly_bill: parseFloat(request.rate) };
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/monthly-bill/', requestParams)
            .then(function (response) {
                dispatch(actions.getHotelDetail(request))
                dispatch(actions.getMembershipPricing(request))
                dispatch(Notify.newUserModal("Monthly Fee updated successfully.", true))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while updating Monthly Fee.", false))
            });
    }
}

export const updateDefaultRate = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.put(Server.API + 'hotel/monthly-bill/')
            .then(function (response) {
                dispatch(actions.getHotelDetail(request))
            })
            .catch(function (error) {
            });
    }
}

export const updateFreeTrial = (request) => {
    return dispatch => {
        let requestParams = { free_trial_end: request.date };
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/free-trial-end/', requestParams)
            .then(function (response) {
                dispatch(actions.getHotelDetail(request))
                dispatch(Notify.newUserModal("Trial end date updated successfully.", true))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while updating Trial end date.", false))
            });
    }
}


const onPmsSettingGetSucceed = (data) => {
    return {
        type: actionTypes.MONTHLY_PRICE,
        state: data
    }
}   

export const getMembershipPricing = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'hotel/membership/pricing/')
            .then(function (response) {
                dispatch(onPmsSettingGetSucceed(response.data))
            })
            .catch(function (error) {
            });
    }
}
const onChargebeConnectionSucceed = (data) => {
    return {
        type: actionTypes.CHARGEBEE_CONNECTION_SUCCEED,
        state: data
    }
}
export const chargebeeSubscription = (request) =>{
    let req={
        subscription_id:request.subscription_id
    }
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.put(Server.API + 'hotel/link-chargebee-subscription/',req)
            .then(function (response) {
                dispatch(onChargebeConnectionSucceed(response.data))
                dispatch(Notify.newUserModal("Chargebee subscription connected successfully.", true))

            })
            .catch(function (error) {
                dispatch(Notify.newUserModal(error.response.data.subscription_id, false))
            });
    }
}
const onChargebeLinkSucceed = (data) => {
    return {
        type: actionTypes.CHARGEBEE_LINK_SUCCEED,
        state: data
    }
}
export const getChargebeeSubscriptionLink= (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'hotel/get-payment-info-chargebee/')
        .then(function (response) {
            dispatch(onChargebeLinkSucceed(response.data))
        })
        .catch(function (error) {
            dispatch(Notify.newUserModal("Failed Chargebee subscription",false))
        });
    }
}

export const storeChargebeeNotification= (request) => {
    let data={
        show_payment_status:request.show_payment_status
    }
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.patch(Server.API + 'hotel/get-payment-info-chargebee/',data)
        .then(function (response) {
            dispatch(getChargebeeSubscriptionLink(request))
        })
        .catch(function (error) {
            dispatch(Notify.newUserModal("Failed Chargebee subscription",false))
        });
    }
}

