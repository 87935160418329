import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../shared/utility";
const initialState = [];
const reducer = (state = initialState, action) => {
    
    switch (action.type) {
        case actionTypes.GET_OTA_COMPETITOR_START:
            return updateObject(state, { loading: true, error: null });
        case actionTypes.GET_OTA_COMPETITOR_SUCCEED:
            return updateObject(state, {loading: false,error: null,OTACompetitorList: action.state});
        case actionTypes.GET_OPEN_TICKETS_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        default:
            return updateObject(state, { loading: false, error: null });    
    }
};
export default reducer