import * as actionTypes from "../actionTypes";
import Server from "../../../config/server";
import axios from "axios";
import Notify from "../Notify";
import * as actions from "../../../store/actions/index";
import * as Scrape from "./scrap";

const onCompetitorStart = () => {
  return {
    type: actionTypes.COMPETITOR_START,
  };
};

const onCompetitorFailed = (error) => {
  return {
    type: actionTypes.COMPETITOR_FAILED,
    error: error,
  };
};

const onCompetitorSucceed = (data) => {
  return {
    type: actionTypes.COMPETITOR_SUCCEED,
    state: data,
  };
};

export const getCompetitor = (request) => {
  return (dispatch) => {
    dispatch(onCompetitorStart());
    axios.defaults.headers.common["Authorization"] = "Token " + request.token;
    axios
      .get(Server.API + "hotel/competitor/list/")
      .then(function (response) {
        dispatch(onCompetitorSucceed(response.data));
      })
      .catch(function (error) {
        dispatch(onCompetitorFailed(error));
      });
  };
};

const onNearCompetitorStart = () => {
  return {
    type: actionTypes.NEAR_COMPETITOR_START,
  };
};

const onNearCompetitorFailed = (error) => {
  return {
    type: actionTypes.NEAR_COMPETITOR_FAILED,
    error: error,
  };
};

const onNearCompetitorSucceed = (data) => {
  return {
    type: actionTypes.NEAR_COMPETITOR_SUCCEED,
    state: data,
  };
};

export const getNearCompetitor = (request) => {
  return (dispatch) => {
    dispatch(onNearCompetitorStart());
    axios.defaults.headers.common["Authorization"] = "Token " + request.token;
    axios
      .get(Server.API + "hotel/competitor/hotel/nearest/?limit=100&best=100")
      .then(function (response) {
        dispatch(
          Notify.newUserModal("Nearest Competitor load successfully", true)
        );
        dispatch(onNearCompetitorSucceed(response.data));
      })
      .catch(function (error) {
        dispatch(Notify.newUserModal("Nearest Competitor load Failed", false));
        dispatch(onNearCompetitorFailed(error));
      });
  };
};

const onScoreCompetitorStart = () => {
  return {
    type: actionTypes.SCORE_COMPETITOR_START,
  };
};

const onScoreCompetitorFailed = (error) => {
  return {
    type: actionTypes.SCORE_COMPETITOR_FAILED,
    error: error,
  };
};

const onScoreCompetitorSucceed = (data) => {
  return {
    type: actionTypes.SCORE_COMPETITOR_SUCCEED,
    state: data,
  };
};

export const getScoreCompetitor = (request) => {
  return (dispatch) => {
    dispatch(onScoreCompetitorStart());
    axios.defaults.headers.common["Authorization"] = "Token " + request.token;
    axios
      .get(Server.API + "hotel/competitor/setup/?type=1")
      .then(function (response) {
        
        if (request.callBack) {
          dispatch(actions.getScoreCompetitor(request));
        }

        dispatch(onScoreCompetitorSucceed(response.data));
        // dispatch(getCompetitor(request))
      })
      .catch(function (error) {
        dispatch(Notify.newUserModal("Score Competitor load Failed", false));
        dispatch(onScoreCompetitorFailed(error));
      });
  };
};

const onTopCompetitorStart = () => {
  return {
    type: actionTypes.TOP_COMPETITOR_START,
  };
};

const onTopCompetitorFailed = (error) => {
  return {
    type: actionTypes.TOP_COMPETITOR_FAILED,
    error: error,
  };
};

const onTopCompetitorSucceed = (data) => {
  return {
    type: actionTypes.TOP_COMPETITOR_SUCCEED,
    state: data,
  };
};
export const getTopCompetitors = (request) => {
  let requestParam={
    distance:request.distance
  }
  return (dispatch) => {
    dispatch(onTopCompetitorStart());
    axios.defaults.headers.common["Authorization"] = "Token " + request.token;
    axios
      .post(Server.API + "hotel/competitor/setup/",requestParam)
      .then(function (response) {
        if (response.data.success) {
          dispatch(actions.getCompetitor(request));
          setTimeout(() => dispatch(actions.getScoreCompetitor(request)), 5000);
          dispatch(
            Notify.newUserModal("Top Competitor load successfully", true)
          );
        } else {
          dispatch(Notify.newUserModal(response.data.message, false));
        }
      })
      .catch(function (error) {
        dispatch(Notify.newUserModal("Top Competitor load Failed", false));
        dispatch(onTopCompetitorFailed(error));
      });
  };
};

const onScoreTopCompetitorStart = () => {
  return {
    type: actionTypes.SCORE_TOP_COMPETITOR_START,
  };
};

const onScoreTopCompetitorFailed = (error) => {
  return {
    type: actionTypes.SCORE_TOP_COMPETITOR_FAILED,
    error: error,
  };
};
const onScoreTopCompetitorSucceed = (data) => {
  return {
    type: actionTypes.SCORE_TOP_COMPETITOR_SUCCEED,
    state: data,
  };
};
export const getScoreTopCompetitors = (request) => {
  return (dispatch) => {
    let requestData = {
      room_id: request.room_id.toString(),
      do_sorting: request.do_sorting,
    };
    dispatch(onScoreTopCompetitorStart());
    axios.defaults.headers.common["Authorization"] = "Token " + request.token;
    axios
      .post(Server.API + "hotel/score_top_20_competitors/", requestData)
      .then(function (response) {
        if (response.data.success) {
          dispatch(actions.getCompetitor(request));
          dispatch(actions.getImputation(request));
          dispatch(
            Notify.newUserModal("Score Top Competitor load successfully", true)
          );
          dispatch(onScoreTopCompetitorSucceed(response.data));
        } else {
          dispatch(
            Notify.newUserModal("Score Top Competitor load Failed", false)
          );
        }
      })
      .catch(function (error) {
        dispatch(
          Notify.newUserModal("Score Top Competitor load Failed", false)
        );
        dispatch(onScoreTopCompetitorFailed(error));
      });
  };
};

const onUpdateCompetitorStart = () => {
  return {
    type: actionTypes.UPDATE_COMPETITOR_START,
  };
};

const onUpdateCompetitorFailed = (error) => {
  return {
    type: actionTypes.UPDATE_COMPETITOR_FAILED,
    error: error,
  };
};

const onUpdateCompetitorSucceed = (data) => {
  return {
    type: actionTypes.UPDATE_COMPETITOR_SUCCEED,
    state: data,
  };
};

export const getUpdateCompetitor = (request) => {
  return (dispatch) => {
    axios.defaults.headers.common["Authorization"] =
      "Token " + request.list[request.key].token;
    axios
      .patch(
        Server.API +
        "hotel/competitor/update/" +
        request.list[request.key].id +
        "/",
        request.list[request.key]
      )
      .then(function (response) {
        dispatch(onUpdateCompetitorSucceed(response.data));
        let newKey = request.key + 1;
        if (request.list[newKey]) {
          request.key = newKey;
          dispatch(actions.getUpdateCompetitor(request));
        } else {
          dispatch(
            Notify.newUserModal("Competitors Updated Successfully", true)
          );
        }
      })
      .catch(function (error) {
        dispatch(Notify.newUserModal("Competitor Update Failed", false));
        dispatch(onUpdateCompetitorFailed(error));
      });
  };

  return (dispatch) => {
    let reqestParams = {
      competitor: request.competitor,
      weight: request.weight,
    };
    dispatch(onUpdateCompetitorStart());
    axios.defaults.headers.common["Authorization"] = "Token " + request.token;
    axios
      .patch(
        Server.API + "hotel/competitor/update/" + request.id + "/",
        reqestParams
      )
      .then(function (response) {
        dispatch(Notify.newUserModal("Competitors Updated Successfully", true));
        dispatch(onUpdateCompetitorSucceed(response.data));
      })
      .catch(function (error) {
        dispatch(Notify.newUserModal("Competitor Update Failed", false));
        dispatch(onUpdateCompetitorFailed(error));
      });
  };
};

const onBulkUpdateCompetitorStart = () => {
  return {
    type: actionTypes.UPDATE_BULKCOMPETITOR_START,
  };
};

const onBulkUpdateCompetitorFailed = (error) => {
  return {
    type: actionTypes.UPDATE_BULKCOMPETITOR_FAILED,
    error: error,
  };
};

const onBulkUpdateCompetitorSucceed = (data) => {
  return {
    type: actionTypes.UPDATE_BULKCOMPETITOR_SUCCEED,
    state: data,
  };
};

export const bulkUpdateCompetitor = (request) => {
  return (dispatch) => {
    axios.defaults.headers.common["Authorization"] = "Token " + request.token;
    dispatch(onBulkUpdateCompetitorStart());
    axios
      .post(Server.API + "hotel/competitor/bulk-update/", request)
      .then(function (response) {
        // dispatch(actions.updateSettingJson(request, response.data, 'competitorUpdate'))
          if (response.data.error) {
            dispatch(Notify.newUserModal(response.data.message, false));
          } else {
            axios.post(Server.API + "hotel/competitor/scrape/", {top_20:false})
            .then(function (response) {              
              dispatch(actions.getCompetitor(request));
              dispatch(onBulkUpdateCompetitorSucceed(response));
              dispatch(
                Notify.newUserModal("Competitors Updated Successfully", true)
              );
            })
          .catch(function (error) {
            dispatch(Notify.newUserModal("Competitor Update Failed", false));
          });
         
        }
      })
      .catch(function (error) {
        dispatch(Notify.newUserModal("Competitor Update Failed", false));
      });
  };
};

export const bulkUpdateCompetitorNscrape = (request) => {
  return (dispatch) => {
    axios.defaults.headers.common["Authorization"] = "Token " + request.token;
    axios
      .post(Server.API + "hotel/competitor/bulk-update/", request)
      .then(function (response) {
        dispatch(Notify.newUserModal("Competitors Updated Successfully", true));
        dispatch(Scrape.getScrapCompetitor(request));
      })
      .catch(function (error) {
        dispatch(Notify.newUserModal("Competitor Update Failed", false));
      });
  };
};

const onImputationSucceed = (data) => {
  return {
    type: actionTypes.IMPUTATION_SUCCEED,
    state: data,
  };
};

export const getImputation = (request) => {
  return (dispatch) => {
    axios.defaults.headers.common["Authorization"] = "Token " + request.token;
    axios
      .post(Server.API + "hotel/competitor/scores/")
      .then(function (response) {
        dispatch(onImputationSucceed(response.data));
      })
      .catch(function (error) { });
  };
};

const onBookingCompetitorSucceed = (data) => {
  return {
    type: actionTypes.BOOKING_COMPETITOR_SUCCEED,
    state: data,
  };
};

export const searchBookingCompetitor = (request) => {
  return (dispatch) => {
    axios.defaults.headers.common["Authorization"] = "Token " + request.token;
    axios
      .post(Server.API + "hotel/competitor_setup/")
      .then(function (response) {
        dispatch(onBookingCompetitorSucceed(response.data));
      })
      .catch(function (error) { });
  };
};

export const resetPmsPrice = (request, loaderFun, updateType) => {
  return (dispatch) => {
    axios.defaults.headers.common["Authorization"] = "Token " + request.token;
    axios
      .post(Server.API + "pricingalgorithm/reset-pickupboostprice/",request)
      .then(function (response) {

          // if(updateType == 'priceRun'){
          //   let flagReq = {
          //       run_pricing: true,
          //       token: request.token
          //   }                    
          //   dispatch(actions.setRunPriceFlags(flagReq))
          // }

        loaderFun();
        dispatch(Notify.newUserModal("Reset Prices Successfully", true));

      })
      .catch(function (error) { 
        loaderFun();       
        dispatch(Notify.newUserModal("Reset Prices Failed", false)); 
      });
  };
};

export const resetPmsPriceOccupancy = (request, loaderFun,updateType) => {
  return (dispatch) => {    

    axios.defaults.headers.common["Authorization"] = "Token " + request.token;
    axios
      .post(Server.API + "pricingalgorithm/reset-pickupboostprice-bad-pms/", request)
      .then(function (response) {
          // if(updateType == 'occPriceRun'){
          //     let flagReq = {
          //       run_pricing: true,
          //       token: request.token
          //     }                    
          //   dispatch(actions.setRunPriceFlags(flagReq))
          // }
        loaderFun();
        dispatch(Notify.newUserModal("Reset Prices Successfully", true));
      })
      .catch(function (error) {
        loaderFun();
        dispatch(Notify.newUserModal("Reset Prices Failed", false));
       });
  };
};

const onScrapeTop20CompetitorsStart = () => {
  return {
    type: actionTypes.SCRAPE_TOP_20_COMPETITORS_START,
  };
};

const onScrapeTop20CompetitorsFailed = (error) => {
  return {
    type: actionTypes.SCRAPE_TOP_20_COMPETITORS_FAILED,
    error: error,
  };
};

const onScrapeTop20CompetitorsSucceed = (data) => {
  return {
    type: actionTypes.SCRAPE_TOP_20_COMPETITORS_SUCCEED,
    state: data,
  };
};

export const getScrapeTop20Competitors = (request) => {
  let requestData={
    top_20:true
  }
  return (dispatch) => {
    dispatch(onScrapeTop20CompetitorsStart());
    axios.defaults.headers.common["Authorization"] = "Token " + request.token;
    axios.post(Server.API + 'hotel/competitor/scrape/',requestData)
      .then(function (response) {
        dispatch(onScrapeTop20CompetitorsSucceed(response.data));
        dispatch(Notify.newUserModal("Scrape Top 20 Competitors has been Started", true))
      })
      .catch(function (error) {
        dispatch(onScrapeTop20CompetitorsFailed(error));
      });
  };
};

const onAirDnaDataSuccess = (data) => {
  return {
    type: actionTypes.GET_AIR_DNA_DATA,
    state: data,
  };
};


export const getAirDnaGraphData = (request, showNotification) => {  
  return (dispatch) => {
    axios.defaults.headers.common["Authorization"] = "Token " + request.token;
    axios.get(Server.API + 'hotel/get-future-daily/')
      .then(function (response) {
        dispatch(onAirDnaDataSuccess(response.data));
        if(showNotification){
          dispatch(Notify.newUserModal("AirDna Data Fetched Successfully", true))
        }
      })
      .catch(function (error) {
        dispatch(Notify.newUserModal("Somehing Went Wrong!", false))
      });
  };
};

export const saveMarketCode = (request, updateType, callback) => {  
  return (dispatch) => {
    axios.defaults.headers.common["Authorization"] = "Token " + request.token;
    axios.post(Server.API + 'hotel/save-market-code/',{selected_market_code: request.data})
      .then(function (response) {
                // if(updateType == 'priceRun'){
                //   let flagReq = {
                //       run_pricing: true,
                //       token: request.token
                //   }                    
                //   dispatch(actions.setRunPriceFlags(flagReq))
                // }
            dispatch(getAirDnaGraphData(request));        
          if(!response.data.success){
                          
            callback();    
            dispatch(Notify.newUserModal("No Data Found for Market Code.", false))
          }else{            
            dispatch(Notify.newUserModal("Market Code Saved Successfully", true));
          }
      })
      .catch(function (error) {
        dispatch(Notify.newUserModal("Somehing Went Wrong!", false))
      });
  };
};

export const findNearestCity = (request,updateType, callback) => {  
  return (dispatch) => {
    axios.defaults.headers.common["Authorization"] = "Token " + request.token;
    axios.post(Server.API + 'hotel/get-market-code-lat-long/')
      .then(function (response) {
        if(!response.data.success){            
          callback();
        }
        // if(updateType == 'priceRun'){
        //   let flagReq = {
        //       run_pricing: true,
        //       token: request.token
        //   }                    
        //   dispatch(actions.setRunPriceFlags(flagReq))
        // }
        dispatch(actions.getAirDnaGraphData(request))        
      })
      .catch(function (error) {
        dispatch(Notify.newUserModal("Somehing Went Wrong!", false))
      });
  };
};