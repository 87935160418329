import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../../../store/actions/index';
import Notify from '../Notify';
import { ValidateBaseSettings } from '../../../../src/App/Utils/SettingValidation/Validation';

const getusersLogListStart = (data) => {
    return {
        type: actionTypes.GET_USERS_LOG_START
    }
}

const getusersLogListSuccess = (data) => {
    return {
        type: actionTypes.GET_USERS_LOG_SUCCESS,
        state: data
    }
}

const getusersLogListFailed = (error) => {
    return {
        type: actionTypes.GET_USERS_LOG_FAILED,
        error: error
    }
}

export const getusersLogList = (request) => {
    return dispatch => {
        dispatch(getusersLogListStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'account/hotel/gettracktime/')
            .then((response) => {
                dispatch(getusersLogListSuccess(response.data))
            })
            .catch(function (error) {
                dispatch(getusersLogListFailed(error))
            });
    }
}

const setUsersLogStart = (data) => {
    return {
        type: actionTypes.SET_USERS_LOG_START
    }
}

const setUsersLogSuccess = (data) => {
    return {
        type: actionTypes.SET_USERS_LOG_SUCCESS,
        state: data
    }
}

const setUsersLogFailed = (error) => {
    return {
        type: actionTypes.SET_USERS_LOG_FAILED,
        error: error
    }
}

export const setUserLog = (request) => {
    return dispatch => {
        dispatch(setUsersLogStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'account/hotel/tracktime/', request)
            .then((response) => {
                dispatch(setUsersLogSuccess(response.data))
            })
            .catch(function (error) {
                dispatch(setUsersLogFailed(error))
            });
    }
}