import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = [];

const manageList = (data, state) => {
    state.preDefinedRates.push(data);
    return state.preDefinedRates;
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PREDEFINE_RATE_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.PREDEFINE_RATE_SUCCEED:
            return updateObject(state, { loading: false, error: null, preDefinedRates: action.state })
        case actionTypes.PREDEFINE_RATE_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.PREDEFINE_RATE_CREATE_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.PREDEFINE_RATE_CREATE_SUCCEED:
            return updateObject(state, { loading: false, error: null, preDefinedRates: manageList(action.state, state) })
        case actionTypes.PREDEFINE_RATE_CREATE_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.PREDEFINE_RATE_UPDATE_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.PREDEFINE_RATE_UPDATE_SUCCEED:
            return updateObject(state, { loading: false, error: null })
        case actionTypes.PREDEFINE_RATE_UPDATE_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.PREDEFINE_RATE_DELETE_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.PREDEFINE_RATE_DELETE_SUCCEED:
            return updateObject(state, { loading: false, error: null, preDefinedRates: action.state })
        case actionTypes.PREDEFINE_RATE_DELETE_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.PREDEFINE_RATE_MOVEUP_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.PREDEFINE_RATE_MOVEUP_SUCCEED:
            return updateObject(state, { loading: false, error: null, preDefinedRates: action.state })
        case actionTypes.PREDEFINE_RATE_MOVEUP_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.PREDEFINE_RATE_MOVEDOWN_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.PREDEFINE_RATE_MOVEDOWN_SUCCEED:
            return updateObject(state, { loading: false, error: null, preDefinedRates: action.state })
        case actionTypes.PREDEFINE_RATE_MOVEDOWN_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        default:
            return updateObject(state, { loading: false, error: null });
    }
}

export default reducer