import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = [];

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SETTINGS_LOG_START:
            return updateObject(state, { loading: true, error: null });
        case actionTypes.GET_SETTINGS_LOG_SUCCESS:
            return updateObject(state, { loading: false, error: null, settingsLog: action.state })
        case actionTypes.GET_SETTINGS_LOG_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        default:
            return updateObject(state, { loading: false, error: null });
    }
}

export default reducer