import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import settingsLogCss from './css/settingsLogCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../store/actions/index';
import MUIDataTable from "mui-datatables";
import Button from '@material-ui/core/Button';
import Modal from './modal/modal'
import { MuiThemeProvider } from '@material-ui/core/styles';
import { settingsLogTheme } from '../muiTheme';
import { Icon } from '@material-ui/core';
import Modals from './modal/modal';
import { withNamespaces, Trans } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';
import Server from '../../config/server';
import HeaderCard from './HeaderCard';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

const styles = settingsLogCss;


class settingsLog extends React.Component {

    state = {
        scheduleList: [],
        error:false,        
        showLogDetail: false,
        cachePrice: null,
        inventoryPriceJson: null
    };

    componentWillMount() {
        let requestParam = {token: this.props.hotelAuthReducer.hotelDetails.token};
        this.props.getsettingsLogList(requestParam);
        this.getCache();
        this.getInventoryPrice();
    }

    componentWillReceiveProps(props) {
        if (props.settingsLogReducer.settingsLog) {
            this.setState({
                settingsLog: props.settingsLogReducer.settingsLog
            })
        }
    }

    getCache = () =>{
        axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
        axios.get(Server.API + 'pricingalgorithm/cache-price/')
        .then((response) => {
            if (response.data) {
                this.setState({
                    cachePrice: response.data
                })      
            }
        })
    }

    getInventoryPrice = () =>{
        axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
        axios.get(Server.API + 'pricingalgorithm/inventory-price/')
        .then((response) => {
            if (response.data) {
                this.setState({
                    inventoryPriceJson: response.data
                })
            }
        })
    }

    rendersettingsLogData = (data) => {
        let jsx = [];
        data.map((val) => {
            jsx.push([
                val.date_time,
                val.email,
                val.username,
                val
            ])
            return null;
        })
        return jsx;
    }
    
    ViewLogModal = (data) => {
        this.setState({
            logDetail:data.data,
            showLogDetail: true
        })        
    }
    CloseLogModal = (data) => {
        this.setState({
            logDetail:{},
            showLogDetail: false
        })        
    }

    options = () => {
        return {
            responsive: 'scroll',
            print: false,
            download: false,
            viewColumns: false,
            responsive: "scroll",
            selectableRows: false,
            serverSide: false,
            filter:false,
            rowsPerPageOptions : false,
            count: this.state.count,
            rowsPerPage: 20,
            search:false,
            textLabels: {
                body: {
                noMatch: this.props.settingsLogReducer.loading ?
                    'Loading Records...' :
                    'Sorry, no matching records found.',
                },
            }
        }
    };

    cacheDownload = () =>{
        var content = JSON.stringify(this.state.cachePrice);
        const element = document.createElement("a");
        const file = new Blob([content], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "pricingCache"+this.props.hotelAuthReducer.hotelDetails.hotel.id+"-"+moment().format()+".json";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        
    }

    jsonDownload = () =>{
        var content = JSON.stringify(this.props.priceSettingReducer.priceSetting);
        const element = document.createElement("a");
        const file = new Blob([content], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "SettingJson"+this.props.hotelAuthReducer.hotelDetails.hotel.id+"-"+moment().format()+".json";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    inventoryPriceDownload = () =>{
        var content = JSON.stringify(this.state.inventoryPriceJson);
        const element = document.createElement("a");
        const file = new Blob([content], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "inventoryPriceJson"+this.props.hotelAuthReducer.hotelDetails.hotel.id+"-"+moment().format()+".json";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    render() {
        const { classes } = this.props;

        const columns = [
            {
                name: "Date Modified",
                options: {
                    filter: false,
                    sort: true,
                    sortDirection: 'desc'
                }
            },
            { 
                name: "Email",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: "User Name",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: "Change Log",
                options: {
                    filter: false,
                    download: false,
                    sort: false,
                    setCellProps: () => {
                        return { style: { color: '#541388', cursor: 'pointer' } }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                                <Tooltip title={JSON.stringify(value.data)}>
                                    <Button className={classes.changelogBtn}  onClick={(id) => this.ViewLogModal(value)}>{value.data ? JSON.stringify(value.data).substring(0, 50) : ""}</Button>
                                </Tooltip>
                        );
                    }
                }
            }
        ];
        return (
            <>
            <HeaderCard >
                <Grid container>
                <Grid item xs={12} >
                    <div className={classes.divStyle} >
                        <Typography variant="h5" className={classes.fontStyle} >
                            Settings Log
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} >

                <div className={classes.saveBtn}>
                        <Button  onClick={this.cacheDownload} variant="outlined" color="primary" className={classes.pricecacheBtn} >
                            Price Cache <Icon>downloading</Icon>
                        </Button>
                        <Button  onClick={this.inventoryPriceDownload} variant="outlined" color="primary" className={classes.pricecacheBtn} >
                            Inventory Json <Icon>downloading</Icon>
                        </Button>
                        <Button  onClick={this.jsonDownload} variant="outlined" color="primary" >
                            Setting Json <Icon>downloading</Icon>
                        </Button>
                    </div>

                    </Grid>
                </Grid>
            </HeaderCard>

            <Card>
                <MuiThemeProvider theme={settingsLogTheme}>                   
                    <CardContent>
                        <MUIDataTable
                            title={''}
                            data={(this.state.settingsLog) ? this.rendersettingsLogData(this.state.settingsLog):[]}
                            columns={columns}
                            options={this.options()}
                        />
                    </CardContent>

                </MuiThemeProvider>
                {this.state.logDetail && Modals.viewSettingLog(this)}
            </Card>
            </>
        );
    }
}

settingsLog.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        loading: state.settingsLogReducer.loading,
        error: state.settingsLogReducer.error,
        settingsLogReducer: state.settingsLogReducer,
        hotelAuthReducer: state.hotelAuthReducer,
        priceSettingReducer: state.priceSettingReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getsettingsLogList: (request) => { dispatch(actions.getsettingsLogList(request)) },
    };
};

export default compose(withStyles(styles, {
    withTheme: true
}), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(settingsLog));