import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import paymentCss from "./css/paymentSettingCss";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../../../store/actions/index";
import Server from "../../../config/server";
import axios from "axios";
import { Typography } from "@material-ui/core";
import Modals from "./modal/modal";
import DialogContentText from "@material-ui/core/DialogContentText";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import { withNamespaces } from "react-i18next";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Switch from "@material-ui/core/Switch";
import CardActions from "@material-ui/core/CardActions";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import ArrowForward from "@material-ui/icons/ArrowForward";
import FormControl from "@material-ui/core/FormControl";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import enLocale from "date-fns/locale/en-US";
import HeaderCard from "../HeaderCard";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = paymentCss;

const urlEncode = function (data) {
  var str = [];
  for (var p in data) {
    if (
      data.hasOwnProperty(p) &&
      !(data[p] === undefined || data[p] === null)
    ) {
      str.push(
        encodeURIComponent(p) +
          "=" +
          (data[p] ? encodeURIComponent(data[p]) : "")
      );
    }
  }
  return str.join("&");
};

class PaymentSetting extends React.Component {
  state = {
    pricingData: {
      currency: { name: "", symbol: "" },
      pricing: [],
    },
    rate: 0,
    openDate: false,
    openEdit: false,
    open: false,
    trialDate: "",
    //this is used for change staging and live
    cbInstance: window.Chargebee.init({
      site:
        process.env.REACT_APP_ENV == "live"
          ? "roompricegenie"
          : "roompricegenie-test",
    }),
    error: [],
    yearPrice: 0,
    monthPrice: 0,
    featuresEnabled: [],
    is_test: false,
    is_monthly: false,
    is_yearly: true,
    selectedPlanId: null,
    flagButton: 0,
    btnText: "Current Plan",
    openContactModal: false,
    selectedPlanName: "",
    start_monday: false,
    room_apartment_space_name: "",
    connectChargebeeModel: false,
    subscription_id: null,
  };

  componentWillMount() {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
    };
    this.props.getHotelDetail(requestParam);
    this.props.getMembershipPricing(requestParam);
    // this.props.getFeaturesList(requestParam);
    this.setState({
      room_apartment_space_name:
        this.props.hotelAuthReducer.hotelDetails.hotel
          .room_apartment_space_name,
    });
  }
  // ===============payment==============
  // hotel/payment/portal/
  handlePortal = () => {
    this.state.cbInstance.setPortalSession(function () {
      return axios
        .post(Server.API + "hotel/payment/portal/")
        .then((response) => response.data);
    });
    let cbPortal = this.state.cbInstance.createChargebeePortal();

    cbPortal.open({
      close() {
        //close callbacks
      },
    });
  };
  handleAccountInformation = (type) => {
    this.state.cbInstance.setPortalSession(function () {
      return axios
        .post(Server.API + "hotel/payment/portal/")
        .then((response) => response.data);
    });
    var cbPortal = this.state.cbInstance.createChargebeePortal();
    var callbacks = {};
    if (type == "AccountInfo") {
      cbPortal.openSection(
        {
          sectionType: window.Chargebee.getPortalSections().ACCOUNT_DETAILS,
          params: {
            subscriptionId: this.state.subscription_id,
          },
        },
        callbacks
      );
    } else if (type == "Address") {
      cbPortal.openSection(
        {
          sectionType: window.Chargebee.getPortalSections().ADDRESS,
          params: {
            subscriptionId: this.state.subscription_id,
          },
        },
        callbacks
      );
    } else if (type == "Payment") {
      cbPortal.openSection(
        {
          sectionType: window.Chargebee.getPortalSections().PAYMENT_SOURCES,
          params: {
            subscriptionId: this.state.subscription_id,
          },
        },
        callbacks
      );
    } else if (type == "Billing") {
      cbPortal.openSection(
        {
          sectionType: window.Chargebee.getPortalSections().BILLING_HISTORY,
          params: {
            subscriptionId: this.state.subscription_id,
          },
        },
        callbacks
      );
    }
  };
  handleCheckout = (event, is_yearly, subscription_plan) => {
    axios.defaults.headers.common["Authorization"] =
      "Token " + this.props.hotelAuthReducer.hotelDetails.token;
    // chargbee checkout
    this.state.cbInstance.openCheckout({
      hostedPage: () => {
        var data = {
          is_yearly: is_yearly ? 1 : 0,
          subscription_plan: subscription_plan,
        };
        return axios
          .post(Server.API + "hotel/payment/checkout/", data)
          .then((response) => response.data);
      },
      loaded: function (data) {},
      error: function (error) {},
      success(hostedPageId) {
        axios
          .post(
            Server.API + "hotel/chargebee/link/",
            urlEncode({
              hosted_page_token: hostedPageId,
              subscription_plan: subscription_plan,
            })
          )
          .then((response) => response.data);
      },
      close: () => {
        this.setState({ loading: false });
        window.location.reload();
      },
      step(step) {
        if (step === "thankyou_screen") {
        }
      },
    });
    event.preventDefault();
  };

  handleInvoiceCheckout(event) {
    // chargbee checkout
    this.state.cbInstance.openCheckout({
      hostedPage: () => {
        var data = {};
        return axios
          .post(
            Server.API + "account/chragebee-invoice-checkout/",
            urlEncode(data)
          )
          .then((response) => response.data);
      },
      loaded: function (data) {},
      error: function (error) {},
      success(hostedPageId) {
        axios
          .post(
            Server.API + "hotel/chargebee/link/",
            urlEncode({ hosted_page_token: hostedPageId })
          )
          .then((response) => response.data);
      },
      close: () => {
        this.setState({ loading: false });
        window.location.reload();
      },
      step(step) {
        if (step === "thankyou_screen") {
        }
      },
    });
    event.preventDefault();
  }

  handleInvoiceCheckout(event) {
    // chargbee checkout
    this.state.cbInstance.openCheckout({
      hostedPage: () => {
        var data = {};
        return axios
          .post(
            Server.API + "account/chragebee-invoice-checkout/",
            urlEncode(data)
          )
          .then((response) => response.data);
      },
      loaded: function (data) {},
      error: function (error) {},
      success(hostedPageId) {
        axios
          .post(
            Server.API + "hotel/chargebee/link/",
            urlEncode({ hosted_page_token: hostedPageId })
          )
          .then((response) => response.data);
      },
      close: () => {
        this.setState({ loading: false });
        window.location.reload();
      },
      step(step) {
        if (step === "thankyou_screen") {
        }
      },
    });
    event.preventDefault();
  }

  // ==============payment END ===============

  componentWillReceiveProps(props) {
    if (props.hotelDetailReducer && props.hotelDetailReducer.hoteDetail) {
      this.setState({
        start_monday: props.hotelDetailReducer.hoteDetail.starts_monday,
        subscription_id: props.hotelDetailReducer.hoteDetail.subscription_id,
      });
    }
    if (
      this.props.hotelDetailReducer.hoteDetail &&
      this.props.hotelDetailReducer.hoteDetail.free_trial_end
    ) {
      this.setState({
        trialDate: moment(
          this.props.hotelDetailReducer.hoteDetail.free_trial_end
        ).format("YYYY-MM-DD"),
      });
    }

    if (props.priceSettingReducer && props.priceSettingReducer.priceSetting) {
      this.setState({
        featuresEnabled: props.priceSettingReducer.priceSetting.features,
      });
    }

    if (
      props.commonReducer.priceAmount &&
      props.commonReducer.priceAmount.pricing
    ) {
      this.setState({
        pricingData: props.commonReducer.priceAmount,
      });
    }

    if (
      props.hotelDetailReducer.hoteDetail &&
      props.hotelDetailReducer.hoteDetail !=
        this.props.hotelDetailReducer.hoteDetail
    ) {
      let isTest =
        props.hotelDetailReducer.hoteDetail.state == 8 ? true : false;
      this.setState({
        is_test: isTest,
      });
    }
    if (props.hotelDetailReducer.hoteDetail) {
      this.setState({
        rate: props.hotelDetailReducer.hoteDetail.monthly_bill,
        selectedPlanId: props.hotelDetailReducer.hoteDetail.plan,
        selectedSubscriptionType:
          props.hotelDetailReducer.hoteDetail.subscription_type,
      });

      // this.state.selectedSubscriptionType == 1 ? this.setState({ is_yearly: false, is_monthly: true }) : this.setState({ is_yearly: true, is_monthly: false })

      if (this.state.featuresEnabled.includes(28)) {
        this.setState(
          {
            is_yearly: false,
            is_monthly: true,
          },
          () => this.toggleSubscriptionRate(0)
        );
      } else {
        this.state.selectedSubscriptionType == 1
          ? this.setState({ is_yearly: false, is_monthly: true })
          : this.setState({ is_yearly: true, is_monthly: false });
      }
    }
  }

  handleEditRate = (event) => {
    this.setState({
      rate: parseFloat(event.target.value) < 0 ? 0 : event.target.value,
    });
  };

  toggleEditRate = () => {
    this.setState({
      openEdit: !this.state.openEdit,
      rate: this.props.hotelDetailReducer.hoteDetail.monthly_bill,
      error: [],
    });
  };
  toggleEditDateOnSave = (value) => {
    this.setState({
      trialDate: value,
    });
  };

  toggleEditDate = (value) => {
    this.setState({
      trialDate: value,
      openDate: !this.state.openDate,
    });
  };

  closeEditDate = () => {
    this.setState({
      openDate: !this.state.openDate,
    });
  };
  handleDate = (event) => {
    this.setState({
      trialDate: event,
    });
  };

  makeDefaultRate = () => {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
    };
    this.props.updateDefaultRate(requestParam);
  };

  updateFreeTrialonSave = () => {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      date: moment(this.state.trialDate).format("YYYY-MM-DD"),
    };
    this.props.updateFreeTrial(requestParam);
  };

  updateFreeTrial = () => {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      date: moment(this.state.trialDate).format("YYYY-MM-DD"),
    };
    this.props.updateFreeTrial(requestParam);
    this.closeEditDate();
  };

  validateForm = (data) => {
    let error = [];
    if (data) {
      if (!this.state.rate) {
        error.push("rate");
      }

      if (error) {
        this.setState({
          error: error,
        });
        return error;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  updateRate = () => {
    let validateError = this.validateForm(this.state);
    if (validateError.length > 0) {
      return;
    }

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      rate: this.state.rate,
    };
    this.props.updateRate(requestParam);
    this.toggleEditRate();
  };

  renderNote = () => {
    const { classes, t } = this.props;
    if (
      this.props.hotelDetailReducer.hoteDetail &&
      this.props.hotelDetailReducer.hoteDetail.id
    ) {
      let userState = this.props.hotelDetailReducer.hoteDetail
        ? this.props.hotelDetailReducer.hoteDetail.state
        : null;
      if (userState == 7) {
        return (
          <div>
            <Typography variant='h6' align='left'>
              <span className={classes.trialColor}>
                {t("Your Subscription Has Expired")}
              </span>
            </Typography>

            <DialogContentText
              align='left'
              className={[classes.marginTop10, classes.helpText]}
            >
              {t(
                "We're sorry, but your subscription has expired. Please contact your representative "
              )}
              {this.state.featuresEnabled.includes(10)
                ? this.props.hotelDetailReducer.hoteDetail.reseller
                  ? " at " +
                    this.props.hotelDetailReducer.hoteDetail.reseller.name
                  : " at Reseller"
                : ""}
              .
            </DialogContentText>
          </div>
        );
      } else if (userState == 6 && this.state.featuresEnabled.includes(10)) {
        return (
          <div>
            <Typography variant='h6' align='left'>
              <span className={classes.trialColor}>
                {t("You are subscribed.")}
              </span>
            </Typography>

            <DialogContentText
              align='left'
              className={[classes.marginTop10, classes.helpText]}
            >
              {t(
                "If you have any questions, please contact your representative "
              )}
              {this.state.featuresEnabled.includes(10)
                ? this.props.hotelDetailReducer.hoteDetail.reseller
                  ? " at " +
                    this.props.hotelDetailReducer.hoteDetail.reseller.name
                  : " at Reseller"
                : ""}
              .
            </DialogContentText>
          </div>
        );
      } else if (
        this.props.hotelDetailReducer.hoteDetail.is_locked &&
        userState != 6 &&
        userState != 8
      ) {
        return (
          <div>
            <Typography variant='h6' align='left'>
              <span className={classes.trialColor}>
                {userState == 4 || userState == 5
                  ? "Oops!"
                  : t("All Good Things Come to an End...")}
              </span>
            </Typography>

            <DialogContentText
              align='left'
              className={[classes.marginTop10, classes.helpText]}
            >
              {userState == 4 || userState == 5
                ? t(
                    "It seems like there is a problem with your credit card. We tried 3 times but unfortunately it didn`t work. Please update your details if they are incorrect. And please call us if you are having any issues."
                  )
                : t(
                    "But this one doesn't have to. Just pop in your credit card details to continue to benefit from our great prices and your increased revenue. And if you choose the monthly payment option, you will have the flexibility to be able to cancel with maximum one month's notice. We're quietly confident that you won't want to though."
                  )}
            </DialogContentText>

            <DialogContentText
              align='left'
              className={[classes.marginTop10, classes.helpText]}
            >
              {userState == 4 || userState == 5
                ? ""
                : t(
                    "If not, best of luck in the future. And let us know if we can ever be of service."
                  )}
            </DialogContentText>
          </div>
        );
      }
    }
    return null;
  };

  adjustCall = (event) => {
    let requestParams = {
      user: this.props.hotelDetailReducer.hoteDetail.client,
      token: this.props.userSession.token,
      do_not_call: !this.props.hotelDetailReducer.hoteDetail.do_not_call,
    };
    this.props.updateAccountCall(requestParams);
  };

  changeState = () => (event) => {
    if (event.target.value) {
      let requestParam = {
        token: this.props.hotelAuthReducer.hotelDetails.token,
        state: event.target.value,
      };
      this.props.updateHotelState(requestParam);
    }
  };

  makeTestHotel = () => {
    this.setState(
      {
        is_test: !this.state.is_test,
      },
      () => {
        let requestParam = {
          token: this.props.hotelAuthReducer.hotelDetails.token,
          state: this.state.is_test ? 8 : 0,
        };

        if (this.state.is_test) {
          axios.post(Server.API + "hotel/set-to-test/").then((response) => {
            this.props.updateHotelState(requestParam);
          });
        } else {
          this.props.updateHotelState(requestParam);
        }
      }
    );
  };

  openContactModal = (value) => {
    this.setState({
      openContactModal: true,
      selectedPlanName: value,
    });
  };

  closeContactModal = () => {
    this.setState({
      openContactModal: false,
    });
  };

  toggleSubscriptionRate = (rate) => {
    const { t } = this.props;

    if (rate == 0) {
      this.setState(
        {
          is_monthly: true,
          is_yearly: false,
        },
        () => {
          if (this.state.selectedSubscriptionType) {
            if (
              this.props.hotelDetailReducer.hoteDetail.subscription_type == 1 &&
              this.state.is_yearly
            ) {
              this.setState({
                flagButton: 1,
                btnText: t("Switch to yearly payment"),
              });
            } else if (
              this.props.hotelDetailReducer.hoteDetail.subscription_type == 2 &&
              this.state.is_monthly
            ) {
              this.setState({
                flagButton: 1,
                btnText: t("Switch to monthly payment"),
              });
            } else if (
              this.props.hotelDetailReducer.hoteDetail.subscription_type == 1 &&
              this.state.is_monthly
            ) {
              this.setState({
                flagButton: 0,
                btnText: t("Current plan"),
              });
            } else if (
              this.props.hotelDetailReducer.hoteDetail.subscription_type == 2 &&
              this.state.is_yearly
            ) {
              this.setState({
                flagButton: 0,
                btnText: t("Current plan"),
              });
            }
          }
        }
      );
    } else {
      this.setState(
        {
          is_yearly: true,
          is_monthly: false,
        },
        () => {
          if (this.state.selectedSubscriptionType) {
            if (
              this.props.hotelDetailReducer.hoteDetail.subscription_type == 1 &&
              this.state.is_yearly
            ) {
              this.setState({
                flagButton: 1,
                btnText: t("Switch to yearly payment"),
              });
            } else if (
              this.props.hotelDetailReducer.hoteDetail.subscription_type == 2 &&
              this.state.is_monthly
            ) {
              this.setState({
                flagButton: 1,
                btnText: t("Switch to monthly payment"),
              });
            } else if (
              this.props.hotelDetailReducer.hoteDetail.subscription_type == 1 &&
              this.state.is_monthly
            ) {
              this.setState({
                flagButton: 0,
                btnText: t("Current Plan"),
              });
            } else if (
              this.props.hotelDetailReducer.hoteDetail.subscription_type == 2 &&
              this.state.is_yearly
            ) {
              this.setState({
                flagButton: 0,
                btnText: t("Current Plan"),
              });
            }
          }
        }
      );
    }
  };
  connectChargebee = () => {
    this.setState({
      connectChargebeeModel: true,
    });
  };
  closeChargebeeModel = () => {
    this.setState({
      connectChargebeeModel: false,
    });
  };

  chargebeeSubscription = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  connectChargebeeSubscription = () => {
    let requestParam = {
      subscription_id: this.state.subscription_id,
      token: this.props.hotelAuthReducer.hotelDetails.token,
    };
    this.props.chargebeeSubscription(requestParam);
  };
  renderNewDesign() {
    const { classes } = this.props;
    const { t } = this.props;
    var CurrencyFormat = require("react-currency-format");
    let userState = this.props.hotelAuthReducer.hotelDetails.hotel
      ? this.props.hotelAuthReducer.hotelDetails.hotel.state
      : null;
    let is_admin_partners =
      this.props.userSession.user.is_staff ||
      this.props.userSession.user.sub_account_type
        ? true
        : false;
    this.state.start_monday
      ? (enLocale.options.weekStartsOn = 1)
      : (enLocale.options.weekStartsOn = 0);
    let is_admin = this.props.userSession.user.is_staff ? true : false;
    return (
      <>
        <HeaderCard>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.divStyle}>
                <Typography className={classes.fontStyle} variant='h5'>
                  {t("Subscription")}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </HeaderCard>
        <Card>
          {this.props.hotelDetailReducer.hoteDetail &&
          this.props.hotelDetailReducer.hoteDetail.free_trial_end &&
          moment(this.props.hotelDetailReducer.hoteDetail.free_trial_end).diff(
            moment().startOf("day"),
            "days"
          ) >= 0 ? (
            userState == 0 ? (
              <Typography className={classes.trialAlignment1}>
                {t("Your free trial is about to expire on")}
                <span className={classes.trialSpan}>
                  {" " +
                    moment(
                      this.props.hotelDetailReducer.hoteDetail.free_trial_end
                    ).format("Do MMMM")}
                  .{" "}
                </span>
                {t("You have")}
                <span className={classes.trialSpan}>
                  {" "}
                  {moment(
                    this.props.hotelDetailReducer.hoteDetail.free_trial_end
                  ).diff(moment().startOf("day"), "days") + " "}
                  {t("days left.")}
                </span>
              </Typography>
            ) : (
              ""
            )
          ) : userState == 3 ? (
            <Typography className={classes.trialAlignment}>
              {t("Your free trial has expired.")}
            </Typography>
          ) : (
            ""
          )}

          <CardContent className={classes.mainCardContent}>
            <Typography className={classes.renderNote}>
              {this.renderNote()}
            </Typography>
          </CardContent>

          <Grid container>
            {userState == 1 && (
              <Typography className={classes.subscribeMsg}>
                {t("You are subscribed.")}
              </Typography>
            )}

            {userState == 9 && (
              <Typography className={classes.subscribeMsg}>
                {t("Your subscription is paused.")}
              </Typography>
            )}

            {userState != 0 && userState != 3 && userState != 8 && (
              <Grid item xs={12} className={classes.yrAccGrid}>
                {this.props.hotelDetailReducer.hoteDetail &&
                  this.props.hotelDetailReducer.hoteDetail.subscription_id && (
                    <span>
                      <Button
                        className={classes.manageYr}
                        variant='contained'
                        color='primary'
                        onClick={this.handlePortal}
                      >
                        {t("Edit Subscription and Billing")}
                      </Button>
                      <Button
                        className={classes.manageYrAcc}
                        onClick={(e) =>
                          this.handleAccountInformation("AccountInfo")
                        }
                      >
                        {t("Account Information")}
                      </Button>
                      <Button
                        className={classes.manageYrAcc}
                        onClick={(e) =>
                          this.handleAccountInformation("Address")
                        }
                      >
                        {t("Billing Addresses")}
                      </Button>
                      <Button
                        className={classes.manageYrAcc}
                        onClick={(e) =>
                          this.handleAccountInformation("Payment")
                        }
                      >
                        {t("Payment Methods")}
                      </Button>
                      <Button
                        className={classes.manageYrAcc}
                        onClick={(e) =>
                          this.handleAccountInformation("Billing")
                        }
                      >
                        {t("Billing History")}
                      </Button>
                    </span>
                  )}
              </Grid>
            )}

            <Grid item xs={12} className={classes.mailToggleGrid}>
              {(this.props.userSession.user.is_staff ||
                this.props.userSession.user.sub_account_type) && (
                <FormControlLabel
                  className={classes.mailToggle}
                  control={
                    <Switch
                      color='primary'
                      checked={this.state.is_test}
                      onChange={() => this.makeTestHotel()}
                    />
                  }
                  label={t("Demo Hotel")}
                />
              )}

              {this.state.featuresEnabled.includes(10) &&
                (this.props.userSession.user.is_staff ||
                  this.props.userSession.user.sub_account_type) && (
                  <RadioGroup
                    aria-label='Language'
                    className={classes.radioPartner}
                    value={
                      this.props.hotelDetailReducer.hoteDetail
                        ? this.props.hotelDetailReducer.hoteDetail.state
                        : ""
                    }
                  >
                    <FormControlLabel
                      onClick={this.changeState()}
                      value={6}
                      label='Subscribed to Partner'
                      control={<Radio color='primary' />}
                    />

                    <FormControlLabel
                      onClick={this.changeState()}
                      value={7}
                      label='Unsubscribed'
                      control={<Radio color='primary' />}
                    />

                    <FormControlLabel
                      onClick={this.changeState()}
                      value={0}
                      label='Free Trial'
                      control={<Radio color='primary' />}
                    />
                  </RadioGroup>
                )}
            </Grid>

            {(userState == 0 || userState == 3) &&
              is_admin_partners &&
              this.props.hotelDetailReducer.hoteDetail &&
              this.props.hotelDetailReducer.hoteDetail.free_trial_end && (
                <Grid container className={classes.datePickerDiv}>
                  <Grid item xs={12} md={2}>
                    <FormControl
                      margin='normal'
                      required
                      fullWidth
                      className={classes.formControl}
                    >
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={enLocale}
                      >
                        <DatePicker
                          label={t("End of Free Trial")}
                          minDate={moment()}
                          value={this.state.trialDate && this.state.trialDate}
                          onChange={(event) => this.handleDate(event)}
                          className={classes.datePicker}
                          format={"dd/MM/yyyy"}
                          InputLabelProps={{ shrink: true }}
                          autoOk={true}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    {(this.props.userSession.user.is_staff ||
                      this.props.userSession.user.sub_account_type) && (
                      <Button
                        className={classes.dateSaveBtn}
                        variant='contained'
                        color='primary'
                        onClick={() => this.updateFreeTrialonSave()}
                      >
                        {t("Save")}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              )}
            <Grid className={classes.toggleGrid} item xs={12} md={12}>
              <Typography className={classes.togglesubBtn}>
                {t("Paid")}
              </Typography>

              <Button
                variant='outlined'
                className={classes.newTabBtnFirst}
                onClick={() => this.toggleSubscriptionRate(0)}
                style={this.state.is_monthly ? { background: "#f2f2f2" } : {}}
              >
                {t("Monthly")}
              </Button>

              <Button
                variant='outlined'
                className={classes.newTabBtnLast}
                onClick={() => this.toggleSubscriptionRate(1)}
                style={this.state.is_yearly ? { background: "#f2f2f2" } : {}}
              >
                {t("Yearly")}
              </Button>

              {is_admin && (
                <Button
                  variant='outlined'
                  className={classes.chargebeeBtn}
                  color='primary'
                  onClick={() => this.connectChargebee()}
                >
                  {t("Connect a Chargebee Subscription")}
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {this.state.featuresEnabled.includes(28) &&
              !this.state.is_yearly &&
              this.props.commonReducer.priceAmount &&
              this.props.commonReducer.priceAmount?.pricing[5]?.yearly &&
              this.props.commonReducer.priceAmount?.pricing[5]?.monthly && (
                <Grid item xs={12} sm={3} className={classes.subscriptionGrid}>
                  <Card className={classes.subscriptionCard} variant='outlined'>
                    <CardHeader
                      classes={{ title: classes.title }}
                      title={"Small Business"}
                      className={classes.subscriptionCardHeader}
                    ></CardHeader>
                    <CardContent className={classes.MuiCardContent}>
                      <Typography className={classes.subscriptionCardContent}>
                        {t(
                          "Our starter solution helping you to automate your pricing. Covering all the basics you need for great pricing."
                        )}
                      </Typography>
                      <Typography
                        className={[classes.subContent, classes.priceCheck]}
                      >
                        {t("Your Price")}
                      </Typography>

                      <Typography className={classes.priceContent}>
                        <CurrencyFormat
                          value={
                            this.props.commonReducer.priceAmount
                              ? this.state.is_yearly
                                ? this.props.commonReducer.priceAmount
                                    .pricing[5].yearly.price_per_month
                                : this.state.pricingData.pricing[5].monthly
                                    .price
                              : " "
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          className={classes.perMonath}
                          prefix={
                          this.props.commonReducer.priceAmount
                              ? this.props.commonReducer.priceAmount.pricing[5].currency
                                    .symbol 
                                : ""
                          }
                        />
                        <span className={classes.perMontheSpan}>
                          {t("per month")}
                        </span>
                        <br />

                        {/* <CurrencyFormat
                        value={this.props.commonReducer.priceAmount ? (this.state.is_yearly ? this.props.commonReducer.priceAmount.pricing[5].yearly.price : '') : " "}
                        displayType={"text"}
                        thousandSeparator={true}
                        className={classes.perYear}
                        prefix={this.props.commonReducer.priceAmount ? (this.props.commonReducer.priceAmount.currency.name == "AUD" ? (this.props.commonReducer.priceAmount.currency.name + this.props.commonReducer.priceAmount.currency.symbol) : this.props.commonReducer.priceAmount.currency.symbol) : " "} />
                      {this.state.is_yearly && <span className={classes.perYearSpan} > {t("per year")}</span>} */}
                      </Typography>
                      <Typography
                        className={[classes.subContent, classes.helpFont]}
                      >
                        {this.props.commonReducer.priceAmount
                          ? this.state.is_yearly
                            ? t("Paid Yearly")
                            : t("Paid Monthly")
                          : " "}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      {this.state.selectedPlanId ? (
                        this.state.selectedPlanId == 5 ? (
                          this.state.flagButton == 0 ? (
                            <Button
                              variant='outlined'
                              color='primary'
                              className={classes.subscribeBtn}
                              fullWidth={true}
                              onClick={this.handlePortal}
                            >
                              {t(this.state.btnText)}
                            </Button>
                          ) : (
                            <Button
                              variant='outlined'
                              color='primary'
                              className={[classes.subscribeBtn, classes.btnsub]}
                              fullWidth={true}
                              onClick={(event) =>
                                this.handleCheckout(
                                  event,
                                  this.state.is_yearly ? true : false,
                                  5
                                )
                              }
                            >
                              {t(this.state.btnText)}
                            </Button>
                          )
                        ) : this.state.selectedPlanId >= 2 ? (
                          <Button
                            variant='outlined'
                            color='primary'
                            className={classes.subscribeBtn}
                            fullWidth={true}
                            onClick={() =>
                              this.openContactModal("Small Business")
                            }
                          >
                            {t("Contact Us")}
                          </Button>
                        ) : (
                          <Button
                            variant='contained'
                            color='primary'
                            className={classes.subscribeBtn}
                            fullWidth={true}
                            onClick={(event) =>
                              this.handleCheckout(
                                event,
                                this.state.is_yearly ? true : false,
                                5
                              )
                            }
                          >
                            {t("Upgrade")}
                          </Button>
                        )
                      ) : (
                        <Button
                          variant='outlined'
                          color='primary'
                          className={classes.subscribeBtn}
                          fullWidth={true}
                          onClick={(event) =>
                            this.handleCheckout(
                              event,
                              this.state.is_yearly ? true : false,
                              5
                            )
                          }
                        >
                          {t("Subscribe")}
                        </Button>
                      )}
                    </CardActions>
                    {/* <CardContent className={classes.MuiCardContent2}>
                      <Typography className={classes.topContent}>
                        {t("Top Features:")}
                      </Typography>

                      <Typography className={classes.typoText}>
                        <CheckIcon className={classes.checkIcon}> </CheckIcon>
                        {t("Aggregated OTA market data")}
                      </Typography>
                      <Typography className={classes.typoText}>
                        <CheckIcon className={classes.checkIcon}> </CheckIcon>
                        {t("9 auto-updates a day")}
                      </Typography>
                      <Typography className={classes.typoText}>
                        <CheckIcon className={classes.checkIcon}> </CheckIcon>
                        {t("Control individual day prices")}
                      </Typography>
                      <Typography className={classes.typoText}>
                        <CheckIcon className={classes.checkIcon}> </CheckIcon>
                        {t("Control adjustments by")}
                        <Typography className={classes.subFeature}>
                          {t("day-of-week")}
                        </Typography>
                      </Typography>
                      <Typography className={classes.typoText}>
                        <CheckIcon className={classes.checkIcon}> </CheckIcon>
                        {t("Simplest version to use")}
                      </Typography>
                      {this.state.openContactModal
                        ? Modals.contactUsModal(this)
                        : ""}
                    </CardContent> */}
                  </Card>
                </Grid>
              )}
            {!this.state.featuresEnabled.includes(28) &&
              this.props.commonReducer.priceAmount &&
              this.props.commonReducer.priceAmount.pricing[2].yearly &&
              this.props.commonReducer.priceAmount.pricing[2].monthly && (
                <Grid item xs={12} sm={3} className={classes.subscriptionGrid}>
                  <Card className={classes.subscriptionCard} variant='outlined'>
                    <CardHeader
                      classes={{ title: classes.title }}
                      title={"Starter"}
                      className={classes.subscriptionCardHeader}
                    ></CardHeader>

                    <CardContent className={classes.MuiCardContent}>
                      <Typography className={classes.subscriptionCardContent}>
                    {t("Our starter solution helping you to automate your pricing. Covering all the basics you need for great pricing.")}
                      </Typography>
                      <Typography
                        className={[classes.subContent, classes.priceCheck]}
                      >
                        {t("Your Price")}
                      </Typography>

                      <Typography className={classes.priceContent}>
                        <CurrencyFormat
                          value={
                            this.props.commonReducer.priceAmount
                              ? this.state.is_yearly
                                ? this.props.commonReducer.priceAmount
                                    .pricing[2].yearly.price_per_month
                                : this.state.pricingData.pricing[2].monthly
                                    .price
                              : " "
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          className={classes.perMonath}
                          prefix={
                            this.props.commonReducer.priceAmount
                              ? this.props.commonReducer.priceAmount.pricing[2].currency
                                    .symbol 
                                : ""
                          }
                        />
                        <span className={classes.perMontheSpan}>
                          {t("per month")}
                        </span>
                        <br />

                        <CurrencyFormat
                          value={
                            this.props.commonReducer.priceAmount
                              ? this.state.is_yearly
                                ? this.props.commonReducer.priceAmount
                                    .pricing[2].yearly.price
                                : ""
                              : " "
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          className={classes.perYear}
                          prefix={
                          this.props.commonReducer.priceAmount
                              ?  this.props.commonReducer.priceAmount.pricing[2].currency
                                    .symbol 
                                : ""
                          }
                        />
                        {this.state.is_yearly && (
                          <span className={classes.perYearSpan}>
                            {" "}
                            {t("per year")}
                          </span>
                        )}
                      </Typography>
                      <Typography
                        className={[classes.subContent, classes.helpFont]}
                      >
                        {this.props.commonReducer.priceAmount
                          ? this.state.is_yearly
                            ? t("Paid Yearly")
                            : t("Paid Monthly")
                          : " "}
                      </Typography>
                    </CardContent>

                    <CardActions>
                      {this.state.selectedPlanId ? (
                        this.state.selectedPlanId == 2 ? (
                          this.state.flagButton == 0 ? (
                            <Button
                              variant='outlined'
                              color='primary'
                              className={classes.subscribeBtn}
                              fullWidth={true}
                              onClick={this.handlePortal}
                            >
                              {t(this.state.btnText)}
                            </Button>
                          ) : (
                            <Button
                              variant='outlined'
                              color='primary'
                              className={[classes.subscribeBtn, classes.btnsub]}
                              fullWidth={true}
                              onClick={(event) =>
                                this.handleCheckout(
                                  event,
                                  this.state.is_yearly ? true : false,
                                  2
                                )
                              }
                            >
                              {t(this.state.btnText)}
                            </Button>
                          )
                        ) : this.state.selectedPlanId > 2 ? (
                          <Button
                            variant='outlined'
                            color='primary'
                            className={classes.subscribeBtn}
                            fullWidth={true}
                            onClick={() => this.openContactModal("Basic")}
                          >
                            {t("Contact Us")}
                          </Button>
                        ) : (
                          <Button
                            variant='contained'
                            color='primary'
                            className={classes.subscribeBtn}
                            fullWidth={true}
                            onClick={(event) =>
                              this.handleCheckout(
                                event,
                                this.state.is_yearly ? true : false,
                                2
                              )
                            }
                          >
                            {t("Upgrade")}
                          </Button>
                        )
                      ) : (
                        <Button
                          variant='outlined'
                          color='primary'
                          className={classes.subscribeBtn}
                          fullWidth={true}
                          onClick={(event) =>
                            this.handleCheckout(
                              event,
                              this.state.is_yearly ? true : false,
                              2
                            )
                          }
                        >
                          {t("Subscribe")}
                        </Button>
                      )}
                    </CardActions>

                    {/* <CardContent className={classes.MuiCardContent2}>
                      <Typography className={classes.topContent}>
                        {t("Top Features:")}
                      </Typography>

                      <Typography className={classes.typoText}>
                        <CheckIcon className={classes.checkIcon}> </CheckIcon>
                        {t("12 months future price recommendations")}
                      </Typography>

                      <Typography className={classes.typoText}>
                        <CheckIcon className={classes.checkIcon}> </CheckIcon>
                        {t("Basics controls")}
                      </Typography>
                      <Typography className={classes.typoText}>
                        <CheckIcon className={classes.checkIcon}> </CheckIcon>
                        {t("1 auto-update to your PMS a week (unlimited manual updates)")}
                      </Typography>
                    </CardContent>
                    <CardContent className={classes.MuiCardContentServices}>
                      <Typography className={classes.topContent}>
                        {t("Included Services:")}
                      </Typography>

                      <Typography className={classes.typoText}>
                        <CheckIcon className={classes.checkIcon}> </CheckIcon>
                        {t("Integration Setup")}
                      </Typography>

                      <Typography className={classes.typoText}>
                        <CheckIcon className={classes.checkIcon}> </CheckIcon>
                        {t("Personal onboarding")}
                      </Typography>
                      <Typography className={classes.typoText}>
                        <CheckIcon className={classes.checkIcon}> </CheckIcon>
                        {t("Email and chat support")}
                      </Typography>
                    </CardContent> */}
                    {this.state.openContactModal
                      ? Modals.contactUsModal(this)
                      : ""}
                  </Card>
                </Grid>
              )}

            {this.props.commonReducer.priceAmount &&
              this.props.commonReducer.priceAmount.pricing[3].yearly &&
              this.props.commonReducer.priceAmount.pricing[3].monthly && (
                <Grid item xs={12} sm={3} className={classes.subscriptionGrid}>
                  <Card
                    className={classes.subscriptionCardFlexible}
                    variant='outlined'
                  >
                    <CardHeader
                      classes={{ title: classes.title }}
                      title={"Advanced"}
                      className={classes.subscriptionCardHeader}
                    ></CardHeader>

                    <CardContent className={classes.MuiCardContent}>
                      <Typography className={classes.subscriptionCardContent}>
                        {t(
                          "Our always-up-to-date and never-late solution that lets you maximize your opportunities. The perfect solution for everyone looking for a full-time revenue genie."
                        )}
                      </Typography>

                      <Typography
                        className={[classes.subContent, classes.priceCheck]}
                      >
                        {t("Your Price")}
                      </Typography>

                      <Typography className={classes.priceContent}>
                        <CurrencyFormat
                          value={
                            this.props.commonReducer.priceAmount
                              ? this.state.is_yearly
                                ? this.props.commonReducer.priceAmount
                                    .pricing[3].yearly.price_per_month
                                : this.state.pricingData.pricing[3].monthly
                                    .price
                              : " "
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          className={classes.perMonath}
                          prefix={
                           this.props.commonReducer.priceAmount
                              ? this.props.commonReducer.priceAmount.pricing[3].currency
                                    .symbol 
                                : ""
                          }
                        />
                        <span className={classes.perMontheSpan}>
                          {t("per month")}
                        </span>
                        <br />

                        <CurrencyFormat
                          value={
                            this.props.commonReducer.priceAmount
                              ? this.state.is_yearly
                                ? this.props.commonReducer.priceAmount
                                    .pricing[3].yearly.price
                                : ""
                              : " "
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          className={classes.perYear}
                          prefix={
                            this.props.commonReducer.priceAmount
                              ? this.props.commonReducer.priceAmount.pricing[3].currency
                                    .symbol 
                                : ""
                          }
                        />
                        {this.state.is_yearly && (
                          <span className={classes.perYearSpan}>
                            {" "}
                            {t("per year")}
                          </span>
                        )}
                      </Typography>

                      <Typography
                        className={[classes.subContent, classes.helpFont]}
                      >
                        {this.props.commonReducer.priceAmount
                          ? this.state.is_yearly
                            ? t("Paid Yearly")
                            : t("Paid Monthly")
                          : " "}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      {this.state.selectedPlanId ? (
                        this.state.selectedPlanId == 3 ? (
                          this.state.flagButton == 0 ? (
                            <Button
                              variant='outlined'
                              color='primary'
                              className={classes.subscribeBtn}
                              fullWidth={true}
                              onClick={this.handlePortal}
                            >
                              {t(this.state.btnText)}
                            </Button>
                          ) : (
                            <Button
                              variant='outlined'
                              color='primary'
                              className={[classes.subscribeBtn, classes.btnsub]}
                              fullWidth={true}
                              onClick={(event) =>
                                this.handleCheckout(
                                  event,
                                  this.state.is_yearly ? true : false,
                                  3
                                )
                              }
                            >
                              {t(this.state.btnText)}
                            </Button>
                          )
                        ) : this.state.selectedPlanId > 3 ? (
                          <Button
                            variant='outlined'
                            color='primary'
                            className={classes.subscribeBtn}
                            fullWidth={true}
                            onClick={() => this.openContactModal("Flexible")}
                          >
                            {t("Contact Us")}
                          </Button>
                        ) : (
                          <Button
                            variant='contained'
                            color='primary'
                            className={classes.subscribeBtn}
                            fullWidth={true}
                            onClick={(event) =>
                              this.handleCheckout(
                                event,
                                this.state.is_yearly ? true : false,
                                3
                              )
                            }
                          >
                            {t("Upgrade")}
                          </Button>
                        )
                      ) : (
                        <Button
                          variant='contained'
                          color='primary'
                          className={classes.subscribeBtn}
                          fullWidth={true}
                          onClick={(event) =>
                            this.handleCheckout(
                              event,
                              this.state.is_yearly ? true : false,
                              3
                            )
                          }
                        >
                          {t("Subscribe")}
                        </Button>
                      )}
                    </CardActions>
                    {/* <CardContent className={classes.MuiCardContent2}>
                      <Typography className={classes.topContent}>
                        {t("Top Features:")}
                      </Typography>

                      <Typography className={classes.typoText}>
                        <CheckIcon className={classes.checkIcon}> </CheckIcon>
                        {t("12 months future price recommendations")}
                      </Typography>
                      <Typography className={classes.typoText}>
                        <CheckIcon className={classes.checkIcon}> </CheckIcon>
                        {t("Full pricing control")}
                      </Typography>

                      



                      <Typography className={classes.typoText}>
                        <CheckIcon className={classes.checkIcon}> </CheckIcon>
                        {t("4 auto-updates to your PMS a day (unlimited manual updates)")}
                      </Typography>
                      <Typography className={classes.typoText}>
                        <CheckIcon className={classes.checkIcon}> </CheckIcon>
                        {t("Bulk edit")}
                      </Typography>
                      <Typography className={classes.typoText}>
                        <CheckIcon className={classes.checkIcon}> </CheckIcon>
                        {t("Strategy settings")}
                      </Typography>
                    </CardContent>
                    <CardContent className={classes.MuiCardContentServices}>
                      <Typography className={classes.topContent}>
                        {t("Included Services:")}
                      </Typography>

                      <Typography className={classes.typoText}>
                        <CheckIcon className={classes.checkIcon}> </CheckIcon>
                        {t("Yearly revenue management review")}
                      </Typography>
                      <Typography className={classes.typoText}>
                        <CheckIcon className={classes.checkIcon}> </CheckIcon>
                        {t("Integration Setup")}
                      </Typography>
                      <Typography className={classes.typoText}>
                        <CheckIcon className={classes.checkIcon}> </CheckIcon>
                        {t("Personal onboarding")}
                      </Typography>
                      <Typography className={classes.typoText}>
                        <CheckIcon className={classes.checkIcon}> </CheckIcon>
                        {t("Email and chat support")}
                      </Typography>
                    </CardContent> */}
                  </Card>
                </Grid>
              )}
            {this.props.commonReducer.priceAmount &&
              this.props.commonReducer.priceAmount.pricing[4].yearly &&
              this.props.commonReducer.priceAmount.pricing[4].monthly && (
                <Grid item xs={12} sm={3} className={classes.subscriptionGrid}>
                  <Card className={classes.subscriptionCard} variant='outlined'>
                    <CardHeader
                      classes={{ title: classes.title }}
                      title={"Professional"}
                      className={classes.subscriptionCardHeader}
                    ></CardHeader>
                    <CardContent className={classes.MuiCardContent}>
                      <Typography className={classes.subscriptionCardContent}>
                        {t(
                          "More information, more control, and more service. Ideal for either an expert revenue manager or if you would like the help and support to become one."
                        )}
                      </Typography>

                      <Typography
                        className={[classes.subContent]}
                      >
                        {t("Your Price")}
                      </Typography>

                      <Typography
                        className={[classes.priceContent]}
                      >
                        <CurrencyFormat
                          value={
                            this.props.commonReducer.priceAmount
                              ? this.state.is_yearly
                                ? this.props.commonReducer.priceAmount
                                    .pricing[4].yearly.price_per_month
                                : this.state.pricingData.pricing[4].monthly
                                    .price
                              : " "
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          className={classes.perMonath}
                          prefix={
                            this.props.commonReducer.priceAmount
                              ? this.props.commonReducer.priceAmount.pricing[4].currency
                                .symbol 
                                : ""
                          }
                        />
                        <span className={classes.perMontheSpan}>
                          {t("per month")}
                        </span>
                        <br />

                        <CurrencyFormat
                          value={
                            this.props.commonReducer.priceAmount
                              ? this.state.is_yearly
                                ? this.props.commonReducer.priceAmount
                                    .pricing[4].yearly.price
                                : ""
                              : " "
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          className={classes.perYear}
                          prefix={
                              this.props.commonReducer.priceAmount
                              ? 
                              this.props.commonReducer.priceAmount.pricing[4].currency
                                    .symbol 
                                : ""
                          }
                        />
                        {this.state.is_yearly && (
                          <span className={classes.perYearSpan}>
                            {" "}
                            {t("per year")}
                          </span>
                        )}
                      </Typography>

                      <Typography
                        className={[classes.subContent, classes.subContent2]}
                      >
                        {this.props.commonReducer.priceAmount
                          ? this.state.is_yearly
                            ? t("Paid Yearly")
                            : t("Paid Monthly")
                          : " "}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      {this.state.selectedPlanId ? (
                        this.state.selectedPlanId == 4 ? (
                          this.state.flagButton == 0 ? (
                            <Button
                              // disabled={true}
                              variant='outlined'
                              color='primary'
                              className={classes.subscribeBtn}
                              fullWidth={true}
                              onClick={this.handlePortal}
                            >
                              {t(this.state.btnText)}
                            </Button>
                          ) : (
                            <Button
                              variant='outlined'
                              color='primary'
                              className={[classes.subscribeBtn, classes.btnsub]}
                              fullWidth={true}
                              // disabled={true}
                              onClick={(event) =>
                                this.handleCheckout(
                                  event,
                                  this.state.is_yearly ? true : false,
                                  4
                                )
                              }
                            >
                              {t(this.state.btnText)}
                            </Button>
                          )
                        ) : this.state.selectedPlanId > 4 ? (
                          <Button
                            variant='outlined'
                            color='primary'
                            // disabled={true}
                            className={classes.subscribeBtn}
                            fullWidth={true}
                            onClick={() => this.openContactModal("Pro")}
                          >
                            {t("Contact Us")}
                          </Button>
                        ) : (
                          <Button
                            variant='contained'
                            color='primary'
                            // disabled={true}
                            className={classes.subscribeBtn}
                            fullWidth={true}
                            onClick={(event) =>
                              this.handleCheckout(
                                event,
                                this.state.is_yearly ? true : false,
                                4
                              )
                            }
                          >
                            {t("Upgrade")}
                          </Button>
                        )
                      ) : (
                        <Button
                          variant='outlined'
                          // disabled={true}
                          color='primary'
                          className={classes.subscribeBtn}
                          fullWidth={true}
                          onClick={(event) =>
                            this.handleCheckout(
                              event,
                              this.state.is_yearly ? true : false,
                              4
                            )
                          }
                        >
                          {t("Subscribe")}
                        </Button>
                      )}
                    </CardActions>
                    {/* <CardContent className={classes.MuiCardContent2}>
                      <Typography
                        className={[classes.topContent]}
                      >
                        {t("Top Features:")}
                      </Typography>
                      <Typography className={classes.typoText}>
                        <CheckIcon
                          className={[classes.checkIcon]}
                        ></CheckIcon>
                        {t("18 months future price recommendations")}
                      </Typography>

                      <Typography className={classes.typoText}>
                        <CheckIcon
                          className={[classes.checkIcon]}
                        ></CheckIcon>
                        {t("Full algorithm control")}
                      </Typography>

                      <Typography className={classes.typoText}>
                        <CheckIcon
                          className={[classes.checkIcon]}
                        ></CheckIcon>
                        {t("12 auto-updates to your PMS a day (unlimited manual updates)")}
                      </Typography>
                      <Typography className={classes.typoText}>
                        <CheckIcon
                          className={[classes.checkIcon]}
                        ></CheckIcon>
                        {t("Monthly Revenue Forecasting")}
                      </Typography>

                      <Typography className={classes.typoText}>
                        <CheckIcon
                          className={[classes.checkIcon]}
                        ></CheckIcon>
                        {t("Performance Dashboard (incl. KPI Analysis, Pickup Analysis & Revenue Budgeting and Forecasting)")}
                      </Typography>

                      <Typography className={classes.typoText}>
                        <CheckIcon
                          className={[classes.checkIcon]}
                        ></CheckIcon>
                        {t("Analytics Dashboard")}
                      </Typography>

                      <Typography className={classes.typoText}>
                        <CheckIcon
                          className={[classes.checkIcon]}
                        ></CheckIcon>
                        {t("Scheduled Reports")}
                      </Typography>

                      <Typography className={classes.typoText}>
                        <CheckIcon
                          className={[classes.checkIcon]}
                        ></CheckIcon>
                        {t("Segmentation")}
                      </Typography>
                      <Typography className={classes.typoText}>
                        <CheckIcon
                          className={[classes.checkIcon]}
                        ></CheckIcon>
                        {t("Yield room types individually")}
                      </Typography>
                      <Typography className={classes.typoText}>
                        <CheckIcon
                          className={[classes.checkIcon]}
                        ></CheckIcon>
                        {t("OTA Insight integration*")}
                      </Typography>
                    </CardContent>
                    <CardContent className={classes.MuiCardContentServices}>
                      <Typography
                        className={[classes.topContent]}
                      >
                        {t("Included Services:")}
                      </Typography>
                      <Typography className={classes.typoText}>
                        <CheckIcon
                          className={[classes.checkIcon]}
                        ></CheckIcon>
                        {t("Monthly revenue management reviews")}
                      </Typography>
                      <Typography className={classes.typoText}>
                        <CheckIcon
                          className={[classes.checkIcon]}
                        ></CheckIcon>
                        {t("Phone Support")}
                      </Typography>
                      <Typography className={classes.typoText}>
                        <CheckIcon
                          className={[classes.checkIcon]}
                        ></CheckIcon>
                        {t("Integration Setup")}
                      </Typography>
                      <Typography className={classes.typoText}>
                        <CheckIcon
                          className={[classes.checkIcon]}
                        ></CheckIcon>
                        {t("Personal onboarding")}
                      </Typography>
                      <Typography className={classes.typoText}>
                        <CheckIcon
                          className={[classes.checkIcon]}
                        ></CheckIcon>
                        {t("Email and chat support")}
                      </Typography>
                    </CardContent> */}
                  </Card>
                </Grid>
              )}
              
          </Grid>
          <Button
                className={classes.redirectBtn}
                onClick={(e) =>
                  window.open("https://roompricegenie.com/pricing/",'_blank')
                }
              >
                {t("See how our plans compare")}
                <ArrowForward />
              </Button>
        </Card>
        {/* <Card className={classes.tableCard}>
        <Grid sm={12} md={9}>
            <Typography variant="h6" align="left">{t('Choose the plan that’s right for your property')}</Typography>
          <Table className={classes.table}>
            <TableHead>
                <TableRow className={classes.row} >
                <TableCell align="center"></TableCell>
                <TableCell align="center" className={classes.tableHeader}>{t('Starter')}</TableCell>
                <TableCell align="center" className={classes.tableHeader}>{t('Advanced')}</TableCell>
                <TableCell align="center" className={classes.tableHeader}>{t('Professional')}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            <TableRow className={classes.row} >
              <TableCell align="left">{t('Product inclusions')}</TableCell>
              <TableCell align="center">12</TableCell>
              <TableCell align="center">12</TableCell>
              <TableCell align="center">18</TableCell>
            </TableRow>
            <TableRow className={classes.row} >
              <TableCell align="left">  {t('Months future price recommendations')}</TableCell>
              <TableCell align="center">1 x week</TableCell>
              <TableCell align="center">4 x week</TableCell>
              <TableCell align="center">12 x week</TableCell>
            </TableRow>
            <TableRow className={classes.row} >
              <TableCell align="left">  {t('Automated price upload to your PMS')}</TableCell>
              <TableCell align="center">Yes, limited</TableCell>
              <TableCell align="center"> <CheckIcon className={classes.checkIcon} /> </TableCell>
              <TableCell align="center"> <CheckIcon className={classes.checkIcon} /> </TableCell>
            </TableRow>
            <TableRow className={classes.row} >
              <TableCell align="left">  {t('Manual pricing upload')}</TableCell>
              <TableCell align="center"><CloseIcon className={classes.checkIconRed} /></TableCell>
              <TableCell align="center"><CheckIcon className={classes.checkIcon} /></TableCell>
              <TableCell align="center"><CheckIcon className={classes.checkIcon} /></TableCell>
            </TableRow>
            <TableRow className={classes.row} >
              <TableCell align="left">  {t('Daily pricing control')}</TableCell>
              <TableCell align="center"><CloseIcon className={classes.checkIconRed} /></TableCell>
              <TableCell align="center"><CheckIcon className={classes.checkIcon} /></TableCell>
              <TableCell align="center"><CheckIcon className={classes.checkIcon} /></TableCell>
            </TableRow>
            <TableRow className={classes.row} >
              <TableCell align="left">  {t('Daily pricing bulk edit')}</TableCell>
              <TableCell align="center"><CloseIcon className={classes.checkIconRed} /></TableCell>
              <TableCell align="center"><CheckIcon className={classes.checkIcon} /></TableCell>
              <TableCell align="center"><CheckIcon className={classes.checkIcon} /></TableCell>
            </TableRow>
            <TableRow className={classes.row} >
              <TableCell align="left">  {t('Algorithmic control & Strategy settings')}</TableCell>
              <TableCell align="center"><CloseIcon className={classes.checkIconRed} /></TableCell>
              <TableCell align="center"><CheckIcon className={classes.checkIcon} /></TableCell>
              <TableCell align="center"><CheckIcon className={classes.checkIcon} /></TableCell>
            </TableRow>
            <TableRow className={classes.row} >
              <TableCell align="left">  {t('Lead time settings')}</TableCell>
              <TableCell align="center"><CloseIcon className={classes.checkIconRed} /></TableCell>
              <TableCell align="center"><CheckIcon className={classes.checkIcon} /></TableCell>
              <TableCell align="center"><CheckIcon className={classes.checkIcon} /></TableCell>
            </TableRow>
            <TableRow className={classes.row} >
              <TableCell align="left">  {t('Comp-set adjustments')}</TableCell>
              <TableCell align="center"><CloseIcon className={classes.checkIconRed} /></TableCell>
              <TableCell align="center">{t('Yes, limited')}</TableCell>
              <TableCell align="center"><CheckIcon className={classes.checkIcon} /></TableCell>
            </TableRow>
            <TableRow className={classes.row} >
              <TableCell align="left">  {t('Revenue performance dashboard')}</TableCell>
              <TableCell align="center"><CloseIcon className={classes.checkIconRed} /></TableCell>
              <TableCell align="center">{t('Yes, limited')}</TableCell>
              <TableCell align="center"><CheckIcon className={classes.checkIcon} /></TableCell>
            </TableRow>
            <TableRow className={classes.row} >
              <TableCell align="left">  {t('Revenue analytics dashboard')}</TableCell>
              <TableCell align="center"><CloseIcon className={classes.checkIconRed} /></TableCell>
              <TableCell align="center"><CloseIcon className={classes.checkIconRed} /></TableCell>
              <TableCell align="center"><CheckIcon className={classes.checkIcon} /></TableCell>
            </TableRow>
            <TableRow className={classes.row} >
              <TableCell align="left">  {t('Scheduled reporting')}</TableCell>
              <TableCell align="center"><CloseIcon className={classes.checkIconRed} /></TableCell>
              <TableCell align="center"><CloseIcon className={classes.checkIconRed} /></TableCell>
              <TableCell align="center"><CheckIcon className={classes.checkIcon} /></TableCell>
            </TableRow>
            <TableRow className={classes.row} >
              <TableCell align="left">  {t('Monthly forecast')}</TableCell>
              <TableCell align="center"><CloseIcon className={classes.checkIconRed} /></TableCell>
              <TableCell align="center"><CloseIcon className={classes.checkIconRed} /></TableCell>
              <TableCell align="center"><CheckIcon className={classes.checkIcon} /></TableCell>
            </TableRow>
            <TableRow className={classes.row} >
              <TableCell align="left">  {t('Yield room types individually')}</TableCell>
              <TableCell align="center"><CloseIcon className={classes.checkIconRed} /></TableCell>
              <TableCell align="center"><CloseIcon className={classes.checkIconRed} /></TableCell>
              <TableCell align="center"><CheckIcon className={classes.checkIcon} /></TableCell>
            </TableRow>
            <TableRow className={classes.row} >
              <TableCell align="left">  {t('Segmentation')}</TableCell>
              <TableCell align="center"><CloseIcon className={classes.checkIconRed} /></TableCell>
              <TableCell align="center"><CloseIcon className={classes.checkIconRed} /></TableCell>
              <TableCell align="center"><CheckIcon className={classes.checkIcon} /></TableCell>
            </TableRow>
            <TableRow className={classes.row} >
              <TableCell align="left" style={{borderBottom:"3px solid rgba(224, 224, 224, 1)"}}>  {t('OTA Insight integration')}</TableCell>
              <TableCell align="center" style={{borderBottom:"3px solid rgba(224, 224, 224, 1)"}}><CloseIcon className={classes.checkIconRed} /></TableCell>
              <TableCell align="center" style={{borderBottom:"3px solid rgba(224, 224, 224, 1)"}}><CloseIcon className={classes.checkIconRed} /></TableCell>
              <TableCell align="center" style={{borderBottom:"3px solid rgba(224, 224, 224, 1)"}}><CheckIcon className={classes.checkIcon} /></TableCell>
            </TableRow>
            <TableRow className={classes.row} >
              <TableCell align="left"style={{borderBottom:"3px solid rgba(224, 224, 224, 1)"}}>  {t('Service inclusions')}</TableCell>
              <TableCell align="center"style={{borderBottom:"3px solid rgba(224, 224, 224, 1)"}}></TableCell>
              <TableCell align="center"style={{borderBottom:"3px solid rgba(224, 224, 224, 1)"}}></TableCell>
              <TableCell align="center"style={{borderBottom:"3px solid rgba(224, 224, 224, 1)"}}></TableCell>
            </TableRow>
            <TableRow className={classes.row} >
              <TableCell align="left">  {t('Personal Onboarding')}</TableCell>
              <TableCell align="center">{t('Standard')}</TableCell>
              <TableCell align="center">{t('Standard')}</TableCell>
              <TableCell align="center">{t('VIP')}</TableCell>
            </TableRow>
            <TableRow className={classes.row} >
              <TableCell align="left">  {t('Onboarding cost')}</TableCell>
              <TableCell align="center">{t('Free')}</TableCell>
              <TableCell align="center">{t('Free')}</TableCell>
              <TableCell align="center">{t('Free')}</TableCell>
            </TableRow>
            <TableRow className={classes.row} >
              <TableCell align="left">  {t('Free trial')}</TableCell>
              <TableCell align="center">{t('2 weeks')}</TableCell>
              <TableCell align="center">{t('2 weeks')}</TableCell>
              <TableCell align="center">{t('2 weeks')}</TableCell>
            </TableRow>
            <TableRow className={classes.row} >
              <TableCell align="left">  {t('Revenue strategy reviews')}</TableCell>
              <TableCell align="center">{t('1 x year')}</TableCell>
              <TableCell align="center">{t('1 x year')}</TableCell>
              <TableCell align="center">{t('12 x year')}</TableCell>
            </TableRow>
            <TableRow className={classes.row} >
              <TableCell align="left">  {t('RoomPriceGenie Revenue Academy with additional content for users')}            </TableCell>
              <TableCell align="center">{t('Free')}</TableCell>
              <TableCell align="center">{t('Free')}</TableCell>
              <TableCell align="center">{t('Free')}</TableCell>
            </TableRow>
            <TableRow className={classes.row} >
              <TableCell align="left">  {t('Support')}</TableCell>
              <TableCell align="center">{t('In-App Walkthroughs, Help Desk & Chat')}</TableCell>
              <TableCell align="center">{t('In-App Walkthroughs, Help Desk, Email & chat')}</TableCell>
              <TableCell align="center">{t('In-App Walkthroughs, Help Desk plus Priority Email & Chat')}</TableCell>
            </TableRow>

            </TableBody>
        </Table>
          </Grid>
          </Card> */}
      </>
    );
  }

  
  renderOldDesign() {
    const { classes } = this.props;
    const { t } = this.props;

    let width = window.screen.width;
    let is_admin_partners =
      this.props.userSession.user.is_staff ||
      this.props.userSession.user.sub_account_type
        ? true
        : false;
    let userState = this.props.hotelAuthReducer.hotelDetails.hotel
      ? this.props.hotelAuthReducer.hotelDetails.hotel.state
      : null;
    this.state.start_monday
      ? (enLocale.options.weekStartsOn = 1)
      : (enLocale.options.weekStartsOn = 0);

    return (
      <>
        <HeaderCard>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.divStyle}>
                <Typography className={classes.fontStyle} variant='h5'>
                  {t("Subscription")}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </HeaderCard>
        <Grid container direction={width < 800 ? "column" : ""}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className={classes.fullWidth}
          >
            <Card>
              <CardContent>
                <Typography className={classes.divTypography}>
                  {this.renderNote()}
                </Typography>
                {this.props.hotelDetailReducer.hoteDetail && (
                  <div>
                    {(this.props.userSession.user.is_staff ||
                      this.props.userSession.user.sub_account_type) && (
                      <FormControlLabel
                        className={classes.mailToggle}
                        control={
                          <Switch
                            color='primary'
                            checked={this.state.is_test}
                            onChange={() => this.makeTestHotel()}
                          />
                        }
                        label={t("Demo Hotel")}
                      />
                    )}
                    {this.state.featuresEnabled.includes(10) &&
                      (this.props.userSession.user.is_staff ||
                        this.props.userSession.user.sub_account_type) && (
                        <RadioGroup
                          aria-label='Language'
                          value={this.props.hotelDetailReducer.hoteDetail.state}
                          className={classes.radioPartner}
                        >
                          <FormControlLabel
                            onClick={this.changeState()}
                            value={6}
                            label='Subscribed to Partner'
                            control={<Radio color='primary' />}
                          />

                          <FormControlLabel
                            onClick={this.changeState()}
                            value={7}
                            label='Unsubscribed'
                            control={<Radio color='primary' />}
                          />

                          <FormControlLabel
                            onClick={this.changeState()}
                            value={0}
                            label='Free Trial'
                            control={<Radio color='primary' />}
                          />
                        </RadioGroup>
                      )}
                    {(userState == 0 || userState == 3) &&
                      is_admin_partners &&
                      this.props.hotelDetailReducer.hoteDetail &&
                      this.props.hotelDetailReducer.hoteDetail
                        .free_trial_end && (
                        <Grid container className={classes.datePickerDiv}>
                          <Grid item xs={12} md={4}>
                            <FormControl
                              margin='normal'
                              required
                              fullWidth
                              className={classes.formControl}
                            >
                              <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={enLocale}
                              >
                                <DatePicker
                                  label={t("End of Free Trial")}
                                  minDate={moment()}
                                  value={
                                    this.state.trialDate && this.state.trialDate
                                  }
                                  onChange={(event) => this.handleDate(event)}
                                  className={classes.datePicker}
                                  format={"dd/MM/yyyy"}
                                  InputLabelProps={{ shrink: true }}
                                  autoOk={true}
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            {(this.props.userSession.user.is_staff ||
                              this.props.userSession.user.sub_account_type) && (
                              <Button
                                className={classes.dateSaveBtn}
                                variant='contained'
                                color='primary'
                                onClick={() => this.updateFreeTrialonSave()}
                              >
                                {t("Save")}
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      )}
                  </div>
                )}
              </CardContent>
              {Modals.editClientRate(this)} {Modals.updateFreeTrial(this)}
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
  render() {
    return (
      <>
        {this.state.featuresEnabled.includes(10)
          ? this.renderOldDesign()
          : this.renderNewDesign()}
        {this.state.connectChargebeeModel &&
          Modals.connectChargebeeSubscription(this)}
      </>
    );
  }
}

PaymentSetting.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    userSession: state.sessionReducer,
    hotelAuthReducer: state.hotelAuthReducer,
    hotelDetailReducer: state.hotelSettingReducer,
    commonReducer: state.commonReducer,
    featuresReducer: state.featuresReducer,
    priceSettingReducer: state.priceSettingReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHotelDetail: (request) => {
      dispatch(actions.getHotelDetail(request));
    },
    updateRate: (request) => {
      dispatch(actions.updateRate(request));
    },
    updateAccountCall: (request) => {
      dispatch(actions.updateAccountCall(request));
    },
    updateDefaultRate: (request) => {
      dispatch(actions.updateDefaultRate(request));
    },
    updateFreeTrial: (request) => {
      dispatch(actions.updateFreeTrial(request));
    },
    getMembershipPricing: (request) => {
      dispatch(actions.getMembershipPricing(request));
    },
    updateHotel: (request) => {
      dispatch(actions.updateHotelSetting(request));
    },
    updateHotelState: (request) => {
      dispatch(actions.updateHotelState(request));
    },
    chargebeeSubscription: (request) => {
      dispatch(actions.chargebeeSubscription(request));
    },
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps)
)(withNamespaces("translations")(PaymentSetting));
