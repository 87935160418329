import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import HotelCss from './css/hotelSettingCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../../store/actions/index';
import GoogleMapReact from 'google-map-react';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
// import Select, { components } from 'react-select';
import Select  from '@material-ui/core/Select';
import { withNamespaces, Trans } from 'react-i18next';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import CustomTooltip from '../../Utils/toolTip';
import Divider from '@material-ui/core/Divider';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HeaderCard from '../HeaderCard';
import Typography from '@material-ui/core/Typography';


const styles = HotelCss;
const AnyReactComponent = ({ text }) => <div>{text}</div>;

let CountryList = [];
let RoomList = [];

class HotelSetting extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      timezone: "",
      hotelDetail: '',
      loadData: false,
      error: [],
      roomList: [],
      address: '',
      latitude: '',
      longitude: '',
      resellerList: [],
      salesList: [],
      memberList: [],
      allMemberList: [],
      responsible_account: '',
      reseller: '',
      is_send_email: false,
      starts_monday: false,
      booking_ref_url: "",
      event_types: null,
      is_ready:false,
    };
  }

  currencyChange = () => event => {
    this.props.currencyList
      .filter(List => List.id === event.value)
      .map((List) => {
        this.setState({
          hotelDetail: {
            ...this.state.hotelDetail,
            currency: List
          }
        })
        return ('');
      })

  };

  countryChange = () => event => {
    this.props.countryList
      .filter(List => List.id === event.target.value)
      .map((List) => {
        this.setState({
          hotelDetail: {
            ...this.state.hotelDetail,
            country: List
          }
        })
        return ('');
      })

  };

  refRoomChange = () => event => {
    this.props.roomList.roomList
      .filter(List => List.id === event.value)
      .map((List) => {
        this.setState({
          hotelDetail: {
            ...this.state.hotelDetail,
            reference_room: event.value
          },
          selectedRoom: event,
        })
        return ('');
      })

  };

  componentWillMount() {
    let requestParam = { token: this.props.hotelAuthReducer.hotelDetails.token }
    this.props.getHotelDetail(requestParam);
    if (this.props.userSession.user.is_staff) {
      this.props.getResellerAll(requestParam);
      this.props.getAdminSales(requestParam);
      this.props.getResellerMemberAll(requestParam)
    }
    if (this.props.userSession.user.sub_account_type) {
      this.props.getResellerMemberAll(requestParam)
    }
  }

  updateHotelSetting = () => {

    this.setState({
      loadData: true,
    })

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      reseller_id: this.state.reseller,
      responsible_person_id: this.state.responsible_account,
    }

    this.props.updateHotel(requestParam);


  }

  handleSettingChange = (name) => event => {
    this.setState({
      hotelDetail: {
        ...this.state.hotelDetail,
        [name]: event.target.value,
      }
    });
  }

  handleMemberSettings = (name) => event => {
    this.setState({
      responsible_account: null,
      reseller: event.target.value,
      memberList: this.state.allMemberList.filter(opt => opt.reseller.id == event.target.value)
    });
  }

  handleSettings = (name) => event => {

    if (event.target.name == "sales_team") {
      this.setState({
        reseller: '',
        memberList: [],
        [name]: event.target.value,
      })
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  }

  componentWillReceiveProps(props) {

    if (props.adminUserReducer.adminUserList) {
      this.setState({
        salesList: props.adminUserReducer.adminUserList
      })
    }

    if (props.resellerReducer.allResellerList) {
      this.setState({
        resellerList: props.resellerReducer.allResellerList
      })
    }

    if (props.resellerReducer.resellerAllMemberList) {
      this.setState({
        allMemberList: props.resellerReducer.resellerAllMemberList
      })
      if (this.state.reseller) {
        this.setState({
          memberList: props.resellerReducer.resellerAllMemberList.filter(opt => opt.reseller.id == this.state.reseller)
        })
      }
    }

    if (props.commonReducer.notify) {
      if (props.commonReducer.notify.message) {
        this.setState({
          loadData: false
        })
      }
    }

    if (props.hotelDetailReducer.hoteDetail) {
      this.setState({
        hotelDetail: props.hotelDetailReducer.hoteDetail,
        selectedCountry: { value: props.hotelDetailReducer.hoteDetail.country.id, label: props.hotelDetailReducer.hoteDetail.country.name + ' (' + props.hotelDetailReducer.hoteDetail.country.abbreviation + ')' },
        latitude: parseFloat(props.hotelDetailReducer.hoteDetail.lat).toFixed(2),
        longitude: parseFloat(props.hotelDetailReducer.hoteDetail.lng).toFixed(2),
        
        // reseller: props.hotelDetailReducer.hoteDetail.reseller ? props.hotelDetailReducer.hoteDetail.reseller.id : null,
        is_send_email: props.hotelDetailReducer.hoteDetail.is_send_email,
        starts_monday: props.hotelDetailReducer.hoteDetail.starts_monday,
        booking_ref_url: props.hotelDetailReducer.hoteDetail.booking_ref_url,
      })
      
      if(!this.state.is_ready){
        this.setState({
          reseller: props.hotelDetailReducer.hoteDetail.reseller ? props.hotelDetailReducer.hoteDetail.reseller.id : null,
          responsible_account: props.hotelDetailReducer.hoteDetail.responsible_person ? props.hotelDetailReducer.hoteDetail.responsible_person.id : null,
          is_ready: true
        })
      }
    }

    if (props.roomList.roomList) {
      RoomList = [];
      props.roomList.roomList.map(option => (
        RoomList.push({ value: option.id, label: option.name })
      ))

      this.setState({
        roomList: props.roomList.roomList,
      })

      props.roomList.roomList.map(room => {
        if (room.is_reference_room) {
          this.setState(prevState => ({
            hotelDetail: {
              ...prevState.hotelDetail,
              reference_room: room.id
            },
            selectedRoom: (!this.state.selectedRoom) ? { value: room.id, label: room.name } : this.state.selectedRoom
          }))

        }
        return null;
      })
    }

    if (this.props.countryList) {
      CountryList = [];
      this.props.countryList.map(option => (
        CountryList.push({ value: option.id, label: option.name + ' (' + option.abbreviation + ')' })
      ))
    }
  }

  handleChange = address => {
    this.setState(prevState => ({
      hotelDetail: {
        ...prevState.hotelDetail,
        address: address
      }
    }))
  };

  handleChangeCountry = () => data => {
    this.setState(prevState => ({
      selectedCountry: data,
    }))
  }

  renderCurrencyOption = (data) => {
    if (data) {
      return data.map(val => {
        return {
          value: val.id,
          label: val.name + "( " + val.symbol + " )"
        }
      })
    }
  }

  renderCurrencyValue = (data) => {
    return {
      value: data.id,
      label: data.name + "( " + data.symbol + " )"
    }
  }

  handleEmailSend = () => {
    this.setState({
      is_send_email: !this.state.is_send_email
    })
  }

  handleStartMonday = () => {
    this.setState({
      starts_monday: !this.state.starts_monday
    })
  }

  renderForm = () => {
    const { classes } = this.props;

    const { t } = this.props;

    return (
      <div>
        <CardContent className={classes.cardContent} >
          <div >
            <form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid xs={12} item className={classes.gridField}>
                  {(this.props.userSession.user.is_staff) &&
                    <FormControl required className={classes.formControl} >
                      <InputLabel shrink={true} htmlFor="name">{t('Sales Team')}</InputLabel>
                      <Select
                        InputLabelProps={{ shrink: true }}
                        id="standard-select-currency"                        
                        value={this.state.responsible_account}
                        onChange={this.handleSettings('responsible_account')}
                        name="sales_team"
                        className={classes.textFieldSelect}
                        margin="normal"
                        displayEmpty
                      >
                        <MenuItem disabled value={''}>{t('Select Sales Team')}</MenuItem>
                        {this.state.salesList.map(option => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.first_name} {option.last_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  }
                </Grid>

                <Grid xs={12} item className={classes.gridField}>
                  {(this.props.userSession.user.is_staff) &&
                    <FormControl required className={classes.formControl} >
                      <InputLabel shrink={true} htmlFor="name">{t('Partner')}</InputLabel>
                      <Select
                        InputLabelProps={{ shrink: true }}
                        id="standard-select-currency"                        
                        value={this.state.reseller}
                        onChange={this.handleMemberSettings('reseller')}
                        className={classes.textFieldSelect}
                        margin="normal"
                        displayEmpty              
                      >
                        <MenuItem value={''}>{t('Select Partner')}</MenuItem>
                        {this.state.resellerList.map(option => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  }
                </Grid>

                <Grid xs={12} item className={classes.gridField}>
                  {(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) &&
                    <>
                      <FormControl required className={classes.formControl} >
                        <InputLabel shrink={true} htmlFor="name">{t('Partner User')}</InputLabel>
                        <Select
                          InputLabelProps={{ shrink: true }}
                          id="standard-select-currency"                      
                          value={this.state.responsible_account}
                          onChange={this.handleSettings('responsible_account')}
                          className={classes.textFieldSelect}
                          margin="normal"
                          displayEmpty 
                        >
                          <MenuItem disabled value={''}>{t('Select Partner User')}</MenuItem>
                          {this.state.memberList.map(option => (
                            <MenuItem key={option.id} value={option.user.id}>
                              {option.user.first_name} {option.user.last_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl required className={classes.formControl} ></FormControl>
                    </>
                  }
                </Grid>
              </Grid>
            </form>
          </div>
        </CardContent>
      </div >
    )
  }

  render() {
    const { t } = this.props;
    const { classes } = this.props;
    return (
      <>
        <HeaderCard >
          <Grid container>
            <Grid item xs={12} >
              <div className={classes.DivStyle} >
                  <Typography variant="h5" className={classes.fontstyle} >
                    Deal
                  </Typography>
              </div>
            </Grid>
          </Grid>
        </HeaderCard>
      <Grid container spacing={3}>
        <Grid lg={6} sm={12} item className={classes.gridPadding}>
          <Card>
            {this.renderForm()}
            <Button className={classes.savBtn}  disabled={this.state.loadData} onClick={this.updateHotelSetting} variant="contained" color="primary" >
              {t('Save')} {this.state.loadData && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
            </Button>
          </Card >
        </Grid>
      </Grid>
      </>
    );
  }
}

HotelSetting.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
  return {
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    userSession: state.sessionReducer,
    hotelDetailReducer: state.hotelSettingReducer,
    currencyList: state.currencyReducer.currencyList,
    hotelAuthReducer: state.hotelAuthReducer,
    roomList: state.roomListReducer,
    countryList: state.countryReducer.countryList,
    commonReducer: state.commonReducer,
    resellerReducer: state.resellerReducer,
    adminUserReducer: state.adminUserReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getRoomList: (request) => { dispatch(actions.getRoomList(request)) },
    getHotelDetail: (request) => { dispatch(actions.getHotelDetail(request)) },
    getCurrency: (request) => { dispatch(actions.currency(request)) },
    updateHotel: (request) => { dispatch(actions.updateHotelSetting(request)) },
    AddRefRoom: (request) => { dispatch(actions.AddRefRoom(request)) },
    getCountry: (request) => { dispatch(actions.getCountry(request)) },
    getResellerAll: (request) => { dispatch(actions.getResellerAll(request)) },
    getAdminSales: (request) => { dispatch(actions.getAdminSales(request)) },
    getResellerMemberAll: (request) => { dispatch(actions.getResellerMemberAll(request)) },
  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(HotelSetting));