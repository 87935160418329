import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';
import Pusher from 'pusher-js';

const getPusher = {
    connectionPusher: new Pusher(process.env.REACT_APP_KEY, {
        cluster: process.env.REACT_APP_CLUSTER
    }),
};

const initialState = {
    notify: [],
    tempData: false,
    initPusher: null,
    pusher: getPusher
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PUT_NOTIFY:
            return updateObject(state, { notify: action.data })
        case actionTypes.SET_CALENDAR_ROOM:
            return updateObject(state, { calendarRoom: action.data })
        case actionTypes.LAST_PRICING_DATE:
            return updateObject(state, { lastPrice: action.data })
        case actionTypes.AVERAGE_PRICE_SUCCEED:
            return updateObject(state, { avg_graph: action.state })
        case actionTypes.AVERAGE_MONTH_WEEK:
            return updateObject(state, { avg_month_week: action.state })
        case actionTypes.UPDATE_LAST_PRICING_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.UPDATE_LAST_PRICING_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.UPDATE_LAST_PRICING_SUCCEED:
            return updateObject(state, { loading: false, error: null })
        case actionTypes.STOP_NAVIGATION:
            return updateObject(state, { loading: false, error: null, stopNavigation: action.state })
        case actionTypes.SHOW_NAVIGATION_POPUP:
            return updateObject(state, { loading: false, error: null, showNavidationPop: action.state })
        case actionTypes.MONTHLY_PRICE:
            return updateObject(state, { loading: false, error: null, priceAmount: action.state })
        case actionTypes.WEATHER_DETAILS:
            return updateObject(state, { loading: false, error: null, weatherList: action.state })
        case actionTypes.REFERRAL_DEAL_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.REFERRAL_DEAL_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.REFERRAL_DEAL_SUCCEED:
            return updateObject(state, { loading: false, error: null, referralSuccess: action.state })
        case actionTypes.HOTEL_REGISTRATION_START:
            return updateObject(state, { hotelRegLoading: true, error: null })
        case actionTypes.HOTEL_REGISTRATION_SUCCEED:
            return updateObject(state, { hotelRegLoading: false, error: null })
        case actionTypes.HOTEL_REGISTRATION_FAILED:
            return updateObject(state, { hotelRegLoading: false, error: null })
        case actionTypes.ON_ACADEMY_SIGNUP_START:
            return updateObject(state, { academyLoading: true, academyError: false })
        case actionTypes.ON_ACADEMY_SIGNUP_SUCCEED:
            return updateObject(state, { academyLoading: false, academySuccess: true })
        case actionTypes.ON_ACADEMY_SIGNUP_FAILED:
            return updateObject(state, { academyLoading: false, academyError: true })
        case actionTypes.REFERRAL_DEAL_PROGRAM_SUCCEED:
            return updateObject(state, { referralDealProgram: action.state, error: null, })
        case actionTypes.STORE_ISSUE_REPORT_START:
            return updateObject(state, { reportIssueLoading: true })
        case actionTypes.STORE_ISSUE_REPORT_SUCCEED:
            return updateObject(state, { reportIssueLoading: false, reportIssueSuccess: true })
        case actionTypes.STORE_ISSUE_REPORT_FAILED:
            return updateObject(state, { reportIssueLoading: false, reportIssueError: true })
        case actionTypes.UPDATE_TEMP_DATA:
            return updateObject(state, { tempData: !state.tempData, })
        case actionTypes.GET_DEFAULT_VALUE_AFTER_YEAR:
            return updateObject(state, { defaultYearlyData: action.state })
        case actionTypes.GET_PROFIT_REPORT_SUCCESS:
            return updateObject(state, { profitReportLoading: false, profitReport: action.state })
        case actionTypes.GET_PROFIT_REPORT_START:
            return updateObject(state, { profitReportLoading: true })
        case actionTypes.GET_PROFIT_REPORT_FAILED:
            return updateObject(state, { profitReportLoading: false })
        case actionTypes.PREPARE_PUSHER:
            let channel = {};
            let hotel = action.data;
            
            if(hotel && Number.isInteger(hotel)){
                hotel = hotel.toString();
            }
            
            try {                
                channel = state.pusher.connectionPusher.subscribe(hotel);
              }
              catch(err) {
                  window.location.reload();
              }
            
            return updateObject(state, { initPusher: channel })
        case actionTypes.CHARGEBEE_LINK_SUCCEED:
            return updateObject(state,{loading:false,chargebeeSubscriptionLink:action.state})
        default:
            return state;
    }
}

export default reducer