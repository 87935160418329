import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ScrapListCss from './css/scrapProgramCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../store/actions/index';
import MUIDataTable from "mui-datatables";
import Button from '@material-ui/core/Button';
import Modal from './modal/modal'
import {  MuiThemeProvider } from '@material-ui/core/styles';
import { scrapeProgramTheme } from '../muiTheme';
import { Icon } from '@material-ui/core';

const styles = ScrapListCss;


class RateSetting extends React.Component {

    state = {
        tempValue: "",
        scrapList: [],
        showDeleteModal: false,
        showEditModal: false,
        editModalData: {},
        showAddModal: false,
        addModalData: {
            name: "",
            hour: 0,
            minute: 1,
            days: [],
            limit: 50,
        },
        error: []
    };

    componentWillMount() {
        let requestParam = { token: this.props.userSession.token }
        this.props.getScrapProgram(requestParam);
    }

    componentWillReceiveProps(props) {
        if (props.scrapReducer.scrapList) {
            this.setState({
                scrapList: props.scrapReducer.scrapList,
            })
        }
    }

    renderScrapData = (data) => {
        let jsx = [];
        data.map((val) => {
            jsx.push([
                val.name,
                val.hour + ' / ' + val.minute,
                val.days,
                val
            ])
            return null;
        })
        return jsx;
    }

    options = () => {
        return {
            responsive: 'scroll',
            print: false,
            download: false,
            viewColumns: false,
            responsive: "scroll",
            selectableRows: false,
            serverSide: true,
            count: this.state.count,
            rowsPerPage: 20,
            filter: false,
            rowsPerPageOptions: false,
            search: false,
            textLabels: {
                body: {
                    noMatch: this.props.scrapReducer.loading ?
                        'Loading Records...' :
                        'Sorry, no matching records found.',
                },
            }
        }
    };

    toggleAddProgram = () => {
        this.setState({
            showAddModal: !this.state.showAddModal,
            addModalData: {
                name: "",
                hour: '',
                minute: '',
                days: [],
                limit: 50,
            },
            error: []
        })
    }

    validateForm = (data) => {
        let error = [];
        if (data) {

            if (!this.state.addModalData.name) {
                error.push('name')
            }
            if (!this.state.addModalData.hour && this.state.addModalData.hour !== 0) {
                error.push('hour')
            }
            if (!this.state.addModalData.minute && this.state.addModalData.minute !== 0) {
                error.push('minute')
            }
            if (this.state.addModalData.days.length === 0) {
                error.push('days')
            }


            if (error) {
                this.setState({
                    error: error
                })
                return error
            } else {
                return false
            }
        } else {
            return true;
        }
    }

    addNewProgram = () => {
        let validateError = this.validateForm(this.state.addModalData);
        if (validateError.length > 0) {
            return;
        }

        let data = this.state.addModalData;
        data.days = data.days.toString();
        let requestParam = {
            ...data,
            token: this.props.userSession.token
        }
        this.props.createScrapProgram(requestParam);
        this.toggleAddProgram();
    }

    closeAddProgram = () => {
        this.setState({
            showAddModal: !this.state.showAddModal,
            addModalData: []
        })
    }

    handleNewDays = (val) => {
        var chipsArray = val.filter(function (value) { return !isNaN(parseFloat(value)) });
        this.setState({
            addModalData: {
                ...this.state.addModalData,
                days: chipsArray
            }
        })
    }

    handleNewProgram = (name) => event => {
        this.setState({
            addModalData: {
                ...this.state.addModalData,
                [name]: event.target.value
            }
        })
    }

    toggleEditProgram = (value) => {
        if (!Array.isArray(value.days)) {
            value.daysArray = value.days.split(",")
        }

        this.setState({
            editModalData: value,
            showEditModal: !this.state.showEditModal
        })
    }
    closeEditProgram = () => {
        this.setState({
            editModalData: [],
            showEditModal: !this.state.showEditModal
        })
    }

    addNewdate = (event) => {

    }

    DateDayChange = (val) => {
        var chipsArray = val.filter(function (value) { return !isNaN(parseFloat(value)) });
        this.setState({
            editModalData: {
                ...this.state.editModalData,
                daysArray: chipsArray,
                days: chipsArray.join(',')
            }
        })
    }

    handleEditProgram = (name) => event => {
        this.setState({
            editModalData: {
                ...this.state.editModalData,
                [name]: event.target.value
            }
        })
    }

    validateEditForm = (data) => {
        let error = [];
        if (data) {

            if (!this.state.editModalData.name) {
                error.push('edit_name')
            }
            if (!this.state.editModalData.hour && this.state.editModalData.hour !== 0) {
                error.push('edit_hour')
            }
            if (!this.state.editModalData.minute && this.state.editModalData.minute !== 0) {
                error.push('edit_minute')
            }
            if (this.state.editModalData.daysArray.length === 0) {
                error.push('edit_days')
            }

            if (error) {
                this.setState({
                    error: error
                })
                return error
            } else {
                return false
            }
        } else {
            return true;
        }
    }


    EditProgram = () => {
        let validateError = this.validateEditForm(this.state.editModalData);
        if (validateError.length > 0) {
            return;
        }

        let data = this.state.editModalData;
        data.days = data.days.toString();
        let requestParam = {
            ...data,
            token: this.props.userSession.token
        }
        this.props.updateScrapProgram(requestParam);
        this.closeEditProgram();
    }

    toggleDeleteProgram = (value) => {
        this.setState({
            deleteId: value.id,
            showDeleteModal: !this.state.showDeleteModal
        })
    }

    closeDeleteProgram = () => {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal
        })
    }

    deleteProgram = () => {
        let requestParam = {
            id: this.state.deleteId,
            token: this.props.userSession.token
        }
        this.props.deleteScrapProgram(requestParam);
        this.closeDeleteProgram()
    }

    updateTempValue = (event) => {
        this.setState({
            tempValue: event.target.value
        })
    }

    addNewValue = () => {
        let days = this.state.addModalData.days;
        days.push(this.state.tempValue)
        this.setState({
            addModalData: {
                ...this.state.addModalData,
                days: days
            },
            tempValue: ""
        })
    }

    addNewValueEdit = () => {
        let days = this.state.editModalData.days;
        days.push(this.state.tempValue)
        this.setState({
            editModalData: {
                ...this.state.editModalData,
                days: days
            },
            tempValue: ""
        })
    }


    render() {
        const { classes } = this.props;

        const columns = [
            { name: "Name" },
            { name: "Hour/ minutes" },
            { name: "Days" },
            {
                name: "Actions",
                options: {
                    filter: false,
                    download: false,
                    setCellProps: () => {
                        return { style: { color: '#541388', cursor: 'pointer' } }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment><Button color="primary" className={classes.actionIcon} onClick={(data) => this.toggleEditProgram(value)}><Icon>edit</Icon></Button> <Button className={classes.actionIcon} color="secondary" onClick={(id) => this.toggleDeleteProgram(value)}><Icon>delete</Icon></Button> </React.Fragment>
                        );
                    }
                }
            }
        ];

        return (
            <Card>
                <Button onClick={this.toggleAddProgram} className={classes.programBtn} variant="contained" color="primary" >
                    Add New Program
                </Button>
                <CardHeader
                    className="headig-top"
                    title="Scrape Program">
                </CardHeader>
                <CardContent>
                    <MuiThemeProvider theme={scrapeProgramTheme}>
                        <MUIDataTable
                            title={''}
                            data={this.renderScrapData(this.state.scrapList)}
                            columns={columns}
                            options={this.options()}
                        />
                    </MuiThemeProvider>
                </CardContent>
                {Modal.updateProgramModal(this)}
                {Modal.deleteProgramModal(this)}
                {Modal.addProgramModal(this)}
            </Card >
        );
    }
}

RateSetting.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        userSession: state.sessionReducer,
        scrapReducer: state.scrapReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getScrapProgram: (request) => { dispatch(actions.getScrapProgram(request)) },
        createScrapProgram: (request) => { dispatch(actions.createScrapProgram(request)) },
        updateScrapProgram: (request) => { dispatch(actions.updateScrapProgram(request)) },
        deleteScrapProgram: (request) => { dispatch(actions.deleteScrapProgram(request)) },
    };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(RateSetting);