var style = {
  calenderStyle: {
    display: "inherit",
    height: 'auto !important',
    width: "100%",
    minWidth: "918px"
  },
  buttonGroupBottom: {
    display: "flex",
    flexDirection: "column",
    marginRight: "18px",
    "@media (max-width: 540px)": {
      // float: "left",
    },
    "@media (min-width: 550px)": {
      bottom: 0,
      position: "absolute",
      // float:'right',
      marginTop: "10px",
      marginRight: "105px",
      marginBottom: "10px",
    },
  },
  occCalenderStyle: {
    display: "inherit",
    height: 'auto !important',
    width: "100%",
    minWidth: "918px"
  },
  pricePmsBtn: {
    marginRight: '20px',
    marginTop: '17px',
    '@media screen and (max-width: 800px)': {
      marginTop: '10px',
    },
  },
  pricePmsBtnCheckBox: {
    float: 'right',
    margin: "11px 5px -6px 0",
    '@media screen and (max-width: 800px)': {
      marginTop: '10px',
    },
    ['@media (max-width: 414px) and (min-width: 360px)']: {
      margin: "13px ​0px 0px 0px",
      float: "right"
    },
    "@media (max-width: 540px)": {
      float: "left" 
    }
  },
  pricePmsBtnUpdate: {
    float: 'right',
    marginRight: '10px',
    background: "#fe5454",
    color: "#ffffff"
  },
  currentPriceInput: {
    minWidth: '160px',
    width: '23%'
  },
  currentPriceInputBtn: {
    '@media screen and (min-width: 800px)': {
      margin: '0px 0px 0px 4px',
    },
    // padding: '6px 25px 6px 25px'
  },
  runPriceBtn: {
    float: 'right',
  },
  tableCell: {
    // minWidth: '125px',
    padding: '10px',
    "@media (max-width: 540px)": {
      padding:"0px 0px 0px 0px  !important"
    }
  },
  cellDate: {
    float: 'left',
    paddingLeft: "10px",
    '& label': {
      ['@media (max-width: 540px) and (min-width: 400px)']: {
        marginRight: '14px'
      },
      ['@media (max-width: 360px) and (min-width: 321px)']: {
        marginRight: '14px'
      },
      ['@media (max-width: 320px)']: {
        marginRight: '14px'
      }
    },
  },
  cellDay: {
    float: 'left'
  },
  textField: {
    marginRight: '15px',
    minWidth: '120px',
  },
  table: {
    marginTop: '40px',
    '@media screen and (min-width: 800px)': {
      marginLeft: "161px",
    },
    width: "unset",
    ['@media (max-width: 414px) and (min-width: 321px)']: {
      width: "100%",
    }
  },
  tableOpt: {
    marginTop: '30px'
  },
  tableRow: {
    height: "20px !important",
    '& td:first-child': {
      '@media screen and (min-width: 800px)': {
        left: "285px",
        textAlign: "left",
        borderBottom: 'none',
        height: "32px"
      },
      position: "absolute",
      backgroundColor: '#fff',
      padding: "5px 0px 0px 5px !important",
      minWidth: '162px',
      zIndex: '9',
      ['@media (max-width: 540px) and (min-width: 320px)']: {
        minWidth: '120px !important',
        padding: "0px 5px 0px 0px !important",
        position: 'relative'
      }
    },
    '& td': {
      ['@media (max-width: 540px) and (min-width: 320px)']: {
        fontSize: '12px !important'
      }
    },
    
    // '&:nth-child(even)': {
    // }
  },
  tableRoomNameRow: {
    fontSize: '16px',
    fontWeight: "400",
    marginLeft: '-3px',
    position: 'absolute',
  },
  bultUpdateBtn: {
    marginLeft: "15px",
    float: "right"
  },
  editBtn: {
    fontSize: '10px'
  },
  prevBtn: {
    float: 'left',
  },
  nextBtn: {
    float: 'right'
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
  },
  userDiv: {
    display: 'inherit',
    float: 'right'
  },
  plusIcon: {
    cursor: "pointer"
  },
  socketLoader: {
    marginTop: '5px',
  },
  loaderDiv: {
    textAlign: 'left',
    fontSize: '13px',
    marginTop: '-6px',
    color: "#3772FF"
  },
  fileCsv: {
    marginTop: '20px',
  },
  colorPrimary: {
    backgroundColor: '#92cb3f57',
  },
  barColorPrimary: {
    backgroundColor: '#92CB3F',
  },
  pmsTableRow: {
    height: '39px',
    margin: "30px 0px 30px 0px",
    '@media screen and (max-width: 800px)': {
      paddingRight: '0px'
    }
  },
  pmsRow: {
    paddingLeft: '5px',
    '@media screen and (max-width: 800px)': {
      paddingRight: '0px',
      minWidth: '100px'
    }
  },
  pmsRowBlankBorder: {
    paddingRight: '10px !important',
    // paddingLeft: '10px !important',
    borderBottom: 'none',
    // paddingTop: '4px',
    '@media screen and (max-width: 800px)': {
      paddingRight: '0px',
      minWidth: '100px'
    }
  },
  pmsNormalRow: {
    paddingRight: '10px !important',
    // paddingLeft: '10px !important',
    borderBottom: 'none',
    // paddingTop: '0px',
    '@media screen and (max-width: 800px)': {
      paddingRight: '0px',
      minWidth: '100px'
    }
  },
  eventNameRow: {
    height: '20px'
  },
  eventList: {
    height: '20px',
  },
  eventReadMore: {
    cursor: "pointer",
    color: '#3772FF'
  },
  eventTitle: {
    paddingLeft: '0px !important',
    paddingBottom: '0px !important'
  },
  pmsTable: {
    marginTop: "12px",
    '@media screen and (min-width: 800px)': {
      maxWidth: '390px'
    }
  },
  pmsContent: {
    display: 'flex',
  },
  pmsContentDiv: {
    display: "flex",
    '@media screen and (max-width: 800px)': {
      marginTop: "20px",
    },
  },
  eventTable: {
    marginTop: '10px',
  },
  eventName: {
    textAlign: 'left',
    fontWeight: '600',
    border: '1px solid rgba(224, 224, 224, 1)',
    backgroundColor: 'rgba(224, 224, 224, 1)'
  },
  eventDates: {
    textAlign: 'left',
    padding: "10px 20px 12px 20px",
    border: '1px solid rgba(224, 224, 224, 1)'
  },
  reFontsize: {
    fontSize: "30px"
  },
  diagnosticCheck: {
    display: 'flow-root',
    width: "100%",
    ['@media (max-width: 540px) and (min-width: 400px)']: {
      display: 'flex',
      flexDirection: 'row-reverse'
    },
    ['@media (max-width: 399px) and (min-width: 361px)']: {
      marginLeft: '13px',
      display: 'flex',
      flexDirection: 'column-reverse'
    },
    ['@media (max-width: 360px) and (min-width: 321px)']: {
    },
    ['@media (max-width: 360px) and (min-width: 360px)']: {
      marginLeft: '13px',
      display: 'flex',
      flexDirection: 'column-reverse'
    },
    ['@media (max-width: 320px)']: {
    },
    '& div:first-child': {
      // ['@media (max-width: 399px) and (min-width: 361px)']: {
      //   alignSelf: 'end',
      //   marginTop: '-23px',
      //   marginRight: '30%'
      // },
      ['@media (max-width: 375px) and (min-width: 360px)']: {
        // position: 'absolute',
        // right: '25%'
      }
    },

  },
  checkbox: {
    padding: "0px"
  },
  viewBtn: {
    color: "#3772FF",
    cursor: "pointer",
    marginTop: '12px',
    '@media (min-width: 481px) and (max-width: 767px)': {
      marginTop: 5,
      textAlign: 'left'
    },
    '@media (min-width: 320px) and (max-width: 480px)': {
      marginTop: 5,
      textAlign: 'left'
    }
  },
  tabs: {
    marginTop: '10px',
    color: "#A0A2A6",
  },
  nothappyBtn: {
    color: '#3772FF',
    marginTop: "5px",
    cursor: "pointer",
    width: 'fit-content'
  },
  happyPriceFrame: {
    height: "400px",
    width: "100%",
    border: "none"
  },
  priceTooltip: {
    float: 'right',
    marginTop: '-25px',
    marginRight: '-23px',
    margin: '-24px -17px -5px 0px',
  },
  btnCard: {
    marginBottom: '14px',
    paddingBottom: '18px',
    paddingTop: "3px"
  },
  pageTitle: {
    '@media screen and (min-width: 800px)': {
      float: 'left',
    },
    display: 'inline-flex',
    '& h6': {
      fontSize: '1.10rem',
      fontWeight: '400'
    },
    padding: '8px 20px 8px 0px',
    minWidth: "210px",
    backgroundColor: '#fff',
    borderRadius: '15px',
    ['@media (max-width: 540px) and (min-width: 400px)']: {
      width: '100%',
    },
    ['@media (max-width: 399px) and (min-width: 361px)']: {
      width: '100%',
      marginTop: '10px'
    },
    ['@media (max-width: 360px) and (min-width: 321px)']: {
      width: '100%',
    },
    ['@media (max-width: 320px)']: {
      width: '100%',
    }
  },
  progressCircle: {
    marginTop: '-4px',
    width: 'fit-content',
    float: 'right',
    textAlign: '-webkit-center'
  },
  progressAlign: {
    fontSize: 14,
    position: 'absolute',
    margin: '10px 0px 0px 8px',
  },
  gridItems: {
    color: '#fff',
    fontSize: '14px',
  },
  gridTitle: {
    fontSize: '14px',
    textAlign: 'left',
    '@media (min-width: 481px) and (max-width: 767px)': {
      marginTop: 5,
    },
    '@media (min-width: 320px) and (max-width: 480px)': {
      marginTop: 5
    }
  },
  marginTop: {
    marginTop: '8px'
  },
  viewChangeBtn: {
    textTransform: 'inherit',
    marginTop: 5,
    float: 'right'
  },
  grapgDiv: {
    overflowX: "auto",
    marginTop:"10px",
    '@media (min-width: 320px) and (max-width: 800px)': {
      marginTop:"80px",
    }
  },
  calDiv:{
    overflowX: "auto",
    marginTop:"10px",
    '@media (min-width: 320px) and (max-width: 800px)': {
      marginTop:"10px",
    }
  },
  graphView: {
    minWidth: "800px",
    '@media (min-width: 320px) and (max-width: 800px)': {
      marginTop: "35px !important",
    },
    marginLeft: "-28px",
    marginRight: "12px"
  },
  predefined: {
    margin: '-12px 0px 0px 0px'
  },
  pricingUpdStatus: {
    marginLeft: 10,
    padding: '6px 10px',
    fontSize: '12px',
    color: '#fff',
    fontWeight: '500',
    borderRadius: 5,
    cursor: 'pointer'
  },
  eventDiv: {
    display: 'flex', flexDirection: 'column', height: "60px"
  },
  occupancyDiv: {
    fontSize: '12px', fontWeight: 700, marginTop: "2px"
  },
  eventTitleDiv: {
    fontSize: '27px',
  },
  eventCurrency: {
    position: 'absolute', fontSize: '13px', marginLeft: '-12px', fontWeight: 700, marginTop: '4px',
  },
  changeDiv: {
    display: 'block', fontSize: '12px', fontWeight: 700, marginTop: '-1px'
  },
  changeIcon: {
    fontSize: '12px', marginRight: '3px', paddingTop: '2px', fontWeight: '700'
  },
  occupanyLeft: {
    width: '300x',
  },
  chartDiv: {
    marginTop: "20px"
  },
  editSingleDate: {
    fontSize: '17px',
    marginBottom: '-3px',
    marginLeft: '7px',
    cursor: "pointer",
    color: '#4998f7',
  },
  editPmsTypeBtn: {
    float: 'right',
    color: "#545454"
  },
  updatePmsTypeDiv: {
    display: "flex"
  },
  skipWeekdiv: {
    paddingLeft: "90px"
  },
  skipValidation: {
    width: "200px"
  },
  calendatIcon: {
    margin:"17px 10px 0px 5px",
    float: 'right',
    // marginLeft: '-5px',
    // marginTop: '-3px',
    // marginBottom: "-5px",
    '&:hover': {
      color: '#b800d8',
      cursor: 'pointer'
    },
    ['@media (max-width: 540px) and (min-width: 400px)']: {
      marginTop: '18px',
    },
    ['@media (max-width: 399px) and (min-width: 361px)']: {
      marginTop: '18px'
    },
    ['@media (max-width: 360px) and (min-width: 321px)']: {
      marginTop: '18px'
    },
    ['@media (max-width: 320px)']: {
      marginTop: '18px',
      width:"100%"
    }
   
  },
  calIcon: {
    fontSize: '18px',
  },
  pullRight: {
    float: 'right'
  },
  circleIcon: {
    color: '#399E5A',
    display: 'block',
    marginLeft: '10px',
    fontSize: '28px',
    marginTop: '-5px'
  },
  circleIconRed: {
    color: '#FE5454',
    display: 'block',
    marginLeft: '10px',
    fontSize: '28px',
    marginTop: '-5px'
  },
  htmlTooltip: {
    backgroundColor: '#323338 !important',
    color: 'white',
    maxWidth: '350px',
    // border: '1px solid #1976d2',
    opacity: '1',
    filter: 'alpha(opacity=100)'
  },
  bookingTooltip: {
    backgroundColor: '#323338 !important',
    color: 'white',
    maxWidth: '450px',
    // border: '1px solid #1976d2',
    opacity: '1',
    filter: 'alpha(opacity=100)'
  },
  htmlPopper: {
    opacity: "1"
  },
  calendarNavigate: {
    display: "flex",
    margin: '0px 0px -36px 0px'
  },
  navigateBtn: {
    minWidth: "35px",
    padding: "0px",
    marginTop: "2px",
    color: "#000000bf",
    height: '30px',
    // '&:hover': {
    //   backgroundColor: '#fff',
    // },
    float: "left"
  },
  navigateBtnSave: {
    margin: "-2px 0px 5px 20px",
    float: "right",
    ['@media (max-width: 414px) and (min-width: 360px)']: {
      marginTop: "5px",
      width: "42%",
      float: 'left'
    }
  },
  settingIcon: {
    fontSize: "19px",
    '&:hover': {
      color: "grey",
      cursor: "pointer"
    }
  },
  customlegend: {
    '@media screen and (min-width: 800px)': {
      margin: "-33px 0",
    },
  },
  gridResposive: {
    display: 'flex',
    alignItems: 'baseline',
    '@media screen and (max-width: 800px)': {
      minWidth: "100%"
    },
  },
  dropdownBtn: {
    height: "32px",
    border: "1px solid #c5c5c5",
    marginRight: "5px",
    borderRadius: "5px",
    paddingLeft: '15px',
    minHeight: "auto",
    '&:hover': {
      backgroundColor: "#f0f4f9"
    },
    '&:focus': {
      backgroundColor: "#f0f4f9"
    },
  },
  typeDropdown: {
    '&:focus': {
      backgroundColor: "#00000000"
    },
    minHeight: "auto",
    fontSize: "15px",
  },
  radioGroup: {
    flexDirection: "row",
  },
  radioGroupDates: {
    flexDirection: "row",
  },
  iconColor: {
    color: 'black',
  },
  dateNavigationDiv: {
    margin:'0px 10px 20px 0px',
    float:"left",
    display: 'flex',
    alignItems: 'center',
    '@media screen and (max-width: 948px)': {
      width: "100%",
      textAlign: "-webkit-right",
      marginBottom:"0px", 
      marginTop:"0px", 
      whiteSpace:'nowrap'
    },
  },
  colorLegend: {
    flexDirection: "column",
    display: 'flex',
    alignItems: 'baseline',
    minHeight: '36px',
    width: "520px",
    '@media screen and (max-width: 1100px)': {
      width: "100%",
    },
    '@media screen and (max-width: 1000px)': {
      marginBottom: "15px",
    },
  },
  adjustmentDiv: {
    display: 'flex',
  },
  adjustmentDiv2: {
    width: '100%',
    marginBottom: "-25px"
  },
  adjustmentBtn: {
    boxShadow: "none",
    zIndex: "10",
    height: "30px",
    width: '40px',
    minWidth: '35px',
    padding: "2px",
    margin: "0px 0px -25px -75px",
    '@media screen and (max-width: 1000px)': {
      display: 'none'
    },
  },
  adjustmentBtnSpan: {
    marginTop: '-4px',
    fontSize: "14px"
  },
  allAdjustmentBtn: {
    boxShadow: "none",
    height: "25px",
    width: 'auto',
    minWidth: '35px',
    padding: "3px 0px",
    marginTop: '2px',
  },
  adornment: {
    color: 'rgb(118, 118, 118)',
    paddingRight: '5px'
  },
  calendarMenuMainDiv: {
    marginTop: "-6px",
    minHeight: "40px",
    '@media screen and (max-width: 1000px)': {
      flexDirection: 'column'
    },
  },
  calendarMenuMainDiv2: {
    minHeight: "50px",
    // margin: "2px 0px 12px 0px",
    '@media screen and (max-width: 1100px)': {
      flexDirection: 'column'
    },
    ['@media (max-width: 414px) and (min-width: 360px)']: {
      // margin: "20px 0px 0px 0px"
    }
  },
  calendarMenuMainDiv3: {
    minHeight: "45px",
    marginBottom: "-12px",
    marginTop:"-56px",    
    ['@media (max-width: 1550px)']: {
      marginTop:"0px",
    },
    ['@media (max-width: 600px)']: {
      marginTop:"-20px",
    },
    ['@media (max-width: 600px)']: {
      marginTop:"-20px",
    },
    ['@media (max-width: 414px) and (min-width: 360px)']: {
      marginTop:"-14px",
        },
    ['@media (max-width: 414px) and (min-width: 360px)']: {
      marginTop:"-14px",
    }
  },
  fullWidth: {
    width: "100%",
  },
  calendarRightMenu: {
    width: "100%",
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    '@media screen and (max-width: 720px)': {
      flexDirection: 'column-reverse'
    },
    ['@media (max-width: 414px) and (min-width: 360px)']: {
      margin: '15px 15px 15px 15px'
    }
  },
  divSection2: {

    display: "flex",
    width: "100%",
    alignItems: "center",
    '@media screen and (max-width: 720px)': {
      flexDirection: 'column'
    },
    alignSelf: 'flex-start'
  },
  PricePopupInput: {
    minWidth: '180px',
    width: '23%'
  },
  eventListDiv: {
    marginTop: "20px"
  },
  beta: {
    padding: '0px 5px',
    border: 'solid 1px',
    color: '#d515f9',
    marginLeft: '8px',
    borderRadius: '15px',
    fontSize: '13px',
  },
  saveUploadBtn: {
    marginTop: '10px',
    // float: "right",
    marginRight: "8px",
    ['@media (max-width: 600px)']: {
      // width: "100% !important",
      marginLeft: "0px"
    }
  },
  buttonProgress: {
    position: "absolute"
  },
  actionIcon: {
    margin: "-5px 0px -3px 3px",
    minWidth: '25px',
    padding: '0px'
  },
  adjDeleteBtn: {
    padding: "0px",
    minWidth: "51px",
    height: "20px",
    fontWeight: '400',
    fontSize: '13px',
    textAlign: "right",
    '& span:first-child': {
      '& span:first-child': {
        width: '100%',
      },
    },
    '&:hover': {
      backgroundColor: "#ff000014",
      '& span:first-child': {
        '& span:first-child': {
          display: "none",
          width: '100%',
        },
        '& span:nth-child(2)': {
          visibility: 'visible',
        }
      }
    }
  },
  adjDeleteBtnspan: {
    fontSize: "17px",
    color: "red",
    position: "absolute",
    visibility: 'hidden'
  },
  settingIcon: {
    padding: '5px'
  },
  dropdownIcon: {
    fontSize: 15,
    margin: '6px 15px 0px 0px'
  },
  dropdownText: {
    margin: '5px 0px 0px 0px'
  },
  menuItem: {
    '& div > div:nth-child(2)': {
      marginTop: '2px'
    }
  },
  navigatePopupBtn: {
    minWidth: "40px",
    color: "#000000bf",
    padding: '0px',
    height: '30px',
    '&:hover': {
      backgroundColor: '#fff',
    },
    float: "right"
  },
  roomNames: {
    marginRight: "10px",
  },
  turnAlgoBtn: {
    float: 'right',
    marginLeft: "2px",
    '@media screen and (max-width: 800px)': {
      marginTop: '10px',
    },
    backgroundColor: '#d32f2f',
    color: "#fff",
    '&:hover': {
      backgroundColor: '#d32f2f',
    },
    ['@media (max-width: 540px) and (min-width: 400px)']: {
      marginLeft: '30px',
    },
    ['@media (max-width: 399px) and (min-width: 361px)']: {
      marginLeft: '30px',
    },
    ['@media (max-width: 360px) and (min-width: 321px)']: {
      marginLeft: '30px',
    },
    ['@media (max-width: 320px)']: {
      marginLeft: '30px',
    }
  },
  tabs: {
    minWidth: '100px',
  },
  tabNavigation: {
    marginTop: '-10px',
    float: "right",
    ['@media (max-width: 540px) and (min-width: 400px)']: {
      marginTop: "0px",
      float: "unset"
    },
    ['@media (max-width: 399px) and (min-width: 361px)']: {
      marginTop: "0px",
      float: "unset"
    },
    ['@media (max-width: 360px) and (min-width: 321px)']: {
      marginTop: "0px",
      float: "unset"
    },
    ['@media (max-width: 320px)']: {
      marginTop: "0px",
      float: "unset"
    }
  },
  tooltipTableeCell: {
    paddingLeft: '5px',
    borderBottom: 'none !important',
    padding: '0px 10px 18px 0px !important',
  },
  tooltipTableeCell1: {
    paddingLeft: '5px',
    borderBottom: 'none !important',
    padding: '18px 10px 18px 0px !important',
  },
  newCardDiv: {
    flexDirection: 'row-reverse',
    display: 'flex',
    background: '#f0f4f9'
  },
  bookingMenu: {
    height: "32px !important",
    color: "#6E6F72 !important",
    padding: "0px !important",
  },
  chartBtn: {
    width: "80px",
    marginTop: "-4px",
    float: "right",
    '&:hover': {
      backgroundColor: '#f0f4f8 !important',
      cursor: 'pointer'
    },
  },
  calendarNaviBtn: {
    minWidth: "35px",
    padding: "0px",
    marginTop: "1px"
  },
  roomNameColumn: {
    position: 'absolute',
    fontSize: '16px',
    fontWeight: "500",
    marginTop: '-5px',
    display: "inline-block",
    width: "147px",
    whiteSpace: "nowrap",
    overflow: "hidden !important",
    textOverflow: "ellipsis",
  },
  labelColumn: {
    marginTop: "30px",
    marginBottom: "-30px",
    fontWeight: "400",
    ['@media (max-width: 540px) and (min-width: 320px)']: {
      marginTop: "10px",
      '& button:first-child': {
        width: '100%',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '5px',
        float: 'unset'
      },
      '& button:nth-child(2)': {
        width: '100%',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '5px',
        float: 'unset'
      },
      '& button:nth-child(3)': {
        width: '100%',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '5px',
        float: 'unset'
      }
    }

  },
  calendarLabel: {
    marginTop: '4px',
    fontWeight: "400",
    display: "inline-block",
    float: "left",
  },
  penIcon: {
    marginLeft: "5px",
    fontSize: '14px',
    color: '#3f72ff',
    cursor: 'pointer'
  },
  top_left_part: {
    marginRight: "5px",
    ['@media (max-width: 540px) and (min-width: 400px)']: {
      width: '50%',
    },
    ['@media (max-width: 399px) and (min-width: 361px)']: {
      width: '50%',
    },
    ['@media (max-width: 360px) and (min-width: 321px)']: {
      width: '50%',
    },
    ['@media (max-width: 320px)']: {
      width: '50%',
    }
  },
  top_right_part: {
    marginLeft: "5px",
    ['@media (max-width: 540px) and (min-width: 400px)']: {
      width: '50%',
    },
    ['@media (max-width: 399px) and (min-width: 361px)']: {
      width: '50%',
    },
    ['@media (max-width: 360px) and (min-width: 321px)']: {
      width: '50%',
    },
    ['@media (max-width: 320px)']: {
      width: '50%',
    }
  },
  pricingActions: {
    float: "right",
    padding: '15px',
    backgroundColor: '#fff',
    borderRadius: '15px',
    ['@media (max-width: 540px) and (min-width: 400px)']: {
      display: "inline-flex",
      flexWrap: "wrap-reverse",
      width: "100%"
    },
    ['@media (max-width: 399px) and (min-width: 361px)']: {
      display: "inline-flex",
      flexWrap: "wrap-reverse",
      width: "100%"
    },
    ['@media (max-width: 360px) and (min-width: 321px)']: {
      display: "inline-flex",
      flexWrap: "wrap-reverse",
      width: "100%"
    },
    ['@media (max-width: 320px)']: {
      display: "inline-flex",
      flexWrap: "wrap-reverse",
      width: "100%"
    }
  },
  uploadBtn: {
    // ['@media (max-width: 320px)']: {
    //   width: '100%',
    //   marginLeft: '0px',
    //   marginRight: '0px'
    // },
    ['@media (max-width: 540px)']: {
     float:"left",
     marginLeft: '0px !important',
     marginRight: '0px !important',
    },
  },
  diagnosticBtn: {
    ['@media (max-width: 540px)']: {
      width: '100%',
      marginTop: '10px',
      paddingTop: '0px'
    },
  },
  occupancyIndication: {
    minWidth: '350px',
    ['@media (max-width: 540px) and (min-width: 400px)']: {
      margin: '34px 0px'
    },
    ['@media (max-width: 399px) and (min-width: 361px)']: {
      margin: '34px 0px'
    },
    ['@media (max-width: 360px) and (min-width: 321px)']: {
      margin: '34px 0px'
    },
    ['@media (max-width: 320px)']: {
      margin: '34px 0px'
    },
    
  },
  calendarIndication: {
    minWidth: '160px',
    ['@media (max-width: 600px)']: {
    },
    ['@media (max-width: 540px) and (min-width: 400px)']: {
      marginBottom: '20px'
    },
    ['@media (max-width: 399px) and (min-width: 361px)']: {
      marginBottom: '10px'
    },
    ['@media (max-width: 360px) and (min-width: 321px)']: {
      marginBottom: '10px'
    },
    ['@media (max-width: 320px)']: {
      marginBottom: '10px'
    },
  },
  occupancyIndicationIcon: {
    fontSize: "15px",
    height: "15px",
    margin: "-3px 0px 0px 5px",
    color: "#A0A2A6",
    ['@media (max-width:768px) and (min-width: 400px)']: {
      marginTop: '2px',
      fontSize: '14px'
    },
    ['@media (max-width: 399px) and (min-width: 361px)']: {
      marginTop: '2px',
      fontSize: '14px'
    },
    ['@media (max-width: 360px) and (min-width: 321px)']: {
      marginTop: '2px',
      fontSize: '14px'
    },
    ['@media (max-width: 320px)']: {
      marginTop: '2px',
      fontSize: '14px'
    }
  },
  textFieldResponsive: {
    ['@media (max-width: 540px) and (min-width: 320px)']: {
      marginBottom: '15px',
    }
  },
  marginResponsive: {
    ['@media (max-width: 540px) and (min-width: 320px)']: {
      marginRight: '15px',
    }
  },
  blukSettingTopDiv: {
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse",
    '& button': {
      ['@media (max-width: 540px) and (min-width: 400px)']: {
        width: '50%',
        marginTop: '10px',
        marginRight: '10px !important',
        marginLeft: '0px'
      },
      ['@media (max-width: 399px) and (min-width: 361px)']: {
        width: '50%',
        marginTop: '10px',
        marginRight: '10px !important',
        marginLeft: '0px'
      },
      ['@media (max-width: 360px) and (min-width: 360px)']: {
        width: '50%',
        marginTop: '10px',
        marginRight: '10px !important',
        marginLeft: '0px'
      },
      ['@media (max-width: 360px) and (min-width: 321px)']: {
        width: '50%'
      },
      ['@media (max-width: 320px)']: {
        width: '50%'
      }
    }
  },
  alignRight: {
    textAlign: "right"
  },
  updateNotice: {
    color: '#9a9a9a',
    marginTop: '20px',
  },
  adjustmentTextfield: {
    minWidth: "230px",
    width: "230px",
    ['@media (max-width: 540px)']: {
      minWidth:"unset !important",
      width: "unset !important",
    }

  },
  adjustmentBtnTop: {
    fontSize: "22px",
    marginTop: "-10px"
  },
  priceBtnLabel: {
    float: "right",
    margin: "9px -10px 0px 18px"
  },
  formControlVs: {
    minWidth: "130px",
    float: "right",
    borderRadius: "10px",
    '&:hover': {
      backgroundColor: "#f2f2f2",
      cursor: 'pointer',
    },
  },
  adjType: {
    borderRadius: "10px",
    '&:hover': {
      backgroundColor: '#f2f2f2',
      cursor: 'pointer'
    },
  },
  defaultRate: {
    whiteSpace: "nowrap",
    color: "grey",
    display: "flex",
    flexDirection: "column",
    fontSize: "12px"
  },
  healthScoreTip: {
    fontSize: "14px",
    color: "#fff",
    borderRadius: '10px',
    padding: "4px 9px 2px 8px",
    height: "24px",
    marginLeft: "5px",
    marginTop: "4px",
  },
  runPriceLabel: {
    color: "grey",
    fontSize: "12px",
    textAlign: "right",
    padding: "4px 2px"
  },
  graphTextField: {
    width: "170px",
    margin: "-4px 20px 0px 0px",
  },
  graphTextBtn: {
    backgroundColor: "#3772ff",
    fontWeight: "500"
  },
  graphtextDiv: {
    display: "flex",
    flexDirection: "column",
    ['@media (max-width: 540px)']: {
      float: "left !important",
      // width: '100% !important',
      marginTop: '0px !important',
      paddingTop: '0px !important'
    },
    ['@media (max-width: 949px)']: {   
      marginTop: '-10px !important',
      marginBottom: '10px !important',
    },
  },
  boldFont: {
    fontWeight: "500",
    fontSize: "18px",
    color: "#707070"
  },
  newTabBtnFirst: {
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    padding: "5px 10px"
  },
  newTabBtn: {
    marginLeft: "0px",
    borderRadius: "0px",
  },
  newTabBtnLast: {
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
  },
  calendartogglediv: {
    float: "left",
    maxWidth: "min-content",
    marginRight: "10px",
    ['@media (max-width: 414px) and (min-width: 360px)']: {
    }
  },
  roomSelect: {
    '&:hover': {
      borderRadius: "10px",
      backgroundColor: "#f2f2f2",
      cursor: 'pointer'
    },
    ['@media (max-width: 540px)']: {
      width: '100%',
      marginTop: '0px',
      paddingTop: '0px',
      marginBottom:"10px"
    },
  },
  compareDiv: {
    display: "flex",
    float: "right",  
    marginBottom: "10px",
    flexDirection: "column-reverse",
    alignItems: "baseline",
    ['@media (max-width: 900px)']: {
      display: 'none',
      width: "wrap",
      width: "100%",
      marginTop: "10px",
      marginBottom: "10px"
    }
  },
  marginBottom20: {
    marginBottom: "20px"
  },
  dateNavigationDivResponsive: {
    // ['@media (max-width: 600px)']: {
    //   flexWrap: "wrap"
    // }
  },
  fullWidthResp: {
    ['@media (max-width: 600px)']: {
      // width: "100%",
      maxWidth: "100%",
      margin: "10px 0px !important",
    }
  },
  roomTypeLabel: {
    float: "left",
    margin: "-25px 0px 0px 2px",
    ['@media (max-width: 600px)']: {
      margin: "5px 25px 0px 2px",
    }
  },
  adjustTypeLabel: {
    float: "left",
    margin: "-25px 0px 4px 2px",
    ['@media (max-width: 600px)']: {
      margin: "8px 8px 4px 2px",
    }
  },
  formControlFeedback: {
    position: "absolute",
    top: ".5rem",
    right: "1.5rem",
    zIndex: "2",
    display: "block",
    textAlign: "center",
    pointerEvents: "none",
  },
  respFullwidth: {
    ['@media (max-width: 540px)']: {
      width: '100%',
      marginTop: '0px',
      paddingTop: '0px'
    },
  },
  compareLabel: {
    float: "left",
    margin: "-25px 0px 4px 2px",
    ['@media (max-width: 600px)']: {
      margin: "0px 25px 0px 2px",
    }
  },
  uploadMsg: {
    float: "right",
    marginTop: "22px",
    marginRight: "20px",
    ['@media (max-width: 540px)']: {
      float: "left !important",
      width: '100% !important',
      marginTop: '0px !important',
      paddingTop: '0px !important'
    },
  },
  hideOnMobile: {
    ['@media (max-width: 540px)']: {
      display: "none !important"
    },
  },  
  hideOnMobileIcon: {
    ['@media (max-width: 1350px)']: {
      display: "none !important"
    },
  },
  
  showOnMobile: {
    ['@media (min-width: 540px)']: {
      display: "none !important"
    },
  },
  viewButtons: {
    float: "left",
    margin: "-25px 0px 4px 2px",
    ['@media (max-width: 600px)']: {
      margin: "10px 116px 0px 2px",
    }
  },
  closeButtonPrice: {
    position:'absolute',
    right:'0',
    ['@media (max-width: 540px)']: {
      marginTop: "-10px",
    }
  },
  autorunProgress: {
    minWidth: "330px",
    ['@media (max-width: 540px)']: {
      minWidth: "250px",
    }
  },
  tagBox:{
    overflow:'hidden',
    whiteSpace:'nowrap',
    maxWidth:'95px',
    textOverflow:'ellipsis',
    fontWeight: "400", 
    margin: "0 0 0 -22px",
    padding: "7px 14px 7px 14px", 
    color: "#fff", 
    display: "inline-block", 
    borderRadius: "25px",
    ['@media (max-width: 840px)']: {
     margin:'10px !important',
     padding: "7px !important",   
    }
  },
  textFieldAdj:{
    width:"180px",
    ['@media (max-width: 540px)']: {
      width:"80px"
    }
  },
  rowAdj: {
    paddingRight: '10px !important',
    paddingLeft: '10px !important',
    paddingTop: '0px',
    '@media screen and (max-width: 800px)': {
      paddingRight: '0px',
      minWidth: '100px'
    }
  },
  MuiTableCell: {
    root: {
      border: "2px solid white",//test-table
      borderBottom: "unset",//test-table
      padding: "4px 10px",//test-table,
        textAlign: 'left',
        '&:nth-child(10)': {
            minWidth: '120px',
        },
    },
  },
  MuiTableRow: {
    root: { 
      background:"#F5F5F5",//test-table
      '&:hover': {
        background:"#f2f2f2 !important"  
      }
    },
    footer:{
      background:"#FFFF",//test-table 
      '&:hover': {
         background:"#FFFF !important" ,//test-table 
       }
    }
  },
  drawerPaper: {
    "@media (max-width: 540px)": {
      width: "100% !important",
      padding: '0px !important'
    },
    "@media (min-width: 540px)": {
      width: "100% !important",
      padding: '0px !important'
    },
    '&:hover':{
      // overflowY:'auto !important'
    },
    padding: "10px 25px !important",
  },
  adjustmentIconHover:{
    '&:hover': {
      background:'#f2f2f2',
      borderRadius:'10%'
    }
  },
  eventListContent:{
    border: "1px solid #ccc",
    marginBottom:"12px",
    padding:"12px 16px"
  },
  notesBorder:{
    border: "1px solid #ccc",
    borderRadius:'15px',
    padding:'0px 10px 12px 10px',
  },
  noteListBorder:{
    border: "1px solid #ccc",
    borderRadius:'15px',
    padding:'12px 12px ',
    cursor:'pointer',
    '&:hover':{
      background:'#f2f2f2'
    }
  },
  noteListBorderEdit:{
    border: "1px solid #ccc",
    borderRadius:'15px',
    padding:'12px 12px ',
    cursor:'pointer'
  },
  noteListTitle:{textAlign:'center',maxWidth:'100px'},
  noteListdate:{float:'right',color:'#000000de',fontSize:'12px',fontWeight: '500',paddingTop:'3px'},
  notesBtn:{
    marginTop:'5px'
  },
  cancelBtn:{
    border:'1px solid lightgray !important',
    color:'#969696 !important',
    // padding: '6px 16px !important',
    '&:hover':{
      background:'lightgray !important',
      border:'1px solid lightgray !important',
      color:'#969696 !important',
    }
  },
  
  chipContent:{
    marginBottom:8,
    fontSize:"0.90rem",
    background:"#ccc",
    borderRadius:"20px",
    padding: "2px 10px",
    fontWeight:"400",
    color:"#fff",
    display:"inline-block",
    lineHeight: "21px",
    textTransform:"capitalize"
  },
  chipContentIcon:{
    width:"25px", 
    height:"25px", 
    borderRadius:"50%",
    display:"flex", 
    alignItems:"center",
    justifyContent:"center",
    marginLeft:"4px"
  },
  dangerBtnHvr:{
    background:'#d32f2f',
    color:'white',
    // padding:'6px 16px',
    '&:hover': {
      background:'#a93939'
    }
  },
  sidePopupFooter:{
    background: "#f1f1f1",
    width: "100%",
    border: "#d6d6d6 1px solid",
    margin: "0px -25px !important",
    paddingLeft: "24px",    
    paddingBottom: "10px",
    "@media (max-width: 540px)": {
      width: "100% !important",
      // padding: '10px 25px !important',
      margin: "0px !important",
    },
    "@media (min-width: 540px)": {
      width: "100% !important",
      // padding: '10px 25px !important',
      margin: "0px !important",
    },
  },
  flexResponsive:{
    display:"flex",
    "@media (max-width: 600px)": {
      display:"grid"
  }
  },
  textSecondary:{
    color:"#707070 !important"
  },
  dangerBtnHover : {
    '&:hover': {
      background:'#a93939 !important',
      color:'white'
    }
  },
  TabsView:{
    // float:"left"
    // marginTop: "34px",
    borderBottom : "1px solid #f0f0f0",
    
  },
  lastUpdateBtn:{
    display: 'flex', 
    margin: "4px 0px",
    "@media (max-width: 540px)": {
      marginTop:'20px'
    }
  },
  textAlignLeft:{
    textAlign:'right',
    "@media (max-width: 540px)": {
      textAlign:'left'
    }
  },
  headerMarginOnMobile:{
    "@media (max-width: 840px)": {
      marginTop:'10px'
    }
  },
  adminViewText:{
    background: "#eda942",
    color: "#fff",
    padding: "5px 10px",
    fontSize: "12px",
    marginLeft: "18px",
    borderRadius:"4px",
    marginTop: "2px",
    position:"absolute",
  },
  toggleGrid: {
    float: "left", 
    marginLeft:'10px',
    marginRight:'9px'
  },
  togglesubBtn:{
    margin: '-24px 15px 0px 0px',
    float:'left',
  },
  newTabBtnFirstGraph: {
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    padding: "5px 10px",
    float:'left',
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.875rem",
    fontWeight: 400,
  },
  newTabBtnLastGraph: {
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    float:'left',
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.875rem",
    fontWeight: 400,
    padding: "5px 10px",
  },
  graphView: {
    minWidth: "800px",
    // marginLeft:'-6px',
    marginTop: "10px",
    paddingLeft:'8px'
  },
  hidediv:{
    display:'none'
  },
  dateNavigationDiv2: {
    margin:'15px 10px 0px 0px',
    float:"left",
    display: 'flex',
    alignItems: 'center',
    '@media screen and (max-width: 948px)': {
      width: "100%",
      textAlign: "-webkit-right",
      marginBottom:"0px",
      marginTop:"0px",
      whiteSpace:'nowrap'
    },
  },
  priceCalModal:{
    height:'252px',margin:'-15px 0px -15px 0px'
  },
  sccessBar: {
    padding: "0px 20px 0px 20px",
    backgroundColor: '#ed6c02',
    // minWidth:'60%',
    // whiteSpace:'nowrap'
  },
  warningBar: {
  // minWidth:'64%',
  padding: "0px 20px 0px 20px",
  backgroundColor: '#d32f2f',
  '@media screen and (min-width: 948px)': {
    marginLeft:'239px',
    }
  // whiteSpace:'nowrap'
  },
  warningIcon: {
    margin: "0px 19px -7px -5px",
  },
  icon: {
    fontSize: 20,
  },
}
export default style;