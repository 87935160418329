import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import runAlgoCss from './css/runAlgoCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../store/actions/index';
import Button from '@material-ui/core/Button';
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { runAlgoTheme } from '../muiTheme';
import Icon from '@material-ui/core/Icon';
import modal from './modal/modal';
import Switch from '@material-ui/core/Switch';
import { withNamespaces, Trans } from 'react-i18next';
import Link from '@material-ui/core/Link';
import moment from 'moment';
import FilterListIcon from '@material-ui/icons/FilterList';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import HeaderCard from './HeaderCard';
import Typography from '@material-ui/core/Typography';

const styles = runAlgoCss;
let StatusList = { 1: { label: 'Scheduled', color: '#2FA4E7' }, 2: { label: 'In progress', color: '#DD5600' }, 3: { label: 'Success', color: '#73A839' }, 4: { label: 'Error', color: '#C71C22' }, 5: { label: 'Revoked', color: '#a2b0b9' } };

class runAlgo extends React.Component {
    state = {
        types: [
            // { key: 1, value: '1 Week' },
            { key: 2, value: '2 Week' },
            // { key: 3, value: '1 Month' },
            // { key: 4, value: '3 Month' },
            // { key: 5, value: '6 Month' },
            // { key: 6, value: '9 Month' },
            { key: 7, value: 'Year' },
            { key: 8, value: '18 Month' },
        ],
        hotelList: [],
        programList: [],
        editModal: {},
        createModal: {},
        showEdit: false,
        showDelete: false,
        showCreate: false,
        deleteId: null,
        error: [],
        is_update_to_pms: 0,
        openDateFilter: false,
        start_date: moment(),
        end_date: moment(),
        filtered: false,
        pms_provider: null,        
        featuresEnabled: [],
    };

    componentDidMount() {
        let requestData = {
            token: this.props.hotelAuthReducer.hotelDetails.token,
            timezone: this.props.hotelAuthReducer.hotelDetails.hotel.timezone,
        };
        this.props.getAlgoProgram(requestData);
        this.props.getAlgoProgramLog(requestData);

        this.setState({
            is_update_to_pms: this.props.hotelAuthReducer.hotelDetails.hotel.is_update_to_pms
        })

    }

    componentWillReceiveProps(props) {

        if (props.priceSettingReducer && props.priceSettingReducer.priceSetting) {
            this.setState({
              featuresEnabled: props.priceSettingReducer.priceSetting.features
            })
          }

        if (props.algoReducer && props.algoReducer.runAlgo) {
            this.setState({
                programList: props.algoReducer.runAlgo
            })
        }
        if (props.algoReducer && props.algoReducer.runAlgoLog) {
            this.setState({
                programLogList: props.algoReducer.runAlgoLog.results
            })
        }
        if (props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) {
            if (props.hotelAuthReducer.hotelDetails.hotel_list) {
                this.setState({
                    hotelList: props.hotelAuthReducer.hotelDetails.hotel_list,
                    pms_provider: props.hotelAuthReducer.hotelDetails.hotel.pms_provider
                })
            }
        } else {
            if (props.userSession.hotel_list) {
                this.setState({
                    hotelList: props.userSession.hotel_list
                })
            }
        }

    }

    handleUpdate = (name) => event => {
        this.setState({
            [name]: event.target.value,
        })
    }

    handleCreate = (name) => event => {
        this.setState({
            createModal: {
                ...this.state.createModal,
                [name]: (name === 'is_update_to_pms') ? !((event.target.value === 'false') ? false : true) : event.target.value,
            },
        })
    }

    openEditModal = (value) => {
        value.timezone = this.props.hotelAuthReducer.hotelDetails.hotel.timezone;
        this.setState({
            editModal: value,
            showEdit: true,
            error: []
        })
    }

    closeEditModal = () => {
        this.setState({
            showEdit: false,
        })
    }

    updateHandle = (name) => event => {
        this.setState({
            editModal: {
                ...this.state.editModal,
                [name]: (name === 'is_update_to_pms') ? !((event.target.value === 'false') ? false : true) : event.target.value,
            },
        })
    }

    validateEditForm = (data) => {
        let error = [];
        if (data) {

            if (!this.state.editModal.name) {
                error.push('edit_name')
            }
            if (!this.state.editModal.hour) {
                error.push('edit_hour')
            }
            // if (!this.state.editModal.minute) {
            //     error.push('edit_minute')
            // }
            if (!this.state.editModal.type) {
                error.push('edit_type')
            }


            if (error) {
                this.setState({
                    error: error
                })
                return error
            } else {
                return false
            }
        } else {
            return true;
        }
    }

    updateAlgo = () => {

        let validateError = this.validateEditForm(this.state.editModal);
        if (validateError.length > 0) {
            return;
        }

        let requestParams = {
            ...this.state.editModal,
            token: this.props.hotelAuthReducer.hotelDetails.token,            
            timezone: this.props.hotelAuthReducer.hotelDetails.hotel.timezone,
            hotel: this.props.hotelAuthReducer.hotelDetails.hotel.id,
        }
        this.props.updateAlgoProgram(requestParams)
        this.closeEditModal();
    }

    openDeleteModal = (val) => {
        this.setState({
            deleteId: val.id,
            showDelete: true,
        }, () => {
            this.setState({
                showEdit: false
            })
        })
    }

    closeDeleteModal = () => {
        this.setState({
            showDelete: false,
        })
    }

    deleteAlgo = () => {
        let requestParams = {
            id: this.state.deleteId,
            token: this.props.hotelAuthReducer.hotelDetails.token,
            timezone: this.props.hotelAuthReducer.hotelDetails.hotel.timezone,
        }
        this.props.deleteAlgoProgram(requestParams);
        this.closeDeleteModal();
    }

    openCreateModal = () => {
        this.setState({
            createModal: {
                hotel: "",
                hour: "",
                minute: "",
                name: "",
                type: "",
                timezone: this.props.hotelAuthReducer.hotelDetails.hotel.timezone
            },
            showCreate: true,
            error: []
        })
    }

    closeCreateModal = () => {
        this.setState({
            showCreate: false,
        })
    }

    validateForm = (data) => {
        let error = [];
        if (data) {

            if (!this.state.createModal.name) {
                error.push('name')
            }
            if (!this.state.createModal.hour && this.state.createModal.hour !== 0) {
                error.push('hour')
            }
            // if (!this.state.createModal.minute && this.state.createModal.minute !== 0) {
            //     error.push('minute')
            // }
            if (!this.state.createModal.type) {
                error.push('type')
            }


            if (error) {
                this.setState({
                    error: error
                })
                return error
            } else {
                return false
            }
        } else {
            return true;
        }
    }

    createAlgo = () => {

        let validateError = this.validateForm(this.state.createModal);
        if (validateError.length > 0) {
            return;
        }

        let requestParams = {
            ...this.state.createModal,
            token: this.props.hotelAuthReducer.hotelDetails.token,
            timezone: this.props.hotelAuthReducer.hotelDetails.hotel.timezone,
            hotel: this.props.hotelAuthReducer.hotelDetails.hotel.id,
        }
        this.props.createAlgoProgram(requestParams)
        this.closeCreateModal();
    }

    generateTable = (data) => {
        if (data) {
            let jsx = []
            data.map((val) => {
                jsx.push([
                    val.id,
                    val.name,
                    val.hour,
                    // val.minute,
                    val.type,
                    val
                ]);
            })
            return jsx;
        } else {
            return [];
        }
    }
    generateAlgoLogTable = (data) => {
        const { t } = this.props;
        if (data) {
            let jsx = []
            data.map((val) => {
                jsx.push([
                    val.program_name,
                    val,
                    // val.created,
                    val.date,
                    (val.is_update_to_pms ? t('ON') : t('OFF')),
                    val,
                    val,
                    val.dates_count,
                    val.requests_count,
                    val.upload_price_requests_count,
                    val.total_price_count,
                    val.above_threshold_dates_count,
                    val.total_above_threshold_price_count,
                    val.status,
                    val
                ]);
            })
            return jsx;
        } else {
            return [];
        }
    }




    handleUpdatePMS(event) {
        let value = !(event.target.value === 'false' ? false : true)

        this.setState({ is_update_to_pms: value })

        let requestParam = {
            token: this.props.hotelAuthReducer.hotelDetails.token,
            is_update_to_pms: value,
            lat: this.props.hotelAuthReducer.hotelDetails.hotel.lat,
            lng: this.props.hotelAuthReducer.hotelDetails.hotel.lng,
        }

        this.props.updateHotel(requestParam);
    }

    handlefilterOpen = () => {
        this.setState({ openDateFilter: true });
    };
    handlefilterClose = () => {
        this.setState({ openDateFilter: false });
    };

    changeDate = (name) => dateValue => {
        this.setState({
            [name]: dateValue
        })
    }

    handlefilterAlgoLog = () => {
        let requestData = {
            token: this.props.hotelAuthReducer.hotelDetails.token,
            start_date: moment(this.state.start_date).format('YYYY-MM-DD'),
            end_date: moment(this.state.end_date).format('YYYY-MM-DD')
        };

        this.props.getAlgoProgramLog(requestData);
        this.handlefilterClose();
        this.setState({ filtered: true })
    };

    handleResetfilterAlgoLog = () => {
        let requestData = { token: this.props.hotelAuthReducer.hotelDetails.token }
        this.props.getAlgoProgramLog(requestData);
        this.setState({ filtered: false })
    };

    createDirectProgram = () => {

        let defaultData = [
            ['early morning', 7, 0, 7],
            ['quickmorning', 9, 0, 2],
            ['morning', 11, 0, 7],
            ['quickafternoon', 13, 0, 2],
            ['afternoon', 15, 0, 7],
            ['quickevening', 17, 0, 2],
            ['evening', 19, 0, 7],
            ["end of day run", 22, 30, 2],
            ["night run", 1, 0, 7]
        ];

        if (this.state.pms_provider == 24) {
            defaultData = [
                ['early morning', 6, 30, 7],
                ['quickmorning', 8, 55, 2],
                ['morning', 10, 55, 7],
                ['quickafternoon', 12, 55, 7],
                ['afternoon', 14, 55, 7],
                ['quickevening', 17, 55, 2],
                ['evening', 20, 55, 7],
                ["end of day run", 22, 30, 2],
                ["night run", 1, 0, 7]
            ];
        }

        defaultData.map((data) => {
            let reqParams = {
                hotel: this.props.hotelAuthReducer.hotelDetails.hotel.id,
                hour: data[1],
                minute: data[2],
                name: data[0],
                timezone: this.props.hotelAuthReducer.hotelDetails.hotel.timezone,
                type: data[3],
                token: this.props.hotelAuthReducer.hotelDetails.token
            }
            this.props.createAlgoProgram(reqParams)
        })
    }

    downloadResponse = (respJson, date) => {
        var content = JSON.stringify(respJson);
        const element = document.createElement("a");
        const file = new Blob([content], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "ResponseJson(" + moment.tz(date, this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY HH:mm') + ").json";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    downloadHistory = (value) => {
        let requestData = {
            token: this.props.hotelAuthReducer.hotelDetails.token,
            pricing_history_id: value.pricing_history_id,
            name: value.program_name,            
            date: moment.tz(value.date, this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY HH:mm')
        }
        this.props.downloadHistory(requestData)
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;

        const columns = [
            {
                name: t("ID"),
                options: {
                    filter: false
                }
            },
            {
                name: t("Name"),
                options: {
                    filter: false
                }
            },
            {
                name: t("Hour"),
                options: {
                    filter: false,
                }
            },
            // {
            //     name: t("Minute"),
            //     options: {
            //         filter: false
            //     }
            // },
            {
                name: t("Type"),
                options: {
                    filter: false,
                    download: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const { classes } = this.props;
                        const typeRun= this.state.types.find(val => val.key == value)
                        return <React.Fragment> {typeRun ? typeRun.value:""} </React.Fragment>
                    }
                }
            },
            {
                name: t("Delete"),
                options: {
                    filter: false,
                    download: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const { classes } = this.props;
                        return <React.Fragment>
                            <Button color="secondary" onClick={() => this.openDeleteModal(value)} className={classes.actionIcon}><Icon>delete</Icon></Button> </React.Fragment>
                    }
                }
            }
        ];

        const options = {
            responsive: 'scroll',
            selectableRows: false,
            filter: false,
            search: false,
            print: false,
            download: false,
            viewColumns: false,
            filter: false,
            rowsPerPage: 20,
            rowsPerPageOptions: false,
            onRowClick: (rowData) => {
                let program = this.state.programList.filter(val => val.id == rowData[0])
                this.openEditModal(program[0])
            },
            textLabels: {
                body: {
                    noMatch: this.props.algoReducer.loading ?
                        'Loading Records...' :
                        'Sorry, no matching records found.',
                },
            },
        };

        const logColumns = [
            {
                name: t("Name"),
                options: {
                    filter: false
                }
            },
            {
                name: t("Task"),
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <Link href={value.task_url} target="_blank">{value.task}</Link>
                    }
                }
            },
            // {
            //     name: t("Task created on"),
            //     options: {
            //         filter: false,
            //         customBodyRender: (value, tableMeta, updateValue) => {
            //             return moment.tz(value, this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY HH:mm')
            //         }
            //     }
            // },
            {
                name: t("Planned Execution"),
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return moment.tz(value, this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY HH:mm')
                    }
                }
            },
            {
                name: t("Auto-Upload"),
                options: {
                    filter: false,
                    download: false,
                    sort: false
                }
            },
            {
                name: t("Upload To Pms"),
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const { classes } = this.props;
                        let status = "";
                        let colour = "";
                        
                        
                        if(value.update_status  == 0){
                            status = "ERROR";
                            colour = "#C71C22";
                        }
                        if(value.update_status  == 1){
                            status = "SUCCESS";                            
                            colour = "#73A839";
                        }
                        if(value.update_status  == 2){
                            status = "Skipped";                          
                            colour = "orange";
                        }
                        console.log(value.update_status , status, colour)
                        return <div className={classes.filledDiv} style={{ textAlign: "center", background: colour }}>{status}</div>;
                    }
                }
            },
            {
                name: t("Response"),
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const { classes } = this.props;
                        if (value.update_result) {
                            return <Tooltip title={value.update_result}>
                                <Button className={classes.button} onClick={() => this.downloadResponse(value.update_result, value.date)}>{value.update_result ? value.update_result.substring(0, 5) : ""}</Button>
                            </Tooltip>
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: t("Dates Count"),
                options: {
                    filter: false
                }
            },
            {
                name: t("Request Count"),
                options: {
                    filter: false
                }
            },
            {
                name: t("Upload Price Request Count"),
                options: {
                    filter: false
                }
            },
            {
                name: t("Total Price Count"),
                options: {
                    filter: false
                }
            },
            {
                name: t("Threshold Date Count"),
                options: {
                    filter: false
                }
            },
            {
                name: t("Total Threshold Price Count"),
                options: {
                    filter: false
                }
            },
            {
                name: t("Status"),
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const { classes } = this.props;
                        return <div className={classes.filledDiv} style={{ background: StatusList[value].color }}>{t(StatusList[value].label)}</div>
                    }
                }
            },
            {
                name: t("Pricing History"),
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const { classes } = this.props;
                        return value ? <Button variant="outlined" color="primary" onClick={() => this.downloadHistory(value)}>Download</Button> : ""
                    }
                }
            }
        ];
        const logOptions = {
            responsive: 'scroll',
            selectableRows: false,
            search: false,
            print: false,
            download: false,
            viewColumns: false,
            filter: false,
            rowsPerPage: 20,
            serverSide: true,
            count: (this.props.algoReducer.runAlgoLog) ? (this.props.algoReducer.runAlgoLog.count) : 0,
            rowsPerPageOptions: false,
            page: (this.props.algoReducer.runAlgoLog) ? (this.props.algoReducer.runAlgoLog.current - 1) : 0,
            textLabels: {
                body: {
                    noMatch: this.props.algoReducer.loading ?
                        'Loading Records...' :
                        'Sorry, no matching records found.',
                },
            },
            onTableChange: (action, tableState) => {
                if (action === 'changePage') {
                    let page = tableState.page + 1;
                    let requestParams = {
                        token: this.props.hotelAuthReducer.hotelDetails.token,
                        url: 'pricingalgorithm/algo-program/execute/list/?page=' + page + '&',
                    };
                    this.props.getAlgoProgramLog(requestParams)
                }
            }
        };

        const { anchorEl } = this.state;
        const { fullScreen } = this.props;
        const open = this.state.changeLng ? false : Boolean(anchorEl);
        const { anchorEh } = this.state;

        return (
            <React.Fragment>
                <HeaderCard >
                    <Grid container>
                        <Grid item xs={12} >
                        <div className={classes.divStyle} >
                            <Typography variant="h5" className={classes.FontStyle} >
                                {t("Pricing Schedule")}
                            </Typography>
                        </div>
                        </Grid>
                       
                    </Grid>
                </HeaderCard>
                <Card>
                    <CardContent>                        
                        <div>
                            <Grid container>
                        <Grid item xs={12} >
                            <div className={classNames( classes.responsiveBtn)}>
                            <div className={classes.addBtns}>
                                <Button className={classes.addNewBtn} onClick={() => this.createDirectProgram()} variant="contained" color="primary" >
                                    {t("Add Default Programs")}
                                </Button>
                                <Button className={classes.addNewBtn} onClick={this.openCreateModal} variant="outlined" color="primary" >
                                    {t("Add New Program")}
                                </Button>
                               
                                <Button disabled={this.state.programLoader} onClick={() => this.openDeleteModal(true)} className={[classes.pricePmsBtn, classes.turnAlgoBtn, classes.uploadBtn]} color="danger" variant="contained" >
                                    {t("Delete All")}
                                </Button>
                            </div>
                        </div>
                        </Grid>
                        <Grid item xs={12} >
                            <MuiThemeProvider theme={runAlgoTheme}>
                                <MUIDataTable
                                    title=''
                                    data={this.generateTable(this.state.programList)}
                                    columns={columns}
                                    options={options}
                                />
                            </MuiThemeProvider>
                            </Grid>

                            </Grid>
                        </div>
                    </CardContent>
                </Card>
                <MuiThemeProvider theme={runAlgoTheme}>
                    <Card className={classes.logCard}>
                        <CardHeader  titleTypographyProps={{variant:'h6' }} title={t("Pricing Schedule Log")}
                            action={
                                <React.Fragment>
                                    {(this.state.filtered && this.state.programLogList) &&
                                        <Button onClick={() => this.handleResetfilterAlgoLog()} color="primary" >{t("Reset")}</Button>
                                    }
                                    <Button onClick={this.handlefilterOpen} color="primary" >
                                        <FilterListIcon />
                                    </Button>
                                </React.Fragment>}
                        >
                        </CardHeader>
                        <CardContent>
                            <div>
                                <MUIDataTable
                                    title=''
                                    data={this.generateAlgoLogTable(this.state.programLogList)}
                                    columns={logColumns}
                                    options={logOptions}
                                />
                            </div>
                        </CardContent>
                    </Card >
                </MuiThemeProvider>
                {this.state.showCreate ? modal.createRunAlgomodal(this) : ""}
                {this.state.showEdit ? modal.updateRunAlgomodal(this) : ""}
                {this.state.showDelete ? modal.deleteRunAlgomodal(this) : ""}
                {modal.algoProgramLogFilterModal(this)}
            </React.Fragment>
        );
    }
}

runAlgo.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        userSession: state.sessionReducer,
        hotelDetail: state.hotelSettingReducer.hoteDetail,
        hotelAuthReducer: state.hotelAuthReducer,
        algoReducer: state.algoReducer,
        priceSettingReducer: state.priceSettingReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAlgoProgram: (request) => { dispatch(actions.getAlgoProgram(request)) },
        getAlgoProgramLog: (request) => { dispatch(actions.getAlgoProgramLog(request)) },
        createAlgoProgram: (request) => { dispatch(actions.createAlgoProgram(request)) },
        updateAlgoProgram: (request) => { dispatch(actions.updateAlgoProgram(request)) },
        deleteAlgoProgram: (request) => { dispatch(actions.deleteAlgoProgram(request)) },
        downloadHistory: (request) => { dispatch(actions.downloadHistory(request)) },
        updateHotel: (request) => { dispatch(actions.updateHotelSetting(request)) },
    };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(runAlgo));