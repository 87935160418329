import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import RateCss from './css/rateSettingCss';
import Modals from './modal/modal';
import Switch from '@material-ui/core/Switch';
import { connect } from "react-redux";
import { compose } from 'redux';
import DialogContentText from '@material-ui/core/DialogContentText';
import * as actions from '../../../store/actions/index';
import Icon from '@material-ui/core/Icon';
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { rateSettingTheme } from '../../muiTheme';
import Grid from '@material-ui/core/Grid';
import CustomTooltip from '../../Utils/toolTip';
import { withNamespaces, Trans } from 'react-i18next';
import Warning from '../warning';
import HeaderCard from "../HeaderCard";
import Typography from '@material-ui/core/Typography';

const styles = RateCss;

let RateList = [];

class RateSetting extends React.Component {

  state = {
    addNewRateModal: false,
    editRateModal: false,
    deleteRateModal: false,
    addNewRateData: {
      price: '',
      name: ''
    },
    editRateData: {
      price: '',
      name: '',
      id: ''
    },
    deleteRateData: {
      price: '',
      name: '',
      id: ''
    },
    loadData: true,
    predefined_rate: false,
    error: [],
    isNoDataPopup: false,
    warning: false,
    warningMsg: [],
    warningMethod: null,
    errorMsg: "",
    showWarn: false,
    showEdit: false,
    showDelete: false,
  };

  componentWillMount() {
    let requestParam = { token: this.props.hotelAuthReducer.hotelDetails.token }
    this.props.getHotelDetail(requestParam);
  }

  componentWillReceiveProps(props) {
    


    if (props.priceSettingReducer.priceSetting) {
      RateList = [];
      if (Array.isArray(props.priceSettingReducer.priceSetting.predefined_rates)) {
        props.priceSettingReducer.priceSetting.predefined_rates.map((val, index) => {
          RateList.push([val,index,index]);
        })
       
      }
    }

  }


   //DELETE rate functions

  toggleDeleteRateModal = (index) => {
    if (index != undefined) {
      this.setState({
        showDelete: true,
        deleteRateData: {
          id: index
        }
      },() => {
        this.setState({
            showEdit: false
        })
    }
      );
    }
    this.setState({ deleteRateModal: !this.state.deleteRateModal });
  }

  deleteRate = () => {
    let rates = [];
    RateList.map((val, index) => {
      if (index != this.state.deleteRateData.id) {
        rates.push(parseInt(val[0]))
      }
    })

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      rates: rates
    }
    this.props.defineRateCreate(requestParam)
    this.toggleDeleteRateModal()
  }


  //edit rate functions

  toggleEditRateModal = (index) => {
    if (RateList[index]) {
      this.setState({
        showEdit: true,
        editRateData: {
          price: RateList[index][0],
          id: index
        },
        error: []
      });
    }
    this.setState({ editRateModal: !this.state.editRateModal });
  }

  handleEditRate = (name) => event => {
    this.setState({
      editRateData: {
        ...this.state.editRateData,
        [name]: event.target.value,
      }
    });
  }

  validateEditForm = () => {
    let error = [];

    

    if (!this.state.editRateData.price && this.state.editRateData.price !== 0) {
      error.push('edit_price')
    }

    if (error) {
      this.setState({
        error: error
      })
      return error
    }
    else {
      return false
    }
  }

  editRate = () => {
    let validateError = this.validateEditForm();

    if (validateError.length > 0) {
      return;
    }

    let rates = [];
    RateList.map((val, index) => {
      if (index == this.state.editRateData.id) {
        rates.push(parseInt(this.state.editRateData.price))
      } else {
        rates.push(parseInt(val[0]))
      }
    })

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      rates: rates
    }
    this.props.defineRateCreate(requestParam)
    this.toggleEditRateModal()
  }


  //create rate functions 
  toggleAddNewRateModal = () => {
    this.setState({
      addNewRateModal: !this.state.addNewRateModal,
      error: [],
      addNewRateData: {
        price: '',
        name: ''
      }
    });
  }

  handleNewRate = (name) => event => {
    this.setState({
      addNewRateData: {
        ...this.state.addNewRateData,
        [name]: event.target.value,
      }
    });
  }

  validateCreateForm = () => {
    let error = [];


    if (!this.state.addNewRateData.price && this.state.addNewRateData.price !== 0) {
      error.push('price')
    }

    if (error) {
      this.setState({
        error: error
      })
      return error
    }
    else {
      return false
    }
  }

  createNewRate = () => {
    let validateError = this.validateCreateForm();

    if (validateError.length > 0) {
      return;
    }

    let rates = [];
    RateList.map((val, index) => {
      rates.push(parseInt(val[0]))
    })
    rates.push(parseInt(this.state.addNewRateData.price))

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      rates: rates
    }
    this.props.defineRateCreate(requestParam)
    this.toggleAddNewRateModal()
  }

  changePredefinedRate = () => {
    if (RateList.length > 0) {
      let requestParam = {
        token: this.props.hotelAuthReducer.hotelDetails.token,
        predefined_rate: this.state.predefined_rate ? false : true,
      }

      this.props.updateHotel(requestParam);
    } else {
      this.setState({
        isNoDataPopup: true
      })
    }
  }

  noDataModal = () => {
    this.setState({
      isNoDataPopup: !this.state.isNoDataPopup
    })
  }

  addNewWithNodata = () => {
    this.setState({
      isNoDataPopup: !this.state.isNoDataPopup,
    })
    this.toggleAddNewRateModal();
  }




  render() {
    const { classes } = this.props;
    const { t } = this.props;

    let width = window.screen.width;
    const columns = [
     
      {
        name: t("Price"),
        options: {
          sortDirection: 'asc',
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const { classes } = this.props;
            return this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol + value
          }
        },
      },
      {
        name: t("Delete"),
        options: {
          filter: false,
          download: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const { classes } = this.props;
            return <React.Fragment> 
               <Button className={classes.actionIcon}  onClick={() => this.toggleDeleteRateModal(value)}><Icon className={classes.delIcon} >delete</Icon></Button> </React.Fragment>
          }
        }
      }, 
      {
        name:"index",
        options:{
          display:false
        }
      },
    ];
    const options = {
      responsive: 'scroll',
      selectableRows: false,
      search: false,
      print: false,
      download: false,
      viewColumns: false,
      filter: false,
      rowsPerPage: 20,
      rowsPerPageOptions: false,
      onRowClick: (rowData) => {
        let rate = RateList.filter(val => val[1]==rowData[2])
        this.toggleEditRateModal(rate[0][1])
    },
    };

    return (
      <>
      <HeaderCard>
                <Grid container direction={width < 800 ? "column" : ""}>
                    <Grid item >
                      <div className={classes.divStyle} >
                        <Typography variant="h5" className={classes.fontStyle}>
                          {t("BAR Levels")}
                          <CustomTooltip title="" description={t("The pricing suggestions we give are rounded to the nearest unit. For example, if we think you should charge $112.32 we will suggest $112. Some hotel owners would prefer more rounding than this. For this reason, we offer the option of choosing a set of predefined rates. We would suggest at least 10. If your minimum price is $100 and your maximum is $280 you could set up these rates up at $100, $120, $140… all the way up to $280.")} />
                        </Typography>
                        <DialogContentText align="left" className={classes.helpText}>
                          {t("Use BAR Levels to pre-define price levels for the reference room at certain set price-points. For example, you may always want your prices to end with a 9. If you wish to use this feature, we recommend setting at least 10 levels stretching from minimum to maximum price.We will round the price-recommendation to the nearest BAR Level.")}
                        </DialogContentText>
                        <div className={classes.btnAdd} >
                          <Button className={classes.addUserBtn} variant="contained" color="primary" onClick={() => this.toggleAddNewRateModal()}>
                            {t("Add New BAR Level")}
                          </Button>
                        </div>
                      </div>
                    </Grid>
                </Grid>
            </HeaderCard>
      
      <Card className={classes.mainCard} >
        <CardContent>
              <div className={classes.dateTableDiv}>
              <MuiThemeProvider theme={rateSettingTheme}>
                {RateList.length > 0 ?
                  
                    <MUIDataTable
                      title=''
                      data={RateList}
                      columns={columns}
                      options={options}
                    />
                   : 
                  <Typography style={{fontSize:'16px',color:'#707070'}}>{t("No BAR Levels defined yet.")}</Typography>
                }</MuiThemeProvider>
              </div>
              <span class="error-message">{this.state.errorMsg}</span>
           
        </CardContent>
        {Modals.addNewRate(this)}
            {this.state.showEdit? Modals.editNewRate(this):""}
            {this.state.showDelete?Modals.DeleteRateModal(this):""}
            {Modals.noRateModal(this)}
            {Warning.warningModal(this)}
            
        </Card>
     
      </>
    );
  }
}

RateSetting.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
  return {
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    userSession: state.sessionReducer,
    currencyList: state.currencyReducer.currencyList,
    hotelAuthReducer: state.hotelAuthReducer,
    rateSettingReducer: state.rateSettingReducer,
    hotelDetailReducer: state.hotelSettingReducer,
    priceSettingReducer: state.priceSettingReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    
    defineRateCreate: (request) => { dispatch(actions.defineRateCreate(request)) },
    
    getHotelDetail: (request) => { dispatch(actions.getHotelDetail(request)) },
    updateHotel: (request) => { dispatch(actions.updateHotelSetting(request)) }
  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(RateSetting));