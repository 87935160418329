import * as actionTypes from '../actionTypes';
import * as actions from '../../../store/actions/index';
import Server from '../../../config/server'
import axios from 'axios';
import Notify from '../Notify';

const onRoomListStart = () => {
    return {
        type: actionTypes.ROOM_LIST_START
    }
}

const onRoomListFailed = (error) => {
    return {
        type: actionTypes.ROOM_LIST_FAILED,
        error: error
    }
}

const onRoomListSucceed = (data) => {
    return {
        type: actionTypes.ROOM_LIST_SUCCEED,
        state: data
    }
}

export const getRoomList = (request, updateAll) => {
    return dispatch => {
        dispatch(onRoomListStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'hotel/room/list/')
            .then(function (response) {
                if (updateAll) {
                    // dispatch(actions.updateSettingJson(request, response.data, 'roomUpdateAll'))
                }
                let responseData = {};
                // responseData.results = response.data;

                responseData.results = response.data.map(val => {
                    delete val.max_occupancy;
                    delete val.variable_cost_per_room;
                    delete val.adjustment_to_reference_room;
                    delete val.avg_price;
                    delete val.max_price;
                    delete val.min_price;

                    return val;
                })
                dispatch(onRoomListSucceed(responseData))
                //                dispatch(onRoomListSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onRoomListFailed(error))
            });
    }
}

const onAddNewRoomStart = () => {
    return {
        type: actionTypes.ADD_ROOM_START
    }
}

const onAddNewRoomFailed = (error) => {
    return {
        type: actionTypes.ADD_ROOM_FAILED,
        error: error
    }
}

const onAddNewRoomSucceed = (data) => {
    return {
        type: actionTypes.ADD_ROOM_SUCCEED,
        state: data
    }
}

export const addNewRoom = (request) => {
    return dispatch => {
        let requestParams = {
            // adjustment_to_reference_room_is_absolute: request.adjustment_to_reference_room_is_absolute ? true : false,
            // avg_price: request.avg_price,
            // handleRate: request.handleRate,
            max_occupancy: request.max_occupancy,
            name: request.name,
            pms_rate: request.pms_rate,
            pms_room: request.pms_room,
            discount_per_person: request.discount_per_person ? request.discount_per_person : 0,
            // adjustment_to_reference_room_is_locked: request.adjustment_to_reference_room_is_locked,
            use_prices_from: request.use_prices_from,
            pms_price_applicable_occupancy: request.pms_price_applicable_occupancy,
            // max_price: request.max_price,
            // min_price: request.min_price,
            default_occupancy: request.default_occupancy,
            extra: request.extra,
            ignore_upload_count:request.ignore_upload_count,
        }
        dispatch(onAddNewRoomStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/room/register/', requestParams)
            .then(function (response) {

                dispatch(actions.updateSettingJson(request, response.data, 'createRoom'))
                
                // dispatch(actions.getRoomList(request, true))
                let requestData={
                    token:request.token,
                    occupancyPrices:request.occupancyPrices,
                    room_id:response.data.id
                }
                dispatch(storeOccupancyPrices(requestData))
                dispatch(actions.getRoomList(request))
                dispatch(actions.getPmsList(request))
                dispatch(onAddNewRoomSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while creating Room", false))
                dispatch(onAddNewRoomFailed(error))
            });
    }
}

const onEditRoomStart = () => {
    return {
        type: actionTypes.UPDATE_ROOM_START
    }
}

const onEditRoomFailed = (error) => {
    return {
        type: actionTypes.UPDATE_ROOM_FAILED,
        error: error
    }
}

const onEditRoomSucceed = (data) => {
    return {
        type: actionTypes.UPDATE_ROOM_SUCCEED,
        state: data
    }
}

export const editRoom = (request, allData) => {
    return dispatch => {
        dispatch(onEditRoomStart())
        let requestParams = {
            name: request.name,
            pms_price_applicable_occupancy_id: request.pms_price_applicable_occupancy,
            pms_rate_id: request.pms_rate,
            pms_room_id: request.pms_room,
            discount_per_person: request.discount_per_person ? request.discount_per_person : 0,
            use_prices_from: request.use_prices_from,
            default_occupancy: request.default_occupancy,
            extra: request.extra,
            extra_child:request.extra_child,
            ignore_upload_count:request.ignore_upload_count,
        }
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.patch(Server.API + 'hotel/room/update/' + request.id + '/', requestParams)
            .then(function (response) {
                if (response.data.is_reference_room) {
                    dispatch(actions.updateSettingJson(request, response.data, 'roomUpdate'))
                    setTimeout(() => { dispatch(actions.getRoomList(request, true)) }, 2000);
                } else {
                    dispatch(actions.updateSettingJson(request, response.data, 'roomUpdate'))
                    dispatch(actions.getRoomList(request))
                }
                dispatch(actions.getPmsList(request))
                dispatch(onEditRoomSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while updating Room Type", false))
                dispatch(onEditRoomFailed(error))
            });
    }
}


const onDeleteRoomStart = () => {
    return {
        type: actionTypes.DELETE_ROOM_START
    }
}

const onDeleteRoomFailed = (error) => {
    return {
        type: actionTypes.DELETE_ROOM_FAILED,
        error: error
    }
}

const onDeleteRoomSucceed = (data) => {
    return {
        type: actionTypes.DELETE_ROOM_SUCCEED,
        state: data
    }
}


export const deleteRoom = (request) => {
    return dispatch => {
        dispatch(onDeleteRoomStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.delete(Server.API + 'hotel/room/delete/' + request.id + '/', request)
            .then(function (response) {
                dispatch(actions.updateSettingJson(request, response.data, 'deleteRoom'))
                dispatch(actions.getRoomList(request))
                dispatch(actions.getPmsList(request))
                dispatch(onDeleteRoomSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Error While Deleting Room Type", false))
                dispatch(onDeleteRoomFailed(error))
            });
    }
}

const onAddRefRoomStart = () => {
    return {
        type: actionTypes.ADD_REF_ROOM_START
    }
}

const onAddRefRoomFailed = (error) => {
    return {
        type: actionTypes.ADD_REF_ROOM_FAILED,
        error: error
    }
}

const onAddRefRoomSucceed = (data) => {
    return {
        type: actionTypes.ADD_REF_ROOM_SUCCEED,
        state: data
    }
}

export const AddRefRoom = (request, allData) => {
    return dispatch => {
        dispatch(onAddRefRoomStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/room/reference/update/', request)
            .then(function (response) {
                dispatch(actions.getRoomList(request, true))
                dispatch(onAddRefRoomSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onAddRefRoomFailed(error))
            });
    }
}

const onAveragePriceSucceed = (data) => {
    return {
        type: actionTypes.AVERAGE_PRICE_SUCCEED,
        state: data
    }
}


export const updateAveragePrice = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/room/calculate-average-price/', request)
            .then(function (response) {
                if(response.data.is_final_data){                    
                    dispatch(onAveragePriceSucceed(response.data.data));
                }        
                // dispatch(onAveragePriceSucceed(response.data))
                dispatch(actions.getRoomList(request))
                // dispatch(Notify.newUserModal("Average Price Fetching Successfully", true))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Error While Fetching Average Price", false))
            });
    }
}

export const connectRooms = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/pms/room/map/', request)
            .then(function (response) {
                if (request.updateRoomData) {
                    dispatch(actions.updateSettingJson(request, response.data, 'autoSetup', "editRoom", [request.updateRoomData]))
                } else {
                    dispatch(actions.updateSettingJson(request, response.data, 'autoSetup'))
                }
                dispatch(actions.getPmsList(request))
                dispatch(actions.getRoomList(request))
                dispatch(Notify.newUserModal("Auto Setup Successfully", true))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Error While Auto Setup", false))
            });
    }
}

const getAverageMonthWeekSuccess = (data) => {
    return {
        type: actionTypes.AVERAGE_MONTH_WEEK,
        state: data
    }
}


export const getAverageMonthWeek = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/adjustment/calculate-date-settings/', request)
            .then(function (response) {
                dispatch(getAverageMonthWeekSuccess(response.data))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Error While Fetching Average Price", false))
            });
    }
}

const onRoomBulkUpdateStart = () => {
    return {
        type: actionTypes.ROOM_BILKUPDATE_START
    }
}

const onRoomBulkUpdateFailed = (error) => {
    return {
        type: actionTypes.ROOM_BILKUPDATE_FAILED,
        error: error
    }
}

const onRoomBulkUpdateSucceed = (data) => {
    return {
        type: actionTypes.ROOM_BILKUPDATE_SUCCEED,
        state: data
    }
}

export const roomBulkUpdate = (request, refRoomRequest) => {
    return dispatch => {
        dispatch(onRoomBulkUpdateStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/room/bulk/', { rooms: JSON.stringify(request.roomAdjustment) })
            .then(function (response) {
                if (refRoomRequest) {
                    // dispatch(actions.AddRefRoom(refRoomRequest));
                } else {
                    dispatch(actions.getRoomList(request));
                }
            })
            .catch(function (error) {
                dispatch(onRoomBulkUpdateFailed(error))
            });
    }
}

const storeOccupancyPricesStart = () => {
    return {
        type: actionTypes.STORE_OCCUPANCY_PRICES_START
    }
}

const storeOccupancyPricesFailed = (error) => {
    return {
        type: actionTypes.STORE_OCCUPANCY_PRICES_FAILED,
        error: error
    }
}

const storeOccupancyPricesSuccess = (data) => {
    return {
        type: actionTypes.STORE_OCCUPANCY_PRICES_SUCCESS,
        state: data
    }
}
export const storeOccupancyPrices = (request) => {    
    let occArr=[];
    request.occupancyPrices.map((val)=>{
        if(val.occupancy){
            occArr.push({ occupancy: val.occupancy, derivation: val.derivation, room:request.room_id })
        }        
    })
    return dispatch => {
        dispatch(storeOccupancyPricesStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/price-per-occupancy/'+ request.room_id +'/', occArr)
            .then(function (response) {
                dispatch(getOccupancyPricesSuccess(response))                
            })
            .catch(function (error) {
                dispatch(storeOccupancyPricesFailed(error))
            });
    }   
}

const getOccupancyPricesStart = () => {
    return {
        type: actionTypes.GET_OCCUPANCY_PRICES_START
    }
}

const getOccupancyPricesFailed = (error) => {
    return {
        type: actionTypes.GET_OCCUPANCY_PRICES_FAILED,
        error: error
    }
}

const getOccupancyPricesSuccess = (data) => {
    return {
        type: actionTypes.GET_OCCUPANCY_PRICES_SUCCESS,
        state: data
    }
}
export const getOccupancyPrices = (request) => {
    return dispatch => {
        dispatch(getOccupancyPricesStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'hotel/price-per-occupancy/'+request.room_id + '/')
            .then(function (response) {
                dispatch(getOccupancyPricesSuccess(response))
            })
            .catch(function (error) {
                dispatch(getOccupancyPricesFailed(error))
            });
    }   
}

const getOccupancyPricesAllStart = () => {
    return {
        type: actionTypes.GET_OCCUPANCY_PRICES_ALL_START
    }
}

const getOccupancyPricesAllFailed = (error) => {
    return {
        type: actionTypes.GET_OCCUPANCY_PRICES_ALL_FAILED,
        error: error
    }
}

const getOccupancyPricesAllSuccess = (data) => {
    return {
        type: actionTypes.GET_OCCUPANCY_PRICES_ALL_SUCCESS,
        state: data
    }
}
export const getOccupancyPricesAll = (request) => {
    return dispatch => {
        dispatch(getOccupancyPricesAllStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'hotel/price-per-occupancy/')
            .then(function (response) {
                dispatch(getOccupancyPricesAllSuccess(response))
            })
            .catch(function (error) {
                dispatch(getOccupancyPricesAllFailed(error))
            });
    }   
}

export const setupNumberRooms = (request) => {
    return dispatch => {
        axios.post(Server.API + 'hotel/calculate_no_of_rooms/', { is_calculate_no_of_rooms: true })
        .then(() => {            
            dispatch(actions.getPricingSettings(request));
            dispatch(Notify.newUserModal("Setup number of rooms successfully", true))
        }).catch(function (error) {
            dispatch(Notify.newUserModal("An error occured while calculating number of rooms", false))
        });
    }   
}
const getPricePerRateStart = () => {
    return {
        type: actionTypes.GET_PRICE_PER_RATE_START
    }
}

const getPricePerRateFailed = (error) => {
    return {
        type: actionTypes.GET_PRICE_PER_RATE_FAILED,
        error: error
    }
}

const getPricePerRateSuccess = (data) => {
    return {
        type: actionTypes.GET_PRICE_PER_RATE_SUCCEED,
        state: data
    }
}
export const getPricePerRate = (request)  => {      
    return dispatch => {
        dispatch(getPricePerRateStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'hotel/price-per-rate/'+request.pms_room_id+"/" )
        .then((response) => {            
            dispatch(getPricePerRateSuccess(response))
            // dispatch(Notify.newUserModal("Setup number of rooms successfully", true))
        }).catch(function (error) {
            dispatch(getPricePerRateFailed())
            dispatch(Notify.newUserModal("An error occured while get Price Per Rate  ", false))
        });
    }
}

export const storePricePerRate = (request) =>  {   
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/price-per-rate/'+request.pms_room+"/", request.pricePerRate)
        .then((response) => { 
        }).catch(function (error) {
            dispatch(Notify.newUserModal("An error occured while store Price Per Rate  ", false))
        });
    }
}


