import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Grid } from '@material-ui/core';
import SubscribedRecords from './components/SubscribedRecords';
import OpenCases from './components/OpenCases';
import PmsChart from './components/PmsChart';
import DealOwnerChart from './components/DealOwnerChart';
import PricechangeOver20 from './components/PricechangeOver20';
import AutoUploadOff from './components/AutoUploadOff';
import AccountSuspended from './components/AccountSuspended';
import LastLogin30Days from './components/LastLogin30Days';
import * as actions from '../../../store/actions/index';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import FreetrialRecords from './components/FreetrialRecords';
import HeaderCard from "../../Client/HeaderCard";
import subscribedClientsCss from '../css/subscribedClientsCss'

const styles = subscribedClientsCss;


class subscribedClients extends React.Component {
    state = {
      subscribedClientData:{},
      deal_owner:"all|all",
      deal_owner_filter:'all',
      deal_owner_type:'all',
      status_type:'all',
      error: [],
      freeTrialClientData:{},

    };

    handleChange = (event) => {
      const name = event.target.name;
      let deal_owner_list = event.target.value.split("|");
      this.setState({
        ...this.state,
        [name]: event.target.value,
        deal_owner_filter: deal_owner_list[0],
        deal_owner_type: deal_owner_list[1]
      });
      let requestParams = {
        token: this.props.userSession.token,
        deal_owner_filter: deal_owner_list[0],
        deal_owner_type: deal_owner_list[1],
        status_type: this.state.status_type
      };
        this.props.getSubscribedClientData(requestParams);
    };

    handleChangeStatus = (event) => {
      const name = event.target.name;
      this.setState({
        ...this.state,
        [name]:  event.target.value
      });
      let requestParams = {
        token: this.props.userSession.token,
        deal_owner_filter: this.state.deal_owner_filter,
        deal_owner_type: this.state.deal_owner_type,
        status_type: event.target.value
      };
        this.props.getSubscribedClientData(requestParams);
    };

    componentWillMount() {
      let requestParams = {
            token: this.props.userSession.token,
            deal_owner_filter: 'all',
            deal_owner_type: 'all',
            status_type: 'all'
        };
          this.props.getResponsiblePerson(requestParams);
          this.props.getSubscribedClientData(requestParams);
          this.props.getFreeTrialClientData(requestParams);

    }

    componentWillReceiveProps(props) {
        if (props.adminUserReducer.responsiblePersonList) {
            this.setState({
                responsiblePersonList: props.adminUserReducer.responsiblePersonList
            })
        }
        if (props.adminUserReducer.subscribedClientData) {
            this.setState({
              subscribedClientData: props.adminUserReducer.subscribedClientData
            })
        }
        if (props.adminUserReducer.freeTrialClientData) {
          this.setState({
            freeTrialClientData: props.adminUserReducer.freeTrialClientData
          })
      }

    }

    render() {
      const { classes } = this.props;
        return (
          <>
          <HeaderCard>
                <Grid container>
                    <Grid item >
                        <div className={classes.divStyle}>
                        <Typography variant="h5" className={classes.fontStyle} >
                          Client Dashboard
                        </Typography>
                        </div>
                    </Grid>
                </Grid>
            </HeaderCard>
          <Card>
            <CardContent>
            <Typography variant="subtitle1" gutterBottom align="left"  className={classes.filter} >
              <FormControl className={classes.formControl}>
                <NativeSelect
                  className={classes.selectEmpty}
                  value={this.state.deal_owner}
                  name="deal_owner"
                  onChange={this.handleChange}
                  inputProps={{ 'aria-label': 'age' }}
                >
                  <option value="all|all">All Users</option>
                  {this.state.responsiblePersonList && this.state.responsiblePersonList.sort(function (a, b) {
                            if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                            if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }  
                            return 0;
                        }).map((e, key) => {
                      return <option key={key} value={e.id+"|"+e.type}>{(e.name)}</option>;
                  })}   
                </NativeSelect>
                <FormHelperText>Deal Owner</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <NativeSelect
                  className={classes.selectEmpty}
                  value={this.state.status}
                  name="status_type"
                  onChange={this.handleChangeStatus}
                  inputProps={{ 'aria-label': 'Status' }}
                >
                  <option value="all">Open/In Progress</option>
                  <option value="open">Open</option>
                  <option value="in_progress">In Progress</option>
                  <option value="closed">Closed</option> 
                </NativeSelect>
                <FormHelperText>Status</FormHelperText>
              </FormControl>
            </Typography>
            <Grid container spacing={4} >
              <Grid item lg={4} md={6} xl={4} xs={12} >
                <SubscribedRecords subscribed_hotel_count={this.state.subscribedClientData.subscribed_hotel_count} subscribed_hotel_last_one_week_count ={this.state.subscribedClientData.subscribed_hotel_last_one_week_count} deal_owner_filter={this.state.deal_owner_filter} deal_owner_type={this.state.deal_owner_type} cancelled_subscription_last_one_week_count={this.state.subscribedClientData.cancelled_subscription_last_one_week_count} />
              </Grid>
              <Grid item lg={4} md={6} xl={4} xs={12} >
                <FreetrialRecords free_trial_count={this.state.freeTrialClientData.free_trial_count} free_trial_last_one_week_count ={this.state.freeTrialClientData.free_trial_last_one_week_count} deal_owner_filter={this.state.deal_owner_filter} deal_owner_type={this.state.deal_owner_type} status_type={this.state.status_type} />
              </Grid>
              <Grid item lg={4} sm={12} md={12} xl={4} xs={12} ></Grid>
              <Grid item lg={6} sm={12} md={6} xl={6} xs={12} >
                  <PmsChart deal_owner_filter={this.state.deal_owner_filter} deal_owner_type={this.state.deal_owner_type} />
              </Grid> 
              <Grid item lg={6} sm={12} md={6} xl={6} xs={12} >
                <DealOwnerChart deal_owner_filter={this.state.deal_owner_filter} deal_owner_type={this.state.deal_owner_type} />
              </Grid>   
            </Grid>
            </CardContent>
          </Card >
          </>
        );
    }
}

const mapStateToProps = state => {
  return {
      loading: state.authReducer.loading,
      error: state.authReducer.error,
      userSession: state.sessionReducer,
      adminUserReducer: state.adminUserReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
      getClientList: (request) => { dispatch(actions.getClientList(request)) },
      getCountry: (request) => { dispatch(actions.getCountry(request)) },
      getResponsiblePerson: (request) => { dispatch(actions.getResponsiblePerson(request)) },
      getSubscribedClientData: (request) => { dispatch(actions.getSubscribedClientData(request)) },
      getFreeTrialClientData: (request) => { dispatch(actions.getFreeTrialClientData(request)) },

  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(subscribedClients);
