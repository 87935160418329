var style = {
  RadioGroup: {
    display: 'inline-block',
  },
  emailLabel: {
    marginTop: '20px'
  },
  userTable: {
    padding: '20px'
  },
  addUserBtn: {
    float: 'right',
    marginBottom: '30px'
  },
  userTableDiv: {
    overflow: 'auto'
  },
  actionIcon: {
    padding: '0',
    cursor: 'pointer',
    minWidth: '30px'
  },
  textField: {
    minWidth: '250px'
  },
  popupDiv: {
    display: "flex",
    flexDirection: 'inherit'
  },
  MainLoader: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    background: 'rgb(15, 51, 80, 0.3)',
    zIndex: '9',
  },
  loader: {
    position: 'absolute',
    top: '35%',
    left: '35%'
  },
  fieldLeft: {
    float: 'left',
    marginRight: '20px'
  },
  refRoomOnly: {
    float: 'left'
  },
  paddingTen: {
    padding: '10px 15px 10px 15px',
    textAlign: 'center'
  },
  actionCell: {
    padding: '10px 15px 10px 15px',
    textAlign: 'left'
  },
  gridContainer: {
    textAlign: 'left'
  },
  adornment: {
    color: 'rgb(118, 118, 118)',
    paddingRight: '5px'
  },
  halfWidth: {
    width: '50%'
  },
  btnLeft: {
    float: 'left',
    margin: "30px 0px"
  }
}
export default style;