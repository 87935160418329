import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = {
    eventCategory: [],
    impEvents:[]
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.EVENT_CATEGORY_START:
            return updateObject(state, {eventCatLoding:true,error: null})
        case actionTypes.EVENT_CATEGORY_SUCCEED:
            return updateObject(state, {eventCatLoding:false, error: null, eventCategory: action.state })
        case actionTypes.EVENT_CATEGORY_FAILED:
            return updateObject(state, {eventCatLoding:false, error: action.error })
        case actionTypes.IMPORTANT_EVENT_SUCCEED:
            return updateObject(state, {eventCatLoding:false, error: null, impEvents: action.state })
        default:
            return updateObject(state, { loading: false, error: null });
    }
}

export default reducer