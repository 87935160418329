var style = {
  logCard: {
    marginTop: 30
  },
  checkBoxContainers:{
    ['@media (max-width: 414px) and (min-width: 360px)']: {      
      flexDirection: 'column'
    }
  },
  saveBtn: {
    // minWidth: '100px',
    float: 'left'
  },
  buttonProgress:{
    position:"absolute"
  },
  btnDiv:{
    marginTop: '0px',
    width:"100%"
  },
  divStyle: {
    float: "left",
    marginTop: "2px"
  },
  FontStyle: {
    textAlign: "left", 
    fontWeight: "500"
  },
  checkbox: {
    float: "left", 
    width: "100%", 
    marginBottom: "24px", 
    marginTop:"-15px"
  },
  checkboxLable: {
    textAlign: "left",
    whiteSpace:"nowrap"
  },
  checkboxLable2:{
    textAlign: "left",
    display:'flex',
    alinItems:'center',
  },
  checkboxLable1: {
    textAlign: "left",
    display:'flex',
    alinItems:'center',
    whiteSpace:'nowrap'
  },
  checkboxFeature: {
    float: "left", 
    width: "100%"
  },
  htmlTooltip: {
    backgroundColor: "#323338 !important",
    color: "white",
    maxWidth: "250px",
    // border: "1px solid #1976d2",
    opacity: "1",
    filter: "alpha(opacity=100)",
  },
}
export default style;