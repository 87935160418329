import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import HotelCss from './css/hotelSettingCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../../store/actions/index';
import GoogleMapReact from 'google-map-react';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select, { components } from 'react-select';
import Select2 from '@material-ui/core/Select';
import { withNamespaces, Trans } from 'react-i18next';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Icon, IconButton } from '@material-ui/core';
import CustomTooltip from '../../Utils/toolTip';
import Divider from '@material-ui/core/Divider';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import HeaderCard from '../HeaderCard';
import Typography from '@material-ui/core/Typography';

const styles = HotelCss;
const AnyReactComponent = ({ text }) => <div>{text}</div>;

let CountryList = [];
let RoomList = [];

class HotelSetting extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      timezone: "",
      hotelDetail: '',
      loadData: false,
      error: [],
      roomList: [],
      address: '',
      latitude: '',
      longitude: '',
      resellerList: [],
      salesList: [],
      memberList: [],
      allMemberList: [],
      responsible_account: '',
      reseller: null,
      is_send_email: false,
      starts_monday: false,
      booking_ref_url: "",
      event_types: null,
      event_radius:null,
      validateUrlSuccess: true,
      validateUrlMessage: "",
      enc_hotel_id:"",
      tab:0,
      prefered_date_format:'DD/MM/YYYY, hh:mm A'
    };
  }

  componentDidMount() {
    let requestParam = { token: this.props.hotelAuthReducer.hotelDetails.token }
    this.props.getHotelDetail(requestParam);
    this.props.getCurrency(requestParam);
    this.props.getCountry(requestParam);
    if (this.props.userSession.user.is_staff) {
      this.props.getResellerAll(requestParam);
      this.props.getAdminSales(requestParam);
      this.props.getResellerMemberAll(requestParam)
    }
    if (this.props.userSession.user.sub_account_type) {
      this.props.getResellerMemberAll(requestParam)
    }
  }

  componentWillReceiveProps(props) {
    if (props.hotelDetailReducer.validateBookingUrl) {      
      if (!props.hotelDetailReducer.validateBookingUrl.is_ref_url_valid) {
        this.setState({
          validateUrlSuccess: false,
          validateUrlMessage: props.hotelDetailReducer.validateBookingUrl.message ? props.hotelDetailReducer.validateBookingUrl.message : "Suggested: " + props.hotelDetailReducer.validateBookingUrl.booking_ref_url ,          
        })
      } else {
        this.setState({
          validateUrlSuccess: true,
          hotelDetail: {
            ...this.state.hotelDetail,
          },
        })
      }
    }

    if (props.adminUserReducer.adminUserList) {
      this.setState({
        salesList: props.adminUserReducer.adminUserList
      })
    }

    if (props.resellerReducer.allResellerList) {
      this.setState({
        resellerList: props.resellerReducer.allResellerList
      })
    }

    if (props.resellerReducer.resellerAllMemberList) {
      this.setState({
        allMemberList: props.resellerReducer.resellerAllMemberList
      })
      if (this.state.reseller) {
        this.setState({
          memberList: props.resellerReducer.resellerAllMemberList.filter(opt => opt.reseller.id == this.state.reseller)
        })
      }
    }

    if (props.commonReducer.notify) {
      if (props.commonReducer.notify.message) {
        this.setState({
          loadData: false,
          loadEvents: false
        })
      }
    }

    if (props.hotelDetailReducer.hoteDetail && !this.state.hotelDetail) {      
      this.setState({
       
        hotelDetail: props.hotelDetailReducer.hoteDetail,
        selectedCountry: { value: props.hotelDetailReducer.hoteDetail.country.id, label: props.hotelDetailReducer.hoteDetail.country.name + ' (' + props.hotelDetailReducer.hoteDetail.country.abbreviation + ')' },
        latitude: parseFloat(props.hotelDetailReducer.hoteDetail.lat).toFixed(2),
        longitude: parseFloat(props.hotelDetailReducer.hoteDetail.lng).toFixed(2),
        responsible_account: props.hotelDetailReducer.hoteDetail.responsible_person ? props.hotelDetailReducer.hoteDetail.responsible_person.id : null,
        reseller: props.hotelDetailReducer.hoteDetail.reseller ? props.hotelDetailReducer.hoteDetail.reseller.id : null,
        is_send_email: props.hotelDetailReducer.hoteDetail.is_send_email,
        starts_monday: props.hotelDetailReducer.hoteDetail.starts_monday,
        booking_ref_url: props.hotelDetailReducer.hoteDetail.booking_ref_url,
        prefered_date_format:props.hotelDetailReducer.hoteDetail.prefered_date_format
      }, () => {
        this.validateBookingUrl(props.hotelDetailReducer.hoteDetail.booking_ref_url)
        console.log('AAAAAAAAAAAAAAAA',this.state.hotelDetail.property_type);
      })
            
    }

    if (this.props.countryList) {
      CountryList = [];
      this.props.countryList.map(option => (
        CountryList.push({ value: option.id, label: option.name + ' (' + option.abbreviation + ')' })
      ))
    }
    if(props.hotelAuthReducer && props.hotelAuthReducer.hotelDetails){
      this.setState({enc_hotel_id: props.hotelAuthReducer.hotelDetails.enc_hotel_id})
    }
  }

  currencyChange = () => event => {
    this.props.currencyList
      .filter(List => List.id === event.value)
      .map((List) => {
        this.setState({
          hotelDetail: {
            ...this.state.hotelDetail,
            currency: List
          }
        })
        return ('');
      })

  };

  countryChange = () => event => {
    this.props.countryList
      .filter(List => List.id === event.target.value)
      .map((List) => {
        this.setState({
          hotelDetail: {
            ...this.state.hotelDetail,
            country: List
          }
        })
        return ('');
      })

  };

 

  renderMarkers(map, maps) {
    new maps.Marker({
      position: { lat: parseFloat(this.state.hotelDetail.lat), lng: parseFloat(this.state.hotelDetail.lng) },
      map,
      title: 'Hello World!'
    });
  }

  handleName = (value) => {
    this.setState({
      hotelDetail: {
        ...this.state.hotelDetail,
        name: value
      }
    }
    )
  }
  validateBookingUrl = (value) => {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      booking_ref_url: value,
      is_booking_ref_url: value ? true : false
    };
    this.props.validateBookingUrl(requestParam);
  }
  validateForm = (data) => {
    let error = [];
    if (data) {
      if((this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type)){
        if (this.state.validateUrlSuccess) {
          if (data.booking_ref_url) {
            if (data.booking_ref_url.endsWith("en-gb.html")) {
              this.setState({
                validateUrlMessage: ""
              })
            } else {
              this.setState({
                validateUrlMessage: "* Please enter a valid Booking URL, has to end on en-gb.html"
              })
              error.push('booking_ref_url')
            }
          }
        } else {
          error.push('booking_ref_url')
        }
      }
      if (!this.state.hotelDetail.lat) {
        error.push('lat')
      }

      if (!this.state.hotelDetail.lng) {
        error.push('lng')
      }

      if (!this.state.hotelDetail.currency.id) {
        error.push('currency')
      }

      if (!this.state.hotelDetail.name) {
        error.push('name')
      }

      if (!this.state.hotelDetail.address) {
        error.push('address')
      }
      if (!this.state.hotelDetail.event_radius){
        error.push('event_radius')
      }

      if (!this.state.hotelDetail.property_type && this.state.hotelDetail.property_type != 0) {
        error.push('property_type')
      }
      if (this.state.hotelDetail.business_leisure_ratio == 'null') {        
        error.push('business_leisure_ratio')
      }
      if(!this.state.hotelDetail.event_types) {        
        error.push('event_types')
      }
      if (error) {
        this.setState({
          error: error
        })
        return error
      } else {
        return false
      }
    } else {
      return true;
    }
    
  }

  updateHotelSetting = () => {
    let validateError = this.validateForm(this.state.hotelDetail);
    if(!this.state.hotelDetail.currency.id || !this.state.hotelDetail.name || !this.state.hotelDetail.lng || !this.state.hotelDetail.lat || !this.state.hotelDetail.address ){
      this.setState({
        tab:0
      })
    }
    if(this.state.validateUrlMessage){
      this.setState({
        tab:1
      })
    }
    if (validateError.length > 0) {
      return;
    }
    this.setState({
      loadData: true,
    })
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      lat: this.state.hotelDetail.lat,
      lng: this.state.hotelDetail.lng,
      currency_id: this.state.hotelDetail.currency.id,
      address: this.state.hotelDetail.address,
      name: this.state.hotelDetail.name,
      postcode: this.state.hotelDetail.postcode,
      country_id: this.state.selectedCountry.value,
      notes: this.state.hotelDetail.notes,
      review: this.state.hotelDetail.review,
      property_type: parseInt(this.state.hotelDetail.property_type),
      business_leisure_ratio: this.state.hotelDetail.business_leisure_ratio,
      is_send_email: this.state.is_send_email,
      starts_monday: this.state.starts_monday,
      booking_ref_url: this.state.hotelDetail.booking_ref_url,
      event_types: this.state.hotelDetail.event_types,
      event_radius: this.state.hotelDetail.event_radius,
      prefered_date_format: this.state.prefered_date_format,
      room_apartment_space: this.state.hotelDetail.room_apartment_space
    }



    this.props.updateHotel(requestParam);


  }

  handleSettingChange = (name) => event => {
    if (name == "booking_ref_url") {
      this.validateBookingUrl(event.target.value);
    }
    this.setState({
      hotelDetail: {
        ...this.state.hotelDetail,
        [name]: event.target.value,
      }
    });
  }

  handleMemberSettings = (name) => event => {
    this.setState({
      responsible_account: null,
      reseller: event.target.value,
      memberList: this.state.allMemberList.filter(opt => opt.reseller.id == event.target.value)
    });
  }

  handleSettings = (name) => event => {

    if (event.target.name == "sales_team") {
      this.setState({
        reseller: null,
        memberList: [],
        [name]: event.target.value,
      })
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  }



  handleChange = address => {
    this.setState(prevState => ({
      hotelDetail: {
        ...prevState.hotelDetail,
        address: address
      }
    }))
  };

  handleChangeCountry = () => data => {
    this.setState(prevState => ({
      selectedCountry: data,
    }))
  }

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {

        this.setState(prevState => ({
          hotelDetail: {
            ...prevState.hotelDetail,
            lat: latLng.lat,
            lng: latLng.lng,
            address: address,
          },
          latitude: latLng.lat.toFixed(2),
          longitude: latLng.lng.toFixed(2)
        }), () => {
          this.renderMarkers(this._map.map_, this._map.maps_)
          this._map.map_.setCenter(latLng);
        });

      })
      .catch(error => console.error('Error', error));
  };

  loadMap = () => {
    return (
      <GoogleMapReact
        ref={(map) => this._map = map}
        defaultCenter={this.state.hotelDetail.lat ? { lat: parseFloat(this.state.hotelDetail.lat), lng: parseFloat(this.state.hotelDetail.lng) } : {lat:51.5074, lng:0.1278}}
        defaultZoom={this.state.hotelDetail.lat ? 11 : 1}
        onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
        bootstrapURLKeys={{ key: 'AIzaSyBORMnlZ9I34XUMZVh2h2HTo7C7s1Q8giY' }}
      >
        {this.state.hotelDetail.lat &&
            <AnyReactComponent
              lat={parseFloat(this.state.hotelDetail.lat)}
              lng={parseFloat(this.state.hotelDetail.lng)}
            />
        }
      </GoogleMapReact>
    )
  }
  getEventRadiusKilomiters = () => {
    var radiuskilometers = [];
    for (var i = 20; i <= 100; i=i+5) {
      radiuskilometers[i] = (<MenuItem value={i}>{i}</MenuItem>);
    }
    return radiuskilometers
  }
  renderCurrencyOption = (data) => {
    if (data) {
      return data.sort(function (a, b) {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      }).map(val => {
        return {
          value: val.id,
          label: val.name + "( " + val.symbol + " )"
        }
      })
    }
  }

  renderCurrencyValue = (data) => {
    return {
      value: data.id,
      label: data.name + "( " + data.symbol + " )"
    }
  }

  handleEmailSend = () => {
    this.setState({
      is_send_email: !this.state.is_send_email
    })
  }

  handleStartMonday = () => {
    this.setState({
      starts_monday: !this.state.starts_monday
    })
  }
  handleDateFormat = (e) => {
    this.setState({
      prefered_date_format:e
    })
  }
  handleTabs = (event, newValue) => {
    this.setState({
        tab: newValue
    })
  };

  refreshEvents = () =>{
    let requestParam = { token: this.props.hotelAuthReducer.hotelDetails.token }
    this.props.refreshEvents(requestParam);
    this.setState({
      loadEvents: true
    })
  }

  renderForm = () => {
    if (!this.state.hotelDetail.id) {
      return ('');
    }
    const { classes } = this.props;

    const SelectStyle = {
      control: (base, state) => ({
        ...base,
        height: "40px",
        border: 'none',
        boxShadow: "0 !important",
        width: '100%',
        borderRadius: "4px !important",
        background: "#f2f2f2",
        textAlign: 'left',
        "&:hover": {
            border: 'none',
        },
        "&:focus-within": {
            border: 'none',
        },
        "&:active": {
            border: 'none',
        },
    })
    };

    const SelectStyleError = {
      control: (base, state) => ({
        ...base,
        border: "0",
        height:"44px",
        borderBottom: '2px solid red',
        boxShadow: "0 !important",
        width: '100%',
        borderRadius: '0px !important',
        textAlign: 'left',
        "&:hover": {
          borderBottom: '2px solid red'
        },
        "&:focus-within": {
          borderBottom: '2px solid red'
        },
        "&:active": {
          borderBottom: '2px solid red'
        },
        ">div >div": {
          paddingLeft: "0 !important",
          color: "red !important"
        }
      })
    };
    const { t } = this.props;

    const CountryComponent = (props) => (<React.Fragment>{<FormLabel className={classes.formLable} >{t("Country")}</FormLabel>}<components.Control {...props} /></React.Fragment>);
    const CurrencyComponent = (props) => (<React.Fragment>{<FormLabel className={classes.formLable} >{t("Currency")}</FormLabel>}<components.Control {...props} /></React.Fragment>);
    const RefroomComponent = (props) => (<React.Fragment>{<FormLabel className={classes.formLable} >Reference room</FormLabel>}<components.Control {...props} /></React.Fragment>);
    let is_admin=this.props.userSession.user.is_staff ? true :false;
    return (
      <div>
        <CardContent className={classes.cardContent} >        
          <div >
            <form noValidate autoComplete="off">
              <Grid container spacing={0}>
              <Grid xs={12} sm={6} md={6} item className={classes.gridPadding}>
                {this.state.tab==0 &&
                  <div>
                  <Grid sm={12} item className={classes.gridField}>
                    <FormControl required className={classes.formControl}>
                      <InputLabel shrink={true} htmlFor="name" shrink={true}>{t('Property Name')}</InputLabel>
                      <Input
                        error={this.state.error.includes("name")}
                        id="name"
                        name="name"
                        className={classes.textField}
                        onChange={this.handleSettingChange('name')}
                        value={this.state.hotelDetail.name}
                        placeholder={t('Please enter Property Name')}
                      />
                      {this.state.error.includes("name") ? <span class='error-message'>{t('* Please enter Hotel name')}</span> : ''}
                    </FormControl>
                  </Grid>
                  <Grid sm={12} item className={classes.gridField}>
                    <FormControl required className={classes.formControlSelect} >
                      <Select
                        components={{ Control: CountryComponent }}
                        value={this.state.selectedCountry}
                        error={this.state.error.includes("country")}
                        label={t('Country')}
                        placeholder='Country'
                        styles={this.state.error.includes("country") ? SelectStyleError : SelectStyle}
                        onChange={this.handleChangeCountry()}
                        options={CountryList}
                      />
                      {this.state.error.includes("country") &&
                        <span class='error-message'>{'* Please select country'}</span>
                      }
                      {this.state.error.includes("currency") ? <span class='error-message'>{t('* Please select your currency')}</span> : ''}
                    </FormControl>
                  </Grid>
                  <Grid sm={12} item className={classes.gridField}>
                    <FormControl required className={classes.formControlSelect} >
                      <Select
                        components={{ Control: CurrencyComponent }}
                        value={this.renderCurrencyValue(this.state.hotelDetail.currency)}
                        error={this.state.error.includes("Currency")}
                        label={t("Currency")}
                        placeholder='Currency'
                        styles={this.state.error.includes("Currency") ? SelectStyleError : SelectStyle}
                        onChange={this.currencyChange()}
                        options={this.renderCurrencyOption(this.props.currencyList)}
                      />
                      {this.state.error.includes("currency") ? <span class='error-message'>{t('* Please select your currency')}</span> : ''}
                    </FormControl>
                  </Grid>
                  <Grid sm={12} item className={classes.gridField}>
                    <FormControl className={classes.formControl}>
                      <InputLabel shrink={true} htmlFor="postcode">{t('Post code')}</InputLabel>
                      <Input
                        error={this.state.error.includes("postcode")}
                        id="postcode"
                        name="postcode"
                        className={classes.textField}
                        onChange={this.handleSettingChange('postcode')}
                        value={this.state.hotelDetail.postcode}
                        placeholder={t('Please enter Post Code')}
                      />
                      {this.state.error.includes("postcode") ? <span class='error-message'>{t('* Please enter Post code')}</span> : ''}
                    </FormControl>
                  </Grid>
                  <Grid sm={12} item className={classes.gridField}>
                    <FormControl required className={classes.formControl}>
                      <PlacesAutocomplete
                        value={this.state.hotelDetail.address}
                        onChange={this.handleChange}
                        onSelect={this.handleSelect}
                        id="address"
                        name="address"
                        multiline
                        className={classes.textField}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <React.Fragment>
                            <InputLabel shrink={true} htmlFor="search">{t('Address')}</InputLabel>
                            <Input
                              {...getInputProps({
                                className: 'location-search-input',
                              })}
                              className={classes.textField}
                              error={this.state.error.includes("address")}
                              endAdornment={<CustomTooltip title="" description={t("Your address is vital for pricing. We will use it to identify your local competitors and to check for up to 15 different event types in your area.")}></CustomTooltip>}
                            />
                            {this.state.error.includes("address") ? <span class='error-message'>{'* Please enter Address'}</span> : ''}
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <MenuItem value={''}>{suggestion.description}</MenuItem>
                                  </div>
                                );
                              })}
                            </div>
                          </React.Fragment>
                        )}
                      </PlacesAutocomplete>
                    </FormControl>

                  </Grid>
                  
                  {/* <Grid sm={12} item className={classes.gridField}>
                    <FormControl required className={[classes.formControl]}>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12} className={classes.gridField}>
                    <FormControl required className={classes.formControl} >
                      <TextField
                        id="standard-select-currency"
                        select
                        label={t('Start Calendar From')}
                        value={this.state.starts_monday}
                        onChange={() => this.handleStartMonday()}
                        className={classes.textFieldSelect}
                        InputLabelProps={{ shrink: true }}
                      >
                        <MenuItem value='false'>{t('Sunday')}</MenuItem>
                        <MenuItem value='true'>{t('Monday')}</MenuItem>
                      </TextField>
                    </FormControl>

                  </Grid>
                 
                    <Grid item xs={12} className={classes.gridField}>
                    <FormControl required className={classes.formControl} >
                      <TextField
                        id="standard-select-currency"
                        select
                        label={t('Preferred Date Format')}
                        value={this.state.prefered_date_format}
                        onChange={(event) => this.handleDateFormat(event.target.value)}
                        className={classes.textFieldSelect}
                        InputLabelProps={{ shrink: true }}
                      >
                        <MenuItem value='DD/MM/YYYY, hh:mm A'>DD/MM/YYYY, hh:mm A</MenuItem>
                        <MenuItem value='MM/DD/YYYY, hh:mm A'>MM/DD/YYYY, hh:mm A</MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>

                    <Grid item xs={12} className={classes.gridField}>
                      <FormControl required className={classes.formControl}>
                        <TextField
                          id="room_apartment_space"
                          select
                          label={t('Type Of Units')}
                          value={this.state.hotelDetail.room_apartment_space}
                          onChange={this.handleSettingChange('room_apartment_space')}
                          className={classes.textFieldSelect}
                          InputLabelProps={{ shrink: true }}
                        >
                          <MenuItem value='1'>{t('Room')}</MenuItem>
                          <MenuItem value='2'>{t('Apartment')}</MenuItem>
                        </TextField>
                      </FormControl>
                    </Grid>
                  {(this.props.userSession.user.is_staff) &&
                  <Grid sm={12} item className={classes.gridField}>
                    
                      <FormControl className={classes.formControl} >
                        <InputLabel shrink={true} htmlFor="lat">{t('Timezone')}</InputLabel>
                        <Input
                          readOnly
                          className={classes.textField}
                          value={this.state.hotelDetail.timezone}
                          placeholder={t('Please enter Timezone')}
                        />
                      </FormControl>
                    
                  </Grid>
                  }

                  {(this.props.userSession.user.is_staff) &&
                  <Grid sm={12} item className={classes.gridField}>
                    <FormControl required className={classes.formControl} >
                      <InputLabel shrink={true} htmlFor="lat">{t('Latitude')}</InputLabel>
                      <Input
                        readOnly
                        type="number"
                        className={classes.textField}
                        error={this.state.error.includes("lat")}
                        id="lat"
                        name="lat"
                        onChange={this.handleSettingChange('lat')}
                        value={this.state.latitude}
                        placeholder={t('Please enter Latitude')}
                      />
                      {this.state.error.includes("lat") ? <span class='error-message'>{'* Please enter Latitude'}</span> : ''}
                    </FormControl>
                  </Grid>
                  }
                  {(this.props.userSession.user.is_staff) &&
                  <Grid sm={12} item className={classes.gridField}>
                    <FormControl required className={classes.formControl} >
                      <InputLabel shrink={true} htmlFor="lng">{t('Longitude')}</InputLabel>
                      <Input
                        readOnly
                        type="number"
                        error={this.state.error.includes("lng")}
                        id="lat"
                        name="lng"
                        className={classes.textField}
                        onChange={this.handleSettingChange('lng')}
                        value={this.state.longitude}
                        placeholder={t('Please enter Longitude')}
                      />
                      {this.state.error.includes("lng") ? <span class='error-message'>{'* Please enter Longitude'}</span> : ''}
                    </FormControl>
                  </Grid>
                  }
                  {(this.props.userSession.user.is_staff) &&
                  <Grid sm={12} item className={classes.gridField}>
                    <FormControl required className={classes.formControl} >
                      <InputLabel shrink={true} htmlFor="lng">{t('Pms Id')}</InputLabel>
                      <Input
                        disabled
                        type="number"
                        id="pmsid"
                        name="pmsid"
                        className={classes.textField}
                        value={this.state.hotelDetail.pms}
                        placeholder={t('Please enter Pms Id')}
                      />
                    </FormControl>
                  </Grid>
                  }
                  {(this.props.userSession.user.is_staff) &&
                  <Grid sm={12} item className={classes.gridField}>
                    <FormControl required className={classes.formControl} >
                      <InputLabel shrink={true} htmlFor="lng">{t('Hotel Id')}</InputLabel>
                      <TextField
                        rows={2}
                        multiline
                        disabled
                        id="hotel_id"
                        name="hotel_id"
                        className={classes.textField}
                        value={this.state.enc_hotel_id}
                        placeholder={t('Please enter Hotel Id')}
                      />
                    </FormControl>
                  </Grid>
                  }
                  </div>
              }    
                         
                {/* part 2 */}

                {this.state.tab==1 &&
                 <div>
                   <Grid item xs={12} className={classes.gridField}>
                    <FormControl required className={classes.formControl} >
                      <InputLabel shrink={true} htmlFor="name">{t('Property Type')}</InputLabel>
                      <Select2                      
                        error={this.state.error.includes("property_type")}
                        id="standard-select-currency"                                          
                        value={this.state.hotelDetail.property_type}
                        onChange={this.handleSettingChange('property_type')}
                        className={classes.textFieldSelect}
                        margin="normal"
                        displayEmpty
                      >
                        <MenuItem disabled value={null}>{t(' Select Property Type')}</MenuItem>
                        <MenuItem value={0}>{t("City")}</MenuItem>
                        <MenuItem value={1}>{t("Countryside")}</MenuItem>
                        <MenuItem value={2}>{t("Ski")}</MenuItem>
                        <MenuItem value={3}>{t("Seaside")}</MenuItem>
                        <MenuItem value={4}>{t("Other")}</MenuItem>
                      </Select2>
                      {this.state.error.includes("property_type") ? <span class='error-message'>{`* Please select ${t(' Select Property Type')} `}</span> : ''}
                    </FormControl>

                  </Grid>
                  {is_admin &&
                  <Grid item xs={12} className={classes.gridField}>
                    <FormControl required className={classes.formControl} >
                      <InputLabel shrink={true} htmlFor="name">{t('Business / Leisure Ratio')}</InputLabel>
                      <Select2
                        error={this.state.error.includes("business_leisure_ratio")}
                        id="standard-select-currency"                                                
                        value={this.state.hotelDetail.business_leisure_ratio}
                        onChange={this.handleSettingChange('business_leisure_ratio')}
                        className={classes.textFieldSelect}
                        margin="normal"
                        displayEmpty                        
                      >
                        <MenuItem disabled value={'null'}>{t('Select Business / Leisure Ratio')}</MenuItem>
                        <MenuItem value={0}>{t("All Leisure")}</MenuItem>
                        <MenuItem value={0.25}>{t("Mostly Leisure")}</MenuItem>
                        <MenuItem value={0.5}>{t("Mixed")}</MenuItem>
                        <MenuItem value={0.75}>{t("Mostly Business")}</MenuItem>
                        <MenuItem value={1}>{t("All Business")}</MenuItem>
                      </Select2>
                       {this.state.error.includes("business_leisure_ratio") ? <span class='error-message'>{`* Please select ${t('Business / Leisure Ratio')} `}</span> : ''}
                    </FormControl>
                  </Grid>
                  }
                  <Grid sm={12} item className={classes.gridField}>
                    <FormControl required className={classes.formControl} >
                      <InputLabel shrink={true} htmlFor="name">{t('Event Type')}</InputLabel>
                      <Select2
                        id="standard-select-currency"
                        error={this.state.error.includes("event_types")}                       
                        value={this.state.hotelDetail.event_types}
                        onChange={this.handleSettingChange('event_types')}
                        className={classes.textFieldSelect}   
                        margin="normal"
                        displayEmpty                      
                      >
                        <MenuItem disabled value={''}>{t('Select Event Type')}</MenuItem>
                        <MenuItem value={1}>{t("Resort/Ski")}</MenuItem>
                        <MenuItem value={2}>{t("City Hotels")}</MenuItem>
                      </Select2>
                      {this.state.error.includes("event_types") ? <span class='error-message'>{`* Please select ${t('Event Type')} `}</span> : ''}
                    </FormControl>


                  </Grid>
                  {(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) &&
                    <Grid sm={12} item className={classes.gridField}>                  
                      <Button className={classes.btnFetch} onClick={this.refreshEvents} disabled={this.state.loadEvents} variant="outlined" color="primary" >
                        {t('Fetch Latest Event Data')} {this.state.loadEvents && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                      </Button>                  
                    </Grid>
                  }
                  {(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) &&
                  <Grid sm={12} item className={classes.gridField}>
                    <FormControl className={classes.formControl} >
                      <InputLabel shrink={true} htmlFor="lng">{t("Booking Url")}</InputLabel>
                      <Input
                        error={this.state.error.includes("booking_ref_url")}
                        name="booking"
                        className={classes.textField}
                        onChange={this.handleSettingChange('booking_ref_url')}
                        value={this.state.hotelDetail.booking_ref_url}
                        placeholder={t('Please enter Booking Url')}
                      />
                      {this.state.error.includes("booking_ref_url") ? <span class='error-message'>{this.state.validateUrlMessage}</span> : ''}
                    </FormControl>
                  </Grid>
                  }
                  <Grid sm={12} item className={classes.gridField}>
                    <FormControl className={classes.formControl} >
                      {/* <InputLabel shrink={true} htmlFor="lng">{t("Event Radius in Kilometers")}</InputLabel> */}
                      <TextField
                        id="standard-select-currency"
                        select
                        label={t('Event Radius in Kilometers')}
                        value={this.state.hotelDetail.event_radius}
                        onChange={this.handleSettingChange('event_radius')}
                        className={classes.textFieldSelect}
                        InputLabelProps={{ shrink: true }}
                      >
                        <MenuItem value={''}>Select</MenuItem>
                        {this.getEventRadiusKilomiters()}
                      </TextField>
                      {this.state.error.includes("event_radius") ? <span class='error-message'>{'* Please select Event Radius Kilometers'}</span> : ''}
                    </FormControl>
                  </Grid>
                  
                  </div>
                
                }
                <Button className={classes.btnSave} disabled={this.state.loadData} onClick={this.updateHotelSetting} variant="contained" color="primary" >
                  {t('Save')} {this.state.loadData && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                </Button>

              </Grid>
              <Grid xs={12} sm={6} md={6} item className={classes.gridPadding2}>
                <div className={classes.mapDiv}>
                  {this.loadMap()}
                </div>
              </Grid>
              </Grid>
              
            </form>
          </div>
        </CardContent>
        
      </div >
    )
  }

  render() {
    const { t } = this.props;
    const { classes } = this.props;
    return (
      <>
        <HeaderCard >
          <Grid container>
            <Grid item xs={12} >
              <div className={classes.divStyle} >
                <Typography variant="h5" className={classes.fontstyle} >
                  {t("Property")}
                </Typography>
                <Grid item xs={12} >
                  <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="on"
                    value={this.state.tab}
                    onChange={this.handleTabs}
                    className={classes.tabView}
                  >
                    <Tab label={t("Name and Location")} value={0} />
                    <Tab label={t("Property Type")} value={1} />
                  </Tabs>
            </Grid>
              </div>
            </Grid>
          </Grid>
        </HeaderCard>
      <Card>
        
        {this.renderForm()}
      
      </Card >
      </>
    );
  }
}

HotelSetting.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
  return {
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    userSession: state.sessionReducer,
    hotelDetailReducer: state.hotelSettingReducer,
    currencyList: state.currencyReducer.currencyList,
    hotelAuthReducer: state.hotelAuthReducer,
    roomList: state.roomListReducer,
    countryList: state.countryReducer.countryList,
    commonReducer: state.commonReducer,
    resellerReducer: state.resellerReducer,
    adminUserReducer: state.adminUserReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getRoomList: (request) => { dispatch(actions.getRoomList(request)) },
    getHotelDetail: (request) => { dispatch(actions.getHotelDetail(request)) },
    getCurrency: (request) => { dispatch(actions.currency(request)) },
    updateHotel: (request) => { dispatch(actions.updateHotelSetting(request)) },
    refreshEvents: (request) => { dispatch(actions.refreshEvents(request)) },
    AddRefRoom: (request) => { dispatch(actions.AddRefRoom(request)) },
    getCountry: (request) => { dispatch(actions.getCountry(request)) },
    getResellerAll: (request) => { dispatch(actions.getResellerAll(request)) },
    getAdminSales: (request) => { dispatch(actions.getAdminSales(request)) },
    getResellerMemberAll: (request) => { dispatch(actions.getResellerMemberAll(request)) },
    validateBookingUrl: (request) => { dispatch(actions.validateBookingUrl(request)) }
  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(HotelSetting));
