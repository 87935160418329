import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import occupancyCss from './css/targetOccupancyCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import { withNamespaces } from 'react-i18next';
import * as actions from '../../store/actions/index';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import { Icon, Tooltip } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { quickSetupTheme } from '../muiTheme';
import DialogContentText from '@material-ui/core/DialogContentText';
import axios from 'axios';
import moment from 'moment';
import Server from '../../config/server';
import Modal from './modal/modal';
import Checkbox from '@material-ui/core/Checkbox';

const styles = occupancyCss;
var ws = {};
const pmsList = ["Ibelsa", "Beds24", "RMS", "HighLevelSoftware", "Acomos", "Agilysys", "Amadeus Property Management", "Apaleo pms", "Autoclerk (PMS)", "Avon Data (Hotel Executive PMS)", "AxisRooms (Property Management System)", "Base7booking by Trivago", "Bay Lakes Information Systems", "Benson PMS", "Bnovo PMS", "Booking Ninjas", "Cenium Hospitality", "Cirrus PMS", "Clarity Hospitality Software", "Clock PMS+", "Cloudbeds PMS", "Cloudinn", "CMS Hospitality (GuestCentrix)", "ComSyS Software", "Counter", "Elina PMS", "eviivo", "Execu-tech PMS", "EZee FrontDesk", "FCS Computer Systems (CosmoPMS)", "FOSSE", "Frontdesk Anywhere", "Gearco - True Extended Stay", "Guestline (Rezlynx PMS)", "Hetras", "Hilton OnQ", "Hop PMS", "Host Hotel Systems", "HotelKey PMS", "Hotellinx", "Hotello PMS (by Mingus)", "Hotelogix", "HotelTime PMS", "Hotsoft Inc.", "Ibs plc", "Infor HMS", "InnRoad", "Jonas Chorum", "Lightspeed Galaxy", "Lodging System", "Lodgit Desk Hotelsoftware", "Maestro PMS (Northwind)", "ManCloud", "Mayven", "Mews", "Misterbooking (PMS)", "MyCloud Hospitality PMS", "NewBook (PMS)", "NiteVision by MSI", "Octorate (Easy Planning)", "OneHotel", "OpenHotel", "OPERA Property Management Suite", "Optima PMS (by Silverbite)", "Preno", "Prologic First", "Protel (PMS)", "PT. Supranusa Sindata", "Redforts", "Resort Data", "Rezovation", "RMS Hospitality Cloud", "Roombler", "RoomKey PMS", "RoomMaster Anywhere (by innQuest)", "RoomRaccoon PMS", "Roomsy", "Sabee App", "Sirvoy", "Skytouch", "Skyware Systems", "Springer Miller", "StayNTouch", "Tesipro", "Thais-Soft", "The Alpha", "ThinkReservations", "WebRezPro", "Welcome Systems", "Winhotel"].sort();
const channels = ["AdventureRes", "Agentc Media", "Amadeus – Digital Media", "AxisRooms (Channel Manager)", "Base7booking (Channel Manager)", "Booking Engine", "CapCorn", "Channel Connect (Sabre Synxis)", "ChannelRush Channel manager", "Cloudbeds Channel Manager (myallocator)", "CRS AvailProCom", "CRS FastBooking.com", "CRS Globekey (Oceanic)", "Cubilis", "Cultuzz", "D-EDGE - Smart Channel Manager", "Dirs 21", "DIRS21", "Djubo (Instaconnekt)", "Easybooking Channel Manager", "E-GDS BookingSuite", "ERevMax (RateTiger)", "Escapio", "Evolution Distribution", "EZee Centrix", "Feratel Deskline 3.0", "GnaHS Channel Manager", "Hotel Link Solutions (Channel Manager)", "HotelNetSolutions Channel Manager", "HotelRunner", "Hotel-Spider", "InnRoad Channel Manager", "Intobis", "Levart Channel Control", "Lodgify", "MAD Connect", "MappingMaster Channel Manager", "MaxiMojo", "Myallocator", "NetAffinity", "NetHotels ReServer", "Netroomz", "NightsBridge", "Octorate (Channel Manager)", "Onlineres", "OptiRez", "OrbisMat", "Othyssia", "Parity Rate", "Prestige Hospitality Solutions", "Primal-res OnLine Reservation Platform", "Profitroom Channel Manager", "RateGain (RezGain Channel Manager)", "RateTiger", "RDX Channel Manager (Hoteliers.com)", "Reseliva Booking Engine and Channel Manager System", "Reservento", "ReservIT", "ResMaster", "RoomCloud Channel Manager", "SabeeApp", "Simple Booking (Channel Manager)", "SiteMinder (The Channel Manager)", "SmartHOTEL (Channel Manager)", "STAAH Channel Manager", "Stardekk (Cubilis)", "Synchro", "SynXis", "The Homelike", "Tiscover", "TravelLine (Channel Manager)", "Vertical Booking (Synchro Channel Manager)", "WebHotelier Channel Manager", "WebsLINE CMS", "WuBook (Channel Manager)", "X-AdapT Gmbh", "YieldPlanet (Channel Manager)"].sort();

class QuickSetup extends React.Component {

    constructor(props) {
        super(props);
        // create a ref to store the textInput DOM element
        this.textInput = React.createRef();
        this.textInputcm = React.createRef();
    }

    state = {
        activeStep: 0,
        activeSubStep: 0,
        pmsData: [],
        pmsRates: [],
        property: null,
        guestLeisure: null,
        numberOfRooms: null,
        pmsRoom: null,
        pmsRate: null,
        baseRate: null,
        minPriceCheckbox: "1",
        maxPriceCheckbox: "1",
        minPrice: null,
        maxPrice: null,
        seasonalPricing: null,
        currentPricing: null,
        variableCost: null,
        priceElastic: null,
        file: [],
        error: [],
        laoder: false,
        currencySymbol: "",
        yourGoal: null,
        currentPricing: null,
        completePercentage: 0,
        completedCompetitor: "",
        totalCompetitor: "",
        oldLoaded: false,
        bookingUrl: "",
        validateUrlSuccess: false,
        validateUrlMessage: "",
        warningPopup: false,
        agree: false,
        userEmail: "",
        userFirstname: "",
        userLastname: "",
        pms_name: "",
        channel_manager: "",
        room_apartment_space_name: "",
        hotelName: "",
        address: "",
        propertyId:""
    };

    componentWillUnmount() {
        if (ws && ws.readyState) {
            ws.close();
        }
    }

    componentDidMount() {
        //         axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
        //         axios.post(Server.API + 'hotel/quick-setup-data/create/', { setup_data: "" })
        // return "";

        this.setState({
            userEmail: this.props.hotelAuthReducer.hotelDetails.hotel.email,
            userFirstname: this.props.hotelAuthReducer.hotelDetails.client.first_name,
            userLastname: this.props.hotelAuthReducer.hotelDetails.client.last_name,
            hotelName: this.props.hotelAuthReducer.hotelDetails.hotel.name,
            address: this.props.hotelAuthReducer.hotelDetails.hotel.address
        })
        var items = document.getElementById("sideBar");
        if (items) {
            items.style.display = "none";
        }

        var itemsnav = document.getElementsByClassName("mainConentBody");
        if (itemsnav[0]) {
            itemsnav[0].style.marginLeft = "0px";
        }

        axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
        axios.get(Server.API + 'hotel/client/detail/')
            .then((response) => {
                // debugger
                this.setState({
                    currencySymbol: this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol,
                    bookingUrl: response.data.booking_ref_url
                })

                axios.post(Server.API + 'hotel/check/booking_ref_url/', { booking_ref_url: response.data.booking_ref_url })
                    .then((response) => {
                        if (!response.data.success) {
                            this.setState({
                                warningPopup: true,
                                isBookingUrl: true,
                                blockNext: true
                            })
                        }
                    })

                axios.get(Server.API + 'hotel/quick-setup-data/get/', { setup_data: this.state })
                    .then((resp) => {
                        let requestParam = { token: this.props.hotelAuthReducer.hotelDetails.token }
                        this.props.getPmsList(requestParam);
                        if (resp.data && resp.data.setup_data && !resp.data.is_finished) {
                            setTimeout(() => {
                                let oldData = JSON.parse(resp.data.setup_data)

                                if (oldData.pmsRoom) {
                                    let pmsData = this.state.pmsData.filter(val => val.id == oldData.pmsRoom)[0];
                                    if (pmsData) {
                                        oldData.pmsRates = pmsData.rates;
                                    }
                                }

                                this.setState({
                                    ...oldData,
                                    oldLoaded: false,
                                    bookingUrl: response.data.booking_ref_url,
                                    validateUrlSuccess: this.state.validateUrlSuccess ? true : false
                                }, () => this.setSubStep(this.state.activeSubStep, true))
                            }, 3000);
                        } else {
                            this.setState({
                                oldLoaded: true,
                                bookingUrl: response.data.booking_ref_url,
                            })
                        }

                        if (response.data.booking_ref_url) {
                            this.validateBookingUrl(response.data.booking_ref_url)
                        } else {
                            this.setState({
                                validateUrlSuccess: false,
                                validateUrlMessage: ""
                            })
                        }

                    })

            })
    }

    componentWillUnmount() {
        var items = document.getElementById("sideBar");
        if (items) {
            items.style.display = "block";
        }

        var itemsnav = document.getElementsByClassName("mainConentBody");
        if (itemsnav[0]) {
            itemsnav[0].style.marginLeft = "240px";
        }

    }

    componentWillReceiveProps(props) {


        if (props.pmsList && props.pmsList.mapped_data) {
            let pmsData = [];
            let RefRoom = this.props.roomList.roomList.filter(val => val.is_reference_room)[0];
            props.pmsList.mapped_data.filter(val => (!val.connected_room || val.connected_room.id == RefRoom.id)).map(val => {
                pmsData.push(val)
            })
            this.setState({
                pmsData
            })
        }

        if (this.props.priceSettingReducer.priceSetting) {
            if (this.state.numberOfRooms == null || this.state.numberOfRooms != props.priceSettingReducer.priceSetting.hotel.number_of_rooms)
                this.setState({
                    numberOfRooms: props.priceSettingReducer.priceSetting.hotel.number_of_rooms
                })
        }
        if (props.hotelAuthReducer.hotelDetails.hotel) {
            this.setState({
                room_apartment_space_name: props.hotelAuthReducer.hotelDetails.hotel.room_apartment_space_name
            })
        }
    }

    toggleOtherPms = () => {
        this.setState({
            isOtherPms: !this.state.isOtherPms
        }, () => this.textInput.current.focus())
    }

    toggleOtherCm = () => {
        this.setState({
            isOtherCm: !this.state.isOtherCm
        }, () => this.textInputcm.current.focus())
    }

    validateBookingUrl = (value) => {
        let requestParam = {
            token: this.props.hotelAuthReducer.hotelDetails.token,
            booking_ref_url: value
        };
        this.props.validateBookingUrl(requestParam);
    }

    toggleWarningPopup = () => {
        this.setState({
            warningPopup: !this.state.warningPopup,
            isBookingUrl: false
        })
    }

    handleChange = (name) => event => {

        if (name == "bookingUrl") {
            if (!event.target.value.endsWith("en-gb.html")) {
                this.setState({
                    error: ["bookingUrl"]
                })
            } else {
                this.setState({
                    error: []
                })
                this.validateBookingUrl(event.target.value)
            }


        }

        if (name == "baseRate" && event.target.value) {

            let baseRate = event.target.value;
            let minPrice = Math.round(baseRate * 0.65)
            let maxPrice = Math.round(baseRate * 1.8)

            this.setState({
                minPrice,
                maxPrice,
                minPriceCheckbox: "1",
                maxPriceCheckbox: "1",
            })

        }

        if (name == "minPriceCheckbox") {
            let baseRate = this.state.baseRate;
            let value = event.target.value;
            let minPrice = 0;

            if (value == "0") {
                minPrice = Math.abs(baseRate * 0.5)
            } else if (value == "1") {
                minPrice = Math.abs(baseRate * 0.65)
            } else if (value == "2") {
                minPrice = Math.abs(baseRate * 0.8)
            } else {
                minPrice = value
            }

            this.setState({
                minPrice: Math.round(minPrice)
            })
        }

        if (name == "minPrice") {
            this.setState({
                minPriceCheckbox: "3"
            })
        }

        if (name == "maxPriceCheckbox") {
            let baseRate = this.state.baseRate;
            let value = event.target.value;
            let maxPrice = 0;

            if (value == "0") {
                maxPrice = Math.abs(baseRate * 2)
            } else if (value == "1") {
                maxPrice = Math.abs(baseRate * 1.8)
            } else if (value == "2") {
                maxPrice = Math.abs(baseRate * 1.4)
            }

            this.setState({
                maxPrice: Math.round(maxPrice)
            })
        }

        if (name == "maxPrice") {
            this.setState({
                maxPriceCheckbox: "3"
            })
        }

        this.setState({
            [name]: event.target.value
        })
    }

    redirectToBooking = () => {
        this.setState({
            activeStep: 1,
            activeSubStep: 5,
            error: [],
            laoder: false,
            oldLoaded: true
        })
    }

    setSubStep = (activeSubStep, callApi) => {
        let error = [];
        let activeStep = 0;
        let property_type = 0;
        let business_leisure_ratio = 0;



        if (activeSubStep == 1) {
            var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            if (!this.state.userEmail || !this.state.userFirstname || !this.state.userLastname || reg.test(this.state.userEmail) == false) {
                var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

                if (!this.state.userEmail || reg.test(this.state.userEmail) == false) {
                    error.push("userEmail")
                }
                if (!this.state.userFirstname) {
                    error.push("userFirstname")
                }
                if (!this.state.userLastname) {
                    error.push("userLastname")
                }
            }
        }

        if (activeSubStep == 2) {

            if (!this.state.hotelName) {
                error.push("hotelName")
            }
            if (!this.state.address) {
                error.push("address")
            }
        }

        if (activeSubStep == 3) {
            if (!this.state.property) {
                error.push("property")
            } else {
                let event_types = 1;
                if (this.state.property == "0") {
                    event_types = 2
                }
                let requestParam = {
                    event_types: event_types,
                    token: this.props.hotelAuthReducer.hotelDetails.token,
                    hideNotification: true,
                    name: this.state.hotelName,
                    address: this.state.address,
                }
                this.props.updateHotel(requestParam);
            }
        }

        if (activeSubStep == 4) {

            if (this.state.property == "0") {
                property_type = 0
            } else if (this.state.property == "1") {
                property_type = 1
            } else if (this.state.property == "2") {
                property_type = 2
            } else if (this.state.property == "3") {
                property_type = 3
            } else if (this.state.property == "4") {
                property_type = 4
            } else {
                error.push("property")
            }

            if (this.state.guestLeisure == "0") {
                business_leisure_ratio = 0
            } else if (this.state.guestLeisure == "1") {
                business_leisure_ratio = 0.25
            } else if (this.state.guestLeisure == "2") {
                business_leisure_ratio = 0.5
            } else if (this.state.guestLeisure == "3") {
                business_leisure_ratio = 0.75
            } else if (this.state.guestLeisure == "4") {
                business_leisure_ratio = 1
            } else {
                error.push("guestLeisure")
            }

            if (error.length == 0) {
                let request = {
                    token: this.props.hotelAuthReducer.hotelDetails.token,
                    property_type: property_type,
                    business_leisure_ratio: business_leisure_ratio
                }
                this.props.quickSetupStepOne(request)
            }
        }


        if (activeSubStep == 6) {
            // dispatch(ontPmsListStart())
            // axios.defaults.headers.common['Authorization'] = "Token " + request.Authtoken;
            // axios.get(Server.API + 'hotel/pms/list/')
            //     .then(function (response) {
            //         dispatch(ontPmsListSucceed(response.data))
            //     })
            //     .catch(function (error) {
            //         dispatch(ontPmsListFailed(error))
            //     });
        }

        if (activeSubStep == 7) {
            if (!this.state.pmsRoom) {
                error.push("pmsRoom")
            } else if (callApi) {
                let pmsRates = this.state.pmsData.filter(val => val.id == this.state.pmsRoom)[0];

                if (pmsRates && pmsRates.length == 0) {
                    this.setState({
                        pmsRates: []
                    })
                    return null;
                }

                this.setState({
                    laoder: true
                })
                let RefRoom = this.props.roomList.roomList.filter(val => val.is_reference_room)[0];
                let requestParams = {
                    // name: RefRoom.name,
                    pms_price_applicable_occupancy_id: RefRoom.pms_price_applicable_occupancy_id,
                    pms_rate_id: this.state.pmsRate,
                    pms_room_id: this.state.pmsRoom,
                    discount_per_person: RefRoom.discount_per_person,
                    use_prices_from: RefRoom.use_prices_from,
                    default_occupancy: RefRoom.default_occupancy,
                }
                axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                axios.patch(Server.API + 'hotel/room/update/' + RefRoom.id + '/', requestParams)
                    .then((response) => {

                        let pmsDetails = this.state.pmsData.filter(val => val.id == this.state.pmsRoom)[0]

                        let request = {
                            token: this.props.hotelAuthReducer.hotelDetails.token,
                            selected_pms_ref_room_id: pmsDetails.id,
                            selected_pms_ref_room_name: pmsDetails.name,
                        }

                        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
                        axios.post(Server.API + 'hotel/pms/room/map/', request)
                            .then((response) => {
                                this.props.getPmsList(request);
                                this.setState({
                                    activeStep,
                                    activeSubStep,
                                    error: [],
                                    laoder: false,
                                    oldLoaded: true,
                                    completePercentage: 0
                                }, () => {
                                    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                                    axios.post(Server.API + 'hotel/quick-setup-data/create/', { setup_data: JSON.stringify(this.state) })
                                })
                            })
                            .catch((error) => {

                                this.setState({
                                    activeStep,
                                    activeSubStep: 5,
                                    error: [],
                                    laoder: false,
                                    oldLoaded: true,
                                }, () => {
                                    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                                    axios.post(Server.API + 'hotel/quick-setup-data/create/', { setup_data: JSON.stringify(this.state) })
                                })
                                if (this.props.userSession.user.is_staff) {
                                    let requestData = {
                                        msg: "Error While Auto Setup",
                                        is_success: false,
                                    }
                                    this.props.putNotify(requestData);
                                }
                                this.toggleWarningPopup()

                            });
                    }).catch((error) => {

                        this.setState({
                            activeStep,
                            activeSubStep: 5,
                            error: [],
                            laoder: false,
                            oldLoaded: true,
                        }, () => {
                            axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                            axios.post(Server.API + 'hotel/quick-setup-data/create/', { setup_data: JSON.stringify(this.state) })
                        })
                        if (this.props.userSession.user.is_staff) {
                            let requestData = {
                                msg: `Error While Updating ${this.state.room_apartment_space_name}`,
                                is_success: false,
                            }
                            this.props.putNotify(requestData);
                        }
                        this.toggleWarningPopup()
                    });
            } else {
                this.setState({
                    activeStep,
                    activeSubStep,
                    laoder: false,
                    oldLoaded: true,
                }, () => {
                    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                    axios.post(Server.API + 'hotel/quick-setup-data/create/', { setup_data: JSON.stringify(this.state) })
                })
            }
        }

        if (activeSubStep == 8) {
            if (!this.state.pmsRate) {
                error.push("pmsRate")
            } else if (callApi) {
                this.setState({
                    laoder: true
                })
                let RefRoom = this.props.roomList.roomList.filter(val => val.is_reference_room)[0];
                let requestParams = {
                    // name: RefRoom.name,
                    pms_price_applicable_occupancy_id: RefRoom.pms_price_applicable_occupancy_id,
                    pms_rate_id: this.state.pmsRate,
                    pms_room_id: this.state.pmsRoom,
                    discount_per_person: RefRoom.discount_per_person,
                    use_prices_from: RefRoom.use_prices_from,
                    default_occupancy: RefRoom.default_occupancy,
                }
                let inventoryData = {
                    start_date: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'),
                    end_date: moment().add('363', 'd').format('YYYY-MM-DD'),
                    get_pms_data: true
                }
                axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                axios.patch(Server.API + 'hotel/room/update/' + RefRoom.id + '/', requestParams)
                    .then((response) => {

                        axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                        axios.post(Server.API + 'pricingalgorithm/get-inventory-price/', inventoryData)
                            .then((response) => {

                                axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                                axios.post(Server.API + 'hotel/calculate_no_of_rooms/', { is_calculate_no_of_rooms: true, is_from_quick_setup: true })
                                    .then((response) => {
                                        if (response.data.success) {
                                            this.setState({
                                                activeStep,
                                                activeSubStep,
                                                error: [],
                                                laoder: false,
                                                oldLoaded: true,
                                                completePercentage: 0
                                            }, () => {
                                                axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                                                axios.post(Server.API + 'hotel/quick-setup-data/create/', { setup_data: JSON.stringify(this.state) })
                                            })
                                        } else {
                                            this.setState({
                                                activeStep,
                                                activeSubStep: 6,
                                                error: [],
                                                laoder: false,
                                                oldLoaded: true,
                                            }, () => {
                                                axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                                                axios.post(Server.API + 'hotel/quick-setup-data/create/', { setup_data: JSON.stringify(this.state) })
                                            })
                                            if (this.props.userSession.user.is_staff) {
                                                let requestData = {
                                                    msg: response.data.message,
                                                    is_success: false,
                                                }
                                                this.props.putNotify(requestData);
                                            }
                                            this.toggleWarningPopup()
                                        }
                                    }).catch((error) => {

                                        this.setState({
                                            activeStep,
                                            activeSubStep: 6,
                                            error: [],
                                            laoder: false,
                                            oldLoaded: true,
                                        }, () => {
                                            axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                                            axios.post(Server.API + 'hotel/quick-setup-data/create/', { setup_data: JSON.stringify(this.state) })
                                        })
                                        if (this.props.userSession.user.is_staff) {
                                            let requestData = {
                                                msg: `Error While Calculating Number of ${this.state.room_apartment_space_name}s`,
                                                is_success: false,
                                            }
                                            this.props.putNotify(requestData);
                                        }
                                        this.toggleWarningPopup()
                                    })

                            }).catch((error) => {
                                this.setState({
                                    activeStep,
                                    activeSubStep: 6,
                                    error: [],
                                    laoder: false,
                                    oldLoaded: true,
                                }, () => {
                                    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                                    axios.post(Server.API + 'hotel/quick-setup-data/create/', { setup_data: JSON.stringify(this.state) })
                                })
                                if (this.props.userSession.user.is_staff) {
                                    let requestData = {
                                        msg: `Error While Updating ${this.state.room_apartment_space_name}`,
                                        is_success: false,
                                    }
                                    this.props.putNotify(requestData);
                                }
                                this.toggleWarningPopup()
                            })
                    }).catch((error) => {

                        this.setState({
                            activeStep,
                            activeSubStep: 6,
                            error: [],
                            laoder: false,
                            oldLoaded: true,
                        }, () => {
                            axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                            axios.post(Server.API + 'hotel/quick-setup-data/create/', { setup_data: JSON.stringify(this.state) })
                        })
                        if (this.props.userSession.user.is_staff) {
                            let requestData = {
                                msg: `Error While Updating ${this.state.room_apartment_space_name}`,
                                is_success: false,
                            }
                            this.props.putNotify(requestData);
                        }
                        this.toggleWarningPopup()
                    })
            } else {
                this.setState({
                    activeStep,
                    activeSubStep,
                    laoder: false,
                    oldLoaded: true,
                }, () => {
                    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                    axios.post(Server.API + 'hotel/quick-setup-data/create/', { setup_data: JSON.stringify(this.state) })
                })
            }
        }

        if (activeSubStep == 9) {

            if (!this.state.numberOfRooms) {
                error.push("numberOfRooms")
            }
            if (error.length == 0 && callApi) {
                this.setState({
                    laoder: true
                })
                let PELL_weekday = ""
                let PELL_weekend = ""

                if (this.state.numberOfRooms < 6) {
                    PELL_weekday = -12
                    PELL_weekend = -12
                } else if (this.state.numberOfRooms <= 11) {
                    PELL_weekday = -8
                    PELL_weekend = -8
                } else if (this.state.numberOfRooms > 12) {
                    PELL_weekday = -5.5
                    PELL_weekend = -5.5
                }


                let requestParam = {
                    token: this.props.hotelAuthReducer.hotelDetails.token,
                    number_of_rooms: this.state.numberOfRooms,
                    PELL_weekday: PELL_weekday,
                    PELL_weekend: PELL_weekend,
                }

                this.props.updatePriceJson(requestParam, [], 'updateNumberOfRoomOnly')


                this.setState({
                    loadTopCompetitors: true
                });

                let req = {
                    token: this.props.hotelAuthReducer.hotelDetails.token,
                }
                let params = {
                    quick_setup: true
                }
                axios.defaults.headers.common["Authorization"] = "Token " + req.token;
                axios.post(Server.API + "hotel/competitor/setup/", params)
                    .then((response) => {
                        if (response.data.success) {

                            axios.post(Server.API + "hotel/competitor/scrape/", { top_20: false, is_from_quick_setup: true })
                                .then((r) => {

                                    axios.get(Server.API + "hotel/quick-setup-data/setflag/")
                                    axios.defaults.headers.common["Authorization"] = "Token " + requestParam.token;
                                    axios.get(Server.API + "hotel/competitor/setup/?type=2")
                                        .then((response) => {
                                            axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                                            axios.post(Server.API + 'hotel/quick-setup-data/create/', { setup_data: "" })

                                        })
                                    this.setState({
                                        activeStep,
                                        activeSubStep,
                                        error: [],
                                        laoder: false,
                                        oldLoaded: true,
                                        completePercentage: 0
                                    }, () => {
                                        axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                                        axios.post(Server.API + 'hotel/quick-setup-data/create/', { setup_data: JSON.stringify(this.state) })
                                    })

                                })
                        } else {
                            this.setState({
                                activeStep,
                                activeSubStep: 7,
                                error: [],
                                laoder: false,
                                oldLoaded: true
                            }, () => {
                                axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                                axios.post(Server.API + 'hotel/quick-setup-data/create/', { setup_data: JSON.stringify(this.state) })
                            })
                            if (this.props.userSession.user.is_staff) {
                                let requestData = {
                                    msg: response.data.message,
                                    is_success: false,
                                }
                                this.props.putNotify(requestData);
                            }
                            this.toggleWarningPopup()
                        }
                    })
                    .catch((error) => {
                        if (this.props.userSession.user.is_staff) {
                            let requestData = {
                                msg: "Top Competitor load Failed",
                                is_success: false,
                            }
                            this.props.putNotify(requestData);
                        }
                        this.toggleWarningPopup()
                        this.setState({
                            activeStep,
                            activeSubStep: 7,
                            error: [],
                            laoder: false,
                            oldLoaded: true,
                        }, () => {
                            axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                            axios.post(Server.API + 'hotel/quick-setup-data/create/', { setup_data: JSON.stringify(this.state) })
                        })
                    });

            } else {
                this.setState({
                    activeStep,
                    activeSubStep,
                    laoder: false,
                    oldLoaded: true,
                }, () => {
                    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                    axios.post(Server.API + 'hotel/quick-setup-data/create/', { setup_data: JSON.stringify(this.state) })
                })
            }
        }

        if (activeSubStep == 10) {
            if (!this.state.pms_name || !this.state.channel_manager) {
                if (!this.state.pms_name) {
                    error.push("pms_name")
                }
                if (!this.state.channel_manager) {
                    error.push("channel_manager")
                }
            } else {

                this.setState({
                    laoder: true
                })

                let requestParams = {
                    first_name: this.state.userFirstname,
                    last_name: this.state.userLastname,
                    email: this.state.userEmail,
                    extra: "account_owner_user",
                    pms_name: this.state.pms_name,
                    channel_manager: this.state.channel_manager
                }

                axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                axios.patch(Server.API + 'account/quick-setup/update/', requestParams)
                    .then((response) => {

                    })
            }
        }



        // if (activeSubStep == 4) {
        //     if (!this.state.property) {
        //         error.push("property")
        //     } else {
        //         let event_types = 1;
        //         if (this.state.property == "0") {
        //             event_types = 2
        //         }
        //         let requestParam = {
        //             event_types: event_types,
        //             token: this.props.hotelAuthReducer.hotelDetails.token,
        //             hideNotification: true
        //         }
        //         this.props.updateHotel(requestParam);
        //     }
        // }






        if ([1, 2, 3, 4, 5].includes(activeSubStep)) {
            activeStep = 1;
        } else if ([6, 7,8,9].includes(activeSubStep)) {
            activeStep = 2;
        } else if ([10].includes(activeSubStep)) {
            activeStep = 4;
        }
        
        // if (error.length) {
        //     this.setState({
        //         error
        //     })
        //     return
        // }
        // this.setState({
        //     activeStep,
        //     activeSubStep,
        //     error: [],
        //     laoder: false,
        //     oldLoaded: true
        // })
        // return


        if (error.length == 0 && activeSubStep != 8 && activeSubStep != 7 && activeSubStep != 9) {
            if (callApi) {
                this.setState({
                    laoder: true
                })
                if (activeSubStep != 8) {
                    setTimeout(() => {
                        this.setState({
                            activeStep,
                            activeSubStep,
                            error: [],
                            laoder: false,
                            oldLoaded: true
                        }, () => {
                            axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                            axios.post(Server.API + 'hotel/quick-setup-data/create/', { setup_data: JSON.stringify(this.state) })
                        })
                    }, 2000);
                } else {
                    setTimeout(() => {
                        this.setState({
                            activeStep,
                            activeSubStep,
                            error: [],
                            laoder: false,
                            oldLoaded: true
                        }, () => {
                            axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                            axios.post(Server.API + 'hotel/quick-setup-data/create/', { setup_data: JSON.stringify(this.state) })
                        })
                    }, 8000);
                }
            } else {
                this.setState({
                    activeStep,
                    activeSubStep,
                    error: [],
                    laoder: false,
                    oldLoaded: true
                }, () => {
                    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                    axios.post(Server.API + 'hotel/quick-setup-data/create/', { setup_data: JSON.stringify(this.state) })
                })
            }
        } else {
            this.setState({
                error,
            })
        }
    }

    saveDataWizard = () => {
        let error = [];

        this.setState({
            error,
        })
    }

    onChnagePms = () => event => {
        let pmsData = this.state.pmsData.filter(val => val.id == event.target.value)[0];
        if (pmsData) {
            this.setState({
                pmsRoom: event.target.value,
                pmsRates: pmsData.rates
            })
        }

    }




    runPricing = () => {
        if (![15, 34, 22].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) && this.state.get_pms_data) {

            const requestData = {
                start_date: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'),
                end_date: moment().add('363', 'd').format('YYYY-MM-DD'),
                get_pms_data: true,
            }

            axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
            axios.post(Server.API + 'pricingalgorithm/get-inventory-price/', requestData)
                .then((response) => {
                    if (!response.data.error) {
                        this.runPricingSocket()
                    } else {
                        let msg = 'Something went Wrong While Fetching Values from PMS';
                        if (response.data && response.data.message) {
                            msg = response.data.message;
                        }
                        if (this.props.userSession.user.is_staff) {
                            let requestData = {
                                msg: msg,
                                is_success: false,
                            }
                            this.props.putNotify(requestData);
                        }
                        this.toggleWarningPopup()
                    }
                }).catch(error => {
                    if (this.props.userSession.user.is_staff) {
                        let msg = 'Something went Wrong While Fetching Values from PMS';
                        let requestData = {
                            msg: msg,
                            is_success: false,
                        }
                        this.props.putNotify(requestData);
                    }
                    this.toggleWarningPopup()
                })
        } else {
            this.runPricingSocket()
        }

    }
    handleLogout = () => {
        localStorage.removeItem("__UserProps__")
        localStorage.removeItem("email")
        localStorage.removeItem("name")
        window.location = "/";
    }
    runPricingSocket = () => {
        const requestData = {
            start_date: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'),
            end_date: moment().add('363', 'd').format('YYYY-MM-DD'),
            get_pms_data: true,
            command: "run_pricing_on_demand",
            return_full_dates: false,
            pricing_type: 1
        }

        ws = new WebSocket(Server.SOCKETAPI + this.props.hotelAuthReducer.hotelDetails.token);
        ws.onopen = () => {
            ws.send(JSON.stringify(requestData))
        };

        ws.onerror = (evt) => {

        }

        ws.onmessage = (evt) => {
            if (evt.data) {

                var received_msg = JSON.parse(evt.data);

                if (received_msg.error) {
                    if (this.props.userSession.user.is_staff) {
                        let requestData = {
                            msg: received_msg.error ? received_msg.message : 'Error While run pricing',
                            is_success: false,
                        }
                        this.props.putNotify(requestData)
                    }
                    this.toggleWarningPopup()
                } else {

                    let requestMonthly = {
                        token: this.props.hotelAuthReducer.hotelDetails.token,
                        monthly_self_assessment: 3,
                        weekly_self_assessment: 3
                    }

                    axios.post(Server.API + 'hotel/monthly-weekly-self-assessment-settings/', requestMonthly)
                        .then(() => {
                            axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
                            axios.post(Server.API + 'hotel/quick-setup-data/create/', { setup_data: "" })
                                .then(() => {
                                    document.location.href = "/client/Calendar/" + this.props.hotelAuthReducer.hotelDetails.hotel.id;
                                })
                        }).catch((error) => {
                            if (this.props.userSession.user.is_staff) {
                                let requestData = {
                                    msg: "Monthly and Weekly Assesment Failed",
                                    is_success: false,
                                }
                                this.props.putNotify(requestData);
                            }
                            this.toggleWarningPopup()
                        });
                }
            }
        }
    }

    changeAgree = () => {
        this.setState({
            agree: !this.state.agree
        })
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        const { activeStep, activeSubStep } = this.state;

        let pmsName = this.state.pmsData.filter(val => val.id == this.state.pmsRoom)
        let pmsRateName = this.state.pmsRates.filter(val => val.id == this.state.pmsRate)

        if (pmsName[0]) {
            pmsName = pmsName[0].name;
        } else {
            pmsName = "";
        }

        if (pmsRateName[0]) {
            pmsRateName = pmsRateName[0].name;
        } else {
            pmsRateName = "";
        }

        let baseRate = this.state.baseRate;
        let minPrice1 = "";
        let minPrice2 = "";
        let minPrice3 = "";
        let maxPrice1 = "";
        let maxPrice2 = "";
        let maxPrice3 = "";

        if (baseRate) {
            minPrice1 = this.state.currencySymbol + Math.round(baseRate * 0.5);
            minPrice2 = this.state.currencySymbol + Math.round(baseRate * 0.65);
            minPrice3 = this.state.currencySymbol + Math.round(baseRate * 0.8);
            maxPrice1 = this.state.currencySymbol + Math.round(baseRate * 2);
            maxPrice2 = this.state.currencySymbol + Math.round(baseRate * 1.8);
            maxPrice3 = this.state.currencySymbol + Math.round(baseRate * 1.4);
        }

        return (
            <MuiThemeProvider theme={quickSetupTheme}>
                {(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) &&
                    <Button onClick={() => { document.location.href = "/client/RoomSetting/" + this.props.hotelAuthReducer.hotelDetails.hotel.id }} color={"primary"} style={{ position: "absolute", left: "0px" }}><Icon style={{ marginRight: "15px" }}>keyboard_backspace</Icon> {t(" Back to App")}</Button>
                }

                {this.state.oldLoaded &&
                    <Card style={(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) ? { minHeight: "530px", margin: "auto" } : { minHeight: "530px" }}>
                        <CardContent style={{ display: "flex", flexDirection: "column" }}>
                            <div className={classes.root}>
                                <Stepper activeStep={activeStep}>
                                    <Step key={1}>
                                        <StepLabel >{t("Account")}</StepLabel>
                                    </Step>
                                    <Step key={2}>
                                        <StepLabel >{t("Property")}</StepLabel>
                                    </Step>
                                    <Step key={3}>
                                        <StepLabel >{t("Mapping")}</StepLabel>
                                    </Step>
                                    <Step key={4}>
                                        <StepLabel >{t("Done!")}</StepLabel>
                                    </Step>
                                </Stepper>
                            </div>

                            <div style={{ minHeight: "400px" }}>
                                {/* {activeSubStep == 0 &&
                                    <div style={{ minHeight: activeSubStep == 0 ? "458px" : "400px" }}>
                                        <Grid item xs={12}>
                                            <Typography ><img height="100px" src="/img/roompricegenie_logo1.png" /></Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h5" style={{ marginTop: "24px" }}>Welcome!</Typography>
                                            <Typography className={classes.questionDiv} style={{ padding: "0px 130px" }}>
                                                Help the Genie to tailor the pricing to your property. It'll take just 2 minutes of work (we've timed it!).
                                        </Typography>
                                            <Typography className={classes.questionDiv} style={{ padding: "0px 175px", display: "flex", alignItems: "baseline" }}>
                                                <div style={{ paddingTop: "10px" }}>
                                                    <Checkbox
                                                        color={"primary"}
                                                        checked={this.state.agree}
                                                        onChange={this.changeAgree}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </div>
                                                <div>
                                                    <Typography align={"left"} style={{ fontSize: "14px" }}>I agree to receive onboarding and product related emails from RoomPriceGenie and to my data being processed. I accept the terms and conditions and the privacy policy.</Typography>
                                                    <br />
                                                    <DialogContentText align={"left"} style={{ fontSize: "14px", marginTop: "-20px" }}>You can unsubscribe from these communications at any time. For more information please review our <a href={"https://roompricegenie.com/terms-and-conditions/"} target={"_blank"}>Terms & Conditions</a> and <a href={"https://roompricegenie.com/privacy-policy/"} target={"_blank"}>Privacy Policy</a>.</DialogContentText>
                                                </div>
                                            </Typography>
                                        </Grid>
                                    </div>
                                } */}

                                {activeSubStep == 0 &&
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">First, create your account</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl required className={classes.adjustmentTextfield} margin="normal">
                                                {/* <Typography className={classes.questionDiv} style={{ padding: "0px 130px", marginTop: "0px" }}>By default, we will send the emails to your booking.com email address. If you want to receive GenieIntelligence to another email address, please let us know below.</Typography> */}
                                                <Typography className={classes.questionDivText} style={{ marginTop: "0px" }}>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                className={classes.quickText}
                                                                id="standard-name"
                                                                label="First Name"
                                                                value={this.state.userFirstname}
                                                                onChange={this.handleChange("userFirstname")}
                                                                error={this.state.error.includes('userFirstname')}
                                                                style={{ float: "right", marginRight: "8px" }}
                                                            />

                                                            <div className={classes.quickText} style={{ marginTop: "4px" }}>
                                                                {this.state.error.includes('userFirstname') ? <span class='error-message' style={{ float: "left" }}>{("Please add first name")}</span> : ''}
                                                            </div>

                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                className={classes.quickText}
                                                                id="standard-name"
                                                                label="Last Name"
                                                                value={this.state.userLastname}
                                                                onChange={this.handleChange("userLastname")}
                                                                error={this.state.error.includes('userLastname')}
                                                                style={{ float: "left", marginLeft: "8px" }}
                                                            />

                                                            <div className={classes.quickText} style={{ marginTop: "4px" }}>
                                                                {this.state.error.includes('userLastname') ? <span class='error-message' style={{ float: "left" }}>{("Please add last name")}</span> : ''}
                                                            </div>

                                                        </Grid>
                                                        <Grid item xs={12}>

                                                            <TextField
                                                                className={classes.quickText}
                                                                id="standard-name"
                                                                label="Email"
                                                                value={this.state.userEmail}
                                                                onChange={this.handleChange("userEmail")}
                                                                error={this.state.error.includes('userEmail')}
                                                            />
                                                            <div className={classes.quickText} style={{ marginTop: "4px" }}>
                                                                {this.state.error.includes('userEmail') ? <span class='error-message' style={{ float: "left" }}>{("Please add Email")}</span> : ''}
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={2}></Grid>
                                                        <Grid item xs={8} style={{ display: "flex", marginTop: "32px" }}>
                                                            <div style={{ paddingTop: "10px" }}>
                                                                <Checkbox
                                                                    color={"primary"}
                                                                    checked={this.state.agree}
                                                                    onChange={this.changeAgree}
                                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                />
                                                            </div>
                                                            <div>
                                                                <Typography align={"left"} style={{ fontSize: "14px" }}>I agree to receive onboarding and product related emails from RoomPriceGenie and to my data being processed. I accept the terms and conditions and the privacy policy.</Typography>
                                                                <br />
                                                                <DialogContentText align={"left"} style={{ fontSize: "14px", marginTop: "-20px" }}>You can unsubscribe from these communications at any time. For more information please review our <a href={"https://roompricegenie.com/terms-and-conditions/"} target={"_blank"}>Terms & Conditions</a> and <a href={"https://roompricegenie.com/privacy-policy/"} target={"_blank"}>Privacy Policy</a>.</DialogContentText>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Typography>
                                            </FormControl>
                                        </Grid>
                                    </>
                                }

                                {activeSubStep == 1 &&
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">Your property’s details</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                className={classes.quickText}
                                                id="standard-name"
                                                label="Property Name"
                                                value={this.state.hotelName}
                                                onChange={this.handleChange("hotelName")}
                                                error={this.state.error.includes('hotelName')}
                                            />
                                            <div className={classes.quickText} style={{ marginTop: "4px" }}>
                                                {this.state.error.includes('hotelName') ? <span class='error-message' style={{ float: "left" }}>{("Please add Hotel Name")}</span> : ''}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                className={classes.quickText}
                                                id="standard-name"
                                                label="Address"
                                                value={this.state.address}
                                                onChange={this.handleChange("address")}
                                                error={this.state.error.includes('address')}
                                            />
                                            <div className={classes.quickText} style={{ marginTop: "4px" }}>
                                                {this.state.error.includes('address') ? <span class='error-message' style={{ float: "left" }}>{("Please add Address")}</span> : ''}
                                            </div>
                                        </Grid>
                                    </Grid>
                                }

                                {activeSubStep == 2 &&
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">Property Type</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl required className={classes.adjustmentTextfield} margin="normal">
                                                <Typography className={classes.questionDiv}>Which best describes your property?</Typography>
                                                <RadioGroup
                                                    className={classes.imgRadioGroup}
                                                    value={this.state.property}
                                                    onClick={this.handleChange("property")}
                                                >
                                                    <FormControlLabel className={classes.imgRadioLabel} value={'0'} control={<Radio color="primary" />} label={<span className={this.state.property == "0" ? classes.imgRadioSpanActive : classes.imgRadioSpan}>City/Town<img className={classes.imgRadioImg} src={process.env.PUBLIC_URL + '/img/qs3.png'} /></span>} />
                                                    <FormControlLabel className={classes.imgRadioLabel} value={'1'} control={<Radio color="primary" />} label={<span className={this.state.property == "1" ? classes.imgRadioSpanActive : classes.imgRadioSpan}>Countryside<img className={classes.imgRadioImg} src={process.env.PUBLIC_URL + '/img/qs4.png'} /></span>} />
                                                    <FormControlLabel className={classes.imgRadioLabel} value={'2'} control={<Radio color="primary" />} label={<span className={this.state.property == "2" ? classes.imgRadioSpanActive : classes.imgRadioSpan}>Ski<img className={classes.imgRadioImg} src={process.env.PUBLIC_URL + '/img/qs5.png'} /></span>} />
                                                    <FormControlLabel className={classes.imgRadioLabel} value={'3'} control={<Radio color="primary" />} label={<span className={this.state.property == "3" ? classes.imgRadioSpanActive : classes.imgRadioSpan}>Seaside<img className={classes.imgRadioImg} src={process.env.PUBLIC_URL + '/img/qs6.png'} /></span>} />
                                                    <FormControlLabel className={classes.imgRadioLabel} value={'4'} control={<Radio color="primary" />} label={<span className={this.state.property == "4" ? classes.imgRadioSpanActive : classes.imgRadioSpan} >Other <img className={classes.imgRadioImg} src={process.env.PUBLIC_URL + '/img/qs11.png'} /></span>} />
                                                </RadioGroup>
                                                {this.state.error.includes('property') ? <span class='error-message' style={{ textAlign: "center" }}>{t('* Please Select an Option')}</span> : ''}
                                            </FormControl>
                                        </Grid>
                                    </>}

                                {activeSubStep == 3 &&
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">Your Guests</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl required className={classes.adjustmentTextfield} margin="normal" style={{ padding: "0px 110px" }}>
                                                <Typography align="center" className={classes.questionDiv}>Are your guests mostly leisure or business? </Typography>
                                                <RadioGroup
                                                    className={classes.radioGroup}
                                                    value={this.state.guestLeisure}
                                                    onClick={this.handleChange("guestLeisure")}
                                                >
                                                    <FormControlLabel className={this.state.guestLeisure == "0" ? classes.radioLabelActive : classes.radioLabel} value={'0'} control={<Radio color="primary" />} label={('All leisure')} />
                                                    <FormControlLabel className={this.state.guestLeisure == "1" ? classes.radioLabelActive : classes.radioLabel} value={'1'} control={<Radio color="primary" />} label={('Mostly leisure')} />
                                                    <FormControlLabel className={this.state.guestLeisure == "2" ? classes.radioLabelActive : classes.radioLabel} value={'2'} control={<Radio color="primary" />} label={('Mixed')} />
                                                    <FormControlLabel className={this.state.guestLeisure == "3" ? classes.radioLabelActive : classes.radioLabel} value={'3'} control={<Radio color="primary" />} label={('Mostly business')} />
                                                    <FormControlLabel className={this.state.guestLeisure == "4" ? classes.radioLabelActive : classes.radioLabel} value={'4'} control={<Radio color="primary" />} label={('All business')} />
                                                </RadioGroup>
                                                {this.state.error.includes('guestLeisure') ? <span class='error-message' style={{ textAlign: "center" }}>{t('* Please Select an Option')}</span> : ''}
                                            </FormControl>
                                        </Grid>

                                    </>}

                                {activeSubStep == 4 &&
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">Granting Access to your Booking.com property</Typography>
                                        </Grid>
                                        <FormControl required className={classes.adjustmentTextfield} margin="normal" style={{ padding: "0px 130px" }}>
                                            <Typography className={classes.questionDivText}>
                                                {t(`Please enter your Booking.com property ID below. The button will redirect you to your Booking.com account in a new tab, where you need to confirm that RoomPriceGenie can access your property’s data. Once confirmed, please come back to continue. `)}
                                                <TextField
                                                    className={classes.quickText}
                                                    id="standard-name"
                                                    label="Booking.com Property Id"
                                                    value={this.state.propertyId}
                                                    onChange={this.handleChange("propertyId")}
                                                    error={this.state.error.includes('propertyId')}
                                                />
                                                <div className={classes.quickText} style={{ marginTop: "4px" }}>
                                                    {this.state.error.includes('propertyId') ? <span class='error-message' style={{ float: "left" }}>{("Please add propertyId")}</span> : ''}
                                                </div>

                                            </Typography>

                                        </FormControl>
                                    </>}


                                {activeSubStep == 5 &&
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">Waiting for access confirmation</Typography>
                                        </Grid>
                                        <FormControl required className={classes.adjustmentTextfield} margin="normal" style={{ padding: "0px 130px" }}>
                                            <Typography className={classes.questionDivText}>
                                                {t(`We’re waiting on the access confirmation from your Booking.com account. This might take a few minutes`)}
                                                <CircularProgress color="primary" size={32} style={{ marginTop: "16px" }} />

                                            </Typography>

                                        </FormControl>
                                    </>}

                                {activeSubStep == 6 &&
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">{`Your Most Popular ${this.state.room_apartment_space_name} Type`}</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <FormControl required className={classes.adjustmentTextfield} margin="normal" style={{ padding: "0px 130px" }}>
                                                <Typography className={classes.questionDivText}>
                                                    {t(`Please select the ${this.state.room_apartment_space_name.toLowerCase()} type you want to receive price recommendations for. Most people go with their most popular double ${this.state.room_apartment_space_name.toLowerCase()}.`)}
                                                    <TextField
                                                        select
                                                        className={classes.quickText}
                                                        type="number"
                                                        id="standard-name"
                                                        // label="Room Type in Pms "
                                                        style={{ width: "300px" }}
                                                        value={this.state.pmsRoom}
                                                        onChange={this.onChnagePms()}

                                                    >
                                                        {this.state.pmsData.map(val => (
                                                            <MenuItem value={val.id}>{val.name}</MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Typography>
                                                {this.state.pmsData && this.state.pmsData.length == 0 ? <span class='error-message' style={{ textAlign: "center", color: "red" }}>{t(`We’re sorry, but we couldn’t fetch your ${this.state.room_apartment_space_name.toLowerCase()} types. We’ll check and get back to you as soon as possible.`)}</span> : ""}
                                                {this.state.pmsRoom && this.state.pmsRates.length == 0 ? <span class='error-message' style={{ textAlign: "center", color: "red" }}>{t(`We’re sorry, but we couldn’t fetch your ${this.state.room_apartment_space_name.toLowerCase()} rate. We’ll check and get back to you as soon as possible.`)}</span> : ""}
                                                {this.state.error.includes('pmsRoom') ? <span class='error-message' style={{ textAlign: "center" }}>{t(`* Please Select a PMS ${this.state.room_apartment_space_name} Type`)}</span> : ''}
                                            </FormControl>
                                        </Grid>
                                    </>}

                                {activeSubStep == 7 &&
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">Your (Base) Rate</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl required className={classes.adjustmentTextfield} margin="normal" style={{ padding: "0px 130px" }}>
                                                <Typography className={classes.questionDivText}> Please select the rate you want to receive price recommendations for. If you have multiple rates, we recommend using the "base" rate, from which you derive the other rates.
                                                    <TextField
                                                        select
                                                        className={classes.quickText}
                                                        style={{ width: "300px" }}
                                                        type="number"
                                                        id="standard-name"
                                                        value={this.state.pmsRate}

                                                        onChange={this.handleChange("pmsRate")}
                                                    >
                                                        {this.state.pmsRates.map(val => (
                                                            <MenuItem value={val.id}>{val.name}</MenuItem>
                                                        ))}

                                                    </TextField></Typography>
                                                {this.state.pmsRates && this.state.pmsRates.length == 0 ? <span class='error-message' style={{ textAlign: "center", color: "red" }}>{t(`We’re sorry, but we couldn’t fetch your ${this.state.room_apartment_space_name.toLowerCase()} rate. We’ll check and get back to you as soon as possible.`)}</span> : ""}
                                                {this.state.error.includes('pmsRate') ? <span class='error-message' style={{ textAlign: "center" }}>{t('* Please Select Base Rate')}</span> : ''}
                                            </FormControl>
                                        </Grid>

                                    </>}


                                {activeSubStep == 8 &&
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">{`Number of ${this.state.room_apartment_space_name}s`}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl required className={classes.adjustmentTextfield} margin="normal">
                                                <Typography className={classes.questionDivText}>
                                                    {`How many ${this.state.room_apartment_space_name}s do you have?`}
                                                    <TextField

                                                        className={classes.quickText}
                                                        type="number"
                                                        id="standard-name"
                                                        label={`Total Number of ${this.state.room_apartment_space_name}s`}
                                                        value={this.state.numberOfRooms}
                                                        onChange={this.handleChange("numberOfRooms")}

                                                    />
                                                </Typography>

                                                {this.state.error.includes('numberOfRooms') ? <span class='error-message' style={{ textAlign: "center" }}>{t(`* Please enter Total Number of ${this.state.room_apartment_space_name}s`)}</span> : ''}
                                            </FormControl>
                                        </Grid>

                                    </>}
                                {activeSubStep == 9 &&
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">Which PMS and Channel Manager do you use?</Typography>
                                        </Grid>
                                        <Grid item xs={12}>


                                            <FormControl required className={classes.adjustmentTextfield} margin="normal">
                                                <Typography className={classes.questionDiv} style={{ padding: "0px 130px", marginTop: "0px" }}>Providing you with the best possible experience and pricing is a joint effort. Knowing your PMS and Channel Manager helps us to prioritize new integrations and partnerships.</Typography>

                                                <Typography className={classes.questionDivText} style={{ marginTop: "0px" }}>
                                                    <div>
                                                        {this.state.isOtherPms ?
                                                            <TextField
                                                                InputLabelProps={{ shrink: true }}
                                                                className={classes.quickText}
                                                                id="standard-name"
                                                                label="Property Management System "
                                                                style={{ width: "300px" }}
                                                                value={this.state.pms_name}
                                                                onChange={this.handleChange("pms_name")}
                                                                error={this.state.error.includes('pms_name')}
                                                                inputRef={this.textInput}
                                                            >
                                                            </TextField>
                                                            :
                                                            <TextField
                                                                InputLabelProps={{ shrink: true }}
                                                                select
                                                                className={classes.quickText}
                                                                id="standard-name"
                                                                label="Property Management System "
                                                                style={{ width: "300px" }}
                                                                value={this.state.pms_name}
                                                                onChange={this.handleChange("pms_name")}
                                                                error={this.state.error.includes('pms_name')}
                                                                inputRef={this.textInput}
                                                            >
                                                                {pmsList.map(val => <MenuItem value={val}>{val}</MenuItem>)}
                                                            </TextField>
                                                        }
                                                        <Button onClick={this.toggleOtherPms} color={"primary"} style={{ marginTop: "44px" }}>Other</Button>
                                                    </div>

                                                    <div className={classes.quickText} style={{ marginTop: "4px", width: "370px" }}>
                                                        {this.state.error.includes('pms_name') ? <span class='error-message' style={{ float: "left" }}>{("Please add Property Management System")}</span> : ''}
                                                    </div>

                                                    <div>
                                                        {this.state.isOtherCm ?
                                                            <TextField
                                                                InputLabelProps={{ shrink: true }}
                                                                className={classes.quickText}
                                                                id="standard-name"
                                                                label="Channel Manager "
                                                                style={{ width: "300px" }}
                                                                value={this.state.channel_manager}
                                                                onChange={this.handleChange("channel_manager")}
                                                                error={this.state.error.includes('channel_manager')}
                                                                inputRef={this.textInputcm}
                                                            >
                                                            </TextField>
                                                            :
                                                            <TextField
                                                                InputLabelProps={{ shrink: true }}
                                                                select
                                                                className={classes.quickText}
                                                                id="standard-name"
                                                                label="Channel Manager "
                                                                style={{ width: "300px" }}
                                                                value={this.state.channel_manager}
                                                                onChange={this.handleChange("channel_manager")}
                                                                error={this.state.error.includes('channel_manager')}
                                                                inputRef={this.textInputcm}
                                                            >
                                                                {channels.map(val => <MenuItem value={val}>{val}</MenuItem>)}
                                                            </TextField>
                                                        }
                                                        <Button onClick={this.toggleOtherCm} color={"primary"} style={{ marginTop: "44px" }}>Other</Button>
                                                    </div>
                                                    <div className={classes.quickText} style={{ marginTop: "4px", width: "370px" }}>
                                                        {this.state.error.includes('channel_manager') ? <span class='error-message' style={{ float: "left" }}>{("Please add Channel Manager")}</span> : ''}
                                                    </div>

                                                    {/* <TextField
                                                         
                                                        className={classes.quickText}
                                                        id="standard-name"
                                                        label="Property Management System"
                                                        value={this.state.pms_name}
                                                        onChange={this.handleChange("pms_name")}
                                                        error={this.state.error.includes('pms_name')}
                                                    />
                                                    
                                                    <TextField
                                                        
                                                        className={classes.quickText}
                                                        id="standard-name"
                                                        label="Channel Manager"
                                                        value={this.state.channel_manager}
                                                        onChange={this.handleChange("channel_manager")}
                                                        error={this.state.error.includes('channel_manager')}
                                                    /> */}

                                                </Typography>

                                            </FormControl>
                                        </Grid>
                                    </>
                                }






                                {activeSubStep == 10 &&
                                    <>

                                        <Grid container spacing={2}>
                                            <Grid item xs={5}>
                                                <Typography variant="h5" align="left" >Give yourself a pat on the back! You've completed the GenieIntelligence setup.</Typography>
                                                <Typography className={classes.adjustmentTextfield} variant="subheading" style={{ fontWeight: "500" }} align="left" >What should I expect next?</Typography>
                                                <FormControl required className={classes.adjustmentTextfield} margin="normal" style={{ textAlign: "left" }}>
                                                    Your GenieIntelligence email will start to arrive in your inbox every morning (with love from all of us here). Please allow two working days so we can check the prices before starting.
                                                </FormControl>

                                                <Typography className={classes.adjustmentTextfield} variant="subheading" align="left" style={{ fontWeight: "500" }}>What if I would like to talk to you about full RoomPriceGenie 2-way integration?</Typography>
                                                <FormControl required className={classes.adjustmentTextfield} margin="normal" style={{ textAlign: "left" }}>

                                                    If you would like RoomPriceGenie to upload prices to your property management system or channel manager, we will need to discuss your needs in more detail. Please book a call with our experts on the right. We would absolutely love to hear from you.
                                                </FormControl>

                                            </Grid>
                                            <Grid item xs={7}>
                                                <iframe name="myframe" style={{ 'border': 'none' }} src={"https://static.roompricegenie.com/quickSetup.html"} width="100%" height="700px"></iframe>
                                                {/* <Typography variant="subtitle1" align="left"><b>{this.state.completePercentage ? parseInt(this.state.completePercentage) : 0}%</b> of Market Analysis done</Typography>

                                                <LinearProgress variant="determinate" color="primary" value={this.state.completePercentage} style={{ width: "330px", marginTop: "20px", }} /> */}
                                            </Grid>
                                        </Grid>
                                    </>
                                }
                            </div>
                            <Grid container spacing={2}>
                                <Grid item container xs={4} >
                                    {activeSubStep > 0 && activeSubStep < 10 &&
                                        <Tooltip
                                            title={
                                                <>
                                                    <Typography style={{ color: "#fff" }} variant={"h6"}>We'll figure this out!</Typography>
                                                    <Typography style={{ color: "#fff" }}>Please find the chat icon on the bottom right corner and leave us a message. We'll get back to you as soon as possible! Thank you very much!</Typography>
                                                </>
                                            }
                                        >
                                            <Button disabled={this.state.laoder} color={"primary"} style={{ marginLeft: "0px !important" }}>Stuck?</Button>
                                        </Tooltip>

                                    }
                                </Grid>
                                <Grid item xs={8}>
                                    <div>
                                        {activeSubStep < 10 && activeSubStep != 4 && activeSubStep != 5 &&
                                            <Button disabled={this.state.blockNext || !this.state.agree || this.state.laoder || (activeSubStep == 5 && this.state.pmsRoom && this.state.pmsRates.length == 0)} onClick={() => this.setSubStep(activeSubStep + 1, true)} color={"primary"} variant={"contained"}>Next {this.state.laoder && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}</Button>
                                        }
                                        {activeSubStep == 10 && activeSubStep != 4 &&
                                            <Button disabled={this.state.laoder || this.state.completePercentage != 100} color={"primary"} variant={"contained"} onClick={this.saveDataWizard}>Finish{this.state.laoder && <CircularProgress color="secondary" size={24} className={classes.buttonProgress} />}</Button>
                                        }
                                        {activeSubStep > 0 && activeSubStep < 10 && activeSubStep != 4 &&
                                            <Button disabled={this.state.laoder} onClick={() => this.setSubStep(activeSubStep - 1, false)} color={"primary"} variant={"outlined"}>Go Back</Button>
                                        }
                                        {activeSubStep == 4 && 
                                            <Button disabled={!this.state.propertyId} variant='contained' onClick={this.redirectToBooking} color={"primary"} target={"_blank"} href={"https://admin.booking.com/hotel/hoteladmin/extranet_ng/manage/channel-manager/provider-profile.html?provider_id=1585&lang=en&hotel_id="+this.props.hotelAuthReducer.hotelDetails.hotel.id} >Go to Booking.com and Confirm Access</Button>
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                }
                {!this.state.oldLoaded &&
                    <CircularProgress color="primary" size={36} className={classes.buttonProgress} style={{ top: "38%", left: "48%" }} />
                }

                <Button onClick={this.handleLogout} color={"primary"} style={{ position: "absolute", left: "0px", marginTop: !this.state.oldLoaded ? "530px" : "0px" }}> <Icon style={{ marginRight: "15px" }}>login</Icon>{t("Logout")}</Button>
                {Modal.warningPopupQuickSetup(this)}
            </MuiThemeProvider>
        );
    }
}

QuickSetup.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        hotelAuthReducer: state.hotelAuthReducer,
        userSession: state.sessionReducer,
        roomList: state.roomListReducer,
        pmsList: state.PmsListReducer.pmsList,
        commonReducer: state.commonReducer,
        competitorReducer: state.competitorReducer,
        priceSettingReducer: state.priceSettingReducer,
        hotelDetailReducer: state.hotelSettingReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateSettingJson: (request, callback, backdata) => { dispatch(actions.updateSettingJson(request, {}, 'targetOccupancy', callback, backdata)) },
        getPmsList: (request) => { dispatch(actions.getPmsList(request)) },
        quickSetupStepOne: (request) => { dispatch(actions.quickSetupStepOne(request)) },
        quickSetupStepFour: (request) => { dispatch(actions.quickSetupStepFour(request)) },
        editRoom: (request) => { dispatch(actions.editRoom(request)) },
        updateHotel: (request) => { dispatch(actions.updateHotelSetting(request)) },
        connectRooms: (request) => { dispatch(actions.connectRooms(request)) },
        updatePriceJson: (request, response, type) => { dispatch(actions.updateSettingJson(request, response, type)) },
        getTopCompetitors: (request) => { dispatch(actions.getTopCompetitors(request)) },
        getScoreCompetitor: (request) => { dispatch(actions.getScoreCompetitor(request)) },
        getScoreTopCompetitors: (request) => { dispatch(actions.getScoreTopCompetitors(request)) },
        putNotify: (request) => { dispatch(actions.putNotify(request)) },
        validateBookingUrl: (request) => { dispatch(actions.validateBookingUrl(request)) }
    };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(QuickSetup));
