var style = {
    root: {
      display: 'flex',
      boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
      borderRadius: "5px",
      minWidth: "150px",
      minHeight: "200px",
      margin: "15px",
      background: "#5045451c"
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      width:"100%",
      textAlign:"center"
    },
    content: {
      flex: '1 0 auto'
    },
    table:{
      maxHeight:'240px',
      overflow:'auto'
    },
    hotel:{
      width:'50%'
    },
    date:{
      width:'25%'
    },
    days:{
      width:'25%'
    },
    subtitle1:{
      fontSize:'18px',
      fontWeight:'500'
    },
    rowLinks:{
      textDecoration:"none",
      color:"black",
    },
    titleBottom:{
      paddingBottom : '10px'
    },
    tabletitle:{
      float: "right", fontWeight: "500", paddingBottom: "10px"
    }
}
export default style;