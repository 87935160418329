import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ClientListCss from './css/clientListCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../store/actions/index';
import MUIDataTable from "mui-datatables";
import Button from '@material-ui/core/Button';
import Modal from './modal/modal'
import { MuiThemeProvider } from '@material-ui/core/styles';
import { scrapeListTheme } from '../muiTheme';
import { Icon } from '@material-ui/core';
import moment from "moment";
import HeaderCard from "../Client/HeaderCard";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
const styles = ClientListCss;


class RateSetting extends React.Component {

    state = {
        scheduleList: [],
        showDelete: false,
        showError: false,
        error: false
    };

    componentWillMount() {
        let requestParam = { token: this.props.userSession.token }
        this.props.getScrapList(requestParam);
    }

    componentWillReceiveProps(props) {
        if (props.scrapListReducer.scrapList) {
            this.setState({
                count: props.scrapListReducer.scrapList.count,
                current: props.scrapListReducer.scrapList.current,
                next: props.scrapListReducer.scrapList.next,
                previous: props.scrapListReducer.scrapList.previous,
                scheduleList: props.scrapListReducer.scrapList.results
            })
        }
        if (this.props.scrapListReducer.error) {
            this.setState({
                error: this.props.scrapListReducer.error,
                showDelete: false,
                showError: true,
            })
        }
    }

    toggleDeleteList = (val) => {
        this.setState({
            deleteId: val.id,
            showDelete: !this.state.showDelete,
        })
    }

    toggleErrorModal = (val) => {
        this.setState({
            showError: !this.state.showError,
        })
    }

    closeDelete = () => {
        this.setState({
            showDelete: !this.state.showDelete,
        })
    }

    deleteList = () => {
        let requestParam = {
            token: this.props.userSession.token,
            id: this.state.deleteId
        }
        this.props.deleteScrapList(requestParam);
    }

    renderScrapData = (data) => {
        let jsx = [];
        data.map((val) => {
            jsx.push([
                val.hotel.name,

                val.last_updated_at ? moment(val.last_updated_at).format('YYYY-MM-DD HH:mm') : '-',
                val.number_of_price,
                val.avg_price,
                val.max_price,
                val.min_price,
                val
            ])
            return null;
        })
        return jsx;
    }

    options = () => {
        return {
            responsive: 'scroll',
            print: false,
            download: false,
            viewColumns: false,
            responsive: "scroll",
            selectableRows: false,
            serverSide: true,
            filter: false,
            rowsPerPageOptions: false,
            count: this.state.count,
            rowsPerPage: 20,
            search: false,
            onTableChange: (action, tableState) => {
                if (action === 'changePage') {
                    let page = tableState.page + 1;
                    let requestParams = {
                        token: this.props.userSession.token,
                        url: 'hotel/scrape/list/?page=' + page,
                    };
                    this.props.getScrapList(requestParams)
                }
                if (action === 'search') {
                    let searchText = tableState.searchText ? tableState.searchText : '';
                    let requestParams = {
                        token: this.props.userSession.token,
                        url: 'hotel/scrape/list/?search=' + searchText,
                    };
                    this.props.getScrapList(requestParams)
                }
            },
            textLabels: {
                body: {
                    noMatch: this.props.scrapListReducer.loading ?
                        'Loading Records...' :
                        'Sorry, no matching records found.',
                },
            }
        }
    };
  

    render() {
        const { classes } = this.props;

        const columns = [
            { name: "Name" },
            {
                name: "Last Scrape",
                options: {
                    filter: false,
                    download: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (value === null) ? '' : value;
                    }
                }
            },
            {
                name: "Number of Price",
                options: {
                    filter: false,
                    download: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (value === null) ? '' : value;
                    }
                }
            },
            {
                name: "Ave. Price",
                options: {
                    filter: false,
                    download: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (value === null) ? '' : value.toFixed(2);
                    }
                }
            },
            {
                name: "Max. Price",
                options: {
                    filter: false,
                    download: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (value === null) ? '' : value.toFixed(2);
                    }
                }
            },
            {
                name: "Min. Price",
                options: {
                    filter: false,
                    download: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (value === null) ? '' : value.toFixed(2);;
                    }
                }
            },
            {
                name: "Actions",
                options: {
                    filter: false,
                    download: false,
                    setCellProps: () => {
                        return { style: { color: '#541388', cursor: 'pointer' } }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment><Button className={classes.actionIcon} color="secondary" onClick={(id) => this.toggleDeleteList(value)}><Icon>delete</Icon></Button> </React.Fragment>
                        );
                    }
                }
            }
        ];
        return (
            <>
                <HeaderCard>
                    <Grid container>
                        <Grid item >
                            <div className={classes.divStyle} >
                            <Typography variant="h5" className={classes.fontStyle} >
                                Scrape List
                            </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </HeaderCard>
            <Card>
                <CardContent>
                <MuiThemeProvider theme={scrapeListTheme}>
                    <MUIDataTable
                        title={''}
                        data={this.renderScrapData(this.state.scheduleList)}
                        columns={columns}
                        options={this.options()}
                    />
                </MuiThemeProvider>
                </CardContent>
                {Modal.deleteProgramList(this)}
                {Modal.ErrorModal(this)}
            </Card >
           </>
        );
    }
}

RateSetting.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        userSession: state.sessionReducer,
        scrapListReducer: state.scrapListReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getScrapList: (request) => { dispatch(actions.getScrapList(request)) },
        deleteScrapList: (request) => { dispatch(actions.deleteScrapList(request)) },
    };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(RateSetting);