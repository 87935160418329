import React from "react";
import * as actions from "../../store/actions/index";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { performanceDashboardTheme, ButtonTheme,monthPickerStyle,monthPickerStyleSingleYear } from '../muiTheme';
import * as actionTypes from "../../store/actions/actionTypes";
import { withNamespaces } from "react-i18next";
import PerformanceDashboardCss from './css/performanceDashboardCss';
import { Card, CardHeader, CardContent, Grid, Icon, Typography, IconButton, Button, Tabs, Tab } from "@material-ui/core";
import moment from "moment";
import BigCalendar from 'react-big-calendar';
import ReactGA from 'react-ga';
import Server from '../../config/server';
import classNames from "classnames";
import { Bar, Line } from 'react-chartjs-2';
import axios from 'axios';
import Skeleton from '@material-ui/lab/Skeleton';
import HeaderCard from './HeaderCard';
import CustomTooltip from '../Utils/toolTip';
import Modals from './modal/dashboard';
import { ContactsOutlined, DesktopMacTwoTone } from "@material-ui/icons";
import { MuiPickersUtilsProvider, DatePicker, InlineDatePicker  } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from "date-fns/locale/en-US";
import * as html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ArrowForwardIcon from '@material-ui/icons//ArrowForward';
import Tooltip from '@material-ui/core/Tooltip';

moment.locale("es-es", { week: { dow: 7 } });



const localizer = BigCalendar.momentLocalizer(moment)
const styles = PerformanceDashboardCss
const plugins= [{
  beforeInit: function(chart, args, options) {
    chart.legend.afterFit = function() {
      this.height = this.height + 30;
    }
  }
}]
let revenueBud={}
let keyArray = [];

class PerformanceDashboard extends React.Component {

  constructor(props) {
    super(props);
    this.myChartRef = React.createRef();
    this.myChartRef0 = React.createRef();
    this.myChartRef2 = React.createRef();
    this.myChartRef3 = React.createRef();
    this.myChartRef4 = React.createRef();
    this.myChartRef5 = React.createRef();
  }

  state = {
    monthChangeDate: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'),
    yearChangeDate:moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'),
    financialsMonthly: [],
    tab: 0,
    bookingTabs: 0,
    update_message: "",
    loading: false,
    tillDate: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).add(12, 'months').format('MM-YYYY'),
    startDate: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).subtract(24, 'months').format('MM-YYYY'),
    tillYear: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).add(1, 'year').format('YYYY'),
    startYear: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).subtract(2, 'year').format('YYYY'),
    todayDate: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'),
    graphEndDate: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).add(12, 'months').format('MM-YYYY'),
    graphStartDate: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).subtract(24, 'months').format('MM-YYYY'),
    graphMonthChangeDate: new Date(moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY'), (moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('M') - 1), 5),
    viewType: this.props.hotelAuthReducer.hotelDetails.hotel.include_in_hourly_execution,
    purgeReservationModal: false,
    dataSameTime: {},
    dataPickUp: {},
    dateLables: [],
    last_reservation_received:"",
    performanceReservationLoading:false,
    pickupLoading:false,
    showFullYearGraph:false,
    featuresEnabled: [],
    openBudgetModal:false,
    currency:{},
    adrValues:{},
    revenueBudgetVal:{
      revenue_budget_gross_excl_services: {},
      revenue_budget_gross_incl_services: {},
      revenue_budget_net_incl_services: {},
      revenue_budget_net_excl_services: {},
    },
    forecastData: {},
    anchorEl: null,
    selectedTab:1,
    analyticsDropdownVal:3,
    dashboardServices:0,
    loadingPost:false,
    bookingCurveModalOpen:false,
    bookingCurveGraphEndDate: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).add(2, 'months').format('MM-YYYY'),
    bookingCurveGraphStartDate: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).subtract(1, 'months').format('MM-YYYY'),

  }

  componentWillMount() {
    const{yearChangeDate}=this.state;
    var dateLables = []
    var analyticsAllMonths=[]

    var allMonths = Array.apply(0, Array(12)).map(function (_, i) {
      dateLables.push(moment(yearChangeDate).month(i).format('MMM YY'));
      return moment(yearChangeDate).month(i).format('YYYY-MM')
    })

    var analyticsAllMonths=Array.apply(0, Array(12)).map(function (_, i) {
      analyticsAllMonths.push(moment(yearChangeDate).month(i).format('MMM YY'));
      return moment(yearChangeDate).month(i).format('YYYY-M')
    })

    this.setState({
      dateLables: dateLables,
      allMonths: allMonths,
      analyticsAllMonths:analyticsAllMonths
    })

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      date_range:'dashboard_kpis_full_date_range',
      is_calendar:true,
    };
    this.props.getHotelDetail(requestParam);
    this.props.getPerformanceReservation(requestParam);
    this.props.getPerformanceReservationPickup(requestParam);
    // this.props.dashboardDefaultBudget(requestParam)
    this.props.getAnalyticsData(requestParam)
    this.props.getForecastData(requestParam)       
  }

  componentDidMount(){    
    window.addEventListener("scroll", ()=>this.checkLegendSlide());
    const storedServices = localStorage.getItem('dashboardServices');
    if (storedServices) {
    const parsedServices = JSON.parse(storedServices);
    this.setState({dashboardServices: parsedServices});
  }
  }

  checkLegendSlide = () =>{
    var header = document.getElementById("myHeader");
    var revanueCard = document.getElementById("revanueCard");    
    if(header){
      var sticky = header.offsetTop;
      let revanueCard1 = (revanueCard.clientHeight + 50);
        if (window.pageYOffset > revanueCard1 && (window.pageYOffset + 190) > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
    }
  }
  

  componentWillReceiveProps(props) {

    if(props.PerformanceDashboardReducer.forecastData && props.PerformanceDashboardReducer.forecastData.data && props.PerformanceDashboardReducer.forecastData.data.forecasting_result){
      this.setState({
        forecastData: props.PerformanceDashboardReducer.forecastData.data.forecasting_result
      })
      // console.log("xxxxxaaaa", props.PerformanceDashboardReducer.forecastData.data.forecasting_result)
    }

    if(props.PerformanceDashboardReducer.analyticsData){
      this.setState({
        channelAnalytics:props.PerformanceDashboardReducer.analyticsData.report_channel_name,
        roomtypeAnalytics:props.PerformanceDashboardReducer.analyticsData.report_room_type,
        segmentAnalytics:props.PerformanceDashboardReducer.analyticsData.report_segment_id,
        weekdayAnalytics:props.PerformanceDashboardReducer.analyticsData.report_day_of_the_week,
        customSegmentAnalytics: (props.PerformanceDashboardReducer.analyticsData.report_segment_group || {})
      })
    }
    if (props.PerformanceDashboardReducer) {
      this.setState({
        performanceReservationLoading:props.PerformanceDashboardReducer.reservationKpiLoading,
        pickupLoading:props.PerformanceDashboardReducer.pickupLoading,
      })
    }
    if (props.PerformanceDashboardReducer.performancePickup) {
      const dataPickUp = props.PerformanceDashboardReducer.performancePickup
      this.setState({ dataPickUp: dataPickUp })
    }
    if (props.PerformanceDashboardReducer.performanceReservation) {
      const monthly_data = props.PerformanceDashboardReducer.performanceReservation?.kpi_table_monthly;      
      const daily_data=props.PerformanceDashboardReducer.performanceReservation?.kpi_table_daily;
      const yearly_data=props.PerformanceDashboardReducer.performanceReservation?.kpi_table_yearly;
      const stlyMonthlyBookingCurve=props.PerformanceDashboardReducer.performanceReservation?.monthly_booking_curve?.stly;
      const totalMonthlyBookingCurve=props.PerformanceDashboardReducer.performanceReservation?.monthly_booking_curve?.total
      if(monthly_data){
        var sameTimeMonths = [];
        monthly_data.stly && monthly_data.stly.ADR && Object.keys(monthly_data.stly.ADR).map((key) => {
          sameTimeMonths.push({
            date: key,
            ...monthly_data.stly.ADR[key],
          })
        })
        this.setState({
          performanceStly: monthly_data.stly,
          performanceCurrent: monthly_data.total,
          sameTimeMonths,
          stlyBooking:stlyMonthlyBookingCurve,
          totalBooking:totalMonthlyBookingCurve
        });
      }
      if(daily_data){
        this.setState({
          kpi_daily_total: daily_data.total,
          kpi_daily_stly: daily_data.stly,
        })
      }
      if(yearly_data){
        this.setState({
          last_reservation_received:yearly_data.last_reservation_received,
          kpi_yearly_total:yearly_data.total,
          kpi_yearly_stly:yearly_data.stly
        })
      }
      // if(props.PerformanceDashboardReducer.defaultBudget) {
      //   this.setState({
      //     defaultBudget:props.PerformanceDashboardReducer.defaultBudget
      //   })
      // }
    }

    if (props.PerformanceDashboardReducer.performanceReservation && props.PerformanceDashboardReducer.performanceReservation.financials) {
      this.setState({
        financialsMonthly: props.PerformanceDashboardReducer.performanceReservation.financials
      })
    }
    if (props.priceSettingReducer && props.priceSettingReducer.priceSetting) {
      this.setState({
        featuresEnabled: props.priceSettingReducer.priceSetting.features,
        monthlyTargetOccupancy: props.priceSettingReducer.priceSetting.monthly_target_occupancy,
        totalRooms:props.priceSettingReducer.priceSetting.hotel.number_of_rooms
      })
    }
    if(props.hotelAuthReducer.hotelDetails && props.hotelAuthReducer.hotelDetails.hotel.currency){
      this.setState({
        currency:props.hotelAuthReducer.hotelDetails.hotel.currency
      })
    }
    if (props.priceSettingReducer.priceSetting && props.priceSettingReducer.priceSetting.revenue_budget) {
      this.setState({revenueBudgetVal:props.priceSettingReducer.priceSetting.revenue_budget})
      // revenueBud=props.priceSettingReducer.priceSetting.revenueBudget
    }
      // let is_admin=props.userSession.user.is_staff? true : false
      // this.setState({
      //   dashboardServices:is_admin?1:0
      // })
    // }
  }
  
  navigateYear = (date) => {
    if ((Server.env === "live") && !(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type)) {
      ReactGA.event({
        // category: 'Occupancy-View',
        action: 'switch-year'
      });
    }
    const {yearChangeDate}=this.state
    var dateLables = []
    var analyticsAllMonths=[]
    var allMonths = Array.apply(0, Array(12)).map(function (_, i) {
      dateLables.push(moment(date).month(i).format('MMM YY'));
      return moment(date).month(i).format('YYYY-MM')
    })
    var analyticsAllMonths=Array.apply(0,Array(12)).map(function (_,i) {
      analyticsAllMonths.push(moment(date).month(i).format('MMM YY'));
      return moment(date).month(i).format('YYYY-M')
    })
    this.setState({
      yearChangeDate: date,
      dateLables: dateLables,
      allMonths: allMonths,
      analyticsAllMonths:analyticsAllMonths
    },() => {
      this.setAdr()
    }) 
  }
  navigateMonth = (date) => {
    if ((Server.env === "live") && !(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type)) {
      ReactGA.event({
        // category: 'Occupancy-View',
        action: 'switch-month'
      });
    }
    this.setState({
      monthChangeDate: date,
    })
  }
  graphNavigateMonth = (date) => {
    if ((Server.env === "live") && !(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type)) {
      ReactGA.event({
        // category: 'Occupancy-View',
        action: 'switch-month'
      });
    }
    this.setState({
      graphMonthChangeDate: new Date(moment(date).format('YYYY'), (moment(date).format('M') - 1), 5),
    })
  }

  changeCalendarYear = () => dateValue => { 
    this.navigateYear(moment(dateValue).format('YYYY'))
  }

  changeCalendarMonthYear = () => dateValue => { 
    this.graphNavigateMonth(moment(dateValue).format('YYYY-MM-DD'))
  }

  handleTabs = (event,newValue) => {
    this.setState({
      tab: newValue
    })
  };
  handleBookingBehaviorTabs = (e, val) => {
    this.setState({
      bookingTabs: val
    })
  }
  renderMonthlyGraph(val) {
    const { classes } = this.props;
    const { t } = this.props;
    const { showFullYearGraph,performanceStly, performanceCurrent, allMonths, dateLables,yearChangeDate,kpi_daily_stly,kpi_daily_total,graphMonthChangeDate } = this.state;
    let preferred_date;
    if(this.props.hotelDetail && this.props.hotelDetail.prefered_date_format){
       preferred_date= this.props.hotelDetail.prefered_date_format.replace(', hh:mm A','')
    }else{
      preferred_date='DD/MM/YYYY'
    }
    let graphData = {
      values: [],
      lyValues: [],
      stlyValues: [],
      graphDate:[]
    }
    let maxvalue = 0;
   
      if(kpi_daily_total && kpi_daily_total[val] ){
        Object.keys(kpi_daily_total[val]).map(value=>{
          if (moment(this.state.graphMonthChangeDate).isSame(moment(value), 'month')){
            graphData.graphDate.push(moment(value).format('ddd, '+preferred_date))
            graphData.values.push(kpi_daily_total[val][value])
            const lastYearSameDate = moment(value).subtract(365, 'days').format('YYYY-MM-DD');
            graphData.lyValues.push(kpi_daily_total[val][lastYearSameDate])
          }
        })
      }
      if(kpi_daily_stly && kpi_daily_stly[val]){
        Object.keys(kpi_daily_stly[val]).map(value=>{
          if (moment(this.state.graphMonthChangeDate).isSame(moment(value), 'month')){
            graphData.graphDate.push(moment(value).format('ddd, '+preferred_date))
            const lastYearSameDate = moment(value).subtract(365, 'days').format('YYYY-MM-DD');
            graphData.stlyValues.push(kpi_daily_stly[val][lastYearSameDate])
          }
        })
      }

    let graphSuggestedMax = (maxvalue * 1.1);    
    //Calculation => y-axis should display range (0%, 100%) -> except the occupancy > 100%. then range($0, x% + 10%) where x = max(occupancy of this month)
    //Only for Occupancy Tab
    if(this.state.tab == 1){
      let allPercentageAry = [].concat(graphData.lyValues, graphData.stlyValues, graphData.values);
      let maxPercentage = 100; //Assume default 100
      if(allPercentageAry.length > 0){
        let findMaxPercentage = Math.max(...[].concat(graphData.lyValues, graphData.stlyValues, graphData.values));
        if(findMaxPercentage > 100){
          maxPercentage = findMaxPercentage + 10;
        }
      }
      graphSuggestedMax = maxPercentage;
    }  

   
    const monthlyOptions = {
      legend: {
        labels: {
          boxWidth: 11,
        }
      } ,
      responsive: true,
      scales: {
        xAxes: [{
          barThickness: 20,
          maxBarThickness: 40,
          type: 'time',
          time: {
            parser: "ddd,"+preferred_date,
            unit: 'day',
            unitStepSize: 1,
          },
          gridLines: {
            color: '#ffffff',
            borderDash: [1, 3],
          },
        }],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              show: true,
              labelString: 'Value'
            },
            ticks: {
                suggestedMin: 0,
                suggestedMax: graphSuggestedMax,
                callback: (label, index, labels) => {
                  return (val != 'OCCUPANCY' ? this.state.currency.symbol :'') + 
                          new Intl.NumberFormat('ja-JP').format(label) + 
                          (val == 'OCCUPANCY' ? '%':'');
                }
            }
          }
        ]
      },
      tooltips: {
        enabled: true,
        mode: 'index',
        position: 'nearest',
        // displayColors: false,
        callbacks: {
          // title: function(tooltipItem, data) {
          //   let date=moment(tooltipItem[0].label,'ddd, DD/MM/YYYY').format('YYYY-MM-DD')
          //   const lastYearSameDate = moment(date).subtract(365, 'days').format('ddd, DD/MM/YYYY');
          //   if (tooltipItem[0].datasetIndex == 2) {
          //     return tooltipItem[0].label;
          //   }else if(tooltipItem[0].datasetIndex == 0) {
          //     return lastYearSameDate;
          //   }else if (tooltipItem[0].datasetIndex == 1) {
          //     return lastYearSameDate;
          //   }
          // },
          label: (tooltipItems, data) => {
            if (tooltipItems.datasetIndex == 2) {
              return t("On the Books: ") + (val == 'OCCUPANCY' ? '':this.state.currency.symbol) + new Intl.NumberFormat('ja-JP').format(parseInt(data.datasets[2].data[tooltipItems.index])) +(val == 'OCCUPANCY' ? '%':'');
            } else if (tooltipItems.datasetIndex == 0) {
              return t("Total Last Year: ") +(val == 'OCCUPANCY' ? '':this.state.currency.symbol)  + new Intl.NumberFormat('ja-JP').format(parseInt(data.datasets[0].data[tooltipItems.index]))+(val == 'OCCUPANCY' ? '%':'');
            } else if (tooltipItems.datasetIndex == 1) {
              return t("Same Time Last Year: ") + (val == 'OCCUPANCY' ? '':this.state.currency.symbol) + new Intl.NumberFormat('ja-JP').format(parseInt(data.datasets[1].data[tooltipItems.index]))+(val == 'OCCUPANCY' ? '%':'');
            }
          }
          // label: (tooltipItems, data) => {
          //   let date=moment(tooltipItems.label,'ddd, DD/MM/YYYY').format('YYYY-MM-DD')
          //   const lastYearSameDate = moment(date).subtract(1, 'years').format('YYYY-MM-DD');

            // let occupancy=kpi_daily_total['OCCUPANCY'][date]?kpi_daily_total['OCCUPANCY'][date]:'n.a'
            // let occupancyStly=(kpi_daily_stly['OCCUPANCY'][lastYearSameDate] || kpi_daily_stly['OCCUPANCY'][lastYearSameDate] != null) ?kpi_daily_stly['OCCUPANCY'][lastYearSameDate]:'n.a'
            // let revenue=kpi_daily_total['REVENUE'][date]?kpi_daily_total['REVENUE'][date]:'n.a'
            // let revenueStly=(kpi_daily_stly['REVENUE'][lastYearSameDate] || kpi_daily_stly['REVENUE'][lastYearSameDate] != null) ?kpi_daily_stly['REVENUE'][lastYearSameDate]:'n.a'
            // let adr=kpi_daily_total['ADR'][lastYearSameDate]?kpi_daily_total['ADR'][lastYearSameDate]:'n.a'
            // let adrStly=(kpi_daily_stly['ADR'][lastYearSameDate] || kpi_daily_stly['ADR'][lastYearSameDate] != null )?kpi_daily_stly['ADR'][date]:'n.a'
            // let pickup=this.state.boostPrice?this.state.boostPrice[date]?(this.state.boostPrice[date].arguments.roomsleft_change_vs_7daysago ? this.state.boostPrice[date].arguments.roomsleft_change_vs_7daysago:'n.a.' ):'n.a.':"n.a"
            // let graphVal=[];
            // if(tooltipItems.datasetIndex == 1){
            //   graphVal=[
                // 'Occupancy: '+occupancy+'%','Revenue: '+this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol+Intl.NumberFormat('ja-JP').format(revenue),'ADR: '+this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol+new Intl.NumberFormat('ja-JP').format(adr)
            //   ]
            // }else{
            //   graphVal=[
            //     'Occupancy STLY: '+occupancyStly+'%','Revenue STLY: '+this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol+Intl.NumberFormat('ja-JP').format(revenueStly),'ADR STLY: '+this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol+new Intl.NumberFormat('ja-JP').format(adrStly)
            //   ]
            // }
            // return graphVal
          // }
        }
      }
    }    

    const genData = () => ({
      labels:graphData.graphDate,
      datasets: [
        {
          type: 'line',
          label: t('Total Last Year'),
          backgroundColor: '#521383',
          borderColor: '#521383',
          borderWidth: 1,
          fill: false,
          lineTension: 0,
          data: graphData.lyValues,
          extra:graphData.graphDate,
          order: 2
        },
        {
          type: 'line',
          label: t('Same Time Last Year'),
          backgroundColor: '#f9a61e',
          borderWidth: 1,
          borderColor: '#f9a61e',
          fill: false,
          data: graphData.stlyValues,
          lineTension: 0,
          extra:graphData.graphDate,
          order: 3
        },
        {
          type: 'bar',
          label: t('On the Books'),
          backgroundColor: '#7dd5de',
          data: graphData.values,
          borderColor: 'white',
          borderWidth: 1,
          extra:graphData.graphDate,
          order: 4
        },
      ],
    })

    
    const data = genData()
    return (
      <>
        <div className={classes.graphView} >
          <Bar height={100} data={data} options={monthlyOptions} plugins={plugins} />
        </div>
      </>
    )
  }
  handleGraphClick = (graphData) => {
    if(graphData && graphData[1]){
      let date = graphData[1]._chart.config.data.labels[graphData[0]._index];
      let start = moment(date,'MMM YY').format('YYYY-MM-DD');   
      this.setState({
        graphMonthChangeDate:start,
        showFullYearGraph:false
      })
    }
  }    
  renderYearlyGraph(val) {
    const { classes } = this.props;
    const { t } = this.props;
    const { revenueBudgetVal,showFullYearGraph,performanceStly, performanceCurrent, allMonths, dateLables,yearChangeDate,kpi_daily_stly,kpi_daily_total,graphMonthChangeDate } = this.state;
    let preferred_date;
    if(this.props.hotelDetail && this.props.hotelDetail.prefered_date_format){
       preferred_date= this.props.hotelDetail.prefered_date_format.replace(', hh:mm A','')
    }else{
      preferred_date='DD/MM/YYYY'
    }
    let graphData = {
      values: [],
      lyValues: [],
      stlyValues: [],
      graphDate:[],
      revenueBudget:[],
      foreCastValues: []
    }
    
    let serviceKey = this.getServiceKey();
    let maxvalue = 0;
      if (performanceCurrent && performanceCurrent[val]) {
        allMonths.map(key=>{
          if(this.state.forecastData.forecasted_values_gross && (this.state.dashboardServices == 0 || this.state.dashboardServices == 1)){
            graphData.foreCastValues.push(this.state.forecastData.forecasted_values_gross[key]);
          }else if(this.state.forecastData.forecasted_values_net) {
            graphData.foreCastValues.push(this.state.forecastData.forecasted_values_net[key]);
          }

          graphData.values.push(performanceCurrent[val][key]);
          const lastMonthYear = moment(key).subtract(1, 'years').format('YYYY-MM');
          if(performanceCurrent[val][lastMonthYear]){
            graphData.lyValues.push(performanceCurrent[val][lastMonthYear]);
          }else{
            graphData.lyValues.push(0);
          }
          if(revenueBudgetVal[serviceKey][key]){
            graphData.revenueBudget.push(revenueBudgetVal[serviceKey][key])
          }else{
            graphData.revenueBudget.push(0)
          }
        })
        Object.keys(performanceCurrent[val]).map(key => {
          if(performanceCurrent[val][key] > maxvalue){
            maxvalue=performanceCurrent[val][key];
          }
        })
      }
      if (performanceStly && performanceStly[val]) {
        allMonths.map(key=>{
          const lastMonthYear = moment(key).subtract(1, 'years').format('YYYY-MM');
          graphData.stlyValues.push(performanceStly[val][lastMonthYear]);
        })
      }
    let graphArr=[
      {
        type: 'bar',
        label: t('On the Books'),
        backgroundColor: '#7dd5de',
        data: graphData.values,
        borderColor: 'white',
        borderWidth: 1,
        order: 4
      },
      {
      type: 'line',
      label: t('Same Time Last Year'),
      backgroundColor: '#f9a61e',
      borderWidth: 1,
      borderColor: '#f9a61e',
      fill: false,
      data: graphData.stlyValues,
      lineTension: 0,
      order: 3
      },
      {
        type: 'line',
        label: t('Total Last Year'),
        backgroundColor: '#521383',
        borderColor: '#521383',
        borderWidth: 1,
        fill: false,
        lineTension: 0,
        data: graphData.lyValues,
        order: 2
      }
    ]
  if(this.state.tab==0){
   let withBudget=[{
      type: 'line',
      label: t('Budget'),
      backgroundColor: '#837777',
      borderWidth: 1,
      borderColor: '#837777',
      fill: false,
      data: this.state.tab==0 ? graphData.revenueBudget :'',
      lineTension: 0,
      showLine:false,
      pointStyle:'dash',
      pointBorderWidth:'25',
      pointRotation:'90',
      pointHoverBorderWidth:'25',
      order: 1
    },
    ]
    graphArr=withBudget.concat(graphArr)
  }

  if(this.state.tab==0 && this.state.featuresEnabled.includes(25)){
    let foreCastService=[{
      type: 'bar',
      label: t('Forecast excl. Services'),
      backgroundColor: '#7dd5de40',
      data: graphData.foreCastValues,
      borderColor: 'white',
      borderWidth: 1,
      order: 5,
      hidden: (this.state.featuresEnabled.includes(25) ? false : true)
    }]
    graphArr=foreCastService.concat(graphArr)
  }

  const genData = () => ({
    labels:dateLables,
    datasets:graphArr
  })
    
    const data = genData()
    let tabChange=this.state.tab
    const options = {
      // maintainAspectRatio: false,
      legend: {
        labels: {
          boxWidth: 11,
        }
      },
      responsive: true,
      scales: {
        xAxes: [{
          stacked: true,
          barThickness: 35,
          maxBarThickness: 60,
          gridLines: {
            color: '#ffffff',
            borderDash: [1, 3],
          },
        }],
       
        yAxes: [
          {
            
              display: true,
              scaleLabel: {
                  show: true,
                  labelString: 'Value'
              },
              ticks: {
                  suggestedMin: 0,
                  suggestedMax: (maxvalue * 1.1),
                  callback: (label, index, labels) => {
                    return (this.state.tab != 1 ? this.state.currency.symbol:'') + new Intl.NumberFormat('ja-JP').format(label)+ (this.state.tab == 1 ?'%':'');
                  }
              }
          }
      ]
      },
      tooltips: {
        enabled: true,
        mode: 'index',
        position: 'nearest',
        callbacks: {
        //   // title: function(tooltipItem, data) {
        //   //   let date=moment(tooltipItem[0].label,'MMM YY').format('01/'+'MM/YY')
        //   //   let dates=moment(tooltipItem[0].label,'MMM YY').format('MM/YY')
        //   //   const lastYearSameDate = moment(date).subtract(1, 'years').format('DD/YY');
        //   //   if(tabChange == 0){

        //   //     if (tooltipItem[0].datasetIndex == 3 ) {
        //   //       return dates;
        //   //     }else if(tooltipItem[0].datasetIndex == 1 ) {
        //   //       return lastYearSameDate;
        //   //     }else if (tooltipItem[0].datasetIndex == 2 ) {
        //   //       return lastYearSameDate;
        //   //     }else if (tooltipItem[0].datasetIndex == 0 ) {
        //   //       return dates;
        //   //     }  
        //   //   }
        //   //   else{
        //   //      if (tooltipItem[0].datasetIndex == 3 ) {
        //   //       return dates;
        //   //     }else if(tooltipItem[0].datasetIndex == 0) {
        //   //       return lastYearSameDate;
        //   //     }else if (tooltipItem[0].datasetIndex == 1) {
        //   //       return lastYearSameDate;
        //   //     }else if (tooltipItem[0].datasetIndex == 2) {
        //   //       return dates;
        //   //     }
        //   //   }
           
        //   // },
          label: (tooltipItems, data) => {
            if(this.state.tab==0){
              // console.log(tooltipItems.datasetIndex, data.datasets)
              if (tooltipItems.datasetIndex == 2) {
                return t("On the Books: ") + (val == 'OCCUPANCY' ? '':this.state.currency.symbol)+ new Intl.NumberFormat('ja-JP').format(parseInt(data.datasets[2].data[tooltipItems.index]))+(val == 'OCCUPANCY' ? '%':'');
              } else if (tooltipItems.datasetIndex == 4) {
                return t("Total Last Year: ") + (val == 'OCCUPANCY' ? '':this.state.currency.symbol) + new Intl.NumberFormat('ja-JP').format(parseInt(data.datasets[4].data[tooltipItems.index]))+(val == 'OCCUPANCY' ? '%':'');
              } else if (tooltipItems.datasetIndex == 3) {
                return t("Same Time Last Year: ") + (val == 'OCCUPANCY' ? '':this.state.currency.symbol) + new Intl.NumberFormat('ja-JP').format(parseInt(data.datasets[3].data[tooltipItems.index]))+(val == 'OCCUPANCY' ? '%':'');
              } else if (tooltipItems.datasetIndex == 1) {
                return t("Budget: ") +this.state.currency.symbol+new Intl.NumberFormat('ja-JP').format(parseInt(data.datasets[1].data[tooltipItems.index]));
              }else if (tooltipItems.datasetIndex == 0) {
                return t("Forecast excl. Services: ") +this.state.currency.symbol+new Intl.NumberFormat('ja-JP').format(parseInt(data.datasets[0].data[tooltipItems.index]));
              }
              
            }else{
              if (tooltipItems.datasetIndex == 0) {
                return t("On the Books: ") + (val == 'OCCUPANCY' ? '':this.state.currency.symbol)+ new Intl.NumberFormat('ja-JP').format(parseInt(data.datasets[0].data[tooltipItems.index]))+(val == 'OCCUPANCY' ? '%':'');
              } else if (tooltipItems.datasetIndex == 2) {
                return t("Total Last Year: ") + (val == 'OCCUPANCY' ? '':this.state.currency.symbol) + new Intl.NumberFormat('ja-JP').format(parseInt(data.datasets[2].data[tooltipItems.index]))+(val == 'OCCUPANCY' ? '%':'');
              } else if (tooltipItems.datasetIndex == 1) {
                return t("Same Time Last Year: ") + (val == 'OCCUPANCY' ? '':this.state.currency.symbol) + new Intl.NumberFormat('ja-JP').format(parseInt(data.datasets[1].data[tooltipItems.index]))+(val == 'OCCUPANCY' ? '%':'');
              }
            }
          }
        }
      },
    } 
    return (
      <>
        <div className={classes.graphView} >
            <Bar height={100} onElementsClick={(data) => this.handleGraphClick(data)} data={data} options={options} plugins={plugins} /> 
        </div>
      </>
    )
  }
  renderBookingBehavior() {
    const { classes, t } = this.props;
    const { dataPickUp,pickupLoading } = this.state;
    let nightsSold = { value: null, absoluteDiff: null,stly:null }
    let bookings = { value: null, absoluteDiff: null,stly:null }
    let picupRevenue = { value: null, absoluteDiff: null,stly:null }
    let cancelledRevenue = {value:null,absoluteDiff:null,stly:null}
    if(this.state.showFullYearGraph && dataPickUp && dataPickUp.pickup_total && dataPickUp.pickup_total['NIGHTS_SOLD'] && dataPickUp.pickup_total['NUMBER_OF_BOOKINGS'] && dataPickUp.pickup_total['REVENUE'] ){
      if(this.state.bookingTabs == 0){
        nightsSold.value=dataPickUp.pickup_total['NIGHTS_SOLD'].pickup_yesterday
        bookings.value=dataPickUp.pickup_total['ADR'].pickup_yesterday
        picupRevenue.value=dataPickUp.pickup_total['REVENUE'].pickup_yesterday
        cancelledRevenue.value= this.state.featuresEnabled.includes(25) ? dataPickUp.pickup_total['CANCELLED_REVENUE'].pickup_yesterday:''

        nightsSold.stly= dataPickUp.pickup_stly ? dataPickUp.pickup_stly['NIGHTS_SOLD'].pickup_yesterday: null
        bookings.stly= dataPickUp.pickup_stly ? dataPickUp.pickup_stly['ADR'].pickup_yesterday: null
        picupRevenue.stly= dataPickUp.pickup_stly ? dataPickUp.pickup_stly['REVENUE'].pickup_yesterday: null
        cancelledRevenue.stly=this.state.featuresEnabled.includes(25) ?  dataPickUp.pickup_stly ? dataPickUp.pickup_stly['CANCELLED_REVENUE'].pickup_yesterday : null :''

      } if(this.state.bookingTabs == 1){
        nightsSold.value=dataPickUp.pickup_total['NIGHTS_SOLD'].pickup_last_7_days
        bookings.value=dataPickUp.pickup_total['ADR'].pickup_last_7_days
        picupRevenue.value=dataPickUp.pickup_total['REVENUE'].pickup_last_7_days
        cancelledRevenue.value=this.state.featuresEnabled.includes(25) ? dataPickUp.pickup_total['CANCELLED_REVENUE'].pickup_last_7_days:''

        nightsSold.stly= dataPickUp.pickup_stly ? dataPickUp.pickup_stly['NIGHTS_SOLD'].pickup_last_7_days : null
        bookings.stly= dataPickUp.pickup_stly ? dataPickUp.pickup_stly['ADR'].pickup_last_7_days : null
        picupRevenue.stly= dataPickUp.pickup_stly ? dataPickUp.pickup_stly['REVENUE'].pickup_last_7_days : null
        cancelledRevenue.stly=this.state.featuresEnabled.includes(25) ?  dataPickUp.pickup_stly ? dataPickUp.pickup_stly['CANCELLED_REVENUE'].pickup_last_7_days : null :''

      } if(this.state.bookingTabs == 2){
        nightsSold.value=dataPickUp.pickup_total['NIGHTS_SOLD'].pickup_last_30_days
        bookings.value=dataPickUp.pickup_total['ADR'].pickup_last_30_days
        picupRevenue.value=dataPickUp.pickup_total['REVENUE'].pickup_last_30_days
        cancelledRevenue.value=this.state.featuresEnabled.includes(25) ? dataPickUp.pickup_total['CANCELLED_REVENUE'].pickup_last_30_days :''

        nightsSold.stly= dataPickUp.pickup_stly ? dataPickUp.pickup_stly['NIGHTS_SOLD'].pickup_last_30_days : null
        bookings.stly= dataPickUp.pickup_stly ? dataPickUp.pickup_stly['ADR'].pickup_last_30_days : null
        picupRevenue.stly= dataPickUp.pickup_stly ? dataPickUp.pickup_stly['REVENUE'].pickup_last_30_days : null
        cancelledRevenue.stly=this.state.featuresEnabled.includes(25) ?  dataPickUp.pickup_stly ? dataPickUp.pickup_stly['CANCELLED_REVENUE'].pickup_last_30_days : null : ''

      }if(this.state.bookingTabs == 3){
        nightsSold.value=dataPickUp.pickup_total['NIGHTS_SOLD'].pickup_last_90_days
        bookings.value=dataPickUp.pickup_total['ADR'].pickup_last_90_days
        picupRevenue.value=dataPickUp.pickup_total['REVENUE'].pickup_last_90_days
        cancelledRevenue.value=this.state.featuresEnabled.includes(25) ? dataPickUp.pickup_total['CANCELLED_REVENUE'].pickup_last_90_days :''

        nightsSold.stly= dataPickUp.pickup_stly ? dataPickUp.pickup_stly['NIGHTS_SOLD'].pickup_last_90_days : null
        bookings.stly= dataPickUp.pickup_stly ? dataPickUp.pickup_stly['ADR'].pickup_last_90_days : null
        picupRevenue.stly= dataPickUp.pickup_stly ? dataPickUp.pickup_stly['REVENUE'].pickup_last_90_days : null
        cancelledRevenue.stly=this.state.featuresEnabled.includes(25) ?  dataPickUp.pickup_stly ? dataPickUp.pickup_stly['CANCELLED_REVENUE'].pickup_last_90_days : null :''

      }
      nightsSold.absoluteDiff= nightsSold.stly > 0 ? (nightsSold.value - nightsSold.stly) :0;
      bookings.absoluteDiff= bookings.stly > 0 ? (bookings.value - bookings.stly) : 0;
      picupRevenue.absoluteDiff= picupRevenue.stly > 0 ? ( Math.round(((picupRevenue.value / picupRevenue.stly)-1)*100)):0;
      cancelledRevenue.absoluteDiff=cancelledRevenue.stly > 0 ? (cancelledRevenue.value - cancelledRevenue.stly) :0;
    } 

    if(!this.state.showFullYearGraph && dataPickUp && dataPickUp.pickup_monthly_total && dataPickUp.pickup_monthly_total['NIGHTS_SOLD'] && dataPickUp.pickup_monthly_total['NUMBER_OF_BOOKINGS'] && dataPickUp.pickup_monthly_total['REVENUE'] ){
      let month = moment(this.state.graphMonthChangeDate).format("YYYY-MM");
      let lastYearMonth = moment(this.state.graphMonthChangeDate).subtract(1,"year").format("YYYY-MM");

      // console.log("xxxxxxxx", month,        lastYearMonth)
      if(this.state.bookingTabs == 0){
        // console.log("xxxxx",dataPickUp.pickup_monthly_total['NIGHTS_SOLD'].pickup_yesterday, month )
        nightsSold.value=dataPickUp.pickup_monthly_total['NIGHTS_SOLD'].pickup_yesterday[month]
        bookings.value=dataPickUp.pickup_monthly_total['ADR'].pickup_yesterday[month]
        picupRevenue.value=dataPickUp.pickup_monthly_total['REVENUE'].pickup_yesterday[month]
        cancelledRevenue.value= this.state.featuresEnabled.includes(25) ? dataPickUp.pickup_monthly_total['CANCELLED_REVENUE'].pickup_yesterday[month]:''

        nightsSold.stly= dataPickUp.pickup_monthly_stly ? dataPickUp.pickup_monthly_stly['NIGHTS_SOLD'].pickup_yesterday[lastYearMonth] : null
        bookings.stly= dataPickUp.pickup_monthly_stly ? dataPickUp.pickup_monthly_stly['ADR'].pickup_yesterday[lastYearMonth] : null
        picupRevenue.stly= dataPickUp.pickup_monthly_stly ? dataPickUp.pickup_monthly_stly['REVENUE'].pickup_yesterday[lastYearMonth] : null
        cancelledRevenue.stly=this.state.featuresEnabled.includes(25) ? dataPickUp.pickup_monthly_stly ? dataPickUp.pickup_monthly_stly['CANCELLED_REVENUE'].pickup_yesterday[lastYearMonth] : null :''

      } if(this.state.bookingTabs == 1){
        nightsSold.value=dataPickUp.pickup_monthly_total['NIGHTS_SOLD'].pickup_last_7_days[month]
        bookings.value=dataPickUp.pickup_monthly_total['ADR'].pickup_last_7_days[month]
        picupRevenue.value=dataPickUp.pickup_monthly_total['REVENUE'].pickup_last_7_days[month]
        cancelledRevenue.value=this.state.featuresEnabled.includes(25) ? dataPickUp.pickup_monthly_total['CANCELLED_REVENUE'].pickup_last_7_days[month]:''

        nightsSold.stly= dataPickUp.pickup_monthly_stly ? dataPickUp.pickup_monthly_stly['NIGHTS_SOLD'].pickup_last_7_days[lastYearMonth] : null
        bookings.stly= dataPickUp.pickup_monthly_stly ? dataPickUp.pickup_monthly_stly['ADR'].pickup_last_7_days[lastYearMonth] : null
        picupRevenue.stly= dataPickUp.pickup_monthly_stly ? dataPickUp.pickup_monthly_stly['REVENUE'].pickup_last_7_days[lastYearMonth] : null
        cancelledRevenue.stly=this.state.featuresEnabled.includes(25) ? dataPickUp.pickup_monthly_stly ? dataPickUp.pickup_monthly_stly['CANCELLED_REVENUE'].pickup_last_7_days[lastYearMonth] : null :''

      } if(this.state.bookingTabs == 2){
        nightsSold.value=dataPickUp.pickup_monthly_total['NIGHTS_SOLD'].pickup_last_30_days[month]
        bookings.value=dataPickUp.pickup_monthly_total['ADR'].pickup_last_30_days[month]
        picupRevenue.value=dataPickUp.pickup_monthly_total['REVENUE'].pickup_last_30_days[month]
        cancelledRevenue.value=this.state.featuresEnabled.includes(25) ? dataPickUp.pickup_monthly_total['CANCELLED_REVENUE'].pickup_last_30_days[month] :''

        nightsSold.stly= dataPickUp.pickup_monthly_stly ? dataPickUp.pickup_monthly_stly['NIGHTS_SOLD'].pickup_last_30_days[lastYearMonth] : null
        bookings.stly= dataPickUp.pickup_monthly_stly ? dataPickUp.pickup_monthly_stly['ADR'].pickup_last_30_days[lastYearMonth] : null
        picupRevenue.stly= dataPickUp.pickup_monthly_stly ? dataPickUp.pickup_monthly_stly['REVENUE'].pickup_last_30_days[lastYearMonth] : null
        cancelledRevenue.stly=this.state.featuresEnabled.includes(25) ? dataPickUp.pickup_monthly_stly ? dataPickUp.pickup_monthly_stly['CANCELLED_REVENUE'].pickup_last_30_days[lastYearMonth] : null :''

      }if(this.state.bookingTabs == 3){
        nightsSold.value=dataPickUp.pickup_monthly_total['NIGHTS_SOLD'].pickup_last_90_days[month]
        bookings.value=dataPickUp.pickup_monthly_total['ADR'].pickup_last_90_days[month]
        picupRevenue.value=dataPickUp.pickup_monthly_total['REVENUE'].pickup_last_90_days[month]
        cancelledRevenue.value=this.state.featuresEnabled.includes(25) ? dataPickUp.pickup_monthly_total['CANCELLED_REVENUE'].pickup_last_90_days[month] :''

        nightsSold.stly= dataPickUp.pickup_monthly_stly ? dataPickUp.pickup_monthly_stly['NIGHTS_SOLD'].pickup_last_90_days[lastYearMonth] : null
        bookings.stly= dataPickUp.pickup_monthly_stly ? dataPickUp.pickup_monthly_stly['ADR'].pickup_last_90_days[lastYearMonth] : null
        picupRevenue.stly= dataPickUp.pickup_monthly_stly ? dataPickUp.pickup_monthly_stly['REVENUE'].pickup_last_90_days[lastYearMonth] : null
        cancelledRevenue.stly=this.state.featuresEnabled.includes(25) ? dataPickUp.pickup_monthly_stly ? dataPickUp.pickup_monthly_stly['CANCELLED_REVENUE'].pickup_last_90_days[lastYearMonth] : null :''

      }
      nightsSold.absoluteDiff= nightsSold.stly > 0 ? (nightsSold.value - nightsSold.stly) :0;
      bookings.absoluteDiff= bookings.stly > 0 ? (bookings.value - bookings.stly) : 0;
      picupRevenue.absoluteDiff= picupRevenue.stly > 0 ? ( Math.round(((picupRevenue.value / picupRevenue.stly)-1)*100)):0;
      cancelledRevenue.absoluteDiff=cancelledRevenue.stly > 0 ? (cancelledRevenue.value - cancelledRevenue.stly) :0;
 
    }
    return (
      <>
        <Grid item xs={12} md={3} lg={3} style={{ marginTop: '15px' }}>
          <Card variant="outlined" className={classes.cardBorder} >
            <Grid container style={{ padding: '17px 15px' }} >
              <Grid item xs={12}>
                <Typography className={classes.performanceTitle} >{t('Nights sold')}</Typography>
              </Grid>
              <Grid item xs={12} className={classes.flexCenter} >
                <span className={classes.performancePrice}>
                {nightsSold.value != null ? nightsSold.value : '0'}
                </span>
                {
                  nightsSold.absoluteDiff ?
                  <Tooltip
                      classes={{ popper: classes.htmlPopper, tooltip: classes.htmlTooltip, }}
                      title={<Typography className={classes.htmlTooltip}>{t('vs Same Time Last Year')}</Typography>}>
                    {(nightsSold.absoluteDiff >= 0 ?
                      <span className={[classes.upDownPickUp]}>
                        <span className={classes.upDownText} style={{ background: '#BBF2DD', }}>
                          <Icon className={[classes.iconStyle, classes.successColor]}>arrow_drop_up</Icon>
                          <span className={[classes.successColor]} style={{ fontSize: '14px', marginLeft: '-3px' }}>{' +' + nightsSold.absoluteDiff}</span>
                        </span>
                        {/* // <span className={classes.vsStyle} >vs. STLY</span> */}
                      </span>
                      : <span className={classes.upDownPickUp}>
                        <span className={classes.upDownText} style={{ background: '#FAC6D1' }}>
                          <Icon className={[classes.iconStyle, classes.redColor]}>arrow_drop_down</Icon>
                          <span className={[classes.redColor]} style={{ fontSize: '14px', marginLeft: '-3px' }}>{'' + nightsSold.absoluteDiff}</span>
                        </span>
                        {/* // <span className={classes.vsStyle} >vs. STLY</span> */}
                      </span>
                    )}</Tooltip>
                    : " "
                }
              </Grid>
              <Grid item xs={12}><Typography className={classes.grayText}>{t("Same Time Last Year")}</Typography></Grid>
              <Grid item xs={12}>
                <span className={classes.normalPrice}>
                {/* {pickupLoading ? 
                  <Skeleton  width={200}  /> : */}
                  {nightsSold.stly != null ? nightsSold.stly : '0'}
                </span>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={3} lg={3} style={{ marginTop: '15px' }}>
          <Card variant="outlined" className={classes.cardBorder}>
            <Grid container style={{ padding: '17px 15px' }} >
              <Grid item xs={12}>
                <Typography className={classes.performanceTitle} >{t("ADR")}</Typography>
              </Grid>
              <Grid item xs={12} className={classes.flexCenter} >
                <span className={classes.performancePrice}>
                {this.state.currency.symbol}{ bookings.value != null ? new Intl.NumberFormat('ja-JP').format(Math.round(bookings.value))  : '0'}
                </span>
                {
                  bookings.absoluteDiff ?
                  <Tooltip
                      classes={{ popper: classes.htmlPopper, tooltip: classes.htmlTooltip, }}
                      title={<Typography className={classes.htmlTooltip}>{t('vs Same Time Last Year')}</Typography>}>
                    {(bookings.absoluteDiff >= 0 ?
                      <span className={classes.upDownPickUp}>
                        <span className={classes.upDownText} style={{ background: '#BBF2DD' }}>
                          <Icon className={[classes.iconStyle, classes.successColor]}>arrow_drop_up </Icon>
                          <span className={[classes.successColor]} style={{ fontSize: '14px', marginLeft: '-3px' }}>{' +' + new Intl.NumberFormat('ja-JP').format(Math.round(bookings.absoluteDiff)) }</span>
                        </span>
                        {/* <span className={classes.vsStyle} >vs. STLY</span> */}
                      </span>
                      : <span className={classes.upDownPickUp}>
                        <span className={classes.upDownText} style={{ background: '#FAC6D1' }}>
                          <Icon className={[classes.redColor, classes.iconStyle]}>arrow_drop_down</Icon>
                          <span className={[classes.redColor]} style={{ fontSize: '14px', marginLeft: '-3px' }}> {'' + new Intl.NumberFormat('ja-JP').format(bookings.absoluteDiff)}</span>
                        </span>
                        {/* <span className={classes.vsStyle} >vs. STLY</span> */}
                      </span>
                    )}</Tooltip>
                    : " "
                }
              </Grid>
              <Grid item xs={12}><Typography className={classes.grayText}>{t("Same Time Last Year")}</Typography></Grid>
              <Grid item xs={12}>
                <span className={classes.normalPrice}>
                {this.state.currency.symbol}{bookings.stly != null ?  new Intl.NumberFormat('ja-JP').format(bookings.stly) : '0'}
                </span>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={3} lg={3} style={{ marginTop: '15px' }}>
          <Card variant="outlined" className={classes.cardBorder}>
            <Grid container style={{ padding: '17px 15px' }} >
              <Grid item xs={12}>
                <Typography className={classes.performanceTitle}>{t("Pickup Revenue")}</Typography>
              </Grid>
              <Grid item xs={12} className={classes.flexCenter} >
                <span className={classes.performancePrice}>
                {this.state.currency.symbol}{ 
                 
                  (picupRevenue.value != null && picupRevenue.value != "n.a.")  ? new Intl.NumberFormat('ja-JP').format(picupRevenue.value) : '0'}
                </span>
                {
                  picupRevenue.absoluteDiff ?
                  <Tooltip
                      classes={{ popper: classes.htmlPopper, tooltip: classes.htmlTooltip, }}
                      title={<Typography className={classes.htmlTooltip}>{t('vs Same Time Last Year')}</Typography>}>
                    {(picupRevenue.absoluteDiff >= 0 ?
                      <span className={classes.upDownPickUp}>
                        <span className={classes.upDownText} style={{ background: '#BBF2DD' }}>
                          <Icon className={[classes.successColor, classes.iconStyle]} >arrow_drop_up</Icon>
                          <span className={[classes.successColor]} style={{ fontSize: '14px', marginLeft: '-3px' }}>{' +' + new Intl.NumberFormat('ja-JP').format(picupRevenue.absoluteDiff) + '%'}</span>
                        </span>
                        {/* <span className={classes.vsStyle} >vs. STLY</span> */}
                      </span>
                      : <span className={classes.upDownPickUp}>
                        <span className={classes.upDownText} style={{ background: '#FAC6D1' }}>
                          <Icon className={[classes.redColor, classes.iconStyle]}>arrow_drop_down</Icon>
                          <span className={[classes.redColor]} style={{ fontSize: '14px', marginLeft: '-3px' }}> {'' + new Intl.NumberFormat('ja-JP').format(picupRevenue.absoluteDiff) + '%'}</span>
                        </span>
                        {/* <span className={classes.vsStyle} >vs. STLY</span> */}
                      </span>
                    )}</Tooltip>
                    : " "
                }
              </Grid>
              <Grid item xs={12}><Typography className={classes.grayText}>{t("Same Time Last Year")}</Typography></Grid>
              <Grid item xs={12}>
                <span className={classes.normalPrice}>
                  {this.state.currency.symbol}{
                    picupRevenue.stly != null ?   new Intl.NumberFormat('ja-JP').format(picupRevenue.stly) : '0'
                  }
                </span>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {this.state.featuresEnabled.includes(25) &&
          <Grid item xs={12} md={3} lg={3} style={{ marginTop: '15px' }}>
            <Card variant="outlined" className={classes.cardBorder}>
              <Grid container style={{ padding: '17px 15px' }} >
                <Grid item xs={12}>
                  <Typography className={classes.performanceTitle}>{t("Cancelled Revenue")}</Typography>
                </Grid>
                <Grid item xs={12} className={classes.flexCenter} >
                  <span className={classes.performancePrice}>
                  {this.state.currency.symbol}{
                    (cancelledRevenue.value != null && cancelledRevenue.value != "n.a.")  ?  new Intl.NumberFormat('ja-JP').format(cancelledRevenue.value) : '0'}
                  </span>
                  {
                    cancelledRevenue.absoluteDiff ?
                    <Tooltip
                        classes={{ popper: classes.htmlPopper, tooltip: classes.htmlTooltip, }}
                        title={<Typography className={classes.htmlTooltip}>{t('vs Same Time Last Year')}</Typography>}>
                      {(picupRevenue.absoluteDiff >= 0 ?
                        <span className={classes.upDownPickUp}>
                          <span className={classes.upDownText} style={{ background: '#BBF2DD' }}>
                            <Icon className={[classes.successColor, classes.iconStyle]} >arrow_drop_up</Icon>
                            <span className={[classes.successColor]} style={{ fontSize: '14px', marginLeft: '-3px' }}>{' +' + new Intl.NumberFormat('ja-JP').format(cancelledRevenue.absoluteDiff) + '%'}</span>
                          </span>
                          {/* <span className={classes.vsStyle} >vs. STLY</span> */}
                        </span>
                        : <span className={classes.upDownPickUp}>
                          <span className={classes.upDownText} style={{ background: '#FAC6D1' }}>
                            <Icon className={[classes.redColor, classes.iconStyle]}>arrow_drop_down</Icon>
                            <span className={[classes.redColor]} style={{ fontSize: '14px', marginLeft: '-3px' }}> {'' + new Intl.NumberFormat('ja-JP').format(cancelledRevenue.absoluteDiff) + '%'}</span>
                          </span>
                          {/* <span className={classes.vsStyle} >vs. STLY</span> */}
                        </span>
                      )}</Tooltip>
                      : " "
                  }
                </Grid>
                <Grid item xs={12}><Typography className={classes.grayText}>{t("Same Time Last Year")}</Typography></Grid>
                <Grid item xs={12}>
                  <span className={classes.normalPrice}>
                    {this.state.currency.symbol}{ cancelledRevenue.stly != null ?   new Intl.NumberFormat('ja-JP').format(cancelledRevenue.stly) : '0'
                    }
                  </span>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        }
      </>
    )
  }
  updatePurgeReservationModal = () => {
    this.setState({
      purgeReservationModal: true
    })
  }
  updatePurgeReservationModalClose = () => {
    this.setState({ purgeReservationModal: false })
  }
  updatePurgeReservation = () => {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
    };
    this.props.getPurgeReservation(requestParam);
    this.setState({ purgeReservationModal: false })
  }

  updateReservation = () => {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
    };
    this.props.getLatestReservation(requestParam);
  }
  processAllReservation = () => {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
    };
    this.props.getProcessAllReservation(requestParam);
  }
  changeView = () => {
    let requestParam = {}
    this.setState({
      viewType: !this.state.viewType
    }, () => {
      requestParam = {
        token: this.props.hotelAuthReducer.hotelDetails.token,
        include_in_hourly_execution: this.state.viewType,
      };
      this.props.getHourlyExecution(requestParam);
    })
  }
  getPerformanceValue = (data, isOcc, isLastYear) => {
    let valueStly = "n.a."
    const currencySymbol = this.state.currency.symbol
    let monthChangeDate;
    let lastMonthYear;
    if(this.state.showFullYearGraph){
      monthChangeDate = moment(this.state.yearChangeDate).format('YYYY');
      lastMonthYear = moment(this.state.yearChangeDate).subtract(1, 'years').format('YYYY');
    }else{
      monthChangeDate = moment(this.state.graphMonthChangeDate).format('YYYY-MM');
      lastMonthYear = moment(this.state.graphMonthChangeDate).subtract(1, 'years').format('YYYY-MM');
    }
   
    data && Object.keys(data).forEach(function (key) {
      if (key === (isLastYear ? lastMonthYear : monthChangeDate)) {
        valueStly = isOcc ? (new Intl.NumberFormat('ja-JP').format(data[key]) + "%") : (currencySymbol + new Intl.NumberFormat('ja-JP').format(data[key]))
      }
    })
    
    return valueStly
  }

  getUpDownPerformanceValue = (totalData, sameLastData,name) => {
    let total = 0;
    let sameLastYear = 0
    let monthChangeDate;
    let lastMonthChangeDate;
   if(this.state.showFullYearGraph){
     monthChangeDate = moment(this.state.yearChangeDate).format('YYYY');
     lastMonthChangeDate=moment(this.state.yearChangeDate).subtract(1, 'years').format('YYYY');
   }else{
     monthChangeDate = moment(this.state.graphMonthChangeDate).format('YYYY-MM');
     lastMonthChangeDate=moment(this.state.graphMonthChangeDate).subtract(1, 'years').format('YYYY-MM');
   }
    
    totalData && Object.keys(totalData).forEach(function (key) {
      if (key === monthChangeDate) {
        total = totalData[key]
      }
    })
    sameLastData && Object.keys(sameLastData).forEach(function (key) {
      if (key === lastMonthChangeDate) {
        sameLastYear = sameLastData[key]
      }
    })
    let calValue;
    if(name == "REVENUE"){
       calValue = Math.round(((total / sameLastYear) - 1) * 100)
    }else{
       calValue = Math.round((total - sameLastYear))
    }
    return Number.isFinite(calValue) ? calValue : null
  }

  downloadCSV = () => {
    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
    axios.get(Server.API + 'performancedashboard/reservation-kpi-csv/?date_range=dashboard_kpis_full_date_range')
      .then((response) => {
        if (response.data) {
          var received_msg = response.data;
          if (received_msg) {
            var link = document.createElement('a');
            link.download = "kpi_table_daily.csv";
            link.href = 'data:text/csv;charset=utf-8,' + escape(received_msg);
            document.body.appendChild(link);
            link.click();
          }
        }
      })
  }
  downloadPdf = () => {
   let input=document.getElementById('divPrint');
   window.scrollTo(0, 0)
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png',1);
        const pdf = new jsPDF();
        let width = pdf.internal.pageSize.getWidth()
        let height = pdf.internal.pageSize.getHeight()
        
        let widthRatio = width / canvas.width
        let heightRatio = height / canvas.height
        
        let ratio = widthRatio > heightRatio ? heightRatio :  widthRatio
      
        pdf.addImage(
          imgData,
          'PNG',
          0,
          0,
          canvas.width * ratio,
          canvas.height * ratio,
        )
        pdf.setFillColor("#000")

        // const pdf = new jsPDF("p");
        // pdf.setFillColor(204, 204,204,0);
        // pdf.rect(10, 10, 150, 160, "F");
        // pdf.setFillColor(245,246,248,1);
        // const imgProps= pdf.getImageProperties(imgData);
        // const pdfWidth = pdf.internal.pageSize.getWidth();
        // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        // pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);


        // const imgData = canvas.toDataURL('image/jpeg');
        // const pdf = new jsPDF("p", "mm", "a4");
        // // var width = 580;
        // // var height = 800;
        // var width = pdf.internal.pageSize.getWidth();
        // var height = pdf.internal.pageSize.getHeight();
        // pdf.addImage(imgData, 'JPEG',0,0, width,height);
        pdf.save("Performance_Dashboard_"+this.props.hotelAuthReducer.hotelDetails.hotel.name+"_"+moment(this.state.graphMonthChangeDate).format('YYYY-MM')+".pdf");
      })
    ;

  }

  resetSnowflake = () => {
    this.setState({
      loading: true
    })
    
    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
    axios.post(Server.API + 'performancedashboard/reset-snowflake-and-postgres-data/')
        .then((response) => {
            let messageData = {
                msg: response.data.Message,
                is_success: true,
            }
            this.props.putNotify(messageData)
        }).catch((error) => {            
            let messageData = {
                msg: 'Something Went Wrong!',
                is_success: false,
            }
            this.props.putNotify(messageData)
        }).then(() => {
          this.setState({
            loading: false
          })
        });    
  }
  removePostgres = () => {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
    };
    this.props.removePostgress(requestParam);
        
  }
  
  fullYearGraph = (val) => {
    this.setState({
      showFullYearGraph:val
    })      
  }

  getServiceKey = () => {
    switch (this.state.dashboardServices) {
      case 0:
        return "revenue_budget_gross_excl_services"
      case 1:
        return "revenue_budget_gross_incl_services"
      case 2:
        return "revenue_budget_net_incl_services"
      case 3:
        return "revenue_budget_net_excl_services"
      default:
        return null
    }
  }

  setAdr =() =>{

    let monthlyTargetOccupancy=this.state.monthlyTargetOccupancy
    // let defaultBudget=this.state.defaultBudget
    let totalRooms=this.state.totalRooms
    // let targetOccu={}
    let budgetObj={};

    let key = this.getServiceKey();
    let budget = this.state.revenueBudgetVal[key] || {};

    this.state.allMonths.map((val, index)=>{
      // monthlyTargetOccupancy.map(occ=>{
      //   targetOccu[val]=occ
      // })

      let budgetValue = (budget && budget[val])?parseInt(budget[val]):0;
      let daysInMonth = moment(val, "YYYY-MM").daysInMonth()      
      // let defaultBudgetValue = defaultBudget[val] ? defaultBudget[val].dashboard_default_budget : 0 //it will change
      let defaultBudgetValue = 0 //it will change
      let adr = (budgetValue / (daysInMonth * totalRooms * monthlyTargetOccupancy[index]) * 100);  
      // console.log(monthlyTargetOccupancy, budgetValue, daysInMonth ,totalRooms, monthlyTargetOccupancy[index])   
      let lastYearDate=moment(val).subtract(1, 'years').format('YYYY-MM');
      
      let key = 'REVENUE';

      if(this.state.dashboardServices == 0){
        key = 'REVENUE';
      }else if(this.state.dashboardServices == 1){
        key = 'REVENUE_INCL_SERVICES';
      }else if(this.state.dashboardServices == 2){
        key = 'REVENUE_NET_INCL_SERVICES';
      }else if(this.state.dashboardServices == 3){
        key = 'REVENUE_NET';
      }

      let lastYearRevenueTotal = 0;

      if(this.state.performanceCurrent && this.state.performanceCurrent[key]){
        lastYearRevenueTotal = this.state.performanceCurrent[key][lastYearDate];
      }
      
      let Obj={
        lastYearRevenue: lastYearRevenueTotal,
        defaultBudget: defaultBudgetValue,
        totalRooms: totalRooms,
        targetOccupancy: monthlyTargetOccupancy[index],
        daysInMonth: daysInMonth,
        adr: adr,
        revenueBudget: budgetValue
      }
      
      budgetObj[val]=Obj
    })
    this.setState({
      budgetObj
    })
  }
  setBudgetModal = () =>{
    this.setAdr();
    this.setState({
      openBudgetModal:true
    })
  }
  closeBudgetModal  = () =>{
    this.setState({
      openBudgetModal:!this.state.openBudgetModal
    })
  }
  handleRevenueBudget = (e,date) =>{
    let budgetObj=this.state.budgetObj
    let revenueBudget=parseInt(e.target.value)
    let key = this.getServiceKey();
    let revenuebud = this.state.revenueBudgetVal[key] || {}

    // let defaultBudget=this.state.defaultBudget
    let adr;
    // if(budgetObj[date]  && defaultBudget[date]){
    //   // adr= ((parseInt(this.state.revenueBudgetVal[val]))/defaultBudget[val].budget_expected_rooms_sold)
    //   // let adr=((revenueBudget*100)/(budgetObj[date].daysInMonth * budgetObj[date].targetOccupancy * budgetObj[date].totalRooms))
    //   adr=((revenueBudget)/defaultBudget[date]?.budget_expected_rooms_sold??0)      
    //   budgetObj[date].revenueBudget=revenueBudget
    //   budgetObj[date].adr=adr
    // }else{
      adr= ((revenueBudget)/(budgetObj[date].totalRooms*(budgetObj[date].targetOccupancy/100)))
      budgetObj[date].revenueBudget=revenueBudget
      budgetObj[date].adr=adr
    // }

    revenuebud[date]=revenueBudget

    this.setState({
      budgetObj,
      revenueBudgetVal: {
        ...this.state.revenueBudgetVal, 
        [key]: revenuebud
      }
    },()=>this.setAdr())
  }
  
  handleSetBudget = ()=> {
    
    let setting = this.props.priceSettingReducer.priceSetting;
    
    if(!setting.revenue_budget) {
      setting.revenue_budget = {}
    }


    setting.revenue_budget = this.state.revenueBudgetVal;

    let requestData = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      dateSetting: setting,
    };
    
    this.props.updatePricingSettings(requestData);
    this.props.updateForecastData({
      token: this.props.hotelAuthReducer.hotelDetails.token,
    });
    this.closeBudgetModal();

  }
  handleClose = (val) => {
    if(val==1){
      this.downloadCSV()
    }else if(val == 2){
      this.downloadPdf()
    }
    this.setState({ anchorEl: null });
  };
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  changeTab = val =>{
    this.setState({
      selectedTab:val
    })
  }

  manageLegend = (legendItem) => {

    let refs = [
      this.myChartRef,
      this.myChartRef0,
      this.myChartRef2,
      this.myChartRef3,
      this.myChartRef4,
      this.myChartRef5
    ];

    refs.map(val => {
      let chartInstance = val.current.chartInstance;                
      var index = legendItem.datasetIndex;
      var ci = chartInstance;
      var meta = ci.getDatasetMeta(index);
  
      // See controller.isDatasetVisible comment
      meta.hidden = meta.hidden === null? !ci.data.datasets[index].hidden : null;
  
      // We hid a dataset ... rerender the chart
      ci.update();
    })


  }

 
  segmentOrder = (analyticsDropdownVal, segmentName) => {
  let orders = {
    segment1: [2, 8, 3, 2, 2],
    segment2: [3, 2, 2, 3, 3],
    segment3: [4, 4, 4, 4, 4],
    segment4: [5, 3, 5, 5, 5],
    segment5: [6, 6, 6, 6, 6],
    segment6: [7, 5, 7, 7, 7],
    segment7: [8, 7, 8, 8, 8],
    segment8: [9, 9, 9, 9, 9],
    segment9: [10, 10, 10, 10, 10],
    segment10: [11, 11, 11, 11, 11]
  };
  
  let orderNumber = orders[segmentName];
  
  if (orderNumber) {
    return orderNumber[analyticsDropdownVal] || 0;
  } else {
    return 0;
  }
}

  revenueGraph=()=>{
    const { classes } = this.props;
    const { t } = this.props;
    const currencySymbol = this.state.currency.symbol
    const {analyticsAllMonths, dateLables,channelAnalytics,segmentAnalytics,roomtypeAnalytics,weekdayAnalytics,analyticsDropdownVal, customSegmentAnalytics } = this.state;
    let revenue=null
    let title = "";
    let graphData = {
      total: [],
      segment1:[],
      segment2:[],
      segment3:[],
      segment4:[],
      segment5:[],
      segment6:[],
      segment7:[],
      segment8:[],
      segment9:[],
      segment10:[],
      nameSegment1:'',
      nameSegment2:'',
      nameSegment3:'',
      nameSegment4:'',
      nameSegment5:'',
      nameSegment6:'',
      nameSegment7:'',
      nameSegment8:'',
      nameSegment9:'',
      nameSegment10:'',
      graphDate:[]
    }
    
    if(analyticsDropdownVal == 0 ){
      title = t("Revenue per Segment");
      revenue=segmentAnalytics.report_table_monthly
      graphData.nameSegment1=segmentAnalytics.name?.subtotal_0
      graphData.nameSegment2=segmentAnalytics.name?.subtotal_1
      graphData.nameSegment3=segmentAnalytics.name?.subtotal_2
      graphData.nameSegment4=segmentAnalytics.name?.subtotal_3
      graphData.nameSegment5=segmentAnalytics.name?.subtotal_4
      graphData.nameSegment6=segmentAnalytics.name?.subtotal_5
      graphData.nameSegment7=segmentAnalytics.name?.subtotal_6      
      graphData.nameSegment8=segmentAnalytics.name?.subtotal_7
      graphData.nameSegment9=segmentAnalytics.name?.subtotal_8
      graphData.nameSegment10=segmentAnalytics.name?.subtotal_9

    }else if(analyticsDropdownVal == 1){
      title = t("Revenue per Room Type");
      revenue=roomtypeAnalytics.report_table_monthly
      graphData.nameSegment1=roomtypeAnalytics.name?.subtotal_0
      graphData.nameSegment2=roomtypeAnalytics.name?.subtotal_1
      graphData.nameSegment3=roomtypeAnalytics.name?.subtotal_2
      graphData.nameSegment4=roomtypeAnalytics.name?.subtotal_3
      graphData.nameSegment5=roomtypeAnalytics.name?.subtotal_4
      graphData.nameSegment6=roomtypeAnalytics.name?.subtotal_5
      graphData.nameSegment7=roomtypeAnalytics.name?.subtotal_6
      graphData.nameSegment8=roomtypeAnalytics.name?.subtotal_7
      graphData.nameSegment9=roomtypeAnalytics.name?.subtotal_8
      graphData.nameSegment10=roomtypeAnalytics.name?.subtotal_9

    }else if(analyticsDropdownVal == 2){
      
      title = t("Revenue per Channel");
      revenue=channelAnalytics.report_table_monthly
      graphData.nameSegment1=channelAnalytics.name?.subtotal_0
      graphData.nameSegment2=channelAnalytics.name?.subtotal_1
      graphData.nameSegment3=channelAnalytics.name?.subtotal_2
      graphData.nameSegment4=channelAnalytics.name?.subtotal_3
      graphData.nameSegment5=channelAnalytics.name?.subtotal_4
      graphData.nameSegment6=channelAnalytics.name?.subtotal_5
      graphData.nameSegment7=channelAnalytics.name?.subtotal_6
      graphData.nameSegment8=channelAnalytics.name?.subtotal_7
      graphData.nameSegment9=channelAnalytics.name?.subtotal_8
      graphData.nameSegment10=channelAnalytics.name?.subtotal_9
    }else if(analyticsDropdownVal == 3){
      title = t("Revenue per Weekday");
      revenue=weekdayAnalytics.report_table_monthly
      graphData.nameSegment1=weekdayAnalytics.name?.subtotal_0
      graphData.nameSegment2=weekdayAnalytics.name?.subtotal_1
      graphData.nameSegment3=weekdayAnalytics.name?.subtotal_2
      graphData.nameSegment4=weekdayAnalytics.name?.subtotal_3
      graphData.nameSegment5=weekdayAnalytics.name?.subtotal_4
      graphData.nameSegment6=weekdayAnalytics.name?.subtotal_5
      graphData.nameSegment7=weekdayAnalytics.name?.subtotal_6
      graphData.nameSegment8=weekdayAnalytics.name?.subtotal_7
      graphData.nameSegment9=weekdayAnalytics.name?.subtotal_8
      graphData.nameSegment10=weekdayAnalytics.name?.subtotal_9

    }else if(analyticsDropdownVal == 4){
      title = t("Custom Segment");
      revenue=customSegmentAnalytics.report_table_monthly
      graphData.nameSegment1=customSegmentAnalytics.name?.subtotal_0
      graphData.nameSegment2=customSegmentAnalytics.name?.subtotal_1
      graphData.nameSegment3=customSegmentAnalytics.name?.subtotal_2
      graphData.nameSegment4=customSegmentAnalytics.name?.subtotal_3
      graphData.nameSegment5=customSegmentAnalytics.name?.subtotal_4
      graphData.nameSegment6=customSegmentAnalytics.name?.subtotal_5
      graphData.nameSegment7=customSegmentAnalytics.name?.subtotal_6      
      graphData.nameSegment8=customSegmentAnalytics.name?.subtotal_7
      graphData.nameSegment9=customSegmentAnalytics.name?.subtotal_8
      graphData.nameSegment10=customSegmentAnalytics.name?.subtotal_9

    }
    if(revenue){
      analyticsAllMonths.map(key=>{
        graphData.total.push(revenue[key]?.revenue?.total);
        graphData.segment1.push(revenue[key]?.revenue?.subtotal_0||0)
        graphData.segment2.push(revenue[key]?.revenue?.subtotal_1||0)
        graphData.segment3.push(revenue[key]?.revenue?.subtotal_2||0)
        graphData.segment4.push(revenue[key]?.revenue?.subtotal_3||0)
        graphData.segment5.push(revenue[key]?.revenue?.subtotal_4||0)
        graphData.segment6.push(revenue[key]?.revenue?.subtotal_5||0)
        graphData.segment7.push(revenue[key]?.revenue?.subtotal_6||0)
        graphData.segment8.push(revenue[key]?.revenue?.subtotal_7||0)
        graphData.segment9.push(revenue[key]?.revenue?.subtotal_8||0)
        graphData.segment10.push(revenue[key]?.revenue?.subtotal_9||0)
      })
    }
    let maxvalue=0
    const options = {
      legend: {        
        display:false
      },
      layout: {
        padding: {
         top:60,
         bottom:60  //set that fits the best
         }
       },
      responsive: true,
      scales: {
        xAxes: [{
          barThickness: 35,
          maxBarThickness: 60,
          gridLines: {
            color: '#ffffff',
            borderDash: [1, 3],
          },
        }],
       
        yAxes: [
          {
              display: true,
              scaleLabel: {
                  show: true,
                  labelString: 'Value'
              },
              ticks: {
                  suggestedMin: 0,
                  suggestedMax: (maxvalue * 1.1),
                  callback: (label, index, labels) => {
                    return currencySymbol+new Intl.NumberFormat('ja-JP').format(label);
                  }
              }
          }
      ]
      },
      tooltips: {
        enabled: true,
        mode: 'index',
        bodyFontStyle: 'medium',
        position: 'nearest',
        callbacks: {
          label: function(tooltipItem, data) {
            return data.datasets[tooltipItem.datasetIndex].label + ': ' + currencySymbol + new Intl.NumberFormat('ja-JP').format(tooltipItem.yLabel);
          }
        } 
      }
    } 
  let graphArr=[
    {
      type: 'line',
      label: t('Total'),
      backgroundColor: '#521383',
      borderColor: '#521383',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.total,
      order: 1,
      hidden: true
    },

  ]
  if(graphData.nameSegment1){
    let segment1=[{
      type: 'line',
      label: t(graphData.nameSegment1),
      backgroundColor: '#f9a61e',
      borderColor:'#f9a61e' ,
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment1,
      order: this.segmentOrder(analyticsDropdownVal, 'segment1')
     }]
     graphArr=segment1.concat(graphArr)
   }
   if(graphData.nameSegment2){
    let segment2=[{
      type: 'line',
      label: t(graphData.nameSegment2),
      backgroundColor: '#03fc6b',
      borderColor: '#03fc6b',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment2,
      order: this.segmentOrder(analyticsDropdownVal, 'segment2')
     }]
     graphArr=segment2.concat(graphArr)
   }
   if(graphData.nameSegment3){
    let segment3=[{
      type: 'line',
      label: t(graphData.nameSegment3),
      backgroundColor:'#32586b' ,
      borderColor:'#32586b',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment3,
      order: this.segmentOrder(analyticsDropdownVal, 'segment3')
     }]
     graphArr=segment3.concat(graphArr)
   }
  //  Thu
   if(graphData.nameSegment4){
    let segment4=[{
      type: 'line',
      label: t(graphData.nameSegment4),
      backgroundColor: '#3a7fbd	',
      borderColor: '#3a7fbd	',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment4,
      order: this.segmentOrder(analyticsDropdownVal, 'segment4')
     }]
     graphArr=segment4.concat(graphArr)
   }
  //  Fri
   if(graphData.nameSegment5){
    let segment5=[{
      type: 'line',
      label: t(graphData.nameSegment5),
      backgroundColor: '#265f8f		',
      borderColor: '#265f8f',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment5,
      order: this.segmentOrder(analyticsDropdownVal, 'segment5')
     }]
     graphArr=segment5.concat(graphArr)
   }
  //  Sat
   if(graphData.nameSegment6){
    let segment6=[{
      type: 'line',
      label: t(graphData.nameSegment6),
      backgroundColor: '#11334f',
      borderColor: '#11334f',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment6,
      order: this.segmentOrder(analyticsDropdownVal, 'segment6')
     }]
     graphArr=segment6.concat(graphArr)
   }
  //  sun
   if(graphData.nameSegment7){
    let segment7=[{
      type: 'line',
      label: t(graphData.nameSegment7),
      backgroundColor: '#ff9900',
      borderColor: '#ff9900',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment7,
      order: this.segmentOrder(analyticsDropdownVal, 'segment7')
     }]
     graphArr=segment7.concat(graphArr)
   }
   if(graphData.nameSegment8){
    let segment8=[{
      type: 'line',
      label: t(graphData.nameSegment8),
      backgroundColor: '#cc6699',
      borderColor: '#cc6699',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment8,
      order: this.segmentOrder(analyticsDropdownVal, 'segment8')
     }]
     graphArr=segment8.concat(graphArr)
   }
   if(graphData.nameSegment9){
    let segment9=[{
      type: 'line',
      label: t(graphData.nameSegment9),
      backgroundColor: '#990066',
      borderColor: '#990066',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment9,
      order: this.segmentOrder(analyticsDropdownVal, 'segment9')
     }]
     graphArr=segment9.concat(graphArr)
   }
   if(graphData.nameSegment10){
    let segment10=[{
      type: 'line',
      label: t(graphData.nameSegment10),
      backgroundColor: '#8ebcca',
      borderColor: '#8ebcca',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment10,
      order: this.segmentOrder(analyticsDropdownVal, 'segment10')
     }]
     graphArr=segment10.concat(graphArr)
   }
  const genData = () => ({
    labels:dateLables,
    datasets:graphArr
  })
    
  const data = genData()

  const options1 = {
    legend: {        
      onClick: (e, legendItem) =>{          
        this.manageLegend(legendItem)
      },
      labels: {
        boxWidth: 11,
      }
    },
    scales: {
        xAxes: [{
          display: false,
        }],       
        yAxes: [
          {
              display: false,
          }
      ]
      },
    responsive: true    
  } 

  let data1 = {
    ...data,  
    datasets: data.datasets.map(val=>({...val,data:[]}))
  }
  
  return(
    <>
    <Grid item xs={12} >
      <div id="myHeader" style={{
        backgroundColor:'white',
        padding: "8px 0px 8px 0px",
      }}>
        <Line ref={this.myChartRef} height={20}  data={data1} options={options1}  />
      </div>
      <div style={{ float: "left", marginTop: "2px", marginBottom:"16px" }}>
        <Typography variant="h6" style={{ textAlign: "left", fontWeight: "500" }}>
          {title} 
        </Typography>
      </div>
    </Grid>
    <Grid item xs={12} >
      <div>
      <div className={classes.graphView} >
        <Line ref={this.myChartRef0} height={120} data={data} options={options}  />
      </div>
      </div>
    </Grid>
    </>
    )
  }
  occupancyGraph=()=>{
    const { classes } = this.props;
    const { t } = this.props;
    const { analyticsAllMonths, dateLables,channelAnalytics,segmentAnalytics,roomtypeAnalytics,weekdayAnalytics,analyticsDropdownVal, customSegmentAnalytics } = this.state;
    let occupancy=null
    let graphData = {
      total: [],
      segment1:[],
      segment2:[],
      segment3:[],
      segment4:[],
      segment5:[],
      segment6:[],
      segment7:[],
      segment8:[],
      segment9:[],
      segment10:[],
      nameSegment1:'',
      nameSegment2:'',
      nameSegment3:'',
      nameSegment4:'',
      nameSegment5:'',
      nameSegment6:'',
      nameSegment7:'',
      nameSegment8:'',
      nameSegment9:'',
      nameSegment10:'',
      graphDate:[]
    }
    if(analyticsDropdownVal == 0 ){
      occupancy=segmentAnalytics.report_table_monthly
      graphData.nameSegment1=segmentAnalytics.name?.subtotal_0
      graphData.nameSegment2=segmentAnalytics.name?.subtotal_1
      graphData.nameSegment3=segmentAnalytics.name?.subtotal_2
      graphData.nameSegment4=segmentAnalytics.name?.subtotal_3
      graphData.nameSegment5=segmentAnalytics.name?.subtotal_4
      graphData.nameSegment6=segmentAnalytics.name?.subtotal_5
      graphData.nameSegment7=segmentAnalytics.name?.subtotal_6
      graphData.nameSegment8=segmentAnalytics.name?.subtotal_7
      graphData.nameSegment9=segmentAnalytics.name?.subtotal_8
      graphData.nameSegment10=segmentAnalytics.name?.subtotal_9

    }else if(analyticsDropdownVal == 1){
      occupancy=roomtypeAnalytics.report_table_monthly
      graphData.nameSegment1=roomtypeAnalytics.name?.subtotal_0
      graphData.nameSegment2=roomtypeAnalytics.name?.subtotal_1
      graphData.nameSegment3=roomtypeAnalytics.name?.subtotal_2
      graphData.nameSegment4=roomtypeAnalytics.name?.subtotal_3
      graphData.nameSegment5=roomtypeAnalytics.name?.subtotal_4
      graphData.nameSegment6=roomtypeAnalytics.name?.subtotal_5
      graphData.nameSegment7=roomtypeAnalytics.name?.subtotal_6
      graphData.nameSegment8=roomtypeAnalytics.name?.subtotal_7
      graphData.nameSegment9=roomtypeAnalytics.name?.subtotal_8
      graphData.nameSegment10=roomtypeAnalytics.name?.subtotal_9
    }else if(analyticsDropdownVal == 2){
      occupancy=channelAnalytics.report_table_monthly
      graphData.nameSegment1=channelAnalytics.name?.subtotal_0
      graphData.nameSegment2=channelAnalytics.name?.subtotal_1
      graphData.nameSegment3=channelAnalytics.name?.subtotal_2
      graphData.nameSegment4=channelAnalytics.name?.subtotal_3
      graphData.nameSegment5=channelAnalytics.name?.subtotal_4
      graphData.nameSegment6=channelAnalytics.name?.subtotal_5
      graphData.nameSegment7=channelAnalytics.name?.subtotal_6
      graphData.nameSegment8=channelAnalytics.name?.subtotal_7
      graphData.nameSegment9=channelAnalytics.name?.subtotal_8
      graphData.nameSegment10=channelAnalytics.name?.subtotal_9
    }else if(analyticsDropdownVal == 3){
      occupancy=weekdayAnalytics.report_table_monthly
      graphData.nameSegment1=weekdayAnalytics.name?.subtotal_0
      graphData.nameSegment2=weekdayAnalytics.name?.subtotal_1
      graphData.nameSegment3=weekdayAnalytics.name?.subtotal_2
      graphData.nameSegment4=weekdayAnalytics.name?.subtotal_3
      graphData.nameSegment5=weekdayAnalytics.name?.subtotal_4
      graphData.nameSegment6=weekdayAnalytics.name?.subtotal_5
      graphData.nameSegment7=weekdayAnalytics.name?.subtotal_6
      graphData.nameSegment8=weekdayAnalytics.name?.subtotal_7
      graphData.nameSegment9=weekdayAnalytics.name?.subtotal_8
      graphData.nameSegment10=weekdayAnalytics.name?.subtotal_9
    }else if(analyticsDropdownVal == 4){
      occupancy=customSegmentAnalytics.report_table_monthly
      graphData.nameSegment1=customSegmentAnalytics.name?.subtotal_0
      graphData.nameSegment2=customSegmentAnalytics.name?.subtotal_1
      graphData.nameSegment3=customSegmentAnalytics.name?.subtotal_2
      graphData.nameSegment4=customSegmentAnalytics.name?.subtotal_3
      graphData.nameSegment5=customSegmentAnalytics.name?.subtotal_4
      graphData.nameSegment6=customSegmentAnalytics.name?.subtotal_5
      graphData.nameSegment7=customSegmentAnalytics.name?.subtotal_6      
      graphData.nameSegment8=customSegmentAnalytics.name?.subtotal_7
      graphData.nameSegment9=customSegmentAnalytics.name?.subtotal_8
      graphData.nameSegment10=customSegmentAnalytics.name?.subtotal_9

    }
    if(occupancy){
      analyticsAllMonths.map(key=>{
        graphData.total.push(occupancy[key]?.occupancy?.total);
        graphData.segment1.push(occupancy[key]?.occupancy?.subtotal_0||0)
        graphData.segment2.push(occupancy[key]?.occupancy?.subtotal_1||0)
        graphData.segment3.push(occupancy[key]?.occupancy?.subtotal_2||0)
        graphData.segment4.push(occupancy[key]?.occupancy?.subtotal_3||0)
        graphData.segment5.push(occupancy[key]?.occupancy?.subtotal_4||0)
        graphData.segment6.push(occupancy[key]?.occupancy?.subtotal_5||0)
        graphData.segment7.push(occupancy[key]?.occupancy?.subtotal_6||0)
        graphData.segment8.push(occupancy[key]?.occupancy?.subtotal_7||0)
        graphData.segment9.push(occupancy[key]?.occupancy?.subtotal_8||0)
        graphData.segment10.push(occupancy[key]?.occupancy?.subtotal_9||0)

      })
    }
    let maxvalue=0
    const options = {
      legend: {
        display:false
      },
      layout: {
        padding: {
         top:60,
         bottom:60  //set that fits the best
         }
       },
      responsive: true,
      scales: {
        xAxes: [{
          barThickness: 35,
          maxBarThickness: 60,
          gridLines: {
            color: '#ffffff',
            borderDash: [1, 3],
          },
        }],
       
        yAxes: [
          {
              display: true,
              scaleLabel: {
                  show: true,
                  labelString: 'Value'
              },
              ticks: {
                  suggestedMin: 0,
                  suggestedMax: (maxvalue * 1.1),
                  callback: (label, index, labels) => {
                    return label+'%';
                  }
              }
          }
      ]
      },
      tooltips: {
        enabled: true,
        mode: 'index',
        bodyFontStyle: 'medium',
        position: 'nearest',
        callbacks: {
          label: function(tooltipItem, data) {
            return data.datasets[tooltipItem.datasetIndex].label + ': '  + tooltipItem.yLabel+ "%" ;
          }
        } 
      }
    } 
  let graphArr=[
    {
      type: 'line',
      label: t('Total'),
      backgroundColor: '#521383',
      borderColor: '#521383',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.total,
      order: 1,
      hidden: true
    },

  ]
  if(graphData.nameSegment1){
    let segment1=[{
      type: 'line',
      label: t(graphData.nameSegment1),
      backgroundColor: '#f9a61e',
      borderColor:'#f9a61e' ,
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment1,
      order: this.segmentOrder(analyticsDropdownVal, 'segment1')
     }]
     graphArr=segment1.concat(graphArr)
   }
   if(graphData.nameSegment2){
    let segment2=[{
      type: 'line',
      label: t(graphData.nameSegment2),
      backgroundColor: '#03fc6b',
      borderColor: '#03fc6b',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment2,
      order: this.segmentOrder(analyticsDropdownVal, 'segment2')
     }]
     graphArr=segment2.concat(graphArr)
   }
   if(graphData.nameSegment3){
    let segment3=[{
      type: 'line',
      label: t(graphData.nameSegment3),
      backgroundColor:'#32586b' ,
      borderColor:'#32586b',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment3,
      order: this.segmentOrder(analyticsDropdownVal, 'segment3')
     }]
     graphArr=segment3.concat(graphArr)
   }
   if(graphData.nameSegment4){
    let segment4=[{
      type: 'line',
      label: t(graphData.nameSegment4),
      backgroundColor: '#3a7fbd	',
      borderColor: '#3a7fbd	',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment4,
      order: this.segmentOrder(analyticsDropdownVal, 'segment4')
     }]
     graphArr=segment4.concat(graphArr)
   }
   if(graphData.nameSegment5){
    let segment5=[{
      type: 'line',
      label: t(graphData.nameSegment5),
      backgroundColor: '#265f8f',
      borderColor: '#265f8f',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment5,
      order: this.segmentOrder(analyticsDropdownVal, 'segment5')
     }]
     graphArr=segment5.concat(graphArr)
   }
   if(graphData.nameSegment6){
    let segment6=[{
      type: 'line',
      label: t(graphData.nameSegment6),
      backgroundColor: '#11334f',
      borderColor: '#11334f',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment6,
      order: this.segmentOrder(analyticsDropdownVal, 'segment6')
     }]
     graphArr=segment6.concat(graphArr)
   }
   if(graphData.nameSegment7){
    let segment7=[{
      type: 'line',
      label: t(graphData.nameSegment7),
      backgroundColor: '#ff9900',
      borderColor: '#ff9900',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment7,
      order: this.segmentOrder(analyticsDropdownVal, 'segment7')
     }]
     graphArr=segment7.concat(graphArr)
   }
   if(graphData.nameSegment8){
    let segment8=[{
      type: 'line',
      label: t(graphData.nameSegment8),
      backgroundColor: '#cc6699',
      borderColor: '#cc6699',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment8,
      order: this.segmentOrder(analyticsDropdownVal, 'segment8')
     }]
     graphArr=segment8.concat(graphArr)
   }
   if(graphData.nameSegment9){
    let segment9=[{
      type: 'line',
      label: t(graphData.nameSegment9),
      backgroundColor: '#990066',
      borderColor: '#990066',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment9,
      order: this.segmentOrder(analyticsDropdownVal, 'segment9')
     }]
     graphArr=segment9.concat(graphArr)
   }
   if(graphData.nameSegment10){
    let segment10=[{
      type: 'line',
      label: t(graphData.nameSegment10),
      backgroundColor: '#8ebcca',
      borderColor: '#8ebcca',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment10,
      order: this.segmentOrder(analyticsDropdownVal, 'segment10')
     }]
     graphArr=segment10.concat(graphArr)
   }
  const genData = () => ({
    labels:dateLables,
    datasets:graphArr
  })
    
  const data = genData()
  
  return(
    <>
    <div style={{
      marginBottom: '1.5rem',
    }}>

      <Line ref={this.myChartRef2} height={240} data={data} options={options}  />      
    </div>
    </>
    )
  }
  adrGraph=()=>{
    const { classes } = this.props;
    const { t } = this.props;
    const { analyticsAllMonths, dateLables,channelAnalytics,segmentAnalytics,roomtypeAnalytics,weekdayAnalytics,analyticsDropdownVal, customSegmentAnalytics } = this.state;
    let currencySymbol=this.state.currency.symbol
    let adr=null
    let graphData = {
      total: [],
      segment1:[],
      segment2:[],
      segment3:[],
      segment4:[],
      segment5:[],
      segment6:[],
      segment7:[],
      segment8:[],
      segment9:[],
      segment10:[],
      nameSegment1:'',
      nameSegment2:'',
      nameSegment3:'',
      nameSegment4:'',
      nameSegment5:'',
      nameSegment6:'',
      nameSegment7:'',
      nameSegment8:'',
      nameSegment9:'',
      nameSegment10:'',
      graphDate:[]
    }
    if(analyticsDropdownVal == 0 ){
      adr=segmentAnalytics.report_table_monthly
      graphData.nameSegment1=segmentAnalytics.name?.subtotal_0
      graphData.nameSegment2=segmentAnalytics.name?.subtotal_1
      graphData.nameSegment3=segmentAnalytics.name?.subtotal_2
      graphData.nameSegment4=segmentAnalytics.name?.subtotal_3
      graphData.nameSegment5=segmentAnalytics.name?.subtotal_4
      graphData.nameSegment6=segmentAnalytics.name?.subtotal_5
      graphData.nameSegment7=segmentAnalytics.name?.subtotal_6
      graphData.nameSegment8=segmentAnalytics.name?.subtotal_7
      graphData.nameSegment9=segmentAnalytics.name?.subtotal_8
      graphData.nameSegment10=segmentAnalytics.name?.subtotal_9
    }else if(analyticsDropdownVal == 1){
      adr=roomtypeAnalytics.report_table_monthly
      graphData.nameSegment1=roomtypeAnalytics.name?.subtotal_0
      graphData.nameSegment2=roomtypeAnalytics.name?.subtotal_1
      graphData.nameSegment3=roomtypeAnalytics.name?.subtotal_2
      graphData.nameSegment4=roomtypeAnalytics.name?.subtotal_3
      graphData.nameSegment5=roomtypeAnalytics.name?.subtotal_4
      graphData.nameSegment6=roomtypeAnalytics.name?.subtotal_5
      graphData.nameSegment7=roomtypeAnalytics.name?.subtotal_6
      graphData.nameSegment8=roomtypeAnalytics.name?.subtotal_7
      graphData.nameSegment9=roomtypeAnalytics.name?.subtotal_8
      graphData.nameSegment10=roomtypeAnalytics.name?.subtotal_9

    }else if(analyticsDropdownVal == 2){
      adr=channelAnalytics.report_table_monthly
      graphData.nameSegment1=channelAnalytics.name?.subtotal_0
      graphData.nameSegment2=channelAnalytics.name?.subtotal_1
      graphData.nameSegment3=channelAnalytics.name?.subtotal_2
      graphData.nameSegment4=channelAnalytics.name?.subtotal_3
      graphData.nameSegment5=channelAnalytics.name?.subtotal_4
      graphData.nameSegment6=channelAnalytics.name?.subtotal_5
      graphData.nameSegment7=channelAnalytics.name?.subtotal_6
      graphData.nameSegment8=channelAnalytics.name?.subtotal_7
      graphData.nameSegment9=channelAnalytics.name?.subtotal_8
      graphData.nameSegment10=channelAnalytics.name?.subtotal_9
    }else if(analyticsDropdownVal == 3){
      adr=weekdayAnalytics.report_table_monthly

      graphData.nameSegment1=weekdayAnalytics.name?.subtotal_0
      graphData.nameSegment2=weekdayAnalytics.name?.subtotal_1
      graphData.nameSegment3=weekdayAnalytics.name?.subtotal_2
      graphData.nameSegment4=weekdayAnalytics.name?.subtotal_3
      graphData.nameSegment5=weekdayAnalytics.name?.subtotal_4
      graphData.nameSegment6=weekdayAnalytics.name?.subtotal_5
      graphData.nameSegment7=weekdayAnalytics.name?.subtotal_6
      graphData.nameSegment8=weekdayAnalytics.name?.subtotal_7
      graphData.nameSegment9=weekdayAnalytics.name?.subtotal_8
      graphData.nameSegment10=weekdayAnalytics.name?.subtotal_9
    }else if(analyticsDropdownVal == 4){
      adr=customSegmentAnalytics.report_table_monthly
      graphData.nameSegment1=customSegmentAnalytics.name?.subtotal_0
      graphData.nameSegment2=customSegmentAnalytics.name?.subtotal_1
      graphData.nameSegment3=customSegmentAnalytics.name?.subtotal_2
      graphData.nameSegment4=customSegmentAnalytics.name?.subtotal_3
      graphData.nameSegment5=customSegmentAnalytics.name?.subtotal_4
      graphData.nameSegment6=customSegmentAnalytics.name?.subtotal_5
      graphData.nameSegment7=customSegmentAnalytics.name?.subtotal_6      
      graphData.nameSegment8=customSegmentAnalytics.name?.subtotal_7
      graphData.nameSegment9=customSegmentAnalytics.name?.subtotal_8
      graphData.nameSegment10=customSegmentAnalytics.name?.subtotal_9

    }
    if(adr){
      analyticsAllMonths.map(key=>{
        graphData.total.push(adr[key]?.adr?.total);
        graphData.segment1.push(adr[key]?.adr?.subtotal_0||0)
        graphData.segment2.push(adr[key]?.adr?.subtotal_1||0)
        graphData.segment3.push(adr[key]?.adr?.subtotal_2||0)
        graphData.segment4.push(adr[key]?.adr?.subtotal_3||0)
        graphData.segment5.push(adr[key]?.adr?.subtotal_4||0)
        graphData.segment6.push(adr[key]?.adr?.subtotal_5||0)
        graphData.segment7.push(adr[key]?.adr?.subtotal_6||0)
        graphData.segment8.push(adr[key]?.adr?.subtotal_7||0)
        graphData.segment9.push(adr[key]?.adr?.subtotal_8||0)
        graphData.segment10.push(adr[key]?.adr?.subtotal_9||0)

      })
    }
    let maxvalue=0
    const options = {
      legend: {
        display:false
      },
      layout: {
        padding: {
         top: 50,
         bottom: 50  //set that fits the best
         }
       },
      // maintainAspectRatio: false,
      responsive: true,
      scales: {
        xAxes: [{
          barThickness: 35,
          maxBarThickness: 60,
          gridLines: {
            color: '#ffffff',
            borderDash: [1, 3],
          },
        }],
       
        yAxes: [
          {
              display: true,
              scaleLabel: {
                  show: true,
                  labelString: 'Value'
              },
              ticks: {
                  suggestedMin: 0,
                  suggestedMax: (maxvalue * 1.1),
                  callback: (label, index, labels) => {
                    return currencySymbol+new Intl.NumberFormat('ja-JP').format(label);
                  }
              }
          }
      ]
      },
      tooltips: {
        enabled: true,
        mode: 'index',
        // mode: 'point',
        // bodyFontSize: 8,
        bodyFontStyle: 'medium',
        // bodySpacing: 3,
        intersect: false,
        // xAlign: 'center',
        // yAlign: 'center',
        position: 'nearest',
        callbacks: {
          label: function(tooltipItem, data) {
            return data.datasets[tooltipItem.datasetIndex].label + ': ' + currencySymbol + new Intl.NumberFormat('ja-JP').format(tooltipItem.yLabel);
          }
        } 
      }
    } 
  let graphArr=[
    {
      type: 'line',
      label: t('Total'),
      backgroundColor: '#521383',
      borderColor: '#521383',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.total,
      order: 1,
      hidden: true
    },

  ]
  if(graphData.nameSegment1){
    let segment1=[{
      type: 'line',
      label: t(graphData.nameSegment1),
      backgroundColor: '#f9a61e',
      borderColor:'#f9a61e' ,
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment1,
      order: this.segmentOrder(analyticsDropdownVal,'segment1')
     }]
     graphArr=segment1.concat(graphArr)
   }
   if(graphData.nameSegment2){
    let segment2=[{
      type: 'line',
      label: t(graphData.nameSegment2),
      backgroundColor: '#03fc6b',
      borderColor: '#03fc6b',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment2,
      order: this.segmentOrder(analyticsDropdownVal,'segment2')
     }]
     graphArr=segment2.concat(graphArr)
   }
   if(graphData.nameSegment3){
    let segment3=[{
      type: 'line',
      label: t(graphData.nameSegment3),
      backgroundColor:'#32586b' ,
      borderColor:'#32586b',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment3,
      order: this.segmentOrder(analyticsDropdownVal,'segment3')
     }]
     graphArr=segment3.concat(graphArr)
   }
   if(graphData.nameSegment4){
    let segment4=[{
      type: 'line',
      label: t(graphData.nameSegment4),
      backgroundColor: '#3a7fbd	',
      borderColor: '#3a7fbd	',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment4,
      order: this.segmentOrder(analyticsDropdownVal,'segment4')
     }]
     graphArr=segment4.concat(graphArr)
   }
   if(graphData.nameSegment5){
    let segment5=[{
      type: 'line',
      label: t(graphData.nameSegment5),
      backgroundColor: '#265f8f',
      borderColor: '#265f8f',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment5,
      order: this.segmentOrder(analyticsDropdownVal,'segment5')
     }]
     graphArr=segment5.concat(graphArr)
   }
   if(graphData.nameSegment6){
    let segment6=[{
      type: 'line',
      label: t(graphData.nameSegment6),
      backgroundColor: '#11334f',
      borderColor: '#11334f',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment6,
      order: this.segmentOrder(analyticsDropdownVal,'segment6')
     }]
     graphArr=segment6.concat(graphArr)
   }
   if(graphData.nameSegment7){
    let segment7=[{
      type: 'line',
      label: t(graphData.nameSegment7),
      backgroundColor: '#ff9900',
      borderColor: '#ff9900',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment7,
      order: this.segmentOrder(analyticsDropdownVal,'segment7')
     }]
     graphArr=segment7.concat(graphArr)
   }
   if(graphData.nameSegment8){
    let segment8=[{
      type: 'line',
      label: t(graphData.nameSegment8),
      backgroundColor: '#cc6699',
      borderColor: '#cc6699',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment8,
      order: this.segmentOrder(analyticsDropdownVal,'segment8')
     }]
     graphArr=segment8.concat(graphArr)
   }
   if(graphData.nameSegment9){
    let segment9=[{
      type: 'line',
      label: t(graphData.nameSegment9),
      backgroundColor: '#990066',
      borderColor: '#990066',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment9,
      order: this.segmentOrder(analyticsDropdownVal,'segment9')
     }]
     graphArr=segment9.concat(graphArr)
   }
   if(graphData.nameSegment10){
    let segment10=[{
      type: 'line',
      label: t(graphData.nameSegment10),
      backgroundColor: '#8ebcca',
      borderColor: '#8ebcca',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment10,
      order: this.segmentOrder(analyticsDropdownVal,'segment10')
     }]
     graphArr=segment10.concat(graphArr)
   }
  const genData = () => ({
    labels:dateLables,
    datasets:graphArr
  })
    
  const data = genData()
  
  return(
    <>
    <div style={{
      marginBottom: '1.5rem',
    }}>
 
      <Line ref={this.myChartRef3}  height={240} data={data} options={options}  />  
   
    </div>
    </>
    )
  }
  medianLeadTimeGraph=()=>{
    const { classes } = this.props;
    const { t } = this.props;
    const { analyticsAllMonths, dateLables,channelAnalytics,segmentAnalytics,roomtypeAnalytics,weekdayAnalytics,analyticsDropdownVal, customSegmentAnalytics } = this.state;
    
    let currencySymbol=this.state.currency.symbol
    let medianLeadTime=null
    let graphData = {
      total: [],
      segment1:[],
      segment2:[],
      segment3:[],
      segment4:[],
      segment5:[],
      segment6:[],
      segment7:[],
      segment8:[],
      segment9:[],
      segment10:[],
      nameSegment1:'',
      nameSegment2:'',
      nameSegment3:'',
      nameSegment4:'',
      nameSegment5:'',
      nameSegment6:'',
      nameSegment7:'',
      nameSegment8:'',
      nameSegment9:'',
      nameSegment10:'',
      graphDate:[]
    }
    if(analyticsDropdownVal == 0 ){
      medianLeadTime=segmentAnalytics.report_table_monthly
      graphData.nameSegment1=segmentAnalytics.name?.subtotal_0
      graphData.nameSegment2=segmentAnalytics.name?.subtotal_1
      graphData.nameSegment3=segmentAnalytics.name?.subtotal_2
      graphData.nameSegment4=segmentAnalytics.name?.subtotal_3
      graphData.nameSegment5=segmentAnalytics.name?.subtotal_4
      graphData.nameSegment6=segmentAnalytics.name?.subtotal_5
      graphData.nameSegment7=segmentAnalytics.name?.subtotal_6
      graphData.nameSegment8=segmentAnalytics.name?.subtotal_7
      graphData.nameSegment9=segmentAnalytics.name?.subtotal_8
      graphData.nameSegment10=segmentAnalytics.name?.subtotal_9
    }else if(analyticsDropdownVal == 1){
      medianLeadTime=roomtypeAnalytics.report_table_monthly
      graphData.nameSegment1=roomtypeAnalytics.name?.subtotal_0
      graphData.nameSegment2=roomtypeAnalytics.name?.subtotal_1
      graphData.nameSegment3=roomtypeAnalytics.name?.subtotal_2
      graphData.nameSegment4=roomtypeAnalytics.name?.subtotal_3
      graphData.nameSegment5=roomtypeAnalytics.name?.subtotal_4
      graphData.nameSegment6=roomtypeAnalytics.name?.subtotal_5
      graphData.nameSegment7=roomtypeAnalytics.name?.subtotal_6
      graphData.nameSegment8=roomtypeAnalytics.name?.subtotal_7
      graphData.nameSegment9=roomtypeAnalytics.name?.subtotal_8
      graphData.nameSegment10=roomtypeAnalytics.name?.subtotal_9
    }else if(analyticsDropdownVal == 2){
      medianLeadTime=channelAnalytics.report_table_monthly
      graphData.nameSegment1=channelAnalytics.name?.subtotal_0
      graphData.nameSegment2=channelAnalytics.name?.subtotal_1
      graphData.nameSegment3=channelAnalytics.name?.subtotal_2
      graphData.nameSegment4=channelAnalytics.name?.subtotal_3
      graphData.nameSegment5=channelAnalytics.name?.subtotal_4
      graphData.nameSegment6=channelAnalytics.name?.subtotal_5
      graphData.nameSegment7=channelAnalytics.name?.subtotal_6
      graphData.nameSegment8=channelAnalytics.name?.subtotal_7
      graphData.nameSegment9=channelAnalytics.name?.subtotal_8
      graphData.nameSegment10=channelAnalytics.name?.subtotal_9
    }else if(analyticsDropdownVal == 3){
      medianLeadTime=weekdayAnalytics.report_table_monthly
      graphData.nameSegment1=weekdayAnalytics.name?.subtotal_0
      graphData.nameSegment2=weekdayAnalytics.name?.subtotal_1
      graphData.nameSegment3=weekdayAnalytics.name?.subtotal_2
      graphData.nameSegment4=weekdayAnalytics.name?.subtotal_3
      graphData.nameSegment5=weekdayAnalytics.name?.subtotal_4
      graphData.nameSegment6=weekdayAnalytics.name?.subtotal_5
      graphData.nameSegment7=weekdayAnalytics.name?.subtotal_6
      graphData.nameSegment8=weekdayAnalytics.name?.subtotal_7
      graphData.nameSegment9=weekdayAnalytics.name?.subtotal_8
      graphData.nameSegment10=weekdayAnalytics.name?.subtotal_9
    }else if(analyticsDropdownVal == 4){
      medianLeadTime=customSegmentAnalytics.report_table_monthly
      graphData.nameSegment1=customSegmentAnalytics.name?.subtotal_0
      graphData.nameSegment2=customSegmentAnalytics.name?.subtotal_1
      graphData.nameSegment3=customSegmentAnalytics.name?.subtotal_2
      graphData.nameSegment4=customSegmentAnalytics.name?.subtotal_3
      graphData.nameSegment5=customSegmentAnalytics.name?.subtotal_4
      graphData.nameSegment6=customSegmentAnalytics.name?.subtotal_5
      graphData.nameSegment7=customSegmentAnalytics.name?.subtotal_6      
      graphData.nameSegment8=customSegmentAnalytics.name?.subtotal_7
      graphData.nameSegment9=customSegmentAnalytics.name?.subtotal_8
      graphData.nameSegment10=customSegmentAnalytics.name?.subtotal_9

    }
    if(medianLeadTime){
      analyticsAllMonths.map(key=>{
        graphData.total.push(medianLeadTime[key]?.median_lead_time?.total);
        graphData.segment1.push(medianLeadTime[key]?.median_lead_time?.subtotal_0||0)
        graphData.segment2.push(medianLeadTime[key]?.median_lead_time?.subtotal_1||0)
        graphData.segment3.push(medianLeadTime[key]?.median_lead_time?.subtotal_2||0)
        graphData.segment4.push(medianLeadTime[key]?.median_lead_time?.subtotal_3||0)
        graphData.segment5.push(medianLeadTime[key]?.median_lead_time?.subtotal_4||0)
        graphData.segment6.push(medianLeadTime[key]?.median_lead_time?.subtotal_5||0)
        graphData.segment7.push(medianLeadTime[key]?.median_lead_time?.subtotal_6||0)
        graphData.segment8.push(medianLeadTime[key]?.median_lead_time?.subtotal_7||0)
        graphData.segment9.push(medianLeadTime[key]?.median_lead_time?.subtotal_8||0)
        graphData.segment10.push(medianLeadTime[key]?.median_lead_time?.subtotal_9||0)

      })
    }
    let maxvalue=0
    const options = {
      // maintainAspectRatio: false,
      legend: {
        display:false

      },
      layout: {
        padding: {
         top:60,
         bottom:60  //set that fits the best
         }
       },
      responsive: true,
      scales: {
        xAxes: [{
          barThickness: 35,
          maxBarThickness: 60,
          gridLines: {
            color: '#ffffff',
            borderDash: [1, 3],
          },
        }],
       
        yAxes: [
          {
              display: true,
              scaleLabel: {
                  show: true,
                  labelString: 'Value'
              },
              ticks: {
                  suggestedMin: 0,
                  suggestedMax: (maxvalue * 1.1),
                  callback: (label, index, labels) => {
                    return label;
                  }
              }
          }
      ]
      },
      tooltips: {
        enabled: true,
        mode: 'index',
        bodyFontStyle: 'medium',
        position: 'nearest',
        callbacks: {
          label: function(tooltipItem, data) {
            return data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel + ' ' + t('nights');
          }
        } 
      }
    } 
  let graphArr=[
    {
      type: 'line',
      label: t('Total'),
      backgroundColor: '#521383',
      borderColor: '#521383',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.total,
      order: 1,
      hidden: true
    },

  ]
  if(graphData.nameSegment1){
    let segment1=[{
      type: 'line',
      label: t(graphData.nameSegment1),
      backgroundColor: '#f9a61e',
      borderColor:'#f9a61e' ,
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment1,
      order: this.segmentOrder(analyticsDropdownVal, 'segment1')
     }]
     graphArr=segment1.concat(graphArr)
   }
   if(graphData.nameSegment2){
    let segment2=[{
      type: 'line',
      label: t(graphData.nameSegment2),
      backgroundColor: '#03fc6b',
      borderColor: '#03fc6b',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment2,
      order: this.segmentOrder(analyticsDropdownVal, 'segment2')
     }]
     graphArr=segment2.concat(graphArr)
   }
   if(graphData.nameSegment3){
    let segment3=[{
      type: 'line',
      label: t(graphData.nameSegment3),
      backgroundColor:'#32586b' ,
      borderColor:'#32586b',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment3,
      order: this.segmentOrder(analyticsDropdownVal, 'segment3')
     }]
     graphArr=segment3.concat(graphArr)
   }
   if(graphData.nameSegment4){
    let segment4=[{
      type: 'line',
      label: t(graphData.nameSegment4),
      backgroundColor: '#3a7fbd	',
      borderColor: '#3a7fbd	',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment4,
      order: this.segmentOrder(analyticsDropdownVal, 'segment4')
     }]
     graphArr=segment4.concat(graphArr)
   }
   if(graphData.nameSegment5){
    let segment5=[{
      type: 'line',
      label: t(graphData.nameSegment5),
      backgroundColor: '#265f8f',
      borderColor: '#265f8f',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment5,
      order: this.segmentOrder(analyticsDropdownVal, 'segment5')
     }]
     graphArr=segment5.concat(graphArr)
   }
   if(graphData.nameSegment6){
    let segment6=[{
      type: 'line',
      label: t(graphData.nameSegment6),
      backgroundColor: '#11334f',
      borderColor: '#11334f',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment6,
      order: this.segmentOrder(analyticsDropdownVal, 'segment6')
     }]
     graphArr=segment6.concat(graphArr)
   }
   if(graphData.nameSegment7){
    let segment7=[{
      type: 'line',
      label: t(graphData.nameSegment7),
      backgroundColor: '#ff9900',
      borderColor: '#ff9900',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment7,
      order: this.segmentOrder(analyticsDropdownVal, 'segment7')
     }]
     graphArr=segment7.concat(graphArr)
   }   
   if(graphData.nameSegment8){
    let segment8=[{
      type: 'line',
      label: t(graphData.nameSegment8),
      backgroundColor: '#cc6699',
      borderColor: '#cc6699',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment8,
      order: this.segmentOrder(analyticsDropdownVal, 'segment8')
     }]
     graphArr=segment8.concat(graphArr)
   }
   if(graphData.nameSegment9){
    let segment9=[{
      type: 'line',
      label: t(graphData.nameSegment9),
      backgroundColor: '#990066',
      borderColor: '#990066',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment9,
      order: this.segmentOrder(analyticsDropdownVal, 'segment9')
     }]
     graphArr=segment9.concat(graphArr)
   }
   if(graphData.nameSegment10){
    let segment10=[{
      type: 'line',
      label: t(graphData.nameSegment10),
      backgroundColor: '#8ebcca',
      borderColor: '#8ebcca',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment10,
      order: this.segmentOrder(analyticsDropdownVal, 'segment10')
     }]
     graphArr=segment10.concat(graphArr)
   }
  const genData = () => ({
    labels:dateLables,
    datasets:graphArr
  })
    
  const data = genData()
  
  return(
    <>
      <Line ref={this.myChartRef4} height={240} data={data} options={options}  />      
    </>
    )
  }
  losGraph=()=>{
    const { classes } = this.props;
    const { t } = this.props;
    const { analyticsAllMonths, dateLables,channelAnalytics,segmentAnalytics,roomtypeAnalytics,weekdayAnalytics,analyticsDropdownVal, customSegmentAnalytics } = this.state;
    
    let los=null
    
    
    
    
    let graphData = {
      total: [],
      segment1:[],
      segment2:[],
      segment3:[],
      segment4:[],
      segment5:[],
      segment6:[],
      segment7:[],
      segment8:[],
      segment9:[],
      segment10:[],
      nameSegment1:'',
      nameSegment2:'',
      nameSegment3:'',
      nameSegment4:'',
      nameSegment5:'',
      nameSegment6:'',
      nameSegment7:'',
      nameSegment8:'',
      nameSegment9:'',
      nameSegment10:'',
      graphDate:[]
    }
    if(analyticsDropdownVal == 0 ){
      
      los=segmentAnalytics.report_table_monthly
      graphData.nameSegment1=segmentAnalytics.name?.subtotal_0
      graphData.nameSegment2=segmentAnalytics.name?.subtotal_1
      graphData.nameSegment3=segmentAnalytics.name?.subtotal_2
      graphData.nameSegment4=segmentAnalytics.name?.subtotal_3
      graphData.nameSegment5=segmentAnalytics.name?.subtotal_4
      graphData.nameSegment6=segmentAnalytics.name?.subtotal_5
      graphData.nameSegment7=segmentAnalytics.name?.subtotal_6
      graphData.nameSegment8=segmentAnalytics.name?.subtotal_7
      graphData.nameSegment9=segmentAnalytics.name?.subtotal_8
      graphData.nameSegment10=segmentAnalytics.name?.subtotal_9

    }else if(analyticsDropdownVal == 1){
      
      los=roomtypeAnalytics.report_table_monthly
      graphData.nameSegment1=roomtypeAnalytics.name?.subtotal_0
      graphData.nameSegment2=roomtypeAnalytics.name?.subtotal_1
      graphData.nameSegment3=roomtypeAnalytics.name?.subtotal_2
      graphData.nameSegment4=roomtypeAnalytics.name?.subtotal_3
      graphData.nameSegment4=roomtypeAnalytics.name?.subtotal_4
      graphData.nameSegment4=roomtypeAnalytics.name?.subtotal_5
      graphData.nameSegment4=roomtypeAnalytics.name?.subtotal_6
      graphData.nameSegment8=roomtypeAnalytics.name?.subtotal_7
      graphData.nameSegment9=roomtypeAnalytics.name?.subtotal_8
      graphData.nameSegment10=roomtypeAnalytics.name?.subtotal_9

    }else if(analyticsDropdownVal == 2){
      
      los=channelAnalytics.report_table_monthly
      graphData.nameSegment1=channelAnalytics.name?.subtotal_0
      graphData.nameSegment2=channelAnalytics.name?.subtotal_1
      graphData.nameSegment3=channelAnalytics.name?.subtotal_2
      graphData.nameSegment4=channelAnalytics.name?.subtotal_3
      graphData.nameSegment5=channelAnalytics.name?.subtotal_4
      graphData.nameSegment6=channelAnalytics.name?.subtotal_5
      graphData.nameSegment7=channelAnalytics.name?.subtotal_6
      graphData.nameSegment8=channelAnalytics.name?.subtotal_7
      graphData.nameSegment9=channelAnalytics.name?.subtotal_8
      graphData.nameSegment10=channelAnalytics.name?.subtotal_9

    }else if(analyticsDropdownVal == 3){
      
      los=weekdayAnalytics.report_table_monthly
      graphData.nameSegment1=weekdayAnalytics.name?.subtotal_0
      graphData.nameSegment2=weekdayAnalytics.name?.subtotal_1
      graphData.nameSegment3=weekdayAnalytics.name?.subtotal_2
      graphData.nameSegment4=weekdayAnalytics.name?.subtotal_3
      graphData.nameSegment5=weekdayAnalytics.name?.subtotal_4
      graphData.nameSegment6=weekdayAnalytics.name?.subtotal_5
      graphData.nameSegment7=weekdayAnalytics.name?.subtotal_6
      graphData.nameSegment8=weekdayAnalytics.name?.subtotal_7
      graphData.nameSegment9=weekdayAnalytics.name?.subtotal_8
      graphData.nameSegment10=weekdayAnalytics.name?.subtotal_9
    } else if(analyticsDropdownVal == 4){
      los=customSegmentAnalytics.report_table_monthly
      graphData.nameSegment1=customSegmentAnalytics.name?.subtotal_0
      graphData.nameSegment2=customSegmentAnalytics.name?.subtotal_1
      graphData.nameSegment3=customSegmentAnalytics.name?.subtotal_2
      graphData.nameSegment4=customSegmentAnalytics.name?.subtotal_3
      graphData.nameSegment5=customSegmentAnalytics.name?.subtotal_4
      graphData.nameSegment6=customSegmentAnalytics.name?.subtotal_5
      graphData.nameSegment7=customSegmentAnalytics.name?.subtotal_6      
      graphData.nameSegment8=customSegmentAnalytics.name?.subtotal_7
      graphData.nameSegment9=customSegmentAnalytics.name?.subtotal_8
      graphData.nameSegment10=customSegmentAnalytics.name?.subtotal_9
    }
    if(los){
      analyticsAllMonths.map(key=>{
        graphData.total.push(los[key]?.median_length_of_stay?.total);
        graphData.segment1.push(los[key]?.median_length_of_stay?.subtotal_0||0)
        graphData.segment2.push(los[key]?.median_length_of_stay?.subtotal_1||0)
        graphData.segment3.push(los[key]?.median_length_of_stay?.subtotal_2||0)
        graphData.segment4.push(los[key]?.median_length_of_stay?.subtotal_3||0)
        graphData.segment5.push(los[key]?.median_length_of_stay?.subtotal_4||0)
        graphData.segment6.push(los[key]?.median_length_of_stay?.subtotal_5||0)
        graphData.segment7.push(los[key]?.median_length_of_stay?.subtotal_6||0)
        graphData.segment8.push(los[key]?.median_length_of_stay?.subtotal_7||0)
        graphData.segment9.push(los[key]?.median_length_of_stay?.subtotal_8||0)
        graphData.segment10.push(los[key]?.median_length_of_stay?.subtotal_9||0)

      })
    }
    let maxvalue=0
    const options = {
      legend: {
        display:false
      },
      responsive: true,
      layout: {
        padding: {
         top:60,
         bottom:60  //set that fits the best
         }
       },
      scales: {
        xAxes: [{
          barThickness: 35,
          maxBarThickness: 60,
          gridLines: {
            color: '#ffffff',
            borderDash: [1, 3],
          },
        }],
       
        yAxes: [
          {
              display: true,
              scaleLabel: {
                  show: true,
                  labelString: 'Value'
              },
              ticks: {
                  suggestedMin: 0,
                  suggestedMax: (maxvalue * 1.1),
                  callback: (label, index, labels) => {
                    return label;
                  }
              }
          }
      ]
      },
      tooltips: {
        enabled: true,
        mode: 'index',
        bodyFontStyle: 'medium',
        position: 'nearest',
        callbacks: {
          label: function(tooltipItem, data) {
            return data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel + ' ' + t('nights');
          }
        } 
      }
    } 
  let graphArr=[
    {
      type: 'line',
      label: t('Total'),
      backgroundColor: '#521383',
      borderColor: '#521383',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.total,
      order: 1,
      hidden: true
    },

  ]
  if(graphData.nameSegment1){
    let segment1=[{
      type: 'line',
      label: t(graphData.nameSegment1),
      backgroundColor: '#f9a61e',
      borderColor:'#f9a61e' ,
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment1,
      order: this.segmentOrder(analyticsDropdownVal, 'segment1')
     }]
     graphArr=segment1.concat(graphArr)
   }
   if(graphData.nameSegment2){
    let segment2=[{
      type: 'line',
      label: t(graphData.nameSegment2),
      backgroundColor: '#03fc6b',
      borderColor: '#03fc6b',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment2,
      order: this.segmentOrder(analyticsDropdownVal, 'segment2')
     }]
     graphArr=segment2.concat(graphArr)
   }
   if(graphData.nameSegment3){
    let segment3=[{
      type: 'line',
      label: t(graphData.nameSegment3),
      backgroundColor:'#32586b' ,
      borderColor:'#32586b',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment3,
      order: this.segmentOrder(analyticsDropdownVal, 'segment3')
     }]
     graphArr=segment3.concat(graphArr)
   }
   if(graphData.nameSegment4){
    let segment4=[{
      type: 'line',
      label: t(graphData.nameSegment4),
      backgroundColor: '#3a7fbd	',
      borderColor: '#3a7fbd	',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment4,
      order: this.segmentOrder(analyticsDropdownVal, 'segment4')
     }]
     graphArr=segment4.concat(graphArr)
   }
   if(graphData.nameSegment5){
    let segment5=[{
      type: 'line',
      label: t(graphData.nameSegment5),
      backgroundColor: '#265f8f',
      borderColor: '#265f8f',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment5,
      order: this.segmentOrder(analyticsDropdownVal, 'segment5')
     }]
     graphArr=segment5.concat(graphArr)
   }
   if(graphData.nameSegment6){
    let segment6=[{
      type: 'line',
      label: t(graphData.nameSegment6),
      backgroundColor: '#11334f',
      borderColor: '#11334f',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment6,
      order: this.segmentOrder(analyticsDropdownVal, 'segment6')
     }]
     graphArr=segment6.concat(graphArr)
   }
   if(graphData.nameSegment7){
    let segment7=[{
      type: 'line',
      label: t(graphData.nameSegment7),
      backgroundColor: '#ff9900',
      borderColor: '#ff9900',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment7,
      order: this.segmentOrder(analyticsDropdownVal, 'segment7')
     }]
     graphArr=segment7.concat(graphArr)
   }
   
   if(graphData.nameSegment8){
    let segment8=[{
      type: 'line',
      label: t(graphData.nameSegment8),
      backgroundColor: '#cc6699',
      borderColor: '#cc6699',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment8,
      order: this.segmentOrder(analyticsDropdownVal, 'segment8')
     }]
     graphArr=segment8.concat(graphArr)
   }
   
   if(graphData.nameSegment9){
    let segment9=[{
      type: 'line',
      label: t(graphData.nameSegment9),
      backgroundColor: '#990066',
      borderColor: '#990066',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment9,
      order: this.segmentOrder(analyticsDropdownVal, 'segment9')
     }]
     graphArr=segment9.concat(graphArr)
   }
   
   if(graphData.nameSegment10){
    let segment10=[{
      type: 'line',
      label: t(graphData.nameSegment10),
      backgroundColor: '#8ebcca',
      borderColor: '#8ebcca',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      data: graphData.segment10,
      order: this.segmentOrder(analyticsDropdownVal, 'segment10')
     }]
     graphArr=segment10.concat(graphArr)
   }
  const genData = () => ({
    labels:dateLables,
    datasets:graphArr
  })
    
  const data = genData()
  
  return(
    <>
      <Line ref={this.myChartRef5} height={240} data={data} options={options}  />      
    </>
    )
  }
  analyticsGraph = () => {
    const { analyticsDropdownVal } = this.state;
    const { t } = this.props;
    
    let titleOcc = t("Occupancy");
    let titleAdr = t("ADR");
    let titleMlt = t("Median Lead Time");
    let titleLid = t("Median LOS in Nights");

    if (analyticsDropdownVal == 0) {
      titleOcc = t("Occupancy per Segment");
      titleAdr = t("ADR per Segment");
      titleMlt = t("Median Lead Time per Segment");
      titleLid = t("Median LOS in Nights per Segment");
    } else if (analyticsDropdownVal == 1) {
      titleOcc = t("Occupancy per Room Type");
      titleAdr = t("ADR per Room Type");
      titleMlt = t("Median Lead Time per Room Type");
      titleLid = t("Median LOS in Nights per Room Type");
    } else if (analyticsDropdownVal == 2) {
      titleOcc = t("Occupancy per Channel");
      titleAdr = t("ADR per Channel");
      titleMlt = t("Median Lead Time per Channel");
      titleLid = t("Median LOS in Nights per Channel");
    } else if (analyticsDropdownVal == 3) {
      titleOcc = t("Occupancy per Weekday");
      titleAdr = t("ADR per Weekday");
      titleMlt = t("Median Lead Time per Weekday");
      titleLid = t("Median LOS in Nights per Weekday");
    }
    return(
      <>
      
      <Grid item xs={12} >
        {this.revenueGraph()}
      </Grid>
      <Grid container spacing={2}>
      <Grid item xs={6} >
        <div style={{ float: "left", margin: "20px 0px 0px 0px" }}>
          <Typography variant="h6" style={{ textAlign: "left", fontWeight: "500" }}>
            {titleOcc}
          </Typography>
        </div>
        <div >
        {this.occupancyGraph()} 
        </div>
      </Grid>
      <Grid item xs={6} >
        <div style={{ float: "left", margin: "20px 0 0 0" }}>
          <Typography variant="h6" style={{ textAlign: "left", fontWeight: "500" }}>
            {titleAdr}
          </Typography>
        </div>
        <div>
        {this.adrGraph()}
        </div>
      </Grid>
      </Grid>
      <Grid container spacing={2}>
      <Grid item xs={6} >
        <div style={{ float: "left" }}>
          <Typography variant="h6" style={{ textAlign: "left", fontWeight: "500" }}>
            {titleMlt}
          </Typography>
        </div>
        <div>
        {this.medianLeadTimeGraph()}
        </div>
      </Grid>
      <Grid item xs={6} >
        <div style={{ float: "left" }}>
          <Typography variant="h6" style={{ textAlign: "left", fontWeight: "500" }}>
            {titleLid}
          </Typography>
        </div>
        <div>
        {this.losGraph()}
        </div>
      </Grid>
      </Grid>
      </>
    )  
  }
  
  yearlyOverviewCal = (val,name,segmentName) =>{
    let data={
      total:'',
      segment1:'',
      segment2:'',
      segment3:'',
      segment4:'',
      segment5:'',
      segment6:'',
      segment7:'',
      segment8:'',
      segment9:'',
      segment10:'',
      nameSegment1:'',
      nameSegment2:'',
      nameSegment3:'',
      nameSegment4:'',
      nameSegment5:'',
      nameSegment6:'',
      nameSegment7:'',
      nameSegment8:'',
      nameSegment9:'',
      nameSegment10:'',
    }
    let monthChangeDate;
    // let lastMonthYear;
      monthChangeDate = moment(this.state.yearChangeDate).format('YYYY');
      // lastMonthYear = moment(this.state.yearChangeDate).subtract(1, 'years').format('YYYY');
   
    val && Object.keys(val).forEach(function (key) {
      if (key === monthChangeDate) {
        data.total=val[key]?.[name]?.total
        data.segment1=val[key]?.[name]?.subtotal_0
        data.segment2=val[key]?.[name]?.subtotal_1
        data.segment3=val[key]?.[name]?.subtotal_2
        data.segment4=val[key]?.[name]?.subtotal_3
        data.segment5=val[key]?.[name]?.subtotal_4
        data.segment6=val[key]?.[name]?.subtotal_5
        data.segment7=val[key]?.[name]?.subtotal_6
        data.segment8=val[key]?.[name]?.subtotal_7
        data.segment9=val[key]?.[name]?.subtotal_8
        data.segment10=val[key]?.[name]?.subtotal_9
        data.nameSegment1=segmentName?.subtotal_0
        data.nameSegment2=segmentName?.subtotal_1
        data.nameSegment3=segmentName?.subtotal_2
        data.nameSegment4=segmentName?.subtotal_3
        data.nameSegment5=segmentName?.subtotal_4
        data.nameSegment6=segmentName?.subtotal_5
        data.nameSegment7=segmentName?.subtotal_6
        data.nameSegment8=segmentName?.subtotal_7
        data.nameSegment9=segmentName?.subtotal_8
        data.nameSegment10=segmentName?.subtotal_9

      }
    })
    return data;
  }

monthlyOverviewCal = (val, name, segmentName) => {
  let data = {
    total: '',
    segment1: '',
    segment2: '',
    segment3: '',
    segment4: '',
    segment5: '',
    segment6: '',
    segment7: '',
    segment8: '',
    segment9: '',
    segment10: '',
    nameSegment1: '',
    nameSegment2: '',
    nameSegment3: '',
    nameSegment4: '',
    nameSegment5: '',
    nameSegment6: '',
    nameSegment7: '',
    nameSegment8: '',
    nameSegment9: '',
    nameSegment10: '',
  };

  const formattedDate = moment(this.state.graphMonthChangeDate).format('YYYY-MM');
  const key = moment(formattedDate, 'YYYY-MM').format('YYYY-M');
  val &&
    Object.keys(val).forEach(function (k) {
      if (k === key) {
        data.total = val[k]?.[name]?.total;
        data.segment1 = val[k]?.[name]?.subtotal_0;
        data.segment2 = val[k]?.[name]?.subtotal_1;
        data.segment3 = val[k]?.[name]?.subtotal_2;
        data.segment4 = val[k]?.[name]?.subtotal_3;
        data.segment5 = val[k]?.[name]?.subtotal_4;
        data.segment6 = val[k]?.[name]?.subtotal_5;
        data.segment7 = val[k]?.[name]?.subtotal_6;
        data.segment8 = val[k]?.[name]?.subtotal_7;
        data.segment9 = val[k]?.[name]?.subtotal_8;
        data.segment10 = val[k]?.[name]?.subtotal_9;
        data.nameSegment1 = segmentName?.subtotal_0;
        data.nameSegment2 = segmentName?.subtotal_1;
        data.nameSegment3 = segmentName?.subtotal_2;
        data.nameSegment4 = segmentName?.subtotal_3;
        data.nameSegment5 = segmentName?.subtotal_4;
        data.nameSegment6 = segmentName?.subtotal_5;
        data.nameSegment7 = segmentName?.subtotal_6;
        data.nameSegment8 = segmentName?.subtotal_7;
        data.nameSegment9 = segmentName?.subtotal_8;
        data.nameSegment10 = segmentName?.subtotal_9;
      }
    });
  return data;
};



  generateColumnJsx = (data, type) =>{
    

    const { t } = this.props;    
    const { classes } = this.props;
    const currencySymbol = this.state.currency.symbol;
    if(keyArray.length === 0 || type == "revenue" || this.state.analyticsDropdownVal === 3){
      keyArray = [
        {valueKey: "segment1", nameKey: "nameSegment1"},
        {valueKey: "segment2", nameKey: "nameSegment2"},
        {valueKey: "segment3", nameKey: "nameSegment3"},
        {valueKey: "segment4", nameKey: "nameSegment4"},
        {valueKey: "segment5", nameKey: "nameSegment5"},
        {valueKey: "segment6", nameKey: "nameSegment6"},
        {valueKey: "segment7", nameKey: "nameSegment7"},
        {valueKey: "segment8", nameKey: "nameSegment8"},
        {valueKey: "segment9", nameKey: "nameSegment9"},
        {valueKey: "segment10", nameKey: "nameSegment10"}
      ]
    }
    
    let tempArray = [];

    keyArray.map(row=>{
      if(data[row.nameKey] && (data[row.valueKey] || data[row.valueKey] === 0)){
        tempArray.push({jsx: <>
            <Grid item xs={12}>
              <Typography className={classes.grayTextRoomType}>{t(data[row.nameKey])}</Typography>
            </Grid>
            <Grid item xs={12}>
                <span className={classes.normalPrice}>
                {
                  type == "occupancy" ? data[row.valueKey]+'%' :  ((type == "revenue" || type == "adr"  ? currencySymbol : "") + new Intl.NumberFormat('ja-JP').format(data[row.valueKey]))
                }
                </span>
            </Grid>
          </>, value: data[row.valueKey], key: row})
        }

    })
    
    if(type == "revenue" && this.state.analyticsDropdownVal != 3){
      keyArray = tempArray.sort((a, b) => b.value-a.value).map(row=>row.key);
      return tempArray.sort((a, b) => b.value-a.value).map(row=>row.jsx);      
    } else{
      return tempArray.map(row=>row.jsx);
    }   
    
  }


  analyticsTab=()=>{
    const { classes } = this.props;
    const { t } = this.props;
    const currencySymbol = this.state.currency.symbol
    const {segmentAnalytics,roomtypeAnalytics,channelAnalytics,weekdayAnalytics, customSegmentAnalytics}=this.state
    let revenue=null
    let adr=null
    let occupancy=null
    let medianLeadTime=null
    let los=null
    let title = "";
    if(segmentAnalytics&&roomtypeAnalytics&&channelAnalytics&&weekdayAnalytics){
      if(this.state.analyticsDropdownVal == 0 ){
        if(this.state.showFullYearGraph){
        title = t("Segment Overview") + " " + moment(this.state.yearChangeDate).format("YYYY");
        revenue=this.yearlyOverviewCal(segmentAnalytics.report_table_yearly,'revenue',segmentAnalytics.name)
        occupancy=this.yearlyOverviewCal(segmentAnalytics.report_table_yearly,'occupancy',segmentAnalytics.name)
        adr=this.yearlyOverviewCal(segmentAnalytics.report_table_yearly,'adr',segmentAnalytics.name)
        medianLeadTime=this.yearlyOverviewCal(segmentAnalytics.report_table_yearly,'median_lead_time',segmentAnalytics.name)
        los=this.yearlyOverviewCal(segmentAnalytics.report_table_yearly,'median_length_of_stay',segmentAnalytics.name)
        }else {
        title = t("Segment Overview") + " " + moment(this.state.graphMonthChangeDate).format("MMMM YYYY");
        revenue=this.monthlyOverviewCal(segmentAnalytics.report_table_monthly,'revenue',segmentAnalytics.name)
        occupancy=this.monthlyOverviewCal(segmentAnalytics.report_table_monthly,'occupancy',segmentAnalytics.name)
        adr=this.monthlyOverviewCal(segmentAnalytics.report_table_monthly,'adr',segmentAnalytics.name)
        medianLeadTime=this.monthlyOverviewCal(segmentAnalytics.report_table_monthly,'median_lead_time',segmentAnalytics.name)
        los=this.monthlyOverviewCal(segmentAnalytics.report_table_monthly,'median_length_of_stay',segmentAnalytics.name)
        }

      }else if(this.state.analyticsDropdownVal == 1){
       if(this.state.showFullYearGraph){
        title = t("Room Type Overview") + " " + moment(this.state.yearChangeDate).format("YYYY");      
        revenue=this.yearlyOverviewCal(roomtypeAnalytics.report_table_yearly,'revenue',roomtypeAnalytics.name)
        occupancy=this.yearlyOverviewCal(roomtypeAnalytics.report_table_yearly,'occupancy',roomtypeAnalytics.name)
        adr=this.yearlyOverviewCal(roomtypeAnalytics.report_table_yearly,'adr',roomtypeAnalytics.name)
        medianLeadTime=this.yearlyOverviewCal(roomtypeAnalytics.report_table_yearly,'median_lead_time',roomtypeAnalytics.name)
        los=this.yearlyOverviewCal(roomtypeAnalytics.report_table_yearly,'median_length_of_stay',roomtypeAnalytics.name)
       } else {
        title = t("Room Type Overview") + " " + moment(this.state.graphMonthChangeDate).format("MMM YYYY");      
        revenue=this.monthlyOverviewCal(roomtypeAnalytics.report_table_monthly,'revenue',roomtypeAnalytics.name)
        occupancy=this.monthlyOverviewCal(roomtypeAnalytics.report_table_monthly,'occupancy',roomtypeAnalytics.name)
        adr=this.monthlyOverviewCal(roomtypeAnalytics.report_table_monthly,'adr',roomtypeAnalytics.name)
        medianLeadTime=this.monthlyOverviewCal(roomtypeAnalytics.report_table_monthly,'median_lead_time',roomtypeAnalytics.name)
        los=this.monthlyOverviewCal(roomtypeAnalytics.report_table_monthly,'median_length_of_stay',roomtypeAnalytics.name)
       }

      }else if(this.state.analyticsDropdownVal == 2){
      if(this.state.showFullYearGraph){
        title = t("Channel Overview") + " " + moment(this.state.yearChangeDate).format("YYYY");
        revenue=this.yearlyOverviewCal(channelAnalytics.report_table_yearly,'revenue',channelAnalytics.name)
        occupancy=this.yearlyOverviewCal(channelAnalytics.report_table_yearly,'occupancy',channelAnalytics.name)
        adr=this.yearlyOverviewCal(channelAnalytics.report_table_yearly,'adr',channelAnalytics.name)
        medianLeadTime=this.yearlyOverviewCal(channelAnalytics.report_table_yearly,'median_lead_time',channelAnalytics.name)
        los=this.yearlyOverviewCal(channelAnalytics.report_table_yearly,'median_length_of_stay',channelAnalytics.name)
      } else{
        title = t("Channel Overview") + " " + moment(this.state.graphMonthChangeDate).format("MMM YYYY");
        revenue=this.monthlyOverviewCal(channelAnalytics.report_table_monthly,'revenue',channelAnalytics.name)
        occupancy=this.monthlyOverviewCal(channelAnalytics.report_table_monthly,'occupancy',channelAnalytics.name)
        adr=this.monthlyOverviewCal(channelAnalytics.report_table_monthly,'adr',channelAnalytics.name)
        medianLeadTime=this.monthlyOverviewCal(channelAnalytics.report_table_monthly,'median_lead_time',channelAnalytics.name)
        los=this.monthlyOverviewCal(channelAnalytics.report_table_monthly,'median_length_of_stay',channelAnalytics.name)
      }

      }else if(this.state.analyticsDropdownVal == 3){
        if (this.state.showFullYearGraph) {
        title = t("Weekday Overview") + " " + moment(this.state.yearChangeDate).format("YYYY");
        revenue=this.yearlyOverviewCal(weekdayAnalytics.report_table_yearly,'revenue',weekdayAnalytics.name)
        occupancy=this.yearlyOverviewCal(weekdayAnalytics.report_table_yearly,'occupancy',weekdayAnalytics.name)
        adr=this.yearlyOverviewCal(weekdayAnalytics.report_table_yearly,'adr',weekdayAnalytics.name)
        medianLeadTime=this.yearlyOverviewCal(weekdayAnalytics.report_table_yearly,'median_lead_time',weekdayAnalytics.name)
        los=this.yearlyOverviewCal(weekdayAnalytics.report_table_yearly,'median_length_of_stay',weekdayAnalytics.name)
        } else {
        title = t("Weekday Overview") + " " + moment(this.state.graphMonthChangeDate).format("MMM YYYY");
        revenue=this.monthlyOverviewCal(weekdayAnalytics.report_table_monthly,'revenue',weekdayAnalytics.name)
        occupancy=this.monthlyOverviewCal(weekdayAnalytics.report_table_monthly,'occupancy',weekdayAnalytics.name)
        adr=this.monthlyOverviewCal(weekdayAnalytics.report_table_monthly,'adr',weekdayAnalytics.name)
        medianLeadTime=this.monthlyOverviewCal(weekdayAnalytics.report_table_monthly,'median_lead_time',weekdayAnalytics.name)
        los=this.monthlyOverviewCal(weekdayAnalytics.report_table_monthly,'median_length_of_stay',weekdayAnalytics.name)
        }
       
      }else if(this.state.analyticsDropdownVal == 4){
       if(this.state.showFullYearGraph){
        title = t("Custom Segment Overview") + " " + moment(this.state.yearChangeDate).format("YYYY");
        revenue=this.yearlyOverviewCal(customSegmentAnalytics.report_table_yearly,'revenue',customSegmentAnalytics.name)
        occupancy=this.yearlyOverviewCal(customSegmentAnalytics.report_table_yearly,'occupancy',customSegmentAnalytics.name)
        adr=this.yearlyOverviewCal(customSegmentAnalytics.report_table_yearly,'adr',customSegmentAnalytics.name)
        medianLeadTime=this.yearlyOverviewCal(customSegmentAnalytics.report_table_yearly,'median_lead_time',customSegmentAnalytics.name)
        los=this.yearlyOverviewCal(customSegmentAnalytics.report_table_yearly,'median_length_of_stay',customSegmentAnalytics.name)
       } else {
        title = t("Custom Segment Overview") + " " + moment(this.state.graphMonthChangeDate).format("MMM YYYY");
        revenue=this.monthlyOverviewCal(customSegmentAnalytics.report_table_monthly,'revenue',customSegmentAnalytics.name)
        occupancy=this.monthlyOverviewCal(customSegmentAnalytics.report_table_monthly,'occupancy',customSegmentAnalytics.name)
        adr=this.monthlyOverviewCal(customSegmentAnalytics.report_table_monthly,'adr',customSegmentAnalytics.name)
        medianLeadTime=this.monthlyOverviewCal(customSegmentAnalytics.report_table_monthly,'median_lead_time',customSegmentAnalytics.name)
        los=this.monthlyOverviewCal(customSegmentAnalytics.report_table_monthly,'median_length_of_stay',customSegmentAnalytics.name)
       }
      }
    }else{
      return "";
    }

    return(
      <>
      <Card id="revanueCard"> 
        <CardContent style={{ padding: '15px 20px 20px 20px' }}>
          <Grid container>
            <Grid item xs={12} >
              <div style={{ float: "left", marginTop: "2px" }}>
                <Typography variant="h6" style={{ textAlign: "left", fontWeight: "500" }}>
                  {title}
                </Typography>
              </div>
            </Grid>
          </Grid>
            <MuiThemeProvider theme={performanceDashboardTheme}>
          <Grid container spacing={2} className={classes.performanceCard} style={{flexWrap:"unset"}}>
              <Grid item  style={{flex: "1", whiteSpace:"nowrap"}}>
                <div className={classes.cardBorder} style={{display: 'flex', flexDirection:"column", paddingLeft:"16px"}}>
                
                  <Grid item xs={12}>
                    <Typography className={classes.performanceTitle} >{t('Revenue')}</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.flexCenter} >
                    <span className={classes.performancePrice}>
                      {
                        (revenue?.total || revenue?.total == 0) ? currencySymbol+ new Intl.NumberFormat('ja-JP').format(revenue?.total) : "n.a." 
                      }
                    </span>
                    <Tooltip
                    classes={{ popper: classes.htmlPopper, tooltip: classes.htmlTooltip, }}
                    title={<Typography className={classes.htmlTooltip}>{t('vs Same Time Last Year')}</Typography>}>
                      <div className={classes.currentTitle} style={{ background:  '#FAC6D1' }}>
                        {/* +568% */}
                      </div>
                    </Tooltip>
                      
                  </Grid>

                  {this.generateColumnJsx(revenue, "revenue")}
                  
              </div>
              </Grid>

              <Grid item  style={{flex: "1", whiteSpace:"nowrap"}}>
                <div className={classes.cardBorder}  style={{display: 'flex', flexDirection:"column", paddingLeft:"16px"}}>
                    <Grid item xs={12}>
                      <Typography className={classes.performanceTitle} >{t('Occupancy')}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.flexCenter} >
                      <span className={classes.performancePrice}>
                        {/* {console.log("occupancy.total", occupancy.total)} */}
                      {
                         (occupancy.total || occupancy.total === 0) ? occupancy.total + '%' : "n.a."
                      }
                      </span>
                      <Tooltip
                      classes={{ popper: classes.htmlPopper, tooltip: classes.htmlTooltip, }}
                      title={<Typography className={classes.htmlTooltip}>{t('vs Same Time Last Year')}</Typography>}>
                        <div className={classes.currentTitle} style={{ background:  '#FAC6D1' }}>
                          {/* +568% */}
                        </div>
                      </Tooltip>
                        
                    </Grid>

                    {this.generateColumnJsx(occupancy, "occupancy")}

                </div>
              </Grid>

              <Grid item  style={{flex: "1", whiteSpace:"nowrap"}}>
                <div className={classes.cardBorder}  style={{display: 'flex', flexDirection:"column", paddingLeft:"16px"}}>
                    <Grid item xs={12}>
                      <Typography className={classes.performanceTitle} >{t('ADR')}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.flexCenter} >
                      <span className={classes.performancePrice}>
                      {
                        (adr?.total || adr?.total === 0) ? currencySymbol + new Intl.NumberFormat('ja-JP').format(adr?.total) : "n.a." 
                      }
                      </span>
                      <Tooltip
                      classes={{ popper: classes.htmlPopper, tooltip: classes.htmlTooltip, }}
                      title={<Typography className={classes.htmlTooltip}>{t('vs Same Time Last Year')}</Typography>}>
                        <div className={classes.currentTitle} style={{ background:  '#FAC6D1' }}>
                          {/* +568% */}
                        </div>
                      </Tooltip>
                    </Grid>
                    
                    {this.generateColumnJsx(adr, "adr")}


                </div>
              </Grid>

              <Grid item  style={{flex: "1", whiteSpace:"nowrap"}}>
                <div className={classes.cardBorder}  style={{display: 'flex', flexDirection:"column", paddingLeft:"16px"}}>
                    <Grid item xs={12}>
                      <Typography className={classes.performanceTitle} >{t('Median Lead Time')}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.flexCenter} >
                      <span className={classes.performancePrice}> 
                      {
                        (medianLeadTime.total || medianLeadTime.total === 0) ? new Intl.NumberFormat('ja-JP').format(medianLeadTime.total) : "n.a." 
                      }
                      </span>
                      <Tooltip
                      classes={{ popper: classes.htmlPopper, tooltip: classes.htmlTooltip, }}
                      title={<Typography className={classes.htmlTooltip}>{t('vs Same Time Last Year')}</Typography>}>
                        <div className={classes.currentTitle} style={{ background:  '#FAC6D1' }}>
                          {/* +568% */}
                        </div>
                      </Tooltip>
                        
                    </Grid>
                    {this.generateColumnJsx(medianLeadTime, "medianLeadTime")}

                </div>
              
              </Grid>

              <Grid item  style={{flex: "1", whiteSpace:"nowrap"}}>
                <div className={classes.cardBorder}  style={{display: 'flex', flexDirection:"column", paddingLeft:"16px"}}>
                    <Grid item xs={12}>
                      <Typography className={classes.performanceTitle} >{t('Median LOS in Nights')}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.flexCenter} >
                      <span className={classes.performancePrice}>
                      {
                        (los.total || los.total === 0) ? los.total : "n.a." 
                      }
                      </span>
                      <Tooltip
                      classes={{ popper: classes.htmlPopper, tooltip: classes.htmlTooltip, }}
                      title={<Typography className={classes.htmlTooltip}>{t('vs Same Time Last Year')}</Typography>}>
                        <div className={classes.currentTitle} style={{ background:  '#FAC6D1' }}>
                          {/* +568% */}
                        </div>
                      </Tooltip>
                        
                    </Grid>
                    {this.generateColumnJsx(los, "los")}                   
                </div>
              
              </Grid>

              </Grid>
            </MuiThemeProvider>
        </CardContent>       
      </Card>
      {
    this.state.showFullYearGraph ? (
     <Card  style={{ marginTop: '50px' }} >
        <CardContent style={{ padding: '15px 20px 20px 20px' }}>
          {
            this.analyticsGraph()
          }          
        </CardContent>
      </Card>
        ) : null
      }
 
      </>
    )
  }
  analyticsDropdown=(val)=>{
    this.setState({
      analyticsDropdownVal:val
    })

  }
changeServices = (val) => {   
  this.setState({ 
    dashboardServices: val 
  }, () => {
    localStorage.setItem('dashboardServices', JSON.stringify(this.state.dashboardServices));
    this.setAdr();
  });
}
  bookingCurveModal=()=>{
      this.setState({
      bookingCurveModalOpen:true
    })
  }
  bookingCurveModalClose=()=>{
    this.setState({
      bookingCurveModalOpen:false
    })
  }
  render() {
    const { classes } = this.props;
    const { t } = this.props;
    const {defaultBudget,yearChangeDate,graphMonthChangeDate,performanceStly, performanceCurrent,last_reservation_received,performanceReservationLoading,kpi_yearly_total,kpi_yearly_stly,allMonths} = this.state;
    const currencySymbol = this.state.currency.symbol
    let revDelta = null;
    let adrDelta = null;
    let occupancyDelta = null;
    let revparDelta = null;
    
    let revenuePrice=null;
    let adrPrice=null;
    let occupancyPrice=null;
    let revparPrice=null;

    let revenueStlyPrice=null;
    let adrStlyPrice=null;
    let revparStlyPrice=null;
    
    let revenueTotalPrice=null;
    let adrTotalPrice=null;
    let revparTotalPrice=null;
    let revenueName=null;
    let adrName=null;
    let revperName=null;
    let forecastBudget=null;
    let forecastRevenue=null;

    let isDisplayForecast = false;
    
    
    if(this.state.showFullYearGraph){
      if(this.state.dashboardServices == 0){
        revenueName='REVENUE'
        adrName='ADR'
        revperName='REV_PAR'


        


        revenuePrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["REVENUE"])) : 'n.a.'
        occupancyPrice= kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["OCCUPANCY"],true)) : 'n.a.'
        adrPrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["ADR"])) : 'n.a.'
        revparPrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["REV_PAR"])) : 'n.a.'
        //stly
        revenueStlyPrice=kpi_yearly_stly ? (this.getPerformanceValue(kpi_yearly_stly["REVENUE"],false,true)) : 'n.a.'
        adrStlyPrice=kpi_yearly_stly ? (this.getPerformanceValue(kpi_yearly_stly["ADR"],false,true)) : 'n.a.'
        revparStlyPrice=kpi_yearly_stly ? (this.getPerformanceValue(kpi_yearly_stly["REV_PAR"],false,true)) : 'n.a.'
        //total
        revenueTotalPrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["REVENUE"],false,true)) : 'n.a.'
        adrTotalPrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["ADR"],false,true)) : 'n.a.'
        revparTotalPrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["REV_PAR"],false,true)) : 'n.a.'

      }else if(this.state.dashboardServices == 1){
        revenueName='REVENUE_INCL_SERVICES'
        adrName='ADR_INCL_SERVICES'
        revperName='REV_PAR_INCL_SERVICES'

        revenuePrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["REVENUE_INCL_SERVICES"])) : 'n.a.'
        occupancyPrice= kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["OCCUPANCY"],true)) : 'n.a.'
        adrPrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["ADR_INCL_SERVICES"])) : 'n.a.'
        revparPrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["REV_PAR_INCL_SERVICES"])) : 'n.a.'
        //stly
        revenueStlyPrice=kpi_yearly_stly ? (this.getPerformanceValue(kpi_yearly_stly["REVENUE_INCL_SERVICES"],false,true)) : 'n.a.'
        adrStlyPrice=kpi_yearly_stly ? (this.getPerformanceValue(kpi_yearly_stly["ADR_INCL_SERVICES"],false,true)) : 'n.a.'
        revparStlyPrice=kpi_yearly_stly ? (this.getPerformanceValue(kpi_yearly_stly["REV_PAR_INCL_SERVICES"],false,true)) : 'n.a.'
        //total
        revenueTotalPrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["REVENUE_INCL_SERVICES"],false,true)) : 'n.a.'
        adrTotalPrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["ADR_INCL_SERVICES"],false,true)) : 'n.a.'
        revparTotalPrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["REV_PAR_INCL_SERVICES"],false,true)) : 'n.a.'
      }else if(this.state.dashboardServices == 2){
        revenueName='REVENUE_NET_INCL_SERVICES'
        adrName='ADR_NET_INCL_SERVICES'
        revperName='REV_PAR_NET_INCL_SERVICES'

        revenuePrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["REVENUE_NET_INCL_SERVICES"])) : 'n.a.'
        occupancyPrice= kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["OCCUPANCY"],true)) : 'n.a.'
        adrPrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["ADR_NET_INCL_SERVICES"])) : 'n.a.'
        revparPrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["REV_PAR_NET_INCL_SERVICES"])) : 'n.a.'
         //stly
         revenueStlyPrice=kpi_yearly_stly ? (this.getPerformanceValue(kpi_yearly_stly["REVENUE_NET_INCL_SERVICES"],false,true)) : 'n.a.'
         adrStlyPrice=kpi_yearly_stly ? (this.getPerformanceValue(kpi_yearly_stly["ADR_NET_INCL_SERVICES"],false,true)) : 'n.a.'
         revparStlyPrice=kpi_yearly_stly ? (this.getPerformanceValue(kpi_yearly_stly["REV_PAR_NET_INCL_SERVICES"],false,true)) : 'n.a.'
        //total
        revenueTotalPrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["REVENUE_NET_INCL_SERVICES"],false,true)) : 'n.a.'
        adrTotalPrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["ADR_NET_INCL_SERVICES"],false,true)) : 'n.a.'
        revparTotalPrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["REV_PAR_NET_INCL_SERVICES"],false,true)) : 'n.a.'
      }else if(this.state.dashboardServices == 3){
        revenueName='REVENUE_NET'
        adrName='ADR_NET'
        revperName='REV_PAR_NET'

        revenuePrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["REVENUE_NET"])) : 'n.a.'
        occupancyPrice= kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["OCCUPANCY"],true)) : 'n.a.'
        adrPrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["ADR_NET"])) : 'n.a.'
        revparPrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["REV_PAR_NET"])) : 'n.a.'
       //stly
       revenueStlyPrice=kpi_yearly_stly ? (this.getPerformanceValue(kpi_yearly_stly["REVENUE_NET"],false,true)) : 'n.a.'
       adrStlyPrice=kpi_yearly_stly ? (this.getPerformanceValue(kpi_yearly_stly["ADR_NET"],false,true)) : 'n.a.'
       revparStlyPrice=kpi_yearly_stly ? (this.getPerformanceValue(kpi_yearly_stly["REV_PAR_NET"],false,true)) : 'n.a.'
      //total
      revenueTotalPrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["REVENUE_NET"],false,true)) : 'n.a.'
      adrTotalPrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["ADR_NET"],false,true)) : 'n.a.'
      revparTotalPrice=kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["REV_PAR_NET"],false,true)) : 'n.a.'
      }
    }else{
      let selectedMonth = moment(this.state.graphMonthChangeDate).format('YYYY-MM');

      let thisMonth = moment(this.state.todayDate).format('YYYY-MM')
      let lastMonthForecast = moment(this.state.todayDate).add(11,"month");

     if(moment(this.state.graphMonthChangeDate).isSameOrAfter(thisMonth, "months") && moment(this.state.graphMonthChangeDate).isSameOrBefore(lastMonthForecast,"month")){
       isDisplayForecast = true;
     }      

      if(this.state.forecastData && this.state.forecastData.revenue_budget_gross_excl_services){
        if(this.state.dashboardServices == 0 || this.state.dashboardServices == 1){
          forecastBudget = this.state.forecastData.revenue_budget_gross_excl_services[selectedMonth]
          forecastRevenue = this.state.forecastData.forecasted_values_gross[selectedMonth]
        } else{  
          forecastBudget = this.state.forecastData.revenue_budget_net_excl_services[selectedMonth]
          forecastRevenue = this.state.forecastData.forecasted_values_net[selectedMonth]
        }
      }
      

      if(this.state.dashboardServices == 0){
        revenueName='REVENUE'
        adrName='ADR'
        revperName='REV_PAR'

        revenuePrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["REVENUE"])) : 'n.a.'
        occupancyPrice= performanceCurrent ? (this.getPerformanceValue(performanceCurrent["OCCUPANCY"],true)) : 'n.a.'
        adrPrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["ADR"])) : 'n.a.'
        revparPrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["REV_PAR"])) : 'n.a.'
        //stly
        revenueStlyPrice=performanceStly ? (this.getPerformanceValue(performanceStly["REVENUE"],false,true)) : 'n.a.'
        adrStlyPrice=performanceStly ? (this.getPerformanceValue(performanceStly["ADR"],false,true)) : 'n.a.'
        revparStlyPrice=performanceStly ? (this.getPerformanceValue(performanceStly["REV_PAR"],false,true)) : 'n.a.'
        // total
        revenueTotalPrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["REVENUE"],false,true)) : 'n.a.'
        adrTotalPrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["ADR"],false,true)) : 'n.a.'
        revparTotalPrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["REV_PAR"],false,true)) : 'n.a.'
        
      }else if(this.state.dashboardServices == 1){
        revenueName='REVENUE_INCL_SERVICES'
        adrName='ADR_INCL_SERVICES'
        revperName='REV_PAR_INCL_SERVICES'

        revenuePrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["REVENUE_INCL_SERVICES"])) : 'n.a.'
        occupancyPrice= performanceCurrent ? (this.getPerformanceValue(performanceCurrent["OCCUPANCY"],true)) : 'n.a.'
        adrPrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["ADR_INCL_SERVICES"])) : 'n.a.'
        revparPrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["REV_PAR_INCL_SERVICES"])) : 'n.a.'
         //stly
         revenueStlyPrice=performanceStly ? (this.getPerformanceValue(performanceStly["REVENUE_INCL_SERVICES"],false,true)) : 'n.a.'
         adrStlyPrice=performanceStly ? (this.getPerformanceValue(performanceStly["ADR_INCL_SERVICES"],false,true)) : 'n.a.'
        revparStlyPrice=performanceStly ? (this.getPerformanceValue(performanceStly["REV_PAR_INCL_SERVICES"],false,true)) : 'n.a.'
        // total
        revenueTotalPrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["REVENUE"],false,true)) : 'n.a.'
        adrTotalPrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["ADR"],false,true)) : 'n.a.'
        revparTotalPrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["REV_PAR"],false,true)) : 'n.a.'
        
      }else if(this.state.dashboardServices == 2){
        revenueName='REVENUE_NET_INCL_SERVICES'
        adrName='ADR_NET_INCL_SERVICES'
        revperName='REV_PAR_NET_INCL_SERVICES'

        revenuePrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["REVENUE_NET_INCL_SERVICES"])) : 'n.a.'
        occupancyPrice= performanceCurrent ? (this.getPerformanceValue(performanceCurrent["OCCUPANCY"],true)) : 'n.a.'
        adrPrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["ADR_NET_INCL_SERVICES"])) : 'n.a.'
        revparPrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["REV_PAR_NET_INCL_SERVICES"])) : 'n.a.'
         //stly
         revenueStlyPrice=performanceStly ? (this.getPerformanceValue(performanceStly["REVENUE_NET_INCL_SERVICES"],false,true)) : 'n.a.'
         adrStlyPrice=performanceStly ? (this.getPerformanceValue(performanceStly["ADR_NET_INCL_SERVICES"],false,true)) : 'n.a.'
        revparStlyPrice=performanceStly ? (this.getPerformanceValue(performanceStly["REV_PAR_NET_INCL_SERVICES"],false,true)) : 'n.a.'
        // total
        revenueTotalPrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["REVENUE_NET_INCL_SERVICES"],false,true)) : 'n.a.'
        adrTotalPrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["ADR_NET_INCL_SERVICES"],false,true)) : 'n.a.'
        revparTotalPrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["REV_PAR_NET_INCL_SERVICES"],false,true)) : 'n.a.'
 
      }else if(this.state.dashboardServices == 3){
        revenueName='REVENUE_NET'
        adrName='ADR_NET'
        revperName='REV_PAR_NET'

        revenuePrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["REVENUE_NET"])) : 'n.a.'
        occupancyPrice= performanceCurrent ? (this.getPerformanceValue(performanceCurrent["OCCUPANCY"],true)) : 'n.a.'
        adrPrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["ADR_NET"])) : 'n.a.'
        revparPrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["REV_PAR_NET"])) : 'n.a.'
        //stly
        revenueStlyPrice=performanceStly ? (this.getPerformanceValue(performanceStly["REVENUE_NET"],false,true)) : 'n.a.'
        adrStlyPrice=performanceStly ? (this.getPerformanceValue(performanceStly["ADR_NET"],false,true)) : 'n.a.'
       revparStlyPrice=performanceStly ? (this.getPerformanceValue(performanceStly["REV_PAR_NET"],false,true)) : 'n.a.'
       // total
       revenueTotalPrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["REVENUE_NET"],false,true)) : 'n.a.'
       adrTotalPrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["ADR_NET"],false,true)) : 'n.a.'
       revparTotalPrice=performanceCurrent ? (this.getPerformanceValue(performanceCurrent["REV_PAR_NET"],false,true)) : 'n.a.'

      }
    }
    
    //updown Values
    if(this.state.showFullYearGraph){
      if (kpi_yearly_total && kpi_yearly_stly) {
        if(this.state.dashboardServices == 0){
          revDelta = this.getUpDownPerformanceValue(kpi_yearly_total["REVENUE"], kpi_yearly_stly["REVENUE"],"REVENUE")
          occupancyDelta = this.getUpDownPerformanceValue(kpi_yearly_total["OCCUPANCY"], kpi_yearly_stly["OCCUPANCY"],"OCCUPANCY")
          adrDelta = this.getUpDownPerformanceValue(kpi_yearly_total["ADR"], kpi_yearly_stly["ADR"],"ADR")
          revparDelta = this.getUpDownPerformanceValue(kpi_yearly_total["REV_PAR"], kpi_yearly_stly["REV_PAR"],"REVPAR")
        }else if(this.state.dashboardServices == 1){
          revDelta = this.getUpDownPerformanceValue(kpi_yearly_total["REVENUE_INCL_SERVICES"], kpi_yearly_stly["REVENUE_INCL_SERVICES"],"REVENUE")
          occupancyDelta = this.getUpDownPerformanceValue(kpi_yearly_total["OCCUPANCY"], kpi_yearly_stly["OCCUPANCY"],"OCCUPANCY")
          adrDelta = this.getUpDownPerformanceValue(kpi_yearly_total["ADR_INCL_SERVICES"], kpi_yearly_stly["ADR_INCL_SERVICES"],"ADR")
          revparDelta = this.getUpDownPerformanceValue(kpi_yearly_total["REV_PAR_INCL_SERVICES"], kpi_yearly_stly["REV_PAR_INCL_SERVICES"],"REVPAR")
        }else if(this.state.dashboardServices == 2){
          revDelta = this.getUpDownPerformanceValue(kpi_yearly_total["REVENUE_NET_INCL_SERVICES"], kpi_yearly_stly["REVENUE_NET_INCL_SERVICES"],"REVENUE")
          occupancyDelta = this.getUpDownPerformanceValue(kpi_yearly_total["OCCUPANCY"], kpi_yearly_stly["OCCUPANCY"],"OCCUPANCY")
          adrDelta = this.getUpDownPerformanceValue(kpi_yearly_total["ADR_NET_INCL_SERVICES"], kpi_yearly_stly["ADR_NET_INCL_SERVICES"],"ADR")
          revparDelta = this.getUpDownPerformanceValue(kpi_yearly_total["REV_PAR_NET_INCL_SERVICES"], kpi_yearly_stly["REV_PAR_NET_INCL_SERVICES"],"REVPAR")
        } else if(this.state.dashboardServices == 3){
          revDelta = this.getUpDownPerformanceValue(kpi_yearly_total["REVENUE_NET"], kpi_yearly_stly["REVENUE_NET"],"REVENUE")
          occupancyDelta = this.getUpDownPerformanceValue(kpi_yearly_total["OCCUPANCY"], kpi_yearly_stly["OCCUPANCY"],"OCCUPANCY")
          adrDelta = this.getUpDownPerformanceValue(kpi_yearly_total["ADR_NET"], kpi_yearly_stly["ADR_NET"],"ADR")
          revparDelta = this.getUpDownPerformanceValue(kpi_yearly_total["REV_PAR_NET"], kpi_yearly_stly["REV_PAR_NET"],"REVPAR")
        }       
      }
    }else{
      if (performanceCurrent && performanceStly) {
       
        // revDelta = this.getUpDownPerformanceValue(performanceCurrent["REVENUE"], performanceStly["REVENUE"],"REVENUE")
        // occupancyDelta = this.getUpDownPerformanceValue(performanceCurrent["OCCUPANCY"], performanceStly["OCCUPANCY"],"OCCUPANCY")
        // adrDelta = this.getUpDownPerformanceValue(performanceCurrent["ADR"], performanceStly["ADR"],"ADR")
        // revparDelta = this.getUpDownPerformanceValue(performanceCurrent["REV_PAR"], performanceStly["REV_PAR"],"REVPAR")

        if(this.state.dashboardServices == 0){
          revDelta = this.getUpDownPerformanceValue(performanceCurrent["REVENUE"], performanceStly["REVENUE"],"REVENUE")
          occupancyDelta = this.getUpDownPerformanceValue(performanceCurrent["OCCUPANCY"], performanceStly["OCCUPANCY"],"OCCUPANCY")
          adrDelta = this.getUpDownPerformanceValue(performanceCurrent["ADR"], performanceStly["ADR"],"ADR")
          revparDelta = this.getUpDownPerformanceValue(performanceCurrent["REV_PAR"], performanceStly["REV_PAR"],"REVPAR")
        }else if(this.state.dashboardServices == 1){
          revDelta = this.getUpDownPerformanceValue(performanceCurrent["REVENUE_INCL_SERVICES"], performanceStly["REVENUE_INCL_SERVICES"],"REVENUE")
          occupancyDelta = this.getUpDownPerformanceValue(performanceCurrent["OCCUPANCY"], performanceStly["OCCUPANCY"],"OCCUPANCY")
          adrDelta = this.getUpDownPerformanceValue(performanceCurrent["ADR_INCL_SERVICES"], performanceStly["ADR_INCL_SERVICES"],"ADR")
          revparDelta = this.getUpDownPerformanceValue(performanceCurrent["REV_PAR_INCL_SERVICES"], performanceStly["REV_PAR_INCL_SERVICES"],"REVPAR")
        }else if(this.state.dashboardServices == 2){
          revDelta = this.getUpDownPerformanceValue(performanceCurrent["REVENUE_NET_INCL_SERVICES"], performanceStly["REVENUE_NET_INCL_SERVICES"],"REVENUE")
          occupancyDelta = this.getUpDownPerformanceValue(performanceCurrent["OCCUPANCY"], performanceStly["OCCUPANCY"],"OCCUPANCY")
          adrDelta = this.getUpDownPerformanceValue(performanceCurrent["ADR_NET_INCL_SERVICES"], performanceStly["ADR_NET_INCL_SERVICES"],"ADR")
          revparDelta = this.getUpDownPerformanceValue(performanceCurrent["REV_PAR_NET_INCL_SERVICES"], performanceStly["REV_PAR_NET_INCL_SERVICES"],"REVPAR")
        } else if(this.state.dashboardServices == 3){
          revDelta = this.getUpDownPerformanceValue(performanceCurrent["REVENUE_NET"], performanceStly["REVENUE_NET"],"REVENUE")
          occupancyDelta = this.getUpDownPerformanceValue(performanceCurrent["OCCUPANCY"], performanceStly["OCCUPANCY"],"OCCUPANCY")
          adrDelta = this.getUpDownPerformanceValue(performanceCurrent["ADR_NET"], performanceStly["ADR_NET"],"ADR")
          revparDelta = this.getUpDownPerformanceValue(performanceCurrent["REV_PAR_NET"], performanceStly["REV_PAR_NET"],"REVPAR")
        }
      }
    }
    let reservationDate;
    if(last_reservation_received){
      if (moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY') == moment.tz(last_reservation_received, this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY')) {
        reservationDate = t('Today') + moment.tz(last_reservation_received, this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format(', hh:mm A');
      }else{
        reservationDate=moment.tz(last_reservation_received, this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM, hh:mm A')
      }
    }
    let yearlyAverage=0;
    let monthlyAverage=0;
    let yearlyOTB=0;
    let monthlyOTB=0;
    let totalYearlyBudget=0;
    let totalMonthlyBudget=0;
    let currentMonth=moment(graphMonthChangeDate).format('YYYY-MM');
    let currentYear=moment(yearChangeDate).format('YYYY');
    let showBookingCurve=moment(graphMonthChangeDate).isSameOrBefore(moment(this.state.bookingCurveGraphEndDate,"MM-YYYY"),"month")
    // moment(graphMonthChangeDate).format('MM-YYYY') >= this.state.bookingCurveGraphStartDate ||

    let serviceKey = this.getServiceKey();
    allMonths.map(val=>{
      if(this.state.revenueBudgetVal[serviceKey] && this.state.revenueBudgetVal[serviceKey][val] && this.state.revenueBudgetVal[serviceKey][val] > 0){
        totalYearlyBudget+=this.state.revenueBudgetVal[serviceKey][val]
      }
      // else{
      //   let defaultBudgetVal=defaultBudget?.[val]?.dashboard_default_budget;
      //   totalYearlyBudget+=(defaultBudgetVal?defaultBudgetVal:0)
      // }      
    })
    if(this.state.revenueBudgetVal[serviceKey] && this.state.revenueBudgetVal[serviceKey][currentMonth]){
      totalMonthlyBudget=this.state.revenueBudgetVal[serviceKey][currentMonth];
    }
    // else{
    //   totalMonthlyBudget=this.state.defaultBudget?.[currentMonth]?.dashboard_default_budget
    // }
    if(kpi_yearly_total && performanceCurrent){
      yearlyAverage= Math.floor(kpi_yearly_total['REVENUE'][currentYear]/12)
      monthlyAverage=Math.floor(performanceCurrent['REVENUE'][currentMonth]/12)
      yearlyOTB=kpi_yearly_total['REVENUE']?.[currentYear];
      monthlyOTB=performanceCurrent['REVENUE']?.[currentMonth];
    }
    let is_admin=this.props.userSession.user.is_staff? true : false 

    let disableNext = false;
    let disablePrev = false;
    // console.log((this.state.graphStartDate == moment(this.state.graphMonthChangeDate).format('MM-YYYY') || (!this.state.featuresEnabled.includes(25) && moment(this.state.todayDate).subtract(1,"month").format('MM-YYYY') == moment(this.state.graphMonthChangeDate).format('MM-YYYY'))))
    if(this.state.graphStartDate == moment(this.state.graphMonthChangeDate).format('MM-YYYY') || (!this.state.featuresEnabled.includes(25) && moment(this.state.todayDate).subtract(1,"month").format('MM-YYYY') == moment(this.state.graphMonthChangeDate).format('MM-YYYY'))){
      // if(this.state.graphStartDate == moment(this.state.graphMonthChangeDate).format('MM-YYYY')){
      disablePrev = true;
    }

    if(this.state.graphEndDate == moment(this.state.graphMonthChangeDate).format('MM-YYYY') || (!this.state.featuresEnabled.includes(25) && moment(this.state.todayDate).add(1,"month").format('MM-YYYY') == moment(this.state.graphMonthChangeDate).format('MM-YYYY'))){
      // if(this.state.graphEndDate == moment(this.state.graphMonthChangeDate).format('MM-YYYY')){
      disableNext = true;
    }

    return (
      <div> 
      <HeaderCard>
        <Grid container>
            <Grid item xs={12}>
                <div style={{ float: "left", marginTop: "2px" }}>
                <Typography variant="h5" style={{ textAlign: "left", fontWeight: "500" }}>
                  {t("Dashboard")}
                  <CustomTooltip title="" description={t("The performance dashboard shows the direct revenue (excluding additional revenue) and other KPIs of your room types mapped in RoomPriceGenie.")} />
                </Typography>
                </div>
            </Grid>
            {this.state.featuresEnabled.includes(25) &&
              <Grid item xs={12}>
                  <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="on"
                    TabIndicatorProps={{ style: { background: "#0073ea" } }}
                    value={this.state.selectedTab}
                    className={classes.TabsView}   
                  >
                  <Tab onClick={() => this.changeTab(1)} label={t("Performance")} value={1} />
                  <Tab onClick={() => this.changeTab(2)} label={t("Analytics")} value={2} />    
                  </Tabs>
              </Grid>
            }
            <Grid item xs={12}>
            <div className={classNames(classes.dateNavigationDivGraph, /*classes.dateNavigationDivResponsive*/)}>
            {this.state.selectedTab == 1 && 
              <>
              {this.state.featuresEnabled.includes(25) &&
                <Grid className={[classes.toggleGrid,classes.responsiveGrpBtn]} style={{marginRight:"16px"}}>
                  <Button variant="outlined" className={classes.newTabBtnFirstGraph} onClick={() => this.fullYearGraph(true)} style={this.state.showFullYearGraph ? { background: "#f2f2f2" } : {}}>
                    {t("Yearly")}
                  </Button>
                  <Button variant="outlined" className={classes.newTabBtnLastGraph} onClick={() => this.fullYearGraph(false)} style={!this.state.showFullYearGraph ? { background: "#f2f2f2" } : {}}>
                    {t("Monthly")}
                  </Button>
                </Grid>
              } 
              {this.state.showFullYearGraph ?
                <>
                {/* <Typography variant="h6" className={ classes.filterDate }>{moment(this.state.yearChangeDate).format('YYYY')}</Typography> */}
                <MuiThemeProvider theme={monthPickerStyleSingleYear}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                    <InlineDatePicker
                          style={{ width: "60px", marginLeft:"0px", color: (this.state.showFullYearGraph && this.state.viewType == 'priceGraph') ? "#00000042" : '#00000042', fontWeight: "400" }}
                          views={["year"]}
                          value={moment(this.state.yearChangeDate)}
                          onChange={this.changeCalendarYear()}
                          format={'yyyy'}
                          autoOk={true}
                          KeyboardButtonProps={{ disabled: false, style: { display: 'none' } }}
                          InputProps={{ readOnly: true }}
                          InputLabelProps={{ shrink: true }}                              
                          maxDate={moment(this.state.tillYear).format('YYYY')}      
                          minDate={moment(this.state.startYear).format('YYYY')}
                          minDateMessage={""}
                          maxDateMessage={""}
                        />
                  </MuiPickersUtilsProvider>
                </MuiThemeProvider>
                <Button
                  disabled={(this.state.startYear == moment(this.state.yearChangeDate).format('YYYY')) ? true : false} className={[classes.calendarNaviBtn,classes.changeIcon]}
                  onClick={() => this.navigateYear(moment(this.state.yearChangeDate).subtract(1, 'year').format('YYYY'))}>
                  <Icon>navigate_before</Icon>
                </Button>
                <Button className={[classes.calendarNaviBtn,classes.changeIcon]}
                  disabled={(this.state.tillYear == moment(this.state.yearChangeDate).format('YYYY')) ? true : false} onClick={() => this.navigateYear(moment(this.state.yearChangeDate).add(1, 'year').format('YYYY'))}>
                  <Icon>navigate_next</Icon>
                </Button>
                <MuiThemeProvider theme={ButtonTheme} >
                  <Button
                    className={classes.fullWidthResp}
                    variant="outlined"
                    onClick={() => this.navigateYear(moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY'))}>
                    {t("Current Year")}
                  </Button>
                </MuiThemeProvider>
                </>
                :
                <>
                  {/* <Typography variant="h6" className={classes.filterDatePro}>{moment(this.state.graphMonthChangeDate).format('MMM YYYY')}</Typography> */}
                  <MuiThemeProvider theme={monthPickerStyle}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                    <InlineDatePicker
                          style={{ width: "97px", marginLeft:"0px", color: (this.state.showFullYearGraph && this.state.viewType == 'priceGraph') ? "#00000042" : '#00000042', fontWeight: "400" }}
                          views={["year","month"]}
                          value={moment(this.state.graphMonthChangeDate)}
                          onChange={this.changeCalendarMonthYear()}
                          format={'MMM yyyy'}
                          autoOk={true}
                          KeyboardButtonProps={{ disabled: false, style: { display: 'none' } }}
                          InputProps={{ readOnly: true }}
                          InputLabelProps={{ shrink: true }}                              
                          maxDate={moment('01-' + this.state.graphEndDate).format('YYYY-DD-MM')}      
                          minDate={moment('01-' + this.state.graphStartDate).format('YYYY-DD-MM')}
                          minDateMessage={""}
                          maxDateMessage={""}
                        />
                  </MuiPickersUtilsProvider>
                </MuiThemeProvider>
                  <Button
                    disabled={disablePrev}
                    className={[classes.calendarNaviBtn, classes.changeIcon]}
                    onClick={() => this.graphNavigateMonth(moment(this.state.graphMonthChangeDate).subtract(1, 'months').format('YYYY-MM-DD'))}>
                    <Icon >navigate_before</Icon>
                  </Button>
                  <Button className={[classes.calendarNaviBtn, classes.changeIcon]}
                    disabled={disableNext} onClick={() => this.graphNavigateMonth(moment(this.state.graphMonthChangeDate).add(1, 'months').format('YYYY-MM-DD'))}>
                    <Icon >navigate_next</Icon>
                  </Button>
                  <MuiThemeProvider theme={ButtonTheme} >
                    <Button
                      className={classes.fullWidthResp}
                      variant="outlined"
                      onClick={() => this.graphNavigateMonth(moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'))}>
                      {t("Current Month")}
                    </Button>
                  </MuiThemeProvider>
                </>
              } 
              </>
            }

                {
                  this.state.selectedTab==2 &&
                  <>
                {this.state.featuresEnabled.includes(25) &&
                <Grid className={[classes.toggleGrid,classes.responsiveGrpBtn]} style={{marginRight:"16px"}}>
                  <Button variant="outlined" className={classes.newTabBtnFirstGraph} onClick={() => this.fullYearGraph(true)} style={this.state.showFullYearGraph ? { background: "#f2f2f2" } : {}}>
                    {t("Yearly")}
                  </Button>
                  <Button variant="outlined" className={classes.newTabBtnLastGraph} onClick={() => this.fullYearGraph(false)} style={!this.state.showFullYearGraph ? { background: "#f2f2f2" } : {}}>
                    {t("Monthly")}
                  </Button>
                </Grid>
                } 

       {
        this.state.showFullYearGraph ? (
         <>
         <MuiThemeProvider theme={monthPickerStyleSingleYear}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                      <InlineDatePicker
                            style={{ width: "60px", marginLeft:"10px", color: (this.state.showFullYearGraph && this.state.viewType == 'priceGraph') ? "#00000042" : '#00000042', fontWeight: "400" }}
                            views={["year"]}
                            value={moment(this.state.yearChangeDate)}
                            onChange={this.changeCalendarYear()}
                            format={'yyyy'}
                            autoOk={true}
                            KeyboardButtonProps={{ disabled: false, style: { display: 'none' } }}
                            InputProps={{ readOnly: true }}
                            InputLabelProps={{ shrink: true }}                              
                            maxDate={moment(this.state.tillYear).format('YYYY')}      
                            minDate={moment(this.state.startYear).format('YYYY')}
                            minDateMessage={""}
                            maxDateMessage={""}
                          />
                    </MuiPickersUtilsProvider>
                  </MuiThemeProvider>
                  <Button
                    disabled={(this.state.startYear == moment(this.state.yearChangeDate).format('YYYY')) ? true : false} className={[classes.calendarNaviBtn,classes.changeIcon]}
                    onClick={() => this.navigateYear(moment(this.state.yearChangeDate).subtract(1, 'year').format('YYYY'))}>
                    <Icon>navigate_before</Icon>
                  </Button>
                  <Button className={[classes.calendarNaviBtn,classes.changeIcon]}
                    disabled={(this.state.tillYear == moment(this.state.yearChangeDate).format('YYYY')) ? true : false} onClick={() => this.navigateYear(moment(this.state.yearChangeDate).add(1, 'year').format('YYYY'))}>
                    <Icon>navigate_next</Icon>
                  </Button>
                  <MuiThemeProvider theme={ButtonTheme} >
                    <Button
                      className={classes.fullWidthResp}
                      variant="outlined"
                      onClick={() => this.navigateYear(moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY'))}>
                      {t("Current Year")}
                    </Button>
                  </MuiThemeProvider>
         </>
        ) : (
          <>
          <MuiThemeProvider theme={monthPickerStyleSingleYear}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                    <InlineDatePicker
                          style={{ width: "97px", marginLeft:"0px", color: (this.state.showFullYearGraph && this.state.viewType == 'priceGraph') ? "#00000042" : '#00000042', fontWeight: "400" }}
                          views={["year","month"]}
                          value={moment(this.state.graphMonthChangeDate)}
                          onChange={this.changeCalendarMonthYear()}
                          format={'MMM yyyy'}
                          autoOk={true}
                          KeyboardButtonProps={{ disabled: false, style: { display: 'none' } }}
                          InputProps={{ readOnly: true }}
                          InputLabelProps={{ shrink: true }}                              
                          maxDate={moment('01-' + this.state.graphEndDate).format('YYYY-DD-MM')}      
                          minDate={moment('01-' + this.state.graphStartDate).format('YYYY-DD-MM')}
                          minDateMessage={""}
                          maxDateMessage={""}
                        />
                  </MuiPickersUtilsProvider>
                </MuiThemeProvider>
                  <Button
                    disabled={disablePrev}
                    className={[classes.calendarNaviBtn, classes.changeIcon]}
                    onClick={() => this.graphNavigateMonth(moment(this.state.graphMonthChangeDate).subtract(1, 'months').format('YYYY-MM-DD'))}>
                    <Icon >navigate_before</Icon>
                  </Button>
                  <Button className={[classes.calendarNaviBtn, classes.changeIcon]}
                    disabled={disableNext} onClick={() => this.graphNavigateMonth(moment(this.state.graphMonthChangeDate).add(1, 'months').format('YYYY-MM-DD'))}>
                    <Icon >navigate_next</Icon>
                  </Button>
                  <MuiThemeProvider theme={ButtonTheme} >
                    <Button
                      className={classes.fullWidthResp}
                      variant="outlined"
                      onClick={() => this.graphNavigateMonth(moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'))}>
                      {t("Current Month")}
                    </Button>
                  </MuiThemeProvider>
                </>
        )
       }

                   
                  </>
                }

              {this.state.selectedTab==1 && this.state.featuresEnabled.includes(25)&&
              <FormControl variant="outlined" className={classes.formControlVs}>
                <Select
                  inputProps={{
                    classes: {
                      root: classes.textSecondary,
                      icon: classes.textSecondary,
                    },
                  }}
                  // className={classes.fullWidthResp}
                  value={this.state.dashboardServices}
                  onChange={(event) => this.changeServices(event.target.value)}
                  input={<OutlinedInput id="outlined-age-simple" />}
                  style={{ borderRadius: "10px", height: "36px",color:'#707070 !important' }}
                  SelectDisplayProps={{
                    style: { height: "100%", padding: "5px 25px 5px 10px", borderRadius: "10px", fontSize: "14px",color:'#707070' }
                  }}
                >
                  <MenuItem value={0}>{t("Gross excl. Extra Services")}</MenuItem>
                  <MenuItem value={3}>{t("Net excl. Extra Services")}</MenuItem>
                  {is_admin && <MenuItem value={1}>{t("Gross incl. Extra Services")}</MenuItem>}
                  {is_admin && <MenuItem value={2}>{t("Net incl. Extra Services")}</MenuItem>}
                </Select>
              </FormControl>
              }
              {this.state.selectedTab == 1 && 
                <>
                {this.state.featuresEnabled.includes(25) &&
                  <Button
                    data-html2canvas-ignore="true"
                    className={[classes.downloadCSV,classes.hideOnMobile]}
                    style={{whiteSpace:'nowrap', marginLeft:"16px", marginRight:"4px"}}
                    onClick={() => this.setBudgetModal()}>
                    {t("Set Budget")}
                  </Button>
                }
              <Button
                data-html2canvas-ignore="true"
                className={[classes.downloadCSV, classes.hideOnMobile]}
                aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
                aria-haspopup="true"
                onClick={this.handleClick}
                >
                   <Icon style={{marginRight:'5px'}}>download</Icon>
                {t("Export")}
               
              </Button>   
              </>
              }            
              {
                is_admin && this.state.selectedTab==1&&
                <MuiThemeProvider theme={ButtonTheme} >
                  <Button
                      className={[classes.fullWidthResp,classes.dangerBtnHvr]}
                      variant="outlined"
                      disabled={this.state.loading}
                      onClick={() => this.resetSnowflake()}
                      >
                        {t("Reset Snowflake")} {this.state.loading ? <CircularProgress color="primary" size={24} className={classes.buttonProgress} /> : ""}
                  </Button>
                </MuiThemeProvider>
              }
              {
                is_admin && this.state.selectedTab==1 &&
                <MuiThemeProvider theme={ButtonTheme} >
                  <Button
                      className={[classes.fullWidthResp,classes.dangerBtnHvr]}
                      variant="outlined"
                      disabled={this.state.loadingPost}
                      onClick={this.removePostgres}
                      style={{marginLeft:"0px"}}
                      >
                        {t("Remove Postgres")} {this.state.loadingPost ? <CircularProgress color="primary" size={24} className={classes.buttonProgress} /> : ""}
                  </Button>
                </MuiThemeProvider>
              }
              {
                this.state.selectedTab==1 &&
              <Menu
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleClose}
              >
                {is_admin &&
                  <MenuItem onClick={e=>this.handleClose(1)}> {t("Download CSV")}</MenuItem>
                }
                <MenuItem onClick={e=>this.handleClose(2)}>{t("Print Dashboard to PDF")}</MenuItem>
              </Menu>
              }
             {/* <Button
                className={[classes.downloadCSV, classes.hideOnMobile]}
                onClick={() => this.downloadCSV()}>
                {t("Download CSV")}
              </Button> */}
              {/* <Button
                className={[classes.downloadCSV,classes.hideOnMobile]}
                onClick={() => this.downloadPdf()}>
                {t("Dashboard as PDF")}
              </Button> */}
               {
                this.state.selectedTab==2 &&
                <FormControl variant="outlined" className={classes.formControlVs}>
                  <Select
                    inputProps={{
                      classes: {
                        root: classes.textSecondary,
                        icon: classes.textSecondary,
                      },
                    }}
                    // className={classes.fullWidthResp}
                    value={this.state.analyticsDropdownVal}
                    onChange={(event) => this.analyticsDropdown(event.target.value)}
                    input={<OutlinedInput id="outlined-age-simple" />}
                    style={{ borderRadius: "10px", height: "36px",color:'#707070 !important' }}
                    SelectDisplayProps={{
                      style: { height: "100%", padding: "5px 25px 5px 10px", borderRadius: "10px", fontSize: "14px",color:'#707070' }
                    }}
                  >
                    <MenuItem value={3}>{t("Weekday Analytics")}</MenuItem>
                    <MenuItem value={1}>{t("Room Type Analytics")}</MenuItem>
                    <MenuItem value={2}>{t("Channel Analytics")}</MenuItem>
                    <MenuItem value={0}>{t("Segment Tag Analytics")}</MenuItem>
                    {/* <MenuItem value={4}>{t("Custom Segment Analytics")}</MenuItem> */}
                  </Select>
                </FormControl>
                
              }
            </div>
            {this.state.selectedTab==1 &&
            <span className={classes.uploadMsg}>{t('Last Reservation:')+" " + (reservationDate ? reservationDate : 'n.a.')}</span>
            }         
          </Grid> 
        </Grid>
      </HeaderCard> 
      {this.state.selectedTab == 1 &&
      <>
      <Card>          
        <CardContent style={{ padding: '15px 20px 20px 20px' }}>
        <Grid container>
            <Grid item xs={12} >
              <div style={{ float: "left", marginTop: "2px" }}>
                <Typography variant="h6" style={{ textAlign: "left", fontWeight: "500" }}>
                  {t("On the Books")} 
                </Typography>
              </div>
            </Grid>
            {/* <Grid item xs={12} >
              <div className={classNames(classes.dateNavigationDiv, classes.dateNavigationDivResponsive)} style={{ float: "left", marginTop:"16px" }}>
                <Typography variant="h6" style={{ width: "95px", color: '#707070', textAlign: "left", fontWeight: "400" }}>{moment(this.state.monthChangeDate).format('MMM YYYY')}</Typography>
                <Button
                  disabled={(this.state.startDate == moment(this.state.monthChangeDate).format('MM-YYYY')) ? true : false}
                  className={[classes.calendarNaviBtn, classes.changeIcon]}
                  onClick={() => this.navigateMonth(moment(this.state.monthChangeDate).subtract(1, 'months').format('YYYY-MM-DD'))}>
                  <Icon >navigate_before</Icon>
                </Button>
                <Button className={[classes.calendarNaviBtn, classes.changeIcon]}
                  disabled={(this.state.tillDate == moment(this.state.monthChangeDate).format('MM-YYYY')) ? true : false} onClick={() => this.navigateMonth(moment(this.state.monthChangeDate).add(1, 'months').format('YYYY-MM-DD'))}>
                  <Icon >navigate_next</Icon>
                </Button>
                <MuiThemeProvider theme={ButtonTheme} >
                  <Button
                    className={classes.fullWidthResp}
                    variant="outlined"
                    onClick={() => this.navigateMonth(moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'))}>
                    {t("Current Month")}
                  </Button>
                </MuiThemeProvider>
               
              </div>
            </Grid> */}
          </Grid>
          <Grid container className={classes.performanceCard}>
          <MuiThemeProvider theme={performanceDashboardTheme}>
            <Grid item xs={12} md={3} lg={3}>

              <Card variant="outlined" className={classes.cardBorder} >
                <Grid container className={classes.cardContainer} >
                  <Grid item xs={12}>
                    <Typography className={classes.performanceTitle} >{t('Revenue')}</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.flexCenter} >
                    <span className={classes.performancePrice}>
                      {revenuePrice}
                    </span>
                    {revDelta ?
                    <Tooltip
                    classes={{ popper: classes.htmlPopper, tooltip: classes.htmlTooltip, }}
                    title={<Typography className={classes.htmlTooltip}>{t('vs Same Time Last Year')}</Typography>}>
                      <div className={classes.currentTitle} style={{ background: revDelta > 0 ? '#BBF2DD' : '#FAC6D1' }}>
                        {revDelta > 0 ?
                          <Icon className={[classes.iconStyle, classes.successColor]} >arrow_drop_up</Icon>
                          : <Icon className={[classes.iconStyle, classes.redColor]} >arrow_drop_down</Icon>
                        }
                        {revDelta ?
                          (revDelta > 0 ?
                            (<span className={[classes.successColor]}>{'+' + revDelta + '%'} </span>)
                            : <span className={[classes.redColor]}>{revDelta + '%'}</span>)
                          : null
                        }
                      </div>
                      </Tooltip>
                      : ''}
                  </Grid>
                  <Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <Typography className={classes.grayText}>{t("Same Time Last Year")}</Typography>
                    {this.state.featuresEnabled.includes(25) && isDisplayForecast &&!this.state.showFullYearGraph &&
                      <Typography className={classes.grayText}>{t("Forecast")}</Typography>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <span className={classes.normalPrice} style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    {/* {
                      this.state.showFullYearGraph ? 
                        (kpi_yearly_stly ? (this.getPerformanceValue(kpi_yearly_stly["REVENUE"],false,true)) : 'n.a.')
                      :
                        (performanceStly ? (this.getPerformanceValue(performanceStly["REVENUE"],false,true)) : 'n.a.')
                    } */}
                    <span>{revenueStlyPrice}</span>
                    {this.state.featuresEnabled.includes(25) && isDisplayForecast && !this.state.showFullYearGraph &&
                      <span>{forecastRevenue ? (currencySymbol + new Intl.NumberFormat('ja-JP').format(forecastRevenue)) : "n.a."} </span>
                    }
                    </span>
                  </Grid>
                  <Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between', width: '100%'}} >
                    <Typography className={classes.grayText}>{t('Total Last Year')}</Typography>
                    
                    {this.state.featuresEnabled.includes(25) && isDisplayForecast && !this.state.showFullYearGraph &&
                      <Typography className={classes.grayText}>{t('Budget')}</Typography>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <span className={classes.normalPrice} style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    {/* {
                      this.state.showFullYearGraph ? 
                        (kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["REVENUE"],false,true)) : 'n.a.')
                      :
                        (performanceCurrent ? (this.getPerformanceValue(performanceCurrent["REVENUE"],false,true)) : 'n.a.')
                    } */}
                    
                    <span>{revenueTotalPrice}</span>
                    {this.state.featuresEnabled.includes(25) && isDisplayForecast &&!this.state.showFullYearGraph &&
                      <span>{forecastBudget ?  (currencySymbol + new Intl.NumberFormat('ja-JP').format(forecastBudget)) : "n.a."} </span>
                    }
                    </span>
                  </Grid>
                </Grid>
              </Card>
            
            </Grid>
            <Grid item xs={12} md={3} lg={3} spacing={2}>
              <Card variant="outlined" className={classes.cardBorder} >
                <Grid container className={classes.cardContainer} >
                  <Grid item xs={12}>
                    <Typography className={classes.performanceTitle} >{t('Occupancy')}</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.flexCenter} >
                    <span className={classes.performancePrice}>
                      {
                        this.state.showFullYearGraph ? 
                          (kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["OCCUPANCY"],true)) : 'n.a.')
                        :
                          (performanceCurrent ? (this.getPerformanceValue(performanceCurrent["OCCUPANCY"],true)) : 'n.a.')
                      }
                    </span>
                    {occupancyDelta ?
                    <Tooltip
                    classes={{ popper: classes.htmlPopper, tooltip: classes.htmlTooltip, }}
                    title={<Typography className={classes.htmlTooltip}>{t('vs Same Time Last Year')}</Typography>}>
                      <div className={classes.currentTitle} style={{ background: occupancyDelta >= 0 ? '#BBF2DD' : '#FAC6D1' }}>
                        {occupancyDelta >= 0 ?
                          <Icon className={[classes.successColor]} >arrow_drop_up</Icon>
                          : <Icon className={[classes.redColor]} >arrow_drop_down</Icon>
                        }
                        {occupancyDelta ? (occupancyDelta >= 0 ? (
                          <span className={[classes.successColor]}>{'+' + occupancyDelta + '%'} </span>)
                          : <span className={[classes.redColor]}>{occupancyDelta + '%'}</span>)
                          : "null"}
                      </div>
                      </Tooltip>
                      : ''}
                  </Grid>
                  <Grid item xs={12}><Typography className={classes.grayText}>{t("Same Time Last Year")}</Typography></Grid>
                  <Grid item xs={12}>
                    <span className={classes.normalPrice}>
                    {
                      this.state.showFullYearGraph ? 
                        (kpi_yearly_stly ? (this.getPerformanceValue(kpi_yearly_stly["OCCUPANCY"],true,true)) : 'n.a.')
                      :
                        (performanceStly ? (this.getPerformanceValue(performanceStly["OCCUPANCY"],true,true)) : 'n.a.')
                    }
                    </span>
                  </Grid>
                  <Grid item xs={12}><Typography className={classes.grayText}>{t('Total Last Year')}</Typography></Grid>
                  <Grid item xs={12}>
                    <span className={classes.normalPrice}>
                    {
                      this.state.showFullYearGraph ? 
                        (kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["OCCUPANCY"],true,true)) : 'n.a.')
                      :
                        (performanceCurrent ? (this.getPerformanceValue(performanceCurrent["OCCUPANCY"],true,true)) : 'n.a.')
                    }
                    </span>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={3} lg={3} spacing={2}>
              <Card variant="outlined" className={classes.cardBorder} >
                <Grid container className={classes.cardContainer} >
                  <Grid item xs={12}>
                    <Typography className={classes.performanceTitle} >{t('ADR')}</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.flexCenter} >
                    <span className={classes.performancePrice}>
                      {adrPrice}
                    </span>
                    {adrDelta ?
                      <Tooltip
                    classes={{ popper: classes.htmlPopper, tooltip: classes.htmlTooltip, }}
                    title={<Typography className={classes.htmlTooltip}>{t('vs Same Time Last Year')}</Typography>}>
                      <div className={classes.currentTitle} style={{ background: adrDelta > 0 ? '#BBF2DD' : '#FAC6D1' }}>
                        {adrDelta > 0 ?
                          <Icon className={[classes.successColor]} >arrow_drop_up</Icon> :
                          <Icon className={[classes.redColor]}>arrow_drop_down</Icon>
                        }
                        {adrDelta ? (adrDelta > 0 ?
                          (<span className={[classes.successColor]}>{'+' + currencySymbol + adrDelta} </span>)
                          : <span className={[classes.redColor]}>{'-' + currencySymbol + Math.abs(adrDelta)}</span>)
                          : "null"}
                      </div>
                      </Tooltip>
                      : ''}
                  </Grid>
                  <Grid item xs={12}><Typography className={classes.grayText}>{t("Same Time Last Year")}</Typography></Grid>
                  <Grid item xs={12}>
                    <span className={classes.normalPrice}>
                      {/* {
                        this.state.showFullYearGraph ? 
                          (kpi_yearly_stly ? (this.getPerformanceValue(kpi_yearly_stly["ADR"],false,true)) : 'n.a.')
                        :
                          (performanceStly ? (this.getPerformanceValue(performanceStly["ADR"],false,true)) : 'n.a.')
                      } */}{adrStlyPrice}
                    </span>
                  </Grid>
                  <Grid item xs={12}><Typography className={classes.grayText}>{t('Total Last Year')}</Typography></Grid>
                  <Grid item xs={12}>
                    <span className={classes.normalPrice}>
                      {/* {
                        this.state.showFullYearGraph ? 
                          (kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["ADR"],false,true)) : 'n.a.')
                        :
                          (performanceCurrent ? (this.getPerformanceValue(performanceCurrent["ADR"],false,true)) : 'n.a.')
                      } */}
                      {adrTotalPrice}
                    </span>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={3} lg={3} spacing={2}>
              <Card variant="outlined" className={classes.cardBorder} >
                <Grid container className={classes.cardContainer} >
                  <Grid item xs={12}>
                    <Typography className={classes.performanceTitle} >{t('RevPAR')}</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.flexCenter} >
                    <span className={classes.performancePrice}>
                      {revparPrice}
                    </span>
                    {revparDelta ?
                      <Tooltip
                      classes={{ popper: classes.htmlPopper, tooltip: classes.htmlTooltip, }}
                      title={<Typography className={classes.htmlTooltip}>{t('vs Same Time Last Year')}</Typography>}>
                      <div className={classes.currentTitle} style={{ background: revparDelta > 0 ? '#BBF2DD' : '#FAC6D1' }}>
                        {revparDelta > 0 ?
                          <Icon className={[classes.successColor]} >arrow_drop_up</Icon> :
                          <Icon className={[classes.redColor]} >arrow_drop_down</Icon>
                        }
                        {revparDelta ? (revparDelta > 0 ?
                          (<span className={[classes.successColor]}>{'+' + currencySymbol + revparDelta} </span>)
                          : <span className={[classes.redColor]}>{'-' + currencySymbol + Math.abs(revparDelta)}</span>)
                          : "null"}
                      </div>
                      </Tooltip>
                      : ''}
                  </Grid>
                  <Grid item xs={12}><Typography className={classes.grayText}>{t("Same Time Last Year")}</Typography></Grid>
                  <Grid item xs={12}>
                    <span className={classes.normalPrice}>
                      {/* {
                        this.state.showFullYearGraph ? 
                          (kpi_yearly_stly ? (this.getPerformanceValue(kpi_yearly_stly["REV_PAR"],false,true)) : 'n.a.')
                        :
                          (performanceStly ? (this.getPerformanceValue(performanceStly["REV_PAR"],false,true)) : 'n.a.')
                      } */}
                      {revparStlyPrice}
                    </span>
                  </Grid>
                  <Grid item xs={12}><Typography className={classes.grayText}>{t('Total Last Year')}</Typography></Grid>
                  <Grid item xs={12}>
                    <span className={classes.normalPrice}>
                      {/* {
                        this.state.showFullYearGraph ? 
                          (kpi_yearly_total ? (this.getPerformanceValue(kpi_yearly_total["REV_PAR"],false,true)) : 'n.a.')
                        :
                          (performanceCurrent ? (this.getPerformanceValue(performanceCurrent["REV_PAR"],false,true)) : 'n.a.')
                      } */}{revparTotalPrice}
                    </span>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            </MuiThemeProvider>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ marginTop: '50px' }}>
        <CardHeader className="headig-top"  titleTypographyProps={{variant:'h6' }} style={{ color: '#3d5170' }} title={t("Recent Pickup")}></CardHeader>
        <CardContent style={{ marginTop: '-11px', paddingBottom: '20px' }}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="off"
            value={this.state.bookingTabs}
            onChange={this.handleBookingBehaviorTabs}
            className={classes.tabStyle}
          >
            <Tab label={t("Yesterday")} value={0} />
            <Tab label={t("Last 7 Days")} value={1} />
            <Tab label={t("Last 30 Days")} value={2} />
            <Tab label={t("Last 90 Days")} value={3} />
            
            {this.state.featuresEnabled.includes(25) && !this.state.showFullYearGraph && showBookingCurve &&
            <span style={{display: 'flex', justifyContent: 'end', cursor: 'pointer', color:'#A0A2A6', fontSize: '0.8125rem', fontWeight: '500', alignSelf: 'center', width: '100%'}} onClick={()=>this.bookingCurveModal()}>{t('Open Booking Curve')}<ArrowForwardIcon style={{marginTop:'2px',fontSize:'17px'}}/></span>
            }
            </Tabs>
          {/* <span style={{display:'flex',justifyContent:'end',marginTop:'10px',cursor:'pointer',color:'#A0A2A6'}} onClick={()=>this.bookingCurveModal()}>{t('Open Booking Curve')}<ArrowForwardIcon /></span> */}
          <Grid container className={classes.performanceCard} >
          <MuiThemeProvider theme={performanceDashboardTheme}>
            {this.renderBookingBehavior()}
          </MuiThemeProvider>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ marginTop: '50px' }}>
        <CardHeader className="headig-top"  titleTypographyProps={{variant:'h6' }} style={{ color: '#3d5170' }} title={this.state.showFullYearGraph?t("Monthly Key Performance Indicators"):t("Daily Key Performance Indicators")}></CardHeader>
        <CardContent style={{ marginTop: '-11px' }}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="off"
            value={this.state.tab}
            onChange={this.handleTabs}
            className={classes.tabStyle}
          >
            <Tab label={t("Revenue")} value={0} />
            <Tab label={t("Occupancy")} value={1} />
            <Tab label={t("ADR")} value={2} />
            <Tab label={t("RevPAR")} value={3}/>
          </Tabs>
          
          <div className={classes.dateTableDiv}>
          {this.state.tab == 0 && this.state.showFullYearGraph &&
           this.renderYearlyGraph(revenueName) 
          }
          {this.state.tab == 0 && !this.state.showFullYearGraph &&
            this.renderMonthlyGraph(revenueName)
          }
          {this.state.tab == 1 && this.state.showFullYearGraph &&
           this.renderYearlyGraph('OCCUPANCY') 
          }
          {this.state.tab == 1 && !this.state.showFullYearGraph &&
            this.renderMonthlyGraph('OCCUPANCY')
          }
          {this.state.tab == 2 && this.state.showFullYearGraph &&
           this.renderYearlyGraph(adrName) 
          }
          {this.state.tab == 2 && !this.state.showFullYearGraph &&
            this.renderMonthlyGraph(adrName)
          }
          {this.state.tab == 3 && this.state.showFullYearGraph &&
           this.renderYearlyGraph(revperName) 
          }
          {this.state.tab == 3 && !this.state.showFullYearGraph &&
            this.renderMonthlyGraph(revperName)
          }
          </div>
        </CardContent>
        {this.state.purgeReservationModal ? Modals.PurgeReservationModal(this) : ""}
        {this.state.openBudgetModal && Modals.setBudget(this)}
        {this.state.bookingCurveModalOpen && Modals.bookingCurveModal(this)}
      </Card>
      </>
      }
      {this.state.selectedTab == 2 && 
          this.analyticsTab()
          // <div>Hello</div>
      }
    </div>

    )
  }
}

const mapStateToProps = (state) => {

  return {
    userSession: state.sessionReducer,
    hotelAuthReducer: state.hotelAuthReducer,
    competitorReducer: state.competitorReducer,
    hotelListReducer: state.hotelListReducer,
    commonReducer: state.commonReducer,
    PerformanceDashboardReducer: state.PerformanceDashboardReducer,
    hotelDetail: state.hotelSettingReducer.hoteDetail,
    priceSettingReducer: state.priceSettingReducer,

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setHotelList: (request) => { dispatch({ type: actionTypes.HOTEL_LIST_SUCCEED, state: request }); },
    getHotelList: (request) => { dispatch(actions.getHotelList(request)); },
    getPerformanceReservation: (request) => { dispatch(actions.getPerformanceReservation(request)) },
    getLatestReservation: (request) => { dispatch(actions.getLatestReservation(request)) },
    getPurgeReservation: (request) => { dispatch(actions.getPurgeReservation(request)) },
    getProcessAllReservation: (request) => { dispatch(actions.getProcessAllReservation(request)) },
    getHourlyExecution: (request) => { dispatch(actions.getHourlyExecution(request)) },
    getHotelDetail: (request) => { dispatch(actions.getHotelDetail(request)) },
    getPerformanceReservationPickup: (request) => { dispatch(actions.getPerformanceReservationPickup(request)) },
    dashboardDefaultBudget:(request)=>{dispatch(actions.dashboardDefaultBudget(request))},
    updatePricingSettings: (request) => { dispatch(actions.updatePricingSettings(request)) },
    putNotify: (request) => { dispatch(actions.putNotify(request)) },
    getAnalyticsData:(request)=>{dispatch(actions.getAnalyticsData(request)) },
    removePostgress:(request)=>{ dispatch(actions.removePostgres(request)) },
    getForecastData:(request)=>{ dispatch(actions.getForecastData(request)) },
    updateForecastData: (request)=>{ dispatch(actions.updateForecastData(request)) },

  };
};
export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces("translations")(PerformanceDashboard));