import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import Notify from '../Notify'

const getApiLogListStart = (data) => {
    return {
        type: actionTypes.GET_API_LOG_START
    }
}

const getApiLogListSuccess = (data) => {
    return {
        type: actionTypes.GET_API_LOG_SUCCESS,
        state: data
    }
}

const getApiLogListFailed = (error) => {
    return {
        type: actionTypes.GET_API_LOG_FAILED,
        error: error
    }
}

export const getApiLogList = (request) => {
    return dispatch => {
        dispatch(getApiLogListStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(request.url ? Server.API + request.url : Server.API + 'hotel/get_logs/')
            .then((response) => {
                if(request.updateApi){
                    dispatch(Notify.newUserModal("Inbound API log Updated Successfully", true))
                }
                dispatch(getApiLogListSuccess(response.data))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Something went Wrong While Fetching Api log", false))
                dispatch(getApiLogListFailed(error))
            });
    }
}
const getOutboundApiLogListStart = () => {
    return {
        type: actionTypes.GET_OUTBOUND_API_LOG_START
    }
}

const getOutboundApiLogListSuccess = (data) => {
    return {
        type: actionTypes.GET_OUTBOUND_API_LOG_SUCCESS,
        state: data
    }
}

const getOutboundApiLogListFailed = (error) => {
    return {
        type: actionTypes.GET_OUTBOUND_API_LOG_FAILED,
        error: error
    }
}
export const getOutboundApiList = (request) => {
    return dispatch => {
        dispatch(getOutboundApiLogListStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(request.url ? Server.API + request.url : Server.API + 'hotel/get-outboud-logs/')
        .then((response) => {
            if(request.updateApi){
                dispatch(Notify.newUserModal("Outbound API log Updated Successfully", true))
            }
            dispatch(getOutboundApiLogListSuccess(response.data))
        })
        .catch(function (error) {
            dispatch(Notify.newUserModal("Something went Wrong While Fetching Outbound Api log", false))
            dispatch(getOutboundApiLogListFailed(error))
        });
    }
}
