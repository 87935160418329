/// AUTH ACTIONS
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_SUCCEED = 'LOGIN_SUCCEED';
export const VERIFIED_OTP_SUCCEED = 'VERIFIED_OTP_SUCCEED';
export const LOGOUT = 'LOGOUT';

export const SESSION_CHECK = 'SESSION_CHECK';

export const HOTEL_AUTH_START = 'HOTEL_AUTH_START';
export const HOTEL_AUTH_FAILED = 'HOTEL_AUTH_FAILED';
export const HOTEL_AUTH_SUCCEED = 'HOTEL_AUTH_SUCCEED';
export const HOTEL_AUTH_UPDATE = 'HOTEL_AUTH_UPDATE';

export const HOTEL_DETAIL_START = 'HOTEL_DETAIL_START';
export const HOTEL_DETAIL_FAILED = 'HOTEL_DETAIL_FAILED';
export const HOTEL_DETAIL_SUCCEED = 'HOTEL_DETAIL_SUCCEED';

export const HOTEL_UPDATE_START = 'HOTEL_UPDATE_START';
export const HOTEL_UPDATE_FAILED = 'HOTEL_UPDATE_FAILED';
export const HOTEL_UPDATE_SUCCEED = 'HOTEL_UPDATE_SUCCEED';

export const ROOM_LIST_START = 'ROOM_LIST_START';
export const ROOM_LIST_FAILED = 'ROOM_LIST_FAILED';
export const ROOM_LIST_SUCCEED = 'ROOM_LIST_SUCCEED';

export const ADD_ROOM_START = 'ADD_ROOM_START';
export const ADD_ROOM_FAILED = 'ADD_ROOM_FAILED';
export const ADD_ROOM_SUCCEED = 'ADD_ROOM_SUCCEED';

export const UPDATE_ROOM_START = 'UPDATE_ROOM_START';
export const UPDATE_ROOM_FAILED = 'UPDATE_ROOM_FAILED';
export const UPDATE_ROOM_SUCCEED = 'UPDATE_ROOM_SUCCEED';

export const DELETE_ROOM_START = 'DELETE_ROOM_START';
export const DELETE_ROOM_FAILED = 'DELETE_ROOM_FAILED';
export const DELETE_ROOM_SUCCEED = 'DELETE_ROOM_SUCCEED';

export const PUT_NOTIFY = 'PUT_NOTIFY';
export const SET_CALENDAR_ROOM = 'SET_CALENDAR_ROOM';
export const LAST_PRICING_DATE = 'LAST_PRICING_DATE';
export const STOP_NAVIGATION = 'STOP_NAVIGATION';
export const SHOW_NAVIGATION_POPUP = 'SHOW_NAVIGATION_POPUP';

export const CURRENCY_START = 'CURRENCY_START';
export const CURRENCY_FAILED = 'CURRENCY_FAILED';
export const CURRENCY_SUCCEED = 'CURRENCY_SUCCEED';

export const COUNTRY_START = 'COUNTRY_START';
export const COUNTRY_FAILED = 'COUNTRY_FAILED';
export const COUNTRY_SUCCEED = 'COUNTRY_SUCCEED';

export const AGGRESIVE_START = 'AGGRESIVE_START';
export const AGGRESIVE_FAILED = 'AGGRESIVE_FAILED';
export const AGGRESIVE_SUCCEED = 'AGGRESIVE_SUCCEED';

export const PMS_LIST_START = 'PMS_LIST_START';
export const PMS_LIST_FAILED = 'PMS_LIST_FAILED';
export const PMS_LIST_SUCCEED = 'PMS_LIST_SUCCEED';

export const CLINET_ADMIN_GET_START = 'CLINET_ADMIN_GET_START';
export const CLINET_ADMIN_GET_FAILED = 'CLINET_ADMIN_GET_FAILED';
export const CLINET_ADMIN_GET_SUCCEED = 'CLINET_ADMIN_GET_SUCCEED';

export const CLINET_ADMIN_CREATE_START = 'CLINET_ADMIN_CREATE_START';
export const CLINET_ADMIN_CREATE_FAILED = 'CLINET_ADMIN_CREATE_FAILED';
export const CLINET_ADMIN_CREATE_SUCCEED = 'CLINET_ADMIN_CREATE_SUCCEED';

export const CLINET_ADMIN_UPDATE_START = 'CLINET_ADMIN_UPDATE_START';
export const CLINET_ADMIN_UPDATE_FAILED = 'CLINET_ADMIN_UPDATE_FAILED';
export const CLINET_ADMIN_UPDATE_SUCCEED = 'CLINET_ADMIN_UPDATE_SUCCEED';

export const CLINET_ADMIN_DELETE_START = 'CLINET_ADMIN_DELETE_START';
export const CLINET_ADMIN_DELETE_FAILED = 'CLINET_ADMIN_DELETE_FAILED';
export const CLINET_ADMIN_DELETE_SUCCEED = 'CLINET_ADMIN_DELETE_SUCCEED';

export const MANAGER_GET_START = 'MANAGER_GET_START';
export const MANAGER_GET_FAILED = 'MANAGER_GET_FAILED';
export const MANAGER_GET_SUCCEED = 'MANAGER_GET_SUCCEED';

export const MANAGER_CREATE_START = 'MANAGER_CREATE_START';
export const MANAGER_CREATE_FAILED = 'MANAGER_CREATE_FAILED';
export const MANAGER_CREATE_SUCCEED = 'MANAGER_CREATE_SUCCEED';

export const MANAGER_UPDATE_START = 'MANAGER_UPDATE_START';
export const MANAGER_UPDATE_FAILED = 'MANAGER_UPDATE_FAILED';
export const MANAGER_UPDATE_SUCCEED = 'MANAGER_UPDATE_SUCCEED';

export const MANAGER_DELETE_START = 'MANAGER_DELETE_START';
export const MANAGER_DELETE_FAILED = 'MANAGER_DELETE_FAILED';
export const MANAGER_DELETE_SUCCEED = 'MANAGER_GET_SUCCEED';

export const MANAGER_USER_DELETE_START = 'MANAGER_USER_DELETE_START';
export const MANAGER_USER_DELETE_FAILED = 'MANAGER_USER_DELETE_FAILED';
export const MANAGER_USER_DELETE_SUCCEED = 'MANAGER_USER_DELETE_SUCCEED';

export const STAFF_GET_START = 'STAFF_GET_START';
export const STAFF_GET_FAILED = 'STAFF_GET_FAILED';
export const STAFF_GET_SUCCEED = 'STAFF_GET_SUCCEED';

export const STAFF_CREATE_START = 'STAFF_CREATE_START';
export const STAFF_CREATE_FAILED = 'STAFF_CREATE_FAILED';
export const STAFF_CREATE_SUCCEED = 'STAFF_CREATE_SUCCEED';

export const STAFF_UPDATE_START = 'STAFF_UPDATE_START';
export const STAFF_UPDATE_FAILED = 'STAFF_UPDATE_FAILED';
export const STAFF_UPDATE_SUCCEED = 'STAFF_UPDATE_SUCCEED';

export const STAFF_DELETE_START = 'STAFF_DELETE_START';
export const STAFF_DELETE_FAILED = 'STAFF_DELETE_FAILED';
export const STAFF_DELETE_SUCCEED = 'STAFF_DELETE_SUCCEED';

export const STAFF_USER_DELETE_START = 'STAFF_USER_DELETE_START';
export const STAFF_USER_DELETE_FAILED = 'STAFF_USER_DELETE_FAILED';
export const STAFF_USER_DELETE_SUCCEED = 'STAFF_USER_DELETE_SUCCEED';

export const GET_TABLE_SETTING = 'GET_TABLE_SETTING'

export const SKIP_PRICE_START = 'SKIP_PRICE_START';
export const SKIP_PRICE_FAILED = 'SKIP_PRICE_FAILED';
export const SKIP_PRICE_SUCCEED = 'SKIP_PRICE_SUCCEED';

export const SKIP_PRICE_CREATE_START = 'SKIP_PRICE_CREATE_START';
export const SKIP_PRICE_CREATE_FAILED = 'SKIP_PRICE_CREATE_FAILED';
export const SKIP_PRICE_CREATE_SUCCEED = 'SKIP_PRICE_CREATE_SUCCEED';

export const SKIP_PRICE_UPDATE_START = 'SKIP_PRICE_UPDATE_START';
export const SKIP_PRICE_UPDATE_FAILED = 'SKIP_PRICE_UPDATE_FAILED';
export const SKIP_PRICE_UPDATE_SUCCEED = 'SKIP_PRICE_UPDATE_SUCCEED';

export const SKIP_PRICE_DELETE_START = 'SKIP_PRICE_DELETE_START';
export const SKIP_PRICE_DELETE_FAILED = 'SKIP_PRICE_DELETE_FAILED';
export const SKIP_PRICE_DELETE_SUCCEED = 'SKIP_PRICE_DELETE_SUCCEED';

export const DAILY_ADJUSTMENT_START = 'DAILY_ADJUSTMENT_START';
export const DAILY_ADJUSTMENT_FAILED = 'DAILY_ADJUSTMENT_FAILED';
export const DAILY_ADJUSTMENT_SUCCEED = 'DAILY_ADJUSTMENT_SUCCEED';

export const DAILY_ADJUSTMENT_CREATE_START = 'DAILY_ADJUSTMENT_CREATE_START';
export const DAILY_ADJUSTMENT_CREATE_FAILED = 'DAILY_ADJUSTMENT_CREATE_FAILED';
export const DAILY_ADJUSTMENT_CREATE_SUCCEED = 'DAILY_ADJUSTMENT_CREATE_SUCCEED';

export const DAILY_ADJUSTMENT_UPDATE_START = 'DAILY_ADJUSTMENT_UPDATE_START';
export const DAILY_ADJUSTMENT_UPDATE_FAILED = 'DAILY_ADJUSTMENT_UPDATE_FAILED';
export const DAILY_ADJUSTMENT_UPDATE_SUCCEED = 'DAILY_ADJUSTMENT_UPDATE_SUCCEED';


export const DAILY_ADJUSTMENT_DELETE_START = 'DAILY_ADJUSTMENT_DELETE_START';
export const DAILY_ADJUSTMENT_DELETE_FAILED = 'DAILY_ADJUSTMENT_DELETE_FAILED';
export const DAILY_ADJUSTMENT_DELETE_SUCCEED = 'DAILY_ADJUSTMENT_DELETE_SUCCEED';

export const DATA_MODAL_START = 'DATA_MODAL_START';
export const DATA_MODAL_FAILED = 'DATA_MODAL_FAILED';
export const DATA_MODAL_SUCCEED = 'DATA_MODAL_SUCCEED';

export const DATA_MODAL_UPDATE_START = 'DATA_MODAL_UPDATE_START';
export const DATA_MODAL_UPDATE_FAILED = 'DATA_MODAL_UPDATE_FAILED';
export const DATA_MODAL_UPDATE_SUCCEED = 'DATA_MODAL_UPDATE_SUCCEED';

export const PREDEFINE_RATE_START = 'PREDEFINE_RATE_START';
export const PREDEFINE_RATE_FAILED = 'PREDEFINE_RATE_FAILED';
export const PREDEFINE_RATE_SUCCEED = 'PREDEFINE_RATE_SUCCEED';

export const PREDEFINE_RATE_CREATE_START = 'PREDEFINE_RATE_CREATE_START';
export const PREDEFINE_RATE_CREATE_FAILED = 'PREDEFINE_RATE_CREATE_FAILED';
export const PREDEFINE_RATE_CREATE_SUCCEED = 'PREDEFINE_RATE_CREATE_SUCCEED';

export const PREDEFINE_RATE_UPDATE_START = 'PREDEFINE_RATE_UPDATE_START';
export const PREDEFINE_RATE_UPDATE_FAILED = 'PREDEFINE_RATE_UPDATE_FAILED';
export const PREDEFINE_RATE_UPDATE_SUCCEED = 'PREDEFINE_RATE_UPDATE_SUCCEED';

export const PREDEFINE_RATE_DELETE_START = 'DAILY_ADJUSTMENT_DELETE_START';
export const PREDEFINE_RATE_DELETE_FAILED = 'DAILY_ADJUSTMENT_DELETE_FAILED';
export const PREDEFINE_RATE_DELETE_SUCCEED = 'DAILY_ADJUSTMENT_DELETE_SUCCEED';

export const PREDEFINE_RATE_MOVEUP_START = 'PREDEFINE_RATE_MOVEUP_START';
export const PREDEFINE_RATE_MOVEUP_FAILED = 'PREDEFINE_RATE_MOVEUP_FAILED';
export const PREDEFINE_RATE_MOVEUP_SUCCEED = 'PREDEFINE_RATE_MOVEUP_SUCCEED';

export const PREDEFINE_RATE_MOVEDOWN_START = 'PREDEFINE_RATE_MOVEDOWN_START';
export const PREDEFINE_RATE_MOVEDOWN_FAILED = 'PREDEFINE_RATE_MOVEDOWN_FAILED';
export const PREDEFINE_RATE_MOVEDOWN_SUCCEED = 'PREDEFINE_RATE_MOVEDOWN_SUCCEED';

export const MONTHLY_ADJUST_START = 'MONTHLY_ADJUST_START';
export const MONTHLY_ADJUST_FAILED = 'MONTHLY_ADJUST_FAILED';
export const MONTHLY_ADJUST_SUCCEED = 'MONTHLY_ADJUST_SUCCEED';

export const WEEKLY_ADJUST_START = 'WEEKLY_ADJUST_START';
export const WEEKLY_ADJUST_FAILED = 'WEEKLY_ADJUST_FAILED';
export const WEEKLY_ADJUST_SUCCEED = 'WEEKLY_ADJUST_SUCCEED';

export const WEEKLY_ADJUST_UPDATE_START = 'WEEKLY_ADJUST_UPDATE_START';
export const WEEKLY_ADJUST_UPDATE_FAILED = 'WEEKLY_ADJUST_UPDATE_FAILED';
export const WEEKLY_ADJUST_UPDATE_SUCCEED = 'WEEKLY_ADJUST_UPDATE_SUCCEED';

export const TIME_BOOKING_SUCCEED = "TIME_BOOKING_SUCCEED"
export const TIME_BOOKING_START = "TIME_BOOKING_START"
export const TIME_BOOKING_FAILED = "TIME_BOOKING_FAILED"
export const TIME_BOOKING_UPDATE_START = "TIME_BOOKING_UPDATE_START"
export const TIME_BOOKING_UPDATE_SUCCEED = "TIME_BOOKING_UPDATE_SUCCEED"
export const TIME_BOOKING_UPDATE_FAILED = "TIME_BOOKING_UPDATE_FAILED"

export const GET_ELASTICITY_LIST = "GET_ELASTICITY_LIST"
export const GET_BOOKING_LIST = "GET_BOOKING_LIST"

export const ADJUSTMENT_SUCCEED = "ADJUSTMENT_SUCCEED"
export const ADJUSTMENT_START = "ADJUSTMENT_START"
export const ADJUSTMENT_FAILED = "ADJUSTMENT_FAILED"
export const ADJUSTMENT_UPDATE_START = "ADJUSTMENT_UPDATE_START"
export const ADJUSTMENT_UPDATE_SUCCEED = "ADJUSTMENT_UPDATE_SUCCEED"
export const ADJUSTMENT_UPDATE_FAILED = "ADJUSTMENT_UPDATE_FAILED"


export const COMPETITOR_SUCCEED = "COMPETITOR_SUCCEED"
export const COMPETITOR_START = "COMPETITOR_START"
export const COMPETITOR_FAILED = "COMPETITOR_FAILED"

export const NEAR_COMPETITOR_SUCCEED = "NEAR_COMPETITOR_SUCCEED"
export const NEAR_COMPETITOR_START = "NEAR_COMPETITOR_START"
export const NEAR_COMPETITOR_FAILED = "NEAR_COMPETITOR_FAILED"

export const UPDATE_COMPETITOR_SUCCEED = "UPDATE_COMPETITOR_SUCCEED"
export const UPDATE_COMPETITOR_START = "UPDATE_COMPETITOR_START"
export const UPDATE_COMPETITOR_FAILED = "UPDATE_COMPETITOR_FAILED"

export const GET_PMS_START = 'GET_PMS_START';
export const GET_PMS_FAILED = 'GET_PMS_FAILED';
export const GET_PMS_SUCCEED = 'GET_PMS_SUCCEED';

export const REMOVE_DEAD_ROOMS_START = 'REMOVE_DEAD_ROOMS_START';
export const REMOVE_DEAD_ROOMS_FAILED = 'REMOVE_DEAD_ROOMS_FAILED';
export const REMOVE_DEAD_ROOMS_SUCCEED = 'REMOVE_DEAD_ROOMS_SUCCEED';

export const REMOVE_DEAD_RATES_START = 'REMOVE_DEAD_RATES_START';
export const REMOVE_DEAD_RATES_FAILED = 'REMOVE_DEAD_RATES_FAILED';
export const REMOVE_DEAD_RATES_SUCCEED = 'REMOVE_DEAD_RATES_SUCCEED';

export const CONNECTION_UPDATE_START = 'CONNECTION_UPDATE_START';
export const CONNECTION_UPDATE_FAILED = 'CONNECTION_UPDATE_FAILED';
export const CONNECTION_UPDATE_SUCCEED = 'CONNECTION_UPDATE_SUCCEED';

export const CLIENT_LIST_START = 'CLIENT_LIST_START';
export const CLIENT_LIST_FAILED = 'CLIENT_LIST_FAILED';
export const CLIENT_LIST_SUCCEED = 'CLIENT_LIST_SUCCEED';
export const CLINET_ADMIN_OWNER = 'CLINET_ADMIN_OWNER';

export const ADMIN_USER_START = 'ADMIN_USER_START';
export const ADMIN_USER_FAILED = 'ADMIN_USER_FAILED';
export const ADMIN_USER_SUCCEED = 'ADMIN_USER_SUCCEED';

export const GET_RESPONSIBLE_PERSON_START = 'GET_RESPONSIBLE_PERSON_START';
export const GET_RESPONSIBLE_PERSON_FAILED = 'GET_RESPONSIBLE_PERSON_FAILED';
export const GET_RESPONSIBLE_PERSON_SUCCEED = 'GET_RESPONSIBLE_PERSON_SUCCEED';

export const GET_SUBSCRIBED_CLIENT_DATA_START = 'GET_SUBSCRIBED_CLIENT_DATA_START';
export const GET_SUBSCRIBED_CLIENT_DATA_SUCCEED = 'GET_SUBSCRIBED_CLIENT_DATA_SUCCEED';
export const GET_SUBSCRIBED_CLIENT_DATA_FAILED = 'GET_SUBSCRIBED_CLIENT_DATA_FAILED';

export const GET_FREE_TRIAL_CLIENT_DATA_START = 'GET_FREE_TRIAL_CLIENT_DATA_START';
export const GET_FREE_TRIAL_CLIENT_DATA_SUCCEED = 'GET_FREE_TRIAL_CLIENT_DATA_SUCCEED';
export const GET_FREE_TRIAL_CLIENT_DATA_FAILED = 'GET_FREE_TRIAL_CLIENT_DATA_FAILED';

export const HOTEL_LIST_START = 'HOTEL_LIST_START';
export const HOTEL_LIST_FAILED = 'HOTEL_LIST_FAILED';
export const HOTEL_LIST_SUCCEED = 'HOTEL_LIST_SUCCEED';

export const RECOVER_START = 'RECOVER_START';
export const RECOVER_FAILED = 'RECOVER_FAILED';
export const RECOVER_SUCCEED = 'RECOVER_SUCCEED';

export const UPDATE_PASSWORD_START = 'UPDATE_PASSWORD_START';
export const UPDATE_PASSWORD_FAILED = 'UPDATE_PASSWORD_FAILED';
export const UPDATE_PASSWORD_SUCCEED = 'UPDATE_PASSWORD_SUCCEED';

export const ADD_REF_ROOM_START = 'ADD_REF_ROOM_START';
export const ADD_REF_ROOM_FAILED = 'ADD_REF_ROOM_FAILED';
export const ADD_REF_ROOM_SUCCEED = 'ADD_REF_ROOM_SUCCEED';

export const INVENTARY_START = 'INVENTARY_START';
export const INVENTARY_FAILED = 'INVENTARY_FAILED';
export const INVENTARY_SUCCEED = 'INVENTARY_SUCCEED';

export const CLIENT_GET_START = 'CLIENT_GET_START';
export const CLIENT_GET_FAILED = 'CLIENT_GET_FAILED';
export const CLIENT_GET_SUCCEED = 'CLIENT_GET_SUCCEED';

export const GET_SCRAP_START = 'GET_SCRAP_START';
export const GET_SCRAP_FAILED = 'GET_SCRAP_FAILED';
export const GET_SCRAP_SUCCEED = 'GET_SCRAP_SUCCEED';

export const GET_SCRAP_SCHEDULE_START = 'GET_SCRAP_SCHEDULE_START';
export const GET_SCRAP_SCHEDULE_FAILED = 'GET_SCRAP_SCHEDULE_FAILED';
export const GET_SCRAP_SCHEDULE_SUCCEED = 'GET_SCRAP_SCHEDULE_SUCCEED';

export const GET_SCRAP_LIST_START = 'GET_SCRAP_LIST_START';
export const GET_SCRAP_LIST_FAILED = 'GET_SCRAP_LIST_FAILED';
export const GET_SCRAP_LIST_SUCCEED = 'GET_SCRAP_LIST_SUCCEED';

export const PMS_SETTING_GET_START = 'PMS_SETTING_GET_START';
export const PMS_SETTING_GET_FAILED = 'PMS_SETTING_GET_FAILED';
export const PMS_SETTING_GET_SUCCEED = 'PMS_SETTING_GET_SUCCEED';

export const PMS_SETTING_UPDATE_START = 'PMS_SETTING_UPDATE_START';
export const PMS_SETTING_UPDATE_SUCCEED = 'PMS_SETTING_UPDATE_SUCCEED';
export const PMS_SETTING_UPDATE_FAILED = 'PMS_SETTING_UPDATE_FAILED';

export const EVENT_SUCCEED = 'EVENT_SUCCEED';

export const EVENT_CATEGORY_START = "EVENT_CATEGORY_START"
export const EVENT_CATEGORY_SUCCEED = "EVENT_CATEGORY_SUCCEED";
export const EVENT_CATEGORY_FAILED = "EVENT_CATEGORY_FAILED";

export const ALGO_START = 'ALGO_START';
export const ALGO_SUCCEED = 'ALGO_SUCCEED';
export const ALGO_FAILED = 'ALGO_FAILED';

export const DISCONNECT_PMS_START = 'DISCONNECT_PMS_START';
export const DISCONNECT_PMS_FAILED = 'DISCONNECT_PMS_FAILED';
export const DISCONNECT_PMS_SUCCEED = 'DISCONNECT_PMS_SUCCEED';
export const SEEKOM_PMS_SUCCEED = 'SEEKOM_PMS_SUCCEED';
export const RESELLER_USER_SUCCEED = 'RESELLER_USER_SUCCEED';
export const RESELLER_MEMBER_USER_SUCCEED = 'RESELLER_MEMBER_USER_SUCCEED';
export const RESELLER_All = 'RESELLER_All';
export const RESELLER_MEMBER_All = 'RESELLER_MEMBER_All';

export const SMART_SETTING_START = 'SMART_SETTING_START';
export const SMART_SETTING_FAILED = 'SMART_SETTING_FAILED';
export const SMART_SETTING_SUCCEED = 'SMART_SETTING_SUCCEED';

export const CLIENT_PASSWORD_UPDATE_START = 'CLIENT_PASSWORD_UPDATE_START';
export const CLIENT_PASSWORD_UPDATE_FAILED = 'CLIENT_PASSWORD_UPDATE_FAILED';
export const CLIENT_PASSWORD_UPDATE_SUCCEED = 'CLIENT_PASSWORD_UPDATE_SUCCEED';

export const ALGO_LOG_START = 'ALGO_LOG_START';
export const ALGO_LOG_FAILED = 'ALGO_LOG_FAILED';
export const ALGO_LOG_SUCCEED = 'ALGO_LOG_SUCCEED';

export const VALIDATE_RESET_TOKEN_START = 'VALIDATE_RESET_TOKEN_START';
export const VALIDATE_RESET_TOKEN_FAILED = 'VALIDATE_RESET_TOKEN_FAILED';
export const VALIDATE_RESET_TOKEN_SUCCEED = 'VALIDATE_RESET_TOKEN_SUCCEED';

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const RESET_PASSWORD_SUCCEED = 'RESET_PASSWORD_SUCCEED';

export const UPDATE_PMS_SYNC_START = 'UPDATE_PMS_SYNC_START';
export const UPDATE_PMS_SYNC_FAILED = 'UPDATE_PMS_SYNC_FAILED';
export const UPDATE_PMS_SYNC_SUCCEED = 'UPDATE_PMS_SYNC_SUCCEED';

export const BOOKINGSUIT_TEST_START = 'BOOKINGSUIT_TEST_START';
export const BOOKINGSUIT_TEST_FAILED = 'BOOKINGSUIT_TEST_FAILED';
export const BOOKINGSUIT_TEST_SUCCEED = 'BOOKINGSUIT_TEST_SUCCEED';

export const AVERAGE_PRICE_SUCCEED = 'AVERAGE_PRICE_SUCCEED';
export const AVERAGE_MONTH_WEEK = 'AVERAGE_MONTH_WEEK';

export const GET_PRICE_NOTE = 'GET_PRICE_NOTE';

export const GET_SCRAPER_LIST_FAILED = 'GET_SCRAPER_LIST_FAILED';
export const GET_SCRAPER_LIST_SUCCEED = 'GET_SCRAPER_LIST_SUCCEED';

export const GET_FEATURES_START = 'GET_FEATURES_START';
export const GET_FEATURES_FAILED = 'GET_FEATURES_FAILED';
export const GET_FEATURES_SUCCEED = 'GET_FEATURES_SUCCEED';

export const UPDATE_FEATURES_START = 'UPDATE_FEATURES_START';
export const UPDATE_FEATURES_FAILED = 'UPDATE_FEATURES_FAILED';
export const UPDATE_FEATURES_SUCCEED = 'UPDATE_FEATURES_SUCCEED';

export const DELETE_FEATURES_START = 'DELETE_FEATURES_START';
export const DELETE_FEATURES_FAILED = 'DELETE_FEATURES_FAILED';
export const DELETE_FEATURES_SUCCEED = 'DELETE_FEATURES_SUCCEED';

export const UPDATE_LAST_PRICING_START = 'UPDATE_LAST_PRICING_START';
export const UPDATE_LAST_PRICING_FAILED = 'UPDATE_LAST_PRICING_FAILED';
export const UPDATE_LAST_PRICING_SUCCEED = 'UPDATE_LAST_PRICING_SUCCEED';


export const ROOM_BILKUPDATE_START = 'ROOM_BILKUPDATE_START';
export const ROOM_BILKUPDATE_FAILED = 'ROOM_BILKUPDATE_FAILED';
export const ROOM_BILKUPDATE_SUCCEED = 'ROOM_BILKUPDATE_SUCCEED';

export const MAIL_SETTINGS = 'MAIL_SETTINGS';

export const MONTHLY_PRICE = 'MONTHLY_PRICE';

export const PUBLISHER_LIST_SUCCEED = "PUBLISHER_LIST_SUCCEED";

export const ROOM_TYPE_LIST_SUCCESS = "ROOM_TYPE_LIST_SUCCESS";
export const ROOM_RATE_LIST_SUCCESS = "ROOM_RATE_LIST_SUCCESS";

export const GET_PRICING_REPORT = "GET_PRICING_REPORT";

export const GET_SETTINGS_LOG_START = "GET_SETTINGS_LOG_START";
export const GET_SETTINGS_LOG_SUCCESS = "GET_SETTINGS_LOG_SUCCESS";
export const GET_SETTINGS_LOG_FAILED = "GET_SETTINGS_LOG_FAILED";

export const GET_USERS_LOG_START = "GET_USERS_LOG_START";
export const GET_USERS_LOG_SUCCESS = "GET_USERS_LOG_SUCCESS";
export const GET_USERS_LOG_FAILED = "GET_USERS_LOG_FAILED";

export const SET_USERS_LOG_START = "SET_USERS_LOG_START";
export const SET_USERS_LOG_SUCCESS = "SET_USERS_LOG_SUCCESS";
export const SET_USERS_LOG_FAILED = "SET_USERS_LOG_FAILED";

export const IMPUTATION_SUCCEED = "IMPUTATION_SUCCEED";
export const BOOKING_COMPETITOR_SUCCEED = "BOOKING_COMPETITOR_SUCCEED";


export const WEATHER_DETAILS = "WEATHER_DETAILS";

export const CLOSE_TICKET_SUCCEED = "CLOSE_TICKET_SUCCEED";
export const CLOSE_TICKET_FAILED = "CLOSE_TICKET_FAILED";
export const CLOSE_TICKET_START = "CLOSE_TICKET_START";

export const UPDATE_TICKET_COMMENT_START = "UPDATE_TICKET_COMMENT_START";
export const UPDATE_TICKET_COMMENT_FAILED = "UPDATE_TICKET_COMMENT_FAILED";
export const UPDATE_TICKET_COMMENT_SUCCEED = "UPDATE_TICKET_COMMENT_SUCCEED";

export const GET_PMS_CHART_DATA_START = "GET_PMS_CHART_DATA_START";
export const GET_PMS_CHART_DATA_FAILED = "GET_PMS_CHART_DATA_FAILED";
export const GET_PMS_CHART_DATA_SUCCEED = "GET_PMS_CHART_DATA_SUCCEED";

export const GET_DEAL_OWNER_CHART_DATA_START = "GET_DEAL_OWNER_CHART_DATA_START";
export const GET_DEAL_OWNER_CHART_DATA_FAILED = "GET_DEAL_OWNER_CHART_DATA_FAILED";
export const GET_DEAL_OWNER_CHART_DATA_SUCCEED = "GET_DEAL_OWNER_CHART_DATA_SUCCEED";

export const RMS_PROPERTY_LIST = "RMS_PROPERTY_LIST";

export const RMS_AGENTS_LIST = "RMS_AGENTS_LIST";

export const GET_ON_DEMAND_SCHEDULE_START = "GET_ON_DEMAND_SCHEDULE_START";
export const GET_ON_DEMAND_SCHEDULE_FAILED = "GET_ON_DEMAND_SCHEDULE_FAILED";
export const GET_ON_DEMAND_SCHEDULE_SUCCEED = "GET_ON_DEMAND_SCHEDULE_SUCCEED";

export const SCHEDULE_HISTORY_LIST_START = "SCHEDULE_HISTORY_LIST_START";
export const SCHEDULE_HISTORY_LIST_FAILED = "SCHEDULE_HISTORY_LIST_FAILED";
export const SCHEDULE_HISTORY_LIST_SUCCEED = "SCHEDULE_HISTORY_LIST_SUCCEED";

export const SCHEDULE_HISTORY_DETAILS_START = "SCHEDULE_HISTORY_DETAILS_START";
export const SCHEDULE_HISTORY_DETAILS_FAILED = "SCHEDULE_HISTORY_DETAILS_FAILED";
export const SCHEDULE_HISTORY_DETAILS_SUCCEED = "SCHEDULE_HISTORY_DETAILS_SUCCEED";

export const SCORE_TOP_COMPETITOR_START = "SCORE_TOP_COMPETITOR_START";
export const SCORE_TOP_COMPETITOR_FAILED = "SCORE_TOP_COMPETITOR_FAILED";
export const SCORE_TOP_COMPETITOR_SUCCEED = "SCORE_TOP_COMPETITOR_SUCCEED";

export const TOP_COMPETITOR_START = "TOP_COMPETITOR_START";
export const TOP_COMPETITOR_FAILED = "TOP_COMPETITOR_FAILED";
export const TOP_COMPETITOR_SUCCEED = "TOP_COMPETITOR_SUCCEED";

export const SCORE_COMPETITOR_START = "SCORE_COMPETITOR_START";
export const SCORE_COMPETITOR_FAILED = "SCORE_COMPETITOR_FAILED";
export const SCORE_COMPETITOR_SUCCEED = "SCORE_COMPETITOR_SUCCEED";

export const PROCESS_DATA_SUCCEED = "PROCESS_DATA_SUCCEED";
export const PROCESS_DATA_START = "PROCESS_DATA_START";
export const PROCESS_DATA_FAILED = "PROCESS_DATA_FAILED";

export const GET_OPEN_TICKETS_START = "GET_OPEN_TICKETS_START";
export const GET_OPEN_TICKETS_FAILED = "GET_OPEN_TICKETS_FAILED";
export const GET_OPEN_TICKETS_SUCCEED = "GET_OPEN_TICKETS_SUCCEED";

export const UPDATE_BULKCOMPETITOR_START = "UPDATE_BULKCOMPETITOR_START";
export const UPDATE_BULKCOMPETITOR_FAILED = "UPDATE_BULKCOMPETITOR_FAILED";
export const UPDATE_BULKCOMPETITOR_SUCCEED = "UPDATE_BULKCOMPETITOR_SUCCEED";

export const VALIDATE_BOOKING_URL_SUCCESS="VALIDATE_BOOKING_URL_SUCCESS";
export const VALIDATE_BOOKING_URL_FAILED="VALIDATE_BOOKING_URL_FAILED";

export const OTAINSIGHTS_COMPETITOR_SETUP_START="OTAINSIGHTS_COMPETITOR_SETUP_START";
export const OTAINSIGHTS_COMPETITOR_SETUP_SUCCEED="OTAINSIGHTS_COMPETITOR_SETUP_SUCCEED";
export const OTAINSIGHTS_COMPETITOR_SETUP_FAILED="OTAINSIGHTS_COMPETITOR_SETUP_FAILED";

export const GET_OTA_COMPETITOR_START="GET_OTA_COMPETITOR_START";
export const GET_OTA_COMPETITOR_FAILED="GET_OTA_COMPETITOR_FAILED";
export const GET_OTA_COMPETITOR_SUCCEED="GET_OTA_COMPETITOR_SUCCEED";

export const OTAINSIGHTS_COMPETITOR_CSV_START="OTAINSIGHTS_COMPETITOR_CSV_START";
export const OTAINSIGHTS_COMPETITOR_CSV_SUCCEED="OTAINSIGHTS_COMPETITOR_CSV_SUCCEED";
export const OTAINSIGHTS_COMPETITOR_CSV_FAILED="OTAINSIGHTS_COMPETITOR_CSV_FAILED";

export const OTA_COMPETITOR_RATE_START="OTA_COMPETITOR_RATE_START";
export const OTA_COMPETITOR_RATE_FAILED="OTA_COMPETITOR_RATE_FAILED";
export const OTA_COMPETITOR_RATE_SUCCEED="OTA_COMPETITOR_RATE_SUCCEED";

export const GET_OTA_COMPETITOR_RATE_START="GET_OTA_COMPETITOR_RATE_START";
export const GET_OTA_COMPETITOR_RATE_FAILED="GET_OTA_COMPETITOR_RATE_FAILED";
export const GET_OTA_COMPETITOR_RATE_SUCCEED="GET_OTA_COMPETITOR_RATE_SUCCEED";

export const GET_OTA_DROPDOWN_DATA_START="GET_OTA_DROPDOWN_DATA_START";
export const GET_OTA_DROPDOWN_DATA_FAILED="GET_OTA_DROPDOWN_DATA_FAILED";
export const GET_OTA_DROPDOWN_DATA_SUCCEED="GET_OTA_DROPDOWN_DATA_SUCCEED";

export const STORE_OTA_DROPDOWN_DATA_START="STORE_OTA_DROPDOWN_DATA_START";
export const STORE_OTA_DROPDOWN_DATA_FAILED="STORE_OTA_DROPDOWN_DATA_FAILED";
export const STORE_OTA_DROPDOWN_DATA_SUCCEED="STORE_OTA_DROPDOWN_DATA_SUCCEED";

export const GET_ERROR_TICKETS_START = "GET_ERROR_TICKETS_START";
export const GET_ERROR_TICKETS_SUCCESS = "GET_ERROR_TICKETS_SUCCESS";
export const GET_ERROR_TICKETS_FAILED = "GET_ERROR_TICKETS_FAILED";

export const UPDATE_SINGLE_ERROR_TICKETS_START = "UPDATE_SINGLE_ERROR_TICKETS_START";
export const UPDATE_SINGLE_ERROR_TICKETS_SUCCESS = "UPDATE_SINGLE_ERROR_TICKETS_SUCCESS";
export const UPDATE_SINGLE_ERROR_TICKETS_FAILED = "UPDATE_SINGLE_ERROR_TICKETS_FAILED";

export const UPDATE_MULTIPLE_ERROR_TICKETS_START = "UPDATE_MULTIPLE_ERROR_TICKETS_START";
export const UPDATE_MULTIPLE_ERROR_TICKETS_SUCCESS = "UPDATE_MULTIPLE_ERROR_TICKETS_SUCCESS";
export const UPDATE_MULTIPLE_ERROR_TICKETS_FAILED = "UPDATE_MULTIPLE_ERROR_TICKETS_FAILED";

export const DELETE_PROPERTY_SUCCESS="DELETE_PROPERTY_SUCCESS"

export const PERFORMANCE_RESERVATION_START = "PERFORMANCE_RESERVATION_START";
export const PERFORMANCE_RESERVATION_SUCCESS = "PERFORMANCE_RESERVATION_SUCCESS";
export const PERFORMANCE_RESERVATION_FAILED = "PERFORMANCE_RESERVATION_FAILED";

export const UPDATE_RESERVATION_START = "UPDATE_RESERVATION_START";
export const UPDATE_RESERVATION_SUCCESS = "UPDATE_RESERVATION_SUCCESS";
export const UPDATE_RESERVATION_FAILED = "UPDATE_RESERVATION_FAILED";

export const GET_GENIEINTELLIGENCE_START = "GET_GENIEINTELLIGENCE_START";
export const GET_GENIEINTELLIGENCE_SUCCESS = "GET_GENIEINTELLIGENCE_SUCCESS";
export const GET_GENIEINTELLIGENCE_FAILED = "GET_GENIEINTELLIGENCE_FAILED";

export const GET_PROCESS_ALL_RESERVATION_START = "GET_PROCESS_ALL_RESERVATION_START";
export const GET_PROCESS_ALL_RESERVATION_SUCCESS = "GET_PROCESS_ALL_RESERVATION_SUCCESS";
export const GET_PROCESS_ALL_RESERVATION_FAILED = "GET_PROCESS_ALL_RESERVATION_FAILED";

export const STORE_OCCUPANCY_PRICES_START = "STORE_OCCUPANCY_PRICES_START";
export const STORE_OCCUPANCY_PRICES_FAILED = "STORE_OCCUPANCY_PRICES_FAILED";
export const STORE_OCCUPANCY_PRICES_SUCCESS = "STORE_OCCUPANCY_PRICES_SUCCESS";

export const GET_OCCUPANCY_PRICES_START = "GET_OCCUPANCY_PRICES_START";
export const GET_OCCUPANCY_PRICES_FAILED = "GET_OCCUPANCY_PRICES_FAILED";
export const GET_OCCUPANCY_PRICES_SUCCESS = "GET_OCCUPANCY_PRICES_SUCCESS";

export const GET_OCCUPANCY_PRICES_ALL_START = "GET_OCCUPANCY_PRICES_ALL_START";
export const GET_OCCUPANCY_PRICES_ALL_FAILED = "GET_OCCUPANCY_PRICES_ALL_FAILED";
export const GET_OCCUPANCY_PRICES_ALL_SUCCESS = "GET_OCCUPANCY_PRICES_ALL_SUCCESS";

export const GET_HOURLY_EXCUTION_FAILED = "GET_HOURLY_EXCUTION_FAILED";

export const REFERRAL_DEAL_START = "REFERRAL_DEAL_START";
export const REFERRAL_DEAL_FAILED = "REFERRAL_DEAL_FAILED";
export const REFERRAL_DEAL_SUCCEED = "REFERRAL_DEAL_SUCCEED";

export const UPDATE_PURGE_RESERVATION_START = "UPDATE_PURGE_RESERVATION_START";
export const UPDATE_PURGE_RESERVATION_SUCCESS = "UPDATE_PURGE_RESERVATION_SUCCESS";
export const UPDATE_PURGE_RESERVATION_FAILED = "UPDATE_PURGE_RESERVATION_FAILED";

export const HOTEL_REGISTRATION_START = "HOTEL_REGISTRATION_START";
export const HOTEL_REGISTRATION_SUCCEED = "HOTEL_REGISTRATION_SUCCEED";
export const HOTEL_REGISTRATION_FAILED = "HOTEL_REGISTRATION_FAILED";

export const IMPORTANT_EVENT_START = "IMPORTANT_EVENT_START"
export const IMPORTANT_EVENT_SUCCEED = "IMPORTANT_EVENT_SUCCEED";
export const IMPORTANT_EVENT_FAILED = "IMPORTANT_EVENT_FAILED";

export const ON_ACADEMY_SIGNUP_SUCCEED = "ON_ACADEMY_SIGNUP_SUCCEED";
export const ON_ACADEMY_SIGNUP_FAILED = "ON_ACADEMY_SIGNUP_FAILED";
export const ON_ACADEMY_SIGNUP_START = "ON_ACADEMY_SIGNUP_START";

export const REFERRAL_DEAL_PROGRAM_START = "REFERRAL_DEAL_PROGRAM_START";
export const REFERRAL_DEAL_PROGRAM_FAILED = "REFERRAL_DEAL_PROGRAM_FAILED";
export const REFERRAL_DEAL_PROGRAM_SUCCEED = "REFERRAL_DEAL_PROGRAM_SUCCEED";

export const SCRAPE_TOP_20_COMPETITORS_START="SCRAPE_TOP_20_COMPETITORS_START";
export const SCRAPE_TOP_20_COMPETITORS_FAILED = "SCRAPE_TOP_20_COMPETITORS_FAILED";
export const SCRAPE_TOP_20_COMPETITORS_SUCCEED = "SCRAPE_TOP_20_COMPETITORS_SUCCEED";

export const ACADEMY_START = "ACADEMY_START";
export const ACADEMY_FAILED = "ACADEMY_FAILED";
export const ACADEMY_SUCCEED = "ACADEMY_SUCCEED";

export const STORE_ISSUE_REPORT_START="STORE_ISSUE_REPORT_START";
export const STORE_ISSUE_REPORT_FAILED="STORE_ISSUE_REPORT_FAILED";
export const STORE_ISSUE_REPORT_SUCCEED="STORE_ISSUE_REPORT_SUCCEED";

export const GET_PRICE_PER_RATE_START="GET_PRICE_PER_RATE_START";
export const GET_PRICE_PER_RATE_FAILED="GET_PRICE_PER_RATE_FAILED";
export const GET_PRICE_PER_RATE_SUCCEED="GET_PRICE_PER_RATE_SUCCEED";

export const UPDATE_TEMP_DATA="UPDATE_TEMP_DATA";
export const GET_DEFAULT_VALUE_AFTER_YEAR="GET_DEFAULT_VALUE_AFTER_YEAR";

export const RESET_INVENTORY_START = "RESET_INVENTORY_START";
export const RESET_INVENTORY_SUCCEED = "RESET_INVENTORY_SUCCEED";
export const RESET_INVENTORY_FAILED = "RESET_INVENTORY_FAILED";

export const GET_RESERVATION_FAILED_TASK_LIST_START = "GET_RESERVATION_FAILED_TASK_LIST_START";
export const GET_RESERVATION_FAILED_TASK_LIST_ERROR = "GET_RESERVATION_FAILED_TASK_LIST_ERROR";
export const GET_RESERVATION_FAILED_TASK_LIST_SUCCEED = "GET_RESERVATION_FAILED_TASK_LIST_SUCCEED";

export const GET_RUN_FLAG = "GET_RUN_FLAG";


export const EVVIVO_PROPERTY_LIST = "EVVIVO_PROPERTY_LIST";
export const HLS_PROPERTY_LIST = "HLS_PROPERTY_LIST";

export const GET_RESERVATION_ERROR_TICKETS_SUCCESS = "GET_RESERVATION_ERROR_TICKETS_SUCCESS";
export const GET_OTHERS_ERROR_TICKETS_SUCCESS = "GET_OTHERS_ERROR_TICKETS_SUCCESS";
export const GET_CRITICAL_ERROR_TICKETS_SUCCESS = "GET_CRITICAL_ERROR_TICKETS_SUCCESS";

export const PERFORMANCE_PICKUP_START = "PERFORMANCE_PICKUP_START";
export const PERFORMANCE_PICKUP_SUCCESS = "PERFORMANCE_PICKUP_SUCCESS";
export const PERFORMANCE_PICKUP_FAILED = "PERFORMANCE_PICKUP_FAILED";
export const FREE_TO_BOOK_PMS_SUCCEED = 'FREE_TO_BOOK_PMS_SUCCEED';

export const PRICING_SETTINGS_SUCCEED = 'PRICING_SETTINGS_SUCCEED';
export const PRICING_SETTINGS_FAILED = 'PRICING_SETTINGS_FAILED';
export const PRICING_SETTINGS_START='PRICING_SETTINGS_START';

export const USER_NOTES_SUCCEED='USER_NOTES_SUCCEED';
export const GET_PROFIT_REPORT_SUCCESS='GET_PROFIT_REPORT_SUCCESS';
export const GET_PROFIT_REPORT_START='GET_PROFIT_REPORT_START';
export const GET_PROFIT_REPORT_FAILED='GET_PROFIT_REPORT_FAILED';

export const UPDATE_CLIENT_VIEW='UPDATE_CLIENT_VIEW';

export const GET_API_LOG_START = "GET_API_LOG_START";
export const GET_API_LOG_SUCCESS = "GET_API_LOG_SUCCESS";
export const GET_API_LOG_FAILED = "GET_API_LOG_FAILED";

export const PREPARE_PUSHER = "PREPARE_PUSHER";
export const GET_INVITE_USER_DETAIL = "GET_INVITE_USER_DETAIL";

export const GET_AIR_DNA_DATA = "GET_AIR_DNA_DATA"

export const GET_OUTBOUND_API_LOG_START = "GET_OUTBOUND_API_LOG_START";
export const GET_OUTBOUND_API_LOG_SUCCESS = "GET_OUTBOUND_API_LOG_SUCCESS";
export const GET_OUTBOUND_API_LOG_FAILED = "GET_OUTBOUND_API_LOG_FAILED";

export const CHARGEBEE_CONNECTION_SUCCEED ="CHARGEBEE_CONNECTION_SUCCEED";
export const CHARGEBEE_LINK_SUCCEED ="CHARGEBEE_LINK_SUCCEED";

export const DASHBOARD_DEFAULT_BUDGET_SUCCEED="DASHBOARD_DEFAULT_BUDGET_SUCCEED";
export const PANDING_USERS_SUCCESS ="PANDING_USERS_SUCCESS";
export const HOTSOFT_ACCOUNT_LIST ="HOTSOFT_ACCOUNT_LIST";

export const POPUP_ALERT_START = 'POPUP_ALERT_START';
export const POPUP_ALERT_SUCCESS = 'POPUP_ALERT_SUCCESS';

export const POPUP_ALERT_FAILD = 'POPUP_ALERT_FAILD';
export const OAUTH2_PMS_SUCCEED = 'OAUTH2_PMS_SUCCEED';

export const GET_SCHEDULE_REPORT = 'GET_SCHEDULE_REPORT';
export const GET_SCHEDULE_REPORT_SUCCESS = 'GET_SCHEDULE_REPORT_SUCCESS';
export const GET_SCHEDULE_REPORT_FAILED = 'GET_SCHEDULE_REPORT_FAILED';

export const TAG_LIST_SUCCESS = 'TAG_LIST_SUCCESS';
export const TAG_LIST_LOG_FAILED = 'TAG_LIST_LOG_FAILED';



export const ANALYTICS_DATA_SUCCESS='ANALYTICS_DATA_SUCCESS'


export const FORECAST_DATA_SUCCESS='FORECAST_DATA_SUCCESS'

export const SABEE_PROPERTY_LIST='SABEE_PROPERTY_LIST'
export const MULTIPROPERTY_SUCCEED = "MULTIPROPERTY_SUCCEED"

export const GET_ERROR_TAGS_LIST_START = "GET_ERROR_TAGS_LIST_START"
export const GET_ERROR_TAGS_LIST_SUCCESS = "GET_ERROR_TAGS_LIST_SUCCESS"
export const GET_ERROR_TAGS_LIST_FAILED = "GET_ERROR_TAGS_LIST_FAILED"
