import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import occupancyCss from './css/targetOccupancyCss';
import Table from '@material-ui/core/Table';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { connect } from "react-redux";
import { compose } from 'redux';
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import CustomTooltip from '../Utils/toolTip';
import { withNamespaces } from 'react-i18next';
import * as actions from '../../store/actions/index';
import InputLabel from '@material-ui/core/InputLabel';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import axios from 'axios';
import { ValidateBaseSettings } from '../Utils/SettingValidation/Validation';
import Server from '../../config/server';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { targetOccupancyTheme } from '../muiTheme';
import purple from '@material-ui/core/colors/purple';
import { Bar } from 'react-chartjs-2';
import Warning from './warning';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Aggressiveness from "./settings/internalParams";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import HeaderCard from "./HeaderCard";

const styles = occupancyCss;

let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
class TargetOccupancy extends React.Component {

    state = {
        monthly_target_occupancy: [],
        rooms_sold_to_groups:[],
        error: [],
        errorMsg: "",
        target_occupancy: '',
        featuresEnabled: [],
        averageWeekMonth: {},
        warning: false,
        warningMsg: [],
        warningMethod: null,
        tab: "0",
        isUpdate: true,
        median_lead_time: 8,
        customLeadTime: false,
        close_out_hour: 0,
        room_apartment_space_name: "",
        isUpdateAggress:false,
        close_out_days:0,
    };
    componentDidMount() {
        let requestParam = { token: this.props.hotelAuthReducer.hotelDetails.token }
        this.props.getHotelDetail(requestParam);
    }
    componentWillReceiveProps(props) {
        
        if(props.hotelDetailReducer.hoteDetail !== this.props.hotelDetailReducer.hoteDetail){
            this.setState({
                close_out_hour:props.hotelDetailReducer.hoteDetail.close_out_hour,
                close_out_days:props.hotelDetailReducer.hoteDetail.close_out_days,
                room_apartment_space_name: props.hotelAuthReducer.hotelDetails.hotel.room_apartment_space_name
            })
        }
        if (props.priceSettingReducer && props.priceSettingReducer.priceSetting) {
            this.setState({
                monthly_target_occupancy: props.priceSettingReducer.priceSetting.monthly_target_occupancy,
                target_occupancy: props.priceSettingReducer.priceSetting.hotel.target_occupancy,
                rooms_sold_to_groups: props.priceSettingReducer.priceSetting.rooms_sold_to_groups
            })
            this.setState({
                target_occupancy_old: JSON.stringify(props.priceSettingReducer.priceSetting.monthly_target_occupancy),
            })
        }
        if (props.priceSettingReducer && props.priceSettingReducer.priceSetting) {
            this.setState({
                featuresEnabled: props.priceSettingReducer.priceSetting.features
            })
        }

        if (props.priceSettingReducer.priceSetting && this.state.isUpdate) {
            this.setState({
                leadTime: props.priceSettingReducer.priceSetting.adjustment.lead_time,
                median_lead_time: props.priceSettingReducer.priceSetting.rpg_arguments.median_lead_time,
                isUpdate: false
            })

            if (![14, 21, 30].includes(props.priceSettingReducer.priceSetting.rpg_arguments.median_lead_time)) {
                this.setState({
                    customLeadTime: "true"
                })
            }
        }

        if (props.commonReducer.notify && props.commonReducer.notify.message) {
            this.setState({
                loadData: false
            })
        }
    }

    handleTab = (tab) => {
        this.setState({
            tab
        })
    }

    toggleCustomLeadTime = () => {
        this.setState({
            customLeadTime: "true",
        })
    }

    toggleWarningModal = (method) => {
        let warningMsg = [];
        let warning = false;
        let errorMsg = "";
        let customButton = null;
        const { t, classes } = this.props;

        if (method == "saveTargetOccupancy") {

            let data = this.state.monthly_target_occupancy.map(row => parseFloat(row));
            let error = [];

            data.map((row, index) => {
                if (!row) {
                    error.push(index)
                }
                if (row > 100) {
                    error.push(index)
                    errorMsg = "Please enter a target occupancy between 10% and 100%"
                }
                if (row < 10) {
                    error.push(index)
                    errorMsg = "Please enter a target occupancy between 10% and 100%"
                }

                if (row > 90 && errorMsg == "") {
                    warning = true;
                    let msg=t("We strongly recommend not targeting over 90% occupancy as this means your pricing will be lower than it needs to be.")
                    warningMsg.push(msg)
                    customButton = (<>
                        <Button disabled={this.state.loadingUplaod} onClick={() => this.warningConfirm(90)} className={classes.saveUploadBtn} variant="contained" color="primary" >
                            {t("Set to 90%")}
                        </Button>
                        <Button disabled={this.state.loadingUplaod} onClick={() => this.warningConfirm()} className={classes.saveUploadBtn} variant="contained" color="primary" >
                            {t("Confirm")}
                        </Button></>);
                }
                if (row < 30 && errorMsg == "") {
                    warning = true;
                    let msg=t("We would recommend not targeting below 30% occupancy as this means your pricing could be too high.");
                    warningMsg.push(msg)
                    customButton = (<>
                        <Button disabled={this.state.loadingUplaod} onClick={() => this.warningConfirm(30)} className={classes.saveUploadBtn} variant="contained" color="primary" >
                            {t("Set to 30%")}
                        </Button>
                        <Button disabled={this.state.loadingUplaod} onClick={() => this.warningConfirm()} className={classes.saveUploadBtn} variant="contained" color="primary" >
                            {t("Confirm")}
                        </Button></>);
                }
            });

            if (error.length > 0) {
                this.setState({
                    error: error
                })
                warning = false
            } else if (!warning) {
                this.saveTargetOccupancy()
                this.setState({
                    error: []
                })
            }

        }

        this.setState({
            customButton: customButton,
            warning: warning,
            warningMethod: method,
            warningMsg: warningMsg,
            errorMsg: errorMsg
        })
    }

    closeWarningModal = (method) => {
        if (this.state.warningMethod == "saveTargetOccupancy") {
            this.setState({
                monthly_target_occupancy: JSON.parse(this.state.target_occupancy_old),
            })
        }
        this.setState({
            warning: false,
        })
    }

    warningConfirm = (value) => {
        if (this.state.warningMethod == "saveTargetOccupancy" && !value) {
            this.saveTargetOccupancy()
        }
        if (this.state.warningMethod == "saveTargetOccupancy" && value) {
            let monthly_target_occupancy = [];
            this.state.monthly_target_occupancy.map((row, index) => {
                if (value == 90 && parseFloat(row) > 90) {
                    monthly_target_occupancy.push(90);
                    return 90;
                }
                if (value == 30 && parseFloat(row) < 30) {
                    monthly_target_occupancy.push(30)
                    return 30;
                }
                if (index < 12) {
                    monthly_target_occupancy.push(row)
                    return row;
                }

            })
            this.setState({
                monthly_target_occupancy: monthly_target_occupancy
            }, () => this.saveTargetOccupancy()
            )
        }
        this.setState({
            warning: false,
        })
    }

    handleChange = (index) => event => {
        let data = this.state.monthly_target_occupancy
        data[index] = event.target.value;

        this.setState({
            monthly_target_occupancy: data
        })
    }

    handleChangeSegment = (index) => event => {
        let data = this.state.rooms_sold_to_groups;
        data[index] = event.target.value ? parseFloat(event.target.value) : event.target.value;

        this.setState({
            rooms_sold_to_groups: data
        })
    }


    handleOccChange = () => event => {
        this.setState({
            target_occupancy: event.target.value,
        })
    }

    validateData = (data) => {
        let error = [];
        data.map((row, index) => {
            if (!row) {
                error.push(index)
            }
        });
        if (error.length > 0) {
            this.setState({
                error: error
            })
            return false
        }
        this.setState({
            error: []
        })
        return true;
    }

    saveTargetOccupancy = () => {
        let data = this.state.monthly_target_occupancy.filter((row, index) => index < 12).map(row => parseFloat(row));

        if (this.validateData(data)) {
            let setting = this.props.priceSettingReducer.priceSetting;

            setting.monthly_target_occupancy = data;
            setting.hotel.target_occupancy = this.state.target_occupancy;


            
            // let flagReq = {
            //     run_pricing: true,
            //     token: this.props.hotelAuthReducer.hotelDetails.token,
            // }
            // this.props.setRunPriceFlags(flagReq);

            
            if(this.state.rooms_sold_to_groups){
                Object.keys(this.state.rooms_sold_to_groups).map((key) => {
                    setting.rooms_sold_to_groups[key] = (this.state.rooms_sold_to_groups[key] ? this.state.rooms_sold_to_groups[key] : 0);
                })
            }            

            let latestJson = ValidateBaseSettings({ dateSetting: setting });

            let reqParams = {
                settings: JSON.stringify(latestJson.dateSetting),
            };

            this.setState({
                loadData: true
            })

            axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
            axios.post(Server.API + 'hotel/pricing-settings/', reqParams)
                .then((response) => {
                    this.props.getPricingSettings({ token: this.props.hotelAuthReducer.hotelDetails.token })
                    let requestData = {
                        msg: 'Settings Updated Successfully',
                        is_success: true,
                    }

                    this.props.putNotify(requestData)

                    this.setState({
                        loadData: false
                    })

                }).catch(() => {
                    let requestData = {
                        msg: 'Error while Applying the Changes',
                        is_success: false,
                    }
                    this.props.putNotify(requestData)
                    this.setState({
                        loadData: false
                    })
                })

        }
    }

    renderTable = (limit) => {
        var jsx = [];
        const { classes } = this.props;
        const { t } = this.props;
        let percentAdorn = <Typography variant="body2" className={classes.adornment}>%</Typography>;
        
        if (this.state.monthly_target_occupancy) {
            this.state.monthly_target_occupancy.filter((row, index) => index < 12).map((row, index) => {
                let shortMonth =  monthNames[index].slice(0, 3) // Jan,Feb
                return jsx.push(
                    <TableCell align="center" className={classes.cellWidth} >
                        <FormControl margin="normal" required className={classes.fieldMargin}>
                            <Input
                                label={t("Value")}
                                type="number"
                                fullWidth
                                error={this.state.error.includes(index)}
                                className={classes.textField}
                                onChange={this.handleChange(index)}
                                value={row}
                                placeholder={t(shortMonth[0].toUpperCase() + shortMonth.slice(1))}
                                endAdornment={percentAdorn}
                            />
                            {this.state.error.includes("mon_" + row) ? <FormHelperText><span class="error-message">{t('* Please enter the value')}</span></FormHelperText> : ''}
                        </FormControl>
                    </TableCell>
                );
            });
        }
        return jsx;
    }

    renderSegmentTable= () => {
        var jsx = [];
        const { classes } = this.props;
        const { t } = this.props;
        if (this.state.rooms_sold_to_groups) {
            Object.keys(this.state.rooms_sold_to_groups).map((key,index) => {  
                let shortMonth =  monthNames[index].slice(0, 3) // Jan,Feb             
                return jsx.push(
                    <TableCell align="center" className={classes.cellWidth} >
                        <FormControl margin="normal" required className={classes.fieldMargin}>
                            <Input
                                label={t("Value")}
                                type="number"
                                fullWidth
                                className={classes.textField}
                                onChange={this.handleChangeSegment(key)}
                                value={this.state.rooms_sold_to_groups[key]}
                                placeholder={t(shortMonth[0].toUpperCase() + shortMonth.slice(1))}
                            />
                        </FormControl>
                    </TableCell>
                );
                
            });
        }
        return jsx;
    }

    handleState = (value) => {
        this.setState({
            median_lead_time: value,
            customLeadTime: false
        });
    }

    handleCloseOuthour = (e) => {
        if(e.target.value != 0){
            this.setState({
                [e.target.name]:e.target.value,
                close_out_days:0
            })
        }else{
            this.setState({
                [e.target.name]:e.target.value,
            })
        }
    }
    handleCloseOutDays = (e) => {
        if(e.target.value != 0){
            this.setState({
                [e.target.name]:e.target.value,
                close_out_hour:0
            })
        }else{
            this.setState({
                [e.target.name]:e.target.value, 
            })
        }
    }

    handleStateCustom = (name) => event => {
        this.setState({
            median_lead_time: event.target.value,
        });
    }
    validateOccupancy = () => {
        let error = [];
        if(this.state.customLeadTime){
            if(!this.state.median_lead_time || this.state.median_lead_time <= 0){
                error.push("median_lead_time")
            }
        }
        
        this.setState({
            error
          })
      
          return error
    }
    updateTimeToBooking = () => {
        let error = this.validateOccupancy();
        if (error.length == 0) {
            let setting = this.props.priceSettingReducer.priceSetting;

            setting.rpg_arguments.median_lead_time = this.state.median_lead_time;


            
            // let flagReq = {
            //     run_pricing: true,
            //     token: this.props.hotelAuthReducer.hotelDetails.token,
            // }
            // this.props.setRunPriceFlags(flagReq);

            this.setState({
                loadData: true
            })

            let requestData = {
                token: this.props.hotelAuthReducer.hotelDetails.token,
                dateSetting: setting,
            };
            this.props.updatePricingSettings(requestData)
        }
    }
    updateCloseOutSales = () => {
        this.setState({ loadData : true})
        let reqParams={
            token:this.props.hotelAuthReducer.hotelDetails.token,
            close_out_hour:this.state.close_out_hour,
            close_out_days:this.state.close_out_days,
        }
        this.props.updateHotel(reqParams);
    }
    updateFromAggress=(val)=>{
        this.setState({isUpdateAggress:false,loadData:val})
    }

    UpdateAggressive=()=>{
        this.setState({isUpdateAggress:true})
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;

        return (
            <>
            <HeaderCard>
                <Grid container>
                    <Grid item className={classes.headerGrid} >
                        <div className={classes.divStyle} >
                        <Typography variant="h5" className={classes.fontStyle}>
                          {t("Occupancy Strategy")}
                          <CustomTooltip title="" description={t("On this page, please enter your expected occupancy for each month. Try to get this somewhere in the right ballpark – it does not have to be exact. If you set the target occupancy much too high, we may think you are doing badly when you are actually doing well and move prices too low. If you set it much too low, we will see you doing better than expected and our price-recommendations may be too high.")} />
                        </Typography>
                        <Tabs
                                indicatorColor="primary"
                                textColor="primary"                             
                                variant="scrollable"
                                scrollButtons="on"
                                value={this.state.tab}
                                onChange={(evt, newtab) => this.handleTab(newtab)}
                                className={classes.tabView}
                            >
                                <Tab value="0" label={t("Target Occupancy")} wrapped />
                                <Tab value="1" label={t("Median Booking Window")} />
                                <Tab value="2" label={t("Aggressiveness")} />
                                <Tab value="3" label={t("Close Out Sales (Last Day)")} />
                            </Tabs>
                        </div>
                    </Grid>
                </Grid>
            </HeaderCard>
            <MuiThemeProvider theme={targetOccupancyTheme}>
                <div className={classes.root}>
                    <Card >
                        <CardContent className={classes.minHeight}> 
                            {this.state.tab == "0" &&
                                <>
                                    <div className={classes.dateTableDiv}>
                                    <DialogContentText align="left" className={classes.helpText}>
                                        {t("Set this at your expected occupancy level so the system knows what your occupancy targets are. RoomPriceGenie then adjusts prices to reach your target occupancy. Having low occupancy targets means we don't reduce prices so much in quiet times. Having high occupancy targets leads to lower prices. Going higher than 90% occupancy in any month can adversely affect revenue.")}
                                    </DialogContentText>

                                    <div> 
                                        {this.state.featuresEnabled.includes(12) ?
                                            <div className={[classes.dateTableDiv,classes.dateTableDiv2]} >
                                                <Table className={classes.userTable}>
                                                    <TableHead>
                                                        <TableRow className={classes.rowTable}>
                                                            <TableCell className={classes.cellWidthTitle}></TableCell>
                                                            <TableCell className={classes.cellWidthTitle} align="left">{t("Jan")}</TableCell>
                                                            <TableCell className={classes.cellWidthTitle} align="left">{t("Feb")}</TableCell>
                                                            <TableCell className={classes.cellWidthTitle} align="left">{t("Mar")}</TableCell>
                                                            <TableCell className={classes.cellWidthTitle} align="left">{t("Apr")}</TableCell>
                                                            <TableCell className={classes.cellWidthTitle} align="left">{t("May")}</TableCell>
                                                            <TableCell className={classes.cellWidthTitle} align="left">{t("Jun")}</TableCell>
                                                            <TableCell className={classes.cellWidthTitle} align="left">{t("Jul")}</TableCell>
                                                            <TableCell className={classes.cellWidthTitle} align="left">{t("Aug")}</TableCell>
                                                            <TableCell className={classes.cellWidthTitle} align="left">{t("Sep")}</TableCell>
                                                            <TableCell className={classes.cellWidthTitle} align="left">{t("Oct")}</TableCell>
                                                            <TableCell className={classes.cellWidthTitle} align="left">{t("Nov")}</TableCell>
                                                            <TableCell className={classes.cellWidthTitle} align="left">{t("Dec")}</TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>

                                                        <TableRow key={1} className={classes.rowTable}>
                                                            <TableCell className={classes.cellWidth} >{t("Target Occupancy")}</TableCell>
                                                            {this.renderTable()}
                                                        </TableRow>
                                                      
                                                        {this.state.featuresEnabled.includes(21) &&                                                         
                                                            <TableRow key={1} className={classes.rowTable}>
                                                            <TableCell className={classes.cellWidth}>{t(`${this.state.room_apartment_space_name} Nights Sold to Groups`)}</TableCell>
                                                                {this.renderSegmentTable()}
                                                            </TableRow>
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </div>
                                            :
                                            <div className={classes.dateTableDiv}>
                                                <FormControl margin="none" className={classes.settingFields}>
                                                    <InputLabel htmlFor="name" className={classes.inputLable} shrink={true}>{t("Target Occupancy")}</InputLabel>
                                                    <Input
                                                        error={this.state.minPriceError}
                                                        margin="none"
                                                        id="name"
                                                        name="name"
                                                        onChange={this.handleOccChange()}
                                                        value={this.state.target_occupancy}
                                                        placeholder={t(`Target Occupancy`)}
                                                        endAdornment={<>%<CustomTooltip title="" description={t(`Sets the occupancy target used to calculate your health score and how many ${this.state.room_apartment_space_name.toLowerCase()}s you’re expected to have left. Most clients set it to 80%. We don’t recommend aiming at 100%, what could be easily achieved by selling your ${this.state.room_apartment_space_name.toLowerCase()}s for $1. Always ending up at 100% may indicate that you’re charging too little.`)}></CustomTooltip></>}
                                                    />
                                                </FormControl>
                                            </div>
                                        }
                                        <span class="error-message">{this.state.errorMsg}</span>
                                    </div>
                                    </div>
                                </>
                            }

                            {this.state.tab == "1" &&
                                <div>
                                    <div className={classes.dateTableDiv}>
                                        <DialogContentText align="left" className={classes.helpText}>
                                            {t("50% of your bookings are made within how many days prior to check in?")} <br />
                                        </DialogContentText>
                                        <RadioGroup
                                            aria-label="Language"
                                            className={classes.radioPartner}
                                            value={this.state.customLeadTime ? false : this.state.median_lead_time}

                                        >
                                            <FormControlLabel
                                                onClick={() => this.handleState(14)}
                                                value={14}
                                                label={t("Short - Less than 14 days, typical for city hotels")}
                                                control={<Radio color={"primary"} className={classes.formLable} />} 
                                            />

                                            <FormControlLabel
                                                onClick={() => this.handleState(21)}
                                                value={21}
                                                label={t("Standard - 15-30 days, this is the default setting")}
                                                control={<Radio color={"primary"} className={classes.formLable} />} 
                                            />

                                            <FormControlLabel
                                                onClick={() => this.handleState(30)}
                                                value={30}
                                                label={t("Long - your average booking is made more than 30 days before arrival, this may be the case for ski resorts or other destination hotels")}
                                                control={<Radio color={"primary"} className={classes.formLable} />} 
                                            />

                                        </RadioGroup>
                                        <RadioGroup
                                            aria-label="Language"
                                            className={classes.radioPartner}
                                            value={this.state.customLeadTime}
                                        >
                                            <FormControlLabel
                                                onClick={this.toggleCustomLeadTime}
                                                value={"true"}
                                                label={t("Custom (Please contact support)")}
                                                control={<Radio color={"primary"} className={classes.formLable} />}
                                            />

                                        </RadioGroup>

                                        {this.state.customLeadTime && (this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) &&
                                            <FormControl required className={classes.leadTimeFromControl}  >
                                                <InputLabel htmlFor="price" className={classes.inputLable}  shrink={true}>{t("Lead Time Median")}</InputLabel>
                                                <Input
                                                    className={classes.textFieldSelect}
                                                    value={this.state.median_lead_time}
                                                    onChange={this.handleStateCustom('median_lead_time')}
                                                    endAdornment={<Typography>days</Typography>}
                                                    error={this.state.error.includes("median_lead_time")}
                                                    disabled={!(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type)}
                                                />
                                                {this.state.error.includes("median_lead_time") ? <span class='error-message'>{'* Please enter a number > 0'}</span> : ''}
                                            </FormControl>
                                        }
                                    </div>
                                    &nbsp;
                                    <DialogContentText align="left" className={classes.helpText2}>
                                            {t("Note: The lead time is used to set expectations about when bookings are made. This affects the colours in the occupancy view and the pricing. For example, if lead time is set to Long, and you do not see the expected demand, we will suggest reducing prices earlier.")}
                                    </DialogContentText>    
                                </div>
                            }

                            {this.state.tab == "2" &&
                            <div>
                                <Aggressiveness isUpdateAggress={this.state.isUpdateAggress}  updateFromAggress={this.updateFromAggress}/>
                           </div>
                            }
                            {this.state.tab == "3" &&
                                <div>
                                    <Grid container>
                                        <Grid sm={12} className={classes.radioPartner} >
                                        <div className={classes.dateTableDiv}>
                                        <DialogContentText align="left" className={classes.helpText}>
                                            {t("If you close out sales on the last day(s) after a certain time, please let us know. Otherwise we might fetch wrong availabilities.")}
                                        </DialogContentText>
                                        
                                        <Grid sm={12}  >
                                        <FormControl required className={[classes.formControl,classes.formControl2]} >
                                            <TextField
                                                name='close_out_hour'
                                                id="standard-select-currency"
                                                select
                                                label={t('Close out sales on last day at')}
                                                defaultValue={0}  
                                                value={this.state.close_out_hour}
                                                onChange={(e) => this.handleCloseOuthour(e)}
                                                className={[classes.textFieldSelect,classes.textFieldSelect2]}
                                                InputLabelProps={{ shrink: true }}
                                            >
                                                <MenuItem value='0'>{t('No Close Out')}</MenuItem>
                                                <MenuItem value='9'>{t('9am')}</MenuItem>
                                                <MenuItem value='10'>{t('10am')}</MenuItem>
                                                <MenuItem value='11'>{t('11am')}</MenuItem>
                                                <MenuItem value='12'>{t('12pm')}</MenuItem>
                                                <MenuItem value='13'>{t('1pm')}</MenuItem>
                                                <MenuItem value='14'>{t('2pm')}</MenuItem>
                                                <MenuItem value='15'>{t('3pm')}</MenuItem>
                                                <MenuItem value='16'>{t('4pm')}</MenuItem>
                                                <MenuItem value='17'>{t('5pm')}</MenuItem>
                                                <MenuItem value='18'>{t('6pm')}</MenuItem>
                                                <MenuItem value='19'>{t('7pm')}</MenuItem>
                                                <MenuItem value='20'>{t('8pm')}</MenuItem>
                                                <MenuItem value='21'>{t('9pm')}</MenuItem>
                                                <MenuItem value='22'>{t('10pm')}</MenuItem>
                                                <MenuItem value='23'>{t('11pm')}</MenuItem>
                                            </TextField>
                                        </FormControl>
                                        </Grid>
                                        <FormControl required className={[classes.formControl,classes.formControl2]} >
                                            <TextField
                                                name="close_out_days"
                                                id="standard-select-currency"
                                                select
                                                label={t('Close out sales on last days')}  
                                                value={this.state.close_out_days}
                                                onChange={(e) => this.handleCloseOutDays(e)}
                                                className={[classes.textFieldSelect,classes.textFieldSelect2]}
                                                InputLabelProps={{ shrink: true }}
                                                defaultValue={0}
                                            >
                                                <MenuItem value='0'>{t('No Close Out')}</MenuItem>
                                                <MenuItem value='1'>{t('Last day')}</MenuItem>
                                                <MenuItem value='2'>{t('Last 2 days')}</MenuItem>
                                            </TextField>
                                        </FormControl>
                                       
                                    </div>
                                        </Grid>
                                    </Grid>
                                </div> 

                            }
                        <Button disabled={this.state.loadData} className={["orangButton", classes.saveBtn]} variant="contained" color="primary" style={{margin:this.state.tab == 0 ? '42px 0px 10px 0px':'14px 0px'}}
                            onClick={() => this.state.tab == 0 ? this.toggleWarningModal("saveTargetOccupancy") : (this.state.tab == 1 ? this.updateTimeToBooking() : (this.state.tab ==3 ? this.updateCloseOutSales():this.state.tab ==2 ? this.UpdateAggressive():''))}>
                            {t("Save")}{this.state.loadData && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                        </Button>
                        </CardContent>
                      
                    </Card>
                   
                    {Warning.warningModal(this)}
                </div>
            </MuiThemeProvider>
            </>
        );
    }
}

TargetOccupancy.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        hotelAuthReducer: state.hotelAuthReducer,
        userSession: state.sessionReducer,
        roomList: state.roomListReducer,
        commonReducer: state.commonReducer,
        featuresReducer: state.featuresReducer,
        priceSettingReducer: state.priceSettingReducer,
        hotelDetailReducer: state.hotelSettingReducer,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateSettingJson: (request, callback, backdata) => { dispatch(actions.updateSettingJson(request, {}, 'targetOccupancy', callback, backdata)) },
        getPricingSettings: (request) => { dispatch(actions.getPricingSettings(request)) },
        putNotify: (request) => { dispatch(actions.putNotify(request)) },
        updatePricingSettings: (request) => { dispatch(actions.updatePricingSettings(request)) },
        updateHotel: (request) => { dispatch(actions.updateHotelSetting(request)) },
        getHotelDetail: (request) => { dispatch(actions.getHotelDetail(request)) },
        // setRunPriceFlags: (request) => { dispatch(actions.setRunPriceFlags(request)) },
    };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(TargetOccupancy));
