import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';

const onCurrencyStart = () => {
    return {
        type: actionTypes.CURRENCY_START
    }
}

const onCurrencyFailed = (error) => {
    return {
        type: actionTypes.CURRENCY_FAILED,
        error: error
    }
}

const onCurrencySucceed = (data) => {
    return {
        type: actionTypes.CURRENCY_SUCCEED,
        state: data
    }
}

export const currency = (request) => {
    return dispatch => {
        dispatch(onCurrencyStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'system/currency/list/')
            .then(function (response) {
                dispatch(onCurrencySucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onCurrencyFailed(error))
            });
    }
}