import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import fingerprint from 'fingerprintjs'
import jwt from 'jsonwebtoken';
import * as actions from '../index';
import Notify from '../Notify'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const onLoginStart = () => {
    return {
        type: actionTypes.LOGIN_START
    }
}

const onLoginFailed = (error) => {
    return {
        type: actionTypes.LOGIN_FAILED,
        error: error
    }
}

const onLoginSucceed = (data, is_otp_required=false) => {
    return {
        type: actionTypes.LOGIN_SUCCEED,
        state: data,
        is_otp_required: is_otp_required
    }
}

const onVerifyOTPSucceed = (data) => {
    return {
        type: actionTypes.VERIFIED_OTP_SUCCEED,
        state: data
    }
}

const storeUserData = (resp) => {
    let finderprint = new fingerprint().get();
    return jwt.sign(resp, finderprint.toString())
}

// export const onLogin = (request) => {
//     return dispatch => {
//         var localData = localStorage.getItem("userData");
//         if (localData) {
//             var localUserData = JSON.parse(JSON.parse(localStorage.getItem("userData")));
//             dispatch(onLoginSucceed(localUserData))
//         } else {
//             dispatch(onLoginStart())
//             axios.post(Server.API + 'account/auth/', request)
//                 .then(function (response) {
//                     dispatch(onLoginSucceed(response.data))
//                 })
//                 .catch(function (error) {
//                     dispatch(onLoginFailed())
//                 });
//         }
//     }
// }

export const directLogin = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'account/hotel_spider/user_data/')
            .then(function (response) { 
                response.data.token = request.token;
                    localStorage.setItem("__UserProps__", storeUserData(response.data))
                    localStorage.setItem("email", (response.data.user.email))
                    localStorage.setItem("name", (response.data.user.first_name))
                    dispatch(onLoginSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onLoginFailed(error))
            });
    }
}

export const resendOTP = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'account/two-factor-auth/resend_otp/')
            .then(function (response) { 
                dispatch(Notify.newUserModal(response.data.message, response.data.success))
            })
            .catch(function (error) {
                dispatch(onLoginFailed(error))
            });
    }
}

export const verifyOTP = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'account/two-factor-auth/verify_otp/', request)
            .then(function (response) {
                if(response.data.success) {
                    response.data.user_data.token = request.token
                    dispatch(updateStorageData(response.data.user_data))
                    dispatch(onVerifyOTPSucceed(response.data))
                    cookies.set('__userid_genie__'+response.data.user_data.user.id, request.token+"."+response.data.user_data.user.id, { expires: new Date(Date.now() + (30*24*3600000)), path: '/' });
                    dispatch(Notify.newUserModal(response.data.message, response.data.success))
                } else {
                    dispatch(onVerifyOTPSucceed(response.data))
                }
            })
            .catch(function (error) {
                dispatch(onLoginFailed(error))
            });
    }
}


export const onLogin = (request) => {
    return dispatch => {
        dispatch(onLoginStart())
        axios.post(Server.API + 'account/auth/', request)
            .then(function (response) {
                let url_string = window.location.href;
                let url = new URL(url_string);
                let code = url.searchParams.get("code");
                let state = url.searchParams.get("state");
                const client_id = url.searchParams.get('client_id');
                const response_type = url.searchParams.get('response_type');
                const redirect_uri = url.searchParams.get('redirect_uri');
                const states = url.searchParams.get('state');
                const scope = url.searchParams.get('scope');
                
                let is_otp_required = false
                let cookie_data = cookies.get('__userid_genie__'+response.data.user.id);

                if (client_id && redirect_uri) {
                    axios.post(Server.API + 'account/oauth2/validate/', { client_id, redirect_uri })
                        .then(function (resp) {
                            response.data.isValidated = resp.data.success;
                            // if((response.data.user.is_staff || response.data.user.is_superuser) && response.data.is_otp_required) {
                            if((response.data.user.is_staff || response.data.user.is_superuser) && cookie_data == null) {
                                dispatch(resendOTP(response.data))
                                is_otp_required = true
                            } else {
                                localStorage.setItem("__UserProps__", storeUserData(response.data))
                                localStorage.setItem("email", (response.data.user.email))
                                localStorage.setItem("name", (response.data.user.first_name))
                                localStorage.setItem("is_authorize_in_moodle", (response.data.user.is_authorize_in_moodle))
                            }
                            dispatch(onLoginSucceed(response.data, is_otp_required))
                        })
                } else {
                    // if((response.data.user.is_staff || response.data.user.is_superuser) && response.data.is_otp_required) {
                    if((response.data.user.is_staff || response.data.user.is_superuser) && cookie_data == null) {
                        dispatch(resendOTP(response.data))
                        is_otp_required = true
                    } else {
                        localStorage.setItem("__UserProps__", storeUserData(response.data))
                        localStorage.setItem("email", (response.data.user.email))
                        localStorage.setItem("name", (response.data.user.first_name))
                        localStorage.setItem("is_authorize_in_moodle", (response.data.user.is_authorize_in_moodle))
                    }
                    console.log('is_otp_required: ', is_otp_required)
                    dispatch(onLoginSucceed(response.data, is_otp_required))
                }
            })
            .catch(function (error) {
                dispatch(onLoginFailed(error))
            });
    }
}
export const freeToBookLogin = (response) => {
    return dispatch => {
        dispatch(onLoginStart())
        if((response.data.user.is_staff || response.data.user.is_superuser) && response.data.is_otp_required) {
        } else {
            localStorage.setItem("__UserProps__", storeUserData(response.data))
            localStorage.setItem("email", (response.data.user.email))
            localStorage.setItem("name", (response.data.user.first_name))
            localStorage.setItem("is_authorize_in_moodle", (response.data.user.is_authorize_in_moodle))
            window.location.href = "/client/PaymentSetting/" + response.data.hotel_list[0].id;
        }
        // dispatch(onLoginSucceed(response.data))
    }
}
export const updateStorageData = (request) => {
    return dispatch => {
        var credential = localStorage.getItem("__UserProps__")
        if(credential){
            let finderprint = new fingerprint().get();
            credential = jwt.verify(credential, finderprint.toString());
            if(request.user){
                request.user.is_staff = credential.user.is_staff 
            }            
        }

        localStorage.setItem("__UserProps__", storeUserData(request))
    }
}

const onBookingSuitTestStart = () => {
    return {
        type: actionTypes.BOOKINGSUIT_TEST_START,
        error: false
    }
}

const onBookingSuitTestFailed = (error) => {
    return {
        type: actionTypes.BOOKINGSUIT_TEST_FAILED,
        error: error
    }
}

const onBookingSuitTestSucceed = (data) => {
    return {
        type: actionTypes.BOOKINGSUIT_TEST_SUCCEED,
        state: data
    }
}

export const bookingSuitAuthTest = (request) => {
    return dispatch => {
        dispatch(onBookingSuitTestStart())
        axios.post(Server.API + 'account/booking-suite/auth-test/', request)
            .then(function (response) {
                localStorage.setItem("__UserProps__", storeUserData(response.data))
                localStorage.setItem("email", (response.data.user.email))
                localStorage.setItem("name", (response.data.user.first_name))
                dispatch(onBookingSuitTestSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onBookingSuitTestFailed(error))
            });
    }
}

export const moodleAuthAuthorize = (userToken) => {
    return dispatch => {
        let url_string = window.location.href;
        let url = new URL(url_string);
        
        let code = url.searchParams.get("code");
        let state = url.searchParams.get("state");
        const client_id = url.searchParams.get('client_id');
        const response_type = url.searchParams.get('response_type');
        const redirect_uri = url.searchParams.get('redirect_uri');
        const states = url.searchParams.get('state');
        const scope = url.searchParams.get('scope');

        fetch(Server.API + `account/oauth2authorizations/?client_id=${encodeURIComponent(client_id)}&response_type=${encodeURIComponent(response_type)}&redirect_uri=${encodeURIComponent(redirect_uri)}&state=${encodeURIComponent(state)}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: "Token " + userToken.token,
        },
        }).then((response) => response.json())
        .then((responseJson) => {
            if (userToken.user.is_authorize_in_moodle == false) {
                let requestData = {
                    is_authorize_in_moodle: true,
                }
                axios.defaults.headers.common['Authorization'] = "Token " + userToken.token;
                axios.patch(Server.API + 'account/update/', requestData)
                    .then(function (response) {
                        localStorage.setItem("is_authorize_in_moodle", response.data.is_authorize_in_moodle)
                        window.location.href = responseJson['redirect_uri'];
                })
            } else {
                window.location.href = responseJson['redirect_uri'];
            }
        })
        .catch((error) => {});
    }
}
