import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Chips from 'react-chips';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import CardHeader from "@material-ui/core/CardHeader";
import {MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import NativeSelect from '@material-ui/core/NativeSelect';
import FormHelperText from '@material-ui/core/FormHelperText';
import DateFnsUtils from '@date-io/date-fns';
import ReactJson from 'react-json-view';
import moment from 'moment';
import { CalendardateTheme, scheduleHistoryTheme } from "../../muiTheme";
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';

export default {
    newUserModal: function (modalData) {
        const { classes } = modalData.props;
        return (<Dialog
            fullScreen={false}
            maxWidth='md'
            open={modalData.state.newUserModal}
            onClose={modalData.toggleAddUser}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">Add new Admin User</DialogTitle>
            <DialogContent>
                <FormControl margin="normal" required className={classes.currentPriceInput}>
                    <TextField
                        error={modalData.state.error.includes("first_name")}
                        id="standard-name"
                        label="First Name"
                        margin="normal"
                        value={modalData.state.first_name}
                        onChange={modalData.handleChange('first_name')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {modalData.state.error.includes("first_name") ? <span class='error-message'>{'* Please enter first name'}</span> : ''}
                </FormControl>
                <FormControl margin="normal" required className={classes.currentPriceInput}>
                    <TextField
                        error={modalData.state.error.includes("last_name")}
                        id="standard-name"
                        label="Last Name"
                        margin="normal"
                        value={modalData.state.last_name}
                        onChange={modalData.handleChange('last_name')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {modalData.state.error.includes("last_name") ? <span class='error-message'>{'* Please enter last name'}</span> : ''}
                </FormControl>
                <FormControl margin="normal" required className={classes.currentPriceInput}>
                    <TextField
                        error={modalData.state.error.includes("userName")}
                        id="standard-name"
                        label="Email"
                        margin="normal"
                        value={modalData.state.userName}
                        onChange={modalData.handleChange('userName')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {modalData.state.error.includes("userName") ? <span class='error-message'>{'* Please enter email'}</span> : ''}
                </FormControl>
                <FormControl margin="normal" required className={classes.currentPriceInput}>
                    <TextField
                        error={modalData.state.error.includes("pass")}
                        id="standard-name"
                        label="Password"
                        margin="normal"
                        value={modalData.state.pass}
                        onChange={modalData.handleChange('pass')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {modalData.state.error.includes("pass") ? <span class='error-message'>{'* Please enter password'}</span> : ''}
                </FormControl>

            </DialogContent>
            <DialogActions>
                <Checkbox
                    value={modalData.state.is_sales}
                    onChange={modalData.handleChange('is_sales')}
                    color="primary"
                    style={{ marginLeft: '-10px' }}
                />
                <Typography style={{ marginRight: '40px', marginLeft: '-8px' }}>
                    Add this user to sales team
                </Typography>
                </DialogActions>
                <DialogActions>
                <Button onClick={modalData.addNewAdmin} variant="contained" color="primary" >
                    Save
                </Button>
                <Button onClick={modalData.toggleAddUser} className="cancelBtn">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>)
    },
    deleteUserModal: function (modalData) {
        //const { classes } = modalData.props;
        const { fullScreen } = modalData.props;
        return (<Dialog
            fullScreen={fullScreen}
            maxWidth='md'
            open={modalData.state.deleteUserModal}
            onClose={modalData.toggleDeleteUser}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">Delete User</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this user?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button  variant="contained"  className="dangerBtnHvr" onClick={modalData.deleteUser} color="secondary" autoFocus>
                    Delete
                </Button>
                <Button onClick={modalData.toggleDeleteUser} className="cancelBtn">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>)
    },
    updateUserModal: function (modalData) {
        const { classes } = modalData.props;
        return (<Dialog
            fullScreen={false}
            maxWidth='md'
            open={modalData.state.updateUserModal}
            onClose={modalData.toggleUpdateUser}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">Update Admin User</DialogTitle>
            <DialogContent>
                <div>
                    <TextField
                        error={modalData.state.error.includes("edit_first_name")}
                        id="standard-name"
                        label="First Name"
                        margin="normal"
                        value={modalData.state.edit_first_name}
                        onChange={modalData.handleChange('edit_first_name')}
                        className={classes.currentPriceInput}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        error={modalData.state.error.includes("edit_last_name")}
                        id="standard-name"
                        label="Last Name"
                        margin="normal"
                        value={modalData.state.edit_last_name}
                        onChange={modalData.handleChange('edit_last_name')}
                        className={classes.currentPriceInput}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        error={modalData.state.error.includes("edit_userName")}
                        id="standard-name"
                        label="Email"
                        margin="normal"
                        value={modalData.state.edit_userName}
                        onChange={modalData.handleChange('edit_userName')}
                        className={classes.currentPriceInput}
                        style={{ marginTop: "15px" }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Checkbox
                    value={modalData.state.is_sales}
                    onChange={modalData.handleCheckChange('is_sales')}
                    color="primary"
                    style={{ marginLeft: '-10px' }}
                    checked={modalData.state.is_sales}
                />
                <Typography style={{ marginRight: '40px', marginLeft: '-8px' }}>
                    Add this user to sales team
                </Typography>  
            </DialogActions>
            <DialogActions>
                <Button onClick={modalData.addUpdateAdmin} variant="contained" color="primary" autoFocus>
                    Save
                </Button>
                <Button className="cancelBtn" onClick={modalData.toggleUpdateUser}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>)
    },
    addProgramModal: function (modalData) {
        const { classes } = modalData.props;

        var hours = [];
        for (var i = 0; i <= 23; i++) {
            hours.push(<MenuItem key={i} value={i}>
                {('0' + i).slice(-2)}
            </MenuItem>);
        }

        var minutes = [];
        for (var j = 0; j < 60; j++) {
            minutes.push(<MenuItem key={j} value={j}>
                {('0' + j).slice(-2)}
            </MenuItem>);
        }

        return (<Dialog
            fullScreen={false}
            maxWidth='sm'
            open={modalData.state.showAddModal}
            onClose={modalData.toggleAddProgram}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">Add New Program</DialogTitle>
            <DialogContent>
                <FormControl margin="normal" required className={classes.modalInputFull}>
                    <TextField
                        id="standard-name"
                        label="Name"
                        value={modalData.state.addModalData.name}
                        onChange={modalData.handleNewProgram('name')}
                        error={modalData.state.error.includes("name")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {modalData.state.error.includes("name") ? <span class='error-message'>{'* Please enter name'}</span> : ''}
                </FormControl>
                <FormControl margin="normal" required className={classes.modalInputLeft}>
                    <TextField
                        select
                        id="standard-name"
                        label="Hour"
                        value={modalData.state.addModalData.hour}
                        onChange={modalData.handleNewProgram('hour')}
                        error={modalData.state.error.includes("hour")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    >
                        {hours}
                    </TextField>
                    {modalData.state.error.includes("hour") ? <span class='error-message'>{'* Please enter hour'}</span> : ''}
                </FormControl>
                <FormControl margin="normal" required className={classes.modalInputRight}>
                    <TextField
                        select
                        id="standard-name"
                        label="Minutes"
                        value={modalData.state.addModalData.minute}
                        onChange={modalData.handleNewProgram('minute')}
                        error={modalData.state.error.includes("minute")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    >
                        {minutes}
                    </TextField>
                    {modalData.state.error.includes("hour") ? <span class='error-message'>{'* Please enter hour'}</span> : ''}
                </FormControl>
                <FormControl margin="normal" required className={classes.modalInputFull}>
                    <Typography variant="body1" gutterBottom className={classes.fieldTitle}>
                        Please hit enter to save day<sup>*</sup>
                    </Typography>
                    <Chips
                        value={modalData.state.addModalData.days}
                        onChange={(val) => modalData.handleNewDays(val)}
                        fromSuggestionsOnly={false}
                        createChipKeys={[9, 13]}
                    />
                    {modalData.state.error.includes("days") ? <span class='error-message'>{'* Please enter days'}</span> : ''}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={modalData.addNewProgram} variant="contained" color="primary" >
                    Save
                </Button>
                <Button onClick={modalData.closeAddProgram} className="cancelBtn">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>)
    },
    updateProgramModal: function (modalData) {
        const { classes } = modalData.props;

        var hours = [];
        for (var i = 0; i <= 23; i++) {
            hours.push(<MenuItem key={i} value={i}>
                {('0' + i).slice(-2)}
            </MenuItem>);
        }

        var minutes = [];
        for (var j = 0; j < 60; j++) {
            minutes.push(<MenuItem key={j} value={j}>
                {('0' + j).slice(-2)}
            </MenuItem>);
        }

        return (<Dialog
            fullScreen={false}
            maxWidth='sm'
            open={modalData.state.showEditModal}
            onClose={modalData.closeEditProgram}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">Update Program</DialogTitle>
            {modalData.state.editModalData &&
                <DialogContent>
                    <div>
                        <FormControl margin="normal" required className={classes.modalInputFull}>
                            <TextField
                                id="standard-name"
                                label="Name"
                                margin="normal"
                                value={modalData.state.editModalData.name}
                                onChange={modalData.handleEditProgram('name')}
                                error={modalData.state.error.includes("edit_name")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            {modalData.state.error.includes("edit_name") ? <span class='error-message'>{'* Please enter name'}</span> : ''}
                        </FormControl>
                        <FormControl margin="normal" required className={classes.modalInputLeft}>
                            <TextField
                                select
                                id="standard-name"
                                label="Hour"
                                margin="normal"
                                value={modalData.state.editModalData.hour}
                                onChange={modalData.handleEditProgram('hour')}
                                error={modalData.state.error.includes("edit_hour")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                                {hours}
                            </TextField>
                            {modalData.state.error.includes("edit_hour") ? <span class='error-message'>{'* Please enter hour'}</span> : ''}
                        </FormControl>

                        <FormControl margin="normal" required className={classes.modalInputRight}>
                            <TextField
                                select
                                id="standard-name"
                                label="Minutes"
                                margin="normal"
                                value={modalData.state.editModalData.minute}
                                onChange={modalData.handleEditProgram('minute')}
                                error={modalData.state.error.includes("edit_minute")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                                {minutes}
                            </TextField>
                            {modalData.state.error.includes("edit_minute") ? <span class='error-message'>{'* Please enter minute'}</span> : ''}
                        </FormControl>
                        <FormControl margin="normal" required className={classes.modalInputFull}>
                            <Typography variant="body1" gutterBottom className={classes.fieldTitle}>
                                Please hit enter to save day
                                </Typography>
                            <Chips
                                value={modalData.state.editModalData.daysArray}
                                uniqueChips={true}
                                onChange={(val) => modalData.DateDayChange(val)}
                                createChipKeys={[9, 13]}
                            />
                            {modalData.state.error.includes("edit_days") ? <span class='error-message'>{'* Please enter days'}</span> : ''}
                        </FormControl>
                    </div>
                </DialogContent>
            }
            <DialogActions>
               
                <Button onClick={modalData.EditProgram} className={classes.currentPriceInputBtn} variant="contained" color="primary" >
                    Save
                </Button>
                <Button onClick={modalData.closeEditProgram} className="cancelBtn" >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>)
    },
    deleteProgramModal: function (modalData) {
        //const { classes } = modalData.props;
        const { fullScreen } = modalData.props;
        return (<Dialog
            fullScreen={fullScreen}
            maxWidth='md'
            open={modalData.state.showDeleteModal}
            onClose={modalData.closeDeleteProgram}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">Delete Scrap Program</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this program?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
               
                <Button variant="contained"  className="dangerBtnHvr" onClick={modalData.deleteProgram} color="secondary" autoFocus>
                    Delete
                </Button>
                <Button onClick={modalData.closeDeleteProgram} className="cancelBtn" >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>)
    },
    deleteProgramList: function (modalData) {
        const { fullScreen } = modalData.props;
        return (<Dialog
            fullScreen={fullScreen}
            maxWidth='md'
            open={modalData.state.showDelete}
            onClose={modalData.closeDelete}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">Delete Hotel OTA</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this Hotel OTA?
                </DialogContentText>
            </DialogContent>
            <DialogActions>               
                <Button onClick={modalData.deleteList} color="secondary" autoFocus variant="contained"  className="dangerBtnHvr">
                Delete
                </Button>
                <Button onClick={modalData.closeDelete} className="cancelBtn" >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>)
    },
    ErrorModal: function (modalData) {
        const { fullScreen } = modalData.props;
        return (<Dialog
            fullScreen={fullScreen}
            maxWidth='md'
            open={modalData.state.showError}
            onClose={modalData.toggleErrorModal}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">Sorry!</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {modalData.state.error}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={modalData.toggleErrorModal} color="primary" >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>)
    },
    addNewUrl: function (modalData) {
        const { fullScreen } = modalData.props;
        return (<Dialog
            fullScreen={fullScreen}
            maxWidth='md'
            open={modalData.state.openModal}
            onClose={modalData.toggleModal}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">Scrape New Hotel</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormControl margin="normal">
                        <TextField
                            id="standard-name"
                            label="Booking.com url"
                            margin="normal"
                            value={modalData.state.url}
                            onChange={modalData.handleUrl()}
                            error={modalData.state.error}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                        </TextField>
                        {modalData.state.error ? <span class='error-message'>{'* Please enter valid url'}</span> : ''}
                    </FormControl>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                
                <Button variant="contained" onClick={modalData.addScraper} color="primary" >
                    scrape
                </Button>
                <Button variant="outlined" onClick={modalData.toggleModal} className="cancelBtn" >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog >)
    },
    newResellerModal: function (modalData) {
        const { classes } = modalData.props;
        return (<Dialog
            fullScreen={false}
            maxWidth='md'
            open={modalData.state.newUserModal}
            onClose={modalData.toggleAddUser}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">Add new Partner</DialogTitle>
            <DialogContent>
                <FormControl margin="normal" required className={classes.currentPriceInput}>
                    <TextField
                        error={modalData.state.error.includes("name")}
                        id="standard-name"
                        label="Name"
                        margin="normal"
                        value={modalData.state.name}
                        onChange={modalData.handleChange('name')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {modalData.state.error.includes("name") ? <span class='error-message'>{'* Please enter first name'}</span> : ''}
                </FormControl>
                <FormControl margin="normal" required className={classes.currentPriceInput}>
                    <TextField
                        error={modalData.state.error.includes("userName")}
                        id="standard-name"
                        label="Email"
                        margin="normal"
                        value={modalData.state.userName}
                        onChange={modalData.handleChange('userName')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {modalData.state.error.includes("userName") ? <span class='error-message'>{'* Please enter email'}</span> : ''}
                </FormControl>
            </DialogContent>
            <DialogActions>
               
                <Button onClick={modalData.addNewAdmin} variant="contained" color="primary" >
                    Save
                </Button>
                <Button onClick={modalData.toggleAddUser} className="cancelBtn" >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>)
    },
    deleteResellerModal: function (modalData) {
        const { fullScreen } = modalData.props;
        return (<Dialog
            fullScreen={fullScreen}
            maxWidth='md'
            open={modalData.state.deleteUserModal}
            onClose={modalData.toggleDeleteUser}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">Delete User</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this user?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
             
                <Button onClick={modalData.deleteUser} color="secondary" autoFocus variant="contained"  className="dangerBtnHvr">
                    Delete
                </Button>
                <Button onClick={modalData.toggleDeleteUser} className="cancelBtn">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>)
    },
    updateResellerModal: function (modalData) {
        const { classes } = modalData.props;
        return (<Dialog
            fullScreen={false}
            maxWidth='md'
            open={modalData.state.updateResellerModal}
            onClose={modalData.toggleUpdateUser}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">Update Partner</DialogTitle>
            <DialogContent>
                <div>
                    <TextField
                        error={modalData.state.error.includes("edit_name")}
                        id="standard-name"
                        label="Name"
                        margin="normal"
                        value={modalData.state.edit_name}
                        onChange={modalData.handleChange('edit_name')}
                        className={classes.currentPriceInput}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        error={modalData.state.error.includes("edit_userName")}
                        id="standard-name"
                        label="Email"
                        margin="normal"
                        value={modalData.state.edit_userName}
                        onChange={modalData.handleChange('edit_userName')}
                        className={classes.currentPriceInput}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions>
               
                <Button onClick={modalData.addUpdateAdmin} variant="contained" color="primary" autoFocus>
                    Save
                </Button>
                <Button className="cancelBtn"  onClick={modalData.toggleUpdateUser} color="primary"  >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>)
    },
    newResellerMemberModal: function (modalData) {
        const { classes } = modalData.props;
        return (<Dialog
            fullScreen={false}
            maxWidth='md'
            open={modalData.state.newMemberModal}
            onClose={modalData.toggleAddMember}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">Add new Partner User</DialogTitle>
            <DialogContent>
                <form>
                    <FormControl >
                        <TextField
                            id="standard-name"
                            label="Email"
                            margin="normal"
                            style={{ position: 'absolute', zIndex: '-1' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            type="password"
                            id="standard-name"
                            label="Password"
                            style={{ position: 'absolute', zIndex: '-1' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                    <FormControl margin="normal" required className={classes.currentPriceInput}>
                        <TextField
                            error={modalData.state.error.includes("first_name")}
                            id="standard-name"
                            label="Name"
                            margin="normal"
                            value={modalData.state.memberData.first_name}
                            onChange={modalData.memberHandleChange('first_name')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {modalData.state.error.includes("first_name") ? <span class='error-message'>{'* Please enter first name'}</span> : ''}
                    </FormControl>
                    <FormControl margin="normal" required className={classes.currentPriceInput}>
                        <TextField
                            error={modalData.state.error.includes("last_name")}
                            id="standard-name"
                            label="Last Name"
                            margin="normal"
                            value={modalData.state.memberData.last_name}
                            onChange={modalData.memberHandleChange('last_name')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {modalData.state.error.includes("last_name") ? <span class='error-message'>{'* Please enter last name'}</span> : ''}
                    </FormControl>
                    <FormControl margin="normal" required className={classes.currentPriceInput}>
                        <TextField
                            error={modalData.state.error.includes("email")}
                            id="standard-name"
                            label="Email"
                            margin="normal"
                            value={modalData.state.memberData.userName}
                            onChange={modalData.memberHandleChange('email')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {modalData.state.error.includes("email") ? <span class='error-message'>{'* Please enter valid email'}</span> : ''}
                    </FormControl>
                    <FormControl margin="normal" required className={classes.currentPriceInput}>
                        <TextField
                            type="password"
                            error={modalData.state.error.includes("password")}
                            id="standard-name"
                            label="Password"
                            margin="normal"
                            value={modalData.state.memberData.pass}
                            onChange={modalData.memberHandleChange('password')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {modalData.state.error.includes("password") ? <span class='error-message'>{'* Please enter password'}</span> : ''}
                    </FormControl>
                    {modalData.props.userSession.user.is_staff &&
                        <FormControl margin="normal" required className={classes.currentPriceInput} style={{ marginTop: "15px" }}>
                            <InputLabel htmlFor="name" shrink={true}>Partner</InputLabel>
                            <Select
                                value={modalData.state.reseller}
                                onChange={modalData.handleChange('reseller')}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                            >
                                <MenuItem key={-1} value=''>-</MenuItem>
                                {modalData.state?.allMemberList.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {modalData.state.error.includes("reseller") ? <span class='error-message'>{'* Please enter Partner'}</span> : ''}
                        </FormControl>
                    }
                </form>
            </DialogContent>
            <DialogActions>
               
                <Button onClick={modalData.addNewMember} variant="contained" color="primary" >
                    Save
                </Button>
                <Button onClick={modalData.toggleAddMember} className="cancelBtn" >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>)
    },
    editResellerMemberModal: function (modalData) {
        const { classes } = modalData.props;        
        return (<Dialog
            fullScreen={false}
            maxWidth='md'
            open={modalData.state.editMemberModal}
            onClose={modalData.toggleEditMember}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">Update Partner User</DialogTitle>
            <DialogContent>
                <FormControl margin="normal" required className={classes.currentPriceInput}>
                    <TextField
                        error={modalData.state.error.includes("first_name")}
                        id="standard-name"
                        label="First Name"
                        margin="normal"
                        value={modalData.state.editMember.first_name}
                        onChange={modalData.editMemberChange('first_name')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {modalData.state.error.includes("first_name") ? <span class='error-message'>{'* Please enter first name'}</span> : ''}
                </FormControl>
                <FormControl margin="normal" required className={classes.currentPriceInput}>
                    <TextField
                        error={modalData.state.error.includes("last_name")}
                        id="standard-name"
                        label="Last Name"
                        margin="normal"
                        value={modalData.state.editMember.last_name}
                        onChange={modalData.editMemberChange('last_name')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {modalData.state.error.includes("last_name") ? <span class='error-message'>{'* Please enter last name'}</span> : ''}
                </FormControl>
                <FormControl margin="normal" required className={classes.currentPriceInput}>
                    <TextField
                        error={modalData.state.error.includes("email")}
                        id="standard-name"
                        label="Email"
                        margin="normal"
                        value={modalData.state.editMember.email}
                        onChange={modalData.editMemberChange('email')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {modalData.state.error.includes("email") ? <span class='error-message'>{'* Please enter email'}</span> : ''}
                </FormControl>
                {modalData.props.userSession.user.is_staff &&
                    <FormControl margin="normal" required className={classes.currentPriceInput} style={{ margin: "17px 0px" }}>
                        <InputLabel htmlFor="name">Partner</InputLabel>
                        <Select
                            disabled
                            margin="normal"
                            value={modalData.state.reseller}
                            onChange={modalData.handleChange('reseller')}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                        >
                            <MenuItem key={-1} value=''>-</MenuItem>
                            {modalData.state?.allMemberList.map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                }
            </DialogContent>
            <DialogActions>            
                <Button onClick={modalData.editMember} variant="contained" color="primary" >
                    Save
                </Button>
                <Button onClick={modalData.toggleEditMember} className="cancelBtn" >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>)
    },
    deleteResellerMemberModal: function (modalData) {
        const { fullScreen } = modalData.props;
        return (<Dialog
            fullScreen={fullScreen}
            maxWidth='md'
            open={modalData.state.deleteMemberModal}
            onClose={modalData.toggleDeleteMember}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">Delete Partner User</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this User?
                </DialogContentText>
            </DialogContent>
            <DialogActions>               
            <Button  variant="contained"  className="dangerBtnHvr" onClick={modalData.deleteMember} color="secondary" autoFocus>
                    Delete
                </Button>
                <Button onClick={modalData.toggleDeleteMember} className="cancelBtn" >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>)
    },
    scheduleHistoryDetails: function (modalData) {
        const { fullScreen } = modalData.props;
        const statusDataArray = [
            { label: 'Scheduled', value: 1},
            { label: 'In Progress', value: 2},
            { label: 'Done', value: 3},
        ]
        const columns = [
            { name: "Name",
                options: {
                    filter: false,
                }
            },
            { 
                name: "Hotel List",
                options: {
                    filter: false,
                }
        
            },
            { 
                name: "Start Time",
                options: {
                    filter: false,
                }
            },
            { name: "End Time",
                options: {
                    filter: false,
                } 
            },
            { name: "Part",
                options: {
                    filter: false,
                }
            },
            { 
                name: "Scaper Version" ,
                options: {
                    filter: false,
                }
            },
            {
                name: "Record Scraped" ,
                options: {
                    filter: false,
                }
            },
            { name: "Status",
                options: {
                    filter: true,
                    filterOptions: statusDataArray.map(row => { return row.label }),
                }
            },
        ];
        return (

            <Dialog
                fullScreen={fullScreen}
                maxWidth='lg'
                open={modalData.state.showScheduleHistoryDetails}
                onClose={modalData.closeScheduleHistoryDetails}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <DialogContentText>
                        <Card>
                            <CardHeader
                                className="headig-top"
                                title="Schedule History Details">
                            </CardHeader>
                            <CardContent>
                            <MuiThemeProvider theme={scheduleHistoryTheme}>
                                <MUIDataTable
                                    title={''}
                                    data={modalData.renderScrapDetailsData(modalData.state.scheduleDetails.results)}
                                    columns={columns}
                                    options={modalData.optionsDetails()}
                                />
                            </MuiThemeProvider>
                            </CardContent>
                        </Card >
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={modalData.closeScheduleHistoryDetails} className="cancelBtn" >
                        Cancel
                </Button>
                   
                </DialogActions>
            </Dialog>)
    },
    errorTicketsModal: function (modalData) {
        const { classes } = modalData.props;
        var jira_ticket_url = "https://roompricegenie.atlassian.net/secure/CreateIssueDetails!init.jspa?issuetype=10009&pid=10015"+"&summary=Hotel Id : " + modalData.state.hotel_id + " - " + modalData.state.pms_name + " - " + "&description=" + "Hotel ID : " + modalData.state.hotel_id + "\\Hotel Name : " + modalData.state.hotel_name + "\\Responsible Person : " + modalData.state.responsible_person + "\\Reseller : " + modalData.state.reseller + "\\PMS/CM Name : " + modalData.state.pms_name
        return (<Dialog
                maxWidth='md'
                open={modalData.state.showerrorTicket}
                onClose={modalData.toggleErrorTicketModal}
                aria-labelledby="responsive-dialog-title"
                >
                <DialogTitle id="responsive-dialog-title">
                    Edit Error Ticket
                    <FormControl style={{float: "right"}} className={classes.formControl}>
                        <NativeSelect
                            className={classes.selectEmpty}
                            value={modalData.state.status}
                            name="status"
                            onChange={modalData.handleChangeTicketStatusModal}
                            inputProps={{ 'aria-label': 'Status' }}
                            style={{width: '100px', fontSize: '15px'}}
                        >
                        <option value="1">Open</option>
                        <option value="2">Closed</option> 
                        </NativeSelect>
                        <FormHelperText>Status</FormHelperText>
                    </FormControl>
                    <FormControl style={{float: "right", marginLeft: "40px"}}>
                        <NativeSelect
                            className={classes.selectEmpty}
                            value={modalData.state.developer_modal}
                            name="developer_modal"
                            onChange={modalData.handleChangeDeveloperModal}
                            inputProps={{ 'aria-label': 'Developer' }}
                            style={{width: '150px', fontSize: '15px'}}
                        >
                        <option value="notassigned">Not Assigned</option>
                        <option value="aji">Aji</option>
                        <option value="bagas">Bagas</option>
                        <option value="chirag">Chirag</option>
                        <option value="dipak">Dipak</option>
                        <option value="hendrik">Hendrik</option>
                        <option value="mumtoz">Mumtoz</option>
                        <option value="nilay">Nilay</option>
                        <option value="niam">Niam</option>
                        <option value="parth">Parth</option>
                        <option value="rajesh">Rajesh</option>
                        <option value="wahid">Wahid</option>
                        </NativeSelect>
                        <FormHelperText>Developer</FormHelperText>
                    </FormControl>
                </DialogTitle>
                <DialogContent>
                    <Typography style={{marginBottom:'5px'}}><span style={{fontWeight:'500'}}>Hotel ID:</span> {modalData.state.hotel_id}</Typography>
                    <Typography style={{marginBottom:'5px'}}><span style={{fontWeight:'500'}}>Code:</span> {modalData.state.code}</Typography>
                    <Typography style={{marginBottom:'5px'}}><span style={{fontWeight:'500'}}>Created Date:</span> {modalData.state.create_date}</Typography>
                    <Typography style={{marginBottom:'5px'}}><span style={{fontWeight:'500'}}>Datadog URL:</span> <a href={modalData.state.datadog_url} target='_blank'>{modalData.state.datadog_url}</a></Typography>
                    <Typography style={{marginBottom:'5px'}}><span style={{fontWeight:'500'}}>Hotel Name:</span> {modalData.state.hotel_name}</Typography>
                    <Typography style={{marginBottom:'5px'}}><span style={{fontWeight:'500'}}>Responsible Person:</span> {modalData.state.responsible_person}</Typography>
                    <Typography style={{marginBottom:'5px'}}><span style={{fontWeight:'500'}}>Reseller:</span> {modalData.state.reseller}</Typography>
                    <Typography style={{marginBottom:'5px'}}><span style={{fontWeight:'500'}}>PMS/CM Name:</span> {modalData.state.pms_name}</Typography>
                    <Typography style={{marginBottom:'5px'}}><span style={{fontWeight:'500'}}>Tags:</span> {modalData.state.tag_ml}</Typography>
                    <Typography style={{marginBottom:'5px'}}><span style={{fontWeight:'500'}}>Count:</span> {modalData.state.total_count}</Typography>
                    <Typography style={{marginBottom:'5px'}}><span style={{fontWeight:'500'}}>Modified Date:</span> {modalData.state.modified_date ? moment(modalData.state.modified_date).format('YYYY-MM-DD HH:mm') : "-"}</Typography>
                    <Typography style={{marginBottom:'5px'}}><span style={{fontWeight:'500'}}>Ticket Created:</span> {modalData.state.time_difference}</Typography>
                    <Typography style={{fontWeight:'500'}}>Error Message : </Typography>
                    <DialogContentText id="alert-dialog-slide-description">
                        {(["Number of rooms doesn't match availability",'Outdated Inventory','General', 'Service (V2)', 'Webhook (V2)', 'Broker (V2)', 'Mapping (V2)', 'Client Request (V2)', 'Protel', 'Upload Price', 'Room Raccoon', 'Missing Inventory', 'Inventory Invalid'].includes(modalData.state.tag_ml)) ? <ReactJson src={typeof modalData.state.error_message === 'string' ?  JSON.parse(modalData.state.error_message) : modalData.state.error_message} name="error_message" enableClipboard={false} collapsed={true} /> :
                          <ReactJson src={typeof modalData.state.error_message === 'string' ?  JSON.parse(modalData.state.error_message) : modalData.state.error_message} name="error_message" enableClipboard={false} collapsed={true} /> }
                    </DialogContentText>
                    <Typography style={{fontWeight:'500'}}>Webhook RS Error Info : </Typography>
                    <DialogContentText id="alert-dialog-slide-description">
                        {(['General', 'Service (V2)', 'Webhook (V2)', 'Broker (V2)', 'Mapping (V2)', 'Client Request (V2)', 'Webhook RS error'].includes(modalData.state.tag_ml)) ? <ReactJson src={typeof modalData.state.extra_info === 'string' ? JSON.parse(modalData.state.extra_info) : modalData.state.extra_info} name="error_message" enableClipboard={false} collapsed={true}  /> : modalData.state.extra_info}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                  
                    <Button href={jira_ticket_url} target="_blank" variant="outlined" color="secondary">
                        Create Jira Ticket
                    </Button>
                    <Button onClick={modalData.updateSingleErrorTicket} variant="contained" color="primary" >
                        Save
                    </Button>
                    <Button onClick={modalData.toggleErrorTicketModal} className="cancelBtn" >
                        Cancel
                    </Button>
                    <Button onClick={()=>window.open("client/Calendar/" + modalData.state.hotel_id, '_blank')} variant="contained" color="primary" >
                       View Hotel
                    </Button>
             
                </DialogActions>
        </Dialog>)
    },

    errorTicketsMultipleUpdateModal: function (modalData) {
        const { classes } = modalData.props;
        return (<Dialog
                maxWidth='md'
                open={modalData.state.show_multiple_update_modal}
                onClose={modalData.toggleMultipleErrorTicketModal}
                aria-labelledby="responsive-dialog-title"
                >
                <DialogTitle id="responsive-dialog-title">
                    Edit Multiple Error Ticket
                </DialogTitle>
                <DialogContent>
                    <FormControl style={{float: "right"}} className={classes.formControl}>
                            <NativeSelect
                                className={classes.selectEmpty}
                                value={modalData.state.ml_status}
                                name="ml_status"
                                onChange={modalData.handleChangeTicketStatusModalMultiple}
                                inputProps={{ 'aria-label': 'Status' }}
                                style={{width: '100px', fontSize: '15px'}}
                            >
                            <option value="1">Open</option>
                            <option value="2">Closed</option>
                            </NativeSelect>
                            <FormHelperText>Status</FormHelperText>
                        </FormControl>
                        <FormControl style={{float: "right"}}>
                            <NativeSelect
                                className={classes.selectEmpty}
                                value={modalData.state.ml_developer}
                                name="ml_developer"
                                onChange={modalData.handleChangeDeveloperModalMultiple}
                                inputProps={{ 'aria-label': 'Developer' }}
                                style={{width: '150px', fontSize: '15px'}}
                            >
                            <option value="notassigned">Not Assigned</option>
                            <option value="aji">Aji</option>
                            <option value="bagas">Bagas</option>
                            <option value="chirag">Chirag</option>
                            <option value="dipak">Dipak</option>
                            <option value="hendrik">Hendrik</option>
                            <option value="mumtoz">Mumtoz</option>
                            <option value="nilay">Nilay</option>
                            <option value="niam">Niam</option>
                            <option value="parth">Parth</option>
                            <option value="rajesh">Rajesh</option>
                            <option value="wahid">Wahid</option>
                            </NativeSelect>
                            <FormHelperText>Developer</FormHelperText>
                        </FormControl>
                </DialogContent>
                <DialogActions>
                   
                    <Button onClick={modalData.updateMultipleErrorTicket} variant="contained" color="primary" >
                        Save
                    </Button>
                    <Button onClick={modalData.toggleMultipleErrorTicketModal} className="cancelBtn">
                        Cancel
                    </Button>
                </DialogActions>
        </Dialog>)
    },
    dateFilterRangeModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        return (<Dialog
            fullScreen={fullScreen}
            transitionDuration={{ enter: 500, exit: 300 }}
            maxWidth='md'
            open={modalData.state.dateFilter || false}
            onClose={modalData.handlefilterClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t("Search")}</DialogTitle>
            <DialogContent>
            <MuiThemeProvider theme={CalendardateTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        margin="normal"
                        label={t("From Date")}
                        value={modalData.state.start_date}
                        onChange={modalData.changeDate('start_date')}
                        className={classes.textField}
                        format={"dd/MM/yyyy"}
                        autoOk={true}
                        style={{margin:"10px"}}
                    />
                    <DatePicker
                        margin="normal"
                        label={t("To Date")}
                        value={modalData.state.end_date}
                        onChange={modalData.changeDate('end_date')}
                        className={classes.textField}
                        format={"dd/MM/yyyy"}
                        autoOk={true}
                        style={{margin:"10px"}}
                    />
                </MuiPickersUtilsProvider>  
                </MuiThemeProvider>            
            </DialogContent>
            <DialogActions>
            <Button  className={classes.currentPriceInputBtn} variant="contained" color="primary"  onClick={modalData.handlefilterDate}>
                    {t("Search")}
                </Button>
                <Button   className="cancelBtn"  onClick={()=>{
                    modalData.setState({
                        dateFilter:false
                    })
                }}>
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>)
    },
}
