import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../index';
import Notify from '../Notify';

const getRoomTypeListSucceed = (data) => {
    return {
        type: actionTypes.ROOM_TYPE_LIST_SUCCESS,
        state: data
    }
}

export const getRoomTypeList = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'hotel/pms/roomtype/list/')
            .then(function (response) {
                dispatch(getRoomTypeListSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while creating Room", false))
            });
    }
}

const getRoomRateListSucceed = (data) => {
    return {
        type: actionTypes.ROOM_RATE_LIST_SUCCESS,
        state: data
    }
}

export const getRoomRateList = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'hotel/pms/roomrate/list/')
            .then(function (response) {
                dispatch(getRoomRateListSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while creating Room", false))
            });
    }
}

export const createRoomType = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/pms/roomtype/register/', request)
            .then(function (response) {
                dispatch(actions.getRoomTypeList(request))
                dispatch(Notify.newUserModal("Room Type Created Successfully", true))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while creating Room Type", false))
            });
    }
}

export const createRoomRate = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/pms/roomrate/register/', request)
            .then(function (response) {
                dispatch(actions.getRoomRateList(request))
                dispatch(Notify.newUserModal("Room Rate Created Successfully", true))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while creating Room Rate", false))
            });
    }
}

export const updateRoomType = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.put(Server.API + 'hotel/pms/roomtype/update/' + request.id + '/', request)
            .then(function (response) {
                // if(request.isOccupancyChanged){
                //     dispatch(actions.updateSettingJson(request, response.data, 'UpdateRoomType'))
                // }
                dispatch(actions.getRoomTypeList(request))
                dispatch(Notify.newUserModal("Room Type Updated Successfully", true))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while updating Room Type", false))
            });
    }
}

export const updateRoomRate = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/pms/roomrate/update/', request)
            .then(function (response) {
                dispatch(actions.getRoomRateList(request))
                dispatch(Notify.newUserModal("Room Rate Updated Successfully", true))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while updating Room Rate", false))
            });
    }
}
export const updateRoomRateProtel = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + `hotel/pms/roomrate/independent/update/${request.id}/`, request)
            .then(function (response) {
                dispatch(actions.getRoomRateList(request))
                dispatch(Notify.newUserModal("Room Rate Updated Succesfully", true))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while updating Room Rate", false))
            });
    }
}
export const deleteRoomType = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.delete(Server.API + 'hotel/pms/roomtype/delete/' + request.id + '/')
            .then(function (response) {
                dispatch(actions.getRoomTypeList(request))
                dispatch(Notify.newUserModal("Room Type Deleted Successfully", true))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while deleting Room Type", false))
            });
    }
}

export const deleteRoomRate = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/pms/roomrate/delete/', { pms_rate_id: request.id })
            .then(function (response) {
                dispatch(actions.getRoomRateList(request))
                dispatch(Notify.newUserModal("Room Rate Deleted Successfully", true))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while deleting Room Rate", false))
            });
    }
}