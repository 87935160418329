import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../index';
import Notify from '../Notify'

const onFailedTaskListStart = () => {
    return {
        type: actionTypes.GET_RESERVATION_FAILED_TASK_LIST_START,
        error: null
    }
}

const onFailedTaskListError= (error) => {
    return {
        type: actionTypes.GET_RESERVATION_FAILED_TASK_LIST_ERROR,
        error: error
    }
}

const onFailedTaskListSucceed = (data) => {
    return {
        type: actionTypes.GET_RESERVATION_FAILED_TASK_LIST_SUCCEED,
        state: data
    }
}

export const getReservationFailedTaskList = (request) => {
    return dispatch => {
        dispatch(onFailedTaskListStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        let url = (request.url ? (Server.API + request.url) : (Server.API + 'hotel/task_hotel_detail/'));
        axios.get(url)
            .then(function (response) {
                dispatch(onFailedTaskListSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onFailedTaskListError(error))
            });
    }
}

export const retryFailedReservationTask = (request) => {
    let task_data = request.data
    return dispatch => {
        dispatch(onFailedTaskListStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/run_task/' + task_data.task + '/')
            .then(function (response) {
                dispatch(actions.getReservationFailedTaskList(request))
                dispatch(Notify.newUserModal("Reservation Task Updated Successfully.", true))
            })
            .catch(function (error) {
                if(error.response.data.competitor_list.message){
                    dispatch(onFailedTaskListError(error.response.data.competitor_list.message))
                }else{                    
                    dispatch(onFailedTaskListError(error))
                }
            });
    }
}