import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles';
import { Icon } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import toolTipCss from './css/toolTipCss';

class CustomTooltip extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    const { classes } = this.props;
    return (
      <div style={{ display: "flex", flexDirection: "column", minWidth: this.props.width ? this.props.width : "100px" }}>
        <Typography align="left" variant="h5" style={{ color: "#25282B", fontWeight: "500", display: "flex" }}>
          {this.props.title}
          {/* <Typography align="left" variant="caption" style={{ color: "#8a8a8a" }}> */}
          {this.props.text}
          {/* </Typography> */}
        </Typography>

        <Typography align="left" variant="caption" style={{ color: "#" }}>
          {this.props.label}
        </Typography>
      </div>
    );
  }
}

CustomTooltip.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default compose(withStyles(toolTipCss), connect(mapStateToProps, mapDispatchToProps))(CustomTooltip);