import moment from 'moment';

const stringToBoolean = function (string) {
    switch (string.toLowerCase().trim()) {
        case "true": case "yes": case "1": return true;
        case "false": case "no": case "0": case null: return false;
        default: return Boolean(string);
    }
}

export const ValidateBaseSettings = (request) => {
    Object.keys(request.dateSetting).forEach((pKey, index) => {
        if (pKey === 'adjustment') {
            Object.keys(request.dateSetting[pKey]).forEach((cKey, index) => {
                Object.keys(request.dateSetting[pKey][cKey]).forEach((gcKey, index) => {
                    Object.keys(request.dateSetting[pKey][cKey][gcKey]).forEach((key, index) => {
                        if ((typeof request.dateSetting[pKey][cKey][gcKey][key] === 'undefined') || request.dateSetting[pKey][cKey][gcKey][key] === '' || request.dateSetting[pKey][cKey][gcKey][key] === null) {
                            request.dateSetting[pKey][cKey][gcKey][key] = 0
                        } else {
                            request.dateSetting[pKey][cKey][gcKey][key] = parseFloat(request.dateSetting[pKey][cKey][gcKey][key])
                        }
                    })
                })
            })
        } else if (pKey === 'predefined_rates') {
            if ((typeof request.dateSetting[pKey] === 'undefined') || request.dateSetting[pKey] === '' || request.dateSetting[pKey] === null) {
                request.dateSetting[pKey] = []
            } else if (typeof request.dateSetting[pKey] === 'object') {
                Object.keys(request.dateSetting[pKey]).forEach((key, index) => {
                    if ((typeof request.dateSetting[pKey][key] === 'undefined') || request.dateSetting[pKey][key] === '' || request.dateSetting[pKey][key] === null) {
                        request.dateSetting[pKey][key] = 0
                    } else {
                        request.dateSetting[pKey][key] = parseFloat(request.dateSetting[pKey][key])
                    }
                })
            } else {
                request.dateSetting[pKey] = parseFloat(request.dateSetting[pKey])
            }
        } else if (pKey === 'hotel') {
            Object.keys(request.dateSetting[pKey]).forEach((key, index) => {
                if (key === 'id') {
                    if (typeof request.dateSetting[pKey][key] !== 'number') {
                        request.dateSetting[pKey][key] = parseInt(request.dateSetting[pKey][key])
                    }
                } else if (key === 'min_price') {
                    if ((typeof request.dateSetting[pKey][key] === 'undefined') || request.dateSetting[pKey][key] === '' || request.dateSetting[pKey][key] === null) {
                        request.dateSetting[pKey][key] = 0
                    } else if (typeof request.dateSetting[pKey][key] !== 'number') {
                        request.dateSetting[pKey][key] = parseFloat(request.dateSetting[pKey][key])
                    }
                } else if (key === 'all_room_pricing') {
                    if ((typeof request.dateSetting[pKey][key] === 'undefined') || request.dateSetting[pKey][key] === '' || request.dateSetting[pKey][key] === null) {
                        request.dateSetting[pKey][key] = false
                    } else if (typeof request.dateSetting[pKey][key] !== 'boolean') {
                        request.dateSetting[pKey][key] = stringToBoolean(request.dateSetting[pKey][key])
                    }
                } else if (key === 'target_occupancy') {
                    if ((typeof request.dateSetting[pKey][key] === 'undefined') || request.dateSetting[pKey][key] === '' || request.dateSetting[pKey][key] === null) {
                        request.dateSetting[pKey][key] = 0
                    } else if (typeof request.dateSetting[pKey][key] !== 'number') {
                        request.dateSetting[pKey][key] = parseFloat(request.dateSetting[pKey][key])
                    }
                } else if (key === 'number_of_rooms') {
                    if ((typeof request.dateSetting[pKey][key] === 'undefined') || request.dateSetting[pKey][key] === '' || request.dateSetting[pKey][key] === null) {
                        request.dateSetting[pKey][key] = 0
                    } else if (typeof request.dateSetting[pKey][key] !== 'number') {
                        request.dateSetting[pKey][key] = parseFloat(request.dateSetting[pKey][key])
                    }
                } else if (key === 'predefined_rate') {
                    if ((typeof request.dateSetting[pKey][key] === 'undefined') || request.dateSetting[pKey][key] === '' || request.dateSetting[pKey][key] === null) {
                        request.dateSetting[pKey][key] = false
                    } else if (typeof request.dateSetting[pKey][key] !== 'boolean') {
                        request.dateSetting[pKey][key] = stringToBoolean(request.dateSetting[pKey][key])
                    }
                } else if (key === 'adjustment_to_reference_room_is_absolute') {
                    if ((typeof request.dateSetting[pKey][key] === 'undefined') || request.dateSetting[pKey][key] === '' || request.dateSetting[pKey][key] === null) {
                        request.dateSetting[pKey][key] = false
                    } else if (typeof request.dateSetting[pKey][key] !== 'boolean') {
                        request.dateSetting[pKey][key] = stringToBoolean(request.dateSetting[pKey][key])
                    }
                }
            })
        } else if (pKey === 'rooms') {
            Object.keys(request.dateSetting[pKey]).forEach((cKey, index) => {
                if (cKey === 'reference') {
                    Object.keys(request.dateSetting[pKey][cKey]).forEach((key, index) => {
                        if (key === 'name') {
                            if (typeof request.dateSetting[pKey][cKey][key] !== 'string') {
                                request.dateSetting[pKey][cKey][key] = request.dateSetting[pKey][cKey][key].toString()
                            }
                        } else if (key === 'number_of_rooms') {
                            if ((typeof request.dateSetting[pKey][cKey][key] === 'undefined') || request.dateSetting[pKey][cKey][key] === '' || request.dateSetting[pKey][cKey][key] === null) {
                                request.dateSetting[pKey][cKey][key] = 0
                            } else if (typeof request.dateSetting[pKey][cKey][key] !== 'number') {
                                request.dateSetting[pKey][cKey][key] = parseInt(request.dateSetting[pKey][cKey][key])
                            }

                        } else if (key === 'max_occupancy') {
                            if ((typeof request.dateSetting[pKey][cKey][key] === 'undefined') || request.dateSetting[pKey][cKey][key] === '' || request.dateSetting[pKey][cKey][key] === null) {
                                request.dateSetting[pKey][cKey][key] = 0
                            } else if (typeof request.dateSetting[pKey][cKey][key] !== 'number') {
                                request.dateSetting[pKey][cKey][key] = parseInt(request.dateSetting[pKey][cKey][key])
                            }
                        } else if (key === 'variable_cost_per_room') {
                            if ((typeof request.dateSetting[pKey][cKey][key] === 'undefined') || request.dateSetting[pKey][cKey][key] === '' || request.dateSetting[pKey][cKey][key] === null) {
                                request.dateSetting[pKey][cKey][key] = 0
                            } else if (typeof request.dateSetting[pKey][cKey][key] !== 'number') {
                                request.dateSetting[pKey][cKey][key] = parseFloat(request.dateSetting[pKey][cKey][key])
                            }
                        } else if (key === 'id') {
                            if (typeof request.dateSetting[pKey][cKey][key] !== 'number') {
                                request.dateSetting[pKey][cKey][key] = parseInt(request.dateSetting[pKey][cKey][key])
                            }
                        }
                    })
                } else if (cKey === 'derived') {
                    if ((typeof request.dateSetting[pKey][cKey] === 'undefined') || request.dateSetting[pKey][cKey] === '' || request.dateSetting[pKey][cKey] === null) {
                        request.dateSetting[pKey][cKey] = []
                    } else {
                        Object.keys(request.dateSetting[pKey][cKey]).forEach((key, index) => {
                            Object.keys(request.dateSetting[pKey][cKey][key]).forEach((gcKey, index) => {
                                if (gcKey === 'name') {
                                    if (typeof request.dateSetting[pKey][cKey][key][gcKey] !== 'string') {
                                        request.dateSetting[pKey][cKey][key][gcKey] = request.dateSetting[pKey][cKey][key][gcKey].toString()
                                    }
                                } else if (gcKey === 'number_of_rooms') {
                                    if ((typeof request.dateSetting[pKey][cKey][key][gcKey] === 'undefined') || request.dateSetting[pKey][cKey][key][gcKey] === '' || request.dateSetting[pKey][cKey][key][gcKey] === null) {
                                        request.dateSetting[pKey][cKey][key][gcKey] = 0
                                    } else if (typeof request.dateSetting[pKey][cKey][key][gcKey] !== 'number') {
                                        request.dateSetting[pKey][cKey][key][gcKey] = parseInt(request.dateSetting[pKey][cKey][key][gcKey])
                                    }

                                } else if (gcKey === 'max_occupancy') {
                                    if ((typeof request.dateSetting[pKey][cKey][key][gcKey] === 'undefined') || request.dateSetting[pKey][cKey][key][gcKey] === '' || request.dateSetting[pKey][cKey][key] === null) {
                                        request.dateSetting[pKey][cKey][key][gcKey] = 0
                                    } else if (typeof request.dateSetting[pKey][cKey][key][gcKey] !== 'number') {
                                        request.dateSetting[pKey][cKey][key][gcKey] = parseInt(request.dateSetting[pKey][cKey][key][gcKey])
                                    }
                                } else if (gcKey === 'variable_cost_per_room') {
                                    if ((typeof request.dateSetting[pKey][cKey][key][gcKey] === 'undefined') || request.dateSetting[pKey][cKey][key][gcKey] === '' || request.dateSetting[pKey][cKey][key][gcKey] === null) {
                                        request.dateSetting[pKey][cKey][key][gcKey] = 0
                                    } else if (typeof request.dateSetting[pKey][cKey][key][gcKey] !== 'number') {
                                        request.dateSetting[pKey][cKey][key][gcKey] = parseFloat(request.dateSetting[pKey][cKey][key][gcKey])
                                    }
                                }
                            })
                        })
                    }
                }
            })
        } else if (pKey === 'dates') {
            if ((typeof request.dateSetting[pKey] === 'undefined') || request.dateSetting[pKey] === '' || request.dateSetting[pKey] === null) {
                request.dateSetting[pKey] = []
            } else {
                Object.keys(request.dateSetting[pKey]).forEach((cKey, index) => {
                    Object.keys(request.dateSetting[pKey][cKey]).forEach((gcKey, index) => {
                        if (gcKey === 'individual_adjustment') {
                            Object.keys(request.dateSetting[pKey][cKey][gcKey]).forEach((key, index) => {
                                if (key === 'name') {
                                    if (typeof request.dateSetting[pKey][cKey][gcKey][key] !== 'string' && request.dateSetting[pKey][cKey][gcKey][key] !== null) {
                                        request.dateSetting[pKey][cKey][gcKey][key] = request.dateSetting[pKey][cKey][gcKey][key].toString()
                                    }
                                } else if (key === 'percentage') {
                                    if ((typeof request.dateSetting[pKey][cKey][gcKey][key] === 'undefined') || request.dateSetting[pKey][cKey][gcKey][key] === '' || request.dateSetting[pKey][cKey][gcKey][key] === null) {
                                        request.dateSetting[pKey][cKey][gcKey][key] = 0
                                    } else if (typeof request.dateSetting[pKey][cKey][gcKey][key] !== 'number') {
                                        request.dateSetting[pKey][cKey][gcKey][key] = parseFloat(request.dateSetting[pKey][cKey][gcKey][key])
                                    }
                                }
                            })
                        } else if (gcKey === 'skip_date') {
                            Object.keys(request.dateSetting[pKey][cKey][gcKey]).forEach((key, index) => {
                                if (key === 'name') {
                                    if (typeof request.dateSetting[pKey][cKey][gcKey][key] !== 'string' && request.dateSetting[pKey][cKey][gcKey][key] !== null) {
                                        request.dateSetting[pKey][cKey][gcKey][key] = request.dateSetting[pKey][cKey][gcKey][key].toString()
                                    }
                                } else if (key === 'fixed_price') {
                                    if ((typeof request.dateSetting[pKey][cKey][gcKey][key] === 'undefined') || request.dateSetting[pKey][cKey][gcKey][key] === '' || request.dateSetting[pKey][cKey][gcKey][key] === null) {
                                        request.dateSetting[pKey][cKey][gcKey][key] = null
                                    } else if (typeof request.dateSetting[pKey][cKey][gcKey][key] !== 'number') {
                                        request.dateSetting[pKey][cKey][gcKey][key] = parseFloat(request.dateSetting[pKey][cKey][gcKey][key])
                                    }
                                }
                            })
                        } else if (gcKey === 'price_note') {
                            if (request.dateSetting[pKey][cKey][gcKey]) {
                                request.dateSetting[pKey][cKey][gcKey] = request.dateSetting[pKey][cKey][gcKey].toString();
                            }
                        } else {
                            Object.keys(request.dateSetting[pKey][cKey][gcKey]).forEach((key, index) => {
                                if ((typeof request.dateSetting[pKey][cKey][gcKey][key] === 'undefined') || request.dateSetting[pKey][cKey][gcKey][key] === '' || request.dateSetting[pKey][cKey][gcKey][key] === null) {
                                    request.dateSetting[pKey][cKey][gcKey][key] = 0
                                } else if (key == 'occupancy_pricing') {
                                    request.dateSetting[pKey][cKey][gcKey][key] = request.dateSetting[pKey][cKey][gcKey][key];
                                }else if (typeof request.dateSetting[pKey][cKey][gcKey][key] !== 'number') {
                                    request.dateSetting[pKey][cKey][gcKey][key] = parseFloat(request.dateSetting[pKey][cKey][gcKey][key])
                                }
                            })
                        }
                    })
                    if (Object.keys(request.dateSetting[pKey][cKey]).length === 0) {
                        delete request.dateSetting[pKey][cKey];
                    }
                    if (moment(cKey).isBefore(moment().subtract(90, 'days').format('YYYY-MM-DD'))) {
                        delete request.dateSetting[pKey][cKey];
                    }
                })
            }
        } else if (pKey === 'rpg_arguments') {
            // if (!request.dateSetting[pKey].median_lead_time) {
            //     request.dateSetting[pKey].median_lead_time = 8;
            // }
            Object.keys(request.dateSetting[pKey]).forEach((key, index) => {
                if (key === 'PELL_weekday') {
                    if ((typeof request.dateSetting[pKey][key] === 'undefined') || request.dateSetting[pKey][key] === '' || request.dateSetting[pKey][key] === null) {
                        request.dateSetting[pKey][key] = 0
                    } else if (typeof request.dateSetting[pKey][key] !== 'number') {
                        request.dateSetting[pKey][key] = parseFloat(request.dateSetting[pKey][key])
                    }
                } else if (key === 'PELL_weekend') {
                    if ((typeof request.dateSetting[pKey][key] === 'undefined') || request.dateSetting[pKey][key] === '' || request.dateSetting[pKey][key] === null) {
                        request.dateSetting[pKey][key] = 0
                    } else if (typeof request.dateSetting[pKey][key] !== 'number') {
                        request.dateSetting[pKey][key] = parseFloat(request.dateSetting[pKey][key])
                    }
                } else if (key === 'univPx_Param1') {
                    if ((typeof request.dateSetting[pKey][key] === 'undefined') || request.dateSetting[pKey][key] === '' || request.dateSetting[pKey][key] === null) {
                        request.dateSetting[pKey][key] = 0
                    } else if (typeof request.dateSetting[pKey][key] !== 'number') {
                        request.dateSetting[pKey][key] = parseFloat(request.dateSetting[pKey][key])
                    }
                } else if (key === 'univPx_Param2') {
                    if ((typeof request.dateSetting[pKey][key] === 'undefined') || request.dateSetting[pKey][key] === '' || request.dateSetting[pKey][key] === null) {
                        request.dateSetting[pKey][key] = 0
                    } else if (typeof request.dateSetting[pKey][key] !== 'number') {
                        request.dateSetting[pKey][key] = parseFloat(request.dateSetting[pKey][key])
                    }
                } else if (key === 'univPx_SpecialDaysRange') {
                    if ((typeof request.dateSetting[pKey][key] === 'undefined') || request.dateSetting[pKey][key] === '' || request.dateSetting[pKey][key] === null) {
                        request.dateSetting[pKey][key] = 0
                    } else if (typeof request.dateSetting[pKey][key] !== 'number') {
                        request.dateSetting[pKey][key] = parseInt(request.dateSetting[pKey][key])
                    }
                } else if (key === 'univPx_PercentageToUse') {
                    if ((typeof request.dateSetting[pKey][key] === 'undefined') || request.dateSetting[pKey][key] === '' || request.dateSetting[pKey][key] === null) {
                        request.dateSetting[pKey][key] = 0
                    } else if (typeof request.dateSetting[pKey][key] !== 'number') {
                        request.dateSetting[pKey][key] = parseFloat(request.dateSetting[pKey][key])
                    }
                } else if (key === 'median_lead_time') {
                    if ((typeof request.dateSetting[pKey][key] === 'undefined') || request.dateSetting[pKey][key] === '' || request.dateSetting[pKey][key] === null) {
                        request.dateSetting[pKey][key] = 0
                    } else if (typeof request.dateSetting[pKey][key] !== 'number') {
                        request.dateSetting[pKey][key] = parseInt(request.dateSetting[pKey][key])
                    }
                } else if (key === 'booking_prop') {
                    Object.keys(request.dateSetting[pKey][key]).forEach((gcKey, index) => {
                        if ((typeof request.dateSetting[pKey][key][gcKey] === 'undefined') || request.dateSetting[pKey][key][gcKey] === '' || request.dateSetting[pKey][key][gcKey] === null) {
                            request.dateSetting[pKey][key][gcKey] = 0
                        } else if (typeof request.dateSetting[pKey][key][gcKey] !== 'number') {
                            request.dateSetting[pKey][key][gcKey] = parseInt(request.dateSetting[pKey][key][gcKey])
                        }
                    })

                }
            })
        } else if (pKey === 'client_preference') {
            Object.keys(request.dateSetting[pKey]).forEach((key, index) => {
                if (key === 'free_canc_stop') {
                    if ((typeof request.dateSetting[pKey][key] === 'undefined') || request.dateSetting[pKey][key] === '' || request.dateSetting[pKey][key] === null) {
                        request.dateSetting[pKey][key] = 0
                    } else if (typeof request.dateSetting[pKey][key] !== 'number') {
                        request.dateSetting[pKey][key] = parseInt(request.dateSetting[pKey][key])
                    }
                } else if (key === 'free_canc_doesnt_reduce') {
                    if ((typeof request.dateSetting[pKey][key] === 'undefined') || request.dateSetting[pKey][key] === '' || request.dateSetting[pKey][key] === null) {
                        request.dateSetting[pKey][key] = 0
                    } else if (typeof request.dateSetting[pKey][key] !== 'number') {
                        request.dateSetting[pKey][key] = parseInt(request.dateSetting[pKey][key])
                    }
                } else {
                    if ((typeof request.dateSetting[pKey][key] === 'undefined') || request.dateSetting[pKey][key] === '' || request.dateSetting[pKey][key] === null) {
                        request.dateSetting[pKey][key] = 0
                    } else if (typeof request.dateSetting[pKey][key] !== 'number') {
                        request.dateSetting[pKey][key] = parseFloat(request.dateSetting[pKey][key])
                    }
                }
            })
        } else if (pKey === 'default') {
            Object.keys(request.dateSetting[pKey]).forEach((cKey, index) => {
                Object.keys(request.dateSetting[pKey][cKey]).forEach((key, index) => {
                    if ((typeof request.dateSetting[pKey][cKey][key] === 'undefined') || request.dateSetting[pKey][cKey][key] === '' || request.dateSetting[pKey][cKey][key] === null) {
                        request.dateSetting[pKey][cKey][key] = 0
                    } else if (typeof request.dateSetting[pKey][cKey][key] !== 'number') {
                        request.dateSetting[pKey][cKey][key] = parseFloat(request.dateSetting[pKey][cKey][key])
                    }
                })
            })
        }
    })
    return request
};