import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import CardMedia from '@material-ui/core/CardMedia';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { BookingSuiteTestTheme } from './muiTheme';
import { connect } from "react-redux";
import { compose } from 'redux'
import * as actions from '../store/actions/index';
import loginCss from './css/login';
const styles = loginCss;
class BookingSuitTest extends React.Component {

  state = {
    hotel_id: '',
    error: []
  }


  validateForm = (data) => {

    let error = [];

    if (!this.state.hotel_id) {
      error.push('hotel_id')
    }

    if (error) {
      this.setState({
        error: error
      })
    }

    return error
  }

  doBSLogin = () => {

    let validateError = this.validateForm(this.state);

    if (validateError.length > 0) {
      return;
    }

    let request = { hotel_ids: this.state.hotel_id };
    this.props.bookingSuitAuthTest(request);
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={BookingSuiteTestTheme}>
        <div className={classes.contentLogin}>
          <main className={classes.main}>
            <CardMedia
              className={classes.siteLogo}
              image="/img/roompricegenie_logo_new.svg"
              title="Booking Suite"
            />
            <form className={classes.form}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="hotel_id">Hotel Id</InputLabel>
                <Input id="hotel_id" name="hotel_id" error={this.state.error.includes('hotel_id')} onChange={this.handleChange('hotel_id')} autoComplete="hotel_id" autoFocus />
              </FormControl>
              <Button
                type="button"
                fullWidth
                disabled={this.props.loading}
                variant="contained"
                color="primary"
                className={classes.LoginBtn}
                onClick={() => this.doBSLogin()}
              >
                Submit
              </Button>
            </form>
          </main>
        </div>

      </MuiThemeProvider >
    );
  }
}

BookingSuitTest.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  container: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    loading: state.authReducer.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    bookingSuitAuthTest: (request) => { dispatch(actions.bookingSuitAuthTest(request)) }
  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(BookingSuitTest);