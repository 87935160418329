import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../../../store/actions/index';
import Notify from '../Notify';
import { ValidateBaseSettings } from '../../../../src/App/Utils/SettingValidation/Validation';



const getTagListSuccess = (data) => {
    return {
        type: actionTypes.TAG_LIST_SUCCESS,
        state: data
    }
}

const getTagListFailed = (error) => {
    return {
        type: actionTypes.TAG_LIST_LOG_FAILED,
        error: error
    }
}

export const getTagList = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'performancedashboard/segmentation-cache/')
            .then((response) => {
                dispatch(getTagListSuccess(response.data))
            })
            .catch(function (error) {
                dispatch(getTagListFailed(error))
            });
    }
}

export const updateSegmentationData = (request, callback) => {
    let reqParams = {
        new_segmentation_data: request.new_segmentation_data,
    };
    return dispatch => {        
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'pricingalgorithm/reset-pickupboostprice-segmentation-effect/', reqParams)
        .then((response) => { 
            // dispatch(actions.getPricingSettings(request)) 
            // dispatch(actions.getRunPriceFlags(request));              
            dispatch(Notify.newUserModal(response.data.message, true))
            callback();             
        }).catch((error) => {            
            dispatch(Notify.newUserModal('Error while Applying the Changes', false))
        })
    }
}
