import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = [];

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.COMPETITOR_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.COMPETITOR_SUCCEED:
            return updateObject(state, { loading: false, error: null, competitorList: action.state.results, otherCompetitorList:action.state.nearby_client_and_competitor })
        case actionTypes.COMPETITOR_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.NEAR_COMPETITOR_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.NEAR_COMPETITOR_SUCCEED:
            return updateObject(state, { loading: false, error: null, competitorList: action.state.results })
        case actionTypes.NEAR_COMPETITOR_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.UPDATE_COMPETITOR_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.UPDATE_COMPETITOR_SUCCEED:
            return updateObject(state, { loading: false, error: null })
        case actionTypes.UPDATE_COMPETITOR_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.IMPUTATION_SUCCEED:
            return updateObject(state, { loading: false, error: action.error, ImputationData: action.state })
        case actionTypes.BOOKING_COMPETITOR_SUCCEED:
            return updateObject(state, { loading: false, error: action.error, bookingCompetitor: action.state })
        case actionTypes.SCORE_COMPETITOR_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.SCORE_COMPETITOR_SUCCEED:
            return updateObject(state, { loading: false, error: action.error, scoreCompetitors: action.state })
        case actionTypes.TOP_COMPETITOR_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.TOP_COMPETITOR_SUCCEED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.SCORE_TOP_COMPETITOR_START:
            return updateObject(state, { loadingScore: true, error: null })
        case actionTypes.SCORE_TOP_COMPETITOR_SUCCEED:
            return updateObject(state, { loadingScore: false, error: null, scoreTopCompetitor: action.state })
        case actionTypes.UPDATE_BULKCOMPETITOR_SUCCEED:
            return updateObject(state, { loadingScore: false, error: null, competitorList: action.state.data })
        case actionTypes.SCRAPE_TOP_20_COMPETITORS_START:
            return updateObject(state, { loadingScrapTop20: true, error: null })
        case actionTypes.SCRAPE_TOP_20_COMPETITORS_FAILED:
            return updateObject(state, { loadingScrapTop20: false, error: null })
        case actionTypes.SCRAPE_TOP_20_COMPETITORS_SUCCEED:
            return updateObject(state, { loadingScrapTop20: false, error: null })
        case actionTypes.GET_AIR_DNA_DATA:
            return updateObject(state, { airDnaData: action.state })
        default:
            return updateObject(state, { loading: false, error: null });
    }
}

export default reducer