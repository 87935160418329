import React from 'react';
import * as actions from '../store/actions/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import academyAccount from './css/academyAccount'
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { AcademyAccountTheme } from './muiTheme';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Checkbox from '@material-ui/core/Checkbox';
import CardMedia from '@material-ui/core/CardMedia';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import { Icon } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = academyAccount;

class AcademyAccount extends React.Component {
	state = {
		error: [],
		pms:'',
		channel_manager:'',
		gdrp:false,
		warning: false,
		academySuccessModalOpen:false,
		academyLoading:false,
		language:'en'
	}     
	componentDidMount()
	{
		document.title = "RoomPriceGenie Academy Signup"
		document.body.style.backgroundColor = "#F6EFE8";
	}	
	componentWillReceiveProps(props){
		if (props.commonReducer && props.commonReducer.notify) {
			
			if (props.commonReducer.notify.message) {
			  this.setState({
				snackbarMsg: props.commonReducer.notify.message,
				snackbarSuccess: props.commonReducer.notify.is_success,
				warning: true,
			  }, () => {
				if (props.commonReducer.notify.is_success) {
				  setTimeout(() => {
					this.setState({
					  warning: false
					})
				  }, 3000);
				} else {
				  // setTimeout(() => {
				  //   this.setState({
				  //     warning: false
				  //   })
				  // }, 6000);
				}
			  })
			  this.props.cleanNotify()
			}
			if(props.commonReducer.notify.is_success == true || props.commonReducer.academySuccess || props.commonReducer.academyError){
				this.setState({
					academySuccessModalOpen:true
				})
			}
		}
			this.setState({
				academyLoading:props.commonReducer.academyLoading
			})
	} 
	toggleWarning = () => {
		this.setState({
		  warning: !this.state.warning,
		})
	  }	
	handleData = (name) =>event => {
		if(name == 'gdrp'){
			event.target.checked ? this.setState({gdrp:true}) : this.setState({gdrp:false})	
		}else{
			this.setState({
				[name]: event.target.value,
			})
		}
		
	}
	validateForm = () => {
		let error = [];
		if (!this.state.first_name) {
			error.push('first_name')
		}if (!this.state.last_name) {
			error.push('last_name')
		}if (!this.state.last_name) {
			error.push('email')
		}if (!this.state.last_name) {
			error.push('property_name')
		}if(this.state.gdrp == false){
			error.push('gdrp')
		}if(!this.state.background){
			error.push('background')
		}if(!this.state.language){
			error.push('language')
		}
		if (error) {
            this.setState({
                error: error
            })
            return error
        }
        else {
            return false
        }
	}

	closeAcademySuccessModal = () =>{
        this.setState({
            academySuccessModalOpen:false,
        })
		window.location.reload()
    }

	submitForm = () => {
		
		let validateError = this.validateForm();
        if (validateError.length > 0) {
            return;
        }
		var url_string = window.location.href
		let params=url_string.split('?');
		
		let requestParams={
			first_name:this.state.first_name,
			last_name:this.state.last_name,
			email:this.state.email,
			contact_company_name:this.state.property_name,
			background:this.state.background,
			lead_source:"RoomPriceGenie Website",
			language: this.state.language,
			pms:this.state.pms ? this.state.pms :null,
			partner_name:params[1] ? params[1] : null ,
			channel_manager:this.state.channel_manager ? this.state.channel_manager : null ,
		}
		this.props.onAcademySignup(requestParams);
	}

	render() {
		const { classes } = this.props;
		const { fullScreen } = this.props;
		const theme = AcademyAccountTheme;
		return (
			<>
			{this.state.warning &&
              <Snackbar
				
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={this.toggleWarning}
                open={this.state.warning}
                TransitionComponent={Fade}
                ContentProps={{
                  classes: {
                    root: this.state.snackbarSuccess ? classes.sccessBar : classes.warningBar
                  }
                }}
                message={<span style={{ textTransform: 'capitalize' }}><Icon className={classes.warningIcon}>{(this.state.snackbarSuccess) ? 'check_circle' : 'error'}</Icon> {(this.state.snackbarMsg)}</span>}
                variant="success"
              />
            }

			    <MuiThemeProvider theme={theme}>
				<CardMedia
					style={{backgroundColor:'#f0f4f9',backgroundSize:'380px'}}
          			className={classes.siteLogo}
          			image="/img/university_genie_text.svg"
          			title="RoomPriceGenie"
        		/>
					<Grid container spacing={2} style={{marginBottom:'50px'}}>
        				<Grid item md={6} sm={12}>
							<Card className={classes.card} variant="outlined" elevation={0}>
								<CardHeader style={{fontWeight:'500'}} title={'Create Academy Account'} />
								<CardContent>
									<Grid xs={12} item>
                                        <FormControl fullWidth required className={classes.fieldLeft} margin="normal" >
                                            <InputLabel shrink={true} htmlFor="name">{("First Name")}</InputLabel>
                                            <Input
                                                error={this.state.error.includes("first_name")}
                                                id="standard-name"
                                                className={classes.textField}
                                                onChange={this.handleData('first_name')}
                                                // value={modalData.state.editModalData.name}
                                                margin="normal"
                                                // endAdornment={<CustomTooltip title="" description={t("Please give this room a name in RoomPriceGenie. For simplicity we recommend the same name as in your PMS.")}></CustomTooltip>}
                                            />
											{this.state.error.includes("first_name") &&
                                                <span class='error-message'>{"* Please enter First Name"}</span>
                                            }
                                        </FormControl>
                                    </Grid>
									<Grid xs={12} item>
                                        <FormControl fullWidth required className={classes.fieldLeft} margin="normal" >
                                            <InputLabel shrink={true} htmlFor="name">{("Last Name")}</InputLabel>
                                            <Input
                                                error={this.state.error.includes("last_name")}
                                                id="standard-name"
                                                className={classes.textField}
                                                onChange={this.handleData('last_name')}
                                                // value={modalData.state.editModalData.name}
                                                margin="normal"
                                            />
											{this.state.error.includes("last_name") &&
                                                <span class='error-message'>{"* Please enter Last Name"}</span>
                                            }
                                        </FormControl>
                                    </Grid>
									<Grid xs={12} item>
                                        <FormControl fullWidth required className={classes.fieldLeft} margin="normal" >
                                            <InputLabel shrink={true} htmlFor="name">{("Email")}</InputLabel>
                                            <Input
                                                error={this.state.error.includes("email")}
                                                id="standard-name"
                                                className={classes.textField}
                                                onChange={this.handleData('email')}
                                                // value={modalData.state.editModalData.name}
                                                margin="normal"
                                            />
											{this.state.error.includes("email") &&
                                                <span class='error-message'>{"* Please enter email"}</span>
                                            }
                                        </FormControl>
                                    </Grid>
									<Grid xs={12} item>
                                        <FormControl fullWidth required className={classes.fieldLeft} margin="normal" >
                                            <InputLabel shrink={true} htmlFor="name">{("Property / Company Name")}</InputLabel>
                                            <Input
                                                error={this.state.error.includes("property_name")}
                                                id="standard-name"
                                                className={classes.textField}
                                                onChange={this.handleData('property_name')}
                                                // value={modalData.state.editModalData.name}
                                                margin="normal"
                                            />
											{this.state.error.includes("property_name") &&
                                                <span class='error-message'>{"* Please enter Property name"}</span>
                                            }
                                        </FormControl>
                                    </Grid>
									<Grid xs={12} item>
                                        <FormControl fullWidth  className={classes.fieldLeft} margin="normal" >
                                            <InputLabel shrink={true} htmlFor="name">{("PMS (if applicable)")}</InputLabel>
                                            <Input
                                                // error={this.state.error.includes("pms")}
                                                id="standard-name"
                                                className={classes.textField}
                                                onChange={this.handleData('pms')}
                                                // value={modalData.state.editModalData.name}
                                                margin="normal"
                                            />
                                        </FormControl>
                                    </Grid>
									<Grid xs={12} item>
                                        <FormControl fullWidth  className={classes.fieldLeft} margin="normal" >
                                            <InputLabel shrink={true} htmlFor="name">{("Channel Manager (if applicable)")}</InputLabel>
                                            <Input
                                                // error={this.state.error.includes("channel_manager")}
                                                id="standard-name"
                                                className={classes.textField}
                                                onChange={this.handleData('channel_manager')}
                                                // value={modalData.state.editModalData.name}
                                                margin="normal"
                                            />
                                        </FormControl>
										
                                    </Grid>
									<Grid xs={12} md={12} item>
										<FormControl component="fieldset"  className={classes.fieldLeft} style={{float:'left',marginTop:'31px'}} margin="normal">
										<InputLabel shrink={true} required htmlFor="name" style={{marginTop:'-23px'}}>{("Background")}</InputLabel>
										{/* <FormLabel component="legend" style={{float:'left'}}>Gender</FormLabel> */}
										<RadioGroup aria-label="background" name="background" onChange={this.handleData('background')}  error={this.state.error.includes("background")}>
											<FormControlLabel value="Hotelier" control={<Radio  color="primary" />} label="Hotel, Serviced Apartments, Camping/RV, B&B" />
											<FormControlLabel value="Consultant" 	control={<Radio color="primary"  />} label="Consultancy" />
											<FormControlLabel value="Academic Institution" control={<Radio color="primary" />} label="Academic Institution" />
											<FormControlLabel value="Other" control={<Radio color="primary" />} label="Other" />
										</RadioGroup>
										</FormControl>
										{this.state.error.includes("background") &&
                                            <span class='error-message' style={{whiteSpace:"nowrap",float:'left',margin:'10px 0px 10px 0px'}}>{"* Please enter background"}</span>
										}
                                    </Grid>
								
									<Grid xs={12}   item>
										<FormControl fullWidth  className={classes.fieldLeft} margin="normal"   style={{float:'left',marginTop:'31px'}} >
										<InputLabel shrink={true} required htmlFor="name" style={{marginTop:'-23px'}}>{("Preferred Course Language")}</InputLabel>
										<RadioGroup aria-label="language" name="language" value={this.state.language} onChange={this.handleData('language')}  error={this.state.error.includes("language")}>
											<FormControlLabel value="en" control={<Radio  color="primary" />} label="English" />
											<FormControlLabel value="de" control={<Radio color="primary"  />} label="German" />
										</RadioGroup>
										</FormControl>
										{this.state.error.includes("language") &&
                                            <span class='error-message' style={{whiteSpace:"nowrap",float:'left',margin:'10px 0px 10px 0px'}}>{"* Please enter Language"}</span>
										}
                                    </Grid>
								
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<FormControlLabel
												style={{ float: "left", marginTop:'15px' }}
												name='gdrp'
												error={this.state.error.includes("gdrp")}
												control={
												<Checkbox
													onChange={this.handleData('gdrp')}
													// value={}
													name="gdrp"
													color="primary"
												/>
												}
												label={<div style={{ textAlign: "left",marginTop:'40px',marginBottom:'25px' }}>I consent to allow RoomPriceGenie to store and process the personal information submitted above to provide you the content requested. I accept the <a href="https://roompricegenie.com/terms-and-conditions/" style={{textDecoration:'none'}} target='blank'>terms and conditions</a> and <a href="https://roompricegenie.com/privacy-policy/" style={{textDecoration:'none'}} target='blank'>privacy policy</a>.*</div>}
											/>
											{this.state.error.includes("gdrp") &&
                                                <span class='error-message' style={{marginTop:'42px',float:'left'}}>{"* Please accept terms and condition."}</span>
                                            }
										</Grid>
									</Grid>
								</CardContent>
								<Grid container spacing={3}>
        							<Grid item xs={12}>
										<Button disabled={this.state.academyLoading} style={{ float:'left',margin:'40px 17px 22px 14px',background:this.state.academyLoading ?'#c7c7c7':'#3772FF',color:'white' }} variant="contained"  onClick={this.submitForm} >
											{("Create Account")} {this.state.academyLoading && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
										</Button>	
									</Grid>
								</Grid>
									{/* <Button variant="contained" color="primary" >
										Create Account
									</Button> */}

							</Card>
						</Grid>
						<Grid item md={6} sm={12}>
							<div className={classes.academyText}>
								<Typography style={{fontWeight:'500',fontSize:'25px',textAlign:'left'}}>Achieve your goals with </Typography>
								<Typography style={{fontWeight:'500',fontSize:'25px',textAlign:'left'}}>RoomPriceGenie Academy</Typography>
								{/* <Typography style={{fontWeight:'500',fontSize:'25px',textAlign:'left'}}></Typography> */}
								<Typography style={{fontWeight:'400',fontSize:'16px',textAlign:'left',color:'#898988',marginTop:'10px',marginBottom:'5px'}}>
									Start our Hotel Digitalization Learning Track today
								</Typography>
								<Typography style={{fontWeight:'400',fontSize:'16px',textAlign:'left',color:'#898988'}}>
									<CheckCircleIcon style={{margin:'-4px -1px',fontSize:'18px',color:'#0073EA'}} /> Full access to courses about Revenue Management,
								</Typography>
								<Typography style={{fontWeight:'400',fontSize:'16px',textAlign:'left',color:'#898988',marginLeft:'21px'}}>Hotel Marketing. Cloud Technology and more</Typography>
								<Typography style={{fontWeight:'400',fontSize:'16px',textAlign:'left',color:'#898988',marginTop:'5px'}}>
									<CheckCircleIcon style={{margin:'-4px -1px',fontSize:'18px',color:'#0073EA'}} /> Course Certificates
								</Typography>
								<Typography style={{fontWeight:'400',fontSize:'16px',textAlign:'left',color:'#898988',marginTop:'5px'}}>
									<CheckCircleIcon style={{margin:'-4px -1px',fontSize:'18px',color:'#0073EA'}} /> Take your business to the next level by implementing 
								</Typography>
								<Typography style={{fontWeight:'400',fontSize:'16px',textAlign:'left',color:'#898988',marginLeft:'21px'}}>the suggestions from the Check List</Typography>
								<Typography style={{fontWeight:'400',fontSize:'16px',textAlign:'left',color:'#898988',marginTop:'5px'}}>
									<CheckCircleIcon style={{margin:'-4px -1px',fontSize:'18px',color:'#0073EA'}} /> Get started with the first steps of manual Revenue Management
								</Typography>
								<Typography style={{fontWeight:'400',fontSize:'16px',textAlign:'left',color:'#898988',marginLeft:'21px'}}>today and see the effects yourself</Typography>
								<div style={{borderBottom:'1px solid gray',margin:'30px auto',width:'50%'}}></div>
								<Typography style={{fontWeight:'500',fontSize:'25px',textAlign:'left'}}>You are already a  </Typography>
								<Typography style={{fontWeight:'500',fontSize:'25px',textAlign:'left'}}>RoomPriceGenie client?  </Typography>
								{/* <Typography style={{fontWeight:'500',fontSize:'25px',float:'left',marginLeft:'5px'}}> </Typography> */}
								
								<Typography style={{fontWeight:'400',fontSize:'16px',textAlign:'left',color:'#898988',marginTop:'10px',marginBottom:'5px'}}>
									Click below to sign in with your RoomPriceGenie Account
								</Typography>
								<Button
									type="button"
									disabled={this.props.loading || this.state.bookingLoad}
									variant="outlined"
									className={classes.LoginBtn}
									style={{ color: "white",backgroundColor:'#0073EA', fontSize: "15px", marginTop: "15px", fontWeight: "400", textTransform: "inherit", height: "35px", borderRadius: "4px", padding: "2px 16px",border:'none',float:'left' }}
									href={" https://academy.roompricegenie.com/login/index.php?roompricegenie_client"}
									target='blank'
								>
									{/* <CardMedia image='/img/favicon.png' title='RoomPriceGenie' style={{backgroundColor:'white',width:'20px',height:'20px',marginRight:'10px'}}/>  */}
									Sign in with  <span style={{ marginLeft: "4px" }}>RoomPriceGenie</span>
								</Button>
							</div>
						</Grid>
					</Grid>
					{this.state.academySuccessModalOpen ?
				<Dialog
					transitionDuration={{ enter: 500, exit: 300 }}
					fullScreen={fullScreen}
					maxWidth='md'
					open={this.state.academySuccessModalOpen}
					onClose={this.state.academySuccessModalOpen}
					aria-labelledby="responsive-dialog-title"
				>
				<IconButton aria-label="Close" className={classes.closeButtonPrice} onClick={this.closeAcademySuccessModal} >
					<CloseIcon />
				</IconButton>
				<DialogContent>
						{this.props.commonReducer.academyError ? 
							<>
							<HighlightOffIcon style={{fontSize:'50px',display:'flex',margin:'38px auto 0px auto',color:'red'}} />
							<Typography style={{fontWeight:'500',fontSize:'20px',margin:'5px 185px',textAlign:'center'}}>We're sorry, an error occured while creating your Academy account. Please contact our RoomPriceGenie support using the chat icon on the bottom right.</Typography>
							</>
							:
							<>
							<CheckCircleOutlineIcon style={{fontSize:'50px',display:'flex',margin:'38px auto 0px auto',color:'green'}} />
							<Typography style={{fontWeight:'500',fontSize:'20px',margin:'5px 185px',textAlign:'center'}}>We have just created your RoomPriceGenie Academy account. You will receive an email with your login details.</Typography>
						</>
					   }
				</DialogContent>
				
				</Dialog>:""	
				}
				</MuiThemeProvider>	
			</>
		)
	}

}
const mapStateToProps = state => {
	return {
		loading: state.authReducer.loading,
		error: state.authReducer.error,
		user: state.authReducer.UserData,
		accountReducer: state.accountReducer,
		sessionReducer: state.sessionReducer,
		userSession: state.sessionReducer,
		commonReducer: state.commonReducer,

	};
};

const mapDispatchToProps = dispatch => {
	return {
		onAcademySignup:(request) => {dispatch(actions.onAcademySignup(request))},
		cleanNotify: () => { dispatch(actions.cleanNotify()) },

		
	};
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(AcademyAccount);
