import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = [];
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CLIENT_GET_START:
            return updateObject(state, {loading: true, error: null})
        case actionTypes.CLIENT_GET_SUCCEED:
            return updateObject(state, {loading: false, error: null, clientUserList: action.state.results})
        case actionTypes.CLIENT_GET_FAILED:
            return updateObject(state, {loading: false, error: action.error})
        default:
            return updateObject(state, {loading: false, error: null});
    }
}

export default reducer