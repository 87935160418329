const styles = {
    main: {
        position: "fixed",
        top: "310px",
        left: "calc(50% - 10em)",
        width: "44em",
        paddingBottom: "24px",
        marginTop: "-10em",
        marginLeft: "-11em",
        border: "1px solid #ccc",
        backgroundColor: "#fff",
        borderRadius: "10px",
        zIndex: "99",
        ['@media (max-width: 960px)']: {
            width: "20em",
            height: "26em",
            marginLeft: "-8em",
            left: "calc(50% - 2em)",
            height: "31em",
        },
    },
    mainError: {
        position: 'absolute',
        width: '300px',
        height: 'auto',
        zIndex: '15',
        top: '34%',
        left: '45%',
        margin: '-100px 0 0 -150px',
        borderRadius: '5px',
        background: 'white',
        padding: '20px',
        opacity: '0.9',
        borderRadius: "15px",
        boxShadow: 'none'
    },
    siteLogo: {
        backgroundSize: "312px 118px",

        padding: "10px",
        height: '85px',
        zIndex: '1',
        margin: 'auto',

    },
    warningBar: {
        backgroundColor: '#d32f2f',
        textAlign: 'left'
    },
    sccessBar: {
        backgroundColor: '#43a047',
        textAlign: 'left'
    },
    warningIcon: {
        margin: "-9px 5px -8px -5px"
    },
    extraBtn: {
        cursor: "pointer",
        marginRight: "5px",
        marginTop: "59px",
        position: "absolute",
        // padding: "5px",
        fontSize: "0.75rem",
        fontWeight: "500",
        '&:hover': {
            background: "#e8f0fe",
            borderRadius: "5px",
        },
    },
    iframeStyle: {
        height: '50px',
        border: 'none',
        width: '230px'
    },
    submit: {
        margin: 10
    },
    LoginBtn: {
        marginTop: "16px",
        float: "left"
    },
    heading: {
        textAlign: 'left',
        marginTop: 16
    },
    warning: {
        display: 'flex',
        textAlign: 'center',
        margin: '20px 0px',
        padding: '15px',
        color: 'inherit',
        background: '#F8E9C3',
        borderRadius: '5px',
        width: '95%',
        flexDirection: "column",
        alignItems: "center"
    },
    warningText: {
        margin: '4px 10px'
    },
    success: {
        display: 'flex',
        textAlign: 'left',
        margin: '20px 0px',
        padding: '15px',
        color: 'inherit',
        background: '#cdffdd',
        borderRadius: '5px',
        width: 'fit-content'
    },
    radioLabel: {
        fontSize: "16px",
        paddingTop: "20px",
        paddingRight: "15px",
        minWidth: "100%"
    },
    warningbooking: {
        color: "red",
        width: "100%",
        margin: "16px 0px",
        float: "left",
        textAlign: "left"
    },
    bookingBtn: {
        marginLeft: "16px",
        float: "right"
    },
    buttonProgress: {
        position: "absolute"
    },
    extraAccBtn: {
        display: "flex",
        width: "100%",
        paddingTop: "10px"
    },
    accountBtn: {
        cursor: "pointer",
        marginRight: "5px",
        float: "left",
        padding: "5px",
        fontSize: "0.75rem",
        fontWeight: "500",
        '&:hover': {
            background: "#e8f0fe",
            borderRadius: "5px",
        },
    },
    forgetBtn: {
        cursor: "pointer",
        marginRight: "5px",
        float: "right",
        padding: "5px",
        fontSize: "0.75rem",
        fontWeight: "500",
        '&:hover': {
            background: "#e8f0fe",
            borderRadius: "5px",
        },
    },
    skyline: {
        position: "fixed",
        width: "100%",
        left: "0",
        opacity: "0.1",
    },
    iframeDiv: {
        float: "left",
        width: "100%",
        marginTop: "40px"
    },
    mainError: {
        padding: '20px',
        margin: '0 auto',
        left: "50%",
        width: "22em",
        height: "22em",
        border: "1px solid #ccc",
        backgroundColor: "#fff",
        borderRadius: "10px",
    },
    errorCard: {
        ['@media (max-width: 768px)']: {
            width: 'auto',
            height: 'auto'
        },
        width: '40%',
        borderRadius: '10px',
        position: 'absolute',
        display: 'inline-block',
        top: '0',
        right: '0',
        left: '0',
        bottom: '0',
        margin: 'auto',
        height: '400px',
        border: '1px solid white'
    },
    mainAcademy: {
        position: "fixed",
        top: "310px",
        left: "calc(50% - 11em)",
        width: "44em",
        marginTop: "-10em",
        marginLeft: "-11em",
        border: "1px solid #ccc",
        backgroundColor: "#fff",
        borderRadius: "10px",
        zIndex: "99",
    },
    siteLogoAcademy: {
        backgroundSize: "120px 108px",
        padding: "25px",
        height: '85px',
        zIndex: '1',
    },
    titleAcademy: {
        fontSize: "22px",
        paddingBottom: "20px",
        fontWeight: "500",
    },
    titleAcademytext: {
        fontSize: "16px",
        paddingBottom: "20px",
    },
    AcceptContinueBtn: {
        marginTop: "20px",
        // fontSize: "20px",
        // width: "40%",
        // border: "2.5px solid #3772FF",
    },
    cancelBtn: {
        marginTop: "20px",
        marginRight: "16px",
        // fontSize: "20px",
        color: "#3772FF",
        // width: "40%",
    },

    boxContainer: {
        width: "100%",
        maxWidth: "600px",
        textAlign: 'left',
        padding: "24px",
        borderRadius: "10px",
        background: "#fff",
        margin: "45px auto 0",
    },
    seekomBtn: {
        color: "#84bc37",
        fontSize: "15px",
        ['@media (max-width: 768px)']: {
            marginTop: "10px",
        },
        marginTop: "85px",
        fontWeight: "400",
        textTransform: "inherit",
        height: "45px",
        borderRadius: "2px",
        padding: "3px 16px",
        width: "200px",
        marginLeft: "-10px"
    },
    twoFields: {
        display: "flex"
    },
    fistFlex: {
        marginRight: "8px"
    },
    secondFlex: {
        marginLeft: "8px"
    },
    loginLogoCard:{
        background:"#5b48ee",display:'flex',alignItems:'center',justifyContent:'center',
        
    },
    loginFormCard:{background:'#f2f2f2',display:'flex',alignItems:'center',justifyContent:'center'},
    cardFormDiv:{
        padding:'40px 20px 40px 20px',border:'1px solid #f2f2f2',borderRadius:'10px',background:'white',width:'70%',
        ['@media (max-width: 768px)']: {
            width:'100%'
        }
    },
    siteLogo1: {
        // backgroundSize: "116px 85px",
        margin:'auto',
        padding: "10px",
        height: '245px',
        width:'347px',
        zIndex: '1',
        margin: 'auto',
        ['@media (max-width: 768px)']: {
            width:'97px',
            height: '70px',
            margin:'10px'
        }  
    },
};
export default styles;