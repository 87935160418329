import React, { Component } from 'react';
import './App.css';
import { withNamespaces } from 'react-i18next';
import MainContainer from './mainContainer';
import FindError from './App/findError';
import * as Sentry from '@sentry/browser';


// Sentry.init({ dsn: 'https://535f8aab2f924f2982ba033f294028f0@sentry.io/1428947',environment:"staging" })
// live app
if (process.env.REACT_APP_ENV == 'live') {
  Sentry.init({ dsn: 'https://535f8aab2f924f2982ba033f294028f0@sentry.io/1428947', environment: "live" });
}

class App extends Component {

  state = {
    isError: false
  }

  componentDidCatch(error, errorInfo) {
    
    Sentry.captureException(new Error('Oh, snap!, please check user get error !!!!'));

    this.setState({
      isError: true
    })
  }

  render() {
    let isError = this.state.isError;

    return (
      <div className="App">
        {/* <MainContainer /> */}
        {isError ? <FindError /> : <MainContainer />}
      </div>
    );
  }
}
export default withNamespaces('translations')(App);
