import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';
import moment from "moment"
const initialState = {
    hotelDetails: {
        token: null,
        hotel: {
            id: null,
            lat: null,
            lng: null,
            address: null,
            region: null,
            postcode: null,
            country: {
                id: null,
                name: null,
                abbreviation: null
            },
            name: null,
            ota_reference: null,
            website: null,
            review: null
        },
        access_level: null
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.HOTEL_AUTH_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.HOTEL_AUTH_SUCCEED:
            return updateObject(state, { loading: false, error: null, hotelDetails: action.data, time: moment.utc(action.time).tz(action.data.hotel.timezone).format(), utcTime: action.time })
        case actionTypes.HOTEL_AUTH_UPDATE:
            return updateObject(state, { loading: false, error: null, hotelDetails: { ...state.hotelDetails, hotel: action.data } })
        case actionTypes.HOTEL_AUTH_FAILED:
            return updateObject(state, { loading: false, error: 'Invalid Credentials',errordata: action.errordata })
        case actionTypes.HOTEL_DETAIL_SUCCEED:
            return updateObject(state, { loading: false, hotelDetails: { ...state.hotelDetails, hotel: action.state } })
        default:
            return (state);
    }
}

export default reducer
