import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../index';
import Notify from "../Notify";


const getpopupAlertMessageSuccess = (data) => {
    return {
        type: actionTypes.POPUP_ALERT_SUCCESS,
        state: data
    }
}
const getpopupAlertMessageFail = (error) => {
    return {
        type: actionTypes.POPUP_ALERT_FAILD,
    }
}
export const popupAlertMessage = (req) => {
    
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + req.token;
        axios.get(Server.API + 'hotel/popup-message/')
        .then((response) => {
            if(response.status == 200){
                dispatch(getpopupAlertMessageSuccess(response.data))
            }
        })
        .catch(function (error) {
            dispatch(getpopupAlertMessageFail(error.data))
        });
    }
}
export const ClosedRoomsDetection = (req) => {
    let reqParams = {
        flag_closed_room_popup:req.flag_closed_room_popup
    }
     return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + req.token;
        axios.patch(Server.API + 'hotel/popup-message/',reqParams)
        .then(function (response) { 
                if(response){
                    axios.defaults.headers.common['Authorization'] = "Token " + req.token;
                    axios.get(Server.API + 'hotel/popup-message/')
                    .then((response) => {
                        if(response.status == 200){
                            dispatch(getpopupAlertMessageSuccess(response.data))
                        }
                    })
                    .catch(function (error) {
                        dispatch(getpopupAlertMessageFail(error.data))
                    });
                    // dispatch(Notify.newUserModal("Enable Detection Successfully",true));
                }                            
        })
        .catch(function (error) {
            dispatch(Notify.newUserModal("Failed Enable Detection",false))
        });
    }
}