import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = [];

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PRICE_NOTE:
            return updateObject(state, { loading: true, notes: action.data, error: null })
        default:
            return updateObject(state, { loading: false, error: null });
    }
}

export default reducer