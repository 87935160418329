import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import ClientList from './clientList';
import ClientRegistration from './clientRegistration';
import ScrapProgram from './scrapProgram';
import ScrapSchedule from './scrapSchedule';
import ScrapList from './scrapList';
import AdminUsers from './adminUsers';
import pmsSetting from './pmsSetting';
import runAlgo from './runAlgo';
import Notfound from './notFound';
import scraper from './scraper';
import resellers from './reseller';
import mailSetting from './mailSetting';
import onDemandSchedule from "./onDemandSchedule";
import scheduleHistory from "./scheduleHistory";
import subscribedClients from './Dashboard/subscribedClients';
import trialClients from './Dashboard/trialClients';
import errorTickets from './errorTickets';
import ErrorTickets from './ErrorTickets/index';
import ReservationFailedTasks from './Dashboard/components/ReservationFailedTasks';
import criticalErrorTickets from './criticalErrorTickets';
import dashboardErrorTickets from './dashboardErrorTickets';
import othersErrorTickets from './othersErrorTickets';

class App extends Component {
    render() {
        return (
            <div className="App">
                <Router />
                <div>
                    <Route exact path="/Admin/Clients/" component={ClientList} />
                    <Route exact path="/Admin/SubscribedClients/" component={subscribedClients} />
                    <Route exact path="/Admin/FreeTrialClients/" component={trialClients} />
                    <Route exact path="/Admin/ClientRegistration/" component={ClientRegistration} />
                    <Route exact path="/Admin/ScrapeProgram/" component={ScrapProgram} />
                    {/* <Route exact path="/Admin/ScrapeSchedule/" component={ScrapSchedule} /> */}
                    <Route exact path="/Admin/ScrapeList/" component={ScrapList} />
                    <Route exact path="/Admin/adminUsers/" component={AdminUsers} />
                    <Route exact path="/Admin/pmsSetting/" component={pmsSetting} />
                    <Route exact path="/Admin/Scraper/" component={scraper} />
                    <Route exact path="/Admin/OnDemand/" component={onDemandSchedule} />
                    <Route exact path="/Admin/ScheduleHistory/" component={scheduleHistory} />
                    <Route exact path="/Admin/resellers/" component={resellers} />
                    {/* <Route exact path="/Admin/mailSettings/" component={mailSetting} /> */}
                    <Route exact path="/Admin/errorTickets/" component={errorTickets} />
                    <Route exact path="/Admin/newErrorTickets/" component={ErrorTickets} />

                    <Route exact path="/Admin/reservationFailedTasks/" component={ReservationFailedTasks} />

                    <Route exact path="/Admin/criticalErrorTickets/" component={criticalErrorTickets} />
                    <Route exact path="/Admin/dashboard-error-tickets/" component={dashboardErrorTickets} />
                    <Route exact path="/Admin/othersErrorTickets/" component={othersErrorTickets} />
                    {/* <Route exact path="/Admin/runAlgo" component={runAlgo} /> */}
                    <Route component={Notfound} />
                </div>
            </div>
        );
    }
}

export default withNamespaces('translations')(App);
