import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import AccountSuspendedCss from './css/AccountSuspendedCss';
import { withNamespaces } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { AccountSuspendedTheme } from "../../../muiTheme";
import MUIDataTable from "mui-datatables";
const styles = AccountSuspendedCss;

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#00000059",
    color: "#fff",
    padding: "4px 20px 4px 15px"
  },
  body: {
    fontSize: 14,
    padding: "4px 20px 4px 15px"
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

class AccountSuspended extends React.Component {

  state = {
    suspendedHotelList:[]
  }
  componentWillReceiveProps(props) {
    if(this.props.suspended_hotel_lists){
      this.setState({
        suspendedHotelList:this.props.suspended_hotel_lists
      })
    }
  }
  options = () => {
    return {
        filter: false,
        responsive: 'scroll',
        print: false,
        download: false,
        viewColumns: false,
        responsive: "scroll",
        selectableRows: false,
        rowsPerPage: 10,
        onRowClick: (rowData) => {
          let id = rowData[0];
          window.open("client/Calendar/" + id,'_blank');
      },
    }
  };

  renderAccontSuspandedData=(data)=>{
    let jsx = [];
        data.map((row) => {
            jsx.push([
              row.id,
              row.name+'('+row.id+')',
              row.in_days === '' || row.in_days === null ? 'n/a' : row.in_days + 'd',
              row.last_login_on === '' || row.last_login_on === null ? 'n/a' : row.last_login_on,
            ])
            return null;
        })
        return jsx;
  }
  render() {
    const { classes, t } = this.props;
    const columns = [
      {
        name: "id" ,
        options: {
          display:false
        }
      },
      {
        name: "Hotel" , 
      },
      {
        name:'Last Login Date'
      },
      {
        name:'Last Login Days'
      }
    ]
    return (
      <Card className={classes.root}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="subtitle1" variant="subtitle1" align={"left"} className={classes.titleBottom}>
              Account Suspended <span className={classes.tabletitle}>Total: {this.props.suspended_hotel_lists_count}</span>
            </Typography>
            <MuiThemeProvider theme={AccountSuspendedTheme}>
              <MUIDataTable
                  title={''}
                  data={this.renderAccontSuspandedData(this.state.suspendedHotelList)}
                  columns={columns}
                  options={this.options()}
              />
            </MuiThemeProvider>
          </CardContent>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userSession: state.sessionReducer,
    hotelAuthReducer: state.hotelAuthReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default compose(withStyles(styles, {
  withTheme: true
}), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(AccountSuspended));
