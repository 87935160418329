import React, { Component } from 'react';
import './App.css';
import Login from './App/login';
import Sidebar from './App/sidebar';
import { connect } from "react-redux";
import { compose } from 'redux';
// import Particals from './App/loginBG';
import AdminSidebar from './App/adminSidebar';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import PasswordRecovery from './App/passwordRecovery';
import BookingSuitTest from './App/BookingSuitTest';
import AcademyMoodle from './App/academyMoodle';
import AcademyAccount from './App/academyAccount';
import * as actions from './store/actions/index';
import fingerprint from 'fingerprintjs'
import jwt from 'jsonwebtoken';
import InvitedUser from './App/InvitedUser';
import axios from 'axios';
import Server from './config/server';


class MainContainer extends Component {


  componentDidMount() {

    let url_string = window.location.href;
    let url = new URL(url_string);

    let iframeUrlSession = url.searchParams.get("iframeUrlSession");

    if (iframeUrlSession) {
      let finderprint = new fingerprint().get();
      let credential = jwt.verify(iframeUrlSession, finderprint.toString());

      this.props.directLogin({ token: credential })
    }
    if(localStorage.getItem("email")){
      let email=localStorage.getItem("email")
      let name=localStorage.getItem("name")
      let data={
        "email":email,
        "firstName": name,
        "lastName": name
      }
      axios.defaults.headers.common['Authorization'] = "Token " + this.props.userSession.token;
      axios.post(Server.API + 'hotel/hubspot/visitor-identification/', data)
      .then((response) => {
        window.hsConversationsSettings = {
          identificationEmail: email,
          identificationToken: response.data.token
       };
       if( window.HubSpotConversations.widget){
          window.HubSpotConversations.widget.load();
        }
       
        

      }).catch((error) => {
        console.log(error)
      }); 

    }else{
      if( window.HubSpotConversations){
        window.HubSpotConversations.widget.load();
      }
    }
  }
  sessionCheck = () => {
    let clientPath = window.location.pathname;
    let pathArray = clientPath.match(/([\d]+)/g)
    let clientID = pathArray ? pathArray[0] : '';

    let url_string = window.location.href;
    let url = new URL(url_string);
    let code = url.searchParams.get("code");
    let state = url.searchParams.get("state");
    let email_token = url.searchParams.get("email_token");

    let iframeUrlSession = url.searchParams.get("iframeUrlSession");

    // AcademyMoodle
    const client_id = url.searchParams.get('client_id');
    const response_type = url.searchParams.get('response_type');
    const redirect_uri = url.searchParams.get('redirect_uri');
    const states = url.searchParams.get('state');
    const scope = url.searchParams.get('scope');
    if (state && state != "apaleo" && !client_id && state.includes("Connections")) {
      state = state + '&code=' + code;
      window.location.href = state;
    }else  if (state && state != "apaleo" && !client_id && state.includes("seekom")) {
      state = state + '&code=' + code;
      window.location.href = state;
    }
    let userData = this.props.sessionReducer;

    if (email_token) {
      return (
        <InvitedUser />
      )
    }

    if (window.location.pathname == "/academy-signup") {
      return (
        <div>
          <Router />
          <Route exact path="/academy-signup" component={AcademyAccount} />

        </div>)
    }

    if (userData.token && client_id && redirect_uri) {
      if (localStorage.getItem("is_authorize_in_moodle") === "true") {
        this.props.moodleAuthAuthorize(this.props.userSession)
      } else {
        return (
          <>
            <Router />
            <Route exact path="/OAuth2Authorize" component={AcademyMoodle} />
          </>
        )
      }

    }
    else if (userData.token) {
      if (userData.user.is_staff || userData.user.sub_account_type) {
        if (clientID) {
          return (
            <Sidebar></Sidebar>
          )
        }
        return (
          <AdminSidebar></AdminSidebar>
        )
      } else {
        return (
          <Sidebar></Sidebar>
        )
      }
    } else {
      return (
        <div>
          {/* <Particals></Particals> */}
          <Router />
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/reset-password/:resetToken" component={PasswordRecovery} />
          <Route exact path="/booksuit-login" component={BookingSuitTest} />
          <Route exact path="/OAuth2Authorize" component={Login} />
          <Route exact path="/academy-signup" component={AcademyAccount} />

        </div>)
    }
  }
  render() {
    return (
      <div className="App">
        {this.sessionCheck()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    hotelAuthReducer: state.hotelAuthReducer,
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    user: state.authReducer.UserData,
    sessionReducer: state.sessionReducer,
    userSession: state.sessionReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    directLogin: (request) => { dispatch(actions.directLogin(request)) },
    moodleAuthAuthorize: (request) => { dispatch(actions.moodleAuthAuthorize(request)) }
  };
};
export default compose(connect(mapStateToProps, mapDispatchToProps))(MainContainer)
