var style = {
 gridContainer: {
        marginLeft:"20px"
},
filter:{
    marginTop:'-15px',
    textAlign:'right',
    marginRight:"20px"
},
formControl:{
    marginLeft:'30px'
},
divStyle: {
    float: "left", 
    marginTop: "2px"
},
fontStyle: {
    textAlign: "left", 
    fontWeight: "500"
}
};
export default style;