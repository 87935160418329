import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../index';


const onScrapProgramStart = () => {
    return {
        type: actionTypes.GET_SCRAP_LIST_START,
        error: null
    }
}

const onScrapProgramFailed = (error) => {
    return {
        type: actionTypes.GET_SCRAP_LIST_FAILED,
        error: error
    }
}

const onScrapProgramSucceed = (data) => {
    return {
        type: actionTypes.GET_SCRAP_LIST_SUCCEED,
        state: data
    }
}

export const getScrapList = (request) => {
    return dispatch => {
        dispatch(onScrapProgramStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        let url = (request.url ? (Server.API + request.url) : (Server.API + 'hotel/scrape/list/'));
        axios.get(url)
            .then(function (response) {
                dispatch(onScrapProgramSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onScrapProgramFailed(error))
            });
    }
}

export const deleteScrapList = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.delete(Server.API + 'hotel/scrape/list/delete/' + request.id + '/')
            .then(function (response) {
                dispatch(actions.getScrapList(request))
            })
            .catch(function (error) {
                if(error.response.data.competitor_list.message){
                    dispatch(onScrapProgramFailed(error.response.data.competitor_list.message))
                }else{                    
                    dispatch(onScrapProgramFailed(error))
                }
            });
    }
}


