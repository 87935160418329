var style = {
  manageAcc: {
    margin: "0px 0px 10px 0px",
    float: "left",
    minWidth: "250px",
    ["@media (max-width: 414px) and (min-width: 360px)"]: {
      minWidth: "100%",
    },
  },
  manageYrAcc: {
    margin: "10px 20px 10px 0px",
    color: "#707070 !important",
    float: "left",
    ["@media (max-width: 414px) and (min-width: 360px)"]: {
      minWidth: "100%",
    },
  },
  manageYr: {
    margin: "10px 20px 10px 0px",
    float: "left",
    ["@media (max-width: 414px) and (min-width: 360px)"]: {
      minWidth: "100%",
    },
  },
  editBtn: {
    marginLeft: "20px",
  },
  title: {
    width: "40%",
    fontWeight: "500",
  },
  titleForTrial: {
    fontWeight: "400",
    paddingLeft: "0px !important",
    borderBottom: "none",
  },
  trialAlignment: {
    backgroundColor: "#FFEF4D",
    margin: "33px 15px 0px 15px",
    display: "block",
    width: "21%",
    borderRadius: "4px",
    padding: "9px",
    textAlign: "center",
    ["@media (max-width: 720px) and (min-width: 320px)"]: {
      width: "80%",
    },
  },
  trialAlignment1: {
    backgroundColor: "#FFEF4D",
    margin: "25px 15px 0px 15px",
    display: "block",
    width: "50%",
    borderRadius: "4px",
    padding: "9px",
    textAlign: "center",
    ["@media (max-width: 720px) and (min-width: 320px)"]: {
      width: "89%",
    },
  },
  settingTable: {
    width: "100%",
  },
  trialNote: {
    padding: "15px",
    borderRadius: "5px",
  },
  trialColor: {
    // background: '#fe87b7',
    // padding: '5px 5px 5px 5px',
    borderRadius: "5px",
    // color: "#fff"
  },
  marginTop10: {
    marginTop: "10px",
  },
  marginTop20: {
    marginTop: "20px",
  },
  paymentBtn: {
    display: "flex",
    flexDirection: "column",
  },
  radioPartner: {
    flexDirection: "row",
    ["@media (max-width: 414px)"]: {
      width: "100%",
    },
  },
  fullWidth: {
    ["@media (max-width: 414px) and (min-width: 360px)"]: {
      maxWidth: "100%",
    },
  },
  noPadding: {
    ["@media (max-width: 414px) and (min-width: 360px)"]: {
      padding: "0",
    },
  },
  paddingSetting: {
    "padding-left": "15px !important",
  },
  padding10: {
    padding: "10px",
    ["@media (max-width: 414px) and (min-width: 360px)"]: {
      paddingLeft: "0",
    },
  },
  leftPadding: {
    paddingLeft: "12px",
    ["@media (max-width: 414px) and (min-width: 360px)"]: {
      padding: "0 !important",
    },
  },
  subscriptionCard: {
    // "&:hover": {
    //   border:'2px solid #3e72ff !important',
    // },
    border: "2px solid #D3D3D3 !important",
    margin: "12px",
    borderRadius: "5px",
  },
  subscriptionCardFlexible: {
    border: "2px solid #3e72ff !important",
    margin: "12px",
    borderRadius: "5px",
  },
  subscriptionCardHeader: {
    padding: "15px 15px 15px 15px",
    borderBottom: "1px solid #D3D3D3",
  },
  title: { fontWeight: "400" },
  subscriptionCardContent: {
    textAlign: "left",
    color: "#807172",
    height: "130px",
  },
  MuiCardContent: { padding: "10px 10px 10px 10px !important" },
  subscriptionGrid: {
   
    "@media (max-width: 1280px)": {
    maxWidth: "30% !important",
    flexBasis: "30% !important",
  }
  },
  subContent: { fontWeight: "400", textAlign: "left" },
  priceContent: { fontWeight: "400", textAlign: "left", fontSize: "16px" },
  subscribeBtn: { marginTop: "5px" },
  topContent: { fontWeight: "500", textAlign: "left", marginTop: "15px" },
  checkIcon: {
    textAlign: "left",
    color: "green",
    width: "15px",
    marginBottom: "-7px",
  },
  checkIconRed: {
    textAlign: "left",
    color: "red",
    width: "15px",
    marginBottom: "-7px",
  },
  newTabBtnFirst: {
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    padding: "5px 10px",
    float: "left",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.875rem",
    fontWeight: 400,
  },
  newTabBtnFirstBorderRadius: {
    padding: "5px 10px",
    float: "left",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.875rem",
    fontWeight: 400,
  },
  newTabBtnLast: {
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    float: "left",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.875rem",
    fontWeight: 400,
    padding: "5px 10px",
  },
  headigTop: {
    textAlign: "left",
    margin: "15px",
    padding: "0px",
  },
  mainCardContent: { margin: "15px", padding: "0px" },
  MuiCardContent2: {
    padding: "10px 10px 10px 10px !important",
    height: "350px",
  },
  MuiCardContentServices: {
    padding: "10px 10px 10px 10px !important",
    height: "200px",
  },
  datePickerDiv: {
    float: "left",
    margin: "0px 15px 0px 15px",
  },
  dateSaveBtn: {
    ["@media (max-width: 740px) and (min-width: 320px)"]: {
      marginTop: "0px",
      marginLeft: "0px",
    },
    float: "left",
    marginTop: "37px",
    marginLeft: "13px",
  },
  togglesubBtn: {
    margin: "-24px 15px 0px 0px",
    float: "left",
  },
  titleGray: {
    color: "#9fa1a6",
  },
  helpText: {
    color: "#707070",
  },
  divStyle: {
    float: "left",
    marginTop: "2px",
  },
  fontStyle: {
    textAlign: "left",
    fontWeight: "500",
  },
  divTypography: {
    marginBottom: "20px",
  },
  mailToggle: {
    float: "left",
  },
  formControl: {
    paddingBottom: "24px",
  },
  trialSpan: {
    fontWeight: "bold",
  },
  renderNote: {
    marginBottom: "10px",
  },
  subscribeMsg: {
    float: "left",
    margin: "15px 15px 0px 15px",
    fontSize: "16px",
  },
  yrAccGrid: {
    float: "left",
    margin: "9px 15px 0px 15px",
  },
  mailToggleGrid: {
    float: "left",
    margin: "-12px 15px 0px 15px",
  },
  toggleGrid: {
    float: "left",
    margin: "39px 0px 24px 15px",
  },
  perMonath: {
    fontSize: "1.2rem",
    fontWeight: "500",
  },
  perMontheSpan: {
    fontSize: "14px",
    position: "absolute",
    marginTop: "4px",
    marginLeft: "4px",
  },
  perYear: {
    fontSize: "14px",
  },
  perYearSpan: {
    fontSize: "14px",
    marginLeft: "3px",
  },
  helpFont: {
    marginBottom: "-16px !imporant",
    marginTop: "24px !imporant",
  },
  btnsub: {
    padding: "5px 7px",
  },
  typoText: {
    textAlign: "left",
  },
  priceCheck: {
    marginTop: "0px",
  },
  subFeature: {
    marginLeft: "18px",
  },
  subContent2: {
    marginBottom: "-16px !imporant",
    marginTop: "24px !imporant",
    color: "#9fa1a6",
  },
  typoColor: {
    textAlign: "left",
    color: "#9fa1a6",
  },
  featureColor: {
    color: "#9fa1a6",
  },
  priceCheck2: {
    marginRight: "0px",
  },
  typoText2: {
    marginLeft: "16px",
  },
  chargebeeBtn: {
    float: "left",
    marginLeft: "18px",
    ["@media (max-width: 414px) and (min-width: 360px)"]: {
      marginLeft: "0px",
      marginTop: "10px",
    },
  },
  tableHeader:{
    fontSize: '16px',
    color: '#1f1e1e'
  },
  tableCard:{
    marginTop: "16px",
    padding: "16px"
  },
  redirectBtn: {
    margin: "5px 0px 16px 16px",
    color: "#707070 !important",
    float: "left",
    ["@media (max-width: 414px) and (min-width: 360px)"]: {
      minWidth: "100%",
    },
  },
};
export default style;
