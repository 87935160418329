import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../index';
import Notify from '../Notify';
import moment from 'moment';
import { ValidateBaseSettings } from '../../../../src/App/Utils/SettingValidation/Validation';

const onDailyAdjustListStart = () => {
    return {
        type: actionTypes.DAILY_ADJUSTMENT_START
    }
}

const onDailyAdjustListFailed = (error) => {
    return {
        type: actionTypes.DAILY_ADJUSTMENT_FAILED,
        error: error
    }
}

const onDailyAdjustListSucceed = (data) => {
    return {
        type: actionTypes.DAILY_ADJUSTMENT_SUCCEED,
        state: data
    }
}

export const dailyAdjustList = (request) => {
    return dispatch => {
        // dispatch(onDailyAdjustListStart())
        // axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        // axios.get(Server.API + 'hotel/adjustment/individual/list/')
        //     .then(function (response) {
        //         dispatch(onDailyAdjustListSucceed(response.data))
        //     })
        //     .catch(function (error) {
        //         dispatch(onDailyAdjustListFailed(error))
        //     });
    }
}

const onDailyAdjustCreateFailed = (error) => {
    return {
        type: actionTypes.DAILY_ADJUSTMENT_CREATE_FAILED,
        error: error
    }
}

const runPriceOnskip = (jsonSetting, date, dispatch) => {
   
    let requestParam = {
        json_settings: jsonSetting,
        is_write_to_cache: true,
        start_date: date,
        end_date: date
    }

    axios.post(Server.API + 'hotel/adjustment/individual/apply/', requestParam)
        .then((response) => {
            let err = 0;
            Object.keys(response.data.data).map(function (key) {
                if (response.data.data[key].error) {
                    err = response.data.data[key].reason;
                }
            })

            if (err != 0) {                
                dispatch(Notify.newUserModal(err, false))
            }

        })
        .catch((error) => {
            dispatch(Notify.newUserModal('Error while Applying the Changes', false))
        });
}


export const dailyAdjustCreate = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'hotel/pricing-settings/')
            .then(function (res) {
                let jsonData = res.data;

                if (!jsonData.dates[request.date]) {
                    jsonData.dates[request.date] = {};
                }
                jsonData.dates[request.date].individual_adjustment = {
                    name: null,
                    percentage: request.percentage
                }

                let latestJson = ValidateBaseSettings({ dateSetting: jsonData });
                let reqParams = {
                    settings: JSON.stringify(latestJson.dateSetting),
                };
                axios.defaults.headers.common['Authorization'] = "Token " + request.token;
                axios.post(Server.API + 'hotel/pricing-settings/', reqParams)
                    .then(function (response) {
                        dispatch(actions.getPricingSettings(request))
                        dispatch(Notify.newUserModal("Single date adjustment created successfully", true))
                        runPriceOnskip(reqParams.settings, request.date, dispatch)
                    })
                    .catch(function (error) {
                        dispatch(onDailyAdjustCreateFailed(error))
                        dispatch(Notify.newUserModal("An error occured while creating Single date adjustment", false))
                    });
            })
    }
}

export const updateDailyAdjust = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'hotel/pricing-settings/')
            .then(function (res) {
                let jsonData = res.data;

                if (!jsonData.dates[request.date]) {
                    jsonData.dates[request.date] = {};
                }
                jsonData.dates[request.date].individual_adjustment = {
                    name: null,
                    percentage: request.percentage
                }

                let latestJson = ValidateBaseSettings({ dateSetting: jsonData });
                let reqParams = {
                    settings: JSON.stringify(latestJson.dateSetting),
                };
                axios.defaults.headers.common['Authorization'] = "Token " + request.token;
                axios.post(Server.API + 'hotel/pricing-settings/', reqParams)
                    .then(function (response) {
                        dispatch(actions.getPricingSettings(request))
                        dispatch(Notify.newUserModal("Single date adjustment updated successfully", true))
                        runPriceOnskip(reqParams.settings, request.date, dispatch)
                    })
                    .catch(function (error) {
                    });
            })
    }
}


export const deleteDailyAdjust = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'hotel/pricing-settings/')
            .then(function (res) {
                let jsonData = res.data;

                delete jsonData.dates[request.date].individual_adjustment

                let latestJson = ValidateBaseSettings({ dateSetting: jsonData });
                let reqParams = {
                    settings: JSON.stringify(latestJson.dateSetting),
                };
                axios.defaults.headers.common['Authorization'] = "Token " + request.token;
                axios.post(Server.API + 'hotel/pricing-settings/', reqParams)
                    .then(function (response) {
                        dispatch(actions.getPricingSettings(request))
                        dispatch(Notify.newUserModal("Single date adjustment deleted successfully", true))
                        runPriceOnskip(reqParams.settings, request.date, dispatch)
                    })
                    .catch(function (error) {
                    });
            })
    }
}


