import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";
import { compose } from 'redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import FreeTrialExpiredLastLoginCss from './css/FreeTrialExpiredLastLoginCss';
import { withNamespaces } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Modal from '../modal/modal'
import * as actions from '../../../../store/actions/index';
import Button from '@material-ui/core/Button';
import { Icon } from '@material-ui/core';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { freeTrialExpiredLastLoginTheme } from "../../../muiTheme";
import MUIDataTable from "mui-datatables";
const styles = FreeTrialExpiredLastLoginCss;
const status_types = {
  '1': 'Open',
  '2': 'In Progress',
  '3': 'Closed'
}

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#00000059",
    color: "#fff",
    padding: "4px 20px 4px 15px"
  },
  body: {
    fontSize: 14,
    padding: "4px 20px 4px 15px"
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

class FreeTrialExpiredLastLogin extends React.Component {

  state = {
    showComments: false,
    error: [],
    type: 'last_login',
    comments: '',
    ticket_id: '',
    status: '',
    freeTrialLastLoginList:[]
  };
  componentWillReceiveProps(props) {
    if(props.free_trial_last_login){
      this.setState({
        freeTrialLastLoginList:props.free_trial_last_login,
      })
    }
  }
  toggleCommentsModal = (details) => {
    this.setState({
      showComments: !this.state.showComments,
      comments: details.comments,
      status: details.status,
      ticket_id: details.ticket_id
    })
  }

  handleChange = () => event => {
    this.setState({
      comments: event.target.value
    });
  }

  updateComment = () => {
    let requestParams = {
      token: this.props.userSession.token,
      comments: this.state.comments,
      ticket_id: this.state.ticket_id,
      ticket_type: this.state.type,
      deal_owner_filter: this.props.deal_owner_filter,
      deal_owner_type: this.props.deal_owner_type,
      status_type: this.props.status_type,
      status: this.state.status
    };
    this.props.updateComment(requestParams)
    this.toggleCommentsModal({})
  }

  handleChangeStatus = (event) => {
    this.setState({
      ...this.state,
      status: event.target.value
    });
  }
  options = () => {
    return {
        filter: false,
        responsive: 'scroll',
        print: false,
        download: false,
        viewColumns: false,
        selectableRows: false,
        rowsPerPage: 10,
        onCellClick: (colData, cellMeta) => {
          if(cellMeta.colIndex != 5){
            let id = this.state.freeTrialLastLoginList[cellMeta.rowIndex].id
            window.open("client/Calendar/" + id,'_blank');
          }
        },
    }
  };

  renderFreeTrialLastLoginData=(data)=>{
    let jsx = [];
        data.map((row) => {
            jsx.push([
              row.id,
              row.name+'('+row.id+')' ,
              row.last_login_on === '' || row.last_login_on === null ? 'n/a' : row.last_login_on,
              row.in_days === '' || row.in_days === null ? 'n/a' : row.in_days + 'd',
              row.status === '' ? 'n/a' : status_types[row.status],
              row
            ])
            return null;
        })
        return jsx;
  }
  render() {
    const { classes, t } = this.props;
    const columns = [
      {
        name: "id" ,
        options: {
          display:false
        }
      },
      {
          name: " Hotel" ,
          options: {
            filter: false,
          }
      },
      {
        name: "Last Login Date" ,
        options: {
          filter: false,
        }
      },
      {
        name: "Last Login " ,
        options: {
          filter: false,
        }
      },
      { 
          name: "Status" ,
          options: {
            filter: false,
          }
      },
      { 
        name: "" ,
        ignoreRowClick: true,
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
                <React.Fragment>
                  <Button className={classes.buttonItems} color="primary" onClick={(id) => this.toggleCommentsModal(value)} title="Edit Ticket"><Icon>edit</Icon></Button>
                </React.Fragment>
            );
          }
            
          }
    }
      
    ];
    return (
      <Card className={classes.root}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="subtitle1" variant="subtitle1" align={"left"} className={classes.titleBottom}>
              Last Trial Login <span className={classes.tabletitle}>Total: {this.props.free_trial_last_login_count}</span>
            </Typography>
            <MuiThemeProvider theme={freeTrialExpiredLastLoginTheme}>
              <MUIDataTable
                  title={''}
                  data={this.renderFreeTrialLastLoginData(this.state.freeTrialLastLoginList)}
                  columns={columns}
                  options={this.options()}
              />
            </MuiThemeProvider>

            {Modal.commentsModal(this)}
            {/* {Modal.confirmModal(this)} */}
          </CardContent>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userSession: state.sessionReducer,
    hotelAuthReducer: state.hotelAuthReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateComment: (request) => { dispatch(actions.updateComment(request)) },
    closeTicket: (request) => { dispatch(actions.closeTicket(request)) }
  };
};

export default compose(withStyles(styles, {
  withTheme: true
}), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(FreeTrialExpiredLastLogin));
