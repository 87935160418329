var style = {
  RadioGroup: {
    display: 'inline-block',
  },
  emailLabel: {
    marginTop: '20px'
  },
  userTable: {
    padding: '20px'
  },
  addUserBtn: {
    margin: '-10px ​0px 10px 0px',
    float:'left'
  },
  root: {
    flexGrow: 1,
  },
  dateTableDiv: {
    overflow: 'auto'
  },
  predefineRateSwich: {
    float: 'left',
    padding:'15px'    
  },
  tablePadding: {
    padding: '4px 0px 4px 24px'
  },
  MainLoader: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    background: 'rgb(15, 51, 80, 0.3)',
    zIndex: '9',
  },
  loader: {
    position: 'absolute',
    top: '35%',
    left: '35%'
  },
  textField:{
      float:'right',
      width:'48%',
      ['@media (max-width: 540px) and (min-width: 320px)']: {
        width:'100%'
      }
  },
  textFieldLeft:{
      float:'left',
      marginRight:'2%',
      width:'48%',
      ['@media (max-width: 540px) and (min-width: 320px)']: {
        width:'100%'
      }
  },
  actionIcon: {
    padding: '0',
    cursor: 'pointer',
    minWidth: '30px'
  },
  gridContainer:{
      textAlign:'left'
  },
  subTitle:{
      textAlign: 'left',
      margin : '10px 15px'
  },
  helpText:{
    maxWidth: "950px", 
    textAlign: "justify",
    margin: "12px 0px 15px 0px",
    color:"#707070"
  },
  divStyle: {
    float: "left", 
    marginTop: "2px"
  },
  fontStyle: {
    textAlign: "left", 
    fontWeight: "500"
  },
  btnAdd: {
    display:"flex"
  },
  mainCard: {
    maxWidth:"50%"
  },
  delIcon: {
    color:"#541388"
  }
}
export default style;