import {
    MuiThemeProvider,
    createMuiTheme
} from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import purple from '@material-ui/core/colors/purple';

export const AcademyAccountTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
        fontSize: 12,
        color: "red"
    },
    overrides: {
        MuiDialog: {
            paperWidthMd: {
                borderRadius: '15px'
            }
        },
        // MuiFormControlLabel:{
        // 	root:{
        // 		fontSize:'14px'
        // 	}
        // },
        MuiSvgIcon: {
            root: {
                fontSize: '28px'
            }
        },
        MuiTypography: {
            h5: {
                fontWeight: '500',
                fontSize: '1.5rem'
            },
            body2: {
                fontSize: '14px'
            }
        },
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            },
            label: {
                fontSize: '0.875rem'
            }
        },
        MuiIcon: {
            root: {
                color: '#D8DAE5'
                // color: "#A0A2A6"
            }
        },
        MuiListItemText: {
            primary: {
                // color: "#000000de"
                color: '#D8DAE5'
            }
        },
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                marginTop: "20px !important",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
            }
        },
        MuiFormControlLabel: {
            root: {
                height: '37px',
            }
        }
    }
});

export const AcademyMoodleTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#3772FF"
        }, //1098F7 2196f3
        secondary: {
            main: "#541388"
        }, //564787
        background: {
            default: '#f0f4f9'
        }
    },
    typography: {
        useNextVariants: true,
        fontSize: 12,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            }
        },
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                marginTop: "20px !important",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
            }
        }
    }
});

export const adminSidebarlistTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
        fontSize: 12,
    },
    overrides: {
        MuiDialogActions: {
            root: {
                justifyContent: "flex-start",
                margin: "8px 20px",
            }
        },
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MuiListItem: {
            button: {
                '&:hover': {
                    background: '#3e72ff63 !important'
                },
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
            },
        },
        MuiIcon: {
            root: {
                // color: "#A0A2A6"
                color: '#D8DAE5'
            }
        },
        MuiSvgIcon: {
            root: {
                // color: "#A0A2A6"
                color: '#D8DAE5'
            }
        },
        MuiListItemText: {
            primary: {
                // color: "#000000de"
                color: '#D8DAE5'
            }
        },
        MuiCardHeader: {
            root: {
                padding: "16px 16px 16px 20px"
            },
            title: {
                fontWeight: "500"
            }
        },
        MuiDialog: {
            paper: {
                borderRadius: "15px",
                boxShadow: "none"
            }
        },
    }
});

export const adminSidebarTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#3772FF"
        },
        secondary: {
            main: "#541388"
        },
        background: {
            default: '#f0f4f9'
        }
    },
    typography: {
        useNextVariants: true,
        fontSize: 14,
    },
    overrides: {
        MuiDialogActions: {
            root: {
                justifyContent: "flex-start",
                margin: "8px 20px",
            }
        },
        MuiCardHeader: {
            root: {
                padding: "16px 16px 16px 20px"
            },
            title: {
                fontWeight: "500"
            }
        },
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MuiCard: {
            root: {
                borderRadius: "15px",
                boxShadow: 'none',
                border: "1px solid #e5e8ec"

            }
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: "#292f4c",
                color: "#1d2034",
                boxShadow: "none"
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            }
        },
        MuiIconButton: {
            root: {
                // color:'#d8dae5 !important',
            }
        },
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                marginTop: "20px !important",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiDrawer: {
            paper: {
                '&:hover': {
                    overflowY: 'auto'
                },
                overflowY: 'hidden'
                // borderRight: "none !important"
            }
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
            }
        },
        MuiDialog: {
            paper: {
                borderRadius: "15px",
                boxShadow: "none"
            }
        },

    }
});

export const adminSidebarThemeBtn = createMuiTheme({
    overrides: {
        MuiIconButton: {
            root: {
                color: '#d8dae5',
            }
        },
    }
})

export const BookingSuiteTestTheme = createMuiTheme({
    palette: {
        primary: blue,
        secondary: purple,
        background: {
            default: '#f0f4f9'
        }
    },
    typography: {
        useNextVariants: true,
        fontSize: 12,
    },
    MuiInput: {
        error: {
            background: "#ffe4e4",
            border: "1px solid #ffa4a4",
        },
        disabled: {
            background: "#d5d5d5"
        },
        root: {
            background: "#f2f2f2",
            border: "none",
            borderRadius: "4px",
            marginTop: "20px !important",
            padding: "4px 4px 4px 8px",
            '&:hover': {
                background: "#f2f2f2", //535454
                border: "none",
                borderRadius: "4px",
                "&&&:before": {
                    borderBottom: "none"
                },
            },
            '&:focus-within': {
                background: "#e9efff",
                boxShadow: "0px 0px 5px -2px #e9efff",
                border: "none",
                borderRadius: "4px",
                "&&&:before": {
                    borderBottom: "none"
                },
            },
        },
        underline: {
            "&&&:before": {
                borderBottom: "none"
            },
            "&&:after": {
                borderBottom: "none"
            }
        },
    },
    MuiInputLabel: {
        root: {
            fontWeight: "400",
            fontSize: "19px",
            fontWeight: "500",
            marginLeft: "1px",
            color: "#4b4b4b !important",
        }
    }
});

export const HotelListTheme = createMuiTheme({
    typography: {
        useNextVariants: true
    },
    palette: {
        primary: blue,
        secondary: purple,
        background: {
            default: '#f0f4f9'
        }
    },
    typography: {
        useNextVariants: true,
        fontSize: 12,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
            },
        },
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                marginTop: "20px !important",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
            }
        }
    }
});

export const InviteUserTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#3772FF"
        }, //1098F7 2196f3
        secondary: {
            main: "#541388"
        }, //564787
        background: {
            default: '#f0f4f9'
        }
    },
    typography: {
        useNextVariants: true,
        fontSize: 14,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            }
        },
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                marginTop: "20px !important",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
            }
        }
    }
});

export const LoginTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#3772FF"
        }, //1098F7 2196f3
        secondary: {
            main: "#541388"
        }, //564787
        background: {
            default: '#f0f4f9'
        }
    },
    typography: {
        useNextVariants: true,
        fontSize: 14,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            }
        },
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                marginTop: "20px !important",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
            }
        }
    }
});

export const PasswordRecoveryTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#3772FF"
        }, //1098F7 2196f3
        secondary: {
            main: "#541388"
        }, //564787
        background: {
            default: '#f0f4f9'
        }
    },
    typography: {
        useNextVariants: true,
        fontSize: 12,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            }
        },
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                marginTop: "20px !important",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
            }
        }
    }
});

export const SidebarListTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
        fontSize: 12,
        color: "red"
    },
    overrides: {
        MuiDialogActions: {
            root: {
                justifyContent: "flex-start",
                margin: "8px 20px",
            }
        },
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MuiButton: {
            // containedPrimary:{
            //   background:'#0073EA !important',
            // },
            outlinedPrimary: {
                color: '#0073EA !important',
                border: '1px solid #0073EA',
                background: 'white',
                '&:hover': {
                    background: '#f2f2f2'
                }
            },
            root: {
                borderRadius: "4px",
            },
        },
        MuiIcon: {
            root: {
                color: '#D8DAE5'
                // color: "#A0A2A6"
            }
        },
        MuiListItemText: {
            primary: {
                // color: "#000000de"
                color: '#D8DAE5'
            }
        },
        MuiInputBase: {
            inputMultiline: {
                padding: '6px 0 7px'
            }
        },
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                marginTop: "20px !important",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
            }
        }
    },
});

export const Sidebartheme = createMuiTheme({
    palette: {
        primary: {
            main: "#0073EA"
        }, //1098F7 2196f3
        secondary: {
            main: "#541388"
        }, //564787
        background: {
            default: '#f0f4f9'
        }
    },
    typography: {
        useNextVariants: true,
        fontSize: 14,
    },
    overrides: {
        MuiDialogActions: {
            root: {
                justifyContent: "flex-start",
                margin: "8px 20px",
            }
        },

        MuiPaper: {
            root: {
                // boxShadow: 'none !important',
            }
        },
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MuiSwitch: {
            bar: {
                opacity: "0.26"
            },
            colorPrimary: {
                "&$checked": {
                    color: '#0073ea'
                }
            }
        },
        MuiPrivateTabScrollButton: {
            root: {
                width: "30px",
                '@media screen and (min-width: 800px)': {
                    width: "0px "
                }
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 14
            }
        },
        MuiCardHeader: {
            root: {
                padding: "16px 16px 16px 20px"
            },
            title: {
                fontWeight: "500"
            }
        },
        MuiDialog: {
            paper: {
                borderRadius: "15px",
                boxShadow: "none"
            }
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: "#292f4c",
                color: "#6E6F72",
                boxShadow: "none"
            }
        },
        MuiCardContent: {
            root: {
                padding: "20px",
            }
        },
        MuiDrawer: {
            paper: {
                '&:hover': {
                    overflowY: 'auto'
                },
                overflowY: 'hidden'
                // borderRight: "none !important"
            }
        },
        MuiCard: {
            root: {
                maxWidth: "1300px",
                borderRadius: "15px",
                boxShadow: 'none',
                border: "1px solid #e5e8ec",
            }
        },
        MuiButton: {
            // outlined:{
            //   padding: '7px 16px'
            // },
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                // padding: '7px 16px',
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            },
            // containedPrimary:{
            //   background:'#0073EA !important',
            // },
            outlinedPrimary: {
                color: '#0073EA !important',
                border: '1px solid #0073EA',
                background: 'white',
                '&:hover': {
                    background: '#f2f2f2'
                }
            },
        },
        MuiStepper: {
            root: {
                padding: "0px 0px 24px 0px"
            }
        },
        MuiTab: {
            textColorPrimary: {
                color: "#A0A2A6",
                selected: {
                    color: "#0073ea",
                }
            },
            root: {
                textTransform: 'capitalize',
            }
        },
        MuiPrivateNotchedOutline: {
            root: {
                borderRadius: "4px"
            }
        },
        MuiOutlinedInput: {
            borderRadius: "10px",
            notchedOutline: {
                border: "1px solid rgba(0, 0, 0, 0.23) !important"
            }
        },
        MuiInputBase: {
            inputMultiline: {
                padding: '6px 0 7px !important'
            }
        },
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                marginTop: "20px !important",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
            }
        },
        MuiIconButton: {
            root: {
                transition: 'none !important'
            }
        },
        // .MuiFormLabel-root-10725.MuiFormLabel-focused-10726
        // .MuiOutlinedInput-root-10071 .MuiOutlinedInput-notchedOutline-10078

        //.MuiOutlinedInput-root-11266 .MuiOutlinedInput-notchedOutline-11273
        // MuiIconButton:{
        //   root:{
        //     padding:'5px',
        //     '&:hover':{
        //       padding:'5px'
        //     }
        //   }
        // }
    },
    MuiTableRow: {
        root: {
            background: "#F5F5F5", //test-table
            '&:hover': {
                background: "#00000014 !important", //test-table 
            }
        },
        footer: {
            background: "#FFFF", //test-table 
            '&:hover': {
                background: "#FFFF !important", //test-table 
            }
        }
    },
});

export const SidebarMUIIconBtn = createMuiTheme({
    overrides: {
        MuiIconButton: {
            root: {
                color: '#d8dae5',
            }
        },
    }
})

export const adminUserTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table               
                padding: "4px 10px", //test-table
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        }
    },
})

export const clientListTheme = createMuiTheme({
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTableToolbar: {
            root: {
                paddingLeft: "0px",
                paddingTop: "16px"
            },
            left: {
                textAlign: 'left'
            }
        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table
                padding: "4px 10px", //test-table
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left',
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important", //test-table 
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiPopover: {
            paper: {
                boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12) !important'
            }
        }
    },
})

export const criticalErrorTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
       MUIDataTableToolbarSelect: {
            root: {
                zIndex: '0 !important',
            }
        },
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTableToolbar: {
            left: {
                textAlign: 'left'
            }
        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },

        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                         
                padding: "4px 10px", //test-table
                textAlign: 'left',
                '&:nth-child(10)': {
                    minWidth: '120px',
                },
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left',
                ['@media (max-width: 768px)']: {
                    '&:nth-child(10)': {
                        minWidth: '130px',
                    },
                },
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
                textTransform: 'unset',
                fontWeight: '400'
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        },
        MuiCardHeader: {
            root: {
                width: '100%',
                padding: '20px 20px 0px 20px'
            },
            title: {
                fontSize: '1.5rem',
                fontWeight: '500',
            }
        },
        MuiTypography: {
            h6: {
                fontWeight: '500',
                fontSize: '16px',
                margin: '15px'
            }
        },
        MuiCardContent: {
            root: {
                padding: '0px 20px 0px 20px'
            }
        },
        MUIDataTableSelectCell: {
            root: {
                display: 'table-cell !important'
            },
            headerCell:{
                zIndex: '0 !important',
            },
            fixedHeader :{
                position:"inherit !important"
            }
        },
        MuiPopover: {
            paper: {
                boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12) !important'
            }
        }
    },
})

export const errorTicketsTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTableToolbar: {
            left: {
                textAlign: 'left'
            }
        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },

        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                         
                padding: "4px 10px", //test-table
                textAlign: 'left',
                '&:nth-child(10)': {
                    minWidth: '120px',
                },
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left',
                ['@media (max-width: 768px)']: {
                    '&:nth-child(10)': {
                        minWidth: '130px',
                    },
                },
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
                textTransform: 'unset',
                fontWeight: '400'
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        },
        MuiCardHeader: {
            root: {
                width: '100%',
                padding: '20px 20px 0px 20px'
            },
            title: {
                fontSize: '1.5rem',
                fontWeight: '500',
            }
        },
        MuiTypography: {
            h6: {
                fontWeight: '500',
                fontSize: '16px',
                margin: '15px'
            }
        },
        MuiCardContent: {
            root: {
                padding: '0px 20px 0px 20px'
            }
        },
        MUIDataTableSelectCell: {
            root: {
                display: 'table-cell !important'
            },
            headerCell:{
                zIndex: '0 !important',
            },
            fixedHeader :{
                position:"inherit !important"
            }
        }
    },
})

export const onDemenadScheduleTheme = createMuiTheme({

    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },

        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                           
                padding: "4px 10px", //test-table
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        }
    },
})

export const otherErrorTickets = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MUIDataTableToolbarSelect: {
            root: {
                zIndex: '0 !important',
            }
        },
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTableToolbar: {
            left: {
                textAlign: 'left'
            }
        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },

        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                         
                padding: "4px 10px", //test-table
                textAlign: 'left',
                '&:nth-child(10)': {
                    minWidth: '120px',
                },
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left',
                ['@media (max-width: 768px)']: {
                    '&:nth-child(10)': {
                        minWidth: '130px',
                    },
                },
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
                textTransform: 'unset',
                fontWeight: '400'
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        },
        MuiCardHeader: {
            root: {
                width: '100%',
                padding: '20px 20px 0px 20px'
            },
            title: {
                fontSize: '1.5rem',
                fontWeight: '500',
            }
        },
        MuiTypography: {
            h6: {
                fontWeight: '500',
                fontSize: '16px',
                margin: '15px'
            }
        },
        MuiCardContent: {
            root: {
                padding: '0px 20px 0px 20px'
            }
        },
        MUIDataTableSelectCell: {
            root: {
                display: 'table-cell !important'
            },
            headerCell:{
                zIndex: '0 !important',
            },
            fixedHeader :{
                position:"inherit !important"
            }
        },
        MuiPopover: {
            paper: {
                boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12) !important'
            }
        }
    },
})

export const preparingAccountTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#0073EA"
        }, //1098F7 2196f3
        secondary: {
            main: "#541388"
        }, //564787
        background: {
            default: '#f0f4f9'
        }
    },
    typography: {
        useNextVariants: true,
        fontSize: 14,
    },
    overrides: {
        MuiDialogActions: {
            root: {
                justifyContent: "flex-start",
                margin: "8px 20px",
            }
        },

        MuiPaper: {
            root: {
                // boxShadow: 'none !important',
            }
        },
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MuiSwitch: {
            bar: {
                opacity: "0.26"
            },
            colorPrimary: {
                "&$checked": {
                    color: '#0073ea'
                }
            }
        },
        MuiPrivateTabScrollButton: {
            root: {
                width: "30px",
                '@media screen and (min-width: 800px)': {
                    width: "0px "
                }
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 14
            }
        },
        MuiCardHeader: {
            root: {
                padding: "16px 16px 16px 20px"
            },
            title: {
                fontWeight: "500"
            }
        },
        MuiDialog: {
            paper: {
                borderRadius: "15px",
                boxShadow: "none"
            }
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: "#292f4c",
                color: "#6E6F72",
                boxShadow: "none"
            }
        },
        MuiCardContent: {
            root: {
                padding: "20px",
            }
        },
        MuiDrawer: {
            paper: {
                '&:hover': {
                    overflowY: 'auto'
                },
                overflowY: 'hidden'
                // borderRight: "none !important"
            }
        },
        MuiCard: {
            root: {
                maxWidth: "1300px",
                borderRadius: "15px",
                boxShadow: 'none',
                border: "1px solid #e5e8ec",
            }
        },
        MuiButton: {
            // outlined:{
            //   padding: '7px 16px'
            // },
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                // padding: '7px 16px',
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            }
        },
        MuiStepper: {
            root: {
                padding: "0px 0px 24px 0px"
            }
        },
        MuiTab: {
            textColorPrimary: {
                color: "#A0A2A6",
                selected: {
                    color: "#0073ea",
                }
            },
            root: {
                textTransform: 'capitalize',
            }
        },
        MuiPrivateNotchedOutline: {
            root: {
                borderRadius: "4px"
            }
        },
        MuiOutlinedInput: {
            borderRadius: "10px",
            notchedOutline: {
                border: "1px solid rgba(0, 0, 0, 0.23) !important"
            }
        },
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                marginTop: "20px !important",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
            }
        }
        // .MuiFormLabel-root-10725.MuiFormLabel-focused-10726
        // .MuiOutlinedInput-root-10071 .MuiOutlinedInput-notchedOutline-10078

        //.MuiOutlinedInput-root-11266 .MuiOutlinedInput-notchedOutline-11273

    },
    MuiTableRow: {
        root: {
            background: "#F5F5F5", //test-table
            '&:hover': {
                background: "#00000014 !important", //test-table 
            }
        },
        footer: {
            background: "#FFFF", //test-table 
            '&:hover': {
                background: "#FFFF !important", //test-table 
            }
        }
    },
});

export const resellerTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                            
                padding: "4px 10px", //test-table
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            },
            containedPrimary: {
                background: '#3772FF !important'
            },
            outlinedPrimary: {
                color: '#0073EA !important',
                border: '1px solid #0073EA',
                background: 'white',
                '&:hover': {
                    background: '#f2f2f2'
                }
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        }
    },
})

export const reservationErrorTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MUIDataTableToolbarSelect: {
            root: {
                zIndex: '0 !important',
            }
        },
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTableToolbar: {
            left: {
                textAlign: 'left'
            }
        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },

        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                         
                padding: "4px 10px", //test-table
                textAlign: 'left',
                '&:nth-child(10)': {
                    minWidth: '120px',
                },
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left',
                ['@media (max-width: 768px)']: {
                    '&:nth-child(10)': {
                        minWidth: '130px',
                    },
                },
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
                textTransform: 'unset',
                fontWeight: '400'
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        },
        MuiCardHeader: {
            root: {
                width: '100%',
                padding: '20px 20px 0px 20px'
            },
            title: {
                fontSize: '1.5rem',
                fontWeight: '500',
            }
        },
        MuiTypography: {
            h6: {
                fontWeight: '500',
                fontSize: '16px',
                margin: '15px'
            }
        },
        MuiCardContent: {
            root: {
                padding: '0px 20px 0px 20px'
            }
        },
        MUIDataTableSelectCell: {
            root: {
                display: 'table-cell !important'
            },
            headerCell:{
                zIndex: '0 !important',
            }
        },
        MuiPopover: {
            paper: {
                boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12) !important'
            }
        }
    },
})

export const scheduleHistoryTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                          
                padding: "4px 10px", //test-table
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        }
    },
})

export const scraperTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                             
                padding: "4px 10px", //test-table
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        }
    },
})

export const scrapeListTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table      
                padding: "4px 10px", //test-table
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        }
    },
})

export const scrapeProgramTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                             
                padding: "4px 10px", //test-table
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        }
    },
})

export const scrapeScheduleTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                             
                padding: "4px 10px", //test-table
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        }
    },
})

export const AccountSuspendedTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit',
                width: "100%"
            }
        },

        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                        
                padding: "4px 10px", //test-table
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        }
    },
})

export const AutoUploadTheme = createMuiTheme({

    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit',
                width: "100%"
            }
        },

        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table               
                padding: "4px 10px", //test-table
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        }
    },
})

export const FreeTrialAutoUploadTheme = createMuiTheme({

    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit',
                width: "100%"
            }
        },

        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                     
                padding: "4px 10px", //test-table
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        }
    },
})

export const freeTrialExpiredTheme = createMuiTheme({

    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit',
                width: "100%"
            }
        },

        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                        
                padding: "4px 10px", //test-table
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        }
    },
})

export const freeTrialExpiredLastLoginTheme = createMuiTheme({

    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit',
                width: "100%"
            }
        },

        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table             
                padding: "4px 10px", //test-table
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        }
    },
})

export const freeTrialExpiredOutTheme = createMuiTheme({

    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit',
                width: "100%"
            }
        },

        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table             
                padding: "4px 10px", //test-table
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        }
    },
})

export const LastLogin30DaysTheme = createMuiTheme({

    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit',
                width: "100%"
            }
        },

        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                    
                padding: "4px 10px", //test-table
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        }
    },
})

export const PriceOver20 = createMuiTheme({

    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit',
                width: "100%"
            }
        },

        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                 
                padding: "4px 10px", //test-table
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        }
    },
})

export const reservationFailedTaskTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table
                padding: "4px 10px", //test-table
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table
                '&:hover': {
                    background: "#FFFF !important", //test-table
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "8px",
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        },
        MuiCircularProgress: {
            root: {
                position: "absolute"
            }
        },
        MuiCardContent: {
            root: {
                padding: '0px 20px 0px 20px'
            }
        },
    },
})

export const apiLogTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiGridList: {
            root: {
                display: 'block'
            }
        },
        MuiGridListTile: {
            root: {
                width: '80%!important'
            }
        },
        MUIDataTableFilter: {
            selectFormControl: {
                width: '350px'
            }
        },
        MuiInputLabel: {
            formControl: {
                whiteSpace: 'nowrap'
            }
        },
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                 
                padding: "4px 10px", //test-table,
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table,
                textAlign: 'left'
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
                textTransform: 'unset',
                fontWeight: '400'
            }
        },
        MuiToolbar: {
            regular: {
                // minHeight: '0px !important',
                // marginTop:'-57px',
                // marginRight:'10px',
                // width:'923px'
            }
        },

        MuiCardHeader: {
            root: {
                width: '100%',
                padding: '20px 20px 0px 20px'
            },
            title: {
                fontSize: '1.5rem',
                fontWeight: '500',
            }
        },
        MuiTypography: {
            h6: {
                fontWeight: '500',
                fontSize: '16px',
                margin: '15px'
            }
        },
        MuiCardContent: {
            root: {
                padding: '0px 20px 0px 20px'
            }
        }
    },
})

export const bulkSettingTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#3772FF"
        }, //1098F7 2196f3
        secondary: {
            main: "#541388"
        }, //564787
    },
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MuiTable: {
            root: {
                width: "99.5%",
                overflow: "auto",
                display: "block"
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                 
                padding: "4px 10px", //test-table
                height: "30px",
                // minHeight: "50px",
                minWidth: "60px",
                maxWidth: "60px",
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                textAlign: "right"
            },
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                borderRadius: "4px",
                boxShadow: 'none',
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiInputBase: {
            root: {
                minWidth: "20px"
            }
        },
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                marginTop: "20px !important",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
            }
        }
    }
});

export const CalendardateTheme = () => createMuiTheme({
    overrides: {
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                marginTop: "20px !important",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
            }
        },
        MuiIconButton: {
            root: {
                padding: '5px',
                '&:hover': {
                    padding: '5px'
                }
            }
        }
    }
})

export const CalendargetMuiTheme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTableToolbar: {
            left: {
                textAlign: 'left'
            },
            titleText: {
                fontSize: '18px',
                marginLeft: '5px'
            }
        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                padding: '0px 20px 0px 30px',
                textAlign: 'left',
                '&:nth-child(10)': {
                    minWidth: '120px',
                },
            },
            head: {
                padding: '2px 0px 4px 0px',
                textAlign: 'left',
                // fontSize:'16px',
                ['@media (max-width: 768px)']: {
                    '&:nth-child(10)': {
                        minWidth: '130px',
                    },
                },
            },
            body: {
                fontSize: '16px'
            },
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
                textTransform: 'unset',
                fontWeight: '400'
            },
            textPrimary: {
                color: '#0073EA',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            containedPrimary: {
                background: '#0073EA !important',
            },
            outlinedPrimary: {
                color: '#0073EA !important',
                border: '1px solid #0073EA',
                background: 'white !important',
                '&:hover': {
                    background: '#f2f2f2 !important'
                }
            },


        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        },
        MuiCardHeader: {
            root: {
                width: '100%',
                padding: '20px 20px 0px 20px'
            },
            title: {
                fontSize: '1.5rem',
                fontWeight: '500',
                textAlign: 'left !important',
            }
        },
        MuiTypography: {
            h6: {
                fontWeight: '500',
                fontSize: '16px',
                margin: '15px'
            },
            h5: {
                float: 'left !important'
            }
        },
        MuiCardContent: {
            root: {
                padding: '0px 20px 0px 20px'
            }
        },
        MUIDataTableSelectCell: {
            root: {
                display: 'table-cell !important'
            }
        },
        MuiTab: {
            textColorPrimary: {
                color: "#A0A2A6",

            },
            root: {
                textTransform: 'capitalize',
            },
            selected: {
                color: "#0073ea",
            }
        },
    },
})

export const CalendargetMuiTheme2 = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTableToolbar: {
            left: {
                textAlign: 'left'
            },
            titleText: {
                fontSize: '18px',
                marginLeft: '5px'
            }
        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table
                padding: "4px 10px", //test-table
                textAlign: 'left',
                '&:nth-child(10)': {
                    minWidth: '120px',
                },
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left',
                // fontSize:'16px',
                ['@media (max-width: 768px)']: {
                    '&:nth-child(10)': {
                        minWidth: '130px',
                    },
                },
            },
            body: {
                fontSize: '16px'
            },
        },
        MuiTableRow: {
            root: {
                fontWeight: "600",
                cursor: "pointer",
                background: "inherit",
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#f2f2f2 !important"
                }
            },
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
                textTransform: 'unset',
                fontWeight: '400'
            },
            textPrimary: {
                color: '#0073EA',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        },
        MuiCardHeader: {
            root: {
                width: '100%',
                padding: '20px 20px 0px 20px'
            },
            title: {
                fontSize: '1.5rem',
                fontWeight: '500',
                textAlign: 'left !important',
            }
        },
        MuiTypography: {
            h6: {
                fontWeight: '500',
                fontSize: '16px',
                margin: '15px'
            },
            h5: {
                float: 'left !important'
            }
        },
        MuiCardContent: {
            root: {
                padding: '0px 20px 0px 20px'
            }
        },
        MUIDataTableSelectCell: {
            root: {
                display: 'table-cell !important'
            }
        },
        MuiTab: {
            textColorPrimary: {
                color: "#A0A2A6",
            },
            root: {
                textTransform: 'capitalize',
            },
            selected: {
                color: "#0073ea",
            }
        }
    },
})

export const CalendartooltipTheme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
        fontSize: 14,
        fontFamily: "Arial",
        root: {
            width: "200px",
            padding: '10px',
            margin: '-10px',
            borderRadius: '5px',
            backgroundColor: '#323338',
            color: 'white',
            maxWidth: '300px'
        },
        body2: {
            width: "200px",
            padding: '10px',
            margin: '-10px',
            borderRadius: '5px',
            backgroundColor: '#323338',
            color: 'white',
            maxWidth: '300px'
        },
        h4: {
            fontFamily: "Arial",
            borderRadius: '5px',
            backgroundColor: '#1976d2',
            color: 'white',
            maxWidth: 300,
            // border: '1px solid #323338',
            marginBottom: '10px'
        },
        subtitle1: {
            borderBottom: "1px solid #ffffffe8",
            height: "25px",
            color: "#fff",
            margin: '10px 0px 5px 0px'
        }
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MuiButton: {
            root: {
                borderRadius: "4px",
            },
        },
        MuiTableRow: {
            root: {
                height: "30px",
            }
        },
        MuiGrid: {
            container: {
                '& div:first-child': {
                    wordWrap: "break-word",
                    marginBottom: '10px',
                    marginTop: '10px'
                },
                '& div:nth-child(4)': {
                    textAlign: 'right',
                    paddingRight: '5px'
                },
                '& div:nth-child(6)': {
                    textAlign: 'right',
                    paddingRight: '5px'
                },
                '& div:nth-child(9)': {
                    textAlign: 'right',
                    paddingRight: '5px'
                },
                '& div:nth-child(11)': {
                    textAlign: 'right',
                    paddingRight: '5px'
                },
                '& div:nth-child(14)': {
                    textAlign: 'right',
                    paddingRight: '5px'
                },
                '& div:nth-child(16)': {
                    textAlign: 'right',
                    paddingRight: '5px'
                }
            }
        }
    }
})

export const CalendarbuttonTheme = () => createMuiTheme({
    palette: {
        primary: {
            main: "#0073EA"
        }, //1098F7 2196f3
        secondary: {
            main: "#541388"
        }, //564787
        background: {
            default: '#f0f4f9'
        }
    },
    typography: {
        useNextVariants: true,
        fontSize: 14,
    },
    overrides: {
        MuiButton: {
            containedPrimary: {
                background: '#0073EA !important',
            },
            outlinedPrimary: {
                background: 'white !important',
                color: '#0073EA !important',
                border: '1px solid #0073EA',
                '&:hover': {
                    background: '#f2f2f2 !important'
                }
            },
            root: {
                fontWeight: "normal",
                fontSize: "14px",
                color: "000000bf",
                borderRadius: "4px",
                boxShadow: 'none',
                textTransform:'none',
                '&:hover': {
                    border: " 1px solid #0000003b !important",
                    background: "#f2f2f2 !important",
                    backgroundColor:'#f2f2f2!important'
                },
            }
        },
        MuiOutlinedInput: {
            notchedOutline: {
                borderColor: "#0000003b !important",
                borderWidth: "1px !important",
                background: "#fff",
                borderRadius: "4px",
            },
            root: {
                '&:hover': {
                    borderColor: "#0000003b !important",
                    borderWidth: "1px !important",
                    background: "#f2f2f2"
                },
                '&:focus': {
                    borderColor: "#0000003b !important",
                    borderWidth: "1px !important",
                }
            }
        },
        MuiSelect: {
            root: {
                color: "#707070",
                height: "64px",
                fontSize: "14px",
                borderRadius: "4px",
                '&:hover': {
                    backgroundColor: "#f2f2f2",
                    border: " 1px solid #0000003b !important",
                },
            },
            selectMenu: {
                paddingTop: '6px',
                paddingBottom: '0px',
                height: "26px",
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MuiInputBase: {
            input: {
                padding: "3px 0 3px"
            }
        },
        // MuiButton: {
        //     contained: {
        //         boxShadow: 'none',
        //         textTransform: 'capitalize',
        //     },
           
        // },
        MuiOutlinedInput: {
            inputAdornedStart: {
                zIndex: "1",
                padding: "0px",
                height: "32px",
            }
        },
        MuiInputAdornment: {
            positionStart: {
                zIndex: "1"
            }
        }
    }
})

export const CalendarTheme = () => createMuiTheme({
    overrides: {
        MuiPrivateTabScrollButton: {
            root: {
                width: '0px !important'
            }
        },
        MuiExpansionPanelSummary: {
            content: {
                margin: '0px !important'
            },
            expanded: {
                margin: '0px !important'
            },
            root: {
                padding: '0px 0px !important'
            }
        },
        MuiExpansionPanelDetails: {
            root: {
                padding: '0px 0px !important'
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: 'none !important'
            }
        },
        MuiTab: {
            textColorPrimary: {
                color: "#A0A2A6",
            },
            root: {
                textTransform: 'capitalize',
            }
        },
        MuiButton: {
            containedPrimary: {
                background: '#0073EA !important',
                '&:hover': {
                    background: '#0050a3 !important',
                }
            },
            outlinedPrimary: {
                color: '#0073EA !important',
                border: '1px solid #0073EA',
                background: 'white !important',
                '&:hover': {
                    background: '#f2f2f2 !important'
                }
            },
            outlined: {
                padding: '6px 16px'
            },
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                borderRadius: "4px",
                textTransform: 'capitalize !important',
                boxShadow: 'none'
            }
        },
        MuiSwitch: {
            bar: {
                opacity: "0.26"
            },
            colorPrimary: {
                "&$checked": {
                    color: '#0073ea'
                }
            }
            // colorPrimary: {
            //   color:'#0073EA !important',
            // },
        },
        MuiSvgIcon: {
            root: {
                color: '#A0A2A6'
            }
        },
        MuiTab: {
            selected: {
                color: "#0073ea !important",
            },
            root: {
                textTransform: 'capitalize',
            },
            textColorPrimary: {
                color: "#A0A2A6",
            },
        }
    }

});

export const CommunityTheme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            main: "#3772FF"
        },
        secondary: {
            main: "#541388"
        },
        background: {
            default: '#f0f4f9'
        }
    },
    overrides: {
        MuiTab: {
            textColorPrimary: {
                color: "#A0A2A6",
            },
            root: {
                textTransform: 'capitalize',
            }
        },
        MuiTabs: {
            root: {
                marginRight: "100px",
                marginBottom: "32px"
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                            
                padding: "4px 10px", //test-table
                '&:nth-child(1)': {
                    width: '50px  !important',
                    minWidth: '50px  !important',
                    textAlign: 'left !important',
                },
                '&:last-child': {
                    padding: '0 !important',
                }
            },
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiCard: {
            root: {
                maxWidth: "1300px",
                borderRadius: "15px",
                boxShadow: 'none',
                border: "1px solid #e5e8ec"
            }
        },
        MuiCardHeader: {
            root: {
                padding: "16px 16px 16px 20px"
            },
            title: {
                fontWeight: "500"
            },
        },
        MuiPaper: {
            root: {
                boxShadow: "none !important",
                borderRadius: "15px !important"
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            },
        },
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            },
            root: {
                textAlign: "left"
            }
        },
        MuiCardContent: {
            root: {
                padding: "0px 0px 0px 24px"
            }
        },
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
                marginTop: "-4px"
            }
        },
        MuiMenu: {
            paper: {
                borderRadius: "2px !important",
                boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) !important'
            }
        },
        MuiExpansionPanelDetails: {
            root: {
                display: 'block',
                textAlign: 'left',
                padding: '20px'
            }
        },
        MuiStepper: {
            root: {
                padding: '10px'
            }
        },
        MuiStepLabel: {
            iconContainer: {
                backgroundColor: '#ccc',
                zIndex: 1,
                color: '#fff',
                width: 50,
                height: 50,
                display: 'flex',
                borderRadius: '50%',
                justifyContent: 'center',
                alignItems: 'center'
            }
        },

        MuiStepConnector: {
            alternativeLabel: {
                top: '25px'
            }
        }
    }
})

export const ConnectiongetMuiTheme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                  
                padding: "4px 10px", //test-table
                textAlign: 'center',
                minWidth: '95px',
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                }
            },
            MuiTableRow: {
                root: {
                    background: "#F5F5F5", //test-table
                    '&:hover': {
                        background: "#00000014 !important"
                    }
                },
                footer: {
                    background: "#FFFF", //test-table 
                    '&:hover': {
                        background: "#FFFF !important", //test-table 
                    }
                }
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'center',
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                },
                '&:nth-child(1)': {
                    textAlign: 'left !important',
                }
            },
            body: {
                '&:nth-child(2)': {
                    textAlign: 'left !important',
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
            },
            textPrimary: {
                color: '#3772FF'
            },
            textSecondary: {
                color: '#541388'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        },
        MUIDataTableBodyCell: {
            root: {
                width: "15%"
            }
        },
        MUIDataTableHeadCell: {
            root: {
                width: "15%"
            }
        },
        MuiCardHeader: {
            root: {
                padding: "16px 16px 16px 20px"
            },
            title: {
                fontWeight: "500"
            },
            action: {
                marginTop: 7,
                marginLeft: 7
            }
        },
        MuiIcon: {
            colorPrimary: {
                color: '#3772FF'
            }
        }
    },
})

export const ConnectiongetMuiThemeType = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
            },
            textPrimary: {
                color: '#3772FF'
            },
            textSecondary: {
                color: '#541388'
            }
        },
        MuiTableCell: {
            root: {
                padding: '4px 0px 0px 4px',
                textAlign: 'center',
                minWidth: '95px',
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                }
            },
            head: {
                padding: '4px 0px 0px 4px',
                textAlign: 'left',
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                },
                '&:nth-child(1)': {
                    textAlign: 'left !important',
                },
                '&:nth-child(2)': {
                    textAlign: 'left !important',
                },
                '&:nth-child(3)': {
                    textAlign: 'right !important',
                },
                '&:nth-child(4)': {
                    textAlign: 'right !important',
                }
            },
            body: {
                '&:nth-child(2)': {
                    textAlign: 'left !important',
                },
                '&:nth-child(4)': {
                    textAlign: 'left !important',
                },
                '&:nth-child(6)': {
                    textAlign: 'right !important',
                    paddingRight: "22px"
                },
                '&:nth-child(8)': {
                    textAlign: 'right !important',
                    paddingRight: "22px"
                },
            }
        },
    },
})

export const ConnectiongetMuiThemeRate = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
            },
            textPrimary: {
                color: '#3772FF'
            },
            textSecondary: {
                color: '#541388'
            }
        },
        MuiTableCell: {
            root: {
                padding: '4px 0px 0px 4px',
                textAlign: 'center',
                minWidth: '95px',
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                }
            },
            head: {
                padding: '4px 0px 0px 4px',
                textAlign: 'left',
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                },
                '&:nth-child(1)': {
                    textAlign: 'left !important',
                },
                '&:nth-child(2)': {
                    textAlign: 'left !important',
                },
                '&:nth-child(3)': {
                    textAlign: 'left !important',
                },
                '&:nth-child(4)': {
                    textAlign: 'left !important',
                }
            },
            body: {
                '&:nth-child(2)': {
                    textAlign: 'left !important',
                },
                '&:nth-child(4)': {
                    textAlign: 'left !important',
                },
                '&:nth-child(6)': {
                    textAlign: 'left !important',
                    paddingRight: "22px"
                },
                '&:nth-child(8)': {
                    textAlign: 'left !important',
                    paddingRight: "22px"
                },
            }
        },
    },
})

export const ConnectiongetMuicard = () => createMuiTheme({
    palette: {
        primary: {
            main: "#0073EA"
        }, //1098F7 2196f3
        secondary: {
            main: "#541388"
        }, //564787
        background: {
            default: '#f0f4f9'
        }
    },
    typography: {
        useNextVariants: true,
        fontSize: 14,
    },
    overrides: {
        MuiDialogActions: {
            root: {
                justifyContent: "flex-start",
                margin: "8px 20px",
            }
        },

        MuiPaper: {
            root: {
                // boxShadow: 'none !important',
            }
        },
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MuiSwitch: {
            bar: {
                opacity: "0.26"
            },
            colorPrimary: {
                "&$checked": {
                    color: '#0073ea'
                }
            }
        },
        MuiPrivateTabScrollButton: {
            root: {
                width: "30px",
                '@media screen and (min-width: 800px)': {
                    width: "0px "
                }
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 14
            }
        },
        MuiCardHeader: {
            root: {
                padding: "16px 16px 16px 20px"
            },
            title: {
                fontWeight: "500"
            }
        },
        MuiDialog: {
            paper: {
                borderRadius: "15px",
                boxShadow: "none"
            }
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: "#292f4c",
                color: "#6E6F72",
                boxShadow: "none"
            }
        },
        MuiCardContent: {
            root: {
                padding: "20px",
            }
        },
        MuiDrawer: {
            paper: {
                '&:hover': {
                    overflowY: 'auto'
                },
                overflowY: 'hidden'
                // borderRight: "none !important"
            }
        },
        MuiCard: {
            root: {
                maxWidth: "1300px",
                borderRadius: "15px",
                boxShadow: 'none',
                border: "1px solid #e5e8ec",
                overflow: 'unset'
            }
        },
        MuiButton: {
            outlined: {
                padding: '6px 16px'
            },
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                padding: '7px 16px',
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            }
        },
        MuiStepper: {
            root: {
                padding: "0px 0px 24px 0px"
            }
        },
        MuiTab: {
            textColorPrimary: {
                color: "#A0A2A6",
                selected: {
                    color: "#0073ea",
                }
            },
            root: {
                textTransform: 'capitalize',
            }
        },
        MuiPrivateNotchedOutline: {
            root: {
                borderRadius: "4px"
            }
        },
        MuiOutlinedInput: {
            borderRadius: "10px",
            notchedOutline: {
                border: "1px solid rgba(0, 0, 0, 0.23) !important"
            }
        },
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                marginTop: "20px !important",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
            }
        }
        // .MuiFormLabel-root-10725.MuiFormLabel-focused-10726
        // .MuiOutlinedInput-root-10071 .MuiOutlinedInput-notchedOutline-10078

        //.MuiOutlinedInput-root-11266 .MuiOutlinedInput-notchedOutline-11273

    },
    MuiTableRow: {
        root: {
            background: "#F5F5F5", //test-table
            '&:hover': {
                background: "#00000014 !important", //test-table 
            }
        },
        footer: {
            background: "#FFFF", //test-table 
            '&:hover': {
                background: "#FFFF !important", //test-table 
            }
        }
    },
});

export const genieIntelligenceTheme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MUIDataTableToolbar: {
            left: {
                textAlign: 'left'
            },
            titleText: {
                fontSize: '18px',
                marginLeft: '5px'
            }
        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                        
                padding: "4px 10px", //test-table,
                textAlign: 'left',
                '&:nth-child(10)': {
                    minWidth: '120px',
                },
            },
            head: {
                padding: "4px 10px", //test-table,
                textAlign: 'left',
                ['@media (max-width: 768px)']: {
                    '&:nth-child(10)': {
                        minWidth: '130px',
                    },
                },
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
                textTransform: 'unset',
                fontWeight: '400'
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        },
        MuiCardHeader: {
            root: {
                width: '100%',
                padding: '20px 20px 0px 20px'
            },
            title: {
                fontSize: '1.5rem',
                fontWeight: '500',
                textAlign: 'left !important',
            }
        },
        MuiTypography: {
            h6: {
                fontWeight: '500',
                fontSize: '16px',
                margin: '15px'
            },
            h5: {
                float: 'left !important'
            }
        },
        MuiCardContent: {
            root: {
                padding: '0px 20px 0px 20px'
            }
        },
        MUIDataTableSelectCell: {
            root: {
                display: 'table-cell !important'
            }
        }
    },
})

export const OutboundTheme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiGridList: {
            root: {
                display: 'block'
            }
        },
        MuiGridListTile: {
            root: {
                width: '80%!important'
            }
        },
        MUIDataTableFilter: {
            selectFormControl: {
                width: '350px'
            }
        },
        MuiInputLabel: {
            formControl: {
                whiteSpace: 'nowrap'
            }
        },
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                 
                padding: "4px 10px", //test-table,
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table,
                textAlign: 'left'
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
                textTransform: 'unset',
                fontWeight: '400'
            }
        },
        MuiToolbar: {
            regular: {
                // minHeight: '0px !important',
                // marginTop:'-57px',
                // marginRight:'10px',
                // width:'923px'
            }
        },

        MuiCardHeader: {
            root: {
                width: '100%',
                padding: '20px 20px 0px 20px'
            },
            title: {
                fontSize: '1.5rem',
                fontWeight: '500',
            }
        },
        MuiTypography: {
            h6: {
                fontWeight: '500',
                fontSize: '16px',
                margin: '15px'
            }
        },
        MuiCardContent: {
            root: {
                padding: '0px 20px 0px 20px'
            }
        }
    },
})

export const performanceDashboardTheme = () => createMuiTheme({
    palette: {
        primary: {
            main: "#0073EA"
        }, //1098F7 2196f3
        secondary: {
            main: "#541388"
        }, //564787
        background: {
            default: '#f0f4f9'
        }
    },
    typography: {
        useNextVariants: true,
        fontSize: 14,
    },
    overrides: {
        MuiDialogActions: {
            root: {
                justifyContent: "flex-start",
                margin: "8px 20px",
            }
        },

        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MuiSwitch: {
            bar: {
                opacity: "0.26"
            },
            colorPrimary: {
                "&$checked": {
                    color: '#0073ea'
                }
            }
        },
        MuiPrivateTabScrollButton: {
            root: {
                width: "30px",
                '@media screen and (min-width: 800px)': {
                    width: "0px "
                }
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 14
            }
        },
        MuiCardHeader: {
            root: {
                padding: "16px 16px 16px 20px"
            },
            title: {
                fontWeight: "500"
            }
        },
        MuiDialog: {
            paper: {
                borderRadius: "15px",
                boxShadow: "none"
            }
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: "#292f4c",
                color: "#6E6F72",
                boxShadow: "none"
            }
        },
        MuiCardContent: {
            root: {
                padding: "20px",
            }
        },
        MuiDrawer: {
            paper: {
                '&:hover': {
                    overflowY: 'auto'
                },
                overflowY: 'hidden'
                // borderRight: "none !important"
            }
        },
        MuiCard: {
            root: {
                maxWidth: "1300px",
                borderRadius: "15px",
                boxShadow: 'none',
                // border:"1px solid #e5e8ec"
            }
        },
        MuiButton: {
            outlined: {
                padding: '6px 16px'
            },
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                padding: '7px 16px',
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            }
        },
        MuiStepper: {
            root: {
                padding: "0px 0px 24px 0px"
            }
        },
        MuiTab: {
            textColorPrimary: {
                color: "#A0A2A6",
                selected: {
                    color: "#0073ea",
                }
            },
            root: {
                textTransform: 'capitalize',
            }
        },
        MuiPrivateNotchedOutline: {
            root: {
                borderRadius: "4px"
            }
        },
        MuiOutlinedInput: {
            borderRadius: "10px",
            notchedOutline: {
                border: "1px solid rgba(0, 0, 0, 0.23) !important"
            }
        },
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                // marginTop: "20px !important",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
            }
        },
        // .MuiFormLabel-root-10725.MuiFormLabel-focused-10726
        // .MuiOutlinedInput-root-10071 .MuiOutlinedInput-notchedOutline-10078

        //.MuiOutlinedInput-root-11266 .MuiOutlinedInput-notchedOutline-11273
        MuiTableRow: {
            root: {
                background: "#ffff", //test-table
                '&:hover': {
                    background: 'none !important', //test-table 
                }
            },
        },
    },
    // MuiTableRow: {
    //     root: {
    //         background: "#F5F5F5", //test-table
    //         '&:hover': {
    //             background: 'none', //test-table 
    //         }
    //     },
    //     footer: {
    //         background: "#FFFF", //test-table 
    //         '&:hover': {
    //             background: "#FFFF !important", //test-table 
    //         }
    //     }
    // },
});

export const pricingReportTheme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
        fontSize: 14,
        fontFamily: "Arial"
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                                 
                padding: "4px 10px", //test-table,
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
    }
})

export const quickSetupTheme = () => createMuiTheme({
    palette: {
        primary: {
            main: "#3772FF"
        }, //1098F7 2196f3
        secondary: {
            main: "#541388"
        }, //564787
        background: {
            default: '#f0f4f9'
        }
    },
    typography: {
        useNextVariants: true,
        fontSize: 14,
    },
    overrides: {
        MuiTypography: {
            h5: {
                fontWeight: "500"
            },
            body2: {
                fontSize: "1rem"
            }
        },
        MuiPrivateTabScrollButton: {
            root: {
                width: "30px",
                '@media screen and (min-width: 800px)': {
                    width: "0px"
                }
            }
        },
        MuiRadio: {
            root: {
                display: "none",
                padding: "6px 12px"
            }
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: "#3772FF !important",
                fontSize: 14
            }
        },
        MuiCardHeader: {
            title: {
                fontWeight: "500"
            }
        },
        MuiDialog: {
            paper: {
                borderRadius: "15px",
                boxShadow: "none"
            }
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: "#f0f4f8",
                color: "#6E6F72",
                boxShadow: "none"
            }
        },
        MuiCardContent: {
            root: {
                padding: "20px",
                paddingBottom: "15px !important",
                paddingRight: "15px"
            }
        },
        MuiDrawer: {
            paper: {
                // borderRight: "none !important"
            }
        },
        MuiCard: {
            root: {
                maxWidth: "1300px",
                borderRadius: "15px",
                boxShadow: 'none',
                width: "835px",
                // display: "inline-block",
                // marginLeft: "-240px"
                border: "1px solid #e5e8ec"
            }
        },
        MuiStepper: {
            root: {
                padding: "0px 0px 40px 0px"
            }
        },
        MuiTab: {
            textColorPrimary: {
                color: "#A0A2A6",
            },
            root: {
                textTransform: 'capitalize',
            }
        },
        MuiButton: {
            textPrimary: {
                color: '#3772FF'
            },
            textSecondary: {
                color: '#541388'
            },
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize',
            },
            root: {
                fontSize: "14px",
                color: "000000bf",
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none',
                float: "right !important",
                marginLeft: "16px !important"
            }
        },
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                marginTop: "20px !important",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
            }
        },
        MuiFormLabel: {
            root: {
                // marginLeft: "15px",
                // textAlign:"center"
            }
        },
        MuiFormGroup: {
            root: {
                marginLeft: "20px"
            }
        },

        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            },
            selectMenu: {
                textAlign: "left"
            }
        },
        MuiDialogContentText: {
            root: {
                fontSize: "1rem"
            }
        },
        MuiFormControl: {
            root: {
                marginTop: "16px"
            }
        },

        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                marginTop: "20px !important",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
            }
        }
    },

})

export const reservationApiLogTheme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiGridList: {
            root: {
                display: 'block'
            }
        },
        MuiGridListTile: {
            root: {
                width: '80%!important'
            }
        },
        MUIDataTableFilter: {
            selectFormControl: {
                width: '350px'
            }
        },
        MuiInputLabel: {
            formControl: {
                whiteSpace: 'nowrap'
            }
        },
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                 
                padding: "4px 10px", //test-table,
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table,
                textAlign: 'left'
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
                textTransform: 'unset',
                fontWeight: '400'
            }
        },
        MuiToolbar: {
            regular: {
                // minHeight: '0px !important',
                // marginTop:'-57px',
                // marginRight:'10px',
                // width:'923px'
            }
        },

        MuiCardHeader: {
            root: {
                width: '100%',
                padding: '20px 20px 0px 20px'
            },
            title: {
                fontSize: '1.5rem',
                fontWeight: '500',
            }
        },
        MuiTypography: {
            h6: {
                fontWeight: '500',
                fontSize: '16px',
                margin: '15px'
            }
        },
        MuiCardContent: {
            root: {
                padding: '0px 20px 0px 20px'
            }
        }
    },
})

export const runAlgoTheme = () => createMuiTheme({
    palette: {
        primary: {
            main: "#3772FF"
        }, //1098F7 2196f3
        secondary: {
            main: "#541388"
        }, //564787
        background: {
            default: '#f0f4f9'
        }
    },
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MuiTypography: {
            h5: {
                fontWeight: '500',
            }
        },
        MuiCard: {
            root: {
                border: "1px solid #e5e8ec",
                maxWidth: "1300px",
                borderRadius: "15px",
                boxShadow: 'none'
            }
        },
        MuiCardHeader: {
            root: {
                padding: "16px 16px 16px 20px"
            },
            action: {
                cursor: 'pointer',
                marginTop: 7,
                marginRight: 16,
                "&:hover": {
                    color: 'rgba(0, 0, 0, 0.54)'
                }
            },
            content: {
                textAlign: 'left'
            }
        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: "none !important",
                borderRadius: "15px !important"
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                      
                padding: "4px 10px", //test-table,
                textAlign: 'left',
                '&:last-child': {
                    textAlign: 'center'
                }
            },
            head: {
                padding: "4px 10px", //test-table,
                textAlign: 'left'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            textPrimary: {
                color: '#3772FF'
            },
            textSecondary: {
                color: '#541388'
            },
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize',
            },
            root: {
                fontSize: "14px",
                color: "000000bf",
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            }
        },
        MuiIcon: {
            colorPrimary: {
                color: '#3772FF'
            },
            colorSecondary: {
                color: '#541388'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        }
    },
})

export const settingsLogTheme = () => createMuiTheme({
    palette: {
        primary: {
            main: "#3772FF"
        }, //1098F7 2196f3
        secondary: {
            main: "#541388"
        }, //564787
        background: {
            default: '#f0f4f9'
        }
    },
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                               
                padding: "4px 10px", //test-table,
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table,
                textAlign: 'left'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        },
        MuiCardHeader: {
            root: {
                width: '100%',
                padding: "16px 16px 16px 20px"
            },
            title: {
                fontSize: '1.5rem',
                fontWeight: '500',
            }
        },
        MuiTypography: {
            h6: {
                fontWeight: '500',
                fontSize: '16px',
                margin: '15px'
            }
        },
        MuiCardContent: {
            root: {
                padding: '0px 20px 0px 20px'
            }
        }
    },
})

export const tableSettingTheme = () => createMuiTheme({
    palette: {
        primary: {
            main: "#3772FF"
        }, //1098F7 2196f3
        secondary: {
            main: "#541388"
        }, //564787
    },
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MuiTable: {
            root: {
                width: "99.5%",
                overflow: "auto",
                display: "block"
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                        
                padding: "4px 10px", //test-table,
                height: "30px",
                // minHeight: "50px",
                minWidth: "60px",
                maxWidth: "60px",
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                textAlign: "right"
            },
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                borderRadius: "4px",
                boxShadow: 'none',
            },
            textPrimary: {
                color: '#3772FF',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            },
            textSecondary: {
                color: '#541388',
                padding: '0',
                cursor: 'pointer',
                minWidth: '30px'
            }
        },
        MuiInputBase: {
            root: {
                minWidth: "20px"
            }
        },
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                marginTop: "20px !important",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
            }
        }
    }
});

export const targetOccupancyTheme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            main: "#3772FF"
        },
        secondary: {
            main: "#541388"
        },
        background: {
            default: '#f0f4f9'
        }
    },
    overrides: {
        MuiTab: {
            textColorPrimary: {
                color: "#A0A2A6",
            },
            root: {
                textTransform: 'capitalize',
            }
        },
        MuiTabs: {
            root: {
                marginRight: "100px",
                marginBottom: "32px"
            }
        },
        MuiTableCell: {
            root: {
                padding: "4px 10px", //test-table,
                '&:nth-child(1)': {
                    width: '90px  !important',
                    minWidth: '50px  !important',
                    textAlign: 'left !important',
                },
                '&:last-child': {
                    paddingRight: "0px"
                },
            },
        },

        MuiCard: {
            root: {
                maxWidth: "1300px",
                borderRadius: "15px",
                boxShadow: 'none',
                border: "1px solid #e5e8ec"

            }
        },
        MuiCardHeader: {
            root: {
                padding: "16px 16px 16px 20px"
            },
            title: {
                fontWeight: "500"
            },
        },
        MuiPaper: {
            root: {
                boxShadow: "none !important",
                borderRadius: "15px !important"
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            },
        },
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            },
            root: {
                textAlign: "left"
            }
        },
        MuiCardContent: {
            root: {
                paddingLeft: "24px",
                paddingTop: "0px"
            }
        },
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
                marginTop: "-4px"
            }
        },
        MuiMenu: {
            paper: {
                borderRadius: "2px !important",
                boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) !important'
            }
        },
        MuiDialogActions: {
            root: {
                justifyContent: "flex-start",
                margin: "8px 20px",
            }
        },
        MuiPrivateTabScrollButton: {
            root: {
                '@media screen and (min-width: 800px)': {
                    width: "0px",
                }
            }
        }
    }
})

export const userLogTheme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table                 
                padding: "4px 10px", //test-table,
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table,
                textAlign: 'left'
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
                textTransform: 'unset',
                fontWeight: '400'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        },
        MuiCardHeader: {
            root: {
                width: '100%',
                padding: '20px 20px 0px 20px'
            },
            title: {
                fontSize: '1.5rem',
                fontWeight: '500',
            }
        },
        MuiTypography: {
            h6: {
                fontWeight: '500',
                fontSize: '16px',
                margin: '15px'
            }
        },
        MuiCardContent: {
            root: {
                padding: '0px 20px 0px 20px'
            }
        }
    },
})

export const priceModalTheme = () => createMuiTheme({
    overrides: {
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                marginTop: "20px !important",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
            }
        },
        MuiIconButton: {
            root: {
                padding: '5px',
                '&:hover': {
                    padding: '5px'
                }
            }
        }
    }
})

export const monthlyAdjustTheme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MuiInputBase: {
            root: {
                // marginRight: '30%'
            }
        },
        MuiCard: {
            root: {
                maxWidth: "1300px",
                borderRadius: "15px",
                boxShadow: 'none'
            }
        },
        MuiPaper: {
            root: {
                marginTop: 20,
                boxShadow: "none !important",
                borderRadius: "15px !important"
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table             
                borderBottom: "none",
                padding: "4px 10px", //test-table,
                textAlign: 'right !important',
                width: '70px  !important',
                '&:last-child': {
                    width: '25px  !important',
                },
                '&:nth-child(1)': {
                    width: '50px  !important',
                    minWidth: '50px  !important',
                    textAlign: 'left !important',
                }
            },
            head: {
                borderBottom: "1px solid #e7e7e7 !important",
                padding: "4px 10px", //test-table,
                textAlign: 'right !important',
                '&:last-child': {
                    width: '25px  !important',
                },
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MUIDataTableToolbar: {
            titleRoot: {
                textAlign: 'left',
                '& h6': {
                    fontSize: 18,
                    fontWeight: '400 !important',
                    '& span': {
                        marginLeft: 10,
                        marginBottom: '-4px'
                    }
                }
            },
            root: {
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                },
                ['@media (max-width: 599.95px)']: {
                    display: 'flex !important'
                },
                ['@media (max-width: 540px) and (min-width: 400px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 399px) and (min-width: 361px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 360px) and (min-width: 321px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 320px)']: {
                    display: 'flex'
                }
            }
        },
        MuiCardHeader: {
            action: {
                marginTop: 0,
                marginRight: 0
            },
            content: {
                display: 'inherit',
                '& span': {
                    marginLeft: 10
                }
            }
        },
        MuiIcon: {
            colorPrimary: {
                color: '#3772FF'
            },
            colorSecondary: {
                color: '#541388'
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            },
            textPrimary: {
                color: '#3772FF',
                minWidth: '30px',
                padding: 0
            },
            textSecondary: {
                color: '#541388',
                minWidth: '30px',
                padding: 0
            },
            containedPrimary: {
                color: '#fff',
                backgroundColor: '#3772FF',
                '&:hover': {
                    backgroundColor: '#1976d2'
                }
            },
            containedSecondary: {
                color: '#fff',
                backgroundColor: '#541388',
                '&:hover': {
                    backgroundColor: '#1976d2'
                }
            }
        }
    },
})

export const weeklyAdjustTheme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MuiInputBase: {
            root: {
                // marginRight: '30%'
            }
        },
        MuiCard: {
            root: {
                maxWidth: "1300px",
                borderRadius: "15px",
                boxShadow: 'none'
            }
        },
        MuiPaper: {
            root: {
                marginTop: 20,
                boxShadow: "none !important",
                borderRadius: "15px !important"
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table             
                borderBottom: "none",
                padding: "4px 10px", //test-table,
                textAlign: 'right !important',
                width: '80px  !important',
                '&:last-child': {
                    width: '35px  !important',
                },
                '&:nth-child(1)': {
                    width: '38px  !important',
                    minWidth: '38px  !important',
                    textAlign: 'left !important',
                }
            },
            head: {
                borderBottom: "1px solid #e7e7e7 !important",
                padding: "4px 10px", //test-table,
                textAlign: 'right !important',
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MUIDataTableToolbar: {
            titleRoot: {
                textAlign: 'left',
                '& h6': {
                    fontSize: 18,
                    fontWeight: '400 !important',
                    '& span': {
                        marginLeft: 10,
                        marginBottom: '-4px'
                    }
                }
            },
            root: {
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                },
                ['@media (max-width: 599.95px)']: {
                    display: 'flex !important'
                },
                ['@media (max-width: 540px) and (min-width: 400px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 399px) and (min-width: 361px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 360px) and (min-width: 321px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 320px)']: {
                    display: 'flex'
                }
            }
        },
        MuiCardHeader: {
            action: {
                marginTop: 0,
                marginRight: 0
            },
            content: {
                display: 'inherit',
                '& span': {
                    marginLeft: 10
                }
            }
        },
        MuiIcon: {
            colorPrimary: {
                color: '#3772FF'
            },
            colorSecondary: {
                color: '#541388'
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            },
            textPrimary: {
                color: '#3772FF',
                minWidth: '30px',
                padding: 0
            },
            textSecondary: {
                color: '#541388',
                minWidth: '30px',
                padding: 0
            },
            containedPrimary: {
                color: '#fff',
                backgroundColor: '#3772FF',
                '&:hover': {
                    backgroundColor: '#1976d2'
                }
            },
            containedSecondary: {
                color: '#fff',
                backgroundColor: '#541388',
                '&:hover': {
                    backgroundColor: '#1976d2'
                }
            }
        }
    },
})

export const after365Theme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MuiCard: {
            root: {
                maxWidth: "1300px",
                borderRadius: "15px",
                boxShadow: 'none'
            }
        },
        MuiPaper: {
            root: {
                marginTop: 20,
                boxShadow: "none !important",
                borderRadius: "15px !important"
            }
        },
        MuiTableCell: {
            root: {
                borderBottom: "none",
                padding: '5px 0px 5px 5px !important',
                textAlign: 'right !important',
                minWidth: '25px',
                borderBottom: "1px solid #e7e7e7 !important",
                ['@media (min-width:1200px)']: {
                    minWidth: '60px'
                },
            },
            head: {
                borderBottom: "1px solid #e7e7e7 !important",
                padding: '0px 0px 0px 0px',
                textAlign: 'left !important',
                minWidth: '25px',
                ['@media (min-width:1200px)']: {
                    minWidth: '60px'
                },
            }
        },
        MUIDataTableToolbar: {
            titleRoot: {
                textAlign: 'left',
                '& h6': {
                    fontSize: 18,
                    fontWeight: '400 !important',
                    '& span': {
                        marginLeft: 10,
                        marginBottom: '-4px'
                    }
                }
            },
            root: {
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                },
                ['@media (max-width: 599.95px)']: {
                    display: 'flex !important'
                },
                ['@media (max-width: 540px) and (min-width: 400px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 399px) and (min-width: 361px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 360px) and (min-width: 321px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 320px)']: {
                    display: 'flex'
                }
            }
        },
        MuiCardHeader: {
            action: {
                marginTop: 0,
                marginRight: 0
            },
            content: {
                display: 'inherit',
                '& span': {
                    marginLeft: 10
                }
            }
        },
        MuiIcon: {
            colorPrimary: {
                color: '#3772FF'
            },
            colorSecondary: {
                color: '#541388'
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            },
            textPrimary: {
                color: '#3772FF',
                minWidth: '30px',
                padding: 0
            },
            textSecondary: {
                color: '#541388',
                minWidth: '30px',
                padding: 0
            },
            containedPrimary: {
                color: '#fff',
                backgroundColor: '#3772FF',
                '&:hover': {
                    backgroundColor: '#1976d2'
                }
            },
            containedSecondary: {
                color: '#fff',
                backgroundColor: '#541388',
                '&:hover': {
                    backgroundColor: '#1976d2'
                }
            }
        },
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
            }
        }
    },
})

export const competitorTheme = () => createMuiTheme({
    palette: {
        primary: {
            main: "#0073EA"
        }, //1098F7 2196f3
        secondary: {
            main: "#541388"
        }, //564787
        background: {
            default: '#f0f4f9'
        }
    },
    typography: {
        useNextVariants: true,
        fontSize: 14,
    },
    overrides: {
        MuiDialogActions: {
            root: {
                justifyContent: "flex-start",
                margin: "8px 20px",
            }
        },

        MuiPaper: {
            root: {
                // boxShadow: 'none !important',
            }
        },
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }

        },
        MuiSwitch: {
            bar: {
                opacity: "0.26"
            },
            colorPrimary: {
                "&$checked": {
                    color: '#0073ea'
                }
            }
        },
        MuiPrivateTabScrollButton: {
            root: {
                width: "30px",
                '@media screen and (min-width: 800px)': {
                    width: "0px "
                }
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 14
            }
        },
        MuiCardHeader: {
            root: {
                padding: "16px 16px 16px 20px"
            },
            title: {
                fontWeight: "500"
            }
        },
        MuiDialog: {
            paper: {
                borderRadius: "15px",
                boxShadow: "none"
            }
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: "#292f4c",
                color: "#6E6F72",
                boxShadow: "none"
            }
        },
        MuiCardContent: {
            root: {
                padding: "20px",
            }
        },
        MuiDrawer: {
            paper: {
                '&:hover': {
                    overflowY: 'auto'
                },
                overflowY: 'hidden'
                // borderRight: "none !important"
            }
        },
        MuiCard: {
            root: {
                maxWidth: "1300px",
                borderRadius: "15px",
                boxShadow: 'none',
                border: "1px solid #e5e8ec",
            }
        },
        MuiButton: {
            // outlined:{
            //   padding: '7px 16px'
            // },
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                // padding: '7px 16px',
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            }
        },
        MuiStepper: {
            root: {
                padding: "0px 0px 24px 0px"
            }
        },
        MuiTab: {
            textColorPrimary: {
                color: "#A0A2A6",
                selected: {
                    color: "#0073ea",
                }
            },
            root: {
                textTransform: 'capitalize',
            }
        },
        MuiPrivateNotchedOutline: {
            root: {
                borderRadius: "4px"
            }
        },
        MuiOutlinedInput: {
            borderRadius: "10px",
            notchedOutline: {
                border: "1px solid rgba(0, 0, 0, 0.23) !important"
            }
        },
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                marginTop: "20px !important",
                height:"37px",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
            }
        },
        // .MuiFormLabel-root-10725.MuiFormLabel-focused-10726
        // .MuiOutlinedInput-root-10071 .MuiOutlinedInput-notchedOutline-10078

        //.MuiOutlinedInput-root-11266 .MuiOutlinedInput-notchedOutline-11273
        MuiTableRow: {
            root: {
                height: '45px !important',
                verticalAlign: 'top !important',
            }
        }
    },
    MuiTableRow: {
        root: {
            background: "#F5F5F5", //test-table
            '&:hover': {
                background: "#00000014 !important", //test-table 
            }
        },
        footer: {
            background: "#FFFF", //test-table 
            '&:hover': {
                background: "#FFFF !important", //test-table 
            }
        }
    },
});

export const dailySettingsTheme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MuiPaper: {
            root: {
                marginTop: 20,
                boxShadow: "none !important",
                borderRadius: "15px !important"
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table             
                borderBottom: "none",
                padding: "4px 10px", //test-table,
                textAlign: 'center',
                minWidth: '95px',
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                }
            },
            head: {
                borderBottom: "1px solid #e7e7e7 !important",
                padding: "4px 10px", //test-table,
                textAlign: 'center'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MUIDataTableToolbar: {
            titleRoot: {
                textAlign: 'left',
                '& h6': {
                    fontSize: 18,
                    fontWeight: '400 !important',
                    '& span': {
                        marginLeft: 10,
                        marginBottom: '-4px'
                    }
                }
            },
            root: {
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                },
                ['@media (max-width: 599.95px)']: {
                    display: 'flex !important'
                },
                ['@media (max-width: 540px) and (min-width: 400px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 399px) and (min-width: 361px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 360px) and (min-width: 321px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 320px)']: {
                    display: 'flex'
                }
            }
        },
        MuiCardHeader: {
            action: {
                marginTop: 0,
                marginRight: 0
            },
            content: {
                display: 'inherit',
                '& span': {
                    marginLeft: 10
                }
            }
        },
        MuiIcon: {
            colorPrimary: {
                color: '#3772FF'
            },
            colorSecondary: {
                color: '#541388'
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            },
            textPrimary: {
                color: '#3772FF',
                minWidth: '30px',
                padding: 0
            },
            textSecondary: {
                color: '#541388',
                minWidth: '30px',
                padding: 0
            },
            containedPrimary: {
                color: '#fff',
                backgroundColor: '#3772FF',
                '&:hover': {
                    backgroundColor: '#1976d2'
                }
            },
            containedSecondary: {
                color: '#fff',
                backgroundColor: '#541388',
                '&:hover': {
                    backgroundColor: '#1976d2'
                }
            }
        }
    },
});

export const dateSettingTheme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MuiPaper: {
            root: {
                marginTop: 20,
                boxShadow: "none !important",
                borderRadius: "15px !important"
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table             
                borderBottom: "none",
                padding: "4px 10px", //test-table,
                textAlign: 'center',
                minWidth: '95px',
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                }
            },
            head: {
                borderBottom: "1px solid #e7e7e7 !important",
                padding: "4px 10px", //test-table,
                textAlign: 'center'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MUIDataTableToolbar: {
            titleRoot: {
                textAlign: 'left',
                '& h6': {
                    fontSize: 18,
                    fontWeight: '400 !important',
                    '& span': {
                        marginLeft: 10,
                        marginBottom: '-4px'
                    }
                }
            },
            root: {
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                },
                ['@media (max-width: 599.95px)']: {
                    display: 'flex !important'
                },
                ['@media (max-width: 540px) and (min-width: 400px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 399px) and (min-width: 361px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 360px) and (min-width: 321px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 320px)']: {
                    display: 'flex'
                }
            }
        },
        MuiCardHeader: {
            action: {
                marginTop: 0,
                marginRight: 0
            },
            content: {
                display: 'inherit',
                '& span': {
                    marginLeft: 10
                }
            }
        },
        MuiIcon: {
            colorPrimary: {
                color: '#3772FF'
            },
            colorSecondary: {
                color: '#541388'
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            },
            textPrimary: {
                color: '#3772FF',
                minWidth: '30px',
                padding: 0
            },
            textSecondary: {
                color: '#541388',
                minWidth: '30px',
                padding: 0
            },
            containedPrimary: {
                color: '#fff',
                backgroundColor: '#3772FF',
                '&:hover': {
                    backgroundColor: '#1976d2'
                }
            },
            containedSecondary: {
                color: '#fff',
                backgroundColor: '#541388',
                '&:hover': {
                    backgroundColor: '#1976d2'
                }
            }
        }
    },
})

export const dayofweekTheme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },

        MuiCard: {
            root: {
                maxWidth: "1300px",
                borderRadius: "15px",
                boxShadow: 'none'
            }
        },
        MuiPaper: {
            root: {
                marginTop: 20,
                boxShadow: "none !important",
                borderRadius: "15px !important"
            }
        },
        MuiTableCell: {
            root: {
                borderBottom: "none",
                padding: "0px 0px 0px 0px !important",
                textAlign: "right !important",
                minWidth: "25px",
                ["@media (min-width:1200px)"]: {
                    minWidth: "60px",
                },
                "&:nth-child(1)": {
                    width: "112px  !important",
                    minWidth: "50px  !important",
                    textAlign: "left !important",
                },
            },
            head: {
                borderBottom: "1px solid #e7e7e7 !important",
                padding: "0px 0px 0px 0px",
                textAlign: "left !important",
                minWidth: "25px",
                ["@media (min-width:1200px)"]: {
                    minWidth: "60px",
                },
            },
        },
        MUIDataTableToolbar: {
            titleRoot: {
                textAlign: 'left',
                '& h6': {
                    fontSize: 18,
                    fontWeight: '400 !important',
                    '& span': {
                        marginLeft: 10,
                        marginBottom: '-4px'
                    }
                }
            },
            root: {
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                },
                ['@media (max-width: 599.95px)']: {
                    display: 'flex !important'
                },
                ['@media (max-width: 540px) and (min-width: 400px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 399px) and (min-width: 361px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 360px) and (min-width: 321px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 320px)']: {
                    display: 'flex'
                }
            }
        },
        MuiCardHeader: {
            action: {
                marginTop: 0,
                marginRight: 0
            },
            content: {
                display: 'inherit',
                '& span': {
                    marginLeft: 10
                }
            }
        },
        MuiIcon: {
            colorPrimary: {
                color: '#3772FF'
            },
            colorSecondary: {
                color: '#541388'
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            },
            textPrimary: {
                color: '#3772FF',
                minWidth: '30px',
                padding: 0
            },
            textSecondary: {
                color: '#541388',
                minWidth: '30px',
                padding: 0
            },
            containedPrimary: {
                color: '#fff',
                backgroundColor: '#3772FF',
                '&:hover': {
                    backgroundColor: '#1976d2'
                }
            },
            containedSecondary: {
                color: '#fff',
                backgroundColor: '#541388',
                '&:hover': {
                    backgroundColor: '#1976d2'
                }
            }
        },
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
            }
        }
    },
})

export const meaTimeTheme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MuiCard: {
            root: {
                maxWidth: "1300px",
                borderRadius: "15px",
                boxShadow: 'none'
            }
        },
        MuiPaper: {
            root: {
                marginTop: 20,
                boxShadow: "none !important",
                borderRadius: "15px !important"
            }
        },
        MuiTableRow: {
            head: {
                height: '40'
            },
            root: {
                height: '30px'
            }
        },
        MuiTableCell: {
            root: {
                borderBottom: "none",
                padding: '0px 5px 0px 0px !important',
                textAlign: 'right !important',
                minWidth: '25px',
                ['@media (min-width:1200px)']: {
                    minWidth: '60px'
                },
                '&:nth-child(1)': {
                    width: '50px  !important',
                    minWidth: '65px  !important',
                    textAlign: 'left !important',
                }
            },
            head: {
                borderBottom: "1px solid #e7e7e7 !important",
                padding: '0px 0px 0px 0px',
                textAlign: 'left !important',
                minWidth: '25px',
                ['@media (min-width:1200px)']: {
                    minWidth: '60px'
                },

            }
        },
        MUIDataTableToolbar: {

            titleRoot: {
                textAlign: 'left',
                '& h6': {
                    fontSize: 18,
                    fontWeight: '400 !important',
                    '& span': {
                        marginLeft: 10,
                        marginBottom: '-4px'
                    }
                }
            },
            root: {
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                },
                ['@media (max-width: 599.95px)']: {
                    display: 'flex !important'
                },
                ['@media (max-width: 540px) and (min-width: 400px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 399px) and (min-width: 361px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 360px) and (min-width: 321px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 320px)']: {
                    display: 'flex'
                }
            }
        },
        MuiCardHeader: {
            action: {
                marginTop: 0,
                marginRight: 0
            },
            content: {
                display: 'inherit',
                '& span': {
                    marginLeft: 10
                }
            }
        },
        MuiIcon: {
            colorPrimary: {
                color: '#3772FF'
            },
            colorSecondary: {
                color: '#541388'
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            },
            textPrimary: {
                color: '#3772FF',
                minWidth: '30px',
                padding: 0
            },
            textSecondary: {
                color: '#541388',
                minWidth: '30px',
                padding: 0
            },
            containedPrimary: {
                color: '#fff',
                backgroundColor: '#3772FF',
                '&:hover': {
                    backgroundColor: '#1976d2'
                }
            },
            containedSecondary: {
                color: '#fff',
                backgroundColor: '#541388',
                '&:hover': {
                    backgroundColor: '#1976d2'
                }
            }
        },
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                padding: "4px 4px 4px 8px",
                marginTop: '5px',
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
            }
        }
    },
})

export const monthlySettingTheme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MuiCard: {
            root: {
                maxWidth: "1300px",
                borderRadius: "15px",
                boxShadow: 'none'
            }
        },
        MuiPaper: {
            root: {
                marginTop: 20,
                boxShadow: "none !important",
                borderRadius: "15px !important"
            }
        },
        MuiTableCell: {
            root: {
                borderBottom: "none",
                padding: "5px 0px 5px 5px !important",
                textAlign: "right !important",
                minWidth: "25px",
                ["@media (min-width:1200px)"]: {
                    minWidth: "60px",
                },
                "&:nth-child(1)": {
                    width: "120px  !important",
                    minWidth: "50px  !important",
                    textAlign: "left !important",
                },
            },
            head: {
                borderBottom: "1px solid #e7e7e7 !important",
                padding: "0px 0px 0px 0px",
                textAlign: "left !important",
                minWidth: "25px",
                ["@media (min-width:1200px)"]: {
                    minWidth: "60px",
                },
            },
        },
        MUIDataTableToolbar: {
            titleRoot: {
                textAlign: 'left',
                '& h6': {
                    fontSize: 18,
                    fontWeight: '400 !important',
                    '& span': {
                        marginLeft: 10,
                        marginBottom: '-4px'
                    }
                }
            },
            root: {
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                },
                ['@media (max-width: 599.95px)']: {
                    display: 'flex !important'
                },
                ['@media (max-width: 540px) and (min-width: 400px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 399px) and (min-width: 361px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 360px) and (min-width: 321px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 320px)']: {
                    display: 'flex'
                }
            }
        },
        MuiCardHeader: {
            action: {
                marginTop: 0,
                marginRight: 0
            },
            content: {
                display: 'inherit',
                '& span': {
                    marginLeft: 10
                }
            }
        },
        MuiIcon: {
            colorPrimary: {
                color: '#3772FF'
            },
            colorSecondary: {
                color: '#541388'
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            },
            textPrimary: {
                color: '#3772FF',
                minWidth: '30px',
                padding: 0
            },
            textSecondary: {
                color: '#541388',
                minWidth: '30px',
                padding: 0
            },
            containedPrimary: {
                color: '#fff',
                backgroundColor: '#3772FF',
                '&:hover': {
                    backgroundColor: '#1976d2'
                }
            },
            containedSecondary: {
                color: '#fff',
                backgroundColor: '#541388',
                '&:hover': {
                    backgroundColor: '#1976d2'
                }
            }
        },
        MuiInput: {
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            error: {
                background: "#ffe4e4",
                border: "1px solid #ffa4a4",
            },
            disabled: {
                background: "#d5d5d5"
            },
            root: {
                background: "#f2f2f2",
                border: "none",
                borderRadius: "4px",
                padding: "4px 4px 4px 8px",
                '&:hover': {
                    background: "#f2f2f2", //535454
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
                '&:focus-within': {
                    background: "#e9efff",
                    boxShadow: "0px 0px 5px -2px #e9efff",
                    border: "none",
                    borderRadius: "4px",
                    "&&&:before": {
                        borderBottom: "none"
                    },
                },
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: "400",
                fontSize: "19px",
                fontWeight: "500",
                marginLeft: "1px",
                color: "#4b4b4b !important",
            }
        }
    },
})

export const rateSettingTheme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiCard: {
            root: {
                maxWidth: "1300px",
                borderRadius: "15px",
                boxShadow: 'none',
                border: "1px solid #e5e8ec"
            }
        },
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiButton: {
            textPrimary: {
                color: '#3772FF'
            },
            textSecondary: {
                color: '#541388'
            },
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize',
            },
            root: {
                fontSize: "14px",
                color: "000000bf",
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            }
        },
        MuiIcon: {
            colorPrimary: {
                color: '#3772FF'
            },
            colorSecondary: {
                color: '#541388'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        }
    },
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MUIDataTableToolbar: {
            root: {
                minHeight: 0
            }
        },
        MuiCardHeader: {
            title: {
                fontWeight: "500"
            },
            action: {
                marginTop: 7,
                marginLeft: 7
            }
        },

        MuiIcon: {
            colorPrimary: {
                color: '#3772FF'
            },
            colorSecondary: {
                color: '#541388'
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table
                padding: "4px 10px", //test-table,
                textAlign: 'left'
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
    },
})

export const roomSettingThme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf",
            },
            select: {
                "&:focus-within": {
                    background: "none",
                },
            },
        },
        MuiButton: {
            textPrimary: {
                color: "#3772FF"
            },
            textSecondary: {
                color: "#541388"
            },
            contained: {
                boxShadow: "none",
                textTransform: "capitalize",
            },
            root: {
                fontSize: "14px",
                color: "000000bf",
                borderRadius: "4px",
                textTransform: "capitalize",
                boxShadow: "none",
            },
        },
        MUIDataTable: {
            responsiveScroll: {
                height: "100%",
                overflow: "auto",
                overflowX: "auto",
                maxHeight: "inherit",
            },
        },
        MuiPaper: {
            root: {
                boxShadow: "none !important",
            },
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table
                padding: "4px 10px", //test-table
                minWidth: "100px",
                ["@media (max-width: 768px)"]: {
                    minWidth: "110px",
                },
            },
            head: {
                padding: "4px 10px", //test-table
                ["@media (max-width: 768px)"]: {
                    minWidth: "110px",
                },
                "&:nth-child(1)": {
                    textAlign: "left !important",
                },
            },
            body: {
                "&:nth-child(2)": {
                    textAlign: "left !important",
                },

            },
        },
        MuiToolbar: {
            regular: {
                minHeight: "0px !important",
            },
        },
        MuiTableRow: {
            root: {
                fontWeight: "600",
                cursor: "pointer",
                background: "inherit",
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MUIDataTableBodyCell: {
            root: {
                width: "15%",
            },
        },
        MUIDataTableHeadCell: {
            root: {
                width: "15%",
            },
        },
        MUIDataTablePagination: {
            root: {
                "&:last-child": {
                    ["@media (max-width: 414px) and (min-width: 360px)"]: {
                        padding: 0,
                    },
                },
            },
        },
    },
});

export const roomtyperateTheme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table             
                padding: "4px 10px", //test-table
                textAlign: 'center',
                minWidth: '95px',
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                }
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'center',
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                },
                '&:nth-child(1)': {
                    textAlign: 'left !important',
                }
            },
            body: {
                '&:nth-child(2)': {
                    textAlign: 'left !important',
                }
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: "4px",
            },
            textPrimary: {
                color: '#3772FF'
            },
            textSecondary: {
                color: '#541388'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        },
        MUIDataTableBodyCell: {
            root: {
                width: "15%"
            }
        },
        MUIDataTableHeadCell: {
            root: {
                width: "15%"
            }
        }
    },
})

export const skipDateTheme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MuiPaper: {
            root: {
                marginTop: 20,
                boxShadow: "none !important",
                borderRadius: "15px !important"
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table             
                borderBottom: "none",
                padding: "4px 10px", //test-table
                textAlign: 'center',
                minWidth: '95px',
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                }
            },
            head: {
                borderBottom: "1px solid #e7e7e7 !important",
                padding: "4px 10px", //test-table
                textAlign: 'center'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MUIDataTableToolbar: {
            titleRoot: {
                textAlign: 'left',
                '& h6': {
                    fontSize: 18,
                    fontWeight: '400 !important',
                    '& span': {
                        marginLeft: 10,
                        marginBottom: '-4px'
                    }
                }
            },
            root: {
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                },
                ['@media (max-width: 599.95px)']: {
                    display: 'flex !important'
                },
                ['@media (max-width: 540px) and (min-width: 400px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 399px) and (min-width: 361px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 360px) and (min-width: 321px)']: {
                    display: 'flex'
                },
                ['@media (max-width: 320px)']: {
                    display: 'flex'
                }
            }
        },
        MuiCardHeader: {
            action: {
                marginTop: 0,
                marginRight: 0
            },
            content: {
                display: 'inherit',
                '& span': {
                    marginLeft: 10
                }
            }
        },
        MuiIcon: {
            colorPrimary: {
                color: '#3772FF'
            },
            colorSecondary: {
                color: '#541388'
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize'
            },
            root: {
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            },
            textPrimary: {
                color: '#3772FF',
                minWidth: '30px',
                padding: 0
            },
            textSecondary: {
                color: '#541388',
                minWidth: '30px',
                padding: 0
            },
            containedPrimary: {
                color: '#fff',
                backgroundColor: '#3772FF',
                '&:hover': {
                    backgroundColor: '#1976d2'
                }
            },
            containedSecondary: {
                color: '#fff',
                backgroundColor: '#541388',
                '&:hover': {
                    backgroundColor: '#1976d2'
                }
            }
        }
    },
})

export const UserSettingTheme = () => createMuiTheme({
    typography: {
        useNextVariants: true
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit',
                marginTop: '5px'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table              
                padding: "4px 10px", //test-table
                textAlign: 'left',
                minWidth: "110px"
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left'
            }
        },
        MuiButton: {
            textPrimary: {
                color: '#3772FF'
            },
            textSecondary: {
                color: '#541388'
            },
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize',
            },
            root: {
                fontSize: "14px",
                color: "000000bf",
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MUIDataTableToolbar: {
            root: {
                paddingLeft: "0px",
                paddingTop: "16px"
            },
            left: {
                textAlign: 'left !important'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important',
                ['@media (max-width: 768px)']: {
                    minHeight: '48px !important',
                    marginTop: '48px !important'
                }
            }
        }
    },
})

export const yeldTagTheme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table
                padding: "4px 10px", //test-table
                textAlign: 'center',
                minWidth: '95px',
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                }
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left',
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                },
                '&:nth-child(1)': {
                    textAlign: 'left !important',
                },
                '&:nth-child(3)': {
                    textAlign: 'center !important',
                },
                '&:nth-child(4)': {
                    textAlign: 'right !important',
                },
                '&:nth-child(5)': {
                    textAlign: 'right !important',
                }
            },
            body: {
                '&:nth-child(2)': {
                    textAlign: 'left !important',
                }
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            textPrimary: {
                color: '#3772FF'
            },
            textSecondary: {
                color: '#541388'
            },
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize',
            },
            root: {
                fontSize: "14px",
                color: "000000bf",
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        },
        MUIDataTableBodyCell: {
            root: {
                width: "15%"
            }
        },
        MUIDataTableHeadCell: {
            root: {
                width: "15%"
            }
        }
    },
});

export const segmentationTheme = () => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#000000bf"
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MUIDataTable: {
            responsiveScroll: {
                height: '100%',
                overflow: 'auto',
                overflowX: 'auto',
                maxHeight: 'inherit'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
            }
        },
        MuiTableCell: {
            root: {
                border: "2px solid white", //test-table
                borderBottom: "unset", //test-table
                padding: "4px 10px", //test-table
                textAlign: 'center',
                minWidth: '95px',
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                }
            },
            head: {
                padding: "4px 10px", //test-table
                textAlign: 'left',
                ['@media (max-width: 768px)']: {
                    minWidth: '110px',
                },
                '&:nth-child(1)': {
                    textAlign: 'left !important',
                },
                '&:nth-child(3)': {
                    textAlign: 'center !important',
                },
                '&:nth-child(4)': {
                    textAlign: 'center !important',
                },
                '&:nth-child(5)': {
                    textAlign: 'left !important',
                }
            },
            body: {
                '&:nth-child(2)': {
                    textAlign: 'left !important',
                }
            }
        },
        MuiTableRow: {
            root: {
                background: "#F5F5F5", //test-table
                '&:hover': {
                    background: "#00000014 !important"
                }
            },
            footer: {
                background: "#FFFF", //test-table 
                '&:hover': {
                    background: "#FFFF !important", //test-table 
                }
            }
        },
        MuiButton: {
            textPrimary: {
                color: '#3772FF'
            },
            textSecondary: {
                color: '#541388'
            },
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize',
            },
            root: {
                fontSize: "14px",
                color: "000000bf",
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '0px !important'
            }
        },
    },
});

export const ButtonTheme = () => createMuiTheme({
    palette: {
        primary: {
            main: "#0073EA"
        }, //1098F7 2196f3
        secondary: {
            main: "#541388"
        }, //564787
        background: {
            default: '#f0f4f9'
        }
    },
    typography: {
        useNextVariants: true,
        fontSize: 14,
    },
    overrides: {
        MuiOutlinedInput: {
            notchedOutline: {
                borderColor: "#0000003b !important",
                borderWidth: "1px !important",
                background: "#fff",
                borderRadius: "4px",
            },
            root: {
                '&:hover': {
                    borderColor: "#0000003b !important",
                    borderWidth: "1px !important",
                    background: "#f2f2f2"
                },
                '&:focus': {
                    borderColor: "#0000003b !important",
                    borderWidth: "1px !important",
                }
            }
        },
        MuiSelect: {
            root: {
                color: "#707070",
                height: "64px",
                fontSize: "14px",
                borderRadius: "4px",
                '&:hover': {
                    backgroundColor: "#f2f2f2",
                    border: " 1px solid #0000003b !important",
                },
            },
            selectMenu: {
                paddingTop: '6px',
                paddingBottom: '0px',
                height: "26px",
            },
            select: {
                '&:focus-within': {
                    background: "none"
                }
            }
        },
        MuiInputBase: {
            input: {
                padding: "3px 0 3px"
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                textTransform: 'capitalize',
            },
            root: {
                fontWeight: "normal",
                fontSize: "14px",
                color: "000000bf",
                borderRadius: "4px",
                textTransform: 'capitalize',
                boxShadow: 'none',
                '&:hover': {
                    border: " 1px solid #0000003b !important",
                    background: "#f2f2f2 !important",
                    backgroundColor:'#f2f2f2!important'
                },
            }
        },
        MuiOutlinedInput: {
            inputAdornedStart: {
                zIndex: "1",
                padding: "0px",
                height: "32px",
            }
        },
        MuiInputAdornment: {
            positionStart: {
                zIndex: "1"
            }
        }
    }
});
export const snackBarTheme = () => createMuiTheme({
    overrides: {
        MuiSnackbarContent:{
            message:{
                width:'85%'
            }
        }
    }
});

export const monthPickerStyle = () =>  createMuiTheme({
    palette: {
        primary: {
            main: "#0073EA"
        }, //1098F7 2196f3
        secondary: {
            main: "#541388"
        }, //564787
        background: {
            default: '#f0f4f9'
        }
    },
    overrides: {
        MuiPickersToolbarButton:{
            toolbarBtn:{
                paddingLeft: "16px",
                cursor: "pointer",
                fontSize: "1.2rem",
                color:"#ffffff8a",
                '&:hover': {
                    color:"#fff"
                },
            }
        },
        MuiPickersToolbar:{
            toolbar:{
                alignItems: "center",
                flexDirection: "row-reverse",
                justifyContent: "flex-end",
                height:"0px",
                minHeight: "50px",
            }
        },
        MuiPickersMonth:{
            root:{
                height:"45px",
                fontSize:"14px"
            }
        },        
        MuiInput: {
            error: {
              background: "#ffe4e4",
              border: "1px solid #ffa4a4",
            },
            disabled: {
              background: "#d5d5d5"
            },
            root: {
              border: "none",
              borderRadius: "4px",
              color:'#707070',
              fontSize: "1.25rem",
              padding: "3px 0px 0px 3px",
              '&:hover': {
                borderRadius: "4px",
                "&&&:before": {
                  borderBottom: "none"
                },
              },
              '&:focus-within': {
                boxShadow: "0px 0px 5px -2px #e9efff",
                borderRadius: "4px",
                "&&&:before": {
                  borderBottom: "none"
                },
              },
            },
            underline: {
              "&&&:before": {
                borderBottom: "none"
              },
              "&&:after": {
                borderBottom: "none"
              }
            },
          },
          MuiInputLabel: {
            root: {
              fontWeight: "400",
              fontSize: "19px",
              fontWeight: "500",
              marginLeft: "1px",
              color: "#4b4b4b !important",
            }
          }, 
          MuiInputBase : {
            input: {
                padding: "0px 0 3px",
                cursor:"pointer",
            }
          },
          MuiIconButton:{
              root:{
                padding:'5px',
                '&:hover':{
                  padding:'5px'
                }
              }
            }
    }

})

export const monthPickerStyleSingleYear = () =>  createMuiTheme({

     palette: {
        primary: {
            main: "#0073EA"
        }, //1098F7 2196f3
        secondary: {
            main: "#541388"
        }, //564787
        background: {
            default: '#f0f4f9'
        }
    },
    overrides: {
        MuiPickersToolbarButton:{
            toolbarBtn:{
                paddingLeft: "16px",
                cursor: "pointer",
                fontSize: "1.2rem",
                color:"#ffffff8a",
                '&:hover': {
                    color:"#fff"
                },
            }
        },
        MuiPickersToolbar:{
            toolbar:{
                alignItems: "center",
                flexDirection: "row-reverse",
                justifyContent: "center",
                height:"0px",
                minHeight: "50px",
            }
        },
        MuiPickersMonth:{
            root:{
                height:"45px",
                fontSize:"14px"
            }
        },        
        MuiInput: {
            error: {
              background: "#ffe4e4",
              border: "1px solid #ffa4a4",
            },
            disabled: {
              background: "#d5d5d5"
            },
            root: {
              border: "none",
              borderRadius: "4px",
              color:'#707070',
              fontSize: "1.25rem",
              padding: "3px 0px 0px 3px",
              '&:hover': {
                borderRadius: "4px",
                "&&&:before": {
                  borderBottom: "none"
                },
              },
              '&:focus-within': {
                boxShadow: "0px 0px 5px -2px #e9efff",
                borderRadius: "4px",
                "&&&:before": {
                  borderBottom: "none"
                },
              },
            },
            underline: {
              "&&&:before": {
                borderBottom: "none"
              },
              "&&:after": {
                borderBottom: "none"
              }
            },
          },
          MuiInputLabel: {
            root: {
              fontWeight: "400",
              fontSize: "19px",
              fontWeight: "500",
              marginLeft: "1px",
              color: "#4b4b4b !important",
            }
          }, 
          MuiInputBase : {
            input: {
                padding: "0px 0 3px",
                cursor:"pointer",
            }
          },
          MuiIconButton:{
              root:{
                padding:'5px',
                '&:hover':{
                  padding:'5px'
                }
              }
            }
    }
})

export const monthPickerStylecompetitors = () =>  createMuiTheme({
    palette: {
        primary: {
            main: "#0073EA"
        }, //1098F7 2196f3
        secondary: {
            main: "#541388"
        }, //564787
        background: {
            default: '#f0f4f9'
        }
    },
    overrides: {
        MuiPickersToolbarButton:{
            toolbarBtn:{
                paddingLeft: "16px",
                cursor: "pointer",
                fontSize: "1.2rem",
                color:"#ffffff8a",
                '&:hover': {
                    color:"#fff"
                },
            }
        },
        MuiPickersToolbar:{
            toolbar:{
                alignItems: "center",
                flexDirection: "row-reverse",
                justifyContent: "flex-end",
                height:"0px",
                minHeight: "50px",
            }
        },
        MuiPickersMonth:{
            root:{
                height:"45px",
                fontSize:"14px"
            }
        },        
        MuiInput: {
            error: {
              background: "#ffe4e4",
              border: "1px solid #ffa4a4",
            },
            disabled: {
              background: "#d5d5d5"
            },
            root: {
              border: "none",
              borderRadius: "4px",
              color:'#707070',
              fontSize: "1.25rem",
              padding: "3px 0px 0px 3px",
              '&:hover': {
                borderRadius: "4px",
                "&&&:before": {
                  borderBottom: "none"
                },
              },
              '&:focus-within': {
                boxShadow: "0px 0px 5px -2px #e9efff",
                borderRadius: "4px",
                "&&&:before": {
                  borderBottom: "none"
                },
              },
            },
            underline: {
              "&&&:before": {
                borderBottom: "none"
              },
              "&&:after": {
                borderBottom: "none"
              }
            },
          },
          MuiInputLabel: {
            root: {
              fontWeight: "400",
              fontSize: "19px",
              fontWeight: "500",
              marginLeft: "1px",
              color: "#4b4b4b !important",
            }
          }, 
          MuiInputBase : {
            input: {
                padding: "0px 0 3px",
                cursor:"pointer",
            }
          },
          MuiIconButton:{
              root:{
                padding:'5px',
                '&:hover':{
                  padding:'5px'
                }
              }
            }
    }

})