import React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import { NamespacesConsumer } from 'react-i18next';

const styles = '';

export default {
    
    badgeSimpleTextMenu: function (modalData,labeltext,index) {
        let  isShow = (( index == 1 && !modalData.state.featuresEnabled.includes(25)) ||  (index == 3 && !modalData.state.featuresEnabled.includes(23))) ? true : false
        return (
            <ListItemText inset primary={<NamespacesConsumer>{t => <span>{t(labeltext)}
                { modalData.props.hotelAuthReducer.hotelDetails.hotel.state === 0 && isShow && modalData.state.featuresEnabled.includes(33) ?
                <Button style={{float:'right',color:'white',padding:'2px 5px',minWidth:'0px',textTransform: 'capitalize',boxShadow:'0 0 white',background:'#eda942'}} variant="contained"  size="small">
                    Limited 
                </Button>
                :
                modalData.props.hotelAuthReducer.hotelDetails.hotel.state === 0 &&  !isShow && modalData.state.featuresEnabled.includes(34) && 
                    <Button style={{float:'right',color:'white',padding:'2px 5px',minWidth:'0px',textTransform: 'capitalize',boxShadow:'0 0 white',background:'#eda942'}} variant="contained"  size="small">
                    PRO
                </Button>
                }
            </span>}</NamespacesConsumer>} />
        )
    },

    badgeChildText:function (modalData,labeltext,index){
        let isShow=(( index == 10 && modalData.state.featuresEnabled.includes(32)) || ( index == 4 && modalData.state.featuresEnabled.includes(25)) || ( index == 5 && modalData.state.featuresEnabled.includes(9)) ||  ( index == 9 && modalData.state.featuresEnabled.includes(16))) ? true : false
        return (
            <ListItemText inset primary={<NamespacesConsumer>{t => <span>{t(labeltext)}            
                { modalData.props.hotelAuthReducer.hotelDetails.hotel.state === 0 && isShow && modalData.state.featuresEnabled.includes(34) && 
                <Button style={{float:'right',color:'white',padding:'2px 5px',minWidth:'0px',textTransform: 'capitalize',boxShadow:'0 0 white',background:'#eda942'}} variant="contained"  size="small">
                PRO
                </Button>
                }
             </span>}</NamespacesConsumer>} />
        )
    },

    badgeCalenderPro:function(modalData){
        let isShow=modalData.state.featuresEnabled.includes(34) ? true :false
       return (
        isShow &&
        <span><Button style={{marginLeft:"10px",float:'right',fontSize:'12px',marginTop:'-3px',color:'white',padding:'0px 9px',minWidth:'0px',textTransform: 'capitalize',boxShadow:'0 0 white',background:'#eda942'}} variant="contained"  size="small">PRO</Button></span>
      
            )
    }

}