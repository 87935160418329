var style = {
  textField: {
    textAlign: 'left'
  },
  btnDiv: {
    textAlign: 'center',
    width: '100%',
    display: 'inline-block',
    paddingTop: '30px',
    marginLeft: '2%',
    marginRight: '2%',
  },
  saveBtn: {
    float: 'left',
  },
  userDiv: {
    display: 'flex',
    width: '100%',
    flexDirection: 'inherit',
    marginLeft: '5%',
  },
  textSelectField: {
    borderBottom: "solid 1px #949494"
  },
  radioDiv: {
  },
  formControl: {
    width: '46%',
    marginLeft: '2%',
    marginRight: '2%',
    minWidth: '275px',
    textAlign: 'left'
  },
  radioGrp: {
    marginBottom:'8px',
    ['@media (max-width: 768px)']: {
      width: '90%',
      float: 'right'
    },
  },
  labels: {
    width: '46%',
    marginLeft: '2%',
    marginRight: '2%',
    minWidth: '275px',
    textAlign: 'left',
    marginTop: "40px"
  },
  divStyle: {
    float: "left", 
    marginTop: "2px"
  },
  fontStyle: {
    textAlign: "left", 
    fontWeight: "500"
  },
  formControl2: {
    marginTop:'25px'
  },
  lableInput: {
    marginTop:'-19px'
  },
  propertyField: {
    marginTop:"6px"
  },
  userField: {
    float: "left",
    marginTop:'35px'
  },
  radioCheck: {
    width: '98% !important'
  },
  nameField: {
    height: "72px"
  },
  hideOnMobile: {
    ['@media (max-width: 540px)']: {
      display: "none !important"
    },
  }, 
}
export default style;