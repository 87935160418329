import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Settings, PlayCircleFilled, Tune, Payment, Description, Apps, Email, Search ,Launch } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import AsyncSelect from 'react-select/lib/Async';
import FormControl from '@material-ui/core/FormControl';
import Select, { components } from "react-select";
import Creatable from 'react-select/lib/Creatable';
import TextField from '@material-ui/core/TextField';
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
export default {
    SidebarModal: function (modalData) {
        const { t } = modalData.props;
        const { classes } = modalData.props;
        const { anchorEl, anchorhint } = modalData.state;
        const { fullScreen } = modalData.props;
        const openhint = Boolean(anchorhint);
        const open = modalData.state.changeLng ? false : Boolean(anchorEl);
        const { anchorEh } = modalData.state;
        const { anchorEg } = modalData.state;
        const Lngopen = Boolean(anchorEg);
        const Hotelopen = Boolean(anchorEh);
        let planId = modalData.props.hotelAuthReducer.hotelDetails.hotel.plan;
        // const DropdownIndicator = props => {
            // return (
            //     <components.DropdownIndicator {...props}>
            //         <Search onClick={modalData.clickText} />
            //     </components.DropdownIndicator>
            // );
        // };
        return (
            <>
                {!modalData.state.appWalkthroughsModal &&
                    <Dialog
                        transitionDuration={{ enter: 500, exit: 300 }}
                        fullScreen={fullScreen}
                        maxWidth='lg'
                        open={openhint}
                        onClose={modalData.handleCloseMenu}
                        aria-labelledby="responsive-dialog-title"
                        PaperProps={{ style: { maxWidth: "1150px", margin: "10px", minWidth: '70%' } }}
                    >
                        <DialogTitle id="responsive-dialog-title" style={{ textAlign: 'center' }}>
                            <span style={{ fontSize: '28px' }}>{t("How can we help?")}</span>
                        </DialogTitle>
                        <IconButton aria-label="Close" onClick={modalData.handleCloseMenu} style={{ position: "absolute", right: "0" }}>
                            <CloseIcon />
                        </IconButton>
                        <DialogContent>
                            <Grid container style={{ margin: '30px 0 50px 0' }}>
                                <Grid xs={12} md={12}>
                                    <FormControl style={{ width: '65%', margin: '0 auto', display: 'block', borderRadius: '3px' }} >
                                        <Creatable
                                            className="basic-single"
                                            // isSearchable={modalData.handleInputChange}
                                            onChange={modalData.handleChange}
                                            onInputChange={modalData.handleInputChange}
                                            // classNamePrefix="select"
                                            value={modalData.state.searchText}
                                            formatCreateLabel={userInput => `Search for ${userInput}`}
                                            placeholder={t("Search in Help Center...")}
                                            name="que"
                                            // components={{ DropdownIndicator }}
                                            options={modalData.promiseQuestionOptions()}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <div style={{display:'flex',justifyContent:'center'}}>
                            {planId == 1 &&
                                <Grid container spacing={1} style={{width:'80%'}}>
                                    <Grid xs={4} md={2} className={classes.mainGrid}>
                                        <div className={classes.iconHover}>
                                            <a href="https://support.roompricegenie.com/home/genieintelligence/genieintelligence-get-started/" style={{ textDecoration: 'none' }} target="_blank">
                                                <Launch color="primary" style={{ fontSize: 40 }} />
                                                <Typography>{t("Get Started")}</Typography>
                                            </a>
                                        </div>
                                    </Grid>
                                    {/* <Grid xs={4} md={2} className={classes.iconHover} style={{textAlign:'center'}}>
                                        <div className={classes.iconHover} onclick="window.location=''">
                                            <Email color="primary" style={{fontSize:40}} />
                                            <Typography>Email</Typography>
                                        </div>
                                    </Grid> */}
                                    <Grid xs={4} md={2} className={classes.mainGrid} >
                                        <div className={classes.iconHover}>
                                            <a href='https://support.roompricegenie.com/home/genieintelligence/genieintelligence-billing/' style={{ textDecoration: 'none' }} target='_blank'>
                                                <Payment color="primary" style={{ fontSize: 40 }} />
                                                <Typography>{t("Billing")}</Typography>
                                            </a>
                                        </div>
                                    </Grid>
                                            {/* <Grid xs={4} md={2}  style={{textAlign:'center'}}>
                                    <div className={classes.iconHover} onclick="window.location=''">
                                        <Description color="primary" style={{fontSize:40}} />
                                        <Typography>Top Articles</Typography>
                                    </div>
                                </Grid> */}
                                            {/* <Grid xs={4} md={2} style={{textAlign:'center'}}>
                                    <div className={classes.iconHover} onclick="window.location=''">
                                        <Apps color="primary" style={{fontSize:40}} />
                                        <Typography>Integrations</Typography>
                                    </div>
                                </Grid> */}
                                </Grid>
                            }
                            {planId == 2 &&
                                <Grid container spacing={1} style={{width:'80%'}} >
                                    <Grid xs={4} md={1} ></Grid>
                                    <Grid xs={4} md={2} className={classes.mainGrid}>
                                        <div className={classes.iconHover}>
                                            <a href='https://support.roompricegenie.com/home/basic-plan/basic-setup/' style={{ textDecoration: 'none' }} target='_blank'>
                                                <Settings color="primary" style={{ fontSize: 40 }} />
                                                <Typography>{t("Setup")}</Typography>
                                            </a>
                                        </div>
                                    </Grid>
                                    <Grid xs={4} md={2} className={classes.mainGrid} >
                                        <div className={classes.iconHover}>
                                            <a href='https://support.roompricegenie.com/home/basic-plan/basic-get-started/' style={{ textDecoration: 'none' }} target='_blank'>
                                                <Launch color="primary" style={{ fontSize: 40 }} />
                                                <Typography>{t("Get Started")}</Typography>
                                            </a>
                                        </div>
                                    </Grid>
                                    <Grid xs={4} md={2} className={classes.mainGrid}>
                                        <div className={classes.iconHover}>
                                            <a href='https://support.roompricegenie.com/home/basic-plan/basic-customize/' style={{ textDecoration: 'none' }} target="_blank">
                                                <Payment color="primary" style={{ fontSize: 40 }} />
                                                <Typography>{t("Customize")}</Typography>
                                            </a>
                                        </div>
                                    </Grid>
                                    <Grid xs={4} md={2} className={classes.mainGrid}>
                                        <div className={classes.iconHover}>
                                            <a href='https://support.roompricegenie.com/home/basic-plan/basic-plan-account-billing/' style={{ textDecoration: 'none' }} target="_blank">
                                                <Description color="primary" style={{ fontSize: 40 }} />
                                                <Typography>{t("Billing")}</Typography>
                                            </a>
                                        </div>
                                    </Grid>
                                    <Grid xs={4} md={1}></Grid>
                                    {/* <Grid xs={4} md={2} style={{textAlign:'center'}}>
                            <div className={classes.iconHover} onclick="window.location=''">
                                <Apps color="primary" style={{fontSize:40}} />
                                <Typography>Integrations</Typography>
                            </div>
                        </Grid> */}
                                </Grid>
                            }
                            {planId == 3 &&
                                <Grid container spacing={1} style={{width:'80%'}} >
                                    <Grid xs={4} md={2} ></Grid>

                                    <Grid xs={4} md={2} className={classes.mainGrid}>
                                        <div className={classes.iconHover}>
                                            <a href='https://support.roompricegenie.com/home/flexible-plan/flexible-setup/' style={{ textDecoration: 'none' }} target="_blank">
                                                <Settings color="primary" style={{ fontSize: 40 }} />
                                                <Typography>{t("Setup")}</Typography>
                                            </a>
                                        </div>
                                    </Grid>
                                    <Grid xs={4} md={2} className={classes.mainGrid}>
                                        <div className={classes.iconHover}>
                                            <a href='https://support.roompricegenie.com/home/flexible-plan/flexible-get-started/' style={{ textDecoration: 'none' }} target="_blank">
                                                <Launch color="primary" style={{ fontSize: 40 }} />
                                                <Typography>{t("Get Started")}</Typography>
                                            </a>
                                        </div>
                                    </Grid>
                                    <Grid xs={4} md={2} className={classes.mainGrid} >
                                        <div className={classes.iconHover}>
                                            <a href='https://support.roompricegenie.com/home/flexible-plan/flexible-customize/' style={{ textDecoration: 'none' }} target="_blank">
                                                <Tune color="primary" style={{ fontSize: 40 }} />
                                                <Typography>{t("Customize")}</Typography>
                                            </a>
                                        </div>
                                    </Grid>
                                    <Grid xs={4} md={2} className={classes.mainGrid}>
                                        <div className={classes.iconHover}>
                                            <a href='https://support.roompricegenie.com/home/flexible-plan/flexible-account-and-billing/' style={{ textDecoration: 'none' }} target="_blank">
                                                <Payment color="primary" style={{ fontSize: 40 }} />
                                                <Typography>{t("Billing")}</Typography>
                                            </a>
                                        </div>
                                    </Grid>
                                    <Grid xs={4} md={2} ></Grid>

                                    {/* <Grid xs={4} md={2} style={{textAlign:'center'}}>
                            <div className={classes.iconHover} onclick="window.location=''">
                                <Description color="primary" style={{fontSize:40}} />
                                <Typography>Top Articles</Typography>
                            </div>
                        </Grid>
                        <Grid xs={4} md={2} style={{textAlign:'center'}}>
                            <div className={classes.iconHover} onclick="window.location=''">
                                <Apps color="primary" style={{fontSize:40}} />
                                <Typography>Integrations</Typography>
                            </div>
                        </Grid> */}
                                </Grid>
                            }
                            </div>
                            {/* {!planId &&
                    <Grid container spacing={3} style={{justifyContent:'center'}}>
                        <Grid xs={4} md={2} style={{textAlign:'center'}}>
                            <div className={classes.iconHover} onclick="window.location=''">
                                <Payment color="primary" style={{fontSize:40}} />
                                <Typography>Billing</Typography>
                            </div>
                        </Grid>
                        <Grid xs={4} md={2} style={{textAlign:'center'}}>
                            <div className={classes.iconHover} onclick="window.location=''"> 
                                <Description color="primary" style={{fontSize:40}} />
                                <Typography>Top Articles</Typography>
                            </div>
                        </Grid>
                        <Grid xs={4} md={2} style={{textAlign:'center'}}>
                            <div className={classes.iconHover}>
                                <Apps color="primary" style={{fontSize:40}} />
                                <Typography>Integrations</Typography>
                            </div>
                        </Grid>
                    </Grid>
                } */}
                            <Grid container spacing={3} style={{ marginTop: '50px',marginBottom:'30px' }}>
                                <Grid xs={12} sm={12} md={3} className={classes.cardGrid2}>
                                    <Card className={classes.helpCard} onClick={()=>{modalData.openAppWalkthroughsModal();modalData.toggleDrawerOpen()}}>
                                        {/* <CardContent> */}
                                            {/* <img height="120" src="/img/RoomPrice Genie_2_phone.png" style={{ marginRight: 'auto', marginLeft: 'auto', display: 'block' }} /> */}
                                        {/* </CardContent> */}
                                        <CardActions >
                                            <Typography style={{ margin: '0 auto', display: 'block',textAlign:'center' }}>{t("In-App Walkthroughs")}    </Typography>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid xs={12} sm={12} md={3} className={classes.cardGrid2}>
                                    <Card className={classes.helpCard} onClick={modalData.openSupportRequestModal}>
                                        {/* <CardContent> */}
                                            {/* <img height="120" src="/img/RoomPrice Genie_2_talking.png" className={classes.genieImg}  /> */}
                                        {/* </CardContent> */}
                                        <CardActions>
                                            <Typography style={{ margin: '0 auto', display: 'block' }}>
                                                {t("Report an Issue")}
                                            </Typography>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid xs={12} sm={12} md={3} className={classes.cardGrid2}>
                                    <Card className={classes.helpCard} onClick={modalData.revenueArticle}>
                                        {/* <CardContent> */}
                                            {/* <img height="120" src="/img/RoomPrice Genie_2_reading.png" style={{ marginRight: 'auto', marginLeft: 'auto', display: 'block' }} /> */}
                                        {/* </CardContent> */}
                                        <CardActions >
                                            <Typography style={{ margin: '0 auto', display: 'block', textAlign: 'center', padding: '5px' }}>
                                                {t("Go to Help Center")}
                                            </Typography>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid xs={12} sm={12} md={3} className={classes.cardGrid2}>
                                    <Card className={classes.helpCard} onClick={modalData.academyLink}>
                                        {/* <CardContent> */}
                                            {/* <img height="120" src="/img/RoomPrice Genie_2_reading.png" style={{ marginRight: 'auto', marginLeft: 'auto', display: 'block' }} /> */}
                                        {/* </CardContent> */}
                                        <CardActions >
                                            <Typography style={{ margin: '0 auto',textAlign:'center', display: 'block', padding: '5px' }}>
                                                {t("RoomPriceGenie Academy")}
                                            </Typography>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                }
                {modalData.state.appWalkthroughsModal &&
                    <Dialog
                        fullScreen={fullScreen}
                        maxWidth='lg'
                        open={openhint}
                        onClose={modalData.closeAppWalkthroughsModal}
                        aria-labelledby="responsive-dialog-title"
                        PaperProps={{ style: { margin: "10px", minWidth: '60%' } }}
                    >
                        <IconButton aria-label="Close" className={classes.goIconHover} onClick={modalData.closeAppWalkthroughsModal} style={{ position: "absolute", left: "0", marginTop: '5px' }}>
                            <ArrowBackIosIcon />
                            <Typography>{t("Go Back")}</Typography>
                        </IconButton>

                        <DialogTitle id="responsive-dialog-title" style={{ textAlign: 'center' }}>
                            <span style={{ fontSize: '28px' }}>{t("In-App Walkthroughs")}</span>
                        </DialogTitle>
                        <IconButton aria-label="Close" onClick={modalData.closeAppWalkthroughsModal} style={{ position: "absolute", right: "0" }}>
                            <CloseIcon />
                        </IconButton>
                        <DialogContent>
                            <Grid container>
                                <Grid md={12}>
                                {modalData.state.language == "de" ?
                                    <>
                                    <a class="btn btn-primary btn-sm" href={" /client/Calendar/"+ modalData.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=9fa928c9-8cf6-413d-8cee-8b418e729b00"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={modalData.handleCloseMenu} className={classes.walkthroughsText}>{t('Walkthrough 0: Navigate through the new app')} </MenuItem></a>
                                    <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + modalData.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=10b1eecd-ccc1-4f4a-9371-bb087d9a5fcf"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={modalData.handleCloseMenu} className={classes.walkthroughsText}>{t('Walkthrough 1: Get an overview of the price calendar')}</MenuItem></a>
                                    <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + modalData.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=0c22b60e-012a-4f59-9529-f727306ab919"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={modalData.handleCloseMenu} className={classes.walkthroughsText}>{t('Walkthrough 2: Understand our price recommendations')}</MenuItem></a>
                                    <div style={{ float: 'right', marginTop: '29px' }}>
                                        <img height="200" src="/img/market.gif" className={classes.hideOnMobile} />
                                    </div>
                                    <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + modalData.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=d339c03c-5b62-4bc4-b9d6-08d77805b2b8"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={modalData.handleCloseMenu} className={classes.walkthroughsText}>{t('Walkthrough 3: Check your occupancy performance')}</MenuItem></a>
                                    <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + modalData.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=2cc08288-c2bd-489d-8ef6-9c5eefe191c2"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={modalData.handleCloseMenu} className={classes.walkthroughsText}>{t('Walkthrough 4: Edit room setup')} </MenuItem></a>
                                    <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + modalData.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=10ef0bd9-bdc0-457a-ad7c-0a311cfc9fb1"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={modalData.handleCloseMenu} className={classes.walkthroughsText}>{t('Walkthrough 5: Define your pricing strategy')}</MenuItem></a>
                                    <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + modalData.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=c218c17c-54fe-4af1-b1b1-a34fe39522c6"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={modalData.handleCloseMenu} className={classes.walkthroughsText}>{t('Walkthrough 6: Adjust your prices')}</MenuItem></a></>
                                    :
                                   <>
                                    <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + modalData.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=-MA6ZXrFxd9I8eYeGUee"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={modalData.handleCloseMenu} className={classes.walkthroughsText}>{t('Walkthrough 0: Navigate through the new app')} </MenuItem></a>
                                    <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + modalData.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=-M5gCyE0m_AvthflMFt3"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={modalData.handleCloseMenu} className={classes.walkthroughsText}>{t('Walkthrough 1: Get an overview of the price calendar')}</MenuItem></a>
                                    <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + modalData.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=-M5uODUX66Yo6DCzQI7Q"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={modalData.handleCloseMenu} className={classes.walkthroughsText}>{t('Walkthrough 2: Understand our price recommendations')}</MenuItem></a>
                                    <div style={{ float: 'right', marginTop: '29px' }}>
                                        <img height="200" src="/img/market.gif" className={classes.hideOnMobile} />
                                    </div>
                                    <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + modalData.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=-M6TgDNoaunYRaVMm-Y3"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={modalData.handleCloseMenu} className={classes.walkthroughsText}>{t('Walkthrough 3: Check your occupancy performance')}</MenuItem></a>
                                    <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + modalData.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=-M6U76Rtf2-9oT1_lMP8"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={modalData.handleCloseMenu} className={classes.walkthroughsText}>{t('Walkthrough 4: Edit room setup')} </MenuItem></a>
                                    <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + modalData.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=-M6U7cblaJaxCoEsU3IC"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={modalData.handleCloseMenu} className={classes.walkthroughsText}>{t('Walkthrough 5: Define your pricing strategy')}</MenuItem></a>
                                    <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + modalData.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=-MA6vA3ueZ09fI76nCaa"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={modalData.handleCloseMenu} className={classes.walkthroughsText}>{t('Walkthrough 6: Adjust your prices')}</MenuItem></a></> }
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                }
                {modalData.state.supportRequestModal &&
                    <Dialog
                        fullScreen={fullScreen}
                        maxWidth='lg'
                        open={openhint}
                        onClose={modalData.closeSupportRequestModal}
                        aria-labelledby="responsive-dialog-title"
                        PaperProps={{ style: { margin: "10px", minWidth: '40%' } }}
                    >
                        <DialogTitle id="responsive-dialog-title" style={{ textAlign: 'left' }}>
                            <span style={{ fontSize: '28px' }}>{t("Report an Issue")}</span>
                            <Typography style={{ fontSize: '14px' }}>{t('Please use this form to send technical errors to RoomPriceGenie Support')}</Typography>
                        </DialogTitle>
                        {/* <DialogTitle> */}
                        <IconButton aria-label="Close" onClick={modalData.closeSupportRequestModal} style={{ position: "absolute", right: "0" }}>
                            <CloseIcon />
                        </IconButton>
                        {/* </DialogTitle> */}
                        <DialogContent>
                            <Grid container>
                                <Grid item md={12} xs={12}>    
                                    <FormControl fullWidth margin="normal" required className={classes.formControl}>
                                        <TextField
                                            error={modalData.state.error.includes("subject")}
                                            required
                                            id="subject"
                                            label={t("Property Name")}
                                            margin="none"
                                            onBlur={modalData.handleCreateReportAnIssue("subject")}
                                            // value={modalData.state.subject}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {modalData.state.error.includes("subject") ? <span class='error-message'>{t('* Please Enter Property Name')}</span> : ''}
                                    </FormControl>
                                    <FormControl fullWidth margin="normal" required className={classes.formControl}>
                                        <TextField
                                            error={modalData.state.error.includes("contant")}
                                            label={t("What issue are you experiencing?")}
                                            placeholder={t("Please describe your issue in detail so we can get it solved for you as soon as possible.")}
                                            multiline
                                            margin="none"
                                            required
                                            rows="3"
                                            // value={modalData.state.content}
                                            onBlur={modalData.handleCreateReportAnIssue("contant")}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            className={classes.textField}
                                        />
                                        {modalData.state.error.includes("contant") ? <span class='error-message'>{t('* Please Enter Contant')}</span> : ''}
                                    </FormControl>
                                    <FormControl fullWidth margin="normal" required className={classes.formControl}>
                                    <FormLabel style={{color:'#4b4b4b',fontWeight:'500'}}>{t('How would you categorize your request?')}</FormLabel>
                                        <FormGroup>
                                        {modalData.state.categorizeRequest.map((row, index) => {
                                            return (
                                                <>
                                                <FormControlLabel
                                                style={{height:'36px'}}
                                                control={
                                                    <Checkbox
                                                    color="primary" 
                                                    className={classes.CheckboxLabel}
                                                    name={row.value}
                                                    onChange={(e) => modalData.toggleTicketCategory(row.value, e)}
                                                    error={modalData.state.error.includes("hs_ticket_category")}
                                                    />
                                                }
                                                label={row.label}
                                                />
                                                
                                                </>
                                            );
                                            })}
                                        </FormGroup>
                                        {modalData.state.error.includes("hs_ticket_category") ? <span class='error-message'>{t('* Please Enter Categorize Request')}</span> : ''}
                                    </FormControl>
                                    {/* <Typography style={{fontWeight:'500'}}>{t('Priority*')}</Typography> */}

                                    <FormControl fullWidth margin="normal" required className={classes.formControl}>
                                        <TextField
                                            margin="none"
                                            required
                                            error={modalData.state.error.includes("hs_ticket_priority")}
                                            id="hs_ticket_priority"
                                            select
                                            placeholder={'Please Select'}
                                            label={t('Indicate the urgency of the issue you are facing')}
                                            onChange={modalData.handleCreateReportAnIssue('hs_ticket_priority')}
                                            value={modalData.state.hs_ticket_priority}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                            <MenuItem value={''}>-Select-</MenuItem>
                                            
                                                <MenuItem value={'LOW'}>
                                                    {t('Low')}
                                                </MenuItem>
                                                <MenuItem value={'MEDIUM'}>
                                                    {t('Medium')}
                                                </MenuItem>
                                                <MenuItem value={'HIGH'}>
                                                    {t('High')}
                                                </MenuItem>                                    
                                        </TextField>
                                        {modalData.state.error.includes("hs_ticket_priority") ? <span class='error-message'>{t('* Please Select Ticket Priority')}</span> : ''}
                                    </FormControl>
                                    <Typography variant='h5' style={{marginTop:'15px'}}>{t('Your Contact Details')}</Typography>
                                    <FormControl fullWidth  margin="normal" required className={classes.formControl}>
                                        <TextField
                                            required
                                            error={modalData.state.error.includes("firstname")}
                                            id="firstname"
                                            label={t("First Name")}
                                            margin="none"
                                            onBlur={modalData.handleCreateReportAnIssue("firstname")}
                                            // value={modalData.state.firstname}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {modalData.state.error.includes("firstname") ? <span class='error-message'>{t('* Please Enter your First Name')}</span> : ''}
                                    </FormControl>
                                    <FormControl fullWidth margin="normal" required className={classes.formControl}>
                                        <TextField
                                            required
                                            error={modalData.state.error.includes("lastname")}
                                            id="standard-name"
                                            label={t("Last Name")}
                                            margin="none"
                                            onBlur={modalData.handleCreateReportAnIssue("lastname")}
                                            // value={modalData.state.lastname}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {modalData.state.error.includes("lastname") ? <span class='error-message'>{t('* Please Enter your Last Name')}</span> : ''}
                                    </FormControl>
                                    <FormControl fullWidth margin='normal'  required className={classes.formControl}>
                                        <TextField
                                            margin="none"
                                            error={modalData.state.error.includes("how_do_you_want_to_be_contacted_")}
                                            id="lastname"
                                            select
                                            required
                                            placeholder={'Please Select'}
                                            label={t('How do you want to be contacted?')}
                                            onChange={modalData.handleCreateReportAnIssue('how_do_you_want_to_be_contacted_')}
                                            value={modalData.state.how_do_you_want_to_be_contacted_}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                            <MenuItem value={''}>-Select-</MenuItem>
                                                <MenuItem value={'Phone'}>
                                                    {t('Phone')}
                                                </MenuItem>
                                                <MenuItem value={'E-Mail'}>
                                                    {t('Email')}
                                                </MenuItem>
                                                                                
                                        </TextField>
                                        {modalData.state.error.includes("how_do_you_want_to_be_contacted_") ? <span class='error-message'>{t('* Please select any one')}</span> : ''}
                                    </FormControl>
                                    <FormControl fullWidth margin="normal" required className={classes.formControl}>
                                        <TextField
                                            required
                                            error={modalData.state.error.includes("email")}
                                            id="email"
                                            label={t("Email")}
                                            margin="none"
                                            onBlur={modalData.handleCreateReportAnIssue("email")}
                                            // value={modalData.state.email}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {modalData.state.error.includes("email") ? <span class='error-message'>{t('* Please Enter your Email')}</span> : ''}
                                    </FormControl>
                                    <FormControl fullWidth margin="normal" className={classes.formControl}>
                                        <TextField
                                            placeholder="+xx xxx xxx xxx"
                                            id="number"
                                            type='number'
                                            label={t("Phone number")}
                                            margin="none"
                                            onBlur={modalData.handleCreateReportAnIssue("phone")}
                                            // value={modalData.state.phone}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                    {/* <div>
                                        <iframe name="myframe" style={{ 'border': 'none', 'marginTop': '50px', width: "650px", height: "1180px" }} src={"https://static.roompricegenie.com/createTicket.html"}></iframe>
                                    </div> */}
                                <Button margin="normal" className={classes.saveBtn} onClick={modalData.submitIssueReport} variant="contained" color="primary" disabled={modalData.props.commonReducer.reportIssueLoading}>
                                    {t('Submit')}
                                    {modalData.props.commonReducer.reportIssueLoading && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                                </Button>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                }
            </>
        )
    }
}
