import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = {};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_FEATURES_START:
            return updateObject(state, {loading: true, error: null})
        case actionTypes.GET_FEATURES_SUCCEED:
            return updateObject(state, {loading: false, error: null, features: action.state})
        case actionTypes.GET_FEATURES_FAILED:
            return updateObject(state, {loading: false, error: action.error})
        case actionTypes.UPDATE_FEATURES_START:
            return updateObject(state, {loading: true, error: null})
        case actionTypes.UPDATE_FEATURES_SUCCEED:
            return updateObject(state, {loading: false, error: null, features: action.state})
        case actionTypes.UPDATE_FEATURES_FAILED:
            return updateObject(state, {loading: false, error: action.error})
        case actionTypes.DELETE_FEATURES_START:
            return updateObject(state, {loading: true, error: null})
        case actionTypes.DELETE_FEATURES_SUCCEED:
            return updateObject(state, {loading: false, error: null, features: action.state})
        case actionTypes.DELETE_FEATURES_FAILED:
            return updateObject(state, {loading: false, error: action.error})
        default:
            return updateObject(state, {loading: false, error: null});
    }
}

export default reducer