import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import adjustmentCss from '../css/modal/adjustmentCss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../../store/actions/index';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { weeklyAdjustTheme } from '../../muiTheme';
import { withNamespaces } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = adjustmentCss;

class WeeklyAdjustment extends React.Component {

  state = {
    loadData: true,
    weeklyData: null,
    error: [],
    settingAlert: false
  };

  componentWillMount() {
    let requestParam = { token: this.props.hotelAuthReducer.hotelDetails.token }
  }

  componentWillReceiveProps(props) {

    if (props.priceSettingReducer.priceSetting) {
      let weeklyData = props.priceSettingReducer.priceSetting.adjustment.weekday;

      this.setState({
        weeklyData: weeklyData
      })
    }

    if (props.weeklyAdjustReducer.weeklyList) {
      this.setState({
        loadData: false
      })
    } else {
      this.setState({
        loadData: true
      })
    }

  }

  //function for weekly adjustments

  validateWeeklyAdjust = (data) => {
    let error = [];
    let weeklyData = this.state.weeklyData;
    var daysInWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    daysInWeek.map((row) => {
      if ((!weeklyData[row].standard || weeklyData[row].standard === '') && weeklyData[row].standard !== 0) {
        error.push("per_" + row);
      }
      return null;
    })
    if (error) {
      this.setState({
        error: error
      })
      return error
    }
    else {
      return false
    }

  }


  toggleSettingAlert = () => {
    this.props.modalDataProps.toggleWeeklyModal();
  }

  updateWeeklyAdjust = () => {

    let validateError = this.validateWeeklyAdjust();

    if (validateError.length > 0) {
      return;
    }

    let rows = this.state.weeklyData;

    let requestParams = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      allData: rows,
    }

    this.props.modalDataProps.saveWeeklyData(requestParams)
    // this.toggleSettingAlert();
  }

  handleWeekly = (row) => event => {
    let weekData = [];
    weekData = this.state.weeklyData;

    if (event.target.value != "" && event.target.value != "-") {
      weekData[row].standard = parseFloat(event.target.value);
    } else {
      weekData[row].standard = (event.target.value);
    }
    this.setState({
      weeklyData: weekData
    }, () => this.props.modalDataProps.forceUpdate())
  }

  renderWeeklyTable = () => {
    const { t } = this.props;
    var jsx = [];
    var rows = [];
    const { classes } = this.props;
    let percentAdorn = <Typography variant="body2" className={classes.adornment}>%</Typography>;

    let weekData = this.state.weeklyData;
    var daysInWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    {
      weekData && daysInWeek.map((row, index) => {
        return jsx.push(
          <TableCell key={index} align="right">
            <FormControl margin="normal" required className={classes.fieldMargin}>
              <Input
                type="number"
                margin="normal"
                error={this.state.error.includes("per_" + row)}
                className={classes.textField}
                onChange={this.handleWeekly(row)}
                value={weekData[row].standard}
                endAdornment={percentAdorn}
              />
              {this.state.error.includes("per_" + row) ? <FormHelperText><span class="error-message">{t('* Please enter the value')}</span></FormHelperText> : ''}
            </FormControl>
          </TableCell>
        )
      })
    }
    return jsx;
  }
  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.root}>
        <div>
          <div className={classes.dateTableDiv} >
            <MuiThemeProvider theme={weeklyAdjustTheme}>
              <Table className={classes.userTable}>
                <TableHead>
                  <TableRow className={classes.rowTable}>
                    <TableCell align="right"> </TableCell>
                    <TableCell className={classes.weekCellWidth} align="right">{t("Sun")}</TableCell>
                    <TableCell className={classes.weekCellWidth} align="right">{t("Mon")}</TableCell>
                    <TableCell className={classes.weekCellWidth} align="right">{t("Tue")}</TableCell>
                    <TableCell className={classes.weekCellWidth} align="right">{t("Wed")}</TableCell>
                    <TableCell className={classes.weekCellWidth} align="right">{t("Thu")}</TableCell>
                    <TableCell className={classes.weekCellWidth} align="right">{t("Fri")}</TableCell>
                    <TableCell className={classes.weekCellWidth} align="right">{t("Sat")}</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={'s1'} className={classes.rowTable}>
                    <TableCell>{t("Adjust")}<br />{t("Price by")}</TableCell>
                    {this.renderWeeklyTable()}
                    <TableCell align="right" ></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </MuiThemeProvider>
          </div>
          <div className={classes.adjustmentBtnPopup}>
            <Button className={"orangButton"} disabled={this.props.modalDataProps.state.monthWeekLoader} variant="contained" color="primary" onClick={this.updateWeeklyAdjust}>
              {t("Save")}{this.props.modalDataProps.state.monthWeekLoader && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
            </Button>
            <Button style={{ marginRight: "10px" }} className="cancelBtn" onClick={() => this.props.modalDataProps.toggleWeeklyModal()}>
              {t("Cancel")}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

WeeklyAdjustment.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
  return {
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    userSession: state.sessionReducer,
    hotelDetail: state.hotelSettingReducer.hoteDetail,
    currencyList: state.currencyReducer.currencyList,
    hotelAuthReducer: state.hotelAuthReducer,
    weeklyAdjustReducer: state.weeklyAdjustReducer,
    roomList: state.roomListReducer,
    commonReducer: state.commonReducer,
    priceSettingReducer: state.priceSettingReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateWeeklyAdjust: (request) => { dispatch(actions.updateWeeklyAdjust(request)) },
    getPricingSettings: (request) => { dispatch(actions.getPricingSettings(request)) },
  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(WeeklyAdjustment));
