import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = [];

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SCHEDULE_HISTORY_LIST_START:
            return updateObject(state, { loading: true, error: null });
        case actionTypes.SCHEDULE_HISTORY_LIST_SUCCEED:
            return updateObject(state, { loading: false, error: null, scheduleHistoryList: action.state })
        case actionTypes.SCHEDULE_HISTORY_LIST_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.SCHEDULE_HISTORY_DETAILS_START:
            return updateObject(state, { loading: true, error: null });
        case actionTypes.SCHEDULE_HISTORY_DETAILS_SUCCEED:
            return updateObject(state, { loading: false, error: null, scheduleHistoryDetails: action.state })
        case actionTypes.SCHEDULE_HISTORY_DETAILS_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        default:
            return updateObject(state, { loading: false, error: null });
    }
}

export default reducer