import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';
import Pusher from 'pusher-js';

const getPusher = {
    connectionPusher: new Pusher(process.env.REACT_APP_KEY, {
        cluster: process.env.REACT_APP_CLUSTER
    }),
};

const initialState = {
    notify: [],
    tempData: false,
    initPusher: null,
    pusher: getPusher
};
const reducer = (state = initialState, action) => {
     switch (action.type) {
         case actionTypes.POPUP_ALERT_SUCCESS:
            return updateObject(state,{error:false,popupMessage:action.state});
        case actionTypes.POPUP_ALERT_FAILD:
            return updateObject(state, { error:true, popupMessage:[]});
        default:
            return state;
     }
}

export default reducer