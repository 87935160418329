import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import errorTicketCss from './css/errorTicketCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../store/actions/index';
import MUIDataTable from "mui-datatables";
import Button from '@material-ui/core/Button';
import Modal from './modal/modal'
import {  MuiThemeProvider } from '@material-ui/core/styles';
import { criticalErrorTheme } from '../muiTheme';
import { Hidden, Icon } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import {Link} from 'react-router-dom'
import moment from 'moment';
import HeaderCard from "../Client/HeaderCard";
import Grid from "@material-ui/core/Grid";
import developerDataArray from "../developersList"

const styles = errorTicketCss;

const statusDataArray = [
    { label: 'Open', value: 1 },
    { label: 'Closed', value: 2 },
]

const tagsDataArray = [
    { label: 'Inventory Invalid', value: 'Inventory Invalid' },
    { label: 'Inventory&Price', value: 'Inventory&Price' },
    { label: 'Missing Inventory', value: 'Missing Inventory' },
    { label: 'Not Mapped', value: 'Not Mapped' },
    { label: 'Inventory Stopped', value: 'Inventory Stopped' },
    { label: 'Program Timeout', value: 'Program Timeout' },
    { label: 'Scheduled Program', value: 'Scheduled Program' },
    { label: 'Negative Inventory', value: 'Negative Inventory' },
    { label: 'Upload Price', value: 'Upload Price' },
    { label: 'Protel', value: 'Protel' },
    { label: 'Protel Upload', value: 'Protel Upload' },
    { label: 'Webhook RS error', value: 'Webhook RS error' },
    { label: 'Client Request (V2)', value: 'Client Request (V2)' },
    { label: 'Mapping (V2)', value: 'Mapping (V2)' },
    { label: 'Broker (V2)', value: 'Broker (V2)' },
    { label: 'Webhook (V2)', value: 'Webhook (V2)' },
    { label: 'Service (V2)', value: 'Service (V2)' },
    { label: 'General', value: 'General' },
    { label: 'Outdated Inventory', value: 'Outdated Inventory' },
    { label: 'Empty Price Result', value: 'Empty Price Result' },
]

class ErrorTickets extends React.Component {

    state = {
        error:false,
        count: 0,
        current: 1,
        ticket_status: 1,
        developer: '',
        ticket_id: '',
        pms: '',
        selectedIndex: [],
        showerrorTicket: false,
        tags: '',
        status: 1,
        total_count:0,
        developer_modal:'',
        error_message:{},
        is_multiple_update: false,
        show_multiple_update_modal: false,
        ml_ticket_ids: '',
        ml_status: 1,
        ml_developer: 'notassigned',
        page: '',
        tag_ml: '',
        extra_info: {},
    };
    
    toggleErrorTicketModal = (data) => {
        var duration = moment.duration((moment(new Date())).diff(moment(data.modified_date).format('YYYY-MM-DD HH:mm')));
        this.setState({
            hotel_name:data.hotel_name,
            hotel_id:data.hotel,
            responsible_person:data.responsible_person,
            reseller:((data.reseller != undefined) ? data.reseller : ""),
            pms_name:((data.pms_name != undefined) ? data.pms_name : ""),
            ticket_id:data.id,
            showerrorTicket: !this.state.showerrorTicket,
            status:data.ticket_status,
            developer_modal:((data.developer != undefined) ? data.developer : ""),
            tag_ml:((data.tags != "") ? data.tags : ""),
            total_count:data.count,
            modified_date:data.modified_date,
            time_difference: (duration.days()) + " days " + (duration.hours()) + " hours " + (duration.minutes()) + " minutes ago",
            error_message:data.error_message,
            extra_info:data.extra_info
        })
    }

    toggleMultipleErrorTicketModal = (ticket_ids, selectedIndices, data={}) => {
        this.setState({
            is_multiple_update: true,
            selectedIndex: selectedIndices ? selectedIndices : [],
            show_multiple_update_modal: !this.state.show_multiple_update_modal,
            ml_ticket_ids: ticket_ids,
            tags:((data.tags != "") ? data.tags : ""),
            developer: ((data.developer != "") ? data.developer : ""),
            pms: ((data.pms != "") ? data.pms : ""),
            page: ((data.page != "") ? data.page : ""),
        });
    }

    handleChangeTicketStatus = (event) => {
        const name = event.target.name;
        this.setState({
          ...this.state,
          [name]:  event.target.value
        });
        let requestParams = {
          token: this.props.userSession.token,
          url:'errors/error/?tags_type=critical&ticket_status='+ event.target.value + ((this.state.developer !== "") ? '&developer=' + this.state.developer : "") + ((this.state.pms !== "") ? '&pms=' + this.state.pms : "")  + ((this.state.tags !== "") ? '&tags=' + this.state.tags : "")
        };
        this.props.geterrorTicketsList(requestParams, "critical");
    };

    handleChangeTicketStatusModal = (event) => {
        this.setState({
          ...this.state,
          status: event.target.value
        });
    };
    
    handleChangeTicketStatusModalMultiple = (event) => {
        this.setState({
          ...this.state,
          ml_status: event.target.value
        });
    };

    handleChangeDeveloper = (event) => {
        const name = event.target.name;
        this.setState({
          ...this.state,
          [name]:  event.target.value
        });
        let requestParams = {
            token: this.props.userSession.token,
            url:'errors/error/?tags_type=critical&ticket_status='+ this.state.ticket_status + ((event.target.value !== "") ? '&developer=' + event.target.value : "") + ((this.state.pms !== "") ? '&pms=' + this.state.pms : "")  + ((this.state.tags !== "") ? '&tags=' + this.state.tags : "")
        };
        this.props.geterrorTicketsList(requestParams, "critical");
    };
    
    handleChangeTags = (event) => {
        const name = event.target.name;
        this.setState({
          ...this.state,
          [name]:  event.target.value
        });
        let requestParams = {
            token: this.props.userSession.token,
            url:'errors/error/?tags_type=critical&ticket_status='+ this.state.ticket_status + ((this.state.developer !== "") ? '&developer=' + this.state.developer : "") + ((this.state.pms !== "") ? '&pms=' + this.state.pms : "") + ((event.target.value !== "") ? '&tags=' + event.target.value : "")
        };
        this.props.geterrorTicketsList(requestParams, "critical");
    };
    
    handleChangePMSCM = (event) => {
        const name = event.target.name;
        this.setState({
          ...this.state,
          [name]:  event.target.value
        });
        let requestParams = {
            token: this.props.userSession.token,
            url:'errors/error/?tags_type=critical&ticket_status='+ this.state.ticket_status + ((this.state.developer !== "") ? '&developer=' + this.state.developer : "") + ((event.target.value !== "") ? '&pms=' + event.target.value : "") + ((this.state.tags !== "") ? '&tags=' + this.state.tags : "")
        };
        this.props.geterrorTicketsList(requestParams, "critical");
    };
    
    handleChangeDeveloperModal = (event) => {
        this.setState({
          ...this.state,
          developer_modal:  event.target.value
        });
    };
    
    handleChangeDeveloperModalMultiple = (event) => {
        this.setState({
          ...this.state,
          ml_developer:  event.target.value
        });
    };

    updateSingleErrorTicket = () => {
        let requestParams = {
            token: this.props.userSession.token,
            id: this.state.ticket_id,
            developer: this.state.developer_modal,
            ticket_status: this.state.status
        };
        let requestParamsReload = {
            token: this.props.userSession.token,
            ticket_status: this.state.ticket_status ? this.state.ticket_status : 1,   
            developer: "",
            tags: this.state.tags ? this.state.tags : "",
            pms: this.state.pms ? this.state.pms : "",
            page: this.state.page ? this.state.page : "",
			tags_type:"critical"
        };
        this.props.updateSingleErrorTicket(requestParams, requestParamsReload, "critical")
        this.toggleErrorTicketModal(requestParamsReload)
    }

    updateMultipleErrorTicket = () => {
        let requestParams = {
            token: this.props.userSession.token,
            ids: this.state.ml_ticket_ids,
            developer: this.state.ml_developer,
            ticket_status: this.state.ml_status
        };
        let requestParamsReload = {
            token: this.props.userSession.token,
            ticket_status: 1,
            developer: "",
            tags: "",
            pms: "",
            page: this.state.page ? this.state.page : "",
			tags_type:"critical"
        };
        this.props.updateMultipleErrorTicket(requestParams, requestParamsReload, "critical")
        this.toggleMultipleErrorTicketModal(this.state.ml_ticket_ids, requestParams)
    }

    componentWillMount() {
        let requestParam = {
            token: this.props.userSession.token,
            ticket_status: 1,
            developer: this.state.developer ? this.state.developer : "",
            tags: this.state.tags ? this.state.tags : "",
            pms: this.state.pms ? this.state.pms : "",
            page: this.state.page ? this.state.page : "",
			tags_type:"critical"
        };

        this.props.geterrorTicketsList(requestParam, "critical");
    }

    componentWillReceiveProps(props) {
        if (props.ErrorTicketsReducer.criticalErrorTickets) {
            this.setState({
                criticalerrorTickets: props.ErrorTicketsReducer.criticalErrorTickets,
                count: props.ErrorTicketsReducer.criticalErrorTickets.count
            })
        }
    }

    rendererrorTicketsData = (data) => {
        let jsx = [];
        data.results.map((val) => {
            let er_ticket_status = "";
            if (val.ticket_status) {
                let estatus = statusDataArray.filter(row => row.value == val.ticket_status);
                
                if (estatus.length > 0) {
                    er_ticket_status = estatus[0].label;
                }
            }
            jsx.push([
                val.hotel,
                val.hotel_name,
                val.tags,
                val.count,
                val.pms_name,
                val.responsible_person,
                val.reseller,
                val.developer,
                val.modified_date ? moment(val.modified_date).format('YYYY-MM-DD HH:mm') : "-",
                er_ticket_status,
                val.id,
                val,
            ])
            return null;
        })
        return jsx;
    }

    options = () => {
        const { classes } = this.props;
        return {
            sort: true,
            filter:true,
            print: false,
            download: false,
            viewColumns: false,
            responsive: "scroll",
            selectableRows: "multiple",
            filterType: "dropdown",
            serverSide: true,
            page: (this.props.ErrorTicketsReducer.criticalErrorTickets) ? (this.props.ErrorTicketsReducer.criticalErrorTickets.current - 1) : 0,
            count: this.state.count,
            rowsPerPage: 20,
            rowsPerPageOptions: false,
            rowsSelected:this.state.selectedIndex.length > 0 ? this.state.selectedIndex : [],
            selectableRowsOnClick: true,
            customToolbarSelect: (selectedRows, displayData) => {
                let ticket_ids = selectedRows.data.map(
                    index => displayData.filter(i => i.dataIndex === index.dataIndex)[0].data[10]
                );
                const selectedIndices = selectedRows.data.map(index => index.dataIndex);
                return <React.Fragment>
                        <Button className={classes.btnUpdate} onClick={(id) => this.toggleMultipleErrorTicketModal(ticket_ids, selectedIndices)} title="Update Error Ticket" variant="contained" color="primary" >Update</Button>
                    </React.Fragment>
            },
            onTableChange: (action, tableState) => {
                let url = 'errors/error/?tags_type=critical&ticket_status=' + this.state.ticket_status;
                let requestParams = { token: this.props.userSession.token };

                if (action === 'changePage') {
                    let page = tableState.page + 1;
                    url += '&page=' + page + ((this.state.developer !== '' && this.state.developer !== null && this.state.developer !== undefined) ? '&developer=' + this.state.developer : "") + ((this.state.pms !== '' && this.state.pms !== null && this.state.pms !== undefined) ? '&pms=' + this.state.pms : "")  + ((this.state.tags !== '' && this.state.tags !== null && this.state.tags !== undefined) ? '&tags=' + this.state.tags : "")
                    requestParams.url = url
                    this.setState({
                        page: tableState.page + 1
                    })
                    this.props.geterrorTicketsList(requestParams, "critical")
                }
                if (action === 'search') {
                    let searchText = tableState.searchText ? tableState.searchText : '';
                    let searchHotelid = '';
                    if(parseInt(searchText) > 0) {
                        searchHotelid = searchText
                        url += '&hotel_id=' +  searchText.replace(/[^0-9]/g, "").replace(" ","") + ((this.state.ordering) ? '&ordering=' + this.state.ordering : '');
                    } else if (tableState.searchText) {
                        url += '&search=' + searchText + ((this.state.ordering) ? '&ordering=' + this.state.ordering : '');
                    }
                    requestParams.url = url
                    this.setState({
                        searchText: searchText,
                        searchHotelid: searchHotelid
                    },
                        () => {
                            setTimeout(() => {
                                if (searchText.length == this.state.searchText.length) {
                                    this.props.geterrorTicketsList(requestParams, "critical")
                                }
                            }, 2000);
                        })
                }

                if(action == 'filterChange' || action == 'resetFilters') {
                    let _filter = tableState.filterList.filter(val => val.length > 0)
                    if (_filter && _filter.length) {
                        if (tableState.filterList[2].length > 0) {
                            let tags = tagsDataArray.filter(row => row.label == tableState.filterList[2])[0].value;
                            url += '&tags=' + tags;
                            this.setState({ tags: tags })
                        }
                        if (tableState.filterList[9].length > 0) {
                            let ticket_status = statusDataArray.filter(row => row.label == tableState.filterList[9])[0].value;
                            url += '&ticket_status=' + ticket_status;
                            this.setState({ ticket_status: ticket_status })
                        }
                        if (tableState.filterList[7].length > 0) {
                            let developer = developerDataArray.filter(row => row.label == tableState.filterList[7])[0].value;
                            url += '&developer=' + developer;
                            this.setState({ developer: developer })
                        }
                        requestParams.url = url
                        this.props.geterrorTicketsList(requestParams, "critical")
                        
                    } else {
                        this.setState({ tags: null, pms: null, developer: null})
                        setTimeout(() => {
                            if(action === 'filterChange') {
                                url += ((this.state.page !== "" && this.state.page !== null) ? '&page=' + this.state.page : "") + ((this.state.developer !== "" && this.state.developer !== null) ? '&developer=' + this.state.developer : "") + ((this.state.pms !== "" && this.state.pms !== null) ? '&pms=' + this.state.pms : "")  + ((this.state.tags !== "" && this.state.tags !== null) ? '&tags=' + this.state.tags : "")
                                requestParams.url = url
                                this.props.geterrorTicketsList(requestParams, "critical")
                            }
                        },1000);
                    }
                }
                
            },
            onColumnSortChange: (changedColumn, direction) => {
                let sort_direction;
                let requestParams = { token: this.props.userSession.token };
                switch (changedColumn) {
                    case 'ID':
                        sort_direction = (direction === 'ascending') ? 'hotel' : '-hotel';
                        break;
                    case 'Hotel':
                        sort_direction = (direction === 'ascending') ? 'hotel_name' : '-hotel_name';
                        break;
                    case 'Tag':
                        sort_direction = (direction === 'ascending') ? 'tags' : '-tags';
                        break;
                    case 'PMS/CM':
                        sort_direction = (direction === 'ascending') ? 'pms_name' : '-pms_name';
                        break;
                    case 'Responsible Person':
                        sort_direction = (direction === 'ascending') ? 'responsible_person' : '-responsible_person';
                        break;
                    case 'Reseller':
                        sort_direction = (direction === 'ascending') ? 'reseller' : '-reseller';
                        break;
                    case 'Developer':
                        sort_direction = (direction === 'ascending') ? 'developer' : '-developer';
                        break;
                    case 'Date Modified':
                        sort_direction = (direction === 'ascending') ? 'modified_date' : '-modified_date';
                        break;
                    case 'Status':
                        sort_direction = (direction === 'ascending') ? 'ticket_status' : '-ticket_status';
                        break;
                    default:
                        sort_direction = (direction === 'ascending') ? 'hotel' : '-hotel';
                        break;
                }

                requestParams.url = 'errors/error/?tags_type=critical&ordering=' + sort_direction + ((this.state.searchText) ? '&search=' + this.state.searchText : '') + ((this.state.developer !== "" && this.state.developer !== null && this.state.developer !== undefined) ? '&developer=' + this.state.developer : "") + ((this.state.pms !== "" && this.state.pms !== null && this.state.pms !== undefined) ? '&pms=' + this.state.pms : "")  + ((this.state.tags !== "" && this.state.tags !== null && this.state.tags !== undefined) ? '&tags=' + this.state.tags : "") + ((this.state.ticket_status !== "") ? '&ticket_status=' + this.state.ticket_status : "");
                this.props.geterrorTicketsList(requestParams, "critical")
                this.setState({ ordering: sort_direction })
            },
            
            textLabels: {
                body: {
                noMatch: this.props.ErrorTicketsReducer.loading ?
                    'Loading Records...' :
                    'Sorry, no matching records found.',
                },
            }
        }
    };
    

    render() {
        const { classes, t } = this.props;
        // const theme = criticalErrorTheme;

        const columns = [
            {
                name: "ID",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            { 
                name: "Hotel",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "Tag",
                options: {
                    filter: true,
                    filterOptions: tagsDataArray.map(row => { return row.label }),
                }
            },
            {
                name: "Count",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "PMS/CM",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "Responsible Person",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "Reseller",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "Developer",
                options: {
                    filter: true,
                    filterOptions: developerDataArray.map(row => { return row.label }),
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if(value != null) {
                            if(value == "notassigned"){
                                return 'Not Assigned'                               
                            }else{
                                return value[0].toUpperCase() + value.slice(1);
                            }
                            
                        } else {
                            return "Notassigned";
                        }
                    }
                }
            },
            {
                name: "Date Modified",
                options: {
                    filter: false,
                    sort: true,
                    sortDirection: 'desc'
                }
            },
            {
                name: "Status",
                options: {
                    filter: true,
                    filterOptions: statusDataArray.map(row => { return row.label })
                }
            },
            {
                name: "Ticket ID",
                options: {
                    filter: false,
                    display: 'false',
                }
            },
            {
                name: "Actions",
                options: {
                  filter: false,
                  cellStyle:{
                    width: 100,
                    maxWidth: 100
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    var jira_ticket_url = "https://roompricegenie.atlassian.net/secure/CreateIssueDetails!init.jspa?issuetype=10009&pid=10015"+"&summary=Hotel Id : " + value.hotel + " - " + value.pms_name + " - " + "&description=" + "Hotel ID : " + value.hotel + "\\Hotel Name : " + value.hotel_name + "\\Responsible Person : " + value.responsible_person + "\\Reseller : " + value.reseller + "\\PMS/CM Name : " + value.pms_name
                    return (
                        <React.Fragment>
                            <Button className={classes.buttonItems} color="primary" onClick={(id) => this.toggleErrorTicketModal(value)} title="Edit Error Ticket"><Icon>edit</Icon></Button>
                            <Button href={jira_ticket_url} className={classes.buttonItems} target="_blank" color="secondary" title="Create Jira Ticket"><Icon>add_box_rounded</Icon></Button>
                        </React.Fragment>
                    );
                  }
                }
            },
        ];
        return (
            <>
                <HeaderCard>
                    <Grid container>
                        <Grid item >
                            <div className={classes.divStyle} >
                            <Typography variant="h5" className={classes.fontStyle} >
                                Critical Error Tickets
                            </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </HeaderCard>
            <Card>
                <CardContent>                
                    <div>
                        <MuiThemeProvider theme={criticalErrorTheme}>   
                            <MUIDataTable
                                title={''}
                                data={(this.state.criticalerrorTickets) ? this.rendererrorTicketsData(this.state.criticalerrorTickets):[]}
                                columns={columns}
                                options={this.options()}
                            />
                        </MuiThemeProvider>
                        {this.state.showerrorTicket ? Modal.errorTicketsModal(this) :""}
                        {this.state.is_multiple_update ? Modal.errorTicketsMultipleUpdateModal(this) : ""}
                    </div>
                </CardContent>
            </Card>
            </>
        );
    }
}

ErrorTickets.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        loading: state.ErrorTicketsReducer.loading,
        error: state.ErrorTicketsReducer.error,
        ErrorTicketsReducer: state.ErrorTicketsReducer,
        userSession: state.sessionReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        geterrorTicketsList: (request, error_tag_type) => { dispatch(actions.geterrorTicketsList(request, error_tag_type)) },
        updateSingleErrorTicket: (request, request2, error_tag_type) => { dispatch(actions.updateSingleErrorTicket(request, request2, error_tag_type)) },
        updateMultipleErrorTicket: (request, request2, error_tag_type) => { dispatch(actions.updateMultipleErrorTicket(request, request2, error_tag_type)) },
    };
};

export default compose(withStyles(styles, {
    withTheme: true
}), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(ErrorTickets));
