import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ClientListCss from '../../css/ReservationFailedTaskCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../../../store/actions/index';
import MUIDataTable from "mui-datatables";
import Button from '@material-ui/core/Button';
import Modal from '../../modal/modal'
import { MuiThemeProvider } from '@material-ui/core/styles';
import { reservationFailedTaskTheme } from '../../../muiTheme';
import { Icon } from '@material-ui/core';
import moment from "moment";
import CircularProgress from '@material-ui/core/CircularProgress';
const styles = ClientListCss;


class ReservationFailedTasks extends React.Component {

    state = {
        taskList: [],
        showDelete: false,
        showError: false,
        error: false,
        taskloader: false,
        page: '',
    };

    componentWillMount() {
        let requestParam = { token: this.props.userSession.token }
        this.props.getReservationFailedTaskList(requestParam);
    }

    componentWillReceiveProps(props) {
        if (props.ResarvationTaskReducer.failedTaskList) {
            const taskData = props.ResarvationTaskReducer.failedTaskList
            this.setState({
                count: taskData.count,
                current: taskData.current,
                next: taskData.next,
                previous: taskData.previous,
                taskList: taskData.results
            })
        }
        if (props.commonReducer.notify && props.commonReducer.notify.message) {
            this.setState({
                taskloader: false
            })
        }
    }

    renderTaskData = (data) => {
        let jsx = [];
        data.map((val) => {
            jsx.push([
                val.hotel,
                val.task,
                val.created ? moment(val.created).format('YYYY-MM-DD HH:mm') : '-',
                val.start_date ? moment(val.start_date).format('YYYY-MM-DD HH:mm') : '-',
                val.end_date ? moment(val.end_date).format('YYYY-MM-DD HH:mm') : '-',
                val
            ])
            return null;
        })
        return jsx;
    }

    retryFailedReservationTask = (task_data) => {
        let requestParam = { token: this.props.userSession.token, data:task_data }
        this.props.retryFailedReservationTask(requestParam);
        this.setState({
            taskloader: task_data.id,
        })
    }
    
    options = () => {
        return {
            sort: true,
            filter: false,
            print: false,
            download: false,
            viewColumns: false,
            responsive: "scroll",
            selectableRows: false,
            serverSide: true,
            page: (this.props.ResarvationTaskReducer.failedTaskList) ? (this.props.ResarvationTaskReducer.failedTaskList.current - 1) : 0,
            count: this.state.count,
            rowsPerPage: 10,
            rowsPerPageOptions: false,
            onTableChange: (action, tableState) => {

                let url = 'hotel/task_hotel_detail/';
                let requestParams = { token: this.props.userSession.token };
                if (action === 'changePage') {
                    let page = tableState.page + 1;
                    url += '?page=' + page + ((this.state.searchText) ? '&search=' + this.state.searchText : '');
                    requestParams.url = url
                    this.props.getReservationFailedTaskList(requestParams);
                }
                if (action === 'search') {
                    let searchText = tableState.searchText ? tableState.searchText : '';
                    if (tableState.searchText) {
                        url += '?search=' + searchText + ((this.state.ordering) ? '&ordering=' + this.state.ordering : '');
                    }
                    requestParams.url = url
                    this.setState({
                        searchText: searchText
                    },
                    () => {
                        setTimeout(() => {
                            if (searchText.length == this.state.searchText.length) {
                                this.props.getReservationFailedTaskList(requestParams)
                            }
                        }, 2000);
                    })
                }
                if (action != 'onSearchOpen' && action != 'search' && action != 'sort') {
                    this.props.getReservationFailedTaskList(requestParams);
                }
                if (action === 'search') {
                    let searchText = tableState.searchText ? tableState.searchText : '';
                    if (tableState.searchText) {
                        url += '&search=' + searchText + ((this.state.ordering) ? '&ordering=' + this.state.ordering : '');
                    }
                    requestParams.url = url
                    this.setState({
                        searchText: searchText
                    },
                        () => {
                            setTimeout(() => {
                                if (searchText.length == this.state.searchText.length) {
                                    this.props.getReservationFailedTaskList(requestParams)
                                }
                            }, 2000);
                        })
                }
            },
            textLabels: {
                body: {
                    noMatch: this.props.ResarvationTaskReducer.loading ?
                        'Loading Records...' :
                        'Sorry, no matching records found.',
                },
            },
            onColumnSortChange: (changedColumn, direction) => {
                let sort_direction;
                let requestParams = { token: this.props.userSession.token };

                switch (changedColumn) {
                    case 'Hotel':
                        sort_direction = (direction === 'ascending') ? 'hotel__id' : '-hotel__id';
                        break;
                    case 'Task':
                        sort_direction = (direction === 'ascending') ? 'task' : '-task';
                        break;
                    case 'Task created on':
                        sort_direction = (direction === 'ascending') ? 'created' : '-created';
                        break;
                    case 'Start Date':
                        sort_direction = (direction === 'ascending') ? 'start_date' : '-start_date';
                        break;
                    case 'End Date':
                        sort_direction = (direction === 'ascending') ? 'end_date' : '-end_date';
                        break;
                    default:
                        sort_direction = (direction === 'ascending') ? 'created' : '-created';
                        break;
                }

                requestParams.url = 'hotel/task_hotel_detail/?ordering=' + sort_direction + ((this.state.searchText) ? '&search=' + this.state.searchText : '');
                this.props.getReservationFailedTaskList(requestParams)
                this.setState({ ordering: sort_direction })
            },
            customSort: (data, colIndex, order) => {
                return data;
            },
        }
    };


    render() {
        const { classes } = this.props;

        const columns = [
            { name: "Hotel" },
            {
                name: "Task",
                options: {
                    filter: false,
                }
            },
            {
                name: "Task created on",
                options: {
                    filter: false,
                }
            },
            {
                name: "Start Date",
                options: {
                    filter: false,
                }
            },
            {
                name: "End Date",
                options: {
                    filter: false,
                }
            },

            {
                name: "Actions",
                options: {
                    filter: false,
                    download: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                <Button disabled={this.state.taskloader == value.id}  type="button" variant="outlined" color="primary" onClick={(id) => this.retryFailedReservationTask(value)} title="Retry Failed Reservation Task">
                                    Retry {(this.state.taskloader == value.id) && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                                </Button>
                            </React.Fragment>
                        );
                    }
                }
            }
        ];
        return (
            <Card>
                <CardHeader
                    className="headig-top"
                    title="Reservation Failed Tasks List">
                </CardHeader>
                <CardContent>
                    <div>
                        <MuiThemeProvider theme={reservationFailedTaskTheme}>
                            <MUIDataTable
                                title={''}
                                data={this.renderTaskData(this.state.taskList)}
                                columns={columns}
                                options={this.options()}
                            />
                        </MuiThemeProvider>
                        {Modal.ErrorModal(this)}
                    </div>
                </CardContent>
            </Card >
        );
    }
}

ReservationFailedTasks.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        userSession: state.sessionReducer,
        ResarvationTaskReducer: state.ResarvationTaskReducer,
        commonReducer: state.commonReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getReservationFailedTaskList: (request) => { dispatch(actions.getReservationFailedTaskList(request)) },
        retryFailedReservationTask: (request) => { dispatch(actions.retryFailedReservationTask(request)) },
    };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(ReservationFailedTasks);
