import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';

const onCountryStart = () => {
    return {
        type: actionTypes.COUNTRY_START
    }
}

const onCountryFailed = (error) => {
    return {
        type: actionTypes.COUNTRY_FAILED,
        error: error
    }
}

const onCountrySucceed = (data) => {
    return {
        type: actionTypes.COUNTRY_SUCCEED,
        state: data
    }
}

export const getCountry = (request) => {
    return dispatch => {
        dispatch(onCountryStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'system/country/list/')
            .then(function (response) {
                dispatch(onCountrySucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onCountryFailed(error))
            });
    }
}