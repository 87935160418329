import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DateCss from './css/dateSettingCss';
import Modals from './modal/modal';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../../store/actions/index';
import moment from 'moment';
import { Icon, CardContent } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { dailySettingsTheme } from '../../muiTheme';
import { withNamespaces, Trans } from 'react-i18next';
import MUIDataTable from "mui-datatables";

const styles = DateCss;

class DateSetting extends React.Component {

  state = {
    avg_month_week: null,
    startDate: null,
    endDate: null,
    openAverageGraph: false,
    avg_graph: null,
    smartDailyError: [],
    smartMonthlyError: [],
    loadData: true,
    openEditSkipDateModal: false,
    openSkipDateModal: false,
    openDeleteSkipDateModal: false,
    editSkipDate: moment().format('YYYY-MM-DD'),
    checkSkipDate: null,
    editSkipId: '',
    deleteSkipDate: moment().format('YYYY-MM-DD'),
    deleteSkipId: '',

    openDailyAdjustModal: false,
    openEditDailyAdjustModal: false,
    openDeleteDailyAdjustModal: false,
    addModalData: {
      percentage: '',
      date: moment().format('YYYY-MM-DD'),
    },
    checkDailyAdjustDate: null,
    editDailyAdjustModal: {
      id: '',
      percentage: '',
      date: '',
    },
    deleteModalData: {
      id: '',
      percentage: '',
      date: '',
    },
    monthlyData: [],
    weeklyData: [],
    error: [],
    average_price: 0,
    daily_adjustment: [],
    daily_competitor_average: [],
    monthly_adjustment: [],
    monthly_competitor_average: [],
    standard_smart: 'standard_settings',
    skipDate: moment().format('YYYY-MM-DD'),
    reference_room: {},
    featuresEnabled: [],
    adjustDates:null
  };

  componentWillMount() {
    let requestParam = { token: this.props.hotelAuthReducer.hotelDetails.token }
  }

  componentWillReceiveProps(props) {

    if (props.roomList.roomList) {
      this.setState({
        reference_room: props.roomList.roomList.find(o => o.is_reference_room === true)
      })
    }

    if (props.priceSettingReducer && props.priceSettingReducer.priceSetting) {
      this.setState({
          featuresEnabled: props.priceSettingReducer.priceSetting.features
      })
}

    if (props.priceSettingReducer.priceSetting) {
      let adjustDates = [];
      Object.keys(props.priceSettingReducer.priceSetting.dates).map((key) => {
        if (props.priceSettingReducer.priceSetting.dates[key].individual_adjustment) {
          adjustDates.push({
            ...props.priceSettingReducer.priceSetting.dates[key].individual_adjustment,
            date: key
          })
        }
      })
      this.setState({
        adjustDates
      })
    }

  }

  handleEndDate = () => value => {
    this.setState({
      endDate: value,
    })
  }

  //functions for daily adjustment

  toggleDailyAdjustModal = () => {
    this.setState({
      addModalData: {
        ...this.state.addModalData,
        date: moment(),
        percentage: ''
      },
      skipDate: moment().format('YYYY-MM-DD'),
      openDailyAdjustModal: !this.state.openDailyAdjustModal,
      error: []
    });
  }

  toggleEditDailyAdjust = (val) => {
    if (val) {
      this.setState({
        editDailyAdjustModal: {
          percentage: val.percentage,
          date: val.date,
        },
        checkDailyAdjustDate: val.date
      });
    }
    this.setState({
      openEditDailyAdjustModal: !this.state.openEditDailyAdjustModal,
    });
  }

  toggleDeleteDailyAdjust = (val) => {
    if (val) {
      this.setState({
        deleteModalData: {
          percentage: val.percentage,
          date: val.date,
        }
      });
    }
    this.setState({
      openDeleteDailyAdjustModal: !this.state.openDeleteDailyAdjustModal,
    });
  }

  handleDailyAdjustModal = (name) => event => {
    let inputValue = ((name === 'date') ? event : event.target.value);
    this.setState({
      addModalData: {
        ...this.state.addModalData,
        [name]: inputValue,
      }
    });
  }

  handleEditDailyAdjustModal = (name) => event => {
    let inputValue = ((name === 'date') ? event : event.target.value);
    this.setState({
      editDailyAdjustModal: {
        ...this.state.editDailyAdjustModal,
        [name]: inputValue,
      }
    });
  }


  validateDailyAdjustForm = (data) => {
    let error = [];

    if (!this.state.addModalData.date) {
      error.push('date')
    }

    if (!this.state.addModalData.percentage) {
      error.push('percentage')
    }

    if (error) {
      this.setState({
        error: error
      })
      return error
    }
    else {
      return false
    }

  }

  addNewDailyAdjust = () => {

    let validateError = this.validateDailyAdjustForm(this.state.addModalData);

    if (validateError.length > 0) {
      return;
    }

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      date: moment(this.state.addModalData.date).format('YYYY-MM-DD'),
      percentage: this.state.addModalData.percentage,
    }
    this.props.dailyAdjustCreate(requestParam);
    this.toggleDailyAdjustModal();
  }

  validateEditDailyAdjust = (data) => {
    let error = [];

    if (!this.state.editDailyAdjustModal.date) {
      error.push('edit_date')
    }

    if (!this.state.editDailyAdjustModal.percentage) {
      error.push('edit_percentage')
    }

    if (error) {
      this.setState({
        error: error
      })
      return error
    }
    else {
      return false
    }

  }


  editDailyAdjust = () => {


    let validateError = this.validateEditDailyAdjust(this.state.monthlyData);

    if (validateError.length > 0) {
      return;
    }

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      ...this.state.editDailyAdjustModal
    }
    this.props.updateDailyAdjust(requestParam);
    this.toggleEditDailyAdjust();
  }

  disableUsedSkipDates = (current_date) => {
    let disabled = false;

    this.props.skipPriceReducer.skipDates.map((row, index) => {

      if ((this.state.checkSkipDate === null) && (moment(current_date).format('YYYY-MM-DD') === row.date)) {
        disabled = true;
      } else if (this.state.checkSkipDate !== null && moment(current_date).format('YYYY-MM-DD') === row.date) {
        if (moment(current_date).format('YYYY-MM-DD') !== this.state.checkSkipDate) {
          disabled = true;
        }
      } else {

      }
      return null;
    })
    return disabled;
  }

  disableUsedSingleAdjustDates = (current_date) => {
    let disabled = false;

    this.props.dailyAdjustmentReducer.dailyAdjustment.map((row, index) => {

      if ((this.state.checkDailyAdjustDate === null) && (moment(current_date).format('YYYY-MM-DD') === row.date)) {
        disabled = true;
      } else if (this.state.checkDailyAdjustDate !== null && moment(current_date).format('YYYY-MM-DD') === row.date) {
        if (moment(current_date).format('YYYY-MM-DD') !== this.state.checkDailyAdjustDate) {
          disabled = true;
        }
      } else {

      }
      return null;
    })
    return disabled;
  }

  renderDailyAdjustTable = (rows) => {
    var jsx = [];
    if (rows) {
      rows.map((row, index) => {
        return jsx.push([moment(row.date).format('DD-MM-YYYY'), 'Change Price by  ' + row.percentage + ' %', row]);
      })
    }
    return jsx;
  }



  render() {
    const { classes } = this.props;
    const { t } = this.props;

    const DailyAdjustOptions = () => {
      return {
        sort: false,
        search: false,
        viewColumns: false,
        responsive: "scroll",
        selectableRows: false,
        rowsPerPage: 15,
        rowsPerPageOptions: false,
        filter: false,
        download: false,
        print: false,
        customToolbar: () => {
          return (
            <React.Fragment>
              <Button className={classes.addUserBtn} variant="contained" color="primary" onClick={this.toggleDailyAdjustModal}>
                {t("Add new")}
              </Button>
            </React.Fragment>
          );
        },
        textLabels: {
          body: {
            noMatch: ''
          },
        }
      }
    };

    const DailyAdjustcols = [
      t("Date"),
      t("Action"),
      {
        name: t("Edit/Delete"),
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <Button color="primary" onClick={() => this.toggleEditDailyAdjust(value)} className={classes.actionIcon}>
                  <Icon>edit</Icon>
                </Button>
                <Button color="secondary" onClick={() => this.toggleDeleteDailyAdjust(value)} className={classes.actionIcon}>
                  <Icon>delete</Icon>
                </Button>
              </React.Fragment>
            );
          }
        }
      }
    ];

    return (
      <div className={classes.root}>
        <Card>
          <CardHeader
            title={t("Daily Adjustment Overview")}
            className={classes.tableHeading}
          >
          </CardHeader>
          <CardContent className={classes.tableContentSkip}>
            <MuiThemeProvider theme={dailySettingsTheme}>
              <div className={classes.dailyTableData}>
                <MUIDataTable
                  data={this.renderDailyAdjustTable(this.state.adjustDates)}
                  columns={DailyAdjustcols}
                  options={DailyAdjustOptions()}
                />
              </div>
            </MuiThemeProvider>
          </CardContent>
        </Card>
        {Modals.DailyAdjustModal(this)}
        {Modals.editDailyAdjustModal(this)}
        {Modals.DeleteDailyAdjustModal(this)}
      </div>
    );
  }
}

DateSetting.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
  return {
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    userSession: state.sessionReducer,
    hotelDetail: state.hotelSettingReducer.hoteDetail,
    currencyList: state.currencyReducer.currencyList,
    hotelAuthReducer: state.hotelAuthReducer,
    skipPriceReducer: state.skipPriceReducer,
    dailyAdjustmentReducer: state.dailyAdjustmentReducer,
    monthlyAdjustReducer: state.monthlyAdjustReducer,
    weeklyAdjustReducer: state.weeklyAdjustReducer,
    smartSettingReducer: state.smartSettingReducer,
    roomList: state.roomListReducer,
    commonReducer: state.commonReducer,
    featuresReducer: state.featuresReducer,
    priceSettingReducer: state.priceSettingReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dailyAdjustCreate: (request) => { dispatch(actions.dailyAdjustCreate(request)) },
    updateDailyAdjust: (request) => { dispatch(actions.updateDailyAdjust(request)) },
    deleteDailyAdjust: (request) => { dispatch(actions.deleteDailyAdjust(request)) },
    updateHotel: (request) => { dispatch(actions.updateHotelSetting(request)) },
    getPricingSettings: (request) => { dispatch(actions.getPricingSettings(request)) },
  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(DateSetting));
