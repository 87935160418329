import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import CardMedia from '@material-ui/core/CardMedia';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { InviteUserTheme } from './muiTheme';
import { connect } from "react-redux";
import { compose } from 'redux'
import * as actions from '../store/actions/index';
import InviteUserCss from './css/inviteUserCss'
import Snackbar from '@material-ui/core/Snackbar';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Grid from '@material-ui/core/Grid';
import BackgroundImg from "./loginBG";
import Fade from '@material-ui/core/Fade';
import Icon from '@material-ui/core/Icon';
import { Typography } from '@material-ui/core';

const styles = InviteUserCss;

class SignIn extends React.Component {

    state = {
        email: '',
        password: '',
        propertyList: [],
        firstName: "",
        lastName: "",
        userType: "",
        error: [],
        showPassword: true,
    }

    componentDidMount() {
        var url_string = window.location.href;
        var url = new URL(url_string);
        var code = url.searchParams.get("email_token");

        let request = {
            code: code
        }
        this.props.getInviteUserDetails(request)
    }

    toggleBookingError = () => {
        this.setState({
            bookingError: false,
            bookingErrorMsg: ""
        })
    }


    componentWillReceiveProps(props) {

        if (props.commonReducer.notify) {
            if (props.commonReducer.notify.message) {
                this.setState({
                    snackbarMsg: props.commonReducer.notify.message,
                    snackbarSuccess: props.commonReducer.notify.is_success,
                    warning: true,
                }, () => {
                    if (props.commonReducer.notify.is_success) {
                        setTimeout(() => {
                            this.setState({
                                warning: false
                            })
                        }, 3000);
                    }
                })
                this.props.cleanNotify()
            }
        }

        if (props.userInviteReducer && props.userInviteReducer.data) {
            this.setState({
                email: props.userInviteReducer.data.email,
                propertyList: props.userInviteReducer.data.property_list,
                userType: props.userInviteReducer.data.user_type
            })
        }

    }

    handleChange = (name) => event => {
        this.setState({
            [name]: event.target.value
        })
    }


    submitUser = () => {

        let error = [];

        if (!this.state.email) {
            error.push("email");
        }
        if (!this.state.password) {
            error.push("password");
        }
        if (!this.state.firstName) {
            error.push("firstName");
        }
        if (!this.state.lastName) {
            error.push("lastName");
        }

        if (error.length == 0) {
            var url_string = window.location.href;
            var url = new URL(url_string);
            var code = url.searchParams.get("email_token");

            let request = {
                email: this.state.email,
                password: this.state.password,
                first_name: this.state.firstName,
                last_name: this.state.lastName
            }
            this.props.createInviteUser(request, code);
        }

        this.setState({
            error
        })
    }

    
  toggleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

    render() {
        const { classes } = this.props;
        let propertyList = this.state.propertyList;
        let propertyLists=[]
        let propertyListsMore=[]
        
        let properties = '';
        Object.keys(propertyList).map((k,i)=>{
            if(i <= 1){
                propertyLists.push(propertyList[k])
            }
            propertyListsMore.push(propertyList[k])
        }).join(",");

        if(propertyListsMore){
            if(propertyListsMore.length > 2){
                properties = propertyLists.toString().replace(/,/g, ', ') + ' & ' + (propertyListsMore.length - 2 ) + ' more.';
            }else{
                properties = propertyLists.toString().replace(/,/g, ', ');
            }
        }

        return (
            <MuiThemeProvider theme={InviteUserTheme}>
                <Grid container className={classes.pageContainer} style={{height:'100vh'}}>
                    <Grid  lg={6} sm={12} className={classes.loginLogoCard}>
                        <CardMedia
                            className={classes.siteLogo1}
                            image="/img/roompricegenie_logo_new.svg"
                            title="RoomPriceGenie"
                        />
                    </Grid>
              <Grid  lg={6} sm={12} className={classes.loginFormCard}>
                {/* <Grid sm={12} md={12}> */}
                <div className={classes.cardFormDiv}>
                    <main >

                        <Grid container>
                            <Grid sm={12} >
                            <Typography variant='h6' style={{padding: "6px 42px", fontWeight:"500" }} align={"left"}>Hello</Typography>
                                <Typography variant='subtitle1' align='left' style={{ padding: "6px 42px", fontWeight:"400" }}>You got invited to join RoomPriceGenie with access to the following properties: {properties}</Typography>
                                {!this.state.isReset &&
                                    <form className={classes.form} style={{ padding: "0px 42px 16px" }}>
                                        {/* <div className={classes.twoFields}> */}
                                            <FormControl margin="normal" required fullWidth >
                                                <InputLabel shrink={true} htmlFor="password">First Name</InputLabel>
                                                <Input error={this.state.error.includes("firstName")} value={this.state.firstName} name="firstName" onChange={this.handleChange('firstName')} id="password" autoComplete="current-password" />
                                                {/* {this.state.error.includes("firstName") &&
                                                    <span class='error-message'>{("* Please enter First Name")}</span>
                                                } */}
                                            </FormControl>
                                            <FormControl margin="normal" required fullWidth >
                                                <InputLabel shrink={true} htmlFor="password">Last Name</InputLabel>
                                                <Input error={this.state.error.includes("lastName")} value={this.state.lastName} name="lastName" onChange={this.handleChange('lastName')} id="password" autoComplete="current-password" />
                                                {/* {this.state.error.includes("lastName") &&
                                                    <span class='error-message'>{("* Please enter Last Name")}</span>
                                                } */}
                                            </FormControl>
                                        {/* </div> */}
                                        {/* <div className={classes.twoFields}> */}
                                            <FormControl margin="normal" required fullWidth >
                                                <InputLabel shrink={true} htmlFor="email">Email Address</InputLabel>
                                                <Input disabled error={this.state.error.includes("email")} value={this.state.email} id="email" name="email" onChange={this.handleChange('email')} autoComplete="email" autoFocus />
                                                {/* {this.state.error.includes("email") &&
                                                    <span class='error-message'>{("* Please enter Email")}</span>
                                                } */}
                                            </FormControl>

                                            <FormControl margin="normal" required fullWidth >
                                                <InputLabel shrink={true} htmlFor="password" >Password</InputLabel>
                                                <Input endAdornment={this.state.showPassword ? <VisibilityOff onClick={this.toggleShowPassword} style={{color:"#8b8b8b"}} /> : <Visibility onClick={this.toggleShowPassword} style={{color:"#8b8b8b"}} />} type={(this.state.showPassword) ? 'password' : 'text'} error={this.state.error.includes("password")} value={this.state.password} name="password" onChange={this.handleChange('password')} id="password" autoComplete="current-password" />
                                                {/* {this.state.error.includes("password") &&
                                                    <span class='error-message'>{("* Please enter Password")}</span>
                                                } */}
                                            </FormControl>
                                        {/* </div> */}
                                        <Button
                                            type="button"
                                            disabled={this.props.loading || this.state.bookingLoad}
                                            variant="contained"
                                            color="primary"
                                            className={classes.LoginBtn}
                                            onClick={() => this.submitUser()}
                                        >
                                            Save And Go To Login
                                        </Button>
                                        <Button
                                            className={["cancelBtn"]}
                                            variant="outlined"
                                            type="button"
                                            color="primary"
                                            href='/'
                                            style={{float:"left",marginLeft:'10px',marginTop:'16px', width:"176px"}}
                                            >
                                            Go Back to Login
                                        </Button>
                                    </form>
                                }
                            </Grid>
                        </Grid>
                    </main>
               
                {/* <img src={"/img/skyline.png"} height="100%" className={classes.skyline} /> */}
                {this.state.warning &&
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        onClose={this.toggleWarning}
                        open={this.state.warning}
                        TransitionComponent={Fade}
                        ContentProps={{
                            classes: {
                                root: this.state.snackbarSuccess ? classes.sccessBar : classes.warningBar
                            }
                        }}
                        message={<span style={{ textTransform: 'capitalize' }}><Icon className={classes.warningIcon}>{(this.state.snackbarSuccess) ? 'check_circle' : 'error'}</Icon> {(this.state.snackbarMsg)}</span>}
                        variant="success"
                    />
                }
            </div></Grid></Grid>
            </MuiThemeProvider >
        );
    }
}

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    container: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        user: state.authReducer.UserData,
        accountReducer: state.accountReducer,
        userInviteReducer: state.UserIviteReducer,
        commonReducer: state.commonReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getInviteUserDetails: (request) => { dispatch(actions.getInviteUserDetails(request)) },
        createInviteUser: (request, code) => { dispatch(actions.createInviteUser(request, code)) },
        cleanNotify: () => { dispatch(actions.cleanNotify()) },
    };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(SignIn);