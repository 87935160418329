const pmsDataArray = [
  { label: 'Apaleo', value: 1 },
  { label: 'Mews', value: 2 },
  { label: 'Booking Factory', value: 3 },
  { label: 'Little Hotelier', value: 4 },
  { label: 'SiteMinder', value: 5 },
  { label: 'Clock System', value: 7 },
  { label: 'Beds24(old)', value: 8 },
  { label: 'Hotel Spider', value: 10 },
  { label: 'Cover', value: 14 },
  { label: 'Protel', value: 15 },
  // { label: 'SMX', value: 16 },
  { label: 'Booking Automation', value: 17 },
  { label: 'Channex', value: 20 },
  { label: 'Globeres', value: 21 },
  { label: 'Guestline', value: 22 },
  { label: 'Bocco', value: 23 },
  { label: 'seekom', value: 24 },
  { label: 'Beds24(new)', value: 25 },
  { label: 'Booking Automation Partner', value: 26 },
  { label: 'RMS', value: 27 },
  { label: 'Web Hotelier', value: 28 },
  { label: 'kognitiv', value: 29 },
  { label: 'Channex Wubook', value: 30 },
  { label: '5stelle', value: 33 },
  { label: 'Vertical Booking', value: 34 },
  { label: 'cultswitch', value: 35 },
  { label: 'SiteMinder RMS API', value: 37 },
  { label: 'Semper', value: 38 },
  { label: 'MappingMaster', value: 39 },
  { label: 'Ibelsa', value: 40 },
  { label: 'RoomRaccoon', value: 41 },
  { label: 'Guesty', value: 42 },
  { label: 'Booking.com', value: 43 },
  { label: 'Reconline', value: 44 },
  { label: 'D-edge', value: 45 },
  { label: 'eviivo', value: 46 },
  { label: 'Ghix', value: 47 },
  { label: 'OneHotel', value: 48 },
  { label: 'HLS', value: 49 },
  { label: 'Cubilis', value: 50 },
  { label: 'Roomcloud', value: 51 },
  { label: 'Freetobook', value: 52 },
  { label: 'Profitroom', value: 53 },
  { label: 'Zimmersoftware', value: 54 },
  { label: 'HotelConnect', value: 57 },
  { label: 'Dirs21', value: 58 },
  { label: 'Stayntouch', value: 60 },
  { label: 'OperaCloud', value: 61 },
  { label: 'Hotelavailabilities', value: 62 },
  { label: 'RateTiger', value: 63 },
  { label: 'SiteMinder New', value: 64 },
  { label: 'SiteMinder RMS API New', value: 65 },
  { label: 'Thais', value: 66 },
  { label: 'Update247', value: 67 },
  { label: 'Resly', value: 68 },
  { label: 'Wincloud', value: 69 },  
  { label: 'Sabee', value: 71 },
  // { label: 'Amenitiz', value: 56 },
  { label: 'Cloudbeds', value: 70 },
];

export default pmsDataArray