import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import yieldingTagsCss from './css/yieldingTagsCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../../store/actions/index';
import CustomTooltip from '../../Utils/toolTip';
import Server from '../../../config/server';
import axios from 'axios';
import { Icon } from '@material-ui/core';
import Modals from './modal/modal';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { yeldTagTheme } from '../../muiTheme';
import MUIDataTable from "mui-datatables";
import classnames from 'classnames';
import Warning from '../warning';
import DialogContentText from '@material-ui/core/DialogContentText';
import HeaderCard from "../HeaderCard";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';

const styles = yieldingTagsCss;

class YieldingTags extends React.Component {

    state = {
        colorArray: ["#FFA500", "#32CD32", "#FF69B4", "#00BFFF", "#0000CD", "#006400"],
        YieldingTagsList: [],
        editModalData: {
            id: '',
            tag_name: '',
            room_types: [],
            min_adjustment: '',
            max_adjustment: '',
            speed: '',
            selected_room_types: [],
            error: [],
            color: ""
        },
        openEditTag: false,
        openDeleteTag: false,
        openCreateTag: false,
        warning: false,
        warningMsg: [],
        warningMethod: null,
        warnMsg: false,
        room_apartment_space_name: ""
    };

    componentWillMount() {
        let requestParam = {
          token: this.props.hotelAuthReducer.hotelDetails.token,
        };
        this.props.getHotelDetail(requestParam);
        this.setState({
          room_apartment_space_name: this.props.hotelAuthReducer.hotelDetails.hotel.room_apartment_space_name
        });
    }

    componentDidMount() {
        this.toggleWarningModal()
    }

    componentWillReceiveProps(props) {
        if (props.priceSettingReducer.priceSetting && props.priceSettingReducer.priceSetting.room_type_tags) {
            this.setState({
                YieldingTagsList: props.priceSettingReducer.priceSetting.room_type_tags
            })
            if (!this.state.warnMsg && props.priceSettingReducer.priceSetting.room_type_tags && props.roomList.roomList) {
                this.setState({
                    warnMsg: true
                }, () => this.toggleWarningModal("warnMsg"))
            }
        }
    }


    toggleWarningModal = (method) => {
        let warningMsg = [];
        let warning = false;
        let hideCancel = false;
        const { t } = this.props;

        if (method == "warnMsg" && this.props.priceSettingReducer.priceSetting && this.props.priceSettingReducer.priceSetting.room_type_tags && this.props.roomList.roomList) {
            let data = this.props.priceSettingReducer.priceSetting.room_type_tags;
            let room_types = [];
            Object.values(data).map((tag, index) => {
                tag.id = Object.keys(data)[index];
                tag.room_types.map(val => room_types.push(val))
            })
            this.props.roomList.roomList.map(opt => {
                if (!room_types.includes(opt.id)) {
                    warning = true;
                    hideCancel = true;
                    warningMsg.push(t('For best results all '+ this.state.room_apartment_space_name +'s should have a tag. Please make sure to set up another tag with the remainder of '+ this.state.room_apartment_space_name+'s.'))
                }
            })

        }

        if (method == "updateYieldingTags") {
            let data = this.state.editModalData;
            let error = [];

            if (!data.tag_name) {
                error.push('tag_name');
            }

            if (data.selected_room_types.length === 0) {
                error.push('room_types');
            }

            if (!data.min_adjustment) {
                error.push('min_adjustment');
            }

            if (!data.max_adjustment) {
                error.push('max_adjustment');
            } else if (data.min_adjustment && data.min_adjustment > data.max_adjustment) {
                error.push('max_min_adjustment');
            }

            if (data.min_adjustment) {
                if (data.min_adjustment > 0) {
                    error.push('min_zero');
                }
            }

            if (data.max_adjustment) {
                if (data.max_adjustment < 0) {
                    error.push('max_zero');
                }
            }


            if (!data.speed) {
                error.push('speed');
            }

            if (!data.color) {
                error.push('color');
            }

            if (error.length > 0) {
                this.setState({
                    editModalData: {
                        ...this.state.editModalData,
                        error: error
                    }
                });
            } else {

                if (data.min_adjustment == 0 && data.max_adjustment == 0) {
                    warning = true;
                    warningMsg.push(t("You have set minimum and maximum to zero. This is an unusual use of this setting. If you'd like help, please message us."))
                } else if (data.min_adjustment == 0 && data.max_adjustment != 0) {
                    warning = true;
                    warningMsg.push(t("You have set minimum adjustment to zero. This is an unusual use of this setting. If you'd like help, please message us."))
                } else if (data.min_adjustment != 0 && data.max_adjustment == 0) {
                    warning = true;
                    warningMsg.push(t("You have set maximum adjustment to zero. This is an unusual use of this setting. If you'd like help, please message us."))
                }

                if (Math.abs(data.min_adjustment) != Math.abs(data.max_adjustment)) {
                    warning = true;
                    warningMsg.push(t("Normally, min and max adjustments are symmetrical so they can go up and down by the same amount."))
                }

                if (Math.abs(data.min_adjustment) > 25 || Math.abs(data.max_adjustment) > 25) {
                    warning = true;
                    warningMsg.push(t("These min and max adjustments are very high potential room adjustments. We would not recommend so large adjustments in normal circumstances. Consider using the Bulk Derivation feature to get the derivation better as a baseline."))
                }

                if (!warning) {
                    this.updateYieldingTags()
                }
            }

        }


       

        this.setState({
            warning: warning,
            warningMethod: method,
            warningMsg: warningMsg,
            hideCancel: hideCancel
        })
    }

    closeWarningModal = (method) => {
        this.setState({
            warning: false,
        })
    }

    warningConfirm = () => {

        if (this.state.warningMethod == "updateYieldingTags") {
            this.updateYieldingTags()
        }
        this.setState({
            warning: false,
        })
    }


    openEditYieldingTagModal = (tagData) => {
        this.setState({
            editModalData: {
                id: tagData.id,
                tag_name: tagData.tag_name,
                room_types: tagData.room_types,
                min_adjustment: tagData.min_adjustment,
                max_adjustment: tagData.max_adjustment,
                speed: tagData.speed,
                selected_room_types: tagData.room_types,
                color: tagData.color,
                error: []
            },
            openEditTag: true
        })
    }

    closeEditYieldingTagModal = () => {
        this.setState({
            openEditTag: false,
        })
    }

    handleEditYieldingTag = (name) => event => {
        this.setState({
            editModalData: {
                ...this.state.editModalData,
                [name]: event.target.value,
            }
        });
    }

    validateTagForm = (data, action) => {

        let error = [];

        if (data) {

            if (!data.tag_name) {
                error.push('tag_name');
            }

            if (data.selected_room_types.length === 0) {
                error.push('room_types');
            }

            if (!data.min_adjustment) {
                error.push('min_adjustment');
            }

            if (!data.max_adjustment) {
                error.push('max_adjustment');
            } else if (data.min_adjustment && data.min_adjustment > data.max_adjustment) {
                error.push('max_min_adjustment');
            }

            if (data.min_adjustment) {
                if (data.min_adjustment > 0) {
                    error.push('min_zero');
                } else if (data.min_adjustment < -20) {
                    error.push('more_than_min');
                }
            }

            if (data.max_adjustment) {
                if (data.max_adjustment < 0) {
                    error.push('max_zero');
                } else if (data.max_adjustment > 20) {
                    error.push('more_than_max');
                }
            }


            if (!data.speed) {
                error.push('speed');
            }

            if (!data.color) {
                error.push('color');
            }

            if (error) {
                if (action === 'edit') {
                    this.setState({
                        editModalData: {
                            ...this.state.editModalData,
                            error: error
                        }
                    });
                } else {
                    this.setState({
                        CreateModalData: {
                            ...this.state.CreateModalData,
                            error: error
                        }
                    });
                }
                return error
            } else {
                return false
            }
        } else {
            return true;
        }
    }

    updateYieldingTags = () => {
        let validateError = this.validateTagForm(this.state.editModalData, 'edit');

        if (validateError.length > 0) {
            return;
        }

        let tagsPricingSettings = this.props.priceSettingReducer.priceSetting;
        for (let [key, value] of Object.entries(this.props.priceSettingReducer.priceSetting.room_type_tags)) {
            if (key === this.state.editModalData.id) {
                tagsPricingSettings.room_type_tags[key] = {
                    tag_name: this.state.editModalData.tag_name,
                    room_types: this.state.editModalData.selected_room_types,
                    min_adjustment: this.state.editModalData.min_adjustment,
                    max_adjustment: this.state.editModalData.max_adjustment,
                    speed: this.state.editModalData.speed,
                    color: this.state.editModalData.color,
                }
            }
        }

        // let flagReq = {
        //     run_pricing: true,
        //     token: this.props.hotelAuthReducer.hotelDetails.token,
        // }
        // this.props.setRunPriceFlags(flagReq);

        let requestData = {
            token: this.props.hotelAuthReducer.hotelDetails.token,
            dateSetting: tagsPricingSettings,
        };
        this.props.updatePricingSettings(requestData)
        this.setState({
            openEditTag: false
        })
    }

    openDeleteYieldingTagModal = (tagData) => {
        this.setState({
            deleteModalData: {
                id: tagData.id,
                tag_name: tagData.tag_name,
                room_types: tagData.room_types,
                min_adjustment: tagData.min_adjustment,
                max_adjustment: tagData.max_adjustment,
                speed: tagData.speed,
                color: tagData.color,
                selected_room_types: tagData.room_types
            },
            openDeleteTag: true
        }, () => {
            this.setState({
                openEditTag: false
            })
        })
    }

    closeDeleteYieldingTagModal = () => {
        this.setState({
            openDeleteTag: false,
        })
    }

    deleteYieldingTag = () => {
        let tagsPricingSettings = this.props.priceSettingReducer.priceSetting;

        for (let [key, value] of Object.entries(this.props.priceSettingReducer.priceSetting.room_type_tags)) {
            if (key === this.state.deleteModalData.id) {
                delete tagsPricingSettings.room_type_tags[key];
            }
        }

        let requestData = {
            token: this.props.hotelAuthReducer.hotelDetails.token,
            dateSetting: tagsPricingSettings,
        };

        // let flagReq = {
        //     run_pricing: true,
        //     token: this.props.hotelAuthReducer.hotelDetails.token,
        // }
        // this.props.setRunPriceFlags(flagReq);
        

        this.props.updatePricingSettings(requestData)
        this.setState({
            openDeleteTag: false
        })
    }

    openCreateYieldingTagModal = () => {
        this.setState({
            CreateModalData: {
                id: '',
                tag_name: '',
                room_types: [],
                min_adjustment: -10,
                max_adjustment: 10,
                speed: "",
                color: "",
                selected_room_types: [],
                error: []
            },
            openCreateTag: true
        })
    }

    closeCreateYieldingTagModal = () => {
        this.setState({
            CreateModalData: {
                id: '',
                tag_name: '',
                room_types: [],
                min_adjustment: '',
                max_adjustment: '',
                speed: '',
                color: "",
                selected_room_types: [],
                error: []
            },
            openCreateTag: false
        })
    }

    handleCreateYieldingTag = (name) => event => {
        this.setState({
            CreateModalData: {
                ...this.state.CreateModalData,
                [name]: event.target.value,
            }
        });
    }

    createYieldingTags = () => {

        let validateError = this.validateTagForm(this.state.CreateModalData, 'create');

        if (validateError.length > 0) {
            return;
        }

        let tagsPricingSettings = this.props.priceSettingReducer.priceSetting;
        if (!tagsPricingSettings.room_type_tags) {
            tagsPricingSettings.room_type_tags = {};
        }
        tagsPricingSettings.room_type_tags[moment().format('YYYYMMDDHHmmss') + this.props.hotelAuthReducer.hotelDetails.hotel.id] = {
            tag_name: (this.state.CreateModalData.tag_name),
            room_types: (this.state.CreateModalData.selected_room_types),
            min_adjustment: (this.state.CreateModalData.min_adjustment),
            max_adjustment: (this.state.CreateModalData.max_adjustment),
            speed: (this.state.CreateModalData.speed),
            color: this.state.CreateModalData.color,
        }

        // let flagReq = {
        //     run_pricing: true,
        //     token: this.props.hotelAuthReducer.hotelDetails.token,
        // }
        // this.props.setRunPriceFlags(flagReq);
        

        let requestData = {
            token: this.props.hotelAuthReducer.hotelDetails.token,
            dateSetting: tagsPricingSettings,
        };

        this.props.updatePricingSettings(requestData)
        this.setState({
            openCreateTag: false
        })
    }

    composeTableData = (tableData) => {
        if (Object.keys(tableData).length > 0 && this.props.roomList.roomList) {
            let returnData = [];
            Object.values(tableData).map((tag, index) => {
                tag.id = Object.keys(tableData)[index];
                let room_types = [];
                this.props.roomList.roomList.map(opt => {
                    if (tag.room_types.includes(opt.id)) {
                        room_types.push(<>{opt.name}<br /></>);
                    }
                })

                let speed = "";
                if (tag.speed == 0.5) {
                    speed = "Low"
                } else if (tag.speed == 1) {
                    speed = "Medium"
                } else if (tag.speed == 1.5) {
                    speed = "High"
                } else if (tag.speed == 2) {
                    speed = "Super High"
                }

                let YieldingTag = [
                    tag,
                    tag.tag_name,
                    room_types,
                    tag.color ? tag.color : "",
                    (tag.min_adjustment > 0 ? "+" : "") + tag.min_adjustment + '%',
                    (tag.max_adjustment > 0 ? "+" : "") + tag.max_adjustment + '%',
                    speed,
                    tag
                ];
                returnData.push(YieldingTag)
                return null;
            })
            return returnData;
        } else {
            return [];
        }
    }




    render() {
        const { classes, t } = this.props;
        const columns = [
            {
                name: t("id"),
                options: {
                    display: false
                }
            },
            {
                name: t("Tag Name"),
                options: {
                    filter: false,
                    sortDirection: 'asc',
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <div className={classes.tableValue} >{value}</div>
                    }
                }
            },
            {
                name: t(`${this.state.room_apartment_space_name} Type`),
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <div className={classes.tableValue}>{value}</div>
                    }
                }
            },
            {
                name: t("Colour"),
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const { classes } = this.props;
                        return <React.Fragment><div className={classes.colorField} style={{ background: value }}></div></React.Fragment>
                    }
                }
            },
            {
                name: t("Min Adjustment"),
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <div className={classes.tableValue2}>{value}</div>
                    }
                }
            },
            {
                name: t("Max Adjustment"),
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <div className={classes.tableValue2} >{value}</div>
                    }
                }
            },
            {
                name: t("Aggressiveness"),
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <div className={classes.tableValue}>{value}</div>
                    }
                }
            },
            {
                name: t("Actions"),
                options: {
                    filter: false,
                    download: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const { classes } = this.props;
                        return <React.Fragment>
                            <Button color="secondary" onClick={() => this.openDeleteYieldingTagModal(value)} className={classes.actionIcon}><Icon>delete</Icon></Button>
                        </React.Fragment>
                    }
                }
            }
        ];
        const options = {
            responsive: 'scroll',
            selectableRows: false,
            search: false,
            print: false,
            download: false,
            viewColumns: false,
            filter: false,
            rowsPerPage: 10,
            onRowClick: (rowData, e) => {
                this.openEditYieldingTagModal(rowData[0])
            },
            setRowProps: (row) => {
                return {
                    className: classnames(
                        {
                            [this.props.classes.refRommRow]: row[1] === "Reference YieldingTag"
                        })
                };
            },
            textLabels: {
                body: {
                    noMatch: this.state.loadData ?
                        'Loading Records...' :
                        'Sorry, no matching records found.',
                },
            }
        }

        return (
            <>
            <HeaderCard>
                <Grid container>
                    <Grid item >
                        <div className={classes.divStyle} >
                        <Typography variant="h5" className={classes.fontStyle} >
                          {t("Yielding Tags")}
                          <CustomTooltip title="" description={t("Yielding Tags is an advanced feature, that enables you to set up different room groups separately. For example, your Doubles may sell very differently from your Singles as they are more used for leisure rather than business. By setting up different groups, we can shift prices around relative to each other. So, if your doubles are selling faster, they will go up in price and your singles will reduce. Please see more at the Help Centre.")} />
                        </Typography>
                        <DialogContentText align="left" className={classes.helpText} >
                            {t("Yielding Tags is an advanced feature, that enables you to set up different room groups separately. For example, your Doubles may sell very differently from your Singles as they are more used for leisure rather than business. By setting up different groups, we can shift prices around relative to each other. So, if your doubles are selling faster, they will go up in price and your singles will reduce. Please see more at the Help Centre.")}
                        </DialogContentText>
                        </div>
                    </Grid>
                </Grid>
            </HeaderCard>
            <Card>
                <CardContent>
                    <div className={classes.addBtnDiv}>
                        <div className={classes.addNewDiv}>
                            <Button  variant="contained" color="primary" onClick={this.openCreateYieldingTagModal}>{t("Add new Yielding Tag")}</Button>
                        </div>
                    </div>
                    <div className={classes.userTableDiv}> 
                    <MuiThemeProvider theme={yeldTagTheme}>                
                            <MUIDataTable
                                title=''
                                data={this.composeTableData(this.state.YieldingTagsList)}
                                columns={columns}
                                options={options}
                            />
                            </MuiThemeProvider>
                    </div>                    
                </CardContent>
                {this.state.openEditTag && Modals.editYieldingTagModal(this)}
                {this.state.openDeleteTag && Modals.DeleteYieldingTagModal(this)}
                {this.state.openCreateTag && Modals.CreateYieldingTagModal(this)}
                {Warning.warningModal(this)}
            </Card >
            </>
        );
    }
}

YieldingTags.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        userSession: state.sessionReducer,
        hotelAuthReducer: state.hotelAuthReducer,
        hotelDetailReducer: state.hotelSettingReducer,
        commonReducer: state.commonReducer,
        priceSettingReducer: state.priceSettingReducer,
        roomList: state.roomListReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getHotelDetail: (request) => { dispatch(actions.getHotelDetail(request)) },
        getPricingSettings: (request) => { dispatch(actions.getPricingSettings(request)) },
        updatePricingSettings: (request) => { dispatch(actions.updatePricingSettings(request, "yelding")) },
        putNotify: (request) => { dispatch(actions.putNotify(request)) },
        // setRunPriceFlags: (request) => { dispatch(actions.setRunPriceFlags(request)) },
    };
};

export default compose(withStyles(styles, {
    withTheme: true
}), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(YieldingTags));