import React from "react";
import OTASetupCss from "./css/OTASetupCss";
import * as actions from "../../store/actions/index";
import { compose } from "redux";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AsyncCreatableSelect from "react-select/lib/AsyncCreatable";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Server from "../../config/server";
import axios from "axios";
import * as actionTypes from "../../store/actions/actionTypes";
import TextField from "@material-ui/core/TextField";
import Notify from "../../store/actions/Notify";
import Warning from './warning';
import DialogContentText from '@material-ui/core/DialogContentText';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import HeaderCard from "./HeaderCard";

const styles = OTASetupCss;

class OTASetup extends React.Component {
  state = {
    competitorData: [],
    roomType: [
      "dormitory",
      "sharedbathroom",
      "suite",
      "family",
      "apartment",
      "parkandfly",
      "camper",
      "villa",
      "bunglow",
      "budget",
      "premium",
      "standard",
      "single",
    ],
    mealType: [
      { key: 0, value: "Filter not applied" },
      { key: 1, value: "Breakfast included" },
      { key: 2, value: "Half board included" },
      { key: 3, value: "Full board included" },
      { key: 4, value: "All inclusive" },
      { key: 5, value: "Room Only" },
    ],
    hotelData: [
      {
        index: 0,
        id: " ",
        name: " ",
      },
      {
        index: 1,
        id: " ",
        name: " ",
      },
      {
        index: 2,
        id: " ",
        name: " ",
      },
      {
        index: 3,
        id: " ",
        name: " ",
      },
      {
        index: 4,
        id: " ",
        name: " ",
      },
      {
        index: 5,
        id: " ",
        name: " ",
      },
      {
        index: 6,
        id: " ",
        name: " ",
      },
      {
        index: 7,
        id: " ",
        name: " ",
      },
      {
        index: 8,
        id: " ",
        name: " ",
      },
      {
        index: 9,
        id: " ",
        name: " ",
      },
    ],
    maxOccupancy: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    selectedroomTypes: [ "premium","standard","single"],
    selectedMaxOccupancy: [1,2],
    selectedMealType: [0],
    selectedCompetitors: [],
    subscription_id: null,
    error: [],
    competitorsId: [],
    hotelList: [],
    OTACompetitorCsv:[], 
    warning: false,
    warningMsg: [],
    warningMethod: null,
    warnMsg: false,
    meal_type:null,
  };
  componentWillMount() {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      url:
        "hotel/list/?country=" +
        this.props.hotelAuthReducer.hotelDetails.hotel.country.id,
      is_from_ota_setup:true,
    };
    this.props.selectedCompetitorsCsv(requestParam)
    this.props.getCompetitor(requestParam);
    this.props.onGetOtaCompetitor(requestParam);
    this.props.getHotelList(requestParam);
  }
  componentWillReceiveProps(props) {
    if(props.OTASetupReducer){
      if (props.OTASetupReducer.OTACompetitorList && props.OTASetupReducer.OTACompetitorList != this.props.OTASetupReducer.OTACompetitorList) {
        if( props.OTASetupReducer.OTACompetitorList.room_type_selections){
        let selectedMaxOccupancy = [];
          props.OTASetupReducer.OTACompetitorList.room_type_selections.max_occupancy.map(
            (row) => {
                selectedMaxOccupancy.push(row);
            }
          );
          this.setState({
            selectedMaxOccupancy: selectedMaxOccupancy,
          });
        
        let selectedMealTypes = [];
        props.OTASetupReducer.OTACompetitorList.room_type_selections.meal_type.map((row)=>{
          selectedMealTypes.push(row)
        })
        this.setState({
          selectedMealType:selectedMealTypes
        })

        let selectedroomTypes = [];
        props.OTASetupReducer.OTACompetitorList.room_type_selections.room_type.map((row)=>{
          selectedroomTypes.push(row)
        })
        this.setState({
          selectedroomTypes:selectedroomTypes
        })
          props.OTASetupReducer.OTACompetitorList.competitors_data.map(
            (row, index) => {
              const { hotelData } = this.state;
              hotelData[index].id = row.competitor_id;
              hotelData[index].name = row.competitor__name;
              this.setState({
                hotelData,
              });
            }
          );

        if (!this.state.subscription_id) {
          this.setState({
            subscription_id:
              props.OTASetupReducer.OTACompetitorList.ota_subscription_id,
          });
        }
      }
    }
  }
    if (
      props.competitorReducer.competitorList &&
      this.state.competitorData != props.competitorReducer.competitorList
    ) {
      this.setState({
        competitorData: props.competitorReducer.competitorList,
      });
    }
    if(props.OTAInsightsReducer.OTACompetitorCSV){
      this.setState({
        OTACompetitorCsv:props.OTAInsightsReducer.OTACompetitorCSV
      })
    }
  }
  handleHotelChange = (selectedHotel, selection, index) => {
    if (selection.action == "clear") {
      const { hotelData } = this.state;
      hotelData[index].id = " ";
      hotelData[index].name = " ";
      if (hotelData[index].selected) {
        delete hotelData[index].selected;
      }
      this.setState(
        {
          hotelData,
        });
    } else {
      const { hotelData } = this.state;
      hotelData[index].id = selectedHotel.competitor_data.id;
      hotelData[index].name = selectedHotel.competitor_data.name;
      hotelData[index].selected = {
        label: selectedHotel.competitor_data.name,
        value: selectedHotel.competitor_data.id,
      };
      this.setState({hotelData,});
    }
  };

  toggleRoomType = (data, e) => {
    let selectedroomTypes = this.state.selectedroomTypes;

    if (e.target.checked) {
      selectedroomTypes.push(data);
    } else {
      selectedroomTypes = selectedroomTypes.filter((val) => val !== data);
    }
    this.setState({
      selectedroomTypes: selectedroomTypes,
    });
  };

  toggleMealType = (data, e) => {
    let selectedMealType = [];
    if (e.target.checked) {
      selectedMealType.push(data);
    }
    
    this.setState({
      selectedMealType: selectedMealType,
    });
  };

  toggleMaxOccupancy = (data, e) => {
    let selectedMaxOccupancy = this.state.selectedMaxOccupancy;
    if (e.target.checked) {
      selectedMaxOccupancy.push(data);
    } else {
      selectedMaxOccupancy = selectedMaxOccupancy.filter((val) => val !== data);
    }
    this.setState({
      selectedMaxOccupancy: selectedMaxOccupancy,
    });
  };
  hotelOptions = (inputValue) =>
    new Promise((resolve) => {
      if (inputValue.length >= 5) {
        axios.defaults.headers.common["Authorization"] =
          "Token " + this.props.hotelAuthReducer.hotelDetails.token;
        axios
          .get(
            Server.API +
              "hotel/list/find-competitor/?search=" + inputValue 
             
          )
          .then((response) => {
            this.props.setHotelList(response.data);

            let jsx = [];
            response.data.results.map((val) => {
              jsx.push({
                label: val.name,
                value: val.id,
                competitor_data: val,
              });
              return null;
            });
            let data = jsx;

            resolve(data);
          })
          .catch((error) => {});
      }
    });
  toggaleSubscriptionId = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };
  validate = () => {
    let error = [];
    if (this.state.selectedroomTypes.length==0 ||this.state.selectedMealType.length==0 || this.state.selectedMealTypes==0) {
      error.push("room_type")
    }
    if (error) {
      this.setState({
        error: error,
      });
      return error;
    } else {
      return false;
    }
  };
  toggleWarningModal = (method) => {
      let warningMsg = [];
      let warning = false;
      let hideCancel = false;
      warning = true;
      hideCancel = true;
      warningMsg.push("Changes in the Room Type Selection will be applied in the next OTA update (once a day). Changes in the Competitor Selection can only be activated by the RoomPriceGenie Team.")
      this.setState({
        warning: warning,
        warningMethod: method,
        warningMsg: warningMsg,
        hideCancel: hideCancel
    })
  }
  closeWarningModal = (method) => {
    this.setState({
        warning: false,

    })
    this.submitOtaSetup()
  }

  warningConfirm = () => {
    this.setState({
        warning: false,
    })
    this.submitOtaSetup()
  } 
  submitOtaSetup = () => {
    let validateError = this.validate();
    if (validateError.length > 0) {
      return;
    }
   
    
    let selectedCompetitorsList = this.state.hotelData;
    let competitorsId = [];
    selectedCompetitorsList.map((row) => {
      if (row.id && row.id != " ") {
        competitorsId.push(row.id);
      }
    });
    this.setState({
      competitorsId: competitorsId,
    });
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      otaCompetitorsSetup: {
        ota_subscription_id: this.state.subscription_id,
        ota_competitors: competitorsId,
        ota_room_types: this.state.selectedroomTypes,
        ota_meal_type: this.state.selectedMealType,
        ota_max_occupancy: this.state.selectedMaxOccupancy,
      },
    };

    this.props.storeOtaCompetitorSetup(requestParam);
  };
  renderCompetitorSelection = () => {
    let rows = [];
    const { classes } = this.props;
    const { t } = this.props;
    if (this.state.competitorData) {
      rows = this.state.hotelData;
    }
    return (
      <>
      <Typography className={classes.subtitle} variant="h6" >{t("Competitor Selection")}</Typography> 
        <DialogContentText align="left" className={[classes.helpText,classes.noteText]}>
          Please note that OTA Competitors can only be changed by the RoomPriceGenie team. Please select your competitive set below and contact us.
        </DialogContentText>
        <Table className={classes.userTable}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellRoot}>
                {"Hotel"}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((row, index) => {
                if (row.id) {
                  row.selected = { label: row.name, value: row.id };
                }

                return (
                  <TableRow key={index}>
                    <TableCell className={classes.tableCellRoot}>
                      <div>
                        <AsyncCreatableSelect
                          onChange={(selHotel, selection) =>
                            this.handleHotelChange(selHotel, selection, index)
                          }
                          value={row.selected}
                          loadOptions={(input) => this.hotelOptions(input)}
                          className={classes.pullLeft}
                          formatCreateLabel={() => false}
                          isClearable
                          menuPosition="fixed"
                          menuPlacement={index > 5 ? "top" : "bottom"}
                          maxMenuHeight="200"
                          name={row.selected}
                        />
                      </div>
                      <div className={classes.addressDiv}>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </>
    );
  };
  renderRoomTypeSelection = () => {
    const { classes } = this.props;
    const { t } = this.props;
    return (
      <>
      {(this.state.error.includes("room_type")|| this.state.error.includes("max_occupancy") || this.state.error.includes('meal_type') )&& (
          <p class="error-message" className={classes.msgError} >
            {"* Sorry, we can't save your selection. For Room Type, Meal Type and Max Occupany at least one option has to be selected."}
          </p>
      )}
      <Typography className={classes.subtitle} variant="h6" >{t("Room Type Selection")}</Typography> 

        <FormControl
          component="fieldset"
          className={[classes.formControl,classes.formControl2]}
        >
          <FormLabel className={classes.subtitle1}>Room Type</FormLabel>
          <FormGroup className={classes.fromGroup}>
            {this.state.roomType.map((row, index) => {
              return (
                <>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={this.state.selectedroomTypes.includes(row) ? true :false}
                      className={classes.CheckboxLabel}
                      name={row}
                      onChange={(e) => this.toggleRoomType(row, e)}
                      error={this.state.error.includes("room_type")}
                    />
                  }
                  label={row}
                />
                
                </>
              );
            })}
          </FormGroup>
        </FormControl>
        <FormControl
          component="fieldset"
          className={classes.formControl,classes.formControl2}
        >
          <FormLabel className={classes.subtitle1}>Meal Type</FormLabel>
          <RadioGroup className={classes.fromGroup} name="meal_type"   value={this.state.meal_type}>
            {this.state.mealType.map((row, index) => {
              return (
                <FormControlLabel
                control={<Radio color="primary" checked={this.state.selectedMealType.includes(row.key) ? true :false} className={classes.CheckboxLabel}/>}
                  onClick={(e) => this.toggleMealType(row.key, e)}
                  value={row.key}
                  label={row.value}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
        <FormControl
          component="fieldset"
          className={classes.formControl,classes.formControl2}
        >
          <FormLabel className={classes.subtitle1}>Max Occupancy</FormLabel>
          <FormGroup className={classes.fromGroup} >
            {this.state.maxOccupancy.map((row, index) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                     color="primary"
                      checked={
                        this.state.selectedMaxOccupancy.includes(row)
                          ? true
                          : false
                      }
                      className={classes.CheckboxLabel}
                      name={row}
                      onChange={(e) => this.toggleMaxOccupancy(row, e)}
                    />
                  }
                  label={row + " guest"}
                />
              );
            })}
          </FormGroup>
          
        </FormControl>
        
      </>
    );
  };
  render() {
    const { classes } = this.props;
    const { t } = this.props;
    let is_admin = (this.props.userSession.user.is_staff) ? true : false;
    return (
      <>
      <HeaderCard>
                <Grid container>
                    <Grid item >
                        <div className={classes.divStyle} >
                        <Typography variant="h5" className={classes.fontStyle} >
                          {t("OTA Setup")}
                        </Typography>
                        </div>
                    </Grid>
                </Grid>
            </HeaderCard>
        <Card>
          <CardContent>
            {is_admin &&
              <Grid className={classes.gridStyle} item >
                <TextField
                  id="standard-required"
                  label="Subscription Id"
                  onChange={this.toggaleSubscriptionId("subscription_id")}
                  value={this.state.subscription_id}
                  placeholder={t('Subscription Id')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
             }
            <div classes={classes.root}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} className={classes.msgError}>
                  {this.renderCompetitorSelection()}
                </Grid>
                <Grid item xs={12} sm={6} className={classes.msgError}>
                  {this.renderRoomTypeSelection()}
                </Grid>
              </Grid>
              <div className={classes.btnDiv}>
              <Button
                className={classes.saveBtn}
                variant="contained"
                color="primary" 
                onClick={this.toggleWarningModal}
              >
                {t("Save")}
              </Button>
              {is_admin &&
              <Button href={this.state.OTACompetitorCsv.url}  className={[classes.pricePmsBtn, classes.diagnosticBtn, classes.msgError]}  color="primary" >
                {t("Download CSV")}
              </Button>
              }
            </div>
            </div>
            
             </CardContent>
         
          {Warning.warningModal(this)}
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userSession: state.sessionReducer,
    hotelAuthReducer: state.hotelAuthReducer,
    competitorReducer: state.competitorReducer,
    hotelListReducer: state.hotelListReducer,
    commonReducer: state.commonReducer,
    OTASetupReducer: state.OTASetupReducer,
    OTAInsightsReducer:state.OTAInsightsReducer
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCompetitor: (request) => {dispatch(actions.getCompetitor(request));},
    setHotelList: (request) => {dispatch({ type: actionTypes.HOTEL_LIST_SUCCEED, state: request });},
    getHotelList: (request) => {dispatch(actions.getHotelList(request));},
    storeOtaCompetitorSetup: (request) => {dispatch(actions.storeOtaCompetitorSetup(request));},
    onGetOtaCompetitor: (request) => {dispatch(actions.onGetOtaCompetitor(request));},
    selectedCompetitorsCsv :(request)=>{dispatch(actions.selectedCompetitorsCsv(request));},
    updatePriceJson: (request, response, type) => { dispatch(actions.updateSettingJson(request, response, type)) },
  };
};
export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps)
)(withNamespaces("translations")(OTASetup));
