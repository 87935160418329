var style = {
  calendarMenuMainDiv3: {
      minHeight: "45px",
      marginBottom: "-12px",
      marginTop: "3px"
  },
  // dateNavigationDivResponsive: {
  //     ['@media (max-width: 600px)']: {
  //       flexWrap: "wrap"
  //     }
  // },
  dateNavigationDivResponsive: {
    width:'77%',
      ['@media (max-width: 414px) and (min-width: 360px)']: {
        flexWrap: "wrap"
      }
  },
  dateNavigationDiv: {
      float: "right",
      display: 'flex',
      alignItems: 'center',
      '@media screen and (max-width: 800px)': {
        width: "100%",
        textAlign: "-webkit-right"
      },
  },
  dateNavigationDivGraph: {
    margin:'20px 0px 0px',
    float: "left",
    // marginTop:'16px',
    display: 'flex',
    alignItems: 'center',
    '@media screen and (max-width: 800px)': {
      width: "100%",
      textAlign: "-webkit-right"
    },
},
  calendarNaviBtn: {
      minWidth: "35px",
      padding: "0px",
      marginTop: "1px"
  },
  fullWidthResp: {
    // fontWeight:'100',
    whiteSpace:'nowrap',
    margin:'0 20px 0 10px',
    color:'#707070 !important',
      ['@media (max-width: 600px)']: {
        // width: "100%",
        maxWidth: "100%",
        marginLeft:'12px'
      }
  },
  changeIcon:{
    color:'#707070'
  },
  performanceCard:{
      marginTop:'27px'
  },
  cardBorder:{        
      '@media (max-width: 768px)': {
          borderBottom: '1px solid #c4c4c4', 
      },
      '@media (min-width: 769px)': {
          borderLeft: '1px solid #c4c4c4', 
      },
      borderRadius: '0px', 
  },
  cardContainer:{
      '@media (max-width: 768px)': {
        padding:'15px 0'
      },
      '@media (min-width: 769px)': {
          padding:'0 15px'
      },
  },
  performancePrice:{
      fontSize:'25px',
      fontWeight:'500',
      float:'left',
      textAlign:'left',
      marginTop:'4px',
      color:'#3d5170',
      letterSpacing:'0px'
  },
  grayText:{
      textAlign:'left',
      float:'left',
      marginTop:'15px',
      // fontSize:'15px',
      color: '#707070'
  },
  normalPrice:{
      fontSize:'18px',
      fontWeight:'500',
      float:'left',
      textAlign:'left',
      marginTop:'2px',
      color:'#3d5170'
  },
  cardTitle:{
      color:'#3d5170 !important',
  },
  cardTitleLeft:{
      width:'100%',
      textAlign:'left'
  },
  dateTableDiv: {
      width: '100%',
      overflow: 'auto'
  },
  graphView: {
    minWidth: "800px",
    marginLeft:'-6px',
    marginTop: "10px",
  },
    uploadMsg: {
      float: "right !important",
      color:'#707070',
      marginTop:'29px',
      fontSize:'15px',
      // margin:'4px 27px',
      ['@media (max-width: 540px)']: {
        float: "left !important",
        width: '100% !important',
        margin:'5px 0px',
        paddingTop: '0px !important',
        textAlign:'left'
      },
    },
  MuiCardHeaderTitle:{
      float:'left'
  },
  updateBtn: {
      margin:'9px 9px 0 5px',
      ['@media (max-width: 768px) and (min-width: 360px)']: {
          
      }
  },
  newTabBtnFirst: {
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "0px",
      padding: "5px 10px"
    },
    newTabBtnLast: {
      borderTopLeftRadius: "0px",
      borderBottomLeftRadius: "0px",
    },
    graphtextDiv: {
      display: "flex",
      flexDirection: "column",
      ['@media (max-width: 540px)']: {
        float: "left !important",
        width: '100% !important',
        marginTop: '0px !important',
        paddingTop: '0px !important'
      },
    },
    pricePmsBtnCheckBox: {
      
      marginLeft: '18px',
      margin: "5px -4px 0px 0px",
    },
    performanceTitle:{
      float: 'left',
      // fontSize: '16px',
      fontWeight: '400',
      color: '#707070'
    },
    currentTitle:{
      marginTop:'7px',
      padding:'0px 6px',
      float:'left',
      fontSize:'14px',
      display:'flex',
      alignItems:'center',
      borderRadius:'5px',
      marginLeft:'15px'
    },
    iconStyle:{
      paddingTop:'0px',
      marginLeft:'-5px'
    },
    vsStyle:{
    marginLeft: '8px',
    fontSize: '14px', 
    color: '#8495b1', 
    marginTop: '6px' 
    },
    flexCenter:{
      display: 'flex', 
      alignItems: 'center' 
    },

    upDownText:{
      float: 'left', 
      display: 'flex', 
      padding: '0 6px', 
      alignItems: 'center', 
      borderRadius: '5px',
      marginLeft: '0px', 
      marginTop: '7px'
    },
    tabStyle:{
      marginBottom: "27px", 
      // float: "left",
      borderBottom: '1px solid #f0f0f0'
    },
    redColor:{
      color: '#890921',
      marginLeft: '-5px'
    },
    successColor:{
      color: '#135138',
      marginLeft: '-5px'
    },
    textHeader:{
     
    },
    upDownPickUp:{
      display: 'flex', 
      alignItems: 'center', 
      marginLeft: '15px'        
   },
   downloadCSV:{
    height:'37px',
    margin:"0px 10px 0px 10px",
    color:"#707070 !important"
   },
   hideOnMobile: {
    ['@media (max-width: 540px)']: {
      display: "none !important"
    },
  },
  newTabBtnFirstGraph: {
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    padding: "5px 10px",
    float:'left',
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.875rem",
    fontWeight: 400,
  },
  newTabBtnLastGraph: {
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    float:'left',
    color: "#707070",
    fontSize: "0.875rem",
    fontWeight: 400,
    padding: "5px 10px",
  },
  htmlPopper: {
    opacity: "1"
  },
  htmlTooltip: {
    backgroundColor: '#323338 !important',
    color: 'white',
    maxWidth: '350px',
    // border: '1px solid #1976d2',
    opacity: '1',
    filter: 'alpha(opacity=100)'
  }, 
  dateNavigationDivforModal:{
    margin:'11px 15px 0px',
    float: "left",
    width:'100%',
    display: 'flex',
    alignItems: 'center',
    '@media screen and (max-width: 800px)': {
      width: "100%",
      textAlign: "-webkit-right"
    },
  },
  defaultRate: {
    whiteSpace: "nowrap",
    color: "grey",
    display: "flex",
    flexDirection: "column",
    fontSize: "12px",
    margin:'0px 5px'
  },
  boldFont: {
    fontWeight: "500",
    fontSize: "18px",
    color: "#707070!important"
  },
  filterDate:{
    width:'72px',
    // marginLeft:'22px',
    color: '#707070',  
    fontWeight: "400",
    '@media screen and (max-width: 800px)': {
      marginLeft:'0px',
    }
  },
  filterDatePro:{
    width:'105px',
    marginLeft:'0px',
    color: '#707070',  
    fontWeight: "400"
  },
  toggleGrid:{
    '@media screen and (max-width: 800px)': {
      marginBottom:'20px',
    }
  },
  responsiveGrpBtn:{
    '@media screen and (min-width: 2100px)': {
      minWidth:'12%',
    },
    '@media screen and (min-width: 1024px)': {
      minWidth:'13%',
    }
  },
  adornment:{
    color:'#0000008a',
    marginRight:'6px'
  },
  buttonProgress: {
      position: 'absolute'
  },
  dangerBtnHvr:{
    background:'#d32f2f !important',
    color:'white !important',
    '&:hover': {
      background:'#a93939 !important'
    }
  },
  TabsView:{
    // float:"left"
    // marginTop: "34px",
    borderBottom : "1px solid #f0f0f0",
    
  },
  grayTextRoomType:{
    textAlign:'left',
    float:'left',
    marginTop:'15px',
    whiteSpace:'normal',
    color: '#707070'
  }
}
export default style;