import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CaledarCss from './css/calendarCss';
import moment from 'moment';
import BigCalendar from 'react-big-calendar';
import Button from '@material-ui/core/Button';
import Modals from './modal/modal';
import {ExpansionPanel,ExpansionPanelSummary, ExpansionPanelDetails, Icon, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Server from '../../config/server';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../store/actions/index';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { withNamespaces } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CalendarbuttonTheme,  CalendarTheme, CalendartooltipTheme, monthPickerStyle } from '../muiTheme';
import * as calendarEvents from './calendarEvents';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ValidateBaseSettings } from '../Utils/SettingValidation/Validation';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CustomTooltip from '../Utils/toolTip';
import ReactGA from 'react-ga';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import LockIcon from '@material-ui/icons/Lock';
import HeaderCard from './HeaderCard';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ConfettiJoy from './confetti';
import { MuiPickersUtilsProvider, DatePicker, InlineDatePicker  } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from "date-fns/locale/en-US";
import HelpCenterIcon from '@material-ui/icons/Help';
import pmsDataArray from '../PMSList';
import Badge from '../Badge';

// import { jsPDF } from "jspdf";
const styles = CaledarCss;
moment.locale("es-es", { week: { dow: 7 } });
const localizer = BigCalendar.momentLocalizer(moment)
var settingJsonData = {};
var cancelTokenSource = null;
var ws = {};
var channel = null;
var calendarPusher = null;
var interval = null;
let fullYearOtaValues = [];
const plugins= [{
  beforeInit: function(chart, args, options) {
    chart.legend.afterFit = function() {
      this.height = this.height + 30;
    }
  }
}]
class CalulatePrice extends React.Component {

  constructor(props) {
    super(props);
    this.myChartRef = React.createRef();
  }

  state = {
    isBooking: false,
    bulkEditType: 1, // 1: percent 2: min/max 3: derivation 4:fixed/skip 5: closed
    modalTab: 1,
    leavePage: false,
    adjTab: "adjust",
    settingAlert: false,
    csvFormat: 1,
    graphTab: 1,
    bulkUpdateList: {
      individual: [],
      deleteIndividual: [],
      skipDate: [],
      deleteSkip: [],
    },
    popupView: "price",
    isSkip: null,
    isGrey: true,
    BulkRoomId: null,
    bulkUpdateData: {
      closedRooms: '',
      skipName: '',
      fixedPrice: '',
      dailyAdjust: '',
      minPrice: '',
      maxPrice: '',
      avgPrice: '',
      adjVsRef: '',
    },
    bulkError: {},
    bulkStartDate: null,
    bulkEndDate: null,
    bulkWeekdays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
    bulkDateAdjModal: false,
    bulkRoomModal: false,
    price_type_value_error: false,
    popupRoomData: null,
    greySaveUpload: false,
    suggestedModalPrice: 0,
    target_occupancy: 0,
    modalWeekData: {},
    modalMonthData: {},
    weeklyModal: false,
    mothlyModal: false,
    monthlyData: [],
    weeklyData: [],
    price_type: "",
    price_type_value: "",
    loadUploadBtn: false,
    last_run_pricing_type: 1,
    pricing_result: null,
    yesterday_pricing_data: null,
    last_week_pricing_result: null,
    calendarType: 0,
    showFullYearGraph: true,
    openBulkUpdate: false,
    bulkStartDate: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'),
    bulkEndDate: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).endOf('month').format('YYYY-MM-DD'),
    bulkUpdateType: "",
    bulkUpdateRooms: "",
    bulkUpdateField: "",
    bulkValue: "",
    openAutoUpdateConfirm: false,
    skipValidation: false,
    skipUpdate: 0,
    openUpdatePmsType: false,
    singleAdjustment: 0,
    openDateAdjustment: false,
    skipData: {},
    openSkipDate: false,
    priceNote: "",
    todayMessage: null,
    stopCachedPrice: false,
    get_pms_data: false,
    preDefinedRates: [],
    uploadLoadig: false,
    happyPricing: false,
    viewType: 'calendar', // can be calendar, table, graph
    showCsvPrice: false,
    showDownloadCsv: false,
    monthChangeDate: new Date(moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY'), (moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('M') - 1), 5),
    updatePmsStart: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'),
    updatePmsEnd: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).add('6', 'M').format('YYYY-MM-DD'),
    showUpdatePrice: false,
    allEvents: false,
    inginiusFiles: [],
    pms_provider: 0,
    pms_provider_obj:[],
    file: [],
    is_runPricing: false,
    socketLoader: false,
    loadPercentage: false,
    loadData: false,
    PriceModalopen: false,
    ManuallPriceOpen: false,
    showPriceBtn: false,
    start_date: '',
    end_date: '',
    selectedRoom: false,
    inventory_data: [],
    value: "0",
    days: '7',
    currency: {},
    calendarData: [],
    myEventsList: [],
    modalData: {},
    file_id: null,
    csvUrl: '',
    update_message: "",
    tableStartDate: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).toDate(),
    daysList: [],
    error: [],
    width: window.innerWidth,
    is_update_to_pms: false,
    update_status: 0,
    updatePmsType: 7,
    types: [
      { key: 1, value: '1 Week' },
      { key: 2, value: '2 Weeks' },
      { key: 3, value: '1 Month' },
      { key: 4, value: '3 Months' },
      { key: 5, value: '6 Months' },
      { key: 6, value: '9 Months' },
      { key: 7, value: '12 Months' },
      { key: 8, value: '18 Months' },
    ],
    dateSetting: null,
    targetOccupancyModal: false,
    featuresEnabled: [],
    skipDates: [],
    performance: {
      future: [],
      past: []
    },
    isAbsolute: false,
    weekPercentage: 0,
    monthPercentage: 0,
    roomRemainingData: [],
    refRoomOnly: false,
    manualAdjusted: 0,
    programList: [],
    removeAdjModal: false,
    adjustDates: [],
    isSkipDateModal: false,
    adjType: 0,
    popupError: {},
    expectedOccupancy: [],
    run_message: "",
    monthlyOccupancy: [],
    averageWeekMonth: [],
    onlyRunAdjustment: false,
    bulkLoader: false,
    calLoader: false,
    disableSave: false,
    minPrice: "",
    maxPrice: "",
    baseRate: "",
    autoRunModal: false,
    tempUpload: {},
    weather: {},
    selectedCompetitors: [],
    competitorRates: [],
    threashold: false,
    yieldingTagsList: [],
    deleteModalData: {
      fix: false,
      minMax: false,
      perAjd: false,
      der: false,
      close: false,
      occupancy: false,
      aggressiVeness:false,
      leadTimeAdjustment:false
    },
    deriveFromRef: false,
    pmsProviderList: [10, 15, 29, 34, 38, 40, 23, 44, 47, 28, 53, 22, 39, 45, 50, 58, 60, 61, 35 , 63, 71],
    start_monday:false,
    room_apartment_space_name: "",
    removeAdjustLoader:false,
    closeAllRoom:false,
    expanded:true,
    ota_update_message:"",
    selectedRoomType: null,
    selectedMaxOccupancy: null,
    selectedMealType: null,
    selectedRateType: "cheapest",
    selectedRPGRoomId: null,
    rateTypes: ["cheapest", "flexible"],
    roomTypes: [],
    maxOccupancy: [],
    mealType: [],
    selectedCompetitors: [],
    rateTypes: ["cheapest", "flexible"],
    competitor: 0,
    confettiStart:false,
    profitLoader: false,
    // notesStartEndDate:false,
    adjScrollDate:false,
    revenueMsg:'',
    opentRevenueTableModal:false,
    userNotesList:[],
    noteEditEnable:false,
    userNotesPricingSetting:[],
    editNotesValue:{},
    chargebeeAPIcall:true,
    chargebeeModal:false,
    is_channel_manager:false,
    pms_channel_manager_text :'',
    bulkAveragePrice: 0,
    bulkSumOfPrice: 0,
    otaInsightHiddenLegends:localStorage.getItem("otaChartHiddenLegendData") ? JSON.parse(localStorage.getItem("otaChartHiddenLegendData")) : [],
    bulkNumberOfDays:0,
    importPMSDataModalopen: false,
    pms_json_file:null,
    importPMSDataLoader:false,
    importPMSDataFileRequire:false,
    importPMSDataFileValidationMessage:'',
    bulkAveragePrice: 0,
    bulkSumOfPrice: 0,
    otaInsightHiddenLegends:localStorage.getItem("otaChartHiddenLegendData") ? JSON.parse(localStorage.getItem("otaChartHiddenLegendData")) : [],
    bulkNumberOfDays:0,
    detectclosedroomspms:false,
    flag_closed_room_popup:false,
    pickupType: 0,
    clientAccessModal:false,
    trialDate:moment().add(14,'days').format('DD-MM-YYYY'),
    minPricingOccupancy: 0,
    maxPricingOccupancy: 0,
    occupancyPrices: {},
    occupancyPricesAll: {},
    pmsList: {
      is_independent_rate: false,
      mapped_data: [],
    },
    selectedRoomBulkEdit: "",
    defaultOccupancy: {},
    occupancy_percent_derivation: false,
    trialType:null
  };

  // componentWillUnmount() {
  //   calendarPusher.disconnect();
  // }

  
  componentWillMount() {

    this.initPusher();

    moment.locale("es-es", { week: { dow: this.props.hotelAuthReducer.hotelDetails.hotel.starts_monday ? 1 : 7 } });
    let requestData = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      url:
      "hotel/list/?country=" +
      this.props.hotelAuthReducer.hotelDetails.hotel.country.id,
      is_from_ota_setup: false,
      date_range:'dashboard_kpis_full_date_range',
      is_calendar:true,
    };
    this.props.selectedCompetitorsCsv(requestData);
    this.props.getHotelDetail(requestData);
    this.props.getRoomList(requestData);
    this.props.getWeatherDetails(requestData);
    this.props.getAlgoProgram(requestData);
    this.props.getCompetitor(requestData);
    this.props.storeOtaCompetitorRates(requestData);
    this.props.onGetOtaCompetitor(requestData);
    this.props.importantEvents(requestData);    
    this.props.storeOtaCompetitorRates(requestData);
    this.props.getOtaDropdownData(requestData)
    this.props.getUserNotes(requestData)
    this.props.popupAlertMessage(requestData)
    this.getCalendarSettingJson();
    this.props.getPmsList(requestData);
    this.props.getOccupancyPricesAll(requestData);
    
    let req = { Authtoken: this.props.hotelAuthReducer.hotelDetails.token };
    this.props.getConnectionList(req);

    let value = this.props.hotelAuthReducer.hotelDetails.hotel.last_run_pricing_type;
    if(!this.props.priceSettingReducer.priceSetting.features.includes(23) && this.props.hotelAuthReducer.hotelDetails.hotel.last_run_pricing_type == 6){
      value = 5
    }
    this.handlePricingType(value);
    this.props.getPerformanceReservation(requestData);
    let dates = [];
    const startOfMonth = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).endOf('month').format('YYYY-MM-DD');

    for (var m = moment(startOfMonth); m.isSameOrBefore(endOfMonth); m.add(1, 'days')) {
      if (m.isSameOrAfter(moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'))) {
        dates.push(moment(m.format('YYYY-MM-DD')));
      }
    }

    var a = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).endOf('month');
    var b = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone);
    let nextMonth = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).add(1, 'month').startOf('month').format('YYYY-MM-DD');

    if (a.diff(b, 'days') <= 30) {
      let count = Math.abs(30 - a.diff(b, 'days'));
      let i = 0;
      for (i == 0; i < count; i++) {
        dates.push(moment(nextMonth).add(i, 'd'))
      }
    }

    this.setState({
      daysList: dates,
      room_apartment_space_name: this.props.hotelAuthReducer.hotelDetails.hotel.room_apartment_space_name
    })

    interval = setInterval(()=>{
          this.props.updateTempProp("");
     }, 500);
  }

  componentWillReceiveProps(props) {

    if (props.connectionReducer.connectionList && !props.connectionReducer.seekom) {
      props.connectionReducer.connectionList.filter(row => row.primary === true).map((row) => {
        this.setState({
            occupancy_percent_derivation:row.occupancy_percent_derivation,
          })
      })
    }

    if(props.roomList?.occupancyPricesAll){
      this.setState({
        occupancyPricesAll:props.roomList?.occupancyPricesAll
      })
    }

    if (props.roomList.occupancyPrices != this.props.roomList.occupancyPrices) {
      let occArr=this.state.occupancyPrices;
      let defaultOccupancy = this.state.defaultOccupancy;
      let minOccupancy;
      let maxOccupancy;

      if(!this.state.occupancyPrices[this.state.selectedRoomBulkEdit]){
        let roomData = this.props.roomList.roomList.find(val => val.id == this.state.selectedRoomBulkEdit);
        defaultOccupancy[this.state.selectedRoomBulkEdit] = props.roomList.occupancyPrices;
        props.pmsList.mapped_data.map((val) => {
          if (roomData && roomData?.pms_room && val.id == roomData.pms_room) {
            minOccupancy = val.min_occupancy;
            maxOccupancy = val.max_occupancy;
          }
        })
        if (props.roomList.occupancyPrices && Object.keys(props.roomList.occupancyPrices).length > 0 ) {      
          for (var i = minOccupancy; i <= maxOccupancy; i++) {
            let data = props.roomList.occupancyPrices.filter(val => val.occupancy == i);
            if(data.length > 0){
              occArr[data[0].room] = occArr[data[0].room] || [];
              occArr[data[0].room].push({ occupancy: i, derivation: "", room: data[0].room });
            }else{
              occArr[this.state.selectedRoomBulkEdit] = occArr[this.state.selectedRoomBulkEdit] || [];
              occArr[this.state.selectedRoomBulkEdit].push({ occupancy: i, derivation: "", room: this.state.selectedRoomBulkEdit });
            }
          }
        }else{      
          for (var i = minOccupancy; i <= maxOccupancy; i++) {
            occArr[this.state.selectedRoomBulkEdit] = occArr[this.state.selectedRoomBulkEdit] || [];
            occArr[this.state.selectedRoomBulkEdit].push({ occupancy: i, derivation: "", room: this.state.selectedRoomBulkEdit });
          }
        }    
        this.setState({
          minPricingOccupancy: minOccupancy,
          maxPricingOccupancy: maxOccupancy,
          occupancyPrices: occArr,     
          defaultOccupancy: defaultOccupancy
        })
      }
    }
    
    if(props.hotelDetail){
      if(props.hotelDetail.state==1 && this.state.chargebeeAPIcall){
        this.callChargebee()
      }
      this.setState({
          start_monday:props.hotelDetail.starts_monday,
          trialType:props.hotelDetail.state
      })
    }
    if(this.props.userSession.user.language && this.props.userSession.user.language != this.state.language){
           

      var myClasses = document.getElementsByClassName("rbc-header");

      let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

      if(props.userSession.user.language == "de"){
        days = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
      }
      
      if(this.props.hotelAuthReducer.hotelDetails.hotel.starts_monday){        
        days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

        if(props.userSession.user.language == "de"){
          days = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
        }
      }

      for (var i = 0; i < myClasses.length; i++) {
        myClasses[i].innerHTML = '<span>'+days[i]+'</span>';
      }

      this.setState({
        langauge: this.props.userSession.user.language
      })
    }

    if (props.OTASetupReducer.OTACompetitorList &&  !this.state.isReady) {
      if (props.OTASetupReducer.OTACompetitorList.room_type_selections) {
        let roomTypes = [];
        let maxOccupancy = [];
        props.OTASetupReducer.OTACompetitorList.room_type_selections.max_occupancy.map(
          (row) => {
            maxOccupancy.push(row);
          }
        );
        let mealTypes = [];
        props.OTASetupReducer.OTACompetitorList.room_type_selections.meal_type.map((row) => {
            mealTypes.push(row);
          }
        );
        props.OTASetupReducer.OTACompetitorList.room_type_selections.room_type.map(
          (row) => {
            roomTypes.push(row);
          }
        );
        let selectedCompetitors = [];
        props.OTASetupReducer.OTACompetitorList.competitors_data.map((row, index) => {
            selectedCompetitors.push(row);
          }
        );
        this.setState({
          selectedCompetitors: selectedCompetitors,
          maxOccupancy: maxOccupancy,
          selectedMaxOccupancy: maxOccupancy[1],
          mealType: mealTypes,
          roomTypes: roomTypes,
          selectedRoomType: roomTypes[0]
        },() => { 
          if (!props.OTAInsightsReducer.OTADropdownData) { 
            this.handleOTADropdownData() 
          } 
        });

        if (!this.state.isReady) {
          this.setState({
            isReady: true,
            subscription_id: props.OTASetupReducer.OTACompetitorList.ota_subscription_id,
          });
        }
      }
    }


    if (props.OTAInsightsReducer.OTACompetitorRate) {
      let update_time_msg = ''
      if (props.hotelDetail && props.hotelDetail.prefered_date_format) {
        update_time_msg = props.OTAInsightsReducer.OTACompetitorRate.updated_at ? moment.tz(props.OTAInsightsReducer.OTACompetitorRate.updated_at).format(props.hotelDetail.prefered_date_format ? props.hotelDetail.prefered_date_format :'DD/MM/YYYY, hh:mm A') : null;
      }

      if (update_time_msg) {
        if (moment.utc(this.props.hotelAuthReducer.utcTime).tz(props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY') == moment.tz(props.OTAInsightsReducer.OTACompetitorRate.updated_at).format('DD/MM/YYYY')) {
          update_time_msg =moment.utc(props.OTAInsightsReducer.OTACompetitorRate.updated_at).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('hh:mm A');
        }
      }
      const { t ,classes} = props;
      let OTAMessage = (
        <span style={{ display: 'flex', flexDirection: 'column' }}>
          <span className={classes.lastUpdateBtn} >
            <Typography className={classes.textSecondary} style={{marginRight:'5px'}} >{t('Last Upload:')} </Typography>
            <Typography className={classes.textSecondary}> 
            <span>
              {' '+(update_time_msg ? update_time_msg : " "+" n.a.")} {update_time_msg ? <div style={{ height: "12px", width: "12px", borderRadius: "12px", background: "#42a047", display: "-webkit-inline-box", marginLeft: "2px", marginBottom: "-1px" }}></div> : " "}
            </span>
            </Typography>
          </span>
        </span>
      );
      this.setState({
        competitorRates: props.OTAInsightsReducer.OTACompetitorRate.competitor_rates,
        ota_update_message: OTAMessage
      })
    }

    if (props.commonReducer.showNavidationPop) {
      this.setState({
        leavePage: true,
        tempType: props.commonReducer.showNavidationPop
      },
        () => {
          this.props.showNavigationPopup(null)
        })
    }

    if (props.algoReducer && props.algoReducer.runAlgo && props.algoReducer.runAlgo.length > 0) {
      this.setState({
        programList: props.algoReducer.runAlgo,
        programLoader: false,
        programListHide: false,
      })
    }
    if (props.algoReducer && props.algoReducer.runAlgo && props.algoReducer.runAlgo.length == 0) {
      this.setState({
        programListHide: true,
      })
    }
    if (props.priceSettingReducer.priceSetting) {
      let skipDates = [];
      Object.keys(props.priceSettingReducer.priceSetting.dates).map((key) => {
        if (props.priceSettingReducer.priceSetting.dates[key].skip_date) {
          skipDates.push({
            ...props.priceSettingReducer.priceSetting.dates[key].skip_date,
            date: key
          })
        }
      })
      this.setState({
        skipDates
      })
    }

    if (props.priceSettingReducer.priceSetting) {
      let adjustDates = [];
      Object.keys(props.priceSettingReducer.priceSetting.dates).map((key) => {
        if (props.priceSettingReducer.priceSetting.dates[key].individual_adjustment) {
          adjustDates.push({
            ...props.priceSettingReducer.priceSetting.dates[key].individual_adjustment,
            date: key
          })
        }
      })
      this.setState({
        adjustDates
      })
    }

    if (props.hotelAuthReducer.hotelDetails) {
      this.setState({
        target_occupancy: props.hotelAuthReducer.hotelDetails.hotel.target_occupancy,
        temp_target_occupancy: props.hotelAuthReducer.hotelDetails.hotel.target_occupancy,
        pms_provider: props.hotelAuthReducer.hotelDetails.hotel.pms_provider, 
        is_channel_manager:props.hotelAuthReducer.hotelDetails.hotel.is_channel_manager,
        pms_channel_manager_text :props.hotelAuthReducer.hotelDetails.hotel.is_channel_manager ? 'Channel Manager':'PMS',
        userState:props.hotelAuthReducer.hotelDetails.hotel.state,
      },() => {
        let obj = pmsDataArray.find(o => o.value === this.state.pms_provider);
        this.setState({
          pms_provider_obj:obj
        })
      })

    }
    if (props.roomList.roomList && props.hotelDetail && this.props.commonReducer.initPusher && this.props.commonReducer.initPusher.subscribed) {      
      if (props.roomList.roomList && !this.state.selectedRoom && props.priceSettingReducer.priceSetting && props.priceSettingReducer.priceFlag) {
        this.setState({
          selectedRoom: props.roomList.roomList.filter(val => val.is_reference_room == true)[0].id,
        })
        clearInterval(interval);
        if (!this.state.selectedRoom && !props.priceSettingReducer.priceFlag.run_pricing) {
          this.getLattestPricing()
        } else if (!this.state.selectedRoom) {
          this.setState({
            start_date: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'),
            end_date: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).add((this.state.featuresEnabled.includes(23) ? '545' : '365'), 'd').format('YYYY-MM-DD'),
            get_pms_data: (![15, 34, 22, 45, 46, 48, 49, 50, 52, 53, 54, 57, 58, 56 ,63, 67, 69].includes(props.hotelAuthReducer.hotelDetails.hotel.pms_provider) && props.priceSettingReducer.priceFlag.fetch_inventory) ? true : false,
          },
            () => {
              this.startRunPricing(true)
            }
          )
        }
      }

      if (props.commonReducer.calendarRoom && props.roomList.roomList) {
        if (props.commonReducer.calendarRoom.roomId && props.commonReducer.calendarRoom.roomId != this.state.viewType) {
          // if ((props.commonReducer.calendarRoom.roomId === 'occCalendar') && (Server.env === "live") && !(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type)) {
          //   ReactGA.event({
          //     category: 'Occupancy-View',
          //     action: 'switch-month'
          //   });
          // }
          this.setState({
            // viewType: (props.commonReducer.calendarRoom.roomId == "calendar" ? (this.state.viewType == "priceGraph" ? "priceGraph" : props.commonReducer.calendarRoom.roomId) : props.commonReducer.calendarRoom.roomId),
            viewType: props.commonReducer.calendarRoom.roomId,
          },
            () => {

              if(this.state.viewType == "priceGraph"){
                this.getMonthData(moment().format('YYYY-MM-DD'), true);
              }

              // this.getCalendarData()

              if (props.commonReducer.calendarRoom.roomId == "calendar" || props.commonReducer.calendarRoom.roomId == "occCalendar") {
                let allPricing = props.priceSettingReducer.priceSetting.hotel.all_room_pricing;
                let displayRooms = props.roomList.roomList.filter(row => (allPricing && (row.pms_rate || [17, 8, 35, 39,42, 52].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider)))).map(row => row.id)
                let refroom = props.roomList.roomList.filter(val => val.is_reference_room)[0];

                if (!displayRooms.includes(this.state.selectedRoom)) {
                  this.setState({
                    selectedRoom: refroom.id
                  })
                }
              }

            })
        }
      }

      // hotel data
      let update_time = props.hotelDetail.last_update_price_pms ? moment.tz(props.hotelDetail.last_update_price_pms, props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM') : null;
      let pricing_time = props.hotelDetail.last_run_pricing ? moment.tz(props.hotelDetail.last_run_pricing, props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM') : null;
      let update_time_msg = '';
      if (props.hotelDetail && props.hotelDetail.prefered_date_format) {
        update_time_msg = props.hotelDetail.last_update_price_pms ? moment.tz(props.hotelDetail.last_update_price_pms, props.hotelAuthReducer.hotelDetails.hotel.timezone).format(props.hotelDetail.prefered_date_format? props.hotelDetail.prefered_date_format.replace('/YYYY','') :'DD/MM, hh:mm A') : null;
      } 

      let pricing_time_msg = props.hotelDetail.last_run_pricing ? moment.tz(props.hotelDetail.last_run_pricing, props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY, hh:mm A') : null;
      let inventary_time_msg = props.hotelDetail.last_inventory_price_get ? moment.tz(props.hotelDetail.last_inventory_price_get, props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY, hh:mm A') : null;

      if (update_time_msg) {
        if (moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY') == moment.tz(props.hotelDetail.last_update_price_pms, props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY')) {
          update_time_msg =  moment.tz(props.hotelDetail.last_update_price_pms, props.hotelAuthReducer.hotelDetails.hotel.timezone).format('hh:mm A');
        }
      }

      if (pricing_time_msg) {
        if (moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY') == moment.tz(props.hotelDetail.last_run_pricing, props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY')) {
          pricing_time_msg = moment.tz(props.hotelDetail.last_run_pricing, props.hotelAuthReducer.hotelDetails.hotel.timezone).format(' hh:mm A');
        }
      }

      if (inventary_time_msg) {
        if (moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY') == moment.tz(props.hotelDetail.last_inventory_price_get, props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY')) {
          inventary_time_msg =  moment.tz(props.hotelDetail.last_inventory_price_get, props.hotelAuthReducer.hotelDetails.hotel.timezone).format(' hh:mm A');
        }
      }
      const { t ,classes} = props;
      let message = (
        <span style={{ display: 'flex', flexDirection: 'column' }}>
          <span className={classes.lastUpdateBtn} >
            <Typography className={classes.textSecondary} style={{marginRight:'5px'}} >{t('Last Upload:')} </Typography>
            <Typography className={classes.textSecondary}>{(!props.hotelDetail.last_update_price_pms_status && props.hotelDetail.last_update_price_pms != null) ? <span>  {' '+t('Failed')} <div style={{ height: "12px", width: "12px", borderRadius: "12px", background: "#d32f2f", display: "-webkit-inline-box", marginLeft: "2px", marginBottom: "-1px" }}></div></span> : <span> {' '+(update_time_msg ? update_time_msg : " "+" n.a.")} </span>}</Typography>
          </span>
        </span>
      );
     
      let messagerun = (
        <span style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ display: 'flex', margin: "5px 0px", borderBottom: "1px solid #ffffffe8" }}>
            <span style={{ width: '140px', marginRight: '10px' }}>{t('Last Pricing Run')}</span>
            <span>{pricing_time_msg ? pricing_time_msg : "n.a."}</span>
          </span>
          <span style={{ display: 'flex', margin: "5px 0px" }}>
            <span style={{ width: '140px', marginRight: '10px' }}>{t(`Last Inventory/Price Fetch From ${this.state.pms_channel_manager_text}`)}</span>
            <span>{inventary_time_msg ? inventary_time_msg : "n.a."}</span>
          </span>
        </span>
      );
      let reservationDate;
      if(this.state.last_reservation_received){
        if (moment.utc(props.hotelAuthReducer.utcTime).tz(props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY') == moment.tz(this.state.last_reservation_received, props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY')) {
          reservationDate = t('Today') + moment.tz(this.state.last_reservation_received, props.hotelAuthReducer.hotelDetails.hotel.timezone).format(', hh:mm A');
        }else{
          reservationDate=moment.tz(this.state.last_reservation_received, props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM, hh:mm A')
        }
      }
    let revenueMsg=(

          <span style={{ display: 'flex', flexDirection: 'column' }}>
          <span className={classes.lastUpdateBtn} >
            <Typography className={classes.textSecondary} style={{marginRight:'5px',fontSize:'15px'}} >{t('Last Reservation:')} </Typography>
            <Typography className={classes.textSecondary}> 
            <span style={{fontSize:'15px'}}>
              {reservationDate ? reservationDate+" " :'n.a.'} 
            </span>
            </Typography>
          </span>
        </span>
    ) 
      this.setState({
        revenueMsg:revenueMsg
      })
      if (props.hotelDetail.last_run_pricing == null || moment(props.hotelDetail.last_update_price_pms).diff(props.hotelDetail.last_run_pricing, 'seconds') >= 0) {
        let todayMessage = " "
        if (moment.tz(props.hotelDetail.last_update_price_pms, props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY') == moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY')) {
          let minutes = Math.abs(moment.tz(props.hotelDetail.last_update_price_pms, props.hotelAuthReducer.hotelDetails.hotel.timezone).diff(moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone), 'minutes'));

          let hour_diff = moment.duration(minutes, 'minutes').hours();
          let min_diff = moment.duration(minutes, 'minutes').minutes();

          if (hour_diff > 0) {
            update_time = hour_diff;
            todayMessage = "h ";
          } else {
            update_time = min_diff;
            todayMessage = "min ";
          }
        } else if (moment.tz(props.hotelDetail.last_update_price_pms, props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY') == moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).subtract(1, 'days').format('DD/MM/YYYY')) {
          update_time = 1;
          todayMessage = "d";
        } else if (moment.tz(props.hotelDetail.last_update_price_pms, props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY') == moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).subtract(2, 'days').format('DD/MM/YYYY')) {
          update_time = 2;
          todayMessage = "d";
        } else if (moment.tz(props.hotelDetail.last_update_price_pms, props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY') == moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).subtract(3, 'days').format('DD/MM/YYYY')) {
          update_time = 3;
          todayMessage = "d";
        }
        if (!props.hotelDetail.last_update_price_pms_status && props.hotelDetail.last_update_price_pms != null) {
          todayMessage = " ";
          update_time = "Failed";
        }
        this.setState({
          todayMessage: todayMessage,
          is_update_to_pms: props.hotelDetail.is_update_to_pms,
          update_status: props.hotelDetail.last_update_price_pms_status,
          update_time: update_time,
          from_Pricing: false,
          update_message: message,
          run_message: messagerun
        })
      } else {
        let todayMessage = " ";
        if (moment.tz(props.hotelDetail.last_run_pricing, props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY') == moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY')) {
          let minutes = Math.abs(moment.tz(props.hotelDetail.last_run_pricing, props.hotelAuthReducer.hotelDetails.hotel.timezone).diff(moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone), 'minutes'));

          let hour_diff = moment.duration(minutes, 'minutes').hours();
          let min_diff = moment.duration(minutes, 'minutes').minutes();

          if (hour_diff > 0) {
            pricing_time = hour_diff;
            todayMessage = "h";
          } else {
            pricing_time = min_diff;
            todayMessage = "min ";
          }
        } else if (moment.tz(props.hotelDetail.last_run_pricing, props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY') == moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).subtract(1, 'days').format('DD/MM/YYYY')) {
          pricing_time = 1;
          todayMessage = "d";
        } else if (moment.tz(props.hotelDetail.last_run_pricing, props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY') == moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).subtract(2, 'days').format('DD/MM/YYYY')) {
          pricing_time = 2;
          todayMessage = "d";
        } else if (moment.tz(props.hotelDetail.last_run_pricing, props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY') == moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).subtract(3, 'days').format('DD/MM/YYYY')) {
          pricing_time = 3;
          todayMessage = "d";
        }

        this.setState({
          todayMessage: todayMessage,
          is_update_to_pms: props.hotelDetail.is_update_to_pms,
          update_status: props.hotelDetail.last_update_price_pms_status,
          update_time: pricing_time,
          from_Pricing: true,
          update_message: message,
          run_message: messagerun
        })
      }

      if (this.state.isBookingView !== props.hotelDetail.is_update_to_pms) {
        this.setState({
          isBooking: props.hotelDetail.is_update_to_pms,
          isBookingView: props.hotelDetail.is_update_to_pms,
          viewType: "calendar"
        },
          () => this.getCalendarData())
      }
    }
    // else if(props.commonReducer.calendarRoom && props.roomList.roomList && !(channel && channel.subscribed)){      
    //     this.props.updateTempProp("");
    // }
    if (props.inventaryReducer.inventaryList) {
      let start_date = props.inventaryReducer.inventaryList[0].date;
      if (!moment(start_date).isSameOrAfter(moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'))) {
        start_date = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD');
      }
      let end_date = props.inventaryReducer.inventaryList[props.inventaryReducer.inventaryList.length - 1].date
      this.setState({
        inventory_data: props.inventaryReducer.inventaryList,
        start_date: start_date,
        end_date: end_date,
      })
    }

    if (props.hotelDetail) {
      this.setState({
        skipUpdate: (props.hotelDetail.update_to_pms_skip_type ? props.hotelDetail.update_to_pms_skip_type : 0),
        updatePmsType: (props.hotelDetail.update_to_pms_type ? props.hotelDetail.update_to_pms_type : 7)
      })
    }

    if (props.priceSettingReducer.priceSetting) {
      this.setState({
        leadTimeAdjustment: props.priceSettingReducer.priceSetting.adjustment.lead_time,
        refRoomOnly: !props.priceSettingReducer.priceSetting.hotel.all_room_pricing,
        dateSetting: props.priceSettingReducer.priceSetting,
        isAbsolute: props.priceSettingReducer.priceSetting.hotel.adjustment_to_reference_room_is_absolute,
      })

      if (Array.isArray(props.priceSettingReducer.priceSetting.predefined_rates)) {
        this.setState({
          preDefinedRates: props.priceSettingReducer.priceSetting.predefined_rates
        })
      }
    }

    if (props.priceSettingReducer.priceSetting) {
      let monthlyData = props.priceSettingReducer.priceSetting.adjustment.monthly;
      let weeklyData = props.priceSettingReducer.priceSetting.adjustment.weekday;

      this.setState({
        monthlyData: monthlyData,
        weeklyData: weeklyData
      })
    }

    if (props.priceSettingReducer && props.priceSettingReducer.priceSetting) {
      this.setState({
        featuresEnabled: props.priceSettingReducer.priceSetting.features
      })
    }

    if (props.commonReducer.notify) {
      if (props.commonReducer.notify.message) {
        this.setState({
          bulkRoomModal: false,
          bulkDateAdjModal: false,
          bulkLoader: false
        })
      }
    }
    if (props.priceSettingReducer.priceSetting && props.priceSettingReducer.priceSetting.room_type_tags) {
      this.setState({
        yieldingTagsList: props.priceSettingReducer.priceSetting.room_type_tags
      })
    }
    if(props.priceSettingReducer && props.priceSettingReducer.priceSetting){
      this.setState({
        userNotesPricingSetting:props.priceSettingReducer.priceSetting.user_notes
      })
    }
    if(props.priceSettingReducer && props.priceSettingReducer.userNotesList){
      this.setState({
        userNotesList:props.priceSettingReducer.userNotesList
      })
    }
    if (props.commonReducer && props.commonReducer.profitReport && props.commonReducer.profitReport.success){
      this.setState({
        profitReportLoading:props.commonReducer.profitReportLoading,
        profitReportMessage:props.commonReducer.profitReport.message,
      })
    }
    if(!props.priceSettingReducer.priceSetting.features.includes(1)){
      this.setState({
        adjType:5
      })
    }
    if(props.PerformanceDashboardReducer.performanceReservation && props.PerformanceDashboardReducer.performanceReservation.kpi_table_yearly){
      this.setState({
        last_reservation_received:props.PerformanceDashboardReducer.performanceReservation.kpi_table_yearly?.last_reservation_received
      })
    }
    if (props.PerformanceDashboardReducer.performanceReservation) {
      let kpi_table_daily = props.PerformanceDashboardReducer.performanceReservation?.kpi_table_daily ? props.PerformanceDashboardReducer?.performanceReservation.kpi_table_daily : {};
      let kpi_table_monthly = props.PerformanceDashboardReducer.performanceReservation?.kpi_table_monthly ? props.PerformanceDashboardReducer?.performanceReservation.kpi_table_monthly : {};
      if (kpi_table_daily && kpi_table_monthly) {
        this.setState({
          kpi_monthly_total: kpi_table_monthly.total ? kpi_table_monthly.total :'',
          kpi_monthly_stly: kpi_table_monthly.stly ? kpi_table_monthly.stly :'',
          kpi_daily_total: kpi_table_daily.total ? kpi_table_daily.total :'',
          kpi_daily_stly: kpi_table_daily.stly ? kpi_table_daily.stly :'',
        })
      }
    }
    if(props.commonReducer?.chargebeeSubscriptionLink && props.commonReducer.chargebeeSubscriptionLink.show_payment_status && (props.commonReducer.chargebeeSubscriptionLink.payment_info.invoice.status == 'payment_due' || props.commonReducer.chargebeeSubscriptionLink.payment_info.invoice.status == 'not_paid')){
      this.setState({
          chargebeeSubscription:props.commonReducer.chargebeeSubscriptionLink,
          chargebeeModal:true
        })
      }
    if(props.popupMessageReducer?.popupMessage?.flag_closed_room_popup){
        this.setState({
          flag_closed_room_popup: props.popupMessageReducer.popupMessage.flag_closed_room_popup
        })
    }
  }

  handleOccupancyPricingDiff = (e, occ, occIndex) => {
    let prices = this.state.occupancyPrices;
    let data = [];
    prices[this.state.selectedRoomBulkEdit].map((val) => {
      if (val.occupancy == occ.occupancy) {
        data.push({
          occupancy: val.occupancy,
          derivation: e.target.value ? parseInt(e.target.value) :null,
          room: occ.room
        })
      } else {
        data.push(val);
      }
    });

    prices[this.state.selectedRoomBulkEdit] = data;
    
    this.setState({
      occupancyPrices: prices,
    });
  };
  
  closeChargebeeModal = () =>{
    this.setState({
      chargebeeModal:false
    })
     let requestData = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      show_payment_status:false
    }
    this.props.storeChargebeeNotification(requestData)
  }
  callChargebee = () => {
    let requestData = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
    }
    this.props.getChargebeeSubscriptionLink(requestData)
    this.setState({
      chargebeeAPIcall:false
    })
  }
  changePricingTab = () => {
    this.setState({
      modalTab: 4
    })
  }
  chnageDeleteAdj = (name) => {
    this.setState({
      deleteModalData: {
        ...this.state.deleteModalData,
        [name]: !this.state.deleteModalData[name]
      }
    })

  }

  uploadSingleModal = () => {
    
    let error = {
      maxPrice: [],
      minPrice: [],
      fixedPrice: []
    };

    let bulkUpdateData = this.state.bulkUpdateData; 
    let refroom = this.props.roomList.roomList.filter(val => val.is_reference_room)[0];

    var currentDate = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD');
    
  

    Object.keys(this.state.bulkUpdateData.maxPrice).map((key) => {      
      if ((this.state.featuresEnabled.includes(6) || refroom.id == key) && ((bulkUpdateData.maxPrice[key] === "0" || bulkUpdateData.maxPrice[key] === 0) || (bulkUpdateData.minPrice[key] && bulkUpdateData.maxPrice[key] && parseFloat(bulkUpdateData.minPrice[key]) > parseFloat(bulkUpdateData.maxPrice[key])))&& (!this.state.refRoomOnly || refroom.id == key)) {
        error.maxPrice.push(parseInt(key))
      }
    })

    Object.keys(this.state.bulkUpdateData.minPrice).map((key) => {      
      if ((bulkUpdateData.minPrice[key] === "0" || bulkUpdateData.minPrice[key] === 0) && (!this.state.refRoomOnly || refroom.id == key)) {
        error.minPrice.push(parseInt(key))
      }
    })

    Object.keys(this.state.bulkUpdateData.fixedPrice).map((key) => {      
      if ((bulkUpdateData.fixedPrice[key] === "0" || bulkUpdateData.fixedPrice[key] === 0) && (!this.state.refRoomOnly || refroom.id == key)) {
        error.fixedPrice.push(parseInt(key))
      }
    })

    if (error.fixedPrice.length > 0 || error.maxPrice.length > 0 || error.minPrice.length > 0) {
      this.setState({
        popupError: error,
        adjTab: "minmax",
        modalTab: 2
      })
      return;
    }

    this.setState({
      uploadSingleModal: !this.state.uploadSingleModal
    })
  }

  handleisSkipDateModal = () => {

    let fixprice_array = {};
    let setting = this.state.dateSetting;

    if (!this.state.isSkipDateModal) {
      Object.keys(setting.default).map((key) => {
        fixprice_array[key] = "";
      })
    }

    this.setState({
      greySaveUpload: false,
      isSkipDateModal: !this.state.isSkipDateModal,
      bulkUpdateData: {
        ...this.state.bulkUpdateData,
        skipName: "",
        fixedPrice: fixprice_array
      }
    })
  }

  toggleAutoPriceRun = () => {
    this.setState({
      autoRunModal: !this.state.autoRunModal
    })
  }

  toggleAdjustmentViewModal = (date) => {
    this.setState({
      BulkRoomId: this.state.selectedRoom,
      bulkStartDate: moment(date).format('YYYY-MM-DD'),
      bulkEndDate: moment(date).format('YYYY-MM-DD'),
      price_type: 'adjust',
    })
  }


  toggleRemoveAdjModal = () => {
    
    let deleteModalData = {
      fix: false,
      minMax: false,
      perAjd: false,
      der: false,
      close: false
    };

    if (this.state.adjType == 0) {
      deleteModalData.perAjd = true;
    } else if (this.state.adjType == 1) {
      deleteModalData.minMax = true;
    } else if (this.state.adjType == 2) {
      deleteModalData.der = true;
    } else if (this.state.adjType == 3) {
      deleteModalData.fix = true;
    } else if (this.state.adjType == 5) {
      deleteModalData.close = true;
    } else if (this.state.adjType == 6) {
      deleteModalData.occupancy = true;
    } else if (this.state.adjType == 7) {
      deleteModalData.aggressiVeness = true;
    } else if (this.state.adjType == 8) {
      deleteModalData.leadTimeAdjustment = true;
    }

    this.setState({
      removeAdjModal: !this.state.removeAdjModal,
      deleteModalData
    })
  }

  closeRemoveAdj = () => {
    this.setState({
      removeAdjModal: false,
      deleteModalData: {
        fix: false,
        minMax: false,
        perAjd: false,
        der: false,
        close: false,
        occupancy: false,
        aggressiVeness:false,
        leadTimeAdjustment:false
      },
    })
  }

  stopNavigation = (data) => {
    this.props.stopNavigation(data)
  }

  createDirectProgram = (value) => {
    if (this.state.programList.length == 0 && value) {
      let defaultData = [
        ['early morning', 7, 0, 7],
        ['quickmorning', 9, 0, 2],
        ['morning', 11, 0, 7],
        ['quickafternoon', 13, 0, 2],
        ['afternoon', 15, 0, 7],
        ['quickevening', 17, 0, 2],
        ['evening', 19, 0, 7],
        ["end of day run", 22, 30, 2],
        ["night run", 1, 0, 7]
      ];

      if (this.state.pms_provider == 24) {
        defaultData = [
          ['early morning', 6, 30, 7],
          ['quickmorning', 8, 55, 2],
          ['morning', 10, 55, 7],
          ['quickafternoon', 12, 55, 7],
          ['afternoon', 14, 55, 7],
          ['quickevening', 17, 55, 2],
          ['evening', 20, 55, 7],
          ["end of day run", 22, 30, 2],
          ["night run", 1, 0, 7]
        ];
      }

      defaultData.map((data, index) => {
        let reqParams = {
          hotel: this.props.hotelAuthReducer.hotelDetails.hotel.id,
          hour: data[1],
          minute: data[2],
          name: data[0],
          timezone: this.props.hotelAuthReducer.hotelDetails.hotel.timezone,
          type: data[3],
          token: this.props.hotelAuthReducer.hotelDetails.token,
          stopFetch: index == 6 ? false : true,
        }
        this.props.createAlgoProgram(reqParams)
      })

      this.setState({
        programLoader: true,
      })

    } else {
      return null;
    }
  }

  toggleLeavePage = (type) => {
    this.setState({
      leavePage: !this.state.leavePage,
      tempType: type ? type : '',
    })
  }

  dontSaveChange = () => {
    this.setState({
      isGrey: true,
      leavePage: false,
      bulkUpdateList: {
        individual: [],
        deleteIndividual: [],
        skipDate: [],
        deleteSkip: [],
      },
      dateSetting: settingJsonData
    },
      () => {
        this.stopNavigation(false);
        this.changeView(this.state.tempType);
      })
  }

  getCalendarSettingJson = () => {
    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
    axios.get(Server.API + 'hotel/pricing-settings/')
      .then(function (response) {
        settingJsonData = response.data
      });
  }

  bulkUpdateData = (name, roomId, realtime) => event => {

    let refroom = this.props.roomList.roomList.filter(val => val.is_reference_room)[0];
    let bulkUpdateData = this.state.bulkUpdateData;

    let tracking_key_name = '';
    if(name == "dailyAdjust") {
      tracking_key_name = "Percent Adjustment Changed";
    } else if (name == "fixedPrice") {
      tracking_key_name = "Fix Prices Changed";
    } else if (name == "minPrice" || name == "maxPrice") {
      tracking_key_name = "Min/Max Prices Changed";
    } 

    if (name == "closedRooms" || name == "minPrice" || name == "maxPrice" || name == "adjVsRef" || name == "fixedPrice") {

      if (refroom.id == roomId && !event.target.value) {
        Object.keys(this.state.bulkUpdateData[name]).map((key) => {
          this.state.bulkUpdateData[name][key] = "";
        })
      }

      bulkUpdateData = {
        ...this.state.bulkUpdateData,
        [name]: {
          ...this.state.bulkUpdateData[name],
          [roomId]: event.target.value
        }
      }
    } else {
      bulkUpdateData = {
        ...this.state.bulkUpdateData,
        [name]: event.target.value
      }
    }

    if (event.target.value && (((name == "minPrice" || name == "maxPrice")) || name == "fixedPrice") && this.state.deriveFromRef && refroom.id == roomId) {      
      let value = event.target.value;
      this.setState({
        disableSave: true
      })
      setTimeout(() => {
        if (bulkUpdateData == this.state.bulkUpdateData) {
        this.setState({
          renderDataLoad: true,
          calLoader: name,
        })
          this.calculateDerivePrice(value, name, roomId)
        }
      }, 1500)

    } else {
      let setting = JSON.stringify(this.state.dateSetting);
      if(realtime && (name == "adjVsRef" || name == "dailyAdjust" || name == "minPrice" || name == "maxPrice" || name == "fixedPrice")) {
        this.setState({
          disableSave: true
        })
      }
      setTimeout(() => {        
        if (bulkUpdateData == this.state.bulkUpdateData && realtime && (name == "adjVsRef" || name == "dailyAdjust" || name == "minPrice" || name == "maxPrice" || name == "fixedPrice")) {
          this.setState({
            renderDataLoad: true,
            calLoader: name
          })
          setting = JSON.parse(setting);

          this.getNewAdjustPrices(bulkUpdateData, roomId, setting);
        }

      }, 1500);
    }


    this.setState({
      greySaveUpload: false,
      bulkUpdateData: bulkUpdateData
    })
  }

  getNewAdjustPrices = (bulkUpdateData, roomId, setting, hideLoader) => {

    let refroom = this.props.roomList.roomList.filter(val => val.is_reference_room)[0];


    if ((parseFloat(bulkUpdateData.fixedPrice[refroom.id]) || this.state.isSkipDateModal) && this.state.deriveFromRef) {

      if (!setting.dates[this.state.modalData.date]) {
        setting.dates[this.state.modalData.date] = {};
      }

      setting.dates[this.state.modalData.date].skip_date = {
        fixed_price: parseFloat(bulkUpdateData.fixedPrice[refroom.id]),
        name: bulkUpdateData.skipName,
      }

    } else {
      if (setting.dates[this.state.modalData.date] && setting.dates[this.state.modalData.date].skip_date) {
        delete setting.dates[this.state.modalData.date].skip_date;
      }
    }

    // if (!this.state.deriveFromRef) {
    Object.keys(bulkUpdateData.fixedPrice).map((roomkey) => {
      // if (refroom.id != roomkey || !this.state.deriveFromRef) {
        if (bulkUpdateData.fixedPrice[roomkey]) {
            if (!setting.dates[this.state.modalData.date]) {
              setting.dates[this.state.modalData.date] = {}
            }
          if (!setting.dates[this.state.modalData.date][roomkey]) {
            setting.dates[this.state.modalData.date][roomkey] = {}
          }
          setting.dates[this.state.modalData.date][roomkey].fix_price = parseFloat(bulkUpdateData.fixedPrice[roomkey]);
        } else if (setting.dates[this.state.modalData.date] && setting.dates[this.state.modalData.date][roomkey] && setting.dates[this.state.modalData.date][roomkey].fix_price) {
          delete setting.dates[this.state.modalData.date][roomkey].fix_price
        }
      // }

      if (!bulkUpdateData.fixedPrice[roomkey] && setting.dates[this.state.modalData.date] && setting.dates[this.state.modalData.date][roomkey] && setting.dates[this.state.modalData.date][roomkey].fix_price) {
        delete setting.dates[this.state.modalData.date][roomkey].fix_price
      }

    })




    if (parseFloat(bulkUpdateData.dailyAdjust)) {
      if (setting.dates[this.state.modalData.date]) {
        setting.dates[this.state.modalData.date].individual_adjustment = {
          name: null,
          percentage: parseFloat(bulkUpdateData.dailyAdjust) ? parseFloat(bulkUpdateData.dailyAdjust) : 0
        }
      } else {
        setting.dates[this.state.modalData.date] = {
          individual_adjustment: {
            name: null,
            percentage: parseFloat(bulkUpdateData.dailyAdjust) ? parseFloat(bulkUpdateData.dailyAdjust) : 0
          }
        }
      }
    } else if (setting.dates[this.state.modalData.date]) {
      delete setting.dates[this.state.modalData.date].individual_adjustment
    }

    let defaults = setting.default;

    if (!setting.dates[this.state.modalData.date]) {
      setting.dates[this.state.modalData.date] = {};
    }

    if (!setting.dates[this.state.modalData.date][roomId]) {
      setting.dates[this.state.modalData.date][roomId] = {}
    }


    Object.keys(bulkUpdateData.minPrice).map((roomkey) => {
      if (defaults[roomkey].min_price != bulkUpdateData.minPrice[roomkey]) {
        if (!setting.dates[this.state.modalData.date][roomkey]) {
          setting.dates[this.state.modalData.date][roomkey] = {}
        }
        setting.dates[this.state.modalData.date][roomkey].min_price = parseFloat(bulkUpdateData.minPrice[roomkey]);
      } else if (setting.dates[this.state.modalData.date][roomkey] && setting.dates[this.state.modalData.date][roomkey].min_price) {
        delete setting.dates[this.state.modalData.date][roomkey].min_price
      }
    })

    Object.keys(bulkUpdateData.maxPrice).map((roomkey) => {
      if (defaults[roomkey].max_price != bulkUpdateData.maxPrice[roomkey]) {
        if (!setting.dates[this.state.modalData.date][roomkey]) {
          setting.dates[this.state.modalData.date][roomkey] = {}
        }
        setting.dates[this.state.modalData.date][roomkey].max_price = parseFloat(bulkUpdateData.maxPrice[roomkey]);
      } else if (setting.dates[this.state.modalData.date][roomkey] && setting.dates[this.state.modalData.date][roomkey].max_price) {
        delete setting.dates[this.state.modalData.date][roomkey].max_price
      }
    })

    Object.keys(bulkUpdateData.adjVsRef).map((roomkey) => {
      let currentDerivation = Math.round(bulkUpdateData.adjVsRef[roomkey]); 
      if (defaults[roomkey].adjustment_to_reference_room != currentDerivation) {
        if (!setting.dates[this.state.modalData.date][roomkey]) {
          setting.dates[this.state.modalData.date][roomkey] = {}
        }
        setting.dates[this.state.modalData.date][roomkey].adjustment_to_reference_room = currentDerivation;
      } else if (setting.dates[this.state.modalData.date][roomkey] && setting.dates[this.state.modalData.date][roomkey].adjustment_to_reference_room) {
        delete setting.dates[this.state.modalData.date][roomkey].adjustment_to_reference_room
      }
    })

    if (setting.dates[this.state.modalData.date][roomId] && setting.dates[this.state.modalData.date][roomId].closed_rooms) {
      setting.dates[this.state.modalData.date][roomId].closed_rooms = setting.dates[this.state.modalData.date][roomId].closed_rooms;
    }

    if (Object.keys(setting.dates[this.state.modalData.date][roomId]).length == 0) {
      delete setting.dates[this.state.modalData.date][roomId]
    }

    let requestData = {
      json_settings: JSON.stringify(setting),
      start_date: this.state.modalData.date,
      end_date: this.state.modalData.date,
      is_write_to_cache: false
    }

    // this.setState({
    //   calLoader: hideLoader ? false : true
    // })

    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
    axios.post(Server.API + 'hotel/adjustment/individual/apply/', requestData)
      .then((response) => {
        this.setState({
          calLoader: false,
          renderDataLoad: false,
          disableSave: false
        })
        if (response.data && response.data[this.state.modalData.date]) {
          if (response.data[this.state.modalData.date].error) {
            let requestData = {
              msg: response.data[this.state.modalData.date].reason,
              is_success: false,
            }

            this.props.putNotify(requestData)
          } else {
            this.setState({
              tempUpload: response.data[this.state.modalData.date],
              modalData: {
                ...this.state.modalData,
                ...response.data[this.state.modalData.date][roomId]
              }
            })
          }
        }
      }).catch((error) => {
        this.setState({
          calLoader: false,
          disableSave: false
        })
        let requestData = {
          msg: 'Error while Applying the Changes',
          is_success: false,
        }
        this.props.putNotify(requestData)
      })
  }

  changeAdjTab = (val) => {
    let tracking_key_name = "";
    if (val == "adjust") {
      tracking_key_name = "Percent Adjustment Clicked";
    } else if (val == "minmax") {
      tracking_key_name = "Min/Max Prices Clicked";
    } else if (val == "fix") {
      tracking_key_name = "Fix Prices Clicked";
    }
    this.setState({
      adjTab: val
    })
  }

  handleAdjVsRef = (value) => {
    this.setState({
      greySaveUpload: false,
      bulkUpdateData: {
        ...this.state.bulkUpdateData,
        adjVsRef: value,
      }
    })

  }

  clearFixedPrice = () => {
    this.setState({
      bulkUpdateData: {
        ...this.state.bulkUpdateData,
        skipName: "",
        fixedPrice: ''
      }
    })
  }

  bulkUpdateIsSkip = (event) => {
    let value = event.target.value;

    if (this.state.isSkip == "4" && value == "4") {
      value = "2";
    }
    if (this.state.isSkip == "3" && value == "3") {
      value = "1";
    }
    if (this.state.isSkip == "6" && value == "6") {
      value = "5";
    }

    if (value) {
      this.setState({
        isSkip: value,
        bulkUpdateData: {
          ...this.state.bulkUpdateData,
          skipName: '',
          fixedPrice: '',
        }
      })
    }
  }

  //updateSettingJson

  setDefaultValues = () => {

    let setting = { ...this.state.dateSetting };

    let min_price_array = {};
    let max_price_array = {};
    let derivation_array = {};
    let fixedPrice = {};

    Object.keys(setting.default).map((key) => {
      min_price_array[key] = setting.default[key].min_price;
      max_price_array[key] = setting.default[key].max_price;
      derivation_array[key] = Math.round(setting.default[key].adjustment_to_reference_room);
      fixedPrice[key] = "";
    })
    this.setState({
      greySaveUpload: false,
      isSkipDateModal: false,
      calLoader: true,
      disableSave: true,
      removeAdjustLoader:true,
      bulkUpdateData: {
        ...this.state.bulkUpdateData,
        minPrice: min_price_array,
        maxPrice: max_price_array,
        adjVsRef: derivation_array,
        skipName: "",
        fixedPrice: fixedPrice,
        dailyAdjust: 0,
      }
    })

    if (setting.dates[this.state.modalData.date]) {
      delete setting.dates[this.state.modalData.date]
    }

    let requestData = {
      json_settings: JSON.stringify(setting),
      start_date: this.state.modalData.date,
      end_date: this.state.modalData.date,
      is_write_to_cache: false
    }

    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
    axios.post(Server.API + 'hotel/adjustment/individual/apply/', requestData)
      .then((response) => {


        this.setState({
          calLoader: false,
          disableSave: false,
          removeAdjustLoader:false
        })
        if (response.data && response.data[this.state.modalData.date]) {
          if (response.data[this.state.modalData.date].error) {
            let requestData = {
              msg: response.data[this.state.modalData.date].reason,
              is_success: false,
            }

            this.props.putNotify(requestData)
          } else {
            this.setState({
              tempUpload: response.data[this.state.modalData.date],
              modalData: {
                ...this.state.modalData,
                ...response.data[this.state.modalData.date][this.state.selectedRoom]
              }
            })
          }
        }
      }).catch((error) => {
        this.setState({
          calLoader: false,
          disableSave: false
        })
        let requestData = {
          msg: 'Error while Applying the Changes',
          is_success: false,
        }
        this.props.putNotify(requestData)
      })
  }

  setDefaultAdj = () => {
    let setting = this.state.dateSetting;

    let adjVsRef = parseFloat(setting.default[this.state.BulkRoomId].adjustment_to_reference_room).toFixed(0);

    this.setState({
      bulkUpdateData: {
        ...this.state.bulkUpdateData,
        adjVsRef: adjVsRef
      }
    },
      () => this.handleAdjVsRef(adjVsRef))
  }

  updateSettingJson = (isUpdate) => { //1

    // console.log("occupancyPrices", this.state.occupancyPrices);
    // return "";

    var a = moment(this.state.bulkStartDate).format('YYYY-MM-DD');
    var b = moment(this.state.bulkEndDate).format('YYYY-MM-DD');
    let bulkUpdateData = this.state.bulkUpdateData;
    var currentDate = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD');
    
    if(a < currentDate){
      return false
    }

    let dates = [];
    for (var m = moment(a); m.isSameOrBefore(b); m.add(1, 'days')) {
      if (this.state.bulkWeekdays.includes(m.format('dddd'))) {
        dates.push(m.format('YYYY-MM-DD'))
      }
    }
    let error = {
      maxPrice: [],
      minPrice: [],
      fixedPrice: [],
      aggressiVeness:false,
      medianleadtime:false,
    };
    if(this.state.bulkEditType == "2" || this.state.bulkEditType == "4"){                  
        let refroom = this.props.roomList.roomList.filter(val => val.is_reference_room)[0];

        Object.keys(this.state.bulkUpdateData.maxPrice).map((key) => {      
          if ((this.state.featuresEnabled.includes(6) || refroom.id == key) && (this.state.featuresEnabled.includes(6) || refroom.id == key) && ((bulkUpdateData.maxPrice[key] === "0" || bulkUpdateData.maxPrice[key] === 0) || (bulkUpdateData.minPrice[key] && bulkUpdateData.maxPrice[key] && parseFloat(bulkUpdateData.minPrice[key]) > parseFloat(bulkUpdateData.maxPrice[key])))&& (!this.state.refRoomOnly || refroom.id == key)) {
            error.maxPrice.push(parseInt(key))
          }
        })

        Object.keys(this.state.bulkUpdateData.minPrice).map((key) => {      
          if ((bulkUpdateData.minPrice[key] === "0" || bulkUpdateData.minPrice[key] === 0) && (!this.state.refRoomOnly || refroom.id == key)) {
            error.minPrice.push(parseInt(key))
          }
        })

        Object.keys(this.state.bulkUpdateData.fixedPrice).map((key) => {      
          if ((bulkUpdateData.fixedPrice[key] === "0" || bulkUpdateData.fixedPrice[key] === 0) && (!this.state.refRoomOnly || refroom.id == key)) {
            error.fixedPrice.push(parseInt(key))
          }
        })
    
        if (error.fixedPrice.length > 0 || error.maxPrice.length > 0 || error.minPrice.length > 0) {
          this.setState({
            bulkError: error,
            bulkEditType: (error.fixedPrice.length > 0 ? 4 : 2)
          })
          return null;
        }        
    }
    if(this.state.bulkEditType === 7){
      if(!bulkUpdateData?.aggressiVeness){        
        error.aggressiVeness = true;        
      }         
    }
    if(this.state.bulkEditType === 8){
      if(!bulkUpdateData?.leadTimeAdjustment){        
        error.medianleadtime = true;       
      }      
    }
    if(error.aggressiVeness == true || error.medianleadtime == true){
      this.setState({
        bulkError: error,
      })
      return null;
    }else{
      this.setState({
        bulkError: error,
      })      
    }    
    this.setSkipDate(dates).then(() => {
      this.saveTabledata(isUpdate);
    })

    let adjType = 0;
    let tracking_key_name = '';
    if (this.state.bulkEditType == 5) {
      adjType = 5;
      tracking_key_name = `Closed ${this.state.room_apartment_space_name}s in Bulk Edit Changed`;
    }else if (this.state.bulkEditType == 6) {
      adjType = 6;
      tracking_key_name = `Ocuupancy Based Pricing Changed.`;
    } else if (this.state.bulkEditType == 7) {
      adjType = 7;
      tracking_key_name = `Aggressiveness Pricing Changed.`;
    }  else if (this.state.bulkEditType == 8) {
      adjType = 8;
      tracking_key_name = `Lead Time Adjustment Pricing Changed.`;
    } else if (this.state.bulkEditType == 4) {
      adjType = 3;
      tracking_key_name = "Fix Prices in Bulk Edit Changed";
    } else if (this.state.bulkEditType == 3) {
      adjType = 2;
      tracking_key_name = `${this.state.room_apartment_space_name} Derivation in Bulk Edit Changed`;
    } else if (this.state.bulkEditType == 2) {
      adjType = 1;
      tracking_key_name = "Minimum and Maximum Prices in Bulk Edit Changed";
    } else {
      adjType = 0;
      tracking_key_name = "Percent Adjustments in Bulk Edit Changed";
    }
    this.chnageAdjType(adjType)

  }

  validateUpdateJson = () => {
    let bulkError = [];

    if (!this.state.bulkUpdateData.dailyAdjust) {
      bulkError.push('dailyAdjust');
    }

    if (!this.state.bulkUpdateData.skipName) {
      bulkError.push('skipName');
    }

    if (!this.state.bulkUpdateData.fixedPrice) {
      bulkError.push('fixedPrice');
    }

    this.setState({
      bulkError
    })

    return bulkError;
  }


  setSkipDate = async (data) => {
    await Promise.all(data.map(async (value) => {
      let oldSettings = settingJsonData;
      let setting = this.state.dateSetting;
      let bulkUpdateList = this.state.bulkUpdateList;
      let type = this.state.bulkEditType;
      let refroom = this.props.roomList.roomList.filter(val => val.is_reference_room)[0];

      if (!setting.dates[value]) {
        setting.dates[value] = {}
      }

      if (type == 1) {
        let percent = parseFloat(this.state.bulkUpdateData.dailyAdjust);
        if (percent) {
          setting.dates[value] = {
            ...setting.dates[value],
            individual_adjustment: {
              name: null,
              percentage: percent,
            },
          };
        } else if (setting.dates[value] && setting.dates[value].individual_adjustment) {
          delete setting.dates[value].individual_adjustment;
        }

      } else if (type == 2) {

        Object.keys(this.state.bulkUpdateData.minPrice).map((key) => {
          let min = this.state.bulkUpdateData.minPrice[key];
          let defaultData = setting.default[key];
          if (min != "" && min != defaultData.min_price) {
            if (!setting.dates[value][key]) {
              setting.dates[value][key] = {}
            }
            setting.dates[value] = {
              ...setting.dates[value],
              [key]: {
                ...setting.dates[value][key],
                min_price: min,
              },
            };
          }else if(min != "" && min == defaultData.min_price && setting.dates[value] && setting.dates[value][key] && setting.dates[value][key].min_price){
            delete setting.dates[value][key].min_price;
          }
        })

        Object.keys(this.state.bulkUpdateData.maxPrice).map((key) => {
          let max = this.state.bulkUpdateData.maxPrice[key];
          let defaultData = setting.default[key];
          if (max != "" && max != defaultData.max_price) {
            if (!setting.dates[value][key]) {
              setting.dates[value][key] = {}
            }
            setting.dates[value] = {
              ...setting.dates[value],
              [key]: {
                ...setting.dates[value][key],
                max_price: max,
              },
            };
          }else if(max != "" && max == defaultData.max_price && setting.dates[value] && setting.dates[value][key] && setting.dates[value][key].max_price){
            delete setting.dates[value][key].max_price;
          }
        })

      } else if (type == 3) {

        Object.keys(this.state.bulkUpdateData.adjVsRef).map((key) => {
          let adjVsRef = this.state.bulkUpdateData.adjVsRef[key];
          let defaultData = setting.default[key];
          let isSameDerivation = Math.round(adjVsRef) != (defaultData.adjustment_to_reference_room) ? true : false;
          if (adjVsRef != "" && isSameDerivation) {
            if (!setting.dates[value][key]) {
              setting.dates[value][key] = {}
            }
            setting.dates[value] = {
              ...setting.dates[value],
              [key]: {
                ...setting.dates[value][key],
                adjustment_to_reference_room: adjVsRef,
              },
            };
          } else if(setting.dates[value] && setting.dates[value][key] && setting.dates[value][key].adjustment_to_reference_room) {
            delete setting.dates[value][key].adjustment_to_reference_room;
          }
        })

      } else if (type == 4) {

        if ((this.state.bulkUpdateData.fixedPrice[refroom.id] || this.state.isSkipDateModal) && this.state.deriveFromRef) {

          if (!setting.dates[value]) {
            setting.dates[value] = {};
          }

          setting.dates[value].skip_date = {
            fixed_price: this.state.bulkUpdateData.fixedPrice[refroom.id],
            name: this.state.bulkUpdateData.skipName,
          }

        } else {
          if (setting.dates[value] && setting.dates[value].skip_date) {
            delete setting.dates[value].skip_date;
          }
        }

        Object.keys(this.state.bulkUpdateData.fixedPrice).map((roomkey) => {
            if (this.state.bulkUpdateData.fixedPrice[roomkey]) {
              if (!setting.dates[value][roomkey]) {
                setting.dates[value][roomkey] = {}
              }
              setting.dates[value][roomkey].fix_price = parseFloat(this.state.bulkUpdateData.fixedPrice[roomkey]);
            } else if (setting.dates[value] && setting.dates[value][roomkey] && setting.dates[value][roomkey].fix_price) {
              delete setting.dates[value][roomkey].fix_price
            }
          if (!this.state.bulkUpdateData.fixedPrice[roomkey] && setting.dates[value] && setting.dates[value][roomkey] && setting.dates[value][roomkey].fix_price) {
            delete setting.dates[value][roomkey].fix_price
          }

        })


      } else if (type == 5) {

        Object.keys(this.state.bulkUpdateData.closedRooms).map((key) => {
          let closedRooms = this.state.bulkUpdateData.closedRooms[key];
          if (closedRooms != "") {
            if (!setting.dates[value][key]) {
              setting.dates[value][key] = {}
            }

            setting.dates[value] = {
              ...setting.dates[value],
              [key]: {
                ...setting.dates[value][key],
                closed_rooms: parseFloat(closedRooms),
              },
            };
          }
        })
      }else if (type == 6) {
        Object.values(this.state.occupancyPrices).map(val => {
          let occupancyPricing = {};
          
          val.map(row=>{
            let defaultValue = this.state.defaultOccupancy[row.room].find(def => row.occupancy === def.occupancy);
            if(defaultValue && defaultValue.derivation != row.derivation){
              occupancyPricing[row.occupancy] = row.derivation;
            }else if(setting.dates[value] && setting.dates[value][val[0].room] && setting.dates[value][val[0].room].occupancy_pricing) {
              delete setting.dates[value][val[0].room].occupancy_pricing;
            }
          })

          if(Object.keys(occupancyPricing).length > 0 && val[0]){
            if (!setting.dates[value][val[0].room]) {
              setting.dates[value][val[0].room] = {}
            }
            setting.dates[value][val[0].room].occupancy_pricing = occupancyPricing;
          }
          
        })

      }else if(type == 7){

        let dayOfWeek = moment(value).isoWeekday();        
        let defaultAggresiveness = setting.rpg_arguments.PELL_weekday;
        
        if((dayOfWeek === 5) || (dayOfWeek  === 6)){
          defaultAggresiveness = setting.rpg_arguments.PELL_weekend
        };

        let aggressiveness = parseFloat(this.state.bulkUpdateData?.aggressiVeness);
        if (aggressiveness != defaultAggresiveness) {
          setting.dates[value] = {
            ...setting.dates[value],
            ['PELL']: aggressiveness,
          };
        } else if(setting.dates[value] && setting.dates[value].PELL){
          delete setting.dates[value].PELL;
        }
        
      }else if(type == 8){
        let leadTimeAdjustment = parseFloat(this.state.bulkUpdateData?.leadTimeAdjustment);
        let defaultLeadTime = setting.rpg_arguments.median_lead_time;
        
        if (leadTimeAdjustment != defaultLeadTime) {
          setting.dates[value] = {
            ...setting.dates[value],
            ['median_lead_time']: leadTimeAdjustment,
          };
        } else if(setting.dates[value] && setting.dates[value].median_lead_time){
          delete setting.dates[value].median_lead_time;
        } 
      }

      if (setting.dates[value] && Object.keys(setting.dates[value]).length == 0) {
        delete setting.dates[value]
      }

      return await this.setState({
        bulkUpdateList: bulkUpdateList,
        dateSetting: setting,
        bulkLoader: true,
      })

    }));
  }

  deleteIndividualDate = (value) => {
    let oldSettings = this.props.priceSettingReducer.priceSetting;
    let setting = this.state.dateSetting;
    let bulkUpdateList = this.state.bulkUpdateList;

    if (setting.dates[value]) {
      if (oldSettings.dates[value] && oldSettings.dates[value].individual_adjustment) {
        if (!bulkUpdateList.deleteIndividual.includes(value)) {
          bulkUpdateList.deleteIndividual.push(value);
        }
      }

      let individuals = [];
      bulkUpdateList.individual.map((val) => {
        if (value != val.date) {
          individuals.push(val);
        }
      })
      bulkUpdateList.individual = individuals;

      delete setting.dates[value].individual_adjustment;
    }

    this.setState({
      bulkUpdateList: bulkUpdateList,
      dateSetting: setting,
      bulkLoader: true,
      isGrey: false,
    },
      () => {
        this.stopNavigation(true)
      })
  }

  deleteSkipDate = (value) => {
    let oldSettings = this.props.priceSettingReducer.priceSetting;
    let setting = this.state.dateSetting;
    let bulkUpdateList = this.state.bulkUpdateList;

    if (setting.dates[value]) {
      if (oldSettings.dates[value] && oldSettings.dates[value].skip_date) {
        if (!bulkUpdateList.deleteSkip.includes(value)) {
          bulkUpdateList.deleteSkip.push(value)
        }
      }

      let skipDates = [];
      bulkUpdateList.skipDate.map((val) => {
        if (value != val.date) {
          skipDates.push(val);
        }
      })

      bulkUpdateList.skipDate = skipDates;
      delete setting.dates[value].skip_date;
    }

    this.setState({
      bulkUpdateList: bulkUpdateList,
      dateSetting: setting,
      bulkLoader: true,
      isGrey: false,
    },
      () => {
        this.stopNavigation(true)
      })
  }


  validateRoomSettingJson = () => {
    let setting = this.state.dateSetting;

    let bulkError = [];

    let min = setting.default[this.state.BulkRoomId].min_price;

    if (this.state.bulkUpdateData.minPrice) {
      min = this.state.bulkUpdateData.minPrice;
    }

    if ((this.state.bulkUpdateData.maxPrice != undefined) && ((this.state.bulkUpdateData.maxPrice === "") || (this.state.bulkUpdateData.maxPrice == Math.round(this.state.bulkUpdateData.maxPrice)))) {
    } else {
      bulkError.push('maxPrice');
    }

    if ((this.state.bulkUpdateData.minPrice != undefined) && ((this.state.bulkUpdateData.minPrice === "") || (this.state.bulkUpdateData.minPrice == Math.round(this.state.bulkUpdateData.minPrice)))) {
    } else {
      bulkError.push('minPrice');
    }

    if (this.state.bulkUpdateData.maxPrice && parseInt(this.state.bulkUpdateData.maxPrice) < parseInt(min)) {
      bulkError.push('maxPriceLow');
    }

    this.setState({
      bulkError
    })

    return bulkError;
  }

  setRoomBulkData = async (data) => {
    await Promise.all(data.map(async (value) => {
      let setting = this.state.dateSetting;

      let defaultData = setting.default[this.state.BulkRoomId];
      let min = setting.default[this.state.BulkRoomId].min_price;
      let max = setting.default[this.state.BulkRoomId].max_price;
      let adjVsRef = setting.default[this.state.BulkRoomId].adjustment_to_reference_room
      let isClosed = false;

      let bulkUpdateList = {
        individual: [],
        deleteIndividual: [],
        skipDate: [],
        deleteSkip: [],
      }

      if (setting.dates[value] && setting.dates[value][this.state.BulkRoomId]) {
        if (setting.dates[value][this.state.BulkRoomId].min_price) {
          min = setting.dates[value][this.state.BulkRoomId].min_price;
        }
        if (setting.dates[value][this.state.BulkRoomId].max_price) {
          max = setting.dates[value][this.state.BulkRoomId].max_price;
        }

        if (setting.dates[value][this.state.BulkRoomId].adjustment_to_reference_room) {
          adjVsRef = setting.dates[value][this.state.BulkRoomId].adjustment_to_reference_room;
        }
        if (setting.dates[value][this.state.BulkRoomId].closed_rooms) {
          isClosed = setting.dates[value][this.state.BulkRoomId].closed_rooms;
        }
      }

      if (this.state.bulkUpdateData.minPrice) {
        min = parseFloat(this.state.bulkUpdateData.minPrice);
      }
      if (this.state.bulkUpdateData.maxPrice) {
        max = parseFloat(this.state.bulkUpdateData.maxPrice);
      }
      if (this.state.bulkUpdateData.adjVsRef) {
        adjVsRef = parseFloat(this.state.bulkUpdateData.adjVsRef);
      }

      if (!setting.dates[value]) {
        setting.dates[value] = {};
      }
      if (this.state.price_type_value) {
        setting.dates[value].individual_adjustment = {
          name: null,
          percentage: this.state.price_type_value
        }
        bulkUpdateList.individual.push({
          date: value,
          name: null,
          percentage: this.state.price_type_value
        })
      }

      if (defaultData.min_price == min && defaultData.max_price == max && defaultData.adjustment_to_reference_room.toFixed(0) == adjVsRef && !isClosed) {
        if (setting.dates[value]) {
          delete setting.dates[value][this.state.BulkRoomId];
        }
      } else {
        let addData = {}

        if (setting.dates[value][this.state.BulkRoomId]) {
          addData = {
            ...setting.dates[value][this.state.BulkRoomId],
          }
        }

        if (defaultData.min_price != min) {
          addData = {
            ...addData,
            min_price: min
          }
        } else if (addData.min_price) {
          delete addData.min_price
        }
        if (defaultData.max_price != max) {
          addData = {
            ...addData,
            max_price: max
          }
        } else if (addData.max_price) {
          delete addData.max_price
        }
        if (defaultData.adjustment_to_reference_room.toFixed(0) != adjVsRef) {
          addData = {
            ...addData,
            adjustment_to_reference_room: adjVsRef
          }
        } else if (addData.adjustment_to_reference_room) {
          delete addData.adjustment_to_reference_room
        }

        setting.dates[value] = {
          ...setting.dates[value],
          [this.state.BulkRoomId]: {
            ...addData
          }
        };
      }

      return await this.setState({
        dateSetting: setting,
        bulkLoader: true,
        bulkUpdateList
      })
    }));
  }

  removeAdjustments = () => {
    var a = moment(this.state.bulkStartDate).format('YYYY-MM-DD');
    var b = moment(this.state.bulkEndDate).format('YYYY-MM-DD');

    let dates = [];
    for (var m = moment(a); m.isSameOrBefore(b); m.add(1, 'days')) {
      if (this.state.bulkWeekdays.includes(m.format('dddd'))) {
        dates.push(m.format('YYYY-MM-DD'))
      }
    }

    this.removeAdjustmentsDates(dates).then(() => {
      this.saveTabledata(true);
    })
  }

  removeAdjustmentsDates = async (data) => {
    await Promise.all(data.map(async (value) => {
      let setting = this.state.dateSetting;
      let type = this.state.deleteModalData;

      if (setting.dates[value]) {
        if (type.perAjd && setting.dates[value].individual_adjustment != undefined) {
          delete setting.dates[value].individual_adjustment;
        }
        if (type.aggressiVeness && setting.dates[value].PELL != undefined) {
          delete setting.dates[value].PELL;
        }
        if (type.leadTimeAdjustment && setting.dates[value].median_lead_time != undefined) {
          delete setting.dates[value].median_lead_time;
        }
        this.props.roomList.roomList.map(val => {

          if (type.minMax) {
            if (setting.dates[value][val.id] && setting.dates[value][val.id].min_price != undefined) {
              delete setting.dates[value][val.id].min_price;
            }
            if (setting.dates[value][val.id] && setting.dates[value][val.id].max_price != undefined) {
              delete setting.dates[value][val.id].max_price;
            }
          }

          if (type.fix) {
            if (setting.dates && setting.dates[value] && setting.dates[value].skip_date) {
              delete setting.dates[value].skip_date
            }
            if (setting.dates[value][val.id] && setting.dates[value][val.id].fix_price != undefined) {
              delete setting.dates[value][val.id].fix_price;
            }
          }

          if (type.der) {
            if (setting.dates[value][val.id] && setting.dates[value][val.id].adjustment_to_reference_room != undefined) {
              delete setting.dates[value][val.id].adjustment_to_reference_room;
            }
          }

          if (type.close) {
            if (setting.dates[value][val.id] && setting.dates[value][val.id].closed_rooms != undefined) {
              delete setting.dates[value][val.id].closed_rooms;
            }
          }

          if (type.occupancy) {
            if (setting.dates[value][val.id] && setting.dates[value][val.id].occupancy_pricing != undefined) {
              delete setting.dates[value][val.id].occupancy_pricing;
            }
          }


          if (setting.dates[value][val.id] && Object.keys(setting.dates[value][val.id]).length == 0) {
            delete setting.dates[value][val.id]
          }

        })
      }

      if (setting.dates[value] && Object.keys(setting.dates[value]).length == 0) {
        delete setting.dates[value]
      }


      return await this.setState({
        dateSetting: setting,
        bulkLoader: true,
      })

    }));
  }

  defaultBulkData = () => {
    let setting = { ...this.state.dateSetting };
    let data = this.state.bulkUpdateData;

    let min_price_array = {};
    let max_price_array = {};
    let derivation_array = {};
    let fixprice_array = {}
    let closedroom_array = {}

    Object.keys(setting.default).map((key) => {
      min_price_array[key] = setting.default[key].min_price;
      max_price_array[key] = setting.default[key].max_price;
      derivation_array[key] = Math.round(setting.default[key].adjustment_to_reference_room);
      fixprice_array[key] = "";
      closedroom_array[key] = "";
    })

    if (this.state.bulkEditType == 2) {
      data.maxPrice = max_price_array;
      data.minPrice = min_price_array;
    } else if (this.state.bulkEditType == 3) {
      data.adjVsRef = derivation_array;
    } else if (this.state.bulkEditType == 4) {
      data.fixedPrice = fixprice_array;
    } else if (this.state.bulkEditType == 5) {
      data.closedRooms = closedroom_array;
    }else if (this.state.bulkEditType == 6) {
      let defaultValues = {};
      Object.keys(this.state.occupancyPrices).map(key => {
         defaultValues[key] = this.state.occupancyPrices[key].map(val =>{
            let defaultOccupancy = this.state.defaultOccupancy[key].find(row => row.occupancy === val.occupancy);
            if(defaultOccupancy){
              val.derivation = defaultOccupancy.derivation;
            }
            return val;
        })
      })
      this.setState({
        occupancyPrices: defaultValues
      })
    }

    this.setState({
      bulkUpdateData: data
    })

  }

  bulkDateAdjModalToggle = () => {

    let startOfMonth = moment(this.state.monthChangeDate).startOf('month').format('YYYY-MM-DD');
    let endOfMonth = moment(this.state.monthChangeDate).endOf('month').format('YYYY-MM-DD');

    let date = moment(startOfMonth)
    let dateDiff = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).diff(date, 'days');
    if (dateDiff > 0) {
      startOfMonth = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD');
    }
    let setting = { ...this.state.dateSetting };

    let min_price_array = {};
    let max_price_array = {};
    let derivation_array = {};
    let fixedPrice = {};
    let closedRooms = {};

    Object.keys(setting.default).map((key) => {
      min_price_array[key] = "";
      max_price_array[key] = "";
      derivation_array[key] = "";
      fixedPrice[key] = "";
      closedRooms[key] = ""
    })

    this.setState({
      bulkError: {
        maxPrice: [],
        minPrice: [],
        fixedPrice: []
      },
      deriveFromRef: true,
      bulkStartDate: startOfMonth,
      bulkEndDate: endOfMonth,
      // isSkip: type,
      bulkUpdateData: {
        ...this.state.bulkUpdateData,
        adjVsRef: derivation_array,
        maxPrice: max_price_array,
        minPrice: min_price_array,
        fixedPrice: fixedPrice,
        dailyAdjust: "",
        closedRooms: closedRooms
      },
      bulkDateAdjModal: !this.state.bulkDateAdjModal,
      bulkWeekdays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      selectedRoomBulkEdit: this.state.selectedRoom
    })
  }

  handleNewTabs = (event, newValue) => {
    let tracking_key_name = "";
    if (newValue == 1) {
      tracking_key_name = "Overview in Price of the Day Clicked";
    } else if (newValue == 2) {
      tracking_key_name = "Make Adjustments in Price of the Day Clicked";
    } else if (newValue == 3) {
      tracking_key_name = `${this.state.room_apartment_space_name} Overview in Price of the Day Clicked`;
    } else if (newValue == 4) {
      tracking_key_name = "Pricing Strategy in Price of the Day Clicked";
    } else if (newValue == 5) {
      tracking_key_name = "OTA Insight in Price of the Day Clicked";
    }
    this.setState({
      modalTab: newValue
    })
  };

  bulkRoomModalToggle = (roomId, type) => {

    let startOfMonth = moment(this.state.monthChangeDate).startOf('month').format('YYYY-MM-DD');
    let endOfMonth = moment(this.state.monthChangeDate).endOf('month').format('YYYY-MM-DD');

    let date = moment(startOfMonth)
    let dateDiff = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).diff(date, 'days');
    if (dateDiff > 0) {
      startOfMonth = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD');
    }

    this.setState({
      bulkStartDate: startOfMonth,
      bulkEndDate: endOfMonth,
      isSkip: type,
      bulkError: {},
      BulkRoomId: roomId ? roomId : this.state.BulkRoomId,
      bulkUpdateData: {
        closedRooms: '',
        skipName: '',
        fixedPrice: '',
        dailyAdjust: '',
        minPrice: '',
        maxPrice: '',
        avgPrice: roomId ? this.state.dateSetting.default[roomId].avg_price : '',
        adjVsRef: '',
      },
      bulkWeekdays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      bulkRoomModal: !this.state.bulkRoomModal
    })
  }

  calculateDerivePrice = (price, name, roomId, hideLoader) => {

    let setting = JSON.stringify(this.state.dateSetting);

    let reqParams = {
      price: parseFloat(price)
    }

    if(cancelTokenSource){
      cancelTokenSource.cancel();
    }    

    cancelTokenSource = axios.CancelToken.source();

    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
    axios.post(Server.API + 'hotel/room/derived/', reqParams, { cancelToken: cancelTokenSource.token } )
      .then((resp) => {
        if (resp.data) {
          this.setState({
            bulkUpdateData: {
              ...this.state.bulkUpdateData,
              [name]: {
                ...this.state.bulkUpdateData[name],
                ...resp.data
              }
            }
          }, () => {
            if (!this.state.bulkDateAdjModal) {
              setting = JSON.parse(setting);
              this.getNewAdjustPrices(this.state.bulkUpdateData, roomId, setting, hideLoader);
            }else{
              this.setState({ 
                renderDataLoad: false,
                calLoader: false,
                disableSave: false
              })
            }
          })
        }
      })
      .catch((err)=>{
        console.log(err)
      })
      

  }

  calculateDerivePrice1 = (price, name, roomId, hideLoader) => {

    let setting = JSON.stringify(this.state.dateSetting);

    let reqParams = {
      price: parseFloat(price)
    }


    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
    axios.post(Server.API + 'hotel/room/derived/', reqParams )
      .then((resp) => {
        if (resp.data) {
          this.setState({
            bulkUpdateData: {
              ...this.state.bulkUpdateData,
              [name]: {
                ...this.state.bulkUpdateData[name],
                ...resp.data
              }
            }
          }, () => {
            if (!this.state.bulkDateAdjModal) {
              setting = JSON.parse(setting);
              this.getNewAdjustPrices(this.state.bulkUpdateData, roomId, setting, hideLoader);
            }else{
              this.setState({ 
                renderDataLoad: false,
                calLoader: false,
                disableSave: false
              })
            }
          })
        }
      })
      .catch((err)=>{
        console.log(err)
      })
      

  }

  saveTabledata = (isUpdate) => { //2
    this.setState({
      updatePmsStart: this.state.bulkStartDate,
      updatePmsEnd: this.state.bulkEndDate,
    })

    // let setting = this.state.dateSetting;

    // Object.keys(setting.dates).map(date => {
    //   if(Object.keys(setting.dates[date]).length > 0){
    //     Object.keys(setting.dates[date]).map(key =>{
    //       if(isInteger(key)){
    //         max_price:800
    //         min_price:10
    //         adjustment_to_reference_room
    //         occupancy_pricing:{:100}
    //       }

    //       if(key == "PELL"){
    //         let dayOfWeek = moment(date).isoWeekday();        
    //         let defaultAggresiveness = setting.rpg_arguments.PELL_weekday;
            
    //         if((dayOfWeek === 6) || (dayOfWeek  === 7)){
    //           defaultAggresiveness = setting.rpg_arguments.PELL_weekend
    //         };

    //         if(setting.dates[date][key].PELL == defaultAggresiveness){
    //           delete setting.dates[date][key].PELL
    //         }
    //       }

    //       if(key == "median_lead_time"){
    //         median_lead_time:30
    //       }
          
    //     })
    //   }
      
    // })

    let requestData = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      dateSetting: this.state.dateSetting,
    };

    calendarEvents.calendarAdjustmentUpload(this, requestData, isUpdate);

    setTimeout(() => {
      this.setState({
        isGrey: true,
      })
      this.props.getRunPriceFlags({ token: this.props.hotelAuthReducer.hotelDetails.token });
    }, 2000);

    this.stopNavigation(false)

  }

  toggleSettingAlert = () => {
    this.setState({
      settingAlert: !this.state.settingAlert,
    })
  }

  // downloadTabledata = () => {
  //   var content = JSON.stringify(this.props.priceSettingReducer.priceSetting);
  //   const element = document.createElement("a");
  //   const file = new Blob([content], { type: 'text/plain' });
  //   element.href = URL.createObjectURL(file);
  //   element.download = "SettingJson.txt";
  //   document.body.appendChild(element); // Required for this to work in FireFox
  //   element.click();
  // }

  handlederiveFromRef = () => {
    this.setState({
      deriveFromRef: !this.state.deriveFromRef
    })
  }

  handlebulkWeekdays = (name) => event => {
    var arr = this.state.bulkWeekdays;
    if (this.state.bulkWeekdays.indexOf(name) > -1) {
      var index = arr.indexOf(name);
      if (index > -1) {
        arr.splice(index, 1);
      }
    } else {
      arr.push(name)
    }
    this.setState({
      bulkWeekdays: arr
    })
  }

  changeTargetOccupancy = () => {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      target_occupancy: this.state.temp_target_occupancy,
      hideNotification: true,
    }
    this.props.updateHotel(requestParam);
    this.targetOccupancyToggle();
  }

  handleTargetOccupancy = () => event => {
    this.setState({
      temp_target_occupancy: event.target.value
    })
  }

  toggleWeeklyModal = (day) => {
    let weekData = {};
    if (day) {
      weekData = this.state.weeklyData[day];
    }

    this.setState({
      modalWeekData: { ...weekData, day: day },
      weeklyModal: !this.state.weeklyModal
    })
  }

  handleWeeklyData = () => event => {
    let day = this.state.modalWeekData.day;
    let weekData = this.state.weeklyData[day];
    weekData.standard = event.target.value;

    this.setState({
      modalWeekData: { ...weekData, day: day },
    })
  }

  saveWeeklyData = (requestParams) => {
    let setting = this.state.dateSetting;
    setting.adjustment.weekday = requestParams.allData;

    let latestJson = ValidateBaseSettings({ dateSetting: setting });

    let reqParams = {
      settings: JSON.stringify(latestJson.dateSetting),
    };

    this.setState({
      monthWeekLoader: true
    })

    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
    axios.post(Server.API + 'hotel/pricing-settings/', reqParams)
      .then((response) => {
        this.getCalendarSettingJson();
        this.props.getPricingSettings({ token: this.props.hotelAuthReducer.hotelDetails.token })

        let start_date = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD');
        let end_date = moment(start_date).add('365', 'd').format('YYYY-MM-DD');


        let requestParam = {
          json_settings: JSON.stringify(latestJson.dateSetting),
          is_write_to_cache: true,
          start_date: start_date,
          end_date: end_date
        }

        axios.post(Server.API + 'hotel/adjustment/individual/apply/', requestParam)
          .then((response) => {
            this.setState({
              monthWeekLoader: false
            })
            this.getLattestPricing()
          }).catch((error) => {
            this.setState({
              monthWeekLoader: false
            })
            let requestData = {
              msg: 'Error while Applying the Changes',
              is_success: false,
            }
            this.props.putNotify(requestData)
          })
      })
  }

  toggleMonthlyModal = () => {
    let monthData = this.state.monthlyData[moment(this.state.monthChangeDate).format('MMMM')];

    this.setState({
      modalMonthData: monthData,
      mothlyModal: !this.state.mothlyModal
    })
  }

  handleMonthlyData = () => event => {
    let monthData = this.state.monthlyData[moment(this.state.monthChangeDate).format('MMMM')];
    monthData.standard = event.target.value;

    this.setState({
      modalMonthData: monthData,
    })
  }

  saveMonthlyData = (requestParams) => {
    let setting = this.state.dateSetting;
    setting.adjustment.monthly = requestParams.allData;

    let latestJson = ValidateBaseSettings({ dateSetting: setting });

    let reqParams = {
      settings: JSON.stringify(latestJson.dateSetting),
    };

    this.setState({
      monthWeekLoader: true
    })

    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
    axios.post(Server.API + 'hotel/pricing-settings/', reqParams)
      .then((response) => {
        this.getCalendarSettingJson();
        this.props.getPricingSettings({ token: this.props.hotelAuthReducer.hotelDetails.token })

        let start_date = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD');
        let end_date = moment(start_date).add('365', 'd').format('YYYY-MM-DD');


        let requestParam = {
          json_settings: JSON.stringify(latestJson.dateSetting),
          is_write_to_cache: true,
          start_date: start_date,
          end_date: end_date
        }

        axios.post(Server.API + 'hotel/adjustment/individual/apply/', requestParam)
          .then((response) => {
            this.setState({
              monthWeekLoader: false
            })
            this.getLattestPricing()
            this.toggleMonthlyModal();
          }).catch((error) => {
            this.setState({
              monthWeekLoader: false
            })
            this.toggleMonthlyModal();
            let requestData = {
              msg: 'Error while Applying the Changes',
              is_success: false,
            }
            this.props.putNotify(requestData)
          })
      })
  }

  handlePriceType = (event) => {
    let value = event.target.value;
    let roomData = { ...this.state.pricing_result[this.state.modalData.date][this.state.selectedRoom] };
    let modalData = { ...this.state.modalData };
    modalData.price = roomData.price;
    modalData.staticPrice = roomData.price;
    modalData.change_user = roomData.change_user;
    this.setState({
      modalData: modalData
    })

    let price_type_value = '';
    if ((this.state.price_type == "fix" || this.state.price_type == "skip") && (value == "fix" || value == "skip")) {
      price_type_value = this.state.price_type_value;
    }

    this.setState({
      greySaveUpload: false,
      price_type: value,
      price_type_value: price_type_value,
    })
  }

  cancelPriceType = () => calendarEvents.cancelPriceType(this, settingJsonData);

  deleteSkipAdjust = () => calendarEvents.deleteSkipAdjust(this, settingJsonData);

  handlePriceTypeValue = (event) => {
    this.setState({
      greySaveUpload: false,
      price_type_value: event.target.value,
    })
  }

  updateTypePricing() {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      last_run_pricing_type: this.state.last_run_pricing_type,
      hideNotification: true,
    }
    this.props.updateHotel(requestParam);
  }

  handlePricingType = (value) => {

    value = value ? value : this.state.last_run_pricing_type
    let start_date = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD');
    let end_date = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD');

    if (value == '1') {

      start_date = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD');
      end_date = moment(start_date).add(2, 'weeks').format('YYYY-MM-DD');

    } else if (value == '2') {

      start_date = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD');
      end_date = moment(start_date).add(1, 'month').format('YYYY-MM-DD');

    } else if (value == '3') {

      start_date = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD');
      end_date = moment(start_date).add(95, 'days').format('YYYY-MM-DD');

    } else if (value == '4') {

      start_date = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD');
      end_date = moment(start_date).add(6, 'months').format('YYYY-MM-DD');

    } else if (value == '5') {

      start_date = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD');
      end_date = moment(start_date).add('365', 'd').format('YYYY-MM-DD');

    } else if (value == '6') {

      start_date = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD');
      end_date = moment(start_date).add(18, 'months').format('YYYY-MM-DD');

    }

    this.setState({
      start_date,
      end_date,
      updatePmsStart: start_date,
      updatePmsEnd: end_date,
      last_run_pricing_type: value,
    })
  }
  handleAllPricingUpload = (e) => {
    e.target.checked ?
      this.setState({
        threashold: true
      }) :
      this.setState({
        threashold: false
      })
  }
  chnageCalendarType = (value) => {
    this.setState({
      calendarType: value,
    },
      () => {
        this.getCalendarData()
      })
  }

  changePickup = (value) => {
    this.setState({
      pickupType: value,
    },
      () => {
        this.getCalendarData()
      })
  }


  chnageAdjType = (value) => {
    // if (value != 5) {
    //   let allPricing = this.props.priceSettingReducer.priceSetting.hotel.all_room_pricing;
    //   if (!allPricing) {
    //     let selectedRoom = this.props.roomList.roomList.filter(val => val.is_reference_room)[0].id;
    //     this.setState({
    //       selectedRoom: selectedRoom ? selectedRoom : this.state.selectedRoom
    //     })
    //   }
    // }
    this.setState({
      adjType: value,      
    },
      () => {        
        this.getCalendarData()
      })

    let tracking_key_name = '';
    if (this.state.adjType == 5) {
      tracking_key_name = `Calendar Adjustment Type Dropdown – Closed ${this.state.room_apartment_space_name}s Clicked`;
    } else if (this.state.adjType == 4) {
      tracking_key_name = "Calendar Adjustment Type Dropdown – Fix Prices Clicked";
    } else if (this.state.adjType == 3) {
      tracking_key_name = `Calendar Adjustment Type Dropdown – ${this.state.room_apartment_space_name} Derivation Clicked`;
    } else if (this.state.adjType == 2) {
      tracking_key_name = "Calendar Adjustment Type Dropdown – Minimum and Maximum Prices Clicked";
    } else {
      tracking_key_name = "Calendar Adjustment Type Dropdown – Percent Adjustment Clicked";
    }
  }

  fullYearGraph = (val) => {
    if(val == true){
      this.setState({
        showFullYearGraph: val,
      },()=> this.getMonthData(this.state.monthChangeDate, true))
    }else{
      this.setState({
        showFullYearGraph: val,
      })
    } 
  }

  

  toggleBulkUpdate = () => {
    this.setState({
      openBulkUpdate: !this.state.openBulkUpdate,
    })
  }

  updatebulkValue = () => event => {

  }

  updateBulkDate = (name) => date => {
    this.setState({
      [name]: moment(date).format('YYYY-MM-DD'),
    })
  }

  updateBulkUpdateType = () => event => {
    let value = event.target.value;
    this.setState({
      bulkUpdateType: value
    })
  }

  updatebulkUpdateRooms = () => event => {
    let value = event.target.value;
    this.setState({
      bulkUpdateRooms: value
    })
  }

  udpateBulkUpdateField = () => event => {
    let value = event.target.value;
    this.setState({
      bulkUpdateField: value
    })
  }

  updateIndividual = (date, type, value, roomId) => {
    let dateSetting = this.state.dateSetting;
    if (type == 'min_price') {
      dateSetting.dates[date][roomId][type] = value;
      this.setState({
        dateSetting: dateSetting
      })
    }
    if (type == 'max_price') {
      dateSetting.dates[date][roomId][type] = value;
      this.setState({
        dateSetting: dateSetting
      })
    }
    if (type == 'adjustment_to_reference_room') {
      dateSetting.dates[date][roomId][type] = value;
      this.setState({
        dateSetting: dateSetting
      })
    }
  }

  updateTable = () => {
    let startDate = this.state.bulkStartDate;
    let endDate = this.state.bulkEndDate;

    let dateSetting = this.state.dateSetting;

    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');

    if (this.state.bulkUpdateType == 2) {
      while (currDate.add(1, 'days').diff(lastDate) <= 0) {
        let date = currDate.format('YYYY-MM-DD');
        let roomId = this.state.bulkUpdateRooms;
        dateSetting.dates[date][roomId][this.state.bulkUpdateField] = 50;
      }
      this.setState({
        dateSetting: dateSetting
      })
    }

    if (this.state.bulkUpdateType == 1) {
      if (this.state.bulkUpdateField == 'monthly' || this.state.bulkUpdateField == 'weekday' || this.state.bulkUpdateField == 'single') {
        while (currDate.add(1, 'days').diff(lastDate) <= 0) {
          let date = currDate.format('YYYY-MM-DD');
          dateSetting.dates[date].internal_date_adjustments[this.state.bulkUpdateField] = 50;
        }
        this.setState({
          dateSetting: dateSetting
        })
      }
    }
  }

  toggleupdatePmsType = () => {
    this.setState({
      skipUpdate: (this.props.hotelDetail.update_to_pms_skip_type ? this.props.hotelDetail.update_to_pms_skip_type : 0),
      updatePmsType: (this.props.hotelDetail.update_to_pms_type ? this.props.hotelDetail.update_to_pms_type : 7),
      openUpdatePmsType: !this.state.openUpdatePmsType,
    })
  }

  toggleAutoUpdateConfirm = () => {
    this.setState({
      openAutoUpdateConfirm: !this.state.openAutoUpdateConfirm
    })
  }

  toggleDateAdjustment = () => {
    this.setState({
      openDateAdjustment: !this.state.openDateAdjustment
    })
  }

  toggleSkipDateModal = (data) => {
    this.setState({
      skipData: data ? data : { date: this.state.modalData.date, fixed_price: null, name: "" },
      openSkipDate: !this.state.openSkipDate,
    })
  }

  handleSkipDate = (name) => event => {
    this.setState({
      skipData: {
        ...this.state.skipData,
        [name]: event.target.value,
      }
    })
  }
  changeNotesDate = (name) => dateValue => {
      this.setState({
        [name]: dateValue,
      })
  }
  
  changeNotesStartEndDate= () => event => {
    this.setState({
      notesStartEndDate:true,
      noteEditEnable:false,
      editNotesValue:{}
    })
  }

  changePriceNote = () => event => {
    this.setState({
      priceNote: event.target.value,
      greySaveUpload: false
    })
  }

  getHotelDetails = () => {
    let requestData = {
      token: this.props.hotelAuthReducer.hotelDetails.token
    };
    this.props.getHotelDetail(requestData);
  }

  happyPricingToggle = () => {
    this.setState({
      happyPricing: !this.state.happyPricing
    })
  }

  toggleUpdatePms = () => {
    this.setState({
      showUpdatePrice: !this.state.showUpdatePrice,
    })
    this.handlePricingType()
  }

  toggleAllEvents = () => {
    this.setState({
      allEvents: !this.state.allEvents,
    })
  }

  handle_file = event => {
    this.setState({
      file: event.target.files[0]
    })
  }

  chnageInventary = (index) => event => {
    let jsx = this.state.inventory_data;
    jsx[index].number_of_rooms = event.target.value;
    this.setState({
      inventory_data: jsx
    })
  }

  plusValue = (index) => {
    let jsx = this.state.inventory_data;
    jsx[index].number_of_rooms = jsx[index].number_of_rooms + 1;
    this.setState({
      inventory_data: jsx
    })
  }

  minusValue = (index) => {
    let jsx = this.state.inventory_data;
    jsx[index].number_of_rooms = jsx[index].number_of_rooms - 1;
    this.setState({
      inventory_data: jsx
    })
  }

  getLattestPricing = () => {
    this.setState({
      roomcount: 0,
    });

    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
    axios.get(Server.API + 'pricingalgorithm/cache-price/')
      .then((response) => {
        if (response.data) {
          var received_msg = response.data;

          if (received_msg && received_msg.currency) {
            this.setState({
              currency: received_msg.currency,
            })
          }

          if (received_msg && received_msg.prices.data && received_msg.prices.data) {
            this.setState({
              pricing_result: received_msg.prices.data
            })
          }

          if (received_msg && received_msg.pickup_boost_prices) {            
            this.setState({
              boostPrice: received_msg.pickup_boost_prices
            })
          }          

          if (received_msg && received_msg.prices && received_msg.prices.performance) {
            this.setState({
              performance: received_msg.prices.performance
            })
          }

          if (received_msg && received_msg.prices.data && received_msg.prices.data && !this.state.stopCachedPrice) {
            let startDate = moment(this.state.monthChangeDate).startOf('month').format('YYYY-MM-DD');
            let PrevMonthLastWeek = moment(startDate).subtract(7, 'days').format('YYYY-MM-DD');
            let nextMonthFirstWeek = moment(startDate).add(38, 'days').format('YYYY-MM-DD');

            let loadAll = this.state.viewType == "priceGraph" ? true : false;
           
            let calendar_data = Object.keys(received_msg.prices.data)
              .filter(key => loadAll || (moment(nextMonthFirstWeek).isAfter(key) && moment(PrevMonthLastWeek).isBefore(key)))
              .sort((a, b) => new Date(a) - new Date(b))
              .reduce((obj,key) => { obj[key] = received_msg.prices.data[key]; return obj;},{});
            
            let jsx = [];
            let jsxKey = [];

            if (received_msg.prices.expected_occupancy) {
              this.setState({
                expectedOccupancy: received_msg.prices.expected_occupancy
              })
            }

            if (received_msg.prices.latest_actual_occupancy) {
              this.setState({
                monthlyOccupancy: received_msg.prices.latest_actual_occupancy
              })
            }

            if (received_msg.prices.average_price_week_month) {
              this.setState({
                averageWeekMonth: received_msg.prices.average_price_week_month
              })
            }

            Object.keys(calendar_data).map((key) => {
              if (!received_msg.prices.data[key].system_skip) {
                Object.keys(received_msg.prices.data[key]).map((roomkey) => {
                  if (!jsxKey.includes(roomkey)) {
                    jsx[roomkey] = [];
                    jsxKey.push(roomkey);
                  }
                  if (!received_msg.prices.data[key].error) {
                    received_msg.prices.data[key][roomkey] = {
                      date: key,
                      total_inventory: received_msg.prices.data[key]['property'].inventory_remaining,
                      total_closed: received_msg.prices.data[key]['property'].closed_rooms,
                      ...received_msg.prices.data[key]['property'],
                      ...received_msg.prices.data[key][roomkey],
                      inventory_remaining: received_msg.prices.data[key]['property'].all_rooms_inventories ? received_msg.prices.data[key]['property'].all_rooms_inventories[roomkey] : null,                      
                    };
                    jsx[roomkey].push(received_msg.prices.data[key][roomkey])
                  }
                  return null;
                })
              } else {
                let skipData = {};
                this.props.roomList.roomList.map((rooms, index) => {
                  if (!jsxKey.includes(rooms.id.toString())) {
                    jsx[rooms.id] = [];
                    jsxKey.push(rooms.id.toString());
                  }
                  jsx[rooms.id].push(received_msg.prices.data[key])
                })
              }
              return null;
            });
            console.log(jsx)
            this.setState({
              calendarData: jsx,
            },
              () => {
                this.getCalendarData();
                setTimeout(() => {
                 this.EnableDetectionModal();
                }, 5000);                
              }
            )
          }
        }
      }).catch((err) => {
        console.log(err)
      })
  }

  downloadCsvFormat = () => {
    this.setState({
      formatLoader: true
    })
    this.getInginiusCsv(this.state.selectedRoom);
  }

  getPricingCsv = (startDate, endDate) => {

    var requestData = {}

    let end_date = ""
    if (this.state.featuresEnabled.includes(23)){
      end_date = endDate ? endDate : moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).add('18', 'M').format('YYYY-MM-DD');
    } else {
      end_date = endDate ? endDate : moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).add('1', 'y').format('YYYY-MM-DD');
    }

    requestData = {
      "command": "create_diagnostic_file",
      start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'),
      end_date: end_date,
    }

    this.setState({
      roomcount: 0,
    });

    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
    axios.post(Server.API + 'pricingalgorithm/diagnostic-file/', requestData)
      .then((response) => {
        if (response.data) {
          var received_msg = response.data;
          if (received_msg.url) {
            this.setState({
              csvUrl: Server.API + received_msg.url + this.props.hotelAuthReducer.hotelDetails.token
            })
          }
        }
      })

  }

  getInginiusCsv = (roomId, startDate, endDate) => {

    var requestData = {}
    requestData = {
      "command": "create_inngenius_csv",
      room_id: roomId,
      start_date: startDate ? startDate : moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'),
      end_date: endDate ? endDate : moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).add('1', 'y').format('YYYY-MM-DD'),
      format_type: parseFloat(this.state.csvFormat)
    }

    this.setState({
      roomcount: 0,
    });

    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
    axios.post(Server.API + 'pricingalgorithm/pms-price-csv/', requestData)
      .then((response) => {
        this.setState({
          formatLoader: false
        })
        if (response.data) {
          var received_msg = response.data;
          if(received_msg.url){
            var filename = received_msg.url.split('/').pop()
            filename = filename.split('?')[0];

            var link = document.createElement("a");
            link.download = filename;
            link.href = received_msg.url;
            link.click();
          }          
        }
      }).catch((err) => {
        this.setState({
          formatLoader: false
        })
      })

  }

  renderSingleSkip = (val, fontColor, note, dateDiff, colours) => {

    const { classes } = this.props;
    const { t } = this.props;
    let skip_data=val.skip_price
    let setting = this.state.dateSetting;

    let roomId = val.room_id
    let roomData = this.props.roomList.roomList.filter(val => val.id == roomId)[0];
    let isToday = false;

    let original_price = val.original_price;

    let closedRooms = 0;
    if (setting.dates && setting.dates[val.date] && setting.dates[val.date][roomId] && setting.dates[val.date][roomId].closed_rooms) {
      closedRooms = setting.dates[val.date][roomId].closed_rooms;
    }

    let fixedPricePerRoom;
    if(setting.dates && setting.dates[val.date] && setting.dates[val.date][roomId] && setting.dates[val.date][roomId].fix_price  ){
      fixedPricePerRoom=setting.dates[val.date][roomId].fix_price;
    }

    if(!note && this.props.eventCategoryReducer.impEvents && this.props.eventCategoryReducer.impEvents[val.date] && this.props.eventCategoryReducer.impEvents[val.date].length > 0){            
      note = this.props.eventCategoryReducer.impEvents[val.date].sort((a,b) => Math.abs(a.weighted_local_rank > b.weighted_local_rank) ? 1 : (Math.abs(b.weighted_local_rank > a.weighted_local_rank) ? -1 : 0))[0].title;            
    }

    if (val.date == moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD')) {
      isToday = true;
    }
    return (
      <>

        <div style={{ display: 'flex', flexDirection: 'column', height: "88px" }}>
          <span style={{ height: "45px", textAlign: 'right', fontSize: '14px', color: colours.priceFontColor, padding: "3px 4px 0px 0px" }}>
            <span style={{ fontWeight: "500", margin: "0px 3px", padding: "1px 3px", background: (isToday ? "#2173ea" : "none"), float: "left", color: (isToday ? "#fff" : colours.priceFontColor), borderRadius: "5px" }}>
              {moment(val.date).format('DD')}
            </span>
            {note &&
              <span style={{ fontSize: '14px', borderRadius: "3px", maxWidth: "76%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", backgroundColor: dateDiff > 0 ? "#999999" : "#475B85  ", padding: "2px 5px", float: "right", color: "#fff" }}>
                {note}
              </span>
            }
          </span>

          <span style={{ textAlign: 'right', fontSize: '14px', color: colours.priceFontColor, padding: "0px 5px" }} className="hideCalenderItemOnMobile">
            {this.state.viewType != 'adjustment' ? (original_price ? (this.state.currency.symbol + original_price) : "n.a.") : <span style={{ display: "inline-block" }} />}
            {this.state.viewType == "adjustment" && this.state.adjType == 5 &&
              <>
                {this.state.currency.symbol}
                {new Intl.NumberFormat('ja-JP').format(val.price)}
              </> 
            }
          </span>

          <span className="currrencyFonts" style={{ padding: "0px 5px", display: "flex", alignItems: "baseline"}}>
            {this.state.viewType != 'adjustment' &&
              <span className="hideCalenderItemOnMobile" style={{  fontSize: '14px', backgroundColor: colours.occColor, color : colours.occFontColor, padding: "4px", borderRadius: "5px" }}>
                {parseInt(val.occupancy) + "%"}
              </span>
            }
            {(this.state.viewType != "adjustment" || this.state.adjType != 5) &&
              <span style={{ color: colours.priceFontColor, fontSize: (this.state.viewType == "adjustment" && this.state.adjType != 3 ? "16px" : '20px'), width: "100%", textAlign: "right", fontWeight: "bold" }}>                
                {((skip_data && skip_data.fixed_price !== null)||fixedPricePerRoom) && <LockIcon style={{ fontSize: "16px", margin: "-1px 2px" }} />}
                {this.state.currency.symbol}
                {new Intl.NumberFormat('ja-JP').format(val.price)}
              </span>
            }
            {this.state.viewType == "adjustment" && this.state.adjType == 5 &&
              <span style={{ color: colours.priceFontColor, fontSize: '16px', width: "100%", textAlign: "right", fontWeight: "bold" }}>
                <span style={{ fontSize: '16px', fontWeight: "normal", marginRight: "5px" }}>
                {t("Closed")}
                </span>
                {closedRooms}
              </span>
            }
          </span>
        </div>
      </ >
    );
  }

  renderCaledarTitle = (skip_data, val, dateDiff, note, colours) => {
    const { classes } = this.props;
    const { t } = this.props;

    let setting = this.state.dateSetting;

    let roomId = val.room_id
    let isToday = false;

    let original_price = val.original_price;

    let closedRooms = 0;
    if (setting.dates && setting.dates[val.date] && setting.dates[val.date][roomId] && setting.dates[val.date][roomId].closed_rooms) {
      closedRooms = setting.dates[val.date][roomId].closed_rooms;
    }
    let fixedPricePerRoom;
    if(setting.dates && setting.dates[val.date] && setting.dates[val.date][roomId] && setting.dates[val.date][roomId].fix_price  ){
      fixedPricePerRoom=setting.dates[val.date][roomId].fix_price;
    }

    if (this.state.calendarType == 1 && this.state.viewType == "calendar") {
      original_price = val.yesterday_price;
    }

    if (this.state.calendarType == 2 && this.state.viewType == "calendar") {
      original_price = val.one_week_price;
    }

    if (val.date == moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD')) {
      isToday = true;
    }
    
    if(!note && this.props.eventCategoryReducer.impEvents && this.props.eventCategoryReducer.impEvents[val.date] && this.props.eventCategoryReducer.impEvents[val.date].length > 0){      
      note = this.props.eventCategoryReducer.impEvents[val.date].sort((a,b) => Math.abs(a.weighted_local_rank > b.weighted_local_rank) ? 1 : (Math.abs(b.weighted_local_rank > a.weighted_local_rank) ? -1 : 0))[0].title;      
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: "88px" }}>
          <span style={{ height: "45px", textAlign: 'right', fontSize: '14px', color: colours.priceFontColor, padding: "3px 4px 0px 0px" }}>

            <span style={{ fontWeight: "500", margin: "0px 3px", padding: "1px 3px", background: (isToday ? "#2173ea" : "none"), float: "left", color: (isToday ? "#fff" : colours.priceFontColor), borderRadius: "5px" }}>
              {moment(val.date).format('DD')}
            </span>
            {note &&
              <span style={{ fontSize: '14px', borderRadius: "3px", maxWidth: "76%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", backgroundColor: dateDiff > 0 ? "#999999" : "#475B85  ", padding: "2px 5px", float: "right", color: "#fff" }}>
                {note}
              </span>
            }
          </span>

          <span className="hideCalenderItemOnMobile" style={{ textAlign: 'right', fontSize: '14px', color: colours.priceFontColor, padding: "0px 5px" }}>
            {this.state.viewType != 'adjustment' ? (original_price ? (this.state.currency.symbol + original_price) : "n.a.") : <span style={{ display: "inline-block" }} />}
            {this.state.viewType == "adjustment" && this.state.adjType == 5 &&
              <>
                {(skip_data.fixed_price !== null) ? this.state.currency.symbol : ""}
                {(skip_data.fixed_price !== null) ? val.price : 'Skip'}
              </>
            }
          </span>

          <span className="currrencyFonts" style={{ padding: "0px 5px", display: "flex", alignItems: "baseline" }}>
          <span className="hideCalenderItemOnMobile" style={{  fontSize: '14px', backgroundColor: colours.occColor, color : colours.occFontColor, padding: "4px", borderRadius: "5px" }}>
              {this.state.viewType != 'adjustment' ? (parseInt(val.occupancy) + "%") : ""}
            </span>
            {(this.state.viewType != "adjustment" || this.state.adjType != 5) &&
              <span style={{ color: colours.priceFontColor, fontSize: (this.state.viewType == "adjustment" ?"16px":'20px'), width: "100%", textAlign: "right", fontWeight: "bold" }}>
                {((skip_data.fixed_price !== null )|| fixedPricePerRoom) && <LockIcon style={{fontSize: "16px",margin:"-1px 2px"}}/>}
                {(skip_data.fixed_price !== null) ? this.state.currency.symbol : ""}
                {(skip_data.fixed_price !== null) ? new Intl.NumberFormat('ja-JP').format(val.price) : 'Skip'}
              </span>
            }
            {this.state.viewType == "adjustment" && this.state.adjType == 5 &&
              <span style={{ color: colours.priceFontColor, fontSize: '16px', width: "100%", textAlign: "right", fontWeight: "bold" }}>
                <span style={{ fontSize: '16px', fontWeight: "normal", marginRight: "5px" }}>
                {t("Closed")}
                </span>
                {closedRooms}
              </span>
            }
          </span>
        </div>
      // </Tooltip >
    );
  }

  renderOccCaledarTitle = (skip_data, occupancy, val, roomsSold, fontColor, dateDiff) => {
    let width = window.screen.width;
    const { classes, t } = this.props;

    let isToday = false;
    if (val.date == moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD')) {
      isToday = true;
    }

    let roomId = val.room_id;
    let roomData = this.props.roomList.roomList.filter(val => val.id == roomId)[0];
    return (
      <Tooltip
        classes={{
          popper: "toolTipPooper",
          tooltip: classes.htmlTooltip,
        }}
        title={
          <MuiThemeProvider theme={CalendartooltipTheme}>
            <Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography>
                    {roomsSold} {t(`${this.state.room_apartment_space_name}s Sold (Total)`)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {val.expectedroomssold_adjusted ? Math.round(val.expectedroomssold_adjusted) : val.expectedroomssold_adjusted} {t(`Expected ${this.state.room_apartment_space_name}s Sold`)}
                  </Typography>
                </Grid>

              </Grid>
            </Typography>
          </MuiThemeProvider>
        }
      >
        <div>
          <div className="divFixedPrice" style={{ display: 'flex', flexDirection: 'column', height: "80px" }}>
            <span style={{ textAlign: 'right', fontSize: '12px', color: fontColor, padding: "3px 4px 0px 0px" }}>
              {(skip_data.fixed_price !== null) ? (this.state.currency.symbol + val.price) : t('Skip')}
              <span style={{ fontWeight: "500", margin: "0px 3px", padding: "1px 3px", background: (isToday ? "#2173ea" : "none"), float: "left", color: (isToday ? "#fff" : fontColor), borderRadius: "5px" }}>
                {moment(val.date).format('DD')}
              </span>
            </span>
            <span className="currrencyFonts" style={{ color: fontColor, fontSize: '30px' }} >
              {parseInt(occupancy)}
              <span className="currrencySymbol" style={{ color: fontColor, width: '52px', textAlign: 'right', position: 'absolute', fontSize: '13px', marginLeft: '-39px', marginTop: '6px' }}>
                %
            </span>
            </span>
            <span className="occupancyDisplay" style={{ display: 'block', fontSize: '12px', marginTop: "-5px" }}>
              <span style={{ padding: (width > 800 ? '2px 10px' : "0"), borderRadius: '15px', color: fontColor }}>
                {skip_data.name ? skip_data.name : ""}
              </span>
            </span>
          </div>
        </div>
      </Tooltip>
    );
  }

  getCalendarData = () => {
    let jsx = [];
    let data = this.state.calendarData;
    let setting = this.props.priceSettingReducer.priceSetting;

    Object.keys(data).map((key) => {
      jsx[key] = [];
      let roomData = this.props.roomList.roomList.filter(val => val.id == key)[0];
      data[key].map((val) => {
        val.room_id = key
        let medianRate = 0;
        let compPercentage = 0;
        val.room_id = key;

        let date = moment(val.date)
        if (this.state.competitorRates) {
          if (this.state.competitorRates[val.date]) {
            if (this.state.competitor != 0) {
              if (this.state.competitorRates[val.date][this.state.competitor]) {
                if (this.state.competitorRates[val.date][this.state.competitor]) {
                  if (this.state.competitorRates[val.date][this.state.competitor][this.state.selectedRateType]) {
                    if (this.state.competitorRates[val.date][this.state.competitor][this.state.selectedRateType][this.state.selectedMaxOccupancy]) {
                      if (this.state.competitorRates[val.date][this.state.competitor][this.state.selectedRateType][this.state.selectedMaxOccupancy][this.state.selectedRoomType]) {

                        medianRate = this.state.competitorRates[val.date][this.state.competitor][this.state.selectedRateType][this.state.selectedMaxOccupancy][this.state.selectedRoomType].rate;
                        let rpgRate = val.price;
                        compPercentage = ((rpgRate / medianRate) - 1) * 100;
                      }
                    }
                  }
                } else {
                  compPercentage = 0;
                }
              }
            } else {
              if (this.state.competitorRates[val.date].median_price) {
                if (this.state.competitorRates[val.date].median_price[this.state.selectedRateType]) {
                  if (this.state.competitorRates[val.date].median_price[this.state.selectedRateType][this.state.selectedMaxOccupancy]) {
                    if (this.state.competitorRates[val.date].median_price[this.state.selectedRateType][this.state.selectedMaxOccupancy][this.state.selectedRoomType]) {
                      medianRate = this.state.competitorRates[val.date].median_price[this.state.selectedRateType][this.state.selectedMaxOccupancy][this.state.selectedRoomType]
                      let rpgRate = val.price;
                      compPercentage = ((rpgRate / medianRate) - 1) * 100;
                    } else {
                      compPercentage = 0;
                    }
                  }
                }
              }
            }
          }
        }
      
        let utcDate = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD');
        let dateDiff = moment(utcDate).diff(date, 'days');

        let lastMonth = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).subtract(1, 'months').format('MM');

        if (dateDiff > 0 && !this.props.userSession.user.is_staff && !(moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format("MM") == moment(val.date).format("MM") || moment(val.date).format("MM") == lastMonth)) {
          return null;
        }

        if (this.state.featuresEnabled.includes(18) && dateDiff < -93) {
          return null;
        }

        let title = "";

        let roomsSold = Math.max(0,(val.number_of_rooms - val.total_inventory));
        let colours = this.getFontBGcolors(val, roomsSold);

        let hexColor = '';
        let fontColor = "";

        let note = "";

        if(this.props.priceSettingReducer.userNotesList && this.props.priceSettingReducer.userNotesList[val.date] && this.props.priceSettingReducer.userNotesList[val.date][0]){
          note = this.props.priceSettingReducer.userNotesList[val.date][0].name
        }
        if (this.state.viewType == 'pickUp') {

          title = this.renderPickUpTitle(val,  colours, roomsSold, dateDiff);

          if (val.number_of_rooms <= 0 && this.state.viewType == 'pickUp') {
            fontColor = dateDiff > 0 ? "#858585" : "#595959";
            title = this.renderCloseTitle(val, dateDiff, colours, note);            
          }
          
        } else if (this.state.viewType == 'adjustment' && this.state.adjType != 3 && this.state.adjType != 4) {
          if (val.skip_price) {
            colours.priceColor = '#181b45';
            colours.priceFontColor = "#fff";
          }
          if (dateDiff > 0) {            
            colours.priceColor = '#EBEBEB';
            colours.priceFontColor = "#858585";
            colours.occColor = '#EBEBEB';
            colours.occFontColor = "#858585";
          }
          title = this.renderAdjustTitle(val, note, colours, roomsSold, dateDiff);
        } else if (this.state.viewType == 'OTA') {
          if (dateDiff > 0) {
            colours.priceColor = '#EBEBEB';
            colours.priceFontColor = "#858585";
            colours.occColor = '#EBEBEB';
            colours.tooltipText='2';
            colours.occFontColor = "#858585";
          }
          title = this.renderOTATitle(val, note, dateDiff, colours, compPercentage, medianRate);            
        }else if (this.state.viewType == 'revenue') {
          if (dateDiff > 0) {
            colours.priceFontColor = "#858585";
            colours.occFontColor = "#858585";
          }
          title = this.renderRevenueTitle(val, note, dateDiff, colours);
        }else {
       
          if (dateDiff > 0) {
            if(this.state.viewType != 'adjustment'){
              colours.priceColor = '#EBEBEB';
              colours.priceFontColor = "#858585";
              colours.occColor = '#EBEBEB';
              colours.tooltipText='2';
              colours.occFontColor = "#858585";
            }else{
              // colours.priceColor = '#595959';
              // colours.priceFontColor = "#858585";
              // colours.occColor = '#595959';
              // colours.tooltipText='2';
              // colours.occFontColor = "#858585";
              colours.priceColor = '#EBEBEB';
              colours.priceFontColor = "#858585";
              colours.occColor = '#EBEBEB';
              colours.occFontColor = "#858585";
            }
          
          }
  
          title = this.renderTitle(val, note, dateDiff, colours);
          if (val.occupancy > 99 && this.state.viewType == 'calendar' && setting.hotel.number_of_rooms > 1) {
            // fontColor = dateDiff > 0 ? "#858585" : "#595959";
            title = this.renderFullTitle(val, dateDiff, colours, note);
          }

          if (val.number_of_rooms <= 0 && this.state.viewType == 'calendar') {            
            fontColor = dateDiff > 0 ? "#858585" : "#595959";
            title = this.renderCloseTitle(val, dateDiff, colours, note);            
          }
        }

        if (val.system_skip) {
          title = this.renderSystemSkip(val, colours, dateDiff);
        }
        
        hexColor = dateDiff > 0 ? '#EBEBEB' : '#F3EFE0';

        if (this.state.viewType == 'pickUp') {
          hexColor = dateDiff > 0 ? '#F5F5F5' : colours.occColor;
        }

        jsx[key].push({
          is_skipped: val.skip_price,
          allDay: false,
          end: date._d,
          start: date._d,
          title: title,
          backgroundColor: hexColor,
          recommended: val.price,
          pricePms: val.original_price,
          currentPrice: val.price,
          occupancy: val.occupancy,
          upperLimit: val.exproomssold_u3,
          lowerLimit: val.exproomssold_l3,
          adjustedPrice: val.expectedroomssold_adjusted,
          roomsSold: roomsSold,
          price: (this.state.currency.symbol + val.price),
          // pmsChange: (val.change_occupancy_perc ? Math.abs(val.change_pms) : ""),
          is_ref: roomData ? roomData.is_reference_room : false
        })
        return null;
      })
    });
    this.setState({
      myEventsList: jsx,
    })
  }

  renderAdjustTitle = (val, note, colours, roomsSold, dateDiff) => {

    let setting = this.props.priceSettingReducer.priceSetting;
    const { classes, t } = this.props;
    let roomId = val.room_id;
    let roomData = this.props.roomList.roomList.filter(val => val.id == roomId)[0];

    let min_price = 0;
    let max_price = 0;
    let derivation = 0;
    let avgPrice = 0;
    let adjVsRef = 0;

    let ind_adj = 0;
    
    let dayOfWeek = moment(val.date).isoWeekday();        
    let ind_aggressiveness = setting.rpg_arguments.PELL_weekday;
    
    if((dayOfWeek === 5) || (dayOfWeek  === 6)){
      ind_aggressiveness = setting.rpg_arguments.PELL_weekend
    };
    let ind_leatTimeAdjust = setting.rpg_arguments.median_lead_time;    

    let features = this.state.featuresEnabled;

    const elasticityList = [
      { id: 1, label: "Ignore Occupancy Changes", value: -1000 },
      { id: 2, label: "Very Low", value: -12 },
      { id: 3, label: "Low", value: -8 },
      { id: 4, label: "Standard", value: -5.5 },
      { id: 5, label: "High", value: -4.25 },
      { id: 6, label: "Very High", value: -3.25 },           
    ]
    
    const MedianOptions = [
      { id: 1, label: "Short", value: 14 },
      { id: 2, label: "Standard", value: 21 },
      { id: 3, label: "Long", value: 30 },                    
    ]
    let closedRooms = 0;
    let isStar = false; 
    let isDefaultLeatTimeAdjust = false;   
    let isDefaultAggr = false;   
    let isDefaultindAdj = false;   
    let isDefaultClosedRooms = false;   
    let isDefaultDerivation = false;   
    let isDefaultminPrice = false;   
    let isDefaultmaxPrice = false;   
    if (roomData) {
      derivation = setting.default[roomData.id].adjustment_to_reference_room;
      avgPrice = setting.default[roomData.id].avg_price;
      adjVsRef = setting.default[roomData.id].adjustment_to_reference_room;
      min_price = setting.default[roomData.id].min_price;
      max_price = setting.default[roomData.id].max_price;
      
      if (setting.dates && setting.dates[val.date] && setting.dates[val.date][roomData.id] && setting.dates[val.date][roomData.id].closed_rooms) {
        closedRooms = setting.dates[val.date][roomData.id].closed_rooms;
        isDefaultClosedRooms = true;
      }

      if (setting.dates && setting.dates[val.date] && setting.dates[val.date].individual_adjustment) {
        ind_adj = setting.dates[val.date].individual_adjustment.percentage;
        isDefaultindAdj = true;
      }
      if (setting.dates && setting.dates[val.date] && setting.dates[val.date].PELL) {
        ind_aggressiveness = setting.dates[val.date].PELL;        
        isDefaultAggr = true;
      }
      const aggr = elasticityList.find((val) => (val.value == ind_aggressiveness));        
      if(aggr){
        ind_aggressiveness = aggr.label;
      }
      if (setting.dates && setting.dates[val.date] && setting.dates[val.date].median_lead_time) {
        ind_leatTimeAdjust = setting.dates[val.date].median_lead_time;        
        isDefaultLeatTimeAdjust = true;
      }
      const median = MedianOptions.find((val) => (val.value == ind_leatTimeAdjust));        
      if(median){
        ind_leatTimeAdjust = median.label;
      }

      if (features.includes(1) && setting.dates && setting.dates[val.date] && setting.dates[val.date][roomId]) {
        if (setting.dates[val.date][roomId].adjustment_to_reference_room != undefined) {
          derivation = setting.dates[val.date][roomId].adjustment_to_reference_room;
          isDefaultDerivation = true;
        }

        if (setting.dates[val.date][roomId].min_price != undefined) {
          min_price = setting.dates[val.date][roomId].min_price;
          isDefaultminPrice = true;
        }

        if (setting.dates[val.date][roomId].max_price != undefined) {
          max_price = setting.dates[val.date][roomId].max_price;
          isDefaultmaxPrice = true;
        }

      }
    } else {
      return ""
    }

    let isToday = false;
    if (val.date == moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD')) {
      isToday = true;
    }

    let price = val.price;
    let recommendation_price = val.suggested_price;
    let isDefaultOcc = true;
    let occupancyPrice = [];
    if(this.state.adjType == 6 && setting.dates[val.date] && setting.dates[val.date][roomId]){
      occupancyPrice = setting.dates[val.date][roomId].occupancy_pricing || {};            
      if(Object.values(occupancyPrice).length > 0){
        isDefaultOcc = false;
      }
    }    
    let occupanyData = []
    if(this.state.adjType == 6){
      const  occupancyPricesAll =  this.state.occupancyPricesAll;
      occupanyData = occupancyPricesAll.filter((val) => val.room == roomId)            
    }

    return (
          <Tooltip        
            style={{display:"none"}}           
            classes={{
              popper: "toolTipPooper",
              tooltip: classes.htmlTooltip,
            }}
            disableHoverListener={(this.state.adjType == 6) ? false : true}
            title={
              <MuiThemeProvider theme={CalendartooltipTheme}>
                <Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                    <span style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ display: 'flex', margin: "2px 0px ", borderBottom: "1px solid #ffffffe8" }}>                  
                      <span style={{marginBottom:'8px', width: '40px', marginLeft: '5px', marginRight: '5px'}}>{t('Occ.')}</span>
                      <span style={{marginBottom:'8px', width: '150px'}}>{t('Price Difference')}</span>
                    </span>
                    {
                      occupanyData.map((val,index) => (
                        <span style={{ display: 'flex', margin: "8px 0px 5px 0px" }}>
                          <span style={{ width: '40px', marginLeft: '5px', marginRight: '5px' }}>{val.occupancy}</span>
                          {(occupancyPrice[val.occupancy]) ?                        
                            <span style={{ width: '150px', marginLeft: '5px', marginRight: '5px' }}>{(roomData.default_occupancy < val.occupancy ? '+':'- ')}{this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol +  occupancyPrice[val.occupancy]}</span>                          
                          :
                            <>
                            {(roomData.default_occupancy == val.occupancy)
                              ?<span style={{ width: '150px', marginLeft: '5px', marginRight: '15px' }}>{t('Base Price')}{':'}  { avgPrice ? this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol + avgPrice : ''}</span> 
                              :<span style={{ width: '150px', marginLeft: '5px', marginRight: '5px' }}>{(roomData.default_occupancy < val.occupancy ? '+':'- ')} { this.state.occupancy_percent_derivation ? (val.derivation + "%") : (this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol + val.derivation)}</span> 
                            }
                            </>                        
                          }                      
                        </span>
                      ))
                    }                                  
                    </span>
                    </Grid>
                  </Grid>              
                </Typography>
              </MuiThemeProvider>
            }
          >
            <div style={{ display: 'flex', flexDirection: 'column', height: "88px" }}>
              <span style={{ height: (this.state.adjType == 1 ? "30px" : "43px"), textAlign: 'right', fontSize: '14px', color: "#595959", padding: "3px 4px 0px 0px" }}>

                <span style={{ fontWeight: "500", margin: "0px 3px", padding: "1px 3px", background: (isToday ? "#2173ea" : "none"), float: "left", color: (isToday ? "#fff" : (dateDiff > 0 ? "#858585" : "#595959")), borderRadius: "5px" }}>
                  {moment(val.date).format('DD')}
                </span>

                {note &&
                  <span style={{ fontSize: '14px', fontWeight: "400", borderRadius: "3px", maxWidth: "76%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", backgroundColor: dateDiff > 0 ? "#999999" : "#475B85  ", padding: "2px 5px", float: "right", color: "#fff" }}>
                    {note}
                  </span>
                }
              </span>
              <span className="hideCalenderItemOnMobile" style={{ fontWeight: "400", textAlign: 'right', fontSize: '14px', color: "#595959", padding: "0px 5px" }}>
                {(this.state.currency.symbol + new Intl.NumberFormat('ja-JP').format(recommendation_price))}
              </span>
            <span className="currrencyFonts" style={{ fontWeight: "400", padding: "0px 5px", display: "flex", alignItems: "flex-end", height: (this.state.adjType == 1 ? "42px" : "24px") }}>
              <span style={{ color: colours.priceFontColor, fontSize: '14px', textAlign: "left", paddingTop: "6px", marginBottom: (this.state.adjType == 1 ? "4px" : "2px") }}>
                <span style={{ backgroundColor: colours.occColor, color: colours.occFontColor, padding: "2px 4px", borderRadius: "5px" }}>
                  {(parseInt(val.occupancy) + "%")}
                </span>
              </span>                      
              <span style={{ color: (dateDiff > 0 ? "#858585" : "#595959"), fontSize: '16px', width: "100%", textAlign: "right", fontWeight: "400" }}>                
                  {this.state.adjType == 0 &&
                    <span style={{ color: (dateDiff > 0 ? "#858585" : "#595959"), fontSize: '16px', width: "100%", textAlign: "right" , fontWeight: (isDefaultindAdj) ? "bold" : ""}}>
                      <span style={{ fontSize: '16px', fontWeight: "normal", marginRight: "5px", fontWeight: (isDefaultindAdj) ? "bold" : "", }}>
                        {t('Adj. by')}
                      </span>
    
                      {ind_adj ? (ind_adj > 0 ? "+" : "-") : ""}
                      {Math.abs(ind_adj)}%
                    </span>
                  }
                  {this.state.adjType == 1 &&
                    <div style={{ float: "right" }}>
                      <span style={{ minWidth: "30px", float: "left", textAlign: "center", display: "flex", flexDirection: "column" }}>
                        <span style={{ paddingLeft: "5px", fontWeight : (isDefaultminPrice) ? "bold" : "" }}>Min</span>
                        <span style={{ paddingLeft: "5px", fontWeight : (isDefaultmaxPrice) ? "bold" : "" }}>Max</span>
                      </span>
                      <span style={{ float: "left", textAlign: "right", display: "flex", flexDirection: "column" }}>
                        <span style={{ paddingLeft: "5px", fontWeight : (isDefaultminPrice) ? "bold" : "" }}>{this.state.currency.symbol}{min_price }</span>
                        <span style={{ paddingLeft: "5px", fontWeight : (isDefaultmaxPrice) ? "bold" : "" }}>{this.state.currency.symbol}{max_price }</span>
                      </span>
                    </div>
                  }  
                  {this.state.adjType == 2 &&
                    <span style={{ color: (dateDiff > 0 ? "#858585" : "#595959"), fontSize: '16px', width: "100%", textAlign: "right", fontWeight: (isDefaultDerivation)  ? "bold" : "" }}>
                      <span style={{ fontSize: '16px', fontWeight: "normal", marginRight: "5px" , fontWeight: (isDefaultDerivation)  ? "bold" : "" }}>
                        { !roomData.is_reference_room  ? "Der" : "" }
                      </span>
                      {features.includes(1) && !roomData.is_reference_room ?
                        (!roomData.is_reference_room ?
                          <span>
                            {derivation ? (derivation > 0 ? "+" : "-") : ""}
                            {this.state.isAbsolute ? this.state.currency.symbol : ""}
                            <span>
                              {parseInt(Math.abs(derivation))}{!this.state.isAbsolute ? "%" : ""}
                            </span>
                          </span>
                          :
                          <span>-</span>
                        )
                        :
                        <span>-</span>
                      }
                    </span>
                  }
    
                  {this.state.adjType == 5 &&
                    <span style={{ color: (dateDiff > 0 ? "#858585" : "#595959"), fontSize: '16px', width: "100%", textAlign: "right", fontWeight: (isDefaultClosedRooms) ? "bold" : "" }}>
                      <span style={{ fontSize: '16px', fontWeight: "normal", marginRight: "5px", fontWeight: (isDefaultClosedRooms) ? "bold" : "" }}>
                      {t("Closed")}
                      </span>
                      {closedRooms}
                    </span>
                  }
                  {this.state.adjType == 6 &&
                    <span style={{ color: (dateDiff > 0 ? "#858585" : "#595959"), fontSize: '16px', width: "100%", textAlign: "right"}}>
                      <span style={{ fontSize: '16px', fontWeight: "normal", marginRight: "5px", fontWeight: (!isDefaultOcc) ? "bold" : ""  }}>
                        {isDefaultOcc ? t('Default') : t('Edited')}
                      </span>
                    </span>
                  }                      
                  {this.state.adjType == 7 &&
                    <span style={{ color: (dateDiff > 0 ? "#858585" : "#595959"), fontSize: '16px', width: "125px", float:"right", textAlign: "right", fontWeight: (isDefaultAggr) ? "bold" : "", overflow: "hidden", textOverflow: "ellipsis", display: "block" }}>
                      <span style={{ fontSize: '16px', fontWeight: "normal", marginRight: "5px",  }}>                    
                      </span>
                      {ind_aggressiveness}
                    </span>
                  }
                  {this.state.adjType == 8 &&
                    <span style={{ color: (dateDiff > 0 ? "#858585" : "#595959"), fontSize: '16px', width: "100%", textAlign: "right", fontWeight : (isDefaultLeatTimeAdjust) ? "bold" : "" }}>
                      <span style={{ fontSize: '16px', fontWeight: "normal", marginRight: "5px" }}>                   
                      </span>                  
                      {ind_leatTimeAdjust}
                    </span>
                  }            
                </span>
              </span>
            </div>
          </Tooltip>
    )
  }
  
  renderOTATitle = (val, note, dateDiff, colours, compPercentage, medianRate) => {
    let roomId = val.room_id;
    let roomData = this.props.roomList.roomList.filter(val => val.id == roomId)[0];

    let setting = this.props.priceSettingReducer.priceSetting;
    const { classes } = this.props;
    const { t } = this.props;

    let price = val.price;
    let original_price = val.original_price;
    let closedRooms = 0;
     if (this.state.calendarType == 1 && this.state.viewType == "calendar") {
      original_price = val.yesterday_price;
    }

    if (setting.dates && setting.dates[val.date] && setting.dates[val.date][roomId] && setting.dates[val.date][roomId].closed_rooms) {
      closedRooms = setting.dates[val.date][roomId].closed_rooms;
    }

    if (this.state.calendarType == 2 && this.state.viewType == "calendar") {
      original_price = val.one_week_price;
    }

    let isToday = false;
    if (val.date == moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD')) {
      isToday = true;
    }

    if(!note && this.props.eventCategoryReducer.impEvents && this.props.eventCategoryReducer.impEvents[val.date] && this.props.eventCategoryReducer.impEvents[val.date].length > 0){      
      note = this.props.eventCategoryReducer.impEvents[val.date].sort((a,b) => Math.abs(a.weighted_local_rank > b.weighted_local_rank) ? 1 : (Math.abs(b.weighted_local_rank > a.weighted_local_rank) ? -1 : 0))[0].title;      
    }else if(!note && val.note){
      note = val.note
    } 
    let compPer = Math.round(compPercentage);

    // priceColor: "#F895A9"
        // occColor: "#FED42C"
        // occFontColor: "#702400"
        // priceFontColor: "#890921"
        // let fontColor = ""
    return (
      // <Tooltip
      //   classes={{
      //     popper: "toolTipPooper",
      //     tooltip: classes.htmlTooltip
      //   }}
      //   title={
      //     <MuiThemeProvider theme={CalendartooltipTheme}>
      //       <Typography>
      //         <Grid container spacing={3}>
      //           <Grid item xs={12}>
      //             <Typography>
      //               {val.inventory_remaining} {t("Rooms Left")} ({(roomData) ? roomData.name : ''})
      //             </Typography>
      //           </Grid>
      //         </Grid>
      //       </Typography>
      //     </MuiThemeProvider>}
      // >
        <div style={{ display: 'flex', flexDirection: 'column', height: "88px" }}>
          <span style={{ height: "45px", textAlign: 'right', fontSize: '14px', padding: "3px 4px 0px 0px" }}>

            <span style={{ fontWeight: "500", margin: "0px 3px", padding: "1px 3px", background: (isToday ? "#3772ff" : "none"), float: "left", color: (isToday ? "#fff" : "#595959"), borderRadius: "5px" }}>
              {moment(val.date).format('DD')}
            </span>

            {note &&
              <span style={{ fontWeight: "400", borderRadius: "3px", maxWidth: "76%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", backgroundColor: (dateDiff > 0 ?  "#999999" : "#475B85 "), padding: "2px 5px", float: "right", color: "#fff" }}>
                {note}
              </span>
            }
          </span>

          <Grid container style={{ padding: "2px 5px" }}>
            <Grid xs={12} className="hideCalenderItemOnMobile" style={{ fontWeight: "400", textAlign: 'right', fontSize: '14px', marginBottom:"6px", color: "#595959" }}>
              {price ? this.state.currency.symbol : " "}
              {price ? price : "n.a."}
            </Grid>
            {/* <Grid className="hideCalenderItemOnMobile" item xs={12} sm={4} style={{ color: colours.priceFontColor, fontSize: '14px', textAlign: "left", paddingTop: "5px" }}> */}
              {/* {this.state.viewType != "adjustment" &&
                <span style={{ backgroundColor: colours.occColor, color: colours.occFontColor, padding: "4px", borderRadius: "5px" }}>
                  {(parseInt(val.occupancy) + "%")}
                </span>
              } */}
            {/* </Grid> */}
            {/* <Grid item xs={12} sm={12} style={{float:'right', color: colours.priceColor, fontSize: '20px', width: "100%", textAlign: "right", fontWeight: "bold" }}>
              <span style={{ fontSize: '16px', fontWeight: "normal", marginRight: "5px" }}>
                  vs comp.
              </span>
              {compPer &&
                <span style={{backgroundColor: colours.priceColor, color : colours.priceFontColor, padding: "0px 4px 0px 2px", borderRadius: "5px" }}>
                  {(compPer != 0 ? (compPer > 0 ? '+' + compPer + "%" : compPer + '%') : 'n.a.')}
                </span> 
              }
            </Grid> */}
                <span style={{ color: "#595959", fontSize: '16px', width: "100%", textAlign: "right", fontWeight: "bold" }}>
                  <span style={{ fontSize: '16px', fontWeight: "normal", marginRight: "5px" }}>
                    {t('vs comp.')}
                  </span>

                  {(compPer != 0 ? (compPer > 0 ? '+' + compPer + "%" : compPer + '%') : 'n.a.')}
                </span>
          </Grid>

        </div>
      // </Tooltip>
    )
  }
  renderSystemSkip = (val, colours, dateDiff) => {
    const { classes } = this.props;
    const { t } = this.props;

    let isToday = false;
    if (val.date == moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD')) {
      isToday = true;
    }

    return (
      <Tooltip
        classes={{
          popper: "toolTipPooper",
          tooltip: classes.htmlTooltip
        }}
        title={
          <MuiThemeProvider theme={CalendartooltipTheme}>
            <Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {t(`Sorry, we couldn't calculate a price for this date.`)}
                </Grid>
                <Grid item xs={12}>
                  {t('Reason:') + val.reason}
                </Grid>
              </Grid>
            </Typography>
          </MuiThemeProvider>}
      >
        <div style={{ display: 'flex', flexDirection: 'column', height: "88px" }}>
          <span style={{ height: "45px", textAlign: 'right', fontSize: '14px', padding: "3px 4px 0px 0px" }}>

            <span style={{ fontWeight: "500", margin: "0px 3px", padding: "1px 3px", background: (isToday ? "#3772ff" : "none"), float: "left", color: (isToday ? "#fff" : "#595959"), borderRadius: "5px" }}>
              {moment(val.date).format('DD')}
            </span>
              <span style={{ fontWeight: "400", borderRadius: "3px", maxWidth: "76%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", backgroundColor: (dateDiff > 0 ?  "#999999" : "#374667 "), padding: "2px 5px", float: "right", color: "#fff" }}>
                {t('No Data')}
              </span>
          </span>

          <Grid container style={{ padding: "2px 5px" }}>
            <Grid xs={12} className="hideCalenderItemOnMobile" style={{ fontWeight: "400", textAlign: 'right', fontSize: '14px', marginBottom:"6px", color: "#595959" }}>
                <span style={{ display: "inline-block" }} />
            </Grid>
            <Grid className="hideCalenderItemOnMobile" item xs={12} sm={4} style={{ color: colours.priceFontColor, fontSize: '14px', textAlign: "left", paddingTop: "5px" }}>
              
            </Grid>
            <Grid item xs={12} sm={8} style={{ color: colours.priceColor, fontSize: '20px', width: "100%", textAlign: "right", fontWeight: "bold" }}>
              <span style={{ backgroundColor: colours.priceColor, color: colours.priceFontColor, padding: "0px 4px 0px 2px", borderRadius: "5px" }}>
              {'n.a.'}
              </span>
            </Grid>

          </Grid>

        </div>
      </Tooltip>
    )
  }

  renderFullTitle = (val, dateDiff, colours, note) => {

    let roomId = val.room_id;
    let roomData = this.props.roomList.roomList.filter(val => val.id == roomId)[0];

    const { classes } = this.props;
    const { t } = this.props;


    let price = val.price;
    let original_price = val.original_price;

    if (this.state.calendarType == 1 && this.state.viewType == "calendar") {
      original_price = val.yesterday_price;
    }

    if (this.state.calendarType == 2 && this.state.viewType == "calendar") {
      original_price = val.one_week_price;
    }

    let isToday = false;
    if (val.date == moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD')) {
      isToday = true;
    }

    return (
      <Tooltip
        classes={{
          popper: "toolTipPooper",
          tooltip: classes.htmlTooltip
        }}
        title={
          <MuiThemeProvider theme={CalendartooltipTheme}>            
            {this.state.viewType == "calendar" &&
            <Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography>
                    {t("Full")}
                  </Typography>
                </Grid>
              </Grid>
            </Typography>
        }
          </MuiThemeProvider>}
      >
        <div style={{ display: 'flex', flexDirection: 'column', height: "88px" }}>
          <span style={{ height: "45px", textAlign: 'right', fontSize: '12px', color: colours.priceFontColor, padding: "3px 4px 0px 0px" }}>

            <span style={{ fontSize: '14px', fontWeight: "500", margin: "0px 3px", padding: "1px 3px", background: (isToday ? "#2173ea" : "none"), float: "left", color: (isToday ? "#fff" : "#595959"), borderRadius: "5px" }}>
              {moment(val.date).format('DD')}
            </span>

            {note &&
              <span title={note} style={{ fontSize: '14px', borderRadius: "3px", maxWidth: "76%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", backgroundColor: dateDiff > 0 ? "#999999" : "#475B85  ", padding: "2px 5px", float: "right", color: "#fff" }}>
                {note}
              </span>
            }
          </span>

          <span style={{ textAlign: 'right', fontSize: '12px', color: colours.priceFontColor, padding: "0px 5px" }}>
            <span style={{ display: "inline-block" }} />
          </span>

          {/* <span className="currrencyFonts" style={{ padding: "0px 5px", display: "flex", alignItems: "baseline" }}>
            <span className="hideCalenderItemOnMobile" style={{ color: fontColor, fontSize: '14px' }}>
              {parseInt(val.occupancy)}%
            </span>
            <span style={{ color: fontColor, fontSize: '20px', width: "100%", textAlign: "right", fontWeight: "bold" }}>
            {t("Full")}
            </span>
          </span> */}

          <Grid container style={{ padding: "2px 5px" }}>
            <Grid className="hideCalenderItemOnMobile" item xs={12} sm={4} style={{ color: colours.priceFontColor, fontSize: '14px', textAlign: "left", paddingTop: "6px" }}>
              {this.state.viewType != "adjustment" &&
                <span style={{ backgroundColor: colours.occColor, color: colours.occFontColor, padding: "2px 4px", borderRadius: "5px" }}>
                  {(parseInt(val.occupancy) + "%")}
                </span>
              }
            </Grid>
            <Grid item xs={12} sm={8} style={{ color: colours.priceColor, fontSize: '20px', width: "100%", textAlign: "right", fontWeight: "bold" }}>
              <span style={{ backgroundColor: colours.priceColor, color: colours.priceFontColor, padding: "0px 4px 0px 2px", borderRadius: "5px" }}>
                {t("Full")}
              </span>
            </Grid>

          </Grid>

        </div>
      </Tooltip>
    )
  }
  renderCloseTitle = (val, dateDiff, colours, note) => {
    let roomId = val.room_id;
    let roomData = this.props.roomList.roomList.filter(val => val.id == roomId)[0];

    const { classes } = this.props;
    const { t } = this.props;


    let price = val.price;
    let original_price = val.original_price;

    if (this.state.calendarType == 1 && this.state.viewType == "calendar") {
      original_price = val.yesterday_price;
    }

    if (this.state.calendarType == 2 && this.state.viewType == "calendar") {
      original_price = val.one_week_price;
    }

    let isToday = false;
    if (val.date == moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD')) {
      isToday = true;
    }
    return (
      <Tooltip
        classes={{
          popper: "toolTipPooper",
          tooltip: classes.htmlTooltip
        }}
        title={
          <MuiThemeProvider theme={CalendartooltipTheme}>            
            { (this.state.viewType == "calendar" ||  this.state.viewType == 'pickUp') &&
            <Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography>
                    {t("Closed")}
                  </Typography>
                </Grid>
              </Grid>
            </Typography>
        }
          </MuiThemeProvider>}
      >
        <div style={{ display: 'flex', flexDirection: 'column', height: "88px" }}>
          <span style={{ height: "45px", textAlign: 'right', fontSize: '14px', padding: "3px 4px 0px 0px" }}>

            <span style={{ fontWeight: "500", margin: "0px 3px", padding: "1px 3px", background: (isToday ? "#3772ff" : "none"), float: "left", color: (isToday ? "#fff" : (dateDiff > 0 ? "#858585" : "#595959")), borderRadius: "5px" }}>
              {moment(val.date).format('DD')}
            </span>

            {note &&
              <span style={{ fontWeight: "400", borderRadius: "3px", maxWidth: "76%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", backgroundColor: (dateDiff > 0 ?  "#999999" : "#374667 "), padding: "2px 5px", float: "right", color: "#fff" }}>
                {note}
              </span>
            }
          </span>

          <Grid container style={{ padding: "2px 5px" }}>
            <Grid xs={12} className="hideCalenderItemOnMobile" style={{ height:"28px" }}>                
            </Grid>
            <Grid className="hideCalenderItemOnMobile" item xs={12} sm={4} style={{ color: colours.priceFontColor, fontSize: '14px', textAlign: "left", paddingTop: "6px" }}>
              <span style={{ backgroundColor: colours.occColor, color: colours.occFontColor, padding: "2px 4px", borderRadius: "5px" }}>
                  {parseInt(val.occupancy)}%
              </span>
            </Grid>
            <Grid item xs={12} sm={8} style={{ color: colours.priceColor, fontSize: '20px', width: "100%", textAlign: "right", fontWeight: "bold" }}>
              <span style={{ backgroundColor: (dateDiff > 0 ) ? '#ebebeb' : '#f3efe0', color: (dateDiff > 0 ) ? '#858585' : '#595959', padding: "0px 4px 0px 2px", borderRadius: "5px" }}>
              {t("Closed")}
              </span>
            </Grid>

          </Grid>

        </div>
      </Tooltip>
    )
  }
  
  renderTitle = (val, note, dateDiff, colours) => {
    let roomId = val.room_id;
    let roomData = this.props.roomList.roomList.filter(val => val.id == roomId)[0];

    let setting = this.props.priceSettingReducer.priceSetting;
    const { classes } = this.props;
    const { t } = this.props;

    let price = val.price;
    let original_price = val.original_price;
    let recommendation_price = val.suggested_price;
    let closedRooms = 0;
    if (this.state.calendarType == 1 && this.state.viewType == "calendar") {
      original_price = val.yesterday_price;
    }

    if (setting.dates && setting.dates[val.date] && setting.dates[val.date][roomId] && setting.dates[val.date][roomId].closed_rooms) {
      closedRooms = setting.dates[val.date][roomId].closed_rooms;
    }

    if (this.state.calendarType == 2 && this.state.viewType == "calendar") {
      original_price = val.one_week_price;
    }

    let isToday = false;
    if (val.date == moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD')) {
      isToday = true;
    }
    if(!note && this.props.eventCategoryReducer.impEvents && this.props.eventCategoryReducer.impEvents[val.date] && this.props.eventCategoryReducer.impEvents[val.date].length > 0){      
      note = this.props.eventCategoryReducer.impEvents[val.date].sort((a,b) => Math.abs(a.weighted_local_rank > b.weighted_local_rank) ? 1 : (Math.abs(b.weighted_local_rank > a.weighted_local_rank) ? -1 : 0))[0].title;      
    }else if(!note && val.note){
      note = val.note
    } 
    

    let fixedPricePerRoom;
    if(setting.dates && setting.dates[val.date] && setting.dates[val.date][roomId] && setting.dates[val.date][roomId].fix_price  ){
      fixedPricePerRoom=setting.dates[val.date][roomId].fix_price;
    }
    
    let roomsSold = Math.max(0,(val.number_of_rooms - val.total_inventory));
    let skip_data=val.skip_price
    // priceColor: "#F895A9"
        // occColor: "#FED42C"
        // occFontColor: "#702400"
        // priceFontColor: "#890921"
        // let fontColor = ""
    return (
      <Tooltip
        classes={{
          popper: "toolTipPooper",
          tooltip: classes.htmlTooltip
        }}
        disableHoverListener={this.state.viewType != "adjustment" ? false : true}
        title={
          <MuiThemeProvider theme={CalendartooltipTheme}>
            {(this.state.viewType == "calendar" || this.state.viewType == "priceGraph") &&
            <>
          <Typography>
          <span style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ display: 'flex', margin: "2px 0px ", borderBottom: "1px solid #ffffffe8" }}>
              <span style={{ marginTop:'4px',background:colours.occColor,width:'15px',borderRadius:'3px',height:'13px',marginRight:'5px' }}></span>
              <span style={{marginBottom:'8px'}}>{colours.tooltipText == 1  ? t('Fewer bookings than expected'): (colours.tooltipText == 2) ? t('More bookings than expected'):t("You're on track")}</span>
            </span>
            <span style={{ display: 'flex', margin: "8px 0px 5px 0px" }}>
              <span style={{ width: '140px', marginRight: '10px' }}> {t(" Rooms Sold (Total)")}</span>
              <span>  {roomsSold}</span>
            </span>
            <span style={{ display: 'flex', margin: "5px 0px" }}>
              <span style={{ width: '140px', marginRight: '10px' }}> {t("Expected Rooms Sold")}</span>
              <span>  {val.expectedroomssold_adjusted ? Math.round(val.expectedroomssold_adjusted) : val.expectedroomssold_adjusted}</span>
            </span>
          </span>
          </Typography>
            {/* <span>
              <span  style={{background:colours.occColor,width:'18px',borderRadius:'3px',height:'25px'}}></span>  
              <span>{t('Fewer bookings than expected')}</span>
            </span> */}
            </> 
            }
              {/* <Typography>
                     
                 <Grid container spacing={1}>
                  <Grid item xs={12}>
                    
                    <Typography>
                      {t('Fewer bookings than expected')}
                    </Typography>
                    <Typography>
                      {roomsSold} {t(" Rooms Sold (Total)")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {val.expectedroomssold_adjusted ? Math.round(val.expectedroomssold_adjusted) : val.expectedroomssold_adjusted} {t("Expected Rooms Sold")}
                    </Typography>
                  </Grid>
                </Grid> 
               </Typography> */}
           
          </MuiThemeProvider>}
      >
        <div style={{ display: 'flex', flexDirection: 'column', height: "88px" }}>
          <span style={{ height: "45px", textAlign: 'right', fontSize: '14px', padding: "3px 4px 0px 0px" }}>

            <span style={{ fontWeight: "500", margin: "0px 3px", padding: "1px 3px", background: (isToday ? "#3772ff" : "none"), float: "left", color: (isToday ? "#fff" : (dateDiff > 0 ? "#858585" : "#595959")), borderRadius: "5px" }}>
              {moment(val.date).format('DD')}
            </span>

            {note &&
              <span style={{ fontWeight: "400", borderRadius: "3px", maxWidth: "76%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", backgroundColor: (dateDiff > 0 ?  "#999999" : "#475B85 "), padding: "2px 5px", float: "right", color: "#fff" }}>
                {note}
              </span>
            }
          </span>

          <Grid container style={{ padding: "2px 5px" }}>
          <Grid xs={12} className="hideCalenderItemOnMobile" style={{ fontWeight: "400", textAlign: 'right', fontSize: '14px', marginBottom:"6px", color: (dateDiff > 0 ? "#858585" : "#595959") }}>
                {this.state.viewType != "adjustment" ? (original_price ? (this.state.currency.symbol + new Intl.NumberFormat('ja-JP').format(original_price)) : "n.a.") : (recommendation_price ? this.state.currency.symbol : "")+(recommendation_price ? new Intl.NumberFormat('ja-JP').format(recommendation_price) : "n.a.")}
            </Grid>
            <Grid className="hideCalenderItemOnMobile" item xs={12} sm={4} style={{ color: colours.priceFontColor, fontSize: '14px', textAlign: "left", paddingTop: "6px" }}>
              {/* {this.state.viewType != "adjustment" && */}
                <span style={{ backgroundColor: colours.occColor, color: colours.occFontColor, padding: "2px 4px", borderRadius: "5px" }}>
                  {(parseInt(val.occupancy) + "%")}
                </span>
              {/* } */}
            </Grid>
            <Grid item xs={12} sm={8} style={{ color: colours.priceColor, fontSize: (this.state.viewType == "adjustment") ? '16px' : "20px", width: "100%", textAlign: "right", fontWeight: (this.state.viewType != "adjustment" || fixedPricePerRoom) ? "bold" : "" }}>
              <span style={{ backgroundColor:colours.priceColor, color: colours.priceFontColor , padding: "0px 4px 0px 2px", borderRadius: "5px" }}>
                {((skip_data && skip_data.fixed_price !== null) || fixedPricePerRoom && this.state.viewType != "adjustment")  ? <LockIcon style={{ fontSize: "16px", margin: "-1px 2px" }} />
                  :
                  (price && original_price != price && colours.showArrow) ?
                    (colours.arrowUp ? <ArrowDropUpIcon style={{ margin: "-8px -4px", fontSize: "28px" }} /> : <ArrowDropDownIcon style={{ margin: "-8px -4px", fontSize: "28px" }} />)
                  : ""
                }
                {(price && this.state.viewType != "adjustment") ? (price) ? this.state.currency.symbol : "" : (fixedPricePerRoom && this.state.viewType == "adjustment") ? this.state.currency.symbol : "" }
                {(price && this.state.viewType != "adjustment") ? (price) ? new Intl.NumberFormat('ja-JP').format(price) : "n.a." : (this.state.viewType == "adjustment" && fixedPricePerRoom) ? new Intl.NumberFormat('ja-JP').format(price) : "-" }
              </span>
            </Grid>

          </Grid>

        </div>
      </Tooltip>
    )
  }
  renderRevenueTitle = (val, note, dateDiff, colours) =>{
    const { classes } = this.props;
    const { t } = this.props;
    if(!note && this.props.eventCategoryReducer.impEvents && this.props.eventCategoryReducer.impEvents[val.date] && this.props.eventCategoryReducer.impEvents[val.date].length > 0){      
      note = this.props.eventCategoryReducer.impEvents[val.date].sort((a,b) => Math.abs(a.weighted_local_rank > b.weighted_local_rank) ? 1 : (Math.abs(b.weighted_local_rank > a.weighted_local_rank) ? -1 : 0))[0].title;      
    }
    let isToday = false;
    if (val.date == moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD')) {
      isToday = true;
    }
    let revenue='n.a.';
    let adrValue='n.a.';
    let occupancy='n.a.'
    if(this.state.kpi_daily_total){
      revenue = this.state.kpi_daily_total['REVENUE']?.[val.date]?new Intl.NumberFormat('ja-JP').format(this.state.kpi_daily_total['REVENUE'][val.date]): 0
      adrValue = this.state.kpi_daily_total['ADR']?.[val.date]?new Intl.NumberFormat('ja-JP').format(this.state.kpi_daily_total['ADR'][val.date]):0
      occupancy= this.state.kpi_daily_total['OCCUPANCY']?.[val.date]?this.state.kpi_daily_total['OCCUPANCY'][val.date]:0
   }
    
    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: "88px" }}>
          <span style={{ height: "45px", textAlign: 'right', fontSize: '14px', padding: "3px 4px 0px 0px" }}>
            <span style={{ fontWeight: "500", margin: "0px 3px", padding: "1px 3px", background: (isToday ? "#3772ff" : "none"), float: "left", color: (isToday ? "#fff" : (dateDiff > 0 ? "#858585" : "#595959")), borderRadius: "5px" }}>
              {moment(val.date).format('DD')}
            </span>

            {note &&
              <span style={{ fontWeight: "400", borderRadius: "3px", maxWidth: "76%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", backgroundColor: (dateDiff > 0 ?  "#999999" : "#475b85"), padding: "2px 5px", float: "right", color: "#fff" }}>
                {note}
              </span>
            }
          </span>

          <Grid container style={{ padding: "2px 5px" }}>
            <Grid xs={12} className="hideCalenderItemOnMobile" style={{ fontWeight: "400", textAlign: 'right', fontSize: '14px', marginBottom:"6px", color: (dateDiff > 0 ? "#858585" : "#595959") }}>
              {this.state.currency.symbol+adrValue}
            </Grid>
            <Grid className="hideCalenderItemOnMobile" item xs={12} sm={4} style={{  fontSize: '14px', textAlign: "left", paddingTop: "6px" }}>
              <span style={{ color: colours.occFontColor, padding: "2px 4px", borderRadius: "5px" }}>
                {parseInt(occupancy)+'%'}
              </span>
            </Grid>
            <Grid item xs={12} sm={8} style={{  color: (dateDiff > 0 ? "#858585" : "#595959"),fontSize: '20px', width: "100%", textAlign: "right", fontWeight: "bold" }}>
              <span style={{ padding: "0px 4px 0px 2px", borderRadius: "5px" }}>
                { this.state.currency.symbol + revenue}
              </span>
            </Grid>

          </Grid>
        </div>
    )
  }
  renderPickUpTitle = (val, colours, roomsSold, dateDiff) => {
    let width = window.screen.width;
    const { classes, t } = this.props;
    let isToday = false;
    
    if (val.date == moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD')) {
      isToday = true;
    }

    let pickupLeft = null;
    let rockets = [];
    let rockets_number = 0;
    if (this.state.boostPrice && this.state.boostPrice[val.date] && this.state.boostPrice[val.date].arguments) {
      // pickupLeft = this.state.boostPrice[val.date].arguments.roomsleft_change_vs_yesterday;
      // if(this.state.pickupType == 1){
      //   pickupLeft = this.state.boostPrice[val.date].arguments.roomsleft_change_vs_7daysago;
      // }

      //Pickup yesterday
      if(this.state.pickupType == 0){
        pickupLeft = this.state.boostPrice[val.date].arguments.roomsleft_change_vs_yesterday;
        rockets_number = this.state.boostPrice[val.date].number_of_rockets_yesterday;
      }

      //Pickup Week
      if(this.state.pickupType == 1){
        pickupLeft = this.state.boostPrice[val.date].arguments.roomsleft_change_vs_7daysago;
        rockets_number = this.state.boostPrice[val.date].number_of_rockets_7daysago;
      }

      //Pickup Month
      if(this.state.pickupType == 2){
        pickupLeft = this.state.boostPrice[val.date].arguments.roomsleft_change_vs_30daysago;
        rockets_number = this.state.boostPrice[val.date].number_of_rockets_30daysago;
      }
      
      // let rockets_number = this.state.boostPrice[val.date].number_of_rockets;
      for (let i = 0; i < rockets_number; i++) {
        rockets.push(<Icon style={{fontSize:"14px"}}>rocket_launch</Icon>);
      }
    }

    return (
      <Tooltip
        classes={{
          popper: "toolTipPooper",
          tooltip: classes.htmlTooltip,
        }}
        title={
          <MuiThemeProvider theme={CalendartooltipTheme}>
            <Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography>
                    {roomsSold} {t(`${this.state.room_apartment_space_name}s Sold (Total)`)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {val.expectedroomssold_adjusted ? Math.round(val.expectedroomssold_adjusted) : val.expectedroomssold_adjusted} {t(`Expected ${this.state.room_apartment_space_name}s Sold`)}
                  </Typography>
                </Grid>
              </Grid>
            </Typography>
          </MuiThemeProvider>
        }
      >
        <div style={{ display: 'flex', flexDirection: 'column', height: "88px" }}>
          <span style={{ height: "45px", textAlign: 'right', fontSize: '14px', color: colours.occFontColor, padding: "3px 4px 0px 0px" }}>
            <span style={{ fontWeight: "500", margin: "0px 3px", padding: "1px 3px", background: (isToday ? "#2173ea" : "none"), float: "left", color: (isToday ? "#fff" : colours.occFontColor), borderRadius: "5px" }}>
              {moment(val.date).format('DD')}
            </span>
          </span>
          <Grid container style={{ padding: "2px 5px" }}>
            <Grid xs={12} className="hideCalenderItemOnMobile" style={{ fontWeight: "400", textAlign: 'right', fontSize: '14px', marginBottom: "6px", color: (dateDiff > 0 ? "#858585" : colours.occFontColor) }}>            
                  {rockets ? rockets : ""} {pickupLeft == null ? "n.a." : parseInt(pickupLeft)  + " Pickup"}
            </Grid>
            <Grid className="hideCalenderItemOnMobile" item xs={12} sm={4} style={{ color: dateDiff > 0 ? "#858585" : colours.occFontColor, fontSize: '14px', textAlign: "left", paddingTop: "6px" }}>
            <span style={{ padding: "2px 4px", borderRadius: "5px" }}>
              {this.state.currency.symbol}{new Intl.NumberFormat('ja-JP').format(val.price)}
              </span>
            </Grid>
            <Grid item xs={12} sm={8} style={{ color: colours.priceColor, fontSize: '20px', width: "100%", textAlign: "right", fontWeight: "bold" }}>
              <span style={{ color: dateDiff > 0 ? "#858585" : colours.occFontColor, padding: "0px 4px 0px 2px", borderRadius: "5px" }}>
                {parseInt(val.occupancy)}%
              </span>
            </Grid>
          </Grid>
        </div>
      </Tooltip>
    )
  }

  getFontBGcolors = (val, roomsSold) => {

    let priceChange = (val.price / val.original_price - 1) * 100;
    let pricePercentage = Math.abs(val.price / val.original_price - 1) * 100;

    if (this.state.calendarType == 1 && this.state.viewType == "calendar") {
      priceChange = (val.price / val.yesterday_price - 1) * 100;
      pricePercentage = Math.abs(val.price / val.yesterday_price - 1) * 100;
    }

    if (this.state.calendarType == 2 && this.state.viewType == "calendar") {
      priceChange = (val.price / val.one_week_price - 1) * 100;
      pricePercentage = Math.abs(val.price / val.one_week_price - 1) * 100;
    }

    let priceColor = "#F3EFE0";
    let priceFontColor = "#595959";

    let occColor = "#F3EFE0";
    let occFontColor = "#595959";
    let arrowUp = true;
    let showArrow = false;
    let tooltipText = 0; //1 for orange color,2 for gray and 0 for other
    if(priceChange < 0){
      arrowUp = false;
    }

    if (val.exproomssold_u3 != undefined) {
      // occFontColor = "#595959";
      switch (true) {
        case (roomsSold == val.expectedroomssold_adjusted):
          occColor = "#F3EFE0";
          tooltipText=2;
          break;
        case (roomsSold >= val.exproomssold_u3): // dark too many
          occColor = "#96D1D9";
          occFontColor = "#0B1C1F";
          tooltipText=2;
          break;
        case (roomsSold >= val.exproomssold_u2): // mid
          occColor = "#9CE0E7";
          occFontColor = "#0E3438";
          tooltipText=2;
          break;
        case (roomsSold >= val.exproomssold_u1): // light
          occFontColor = "#114247";
          occColor = "#BDEAEF";
          tooltipText=2;
          break;
        case (roomsSold >= val.exproomssold_l1 && roomsSold < val.exproomssold_u1):
          occColor = "#F3EFE0";
          tooltipText=0;
          break;
        case (roomsSold < val.exproomssold_l3): // dark not enogh
          occColor = "#FBB84B";
          occFontColor = "#291A01";
          tooltipText=1;
          break;
        case (roomsSold < val.exproomssold_l2): // mid //or
          occColor = "#FBC774";
          tooltipText=1;
          occFontColor = "#311F01";
          break;
        case (roomsSold <= val.exproomssold_l1): // light
          occColor = "#FCD79C";
          occFontColor = "#462C02";
          tooltipText=1;
          break;
        default:
          occColor = "";
          tooltipText=1;
          break;
      }
    }

    if (pricePercentage < 5) {
      priceColor = '#F3EFE0';
    }
    if (5 <= pricePercentage) {
      showArrow = true;
      if (priceChange > 0) {
        if (this.state.viewType === 'adjustment') { // green
          priceColor = '';
          priceFontColor = "#595959";
          // occColor = "#ebebeb";
          showArrow = false;
        } else {
          priceColor = '#99EACB';
          priceFontColor = "#135138";
        }
        
      }
      if (priceChange < 0) {
        arrowUp = false;
        if (this.state.viewType === 'adjustment') { // red
          priceColor = '';
          priceFontColor = "#595959";
          // occColor = "#ebebeb";
          showArrow = false;
        } else {
          priceColor = '#FAB2C1';
          priceFontColor = "#890921";
        }

        
      }
    }

    if (pricePercentage == 0 || pricePercentage == null || pricePercentage == Infinity) {
      priceColor = '#F3EFE0';
      showArrow= false;
      priceColor = "#F3EFE0";
      priceFontColor = "#595959";
    }
    
    if (val.number_of_rooms <= 0 && this.state.viewType == 'calendar') {
      priceColor = '#99EACB';
      priceFontColor = "#135138";
    }

    return { showArrow, arrowUp, priceColor, priceFontColor, occColor, occFontColor, tooltipText }
  }

  getFontcolors = (code) => {

    if (this.state.modalData.date) {

      var bgColor = "#f6efed";
      let price = this.state.modalData.original_price;
      let change_pms_perc = (this.state.modalData.price / this.state.modalData.original_price - 1)*100;
      let priceChange = change_pms_perc;
      let pricePercentage = change_pms_perc;

      if (code == 1) {
        let yesterday_change_perc = (this.state.modalData.price / this.state.modalData.yesterday_price - 1)*100;
        priceChange = yesterday_change_perc;
        pricePercentage = yesterday_change_perc;
      }

      if (5 <= Math.abs(pricePercentage)) {
        if (priceChange > 0) {
          bgColor = "#49D49D";
        }
        if (priceChange < 0) {
          bgColor = "#fe87b7";
        }
      }

      if (priceChange == 0 || priceChange == null) {
        bgColor = '#f6efed';
      }
      return { backgroundColor: bgColor, color: '#000' };
    }
  }

  getOldPricesChange = (code) => {
    const { classes, t } = this.props;
    if (this.state.modalData.date) {

      let title = '';

      if (code == 0) {
        title =  t(`Price in ${this.state.pms_channel_manager_text}`);
      } else if (code == 1) {
        title = t('Price Yesterday');
      } else if (code == 2) {
        title = t('Price 7 Days Ago');
      }

      let price = this.state.modalData.original_price;

      let change_pms_perc = (this.state.modalData.price / this.state.modalData.original_price - 1)*100;

      let priceChange = change_pms_perc ? change_pms_perc.toFixed(0) : 0;
      let pricePercentage = change_pms_perc ? change_pms_perc.toFixed(0) : 0;

      if (code == 1) {        
        let yesterday_change_perc = (this.state.modalData.price / this.state.modalData.yesterday_price - 1)*100;
        price = this.state.modalData.yesterday_price;
        priceChange = yesterday_change_perc ? yesterday_change_perc.toFixed(0) : 0;
        pricePercentage = yesterday_change_perc ? yesterday_change_perc.toFixed(0) : 0;
      }

      if (code == 2) {
        let one_week_change_perc = (this.state.modalData.price / this.state.modalData.one_week_price - 1)*100;
        price = this.state.modalData.one_week_price;
        priceChange = one_week_change_perc ? one_week_change_perc.toFixed(0) : 0;
        pricePercentage = one_week_change_perc ? one_week_change_perc.toFixed(0) : 0;
      }

      if (price == null || price == undefined || this.state.modalData.no_market_data) {
        return (<TableRow className={classes.pmsTableRow} style={this.getFontcolors(0)} style={{ paddingRight: "5px" }}>
          <TableCell align="left" className={code == 1 ? classes.pmsRowBlankBorder : classes.pmsNormalRow} style={{ paddingTop: (code == 0) ? '25px' : '0px' }}>
            <Typography variant="subtitle1">{title}</Typography>
          </TableCell>
          <TableCell align="right" className={code == 1 ? classes.pmsRowBlankBorder : classes.pmsNormalRow} style={{ paddingTop: (code == 0) ? '25px' : '0px' }}>
            <Typography variant="subtitle1" style={{ fontWeight: '400' }}>
              n.a.
          </Typography>
          </TableCell>
        </TableRow>
        );
      }
      // live calculations
      if (this.state.price_type == 'adjust' && this.state.price_type_value) {
        priceChange = (this.state.modalData.staticPrice - price).toFixed(0);
        pricePercentage = ((priceChange * 100) / price).toFixed(0);
      }

      if (this.state.price_type == 'fix') {
        priceChange = (this.state.modalData.staticPrice - price).toFixed(0);
        pricePercentage = ((priceChange * 100) / price).toFixed(0);
      }


      return (
        <TableRow className={classes.pmsTableRow} style={this.getFontcolors(0)} style={{ paddingRight: "5px" }}>
          <TableCell align="left" className={code == 1 ? classes.pmsRowBlankBorder : classes.pmsNormalRow} style={{ paddingTop: (code == 0) ? '25px' : '0px' }}><Typography variant="subtitle1">{title}</Typography></TableCell>
          <TableCell align="right" className={code == 1 ? classes.pmsRowBlankBorder : classes.pmsNormalRow} style={{ paddingTop: (code == 0) ? '25px' : '0px' }}>
            <Typography variant="subtitle1" >
              <span style={{ fontWeight: '400' }}>
                {this.state.currency.symbol}
               {new Intl.NumberFormat('ja-JP').format(price) + ' '}
              </span>
            </Typography>
          </TableCell>
        </TableRow>
      )
    }
  }

  changeDays = (day) => {
    this.setState({
      days: day
    }, () => {
      this.monthChange(this.state.tableStartDate)
    });
  }

  getInventary = () => {
    if (this.state.pms_provider == 11 || this.state.pms_provider == 32) {
      let requestData = {
        file: this.state.file,
        token: this.props.hotelAuthReducer.hotelDetails.token
      };
      this.props.uploadFile(requestData);
    } else {
      let requestParams = {
        token: this.props.hotelAuthReducer.hotelDetails.token,
        start_date: moment(this.state.start_date).format('YYYY-MM-DD'),
        end_date: moment(this.state.end_date).format('YYYY-MM-DD'),
      }; 
      this.props.getInventary(requestParams);
    }
    this.setState({
      showPriceBtn: true,
    })
  }

  
  startRunPricing = (is_first) => {
    if(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider){
      this.setState({
        loadData: true,
      },
        () => this.runPricing(is_first)
      )
    }    
  }

  updateInventary = () => {

  }

  updateLastPricing() {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      last_run_pricing_type: this.state.last_run_pricing_type,
    }
    this.props.updateLastPricing(requestParam);
  }


  runPricing = (is_first) => {

    this.handleClickManualPriceClose()
    this.toggleAutoPriceRun()

    let start_date = moment(this.state.start_date).format('YYYY-MM-DD');
    let end_date = moment(this.state.end_date).format('YYYY-MM-DD');

    if (this.state.featuresEnabled.includes(15)) {
      start_date = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD');
      end_date = moment(start_date).add((this.state.featuresEnabled.includes(23) ? '545' : '365'), 'd').format('YYYY-MM-DD'); 
    }

    let requestData = {};

    if(![15, 34, 22, 45, 46, 48, 49, 50, 52, 53, 54, 57, 58, 56, 63, 69].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) && this.state.get_pms_data){
      requestData = {
        start_date: start_date,
        end_date: end_date,
        get_pms_data: true,
        command :"run_pricing_with_get_inventory"
      }
      this.setState({
        updatePmsStart: start_date,
        updatePmsEnd: end_date,
        roomcount: 0,
        socketLoader: true,
        loadPercentage: false,
        // stopCachedPrice: is_first ? false : true,
        get_pms_data: true,
        requestData
      },()=>{
          axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
          axios.post(Server.API + 'pricingalgorithm/get-inventory-or-run-price/', requestData)
          .then((response) => {
            this.props.getRunPriceFlags({ token: this.props.hotelAuthReducer.hotelDetails.token });
          })
      });
    }else{
      requestData = {
        start_date: start_date,
        end_date: end_date,
        get_pms_data: false,
        command: "run_pricing_on_demand",
        return_full_dates: false,
        pricing_type: parseInt(this.state.last_run_pricing_type)
      }
      this.setState({
        updatePmsStart: start_date,
        updatePmsEnd: end_date,
        roomcount: 0,
        socketLoader: true,
        loadPercentage: true,
        // stopCachedPrice: is_first ? false : true,
        get_pms_data: false,
        requestData
      },()=>{
        axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
        axios.post(Server.API + 'pricingalgorithm/get-inventory-or-run-price/', requestData)
        .then((response) => {
          this.props.getRunPriceFlags({ token: this.props.hotelAuthReducer.hotelDetails.token });
        })
      });
    }      
   

  }

  updatePricePms = (specificDate) => {
    
    let clientTimeZone = moment.tz.guess();
    let clientTime=moment().format('yyyy-MM-DD HH:mm:ss');
    let requestData = {};
    if (specificDate) {
      requestData = {
        specific_date: moment(specificDate).format('YYYY-MM-DD'),
        start_date: "",
        end_date: "",
        command: "upload_price_to_pms",
        disable_threshold: true,
        client_time:clientTime,
        client_timezone:clientTimeZone
      }

    } else {
      requestData = {
        start_date: moment(this.state.updatePmsStart).format('YYYY-MM-DD'),
        end_date: moment(this.state.updatePmsEnd).format('YYYY-MM-DD'),
        command: "upload_price_to_pms",
        disable_threshold: this.state.threashold, // for only protel hotel -> 15
        client_time:clientTime,
        client_timezone:clientTimeZone
      }
    }
    if (this.state.refRoomOnly) {
      requestData.room_id = this.state.selectedRoom;
    }

    this.setState({
      uploadLoadig: true
    })
    
    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
    axios.post(Server.API + 'pricingalgorithm/upload-price/', requestData)
    .then((response) => {
        this.setState({
            showUpdatePrice: false,
            uploadLoadig: false,
            loadingUplaod: false,      
            uploadSingleModal: false,
        })
        let messageData = {
          msg: `Sending Prices. Waiting for the ${this.state.pms_channel_manager_text} to confirm.`,
          is_success: true,
        }
        this.props.putNotify(messageData)
      }).catch((error) => {
        this.setState({            
            uploadLoadig: false,
            loadingUplaod: false,
            uploadSingleModal: false,
        })
        let messageData = {
          msg: 'Something Went Wrong!',
          is_success: false,
        }
        this.props.putNotify(messageData)
      })
      
  }


  changeBulkDate = (name) => dateValue => {
    if (name == 'bulkStartDate') {
      this.setState({
        bulkStartDate: moment(dateValue).format('YYYY-MM-DD'),
        bulkEndDate: moment(dateValue).format('YYYY-MM-DD')
      })
    } else {
      this.setState({
        [name]: dateValue,
      })
    }
  }

  initPusher = () => {
    if(this.props.commonReducer.initPusher){
      this.props.commonReducer.initPusher.bind('webhook.message', (evt)=> {
        if (evt.success) {
          let requestData = {
            msg: "Prices Uploaded Successfully",
            is_success: true,
          }
          this.props.putNotify(requestData)
          this.getHotelDetails();
        }
      });
  
      this.props.commonReducer.initPusher.bind('uploadprice.message', (evt)=> {
        if (evt.error){
          
          let requestData = {
            msg: "Prices update Failed",
            is_success: false,
          }

          if (evt && evt.message) {
            requestData.msg = evt.message;
          }

          this.props.putNotify(requestData)
          this.getHotelDetails();
          this.setState({
            loadUploadBtn: false,
            uploadLoadig: false,
            loadingUplaod: false,
          },()=>{
            this.getLattestPricing();
          })
  
        }else{
          if (this.state.showUpdatePrice) {
            this.setState({
              showUpdatePrice: false,
            })
          }
          
            if (evt.content && evt.content.specific_date) {
              this.setState({
                loadingSave: false,
                loadingUplaod: false,
                greySaveUpload: true,
                uploadSingleModal: false,
                PriceModalopen: false
              })
            }
  
            this.setState({
              loadUploadBtn: false,
              uploadLoadig: false,
            },()=>{
              this.getLattestPricing();
            })
  
            let requestData = {
              msg: this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 15 ? "Waiting for response" : "Prices Uploaded Successfully",
              is_success: true,
            }
            this.props.putNotify(requestData)
            this.getHotelDetails();
  
        }
  
      });
  
      this.props.commonReducer.initPusher.bind('inventory.message', (evt)=> {
        if (!evt.error) {
        this.setState({
          loadPercentage: true,
          })
        }else{
          let msg = `Something went Wrong While Fetching Values from ${this.state.pms_channel_manager_text}`;
          if (evt && evt.message) {
            msg = evt.message;
          }
          let requestData = {
            msg: msg,
            is_success: false,
          }
          this.props.putNotify(requestData);
  
          this.getLattestPricing()
          this.setState({
            loadPercentage: false,
            socketLoader: false,
            loadData: false,
            autoRunModal: false,
            ManuallPriceOpen: false,
          })
        }
      });
      
      this.props.commonReducer.initPusher.bind('runpricing.message', (evt)=> {      
          if (!evt.error) {
              var received_msg = evt.content;
                this.updateLastPricing();
               
                this.setState({
                  loadPercentage: false,
                  autoRunModal: false,
                  socketLoader: false,
                  ManuallPriceOpen: false,
                  loadData: false,
                  showDownloadCsv: this.state.showCsvPrice,
                },
                  () => {
                    this.getCalendarData();
                    this.getHotelDetails();
                    this.getLattestPricing();
                    
                    if (this.state.showCsvPrice) {
                      setTimeout(() => {
                        this.getPricingCsv(this.state.start_date, this.state.end_date)
                      }, 2000);
                    }
                    // if (this.props.hotelAuthReducer.hotelDetails.hotel.pms_sync == 1 || this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type || this.state.pms_provider == 11 || this.state.pms_provider == 32 || this.state.pms_provider == 5) {
                    //   this.props.roomList.roomList.map((rooms, index) => {
                    //     this.getInginiusCsv(rooms.id, received_msg.start_date, received_msg.end_date)
                    //   })
                    // }
      
                    if (this.state.bulkDateAdjModal) {
                      let requestData = {
                        msg: 'Data Updated Successfully',
                        is_success: true,
                      }
                      this.props.putNotify(requestData)
                    }    
                  }
                );
                
  
                } else {
                  let msg = `Something went Wrong While Fetching Values from ${this.state.pms_channel_manager_text}`;
                  if (evt && evt.message) {
                    msg = evt.message;
                  }
                  let requestData = {
                    msg: msg,
                    is_success: false,
                  }
                  this.props.putNotify(requestData);
      
                  this.getLattestPricing()
                  this.setState({
                    loadPercentage: false,
                    socketLoader: false,
                    loadData: false,
                    autoRunModal: false,
                    ManuallPriceOpen: false,
                  })
                }
      }); 
    }

  }


  changeBulkDate = (name) => dateValue => {
    if (name == 'bulkStartDate') {
      this.setState({
        bulkStartDate: dateValue,
        bulkEndDate: moment(dateValue).format('YYYY-MM-DD')
      },()=>{
        this.calculateBulkSumOfAmount(this.state.bulkStartDate,this.state.bulkEndDate);
      })
    } else {
      this.setState({
        [name]: dateValue,
      },()=>{
        this.calculateBulkSumOfAmount(this.state.bulkStartDate,this.state.bulkEndDate);
      })
    }
  }

  changeDate = (name) => dateValue => {
    this.setState({
      [name]: dateValue,
      last_run_pricing_type: 0,
    })
  }

  handleDiagnoChange = (name) => event => {
    this.setState({
      [name]: event.target.value == "false" ? true : false
    })
  }

  changeOnlyPmsRun = () => event => {
    let value = event.target.value == 'false' ? false : true;
    this.setState({
      get_pms_data: !value
    })
  }

  monthChange = (date) => {
    var check = moment(date);

    let daysList = [];
    if (this.state.days === '7') {
      daysList = [
        check.format('MMM DD'),
        check.add(1, 'd').format('MMM DD'),
        check.add(1, 'd').format('MMM DD'),
        check.add(1, 'd').format('MMM DD'),
        check.add(1, 'd').format('MMM DD'),
        check.add(1, 'd').format('MMM DD'),
        check.add(1, 'd').format('MMM DD'),
      ];
    }
    this.setState({
      daysList: daysList,
      tableStartDate: moment(this.state.tableStartDate).add('7', 'd'),
    })
  }

  changeStartDate = (date) => {
    let nextStartDate = moment(date).format('YYYY-MM-DD');
    let daysList = [];

    const startOfMonth = moment(nextStartDate).startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment(nextStartDate).endOf('month').format('YYYY-MM-DD');

    for (var m = moment(startOfMonth); m.isSameOrBefore(endOfMonth); m.add(1, 'days')) {
      if (m.isSameOrAfter(moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'))) {
        daysList.push(moment(m.format('YYYY-MM-DD')));
      }
    }

    var a = moment(endOfMonth);
    var b = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone);
    let nextMonth = moment(startOfMonth).add(1, 'month').startOf('month').format('YYYY-MM-DD');

    if (a.diff(b, 'days') <= 30) {
      let count = Math.abs(30 - a.diff(b, 'days'));
      let i = 0;
      for (i == 0; i < count; i++) {
        daysList.push(moment(nextMonth).add(i, 'd'))
      }
    }

    this.setState({
      daysList: daysList,
      tableStartDate: startOfMonth,
      tableStartDate: nextStartDate
    })
  }

  getNextDays = () => {
    let nextStartDate = moment(this.state.tableStartDate).add(1, 'month').format('YYYY-MM-DD');
    let daysList = [];

    const startOfMonth = moment(nextStartDate).startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment(nextStartDate).endOf('month').format('YYYY-MM-DD');

    for (var m = moment(startOfMonth); m.isSameOrBefore(endOfMonth); m.add(1, 'days')) {
      if (m.isSameOrAfter(moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'))) {
        daysList.push(moment(m.format('YYYY-MM-DD')));
      }
    }

    var a = moment(endOfMonth);
    var b = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone);
    let nextMonth = moment(startOfMonth).add(1, 'month').startOf('month').format('YYYY-MM-DD');

    if (a.diff(b, 'days') <= 30) {
      let count = Math.abs(30 - a.diff(b, 'days'));
      let i = 0;
      for (i == 0; i < count; i++) {
        daysList.push(moment(nextMonth).add(i, 'd'))
      }
    }

    this.setState({
      daysList: daysList,
      tableStartDate: endOfMonth,
    })
  }

  getPrevDays = () => {

    let nextStartDate = moment(this.state.tableStartDate).subtract(1, 'month').format('YYYY-MM-DD');
    if (moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).isAfter(nextStartDate, 'month')) {
      return null;
    }
    let daysList = [];

    const startOfMonth = moment(nextStartDate).startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment(nextStartDate).endOf('month').format('YYYY-MM-DD');

    for (var m = moment(startOfMonth); m.isSameOrBefore(endOfMonth); m.add(1, 'days')) {
      if (m.isSameOrAfter(moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'))) {
        daysList.push(moment(m.format('YYYY-MM-DD')));
      }
    }

    var a = moment(endOfMonth);
    var b = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone);
    let nextMonth = moment(startOfMonth).add(1, 'moznth').startOf('month').format('YYYY-MM-DD');

    if (a.diff(b, 'days') <= 30) {
      let count = Math.abs(30 - a.diff(b, 'days'));
      let i = 0;
      for (i == 0; i < count; i++) {
        daysList.push(moment(nextMonth).add(i, 'd'))
      }
    }

    this.setState({
      daysList: daysList,
      tableStartDate: nextStartDate,
    })
  }

  handleClickOpen = (event, roomId, changeTab) => {
    if(window.Appcues){
      window.Appcues.page();
    }
    let start = moment(event.start).format('YYYY-MM-DD');
    let checkAvailablity = this.state.calendarData[roomId].filter(val => val.date == start);
    let setting = settingJsonData;
    if (checkAvailablity && checkAvailablity.length > 0) {
      let greySaveUpload = false;
      let eventRequest = {
        start_date: start,
        end_date: start,
        event_types: this.props.hotelAuthReducer.hotelDetails.hotel.event_types ? this.props.hotelAuthReducer.hotelDetails.hotel.event_types.toString() : "2",
        token: this.props.hotelAuthReducer.hotelDetails.token
      };
      // this.props.getEventList(eventRequest);
      this.props.getEventCategory(eventRequest); 
      let modalData = this.state.calendarData[roomId].filter(val => val.date == start)[0];

      modalData.staticPrice = modalData.price;

      let roomRemainingData = [];
      let max_occupancy = '';
  
      this.props.roomList.roomList.filter(val => val.is_reference_room).map((room) => {
        if (room.is_reference_room) {
          max_occupancy = setting.rooms.reference.max_occupancy;
        } else {
          max_occupancy = setting.rooms.derived[room.id].max_occupancy;
        }

        if (room.is_reference_room && this.state.pricing_result[start] && this.state.pricing_result[start][room.id] && this.state.pricing_result[start][room.id].no_market_data) {
          modalData.no_market_data = true;
        }

        if (!this.state.pricing_result[start].system_skip) {
          let inventory_remaining = this.state.pricing_result[start]["property"].all_rooms_inventories ? this.state.pricing_result[start]["property"].all_rooms_inventories[room.id] : null;
          let roomCalData = this.state.calendarData[room.id] ? this.state.calendarData[room.id].filter(val => val.date == start)[0] : null;
          if (inventory_remaining != undefined) {
            let showPrice = ((room.pms_rate || [39, 42, 52, 35].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider)) && roomCalData) ? true : false;
            roomRemainingData.push({ id: room.id, name: room.name, inventory: inventory_remaining, price: showPrice ? roomCalData.price : "", defaultOccupancy: room.default_occupancy == 0 || !room.default_occupancy ? max_occupancy : room.default_occupancy })
          }
        }
      })

      this.props.roomList.roomList.filter(val => !val.is_reference_room).map((room) => {
        if (room.is_reference_room) {
          max_occupancy = setting.rooms.reference.max_occupancy;
        } else {
          max_occupancy = setting.rooms.derived[room.id].max_occupancy;
        }

        if (room.is_reference_room && this.state.pricing_result[start] && this.state.pricing_result[start][room.id] && this.state.pricing_result[start][room.id].no_market_data) {
          modalData.no_market_data = true;
        }

        if (!this.state.pricing_result[start].system_skip) {
          let inventory_remaining = this.state.pricing_result[start]["property"].all_rooms_inventories ? this.state.pricing_result[start]["property"].all_rooms_inventories[room.id] : null;
          let roomCalData = this.state.calendarData[room.id] ? this.state.calendarData[room.id].filter(val => val.date == start)[0] : null;
          if (inventory_remaining != undefined) {
            let showPrice = ((room.pms_rate || [39, 42, 52, 35].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider)) && roomCalData) ? true : false;
            roomRemainingData.push({ id: room.id, name: room.name, inventory: inventory_remaining, price: showPrice ? roomCalData.price : "", defaultOccupancy: room.default_occupancy == 0 || !room.default_occupancy ? max_occupancy : room.default_occupancy })
          }
        }
      })

      let note = "";

      let singleAdjust = this.state.adjustDates.filter(opt => opt.date == modalData.date);
      let singleAdjustment = 0;
      if (singleAdjust.length > 0) {
        greySaveUpload = true;
        singleAdjustment = singleAdjust[0].percentage;
      }

      let month = moment(modalData.date).format('MMMM');
      let day = moment(modalData.date).format('dddd').toLowerCase();

      let weekData = { ...this.state.weeklyData[day] };
      let monthData = { ...this.state.monthlyData[month] };

      let weekPer = parseFloat(weekData.standard);
      let monthPer = parseFloat(monthData.standard);

      let geniePrice = modalData.suggested_price_without_restriction;
      let percentageAdj = (1 + (weekPer / 100)) * (1 + (monthPer / 100));
      let newPrice = (percentageAdj * geniePrice).toFixed(0);

      let roomData = this.props.roomList.roomList.filter(val => val.id == roomId)[0];

      let skipName = "";
      let fixedPrice = "";
      let isSkipDateModal = false;
      let deriveFromRef = true;

      if (modalData.skip_price) {
        greySaveUpload = true;
        skipName = modalData.skip_price.name;
        // if (roomData.is_reference_room) {
        fixedPrice = modalData.skip_price.fixed_price;
        // } else {
        //   fixedPrice = modalData.price;
        // }

        if (fixedPrice == null) {
          isSkipDateModal = true;
        } else {
          deriveFromRef = true
        }
      }



      modalData.leadPer = 0;

      let daysDiff = moment(modalData.date).diff(moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'), 'days');

      let leadTimeAdjustment = this.state.leadTimeAdjustment;
      if (leadTimeAdjustment) {
        

        if (daysDiff == 0) {
          modalData.leadPer = leadTimeAdjustment.last_day;
        } else if (daysDiff >= 1 && daysDiff <= 2) {
          modalData.leadPer = leadTimeAdjustment.two_days_to_three_days;
        } else if (daysDiff >= 3 && daysDiff <= 6) {
          modalData.leadPer = leadTimeAdjustment.four_days_to_seven_days;
        } else if (daysDiff >= 7 && daysDiff <= 13) {
          modalData.leadPer = leadTimeAdjustment.one_week_to_two_weeks;
        } else if (daysDiff >= 14 && daysDiff <= 27) {
          modalData.leadPer = leadTimeAdjustment.two_weeks_to_four_weeks;
        } else if (daysDiff >= 28 && daysDiff <= 43) {
          modalData.leadPer = leadTimeAdjustment.four_weeks_to_six_weeks;
        } else if (daysDiff >= 44 && daysDiff <= 88) {
          modalData.leadPer = leadTimeAdjustment.one_half_months_to_three_months;
        } else if (daysDiff >= 89 && daysDiff <= 178) {
          modalData.leadPer = leadTimeAdjustment.three_months_to_six_months;
        } else if (daysDiff >= 178 ) {
          modalData.leadPer = leadTimeAdjustment.six_months_plus;
        } else {
          modalData.leadPer = 0;
        }
      }
      this.toggleAdjustmentViewModal(start);

      let weather = {}
      if (this.props.commonReducer.weatherList && this.props.commonReducer.weatherList.length > 0) {
        let weatherday = this.props.commonReducer.weatherList.filter(val => val.date == start)
        if (weatherday.length > 0) {
          weather = weatherday[0];
        }
      }

      let competitorData = this.state.competitorRates[start] ? this.state.competitorRates[start] : null;

      let bulkUpdateData = this.state.bulkUpdateData

      let min_price_array = {};
      let max_price_array = {};
      let derivation_array = {};
      let fixprice_array = {};

      let refroom = this.props.roomList.roomList.filter(val => val.is_reference_room)[0];

      let ref_min_price = setting.default[refroom.id].min_price;
      let ref_max_price = setting.default[refroom.id].max_price;

      let pricing_result = this.state.pricing_result[start];
      let total_closed_room_pms = 0;

      Object.keys(setting.default).map((key) => {
        let min_price = setting.default[key].min_price;
        let max_price = setting.default[key].max_price;
        let derivation = setting.default[key].adjustment_to_reference_room;
        let fix_price = "";


        if (this.state.featuresEnabled.includes(1) && setting.dates && setting.dates[modalData.date] && setting.dates[modalData.date][key]) {

          if (setting.dates[modalData.date][key].min_price != undefined) {
            min_price = setting.dates[modalData.date][key].min_price;
            if (refroom.id == key) {
              ref_min_price = setting.dates[modalData.date][key].min_price;
            }
          }

          if (setting.dates[modalData.date][key].max_price != undefined) {
            max_price = setting.dates[modalData.date][key].max_price;
            if (refroom.id == key) {
              ref_max_price = setting.dates[modalData.date][key].max_price;
            }
          }

          if (setting.dates[modalData.date][key].adjustment_to_reference_room != undefined) {
            derivation = setting.dates[modalData.date][key].adjustment_to_reference_room;
          }

        }

        if (setting.dates && setting.dates[modalData.date] && setting.dates[modalData.date][key] && setting.dates[modalData.date][key].fix_price) {
          fix_price = setting.dates[modalData.date][key].fix_price;
        }

        min_price_array[key] = min_price;
        max_price_array[key] = max_price;
        derivation_array[key] = Math.round(derivation);
        fixprice_array[key] = fix_price;

        if (refroom.id == key && fixedPrice) {
          fixprice_array[key] = fixedPrice;
        }
      })

      if (!this.state.featuresEnabled.includes(6)) {
        this.calculateDerivePrice1(ref_min_price, "minPrice", roomId, true);
        this.calculateDerivePrice1(ref_max_price, "maxPrice", roomId, true);
      }

      if(!changeTab){
        this.setState({
          modalTab: this.state.viewType == "adjustment" ? 2 : 1
        })
      }      

      let tempCloseRooms = {};

      if (!this.state.featuresEnabled.includes(29)) {
        this.props.roomList.roomList.map(room=>{
          if (setting.dates && setting.dates[modalData.date] && setting.dates[modalData.date][room.id] && setting.dates[modalData.date][room.id].closed_rooms) {
            tempCloseRooms[room.id] = setting.dates[modalData.date][room.id].closed_rooms;
            total_closed_room_pms += setting.dates[modalData.date][room.id].closed_rooms;
          } 
        })

      }else{
          Object.keys(pricing_result).map((roomkey) => {                
              if(modalData.all_closed_rooms && modalData.all_closed_rooms[roomkey]){
                tempCloseRooms[roomkey] = modalData.all_closed_rooms[roomkey];
              }
          })
          total_closed_room_pms = modalData.closed_rooms;
      }

        if(fixprice_array[roomId]){
          modalData.skip_price = {fixed_price: fixprice_array[roomId], name: ""};
        }
      if(daysDiff >= 0){
        modalData.isPastDate = false
      }else{
        modalData.isPastDate = true
      }

      modalData.total_closed_room_pms = total_closed_room_pms;

      if (modalData) {
        this.setState({
          tempCloseRooms,
          deriveFromRef,
          competitorData,
          weather: weather,
          tempUpload: this.state.pricing_result[start],
          isSkipDateModal: isSkipDateModal,
          bulkUpdateData: {
            ...this.state.bulkUpdateData,
            adjVsRef: derivation_array,
            maxPrice: max_price_array,
            minPrice: min_price_array,
            skipName: skipName,
            fixedPrice: fixprice_array,
            dailyAdjust: singleAdjustment,
          },
          roomRemainingData: roomRemainingData,
          // modalMinPrice: min_price,
          // modalmaxPrice: max_price,
          weekPercentage: weekPer,
          monthPercentage: monthPer,
          price_type_value_error: false,
          popupRoomData: roomData,
          greySaveUpload: true,
          suggestedModalPrice: newPrice,
          loadingSave: false,
          singleAdjustment: singleAdjustment,
          priceNote: note,
          allEvents: false,
          modalData: modalData,
          PriceModalopen: true,
          error: [],
          popupError: {},
          notesStartDate:moment(modalData.date).format('YYYY-MM-DD'),
          notesEndDate:moment(modalData.date).format('YYYY-MM-DD'),
          noteEditEnable:false,
          editNotesValue:{}
        });
      }
    } else {
      return null;
    }
  };


  handleGraphClick = (graphData) => {
    if (graphData[0]) {
      let date = graphData[0]._chart.config.data.labels[graphData[0]._index];

      let preferred_date;
      if(this.props.hotelDetail && this.props.hotelDetail.prefered_date_format){
        preferred_date=this.props.hotelDetail ? this.props.hotelDetail.prefered_date_format.replace(', hh:mm A',''):'DD/MM/YYYY';
      }else{
        preferred_date='DD/MM/YYYY'
      }

      let start = moment(date, ('ddd,'+preferred_date)).format('YYYY-MM-DD');      
      let roomId = this.state.selectedRoom;

      let checkAvailablity = this.state.calendarData[roomId].filter(val => val.date == start);
      let setting = settingJsonData;
      if (checkAvailablity && checkAvailablity.length > 0) {
      let greySaveUpload = false;
      let eventRequest = {
        start_date: start,
        end_date: start,
        event_types: this.props.hotelAuthReducer.hotelDetails.hotel.event_types ? this.props.hotelAuthReducer.hotelDetails.hotel.event_types.toString() : "2",
        token: this.props.hotelAuthReducer.hotelDetails.token
      };

      // this.props.getEventList(eventRequest);
      this.props.getEventCategory(eventRequest);

      let modalData = this.state.pricing_result[start][roomId];      

      modalData.staticPrice = modalData.price;

      let roomRemainingData = [];
      let max_occupancy = '';
  
      this.props.roomList.roomList.filter(val => val.is_reference_room).map((room) => {
        if (room.is_reference_room) {
          max_occupancy = setting.rooms.reference.max_occupancy;
        } else {
          max_occupancy = setting.rooms.derived[room.id].max_occupancy;
        }

        if (room.is_reference_room && this.state.pricing_result[start] && this.state.pricing_result[start][room.id] && this.state.pricing_result[start][room.id].no_market_data) {
          modalData.no_market_data = true;
        }

        if (!this.state.pricing_result[start].system_skip) {
          let inventory_remaining = this.state.pricing_result[start]["property"].all_rooms_inventories ? this.state.pricing_result[start]["property"].all_rooms_inventories[room.id] : null;
          let roomCalData = this.state.calendarData[room.id] ? this.state.calendarData[room.id].filter(val => val.date == start)[0] : null;
          if (inventory_remaining != undefined) {
            let showPrice = ((room.pms_rate || [39, 42, 52, 35].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider)) && roomCalData) ? true : false;
            roomRemainingData.push({ id: room.id, name: room.name, inventory: inventory_remaining, price: showPrice ? roomCalData.price : "", defaultOccupancy: room.default_occupancy == 0 || !room.default_occupancy ? max_occupancy : room.default_occupancy })
          }
        }
      })

      this.props.roomList.roomList.filter(val => !val.is_reference_room).map((room) => {
        if (room.is_reference_room) {
          max_occupancy = setting.rooms.reference.max_occupancy;
        } else {
          max_occupancy = setting.rooms.derived[room.id].max_occupancy;
        }

        if (room.is_reference_room && this.state.pricing_result[start] && this.state.pricing_result[start][room.id] && this.state.pricing_result[start][room.id].no_market_data) {
          modalData.no_market_data = true;
        }

        if (!this.state.pricing_result[start].system_skip) {
          let inventory_remaining = this.state.pricing_result[start]["property"].all_rooms_inventories ? this.state.pricing_result[start]["property"].all_rooms_inventories[room.id] : null;
          let roomCalData = this.state.calendarData[room.id] ? this.state.calendarData[room.id].filter(val => val.date == start)[0] : null;
          if (inventory_remaining != undefined) {
            let showPrice = ((room.pms_rate || [39, 42, 52, 35].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider)) && roomCalData) ? true : false;
            roomRemainingData.push({ id: room.id, name: room.name, inventory: inventory_remaining, price: showPrice ? roomCalData.price : "", defaultOccupancy: room.default_occupancy == 0 || !room.default_occupancy ? max_occupancy : room.default_occupancy })
          }
        }
      })

      // let note = "";

      // if (setting.dates && setting.dates[modalData.date] && setting.dates[modalData.date].price_note) {        
      //   note = setting.dates[modalData.date].price_note;
      // }

      let singleAdjust = this.state.adjustDates.filter(opt => opt.date == modalData.date);
      let singleAdjustment = 0;
      if (singleAdjust.length > 0) {
        greySaveUpload = true;
        singleAdjustment = singleAdjust[0].percentage;
      }

      let month = moment(modalData.date).format('MMMM');
      let day = moment(modalData.date).format('dddd').toLowerCase();

      let weekData = { ...this.state.weeklyData[day] };
      let monthData = { ...this.state.monthlyData[month] };

      let weekPer = parseFloat(weekData.standard);
      let monthPer = parseFloat(monthData.standard);

      let geniePrice = modalData.suggested_price_without_restriction;
      let percentageAdj = (1 + (weekPer / 100)) * (1 + (monthPer / 100));
      let newPrice = (percentageAdj * geniePrice).toFixed(0);

      let roomData = this.props.roomList.roomList.filter(val => val.id == roomId)[0];


      let min_price = setting.default[roomId].min_price;
      let max_price = setting.default[roomId].max_price;
      let derivation = setting.default[roomId].adjustment_to_reference_room;

      if (this.state.featuresEnabled.includes(1) && setting.dates && setting.dates[modalData.date] && setting.dates[modalData.date][roomId]) {
        if (setting.dates[modalData.date][roomId].min_price) {
          min_price = setting.dates[modalData.date][roomId].min_price;
        }
        if (setting.dates[modalData.date][roomId].max_price) {
          max_price = setting.dates[modalData.date][roomId].max_price;
        }
        if (setting.dates[modalData.date][roomId].adjustment_to_reference_room) {
          derivation = setting.dates[modalData.date][roomId].adjustment_to_reference_room;
        }
      }

      if (newPrice < min_price) { newPrice = min_price }
      if (newPrice > max_price) { newPrice = max_price }


      let skipName = "";
      let fixedPrice = "";
      let isSkipDateModal = false;
      let deriveFromRef = true;

      if (modalData.skip_price) {
        greySaveUpload = true;
        skipName = modalData.skip_price.name;
        // if (roomData.is_reference_room) {
        fixedPrice = modalData.skip_price.fixed_price;
        // } else {
        //   fixedPrice = modalData.price;
        // }

        if (fixedPrice == null) {
          isSkipDateModal = true;
        } else {
          deriveFromRef = true
        }
      }



      modalData.leadPer = 0;

      let leadTimeAdjustment = this.state.leadTimeAdjustment;
      if (leadTimeAdjustment) {
        var daysDiff = moment(modalData.date).diff(moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'), 'days');

        if (daysDiff == 0) {
          modalData.leadPer = leadTimeAdjustment.last_day;
        } else if (daysDiff >= 1 && daysDiff <= 2) {
          modalData.leadPer = leadTimeAdjustment.two_days_to_three_days;
        } else if (daysDiff >= 3 && daysDiff <= 6) {
          modalData.leadPer = leadTimeAdjustment.four_days_to_seven_days;
        } else if (daysDiff >= 7 && daysDiff <= 13) {
          modalData.leadPer = leadTimeAdjustment.one_week_to_two_weeks;
        } else if (daysDiff >= 14 && daysDiff <= 27) {
          modalData.leadPer = leadTimeAdjustment.two_weeks_to_four_weeks;
        } else if (daysDiff >= 28 && daysDiff <= 43) {
          modalData.leadPer = leadTimeAdjustment.four_weeks_to_six_weeks;
        } else if (daysDiff >= 44 && daysDiff <= 88) {
          modalData.leadPer = leadTimeAdjustment.one_half_months_to_three_months;
        } else if (daysDiff >= 89 && 178) {
          modalData.leadPer = leadTimeAdjustment.three_months_to_six_months;
        } else if (daysDiff >= 178 ) {
          modalData.leadPer = leadTimeAdjustment.six_months_plus;
        } else {
          modalData.leadPer = 0;
        }
      }
      this.toggleAdjustmentViewModal(start);

      let weather = {}
      if (this.props.commonReducer.weatherList && this.props.commonReducer.weatherList.length > 0) {
        let weatherday = this.props.commonReducer.weatherList.filter(val => val.date == start)
        if (weatherday.length > 0) {
          weather = weatherday[0];
        }
      }

      let competitorData = this.state.competitorRates[start] ? this.state.competitorRates[start] : null;

      let bulkUpdateData = this.state.bulkUpdateData

      let min_price_array = {};
      let max_price_array = {};
      let derivation_array = {};
      let fixprice_array = {};

      let refroom = this.props.roomList.roomList.filter(val => val.is_reference_room)[0];

      let ref_min_price = setting.default[refroom.id].min_price;
      let ref_max_price = setting.default[refroom.id].max_price;

      Object.keys(setting.default).map((key) => {
        let min_price = setting.default[key].min_price;
        let max_price = setting.default[key].max_price;
        let derivation = setting.default[key].adjustment_to_reference_room;
        let fix_price = "";


        if (this.state.featuresEnabled.includes(1) && setting.dates && setting.dates[modalData.date] && setting.dates[modalData.date][key]) {

          if (setting.dates[modalData.date][key].min_price != undefined) {
            min_price = setting.dates[modalData.date][key].min_price;
            if (refroom.id == key) {
              ref_min_price = setting.dates[modalData.date][key].min_price;
            }
          }

          if (setting.dates[modalData.date][key].max_price != undefined) {
            max_price = setting.dates[modalData.date][key].max_price;
            if (refroom.id == key) {
              ref_max_price = setting.dates[modalData.date][key].max_price;
            }
          }

          if (setting.dates[modalData.date][key].adjustment_to_reference_room != undefined) {
            derivation = setting.dates[modalData.date][key].adjustment_to_reference_room;
          }

        }

        if (setting.dates && setting.dates[modalData.date] && setting.dates[modalData.date][key] && setting.dates[modalData.date][key].fix_price) {
          fix_price = setting.dates[modalData.date][key].fix_price;
        }

        min_price_array[key] = min_price;
        max_price_array[key] = max_price;
        derivation_array[key] = Math.round(derivation);
        fixprice_array[key] = fix_price;

        if (refroom.id == key && fixedPrice) {
          fixprice_array[key] = fixedPrice;
        }
      })

      if (!this.state.featuresEnabled.includes(6)) {
        this.calculateDerivePrice1(ref_min_price, "minPrice", roomId, true);
        this.calculateDerivePrice1(ref_max_price, "maxPrice", roomId, true);
      }

      this.setState({
        modalTab: this.state.viewType == "adjustment" ? 2 : 1
      })

      let tempCloseRooms = {};
      let total_closed_room_pms = 0;

      if (!this.state.featuresEnabled.includes(29)) {
        this.props.roomList.roomList.map(room=>{
          if (setting.dates && setting.dates[modalData.date] && setting.dates[modalData.date][room.id] && setting.dates[modalData.date][room.id].closed_rooms) {
            tempCloseRooms[room.id] = setting.dates[modalData.date][room.id].closed_rooms;
            total_closed_room_pms += setting.dates[modalData.date][room.id].closed_rooms
          } 
        })

      }else{
          Object.keys(this.state.pricing_result).map((roomkey) => {                
              if(modalData.all_closed_rooms && modalData.all_closed_rooms[roomkey]){
                tempCloseRooms[roomkey] = modalData.all_closed_rooms[roomkey];
              }
          })
          total_closed_room_pms = modalData.closed_rooms
      }      
      modalData.total_closed_room_pms = total_closed_room_pms;

      if(fixprice_array[roomId]){
        modalData.skip_price = {fixed_price: fixprice_array[roomId], name: ""};
      }

      if (modalData) {
        this.setState({
          tempCloseRooms,
          deriveFromRef,
          competitorData,
          weather: weather,
          tempUpload: this.state.pricing_result[start],
          isSkipDateModal: isSkipDateModal,
          bulkUpdateData: {
            ...this.state.bulkUpdateData,
            adjVsRef: derivation_array,
            maxPrice: max_price_array,
            minPrice: min_price_array,
            skipName: skipName,
            fixedPrice: fixprice_array,
            dailyAdjust: singleAdjustment,
          },
          roomRemainingData: roomRemainingData,
          // modalMinPrice: min_price,
          // modalmaxPrice: max_price,
          weekPercentage: weekPer,
          monthPercentage: monthPer,
          price_type_value_error: false,
          popupRoomData: roomData,
          greySaveUpload: true,
          suggestedModalPrice: newPrice,
          loadingSave: false,
          singleAdjustment: singleAdjustment,
          priceNote: '',
          allEvents: false,
          modalData: modalData,
          PriceModalopen: true,
          error: [],
          popupError: {},
          notesStartDate:moment(modalData.date).format('YYYY-MM-DD'),
          notesEndDate:moment(modalData.date).format('YYYY-MM-DD'),
          noteEditEnable:false,
          editNotesValue:{}
          // notesStartDate:moment(modalData.date).format('YYYY-MM-DD'),
          // notesEndDate:moment(modalData.date).format('YYYY-MM-DD'),
        });
      }
    } else {
      return null;
    }

    }
  };

  handleClose = () => {
    this.setState({ 
      PriceModalopen: false,
      notesStartEndDate:false,
      noteEditEnable:false,
      notesStartDate:moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'),
      notesEndDate:moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'),
     });
  };

  saveSkipAdjustData = (isUpload) => {
    let error = {
      maxPrice: [],
      minPrice: [],
      fixedPrice: []
    };

    let bulkUpdateData = this.state.bulkUpdateData; 
    let refroom = this.props.roomList.roomList.filter(val => val.is_reference_room)[0];

    Object.keys(this.state.bulkUpdateData.maxPrice).map((key) => {
      if ((this.state.featuresEnabled.includes(6) || refroom.id == key) && ((bulkUpdateData.maxPrice[key] === "0" || bulkUpdateData.maxPrice[key] === 0) || (bulkUpdateData.minPrice[key] && bulkUpdateData.maxPrice[key] && parseFloat(bulkUpdateData.minPrice[key]) > parseFloat(bulkUpdateData.maxPrice[key])))&& (!this.state.refRoomOnly || refroom.id == key)) {
        error.maxPrice.push(parseInt(key))
      }
    })

    Object.keys(this.state.bulkUpdateData.minPrice).map((key) => {
      if ((this.state.featuresEnabled.includes(6) || refroom.id == key) && (bulkUpdateData.minPrice[key] === "0" || bulkUpdateData.minPrice[key] === 0) && (!this.state.refRoomOnly || refroom.id == key)) {
        error.minPrice.push(parseInt(key))
      }
    })

    Object.keys(this.state.bulkUpdateData.fixedPrice).map((key) => {
      if ((bulkUpdateData.fixedPrice[key] === "0" || bulkUpdateData.fixedPrice[key] === 0) && (!this.state.refRoomOnly || refroom.id == key)) {
        error.fixedPrice.push(parseInt(key))
      }
    })

    if (error.fixedPrice.length > 0 || error.maxPrice.length > 0 || error.minPrice.length > 0) {
      this.setState({
        popupError: error,
        adjTab: error.fixedPrice.length ? "fix" :  "minmax",
        modalTab: 2
      })
    } else {


      calendarEvents.saveSkipAdjustData(this, settingJsonData, isUpload)
      this.setState({
        popupError: {},
      })
    }
  }
  renderOneDate = (response, isMultiDate) => {
    let jsx = [];
    let jsxKey = [];
    let pricing_result = this.state.pricing_result;
    if (isMultiDate) {
      Object.keys(response).map(function (key) {
        pricing_result[key] = response[key];
      })
    } else {

      pricing_result[this.state.modalData.date] = response;
    }
    let newModalData = { ...this.state.modalData, ...response[this.state.selectedRoom] };

    Object.keys(pricing_result).map((key) => {
      if (!pricing_result[key].system_skip) {
        Object.keys(pricing_result[key]).map((roomkey) => {
          if (!jsxKey.includes(roomkey)) {
            jsx[roomkey] = [];
            jsxKey.push(roomkey);
          }

          if (!pricing_result[key].error) {
            pricing_result[key][roomkey] = {
              date: key,
              total_inventory: pricing_result[key]['property'].inventory_remaining,
              total_closed: pricing_result[key]['property'].closed_rooms,
              ...pricing_result[key]['property'],
              ...pricing_result[key][roomkey],
              inventory_remaining: pricing_result[key]['property'].all_rooms_inventories ? pricing_result[key]['property'].all_rooms_inventories[roomkey] : null,              
            };

            jsx[roomkey].push(pricing_result[key][roomkey])
          }
          return null;
        })
      } else {
        let skipData = {};
        this.props.roomList.roomList.map((rooms, index) => {
          if (!jsxKey.includes(rooms.id.toString())) {
            jsx[rooms.id] = [];
            jsxKey.push(rooms.id.toString());
          }
          jsx[rooms.id].push(pricing_result[key])
        })
      }
      return null;
    });

    this.setState({
      pricing_result: pricing_result,
      calendarData: jsx,
      modalData: newModalData,
    },
      () => {
        this.getCalendarData();
      }
    )
  }


  handleDailyUpdate = () => event => {
    this.setState({
      singleAdjustment: event.target.value,
    })
  }

  updateDailyAdjustment = () => {
    let singleAdjust = this.props.dailyAdjustmentReducer.dailyAdjustment.filter(opt => opt.date == this.state.modalData.date);
    if (singleAdjust.length > 0) {
      let req = {
        id: singleAdjust[0].id,
        token: this.props.hotelAuthReducer.hotelDetails.token,
        date: this.state.modalData.date,
        percentage: this.state.singleAdjustment
      }
      this.props.updateDailyAdjust(req);
    } else {
      let req = {
        token: this.props.hotelAuthReducer.hotelDetails.token,
        date: this.state.modalData.date,
        percentage: this.state.singleAdjustment
      }
      this.props.dailyAdjustCreate(req);
    }
  }

  updateSkipDate = () => {
    if (this.state.skipData.id) {
      let req = {
        ...this.state.skipData,
        editSkipId: this.state.skipData.id,
        token: this.props.hotelAuthReducer.hotelDetails.token,
      }
      this.props.updateSkipPrice(req);
    } else {
      let req = {
        ...this.state.skipData,
        token: this.props.hotelAuthReducer.hotelDetails.token,
      }
      this.props.skipPriceCreate(req);
    }
  }

  runPricingAgain = () => {
    this.setState({
      start_date: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'),
      end_date: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).add('1', 'M').format('YYYY-MM-DD'),
      get_pms_data: false,
      openDateAdjustment: false,
      PriceModalopen: false,
      openSkipDate: false,
    },
      () => { this.runPricing(); }
    );
  }

  handleClickManualPrice = (is_runPricing) => {
    this.setState({
      inventory_data: [],
      ManuallPriceOpen: true,
      showPriceBtn: is_runPricing ? true : false,
      is_runPricing: is_runPricing,
      get_pms_data: (this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) ? false : true,
    });
  };

  handleClickManualPriceClose = () => {
    this.setState({ ManuallPriceOpen: false });
  };

  eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = event.backgroundColor;
    let height = '90px';

    var style = {
      width: '99%',
      height: height,
      marginTop: "-70px",
      background: backgroundColor,
      display: 'block',
      color: 'black',
      outline: "none",
      padding: "0px",
      borderRadius: "3px",
      fontFamily: "inherit",
    };
    return {
      style: style
    };
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeBulk = (value) => {
    this.setState({
      bulkEditType: value,      
    })
    let bulkEditType = 1
    if (value == 8) {
      bulkEditType = 8
    } else if (value == 7) {
      bulkEditType = 7
    } else if (value == 6) {
      bulkEditType = 6
    } else if (value == 5) {
      bulkEditType = 5
    } else if (value == 4) {
      bulkEditType = 3
    } else if (value == 3) {
      bulkEditType = 2
    } else if (value == 2) {
      bulkEditType = 1
    } else if (value == 1) {
      bulkEditType = 0
    } else {
      bulkEditType = 1
    }
    this.chnageAdjType(bulkEditType);
  }
  handleCloseAllRooms = (e) =>{
   
    let bulkUpdateData = this.state.bulkUpdateData;
    let closeRoom={};

    this.props.roomList.roomList.map((room) => {
      if (room.is_reference_room) {
        closeRoom[room.id] = settingJsonData.rooms.reference.number_of_rooms;
      } else {
        closeRoom[room.id] = settingJsonData.rooms.derived[room.id].number_of_rooms;
      }
    })
    this.setState({
      bulkUpdateData:{
        ...this.state.bulkUpdateData,
        closedRooms:  closeRoom
      }
    })
    this.setState({
      closeAllRoom:e.target.checked
    })
  }
  handleGraphChange = (name) => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  handleSelectRoomBulkEdit = (event, value) => {
    this.setState({
      selectedRoomBulkEdit: value,
      selectedRoom:value
    });
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      room_id: value,
    };

    this.props.getOccupancyPrices(requestParam);
  };

  handleSelectRoom = (event, value) => {
    let refroom = this.props.roomList.roomList.filter(val => val.id == value && val.is_reference_room)[0];
    let allPricing = this.props.priceSettingReducer.priceSetting.hotel.all_room_pricing;
    let roomData = this.props.roomList.roomList.filter(val => val.id == value)[0];

    let adjType = this.state.adjType;

    if (adjType == 1 && (!(allPricing  && (roomData.pms_rate || [17, 8, 35, 39, 42, 52, 67].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider))) || (!roomData.is_reference_room && !this.state.featuresEnabled.includes(6)))) {
      adjType = 0;
    }

    if (adjType == 2 && (!(allPricing && (roomData.pms_rate || [17, 8, 35, 39, 42, 52, 67].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider))))) {
      adjType = 0;
    }


    this.setState({
      selectedRoom: value,
      adjType: adjType
    },
      () => this.getCalendarData()
    );
  };

  handleUpdatePrice = (event) => {

    if (this.state.price_type == 'fix') {
      let geniePrice = this.state.modalData.suggested_price_without_restriction;
      let newChange = (event.target.value - geniePrice);
      let newPercentage = parseFloat(((newChange * 100) / geniePrice).toFixed(0));
      this.setState({
        greySaveUpload: false,
        modalData: {
          ...this.state.modalData,
          change_pms: newChange,
          change_pms_perc: newPercentage,
          staticPrice: event.target.value,
          price: event.target.value
        }
      });
    } else {
      this.setState({
        greySaveUpload: false,
        modalData: {
          ...this.state.modalData,
          price: event.target.value
        }
      });
    }
  }


  validateSinglePriceForm = (data) => {
    let error = [];
    if (this.state.modalData.price === '' || this.state.modalData.price < 0) {
      error.push('upload_price')
      if (error) {
        this.setState({
          error: error
        })
        return error
      }
    }
    return true;
  }

  updateSiglePrice = () => {

    if (this.props.hotelAuthReducer.hotelDetails.hotel.pms_sync == 2 && this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) {
      let validateError = this.validateSinglePriceForm(this.state.modalData);

      if (validateError.length > 0) {
        return;
      }
      this.setState({
        loadUploadBtn: true
      })

      this.updatePricePms(this.state.modalData.date);
    }
  }

  getMonthData = (date, isAll) => {
    if(!this.state.pricing_result){
      return null;
    }
    var PrevMonthLastWeek = moment(date).subtract(12, 'days').format('YYYY-MM-DD');
    var NextMonthFirstWeek = moment(date).add(33, 'days').format('YYYY-MM-DD');
    let calendar_data = Object.keys(this.state.pricing_result).filter(key => isAll || (moment(NextMonthFirstWeek).isAfter(key) && moment(PrevMonthLastWeek).isBefore(key))).reduce((obj, key) => { obj[key] = this.state.pricing_result[key]; return obj; }, {});
    let jsx = [];
    let jsxKey = [];
    Object.keys(calendar_data).map((key) => {
      if (!this.state.pricing_result[key].system_skip) {
        Object.keys(this.state.pricing_result[key]).map((roomkey) => {
          if (!jsxKey.includes(roomkey)) {
            jsx[roomkey] = [];
            jsxKey.push(roomkey);
          }
          if (!this.state.pricing_result[key].error) {
            this.state.pricing_result[key][roomkey] = {
              date: key,
              total_inventory: this.state.pricing_result[key]['property'].inventory_remaining,
              total_closed: this.state.pricing_result[key]['property'].closed_rooms,
              ...this.state.pricing_result[key]['property'],
              ...this.state.pricing_result[key][roomkey],
              inventory_remaining: this.state.pricing_result[key]['property'].all_rooms_inventories ? this.state.pricing_result[key]['property'].all_rooms_inventories[roomkey] : null,
            };
            jsx[roomkey].push(this.state.pricing_result[key][roomkey])
          }
          return null;
        })
      } else {
        let skipData = {};
        this.props.roomList.roomList.map((rooms, index) => {
          if (!jsxKey.includes(rooms.id.toString())) {
            jsx[rooms.id] = [];
            jsxKey.push(rooms.id.toString());
          }
          jsx[rooms.id].push(this.state.pricing_result[key])
        })
      }
      return null;
    });
    this.setState({
      calendarData: jsx,
    },
      () => {
        this.getCalendarData();
      }
    )
  }

  navigateMonth = (date) => {
    this.getMonthData(date);

    if ((this.state.viewType === 'occCalendar') && (Server.env === "live") && !(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type)) {
      ReactGA.event({
        category: 'Occupancy-View',
        action: 'switch-month'
      });
    }
    this.setState({
      monthChangeDate: new Date(moment(date).format('YYYY'), (moment(date).format('M') - 1), 5),
    })
  }

  changeView = (type) => {
    let tracking_key_name = "";
    if (type == "calendar") {
      tracking_key_name = "Calendar View Price Calendar Clicked";
    } else if (type == "adjustment") {
      tracking_key_name = "Go To Adjustment Calendar Clicked";
    } else if (type == "OTA") {
      tracking_key_name = "Go To OTA Calendar Clicked";
    } else {
      tracking_key_name = "Chart View Price Calendar Clicked";
    }

    let allViews = ['calendar', 'priceGraph', 'occCalendar', "adjustment","OTA", 'revenue', "pickUp"];

    if (allViews.includes(type)) {
      if(window.Appcues){
        window.Appcues.page();
      }

      if (type == "priceGraph") {
        let oldSettings = settingJsonData;
        this.setState({
          baseRate: oldSettings.default[this.state.selectedRoom].avg_price,
          maxPrice: oldSettings.default[this.state.selectedRoom].max_price,
          minPrice: oldSettings.default[this.state.selectedRoom].min_price
        })
      } else {
        this.props.setCalendarRoom(type)
      }


      if (!this.state.isGrey) {
        this.toggleLeavePage(type)
      } else {
        this.setState({
          
          isBooking: type == 'calendar' ? false : true,
          viewType: type
          
        },
          () => {
            this.getCalendarData();
            this.handleClose()
          })
      }
    } else {
      this.props.history.push(type);
    }
    
  }
  
  toggleBooking = () => event => {
    this.setState({
      isBooking: event.target.value,
    }, () => this.getCalendarData())
  }


  updatedRoomData = () => {


    let setting = this.state.dateSetting;

    setting.default[this.state.selectedRoom].avg_price = this.state.baseRate;
    setting.default[this.state.selectedRoom].max_price = this.state.maxPrice;
    setting.default[this.state.selectedRoom].min_pric = this.state.minPrice;


    let latestJson = ValidateBaseSettings({ dateSetting: setting });

    let reqParams = {
      settings: JSON.stringify(latestJson.dateSetting),
    };

    this.setState({
      graphLoader: true
    })

    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
    axios.post(Server.API + 'hotel/pricing-settings/', reqParams)
      .then((response) => {

        this.getCalendarSettingJson();
        this.props.getPricingSettings({ token: this.props.hotelAuthReducer.hotelDetails.token })

        let start_date = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD');
        let end_date = moment(start_date).add((this.state.featuresEnabled.includes(23) ? '545' : '365'), 'd').format('YYYY-MM-DD');


        let requestParam = {
          json_settings: JSON.stringify(latestJson.dateSetting),
          is_write_to_cache: true,
          start_date: start_date,
          end_date: end_date
        }

        axios.post(Server.API + 'hotel/adjustment/individual/apply/', requestParam)
          .then((response) => {
            this.setState({
              graphLoader: false
            })
            this.getLattestPricing()
          }).catch((error) => {
            this.setState({
              graphLoader: false
            })
            let requestData = {
              msg: 'Error while Applying the Changes',
              is_success: false,
            }
            this.props.putNotify(requestData)
          })
      })
  }

  // togglePopupView = (val) => {
  //   this.setState({
  //     popupView: val
  //   })
  // }

  getYeldingTags = (id) => {
    let room_types_tag = this.state.yieldingTagsList
    let tags = [];
    Object.keys(room_types_tag).map((key) => {
      if (room_types_tag[key].room_types.includes(id)) {
        tags.push(room_types_tag[key])
      }
    })
    return tags;
  }
  enableClientAccess = () =>{
    this.setState({
      loader: true,
      clientAccessModal:false
    })
    let totalFeatures = this.state.featuresEnabled.filter(val => val != 19);
    let genieIntelligenceFetureEnable = totalFeatures.includes(17)
    let genieIntelligenceEmailDate = genieIntelligenceFetureEnable ? moment().format('Y-MM-DD') : null;
    let requestParam = {
        token: this.props.hotelAuthReducer.hotelDetails.token,
        features: totalFeatures,
        isMinMax:  false,
        is_calculate_no_of_rooms: false,
        genieIntelligence_email_date: genieIntelligenceEmailDate,
        date: moment(this.state.trialDate).format("YYYY-MM-DD"),
    }
    if((this.state.userState == 0 || this.state.userState == 3)){
      this.props.updateFreeTrial(requestParam);
    }
    this.props.enableFeature(requestParam);
  }
  OpenEnableClientAccessModal = () =>{
    this.setState({
      clientAccessModal:true
    })
  }
  closeEnableClientAccessModal = () =>{
    this.setState({
      clientAccessModal:false
    })
  }
  changeDate = (name) => dateValue => {
    this.setState({
        [name]: dateValue
    })
  }
  handleChangeExpansion = () => {
    if(this.state.expanded == true){
      this.setState({expanded:false})
    }else{
      this.setState({expanded:true})
    }
    
  } 
  handleOTADropdownData = (val) => {
    let selectedRPGRoomId = val ? val : this.state.selectedRoom;
    var dropdown_data = [];
    if (this.props.OTAInsightsReducer.OTADropdownData) {
      let dropdownDataJson = this.props.OTAInsightsReducer.OTADropdownData.dropdown_data;
      let dropdownData = dropdownDataJson ? JSON.parse(dropdownDataJson):{};
      dropdownData && dropdownData.map((val) => {
        if (val.room_type == selectedRPGRoomId) {
          return dropdown_data.push({
            ota_room_type: this.state.selectedRoomType,
            room_type: this.state.selectedRoom,
            rate_type: this.state.selectedRateType,
            occupancy: this.state.selectedMaxOccupancy,
          })
        } else {
          return dropdown_data.push(val)
        }
      })
      let requestParam = {
        token: this.props.hotelAuthReducer.hotelDetails.token,
        dropdown_data: JSON.stringify(dropdown_data),
      }
      this.props.storeOtaDropdownData(requestParam);
    } else {
      if (this.state.selectedMaxOccupancy) {
        {
          this.props.roomList && this.props.roomList.roomList && this.props.roomList.roomList.map((val) => {
            dropdown_data.push({
              ota_room_type: this.state.selectedRoomType,
              room_type: val.id,
              rate_type: this.state.selectedRateType,
              occupancy: this.state.selectedMaxOccupancy,
            })
          })
        }
        let requestParam = {
          token: this.props.hotelAuthReducer.hotelDetails.token,
          dropdown_data: JSON.stringify(dropdown_data),
        }
        this.props.storeOtaDropdownData(requestParam);
      }
    }
  }
  handleSelectedRoom = (event, value) => {
    this.setState({
      selectedRoomType: value
    }, () => { this.getCalendarData(); this.handleOTADropdownData() })
  }
  handleSelectRateType = (event, value) => {
    this.setState({
      selectedRateType: value
    }, () => { this.getCalendarData(); this.handleOTADropdownData() })
  }
  handleSelectOccupancy = (event, value) => {
    this.setState({
      selectedMaxOccupancy: value
    }, () => { this.getCalendarData(); this.handleOTADropdownData() })
  }
  handleSelectCompetitors = (event, value) => {
    this.setState({
      competitor: value
    }, () => { this.getCalendarData() })
  }
  handleClickOTARates = () => {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
    }
    this.props.getOtaCompetitorRates(requestParam);
  }
  
  handleSlotSelection = ({start, end, action}) => {    
    
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      room_id: this.state.selectedRoom,
    };
    this.props.getOccupancyPrices(requestParam);
    this.bulkDateAdjModalToggle();
    
    this.calculateBulkSumOfAmount(start,end);    

    let startDate=moment(start).format('YYYY-MM-DD');
    let endDate=moment(end).format('YYYY-MM-DD');
    let bulkEditType = 1
    if (this.state.adjType == 8) {
      bulkEditType = 8
    } else if (this.state.adjType == 7) {
      bulkEditType = 7
    } else if (this.state.adjType == 6) {
      bulkEditType = 6
    } else if (this.state.adjType == 5) {
      bulkEditType = 5
    } else if (this.state.adjType == 3) {
      bulkEditType = 4
    } else if (this.state.adjType == 2) {
      bulkEditType = 3
    } else if (this.state.adjType == 1) {
      bulkEditType = 2
    } else {
      bulkEditType = 1
    }
    
    let setting = { ...this.state.dateSetting };

    let min_price_array = {};
    let max_price_array = {};
    let derivation_array = {};
    let fixedPrice = {};
    let closedRooms = {};

    Object.keys(setting.default).map((key) => {
      min_price_array[key] = "";
      max_price_array[key] = "";
      derivation_array[key] = "";
      fixedPrice[key] = "";
      closedRooms[key] = ""
    })

    this.setState({
      bulkEditType,
      adjScrollDate:true,
      bulkDateAdjModal:true,
      bulkStartDate:startDate,
      bulkEndDate:endDate,
      bulkUpdateData: {
       ...this.state.bulkUpdateData,
        adjVsRef: derivation_array,
        maxPrice: max_price_array,
        minPrice: min_price_array,
        fixedPrice: fixedPrice,
        dailyAdjust: "",
        closedRooms: closedRooms
      }
     })
  };
 
  changeGraph = (val) =>{
    if(val == 1) {
      this.setState({
        graphTab:val
      })  
    }else{
      this.setState({
        graphTab:val,
        showFullYearGraph:false
      })
    }
    
  }
  openRevenueTable = () =>{
    this.setState({
      opentRevenueTableModal:true
    })
  }
  closeRevenueTable = () =>{
    this.setState({
      opentRevenueTableModal:false
    })
  }
  renderCalendar = () => {
    const { classes } = this.props;
    const { t } = this.props;
    let preferred_date;
    if(this.props.hotelDetail && this.props.hotelDetail.prefered_date_format){
       preferred_date=this.props.hotelDetail ? this.props.hotelDetail.prefered_date_format.replace(', hh:mm A',''):'DD/MM/YYYY';
    }else{
      preferred_date='DD/MM/YYYY'
    }
    let setting = this.props.priceSettingReducer.priceSetting;
    let yeldingTags = [];
    let is_admin_partners = (this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) ? true : false;    
    if (this.props.roomList.roomList) {
      return this.props.roomList.roomList.filter(rooms => rooms.id === this.state.selectedRoom).map((rooms) => {
        let grapgValue = [];
        let grapgDate = [];
        let graphData = [];
        let average = [];
        let currentPrice = [];
        // let upperLimit = [];
        // let adjustedPrice = [];
        // let lowerLimit = [];
        // let roomsSold = [];
        let max = 0;
        let min = 0;
        let end_date=moment().add(365, 'days').calendar();
        // let yeldingTags = this.getYeldingTags(rooms.id);
        if (this.state.myEventsList[rooms.id] && this.state.viewType == "priceGraph") {
          this.state.myEventsList[rooms.id].filter(val => moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).diff(val.start, 'days') <= 0).map((val) => {
            if (val.recommended > max) {
              max = val.recommended;
            }

            if (setting.default[rooms.id].avg_price > max) {
              max = setting.default[rooms.id].avg_price;
            }

            if (val.pricePms && val.pricePms > max) {
              max = val.pricePms;
            }

            if (val.recommended < min || !min) {
              min = val.recommended;
            }

            if (setting.default[rooms.id].avg_price < min || !min) {
              min = setting.default[rooms.id].avg_price;
            }

            if (val.pricePms && (val.pricePms < min || !min)) {
              min = val.pricePms;
            }
            if (moment(this.state.monthChangeDate).isSame(moment(val.start), 'month') || this.state.showFullYearGraph) {
              if(this.state.featuresEnabled && !this.state.featuresEnabled.includes(23)){
                let end_date_graph=moment(end_date);
                let start_date_graph=moment(val.start);
                if(start_date_graph.isSameOrBefore(end_date_graph)){
                // graphData.push(val)
                graphData.push({'recommended':val.recommended,'occupancy':val.occupancy})
                grapgValue.push(val.recommended);
                grapgDate.push(t(moment(val.start).format('ddd, '+preferred_date)));
                average.push(setting.default[rooms.id].avg_price);
  
                if (val.pricePms) {
                  currentPrice.push(val.pricePms);
                } else {
                  currentPrice.push(null);
                }
              }
              }else{
                graphData.push({'recommended':val.recommended,'occupancy':val.occupancy})
                // graphData.push(val)
                grapgValue.push(val.recommended);
                grapgDate.push(t(moment(val.start).format('ddd, '+preferred_date)));
                average.push(setting.default[rooms.id].avg_price);
  
                if (val.pricePms) {
                  currentPrice.push(val.pricePms);
                } else {
                  currentPrice.push(null);
                }
              }
              

              

              // upperLimit.push(val.upperLimit);
              // lowerLimit.push(val.lowerLimit);
              // adjustedPrice.push(val.adjustedPrice);
              // roomsSold.push(val.roomsSold);


             
            }
           
          });
        }
        
        const data = {
          labels: grapgDate,
          datasets: [
            {
              label: t('Price Recommendation'),
              fill: false,
              lineTension: 0.1,
              backgroundColor: 'rgba(75,192,192,1)',
              borderColor: 'rgba(75,192,192,1)',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: 'rgba(75,192,192,1)',
              pointBackgroundColor: 'rgba(75,192,192,1)',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: 'rgba(75,192,192,1)',
              pointHoverBorderColor: 'rgba(75,192,192,1)',
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: grapgValue,
              extra: graphData,
            },
            {
              label: t('Base Price'),
              fill: false,
              lineTension: 0.1,
              backgroundColor: '#541388',
              borderColor: '#541388',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: '#541388',
              pointBackgroundColor: '#541388',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: '#541388',
              pointHoverBorderColor: '541388',
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: average,
              extra: average,
              hidden:true
            },
            {
              label: ((this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider === 5 || this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider === 10) ? t('Price in CM ') : t('Price in PMS ')),
              fill: false,
              lineTension: 0.1,
              backgroundColor: '#d6d6d6',
              borderColor: '#d6d6d6',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: '#d6d6d6',
              pointBackgroundColor: '#d6d6d6',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: '#d6d6d6',
              pointHoverBorderColor: '#d6d6d6',
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: currentPrice,
              extra: currentPrice
            },
          ]
        };

        const graphOptions = {
          maintainAspectRatio: false, 
          legend: {
            position: "top",
            // align: "start",
            labels: {
              // padding: 50,
              boxWidth: 11,
            }
          },
          responsive: true,
          scales: {
            xAxes: [{
              type: 'time',
              time: {
                parser: "ddd,"+preferred_date,
                unit: this.state.showFullYearGraph ? 'month' : 'day',
                unitStepSize: 1,
              },
              gridLines: {
                display : false
              },
            }],
            yAxes: [{
              ticks: {
                // suggestedMax: (max * 1.1),
                // suggestedMin: (min * 0.9),
                callback: (label, index, labels) => {
                  return (this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol) + label;
                }
              },
              scaleLabel: {
                bounds: "xs",
                display: true,
                labelString: '',
              }
            }],
          },
          tooltips: {
            enabled: true,
            mode: 'single',
            callbacks: {
              // labelColor: function(context) {
              //     return {
              //         borderColor: 'rgb(0, 0, 255)',
              //         backgroundColor: 'rgb(255, 0, 0)',
              //         borderWidth: 2,
              //         borderDash: [2, 2],
              //         borderRadius: 2,
              //     };
              // },
              label: (tooltipItems, data) => {
                if (tooltipItems.datasetIndex == 0) {
                  // return t("Price Recommendation: ") + this.state.currency.symbol + (data.datasets[0].extra[tooltipItems.index].recommended ? parseInt(data.datasets[0].extra[tooltipItems.index].recommended) : 0) + ","+' ' + ((this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider === 5 || this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider === 10) ? t('Price in CM: ') : t('Price in PMS: ')) + ' ' + this.state.currency.symbol + (data.datasets[0].extra[tooltipItems.index].pricePms ? data.datasets[0].extra[tooltipItems.index].pricePms : 0) + "," +' '+ t('Occupancy: ') +' '+ parseInt(data.datasets[0].extra[tooltipItems.index].occupancy) + "%";

                  return t("Price Recommendation: ") + this.state.currency.symbol + (data.datasets[0].extra[tooltipItems.index].recommended ? parseInt(data.datasets[0].extra[tooltipItems.index].recommended) : 0) + ", " +t(`Price in ${this.state.pms_channel_manager_text}: `) + this.state.currency.symbol + data.datasets[2].extra[tooltipItems.index]+', '+ t('Occupancy: ') + parseInt(data.datasets[0].extra[tooltipItems.index].occupancy) + "%";
                } else if (tooltipItems.datasetIndex == 1) {
                  return t("Base Price: ") + this.state.currency.symbol + data.datasets[1].extra[tooltipItems.index];
                } else if (tooltipItems.datasetIndex == 2) {
                  return t(`Price in ${this.state.pms_channel_manager_text}: `)  + this.state.currency.symbol + data.datasets[2].extra[tooltipItems.index];
                }
              }
            }
          },
        };
        // const plugins= [{
        //   beforeInit: function(chart, args, options) {
        //     chart.legend.afterFit = function() {
        //       this.height = this.height + 20;
        //     }
        //   }
        // }] 
        let otaValues = []
        let otaGraphDate=[]        
        if(this.state.selectedCompetitors && this.state.competitorRates && this.state.competitorRates){
          this.state.selectedCompetitors.map((comp,index) => { 
            let graphOtaData = []
            let medianFlexRate=[]
            Object.keys(this.state.competitorRates).map((key,index)=>{

              let comData = this.state.competitorRates[key][comp.competitor_id];
              let data= comData ? comData.flexible ? (comData.flexible[this.state.selectedMaxOccupancy] ? (comData.flexible[this.state.selectedMaxOccupancy][this.state.selectedRoomType] ? comData.flexible[this.state.selectedMaxOccupancy][this.state.selectedRoomType].rate : 'n.a') : 'n.a') : 'n.a' : 'n.a';

              let medianData = this.state.competitorRates[key]['median_price'];
              let medianFlexPrice= medianData ?medianData.flexible ? (medianData.flexible[this.state.selectedMaxOccupancy] ? (medianData.flexible[this.state.selectedMaxOccupancy][this.state.selectedRoomType] ? medianData.flexible[this.state.selectedMaxOccupancy][this.state.selectedRoomType]: 'n.a') : 'n.a') : 'n.a':'n.a';

              let itemDataIndex = fullYearOtaValues.indexOf(data);
              if(itemDataIndex < 0){
                fullYearOtaValues.push(data);//Set for full year data
              }

              let itemMedianIndex = fullYearOtaValues.indexOf(medianFlexPrice);
              if(itemMedianIndex < 0){
                fullYearOtaValues.push(medianFlexPrice);//Set for full year data
              }              

              if (moment(this.state.monthChangeDate).isSame(moment(key), 'month') ){
                otaGraphDate.push(moment(key).format('ddd, '+preferred_date))                
                graphOtaData.push(data)                
                medianFlexRate.push(medianFlexPrice)
              }
            })
            otaValues['median_rate']=medianFlexRate
            otaValues[index]= graphOtaData
          })
        }        

        //OTA Insight Prices Line Chart Data
        let otaInsightPrices = [];
        let selectedRoomName = this.getRoomName();
        if (this.state.myEventsList.length > 0 && this.state.myEventsList[rooms.id]) {
          (this.state.myEventsList[rooms.id]).map((val)=>{
            if(val.recommended){
              let itemRecomIndex = fullYearOtaValues.indexOf(val.recommended);
              if(itemRecomIndex < 0){
                fullYearOtaValues.push(val.recommended);//Add insight price
              }              
            }
            
            let startDate = moment(val.start).format('ddd, '+preferred_date)
            if (moment(this.state.monthChangeDate).isSame(moment(val.start), 'month') 
              && otaGraphDate.includes(startDate)
              ){
              otaInsightPrices.push(val.recommended)
            }
          })
        }
        // 

      
        let filteredOtaAmountAry = fullYearOtaValues.filter(e => e != 'n.a');//Remove 'n.a' from array
        let findOtaMaxAmount = 100;

        if(this.state.viewType == 'priceGraph' && this.state.graphTab==2){
          if(filteredOtaAmountAry.length > 0){
            //Calculation: y-axis should display range($0, $x + $20) where x = max(price over all months and competitors)
            findOtaMaxAmount = Math.max(...filteredOtaAmountAry);
            if(findOtaMaxAmount % 50 == 0){
              findOtaMaxAmount = findOtaMaxAmount + 20; //Add extra value if chart line have exact max value, so it show some space on above of line
            }
          }
        }else{
          fullYearOtaValues = [];
        }

        const otaGraphOptions={
          maintainAspectRatio: false, 
          legend: {
            position: "top",
            // align: "start",
            labels: {
              // padding: 50,
              boxWidth: 11,
              usePointStyle: true,              
            },
            onClick: (e, legendItem) => {
              //Get chart reference
              let chartInstance = this.myChartRef.current.chartInstance;                
              var index = legendItem.datasetIndex;
              var ci = chartInstance;
              var meta = ci.getDatasetMeta(index);

              // See controller.isDatasetVisible comment
              meta.hidden = meta.hidden === null? !ci.data.datasets[index].hidden : null;
          
              // We hid a dataset ... rerender the chart
              ci.update();

              //Update Status for Legends in localstorage and states
              let legendHiddenStatus = legendItem?.hidden ? false : true; //This returns old value means if set status hidden then it return hidden false (old value of legend)

              let legendIndex = this.state.otaInsightHiddenLegends.indexOf(legendItem.text);
              if(legendHiddenStatus){
                if (legendIndex < 0) {
                  let newLegendAry = [...this.state.otaInsightHiddenLegends,legendItem.text];                  
                  localStorage.setItem("otaChartHiddenLegendData", JSON.stringify(newLegendAry));      
                  this.setState({
                    otaInsightHiddenLegends:[...this.state.otaInsightHiddenLegends,legendItem.text]
                  })
                }               
              }else{
                if (legendIndex > -1) { // only splice array when item is found
                  let updatedAry;
                  if(this.state.otaInsightHiddenLegends){
                    updatedAry = this.state.otaInsightHiddenLegends.filter((item) => item !== legendItem.text);
                  }
                   
                  localStorage.setItem("otaChartHiddenLegendData", JSON.stringify(updatedAry));   
                  this.setState({
                    otaInsightHiddenLegends:updatedAry
                  })
                }
              }
              // End of Update
              
            }
          },
          responsive: true,
          scales: {
            xAxes: [{
              type: 'time',
              time: {
                parser: "ddd,"+preferred_date,
                unit: this.state.showFullYearGraph ? 'month' : 'day',
                unitStepSize: 1,
              },
              gridLines: {
                display : false
              },
            }],
            yAxes: [{
              ticks: {
                beginAtZero: true,
                suggestedMax: findOtaMaxAmount,
                suggestedMin: 0,
                callback: (label, index, labels) => {
                  return (this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol) + label;
                }
              },
              scaleLabel: {
                bounds: "xs",
                display: true,
                labelString: '',
              }
            }],
          }, 
          tooltips: {
            mode: 'index',
            position: 'nearest',
            callbacks: {
              label: (tooltipItem, data) => data.datasets[tooltipItem.datasetIndex].label + ': ' + (this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol)+tooltipItem.yLabel
            }
          },
        }
        

        const otaData={
          labels: otaGraphDate,
          datasets: [
            {
              type: 'line',
              label:selectedRoomName,
              backgroundColor: "#8a8971",
              borderColor: "#8a8971",
              borderWidth: 1,
              fill: false,
              data: otaInsightPrices,
              pointStyle: 'crossRot',
              hidden: this.state.otaInsightHiddenLegends.indexOf(selectedRoomName) > -1 ? true : false
            },
            {
              type: 'line',
              label:"Median Flexible Rate",
              backgroundColor: "#faf31e",
              // borderColor: "#faf31e",
              borderWidth: 1,
              pointRadius: 4,
              pointBorderColor: "black",
              pointStyle: 'triangle',
              fill: false,
              borderDash: [10,5],
              data:otaValues['median_rate'],
              hidden: this.state.otaInsightHiddenLegends.indexOf("Median Flexible Rate") > -1 ? true : false
            },
            {
              type: 'line',
              label:((this.state.selectedCompetitors.length > 0) &&  this.state.selectedCompetitors[0] && this.state.selectedCompetitors[0].name) ? this.state.selectedCompetitors[0].name:'',
              backgroundColor: '#521383',
              borderColor: '#8b94c0',
              borderWidth: 1,
              fill: false,
              lineTension: 0,
              data:otaValues[0],
              hidden: this.state.otaInsightHiddenLegends.indexOf((((this.state.selectedCompetitors.length > 0) && this.state.selectedCompetitors[0].name) ? this.state.selectedCompetitors[0].name:'')) > -1 ? true : false
            },
            {
              type: 'line',
              label:((this.state.selectedCompetitors.length > 0) &&  this.state.selectedCompetitors[1] && this.state.selectedCompetitors[1].name) ? this.state.selectedCompetitors[1].name:'',
              backgroundColor:'#c0c0bf',
              borderWidth: 1,
              fill: false,
              lineTension: 0,
              data:otaValues[1],
              hidden: this.state.otaInsightHiddenLegends.indexOf((((this.state.selectedCompetitors.length > 1) && this.state.selectedCompetitors[1].name) ? this.state.selectedCompetitors[1].name:'')) > -1 ? true : false
            },
            {
              type: 'line',
              label:((this.state.selectedCompetitors.length > 0) &&  this.state.selectedCompetitors[2] && this.state.selectedCompetitors[2].name) ? this.state.selectedCompetitors[2].name:'',
              backgroundColor:'#cdd1e4',
              borderWidth: 1,
              fill: false,
              lineTension: 0,
              data: otaValues[2],
              hidden: this.state.otaInsightHiddenLegends.indexOf((((this.state.selectedCompetitors.length > 2) && this.state.selectedCompetitors[2].name) ? this.state.selectedCompetitors[2].name:'')) > -1 ? true : false
            },
            {
              type: 'line',
              label:((this.state.selectedCompetitors.length > 0) &&  this.state.selectedCompetitors[3] && this.state.selectedCompetitors[3].name) ? this.state.selectedCompetitors[3].name:'',
              backgroundColor:'#6a76af',
              borderWidth: 1,
              fill: false,
              lineTension: 0,
              data:otaValues[3],
              hidden: this.state.otaInsightHiddenLegends.indexOf((((this.state.selectedCompetitors.length > 3) && this.state.selectedCompetitors[3].name) ? this.state.selectedCompetitors[3].name:'')) > -1 ? true : false
            },
            {
              type: 'line',
              label:((this.state.selectedCompetitors.length > 0) && this.state.selectedCompetitors[4] && this.state.selectedCompetitors[4].name) ? this.state.selectedCompetitors[4].name:'',
              backgroundColor:'#3eda81',
              borderWidth:1,
              fill: false,
              lineTension: 0,
              data:otaValues[4],
              hidden: this.state.otaInsightHiddenLegends.indexOf((((this.state.selectedCompetitors.length > 4) && this.state.selectedCompetitors[4].name) ? this.state.selectedCompetitors[4].name:'')) > -1 ? true : false
            },
            {
              type: 'line',
              label:((this.state.selectedCompetitors.length > 0) &&  this.state.selectedCompetitors[5] && this.state.selectedCompetitors[5].name) ? this.state.selectedCompetitors[5].name:'',
              backgroundColor:'#4d0017',
              borderWidth: 1,
              fill: false,
              lineTension: 0,
              data:otaValues[5],
              hidden: this.state.otaInsightHiddenLegends.indexOf((((this.state.selectedCompetitors.length > 5) && this.state.selectedCompetitors[5].name) ? this.state.selectedCompetitors[5].name:'')) > -1 ? true : false
            },
            {
              type: 'line',
              label:((this.state.selectedCompetitors.length > 0) &&  this.state.selectedCompetitors[6] && this.state.selectedCompetitors[6].name) ? this.state.selectedCompetitors[6].name:'',
              backgroundColor: "rgba(200,0,0,.5)",
              borderWidth: 1,
              fill: false,
              data:otaValues[6],
              hidden: this.state.otaInsightHiddenLegends.indexOf((((this.state.selectedCompetitors.length > 6) && this.state.selectedCompetitors[6].name) ? this.state.selectedCompetitors[6].name:'')) > -1 ? true : false
            },
            {
              type: 'line',
              label:((this.state.selectedCompetitors.length > 0) &&  this.state.selectedCompetitors[7] && this.state.selectedCompetitors[7].name) ? this.state.selectedCompetitors[7].name:'',
              backgroundColor: "#da843e",
              borderColor: "#da843e",
              borderWidth: 1,
              fill: false,
              data:otaValues[7],
              hidden: this.state.otaInsightHiddenLegends.indexOf((((this.state.selectedCompetitors.length > 7) && this.state.selectedCompetitors[7].name) ? this.state.selectedCompetitors[7].name:'')) > -1 ? true : false
            },
            {
              type: 'line',
              label: ((this.state.selectedCompetitors.length > 0) &&   this.state.selectedCompetitors[8] && this.state.selectedCompetitors[8].name) ? this.state.selectedCompetitors[8].name:'',
              backgroundColor: "#da843e",
              borderColor: "#da843e",
              borderWidth: 1,
              fill: false,
              data: otaValues[8],
              hidden: this.state.otaInsightHiddenLegends.indexOf((((this.state.selectedCompetitors.length > 8) && this.state.selectedCompetitors[8].name) ? this.state.selectedCompetitors[8].name:'')) > -1 ? true : false
            },
            {
              type: 'line',
              label:((this.state.selectedCompetitors.length > 0) &&  this.state.selectedCompetitors[9] && this.state.selectedCompetitors[9].name) ? this.state.selectedCompetitors[9].name:'',
              backgroundColor: "#b3b298",
              borderColor: "#b3b298",
              borderWidth: 1,
              fill: false,
              data: otaValues[9],
              hidden: this.state.otaInsightHiddenLegends.indexOf((((this.state.selectedCompetitors.length > 9) && this.state.selectedCompetitors[9].name) ? this.state.selectedCompetitors[9].name:'')) > -1 ? true : false
            },
          ]
        }
        let targetOcc = 0;

        if (this.state.featuresEnabled.includes(12)) {
          targetOcc = this.props.priceSettingReducer.priceSetting.monthly_target_occupancy[moment(this.state.monthChangeDate).format('mm')]
        } else {
          targetOcc = this.props.priceSettingReducer.priceSetting.hotel.target_occupancy
        }

        let allPricing = this.props.priceSettingReducer.priceSetting.hotel.all_room_pricing;
        let roomData = this.props.roomList.roomList.filter(val => val.id == this.state.selectedRoom)[0];
        let updateToPmsType;
        this.state.types.map((val) => {
          if (val.key == this.props.hotelAuthReducer.hotelDetails.hotel.update_to_pms_type) {
            updateToPmsType = val.value
          }
        });
        let is_admin = (this.props.userSession.user.is_staff) ? true : false;
        let reservationDate;
        if(this.state.last_reservation_received){
          if (moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY') == moment.tz(this.state.last_reservation_received, this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM/YYYY')) {
            reservationDate = t('Today') + moment.tz(this.state.last_reservation_received, this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format(', hh:mm A');
          }else{
            reservationDate=moment.tz(this.state.last_reservation_received, this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('DD/MM, hh:mm A')
          }
        }
        let monthChangeFormat=moment(this.state.monthChangeDate).format('YYYY-MM')
        
        let revenueMonthChangeVal= this.state.kpi_monthly_total?.['REVENUE']?.[monthChangeFormat]?new Intl.NumberFormat('ja-JP').format(this.state.kpi_monthly_total['REVENUE'][monthChangeFormat]):'' 
        
        let kpiMonthlyTotalADR = this.state.kpi_monthly_total?.['ADR']?.[monthChangeFormat]?new Intl.NumberFormat('ja-JP').format(this.state.kpi_monthly_total['ADR'][monthChangeFormat]):''
        
        let kpiMonthlyTotalOCCUPANCY = this.state.kpi_monthly_total?.['OCCUPANCY']?.[monthChangeFormat] ?  this.state.kpi_monthly_total['OCCUPANCY'][monthChangeFormat]+ '%':""
        
        return (
          <>
            <HeaderCard style={{ maxWidth: "auto", maxWidth: "none" , position : "sticky" , zIndex : 5 , top :0 , /* minHeight:'183px'*/}}>              
            <MuiThemeProvider theme={CalendarTheme}>

              <Grid container>
                <Grid item xs={12}className={classes.calendarMenuMainDiv}>
                  <div style={{ float: "left", marginTop: "2px" }}>
                    {this.state.viewType != "priceGraph" &&
                      <Typography variant="h5" className={classes.headerMarginOnMobile} style={{ textAlign: "left", fontWeight: "500" }}>
                        {t("Price Calendar")}
                        <CustomTooltip title="" description={t("On this page you can view and edit your prices in a calendar format. You can also see more detail or changes individual dates by clicking on them.")} />
                        {this.props.userSession.user.is_staff &&
                           <span className={classes.adminViewText}>Admin View</span>
                          } 
                      </Typography>
                    }
                    
                    {this.state.viewType == "priceGraph" &&
                      <Typography variant="h5" className={classes.headerMarginOnMobile} style={{ textAlign: "left", fontWeight: "500" }}>
                        {t("Price Chart")}
                        <CustomTooltip title="" description={t("On this page you can view your prices for the next year or months. You can also see more detail clicking on them.")} />
                  
                      </Typography>
                    }
                  </div>
                </Grid>

                <div style={{width:'100%'}}>
                    <ExpansionPanel  expanded={this.state.expanded} >
                    {/* {this.state.viewType != 'priceGraph' && */}
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon  onClick={this.handleChangeExpansion} className={classes.hideOnMobile} />}>
                          <Grid container style={{padding:'0px'}}>
                            <Grid item md={12} xs={12}>
                              {this.state.viewType == 'priceGraph' &&
                                <Tabs
                                  indicatorColor="primary"
                                  textColor="primary"
                                  variant="scrollable"
                                  scrollButtons="on"
                                  TabIndicatorProps={{ style: { background: "#0073ea" } }}
                                  value={this.state.graphTab}
                                  className={classes.TabsView}   
                                >
                                <Tab onClick={() => this.changeGraph(1)} label={t("Prices")} value={1} />
                                {this.state.featuresEnabled.includes(16) &&
                                  <Tab onClick={() => this.changeGraph(2)}  label={<span>{t(`OTA Insight`)}{(this.state.trialType ==0 && this.state.featuresEnabled.includes(16)) && Badge.badgeCalenderPro(this)}</span>} value={2} /> 
                                }
                                {/* {this.state.featuresEnabled.includes(25) && <Tab onClick={() => this.changeGraph(2)} label={t("Occupancy")} value={2} />}
                                {this.state.featuresEnabled.includes(25) && <Tab onClick={() => this.changeGraph(3)} label={t("Revenue")} value={3} />}
                                {this.state.featuresEnabled.includes(25) && <Tab onClick={() => this.changeGraph(4)} label={t("ADR")} value={4} />} */}
                                
                                
                                </Tabs>
                              }
                          
                              {this.state.viewType != 'priceGraph' &&
                                <Tabs
                                  indicatorColor="primary"
                                  textColor="primary"
                                  variant="scrollable"
                                  scrollButtons="on"
                                  value={this.state.viewType}
                                  className={classes.TabsView} 
                                  TabIndicatorProps={{ style: { background: "#0073ea" } }}
                                >
                                  <Tab onClick={() => this.changeView("calendar")} label={t("Prices")} value={"calendar"} />
                                { (this.state.featuresEnabled.includes(1) || !this.state.featuresEnabled.includes(29)) &&
                                  <Tab onClick={() => this.changeView("adjustment")} label={t("Bulk Edit Prices")} value={"adjustment"} />
                                }
                                {( is_admin || this.state.featuresEnabled.includes(30) ) &&
                                  <Tab onClick={() => this.changeView("pickUp")} label={<span>{t(`Occupancy & Pickup`)}{(this.state.trialType ==0 && this.state.featuresEnabled.includes(30)) && Badge.badgeCalenderPro(this)}</span>} value={"pickUp"} />
                                } 
                                {console.log(this.state.featuresEnabled,"feature")}
                                  {this.state.featuresEnabled.includes(16) &&
                                    <Tab onClick={() => this.changeView("OTA")}  label={<span>{t(`OTA Insight`)}{(this.state.trialType ==0 && this.state.featuresEnabled.includes(16)) && Badge.badgeCalenderPro(this)}</span>} value={"OTA"} />
                                  }
                                  {this.state.featuresEnabled.includes(25) &&
                                    <Tab onClick={() => this.changeView("revenue")} 
                                    label={<span>{t(`Revenue`)}{(this.state.featuresEnabled.includes(34)) ? Badge.badgeCalenderPro(this) : <Button style={{float:'right',fontSize:'12px',marginTop:'-3px',color:'white',padding:'0px 9px',minWidth:'0px',textTransform: 'capitalize',boxShadow:'0 0 white',background:'#eda942'}} variant="contained"  size="small">{t('beta')}</Button>}</span>}
                                    value={"revenue"} />
                                  }
                                </Tabs>
                              }
                            </Grid>
                            <Grid item md={12} xs={12} style={{marginBottom:'-25px'}}>
                              {(this.state.expanded == false) &&
                                <div className={classes.showOnMobile} style={{margin:'5px'}} >
                                  <ExpandMoreIcon  onClick={this.handleChangeExpansion} />
                                </div>
                              }
                            </Grid>
                          </Grid>
                        </ExpansionPanelSummary>
                        {/* } */}
                      <ExpansionPanelDetails>
                        <Grid item xs={12} md={12} >
                          <Grid container style={{maxHeight:'100px',minHeight:'60px'}}>
                            {this.props.hotelAuthReducer.hotelDetails.hotel.pms_sync == 2 && this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider && (this.state.viewType == 'pickUp' || this.state.viewType == "adjustment" || this.state.viewType == "calendar" || (this.state.viewType == 'priceGraph' && this.state.graphTab == 1)) &&
                              <Grid item xs={12} sm={"auto"} >
                                <Button  onClick={this.toggleUpdatePms}  className={[classes.pricePmsBtn, classes.uploadBtn]} variant="contained" color="primary">
                                  {t("Upload prices")}
                                </Button>
                              </Grid>
                            }

                            {this.state.viewType == 'adjustment' &&
                              <Grid item xs={12} sm={"auto"}>
                                { (this.state.featuresEnabled.includes(1) || this.state.adjType == 5) &&
                                  <Button variant="outlined"  color='primary' className={classNames(classes.pricePmsBtn, classes.uploadBtn, classes.hideOnMobile)} onClick={this.handleClickBulk} style={{ padding:"5px 16px"}} >
                                    {t("Bulk Edit")}
                                  </Button>
                                }
                                {/* {this.props.userSession.user.is_staff &&
                              <Button variant={"outlined"} disabled={this.state.programLoader} onClick={() => this.downloadTabledata()} className={[classes.pricePmsBtn, classes.uploadBtn]} color="primary" >
                                {t("Json")}<Icon>downloading</Icon>
                              </Button>
                            } */}
                              </Grid>
                            }
                            {this.state.viewType == 'adjustment' &&
                              <Grid item xs={12} sm={"auto"} >
                                <Button  onClick={this.toggleRemoveAdjModal} className={classNames(classes.pricePmsBtn, classes.uploadBtn, classes.hideOnMobile, classes.textSecondary)} >
                                  {t("Bulk Remove")}
                                </Button>
                              </Grid>
                            }
                            {this.state.viewType == 'OTA' &&
                              <>
                              <Grid item xs={12} sm={"auto"} >
                                {is_admin &&
                                  <Button disabled={this.state.OtaRatesLoader} className={classNames(classes.pricePmsBtn, classes.uploadBtn)} onClick={() => this.handleClickOTARates()} color="primary" variant="contained">
                                    {t("Get OTA Rates")}
                                    {this.state.OtaRatesLoader && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                                  </Button>
                                }
                              </Grid>
                              {/* <div className={classes.uploadMsg}>{this.state.ota_update_message}</div> */}
                              </> 
                            }


                            {this.props.hotelAuthReducer.hotelDetails.hotel.pms_sync == 2 && this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider && (this.state.viewType == 'pickUp' || this.state.viewType == 'calendar' || (this.state.viewType == 'priceGraph' && this.state.graphTab == 1)) &&
                              <div className={classes.uploadMsg}>{this.state.update_message}</div>
                            }
                            {(this.state.viewType == 'pickUp' || this.state.viewType == 'calendar' || (this.state.viewType == 'priceGraph' && this.state.graphTab == 1)) &&
                              <Grid item xs={12} sm={"auto"}>
                                {this.props.hotelAuthReducer.hotelDetails.hotel.pms_sync == 2 && this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider && (this.state.viewType == 'pickUp' || this.state.viewType == 'calendar' || this.state.viewType == 'priceGraph') &&
                                  <>
                                    {this.state.is_update_to_pms &&
                                      <Button onClick={() => this.toggleupdatePmsType()} className={classNames(classes.calendatIcon, classes.hideOnMobile, classes.textSecondary)} component="span">
                                        {/* <Icon className={classes.calIcon}>calendar_today</Icon> */}
                                        {t("next ") + t(updateToPmsType)}
                                      </Button>
                                    }
                                    <FormControlLabel
                                      className={classes.pricePmsBtnCheckBox}
                                      control={
                                        <Switch
                                          checked={this.state.is_update_to_pms}
                                          value={this.state.is_update_to_pms}
                                          onClick={() => this.toggleAutoUpdateConfirm()}
                                          color="primary"
                                        />
                                      }
                                      label={<span className={classes.textSecondary} style={{ marginTop: "8px" }}>{t("Auto-upload")}</span>}
                                      labelPlacement="start"
                                    />
                                  </>
                                }
                              </Grid>
                            }
                            {(this.state.viewType == 'pickUp' || this.state.viewType == 'calendar' || this.state.viewType == 'priceGraph') &&
                              <Grid item xs={12} sm={"auto"}>
                                {this.state.showDownloadCsv && (this.state.viewType == 'pickUp' || this.state.viewType == 'calendar' || this.state.viewType == 'priceGraph') &&
                                  <Button disabled={!this.state.csvUrl} className={[classes.pricePmsBtn, classes.diagnosticBtn]} href={this.state.csvUrl} color="primary" >
                                    {t("Diagnostics")}
                                  </Button>
                                }
                              </Grid>
                            }

                            {(this.state.viewType == 'pickUp' || this.state.viewType == 'calendar' || (this.state.viewType == 'priceGraph' &&  this.state.graphTab == 1)) &&
                              <Grid item xs={12} sm={"auto"}>
                                {this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider && this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider != 11 && (this.state.viewType == 'pickUp' || this.state.viewType == 'calendar' || this.state.viewType == 'priceGraph') &&
                                  <Tooltip
                                    classes={{ popper: classes.htmlPopper, tooltip: classes.htmlTooltip, }}
                                    title={<Typography className={classes.htmlTooltip}>{this.state.run_message}</Typography>}>
                                    <Button style={{ marginLeft: "8px" }} className={classNames(classes.pricePmsBtn, classes.uploadBtn, classes.textSecondary)} onClick={() => this.handleClickManualPrice(true)} >
                                      {t("Run Pricing")}
                                    </Button>
                                  </Tooltip>
                                }
                              </Grid>
                            }

                            {(this.state.viewType == 'pickUp' || this.state.viewType == 'calendar' || (this.state.viewType == 'priceGraph' && this.state.graphTab == 1)) &&
                              <Grid item xs={12} sm={"auto"}>
                                {/* {(this.props.hotelAuthReducer.hotelDetails.hotel.pms_sync == 1 || this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type || [2, 11, 32, 5, 42].includes(this.state.pms_provider)) && */}
                                  {this.renderInginiusFiles()}
                                {/* } */}
                              </Grid>
                            }
                            {(this.state.viewType == 'pickUp' || this.state.viewType == 'calendar') && !this.state.is_update_to_pms &&
                            <Grid item xs={12} sm={"auto"}>
                                {(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) &&
                                  this.renderProfit()
                                }
                              </Grid>
                            }
                            { (this.state.viewType == 'calendar' && this.state.graphTab == 1 ) &&
                              <Grid>
                                { (this.state.pms_provider == 56) &&
                                  this.renderImportPMSDataButton()
                                }
                              </Grid>
                            }
                            {(this.state.viewType == 'pickUp' || this.state.viewType == 'calendar' || (this.state.viewType == 'priceGraph' && this.state.graphTab == 1)) &&
                              <Grid item xs={12} sm={"auto"}>
                                {this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider && (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 11) && (this.state.viewType == 'pickUp' || this.state.viewType == 'calendar' || this.state.viewType == 'priceGraph') &&
                                  <Tooltip
                                    classes={{ popper: classes.htmlPopper, tooltip: classes.htmlTooltip, }}
                                    title={<Typography className={classes.htmlTooltip}>{this.state.run_message}</Typography>}>
                                    <Button className={classNames(classes.pricePmsBtn, classes.uploadBtn, classes.textSecondary)} onClick={() => this.handleClickManualPrice(false)} >
                                      {t("Run Pricing")}
                                    </Button>
                                  </Tooltip>
                                }
                              </Grid>
                            }

                            {(this.state.viewType == 'pickUp' || this.state.viewType == 'calendar' || this.state.viewType == 'priceGraph') && (this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) &&
                              <Grid item xs={12} sm={"auto"}>
                                {this.state.programListHide &&
                                  <Button disabled={this.state.programLoader} onClick={() => this.createDirectProgram(true)} className={[classes.pricePmsBtn, classes.turnAlgoBtn, classes.uploadBtn, classes.dangerBtnHvr]} color="primary" >
                                    {t("Turn on Pricing")}{this.state.programLoader && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                                  </Button>
                                }
                              </Grid>
                            }
                            {is_admin_partners && (this.state.viewType == 'pickUp' || this.state.viewType == 'calendar' || this.state.viewType == 'priceGraph') && this.state.featuresEnabled.includes(19) &&
                              <Grid item xs={12} sm={"auto"}>
                                <Button onClick={() => (this.state.userState == 0 || this.state.userState == 3)  ? this.OpenEnableClientAccessModal():this.enableClientAccess()} className={[classes.pricePmsBtn, classes.turnAlgoBtn, classes.uploadBtn, classes.dangerBtnHvr]} color="primary" >
                                  {t("Enable Client Access")}
                                </Button>
                              </Grid>
                            }
                            {(this.state.viewType == 'revenue' || (this.state.viewType == 'priceGraph' && this.state.graphTab != 1)) &&
                              <>      
                                <div className={classes.uploadMsg}>   
                                  {this.state.revenueMsg}                             
                                </div>
                              </>
                            }
                          </Grid>
                          <div className={classes.showOnMobile}>
                            <ExpandLessIcon  onClick={this.handleChangeExpansion} />
                          </div>
                        </Grid>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>

              </Grid>
              </MuiThemeProvider>

            </HeaderCard>

            <Card style={{ maxWidth: "auto", maxWidth: "none" }}>
              <CardContent>
              <Grid item xs={12} className={classes.calendarMenuMainDiv2} style={{height:this.state.viewType == 'priceGraph'?'auto':''}} >
                    <Grid container>
                      {(this.state.viewType == 'pickUp' || this.state.viewType == 'calendar' || (this.state.viewType == 'priceGraph' && this.state.graphTab==1) || (this.state.viewType == 'priceGraph' && this.state.graphTab==2) || this.state.viewType == 'adjustment' ||  this.state.viewType == 'OTA') &&
                        // md={this.state.viewType == 'priceGraph' ? 3:''}
                        <Grid item xs={12}  sm={"auto"}>
                          <div className={classes.calendartogglediv}>
                            {/* <Typography className={classes.roomTypeLabel}>  </Typography>       */}
                            <FormControl variant="outlined" style={{ float: "left", maxWidth: "280px" }} className={classes.roomSelect}>
                              <Select
                                inputProps={{
                                  classes: {
                                    root: classes.textSecondary,
                                    icon: classes.textSecondary,
                                  },
                                }}
                                value={this.state.selectedRoom}
                                onChange={(event) => this.handleSelectRoom(event, event.target.value)}
                                input={<OutlinedInput id="outlined-age-simple" />}
                                style={{ borderRadius: "10px", height: "37px" }}
                                SelectDisplayProps={{
                                  style: { height: "100%", color: "#707070", padding: "5px 25px 6px 10px", borderRadius: "10px", fontSize: "14px", maxWidth: "300px" }
                                }}
                                select
                              >
                                {this.props.roomList.roomList.filter(val => val.is_reference_room).map(val =>
                                  <MenuItem value={val.id} style={{ fontWeight: "500" }}>{t(`${this.state.room_apartment_space_name} Type`)}: {val.name} {this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 12 ? ("(" + val.extra + ")") : ""} ({(val.is_reference_room ? t("Reference") : t("Derived"))}) </MenuItem>
                                )}
                                {/* {this.props.roomList.roomList.filter(val => !val.is_reference_room && (this.state.adjType == 5 || (allPricing && (val.pms_rate || [17, 8, 35, 39].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider))))).map(val => */}
                                {this.props.roomList.roomList.filter(val => !val.is_reference_room && (this.state.viewType == "adjustment" || (allPricing && (val.pms_rate || [17, 8, 35, 39, 42, 52, 67].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider))))).map(val =>
                                  <MenuItem value={val.id}>{t(`${this.state.room_apartment_space_name} Type`)}:  {val.name} {this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 12 ? ("(" + val.extra + ")") : ""} ({(val.is_reference_room ? t("Reference") : t("Derived"))})</MenuItem>
                                )}
                              </Select>
                            </FormControl>

                            {/* {this.state.featuresEnabled.includes(9) && (this.state.viewType == 'adjustment') && yeldingTags && yeldingTags[0] &&
                        <div className={classes.tagBox} style={{ background: yeldingTags[0].color }}>{yeldingTags[0].tag_name}</div>
                      } */}
                          </div>
                        </Grid>
                      }
                      {this.state.viewType == 'priceGraph' && this.state.graphTab == 1 &&
                      <Grid className={classes.toggleGrid} item xs={12} sm={'auto'} >
                        <Button variant="outlined" className={classes.newTabBtnFirstGraph} onClick={() => this.fullYearGraph(true)} style={this.state.showFullYearGraph ? { background: "#f2f2f2" } : {}}>
                          {t("Yearly")}
                        </Button>
                        <Button variant="outlined" className={classes.newTabBtnLastGraph} onClick={() => this.fullYearGraph(false)} style={!this.state.showFullYearGraph ? { background: "#f2f2f2" } : {}}>
                          {t("Monthly")}
                        </Button>
                      </Grid>
                      }
                      {this.state.viewType == "adjustment" &&
                        <Grid item xs={12} sm={"auto"}>
                          <div className={classes.compareDiv} style={{ float: "left", marginRight: "10px", marginLeft: "10px" }}>
                          { (this.state.featuresEnabled.includes(1) || !this.state.featuresEnabled.includes(29)) &&

                            <FormControl variant="outlined" className={classes.adjType}>
                              <Select
                                inputProps={{
                                  classes: {
                                    root: classes.textSecondary,
                                    icon: classes.textSecondary,
                                  },
                                }}
                                className={classes.textfield}
                                value={this.state.adjType}
                                onChange={(event) => this.chnageAdjType(event.target.value)}
                                input={<OutlinedInput id="outlined-age-simple" />}
                                style={{ borderRadius: "10px", height: "37px" }}
                                SelectDisplayProps={{
                                  style: { height: "100%", padding: "5px 25px 5px 10px", borderRadius: "10px", fontSize: "14px" }
                                }}
                              >
                                {this.state.featuresEnabled.includes(1) &&
                                <MenuItem value={0} >{t("Adjustment")}: {t("Percent Adjustments")}</MenuItem>
                                }
                                {this.state.featuresEnabled.includes(1) &&
                                  <MenuItem value={1} disabled={!((allPricing || roomData.is_reference_room) && (roomData.pms_rate || [8, 17, 35, 39, 42, 52, 67].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider))) || (!roomData.is_reference_room && !this.state.featuresEnabled.includes(6))} >{t("Adjustment")}: {t("Minimum and Maximum Prices")}</MenuItem>
                                }
                                {this.state.featuresEnabled.includes(1) &&
                                  <MenuItem value={2} disabled={!(allPricing && (roomData.pms_rate || [8, 17, 35, 39, 42, 52, 67].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider))) }>{t("Adjustment")}: {t(this.state.room_apartment_space_name + " Derivation")}</MenuItem>
                                }
                                {this.state.featuresEnabled.includes(1) &&
                                  <MenuItem value={3} >{t("Adjustment")}: {t("Fix Prices")}</MenuItem>
                                }
                                { !this.state.featuresEnabled.includes(29) &&
                                  <MenuItem value={5} >{t("Adjustment")}: {t("Closed Rooms")}</MenuItem>
                                }
                                {this.state.featuresEnabled.includes(1) && this.state.pmsProviderList.includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) && 
                                  <MenuItem value={6} >{t("Adjustment")}: {t("Occupancy Pricing")}</MenuItem>
                                }
                                {this.state.featuresEnabled.includes(35) &&
                                  <MenuItem value={7} >{t("Adjustment")}: {t("Aggressiveness")}</MenuItem>
                                }
                                {this.state.featuresEnabled.includes(35) && 
                                  <MenuItem value={8} >{t("Adjustment")}: {t("Median Booking Window")}</MenuItem>
                                }
                              </Select>
                            </FormControl>
                            }
                            {/* <Typography className={classes.adjustTypeLabel}>{this.state.viewType == t("adjustment") ? t("Adjustment Type") : t("View")} </Typography> */}
                          </div>
                        </Grid>
                      }
                      {(this.state.viewType == 'OTA' || this.state.graphTab == 2 )&&(this.state.viewType != 'calendar' && this.state.viewType != 'adjustment' && this.state.viewType != 'pickUp' &&  this.state.viewType != 'revenue' ) &&
                       
                        <Grid item xs={12} sm={"auto"} className={classes.calendarMenuMainDiv2}>
                          {/* ------------------OTA Room Type----------------- */}
                            <FormControl 
                             variant="outlined"
                             style={{ float: "left",marginRight:'10px' }}
                             className={classes.roomSelect}
                            >
                              <Select
                                inputProps={{
                                  classes: {
                                    root: classes.textSecondary,
                                    icon: classes.textSecondary,
                                  },
                                }}
                                value={this.state.selectedRoomType}
                                onChange={(event) => this.handleSelectedRoom(event, event.target.value)}
                                input={<OutlinedInput id="outlined-age-simple" />}
                                style={{ borderRadius: "10px", height: "37px" }}
                                SelectDisplayProps={{
                                  style: {
                                    height: "100%",
                                    padding: "5px 25px 6px 10px",
                                    borderRadius: "10px",
                                    fontSize: "14px",
                                    maxWidth: "300px",
                                    minWidth: "80px",
                                    textAlign: "left",
                                    textTransform: 'capitalize'
                                  },
                                }}
                              >
                                {this.state.roomTypes.map((row) => {
                                  return (
                                    <MenuItem value={row} key={row} style={{ textTransform: 'capitalize' }}>
                                      {t("OTA Room Type")}{": "}{row}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          {/* ------------------Rate Type----------------- */}
                            <FormControl  
                              variant="outlined"
                              style={{ float: "left",marginRight:'10px' }}
                              className={classes.roomSelect}
                            >
                              <Select
                                inputProps={{
                                  classes: {
                                    root: classes.textSecondary,
                                    icon: classes.textSecondary,
                                  },
                                }}
                                value={this.state.selectedRateType}
                                onChange={(event) => this.handleSelectRateType(event, event.target.value)}
                                input={<OutlinedInput id="outlined-age-simple" />}
                                style={{ borderRadius: "10px", height: "37px" }}
                                SelectDisplayProps={{
                                  style: {
                                    height: "100%",
                                    padding: "5px 25px 6px 10px",
                                    borderRadius: "10px",
                                    fontSize: "14px",
                                    maxWidth: "300px",
                                    minWidth: "80px",
                                    textAlign: "left",
                                    textTransform: 'capitalize'

                                  },
                                }}
                              >
                                {this.state.rateTypes.map((row) => {
                                  return (
                                    <MenuItem value={row} key={row} style={{ textTransform: 'capitalize' }}>
                                      {t("Rate")}{": "} {row == 'cheapest' ? "Lowest Rate" : "Flexible Rate"}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                           {/* ------------------Occupancy----------------- */}
                            <FormControl
                               variant="outlined"
                               style={{ float: "left",marginRight:'10px' }}
                               className={classes.roomSelect}
                            >
                              <Select
                                inputProps={{
                                  classes: {
                                    root: classes.textSecondary,
                                    icon: classes.textSecondary,
                                  },
                                }}
                                value={this.state.selectedMaxOccupancy}
                                onChange={(event) => this.handleSelectOccupancy(event, event.target.value)}
                                input={<OutlinedInput id="outlined-age-simple" />}
                                style={{ borderRadius: "10px", height: "37px" }}
                                SelectDisplayProps={{
                                  style: {
                                    height: "100%",
                                    padding: "5px 25px 6px 10px",
                                    borderRadius: "10px",
                                    fontSize: "14px",
                                    maxWidth: "300px",
                                    minWidth: "80px",
                                    textAlign: "left",
                                    textTransform: 'capitalize'
                                  },
                                }}
                              >
                                {this.state.maxOccupancy.map((row) => {
                                  return (
                                    <MenuItem value={row} style={{ textTransform: 'capitalize' }} >
                                    {t("Occupancy")}{": "+row +" "}{t("Guest")}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          {/* ------------------Competitors----------------- */}
                          {/* {this.state.graphTab != 2 && */}
                            <FormControl
                              variant="outlined"
                              style={{ float: "left",marginRight:'10px' }}
                              className={classes.roomSelect}
                            >
                              <Select
                                inputProps={{
                                  classes: {
                                    root: classes.textSecondary,
                                    icon: classes.textSecondary,
                                  },
                                }}
                                value={this.state.competitor}
                                onChange={(event) => this.handleSelectCompetitors(event, event.target.value)}
                                input={<OutlinedInput id="outlined-age-simple" />}
                                style={{ borderRadius: "10px", height: "37px" }}
                                SelectDisplayProps={{
                                  style: {
                                    height: "100%",
                                    padding: "5px 25px 6px 10px",
                                    borderRadius: "10px",
                                    fontSize: "14px",
                                    maxWidth: "300px",
                                    minWidth: "80px",
                                    textAlign: "left",
                                    textTransform: 'capitalize'

                                  },
                                }}
                              >
                                <MenuItem value={0}>
                                  {t("Competitors")}{": "}{t("All")}
                                </MenuItem>
                                {this.state.selectedCompetitors.map((row) => {
                                  return (

                                    <MenuItem value={row.competitor_id} style={{ textTransform: 'capitalize' }}>
                                    {t("Competitors")}{": "}{row.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl> 
                          {/* } */}
                        </Grid>
                      }
                      {(this.state.viewType != 'pricegraph' || !this.state.showFullYearGraph || this.state.graphTab != 1) &&
                      <div className={classNames(classes.dateNavigationDiv, classes.dateNavigationDivResponsive, (this.state.showFullYearGraph && this.state.viewType == 'priceGraph') ? classes.hidediv : '')} style={{ float: "left" }}>
                        <MuiThemeProvider theme={monthPickerStyle}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                            <InlineDatePicker
                              style={{ width: "100px", color: (this.state.showFullYearGraph && this.state.viewType == 'priceGraph') ? "#00000042" : '#000000bf', fontWeight: "400" }}
                              views={["year", "month"]}
                              value={t(moment(this.state.monthChangeDate))}
                              onChange={this.changeCalendarMonthYear()}
                              format={'MMM yyyy'}
                              autoOk={true}
                              KeyboardButtonProps={{ disabled: false, style: { display: 'none' } }}
                              InputProps={{ readOnly: true }}
                              InputLabelProps={{ shrink: true }}                              
                              maxDate={moment().add('545', 'd').format('YYYY-MM-DD')}      
                              minDate={moment().subtract('200', 'd').format('YYYY-MM-DD')}
                              minDateMessage={""}
                              maxDateMessage={""}
                            />
                          </MuiPickersUtilsProvider>

                        </MuiThemeProvider>
                            <Button
                              disabled={(this.state.showFullYearGraph && this.state.viewType == 'priceGraph')}
                              className={[classes.calendarNaviBtn, classes.textSecondary]}
                              onClick={() => this.navigateMonth(moment(this.state.monthChangeDate).subtract(1, 'months').format('YYYY-MM-DD'))}>
                              <Icon>navigate_before</Icon>
                            </Button>
                            <Button className={[classes.calendarNaviBtn, classes.textSecondary]}
                              disabled={(this.state.showFullYearGraph && this.state.viewType == 'priceGraph')}
                              onClick={() => this.navigateMonth(moment(this.state.monthChangeDate).add(1, 'months').format('YYYY-MM-DD'))}>
                              <Icon>navigate_next</Icon>
                            </Button>
                            <MuiThemeProvider theme={CalendarbuttonTheme} >
                              <Button
                                className={[classes.fullWidthResp, classes.textSecondary]}
                                disabled={(this.state.showFullYearGraph && this.state.viewType == 'priceGraph')}
                                variant="outlined"
                                style={{ margin: "0px 10px" }}
                                onClick={() => this.navigateMonth(moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'))}>
                                {t("Current Month")}
                              </Button>
                            </MuiThemeProvider>
   
                        </div>
                      }

                    {(this.state.viewType == 'pickUp') &&
                      <Grid item xs={12} sm={"auto"} style={{ float: "right", marginRight: "24px" }}>
                        <div className={classes.compareDiv}>
                          <FormControl variant="outlined" className={classes.formControlVs}>
                            <Select
                              inputProps={{
                                classes: {
                                  root: classes.textSecondary,
                                  icon: classes.textSecondary,
                                },
                              }}
                              className={classes.fullWidthResp}
                              value={this.state.pickupType}
                              onChange={(event) => this.changePickup(event.target.value)}
                              input={<OutlinedInput id="outlined-age-simple" />}
                              style={{ borderRadius: "10px", height: "36px" }}
                              SelectDisplayProps={{
                                style: { height: "100%", padding: "5px 25px 5px 10px", borderRadius: "10px", fontSize: "14px" }
                              }}
                            >
                              <MenuItem value={0}>{t("Compare to")} {t("Pickup Yesterday")}</MenuItem>
                              <MenuItem value={1}>{t("Compare to")} {t("Pickup One Week Ago")}</MenuItem>
                              <MenuItem value={2}>{t("Compare to")} {t("Pickup One Month Ago")}</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </Grid>
                    }
                    {this.state.viewType == 'revenue' &&
                      <>
                        <div style={{ color: '#707070', marginTop: '6px' }} className={classes.hideOnMobile}>
                          <span className={classes.boldFont} >
                            {revenueMonthChangeVal?(this.state.currency?.symbol)+revenueMonthChangeVal:'n.a.'}
                          </span>
                          {" " + t('Revenue')}
                        </div>
                        <div style={{ marginLeft: "16px",color:'#707070',marginTop:'6px' }} className={classes.hideOnMobile}>
                        <span className={classes.boldFont} >
                          {kpiMonthlyTotalADR?(this.state.currency?.symbol)+kpiMonthlyTotalADR:'n.a.'}  
                        </span>
                         {" "+t('ADR')}
                      </div>
                      <div style={{ marginLeft: "16px",color:'#707070',marginTop:'6px' }} className={classes.hideOnMobile}>
                        <span className={classes.boldFont} >
                        { kpiMonthlyTotalOCCUPANCY ? kpiMonthlyTotalOCCUPANCY : 'n.a.' }  
                        </span>
                        {" "+t('Occupancy')} 
                      </div>                      
                        {is_admin &&
                          <div style={{ marginLeft: "5px", color: '#707070', marginTop: '6px' }} className={classes.hideOnMobile}>
                            <HelpCenterIcon style={{ color: '#EDA942', cursor: 'pointer', fontSize: '20px', marginTop: '3px' }} onClick={this.openRevenueTable} />
                          </div>
                        }
                      </>
                    }
                    {/* {(this.state.viewType == 'priceGraph' || this.state.viewType == 'calendar') &&
                        <Grid item xs={12} sm={"auto"} style={{ float: "right", marginRight: "10px" }}>
                          <div >
                            <div className={classes.dateNavigationDiv} style={{ flexDirection: "column-reverse", alignItems: "baseline" }}>
                              <MuiThemeProvider theme={CalendarbuttonTheme} >
                                <div className={classes.graphtextDiv}>
                                  <div>
                                    <Button variant="outlined" className={[classes.newTabBtnFirst, classes.textSecondary]} onClick={() => this.changeView("calendar")} style={this.state.viewType != "priceGraph" ? { background: "#00000014" } : {}}>{t("Calendar")}</Button>
                                    <Button variant="outlined" className={[classes.newTabBtnLast, classes.textSecondary]} onClick={() => this.changeView("priceGraph")} style={this.state.viewType == "priceGraph" ? { background: "#00000014" } : {}}>{t("Chart")}</Button>
                                  </div>
                                </div>
                              </MuiThemeProvider>                              
                            </div>
                          </div>
                        </Grid>
                      } */}
                      {/* {this.state.viewType == 'priceGraph' &&
                        <div className={classes.dateNavigationDiv} style={{ float: "left", margin: "0px 15px 20px 0px", flexDirection: "column-reverse", alignItems: "baseline" }}>
                          <MuiThemeProvider theme={CalendarbuttonTheme} >
                            <div className={classes.graphtextDiv} style={{ marginTop: "4px" }}>
                              <div>
                                <Button style={{ fontSize: "14px", marginLeft: "0px", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", background: (this.state.showFullYearGraph ? "#fff" : "#f2f2f2") }} variant="outlined" className={[classes.chartBtn, classes.textSecondary
                                ]} onClick={() => this.fullYearGraph(false)}>{t("Monthly")}</Button>
                                <Button style={{ fontSize: "14px", marginRight: "0px", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", background: (!this.state.showFullYearGraph ? "#fff" : "#f2f2f2") }} variant="outlined" className={[classes.chartBtn, classes.textSecondary]} onClick={() => this.fullYearGraph(true)}>{t("Yearly")}</Button>
                              </div>
                            </div>
                          </MuiThemeProvider>
                          <Typography className={classes.viewButtons}> {t("Chart View")} </Typography>
                        </div>
                      } */}
                      {this.state.viewType == 'calendar' &&
                        <Grid item xs={12}  sm={"auto"}>
                          <div style={{ float: "left", display: "flex", marginTop:'5px' , marginBottom: "21px", minHeight: "25px", }}>
                            {this.state.performance.future && this.state.performance.future.length > 0 && (this.state.viewType == 'calendar' || this.state.viewType == 'priceGraph' || this.state.viewType == 'occCalendar') &&
                              <div style={{color:'#707070'}} ><span className={classes.boldFont}>{this.getExpectedOccupancy().actual != null ? this.getExpectedOccupancy().actual : "n.a."}</span>{t(' Occupancy')}</div>
                            }

                            {this.state.performance.future && this.state.performance.future.length > 0 && (this.state.viewType == 'calendar' || this.state.viewType == 'priceGraph' || this.state.viewType == 'occCalendar') &&
                              <Tooltip
                                classes={{
                                  popper: classes.htmlPopper,
                                  tooltip: classes.htmlTooltip,
                                }}
                                title={<Typography className={classes.htmlTooltip}>
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ margin: "5px 0px" }}>{t('By today, for')} {moment(this.state.monthChangeDate).format('MMM YYYY')}, {t('you have sold:')} <br /><b>{this.getHealthScore().room_sold ? this.getHealthScore().room_sold : (this.getHealthScore().room_sold == 0 ? this.getHealthScore().room_sold : t('n.a.'))}</b> {t('room nights')}.</div>
                                    <div style={{ margin: "5px 0px" }}>{t('At this point given your target occupancy and lead time you would expect to have sold:')} <br /><b>{this.getHealthScore().expected_room_sold ? this.getHealthScore().expected_room_sold : (this.getHealthScore().expected_room_sold == 0 ? this.getHealthScore().expected_room_sold : t('n.a.'))}</b> {t('room nights')}.</div>
                                  </div>
                                </Typography>}
                              >
                                <div style={{ marginLeft: "16px",color:'#707070',marginRight:'19px' }} className={classes.hideOnMobile}>
                                  <span className={classes.boldFont} >
                                    {this.getHealthScore().expected_occupancy != undefined ? (this.getHealthScore().expected_occupancy + "%") : "n.a."}
                                    {/* {this.getHealthScore().room_sold ? this.getHealthScore().room_sold : t('n.a.')}/{this.getHealthScore().expected_room_sold ? this.getHealthScore().expected_room_sold : t('n.a.')} */}
                                  </span>
                                  {t(' Expected Occupancy by Today')}
                                </div>
                              </Tooltip>
                            }
                          </div>

                        </Grid>
                      }
                      {(this.state.viewType == 'calendar') &&
                        <Grid item xs={12} sm={"auto"} style={{ float: "right", marginRight: "10px" }}>
                          <div className={classes.compareDiv}>
                            <FormControl variant="outlined" className={classes.formControlVs}>
                              <Select
                                inputProps={{
                                  classes: {
                                    root: classes.textSecondary,
                                    icon: classes.textSecondary,
                                  },
                                }}
                                className={classes.fullWidthResp}
                                value={this.state.calendarType}
                                onChange={(event) => this.chnageCalendarType(event.target.value)}
                                input={<OutlinedInput id="outlined-age-simple" />}
                                style={{ borderRadius: "10px", height: "37px" }}
                                SelectDisplayProps={{
                                  style: { height: "100%", padding: "5px 25px 5px 10px", borderRadius: "10px", fontSize: "14px" }
                                }}
                              >
                                <MenuItem value={0}>{t("Compare to")} {t(this.state.pms_channel_manager_text  + " Price")}</MenuItem>
                                <MenuItem value={1}>{t("Compare to")} {t("Yesterday’s Price")}</MenuItem>
                                <MenuItem value={2}>{t("Compare to")} {t("Last Week’s Price")}</MenuItem>
                              </Select>
                            </FormControl>
                            {/* <Typography className={classes.compareLabel} > {t("Compare Price to")} </Typography> */}
                          </div>
                        </Grid>
                      }
                      {(this.state.viewType == 'calendarBooking' || this.state.viewType == 'pickUp') &&

                        <Grid item xs={12} sm={"auto"}  style={{ float: "right", marginRight: "10px" }} className={classNames(classes.hideOnMobile)} >
                            <div style={{marginTop: "10px" }}>
                              <Grid container className={classes.occupancyIndication}>
                                <Grid item xs={12}>
                                  <Grid container>
                                    <Tooltip
                                      classes={{
                                        popper: classes.htmlPopper,
                                        tooltip: classes.bookingTooltip,
                                      }}
                                      title={<Typography className={classes.bookingTooltip}>
                                        {t('Not enough bookings')} <span style={{ display: "inline-block", width: "13px", height: "13px", borderRadius: "15px", background: '#FFA400' }}> </span>: {t('Your bookings are coming in slower than we would expect given your Occupancy Targets and a lead time.')}<br />
                                        {t('Too many bookings')} <span style={{ display: "inline-block", width: "13px", height: "13px", borderRadius: "15px", background: '#68BEC9' }}></span>: {t('Your bookings are coming in faster than we would expect given your Occupancy Targets and a lead time.')}<br /><br />
                                        <span style={{ textDecoration: "underline" }}>{t('How do I use this?')}</span> <br />
                                        {t('This page is simply a check on how you are doing against your Target Occupancy you have set. You can use it to get a basic overview of performance.')}<br />
                                        <br />
                                        <span style={{ textDecoration: "underline" }}>{t('What is RoomPriceGenie doing if these are all orange or all blue?')}</span> <br />
                                        {t('From 9 months out we look at your whole month occupancy and see if it is on target. We adjust our demand forecast with this in mind. For example, if you were charging too much and the bookings are not coming in, we will adjust your prices downwards, in line with the realized demand.')}<br />
                                        <br />
                                        <span style={{ textDecoration: "underline" }}>{t('What should I do?')}</span> <br />
                                        {t('If you are consistently seeing orange or blue prices, or if you are consistently seeing them for certain weekdays or certain seasons, we may need to adjust your original setup.  Please contact us if you would like help here.')}
                                      </Typography>}
                                    >
                                      <Grid item xs={6} style={{ paddingBottom: "1px", textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                                        <span style={{ display: "block", width: "13px", height: "13px", borderRadius: "15px", background: '#FFA400' }}> </span>
                                        <span className={classes.occupancyIndicationIcon}>{t('Not Enough Bookings')}</span>
                                      </Grid>
                                    </Tooltip>
                                    <Tooltip
                                      classes={{
                                        popper: classes.htmlPopper,
                                        tooltip: classes.bookingTooltip,
                                      }}
                                      title={<Typography className={classes.bookingTooltip}>
                                        {t('Not enough bookings')} <span style={{ display: "inline-block", width: "13px", height: "13px", borderRadius: "15px", background: '#FFA400' }}> </span>: {t('Your bookings are coming in slower than we would expect given your Occupancy Targets and a lead time.')}<br />
                                        {t('Too many bookings')} <span style={{ display: "inline-block", width: "13px", height: "13px", borderRadius: "15px", background: '#68BEC9' }}></span>: {t('Your bookings are coming in faster than we would expect given your Occupancy Targets and a lead time.')}<br /><br />
                                        <span style={{ textDecoration: "underline" }}>{t('How do I use this?')}</span> <br />
                                        {t('This page is simply a check on how you are doing against your Target Occupancyyou have set. You can use it to get a basic overview of performance.')}<br />
                                        <br />
                                        <span style={{ textDecoration: "underline" }}>{t('What is RoomPriceGenie doing if these are all orange or all blue?')}</span> <br />
                                        {t('From 3 months out we look at your whole month occupancy and see how it compares to your Target Occupancy for the month. We adjust our demand forecast with this in mind. For example, if you were charging too much and the bookings are not coming in, we will adjust average price for the whole month downwards')}<br />
                                        <br />
                                        <span style={{ textDecoration: "underline" }}>{t('What should I do?')}</span> <br />
                                        {t('If you are consistently seeing orange or blue prices, or if you are consistently seeing them for certain weekdays or certain seasons, we may need to adjust your original setup.  Please contact us if you would like help here.')}
                                      </Typography>}
                                    >
                                      <Grid item xs={6} style={{ paddingRight: "12px", paddingBottom: "1px", textAlign: 'left', display: 'flex', flexDirection: "row-reverse", alignItems: 'center' }}>
                                        <span className={classes.occupancyIndicationIcon} style={{ color: "#A0A2A6" }}>{t('Too Many Bookings')}</span>
                                        <span style={{ display: "block", width: "13px", height: "13px", borderRadius: "15px", background: '#68BEC9' }}></span>
                                      </Grid>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </div>
                        </Grid>
                          }
                    </Grid>
                  </Grid>
                  
                  <div key={rooms.id.toString()} className={this.state.viewType == 'priceGraph' ? classes.grapgDiv : classes.calDiv} >                  
                  {
                    this.state.viewType == 'priceGraph' && 
                    <div className={classes.graphView}  >
                      {this.state.graphTab == 1 &&
                        <div style={{overflowX:'auto'}}>
                          <Line height={440} onElementsClick={(data) => this.handleGraphClick(data)}  options={graphOptions} ref="chart" data={data} plugins={plugins}/>
                        </div>
                      }
                      {this.state.graphTab == 2 &&
                        <div style={{overflowX:'auto',marginTop:"-10px"}}>
                          <Line height={440}   options={otaGraphOptions} ref={this.myChartRef} data={otaData} plugins={plugins}/>
                        </div>
                      }
                     
                    </div>
                  }
                  
                  {(this.state.viewType == 'calendar' || this.state.viewType == 'calendarBooking' || this.state.viewType == 'pickUp' || this.state.viewType == 'adjustment' || this.state.viewType == 'OTA' || this.state.viewType == 'revenue') &&
                    <BigCalendar
                      tooltipAccessor={(event) => { return null }}
                      key={rooms.id}
                      className={(this.state.viewType == 'calendar' || this.state.viewType == 'calendarBooking' || this.state.viewType == 'OTA') ? classes.calenderStyle : classes.occCalenderStyle}
                      localizer={localizer}
                      events={this.state.myEventsList[rooms.id] ? this.state.myEventsList[rooms.id] : []}
                      onSelectEvent={event => this.handleClickOpen(event, rooms.id)}
                      onNavigate={(date) => this.monthChange(date)}
                      eventPropGetter={(this.eventStyleGetter)}
                      views={['month']}
                      startAccessor="start"
                      endAccessor="end"
                      defaultView='month'
                      date={this.state.monthChangeDate}
                      onNavigate={(date) => { this.navigateMonth(date) }}
                      onSelectSlot={(this.state.viewType == 'adjustment' || this.state.viewType == 'calendar' ) ? this.handleSlotSelection : ""}
                      selectable={(this.state.viewType == 'adjustment' || this.state.viewType == 'calendar' ) ? true : false}
                    />
                  }
                  
                  

                </div >
              </CardContent>
            </Card>
          </>
        )
      })
    }
  }

  changeCalendarMonthYear = () => dateValue => {  
    this.navigateMonth(moment(dateValue).format('YYYY-MM-DD'))
  }

  renderInginiusFiles = () => {
    const { classes, t } = this.props;
    // return this.state.inginiusFiles.filter(val => val.id == this.state.selectedRoom).map((val) => {
      return (
        <Button
        // href={val.url} 
        onClick={this.toggleCsvFormatPopup}
        className={classNames(classes.pricePmsBtn, classes.diagnosticBtn,classes.hideOnMobile,classes.textSecondary)}
         >
          {t("Download CSV")}
        </Button>
      )
    // })
  }

  renderProfit = () => {
    const { classes, t } = this.props;
    return (
        <Button
        onClick={() => this.getPdf()}
        disabled={this.state.profitReportLoading}
        className={classNames(classes.pricePmsBtn, classes.diagnosticBtn,classes.hideOnMobile,classes.textSecondary)}
         >
          {t("Profit Report")}
          {this.state.profitReportLoading && <CircularProgress color="#707070" size={24} className={classes.buttonProgress} />}
        </Button>
      )
  }
   renderImportPMSDataButton = () => {
    const { classes, t } = this.props;
    return (
        <Button
        onClick={() => this.openImportPMSDataModal()}
        // disabled={this.state.profitReportLoading}
        className={classNames(classes.pricePmsBtn, classes.diagnosticBtn,classes.hideOnMobile,classes.textSecondary)}
         >
          {t("Import PMS data")}
          {this.state.profitReportLoading && <CircularProgress color="#707070" size={24} className={classes.buttonProgress} />}
        </Button>
      )
  }
 
  getPdf = () => {
    let reqParams={
      token: this.props.hotelAuthReducer.hotelDetails.token
    }
    this.props.getProfitReport(reqParams,this.openPdfModal)
  }
  openImportPMSDataModal = () => {
    this.setState({
      importPMSDataModalopen:true
    })
  }
  closeImportPMSDataModal = () =>{
    this.setState({
      importPMSDataModalopen:false
    })
  }

  handle_PMS_data_json_file = event => {

    const imageFile = event.target.files[0];
    this.setState({ 
        importPMSDataFileRequire: false,
        importPMSDataFileValidationMessage: "",
       });
    if (!imageFile) {
      this.setState({ 
        importPMSDataFileRequire: true,
        importPMSDataFileValidationMessage: "Please select file.",
       });
      return false;
    }
    if (!imageFile.name.match(/\.(json)$/)) {
      this.setState({ 
        importPMSDataFileRequire: true,
        importPMSDataFileValidationMessage: "Please select valid file.",
       });
      return false;
    }

    this.setState({
      pms_json_file: event.target.files[0]
    });
  }

  saveImportPMSDataJson = () => {

    const { pms_json_file } = this.state;

    if(!pms_json_file) {

      this.setState({ 
        importPMSDataFileRequire: true,
        importPMSDataFileValidationMessage: "Please select file.",
       });

       return false;
    }
      const formData = new FormData();
      formData.append("file",pms_json_file);
      let reqParams={
        token: this.props.hotelAuthReducer.hotelDetails.token,
        file: formData
      }
      
      this.props.saveImportPMSDataJson(reqParams)

      this.setState({
        importPMSDataModalopen:false
      })
  }

  EnableDetection = () => {

    const totalFeatures  = [...this.state.featuresEnabled, 29];
    let start_date = this.state.start_date
    let end_date = moment(start_date).add('365', 'd').format('YYYY-MM-DD');
    let requestParam = {
        token: this.props.hotelAuthReducer.hotelDetails.token,
        features: totalFeatures,
        isMinMax:  false,
        is_calculate_no_of_rooms: false,
    }

    this.props.ClosedRoomsDetection({token:this.props.hotelAuthReducer.hotelDetails.token,flag_closed_room_popup:false});

    this.setState({
      featuresEnabled: totalFeatures,
      detectclosedroomspms:false,
      flag_closed_room_popup:false
    },() => {
      this.props.enableFeature(requestParam);      
    });

    this.setState({
      end_date: end_date,
      get_pms_data:true,
    },() => { this.runPricing(true) })
  }

  EnableDetectionModal = () => {  

    if(!this.state.featuresEnabled.includes(29) && [1, 2, 15, 52, 7, 71].includes(this.state.pms_provider) && this.state.flag_closed_room_popup && !this.props.userSession.user.is_staff){
      this.setState({
        detectclosedroomspms:true
      })
    }
  }

  closedetectClosedRoomsPMS = () =>{
    this.setState({
      detectclosedroomspms:false,
      flag_closed_room_popup:false
    },() => this.props.ClosedRoomsDetection({token:this.props.hotelAuthReducer.hotelDetails.token,flag_closed_room_popup:false}))
  }
  openPdfModal=()=>{
    this.setState({
      profitReportSuccess:true
    })
  }
  closePdfModal=()=>{
    this.setState({
      profitReportSuccess:false
    })
  }

  handleFormat = (value) => {
    this.setState({
      csvFormat: value
    })
    
  }

  getRoomName = () => {
    if (this.props.roomList.roomList && this.state.selectedRoom) {
      let Room = this.props.roomList.roomList.filter(val => val.id == this.state.selectedRoom)
      return Room[0].name;
    }
    return ""
  }

  handleUpdatePMS() {

    let value = !this.state.is_update_to_pms;

    if (value) {
      this.setState({
        openAutoUpdateConfirm: false,
        updateHotelPms: true,
      })
      this.toggleupdatePmsType();
    } else {
      this.setState({
        is_update_to_pms: value,
        openAutoUpdateConfirm: false
      },
        () => this.getCalendarData()
      )

      let requestParam = {
        token: this.props.hotelAuthReducer.hotelDetails.token,
        is_update_to_pms: value,
      }

      this.props.updateHotel(requestParam);
    }
  }

  handlePmsType(event) {
    let value = parseInt(event.target.value);
    this.setState({
      updatePmsType: value
    })
  }

  handleSkipUpdate(event) {
    let value = parseInt(event.target.value);
    if (value >= this.state.updatePmsType) {
      this.setState({ skipValidation: true })
      return null;
    } else {
      this.setState({ skipValidation: false })
    }

    if (value == this.state.skipUpdate) {
      this.setState({
        skipUpdate: 0
      })
    } else {
      this.setState({
        skipUpdate: value
      })
    }
  }

  updateSkipDateType = () => {

    let value = !this.state.is_update_to_pms;
    setTimeout(() => {
    this.setState({
      confettiStart:true
    })
    }, 7000)
    if (this.state.updateHotelPms) {
      this.setState({
        is_update_to_pms: value,
        openAutoUpdateConfirm: false,
        
      })

      let requestParam = {
        token: this.props.hotelAuthReducer.hotelDetails.token,
        is_update_to_pms: value,
        update_to_pms_skip_type: this.state.skipUpdate,
        update_to_pms_type: this.state.updatePmsType
      }
      this.getCalendarData();
      this.props.updateHotel(requestParam);
    } else {
      let requestParam = {
        token: this.props.hotelAuthReducer.hotelDetails.token,
        update_to_pms_skip_type: this.state.skipUpdate,
        update_to_pms_type: this.state.updatePmsType
      }
      this.props.updateHotel(requestParam);
     
    }

    this.setState({
      openUpdatePmsType: false,
      updateHotelPms: false,
     
    })

    this.createDirectProgram(value);
    setTimeout(() => {
     this.setState({
      confettiStart:false
     })
    }, 11000)
  }

  getpmsUploadType = () => {
    let data = this.state.types.filter(opt => opt.key == this.state.updatePmsType);
    if (data.length > 0) {
      return data[0].value;
    }
    return '';
  }

  targetOccupancyToggle = () => {
    this.setState({
      targetOccupancyModal: !this.state.targetOccupancyModal
    })
  }

  getHealthScore = () => {
    if (this.state.performance.future) {
      let performance = this.state.performance.future.filter(val => val.month == moment(this.state.monthChangeDate).format('MM') && val.year == moment(this.state.monthChangeDate).format('YYYY'));
      
      if(performance.length == 0){
        performance = this.state.performance.past.filter(val => val.month == moment(this.state.monthChangeDate).format('MM') && val.year == moment(this.state.monthChangeDate).format('YYYY'));
      }      
      if (performance.length > 0) {
        let HealthData = performance[0];

        HealthData.level = 0;
        if (HealthData.health_score < 70) {
          HealthData.level = "Attention";
          HealthData.color = "#FFBE0B";
        } else if (HealthData.health_score > 70 && HealthData.health_score < 85) {
          HealthData.level = "Good";
          HealthData.color = "#92d050";
        } else if (HealthData.health_score > 85) {
          HealthData.level = "Great";
          HealthData.color = "#00b04f";
        }

        if(HealthData.sold_room != undefined){
          HealthData.room_sold = HealthData.sold_room;
        }

        return HealthData;
      }
    }
    return {};
  }

  getExpectedOccupancy = () => {

    let month = (moment(this.state.monthChangeDate).format('YYYY-MM'));

    let actual = "n.a.";
    let dateDiff = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).diff(this.state.monthChangeDate, 'days');
    let dateDiffMonth = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).diff(this.state.monthChangeDate, 'month');

    if (this.state.monthlyOccupancy && this.state.monthlyOccupancy[month] != null) {
      actual = Math.round(this.state.monthlyOccupancy[month]) + "%"
    }
    return {
      actual: actual
    }   
  }

  handleClickBulk = (event) => {
    let bulkEditType = 1
    if (this.state.adjType == 8) {
      bulkEditType = 8
    } else if (this.state.adjType == 7) {
      bulkEditType = 7
    } else if (this.state.adjType == 6) {
      bulkEditType = 6
    } else if (this.state.adjType == 5) {
      bulkEditType = 5
    } else if (this.state.adjType == 3) {
      bulkEditType = 4
    } else if (this.state.adjType == 2) {
      bulkEditType = 3
    } else if (this.state.adjType == 1) {
      bulkEditType = 2
    } else {
      bulkEditType = 1
    }

    this.setState({
      bulkEditType,
      bulkStartDate: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'),
      bulkEndDate: moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'),
    },() => { 
      this.calculateBulkSumOfAmount(new Date(this.state.bulkStartDate),new Date(this.state.bulkEndDate)); 
    })    

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      room_id: this.state.selectedRoom,
    };

    this.props.getOccupancyPrices(requestParam);

    this.bulkDateAdjModalToggle();

  };
  editNotes = () =>{
    this.setState({
      loadingNotesEdit:true
    })
    calendarEvents.saveMultipleNotes(this,settingJsonData,0,'Edit')
  }
  saveNotes = () =>{
    // let validateError = this.validateNotes(this.state.priceNote);
    // debugger
    // if (validateError.length > 0) {
    //   return;
    // }    
    this.setState({
      loadingNotes:true
    })
    calendarEvents.saveMultipleNotes(this, settingJsonData)
    
  }
  deleteNotes = (val) =>{
    this.setState({
      loadingNotesDelete:true
    })
    calendarEvents.saveMultipleNotes(this,settingJsonData,val,'Delete')
  }

  handleEditNotes = (val,modelOpen) =>{
    if(!modelOpen){
      this.setState({
        noteEditEnable:true,
        editNotesValue:val,
        notesStartEndDate:false,
        priceNote:'',
      })
    
    }   
  }
  handleCloseEditNotes=()=>{
    this.setState({
      noteEditEnable:false,
      notesStartEndDate:false,
      editNotesValue:{}
    })
  }
  editNotesData = (name) => event =>{
   this.setState({
     editNotesValue:{
      ...this.state.editNotesValue,
      [name]:event.target.value
     }
   })
  }
  editNotesData2 = (name) => dateValue =>{
    this.setState({
      editNotesValue:{
       ...this.state.editNotesValue,
       [name]:dateValue
      }
    })
   }
  
  handleCloseBulk = () => {
    this.setState({
      anchorEl: null,
    })
  };


  toggleCsvFormatPopup = () =>{
    this.setState({
      csvFormatPopup: !this.state.csvFormatPopup
    })
  }


  calculateBulkSumOfAmount = (start ,end) => { 
    let startDate = moment(start).format('YYYY-MM-DD');
    let endDate = moment(end).format('YYYY-MM-DD');
    let daysDifferenceCount = moment(endDate).diff(startDate, 'days') + 1; //Added + to also count total days within start date and end date
    const pricing_result = this.state.pricing_result
    
    //For Calculation Average Price and Sum of Price in Bulk Edit Popup
    //First Set Default To Zero
    this.setState({
      bulkSumOfPrice: 0,
      bulkAveragePrice: 0,
      bulkNumberOfDays:0
    })

    
    let bulkSumOfPrice = 0;       
    bulkSumOfPrice = Object.keys(pricing_result).filter((key) => key >= startDate && key <= endDate).reduce((result, key) => {                    
      return result + (pricing_result[key][this.state.selectedRoom]?.price || 0)         
    },0)

    let bulkAveragePrice = 0;

    if(bulkSumOfPrice > 0){     
      bulkAveragePrice = Math.round(bulkSumOfPrice / daysDifferenceCount);
    }

    this.setState({
      bulkSumOfPrice: Math.round(bulkSumOfPrice),
      bulkAveragePrice,
      bulkNumberOfDays: daysDifferenceCount
    })
    
    //End of Calculation

  };

  render() {
    return (
      <div>
            {this.state.confettiStart && <ConfettiJoy />}
            {this.state.value === "0" &&
              this.renderCalendar()
            }

            {this.state.PriceModalopen && Modals.PriceModal(this)}
            {this.state.ManuallPriceOpen && Modals.RunManuallmodal(this)}
            {this.state.showUpdatePrice && Modals.RunPricemodal(this)}
            {this.state.happyPricing && Modals.happyPricing(this)}
            {this.state.openSkipDate && Modals.dateSkipModal(this)}
            {this.state.openDateAdjustment && Modals.DailyAdjustModal(this)}
            {this.state.openUpdatePmsType && Modals.udpatePmsType(this)}
            {this.state.openAutoUpdateConfirm && Modals.autoUpdateConfirm(this)}
            {this.state.openBulkUpdate && Modals.bulkUpdate(this)}
            {this.state.mothlyModal && Modals.monthlyAdjustModal(this)}
            {this.state.weeklyModal && Modals.WeeklyAdjustModal(this)}
            {this.state.leavePage && Modals.leavePageModal(this)}
            {this.state.settingAlert && Modals.rerunAlertPopup(this)}
            {this.state.bulkDateAdjModal && Modals.bulkDateAdjustment(this)}
            {this.state.bulkRoomModal && Modals.bulkRoomAdjustment(this)}
            {this.state.removeAdjModal && Modals.removeAdjModal(this)}
            {this.state.autoRunModal && Modals.autoRunModal(this)}
            {this.state.uploadSingleModal && Modals.uploadSingleModal(this)}
            {this.state.csvFormatPopup && Modals.csvFormatPopup(this)}
            {this.state.profitReportSuccess && Modals.profitReportModal(this)}
            {this.state.opentRevenueTableModal && Modals.revenueTable(this)}
            {this.state.chargebeeModal && Modals.chargebeeNotification(this)}
            {this.state.importPMSDataModalopen && Modals.importPMSDataModal(this) }
            {this.state.detectclosedroomspms && Modals.detectClosedRoomsPMSModal(this) }
            {this.state.clientAccessModal && Modals.clientAccessModal(this)}
      </div >
    );
  }
}

CalulatePrice.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
  return {
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    commonReducer: state.commonReducer,
    userSession: state.sessionReducer,
    hotelDetail: state.hotelSettingReducer.hoteDetail,
    currencyList: state.currencyReducer.currencyList,
    hotelAuthReducer: state.hotelAuthReducer,
    inventaryReducer: state.inventaryReducer,
    roomList: state.roomListReducer,
    eventReducer: state.eventReducer,
    eventCategoryReducer: state.eventCategoryReducer,
    OTAInsightsReducer: state.OTAInsightsReducer,
    rateSettingReducer: state.rateSettingReducer,
    dailyAdjustmentReducer: state.dailyAdjustmentReducer,
    priceSettingReducer: state.priceSettingReducer,
    monthlyAdjustReducer: state.monthlyAdjustReducer,
    weeklyAdjustReducer: state.weeklyAdjustReducer,
    featuresReducer: state.featuresReducer,
    skipPriceReducer: state.skipPriceReducer,
    algoReducer: state.algoReducer,
    OTASetupReducer: state.OTASetupReducer,
    PerformanceDashboardReducer: state.PerformanceDashboardReducer,
    popupMessageReducer:state.popupMessageReducer,
    pmsList: state.PmsListReducer.pmsList,
    connectionReducer: state.connectionReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateTempProp: (request) => { dispatch(actions.updateTempProp(request)) },
    onGetOtaCompetitor: (request) => { dispatch(actions.onGetOtaCompetitor(request)) },
    getCompetitor: (request) => { dispatch(actions.getCompetitor(request)) },
    getInventary: (request) => { dispatch(actions.getInventary(request)) },
    updateInventary: (request) => { dispatch(actions.updateInventary(request)) },
    uploadFile: (request) => { dispatch(actions.uploadFile(request)) },
    getRoomList: (request) => { dispatch(actions.getRoomList(request)) },
    updatePricePms: (request) => { dispatch(actions.updatePricePms(request)) },
    // getEventList: (request) => { dispatch(actions.getEventList(request)) },
    // getImportantEventList: (request) => { dispatch(actions.getImportantEventList(request)) },
    getEventCategory: (request) => { dispatch(actions.getEventCategory(request)) },
    importantEvents: (request) => { dispatch(actions.importantEvents(request)) },
    getHotelDetail: (request) => { dispatch(actions.getHotelDetail(request)) },
    putNotify: (request) => { dispatch(actions.putNotify(request)) },
    updateHotel: (request) => { dispatch(actions.updateHotelSetting(request)) },
    dailyAdjustCreate: (request) => { dispatch(actions.dailyAdjustCreate(request)) },
    updateDailyAdjust: (request) => { dispatch(actions.updateDailyAdjust(request)) },
    updateDailyAdjust: (request) => { dispatch(actions.updateDailyAdjust(request)) },
    skipPriceCreate: (request) => { dispatch(actions.skipPriceCreate(request)) },
    updateSkipPrice: (request) => { dispatch(actions.updateSkipPrice(request)) },
    getPricingSettings: (request) => { dispatch(actions.getPricingSettings(request)) },
    updatePricingSettings: (request) => { dispatch(actions.updatePricingSettings(request)) },
    updateMonthlyAdjust: (request) => { dispatch(actions.updateMonthlyAdjust(request)) },
    updateWeeklyAdjust: (request) => { dispatch(actions.updateWeeklyAdjust(request)) },
    updateLastPricing: (request) => { dispatch(actions.updateLastPricing(request)) },
    createAlgoProgram: (request) => { dispatch(actions.createAlgoProgram(request)) },
    getAlgoProgram: (request) => { dispatch(actions.getAlgoProgram(request)) },
    stopNavigation: (request) => { dispatch(actions.stopNavigation(request)) },
    showNavigationPopup: (request) => { dispatch(actions.showNavigationPopup(request)) },
    updatePriceJson: (request, response, type) => { dispatch(actions.updateSettingJson(request, response, type)) },
    getWeatherDetails: (request) => { dispatch(actions.getWeatherDetails(request)) },
    setJsonProp: (data) => { dispatch(data) },
    setCalendarRoom: (request) => { dispatch(actions.setCalendarRoom(request)) },
    storeOtaCompetitorRates: (request) => { dispatch(actions.storeOtaCompetitorRates(request)) },
    enableFeature: (request) => { dispatch(actions.enableFeature(request)) },
    getOtaCompetitorRates: (request) => { dispatch(actions.getOtaCompetitorRates(request)) },
    getOtaDropdownData: (request) => { dispatch(actions.getOtaDropdownData(request)) },
    storeOtaDropdownData: (request) => { dispatch(actions.storeOtaDropdownData(request)) },
    storeOtaCompetitorSetup: (request) => { dispatch(actions.storeOtaCompetitorSetup(request)) }, 
    selectedCompetitorsCsv: (request) => { dispatch(actions.selectedCompetitorsCsv(request)) },
    getProfitReport:(request,openPdfModal)=>{dispatch(actions.getProfitReport(request,openPdfModal))},
    getPerformanceReservation: (request) => { dispatch(actions.getPerformanceReservation(request)) },
    getUserNotes: (request) => { dispatch(actions.getUserNotes(request)) },
    getChargebeeSubscriptionLink:(request)=>{dispatch(actions.getChargebeeSubscriptionLink(request))},
    storeChargebeeNotification:(request)=>{dispatch(actions.storeChargebeeNotification(request))},
    saveImportPMSDataJson:(request)=>{dispatch(actions.saveImportPMSDataJson(request))},
    popupAlertMessage:(request)=>{dispatch(actions.popupAlertMessage(request))},
    ClosedRoomsDetection:(request)=>{dispatch(actions.ClosedRoomsDetection(request))},
    getPmsList: (request) => { dispatch(actions.getPmsList(request)) },
    getOccupancyPrices: (request) => { dispatch(actions.getOccupancyPrices(request)) },
    getRunPriceFlags:(request)=>{dispatch(actions.getRunPriceFlags(request))},
    updateFreeTrial: (request) => { dispatch(actions.updateFreeTrial(request)); },
    getOccupancyPricesAll: (request) => { dispatch(actions.getOccupancyPricesAll(request)) },
    getConnectionList: (request) => { dispatch(actions.getConnectionList(request)) },

  };
};
export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(CalulatePrice));
