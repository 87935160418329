import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FeaturesCss from './css/featuresCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../store/actions/index';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import { withNamespaces } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import HeaderCard from './HeaderCard';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import Modals from '../../App/Client/settings/modal/modal';

const styles = FeaturesCss;

class Features extends React.Component {

    state = {
        featuresEnabled: [],
        room_apartment_space_name: "",
        openDate:false,
        trialDate: "",
        disabled_client_access_flag_change:false,
        setDefaultModal:false,
        is_default_min_max:null,
        featuresEnabledOld:[],
        IbelsaUserPass:{
            username:null,
            password:null,
            provider:null,
        }
    }

    componentDidMount() {

        let requestParam = { token: this.props.hotelAuthReducer.hotelDetails.token }
        this.props.getHotelDetail(requestParam);
        let AuthtokenParam = { Authtoken: this.props.hotelAuthReducer.hotelDetails.token }
        this.props.getConnectionList(AuthtokenParam);
        this.setState({
            room_apartment_space_name: this.props.hotelAuthReducer.hotelDetails.hotel.room_apartment_space_name
        });
    }

    componentWillReceiveProps(props) {
      
        if (props.commonReducer.notify && props.commonReducer.notify.message) {
            this.setState({
                loader: false
            })
        }

        if (props.priceSettingReducer && props.priceSettingReducer.priceSetting && this.state.featuresEnabled.length == 0) {
            this.setState({
                featuresEnabled: props.priceSettingReducer.priceSetting.features,                
            })
        }
        if (this.props.hotelDetailReducer.hoteDetail && this.props.hotelDetailReducer.hoteDetail.free_trial_end) {
            this.setState({
              trialDate: moment(this.props.hotelDetailReducer.hoteDetail.free_trial_end).format("YYYY-MM-DD")
            })
        }
        if (props.connectionReducer && props.connectionReducer.connectionList && props.connectionReducer.connectionList[0]){            
            let currentPMSData = props.connectionReducer.connectionList[0];
            this.setState({
                IbelsaUserPass:{
                    username:currentPMSData?.username,
                    password:currentPMSData?.password,
                    provider:currentPMSData?.provider,
                }
            })
        }
        this.setState({
            featuresEnabledOld: JSON.stringify(props.priceSettingReducer.priceSetting.features),
        })
    }

    handleChange = id => event => {

        let totalFeatures = this.state.featuresEnabled;

        if (event.target.checked) {
            totalFeatures.push(id);
        } else {
            if(id == 20){
                totalFeatures = totalFeatures.filter(val => val !== id && val !== 25);
                totalFeatures = totalFeatures.filter(val => val !== id && val !== 31);
            }else{
                totalFeatures = totalFeatures.filter(val => val !== id);
            } 
            if(id == 20){
                totalFeatures = totalFeatures.filter(val => val !== id && val !== 31);
            }
        }
        if(id == 19 && !event.target.checked && (this.props.hotelDetailReducer.hoteDetail.state == 0)){
            this.setState({
                openDate:id == 19 ? true :false
            })
        }
        this.setState({
            featuresEnabled: totalFeatures,
            isMinMax: id == 6 ? true : false,
            is18months: id == 23 ? true : false,
            is_calculate_no_of_rooms: ((id == 8 || id == 9) && (totalFeatures.includes(8) || totalFeatures.includes(9))) ? true : false,
        })
        if(id == 19){
            this.setState({
                disabled_client_access_flag_change:true
            })
        }
    };
    closeEditDate = () => {
        this.setState({
          openDate: !this.state.openDate,
        });
      };
    handleDate = (event) => {
        this.setState({
          trialDate: event,
        });
    };
    updateFreeTrial = () => {
        let requestParam = {
          token: this.props.hotelAuthReducer.hotelDetails.token,
          date: moment(this.state.trialDate).format("YYYY-MM-DD"),
        };
        this.props.updateFreeTrial(requestParam);
        this.closeEditDate();

    };

    handleDefaultMinMax = () => {
        let enableFeatureold  = JSON.parse(this.state.featuresEnabledOld);
        let totalFeatures = this.state.featuresEnabled;
        if(!enableFeatureold.includes(6) && totalFeatures.includes(6) && this.state.is_default_min_max == null){
            this.setState({
                setDefaultModal: true,
            })
        }else{
           this.updateFeatures();
             this.setState({
                is_default_min_max: null,
            })
        }
    }

    handleDefaultMinMaxModal = (boolean) => {
        this.setState({
            setDefaultModal: false,
            is_default_min_max:boolean,
        },() => this.updateFeatures())
    }

    updateFeatures = () => {

        this.setState({
            loader: true
        })

        let totalFeatures = this.state.featuresEnabled;
        let genieIntelligenceFetureEnable = totalFeatures.includes(17)
        let genieIntelligenceEmailDate = genieIntelligenceFetureEnable ? moment().format('Y-MM-DD') : null;
        let requestParam = {
            token: this.props.hotelAuthReducer.hotelDetails.token,
            features: totalFeatures,
            isMinMax: this.state.isMinMax ? true : false,
            is_calculate_no_of_rooms: this.state.is_calculate_no_of_rooms ? true : false,
            genieIntelligence_email_date: genieIntelligenceEmailDate,
            is18months: this.state.is18months? true : false,
            disabled_client_access:this.state.disabled_client_access_flag_change,
            update_minmax_price: (this.state.is_default_min_max != null) ? this.state.is_default_min_max : false,
        }
        this.props.enableFeature(requestParam);
        // if(totalFeatures.includes(20)){
            requestParam = {
                token: this.props.hotelAuthReducer.hotelDetails.token,
                include_in_hourly_execution: totalFeatures.includes(20) ? true: false,
                is_feature:true
            };
            this.props.getHourlyExecution(requestParam);
        // }
        // Generate default value when 18 months flag is ticked
        if(totalFeatures.includes(23)){
            requestParam = {
                token: this.props.hotelAuthReducer.hotelDetails.token,            
            };
            this.props.genarateDefaultValueSet(requestParam);
        }        
        this.setState({
            disabled_client_access_flag_change: false,
            is_default_min_max: null,
        })
    }

    updateFeature = () => {
        let requestParam = {
            ...this.state,
            token: this.props.userSession.token,
        }
        this.props.registerHotel(requestParam);
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;

        let Features = [
            { id: 33, name: ('Advanced Upload') },
            { id: 34, name: ('Upload All Runs') },
            { id: 1, name: ('Bulk Edits') },
            { id: 5, name: ('Settings Correction ') },
            { id: 6, name: (`Individual min/max for all ${this.state.room_apartment_space_name} types`) },
            { id: 7, name: ('Lead Time Adjustment') },
            { id: 9, name: ('Yield Tag') },
            { id: 10, name: ('Partner Subscription Page') },
            // { id: 11, name: ('Imputation') },
            { id: 12, name: ('Occupancy Strategy') },
            { id: 13, name: ('BAR Levels') },
            { id: 16, name: ('OTA Insight') },
            { id: 17, name: ('GenieIntelligence Email') },
            // { id: 18, name: ('3 Month Pricing') },
            { id: 19, name: ('Disable Client Access')},
            { id: 20, name: ('Limited Dashboard') },
            { id: 21, name: ('Monthly Group Bookings') },
            { id: 22, name: ('Pickup Boost Price') },            
            { id: 23, name: ('18 months pricing') },
            { id: 24, name: ('Starter Upload') },
            { id: 25, name: ('Professional Dashboard') },
            { id: 26, name: ('AirBNB') },
            { id: 28, name: ('Small Business') },
            { id: 29, name: ('Closed Room Detection') },
            { id: 30, name: ('Occupancy') + ' & ' + ('Pickup Calendar') },
            { id: 31, name: t('Dashboard Only') },
            { id: 32, name: ('Segmentation') },
            { id: 35, name: ('Pro Bulk Edits') },
        ];
        let isDisabledAways = (this.state.IbelsaUserPass.provider == 40 && (this.state.IbelsaUserPass.username === null || this.state.IbelsaUserPass.password === null)) ? true : false;
        return (
            <>
                <HeaderCard>
                    <Grid container>
                        <Grid item xs={12} >
                            <div className={classes.divStyle}>
                                <Typography  variant="h5" className={classes.FontStyle}>
                                    Features
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </HeaderCard>
            <Card>
                {this.props.hotelDetailReducer.hoteDetail &&
                    <CardContent>
                        <FormGroup row className={classes.checkBoxContainers}>
                           
                            <Grid container>
                                <Grid md={3} item>
                                    <FormControlLabel
                                        className={classes.checkbox}
                                        control={
                                            <Checkbox
                                                checked={this.state.featuresEnabled.includes(19)}
                                                onChange={this.handleChange(19)}
                                                value={19}
                                                color="primary"
                                            />
                                        }
                                        label={<div className={classes.checkboxLable} >{t('Disable Client Access')}</div>}
                                    />
                                </Grid>
                                <Grid md={3} item >
                                    <FormControlLabel
                                        className={classes.checkbox}
                                        control={
                                            <Checkbox
                                                checked={this.state.featuresEnabled.includes(10)}
                                                onChange={this.handleChange(10)}
                                                // disabled={(this.props.hotelDetailReducer.hoteDetail.state == 6 || this.props.hotelDetailReducer.hoteDetail.state == 7)}
                                                disabled={ this.state.featuresEnabled.includes(28) ? true : false}
                                                value={10}
                                                color="primary"
                                            />
                                        }
                                        label={<div className={classes.checkboxLable} >{t('Partner Subscription Page')}</div>}
                                    />
                                </Grid>
                                {/* <Grid md={3} item >
                                    <FormControlLabel
                                        className={classes.checkbox}
                                        control={
                                            <Checkbox
                                                checked={this.state.featuresEnabled.includes(31)}
                                                onChange={this.handleChange(31)}                                    
                                                value={31}
                                                disabled={(this.state.featuresEnabled.includes(20)?false:true)}
                                                color="primary"
                                            />
                                        }
                                        label={<div className={classes.checkboxLable} >{t('Dashboard Only')}</div>}
                                    />
                                </Grid> */}
                                <Grid md={3} item></Grid>
                                {/* <Grid md={3} item>
                                    <FormControlLabel
                                        className={classes.checkbox}
                                        control={
                                            <Checkbox
                                                checked={this.state.featuresEnabled.includes(5)}
                                                onChange={this.handleChange(5)}
                                                value={5}
                                                color="primary"
                                            />
                                        }
                                        label={<div className={classes.checkboxLable} >{t('Settings Correction')}</div>}
                                    />
                                </Grid> */}
                                {/* <Grid md={1} item>
                                    <FormControlLabel
                                        className={classes.checkbox}
                                        control={
                                            <Checkbox
                                                checked={this.state.featuresEnabled.includes(11)}
                                                onChange={this.handleChange(11)}
                                                value={11}
                                                color="primary"
                                            />
                                        }
                                        label={<div className={classes.checkboxLable} >{t('Imputation')}</div>}
                                    />
                                </Grid> */}
                                {/* <Grid md={3} item>
                                    <FormControlLabel
                                        className={classes.checkbox}
                                        control={
                                            <Checkbox
                                                checked={this.state.featuresEnabled.includes(22)}
                                                onChange={this.handleChange(22)}
                                                value={22}
                                                color="primary"
                                            />
                                        }
                                        label={<div className={classes.checkboxLable} >{t('Pickup Boost Price')}</div>}
                                    />
                                </Grid> */}
                            </Grid>
                            <Grid container>
                                <Grid md={3} item>
                                    <Typography align={"left"} variant={"h6"}>All Plans</Typography>
                                    {Features.filter(val => [5,22,26,13, 31].includes(val.id)).map((feature) => {
                                        let disableFlag = false
                                        if(feature.id == 31 && !this.state.featuresEnabled.includes(20)){
                                            disableFlag = true
                                        }
                                        if(feature.id == 31 && isDisabledAways){
                                            disableFlag = true
                                        }
                                        return (<><FormControlLabel
                                            className={classes.checkboxFeature}
                                            control={
                                                <Checkbox
                                                    checked={this.state.featuresEnabled.includes(feature.id)}
                                                    onChange={this.handleChange(feature.id)}
                                                    value={feature.id}
                                                    color="primary"
                                                    disabled={disableFlag}
                                                />
                                            }
                                            label={<div className={classes.checkboxLable1}>{feature.name}</div>}
                                        /></>)
                                    }
                                    )}
                                    { [1, 2, 15, 52, 7, 71].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) &&
                                    
                                    Features.filter(val => [29].includes(val.id)).map((feature) => {
                                        return (<><FormControlLabel
                                            className={classes.checkboxFeature}
                                            control={
                                                <Checkbox
                                                    checked={this.state.featuresEnabled.includes(feature.id)}
                                                    onChange={this.handleChange(feature.id)}
                                                    value={feature.id}
                                                    color="primary"
                                                />
                                            }
                                            label={<div className={classes.checkboxLable1}>{feature.name}</div>}
                                        /></>)
                                    }
                                    )}
                                </Grid>                            
                                <Grid md={3} item>
                                    <Grid container>
                                        <Grid md={12} item>
                                            <Typography align={"left"} variant={"h6"}>Starter</Typography>
                                                {Features.filter(val => [4, 2, 18, 24].includes(val.id)).map((feature) => {
                                                    let disableFlag = false
                                                    if(feature.id == 24 && (this.state.featuresEnabled.includes(33) || this.state.featuresEnabled.includes(34))){
                                                        disableFlag = true
                                                    }
                                                    return (<><FormControlLabel
                                                        className={classes.checkboxFeature}
                                                        control={
                                                            <Checkbox
                                                                checked={this.state.featuresEnabled.includes(feature.id)}
                                                                onChange={this.handleChange(feature.id)}
                                                                disabled={disableFlag}
                                                                value={feature.id}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={<div className={classes.checkboxLable1}>{feature.name}</div>}
                                                    /></>)
                                                }
                                                )}
                                        </Grid>
                                        <Grid md={12} item style={{marginTop:'35px'}}>
                                            <Typography align={"left"} variant={"h6"}>Small Business</Typography>
                                                {Features.filter(val => [28].includes(val.id)).map((feature) => {
                                                    return (<><FormControlLabel
                                                        className={classes.checkboxFeature}
                                                        control={
                                                            <Checkbox
                                                                checked={this.state.featuresEnabled.includes(feature.id)}
                                                                onChange={this.handleChange(feature.id)}
                                                                disabled={ this.state.featuresEnabled.includes(10) ? true : false}
                                                                value={feature.id}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={<div className={classes.checkboxLable1}>{feature.name}</div>}
                                                    /></>)
                                                }
                                                )}
                                        </Grid>                                        
                                    </Grid>                                    
                                </Grid>
                                <Grid md={3} item>
                                    <Typography align={"left"} variant={"h6"}>Advanced</Typography>
                                    {Features.filter(val => [1, 6, 7,  12, 15,20, 21, 33].includes(val.id)).map((feature) => {
                                        let disableFlag = false
                                        if(feature.id == 33 && (this.state.featuresEnabled.includes(24) || this.state.featuresEnabled.includes(34))){
                                            disableFlag = true
                                        }
                                        if(feature.id == 21 && this.state.featuresEnabled.includes(32)){
                                            disableFlag = true
                                        }
                                        if(feature.id == 20 && isDisabledAways){
                                            disableFlag = true
                                        }
                                        return (<>
                                        <FormControlLabel
                                            className={classes.checkboxFeature}
                                            control={
                                                <Checkbox
                                                    checked={this.state.featuresEnabled.includes(feature.id)}
                                                    onChange={this.handleChange(feature.id)}
                                                    value={feature.id}
                                                    color="primary"
                                                    disabled={disableFlag}
                                                />
                                            }
                                            
                                            label={<>
                                            <div className={feature.id === 6?classes.checkboxLable2:classes.checkboxLable1}>{feature.name}
                                            {/* {feature.id === 20 ? <Button style={{marginLeft:'5px',color:'white',padding:'0px 7px',fontSize:'12px',minWidth:'0px',textTransform: 'capitalize',boxShadow:'0 0 white',background:'#eda942'}} variant="contained"  size="small">beta</Button>:""} */}
                                            {/* {feature.id === 23 ? <><ReportProblemIcon style={{color:'#eda942',marginLeft:'6px',minWidth:'0px',fontSize:'18px'}} /><span style={{color:'#eda942',marginLeft:'5px',whiteSpace:'nowrap'}}>Contact tech</span></>:""} */}
                                            </div>
                                            </>
                                            }
                                        />
                                        </>)
                                    }
                                    )}
                                </Grid>
                                <Grid md={3} item>
                                    <Typography align={"left"} variant={"h6"}>Professional</Typography>
                                    {Features.filter(val => [16, 25,30,23,9, 32, 34, 35].includes(val.id)).map((feature) => {

                                        let disableFlag = false
                                        let limitedState = false
                                        let limitedStateText = '';
                                        if(feature.id == 25 && !this.state.featuresEnabled.includes(20)){
                                            disableFlag = true
                                        }
                                        if(feature.id == 32 && this.state.featuresEnabled.includes(21)){
                                            disableFlag = true
                                        }
                                        if(feature.id == 34 && (this.state.featuresEnabled.includes(33) || this.state.featuresEnabled.includes(24))){
                                            disableFlag = true
                                        }
                                        if(feature.id == 25 && isDisabledAways){
                                            disableFlag = true
                                        }

                                        if(((feature.id === 25 || feature.id === 23) && !this.state.featuresEnabled.includes(feature.id) && this.props.hotelAuthReducer.hotelDetails.hotel.state === 0 )){
                                            limitedState = true
                                            if(feature.id === 25){
                                                limitedStateText = 'This is limited Dashboard during your during the trial period.'
                                            }else if(feature.id === 23){
                                                limitedStateText = 'This is limited Chart during your during the trial period.'
                                            }
                                        }

                                        return (<><FormControlLabel
                                            className={classes.checkboxFeature}
                                            control={
                                                <Checkbox
                                                    checked={this.state.featuresEnabled.includes(feature.id)}
                                                    onChange={this.handleChange(feature.id)}
                                                    disabled={disableFlag}
                                                    value={feature.id}
                                                    color="primary"
                                                />
                                            }
                                            label={<>
                                                <div className={classes.checkboxLable1}>{feature.name}
                                                {(feature.id === 16 )   ? <><ReportProblemIcon style={{color:'#eda942',marginLeft:'6px',minWidth:'0px',fontSize:'18px'}} /><span style={{color:'#eda942',marginLeft:'5px',whiteSpace:'nowrap'}}>Contact tech</span></>:""}
                                                { (limitedState === true)
                                                    ?
                                                    <>
                                                    <ReportProblemIcon style={{color:'#eda942',marginLeft:'6px',minWidth:'0px',fontSize:'18px'}} />
                                                    <span style={{color:'#eda942',marginLeft:'5px',whiteSpace:'nowrap'}}>Limited</span>
                                                    </>                                    
                                                    : "" }                                                
                                                </div>
                                            </>}
                                        /></>)
                                    }
                                    )}
                                </Grid>
                                {/* <Grid md={3} item>
                                    <Typography align={"left"} variant={"h6"}>GenieIntelligence</Typography>
                                    {Features.filter(val => [17].includes(val.id)).map((feature) => {
                                        return (<><FormControlLabel
                                            className={classes.checkboxFeature}
                                            control={
                                                <Checkbox
                                                    checked={this.state.featuresEnabled.includes(feature.id)}
                                                    onChange={this.handleChange(feature.id)}
                                                    value={feature.id}
                                                    color="primary"
                                                />
                                            }
                                            label={<div className={classes.checkboxLable}>{feature.name}</div>}
                                        /></>)
                                    }
                                    )}
                                </Grid> */}
                            </Grid>
                            <div className={classes.btnDiv}>
                                <Button disabled={this.state.loader} onClick={() => this.handleDefaultMinMax()} className={classes.saveBtn} variant="contained" color="primary" >
                                    {t("Save")} {this.state.loader ? <CircularProgress color="primary" size={24} className={classes.buttonProgress} /> : ""}
                                </Button>
                            </div>
                        </FormGroup>
                    </CardContent>
                }
            </Card >
            {this.state.openDate && Modals.updateFreeTrial(this)}
            {Modals.setDefaultMinMaxModal(this)}
            
            </>
        );
    }
}

Features.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        hotelDetail: state.hotelSettingReducer.hoteDetail,
        hotelDetailReducer: state.hotelSettingReducer,
        hotelAuthReducer: state.hotelAuthReducer,
        commonReducer: state.commonReducer,
        featuresReducer: state.featuresReducer,
        priceSettingReducer: state.priceSettingReducer,
        connectionReducer: state.connectionReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getHotelDetail: (request) => { dispatch(actions.getHotelDetail(request)) },
        enableFeature: (request) => { dispatch(actions.enableFeature(request)) },
        getHourlyExecution: (request) => { dispatch(actions.getHourlyExecution(request)) },
        updateFreeTrial: (request) => { dispatch(actions.updateFreeTrial(request)); },
        genarateDefaultValueSet: (request) => { dispatch(actions.genarateDefaultValueSet(request)) },
        getConnectionList: (request) => { dispatch(actions.getConnectionList(request)) },

    };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(Features));
