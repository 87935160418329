import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import adminuserCss from './css/adminUserCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import MUIDataTable from "mui-datatables";
import * as actions from '../../store/actions/index';
import Button from '@material-ui/core/Button';
import Modal from './modal/modal'
import { MuiThemeProvider } from '@material-ui/core/styles';
import { adminUserTheme } from './../muiTheme';
import { Icon } from '@material-ui/core';
import HeaderCard from "../Client/HeaderCard";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';


const styles = adminuserCss;


class RateSetting extends React.Component {

    state = {
        userList: [],
        userName: '',
        pass: '',
        first_name: '',
        last_name: '',
        is_sales: false,
        newUserModal: false,
        deleteUserModal: false,
        updateUserModal: false,
        error: []
    };

    componentWillMount() {
        let requestParams = {
            token: this.props.userSession.token
        };
        this.props.getAdminUser(requestParams)
    }

    componentWillReceiveProps(props) {
        if (props.adminUserReducer.adminUserList) {
            this.setState({
                userList: props.adminUserReducer.adminUserList
            })
        }
    }

    toggleDeleteUser = (id) => {
        this.setState({
            deleteUserModal: !this.state.deleteUserModal,
            id: id
        },() => {
            this.setState({
                updateUserModal: false,
            });
          })
    }

    deleteUser = () => {
        let requestParams = {
            token: this.props.userSession.token,
            id: this.state.id
        };
        this.props.deleteAdminUser(requestParams)
        this.toggleDeleteUser()
    }

    toggleAddUser = (data) => {
        this.setState({
            newUserModal: !this.state.newUserModal,
            userName: '',
            pass: '',
            first_name: '',
            last_name: '',
            is_sales: false,
            error: []
        })
    }

    toggleUpdateUser = (data) => {
        if (!this.state.updateUserModal) {
            this.setState({
                updateUserModal: !this.state.updateUserModal,
                edit_id: data.id,
                edit_userName: data.email,
                edit_first_name: data.first_name,
                edit_last_name: data.last_name,
                is_sales: data.sub_account_type == 1 ? true : false,
                error: []
            })
        } else {
            this.setState({
                updateUserModal: !this.state.updateUserModal
            })
        }
    }

    handleChange = (name) => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    handleCheckChange = (name) => event => {
        this.setState({
            [name]: event.target.checked,
        });
    }

    validateUserForm = (data) => {
        let error = [];
        if (data) {

            if (!this.state.first_name) {
                error.push('first_name')
            }
            if (!this.state.last_name) {
                error.push('last_name')
            }
            if (!this.state.userName) {
                error.push('userName')
            } else {
                var re = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
                if (!re.test(this.state.userName)) {
                    error.push('userName')
                }
            }
            if (!this.state.pass) {
                error.push('pass')
            }

            if (error) {
                this.setState({
                    error: error
                })
                return error
            } else {
                return false
            }
        } else {
            return true;
        }
    }

    addNewAdmin = () => {

        let validateError = this.validateUserForm(this.state);
        if (validateError.length > 0) {
            return;
        }

        let requestParams = {
            token: this.props.userSession.token,
            username: this.state.userName,
            password: this.state.pass,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            is_sales: this.state.is_sales
        };
        this.props.AddAdminUser(requestParams)
        this.toggleAddUser();
    }


    validateEditUserForm = (data) => {
        let error = [];
        if (data) {

            if (!this.state.edit_first_name) {
                error.push('edit_first_name')
            }
            if (!this.state.edit_last_name) {
                error.push('edit_last_name')
            }
            if (!this.state.edit_userName) {
                error.push('edit_userName')
            }

            if (error) {
                this.setState({
                    error: error
                })
                return error
            } else {
                return false
            }
        } else {
            return true;
        }
    }

    addUpdateAdmin = () => {

        let validateError = this.validateEditUserForm(this.state);
        if (validateError.length > 0) {
            return;
        }

        let requestParams = {
            token: this.props.userSession.token,
            id: this.state.edit_id,
            username: this.state.edit_userName,
            first_name: this.state.edit_first_name,
            last_name: this.state.edit_last_name,
            is_sales: this.state.is_sales,
            sub_account_type: (this.state.is_sales ? 1 : null)
        };
        
        this.props.updateAdminUser(requestParams)
        this.toggleUpdateUser();
    }

    renderUserData = (data) => {
        let jsx = [];
        if (data) {
            data.map((val) => {
                jsx.push([
                    val.id,
                    val.email,
                    val.first_name,
                    val.last_name,
                    val.sub_account_type ? 'Yes' : 'No',
                    val
                ])
                return null;
            })
        }
        return jsx;
    }

    options = () => {
        return {
            responsive: 'scroll',
            print: false,
            download: false,
            viewColumns: false,
            filter: false,
            rowsPerPageOptions: false,
            responsive: "scroll",
            selectableRows: false,
            serverSide: false,
            count: this.state.count,
            textLabels: {
                body: {
                    noMatch: this.props.adminUserReducer.loading ?
                        'Loading Records...' :
                        'Sorry, no matching records found.',
                },
            },
            onRowClick: (rowData) => {
                let user = this.state.userList.filter(val => val.id == rowData[0])
                this.toggleUpdateUser(user?user[0]:'')
            },
        }
    };

    render() {
        const { classes } = this.props;

        // const theme = adminUserTheme;

        const columns = [
            { name: "ID" },
            { name: "Email",options: { sortDirection: 'asc' }  },
            { name: "First Name" },
            { name: "Last Name" },
            { name: "In Sales" },
            {
                name: "Actions",
                options: {
                    filter: false,
                    download: false,
                    setCellProps: () => {
                        return { style: { color: '#541388', cursor: 'pointer' } }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <>
                                {/* <Button className={classes.actionIcon} color="primary" onClick={(id) => this.toggleUpdateUser(value)}><Icon>edit</Icon></Button> */}
                                <Button className={classes.actionIcon} color="secondary" onClick={(id) => this.toggleDeleteUser(value.id)}><Icon>delete</Icon></Button>
                            </>
                        );
                    }
                }
            }
        ];
        return (
            <>
                <HeaderCard>
                    <Grid container>
                        <Grid item >
                            <div className={classes.divStyle} >
                                <Typography variant="h5" className={classes.fontStyle} >
                                        Admin User List
                                    </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                </HeaderCard>
            <Card>
                <CardContent>
                    <div className={classes.cardRoot} >
                    <Button onClick={this.toggleAddUser} className={classes.addMemberBtn} variant="contained" color="primary" >
                    Add New Admin User
                </Button>
                </div>
                    <MuiThemeProvider theme={adminUserTheme}>
                        <MUIDataTable
                            title={''}
                            data={this.renderUserData(this.state.userList)}
                            columns={columns}
                            options={this.options()}
                        />
                    </MuiThemeProvider>
                    {Modal.newUserModal(this)}
                    {Modal.deleteUserModal(this)}
                    {this.state.updateUserModal && Modal.updateUserModal(this)}
                </CardContent>
            </Card >
            </>
        );
    }
}

RateSetting.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        userSession: state.sessionReducer,
        adminUserReducer: state.adminUserReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAdminUser: (request) => { dispatch(actions.getAdminUser(request)) },
        AddAdminUser: (request) => { dispatch(actions.AddAdminUser(request)) },
        updateAdminUser: (request) => { dispatch(actions.updateAdminUser(request)) },
        deleteAdminUser: (request) => { dispatch(actions.deleteAdminUser(request)) }
    };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(RateSetting);