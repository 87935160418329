import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import usersLogCss from './css/usersLogCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../store/actions/index';
import MUIDataTable from "mui-datatables";
import {  MuiThemeProvider } from '@material-ui/core/styles';
import { reservationApiLogTheme } from '../muiTheme';
import { withNamespaces, Trans } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import HeaderCard from './HeaderCard';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import {  Icon } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FilterListIcon from '@material-ui/icons/FilterList';
import Cached from '@material-ui/icons/Cached';
import modal from './modal/modal';
import Tooltip from '@material-ui/core/Tooltip';
import DateRangeIcon from '@material-ui/icons/DateRange';
const styles = usersLogCss;
class ReservationApiLog extends React.Component {
    state = {
        error:false,
        count: 0,
        current: 1,
        request_type:1
    };
    componentWillMount() {
          let url='hotel/get_logs/?request_type='+this.state.request_type
        let requestParam = {token: this.props.hotelAuthReducer.hotelDetails.token,url:url};
        this.props.getApiLogList(requestParam);
    }
    componentWillReceiveProps(props) {
        if (props.apiLogReducer && props.apiLogReducer.apiLog && props.apiLogReducer.apiLog.results) {
          this.setState({
            count: props.apiLogReducer.apiLog.count,
            current: props.apiLogReducer.apiLog.current,
            apiLog: props.apiLogReducer.apiLog.results
            })
        }
    }
    renderApiLogData = (data) => {
        let jsx = [];
        data.map((val) => {
            jsx.push([
                val.created ? moment(val.created).format('YYYY-MM-DD hh:mm:ss A'):"",
                val.request_type?val.request_type:"-",
                val.correlation_id ? val.correlation_id:"-" ,
                val.url? val : "-"
            ])
            return null;
        })
        return jsx;
    }
    handlefilterOpen = () => {
        this.setState({ openDateFilter: true });
    };
    handlefilterClose = () => {
        this.setState({ openDateFilter: false });
    };
    changeDate = (name) => dateValue => {
        this.setState({
            [name]: dateValue
        })
    }
    handlefilterApiLog = () => {
        let requestParams = {
            token: this.props.hotelAuthReducer.hotelDetails.token,
            url:'hotel/get_logs/?request_type='+this.state.request_type+'&date='+moment(this.state.date).format('YYYY-MM-DD')+((this.state.page) ? '&page='+this.state.page:'')+((this.state.searchText) ? '&search=' + this.state.searchText : '') + ((this.state.ordering) ? '&ordering=' + this.state.ordering : '')
        }
        this.props.getApiLogList(requestParams)
        this.handlefilterClose();
        this.setState({ filtered: true })
    }
    handleResetfilterApiLog = (reload) => {
        if(reload){
            let url = 'hotel/get_logs/?request_type='+this.state.request_type+((this.state.date) ? '&date='+ moment(this.state.date).format('YYYY-MM-DD'):"")+((this.state.searchText) ? '&search=' + this.state.searchText : '');
            let requestParams = { 
                token: this.props.hotelAuthReducer.hotelDetails.token,
                url:url,
                updateApi:true 
            }
            this.props.getApiLogList(requestParams)
        }else{
            let url='hotel/get_logs/?request_type='+this.state.request_type;
            let requestParams = { token: this.props.hotelAuthReducer.hotelDetails.token,updateApi:true,url:url }
            this.props.getApiLogList(requestParams)
            this.setState({ filtered: false,date:null,searchText:null })
        }
        
    };
    options = () => {
        return {
            responsive: 'scroll',
            print: false,
            download: false,
            viewColumns: false,
            selectableRows: false,
            serverSide: true,
            page: (this.props.apiLogReducer.apiLog) ? (this.props.apiLogReducer.apiLog.current - 1) : 0,
            count: this.state.count,
            filter:false,
            rowsPerPageOptions : false,
            count: this.state.count,
            rowsPerPage: 20,
            search:true,
            sort: true,
            openDateFilter: false,
            textLabels: {
                body: {
                noMatch: this.props.apiLogReducer.loading ?
                    'Loading Records...' :
                    'Sorry, no matching records found.',
                },
            },
            onTableChange: (action, tableState) => {
                let url='hotel/get_logs/?request_type='+this.state.request_type
                let requestParams= { token: this.props.hotelAuthReducer.hotelDetails.token }
                if (action === 'changePage') {
                    let page = tableState.page + 1;
                    url += '&page=' + page + ((this.state.searchText) ? '&search=' + this.state.searchText : '') + ((this.state.date) ? '&date='+ moment(this.state.date).format('YYYY-MM-DD'):"") + ((this.state.ordering) ? '&ordering=' + this.state.ordering : '')
                    requestParams.url = url 
                    this.setState({
                        page: tableState.page + 1
                    })
                    this.props.getApiLogList(requestParams)
                }
                
                if (action === 'search') {
                    let searchText = tableState.searchText ? tableState.searchText : '';
                    if (tableState.searchText) {
                        url += '&search=' + searchText + ((this.state.ordering) ? '&ordering=' + this.state.ordering : '')+ ((this.state.date) ? '&date='+ moment(this.state.date).format('YYYY-MM-DD'):"") ;
                    }
                    requestParams.url = url
                    this.setState({
                        searchText: searchText
                    })
                    this.props.getApiLogList(requestParams)    
                }
                
                if (action != 'onSearchOpen' && action != 'search' && action != 'sort' &&  action != 'filterChange') {
                    this.props.getApiLogList(requestParams)
                }
            },
            onColumnSortChange: (changedColumn, direction) => {
                let sort_direction;
                let requestParams= { token: this.props.hotelAuthReducer.hotelDetails.token }
                switch (changedColumn) {
                    case 'Received at':
                        sort_direction = (direction === 'ascending') ? 'created' : '-created';
                        break;
                    case 'Message Type':
                        sort_direction = (direction === 'ascending') ? 'request_type' : '-request_type';
                        break;
                    case 'Correlation Id':
                        sort_direction = (direction === 'ascending') ? 'correlation_id' : '-correlation_id';
                        break;    
                    default:
                        sort_direction = (direction === 'ascending') ? 'created' : '-created';
                        break;    
                }
                requestParams.url='hotel/get_logs/?request_type='+this.state.request_type+'&ordering='+sort_direction+((this.state.searchText) ? '&search='+ this.state.searchText : '')+((this.state.date) ? '&date='+ moment(this.state.date).format('YYYY-MM-DD'):"")
                this.props.getApiLogList(requestParams)
                this.setState({ ordering: sort_direction })
            },
            customToolbar: () => {
                const { classes, t } = this.props;
                return (
                    <React.Fragment>
                        <Tooltip classes={{popper: "toolTipPooper",tooltip: classes.htmlTooltip}} title={'Reload'}>
                            <Button onClick={() => this.handleResetfilterApiLog(true)}  ><Cached style={{color:'#757575'}}/></Button>
                        </Tooltip>
                        {(this.state.filtered && this.state.apiLog) &&
                        <Button onClick={() => this.handleResetfilterApiLog(false)} >{t("Reset")}</Button>
                        }
                        <Tooltip classes={{popper: "toolTipPooper",tooltip: classes.htmlTooltip}} title={'Date Filter'}>
                        <Button onClick={this.handlefilterOpen}  >
                            <DateRangeIcon style={{color:'#757575'}} />
                        </Button>
                        </Tooltip>
                    </React.Fragment>
                )
            }
        };
    }
 
    render() {
        const { classes, t } = this.props;
        const columns = [
            {
                name: "Received at",
                options: {
                    filter: false,
                    sort: true
                }
            },
            { 
                name: "Message Type",
                options: {
                    filter: false,
                    sort: true
                }
            },
            { 
                name: "Correlation Id",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: "Download",
                options: {
                  filter: false,
                  sort: false,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        value?<Button style={{zIndex:(this.state.searchText||this.state.date) ?'9999':''}} className={classes.buttonItems} color="primary" href={value.url}  title="Download"><Icon>downloadIcon</Icon></Button>:'-'
                    );
                  }
              }
            },
        ];
    
        return (
            <>
            <HeaderCard >
                <Grid container>
                    <Grid item xs={12} >
                    <div className={classes.divStyle} >
                        <Typography variant="h5" className={classes.fontStyle} >
                          Reservation API log
                        </Typography>
                    </div>
                    </Grid>
                </Grid>
            </HeaderCard>
            <Card className={classes.cardPadding}>
                <MuiThemeProvider theme={reservationApiLogTheme}>   
                    <CardContent>                
                        <MUIDataTable
                            title={''}
                            data={(this.state.apiLog) ? this.renderApiLogData(this.state.apiLog):[]}
                            columns={columns}
                            options={this.options()}
                        />
                    </CardContent>
                </MuiThemeProvider>
            </Card>
            {modal.apiLogFilterModal(this)}
            </>
        );
    }
}
ReservationApiLog.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = state => {
    return {
        loading: state.apiLogReducer.loading,
        error: state.apiLogReducer.error,
        apiLogReducer: state.apiLogReducer,
        hotelAuthReducer: state.hotelAuthReducer,
        userSession: state.sessionReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getApiLogList: (request) => { dispatch(actions.getApiLogList(request)) },
    };
};
export default compose(withStyles(styles, {
    withTheme: true
}), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(ReservationApiLog));