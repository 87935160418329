var style = {
    logCard: {
      marginTop: 30
    },
    divStyle: {
      float: "left", 
      marginTop: "2px"
    },
    fontStyle: {
      textAlign: "left", 
      fontWeight: "500"
    },
    cardPadding:{
      paddingTop:'20px'
    },
    
  }
  export default style;