import { ValidateBaseSettings } from '../Utils/SettingValidation/Validation';
import axios from 'axios';
import Server from '../../config/server';
import * as actionTypes from '../../store/actions/actionTypes';
import moment from 'moment';

export function cancelPriceType(thisEvent, jsonData) {

    if (thisEvent.state.price_type == 'skip' || thisEvent.state.price_type == 'fix') {
        let roomData = { ...thisEvent.state.pricing_result[thisEvent.state.modalData.date][thisEvent.state.selectedRoom] };
        thisEvent.handleUpdatePrice({ target: { value: roomData.price ? roomData.price : 0 } })
    } else {
        thisEvent.handlePriceTypeValue({ target: { value: "" } })
    }

    thisEvent.setState({
        price_type: "",
        price_type_value: "",
    })
}

export function deleteSkipAdjust(thisEvent, jsonData) {
    let singleAdjust = thisEvent.state.adjustDates.filter(opt => opt.date == thisEvent.state.modalData.date);
    let skipDate = thisEvent.state.skipDates.filter(val => val.date == thisEvent.state.modalData.date)
    if (singleAdjust.length > 0 || skipDate.length > 0) {
        if (skipDate.length > 0) {
            delete jsonData.dates[thisEvent.state.modalData.date].skip_date
        } else if (singleAdjust.length > 0) {
            delete jsonData.dates[thisEvent.state.modalData.date].individual_adjustment
        }

        let latestJson = ValidateBaseSettings({ dateSetting: jsonData });

        let reqParams = {
            settings: JSON.stringify(latestJson.dateSetting),
        };
        thisEvent.setState({
            loadingSave: true
        });
        axios.defaults.headers.common['Authorization'] = "Token " + thisEvent.props.hotelAuthReducer.hotelDetails.token;
        axios.post(Server.API + 'hotel/pricing-settings/', reqParams)
            .then((response) => {
                thisEvent.getCalendarSettingJson();
                let requestParam = { dates: JSON.stringify([thisEvent.state.modalData.date]) };
                axios.post(Server.API + 'hotel/skip-pricing/apply/', requestParam)
                    .then((response) => {
                        Object.keys(response.data).map(function (key) {
                            thisEvent.renderOneDate(response.data[key]);
                        })
                    })

                let requestData = {
                    msg: 'Updated Successfully',
                    is_success: true,
                }
                thisEvent.props.putNotify(requestData);

                thisEvent.props.getPricingSettings({ token: thisEvent.props.hotelAuthReducer.hotelDetails.token })

                thisEvent.setState({ loadingSave: false });
                thisEvent.setState({
                    price_type: "",
                    price_type_value: "",
                })
            })
    }
}

const successPrice = (data) => {
    return {
        type: actionTypes.GET_TABLE_SETTING,
        data: data
    }
}

export function saveSkipAdjustData(thisEvent, jsonData, isUpload) {

    thisEvent.setState({
        loadingSave: isUpload ? false : true,
        loadingUplaod: isUpload
    });
    let roomData = thisEvent.props.roomList.roomList.filter(val => val.id == thisEvent.state.selectedRoom)[0];

    let callApplyApi = true;

    if (!jsonData.dates[thisEvent.state.modalData.date]) {
        jsonData.dates[thisEvent.state.modalData.date] = {};
    }

    if (!jsonData.dates[thisEvent.state.modalData.date][thisEvent.state.selectedRoom]) {
        jsonData.dates[thisEvent.state.modalData.date][thisEvent.state.selectedRoom] = {}
    }

    let refroom = thisEvent.props.roomList.roomList.filter(val => val.is_reference_room)[0];


    if ((thisEvent.state.bulkUpdateData.fixedPrice[refroom.id] || thisEvent.state.isSkipDateModal) && thisEvent.state.deriveFromRef) {

        if (!jsonData.dates[thisEvent.state.modalData.date]) {
            jsonData.dates[thisEvent.state.modalData.date] = {};
        }

        jsonData.dates[thisEvent.state.modalData.date].skip_date = {
            fixed_price: thisEvent.state.bulkUpdateData.fixedPrice[refroom.id],
            name: thisEvent.state.bulkUpdateData.skipName,
        }

        callApplyApi = true;
    } else {
        if (jsonData.dates[thisEvent.state.modalData.date] && jsonData.dates[thisEvent.state.modalData.date].skip_date) {
            delete jsonData.dates[thisEvent.state.modalData.date].skip_date;
            callApplyApi = true;
        }
    }

    // if (!thisEvent.state.deriveFromRef) {
    Object.keys(thisEvent.state.bulkUpdateData.fixedPrice).map((roomkey) => {
        // if (refroom.id != roomkey || !thisEvent.state.deriveFromRef) {
        if (thisEvent.state.bulkUpdateData.fixedPrice[roomkey]) {
            if (!jsonData.dates[thisEvent.state.modalData.date][roomkey]) {
                jsonData.dates[thisEvent.state.modalData.date][roomkey] = {}
            }
            jsonData.dates[thisEvent.state.modalData.date][roomkey].fix_price = parseFloat(thisEvent.state.bulkUpdateData.fixedPrice[roomkey]);
        } else if (jsonData.dates[thisEvent.state.modalData.date] && jsonData.dates[thisEvent.state.modalData.date][roomkey] && jsonData.dates[thisEvent.state.modalData.date][roomkey].fix_price) {
            delete jsonData.dates[thisEvent.state.modalData.date][roomkey].fix_price
        }
        // }

        if (!thisEvent.state.bulkUpdateData.fixedPrice[roomkey] && jsonData.dates[thisEvent.state.modalData.date] && jsonData.dates[thisEvent.state.modalData.date][roomkey] && jsonData.dates[thisEvent.state.modalData.date][roomkey].fix_price) {
            delete jsonData.dates[thisEvent.state.modalData.date][roomkey].fix_price
        }

    })
    // } else {
    //     Object.keys(thisEvent.state.bulkUpdateData.fixedPrice).map((roomkey) => {
    //         if (jsonData.dates[thisEvent.state.modalData.date] && jsonData.dates[thisEvent.state.modalData.date][roomkey] && jsonData.dates[thisEvent.state.modalData.date][roomkey].fix_price) {
    //             delete jsonData.dates[thisEvent.state.modalData.date][roomkey].fix_price
    //         }
    //     })
    // }



    if (thisEvent.state.bulkUpdateData.dailyAdjust && parseFloat(thisEvent.state.bulkUpdateData.dailyAdjust)) {

        if (!jsonData.dates[thisEvent.state.modalData.date]) {
            jsonData.dates[thisEvent.state.modalData.date] = {};
        }
        
        jsonData.dates[thisEvent.state.modalData.date].individual_adjustment = {
            name: null,
            percentage: parseFloat(thisEvent.state.bulkUpdateData.dailyAdjust)
        }
    } else {
        if (jsonData.dates[thisEvent.state.modalData.date] && jsonData.dates[thisEvent.state.modalData.date].individual_adjustment) {
            delete jsonData.dates[thisEvent.state.modalData.date].individual_adjustment
        }
    }

    let defaults = jsonData.default[thisEvent.state.selectedRoom];

    Object.keys(thisEvent.state.bulkUpdateData.minPrice).filter(key => thisEvent.state.featuresEnabled.includes(6) || refroom.id == key).map((roomkey) => {
        if (defaults.min_price != thisEvent.state.bulkUpdateData.minPrice[roomkey]) {
            if (!jsonData.dates[thisEvent.state.modalData.date][roomkey]) {
                jsonData.dates[thisEvent.state.modalData.date][roomkey] = {}
            }
            jsonData.dates[thisEvent.state.modalData.date][roomkey].min_price = parseFloat(thisEvent.state.bulkUpdateData.minPrice[roomkey]);
            if (thisEvent.state.bulkUpdateData.minPrice[roomkey] == jsonData.default[roomkey].min_price) {
                delete jsonData.dates[thisEvent.state.modalData.date][roomkey].min_price
            }
        } else if (jsonData.dates[thisEvent.state.modalData.date][roomkey] && jsonData.dates[thisEvent.state.modalData.date][roomkey].min_price) {
            delete jsonData.dates[thisEvent.state.modalData.date][roomkey].min_price
        }
    })

    Object.keys(thisEvent.state.bulkUpdateData.maxPrice).filter(key => thisEvent.state.featuresEnabled.includes(6) || refroom.id == key).map((roomkey) => {
        if (defaults.max_price != thisEvent.state.bulkUpdateData.maxPrice[roomkey]) {
            if (!jsonData.dates[thisEvent.state.modalData.date][roomkey]) {
                jsonData.dates[thisEvent.state.modalData.date][roomkey] = {}
            }
            jsonData.dates[thisEvent.state.modalData.date][roomkey].max_price = parseFloat(thisEvent.state.bulkUpdateData.maxPrice[roomkey]);
            if (thisEvent.state.bulkUpdateData.maxPrice[roomkey] == jsonData.default[roomkey].max_price) {
                delete jsonData.dates[thisEvent.state.modalData.date][roomkey].max_price
            }
        } else if (jsonData.dates[thisEvent.state.modalData.date][roomkey] && jsonData.dates[thisEvent.state.modalData.date][roomkey].max_price) {
            delete jsonData.dates[thisEvent.state.modalData.date][roomkey].max_price
        }
    })

    Object.keys(thisEvent.state.bulkUpdateData.adjVsRef).map((roomkey) => {
        if (defaults.adjustment_to_reference_room != thisEvent.state.bulkUpdateData.adjVsRef[roomkey]) {
            if (!jsonData.dates[thisEvent.state.modalData.date][roomkey]) {
                jsonData.dates[thisEvent.state.modalData.date][roomkey] = {}
            }
            jsonData.dates[thisEvent.state.modalData.date][roomkey].adjustment_to_reference_room = parseFloat(thisEvent.state.bulkUpdateData.adjVsRef[roomkey]);
            let currentDerivation = Math.round(jsonData.default[roomkey].adjustment_to_reference_room);
            if (thisEvent.state.bulkUpdateData.adjVsRef[roomkey] == currentDerivation) {
                delete jsonData.dates[thisEvent.state.modalData.date][roomkey].adjustment_to_reference_room
            }
        } else if (jsonData.dates[thisEvent.state.modalData.date][roomkey] && jsonData.dates[thisEvent.state.modalData.date][roomkey].adjustment_to_reference_room) {
            delete jsonData.dates[thisEvent.state.modalData.date][roomkey].adjustment_to_reference_room
        }
    })


    // jsonData.dates[thisEvent.state.modalData.date][thisEvent.state.selectedRoom].avg_price = jsonData.default[thisEvent.state.selectedRoom].avg_price;
    if (jsonData.dates[thisEvent.state.modalData.date][thisEvent.state.selectedRoom] && jsonData.dates[thisEvent.state.modalData.date][thisEvent.state.selectedRoom].closed_rooms) {
        jsonData.dates[thisEvent.state.modalData.date][thisEvent.state.selectedRoom].closed_rooms = jsonData.dates[thisEvent.state.modalData.date][thisEvent.state.selectedRoom].closed_rooms;
    }

    thisEvent.props.roomList.roomList.map(val => {
        if (jsonData.dates[thisEvent.state.modalData.date] && jsonData.dates[thisEvent.state.modalData.date][val.id] && Object.keys(jsonData.dates[thisEvent.state.modalData.date][val.id]).length == 0) {
            delete jsonData.dates[thisEvent.state.modalData.date][val.id]
        }
    })


    if (thisEvent.state.priceNote) {
        jsonData.dates[thisEvent.state.modalData.date] = { ...jsonData.dates[thisEvent.state.modalData.date] }
        if(thisEvent.state.priceNote != "No Market Data"){
            jsonData.dates[thisEvent.state.modalData.date].price_note = thisEvent.state.priceNote;

            // for (var eventDate = moment(thisEvent.state.notesStartDate); eventDate.isSameOrBefore(thisEvent.state.notesEndDate); eventDate.add(1, 'days')) {   
            //     let eventDateFormat=moment(eventDate).format('YYYY-MM-DD');
            //     // jsonData.dates[thisEvent.state.modalData.date].price_note = thisEvent.state.priceNote;
            //     jsonData.dates[eventDateFormat] = { ...jsonData.dates[eventDateFormat] }
            //     jsonData.dates[eventDateFormat].price_note = thisEvent.state.priceNote;
            // }  
        }        
    } else if (jsonData.dates[thisEvent.state.modalData.date] && jsonData.dates[thisEvent.state.modalData.date].price_note) {
        delete jsonData.dates[thisEvent.state.modalData.date].price_note
    }

    let latestJson = ValidateBaseSettings({ dateSetting: jsonData });

    let reqParams = {
        settings: JSON.stringify(latestJson.dateSetting),
    };
    axios.defaults.headers.common['Authorization'] = "Token " + thisEvent.props.hotelAuthReducer.hotelDetails.token;
    axios.post(Server.API + 'hotel/pricing-settings/', reqParams)
        .then((response) => {


            if (callApplyApi) {

                let requestParam = {
                    json_settings: JSON.stringify(latestJson.dateSetting),
                    is_write_to_cache: true,
                    start_date: thisEvent.state.modalData.date,
                    end_date: thisEvent.state.modalData.date
                }

                axios.post(Server.API + 'hotel/adjustment/individual/apply/', requestParam)
                    .then((response) => {
                        Object.keys(response.data.data).map(function (key) {
                            thisEvent.renderOneDate(response.data.data[key]);
                            if (isUpload && thisEvent.props.hotelAuthReducer.hotelDetails.hotel.pms_sync == 2 && thisEvent.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) {
                                thisEvent.updatePricePms(thisEvent.state.modalData.date);
                            }
                        })
                    })
                    .catch((error) => {
                        let requestData = {
                            msg: 'Error while Applying the Changes',
                            is_success: false,
                        }
                        thisEvent.props.putNotify(requestData)
                    });
            }
            // if(!isUpload){
            //     let requestData = {
            //         msg: "Changes saved successfully",
            //         is_success: true,
            //     }
            //     thisEvent.props.putNotify(requestData);
            // }
            

            thisEvent.getCalendarSettingJson();

            axios.get(Server.API + 'hotel/pricing-settings/')
                .then((resp) => {
                    thisEvent.props.setJsonProp(successPrice(resp.data))
                    
                    if(!isUpload){
                        let requestData = {
                            msg: isUpload ? "Prices uploaded successfully" : "Changes saved successfully",
                            is_success: true,
                        }
                        thisEvent.props.putNotify(requestData);
                        thisEvent.setState({
                            loadingSave: false,
                            loadingUplaod: false,
                            greySaveUpload: true,
                            uploadSingleModal: false,
                            PriceModalopen: false
                        }, () => thisEvent.getCalendarData())
                    }          
                })
        })
        .catch(function (error) {
            thisEvent.setState({
                loadingSave: false,
                loadingUplaod: false,
                uploadSingleModal: false,
            })
        });
}


export function saveMultipleNotes(thisEvent, jsonData,val,action) {
    let data={
        id:null,
        name:"",
        start_date:"",
        end_date:"",
    }
    let notes= thisEvent.state.userNotesPricingSetting
    if(val && action == 'Delete'){
        const filterednotes =notes.filter((obj) => obj.id !== val.id);
        jsonData.user_notes=filterednotes;
    }else if(action == 'Edit'){
        let editNotesObj=thisEvent.state.editNotesValue;
        // let notesObj = notes.findIndex((obj => obj.id == thisEvent.state.editNotesValue.id));
        for (let i in notes) { 
            if (notes[i].id == editNotesObj.id) {
                notes[i].name = editNotesObj.name
                notes[i].start_date = moment(editNotesObj.start_date).format('YYYY-MM-DD')
                notes[i].end_date = moment(editNotesObj.end_date).format('YYYY-MM-DD')
                break
            }
        }
        jsonData.user_notes=notes;
    }else{
        if(thisEvent.state.priceNote){
            if(!jsonData.user_notes){
                jsonData.user_notes=[]
            }        
            data.id=Math.floor(1000 + Math.random() * 9000)
            data.name=thisEvent.state.priceNote
            data.start_date=moment(thisEvent.state.notesStartDate).format('YYYY-MM-DD')
            data.end_date=moment(thisEvent.state.notesEndDate).format('YYYY-MM-DD')
            jsonData.user_notes.push(data)
        }
    }
    
    let latestJson = ValidateBaseSettings({ dateSetting: jsonData });
    let reqParams = {
        settings: JSON.stringify(latestJson.dateSetting),
    };
    axios.defaults.headers.common['Authorization'] = "Token " + thisEvent.props.hotelAuthReducer.hotelDetails.token;
    axios.post(Server.API + 'hotel/pricing-settings/', reqParams)
        .then((response) => {
            let reqParams={
                token:thisEvent.props.hotelAuthReducer.hotelDetails.token
            }
            thisEvent.props.getUserNotes(reqParams);
            axios.get(Server.API + 'hotel/pricing-settings/')
                .then((resp) => {
                    thisEvent.props.setJsonProp(successPrice(resp.data))
                    let requestData = {
                        msg: (val && action == 'Delete')?"Note deleted successfully":"Notes saved successfully",
                        is_success: true,
                    }
                    thisEvent.props.putNotify(requestData);
                    thisEvent.setState({
                        notesStartEndDate:false,
                        noteEditEnable:false,
                        userNotesPricingSetting:resp.data.user_notes,
                        editNotesValue:{},
                        loadingNotes: false,
                        loadingNotesEdit:false,
                        loadingNotesDelete:false,
                        priceNote:'',
                    }, () => thisEvent.getCalendarData())
                })
        })
        .catch(function (error) {
            this.setState({
                loadingNotes: false,
                loadingNotesEdit:false,
                loadingNotesDelete:false
            })
            console.log("")
        });
    
}
export const calendarAdjustmentUpload = (thisEvent, request, isUpload) => {

    let latestJson = ValidateBaseSettings(request);

    let reqParams = {
        settings: JSON.stringify(latestJson.dateSetting),
    };
    axios.defaults.headers.common['Authorization'] = "Token " + request.token;
    axios.post(Server.API + 'hotel/pricing-settings/', reqParams)
        .then(function (response) {

            thisEvent.props.getPricingSettings({ token: thisEvent.props.hotelAuthReducer.hotelDetails.token })

            thisEvent.getCalendarSettingJson();

            if (thisEvent.state.isSkip == "7") {

                let start_date = moment.utc(thisEvent.props.hotelAuthReducer.utcTime).tz(thisEvent.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD');
                let end_date = moment(start_date).add('363', 'd').format('YYYY-MM-DD');

                thisEvent.setState({
                    start_date,
                    end_date
                },
                    () => {
                        thisEvent.closeRemoveAdj();
                        thisEvent.runPricingSocket();
                    })
                return;
            }

            let start_date = moment(thisEvent.state.bulkStartDate).format('YYYY-MM-DD');

            let end_date = moment(thisEvent.state.bulkEndDate).format('YYYY-MM-DD');

            if (moment.utc(thisEvent.props.hotelAuthReducer.utcTime).tz(thisEvent.props.hotelAuthReducer.hotelDetails.hotel.timezone).diff(end_date, "days") < -362) {
                end_date = moment(start_date).add('363', 'd').format('YYYY-MM-DD');
            }
           

            let requestParam = {
                json_settings: JSON.stringify(latestJson.dateSetting),
                is_write_to_cache: true,
                start_date: start_date,
                end_date: end_date
            }
            if (thisEvent.state.bulkEditType !== 7 && thisEvent.state.bulkEditType !== 8) {
                axios.post(Server.API + 'hotel/adjustment/individual/apply/', requestParam)
                    .then((response) => {
                        let requestData = {
                            msg: 'Data Updated Successfully',
                            is_success: true,
                        }
                        
                        if((thisEvent.state.removeAdjModal && (thisEvent.state.deleteModalData.aggressiVeness || thisEvent.state.deleteModalData.leadTimeAdjustment)) || (thisEvent.state.bulkDateAdjModal && (thisEvent.state.bulkEditType == 7 || thisEvent.state.bulkEditType == 8))){
                            thisEvent.setState({
                                start_date:thisEvent.state.bulkStartDate,
                                end_date:thisEvent.state.bulkEndDate
                            }, () => {
                                thisEvent.runPricing();
                            })
                        } else{
                            thisEvent.getLattestPricing()
                        }

                        thisEvent.props.putNotify(requestData)
                        thisEvent.closeRemoveAdj();

                    })
                    .catch((error) => {
                        let requestData = {
                            msg: 'Error while Applying the Changes',
                            is_success: false,
                        }
                        thisEvent.props.putNotify(requestData)
                    });
            } else {               
                if((thisEvent.state.removeAdjModal && (thisEvent.state.deleteModalData.aggressiVeness || thisEvent.state.deleteModalData.leadTimeAdjustment)) || (thisEvent.state.bulkDateAdjModal && (thisEvent.state.bulkEditType == 7 || thisEvent.state.bulkEditType == 8))){
                    thisEvent.setState({
                        start_date: moment.utc(thisEvent.props.hotelAuthReducer.utcTime).tz(thisEvent.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'),
                        end_date: moment.utc(thisEvent.props.hotelAuthReducer.utcTime).tz(thisEvent.props.hotelAuthReducer.hotelDetails.hotel.timezone).add((thisEvent.state.featuresEnabled.includes(23) ? '545' : '365'), 'd').format('YYYY-MM-DD'),                       
                    }, () => {
                        thisEvent.startRunPricing();
                    })
                } else {                    
                    thisEvent.getLattestPricing()
                }          
                let requestData = {
                    msg: 'Data Updated Successfully',
                    is_success: true,
                }
                thisEvent.props.putNotify(requestData)
                thisEvent.closeRemoveAdj();
                
            }           
        })
        .catch(function (error) {

        });

}