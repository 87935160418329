import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ClientRegistrationCss from './css/ClientRegistrationCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../store/actions/index';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import AsyncSelect from 'react-select/lib/Async';
import Server from '../../config/server'
import axios from 'axios';
import * as actionTypes from '../../store/actions/actionTypes';
import Select from 'react-select';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import HeaderCard from "../Client/HeaderCard";
import Grid from "@material-ui/core/Grid";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Typography } from '@material-ui/core';

const styles = ClientRegistrationCss;

const Membership = ['Standard', 'pro'];

const ref_room = [{ "name": "Double Room", "max_occupancy": 2, "avg_price": 125 }];

let CountryList = [];
let languages = [{ value: "en", label: "English" }, { value: "de", label: "German" }, { value: "fr", label: "French" }, { value: "es-ES", label: "Spanish" }, { value: "it", label: "Italian" }]
class RateSetting extends React.Component {

    state = {
        lat: '',
        lng: '',
        currency: '',
        address: '',
        region: '',
        postcode: '',
        country: '',
        name: '',
        booking_ref_url: '',
        hotels_ref_url: '',
        expedia_ref_url: '',
        website: '',
        review: 0,
        notes: '',
        number_of_rooms: '',
        membership: 'Standard',
        is_old_user: 'new',
        first_name: '',
        last_name: '',
        email: '',
        password: 'RPG2019$!',
        options: [{ label: 'red', value: 1 }],
        newHotel: [],
        reference_room_data: { "name": "Double Room", "max_occupancy": 2, "avg_price": 125 },
        is_old_hotel: 'old',
        error: [],
        newUser: '',
        reseller: null,
        resellerList: [],
        salesList: [],
        memberList: [],
        responsible_account: '',
        tempMemberList: [],
        hotelRegLoading:false,
        language:'en',
    };

    componentWillMount() {
        let requestParam = { token: this.props.userSession.token }
        this.props.getCurrency(requestParam);
        this.props.getCountry(requestParam);

        if (this.props.userSession.user.is_staff) {
            this.props.getAdminSales(requestParam);
            this.props.getResellerAll(requestParam);
        }

        this.props.getResellerMemberAll(requestParam)
    }

    componentWillReceiveProps(props) {
        if (props.commonReducer.notify) {
            if (props.commonReducer.notify.message) {
                this.setState({
                    hotelRegLoading:false
                })
            }
        }    
        if (props.adminUserReducer.adminUserList) {
            this.setState({
                salesList: props.adminUserReducer.adminUserList
            })
        }

        if (props.resellerReducer.allResellerList) {
            this.setState({
                resellerList: props.resellerReducer.allResellerList
            })
        }

        if (props.resellerReducer.resellerAllMemberList) {
            this.setState({
                memberList: props.resellerReducer.resellerAllMemberList
            })
        }

        if (props.commonReducer.notify) {
            if (props.commonReducer.notify.message == 'Hotel Registered Successfully') {
                this.resetState();
            }
        }

        if (props.hotelListReducer.hotelList) {
            let jsx = [];
            props.hotelListReducer.hotelList.results.map((val) => {
                jsx.push({ label: val.name, value: val.id })
                return null;
            })
            this.setState({
                options: jsx
            })
        }

        if (props.userListReducer.clientUserList) {
            let jsx = [];
            props.userListReducer.clientUserList.map((val) => {
                jsx.push({ label: val.first_name + ' ' + val.last_name + '  -----   ' + val.email, value: val.id })
                return null;
            })
            this.setState({
                userOptions: jsx
            })
        }

        if (this.props.countryReducer) {
            CountryList = [];
            this.props.countryReducer.map(option => (
                CountryList.push({ value: option.id, label: option.name + ' (' + option.abbreviation + ')' })
            ))
        }      
    }

    resetState = () => {
        this.setState({
            selectedCountry: null,
            lat: '',
            lng: '',
            currency: '',
            address: '',
            region: '',
            postcode: '',
            name: '',
            booking_ref_url: '',
            hotels_ref_url: '',
            expedia_ref_url: '',
            website: '',
            review: 0,
            notes: '',
            country: '',
            number_of_rooms: '',
            membership: 'Standard',
            is_old_user: 'new',
            first_name: '',
            last_name: '',
            email: '',
            password: 'RPG2019$!',
            options: [{ label: 'red', value: 1 }],
            newHotel: [],
            reference_room_data: { "name": "Double Room", "max_occupancy": 2, "avg_price": 125 },
            is_old_hotel: 'old',
            error: [],
            newUser: {},
            responsible_account: '',
            reseller: null,
            resellerList: [],
        })
    }

    handleRellerChange = (name) => event => {
        let tempMemberList = this.state.memberList.filter(val => val.reseller.id == event.target.value);
        this.setState({
            [name]: event.target.value,
            tempMemberList: tempMemberList
        });
    }

    handleChange = (name) => event => {
        let value = (name === 'address') ? event : event.target.value;
        this.setState({
            [name]: value,
        });
    }

    handleChangeCountry = () => data => {
        this.setState({
            selectedCountry: data,
            country: data.value,
        });
    }


    handleSelect = address => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                this.setState(prevState => ({
                    ...prevState,
                    lat: latLng.lat,
                    lng: latLng.lng,
                    address: address,
                }), () => {
                });

            })
            .catch(error => console.error('Error', error));
    };

    validateForm = () => {
        let error = [];

        if (!this.state.name) {
            error.push('name')
        }

        if (!this.state.address) {
            error.push('address')
        }

        if (!this.state.lat) {
            error.push('lat')
        }

        if (!this.state.lng) {
            error.push('lng')
        }

        if (!this.state.country) {
            error.push('country')
        }

        if (!this.state.currency) {
            error.push('currency')
        }

     

        if (!this.state.number_of_rooms) {
            error.push('number_of_rooms')
        }

        
        if (this.state.is_old_user === 'new' && !this.state.first_name) {
            error.push('first_name')
        }

        if (this.state.is_old_user === 'new' && !this.state.last_name) {
            error.push('last_name')
        }
        if (this.state.is_old_user === 'new' && !this.state.language) {
            error.push('language')
        }
        if (!this.state.responsible_account && this.props.userSession.user.is_staff) {
            error.push('responsible_account')
        }

        if (this.state.is_old_user === 'new' && !this.state.email) {
            error.push('email')
        } else if (this.state.is_old_user === 'new') {
            var re = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,6})$/;
            if (!re.test(this.state.email)) {
                error.push('email')
            }
        }

        if (this.state.is_old_user === 'new' && !this.state.password) {
            error.push('password')
        }

        if (this.state.is_old_user !== 'new' && !this.state.newUser) {
            error.push('newUser')
        }

        if (!this.state.reference_room_data) {
            error.push('reference_room_data')
        }

        if (error) {
            this.setState({
                error: error
            })
            return error
        }
        else {
            return false
        }
    }

    submitHotel = () => {
        let validateError = this.validateForm();

        if (validateError.length > 0) {
            return;
        }

        let requestParam = {
            ...this.state,
            token: this.props.userSession.token,
            is_staff: this.props.userSession.user.is_staff,
            currentUser: this.props.userSession.user.id
        }
        this.props.registerHotel(requestParam);
        
        this.setState({
            hotelRegLoading: true
        })
    }

    filterOptions = (inputValue) => {
        return this.state.options.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    promiseOptions = inputValue =>
        new Promise(resolve => {
            axios.defaults.headers.common['Authorization'] = "Token " + this.props.userSession.token;
            axios.get(Server.API + 'hotel/list/?search=' + inputValue + '&country=' + this.state.country)
                .then((response) => {
                    this.props.setHotelList(response.data);

                    let jsx = [];
                    response.data.results.map((val) => {
                        jsx.push({ label: val.name, value: val.id })
                        return null;
                    })
                    let data = jsx.filter(i =>
                        i.label.toLowerCase().includes(inputValue.toLowerCase())
                    );

                    resolve(data);

                }).catch((error) => {
                });
        });

    handleHotelChange = (inputVal) => {
        if (inputVal.__isNew__) {
            this.setState({
                newHotel: inputVal,
                name: inputVal.value,
            })
        } else {
            this.props.hotelListReducer.hotelList.results.filter(val => val.id === inputVal.value).map((val) => {
                this.setState({
                    newHotel: inputVal,
                    lat: val.lat,
                    lng: val.lng,
                    currency: val.currency ? val.currency.id : null,
                    address: val.address,
                    region: val.region,
                    postcode: val.postcode,
                    country: val.country.id,
                    name: val.name,
                    booking_ref_url: val.ota_reference,
                    hotels_ref_url: val.hotels_ref_url,
                    expedia_ref_url: val.expedia_ref_url,
                    website: val.website,
                    review: 0,
                    notes: val.notes,
                    number_of_rooms: val.number_of_rooms,
                })
                return null;
            })
        }

    }


    filterUserOptions = (inputValue) => {
        return this.state.userOptions.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    promiseUserOptions = inputValue =>
        new Promise(resolve => {
            axios.defaults.headers.common['Authorization'] = "Token " + this.props.userSession.token;
            axios.get(Server.API + 'account/client/list/?search=' + inputValue)
                .then((response) => {
                    this.props.setClientList(response.data);
                    let jsx = [];
                    response.data.results.map((val) => {
                        jsx.push({ label: (val.first_name ? val.first_name : '') + ' ' + (val.last_name ? val.last_name : '') + ' - ' + val.email, value: val.id })
                        return null;
                    })

                    let data = jsx

                    resolve(data);

                })
                .catch((error) => {

                });
        });

    handleUserChange = (inputVal) => {
        if (!inputVal.__isNew__) {
            this.props.userListReducer.clientUserList.filter(val => val.id === inputVal.value).map((val) => {
                this.setState({
                    newUser: inputVal,
                })
                return false;
            })
        }

    }


    render() {
        const { classes } = this.props;

        const SelectStyle = {
            control: (base, state) => ({
                ...base,
                height: "40px",
                border: 'none',
                boxShadow: "0 !important",
                width: '100%',
                borderRadius: "4px !important",
                background: "#f2f2f2",
                textAlign: 'left',
                "&:hover": {
                    border: 'none',
                },
                "&:focus-within": {
                    border: 'none',
                },
                "&:active": {
                    border: 'none',
                },
            })
        };

        const SelectStyleError = {
            control: (base, state) => ({
                ...base,
                border: "0",
                borderBottom: '2px solid red',
                boxShadow: "0 !important",
                paddingTop: '10px',
                width: '100%',
                borderRadius: '0px !important',
                minWidth: '250px',
                marginTop: '17px',
                marginBottom: '8px',
                textAlign: 'left',
                "&:hover": {
                    borderBottom: '2px solid red'
                },
                "&:focus-within": {
                    borderBottom: '2px solid red'
                },
                "&:active": {
                    borderBottom: '2px solid red'
                },
                ">div >div": {
                    paddingLeft: "0 !important",
                    color: "red !important"
                }
            })
        };

        return (
            <>
                <HeaderCard>
                <Grid container>
                    <Grid item >
                        <div className={classes.divStyle} >
                        <Typography variant="h5" className={classes.fontStyle} >
                            Create Property
                        </Typography>
                        </div>
                    </Grid>
                </Grid>
            </HeaderCard>
            <Card>
                <CardContent>
                    <Typography variant="title" align={"left"} className={classes.formControl}>Property</Typography>
                    <FormControl required className={classes.formControl}>
                        <RadioGroup
                            row
                            aria-label="is_old_hotel"
                            name="is_old_hotel"
                            value={this.state.is_old_hotel}
                            onChange={this.handleChange('is_old_hotel')}
                            className={classes.radioGrp}
                        >
                            <FormControlLabel value='old' control={<Radio color="primary" />} label="Search in Database" />
                            <FormControlLabel value='new' control={<Radio color="primary" />} label="Add New Property" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl required className={classes.formControl}>
                        &nbsp;
                    </FormControl>
                    <FormControl required className={[classes.formControl,classes.formControl2]} >
                        <InputLabel htmlFor="name" shrink={true} className={classes.lableInput} >Country</InputLabel>
                        <Select
                            error={this.state.error.includes("country")}
                            placeholder='Country'
                            styles={this.state.error.includes("country") ? SelectStyleError : SelectStyle}
                            value={this.state.selectedCountry}
                            onChange={this.handleChangeCountry()}
                            options={CountryList}
                            margin="normal"
                            required={true}
                            
                        />
                        {this.state.error.includes("country") &&
                            <span class='error-message'>{'* Please select country'}</span>
                        }
                    </FormControl>
                  
                    {this.state.is_old_hotel == 'old' &&
                        <FormControl required className={[classes.formControl,classes.formControl2]} >
                            <InputLabel htmlFor="name" shrink={true} className={classes.lableInput} >Property Name</InputLabel>
                            <AsyncSelect
                                isDisabled={!this.state.selectedCountry ? true : false}
                                formatCreateLabel={() => false}
                                onChange={this.handleHotelChange}
                                value={this.state.newHotel}
                                loadOptions={this.promiseOptions}
                                styles={this.state.error.includes("name") ? SelectStyleError : SelectStyle}
                                placeholder="Property Name"
                                error={this.state.error.includes("name")}
                                required={true}
                            />
                            {this.state.error.includes("name") &&
                                <span class='error-message'>{'* Please enter property name'}</span>
                            }
                        </FormControl>
                    }

                    {this.state.is_old_hotel == 'new' &&
                        <FormControl required className={classes.formControl}>
                            <TextField
                                error={this.state.error.includes("name")}
                                id="standard-name"
                                label="Property Name"
                                value={this.state.name}
                                placeholder="Property Name"
                                onChange={this.handleChange('name')}
                                className={[classes.textField,classes.propertyField]}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            {this.state.error.includes("name") &&
                                <span class='error-message'>{'* Please enter property  name'}</span>
                            }
                        </FormControl>
                    }
                    <FormControl margin="normal" required className={classes.formControl} >
                        <PlacesAutocomplete
                            value={this.state.address}
                            onChange={this.handleChange('address')}
                            onSelect={this.handleSelect}
                            id="address"
                            name="address"
                            multiline
                            className={classes.textField}
                            
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <React.Fragment>
                                    <InputLabel error={this.state.error.includes("address")} shrink={true} htmlFor="search">Address</InputLabel>
                                    <Input
                                        readOnly={!this.state.selectedCountry ? true : false}
                                        {...getInputProps({
                                            className: 'location-search-input',
                                        })}
                                        className={classes.textField}
                                        error={this.state.error.includes("address")}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <MenuItem value={''}>{suggestion.description}</MenuItem>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </React.Fragment>
                            )}
                        </PlacesAutocomplete>
                        {this.state.error.includes("address") &&
                            <span class='error-message'>{'* Please enter Address'}</span>
                        }
                    </FormControl>
                    <FormControl required className={classes.formControl}>
                        <TextField
                            type="number"
                            disabled={true}
                            error={this.state.error.includes("lat")}
                            id="standard-name"
                            label="Latitude"
                            value={this.state.lat}
                            onChange={this.handleChange('lat')}
                            className={classes.textField}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {this.state.error.includes("lat") &&
                            <span class='error-message'>{'* Please enter Latitude'}</span>
                        }
                    </FormControl>
                    <FormControl required className={classes.formControl}>
                        <TextField
                            type="number"
                            disabled={true }
                            error={this.state.error.includes("lng")}
                            id="standard-name"
                            label="Longitude"
                            value={this.state.lng}
                            onChange={this.handleChange('lng')}
                            className={classes.textField}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {this.state.error.includes("lng") &&
                            <span class='error-message'>{'* Please enter Longitude'}</span>
                        }
                    </FormControl>

                    <FormControl required className={classes.formControl}>
                        <TextField
                            disabled={this.state.is_old_hotel == 'old' ? (!this.state.selectedCountry ? true : false) :''}
                            error={this.state.error.includes("currency")}
                            id="standard-select-currency"
                            select
                            label="Currency"
                            className={classes.textField}
                            value={this.state.currency}
                            onChange={this.handleChange('currency')}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                            <MenuItem value={''}>-Select-</MenuItem>
                            {this.props.currencyList && this.props.currencyList.filter(currency => 
                            currency.id === 7 || currency.id===10 || currency.id === 1 || currency.id === 6 || currency.id === 5).concat(this.props.currencyList.filter(currency => 
                                currency.id !== 7 && currency.id!==10 && currency.id !== 1 && currency.id !== 6 && currency.id !== 5).sort(function(a,b){
                                    if(a.name < b.name) { return -1; }
                                    if(a.name > b.name) { return 1; }
                                    return 0;
                                })).map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name} ({option.symbol})
                                    </MenuItem>
                            ))}
                        </TextField>
                        {this.state.error.includes("currency") &&
                            <span class='error-message'>{'* Please select Currency'}</span>
                        }
                    </FormControl>
                    <FormControl required className={classes.formControl}>
                        <TextField
                            type="number"
                            error={this.state.error.includes("number_of_rooms")}
                            id="standard-name"
                            label={`Total Number of Rooms`}
                            value={this.state.number_of_rooms}
                            onChange={this.handleChange('number_of_rooms')}
                            className={classes.textField}
                            margin="normal"
                            disabled={this.state.is_old_hotel == 'old' ? (!this.state.selectedCountry ? true : false) :''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {this.state.error.includes("number_of_rooms") &&
                            <span class='error-message'>{`* Please enter the total number of rooms`}</span>
                        }
                    </FormControl>
                    <FormControl required className={classes.formControl}>
                        <TextField
                            id="standard-name"
                            label="Booking Url"
                            disabled={this.state.is_old_hotel == 'old' ? (!this.state.selectedCountry ? true : false) :''}
                            value={this.state.booking_ref_url}
                            onChange={this.handleChange('booking_ref_url')}
                            className={classes.textField}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>


                    <FormControl required className={classes.formControl}>

                    </FormControl>
                    <Typography variant="title" align={"left"} className={classes.labels}>Client</Typography>

                    <FormControl component="fieldset" className={[classes.formControl,classes.radioCheck]} >
                        <RadioGroup
                            row
                            name="is_old_user"
                            value={this.state.is_old_user}
                            onChange={this.handleChange('is_old_user')}
                        >
                            <FormControlLabel value='new' control={<Radio color="primary" />} label="Create New User" />
                            <FormControlLabel value="old" control={<Radio color="primary" />} label="Assign To Client" />
                        </RadioGroup>
                    </FormControl>
                   
                    {this.state.is_old_user === 'new' &&
                        
                            <FormControl required className={[classes.formControl,classes.saveBtn]} >
                                <TextField
                                    error={this.state.error.includes("email")}
                                    id="standard-name"
                                    label="Email (User Name)"
                                    value={this.state.email}
                                    onChange={this.handleChange('email')}
                                    className={classes.textField}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {this.state.error.includes("email") &&
                                    <span class='error-message'>{'* Please enter valid Email (User Name)'}</span>
                                }
                            </FormControl>
                    }

                    {this.state.is_old_user === 'new' &&
                            <FormControl required className={[classes.formControl,classes.saveBtn]} >
                            <TextField
                                margin="normal"
                                id="standard-select-currency"
                                select
                                label="Preferred Language"
                                value={this.state.language}
                                onChange={this.handleChange('language')}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                                {languages.map(opt => (
                                    <MenuItem key={opt.value} value={opt.value}>
                                        <ListItemText primary={opt.label} />
                                    </MenuItem>
                                ))}
                            </TextField>
                            {this.state.error.includes("language") &&
                                <span class='error-message'>{'* Please select Language'}</span>
                            }
                        </FormControl>
                    }
                    
                    {/* <FormControl required className={[classes.formControl,classes.nameField,classes.hideOnMobile]} >&nbsp;</FormControl> */}

                    {this.state.is_old_user === 'new' &&
                        <React.Fragment>
                            
                            <FormControl required className={[classes.formControl]} >
                                <TextField
                                    error={this.state.error.includes("last_name")}
                                    id="standard-name"
                                    label="Last Name"
                                    value={this.state.last_name}
                                    onChange={this.handleChange('last_name')}
                                    className={classes.textField}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {this.state.error.includes("last_name") &&
                                    <span class='error-message'>{'* Please enter Last Name'}</span>
                                }
                            </FormControl>
                            <FormControl required className={[classes.formControl,classes.saveBtn]}>
                                <TextField
                                    error={this.state.error.includes("first_name")}
                                    id="standard-name"
                                    label="First Name"
                                    value={this.state.first_name}
                                    onChange={this.handleChange('first_name')}
                                    className={classes.textField}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {this.state.error.includes("first_name") &&
                                    <span class='error-message'>{'* Please enter First Name'}</span>
                                }
                            </FormControl>
                        </React.Fragment>
                    }
                    {this.state.is_old_user === 'old' &&
                        <FormControl required className={[classes.formControl,classes.userField]} style={{marginBottom:'40px'}}>
                            <InputLabel htmlFor="name" shrink={true} className={classes.lableInput} >User Name</InputLabel>
                            <AsyncSelect
                                onChange={this.handleUserChange}
                                value={this.state.newUser}
                                loadOptions={this.promiseUserOptions}
                                styles={this.state.error.includes("newUser") ? SelectStyleError : SelectStyle}
                                formatCreateLabel={() => false}
                                placeholder="User Name"
                                
                            />
                            {this.state.error.includes("newUser") &&
                                <span class='error-message'>{'* Please select user'}</span>
                            }
                        </FormControl>
                    }
                    {this.props.userSession.user.is_staff &&

                        <Typography variant="title" align={"left"} className={classes.labels}>Deal Owner</Typography>
                    }
                    {this.props.userSession.user.is_staff &&
                        <FormControl required className={[classes.formControl,classes.saveBtn]} >
                            <TextField
                                margin="normal"
                                id="standard-select-currency"
                                select
                                label="Partner"
                                value={this.state.reseller}
                                onChange={this.handleRellerChange('reseller')}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                                <MenuItem value={''}>-Select-</MenuItem>
                                {this.state.resellerList.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    }
                    {this.props.userSession.user.is_staff &&
                        <FormControl required className={[classes.formControl,classes.saveBtn]} >
                            <TextField
                                margin="normal"
                                id="standard-select-currency"
                                select
                                label="Sales Team"
                                disabled={this.state.reseller ? true : false}
                                value={this.state.reseller ? null : this.state.responsible_account}
                                onChange={this.handleChange('responsible_account')}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                                <MenuItem value={''}>-Select-</MenuItem>
                                {this.state.salesList.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.first_name} {option.last_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {this.state.error.includes("responsible_account") &&
                                <span class='error-message'>{'* Please enter Sales Team or Partner User'}</span>
                            }
                        </FormControl>
                    }
                    {this.props.userSession.user.is_staff &&
                        <FormControl required className={[classes.formControl,classes.saveBtn]} >
                            <TextField
                                margin="normal"
                                id="standard-select-currency"
                                select
                                label="Partner User"
                                value={this.state.responsible_account}
                                onChange={this.handleChange('responsible_account')}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}  
                            >
                                <MenuItem value={''}>-Select-</MenuItem>
                                {this.state.tempMemberList.map(option => (
                                    <MenuItem key={option.id} value={option.user.id}>
                                        {option.user.first_name} {option.user.last_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {this.state.error.includes("responsible_account") &&
                                <span class='error-message'>{'* Please enter Sales Team or Partner User'}</span>
                            }
                        </FormControl>
                    }
                    {this.state.is_old_user === 'old' &&
                        <FormControl required className={classes.formControl}>&nbsp;</FormControl>
                    }
                    <div className={classes.btnDiv}>
                        <Button className={classes.saveBtn} onClick={this.submitHotel} variant="contained" color="primary" disabled={this.state.hotelRegLoading}>
                        
                        Create Property {this.state.hotelRegLoading &&
                            <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                        </Button>
                    </div>
                </CardContent>
            </Card >
            </>
        );
    }
}

RateSetting.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        userSession: state.sessionReducer,
        currencyList: state.currencyReducer.currencyList,
        countryReducer: state.countryReducer.countryList,
        hotelListReducer: state.hotelListReducer,
        userListReducer: state.allClientsReducer,
        commonReducer: state.commonReducer,
        resellerReducer: state.resellerReducer,
        adminUserReducer: state.adminUserReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCurrency: (request) => { dispatch(actions.currency(request)) },
        getCountry: (request) => { dispatch(actions.getCountry(request)) },
        getHotelList: (request) => { dispatch(actions.getHotelList(request)) },
        registerHotel: (request) => { dispatch(actions.registerHotel(request)) },
        getClientList: (request) => { dispatch(actions.getClientUserList(request)) },
        getResellerAll: (request) => { dispatch(actions.getResellerAll(request)) },
        getAdminSales: (request) => { dispatch(actions.getAdminSales(request)) },
        getResellerMemberAll: (request) => { dispatch(actions.getResellerMemberAll(request)) },
        setHotelList: (request) => { dispatch({ type: actionTypes.HOTEL_LIST_SUCCEED, state: request }) },
        setClientList: (request) => { dispatch({ type: actionTypes.CLIENT_GET_SUCCEED, state: request }) },
    };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(RateSetting);