import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../index';
import Notify from "../Notify";

export const cleanNotify = (request) => {
    return dispatch => {
        dispatch({
            type: actionTypes.PUT_NOTIFY,
            data: {}
        });
    }
}

export const putNotify = (request) => {
    return dispatch => {
        let data = {
            message: request.msg,
            is_success: request.is_success,
        }
        dispatch({
            type: actionTypes.PUT_NOTIFY,
            data: data
        });
    }
}

export const setCalendarRoom = (request) => {
    return dispatch => {
        let data = {
            // roomId: request.id,
            roomId: request,
        }
        dispatch({
            type: actionTypes.SET_CALENDAR_ROOM,
            data: data
        });
    }
}

const lastPricingDate = (data) => {
    return {
        type: actionTypes.LAST_PRICING_DATE,
        data: data
    }
}

export const getLastPricing = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;

        axios.get(Server.API + 'hotel/last-pricing-update/')
            .then(function (response) {
                dispatch(lastPricingDate(response.data))
            })
            .catch(function (error) {
            })
    }

}

const onUpdateLastPricingStart = () => {
    return {
        type: actionTypes.UPDATE_LAST_PRICING_START
    }
}

const onUpdateLastPricingFailed = (error) => {
    return {
        type: actionTypes.UPDATE_LAST_PRICING_FAILED,
        error: error
    }
}

const onUpdateLastPricingSucceed = (data) => {
    return {
        type: actionTypes.UPDATE_LAST_PRICING_SUCCEED,
        state: data
    }
}

export const updateLastPricing = (request) => {
    return dispatch => {
        dispatch(onUpdateLastPricingStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/last-pricing-update/', request)
            .then(function (response) {
                dispatch(actions.getHotelDetail(request))
                dispatch(onUpdateLastPricingSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onUpdateLastPricingFailed(error))
            });
    }
}

const stopNavigationSucceed = (data) => {
    return {
        type: actionTypes.STOP_NAVIGATION,
        state: data
    }
}

export const stopNavigation = (request) => {
    return dispatch => {
        dispatch(stopNavigationSucceed(request));
    }
}

const navigationPopup = (data) => {
    return {
        type: actionTypes.SHOW_NAVIGATION_POPUP,
        state: data
    }
}

export const showNavigationPopup = (request) => {
    return dispatch => {
        dispatch(navigationPopup(request));
    }
}

const weatherSucess = (data) => {
    return {
        type: actionTypes.WEATHER_DETAILS,
        state: data
    }
}

export const getWeatherDetails = (request) => {
    return dispatch => {

        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'pricingalgorithm/calculate-weather/', request)
            .then(function (response) {
                dispatch(weatherSucess(response.data));
            })
            .catch(function (error) {
            });
    }
}

const deletePropertySucess = (data) => {
    return {
        type: actionTypes.DELETE_PROPERTY_SUCCESS,
        state: data
    }
}
export const deleteProperty = (request) => {
    
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/delete/client_hotel/')
            .then(function (response) {
                dispatch(deletePropertySucess(response.data));
                dispatch(Notify.newUserModal(response.data.message, true));
            })
            .catch(function (error) {
            });
    }
}


const onstoreIssueReportStart = () => {
    return {
        type: actionTypes.STORE_ISSUE_REPORT_START
    }
}

const onstoreIssueReportFailed = (error) => {
    return {
        type: actionTypes.STORE_ISSUE_REPORT_FAILED,
        error: error
    }
}

const onstoreIssueReportSucceed = (data) => {
    return {
        type: actionTypes.STORE_ISSUE_REPORT_SUCCEED,
        state: data
    }
}

export const storeIssueReport = (request) =>{
    let requestParam={
        "ticket": {
            subject:request.subject,
            content:request.content,
            hs_ticket_category:request.hs_ticket_category,
            hs_ticket_priority:request.hs_ticket_priority,
            how_do_you_want_to_be_contacted_:request.how_do_you_want_to_be_contacted_,
        },
        "contact": {
            firstname: request.firstname,
            lastname:request.lastname,
            email: request.email,
            phone:request.phone
        }
    }
    return dispatch => {
        dispatch(onstoreIssueReportStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/hs_report_ticket/create/',requestParam)
            .then(function (response) {
                dispatch(onstoreIssueReportSucceed(response.data));
                dispatch(Notify.newUserModal("Support Form Submitted Successfully", true));
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Report Ticket Failed", false));
                dispatch(onstoreIssueReportFailed())
            });
    }    
}




const getDefaultAfterYearSucceed = (data) => {
    return {
        type: actionTypes.GET_DEFAULT_VALUE_AFTER_YEAR,
        state: data
    }
}

export const getDefaultAfterYear = (request) =>{   
    return dispatch => {
        dispatch(onstoreIssueReportStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'pricingalgorithm/default-after-365-daily-averages/')
            .then(function (response) {
                dispatch(getDefaultAfterYearSucceed(response.data));
            })
            .catch(function (error) {
                dispatch(onstoreIssueReportFailed())
            });
    }    
}

export const updateTempProp = (request) =>{
  return dispatch => {
        dispatch({
            type: actionTypes.UPDATE_TEMP_DATA,
            state: ""
        })        
    }
}
const getProfitReportSuccess = (data) => {
    return {
        type: actionTypes.GET_PROFIT_REPORT_SUCCESS,
        state: data
    }
}
const getProfitReportStart = () => {
    return {
        type: actionTypes.GET_PROFIT_REPORT_START,
    }
}
const getProfitReportFailed = (error) => {
    return {
        type: actionTypes.GET_PROFIT_REPORT_FAILED,
    }
}
export const getProfitReport=(req,openPdfModal)=>{
    return dispatch => {
    dispatch(getProfitReportStart())
    axios.defaults.headers.common['Authorization'] = "Token " + req.token;
    axios.get(Server.API + 'hotel/profitwithuncertainity_pdf/')
      .then((response) => {
          if(response.data.success){
            dispatch(getProfitReportSuccess(response.data))
            openPdfModal()
          }else{
            dispatch(Notify.newUserModal(response.data.message, false));              
          } 
        
      })
      .catch(function (error) {
        dispatch(getProfitReportFailed(error))

    });
    }
}

