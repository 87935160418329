import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';

const onTimeBookingStart = () => {
    return {
        type: actionTypes.TIME_BOOKING_START
    }
}

const onTimeBookingFailed = (error) => {
    return {
        type: actionTypes.TIME_BOOKING_FAILED,
        error: error
    }
}

const onTimeBookingSucceed = (data) => {
    return {
        type: actionTypes.TIME_BOOKING_SUCCEED,
        state: data
    }
}

export const timeBooking = (request) => {
    return dispatch => {
        dispatch(onTimeBookingStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'hotel/adjustment/time-to-booking/list/')
            .then(function (response) {
                dispatch(onTimeBookingSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onTimeBookingFailed(error))
            });
    }
}


const onTimeBookingUpdateStart = () => {
    return {
        type: actionTypes.TIME_BOOKING_UPDATE_START
    }
}

const onTimeBookingUpdateFailed = (error) => {
    return {
        type: actionTypes.TIME_BOOKING_UPDATE_FAILED,
        error: error
    }
}

const onTimeBookingUpdateSucceed = (data) => {
    return {
        type: actionTypes.TIME_BOOKING_UPDATE_SUCCEED,
        state: data
    }
}

// export const timeBookingUpdate = (request) => {
//     return dispatch => {
//         dispatch(onTimeBookingUpdateStart())
//         axios.defaults.headers.common['Authorization'] = "Token " + request.token;
//         axios.patch(Server.API + 'hotel/adjustment/time-to-booking/update/', request)
//             .then(function (response) {
//                 dispatch(onTimeBookingUpdateSucceed(response.data))
//             })
//             .catch(function (error) {
//                 dispatch(onTimeBookingUpdateFailed(error))
//             });
//     }
// }

const elasticityListSucceed = (data) => {
    return {
        type: actionTypes.GET_ELASTICITY_LIST,
        state: data
    }
}

export const elasticityList = (request) => {
    return dispatch => {
        // dispatch(onTimeBookingStart())
        // axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        // axios.get(Server.API + 'master/elasticity/list/')
        //     .then(function (response) {
        //         dispatch(elasticityListSucceed(response.data))
        //     })
        //     .catch(function (error) {
        //     });
    }
}

const bookingPropSucceed = (data) => {
    return {
        type: actionTypes.GET_BOOKING_LIST,
        state: data
    }
}

export const bookingPropList = (request) => {
    return dispatch => {
        // dispatch(onTimeBookingStart())
        // axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        // axios.get(Server.API + 'hotel/predefined/booking-prop/list/')
        //     .then(function (response) {
        //         dispatch(bookingPropSucceed(response.data))
        //     })
        //     .catch(function (error) {
        //     });
    }
}

