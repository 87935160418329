import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import fingerprint from 'fingerprintjs'
import jwt from 'jsonwebtoken';

const onLoginStart = () => {
    return {
        type: actionTypes.LOGIN_START
    }
}

const onLoginFailed = (error) => {
    return {
        type: actionTypes.LOGIN_FAILED,
        error: error
    }
}

const onLoginSucceed = (data) => {
    return {
        type: actionTypes.LOGIN_SUCCEED,
        state: data
    }
}

const storeUserData = (resp) => {
    let finderprint = new fingerprint().get();
    return jwt.sign(resp, finderprint.toString())
}

export const bookinAuth = (request) => {
    return dispatch => {
        dispatch(onLoginStart())
        axios.post(Server.API + 'account/booking-suite/auth/', request)
            .then(function (response) {
                localStorage.setItem("__UserProps__", storeUserData(response.data))
                dispatch(onLoginSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onLoginFailed())
            });
    }
}

export const seekomAuth = (request) => {

    return dispatch => {
        dispatch(onLoginStart())
        axios.post(Server.API + 'account/seekom/auth/', request)
            .then(function (response) {
                localStorage.setItem("__UserProps__", storeUserData(response.data))
                dispatch(onLoginSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onLoginFailed())
            });
    }
}




export const apaleoAuth = (request) => {
    return dispatch => {
        dispatch(onLoginStart())
        axios.post(Server.API + 'account/apaleo/auth/', request)
            .then(function (response) {
                localStorage.setItem("__UserProps__", storeUserData(response.data))
                dispatch(onLoginSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onLoginFailed())
            });
    }
}


export const oAuth2Call = (request) => {
    return dispatch => {
        dispatch(onLoginStart())
        axios.post(Server.API + 'account/integrations/oauth2/', request)
            .then(function (response) {
                localStorage.setItem("__UserProps__", storeUserData(response.data))
                dispatch(onLoginSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onLoginFailed())
            });
    }
}


