import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = [];

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DATA_MODAL_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.DATA_MODAL_SUCCEED:
            return updateObject(state, { loading: false, error: null, dataModalList: action.state })
        case actionTypes.DATA_MODAL_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.DATA_MODAL_UPDATE_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.DATA_MODAL_UPDATE_SUCCEED:
            return updateObject(state, { loading: false, error: null, dataModalList: action.state })
        case actionTypes.DATA_MODAL_UPDATE_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        default:
            return updateObject(state, { loading: false, error: null });
    }
}

export default reducer