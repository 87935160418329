import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CloseIcon from '@material-ui/icons/Close';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ClientMenuList from '../config/clientMenu';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ClientRountes from './Client/route';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { MuiThemeProvider,  } from '@material-ui/core/styles';
import { SidebarListTheme,  Sidebartheme,  SidebarMUIIconBtn } from './muiTheme';
import purple from '@material-ui/core/colors/purple';
import CardMedia from '@material-ui/core/CardMedia';
import sideBarCss from './css/sidebarCss';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Icon } from '@material-ui/core';
import { connect } from "react-redux";
import { compose } from 'redux'
import * as actions from '../store/actions/index';
import Loader from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withNamespaces, Trans, NamespacesConsumer } from 'react-i18next';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import moment from 'moment';
import QuickSetup from "./Client/quickSetup";
import axios from 'axios';
import Server from '../config/server';
import Modals from './modal/modal';
import Badge from './Badge';
import AnnounceKit from "announcekit-react";
import PreparingAccount from './Client/preparingAccount';
import pmsDataArray from './PMSList';
const styles = sideBarCss;


let appCuesIdentity = {}
let dataArray={}
class Sidebar extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      referFriend: false,
      allowUrl: false,
      hotelListUpdate: true,
      trialExpire: false,
      changeLng: false,
      language: 'en',
      snackbarMsg: "",
      snackbarSuccess: false,
      warning: false,
      mobileOpen: false,
      childIndex: this.openDefault(),
      auth: true,
      anchorEl: null,
      anchorEh: null,
      anchorEg: null,
      openAccount: false,
      drawerOpen: false,
      drawerOpenAddcss: false,
      drawerArrowButtom:true,
      isResetPassword: false,
      getRoomList: false,
      isBulk: false,
      calendarRoom: "calendar",
      stopNavigation: false,
      resetPassword: {
        old_password: '',
        new_password: '',
        email: '',
      },
      account: {
        first_name: '',
        last_name: '',
      },
      error: [],
      featuresEnabled: [],
      showOld: false,
      showNew: false,
      helpModal: false,
      appWalkthroughsModal: false,
      openSupportRequestModal: false,
      categorizeRequest:[
        {value:'PRODUCT_ISSUE' ,label:'Error Notification'},
        {value:'Price Calculation',label:'Price Calculation'},
        {value:'GENERAL_INQUIRY',label:'Account/Room Setup'},
        {value:'Price Adjustments',label:'Price Adjustments'},
        {value:'BILLING_ISSUE',label:'Subscription / Billing'},
        {value:'FEATURE_REQUEST',label:'Feature Request'},
      ],
      reportIssueLoading:false,      
      hs_ticket_priority:'',
      hs_ticket_category:[],
      room_apartment_space_name: "",
    };
  }

  toggleDrawerOpen = () =>{
    this.setState({
      // drawerOpen: !this.state.drawerOpen
      drawerOpenAddcss:true,
      drawerArrowButtom: true
    })
     let requestParams = {
      data: {is_collapsed_sidebar_enable: true},
      storeData: this.props.userSession
    }
    this.props.updateSidebarView(requestParams)
  }

  // SideBar hide/Show on mouse Enter And Leave (enterDrawerOpen() And enterDrawerClose())
  enterDrawerOpen = (event) =>{
    this.setState({
      drawerOpen: true
    })
    document.getElementById("MainSidebar").addEventListener('scroll', (event) => {
      if(document.getElementById("SmallSidebar") && document.getElementById("MainSidebar")){
        document.getElementById("SmallSidebar").scrollTop = document.getElementById("MainSidebar").scrollTop;
      }      

    });
  }
  enterDrawerClose = (event) =>{
    
    if(this.state.anchorEh == null && this.state.anchorEl == null){
      this.setState({
        drawerOpen: false,
      })
    }
    
    if(event.type == 'click'){
      this.setState({
          drawerOpenAddcss:false,
          drawerArrowButtom: true 
      })

      let requestParams = {
      data: {is_collapsed_sidebar_enable: false},
      storeData: this.props.userSession
    }
    this.props.updateSidebarView(requestParams)
    
    }
  }

  handleDrawerHideShowArrowButton = (event) => {  
    if(event.type == 'mouseenter'){
      this.setState({drawerArrowButtom: true })
    }else if(event.type == 'mouseleave'){
      this.setState({drawerArrowButtom: false })
    }
  }

  openDefault = () => {
    let url = window.location.href;
    let settingArray = ['/client/HotelSetting', '/client/UserSetting', '/client/PaymentSetting', '/client/RateSetting', '/client/Connections', '/client/Competitors', '/client/InternalParams', 'client/ChangePassword'];
    let index = -1;

    if (url.includes('/client/Calendar') === true) {
      index = 0;
    }

    settingArray.filter(val => url.includes(val) === true).map((val) => {
      index = 3;
    });

    return index;
  }

  showPassword = (name) => {
    this.setState({
      [name]: !this.state[name]
    })
  }

  handleUserProfile = () => {
    this.setState({
      openAccount: !this.state.openAccount,
      anchorEl: null,
    })
  }

  componentDidMount() {
    let arr=["?appcue=9fa928c9-8cf6-413d-8cee-8b418e729b00",
             "?appcue=10b1eecd-ccc1-4f4a-9371-bb087d9a5fcf",
             "?appcue=0c22b60e-012a-4f59-9529-f727306ab919",
             "?appcue=d339c03c-5b62-4bc4-b9d6-08d77805b2b8",
             "?appcue=2cc08288-c2bd-489d-8ef6-9c5eefe191c2",
             "?appcue=10ef0bd9-bdc0-457a-ad7c-0a311cfc9fb1",
             "?appcue=c218c17c-54fe-4af1-b1b1-a34fe39522c6",
             "?appcue=-MA6ZXrFxd9I8eYeGUee",
             "?appcue=-M5gCyE0m_AvthflMFt3",
             "?appcue=-M5uODUX66Yo6DCzQI7Q",
             "?appcue=-M6TgDNoaunYRaVMm-Y3",
             "?appcue=-M6U76Rtf2-9oT1_lMP8",
             "?appcue=-M6U7cblaJaxCoEsU3IC",
             "?appcue=-MA6vA3ueZ09fI76nCaa"
            ];
            let isWalkthroughs=arr.some(data=>data==window.location.search);
            if(isWalkthroughs){
              this.toggleDrawerOpen();

            }
    this.getInitHotelData();
    // let currentLng = localStorage.getItem("i18nextLng");

    // let language = "en";
    // if (currentLng.includes("es")) {
    //   language = "es";
    // } else if (currentLng.includes("ja")) {
    //   language = "ja";
    // } else if (currentLng.includes("fr")) {
    //   language = "fr";
    // } else if (currentLng.includes("de")) {
    //   language = "de";
    // }
    let language = "en";

    if (this.props.userSession.user.language) {
      language = this.props.userSession.user.language;
    }

    const { i18n } = this.props;
    i18n.changeLanguage(language);



    this.setState({
      language,
      drawerOpenAddcss: this.props.userSession.user.is_collapsed_sidebar_enable,
      drawerOpen: this.props.userSession.user.is_collapsed_sidebar_enable,
      drawerArrowButtom: true
    })

    
  }

  componentWillReceiveProps(props) {
    if(props.hotelAuthReducer?.errordata){
      if(props.hotelAuthReducer?.errordata?.response?.status == 401){ // invalid Token and Expire Token status code
          localStorage.removeItem("__UserProps__");
          localStorage.removeItem("email");
          localStorage.removeItem("name");
          window.location = "/";
      }
    }

    if(props.commonReducer.reportIssueSuccess){
      this.setState({
        supportRequestModal:false
      })
    }
    if(props.commonReducer && props.commonReducer.reportIssueLoading){
      this.setState({
        reportIssueLoading:props.commonReducer.reportIssueLoading
      })
    }
    if (props.commonReducer.calendarRoom && props.commonReducer.calendarRoom.roomId && props.commonReducer.calendarRoom.roomId != this.state.calendarRoom){
      this.setState({
        calendarRoom: props.commonReducer.calendarRoom.roomId ? props.commonReducer.calendarRoom.roomId : "calendar",
      })
    }

    this.setState({
      stopNavigation: props.commonReducer.stopNavigation
    })
    if (props.commonReducer.notify) {
      if (props.commonReducer.notify.message) {
        this.setState({
          snackbarMsg: props.commonReducer.notify.message,
          snackbarSuccess: props.commonReducer.notify.is_success,
          warning: true,
        }, () => {
          if (props.commonReducer.notify.is_success) {
            setTimeout(() => {
              this.setState({
                warning: false
              })
            }, 3000);
          } else {
            // setTimeout(() => {
            //   this.setState({
            //     warning: false
            //   })
            // }, 6000);
          }
        })
        this.props.cleanNotify()
      }
    }
    if (props.userSession.user) {
      this.setState({
        account: {
          first_name: props.userSession.user.first_name,
          last_name: props.userSession.user.last_name,
          email: props.userSession.user.email,
          language: props.userSession.user.language
        },
      })
      if (props.hotelAuthReducer.hotelDetails.hotel.id && props.priceSettingReducer && props.priceSettingReducer.priceSetting) {
        appCuesIdentity.accountId = props.userSession.user.id; // Current user's account ID
        appCuesIdentity.firstName = props.userSession.user.first_name; // current user's first name
        appCuesIdentity.lastName = props.userSession.user.last_name; // current user's last name
        appCuesIdentity.email = props.userSession.user.email; // Current user's email
        appCuesIdentity.companyName = props.hotelAuthReducer.hotelDetails.hotel.id;
        appCuesIdentity.language = this.state.language;
        appCuesIdentity.freeTrialEndDate = moment(props.hotelAuthReducer.hotelDetails.hotel.free_trial_end).unix();
        appCuesIdentity.account_type = (props.userSession.user.is_staff) ? 'admin' : 'member'
        appCuesIdentity.auto_upload = props.hotelAuthReducer.hotelDetails.hotel.is_update_to_pms;
        let free_trial_end = moment(props.hotelAuthReducer.hotelDetails.hotel.free_trial_end)
        appCuesIdentity.freeTrialEnd = free_trial_end.diff(moment(), 'days');

        appCuesIdentity.location = props.hotelAuthReducer.hotelDetails.hotel.country.name
        appCuesIdentity.planTier = 'n.a.';

        if (props.hotelAuthReducer.hotelDetails.hotel.plan == 1) {
          appCuesIdentity.planTier = 'GenieIntelligence';
        } else if (props.hotelAuthReducer.hotelDetails.hotel.plan == 2) {
          appCuesIdentity.planTier = 'Basic';
        } else if (props.hotelAuthReducer.hotelDetails.hotel.plan == 3) {
          appCuesIdentity.planTier = 'Flexible';
        } else if (props.hotelAuthReducer.hotelDetails.hotel.plan == 4) {
          appCuesIdentity.planTier = 'Pro';
        }


        if (props.hotelAuthReducer.hotelDetails.hotel.state == 0) {
          appCuesIdentity.PlanType = 'free trial'
        } else if (props.hotelAuthReducer.hotelDetails.hotel.state === 1) {
          appCuesIdentity.PlanType = 'subscribed'
        } else if (props.hotelAuthReducer.hotelDetails.hotel.state === 2) {
          appCuesIdentity.PlanType = 'cancelled'
        } else if (props.hotelAuthReducer.hotelDetails.hotel.state === 3) {
          appCuesIdentity.PlanType = 'free trial expired'
        } else if (props.hotelAuthReducer.hotelDetails.hotel.state === 4) {
          appCuesIdentity.PlanType = 'suspended'
        } else if (props.hotelAuthReducer.hotelDetails.hotel.state === 5) {
          appCuesIdentity.PlanType = 'suspended'
        } else if (props.hotelAuthReducer.hotelDetails.hotel.state === 6) {
          appCuesIdentity.PlanType = 'subscribed(P)'
        } else if (props.hotelAuthReducer.hotelDetails.hotel.state === 7) {
          appCuesIdentity.PlanType = 'unsubscribed(P)'
        }
        appCuesIdentity.provider = "";

        let connection = pmsDataArray.filter(row => row.value == this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider);
        if (connection.length > 0) {
          appCuesIdentity.provider = connection[0].label;
        }

        appCuesIdentity.partnerSubscription = props.priceSettingReducer.priceSetting.features.includes(10)
        if(window.Appcues){
          window.Appcues.identify(
            appCuesIdentity.accountId,
            appCuesIdentity
          );
        }

        if(window.hj){
          window.hj('identify',  appCuesIdentity.accountId, appCuesIdentity);
        }

        if (this.props.hotelAuthReducer.hotelDetails.hotel && this.props.hotelAuthReducer.hotelDetails.hotel.room_apartment_space_name){
          this.setState({
            room_apartment_space_name: this.props.hotelAuthReducer.hotelDetails.hotel.room_apartment_space_name
          });
        }

        let sub_account_type = "";
        if (props.userSession.user.sub_account_type === 1) {
          sub_account_type = 'sales'
        } else if (props.userSession.user.sub_account_type === 2) {
          sub_account_type = 'reseller'
        }
      }
    }

    if (props.hotelAuthReducer.hotelDetails.token && !this.state.isReady) {
      axios.defaults.headers.common['Authorization'] = "Token " + props.hotelAuthReducer.hotelDetails.token;
      axios.get(Server.API + 'hotel/quick-setup-data/get/')
        .then((resp) => {
          this.setState({
            isFinished: resp.data.is_finished,
          })
        })
      this.setState({
        isReady: true
      })
    }


    if (props.hotelAuthReducer.hotelDetails.token && this.state.hotelListUpdate) {
      if (props.hotelAuthReducer.hotelDetails.hotel_list.length != props.userSession.hotel_list.length) {
        this.setState({
          hotelListUpdate: false,
        })
        let reqParams = props.userSession;
        reqParams.hotel_list = props.hotelAuthReducer.hotelDetails.hotel_list;
        this.props.updateStorageData(reqParams)
      }
    }

    if (props.priceSettingReducer && props.priceSettingReducer.priceSetting) {

      let url = window.location.pathname;
      let showDashboardOnly = (props.priceSettingReducer.priceSetting.features.includes(31)) ? true : false
      let is_admin = (props.userSession.user.is_staff || props.userSession.user.sub_account_type) ? true : false;
      if (showDashboardOnly && !is_admin && url != "/client/PerformanceDashboard/" + props.hotelAuthReducer.hotelDetails.hotel.id && url != "/client/PaymentSetting/" + props.hotelAuthReducer.hotelDetails.hotel.id) {
        window.location = "/client/PerformanceDashboard/" + props.hotelAuthReducer.hotelDetails.hotel.id;
      }else{
        this.setState({
          featuresEnabled: props.priceSettingReducer.priceSetting.features,
          loadApp: true
        })
      }
    }

    if (props.hotelAuthReducer.hotelDetails.token && props.userSession.user.is_staff === false && this.props.userSession.user.sub_account_type == null && props.priceSettingReducer && props.priceSettingReducer.priceSetting) {
      if (props.hotelAuthReducer.hotelDetails.hotel.state == 9 || props.hotelAuthReducer.hotelDetails.hotel.is_locked || props.priceSettingReducer.priceSetting.features.includes(19)) {
        let url = window.location.pathname;
        if (props.hotelAuthReducer.hotelDetails.hotel.state == 9 && url != "/client/PaymentSetting/" + props.hotelAuthReducer.hotelDetails.hotel.id) {
          window.location = "/client/PaymentSetting/" + props.hotelAuthReducer.hotelDetails.hotel.id;
        }
        if (props.hotelAuthReducer.hotelDetails.hotel.state != 8 && url != "/client/PaymentSetting/" + props.hotelAuthReducer.hotelDetails.hotel.id && url != "/client/UserSetting/" + props.hotelAuthReducer.hotelDetails.hotel.id) {
          window.location = "/client/PaymentSetting/" + props.hotelAuthReducer.hotelDetails.hotel.id;
        }
        this.setState({
          trialExpire: true
        })
      }
    }

    if (props.hotelAuthReducer.hotelDetails.token && !this.state.getRoomList) {
      let reqParam = {
        token: props.hotelAuthReducer.hotelDetails.token
      }
      this.props.getRoomList(reqParam);
      this.props.getPricingSettings(reqParam);

      this.setState({
        getRoomList: true
      })
    }

    if (this.props.hotelAuthReducer.hotelDetails.hotel && this.props.hotelAuthReducer.hotelDetails.hotel.reseller && this.props.hotelAuthReducer.hotelDetails.hotel.reseller.id == 1) {
      var items = document.getElementById("hubspot-messages-iframe-container");
      if (items) {
        items.style.visibility = "hidden";
      }
    }
    // if(this.state.featuresEnabled.includes(28)){
    //   window.HubSpotConversations.widget.close();
    // }else{
    //   window.HubSpotConversations.widget.load();
    // }    
  }

  referFriend = () => {
    this.setState({
      referFriend: !this.state.referFriend
    })
  }

  toggleWarning = () => {
    this.setState({
      warning: !this.state.warning,
    })
  }

  handleCreateReportAnIssue = (name) =>event => {
    this.setState({
      [name]:event.target.value
    })
  }
  toggleTicketCategory = (values,e) =>{
    let selectedTicketCategory=this.state.hs_ticket_category;
    if (e.target.checked) {
      selectedTicketCategory.push(values)
    }else{ 
      selectedTicketCategory = selectedTicketCategory.filter((val) => val !== values);
    }
    this.setState({
      hs_ticket_category: selectedTicketCategory,
    });
  }
  validateIssueReport = () =>{
    let error = [];
    if(!this.state.subject){
      error.push('subject')
    }
    if(!this.state.contant){
      error.push('contant')
    }
    if(!this.state.hs_ticket_category){
      error.push('hs_ticket_category')
    }
    if(!this.state.hs_ticket_priority){
      error.push('hs_ticket_priority')
    }
    if(!this.state.firstname){
      error.push('firstname')
    }
    if(!this.state.lastname){
      error.push('lastname')
    }
    if(!this.state.how_do_you_want_to_be_contacted_){
      error.push('how_do_you_want_to_be_contacted_')
    }
    if(!this.state.email){
      error.push('email')
    }
    if (error) {
      this.setState({
        error: error,
      });
      return error;
    } else {
      return false;
    }
  }
  submitIssueReport = () => {
     let validateError = this.validateIssueReport();
      if (validateError.length > 0) {
          return;
      }
      
      let ticket_category=this.state.hs_ticket_category.toString()
      let hs_ticket_category=ticket_category.replaceAll(",",";");
      
      let requestParam = {
        token: this.props.hotelAuthReducer.hotelDetails.token ,
        subject:this.state.subject,
        content:this.state.contant,
        hs_ticket_category:hs_ticket_category,
        hs_ticket_priority:this.state.hs_ticket_priority,
        how_do_you_want_to_be_contacted_:this.state.how_do_you_want_to_be_contacted_,
        firstname:this.state.firstname,
        lastname:this.state.lastname,
        email:this.state.email,
        phone:this.state.phone,
      }
      this.props.storeIssueReport(requestParam);
  }
  getInitHotelData = () => {
    let clientPath = window.location.pathname;
    let pathArray = clientPath.match(/([\d]+)/g)
    let clientID = pathArray ? pathArray[0] : '';
    let CurrentClient = "";
    let initialHotels = this.props.userSession.hotel_list.filter((row)=>([0,1,6,8].includes(row.state) && !row.is_client_access_disabled));
    
    if(initialHotels.length == 0){
      initialHotels = this.props.userSession.hotel_list;
    }

    if (this.props.userSession.user.is_staff === false && this.props.userSession.user.sub_account_type == null) {
      let localKey = localStorage.getItem("HotelAlias");
      if (localKey) {
        let findKey = this.props.userSession.hotel_list.filter(val => val.id == localKey);
        if (findKey.length > 0) {
          CurrentClient = localKey
        } else {
          CurrentClient = initialHotels[0].id;
        }
      } else {
        CurrentClient = initialHotels[0].id;
      }
    }

    if (clientID) {
      if (this.props.userSession.user.is_staff === false && this.props.userSession.user.sub_account_type == null) {
        let findKey = this.props.userSession.hotel_list.filter(val => val.id == clientID);
        if (findKey.length > 0) {
          CurrentClient = clientID;
        } else {
          CurrentClient = initialHotels[0].id;
        }
      } else {
        CurrentClient = clientID;
      }
    }
    
    if(this.props.userSession.user.isClientView){
      CurrentClient = clientID;
    }

    let defaultHotel = {
      id: CurrentClient,
      token: this.props.userSession.token
    };
    if (this.props.userSession.user.is_staff === false && this.props.userSession.user.sub_account_type == null) {
      localStorage.setItem("HotelAlias", CurrentClient);
    }
    this.props.hotelAuth(defaultHotel);
  }

  handleLogout = () => {
    let requestParams = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      is_logout: true
    };
    this.props.setUserLog(requestParams);
    localStorage.removeItem("__UserProps__");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    window.location = "/";
  }

  handlePassword = (name) => event => {
    this.setState({
      resetPassword: {
        ...this.state.resetPassword,
        [name]: event.target.value
      }
    })
  }

  handleAccount = (name) => event => {
    this.setState({
      account: {
        ...this.state.account,
        [name]: event.target.value
      }
    })
  }

  handleLng = () => {
    this.setState({
      changeLng: !this.state.changeLng
    })
  }


  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };

  handleHotel = event => {
    this.setState({       
      anchorEh: event.currentTarget,
    });
  };

  handleLngPopup = event => {
    this.setState({ anchorEg: event.currentTarget });
  };

  handleClosehotel = () => {
    this.setState({ anchorEh: null });
  }

  handleCloseLng = () => {
    this.setState({ anchorEg: null });
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlehintMenu = event => {
    this.setState({ anchorhint: event.currentTarget, helpModal: true });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null, anchorhint: null });
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  closeDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: false }));
  };

  togglePassword = () => {
    this.setState({
      isResetPassword: !this.state.isResetPassword,
      anchorEl: null,
      error: []
    })
  }
  handleClose = () => {
    this.setState({ helpModal: false, });
  };
  searchTextt = () =>{
    if(this.state.searchText){
      window.open( 'https://help.roompricegenie.com/knowledge/kb-search-results?term='+this.state.searchText)
      this.setState({searchText:''})
    }
  }
  handleChange = (val, actionMeta) => {  
    if(actionMeta.action == "select-option"){
      if (val) {
        return window.open(val.link);
      }
    }
  }
  handleInputChange = (val, actionMeta) =>{
    this.setState({
      searchText:val
    })
    if(actionMeta.action == "set-value"){
      this.searchTextt()
    }
  }
  
  openAppWalkthroughsModal = () => {
    this.setState({
      appWalkthroughsModal: true,
    })
  }
  closeAppWalkthroughsModal = () => {
    this.setState({
      appWalkthroughsModal: false,
      helpModal: true
    })
  }
  openSupportRequestModal = () => {
    this.setState({
      supportRequestModal: true,
      subject:'',
      contant:'',
      firstname:'',
      lastname:'',
      email:'',
      phone:'',
    })
  }
  closeSupportRequestModal = () => {
    this.setState({
      supportRequestModal: false,
      helpModal: true
    })
  }
  revenueArticle = () => {
    window.open(' https://help.roompricegenie.com/knowledge/', '_blank');
  }
  academyLink = () => {
    window.open('https://academy.roompricegenie.com/login/index.php?roompricegenie_client', '_blank');
  }
  promiseQuestionOptions = () => {
    let data = [
      { value: '1', label: 'Change Reference Room', link: ' https://help.roompricegenie.com/knowledge/how-to-change-the-reference-room' },
      { value: '2', label: 'Set Base Price', link: 'https://help.roompricegenie.com/knowledge/set-base-price' },
      { value: '3', label: 'Bulk-Edit Prices', link: 'https://help.roompricegenie.com/knowledge/bulk-edit-prices' },
      { value: '4', label: `Close Rooms`, link: 'https://help.roompricegenie.com/knowledge/closed-rooms' },
    ]
    return data
  }
  changeHotel = (index) => {
    // let defaultHotel = {
    //   id: this.props.hotelAuthReducer.hotelDetails.hotel_list[index].id,
    //   token: this.props.userSession.token
    // };
    // this.props.hotelAuth(defaultHotel);
    window.location = "/client/Calendar/" + this.props.hotelAuthReducer.hotelDetails.hotel_list[index].id;
  }

  validateForm = (data) => {
    let error = [];
    if (data) {

      if (!this.state.resetPassword.old_password) {
        error.push('old_password')
      }
      if (!this.state.resetPassword.new_password) {
        error.push('new_password')
      }

      if (error) {
        this.setState({
          error: error
        })
        return error
      } else {
        return false
      }
    } else {
      return true;
    }
  }

  chnagePassword = () => {

    let validateError = this.validateForm(this.state.resetPassword);
    if (validateError.length > 0) {
      return;
    }

    let requestParams = {
      ...this.state.resetPassword,
      token: this.props.hotelAuthReducer.hotelDetails.token
    }
    this.props.updatePassword(requestParams)
    this.setState({
      isResetPassword: false,
      resetPassword: {
        old_password:'',
        new_password:''
      }
    })
  }


  validateProfileForm = (data) => {
    let error = [];
    if (data) {

      if (!this.state.account.first_name) {
        error.push('first_name')
      }
      if (!this.state.account.last_name) {
        error.push('last_name')
      }
      if (!this.state.account.email) {
        error.push('email')
      }

      if (error) {
        this.setState({
          error: error
        })
        return error
      } else {
        return false
      }
    } else {
      return true;
    }
  }

  chnageAccount = () => {

    let validateError = this.validateProfileForm(this.state.account);
    if (validateError.length > 0) {
      return;
    }

    let requestParams = {
      data: this.state.account,
      storeData: this.props.userSession
    }
    this.props.updateAccount(requestParams)
    this.setState({
      openAccount: false,
    })
  }

  allowedUrl = (path, type=null) => {
    if (!path.includes("client/dailySeasonalAdjustments/") && this.state.stopNavigation) {
      this.setState({
        allowUrl: true,
        allowedUrl: path
      })
    }
  }

  navigatePopup = (pathUrl) => {
    if (this.state.stopNavigation && this.state.allowUrl) {
      this.setState({
        allowUrl: false
      })
      this.props.showNavigationPopup(this.state.allowedUrl);
      return false;
    } else if (this.state.stopNavigation) {
      return false;
    } else {
      return pathUrl;
    }
  }

  handleParentMenu = (onlyIcon) => {
    const { t } = this.props;
    var parentMenu = [];
    const { classes } = this.props;
    let ClientId = this.props.hotelAuthReducer.hotelDetails.hotel.id;
    let reseller = this.props.hotelAuthReducer.hotelDetails.hotel.reseller;

    let siteLogo = "";

    if (ClientId && reseller && reseller.id == 9 && onlyIcon ) {      
      siteLogo = "/img/rsz_hoteliers_inspiration_logo_smallsidebar.png";
    } else if (ClientId && reseller && reseller.id == 9) {
      siteLogo = "/img/Hoteliers Inspiration logo.png";
    } else if (ClientId && reseller && reseller.id == 35 && onlyIcon ) {
      siteLogo = "/img/rsz_revparlogo.png";
    }else if (ClientId && reseller && reseller.id == 35) {
      siteLogo = "/img/revparlogo.png";
    }else if (onlyIcon){
      siteLogo = "/img/genie_logo.png";      
    } else if (ClientId) {
      siteLogo = "/img/roompricegenie_logo_new.svg";
      // siteLogo = "/img/revparlogo.png";
    }
    let calType = this.state.calendarRoom;
    let pathname = window.location.pathname;
    let is_admin = (this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) ? true : false;

    let showDashboard = (this.state.featuresEnabled.includes(20) && (([1, 24, 2, 15, 25, 3, 40, 20, 22, 70].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider)) || this.props.userSession.user.is_staff)) ? true : false;
    let showDashboardOnly = (this.state.featuresEnabled.includes(31)) ? true : false
    console.log(this.state.featuresEnabled,"feature")
    if ((this.props.hotelAuthReducer.hotelDetails.hotel.state == 9 && !is_admin) || (this.state.trialExpire || this.state.featuresEnabled.includes(19)) && this.props.hotelAuthReducer.hotelDetails.hotel.state != 8 && !is_admin) {
      parentMenu.push(
        <div key={1}>
          <div className={classes.toolbar} style={{marginTop: 0, background:"#fff"}} >
            <a href={is_admin ? '/Admin/Clients' : ('/client/Calendar/' + ClientId)}>
              <CardMedia
                className={(ClientId && reseller && reseller.id == 35) ? classes.siteLogo1 : ((ClientId && reseller && reseller.id == 9) ? classes.siteLogoHotelLiers:classes.siteLogo)}
                image={siteLogo}
                style={ (ClientId && reseller && reseller.id == 35  && onlyIcon ) ? {width:"40px", backgroundSize:"100%", margin:0, width:"90%"} : (ClientId && reseller && reseller.id == 9 && onlyIcon ) ? {width:"53px", backgroundSize:"100%", margin:0, width:"90%"} : onlyIcon ? {width:"40px",backgroundSize:"100%", margin:0, width:"90%"} : {backgroundSize:"100%", margin:0, width:"90%"}}
                title="Kanhasoft"
              />
            </a>
          </div>
          <div className={classes.hotelTitleList} >
            <Typography
              className={classes.selectHotel}
              variant="subtitle2"
              color="inherit"
              onClick={this.handleHotel}
              noWrap
            >
              {this.props.hotelAuthReducer.hotelDetails.hotel.name ? this.props.hotelAuthReducer.hotelDetails.hotel.name : '-'}
            </Typography>
            <Icon onClick={this.handleHotel} className={classes.hotelDrop}>arrow_drop_down</Icon>
          </div>
          <Divider />
          <MuiThemeProvider theme={SidebarListTheme}>
            <List className={classes.list}>
              <div key={1}>
                <div
                  tabIndex={0}
                  role="button"
                  onClick={this.closeDrawerToggle}
                  onKeyDown={this.closeDrawerToggle}
                >
                  <Link onClick={() => this.allowedUrl('/client/PaymentSetting/' + this.props.hotelAuthReducer.hotelDetails.hotel.id)} to={this.navigatePopup('/client/PaymentSetting/' + this.props.hotelAuthReducer.hotelDetails.hotel.id)} className={classes.pageUrl}>
                    <ListItem className={classes.active} button >
                      <Icon>credit_card</Icon>
                      <ListItemText inset primary={t('Subscription')} />
                    </ListItem>
                  </Link>
                  {this.state.featuresEnabled.includes(17) && this.props.hotelAuthReducer.hotelDetails.hotel.state != 9 &&
                    <Link onClick={() => this.allowedUrl('/client/UserSetting/' + this.props.hotelAuthReducer.hotelDetails.hotel.id)} to={this.navigatePopup('/client/UserSetting/' + this.props.hotelAuthReducer.hotelDetails.hotel.id)} className={classes.pageUrl}>
                      <ListItem className={classes.active} button >
                        <Icon>settings_input_antenna</Icon>
                        <ListItemText inset primary={t('Users')} />
                      </ListItem>
                    </Link>
                  }
                </div>
              </div>
            </List>
          </MuiThemeProvider>
        </div>
      );
      return parentMenu;
    }
    else {
      parentMenu.push(
        <div key={1}>
          <div className={classes.toolbar} style={{marginTop: 0, background:"#fff"}}>
            <a href={is_admin ? '/Admin/Clients' : ('/client/Calendar/' + ClientId)}>
              <CardMedia
                // className={classes.siteLogo}
                className={(ClientId && reseller && reseller.id == 35) ? classes.siteLogo1 : ((ClientId && reseller && reseller.id == 9) ? classes.siteLogoHotelLiers:classes.siteLogo)}                 
                image={siteLogo}
                title="Room Price Genie"
                style={ (ClientId && reseller && reseller.id == 35  && onlyIcon ) ? {width:"40px",backgroundSize:"100%", margin:0, width:"90%"} : (ClientId && reseller && reseller.id == 9 && onlyIcon ) ? {width:"53px",backgroundSize:"100%", margin:0, width:"90%"} : onlyIcon ? {width:"40px",backgroundSize:"100%", margin:0, width:"90%"} : {backgroundSize:"100%", margin:0, width:"90%"}}
              />
            </a>            
          </div>
          <Divider style={{background:'#ffffff2b'}}/>
          {!onlyIcon &&
          <div className={classes.hotelTitleList} >
            <Typography
              className={classes.selectHotel}
              variant="subtitle2"
              color="inherit"
              onClick={this.handleHotel}
              noWrap
            >
              {this.props.hotelAuthReducer.hotelDetails.hotel.name ? this.props.hotelAuthReducer.hotelDetails.hotel.name : '-'}
            </Typography>
            <Icon onClick={this.handleHotel} className={classes.hotelDrop}>arrow_drop_down</Icon>
          </div>
    }
    {onlyIcon &&
          <div className={classes.hotelTitleList} >            
            <Icon className={classes.smallSidebarIcon}>home</Icon>
            <Icon  className={classes.hotelDropWithOnlyIcon} >arrow_drop_down</Icon>
          </div>
    }
          <Divider style={{background:'#ffffff2b'}}/>
          <MuiThemeProvider theme={SidebarListTheme}>
            <List className={classes.list}>
              {ClientMenuList.map((val, index) => ((showDashboardOnly && val.to === "/client/PerformanceDashboard") || is_admin || !showDashboardOnly) && (
                <div key={index}>
                  {/* {val.to && (is_admin || (!is_admin && val.client)) && index != 0 && (index != 2 || is_report_on) && */}
                  {val.to && (is_admin || (!is_admin && val.client)) && (index != 1 || showDashboard) && ![2, 3, 4].includes(index) &&
                    <div
                      tabIndex={0}
                      role="button"
                      onClick={this.closeDrawerToggle}
                      onKeyDown={this.closeDrawerToggle}
                    >
                      <Link onClick={() => this.allowedUrl(val.to + '/' + ClientId, val.name)} to={this.navigatePopup(val.to + '/' + ClientId)} className={classes.pageUrl}>
                        {/* <ListItem disabled={!this.props.hotelAuthReducer.hotelDetails.token ? true : false} className={(pathname == (val.to + '/' + ClientId) && index != 0) ? classes.active : classes.listItem} button onClick={() => { index != 0 ? this.setState({ childIndex: index }) : (this.state.childIndex == 0 ? this.setState({ childIndex: -1 }) : this.setState({ childIndex: 0 })); this.changeCalendarRoom(null); }}> */}
                        <ListItem disabled={!this.props.hotelAuthReducer.hotelDetails.token ? true : false} className={(pathname == (val.to + '/' + ClientId) && index != 2) ? classes.active : classes.listItem} button onClick={() => { index != 2 ? this.setState({ childIndex: index }) : (this.state.childIndex == 0 ? this.setState({ childIndex: -1 }) : this.setState({ childIndex: 0 })); this.changeCalendarRoom(null); }}>
                          <Icon>{val.icon}</Icon>
                          { index == 1 ?
                              !onlyIcon ? Badge.badgeSimpleTextMenu(this,"Dashboard",index) :""
                            :                             
                            !onlyIcon ? <ListItemText inset primary={val.label} /> :""
                          }
                          
                          {!onlyIcon && val.children && (this.state.childIndex === index ? <ExpandLess className={classes.exppand} /> : <ExpandMore className={classes.exppand} />)}
                        </ListItem>
                      </Link>
                    </div>
                  }

                  {val.to && (is_admin || (!is_admin && val.client)) && [2, 3, 4].includes(index) &&
                    <div
                      tabIndex={0}
                      role="button"
                      onClick={this.closeDrawerToggle}
                      onKeyDown={this.closeDrawerToggle}
                    >
                      <Link onClick={() => this.allowedUrl(val.to + '/' + ClientId, val.name)} to={this.navigatePopup(val.to + '/' + ClientId)} className={classes.pageUrl}>
                        <ListItem
                          disabled={!this.props.hotelAuthReducer.hotelDetails.token ? true : false}
                          className={(((index == 2 && (calType == "pickUp" || calType == "calendar" || calType == "adjustment")) || (index == 3 && calType == "priceGraph")) && pathname == (val.to + '/' + ClientId)) ? classes.active : classes.listItem}
                          button
                          onClick={() => this.changeCalendarRoom(val.type)}>
                          <Icon style={{ fontSize: "24px" }}>{val.icon}</Icon>
                          {( index == 3) ?                             
                            !onlyIcon ? Badge.badgeSimpleTextMenu(this,"Chart", index ) : ""
                            :
                            !onlyIcon ? <ListItemText inset primary={val.label} /> :""
                          }
                          {/* {!onlyIcon ? <ListItemText inset primary={val.label} /> :""} */}
                          {!onlyIcon && val.children && (this.state.childIndex === index ? <ExpandLess className={classes.exppand} /> : <ExpandMore className={classes.exppand} />)}
                        </ListItem>
                      </Link>
                    </div>
                  }

                  {/* {(index == 2 && this.state.featuresEnabled.includes(1)) &&
                    <Link onClick={() => this.allowedUrl(val.to + '/' + ClientId)} to={this.navigatePopup(val.to + '/' + ClientId)} className={classes.pageUrl}>
                      <ListItem className={this.state.isBulk ? classes.active : classes.listItem} onClick={() => this.changeBulkSetting()}>
                        <Icon>{val.icon}</Icon>
                        <ListItemText inset primary={val.label} className={(val.is_reference_room) ? classes.refRoomFont : ""} />
                      </ListItem>
                    </Link>
                  } */}
                  {/* {(!val.to || index == 0) && (is_admin || (!is_admin && val.client)) && */}

                  {(!val.to) && (is_admin || (!is_admin && val.client)) &&
                    <ListItem button className={classes.listItem} onClick={() => { this.state.childIndex === index ? this.setState({ childIndex: -1 }) : this.setState({ childIndex: index }) }}>
                      <Icon>{val.icon}</Icon>
                      {!onlyIcon ? <ListItemText inset primary={val.label} /> :""}
                      {!onlyIcon && val.children && (this.state.childIndex === index ? <ExpandLess className={classes.exppand} /> : <ExpandMore className={classes.exppand} />)}
                      {/* {index == 0 && ((pathname == (val.to + '/' + ClientId) && this.state.childIndex === index) ? <ExpandLess className={classes.exppand} /> : <ExpandMore className={classes.exppand} />)} */}
                    </ListItem>}
                  {this.handleChildMenu(val.children, index, onlyIcon)}
                </div>
              ))}
            </List>
          </MuiThemeProvider>
        </div>
      );
      return parentMenu;
    }
  }

  handleChildMenu = (children, parent_index, onlyIcon) => {
    var jsx = [];
    const { classes } = this.props;
    let pathname = window.location.pathname;
    let ClientId = this.props.hotelAuthReducer.hotelDetails.hotel.id;
    let access_level = this.props.hotelAuthReducer.hotelDetails.access_level;
    // let hideSubscription = (this.props.hotelAuthReducer.hotelDetails.hotel.reseller) ? false : true;
    // let isSeekom = this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 24 ? true : false;
    // let isRessellerHotel = this.props.hotelAuthReducer.hotelDetails.hotel.reseller ? true : false;
    // let isProtel = this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 15 ? true : false;
    let is_admin = (this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) ? true : false;
    let only_admin = this.props.userSession.user.is_staff ? true : false;
    
    if (parent_index == 2 && this.props.roomList.roomList && this.props.priceSettingReducer && this.props.priceSettingReducer.priceSetting) {

      // this.props.roomList.roomList.sort(function (a, b) {
      //   var aRef = Number(a.is_reference_room);
      //   var bRef = Number(b.is_reference_room);
      //   return (aRef > bRef ? -1 : 1)
      // });

      // let allPricing = this.props.priceSettingReducer.priceSetting.hotel.all_room_pricing;

      // this.props.roomList.roomList.filter(val => allPricing ? (val.pms_rate || val.is_reference_room == true || ([17, 8, 35, 39].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider))) : val.is_reference_room == true).map((child, index) => {
      //   jsx.push(
      //     <Collapse in={this.state.childIndex === parent_index} timeout="auto" unmountOnExit key={index}>
      //       <List component="div" disablePadding>
      //         <div
      //           tabIndex={0}
      //           role="button"
      //           onClick={this.closeDrawerToggle}
      //           onKeyDown={this.closeDrawerToggle}
      //         >
      //           <Link onClick={() => this.allowedUrl(val.to + '/' + ClientId)} to={this.navigatePopup(val.to + '/' + ClientId)} className={classes.pageUrl}>
      //             <ListItem style={{ marginLeft: '20px' }} className={(this.state.calendarRoom == child.id && !this.state.isBulk) ? classes.active : classes.listItem} onClick={() => this.changeCalendarRoom(child, val.to + '/' + ClientId)}>
      //               <Icon>chevron_right</Icon>
      //               <ListItemText inset primary={child.name} className={(child.is_reference_room) ? classes.refRoomFont : ""} />
      //             </ListItem>
      //           </Link>
      //         </div>
      //       </List>
      //     </Collapse>
      //   )
      // })
    }

    if (children) {
      const { t } = this.props;
      children.map((child, index) => {
        let chile_menu_name = ""
        if(child.hasOwnProperty("is_room_setup")){
          chile_menu_name = t(`${this.state.room_apartment_space_name}s Setup`)
        } else {
          chile_menu_name = child.label
        }
        if (is_admin || (!is_admin && child.client) || (!is_admin && child.onlyClient && !access_level)) {
          // if (parent_index != 4 || (index == 3 || index == 5 || (index == 4 && this.state.featuresEnabled.includes(7)) || ((index == 1 || index == 2) && !this.state.featuresEnabled.includes(1)) || (index == 0 && this.state.featuresEnabled.includes(1)))) {

          if ((parent_index != 7 || index == 0 || index == 1 || index == 2 || index == 3 || (index == 4 && (this.state.featuresEnabled.includes(25)))) && (parent_index != 6 || index == 0 || index == 1 || index == 2  || (index == 3 && (this.state.featuresEnabled.includes(12) || is_admin)) || (index == 4 && this.state.featuresEnabled.includes(23)) || (index == 5 && this.state.featuresEnabled.includes(9)) || (index == 6 && (this.state.featuresEnabled.includes(7))) || (index == 7 && this.state.featuresEnabled.includes(13)) || index == 8 || (index == 9 && this.state.featuresEnabled.includes(16)) || (index == 10 && this.state.featuresEnabled.includes(32)))) {
            if (parent_index == 7 && index == 0) {
              jsx.push(
                <Collapse in={this.state.childIndex === parent_index} timeout="auto" unmountOnExit key={index}>
                  <List component="div" disablePadding>
                    <div
                      tabIndex={0}
                      role="button"
                      onClick={this.closeDrawerToggle}
                      onKeyDown={this.closeDrawerToggle}
                    >
                      <Link onClick={() => this.allowedUrl(child.to + '/' + ClientId, child.name)} to={this.navigatePopup(child.to + '/' + ClientId)} className={classes.pageUrl} >
                        <ListItem onClick={() => this.changeBulkSetting()} style={onlyIcon ? { marginLeft:  '10px', paddingRight: "0px", paddingLeft: "8px" } : {marginLeft: '20px'}} className={pathname == (child.to + '/' + ClientId) ? classes.active : classes.listItem}>
                          <Icon>{child.icon}</Icon>
                      
                          { !onlyIcon ? <ListItemText inset primary={chile_menu_name} /> : ""}
                        </ListItem>
                      </Link>
                    </div>
                  </List>
                </Collapse>
              )
            } else if (parent_index != 8 || (index == 0 || index == 2 || (index != 0 && only_admin) )){
              jsx.push(
                <Collapse in={this.state.childIndex === parent_index} timeout="auto" unmountOnExit key={index}>
                  <List component="div" disablePadding>
                    <div
                      tabIndex={0}
                      role="button"
                      onClick={this.closeDrawerToggle}
                      onKeyDown={this.closeDrawerToggle}
                    >
                      <Link onClick={() => this.allowedUrl(child.to + '/' + ClientId, child.name)} to={this.navigatePopup(child.to + '/' + ClientId)} className={classes.pageUrl}>
                        <ListItem onClick={() => this.changeCalendarRoom(null)} disabled={!this.props.hotelAuthReducer.hotelDetails.token ? true : false} button className={pathname == (child.to + '/' + ClientId) ? classes.active : classes.listItem} style={onlyIcon ? { marginLeft:  '10px', paddingRight: "0px", paddingLeft: "8px" } : {marginLeft: '20px'}}>
                          <Icon >{child.icon}</Icon>
                          {index==10 ?
                            !onlyIcon ? Badge.badgeChildText(this,"Segmentation", index ) : ""
                          :
                            (index == 4 && parent_index==7) ?
                            !onlyIcon ? Badge.badgeChildText(this,"Schedule Report", index ) : ""
                            :(index == 5 && parent_index==6) ?
                            !onlyIcon ? Badge.badgeChildText(this,"Yielding Tags", index ) : ""
                            : (index == 9 && parent_index==6) ?
                            !onlyIcon ? Badge.badgeChildText(this,"OTA Setup", index ) : ""
                            :
                          !onlyIcon ? <ListItemText inset primary={chile_menu_name} /> : ""
                        }
                        </ListItem>
                      </Link>
                    </div>
                  </List>
                </Collapse>
              )

            }
          }
        }
      }
      )
    }
    return jsx;
  };


  changeCalendarRoom = (roomData, pathUrl) => {
    this.setState({
      isBulk: false,
      calendarRoom: roomData
    })
    this.props.setCalendarRoom(roomData)
    // if (roomData && !this.state.stopNavigation) {
    //   this.setState({
    //     isBulk: false,
    //     calendarRoom: roomData.id
    //   })
    //   this.props.setCalendarRoom(roomData)
    // } else if (roomData) {
    //   this.setState({
    //     isBulk: false,
    //     calendarRoom: roomData.id
    //   })
    //   this.props.setCalendarRoom(roomData)
    //   if (this.state.stopNavigation && pathUrl) {
    //     this.props.showNavigationPopup("calendar");
    //   }
    // } else {
    //   this.setState({
    //     isBulk: false,
    //     calendarRoom: null
    //   })
    // }
  }

  changeBulkSetting = () => {
    this.props.setCalendarRoom("calendar")
    this.setState({
      isBulk: false,
      calendarRoom: "calendar"
    })
  }

  changeLanguage = (lng) => {
    const { t, i18n } = this.props;
    i18n.changeLanguage(lng);
    this.setState({ language: lng, anchorEl: null })
    this.handleCloseLng();
    if (lng) {
      let requestParams = {
        data: {
          ...this.state.account,
          language: lng
        },
        storeData: this.props.userSession
      }
      this.props.updateAccount(requestParams)
    }

  };

  handleOpenForm = () => {
    this.setState({
      openForm: true
    })
  }

  handleCloseForm = () => {
    this.setState({
      openForm: false
    })
  }

  toggleClientView = (value) => {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      is_client_view: value,
      hotel_id: this.props.hotelAuthReducer.hotelDetails.hotel.id
  }
  this.props.updateHotelView(requestParam);
    // window.location.reload();
  }
  

  bottomMenu = (onlyIcon) => {
    const { t } = this.props;
    const { classes } = this.props;

    const { anchorEl, anchorhint } = this.state;
    const open = this.state.changeLng ? false : Boolean(anchorEl);
    return <div style={onlyIcon ? { width: "58px", overflowY:"overlay" } : {}} className={[0, 3, 2, 7].includes(this.props.hotelAuthReducer.hotelDetails.hotel.state) ? classes.referBtndiv : classes.referBtndiv2}>
      <Divider style={{ background: '#ffffff2b' }} />
      {this.props.hotelAuthReducer.hotelDetails.hotel.state != 9 &&
        <MuiThemeProvider theme={SidebarListTheme}>
          <List className={classes.list} style={{ paddingTop: "0px", paddingBottom: "0px"}}>
            <div key={1}>
              <div
                tabIndex={0}
                role="button"
              // onClick={this.closeDrawerToggle}
              // onKeyDown={this.closeDrawerToggle}
              >

                <Link onClick={() => { this.allowedUrl('/client/Community/' + this.props.hotelAuthReducer.hotelDetails.hotel.id); this.setState({ urlPath: window.location.pathname }) }} className={[classes.listItem, classes.listItem1]} to={this.navigatePopup('/client/Community/' + this.props.hotelAuthReducer.hotelDetails.hotel.id)} className={classes.pageUrl}>
                  <ListItem className={[window.location.pathname == ('/client/Community/' + this.props.hotelAuthReducer.hotelDetails.hotel.id) ? classes.active : classes.listItem]} button >
                    <Icon>group</Icon>
                    {!onlyIcon && <ListItemText inset primary={<>{t('Refer a Friend')}
                      {/* <Button style={{float:'right',color:'white',padding:'2px 14px',minWidth:'0px',textTransform: 'capitalize',boxShadow:'0 0 white',background:'#eda942'}} variant="contained"  size="small">New</Button>                               */}
                    </>
                    } />}
                  </ListItem>
                </Link>
              </div>
            </div>
          </List>
        </MuiThemeProvider>
      }

      <MuiThemeProvider theme={SidebarListTheme}>
        <List className={classes.list} style={{ paddingTop: "0px", paddingBottom: "0px", overflow: "hidden" }}>
          <div key={1}>
            <div
              tabIndex={0}
              role="button"
            // onClick={this.closeDrawerToggle}
            // onKeyDown={this.closeDrawerToggle}
            >

              {/* <Link onClick={() => { this.allowedUrl('/client/whatsnew/' + this.props.hotelAuthReducer.hotelDetails.hotel.id); this.setState({urlPath:window.location.pathname}) }} className={[classes.listItem, classes.listItem1]} to={this.navigatePopup('/client/whatsnew/' + this.props.hotelAuthReducer.hotelDetails.hotel.id)} className={classes.pageUrl}> */}
              <ListItem className={["beamerTrigger", classes.listItem, classes.listItem1]} button >
                <Icon>card_giftcard</Icon>
                {/* <AnnounceKit widget="https://announcekit.co/widgets/v2/4rZkmk"> */}
                {!onlyIcon && <ListItemText inset primary={t("What's New")} />}
                {/* <span className={[classes.whatsNew]}  >{t("What's New")}</span> */}
                {/* </AnnounceKit> */}
              </ListItem>
              {/* </Link> */}
            </div>
          </div>
        </List>
      </MuiThemeProvider>

      <MuiThemeProvider theme={SidebarListTheme}>
        <List className={classes.list} style={{ paddingTop: "0px", paddingBottom: "0px", overflow: "hidden" }}>
          <div key={1}>
            <div
              tabIndex={0}
              role="button"
              onClick={this.handlehintMenu}
            // onKeyDown={this.closeDrawerToggle}
            >
              {/* <a href="https://support.roompricegenie.com/" target="_blank" style={{ textDecoration: "none" }}> */}
              <ListItem className={[classes.listItem, classes.listItem1]} button >
                <Icon>live_help</Icon>
                {!onlyIcon && <ListItemText inset primary={<>{t('Help')}
                  {/* <span style={{ background: "rgb(84, 19, 136)", color: "white", padding: "2px 8px", borderRadius: "5px", color: "#fff", fontWeight: "500" }}>{t('New')}</span>  */}
                </>} />}
              </ListItem>
              {/* </a> */}
            </div>
          </div>
        </List>
      </MuiThemeProvider>

      <div className={classes.helpIconDiv}>
        <MuiThemeProvider theme={SidebarMUIIconBtn}>

          <IconButton
            aria-owns={open ? 'menu-appbar' : undefined}
            aria-haspopup="true"
            onClick={this.handleMenu}
            className={[classes.userIcon, classes.userIconColor]}
            disableRipple
          >
            <AccountCircle />
          </IconButton>
        </MuiThemeProvider>
        {!onlyIcon && <Typography
          className={classes.emailText}
          variant="body1"
          onClick={this.handleMenu}
          style={{ cursor: "pointer" }}
          noWrap
        >
          {this.state.account.email}
        </Typography>}
      </div>
    </div>
  }

  render() {
    const { t } = this.props;
    const openForm = this.state.openForm;

    const { classes } = this.props;
    const { anchorEl, anchorhint } = this.state;
    const { fullScreen } = this.props;
    const openhint = Boolean(anchorhint);
    const open = this.state.changeLng ? false : Boolean(anchorEl);
    const { anchorEh } = this.state;
    const { anchorEg } = this.state;
    const Lngopen = Boolean(anchorEg);
    const Hotelopen = Boolean(anchorEh);
    let is_admin = (this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) ? true : false;
    const theme = Sidebartheme;
    const MUIIconBtn = SidebarMUIIconBtn;


    if (!this.state.loadApp) {
      return (
        <div className={classes.MainLoader}>
          <div className={classes.loader}>
            <Loader
              height="100"
              width="100"
            />
          </div>
        </div>
      )
    }
    if( this.state.featuresEnabled.includes(19) && !is_admin ){
      return(
        // {this.props.userSession.user.isClientView &&
        //   <MenuItem onClick={() => this.toggleClientView(false)}> {t('Admin View')} </MenuItem>
        // } 
        <div><PreparingAccount  /></div>
      )
    }
    // if (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider && this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 12 && this.props.hotelAuthReducer.hotelDetails.do_not_call && this.props.userSession.user.is_staff == false && this.props.userSession.user.sub_account_type == null) {
    // if (this.props.hotelAuthReducer.hotelDetails.hotel.state == 0 && this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider && this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 12 && this.state.featuresEnabled.includes(19) && this.props.userSession.user.is_staff == false && this.props.userSession.user.sub_account_type == null && !this.state.isFinished) {
    //   return ( 
    //     <MuiThemeProvider theme={Sidebartheme}>
    //       {/* <main className={classes.content} style={{ maxWidth: "800px" }}>
    //         <QuickSetup />
    //       </main> */}
    //       <div className="mainConentBody">
    //         <CssBaseline />
    //         <AppBar position="fixed" className={classes.appBar}>
    //           <Toolbar>
    //             <IconButton
    //               color="inherit"
    //               aria-label="Open drawer"
    //               onClick={this.handleDrawerToggle}
    //               className={classes.menuButton}
    //             >
    //               <MenuIcon />
    //             </IconButton>
    //             <Grid container className={classes.gridContainer}>
    //               <Grid item lg={9} className={classes.gridHotelSelect}>

    //               </Grid>

    //               <Grid item lg={3} className={classes.gridLng}>
    //                 <Typography
    //                   className={classes.emailText}
    //                   variant="subtitle2"
    //                   color="inherit"
    //                   noWrap
    //                 >
    //                   {this.state.account.email}
    //                 </Typography>
    //               </Grid>
    //             </Grid>

    //             <div className={classes.profileBtn}>
    //             <MuiThemeProvider theme={SidebarMUIIconBtn}>

    //               <IconButton
    //                 aria-owns={open ? 'menu-appbar' : undefined}
    //                 aria-haspopup="true"
    //                 onClick={this.handleMenu}
    //                 classes={classes.userIconColor}
    //               >
    //                 <AccountCircle />
    //               </IconButton>
    //             </MuiThemeProvider>
    //               <Menu
    //                 style={{ float: 'right' }}
    //                 id="menu-appbar"
    //                 anchorEl={anchorEl}
    //                 anchorOrigin={{
    //                   vertical: 'top',
    //                   horizontal: 'right',
    //                 }}
    //                 transformOrigin={{
    //                   vertical: 'top',
    //                   horizontal: 'right',
    //                 }}
    //                 open={open}
    //                 onClose={this.handleCloseMenu}
    //               >
    //                 <MenuItem onClick={this.handleLogout}>{t('Logout')}</MenuItem>
    //               </Menu>
    //             </div>
    //           </Toolbar>
    //         </AppBar>
    //         {/* <nav className={classes.drawer} id="sideBar">

    //         <Hidden smUp implementation="css">
    //           <SwipeableDrawer
    //             container={this.props.container}
    //             variant="temporary"
    //             anchor={theme.direction === 'rtl' ? 'right' : 'left'}
    //             open={this.state.mobileOpen}
    //             onOpen={() => false}
    //             onClose={this.closeDrawerToggle}
    //             classes={{
    //               paper: classes.drawerPaper,
    //             }}
    //           >
    //             <div className={classes.toolbar} >
    //               <CardMedia
    //                 className={classes.siteLogo}
    //                 image="/img/roompricegenie_logo.png"
    //                 title="Room Price Genie"
    //               />
    //             </div>
    //             <List className={classes.list}>
    //               <ListItem button className={classes.active}>
    //                 <Icon color="action">how_to_reg</Icon>
    //                 <ListItemText inset primary={'Sign Up'} />
    //               </ListItem>
    //             </List>
    //           </SwipeableDrawer>
    //         </Hidden>
    //         <Hidden xsDown implementation="css">
    //           <SwipeableDrawer
    //             classes={{
    //               paper: classes.drawerPaper,
    //             }}
    //             variant="permanent"
    //             open
    //             onOpen={() => false}
    //             onClose={this.closeDrawerToggle}
    //           >
    //             <div className={classes.toolbar} >
    //               <CardMedia
    //                 className={classes.siteLogo}
    //                 image="/img/roompricegenie_logo.png"
    //                 title="Room Price Genie"
    //               />
    //             </div>
    //             <List className={classes.list}>
    //               <ListItem button className={classes.active}>
    //                 <Icon color="action">how_to_reg</Icon>
    //                 <ListItemText inset primary={'Sign Up'} />
    //               </ListItem>
    //             </List>
    //             <div className={classes.referBtndiv}>
    //               <div className={classes.helpIconDiv}>
    //                 <IconButton
    //                   aria-owns={open ? 'menu-appbar' : undefined}
    //                   aria-haspopup="true"
    //                   onClick={this.handleMenu}
    //                   className={classes.userIcon}
    //                 >
    //                   <AccountCircle className={classes.userIconColor} />
    //                 </IconButton>

    //                 <Typography
    //                   className={classes.emailText}
    //                   variant="body1"
    //                   onClick={this.handleMenu}
    //                   style={{ cursor: "pointer" }}
    //                   noWrap
    //                 >
    //                   {this.state.account.email}
    //                 </Typography>
    //               </div>


    //             </div>
    //           </SwipeableDrawer>
    //         </Hidden>
    //         </nav> */}
    //         {!this.props.hotelAuthReducer.hotelDetails.token &&
    //           <div className={classes.MainLoader}>
    //             <div className={classes.loader}>
    //               <Loader
    //                 height="100"
    //                 width="100"
    //               />
    //             </div>
    //           </div>
    //         }
    //         {this.state.warning &&
    //           <Snackbar
    //             anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    //             onClose={this.toggleWarning}
    //             open={this.state.warning}
    //             TransitionComponent={Fade}
    //             ContentProps={{
    //               classes: {
    //                 root: this.state.snackbarSuccess ? classes.sccessBar : classes.warningBar
    //               }
    //             }}
    //             message={<span style={{ textTransform: 'capitalize' }}><Icon className={classes.warningIcon}>{(this.state.snackbarSuccess) ? 'check_circle' : 'error'}</Icon> {t(this.state.snackbarMsg)}</span>}
    //             variant="success"
    //           />
    //         }
    //         <main className={classes.content} style={{ maxWidth: "885px", margin: "auto" }}>
    //           {this.props.hotelAuthReducer.hotelDetails.token &&
    //             <QuickSetup />
    //           }
    //         </main>
    //       </div>
    //     </MuiThemeProvider>
    //   )
    // }

    return (
      <MuiThemeProvider theme={Sidebartheme}>
        <Router>
          <div className="mainConentBody">
            {

            this.state.drawerOpen && !this.state.drawerOpenAddcss
              ? <IconButton
                className={[classes.slideIcon,classes.hideOnMobileIcon,'sidebarIconDelay']}
                color="inherit"
                aria-label="Open drawer"
                onClick={this.toggleDrawerOpen}
                onMouseEnter={this.enterDrawerOpen}   
                onMouseLeave={this.enterDrawerClose}            
              >
                <Icon className={classes.slideLeftIcon}>chevron_right</Icon>
              </IconButton>
              :
              this.state.drawerOpen &&  this.state.drawerOpenAddcss && this.state.drawerArrowButtom 
              ?
              <IconButton
                className={[classes.slideIcon,classes.hideOnMobileIcon]}
                color="inherit"
                aria-label="Open drawer"
                // onClick={this.toggleDrawerOpen}
                onMouseEnter={this.handleDrawerHideShowArrowButton}   
                onClick={this.enterDrawerClose}            
              >
                <Icon className={classes.slideLeftIcon}>chevron_left</Icon>
              </IconButton>
              : ''
            }
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar} >
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>

                <Grid container className={classes.gridContainer}>
                  {/* <Grid item lg={6} className={classes.gridHotelSelect}>
                    <Typography
                      className={classes.selectHotel}
                      onClick={this.handleHotel}
                      variant="subtitle2"
                      color="inherit"
                      noWrap
                    >
                      {this.props.hotelAuthReducer.hotelDetails.hotel.name ? this.props.hotelAuthReducer.hotelDetails.hotel.name : '-'}<Icon className={classes.hotelDrop}>arrow_drop_down</Icon>
                    </Typography>
                  </Grid> */}

                  <Grid item lg={12} className={classes.gridLngmenu}>
                  <MuiThemeProvider theme={SidebarMUIIconBtn}>

                    <IconButton
                      aria-owns={open ? 'menu-appbar' : undefined}
                      aria-haspopup="true"
                      onClick={this.handleMenu}
                      classes={classes.userIconColor}
                    >
                      <AccountCircle  />
                    </IconButton>
                  </MuiThemeProvider>
                    <Typography
                      className={classes.emailText}
                      variant="subtitle2"
                      color="inherit"
                      noWrap
                    >
                      {this.state.account.email}
                    </Typography>
                  </Grid>
                </Grid>



                {this.props.hotelAuthReducer.hotelDetails.hotel_list &&
                  <Menu
                    style={{ float: 'right' }}
                    id="menu-appbar1"
                    anchorEl={anchorEg}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Lngopen}
                    onClose={this.handleCloseLng}
                  >
                    <FormControl component="fieldset" className={classes.languageSelect} >
                      <RadioGroup aria-label="Language" value={this.state.language}>
                        <FormControlLabel className={classes.languageMenu} value={'en-US'} label="American English" control={<Radio color="primary" />} onClick={() => this.changeLanguage('en-US')} />
                        <FormControlLabel className={classes.languageMenu} value={'en-GB'} label="British English" control={<Radio color="primary" />} onClick={() => this.changeLanguage('en-GB')} />
                        <FormControlLabel className={classes.languageMenu} value={'de'} label="German" control={<Radio color="primary"/>} onClick={() => this.changeLanguage('de')} />
                        {/* <FormControlLabel className={classes.languageMenu} value={'ja'} label="Japanese" control={<Radio color="primary" />} onClick={() => this.changeLanguage('ja')} /> */}
                        <FormControlLabel className={classes.languageMenu} value={'fr-FR'} label="French" control={<Radio color="primary"/>} onClick={() => this.changeLanguage('fr-FR')} />
                        <FormControlLabel className={classes.languageMenu} value={'es-ES'} label="Spanish" control={<Radio color="primary"/>} onClick={() => this.changeLanguage('es-ES')} />
                        <FormControlLabel className={classes.languageMenu} value={'it'} label="Italian" control={<Radio color="primary"/>} onClick={() => this.changeLanguage('it')} />                        
                      </RadioGroup>
                    </FormControl>
                  </Menu>
                }

                {this.props.hotelAuthReducer.hotelDetails.hotel_list &&
                  <Menu
                    id="menu-appbar1"
                    anchorEl={anchorEh}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Hotelopen}
                    onClose={this.handleClosehotel}
                  >
                    {this.props.hotelAuthReducer.hotelDetails.hotel_list.sort((a, b) => {
                      if (a.name < b.name) { return -1; }
                      if (a.name > b.name) { return 1; }
                      return 0;
                    }).map((val, index) => (
                      <MenuItem className={classes.hotelLists} key={index} onClick={() => this.changeHotel(index)} selected={(this.props.hotelAuthReducer.hotelDetails.hotel.id === val.id)}>{val.name}</MenuItem>
                    ))}
                  </Menu>
                }
                <div className={classes.profileBtn}>
                  <Menu
                    style={{ float: 'right' }}
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={open}
                    onClose={this.handleCloseMenu}
                  >
                    {(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) &&
                      <MenuItem onClick={() => this.toggleClientView(true)}> {t('Client View')} </MenuItem>
                    } 
                    {this.props.userSession.user.isClientView &&
                      <MenuItem onClick={() => this.toggleClientView(false)}> {t('Admin View')} </MenuItem>
                    } 
                    <MenuItem onClick={this.handleLngPopup}> {t('Language')} </MenuItem>
                    <MenuItem onClick={this.handleUserProfile}>{t('My Account')}</MenuItem>
                    <MenuItem onClick={this.togglePassword}>{t('Change Password')}</MenuItem>
                    <MenuItem onClick={this.handleLogout}>{t('Logout')}</MenuItem>
                  </Menu>
                </div>

                {this.props.hotelAuthReducer.hotelDetails.hotel_list &&
                  <Menu
                    id="menu-appbar1"
                    anchorEl={anchorEh}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Hotelopen}
                    onClose={this.handleClosehotel}
                  >
                    {this.props.hotelAuthReducer.hotelDetails.hotel_list.sort((a, b) => {
                      if (a.name < b.name) { return -1; }
                      if (a.name > b.name) { return 1; }
                      return 0;
                    }).map((val, index) => (
                      <MenuItem className={classes.hotelLists} key={index} onClick={() => this.changeHotel(index)} selected={(this.props.hotelAuthReducer.hotelDetails.hotel.id === val.id)}>{val.name}</MenuItem>
                    ))}
                  </Menu>
                }
                <div className={classes.profileBtn}>

                  {/* <Dialog
                    fullScreen={fullScreen}
                    maxWidth='md'
                    open={openhint}
                    onClose={this.handleCloseMenu}
                    aria-labelledby="responsive-dialog-title"
                  >

                    <IconButton aria-label="Close" onClick={this.handleCloseMenu} style={{ position: "absolute", right: "0" }}>
                      <CloseIcon />
                    </IconButton>

                    {this.state.language == "de" ?

                      
                      <Grid container>
                        <Grid item md={6} sm={12} style={{ paddingTop: "16px" }}>
                          <Typography variant={"h5"} style={{ paddingLeft: "16px" }}>App Walk-Throughs</Typography>
                          <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + this.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=9fa928c9-8cf6-413d-8cee-8b418e729b00"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={this.handleCloseMenu}>{t('In-App Anleitung 0: Neuerungen erkunden')} </MenuItem></a>
                          <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + this.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=10b1eecd-ccc1-4f4a-9371-bb087d9a5fcf"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={this.handleCloseMenu}>{t('In-App Anleitung 1: Überblick Preiskalender')}</MenuItem></a>
                          <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + this.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=0c22b60e-012a-4f59-9529-f727306ab919"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={this.handleCloseMenu}>{t('In-App Anleitung 2: Preisempfehlungen verstehen')}</MenuItem></a>
                          <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + this.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=d339c03c-5b62-4bc4-b9d6-08d77805b2b8"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={this.handleCloseMenu}>{t('In-App Anleitung 3: Auslastung & Performance verstehen')}</MenuItem></a>
                          <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + this.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=2cc08288-c2bd-489d-8ef6-9c5eefe191c2"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={this.handleCloseMenu}>{t('In-App Anleitung 4: Zimmer Setup bearbeiten')} </MenuItem></a>
                          <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + this.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=10ef0bd9-bdc0-457a-ad7c-0a311cfc9fb1"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={this.handleCloseMenu}>{t('In-App Anleitung 5: Preisstrategie definieren')}</MenuItem></a>
                          <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + this.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=c218c17c-54fe-4af1-b1b1-a34fe39522c6"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={this.handleCloseMenu}>{t('In-App Anleitung 6: Preise anpassen')}</MenuItem></a>
                        </Grid>
                        <Grid item md={6} sm={12} style={{ paddingLeft: "16px" }}>
                          <Typography variant={"h5"} href="https://support.roompricegenie.com/" style={{ marginTop: "16px" }}>Help Center</Typography>
                          <Button variant={"contained"} color={"primary"} style={{ margin: "16px 16px 8px 0px" }} href={"https://support.roompricegenie.com"} target={"_blank"}>Go to Help Center</Button>
                          <Button variant={"outlined"} color={"primary"} style={{ marginTop: "10px" }} onClick={this.handleOpenForm}>Create a Support Ticket</Button>
                          <Typography>Frequently asked questions:</Typography>
                          <Typography style={{ marginTop: "24px" }}><a class="btn btn-primary btn-sm" href={"https://support.roompricegenie.com/how-it-works/"}>1. How does RoomPriceGenie calculate prices? </a></Typography>
                          <Typography style={{ marginTop: "24px" }}><a class="btn btn-primary btn-sm" href={"https://support.roompricegenie.com/how-do-i-adjust-my-base-price-and-what-happens-then/"}>2. How do I adjust my base price and what happens then? </a></Typography>
                          <Typography style={{ marginTop: "24px" }}><a class="btn btn-primary btn-sm" href={"https://support.roompricegenie.com/how-to-set-my-min-and-max-prices-and-my-variable-costs/"}>3. How to set my minimum/maximum prices and variable costs? </a></Typography>
                          <Typography style={{ marginTop: "24px" }}><a class="btn btn-primary btn-sm" href={"https://support.roompricegenie.com/how-do-i-manually-change-prices-for-consecutive-days/"}>4. How do I change prices for a longer period? </a></Typography>
                          <Typography style={{ marginTop: "24px" }}><a class="btn btn-primary btn-sm" href={"https://support.roompricegenie.com/closed-rooms/"}>5. How does the feature „Closed Rooms“ works? </a></Typography>
                        </Grid>
                      </Grid>

                      
                      :
                      <Grid container>
                        <Grid item md={6} sm={12} style={{ paddingTop: "16px" }}>
                          <Typography variant={"h5"} style={{ paddingLeft: "16px" }}>App Walk-Throughs</Typography>
                          <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + this.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=-MA6ZXrFxd9I8eYeGUee"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={this.handleCloseMenu}>{t('Walkthrough 0: Navigate through the new app')} </MenuItem></a>
                          <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + this.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=-M5gCyE0m_AvthflMFt3"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={this.handleCloseMenu}>{t('Walkthrough 1: Get an overview of the price calendar')}</MenuItem></a>
                          <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + this.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=-M5uODUX66Yo6DCzQI7Q"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={this.handleCloseMenu}>{t('Walkthrough 2: Understand our price recommendations')}</MenuItem></a>
                          <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + this.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=-M6TgDNoaunYRaVMm-Y3"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={this.handleCloseMenu}>{t('Walkthrough 3: Check your occupancy performance')}</MenuItem></a>
                          <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + this.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=-M6U76Rtf2-9oT1_lMP8"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={this.handleCloseMenu}>{t('Walkthrough 4: Edit room setup')} </MenuItem></a>
                          <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + this.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=-M6U7cblaJaxCoEsU3IC"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={this.handleCloseMenu}>{t('Walkthrough 5: Define your pricing strategy')}</MenuItem></a>
                          <a class="btn btn-primary btn-sm" href={"/client/Calendar/" + this.props.hotelAuthReducer.hotelDetails.hotel.id + "?appcue=-MA6vA3ueZ09fI76nCaa"} style={{ textDecoration: "none", color: "#000000de" }}><MenuItem onClick={this.handleCloseMenu}>{t('Walkthrough 6: Adjust your prices')}</MenuItem></a>


                        </Grid>
                        <Grid item md={6} sm={12} style={{ paddingLeft: "16px" }}>
                          <Typography variant={"h5"} href="https://support.roompricegenie.com/" style={{ marginTop: "16px" }} >Help Center</Typography>
                          <Button variant={"contained"} color={"primary"} style={{ margin: "16px 16px 8px 0px" }} href={"https://support.roompricegenie.com"} target={"_blank"}>Go to Help Center</Button>
                          <Button variant={"outlined"} color={"primary"} style={{ marginTop: "10px" }} onClick={this.handleOpenForm}>Create a Support Ticket</Button>

                          <Typography>Frequently asked questions:</Typography>

                          <Typography style={{ marginTop: "24px" }}><a class="btn btn-primary btn-sm" href={"https://support.roompricegenie.com/how-it-works/"}>1. How does RoomPriceGenie calculate prices? </a></Typography>
                          <Typography style={{ marginTop: "24px" }}><a class="btn btn-primary btn-sm" href={"https://support.roompricegenie.com/how-do-i-adjust-my-base-price-and-what-happens-then/"}>2. How do I adjust my base price and what happens then? </a></Typography>
                          <Typography style={{ marginTop: "24px" }}><a class="btn btn-primary btn-sm" href={"https://support.roompricegenie.com/how-to-set-my-min-and-max-prices-and-my-variable-costs/"}>3. How to set my minimum/maximum prices and variable costs? </a></Typography>
                          <Typography style={{ marginTop: "24px" }}><a class="btn btn-primary btn-sm" href={"https://support.roompricegenie.com/how-do-i-manually-change-prices-for-consecutive-days/"}>4. How do I change prices for a longer period? </a></Typography>
                          <Typography style={{ marginTop: "24px" }}><a class="btn btn-primary btn-sm" href={"https://support.roompricegenie.com/closed-rooms/"}>5. How does the feature „Closed Rooms“ works? </a></Typography>
                        </Grid>
                      </Grid>
                    
                    }

                  </Dialog> */}

                  <Dialog
                    fullScreen={fullScreen}
                    maxWidth='lg'
                    open={openForm}
                    onClose={this.handleCloseForm}
                    aria-labelledby="responsive-dialog-title"
                  >
                    <DialogContent>
                      <iframe name="myframe" style={{ 'border': 'none', minHeight: "700px", width: "650px" }} src={"https://static.roompricegenie.com/createTicket.html"}></iframe>
                    </DialogContent>
                  </Dialog>
                </div>
              </Toolbar>
            </AppBar>

            {/* <div style={{ backgroundColor: "#f0f4f8", width: "100%", minHeight: "5px", position: "absolute", zIndex: '1001', left: "0" }}></div>
            <div style={{ backgroundColor: "#A0A2A6", width: "100%", minHeight: "5px", position: "fixed", zIndex: '1000' }}></div> */}
            <nav className={classes.drawer} id="sideBar" style={{width: this.state.drawerOpen && this.state.drawerOpenAddcss ? "240px" : "57px", background:"#292f4c"}} onMouseLeave={ this.state.drawerOpen && !this.state.drawerOpenAddcss ?  this.enterDrawerClose : this.handleDrawerHideShowArrowButton} onMouseEnter={ this.state.drawerOpen && this.state.drawerOpenAddcss ?  this.handleDrawerHideShowArrowButton : this.enterDrawerOpen} >
              {/* The implementation can be swapped with js to avoid SEO duplication 1000of links. */}
              <Hidden smUp implementation="css">
                <SwipeableDrawer
                  container={this.props.container}
                  variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                  open={this.state.mobileOpen}
                  onOpen={() => false}
                  onClose={this.closeDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                >
                  {this.handleParentMenu()}
                </SwipeableDrawer>
              </Hidden>
              <Hidden xsDown implementation="css">
              {/* {!this.state.drawerOpen && */}
                <div id={"SmallSidebar"} className={classes.logoLeft} style={{paddingBottom: "200px", height:"100%", background:"#292F4C", width:"57px", overflow:"hidden"}}  onMouseEnter={this.enterDrawerOpen}>
                  {/* <IconButton
                    style={{ color: "#fff" }}
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={this.toggleDrawerOpen}                    
                  >
                    <Icon>chevron_right</Icon>
                  </IconButton> */}
                   {this.handleParentMenu(true)}
                   
                {this.bottomMenu(true)}
                </div>
                
  {/* } */}
                <SwipeableDrawer
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  variant="persistent"
                  open={this.state.drawerOpen}
                  onOpen={() => false}
                  onClose={this.closeDrawerToggle}
                  style={{overflow:"hidden"}}
                  PaperProps={{
                    style:{overflowX:"hidden"}
                  }}
                >
                  <div id={"MainSidebar"} className="sidebarscrollhide" style={{ paddingBottom: "200px", overflow:"overlay", overflowX : "hidden" }}>
                    {this.handleParentMenu()}
                  </div>
                  
                  {/* Bottom Menu */}

                  {this.bottomMenu()}
                </SwipeableDrawer>
              </Hidden>
            </nav>
            {!this.props.hotelAuthReducer.hotelDetails.token &&
              <div className={classes.MainLoader}>
                <div className={classes.loader}>
                  <Loader
                    height="100"
                    width="100"
                  />
                </div>
              </div>
            }
            <main className={classes.content} id='divPrint'>
              {this.props.hotelAuthReducer.hotelDetails.token &&
                <ClientRountes></ClientRountes>
              }
            </main>
            <Dialog
              fullScreen={fullScreen}
              maxWidth='md'
              open={this.state.openAccount}
              onClose={this.handleUserProfile}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">My Account</DialogTitle>
              <IconButton aria-label="Close" onClick={this.handleUserProfile} style={{ position: "absolute", right: "0" }}>
                <CloseIcon />
              </IconButton>
              <DialogContent>
                <div>
                  <FormControl margin="normal" required className={classes.TextField}>
                    <TextField
                      id="standard-name"
                      label="First Name"
                      onChange={this.handleAccount('first_name')}
                      value={this.state.account.first_name}
                      error={this.state.error.includes("first_name")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={t('Please enter First Name')}
                    />
                    {this.state.error.includes("first_name") ? <span class='error-message'>{'* Please enter first name'}</span> : ''}
                  </FormControl>
                  <FormControl margin="normal" required className={classes.TextField}>
                    <TextField
                      id="standard-name"
                      label="Last Name"
                      onChange={this.handleAccount('last_name')}
                      value={this.state.account.last_name}
                      error={this.state.error.includes("last_name")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={t('Please enter Last Name')}
                    />
                    {this.state.error.includes("last_name") ? <span class='error-message'>{'* Please enter last name'}</span> : ''}
                  </FormControl>
                  <FormControl margin="normal" required className={classes.TextField}>
                    <TextField
                      id="standard-name"
                      label="Email"
                      onChange={this.handleAccount('email')}
                      value={this.state.account.email}
                      error={this.state.error.includes("email")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={t('Please enter Email')}
                    />
                    {this.state.error.includes("email") ? <span class='error-message'>{'* Please enter email address'}</span> : ''}
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>               
                <Button onClick={this.chnageAccount} variant="contained" color="primary" >
                  Save
                </Button>
                <Button onClick={this.handleUserProfile} className="cancelBtn">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              fullScreen={fullScreen}
              maxWidth='sm'
              open={this.state.isResetPassword}
              onClose={this.togglePassword}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">{t('Change Password')}</DialogTitle>
              <IconButton aria-label="Close" onClick={this.togglePassword} style={{ position: "absolute", right: "0" }}>
                <CloseIcon />
              </IconButton>
              <DialogContent>
                <FormControl margin="normal" required className={classes.TextField}>
                  <TextField
                    type={this.state.showOld ? "text" : "password"}
                    id="standard-name"
                    label={t("Old Password")}
                    margin="normal"
                    onChange={this.handlePassword('old_password')}
                    value={this.state.resetPassword.old_password}
                    error={this.state.error.includes("old_password")}
                    InputLabelProps={{ shrink: true }}
                    placeholder={t('Please enter Old Password')}
                    InputProps={{
                      endAdornment: <InputAdornment position="end" style={{ color: "#A0A2A6" }} onClick={() => this.showPassword("showOld")}><Icon>{this.state.showOld ? "visibility" : "visibility_off"}</Icon></InputAdornment>,
                    }}

                  />
                  {this.state.error.includes("old_password") ? <span class='error-message'>{'* Please enter old password'}</span> : ''}
                </FormControl>
                <FormControl margin="normal" required className={classes.TextField}>
                  <TextField
                    type={this.state.showNew ? "text" : "password"}
                    id="standard-name"
                    label={t("New Password")}
                    margin="normal"
                    onChange={this.handlePassword('new_password')}
                    value={this.state.resetPassword.new_password}
                    className={classes.TextField}
                    error={this.state.error.includes("new_password")}
                    placeholder={t('Please enter New Password')}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end" style={{ color: "#A0A2A6" }} onClick={() => this.showPassword("showNew")}><Icon>{this.state.showNew ? "visibility" : "visibility_off"}</Icon></InputAdornment>,
                    }}
                  />
                  {this.state.error.includes("new_password") ? <span class='error-message'>{'* Please enter new password'}</span> : ''}
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.togglePassword} className="cancelBtn" autoFocus>
                  {t("Cancel")}
                </Button>
                <Button onClick={() => this.chnagePassword()} variant="contained" color="primary" autoFocus>
                  {t("Reset Password")}
                </Button>
              </DialogActions>
            </Dialog>
            {this.state.warning &&
              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={this.toggleWarning}
                open={this.state.warning}
                TransitionComponent={Fade}
                ContentProps={{
                  classes: {
                    root: this.state.snackbarSuccess ? classes.sccessBar : classes.warningBar
                  }
                }}
                message={<span style={{ textTransform: 'capitalize' }}><Icon className={classes.warningIcon}>{(this.state.snackbarSuccess) ? 'check_circle' : 'error'}</Icon> {t(this.state.snackbarMsg)}</span>}
                variant="success"
              />
            }
            <Dialog
              fullScreen={fullScreen}
              maxWidth='lg'
              open={this.state.referFriend}
              onClose={() => this.referFriend()}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogContent style={{ height: "600px" }}>
                <iframe src={"https://roompricegenie.referralrock.com/promotion/1/?widget=1&firstname=" + this.state.account.first_name + "&lastname=" + this.state.account.last_name + "&email=" + this.state.account.email} style={{ backgroundColor: 'white', width: "600px", height: "950px", border: "none" }}></iframe>
              </DialogContent>
            </Dialog>
          </div>
        </Router>
        {this.state.helpModal && Modals.SidebarModal(this)}
      </MuiThemeProvider>
    );
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    userSession: state.sessionReducer,
    hotelAuthReducer: state.hotelAuthReducer,
    commonReducer: state.commonReducer,
    roomList: state.roomListReducer,
    featuresReducer: state.featuresReducer,
    priceSettingReducer: state.priceSettingReducer,
    hotelDetailReducer: state.hotelSettingReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hotelAuth: (request) => { dispatch(actions.hotelAuth(request)) },
    updatePassword: (request) => { dispatch(actions.updatePassword(request)) },
    updateAccount: (request) => { dispatch(actions.updateAccount(request)) },
    cleanNotify: () => { dispatch(actions.cleanNotify()) },
    getRoomList: (request) => { dispatch(actions.getRoomList(request)) },
    setCalendarRoom: (request) => { dispatch(actions.setCalendarRoom(request)) },
    updateStorageData: (request) => { dispatch(actions.updateStorageData(request)) },
    showNavigationPopup: (request) => { dispatch(actions.showNavigationPopup(request)) },
    setUserLog: (request) => { dispatch(actions.setUserLog(request)) },
    getPricingSettings: (request) => { dispatch(actions.getPricingSettings(request)) },
    getHotelDetail: (request) => { dispatch(actions.getHotelDetail(request)) },
    storeIssueReport:(request)=>{dispatch(actions.storeIssueReport(request))},
    updateHotelView:(request)=>{dispatch(actions.updateHotelView(request))},
    updateSidebarView:(request)=>{dispatch(actions.updateSidebarView(request))},
    
  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(Sidebar));
