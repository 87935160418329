import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import Notify from '../Notify'
import timezoner from 'timezoner';

//const onCurrencyStart = () => {
//    return {
//        type: actionTypes.CURRENCY_START
//    }
//}
//
//const onCurrencyFailed = (error) => {
//    return {
//        type: actionTypes.CURRENCY_FAILED,
//        error: error
//    }
//}
//
//const onCurrencySucceed = (data) => {
//    return {
//        type: actionTypes.CURRENCY_SUCCEED,
//        state: data
//    }
//}

const clientRegistration = (request, dispatch) => {

    let requestParams = {
        username: request.email,
        password: request.password,
        first_name: request.first_name,
        last_name: request.last_name,
        language: request.language,
    }
    axios.defaults.headers.common['Authorization'] = "Token " + request.token;
    axios.post(Server.API + 'account/client/register/', requestParams)
        .then(function (response) {
            if (request.is_old_hotel == 'new') {
                hotelRegistration(request, response.data, dispatch)
            } else {
                linkHotelToClient(request, response.data, dispatch)
            }
        })
        .catch(function (error) {
            dispatch(Notify.newUserModal("User Email Already Exist", false));
        });
}

const hotelRegistration = (request, ClientResponse, dispatch) => {
    let requestParams = {
        client: request.is_old_user === 'old' ? request.newUser.value : ClientResponse.id,
        reference_room_data: JSON.stringify(request.reference_room_data),
        lat: request.lat,
        lng: request.lng,
        currency: request.currency,
        address: request.address,
        region: request.region,
        postcode: request.postcode,
        country: request.country,
        name: request.name,
        booking_ref_url: request.booking_ref_url,
        hotels_ref_url: request.hotels_ref_url,
        expedia_ref_url: request.expedia_ref_url,
        website: request.website,
        review: 0,
        notes: request.notes,
        number_of_rooms: request.number_of_rooms,
    }

    if (request.is_staff) {
        requestParams.reseller = request.reseller ? request.reseller : null;
        requestParams.responsible_person = request.responsible_account;
    } else {
        requestParams.responsible_person = request.currentUser;
    }

    axios.defaults.headers.common['Authorization'] = "Token " + request.token;
    timezoner.getTimeZone(
        requestParams.lat, // Latitude coordinate
        requestParams.lng, // Longitude coordinate
        function (err, data) {
            if (err) {
                requestParams.timezone = ''
            } else {
                requestParams.timezone = data.timeZoneId
            }
            axios.post(Server.API + 'hotel/register/', requestParams)
                .then((response) => {
                    dispatch(hotelRegistrationSucceed())
                    dispatch(Notify.newUserModal("Hotel Registered Successfully", true));
                    // setTimeout(() => { window.location.reload() }, 1000);
                })
                .catch((error) => {
                    dispatch(hotelRegistrationFailed())
                    dispatch(Notify.newUserModal("Hotel Registration Failed", false));
                });
        },
        { language: 'es', key: Server.GOOGLEAPIKEY }
    );
}

const linkHotelToClient = (request, ClientResponse, dispatch) => {
    let requestParams = {
        client: request.is_old_user === 'old' ? request.newUser.value : ClientResponse.id,
        hotel: request.newHotel.value,
        reference_room_data: JSON.stringify(request.reference_room_data),
        number_of_rooms: request.number_of_rooms,
        currency: request.currency,
        review: 0,
        lat: request.lat,
        lng: request.lng,
        booking_ref_url: request.booking_ref_url
    }
    if (request.is_staff) {
        requestParams.reseller = request.reseller ? request.reseller : null;
        requestParams.responsible_person = request.responsible_account;
    } else {
        requestParams.responsible_person = request.currentUser;
    }

    axios.defaults.headers.common['Authorization'] = "Token " + request.token;

    timezoner.getTimeZone(
        requestParams.lat, // Latitude coordinate
        requestParams.lng, // Longitude coordinate
        function (err, data) {
            if (err) {
                requestParams.timezone = ''
            } else {
                requestParams.timezone = data.timeZoneId
            }
            axios.post(Server.API + 'hotel/link/', requestParams)
                .then((response) => {
                    dispatch(Notify.newUserModal("Hotel Registered Successfully", true));
                    // setTimeout(() => { window.location.reload() }, 1000);
                })
                .catch((error) => {
                    dispatch(Notify.newUserModal("Hotel Registration Failed", false));
                });
        },
        { language: 'es', key: Server.GOOGLEAPIKEY }
    );
}

const hotelRegistrationStart = () => {
   return {
       type: actionTypes.HOTEL_REGISTRATION_START
   }
}

const hotelRegistrationSucceed = (data) => {
   return {
       type: actionTypes.HOTEL_REGISTRATION_SUCCEED,
       state: data
   }
}
const hotelRegistrationFailed = (data) => {
    return {
        type: actionTypes.HOTEL_REGISTRATION_FAILED,
        
    }
 }
export const registerHotel = (request) => {
    return dispatch => {
        dispatch(hotelRegistrationStart())
        if (request.is_old_user === 'old') {
            if (request.is_old_hotel == 'new') {
                hotelRegistration(request, {}, dispatch)
            } else {
                linkHotelToClient(request, {}, dispatch)
            }
        } else {
            clientRegistration(request, dispatch)
        }
    }
}