import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../index';


const scheduleHistoryListStart = () => {
    return {
        type: actionTypes.SCHEDULE_HISTORY_LIST_START,
        error: null
    }
}

const scheduleHistoryListFailed = (error) => {
    return {
        type: actionTypes.SCHEDULE_HISTORY_LIST_FAILED,
        error: error
    }
}

const scheduleHistoryListSucceed = (data) => {
    return {
        type: actionTypes.SCHEDULE_HISTORY_LIST_SUCCEED,
        state: data
    }
}

export const getScheduleHistoryList = (request) => {
    return dispatch => {
        dispatch(scheduleHistoryListStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        let url = (request.url ? (Server.API + request.url) : (Server.API + 'amalgamation/scrape/history/schedule/'));
        axios.get(url)
            .then(function (response) {
                dispatch(scheduleHistoryListSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(scheduleHistoryListFailed(error))
            });
    }
}


const scheduleHistoryDetailsStart = () => {
    return {
        type: actionTypes.SCHEDULE_HISTORY_DETAILS_START,
        error: null
    }
}

const scheduleHistoryDetailsFailed = (error) => {
    return {
        type: actionTypes.SCHEDULE_HISTORY_DETAILS_FAILED,
        error: error
    }
}

const scheduleHistoryDetailsSucceed = (data) => {
    return {
        type: actionTypes.SCHEDULE_HISTORY_DETAILS_SUCCEED,
        state: data
    }
}

export const getScheduleHistoyDetails = (request) => {
    return dispatch => {
        dispatch(scheduleHistoryDetailsStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        let url = (request.url ? (Server.API + request.url) : (Server.API + 'amalgamation/scrape/history/schedule/detail/' + request.programUniqueId));
        axios.get(url)
            .then(function (response) {
                dispatch(scheduleHistoryDetailsSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(scheduleHistoryDetailsFailed(error))
            });
    }
}

