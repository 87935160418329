var style = {
  textField: {
    textAlign: 'left'
  },
  textFieldSelects: {
    textAlign: 'left'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column'
  },

  saveBtn: {
    minWidth: '100px',
    float: 'left',
    // marginTop: '22px',
    marginRight:'20px',
    ['@media (max-width: 860px) and (min-width: 298px)']: {
      marginTop: "22px",
    }
  },
  ProceedBtn: {
    margin: '0px 20px',
  },
  btnDiv: {
    textAlign: 'left',
    marginTop: '16px',
    maxWidth: '100%',
  },
  switchDiv: {
    textAlign: 'left',
  },
  subTitle: {
    textAlign: 'left',
    margin: '10px 15px'
  },
  showPass: {
    color: "#777777"
  },
  formControl: {
    textAlign: 'left',
    marginRight: '15px',
    maxWidth: '100%',
  },
  // cardFixedHeight: {
  //   minHeight: '500px'
  // },
  cardHeight: {
    minHeight: '200px'
  },
  buttonProgress: {
    position: 'absolute'
  },
  iframeStyle: {
    border: "none",
    minHeight: "50px",
    height: "50px",
    width: "230px",
    float: "left",
    marginTop: "20px"
  },
  reservationBtn: {
    float: "left",
    marginLeft: "6px"
  },
  leftMargin: {
    '@media screen and (min-width: 800px)': {
      marginLeft: "15px",
      width: "100%"
    },
    ['@media (max-width: 414px) and (min-width: 360px)']: {
      marginTop: '25px'
    },
  },
  seeKomBtn: {
    margin: '25px 0px 0px',
    width: '212px'
  },
  bsLoginCantainer: {
    display: 'flex',
    ['@media (max-width: 414px) and (min-width: 360px)']: {
      display: 'inline-block',
    },
  },
  RadioGroup: {
    display: 'inline-block',
  },
  emailLabel: {
    marginTop: '20px'
  },
  userTable: {
    padding: '20px'
  },
  addUserBtn: {
    float: 'left',
    marginBottom: '15px',
    marginLeft: "2px",
    ['@media (max-width: 414px) and (min-width: 360px)']: {
      marginLeft: "0px",
      width: "100%"
    }
  },
  userTableDiv: {
    marginTop: "20px",
    '& table > tfoot > tr > td:last-child': {
      padding: '0px'
    }
  },
  actionIcon: {
    padding: '0',
    cursor: 'pointer',
    minWidth: '30px',
    float: "left"
  },
  textField: {
    minWidth: '240px',
    ['@media (max-width: 540px) and (min-width: 400px)']: {
      minWidth: '240px'
    },
    ['@media (max-width: 399px) and (min-width: 361px)']: {
      minWidth: '230px'
    },
    ['@media (max-width: 360px) and (min-width: 321px)']: {
      minWidth: '215px'
    },
    ['@media (max-width: 320px)']: {
      minWidth: '170px'
    }
  },
  popupDiv: {
    display: "flex",
    flexDirection: 'inherit',
    overflow: 'auto'
  },
  MainLoader: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    background: 'rgb(15, 51, 80, 0.3)',
    zIndex: '9',
  },
  loader: {
    position: 'absolute',
    top: '35%',
    left: '35%'
  },
  fieldLeft: {
    marginRight: '20px',
    ['@media (max-width: 540px) and (min-width: 400px)']: {
      width: '100%',
      marginRight: '0px'
    },
    ['@media (max-width: 399px) and (min-width: 361px)']: {
      width: '100%',
      marginRight: '0px',
      "& label": {
        fontSize: "14px"
      },
      ">div": {
        "& label": { fontSize: "14px" }
      }
    },
    ['@media (max-width: 360px) and (min-width: 321px)']: {
      width: '100%',
      marginRight: '0px',
      "& label": {
        fontSize: "13px"
      },
      ">div": {
        "& label": { fontSize: "13px" }
      }
    },
    ['@media (max-width: 320px)']: {
      width: '100%',
      marginRight: '0px',
      "& label": {
        fontSize: "10px"
      },
      ">div": {
        "& label": { fontSize: "10px" }
      }
    }
  },
  refRoomOnly: {
    float: 'left'
  },
  paddingTen: {
    padding: '10px 15px 10px 15px',
    textAlign: 'center'
  },
  actionCell: {
    padding: '10px 15px 10px 15px',
    textAlign: 'left'
  },
  gridContainer: {
    textAlign: 'left'
  },
  adornment: {
    color: 'rgb(118, 118, 118)',
    paddingRight: '5px'
  },
  halfWidth: {
    width: '50%'
  },
  refRommRow: {
    '& td': { fontWeight: "600" }
  },
  minPriceFC: {
    marginTop: 0,
    width: "150px",
    marginLeft: "50px"
  },
  tableHeading: {
    textAlign: 'left',
    marginBottom: '15px',
    marginTop: '15px'
  },
  updateBtn: {
    margin: '14px 24px 0px'
  },
  addBtnDiv: {
    display: "flow-root"
  },
  lockIcon: {
    cursor: "pointer"
  },
  endadrom: {
    display: 'contents'
  },
  endadromspan: {
    width: '20px',
    textAlign: 'right',
    cursor: "pointer",
    fontSize: "24px",
    color: '#541388'
  },
  addNewDiv: {
    '@media screen and (max-width: 900px)': {
      minWidth: "100%",
      display: "-webkit-inline-box"
    },
  },
  minPriceDiv: {
    '@media screen and (max-width: 900px)': {
      minWidth: "100%",
    },
    width: "65%",
  },
  refRoomDiv: {
    width: "100%",
    marginTop: "30px"
  },
  basicSetupCheck: {
    width: "100%",
    marginTop: "15px"
  },
  settingFields: {
    width: '170px',
    marginRight: '5%',
    minWidth: '170px',
    maxWidth: '170px'
  },
  htmlTooltip: {
    backgroundColor: '#323338 !important',
    color: 'white',
    maxWidth: '250px',
    // border: '1px solid #1976d2',
    opacity: '1',
    filter: 'alpha(opacity=100)'
  },
  fullWidth: {
    ['@media (max-width: 414px) and (min-width: 360px)']: {
      maxWidth: "100%"
    }
  },
  alignLeft:{
    textAlign:"left"
  },
  alignRight:{
    textAlign:"right"
  },
  inputLable: {
    marginTop:'-18px'
  },
  formControl2: {
    marginTop:'28px'
  },
  copyText: {
    textAlign: "right", 
    fontSize: "10px", 
    marginTop: "2px", 
    color: "#3550c1"
  },
  formLable: {
    fontSize: "14px", 
    fontWeight: "500", 
    marginLeft: "1px", 
    color: "#494949"
  },
  divStyle: {
    float: "left", 
    marginTop: "2px"
  },
  fontStyle: {
    textAlign: "left", 
    fontWeight: "500"
  },
  btnReprocess: {
    marginTop:'15px',
    ['@media (max-width: 4000px) and (min-width: 860px)']: {
      marginTop: "-6px"
    }

  },
  formTextfield: {
    zIndex: -1, 
    position: 'absolute'
  },
  radioGroupDates: {
    flexDirection: "row",
  },

}
export default style;