import * as actionTypes from './actionTypes';

export default {
    newUserModal: function (msg, success) {
        let data = {
            message: msg,
            is_success: success,
        }
        return {
            type: actionTypes.PUT_NOTIFY,
            data: data
        }
    }
}