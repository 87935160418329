var style = {
  textField: {
    marginLeft: '2%',
    width: '45%',
    minWidth: '250px'
  },
  btnDiv: {
    width: '100%',
    padding: '30px'
  },
  saveBtn: {
    float: 'right',
  },
  userDiv: {
    display: 'flex',
    width: '100%',
    flexDirection: 'inherit',
    marginLeft: '5%',
  },
  textSelectField: {
    marginLeft: '2%',
    width: '45%',
    minWidth: '250px',
    float: 'left',
    borderBottom: "solid 1px #949494"
  },
  currentPriceInput: {
    width: '28%',
    minWidth: "150px",
    margin: "0 0 0px 15px !important"
  },
  addNewBTN: {
    marginTop: '8px',
    marginBottom: '15px'
  },
  programBtn: {
    float: 'right',
    margin: '20px'
  },
  modalInputFull:{
    width: '100%',
    minWidth: "200px",
    marginTop:'10px'
  },
  modalInputLeft:{
    width: '48%',
    minWidth: "200px",
    marginRight:'2%',
    marginTop:'10px'
  },
  modalInputRight:{
    width: '48%',
    minWidth: "200px",
    marginLeft:'2%',
    marginTop:'10px'
  },
  fieldTitle:{
      marginTop:'16px',
      marginBottom:'10px'
  }
}
export default style;