import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = [];

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TIME_BOOKING_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.TIME_BOOKING_SUCCEED:
            return updateObject(state, { loading: false, error: null, timeBookingList: action.state })
        case actionTypes.TIME_BOOKING_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.TIME_BOOKING_UPDATE_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.TIME_BOOKING_UPDATE_SUCCEED:
            return updateObject(state, { loading: false, error: null, timeBookingList: action.state })
        case actionTypes.TIME_BOOKING_UPDATE_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.GET_ELASTICITY_LIST:
            return updateObject(state, { loading: false, error: null, elasticityList: action.state })
        case actionTypes.GET_BOOKING_LIST:
            return updateObject(state, { loading: false, error: null, bookingPropList: action.state })
        default:
            return updateObject(state, { loading: false, error: null });
    }
}

export default reducer