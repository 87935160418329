import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import CompetitorCss from "./css/competitorCss";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TextField from "@material-ui/core/TextField";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../../../store/actions/index";
import InputBase from "@material-ui/core/InputBase";
import AsyncCreatableSelect from "react-select/lib/AsyncCreatable";
import Server from "../../../config/server";
import axios from "axios";
import * as actionTypes from "../../../store/actions/actionTypes";
import { Typography } from "@material-ui/core";
import Modals from "./modal/modal";
import { withNamespaces } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Line } from "react-chartjs-2";
import Icon from "@material-ui/core/Icon";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
import Tab from "@material-ui/core/Tab";
import moment from "moment";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@material-ui/icons/Room";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import HeaderCard from "../HeaderCard";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  InlineDatePicker,
} from "material-ui-pickers";
import {
  monthPickerStylecompetitors,
  CalendarbuttonTheme,
  monthPickerStyle,
} from "../../muiTheme";
import ReactGA from "react-ga";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import AsyncSelect from "react-select/lib/Async";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { competitorTheme } from "../../muiTheme";

const styles = CompetitorCss;
const AnyReactComponent = ({ val, current, index, other }) => (
  <Tooltip title={<Typography style={{ color: "#fff" }}>{val}</Typography>}>
    {other ? (
      <RoomIcon
        style={{ color: other == 1 ? "yellow" : "#ADD8E6", fontSize: "32px" }}
      ></RoomIcon>
    ) : (
      <RoomIcon
        style={{
          color: index > 9 ? "#b3b3b3" : current ? "#ff0000" : "#00008b",
          fontSize: current ? "32px" : "28px",
        }}
      ></RoomIcon>
    )}
  </Tooltip>
);

class Competitors extends React.Component {
  state = {
    loadPriceOcc: false,
    confirmReset: false,
    loadPrice: false,
    addNewHotel: false,
    error: [],
    indexError: [],
    competitorData: [],
    nearestData: false,
    scoreCompetitors: false,
    openconfirmScrapeModal: false,
    staticData: null,
    loadNearData: false,
    loadSaveData: false,
    loadScoreCompetitor: false,
    url: "",
    showGraph: false,
    tab: 0,
    priceImputation: {},
    testScores: {},
    imputationStatics: {},
    featuresEnabled: [],
    uniqueIndexError: [],
    moreIndexError: [],
    completePercentage: null,
    loadTopCompetitors: false,
    room_id: "",
    showGraphToggle: false,
    graphCompetitor: "",
    loadingIndicator: false,
    opensortByScoreConfirmModal: false,
    getTop20Model: false,
    boostPrice: null,
    comp_distance: "80",
    loadingScrapTop20: false,
    is_seasonal: false,
    Weight_of_comp: [0, 1, 5, 10, 20, 30, 40, 50, 60, 70, 80],
    Weight_of_comp_only: ["0", 1, 5, 10, 20, 30, 40, 50, 60, 70, 80],
    airDnaOptions: ["0", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    pubp_today_weight: null,
    model_weights: {
      competitor_model_weight: 10,
      pubp_model_weight: "0",
      airDNA_model_weight: "0",
    },
    airDnaData: [],
    selectedTerm: null,
    todays_total_rooms: "",
    showFullYearGraph: true,
    monthChangeDate: new Date(
      moment
        .utc(this.props.hotelAuthReducer.utcTime)
        .tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone)
        .format("YYYY"),
      moment
        .utc(this.props.hotelAuthReducer.utcTime)
        .tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone)
        .format("M") - 1,
      5
    ),
    graphDataArr: {},
    otherCompetitorList: {},
  };

  componentWillMount() {
    this.initPusher();
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
    };
    this.props.getCompetitor(requestParam);
    this.props.getImputation(requestParam);

    this.props.searchBookingCompetitor(requestParam);
    this.props.getAirDnaGraphData(requestParam);
    this.props.getHotelDetail(requestParam);
    this.getCacheData();
  }

  getCacheData = () => {
    axios.defaults.headers.common["Authorization"] =
      "Token " + this.props.hotelAuthReducer.hotelDetails.token;
    axios.get(Server.API + "pricingalgorithm/cache-price/").then((response) => {
      if (response.data) {
        var received_msg = response.data;
        if (received_msg && received_msg.pickup_boost_prices) {
          this.setState({
            boostPrice: received_msg.pickup_boost_prices,
          });
        }
      }
    });
  };

  getCompetitor = () => {
    this.setState({
      nearestData: false,
      competitorData: this.state.staticData,
    });
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
    };
    this.props.getCompetitor(requestParam);
  };

  componentWillReceiveProps(props) {
    if (
      props.competitorReducer.airDnaData &&
      props.competitorReducer.airDnaData.full_air_dna_data
    ) {
      this.setState({
        airDnaData: props.competitorReducer.airDnaData.full_air_dna_data
          .airdna_data
          ? props.competitorReducer.airDnaData.full_air_dna_data.airdna_data
          : [],
        todays_total_rooms:
          props.competitorReducer.airDnaData.todays_total_rooms,
      });
    }

    if (
      props.competitorReducer.airDnaData &&
      props.competitorReducer.airDnaData.city_name &&
      (!this.state.selectedTerm ||
        this.state.selectedTerm.label !=
          props.competitorReducer.airDnaData.city_name)
    ) {
      this.setState({
        selectedTerm: {
          label: props.competitorReducer.airDnaData.city_name,
          value: props.competitorReducer.airDnaData.city_name,
        },
      });
    }

    props.roomList.roomList &&
      props.roomList.roomList
        .filter((rooms) => rooms.is_reference_room == true)
        .map((rooms, index) => {
          if (rooms.is_reference_room) {
            this.setState({
              room_id: rooms.id,
            });
          }
        });
    if (props.competitorReducer) {
      this.setState({
        loadingScrapTop20: props.competitorReducer.loadingScrapTop20,
      });
    }
    if (props.competitorReducer.competitorList) {
      this.setState({
        competitorData: props.competitorReducer.competitorList,
      });
      if (!this.state.staticData) {
        this.setState({
          staticData: props.competitorReducer.competitorList,
        });
      }
      if (
        props.competitorReducer.loading == false &&
        this.state.completePercentage == 100
      ) {
        this.setState({
          loadTopCompetitors: false,
        });
      }
      this.renderMap();
    }
    if (props.competitorReducer.otherCompetitorList) {
      this.setState({
        otherCompetitorList: props.competitorReducer.otherCompetitorList,
      });
    }
    if (props.competitorReducer.scoreCompetitors) {
      this.setState({
        completedCompetitor:
          props.competitorReducer.scoreCompetitors.completed_competitor,
        totalCompetitor:
          props.competitorReducer.scoreCompetitors.total_competitor,
        completePercentage:
          props.competitorReducer.scoreCompetitors.complete_percentage,
        is_top_20: props.competitorReducer.scoreCompetitors.is_top_20,
      });
    }

    if (props.hotelListReducer.hotelList) {
      let hotelList = [];
      props.hotelListReducer.hotelList.results.map((val) => {
        hotelList.push({
          label: val.name,
          value: val.id,
          competitor_data: val.competitor_data,
          distance: val.distance,
        });
        return null;
      });
      this.setState({
        options: hotelList,
      });
    }

    if (props.commonReducer.notify) {
      if (
        props.commonReducer.notify.message ==
          "Nearest Competitor load successfully" ||
        props.commonReducer.notify.message == "Nearest Competitor load Failed"
      ) {
        this.setState({
          loadNearData: false,
        });
      }
      if (
        props.commonReducer.notify.message == "Competitor Update Failed" ||
        props.commonReducer.notify.message == "Competitors Updated Successfully"
      ) {
        this.setState({
          loadSaveData: false,
        });
      }
      if (
        props.commonReducer.notify.message ==
          "Score Top Competitor load Failed" ||
        props.commonReducer.notify.message ==
          "Score Top Competitor load successfully"
      ) {
        this.setState({
          loadScoreCompetitor: false,
        });
      }

      if (
        props.commonReducer.notify.message ==
          "Sorry, we couldn't find this Booking URL in our database." ||
        props.commonReducer.notify.message == "Top Competitor load successfully"
      ) {
        this.setState({
          loadTopCompetitors: false,
        });
      }
      this.setState({
        weightLoader: false,
        airDnarefreshData: false,
        // findNearest: false
      });
    }

    if (
      props.competitorReducer.ImputationData &&
      props.competitorReducer.ImputationData.data
    ) {
      this.setState({
        priceImputation: props.competitorReducer.ImputationData.data,
      });
    }

    if (props.priceSettingReducer && props.priceSettingReducer.priceSetting) {
      this.setState({
        featuresEnabled: props.priceSettingReducer.priceSetting.features,
      });
    }

    if (
      props.priceSettingReducer &&
      props.priceSettingReducer.priceSetting &&
      this.state.pubp_today_weight == null
    ) {
      this.setState({
        pubp_today_weight: props.priceSettingReducer.priceSetting.rpg_arguments
          .pubp_today_weight
          ? props.priceSettingReducer.priceSetting.rpg_arguments
              .pubp_today_weight
          : 0,
      });
    }

    if (
      props.priceSettingReducer &&
      props.priceSettingReducer.priceSetting &&
      props.priceSettingReducer.priceSetting.model_weights &&
      !this.state.weightLoaded
    ) {
      this.setState({
        model_weights: props.priceSettingReducer.priceSetting.model_weights,
        weightLoaded: true,
      });
    }

    if (props.competitorReducer.bookingCompetitor) {
      this.setState({
        bookingCompetitor: props.competitorReducer.bookingCompetitor,
      });
    }
    if (props.hotelDetailReducer.hoteDetail) {
      this.setState({
        hotelDetail: props.hotelDetailReducer.hoteDetail,
        is_seasonal: props.hotelDetailReducer.hoteDetail.is_seasonal,
      });
    }
    this.getMonthData(moment().format("YYYY-MM-DD"), true);
  }
  handleChangeExpansion = () => {
    if (this.state.expanded == true) {
      this.setState({ expanded: false });
    } else {
      this.setState({ expanded: true });
    }
  };
  toggleAddNewHotel = () => {
    this.setState({
      addNewHotel: !this.state.addNewHotel,
    });
  };

  handleChangeUrl = () => (event) => {
    this.setState({
      url: event.target.value,
      errorUrl: false,
    });
  };

  handleurl = () => (event) => {
    this.setState({
      bookingUrl: event.target.value,
    });
  };

  searchBooking = () => {
    let reqParams = {
      hotel_url: this.state.bookingUrl,
      token: this.props.hotelAuthReducer.hotelDetails.token,
    };

    this.props.searchBookingCompetitor(reqParams);
  };
  validate = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol (optional)
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "\\.en-gb\\.html$" + // .en-gb.html check
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };
  addNewScraper = () => {
    if (this.validate(this.state.url)) {
      let reqParams = {
        url: this.state.url,
        token: this.props.hotelAuthReducer.hotelDetails.token,
      };
      this.props.addScraper(reqParams);
      this.toggleAddNewHotel();
      this.setState({
        url: "",
      });
    } else {
      this.setState({
        errorUrl: true,
      });
    }
    // if (this.state.url == "") {
    //     this.setState({
    //         errorUrl: true,
    //     })
    //     return '';
    // }
    // let reqParams = {
    //     url: this.state.url,
    //     token: this.props.hotelAuthReducer.hotelDetails.token
    // }
    // this.props.addScraper(reqParams)
    // this.toggleAddNewHotel()
    // this.setState({
    //     url: '',
    // })
  };

  AutoCalc() {
    let WeightSum = 0;
    let AutoValue = 0;

    this.state.competitorData.map((val) => {
      if (val.weight && val.competitor) {
        WeightSum += parseInt(val.weight);
      }
    });

    this.state.competitorData.map((val) => {
      if (val.weight && val.competitor) {
        AutoValue +=
          val.competitor.avg_price *
          (1 + val.pricediff * 0.01) *
          (val.weight / WeightSum);
      }
    });

    return AutoValue.toFixed(2);
  }

  componentDidMount() {
    this.getScoreCompetitors();
    setInterval(() => {
      var path = window.location.pathname;
      if (
        path.includes("client/Competitors") &&
        this.state.completePercentage === 100
      ) {
        clearInterval();
      } else if (path.includes("client/Competitors")) {
        this.getScoreCompetitors();
      }
    }, 120000);
  }

  openSortByScoreModal = () => {
    this.setState({
      opensortByScoreConfirmModal: true,
    });
  };

  closeSortByScoreModal = () => {
    this.setState({
      opensortByScoreConfirmModal: false,
    });
  };

  confirmSortAndScore = () => {
    this.setState(
      {
        do_sorting: true,
      },
      () => {
        this.getScoreTopCompetitors();
      }
    );

    this.closeSortByScoreModal();
  };

  confirmOnlyScore = () => {
    this.setState(
      {
        do_sorting: false,
      },
      () => {
        this.getScoreTopCompetitors();
      }
    );
    this.closeSortByScoreModal();
  };

  getScoreCompetitors = () => {
    this.setState({
      scoreCompetitors: true,
    });

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
    };

    this.props.getScoreCompetitor(requestParam);
  };

  getScoreTopCompetitors = () => {
    let requestParam = {
      room_id: this.state.room_id,
      token: this.props.hotelAuthReducer.hotelDetails.token,
      do_sorting: this.state.do_sorting,
    };
    this.props.getScoreTopCompetitors(requestParam);
    this.setState({
      loadScoreCompetitor: true,
    });
  };
  getTopCompetitorsModel = () => {
    this.setState({
      getTop20Model: true,
    });
  };
  handleData = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };
  getTopCompetitors = () => {
    this.setState({
      loadTopCompetitors: true,
      getTop20Model: false,
    });
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      distance: parseInt(this.state.comp_distance),
    };
    this.props.getTopCompetitors(requestParam);
  };
  closeTop20Modal = () => {
    this.setState({
      getTop20Model: !this.state.getTop20Model,
    });
  };
  getNearestCompetitor = () => {
    this.setState({
      nearestData: true,
      loadNearData: true,
    });
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
    };
    this.props.getNearCompetitor(requestParam);
  };

  handleCompetitor = (index, value) => {
    let jsx = this.state.competitorData;
    jsx[index].weight = value;
    this.setState({
      competitorData: jsx,
    });
  };
  handleCompetitorIndex = (index, value) => {
    let jsx = this.state.competitorData;
    jsx[index].index = parseInt(value);
    this.setState({
      competitorData: jsx,
    });
  };
  handleSeasonalData = (event) => {
    if (event.target.checked) {
      this.setState({
        is_seasonal: true,
      });
    }
    let reqParams = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      is_seasonal: event.target.checked,
    };
    this.props.updateHotel(reqParams);
  };
  handleDiff = (index, value) => {
    let jsx = this.state.competitorData;
    jsx[index].pricediff = value;
    this.setState({
      competitorData: jsx,
    });
  };

  validateCompetitor = () => {
    let error = [];
    let indexError = [];
    let indexArray = [];
    let uniqueIndexError = [];
    let moreIndexError = [];
    this.state.competitorData.map((row, index) => {
      if (!row.weight && row.competitor) {
        error.push(index);
      }
      if (row.index > 20) {
        moreIndexError.push(index);
      }
      if (!row.index && row.competitor) {
        indexError.push(index);
      }
      if (indexArray.includes(row.index)) {
        uniqueIndexError.push(index);
      } else {
        indexArray.push(row.index);
      }
    });

    this.setState({
      error: error,
      indexError: indexError,
      uniqueIndexError: uniqueIndexError,
      moreIndexError: moreIndexError,
    });
    if (
      error.length > 0 ||
      indexError.length > 0 ||
      uniqueIndexError.length > 0 ||
      moreIndexError.length > 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  updateCompetitors = (loader) => {
    if (!this.validateCompetitor()) {
      return null;
    }

    let comp_data = [];

    if (loader) {
      this.setState({
        loadSaveData: true,
      });
    }

    if (!this.state.nearestData) {
      this.state.competitorData.map((row) => {
        comp_data.push({
          id: row.id,
          index: row.index,
          competitor: row.competitor ? row.competitor.hotel.id : null,
          weight: row.weight != "" ? row.weight : 0,
        });
      });

      let req_data = {
        token: this.props.hotelAuthReducer.hotelDetails.token,
        competitors: JSON.stringify(comp_data),
      };

      this.props.bulkUpdateCompetitor(req_data);
    } else {
      this.state.staticData.map((row, index) => {
        let newData = this.state.competitorData[index];
        comp_data.push({
          id: row.id,
          index: row.index,
          competitor: newData.id,
          weight: newData.weight ? newData.weight : null,
        });
      });
      let req_data = {
        token: this.props.hotelAuthReducer.hotelDetails.token,
        competitors: JSON.stringify(comp_data),
      };
      this.props.bulkUpdateCompetitor(req_data);
    }
    return "";
  };

  filterOptions = (inputValue) => {
    if (this.state.options) {
      return this.state.options.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    }
  };

  hotelOptions = (inputValue, callback) => {
    inputValue = inputValue.trim();
    this.setState(
      {
        tempValue: inputValue,
      },
      () => {
        setTimeout(() => {
          if (inputValue.length >= 5 && inputValue == this.state.tempValue) {
            axios.defaults.headers.common["Authorization"] =
              "Token " + this.props.hotelAuthReducer.hotelDetails.token;
            axios
              .get(
                Server.API + "hotel/list/find-competitor/?search=" + inputValue
              )
              .then((response) => {
                this.props.setHotelList(response.data);

                let jsx = [];
                response.data.results.map((val) => {
                  jsx.push({
                    label:
                      val.name +
                      " ( " +
                      val.address +
                      ", " +
                      val.distance +
                      "km)",
                    value: val.id,
                    competitor_data: val,
                  });
                  return null;
                });
                let data = jsx;

                callback(data);
              })
              .catch((error) => {});
            this.setState({
              loadingIndicator: true,
            });
          } else {
            this.setState({
              loadingIndicator: false,
            });
          }
        }, 1000);
      }
    );
  };

  handleHotelChange = (selectedHotel, selection, index) => {
    if (selection.action == "clear") {
      const { competitorData } = this.state;
      competitorData[index].name = "";
      competitorData[index].selected = "";
      competitorData[index].max_price = 0;
      competitorData[index].min_price = 0;
      competitorData[index].number_of_price = 0;
      competitorData[index].avg_price = 0;
      competitorData[index].competitor = null;
      competitorData[index].pricediff = "";
      competitorData[index].weight = 0;
      this.setState({
        competitorData,
      });
    } else {
      if (!selectedHotel.__isNew__ && this.state.nearestData) {
        const { competitorData } = this.state;
        competitorData[index].name = selectedHotel.label;
        competitorData[index].id = selectedHotel.value;
        competitorData[index].selected = {
          label: selectedHotel.label,
          value: selectedHotel.value,
        };
        competitorData[index].max_price = selectedHotel.competitor_data
          .max_price
          ? selectedHotel.competitor_data.max_price
          : 0;
        competitorData[index].min_price = selectedHotel.competitor_data
          .min_price
          ? selectedHotel.competitor_data.min_price
          : 0;
        competitorData[index].number_of_price =
          selectedHotel.competitor_data.number_of_price ||
          selectedHotel.competitor_data.number_of_price === 0
            ? selectedHotel.competitor_data.number_of_price
            : 0;
        competitorData[index].avg_price = selectedHotel.competitor_data
          .avg_price
          ? selectedHotel.competitor_data.avg_price
          : 0;

        this.setState({
          competitorData,
        });
      } else {
        if (!selectedHotel.__isNew__) {
          let competitorData = this.state.competitorData;
          competitorData[index] = {
            competitor: {
              avg_price: selectedHotel.competitor_data.competitor_data
                ? selectedHotel.competitor_data.competitor_data.avg_price
                : 0,
              hotel: {
                id: selectedHotel.value,
                name: selectedHotel.label,
                address: selectedHotel.competitor_data.address,
              },
              max_price: selectedHotel.competitor_data.competitor_data
                ? selectedHotel.competitor_data.competitor_data.max_price
                : 0,
              min_price: selectedHotel.competitor_data.competitor_data
                ? selectedHotel.competitor_data.competitor_data.min_price
                : 0,
              number_of_price: selectedHotel.competitor_data.competitor_data
                ? selectedHotel.competitor_data.competitor_data.number_of_price
                : 0,
            },
            id: competitorData[index].id,
            index: index + 1,
            pricediff: null,
            weight: 20,
          };
          this.setState({
            competitorData: competitorData,
          });
        }
      }
    }
  };

  getScrap = () => {
    if (!this.validateCompetitor()) {
      return null;
    }

    let comp_data = [];

    if (!this.state.nearestData) {
      this.state.competitorData.map((row) => {
        comp_data.push({
          id: row.id,
          index: row.index,
          competitor: row.competitor ? row.competitor.hotel.id : null,
          weight: row.weight != "" ? row.weight : 0,
        });
      });
    } else {
      this.state.staticData.map((row, index) => {
        let newData = this.state.competitorData[index];
        comp_data.push({
          id: row.id,
          index: row.index,
          competitor: newData.id,
          weight: newData.weight ? newData.weight : null,
        });
      });
    }

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      id: this.props.hotelAuthReducer.hotelDetails.hotel.id,
      competitors: JSON.stringify(comp_data),
    };

    this.props.bulkUpdateCompetitorNscrape(requestParam);
    this.confirmScrape();
  };

  getToggalGraph = (data, options, min, max, competitor) => {
    this.setState({
      showGraphToggle: !this.state.showGraphToggle,
      graphData: data,
      graphOption: options,
      min: min,
      max: max,
      graphCompetitor: competitor,
    });
  };
  closeWarningModal = (method) => {
    this.setState({
      showGraphToggle: !this.state.showGraphToggle,
    });
  };
  renderCompetitorGraph = () => {
    let rows = [];
    const { classes } = this.props;
    const { t } = this.props;
    if (this.state.competitorData) {
      rows = this.state.competitorData;
    }

    return (
      <Table className={classes.userTable}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCellRoot} align="center">
              {t("Index")}
            </TableCell>
            <TableCell
              className={[classes.tableCellRoot, classes.tableCellRoot2]}
            >
              {t("Hotel")}
            </TableCell>
            <TableCell
              className={[classes.tableCellRoot, classes.tableCellRoot2]}
            >
              {t("Tests")}
            </TableCell>
            <TableCell
              className={[classes.tableCellRoot, classes.tableCellRoot2]}
            >
              {t("Pricing statistics")}
            </TableCell>
            {/* <TableCell className={classes.tableCellRoot} align="center" >{t("Validation Check")}</TableCell> */}
            <TableCell className={classes.tableCellRoot} align="center">
              {t("Graph `unprocessed`")}
            </TableCell>
            <TableCell className={classes.tableCellRoot} align="center">
              {t("Graph `cleaned`")}
            </TableCell>
            <TableCell className={classes.tableCellRoot} align="center">
              {t("Graph `imputed`")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.map((row, index) => {
              if (this.state.nearestData && !this.state.loadNearData) {
                row.selected = { label: row.name, value: row.id };
              } else {
                row.selected = row.competitor
                  ? {
                      label: row.competitor.hotel.name,
                      value: row.competitor.hotel.id,
                    }
                  : {};
              }

              let testScores = this.state.testScores;

              let min = 0;
              let max = 0;

              let cleaned = [];
              let imputed = [];
              let raw = [];
              let labels = [];
              let missing_prices_near_future_score = "";
              let missing_prices_pattern_score = "";
              let pricing_completeness_score = "";
              let pearson_corr_score = "";
              let unique_value_score = "";
              let missing_similarity_testscores_result = "";
              let imputationStatics = "";
              let priceImputation = this.state.priceImputation;
              let dates = [];
              let graphDate = [];
              if (
                row.competitor &&
                row.competitor.hotel &&
                priceImputation.missing_prices_scores &&
                priceImputation.missing_prices_scores[row.competitor.hotel.id]
              ) {
                missing_prices_near_future_score =
                  priceImputation.missing_prices_scores[row.competitor.hotel.id]
                    .missing_prices_near_future_score;
              }

              if (
                row.competitor &&
                row.competitor.hotel &&
                priceImputation.missing_prices_scores &&
                priceImputation.missing_prices_scores[row.competitor.hotel.id]
              ) {
                missing_prices_pattern_score =
                  priceImputation.missing_prices_scores[row.competitor.hotel.id]
                    .missing_prices_pattern_score;
              }

              if (
                row.competitor &&
                row.competitor.hotel &&
                priceImputation.missing_prices_scores &&
                priceImputation.missing_prices_scores[row.competitor.hotel.id]
              ) {
                pricing_completeness_score =
                  priceImputation.missing_prices_scores[row.competitor.hotel.id]
                    .pricing_completeness_score;
              }
              if (
                row.competitor &&
                row.competitor.hotel &&
                priceImputation.similarity_scores &&
                priceImputation.similarity_scores[row.competitor.hotel.id]
              ) {
                pearson_corr_score =
                  priceImputation.similarity_scores[row.competitor.hotel.id]
                    .pearson_corr_score;
              }

              if (
                row.competitor &&
                row.competitor.hotel &&
                priceImputation.similarity_scores &&
                priceImputation.similarity_scores[row.competitor.hotel.id]
              ) {
                unique_value_score =
                  priceImputation.similarity_scores[row.competitor.hotel.id]
                    .unique_value_score;
              }

              if (
                row.competitor &&
                row.competitor.hotel &&
                priceImputation.missing_similarity_testscores_result
              ) {
                missing_similarity_testscores_result =
                  priceImputation.missing_similarity_testscores_result[
                    row.competitor.hotel.id
                  ];
              }

              if (
                row.competitor &&
                row.competitor.hotel &&
                priceImputation.amalgamation_imputed_price
              ) {
                Object.keys(priceImputation.amalgamation_imputed_price).map(
                  (key) => {
                    if (
                      priceImputation.amalgamation_imputed_price[key] &&
                      priceImputation.amalgamation_imputed_price[key][
                        row.competitor.hotel.id
                      ]
                    ) {
                      dates.push(key);
                      graphDate.push(moment(key).format("ddd, D MMM YY"));
                      labels.push("");
                      if (
                        priceImputation.amalgamation_imputed_price[key][
                          row.competitor.hotel.id
                        ]
                      ) {
                        cleaned.push(
                          priceImputation.amalgamation_imputed_price[key][
                            row.competitor.hotel.id
                          ].cleaned
                        );
                        imputed.push(
                          priceImputation.amalgamation_imputed_price[key][
                            row.competitor.hotel.id
                          ].imputed
                        );
                        raw.push(
                          priceImputation.amalgamation_imputed_price[key][
                            row.competitor.hotel.id
                          ].raw
                        );

                        if (
                          !isNaN(
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].imputed
                          ) &&
                          (min == 0 ||
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].cleaned < min)
                        ) {
                          min =
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].cleaned;
                        }
                        if (
                          !isNaN(
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].imputed
                          ) &&
                          (min == 0 ||
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].imputed < min)
                        ) {
                          min =
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].imputed;
                        }
                        if (
                          !isNaN(
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].raw
                          ) &&
                          (min == 0 ||
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].raw < min)
                        ) {
                          min =
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].raw;
                        }

                        if (
                          !isNaN(
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].cleaned
                          ) &&
                          (max == 0 ||
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].cleaned > max)
                        ) {
                          max =
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].cleaned;
                        }
                        if (
                          !isNaN(
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].imputed
                          ) &&
                          (max == 0 ||
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].imputed > max)
                        ) {
                          max =
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].imputed;
                        }
                        if (
                          !isNaN(
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].raw
                          ) &&
                          (max == 0 ||
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].raw > max)
                        ) {
                          max =
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].raw;
                        }
                      }
                    }
                  }
                );
              }

              if (
                row.competitor &&
                row.competitor.hotel &&
                priceImputation.imputation_statics
              ) {
                imputationStatics =
                  priceImputation.imputation_statics[row.competitor.hotel.id];
              }
              if (row.competitor) {
                max = parseInt(1.15 * max);
                min = parseInt(0.9 * min);
              }
              const options = {
                legend: {
                  display: false,
                },
                responsive: true,
                title: {
                  display: false,
                  text: "Chart.js Line Chart",
                },
                tooltips: {
                  mode: "label",
                },
                // hover: {
                //     mode: 'dataset'
                // },
                scales: {
                  xAxes: [
                    {
                      display: false,
                    },
                  ],
                  yAxes: [
                    {
                      display: true,
                      scaleLabel: {
                        show: true,
                        labelString: "Value",
                      },
                      ticks: {
                        suggestedMin: min,
                        suggestedMax: max,
                        callback: (label, index, labels) => {
                          return "£" + label;
                        },
                      },
                    },
                  ],
                },
                tooltips: {
                  enabled: true,
                  mode: "single",
                  callbacks: {
                    label: (tooltipItems, data) => {
                      return (
                        (data.datasets[0].extra[tooltipItems.index]
                          ? data.datasets[0].extra[tooltipItems.index]
                          : "-") +
                        ", Value:" +
                        tooltipItems.value
                      );
                    },
                  },
                },
              };

              const data = {
                labels: graphDate,
                datasets: [
                  {
                    label: t("raw"),
                    fill: false,
                    borderWidth: 2,
                    lineTension: 0.8,
                    backgroundColor: "rgb(75,192,192)",
                    borderColor: "rgba(75,192,192,1)",
                    borderCapStyle: "butt",
                    borderDash: [],
                    borderDashOffset: 0.8,
                    borderJoinStyle: "miter",
                    pointBorderColor: "rgba(75,192,192,1)",
                    pointBackgroundColor: "rgb(75,192,192)",
                    pointBorderWidth: 0.8,
                    pointHoverRadius: 0.8,
                    pointHoverBackgroundColor: "rgba(75,192,192,1)",
                    pointHoverBorderColor: "rgba(75,192,192,1)",
                    pointHoverBorderWidth: 0.8,
                    pointRadius: 0.8,
                    pointHitRadius: 4,
                    data: raw,
                    extra: graphDate,
                  },
                ],
              };

              const data1 = {
                labels: graphDate,
                datasets: [
                  {
                    label: t("cleaned"),
                    fill: false,
                    borderWidth: 2,
                    lineTension: 0.8,
                    backgroundColor: "rgba(75,192,192,1)",
                    borderColor: "rgba(75,192,192,1)",
                    borderCapStyle: "butt",
                    borderDash: [],
                    borderDashOffset: 0.8,
                    borderJoinStyle: "miter",
                    pointBorderColor: "rgba(75,192,192,1)",
                    pointBackgroundColor: "rgba(75,192,192,1)",
                    pointBorderWidth: 0.8,
                    pointHoverRadius: 0.8,
                    pointHoverBackgroundColor: "rgba(75,192,192,1)",
                    pointHoverBorderColor: "rgba(75,192,192,1)",
                    pointHoverBorderWidth: 0.8,
                    pointRadius: 0.8,
                    pointHitRadius: 0.8,
                    data: cleaned,
                    extra: dates,
                  },
                ],
              };

              const data2 = {
                labels: graphDate,
                datasets: [
                  {
                    label: t("imputed"),
                    fill: false,
                    borderWidth: 2,
                    lineTension: 0.8,
                    backgroundColor: "rgba(75,192,192,1)",
                    borderColor: "rgba(75,192,192,1)",
                    borderCapStyle: "butt",
                    borderDash: [],
                    borderDashOffset: 0.8,
                    borderJoinStyle: "miter",
                    pointBorderColor: "rgba(75,192,192,1)",
                    pointBackgroundColor: "rgba(75,192,192,1)",
                    pointBorderWidth: 0.8,
                    pointHoverRadius: 0.8,
                    pointHoverBackgroundColor: "rgba(75,192,192,1)",
                    pointHoverBorderColor: "rgba(75,192,192,1)",
                    pointHoverBorderWidth: 0.8,
                    pointRadius: 0.8,
                    pointHitRadius: 0.8,
                    data: imputed,
                    extra: dates,
                  },
                ],
              };
              return (
                <TableRow key={index}>
                  <TableCell className={classes.tableCellRoot}>
                    <Typography>{row.index}</Typography>
                  </TableCell>
                  <TableCell className={classes.tableCellRoot}>
                    <Typography>{row.selected.label}</Typography>
                  </TableCell>
                  <TableCell className={classes.tableCellRoot} align="center">
                    <table className={classes.tableRoot}>
                      <tr>
                        <td className={classes.tablerow}>
                          {t("Pricing Completeness")}:
                        </td>
                        <td className={classes.tablerowValue}>
                          {pricing_completeness_score
                            ? isNaN(pricing_completeness_score)
                              ? pricing_completeness_score
                              : pricing_completeness_score.toFixed(2)
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.tablerow}>
                          {t("P.C. Near Future")}:
                        </td>
                        <td className={classes.tablerowValue}>
                          {missing_prices_near_future_score
                            ? isNaN(missing_prices_near_future_score)
                              ? missing_prices_near_future_score
                              : missing_prices_near_future_score.toFixed(2)
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.tablerow}>
                          {t("N.A. Patterns")}:
                        </td>
                        <td className={classes.tablerowValue}>
                          {missing_prices_pattern_score
                            ? isNaN(missing_prices_pattern_score)
                              ? missing_prices_pattern_score
                              : missing_prices_pattern_score.toFixed(2)
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <td className={classes.tablerow}>
                          {t("Pearson Corr Score")}:
                        </td>
                        <td className={classes.tablerowValue}>
                          {pearson_corr_score
                            ? isNaN(pearson_corr_score)
                              ? pearson_corr_score
                              : pearson_corr_score.toFixed(2)
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.tablerow}>
                          {t("Unique Value Score")}:
                        </td>
                        <td className={classes.tablerowValue}>
                          {unique_value_score
                            ? isNaN(unique_value_score)
                              ? unique_value_score
                              : unique_value_score.toFixed(2)
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.tablerow}>
                          {t("Final Test Score")}:
                        </td>
                        <td className={classes.tablerowValue}>
                          {missing_similarity_testscores_result
                            ? isNaN(missing_similarity_testscores_result)
                              ? missing_similarity_testscores_result
                              : missing_similarity_testscores_result.toFixed(2)
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.tablerow}>
                          {t("Validation Check")}:
                        </td>
                        <td className={classes.tablerowValue}>
                          {imputationStatics}
                        </td>
                      </tr>
                    </table>
                  </TableCell>
                  <TableCell className={classes.tableCellRoot} align="center">
                    <table className={classes.tableRoot2}>
                      <tr>
                        <td className={classes.tablerow}>
                          {t("Number of prices")}:
                        </td>
                        <td className={classes.tablerowValue}>
                          {this.state.nearestData
                            ? row.competitor_data
                              ? row.competitor_data.number_of_price
                              : "-"
                            : row.competitor
                            ? row.competitor.number_of_price
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.tablerow}>
                          {t("Prices scraped within last 4 weeks")}:
                        </td>
                        <td className={classes.tablerowValue}>
                          {this.state.nearestData
                            ? row.competitor_data
                              ? row.competitor_data.number_of_price_4_week
                              : "-"
                            : row.competitor
                            ? row.competitor.number_of_price_4_week
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.tablerow}>{t("Min Price")}:</td>
                        <td className={classes.tablerowValue}>
                          {this.state.nearestData
                            ? row.competitor_data
                              ? row.competitor_data.min_price
                                ? isNaN(row.competitor_data.min_price)
                                  ? "£" + row.competitor_data.min_price
                                  : "£" +
                                    row.competitor_data.min_price.toFixed(2)
                                : "-"
                              : "-"
                            : row.competitor
                            ? row.competitor.min_price
                              ? isNaN(row.competitor.min_price)
                                ? "£" + row.competitor.min_price
                                : "£" + row.competitor.min_price.toFixed(2)
                              : "-"
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.tablerow}>{t("Max Price")}:</td>
                        <td className={classes.tablerowValue}>
                          {this.state.nearestData
                            ? row.competitor_data
                              ? row.competitor_data.max_price
                                ? isNaN(row.competitor_data.max_price)
                                  ? "£" + row.competitor_data.max_price
                                  : row.competitor_data.max_price.toFixed(2)
                                : "-"
                              : "-"
                            : row.competitor
                            ? row.competitor.max_price
                              ? isNaN(row.competitor.max_price)
                                ? row.competitor.max_price
                                : "£" + row.competitor.max_price.toFixed(2)
                              : "-"
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.tablerow}>
                          {t("Average Price")}:
                        </td>
                        <td className={classes.tablerowValue}>
                          {this.state.nearestData
                            ? row.competitor_data
                              ? row.competitor_data.avg_price
                                ? isNaN(row.competitor_data.avg_price)
                                  ? "£" + row.competitor_data.avg_price
                                  : row.competitor_data.avg_price.toFixed(2)
                                : "-"
                              : "-"
                            : row.competitor
                            ? row.competitor.avg_price
                              ? "£" + row.competitor.avg_price.toFixed(2)
                              : "-"
                            : "-"}
                          <>
                            <br />({"£" + row.imputed_average})
                          </>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.tablerow}>
                          {t("Price Diff vs Comp")}:
                        </td>
                        <td className={classes.tablerowValue}>
                          {row.pricediff
                            ? (isNaN(row.pricediff)
                                ? row.pricediff + "%"
                                : parseFloat(row.pricediff).toFixed(2)) + "%"
                            : "-"}
                          <>
                            <br />(
                            {row.imputed_pricediff
                              ? row.imputed_pricediff + "%"
                              : ""}
                            )
                          </>
                        </td>
                      </tr>
                    </table>
                  </TableCell>
                  {/* <TableCell className={classes.tableCellRoot} align="center">
                                    {imputationStatics}
                                </TableCell> */}
                  <TableCell className={classes.tableCellRoot} align="center">
                    <Button
                      onClick={() =>
                        this.getToggalGraph(data, options, min, max, row)
                      }
                    >
                      <Line
                        height="150px"
                        width="200px"
                        data={data}
                        options={options}
                      />
                    </Button>
                  </TableCell>
                  <TableCell className={classes.tableCellRoot} align="center">
                    <Button
                      onClick={() =>
                        this.getToggalGraph(data1, options, min, max, row)
                      }
                    >
                      <Line
                        height="150px"
                        width="200px"
                        data={data1}
                        options={options}
                      />
                    </Button>
                  </TableCell>
                  <TableCell className={classes.tableCellRoot} align="center">
                    <Button
                      onClick={() =>
                        this.getToggalGraph(data2, options, min, max, row)
                      }
                    >
                      <Line
                        height="150px"
                        width="200px"
                        data={data2}
                        options={options}
                      />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          {Modals.graphDetails(this)}
        </TableBody>
      </Table>
    );
  };

  renderCompetitorTable = () => {
    let rows = [];
    const { classes } = this.props;
    const { t } = this.props;
    if (this.state.competitorData) {
      rows = this.state.competitorData;
    }
    const customStyles = {
      loadingIndicator: (provided, state) => ({
        ...provided,
        display: this.state.loadingIndicator == true ? "unset" : "none",
      }),
    };

    return (
      <Table className={classes.userTable}>
        <TableHead>
          <TableRow>
            <TableCell
              className={classes.tableCellRoot}
              style={{ width: "10%" }}
              align="left"
            >
              {t("Index")}
            </TableCell>
            <TableCell
              className={classes.tableCellRoot}
              style={{ width: "25%" }}
            >
              {t("Hotel")}
            </TableCell>
            <TableCell className={classes.tableCellRoot} align="left">
              {t("Weight of Comp Hotel")}
            </TableCell>
            <TableCell className={classes.tableCellRoot} align="center">
              {t("Graph `cleaned`")}
            </TableCell>
            <TableCell className={classes.tableCellRoot} align="left">
              {t("Last Scraped")}
            </TableCell>
            {/* <TableCell className={classes.tableCellRoot} align="right">{t("Number of Prices")}</TableCell>
                        <TableCell className={classes.tableCellRoot} align="right">{t("Prices scraped within last 4 weeks")}</TableCell>
                        <TableCell className={classes.tableCellRoot} align="right">{t("Max Price")}</TableCell>
                        <TableCell className={classes.tableCellRoot} align="right">{t("Min Price")}</TableCell>
                        {!this.state.nearestData &&
                            <TableCell className={classes.tableCellRoot}
                            align="right">{t("Price Diff vs Competitor")}</TableCell>
                        }

                        <TableCell className={classes.tableCellRoot} align="right">{t("Average Price")}</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.map((row, index) => {
              var dayDiff = 0;
              if (row.competitor && row.competitor.last_updated_at) {
                var startDate = moment(
                  moment(row.competitor.last_updated_at).format("DD.MM.YYYY"),
                  "DD.MM.YYYY"
                );
                var endDate = moment(
                  moment().format("DD.MM.YYYY"),
                  "DD.MM.YYYY"
                );
                dayDiff = endDate.diff(startDate, "days");
              }

              let scrapColor = "";
              if (dayDiff > 5) {
                scrapColor = "red";
              } else {
                scrapColor = "green";
              }

              if (this.state.nearestData && !this.state.loadNearData) {
                row.selected = { label: row.name, value: row.id };
              } else {
                row.selected = row.competitor
                  ? {
                      label: row.competitor.hotel.name,
                      value: row.competitor.hotel.id,
                    }
                  : {};
              }
              let min = 0;
              let max = 0;

              let cleaned = [];
              let imputed = [];
              let raw = [];
              let labels = [];
              let missing_prices_near_future_score = "";
              let missing_prices_pattern_score = "";
              let pricing_completeness_score = "";
              let pearson_corr_score = "";
              let unique_value_score = "";
              let missing_similarity_testscores_result = "";
              let imputationStatics = "";
              let priceImputation = this.state.priceImputation;
              let dates = [];
              let graphDate = [];
              if (
                row.competitor &&
                row.competitor.hotel &&
                priceImputation.missing_prices_scores &&
                priceImputation.missing_prices_scores[row.competitor.hotel.id]
              ) {
                missing_prices_near_future_score =
                  priceImputation.missing_prices_scores[row.competitor.hotel.id]
                    .missing_prices_near_future_score;
              }

              if (
                row.competitor &&
                row.competitor.hotel &&
                priceImputation.missing_prices_scores &&
                priceImputation.missing_prices_scores[row.competitor.hotel.id]
              ) {
                missing_prices_pattern_score =
                  priceImputation.missing_prices_scores[row.competitor.hotel.id]
                    .missing_prices_pattern_score;
              }

              if (
                row.competitor &&
                row.competitor.hotel &&
                priceImputation.missing_prices_scores &&
                priceImputation.missing_prices_scores[row.competitor.hotel.id]
              ) {
                pricing_completeness_score =
                  priceImputation.missing_prices_scores[row.competitor.hotel.id]
                    .pricing_completeness_score;
              }
              if (
                row.competitor &&
                row.competitor.hotel &&
                priceImputation.similarity_scores &&
                priceImputation.similarity_scores[row.competitor.hotel.id]
              ) {
                pearson_corr_score =
                  priceImputation.similarity_scores[row.competitor.hotel.id]
                    .pearson_corr_score;
              }

              if (
                row.competitor &&
                row.competitor.hotel &&
                priceImputation.similarity_scores &&
                priceImputation.similarity_scores[row.competitor.hotel.id]
              ) {
                unique_value_score =
                  priceImputation.similarity_scores[row.competitor.hotel.id]
                    .unique_value_score;
              }

              if (
                row.competitor &&
                row.competitor.hotel &&
                priceImputation.missing_similarity_testscores_result
              ) {
                missing_similarity_testscores_result =
                  priceImputation.missing_similarity_testscores_result[
                    row.competitor.hotel.id
                  ];
              }

              if (
                row.competitor &&
                row.competitor.hotel &&
                priceImputation.amalgamation_imputed_price
              ) {
                Object.keys(priceImputation.amalgamation_imputed_price).map(
                  (key) => {
                    if (
                      priceImputation.amalgamation_imputed_price[key] &&
                      priceImputation.amalgamation_imputed_price[key][
                        row.competitor.hotel.id
                      ]
                    ) {
                      dates.push(key);
                      graphDate.push(moment(key).format("ddd, D MMM YY"));
                      labels.push("");
                      if (
                        priceImputation.amalgamation_imputed_price[key][
                          row.competitor.hotel.id
                        ]
                      ) {
                        cleaned.push(
                          priceImputation.amalgamation_imputed_price[key][
                            row.competitor.hotel.id
                          ].cleaned
                        );
                        imputed.push(
                          priceImputation.amalgamation_imputed_price[key][
                            row.competitor.hotel.id
                          ].imputed
                        );
                        raw.push(
                          priceImputation.amalgamation_imputed_price[key][
                            row.competitor.hotel.id
                          ].raw
                        );

                        if (
                          !isNaN(
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].imputed
                          ) &&
                          (min == 0 ||
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].cleaned < min)
                        ) {
                          min =
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].cleaned;
                        }
                        if (
                          !isNaN(
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].imputed
                          ) &&
                          (min == 0 ||
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].imputed < min)
                        ) {
                          min =
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].imputed;
                        }
                        if (
                          !isNaN(
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].raw
                          ) &&
                          (min == 0 ||
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].raw < min)
                        ) {
                          min =
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].raw;
                        }

                        if (
                          !isNaN(
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].cleaned
                          ) &&
                          (max == 0 ||
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].cleaned > max)
                        ) {
                          max =
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].cleaned;
                        }
                        if (
                          !isNaN(
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].imputed
                          ) &&
                          (max == 0 ||
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].imputed > max)
                        ) {
                          max =
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].imputed;
                        }
                        if (
                          !isNaN(
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].raw
                          ) &&
                          (max == 0 ||
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].raw > max)
                        ) {
                          max =
                            priceImputation.amalgamation_imputed_price[key][
                              row.competitor.hotel.id
                            ].raw;
                        }
                      }
                    }
                  }
                );
              }

              if (
                row.competitor &&
                row.competitor.hotel &&
                priceImputation.imputation_statics
              ) {
                imputationStatics =
                  priceImputation.imputation_statics[row.competitor.hotel.id];
              }
              if (row.competitor) {
                max = parseInt(1.15 * max);
                min = parseInt(0.9 * min);
              }
              const data2 = {
                labels: graphDate,
                datasets: [
                  {
                    label: t("cleaned"),
                    fill: false,
                    borderWidth: 2,
                    lineTension: 0.8,
                    backgroundColor: "rgba(75,192,192,1)",
                    borderColor: "rgba(75,192,192,1)",
                    borderCapStyle: "butt",
                    borderDash: [],
                    borderDashOffset: 0.8,
                    borderJoinStyle: "miter",
                    pointBorderColor: "rgba(75,192,192,1)",
                    pointBackgroundColor: "rgba(75,192,192,1)",
                    pointBorderWidth: 0.8,
                    pointHoverRadius: 0.8,
                    pointHoverBackgroundColor: "rgba(75,192,192,1)",
                    pointHoverBorderColor: "rgba(75,192,192,1)",
                    pointHoverBorderWidth: 0.8,
                    pointRadius: 0.8,
                    pointHitRadius: 0.8,
                    data: cleaned,
                    extra: dates,
                  },
                ],
              };
              const optionss = {
                legend: {
                  display: false,
                },
                responsive: true,
                title: {
                  display: false,
                  text: "Chart.js Line Chart",
                },
                tooltips: {
                  mode: "label",
                },
                // hover: {
                //     mode: 'dataset'
                // },
                scales: {
                  xAxes: [
                    {
                      display: false,
                    },
                  ],
                  yAxes: [
                    {
                      display: true,
                      scaleLabel: {
                        show: true,
                        labelString: "Value",
                      },
                      ticks: {
                        suggestedMin: min,
                        suggestedMax: max,
                        callback: (label, index, labels) => {
                          return "£" + label;
                        },
                      },
                    },
                  ],
                },
                tooltips: {
                  enabled: true,
                  mode: "single",
                  callbacks: {
                    label: (tooltipItems, data) => {
                      return (
                        (data.datasets[0].extra[tooltipItems.index]
                          ? data.datasets[0].extra[tooltipItems.index]
                          : "-") +
                        ", Value:" +
                        tooltipItems.value
                      );
                    },
                  },
                },
              };
              return (
                <TableRow key={index}>
                  <TableCell className={classes.tableCellRoot}>
                    <InputBase
                      onChange={(event) =>
                        this.handleCompetitorIndex(index, event.target.value)
                      }
                      error={this.state.indexError.includes(index)}
                      inputProps={{
                        type: "number",
                        style: { textAlign: "left", paddingLeft: "6px" },
                      }}
                      className={[
                        classes.tableIndexTextFields,
                        classes.baseInput,
                      ]}
                      value={row.index}
                    />
                    {this.state.indexError.includes(index) && (
                      <FormHelperText>
                        <span class="error-message">
                          {t("* Please enter the value")}
                        </span>
                      </FormHelperText>
                    )}
                    {this.state.uniqueIndexError.includes(index) && (
                      <FormHelperText>
                        <span class="error-message">
                          {t("* Please enter the unique value")}
                        </span>
                      </FormHelperText>
                    )}
                    {this.state.moreIndexError.includes(index) && (
                      <FormHelperText>
                        <span class="error-message">
                          {t("* Index must be less than 20. ")}
                        </span>
                      </FormHelperText>
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCellRoot}>
                    <div>
                      <AsyncCreatableSelect
                        styles={customStyles}
                        onChange={(selHotel, selection) =>
                          this.handleHotelChange(selHotel, selection, index)
                        }
                        value={row.selected}
                        loadOptions={(input, callback) =>
                          this.hotelOptions(input, callback)
                        }
                        className={classes.pullLeft}
                        formatCreateLabel={() => false}
                        isClearable
                        menuPosition="fixed"
                        menuPlacement={index > 5 ? "top" : "bottom"}
                        maxMenuHeight="200"
                      />
                    </div>
                    <div className={classes.addressDiv}>
                      {this.state.nearestData
                        ? row.address
                        : row.competitor
                        ? row.competitor.hotel.address
                        : "-"}
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableCellRoot} align="left">
                    <Select
                      value={
                        row.weight || row.weight == 0
                          ? row.weight
                          : this.handleCompetitor(index, 20)
                      }
                      onChange={(event) =>
                        this.handleCompetitor(index, event.target.value)
                      }
                      select
                    >
                      {this.state.Weight_of_comp.map((val) => (
                        <MenuItem value={val}>{val}</MenuItem>
                      ))}
                    </Select>
                    {/* <InputBase onChange={(event) => this.handleCompetitor(index, event.target.value)}
                                        error={true}
                                        inputProps={{ type: 'number', style: { textAlign: 'left',paddingLeft:"6px"  } }}
                                        className={classes.baseInput}
                                        className={classes.tableTextFields}
                                        value={(row.weight || row.weight == 0) ? row.weight : this.handleCompetitor(index, 20)} 
                                        /> */}
                    {this.state.error.includes(index) && (
                      <FormHelperText>
                        <span class="error-message">
                          {t("* Please enter the value")}
                        </span>
                      </FormHelperText>
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCellRoot} align="center">
                    <Button
                      onClick={() =>
                        this.getToggalGraph(data2, optionss, min, max, row)
                      }
                    >
                      <Line
                        height="150px"
                        width="300px"
                        data={data2}
                        options={optionss}
                      />
                    </Button>
                  </TableCell>
                  <TableCell className={classes.tableCellRoot} align="left">
                    {!this.state.nearestData && row.competitor && (
                      <>
                        {row.competitor.last_updated_at ? (
                          <span style={{ color: scrapColor }}>
                            {moment(row.competitor.last_updated_at).format(
                              "DD-MM-YYYY HH:mm"
                            )}
                          </span>
                        ) : (
                          "-"
                        )}
                      </>
                    )}
                  </TableCell>
                  {/* <TableCell className={classes.tableCellRoot} align="right">
                                    {this.state.nearestData ? (row.competitor_data ? row.competitor_data.number_of_price : '-') : (row.competitor ? row.competitor.number_of_price : '-')}
                                </TableCell>
                                <TableCell className={classes.tableCellRoot} align="right">
                                    {this.state.nearestData ? (row.competitor_data ? row.competitor_data.number_of_price_4_week : '-') : (row.competitor ? row.competitor.number_of_price_4_week : '-')}
                                </TableCell>
                                <TableCell className={classes.tableCellRoot} align="right">
                                    {this.state.nearestData ? (row.competitor_data ? ((row.competitor_data.max_price) ? (isNaN(row.competitor_data.max_price) ? row.competitor_data.max_price : row.competitor_data.max_price.toFixed(2)) : '-') : '-') : (row.competitor ? ((row.competitor.max_price) ? (isNaN(row.competitor.max_price) ? row.competitor.max_price : row.competitor.max_price.toFixed(2)) : '-') : '-')}
                                </TableCell>
                                <TableCell className={classes.tableCellRoot} align="right">
                                    {this.state.nearestData ? (row.competitor_data ? ((row.competitor_data.min_price) ? (isNaN(row.competitor_data.min_price) ? row.competitor_data.min_price : row.competitor_data.min_price.toFixed(2)) : '-') : '-') : (row.competitor ? ((row.competitor.min_price) ? (isNaN(row.competitor.min_price) ? row.competitor.min_price : row.competitor.min_price.toFixed(2)) : '-') : '-')}
                                </TableCell>
                                {!this.state.nearestData &&
                                    <TableCell className={classes.tableCellRoot} align="right">
                                        {row.pricediff ? (isNaN(row.pricediff) ? row.pricediff + '%' : parseFloat(row.pricediff).toFixed(2)) + '%' : '-'}
                                        {this.state.featuresEnabled.includes(11) &&
                                        <><br />
                                            ({row.imputed_pricediff? row.imputed_pricediff + '%':''})
                                        </>
                                    }
                                    </TableCell>
                                }
                                
                                {this.state.nearestData && row.competitor_data &&
                                    <TableCell className={classes.tableCellRoot} align="right">
                                        {row.competitor_data.last_updated_at ? moment(row.competitor_data.last_updated_at).format('DD-MM-YYYY HH:mm') : "-"}
                                    </TableCell>

                                }
                                <TableCell className={classes.tableCellRoot} align="right">
                                    {this.state.nearestData ? (row.competitor_data ? ((row.competitor_data.avg_price) ? (isNaN(row.competitor_data.avg_price) ? row.competitor_data.avg_price : row.competitor_data.avg_price.toFixed(2)) : '-') : '-') : (row.competitor ? ((row.competitor.avg_price) ? row.competitor.avg_price.toFixed(2) : '-') : '-')}
                                    {this.state.featuresEnabled.includes(11) &&
                                    <><br />
                                        ({row.imputed_average})
                                    </>
                                }
                                </TableCell> */}
                </TableRow>
              );
            })}
          {Modals.graphDetails(this)}
        </TableBody>
      </Table>
    );
  };

  renderBookingCompetitor = () => {
    let rows = [];
    const { classes } = this.props;
    const { t } = this.props;

    if (this.state.bookingCompetitor && this.state.bookingCompetitor.client) {
      rows = this.state.bookingCompetitor.competitors;
    } else {
      return null;
    }
    return (
      <Table className={classes.userTable}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCellRoot} align="center">
              {t("Hotel Name")}
            </TableCell>
            <TableCell className={classes.tableCellRoot}>
              {t("Property Type")}
            </TableCell>
            <TableCell className={classes.tableCellRoot} align="center">
              {t("Score")}
            </TableCell>
            <TableCell className={classes.tableCellRoot} align="center">
              {t("Business/Leasure")}
            </TableCell>
            <TableCell className={classes.tableCellRoot} align="center">
              {t("Hotel Chain")}
            </TableCell>
            <TableCell className={classes.tableCellRoot}>
              {t("Density")}
            </TableCell>
            <TableCell className={classes.tableCellRoot} align="center">
              {t("Propfactor")}
            </TableCell>
            <TableCell className={classes.tableCellRoot} align="center">
              {t("Reviews")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell className={classes.tableCellRoot}>
                    {row.hotel_name}
                  </TableCell>
                  <TableCell className={classes.tableCellRoot} align="center">
                    {row.property_name}
                  </TableCell>
                  <TableCell className={classes.tableCellRoot} align="center">
                    {row.predictedy}
                  </TableCell>
                  <TableCell className={classes.tableCellRoot} align="center">
                    {row.businessleisurefactor4}
                  </TableCell>
                  <TableCell className={classes.tableCellRoot} align="center">
                    {row.chainfactor3}
                  </TableCell>
                  <TableCell className={classes.tableCellRoot} align="center">
                    {row.densityfactor10}
                  </TableCell>
                  <TableCell className={classes.tableCellRoot} align="center">
                    {row.propfactor1}
                  </TableCell>
                  <TableCell className={classes.tableCellRoot} align="center">
                    {row.reviewscorefactor6}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    );
  };

  confirmScrape = () => {
    this.setState({
      openconfirmScrapeModal: !this.state.openconfirmScrapeModal,
      scrapeConfirmModalData: {
        ...this.state.scrapeConfirmModalData,
      },
    });
  };
  confirmScrapeTop20 = () => {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
    };
    this.updateCompetitors();
    setTimeout(() => {
      this.props.getScrapeTop20Competitors(requestParam);
    }, 2000);
  };
  toggleGraph = () => {
    this.setState({
      showGraph: !this.state.showGraph,
    });
  };

  handleTabs = (event, newValue) => {
    this.setState({
      tab: newValue,
    });
  };

  resetPmsPriceOccupancy = () => {
    let start_date = moment
      .utc(this.props.hotelAuthReducer.utcTime)
      .tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone)
      .format("YYYY-MM-DD");
    let end_date = moment(start_date).add("365", "d").format("YYYY-MM-DD");

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      start_date: start_date,
      end_date: end_date,
      return_full_dates: false,
    };

    this.props.resetPmsPriceOccupancy(
      requestParam,
      this.resetPmsPriceOccupancyLoader
    );

    this.setState({
      loadPriceOcc: true,
      confirmReset: false,
    });
  };

  resetPmsPrice = () => {
    let start_date = moment
      .utc(this.props.hotelAuthReducer.utcTime)
      .tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone)
      .format("YYYY-MM-DD");
    let end_date = moment(start_date).add("365", "d").format("YYYY-MM-DD");

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      start_date: start_date,
      end_date: end_date,
    };
    this.props.resetPmsPrice(requestParam, this.resetPmsPriceLoader);

    this.setState({
      loadPrice: true,
      confirmReset: false,
    });
  };

  resetPmsPriceOccupancyLoader = () => {
    this.setState({
      loadPriceOcc: !this.state.loadPriceOcc,
    });
    this.getCacheData();
  };

  resetPmsPriceLoader = () => {
    this.setState({
      loadPrice: !this.state.loadPrice,
    });
    this.getCacheData();
  };

  toggleConfirmReset = (intent) => {
    this.setState({
      confirmReset: !this.state.confirmReset,
      intent: intent,
    });
  };

  handlePubpWeight = (value) => {
    this.setState({
      pubp_today_weight: value,
    });
  };

  handleAirdna = (value, name) => {
    this.setState({
      model_weights: {
        ...this.state.model_weights,
        [name]: value,
      },
    });
  };

  // saveWeight = (value) => {
  //     this.setState({
  //         weightLoader: true
  //     })

  //     let request = {
  //         token: this.props.hotelAuthReducer.hotelDetails.token,
  //         value: parseFloat(this.state.pubp_today_weight)
  //     }
  //     this.props.updateSettingJson(request, null, null)
  // }

  saveAirDnaWeight = (value) => {
    this.setState({
      weightLoader: true,
    });

    let request = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      value: {
        competitor_model_weight: parseFloat(
          this.state.model_weights.competitor_model_weight
        ),
        pubp_model_weight: parseFloat(
          this.state.model_weights.pubp_model_weight
        ),
        airDNA_model_weight: parseFloat(
          this.state.model_weights.airDNA_model_weight
        ),
      },
    };
    this.props.updateSettingAirDnaJson(request, null, null);
  };

  renderBoostGraph = () => {
    if (!this.state.boostPrice) {
      return <></>;
    }

    const options = {
      maintainAspectRatio: false,
      legend: {
        labels: {
          boxWidth: 11,
        },
      },
      responsive: true,
      scales: {
        xAxes: [
          {
            type: "time",
            time: {
              parser: "ddd, D MMM YY",
              unit: "month",
              unitStepSize: 1,
            },
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              callback: (label, index, labels) => {
                return (
                  this.props.hotelAuthReducer.hotelDetails.hotel.currency
                    .symbol + label
                );
              },
            },
            scaleLabel: {
              bounds: "xs",
              display: true,
              labelString: "",
            },
          },
        ],
      },
      tooltips: {
        enabled: true,
        mode: "single",
        callbacks: {
          label: (tooltipItems, data) => {
            return (
              "Pickup Boost Price: " +
              this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol +
              data.datasets[0].extra[tooltipItems.index]
            );
          },
        },
      },
    };

    let graphDate = [];
    let raw = [];
    let current = [];

    let totalDates = 365;

    if (this.state.featuresEnabled.includes(23)) {
      totalDates = 545;
    }

    for (let i = 0; i < totalDates; i++) {
      let dates = moment().add(i, "days").format("YYYY-MM-DD");
      graphDate.push(moment(dates).format("ddd, D MMM YY"));
      if (this.state.boostPrice[dates]) {
        raw.push(this.state.boostPrice[dates].pickup_boost_price);
        current.push(this.state.boostPrice[dates].arguments.current_pms_price);
      } else {
        raw.push(null);
        current.push(null);
      }
    }

    // Object.keys(this.state.boostPrice).map((key) => {
    //     graphDate.push(moment(key).format('ddd, D MMM YY'));
    //     raw.push(this.state.boostPrice[key].pickup_boost_price);
    //     current.push(this.state.boostPrice[key].arguments.current_pms_price);
    // })

    const data = {
      labels: graphDate,
      datasets: [
        {
          label: "Pickup Boost Price",
          fill: false,
          borderWidth: 2,
          lineTension: 0.8,
          backgroundColor: "rgb(75,192,192)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.8,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "rgba(75,192,192,1)",
          pointBorderWidth: 0.8,
          pointHoverRadius: 0.8,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(75,192,192,1)",
          pointHoverBorderWidth: 0.8,
          pointRadius: 0.8,
          pointHitRadius: 4,
          data: raw,
          extra: raw,
        },
        {
          label: "Current Pms Price",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "#d6d6d6",
          borderColor: "#d6d6d6",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "#d6d6d6",
          pointBackgroundColor: "#d6d6d6",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#d6d6d6",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          data: current,
          extra: current,
        },
      ],
    };
    const plugins = [
      {
        beforeInit: function (chart, args, options) {
          chart.legend.afterFit = function () {
            this.height = this.height + 30;
          };
        },
      },
    ];
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.pickupDiv}>
        <Grid container>
          <Grid item xs={12}>
            <Button
              disabled={this.state.loadPrice}
              onClick={() => this.toggleConfirmReset("price")}
              variant="outlined"
              color="primary"
              className={classes.buttonBoost}
            >
              {t("Reset Model to PMS Prices")}{" "}
              {this.state.loadPrice && (
                <CircularProgress
                  color="primary"
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>

            <Button
              disabled={this.state.loadPriceOcc}
              onClick={() => this.toggleConfirmReset("occ")}
              variant="outlined"
              color="primary"
              className={classes.buttonBoost}
            >
              {t("Reset Model to PMS Prices + Occupancy")}{" "}
              {this.state.loadPriceOcc && (
                <CircularProgress
                  color="primary"
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>

            <Button
              disabled={this.state.loadPriceOcc}
              onClick={() => this.download()}
              className={[classes.buttonBoost, classes.textSecondary]}
            >
              {t("Download Log")}
            </Button>
          </Grid>
        </Grid>
        <a id="downloadAnchorElem" className={classes.downloadAnchorElem}></a>
        <div className={classes.chartdiv}>
          <Line data={data} options={options} plugins={plugins} />
        </div>
      </div>
    );
  };

  promiseOptions = (inputValue, callback) => {
    axios.defaults.headers.common["Authorization"] =
      "Token " + this.props.hotelAuthReducer.hotelDetails.token;
    axios
      .post(Server.API + "hotel/get-market-codes/", { term: inputValue })
      .then((response) => {
        let jsx = [];
        if (response.data && response.data.data) {
          response.data.data.map((val) => {
            jsx.push({ label: val.name + ` (${val.city.id}) `, value: val });
          });
        }
        callback(jsx);
      })
      .catch((error) => {});
  };

  saveMarketCode = () => {
    if (this.state.selectedTerm && this.state.selectedTerm.value) {
      if (this.state.selectedTerm.value.name) {
        this.setState({
          airDnaweightLoader: true,
          airDnaError: false,
        });
        let request = {
          token: this.props.hotelAuthReducer.hotelDetails.token,
          data: this.state.selectedTerm.value,
        };
        this.props.saveMarketCode(request, this.closeFindAirDnaCity);
      }
    } else {
      this.setState({
        airDnaError: true,
      });
    }
  };

  handleMarketCode = (inputVal) => {
    this.setState({
      selectedTerm: inputVal,
    });
  };

  // refreshData = () =>{
  //     this.setState({
  //         airDnarefreshData: true
  //     })
  //     let requestParam = { token: this.props.hotelAuthReducer.hotelDetails.token }
  //     this.props.getAirDnaGraphData(requestParam, true);
  // }

  findNearestCity = () => {
    this.setState({
      findNearest: true,
    });
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
    };
    this.props.findNearestCity(requestParam, this.stopNearestLoader);
  };

  stopNearestLoader = () => {
    this.setState({
      findNearest: false,
    });
  };

  closeFindAirDnaCity = () => {
    this.setState({
      findAirDnaCity: false,
      airDnaweightLoader: false,
    });
  };

  toggleFindAirDna = () => {
    this.setState({
      findAirDnaCity: !this.state.findAirDnaCity,
    });
  };

  initPusher = () => {
    this.props.commonReducer.initPusher.bind("competitor.message", (evt) => {
      this.closeFindAirDnaCity();
      this.stopNearestLoader();
      if (evt.success) {
        let requestParam = {
          token: this.props.hotelAuthReducer.hotelDetails.token,
        };
        this.props.getAirDnaGraphData(requestParam, true);
      } else {
        if (evt.message) {
          let requestData = {
            msg: evt.message,
            is_success: false,
          };
          this.props.putNotify(requestData);
        }
      }
    });
  };

  renderAirDna = () => {
    const options = {
      maintainAspectRatio: false,
      legend: {
        labels: {
          boxWidth: 11,
        },
      },
      responsive: true,
      scales: {
        xAxes: [
          {
            type: "time",
            time: {
              parser: "ddd, D MMM YY",
              unit: this.state.showFullYearGraph ? "month" : "day",
              unitStepSize: 1,
            },
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              callback: (label, index, labels) => {
                return (
                  this.props.hotelAuthReducer.hotelDetails.hotel.currency
                    .symbol + label
                );
              },
            },
            scaleLabel: {
              bounds: "xs",
              display: true,
              labelString: "",
            },
          },
        ],
      },
      tooltips: {
        enabled: true,
        mode: "single",
        callbacks: {
          label: (tooltipItems, data) => {
            return (
              "Airbnb Price: " +
              this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol +
              data.datasets[0].extra[tooltipItems.index]
            );
          },
        },
      },
    };

    let graphDate = [];
    let raw = [];
    let current = [];

    let totalDates = 365;

    if (this.state.featuresEnabled.includes(23)) {
      totalDates = 545;
    }

    for (let i = 0; i < totalDates; i++) {
      let dates = moment().add(i, "days").format("YYYY-MM-DD");
      graphDate.push(moment(dates).format("ddd, D MMM YY"));
      if (this.state.airDnaData[dates]) {
        raw.push(this.state.airDnaData[dates].airdna_new_price_for_compset);
      } else {
        raw.push(null);
        current.push(null);
      }
    }

    const data = {
      // labels: graphDate,
      labels: this.state.graphDataArr.graphDate,
      datasets: [
        {
          label: "Airbnb market price",
          fill: false,
          borderWidth: 2,
          lineTension: 0.1,
          backgroundColor: "rgb(75,192,192)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "rgba(75,192,192,1)",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(75,192,192,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 2,
          pointHitRadius: 10,
          // data: raw,
          // extra: raw,

          data: this.state.graphDataArr.raw,
          extra: this.state.graphDataArr.raw,
        },
      ],
    };
    const plugins = [
      {
        beforeInit: function (chart, args, options) {
          chart.legend.afterFit = function () {
            this.height = this.height + 30;
          };
        },
      },
    ];

    const { classes } = this.props;
    const { t } = this.props;

    const SelectStyle = {
      menu: (provided, state) => ({
        ...provided,
        textAlign: "left",
      }),
      control: (base, state) => ({
        ...base,
        marginTop: "20px",
        height: "40px",
        border: "none",
        boxShadow: "0 !important",
        width: "100%",
        minWidth: "350px",
        borderRadius: "4px !important",
        background: "#f2f2f2",
        textAlign: "left",
        "&:hover": {
          border: "none",
        },
        "&:focus-within": {
          border: "none",
        },
        "&:active": {
          border: "none",
        },
      }),
    };

    return (
      <div className={classes.pickupDiv}>
        <Grid container>
          <Grid item xs={12}>
            <Button
              disabled={this.state.findNearest}
              className={classes.savebtnWight1}
              onClick={this.findNearestCity}
              variant="contained"
              color="primary"
            >
              {t("Find nearest city/region")}
              {this.state.findNearest && (
                <CircularProgress
                  color="primary"
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
            <FormControl
              margin="none"
              className={[classes.disabledInput1, classes.disabledInputWidth]}
            >
              <InputLabel
                shrink={true}
                className={classes.settingInputLable}
                htmlFor="name"
              >
                {t(`Airbnb city or region`)}
              </InputLabel>
              <TextField
                disabled={true}
                onChange={this.handleMarketCode}
                value={this.state.selectedTerm?.label}
                placeholder="Enter city or region"
                error={this.state.airDnaError}
                InputLabelProps={{ shrink: true }}
                required={true}
              />
              {this.state.airDnaError && (
                <span class="error-message">
                  {"* Please Enter city or region."}
                </span>
              )}
            </FormControl>
            <FormControl margin="none" className={[classes.disabledInput1]}>
              <InputLabel
                shrink={true}
                className={classes.settingInputLable}
                htmlFor="name"
              >
                {t(`Total Rooms`)}
              </InputLabel>
              <TextField
                style={{ width: "100px" }}
                disabled={true}
                value={this.state.todays_total_rooms}
                InputLabelProps={{ shrink: true }}
                required={true}
              />
            </FormControl>
            <Button
              disabled={this.state.airDnarefreshData}
              variant={"outlined"}
              onClick={() => this.toggleFindAirDna()}
              color="primary"
              className={[classes.savebtnWight1]}
            >
              {t("Manual Search")}
            </Button>
            {/* <Button disabled={this.state.airDnarefreshData} onClick={() => this.refreshData()} color="primary" className={[classes.buttonBoost, classes.savebtnWight]} >
                            {t("Refresh")}
                            {this.state.airDnarefreshData && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                        </Button> */}
            <Button
              disabled={this.state.loadPriceOcc}
              onClick={() => this.downloadAirDna()}
              className={[classes.textSecondary, classes.savebtnWight1]}
            >
              {t("Download Log")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Grid className={classes.toggleGrid} sm={"auto"}>
              <Button
                variant="outlined"
                className={classes.newTabBtnFirstGraph}
                onClick={() => this.fullYearGraph(true)}
                style={
                  this.state.showFullYearGraph ? { background: "#f2f2f2" } : {}
                }
              >
                {t("Yearly")}
              </Button>
              <Button
                variant="outlined"
                className={classes.newTabBtnLastGraph}
                onClick={() => this.fullYearGraph(false)}
                style={
                  !this.state.showFullYearGraph ? { background: "#f2f2f2" } : {}
                }
              >
                {t("Monthly")}
              </Button>

              {!this.state.showFullYearGraph && (
                <>
                  <MuiThemeProvider theme={monthPickerStyle}>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={enLocale}
                    >
                      <InlineDatePicker
                        style={{
                          width: "90px",
                          marginTop: "4px",
                          marginLeft: "10px",
                          color: "#00000042",
                          fontWeight: "400",
                        }}
                        views={["year", "month"]}
                        value={moment(this.state.monthChangeDate)}
                        onChange={this.changeCalendarMonthYear()}
                        format={"MMM yyyy"}
                        autoOk={true}
                        KeyboardButtonProps={{
                          disabled: false,
                          style: { display: "none" },
                        }}
                        InputProps={{ readOnly: true }}
                        InputLabelProps={{ shrink: true }}
                        maxDate={moment()
                          .add(
                            this.state.featuresEnabled.includes(23)
                              ? "545"
                              : "365",
                            "d"
                          )
                          .format("YYYY-MM-DD")}
                        minDate={moment().format("YYYY-MM")}
                        minDateMessage={""}
                        maxDateMessage={""}
                      />
                    </MuiPickersUtilsProvider>
                  </MuiThemeProvider>
                  <Button
                    disabled={
                      !this.state.showFullYearGraph &&
                      moment().format("YYYY-MM") ==
                        moment(this.state.monthChangeDate).format("YYYY-MM")
                    }
                    className={[classes.calendarNaviBtn, classes.textSecondary]}
                    onClick={() =>
                      this.navigateMonth(
                        moment(this.state.monthChangeDate)
                          .subtract(1, "months")
                          .format("YYYY-MM-DD")
                      )
                    }
                  >
                    <Icon>navigate_before</Icon>
                  </Button>
                  <Button
                    className={[classes.calendarNaviBtn, classes.textSecondary]}
                    disabled={
                      !this.state.showFullYearGraph &&
                      moment(this.state.monthChangeDate).format("YYYY-MM") ==
                        moment()
                          .add(
                            this.state.featuresEnabled.includes(23)
                              ? "545"
                              : "365",
                            "d"
                          )
                          .format("YYYY-MM")
                    }
                    onClick={() =>
                      this.navigateMonth(
                        moment(this.state.monthChangeDate)
                          .add(1, "months")
                          .format("YYYY-MM-DD")
                      )
                    }
                  >
                    <Icon>navigate_next</Icon>
                  </Button>
                  <MuiThemeProvider theme={CalendarbuttonTheme}>
                    <Button
                      className={[classes.fullWidthResp, classes.textSecondary]}
                      disabled={
                        this.state.showFullYearGraph &&
                        this.state.viewType == "priceGraph"
                      }
                      variant="outlined"
                      style={{ margin: "0px 10px" }}
                      onClick={() =>
                        this.navigateMonth(
                          moment
                            .utc(this.props.hotelAuthReducer.utcTime)
                            .tz(
                              this.props.hotelAuthReducer.hotelDetails.hotel
                                .timezone
                            )
                            .format("YYYY-MM-DD")
                        )
                      }
                    >
                      {t("Current Month")}
                    </Button>
                  </MuiThemeProvider>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <a id="downloadAnchorElem" className={classes.downloadAnchorElem}></a>
        <div className={classes.chartdiv}>
          <Line data={data} options={options} plugins={plugins} />
        </div>
      </div>
    );
  };

  download = () => {
    if (this.state.boostPrice) {
      var dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(this.state.boostPrice));
      var dlAnchorElem = document.getElementById("downloadAnchorElem");
      dlAnchorElem.setAttribute("href", dataStr);
      dlAnchorElem.setAttribute(
        "download",
        "boostPrices-" +
          this.state.hotelDetail.id +
          "-" +
          moment().format() +
          ".json"
      );
      dlAnchorElem.click();
    }
  };

  downloadAirDna = () => {
    if (this.state.airDnaData) {
      var dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(this.state.airDnaData));
      var dlAnchorElem = document.getElementById("downloadAnchorElem");
      dlAnchorElem.setAttribute("href", dataStr);
      dlAnchorElem.setAttribute(
        "download",
        "Airbnb-Data-" +
          this.state.hotelDetail.id +
          "-" +
          moment().format() +
          ".json"
      );
      dlAnchorElem.click();
    }
  };

  renderMarkers = (map, maps) => {
    this.state.competitorData.map((val) => {
      new maps.Marker({
        position: {
          lat: parseFloat(val.competitor.hotel.lat),
          lng: parseFloat(val.competitor.hotel.lng),
        },
        map,
        title: val.index + ". " + val.competitor.hotel.name,
        icon: {
          path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0",
          fillColor: val.index < 10 ? "#3772FF" : "#dbdee2",
          fillOpacity: 1.0,
          strokeColor: "#000000",
          strokeWeight: 1,
          scale: 0.75,
          label: "1",
        },
      });
    });
    new maps.Marker({
      position: {
        lat: parseFloat(this.state.hotelDetail.lat),
        lng: parseFloat(this.state.hotelDetail.lng),
      },
      map,
      title: this.state.hotelDetail.name,
    });
  };
  renderMap = () => {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.divMap}>
        {this.state.hotelDetail && (
          <GoogleMapReact
            ref={(map) => (this._map = map)}
            defaultCenter={{
              lat: parseFloat(this.state.hotelDetail.lat),
              lng: parseFloat(this.state.hotelDetail.lng),
            }}
            defaultZoom={14}
            hoverDistance={75}
            bootstrapURLKeys={{
              key: "AIzaSyBORMnlZ9I34XUMZVh2h2HTo7C7s1Q8giY",
            }}
          >
            {this.state.otherCompetitorList.client_competitor_list &&
              this.state.otherCompetitorList?.client_competitor_list.map(
                (val, index) => {
                  if (val.competitor && val.competitor.hotel) {
                    return (
                      <AnyReactComponent
                        lat={parseFloat(val.competitor.hotel.lat)}
                        lng={parseFloat(val.competitor.hotel.lng)}
                        val={val.index + ". " + val.competitor.hotel.name}
                        other={2}
                        index={index}
                      />
                    );
                  }
                }
              )}
            {this.state.otherCompetitorList.client_list &&
              this.state.otherCompetitorList?.client_list.map((val, index) => {
                return (
                  <AnyReactComponent
                    lat={parseFloat(val.lat)}
                    lng={parseFloat(val.lng)}
                    val={val.name}
                    other={1}
                    index={index}
                  />
                );
              })}
            {this.state.competitorData.map((val, index) => {
              if (val.competitor && val.competitor.hotel) {
                return (
                  <AnyReactComponent
                    lat={parseFloat(val.competitor.hotel.lat)}
                    lng={parseFloat(val.competitor.hotel.lng)}
                    val={val.index + ". " + val.competitor.hotel.name}
                    index={index}
                  />
                );
              }
            })}
            <AnyReactComponent
              lat={parseFloat(this.state.hotelDetail.lat)}
              lng={parseFloat(this.state.hotelDetail.lng)}
              current={true}
              val={this.state.hotelDetail.name}
              index={0}
            />
          </GoogleMapReact>
        )}
      </div>
    );
  };
  fullYearGraph = (val) => {
    if (val == true) {
      this.setState(
        {
          showFullYearGraph: val,
        },
        () => this.getMonthData(this.state.monthChangeDate, true)
      );
    } else {
      this.setState(
        {
          showFullYearGraph: val,
        },
        () => this.getMonthData(this.state.monthChangeDate, false)
      );
    }
  };
  changeCalendarMonthYear = () => (dateValue) => {
    this.navigateMonth(moment(dateValue).format("YYYY-MM-DD"));
  };
  getMonthData = (date, isAll) => {
    var PrevMonthLastWeek = "";
    var NextMonthFirstWeek = "";
    if (isAll) {
      PrevMonthLastWeek = moment().format("YYYY-MM-DD");
      NextMonthFirstWeek = moment().add(365, "days").format("YYYY-MM-DD");
      if (this.state.featuresEnabled.includes(23)) {
        NextMonthFirstWeek = moment().add(545, "days").format("YYYY-MM-DD");
      }
    } else {
      let numberofdate = moment(date, "YYYY-MM").daysInMonth(); // 31
      PrevMonthLastWeek = moment(date).subtract(5, "days").format("YYYY-MM-DD");
      NextMonthFirstWeek = moment(date)
        .add(numberofdate, "days")
        .format("YYYY-MM-DD");
    }
    let calendar_data = Object.keys(this.state.airDnaData)
      .filter(
        (key) =>
          isAll ||
          (moment(NextMonthFirstWeek).isAfter(key) &&
            moment(PrevMonthLastWeek).isBefore(key))
      )
      .reduce((obj, key) => {
        obj[key] = this.state.airDnaData[key];
        return obj;
      }, {});

    let graphDate = [];
    let raw = [];
    let current = [];

    Object.keys(calendar_data).map((val) => {
      if (
        moment(this.state.monthChangeDate).isSame(moment(val), "month") ||
        this.state.showFullYearGraph
      ) {
        graphDate.push(moment(val).format("ddd, D MMM YY"));
        if (this.state.airDnaData[val]) {
          raw.push(this.state.airDnaData[val].airdna_new_price_for_compset);
        } else {
          raw.push(null);
          current.push(null);
        }
      }
    });

    let arr = { graphDate: graphDate, raw: raw, current: current };
    this.setState({
      graphDataArr: arr,
    });
  };
  navigateMonth = (date) => {
    this.setState(
      {
        monthChangeDate: new Date(
          moment(date).format("YYYY"),
          moment(date).format("M") - 1,
          5
        ),
      },
      () => this.getMonthData(date, false)
    );
  };
  render() {
    let is_admin = this.props.userSession.user.is_staff ? true : false;
    let is_admin_partners =
      this.props.userSession.user.is_staff ||
      this.props.userSession.user.sub_account_type
        ? true
        : false;
    const { classes } = this.props;
    const { t } = this.props;
    return (
      <>
        <HeaderCard>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.divSyle}>
                <Typography variant="h5" className={classes.fontSyle}>
                  {t("Market Intelligence")}
                </Typography>
              </div>
              <div style={{ width: "100%" }}>
                <Grid item xs={12}>
                  <div className={classes.airDnaSelectDiv}>
                    {this.props.userSession.user.is_staff && (
                      <FormControl
                        margin="none"
                        className={classes.settingLable}
                      >
                        <InputLabel
                          shrink={true}
                          className={classes.settingInputLable}
                          htmlFor="name"
                        >
                          {t(`Competitor Model Weight`)}
                        </InputLabel>
                        <TextField
                          fullWidth
                          className={classes.airDnaSelect}
                          id="standard-name"
                          label={""}
                          select
                          value={
                            this.state.model_weights.competitor_model_weight
                          }
                          onChange={(event) =>
                            this.handleAirdna(
                              event.target.value,
                              "competitor_model_weight"
                            )
                          }
                          InputLabelProps={{ shrink: true }}
                        >
                          {this.state.airDnaOptions.map((val) => (
                            <MenuItem value={val}>{val}</MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    )}
                    {this.state.featuresEnabled.includes(22) &&
                      this.props.userSession.user.is_staff && (
                        <FormControl
                          margin="none"
                          className={classes.settingLable}
                        >
                          <InputLabel
                            shrink={true}
                            className={classes.settingInputLable}
                            htmlFor="name"
                          >
                            {t(`Pickup Model Weight`)}
                          </InputLabel>
                          <TextField
                            fullWidth
                            className={classes.airDnaSelect}
                            id="standard-name"
                            label={""}
                            select
                            value={this.state.model_weights.pubp_model_weight}
                            onChange={(event) =>
                              this.handleAirdna(
                                event.target.value,
                                "pubp_model_weight"
                              )
                            }
                            InputLabelProps={{ shrink: true }}
                          >
                            {this.state.airDnaOptions.map((val) => (
                              <MenuItem value={val}>{val}</MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      )}

                    {this.state.featuresEnabled.includes(26) &&
                      this.props.userSession.user.is_staff && (
                        <FormControl
                          margin="none"
                          className={classes.settingLable}
                        >
                          <InputLabel
                            shrink={true}
                            className={classes.settingInputLable}
                            htmlFor="name"
                          >
                            {t(`Airbnb Model Weight`)}
                          </InputLabel>
                          <TextField
                            fullWidth
                            className={classes.airDnaSelect}
                            id="standard-name"
                            label={""}
                            select
                            value={this.state.model_weights.airDNA_model_weight}
                            onChange={(event) =>
                              this.handleAirdna(
                                event.target.value,
                                "airDNA_model_weight"
                              )
                            }
                            InputLabelProps={{ shrink: true }}
                          >
                            {this.state.airDnaOptions.map((val) => (
                              <MenuItem value={val}>{val}</MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      )}
                    {this.props.userSession.user.is_staff && (
                      <Button
                        disabled={this.state.weightLoader}
                        onClick={this.saveAirDnaWeight}
                        variant="contained"
                        color="primary"
                        className={classes.savebtnWight}
                        style={{ marginLeft: "2px" }}
                      >
                        {t("Save")}
                        {this.state.weightLoader && (
                          <CircularProgress
                            color="primary"
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </Button>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.tabDiv}>
                    <Tabs
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="on"
                      value={this.state.tab}
                      onChange={this.handleTabs}
                      className={classes.tabView}
                    >
                      <Tab label={t("Competitor List")} value={0} />
                      {this.props.userSession.user.is_staff && (
                        <Tab label={t("Analytics")} value={1} />
                      )}
                      {this.props.userSession.user.is_staff && (
                        <Tab label={t("Map")} value={3} />
                      )}
                      {this.props.userSession.user.is_staff &&
                        this.state.featuresEnabled.includes(22) && (
                          <Tab label={t("Pickup Boost Prices")} value={2} />
                        )}
                      {this.props.userSession.user.is_staff &&
                        this.state.featuresEnabled.includes(26) && (
                          <Tab label={t("Airbnb")} value={4} />
                        )}
                    </Tabs>
                  </div>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </HeaderCard>
        <Card>
          <MuiThemeProvider theme={competitorTheme}>
            <CardContent>
              <div>
                {this.state.nearestData && !this.state.loadNearData && (
                  <Button
                    onClick={this.getCompetitor}
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                  >
                    {t("Cancel")}
                  </Button>
                )}

                {this.state.tab == 0 && (
                  <div className={classes.dateTableDiv}>
                    {this.props.userSession.user.is_staff && (
                      <Grid md={12} className={classes.gridStyle}>
                        <div className={classes.boxGroup}>
                          <div className={classes.boxItem}>
                            <label className={classes.lableInput}>
                              Initial Setup
                            </label>
                            {is_admin_partners && (
                              <Button
                                onClick={this.getTopCompetitorsModel}
                                variant="outlined"
                                color="primary"
                                className={[classes.button, classes.btnAction]}
                                disabled={
                                  this.state.loadTopCompetitors ||
                                  this.state.is_20_comp_start
                                }
                              >
                                {t("Get Top 20")}
                                {this.state.loadTopCompetitors && (
                                  <CircularProgress
                                    color="primary"
                                    size={24}
                                    className={classes.buttonProgress}
                                  />
                                )}
                              </Button>
                            )}
                            <em className={classes.arrowIcon}>>></em>
                            <span className={classes.spanLable}>
                              {" "}
                              Check competitors{" "}
                            </span>
                            <em className={classes.arrowIcon}>>></em>
                            <Button
                              disabled={this.state.loadingScrapTop20}
                              onClick={this.confirmScrapeTop20}
                              variant="outlined"
                              color="primary"
                              className={[classes.button, classes.btnAction]}
                            >
                              {t("Scrape Top 20")}
                              {this.state.loadingScrapTop20 && (
                                <CircularProgress
                                  color="primary"
                                  size={24}
                                  className={classes.buttonProgress}
                                />
                              )}
                            </Button>
                            <em className={classes.arrowIcon}>>></em>
                            {is_admin && (
                              <Button
                                onClick={this.openSortByScoreModal}
                                variant="outlined"
                                color="primary"
                                disabled={
                                  this.state.loadScoreCompetitor ||
                                  this.state.is_20_comp_start
                                }
                                className={[
                                  classes.button,
                                  classes.btnAction,
                                  classes.btnScrore,
                                ]}
                              >
                                {t("Score")}
                                {this.state.loadScoreCompetitor && (
                                  <CircularProgress
                                    color="primary"
                                    size={24}
                                    className={classes.buttonProgress}
                                  />
                                )}
                              </Button>
                            )}
                          </div>
                        </div>
                        <label className={classes.lable}>
                          {t("Seasonal Property")}
                        </label>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.is_seasonal}
                              onChange={(e) => this.handleSeasonalData(e)}
                              color="primary"
                            />
                          }
                        />
                      </Grid>
                    )}
                    <div className={classes.CompetitorsScraped}>
                      <div className={classes.autoDiv}>
                        <div className={classes.right}>
                          <Button
                            disabled={this.state.loadSaveData}
                            onClick={this.updateCompetitors}
                            variant="contained"
                            color="primary"
                            className={[classes.button, classes.btnScrapes]}
                          >
                            {t("Save & Scrape New Ones")}
                            {this.state.loadSaveData && (
                              <CircularProgress
                                color="primary"
                                size={24}
                                className={classes.buttonProgress}
                              />
                            )}
                          </Button>
                          <Button
                            onClick={this.toggleAddNewHotel}
                            className={[classes.compQue, classes.textSecondary]}
                          >
                            {t("Can't find competitor?")}
                          </Button>
                          <Typography variant="subtitle1" align="left">
                            {" "}
                            {this.state.is_top_20 == true
                              ? "Top 20 scrape running: "
                              : ""}{" "}
                            {(this.state.completedCompetitor
                              ? this.state.completedCompetitor
                              : 0) +
                              "/" +
                              (this.state.totalCompetitor
                                ? this.state.totalCompetitor
                                : 0) +
                              " Competitors Scraped"}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    {this.renderCompetitorTable()}
                  </div>
                )}
                {this.state.tab == 1 && (
                  <React.Fragment>
                    <div className={classes.dateTableDiv}>
                      {this.renderCompetitorGraph()}
                    </div>
                    <div
                      style={{ marginTop: "10px" }}
                      className={[classes.autoDiv, classes.autoCalcDiv]}
                    >
                      <Typography variant="subtitle1" align="left">
                        {t("Base Price Check")}: {this.AutoCalc()}
                      </Typography>
                    </div>
                  </React.Fragment>
                )}
                {this.state.tab == 2 && (
                  <div className={classes.dateTableDiv}>
                    {this.renderBoostGraph()}
                  </div>
                )}
                {this.state.tab == 3 && (
                  <div className={classes.dateTableDiv}>{this.renderMap()}</div>
                )}

                {this.state.tab == 4 && (
                  <div className={classes.dateTableDiv}>
                    {this.renderAirDna()}
                  </div>
                )}
              </div>
            </CardContent>
          </MuiThemeProvider>
          {Modals.scrapeConfirmModal(this)}
          {this.state.opensortByScoreConfirmModal
            ? Modals.sortByScoreConfirmModal(this)
            : ""}
          {Modals.addHotelScrap(this)}
          {Modals.resetConfirmModal(this)}
          {this.state.getTop20Model ? Modals.top20CompDistanceModel(this) : ""}
          {this.state.findAirDnaCity ? Modals.FindAirDnaModal(this) : ""}
        </Card>
      </>
    );
  }
}

Competitors.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    loading: state.authReducer.loading,
    hotelDetailReducer: state.hotelSettingReducer,
    error: state.authReducer.error,
    userSession: state.sessionReducer,
    hotelAuthReducer: state.hotelAuthReducer,
    competitorReducer: state.competitorReducer,
    hotelListReducer: state.hotelListReducer,
    commonReducer: state.commonReducer,
    priceSettingReducer: state.priceSettingReducer,
    roomList: state.roomListReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHotelDetail: (request) => {
      dispatch(actions.getHotelDetail(request));
    },
    getCompetitor: (request) => {
      dispatch(actions.getCompetitor(request));
    },
    getImputation: (request) => {
      dispatch(actions.getImputation(request));
    },
    getNearCompetitor: (request) => {
      dispatch(actions.getNearCompetitor(request));
    },
    getScoreCompetitor: (request) => {
      dispatch(actions.getScoreCompetitor(request));
    },
    getScoreTopCompetitors: (request) => {
      dispatch(actions.getScoreTopCompetitors(request));
    },
    getTopCompetitors: (request) => {
      dispatch(actions.getTopCompetitors(request));
    },
    getUpdateCompetitor: (request) => {
      dispatch(actions.getUpdateCompetitor(request));
    },
    bulkUpdateCompetitor: (request) => {
      dispatch(actions.bulkUpdateCompetitor(request));
    },
    bulkUpdateCompetitorNscrape: (request) => {
      dispatch(actions.bulkUpdateCompetitorNscrape(request));
    },
    getHotelList: (request) => {
      dispatch(actions.getHotelList(request));
    },
    getScrapCompetitor: (request) => {
      dispatch(actions.getScrapCompetitor(request));
    },
    getScrapeTop20Competitors: (request) => {
      dispatch(actions.getScrapeTop20Competitors(request));
    },
    setHotelList: (request) => {
      dispatch({ type: actionTypes.HOTEL_LIST_SUCCEED, state: request });
    },
    addScraper: (request) => {
      dispatch(actions.addScraper(request));
    },
    getPricingSettings: (request) => {
      dispatch(actions.getPricingSettings(request));
    },
    searchBookingCompetitor: (request) => {
      dispatch(actions.searchBookingCompetitor(request));
    },
    resetPmsPriceOccupancy: (request, loaderFun) => {
      dispatch(
        actions.resetPmsPriceOccupancy(request, loaderFun, "occPriceRun")
      );
    },
    resetPmsPrice: (request, loaderFun) => {
      dispatch(actions.resetPmsPrice(request, loaderFun, "priceRun"));
    },
    updateHotel: (request) => {
      dispatch(actions.updateHotelSetting(request));
    },
    updateSettingJson: (request, callback, backdata) => {
      dispatch(
        actions.updateSettingJson(request, {}, "pubpWeight", callback, backdata)
      );
    },
    updateSettingAirDnaJson: (request, callback, backdata) => {
      dispatch(
        actions.updateSettingJson(
          request,
          {},
          "airDnaWeight",
          callback,
          backdata
        )
      );
    },
    getAirDnaGraphData: (request, showNotification) => {
      dispatch(actions.getAirDnaGraphData(request, showNotification));
    },
    saveMarketCode: (request, callback) => {
      dispatch(actions.saveMarketCode(request, "priceRun", callback));
    },
    findNearestCity: (request, callback) => {
      dispatch(actions.findNearestCity(request, "priceRun", callback));
    },
    putNotify: (request) => {
      dispatch(actions.putNotify(request));
    },
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps)
)(withNamespaces("translations")(Competitors));
