import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import { connect } from "react-redux";
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import SubscribedBlockCss from './css/SubscribedBlockCss';
import { withNamespaces } from 'react-i18next';

const styles = SubscribedBlockCss;

class SubscribedRecords extends React.Component {
  render() {
    const { classes, t } = this.props;  
  return (
    <Card className={classes.root}>

<div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="subtitle1" variant="subtitle1" align={"left"}>
          # of Subscribed Clients
          </Typography>
          <Typography component="h5" variant="h5" className={classes.h5font} align={"left"}>
          {this.props.subscribed_hotel_count}
          </Typography>
          <Typography variant="caption" color="caption" align={"left"}>
          <span className={classes.boldFont}>+ {this.props.subscribed_hotel_last_one_week_count} </span> New Subscription in Last 7 Days, <br />
          <span className={classes.boldFont}>- {this.props.cancelled_subscription_last_one_week_count} </span> Cancellations in Last 7 Days,
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
  }
}

const mapStateToProps = (state) => {
    return {
        userSession: state.sessionReducer,
        hotelAuthReducer: state.hotelAuthReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default compose(withStyles(styles, {
  withTheme: true
}), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(SubscribedRecords));
