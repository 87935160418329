var style = {
 CardHeader:{
     margin:"-20px -24px 24px -24px",
     borderRadius: "0px",
     borderBottom:"1px solid #dfdfdf",
     maxWidth: "auto",
     maxWidth: "none",
     position : "sticky",
     zIndex : 105,
     top :0,
    //  minHeight:'183px'
 }
}
export default style;