import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';

const onHotelListStart = () => {
    return {
        type: actionTypes.HOTEL_LIST_START
    }
}

const onHotelListFailed = (error) => {
    return {
        type: actionTypes.HOTEL_LIST_FAILED,
        error: error
    }
}

const onHotelListSucceed = (data) => {
    return {
        type: actionTypes.HOTEL_LIST_SUCCEED,
        state: data
    }
}

export const getHotelList = (request) => {
    
    return dispatch => {
        dispatch(onHotelListStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        let hotelUrl = request.url ? (Server.API + request.url) : (Server.API + 'hotel/list/');
        axios.get(hotelUrl)
            .then(function (response) {
                dispatch(onHotelListSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onHotelListFailed(error))
            });
    }
}