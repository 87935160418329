import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../../../store/actions/index';
import Notify from '../Notify';

const successPriceNote = (data) => {
    return {
        type: actionTypes.GET_PRICE_NOTE,
        data: data
    }
}

export const getPriceNote = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'hotel/price/note/list/')
            .then(function (response) {
                dispatch(successPriceNote(response.data))                
            })
            .catch(function (error) {
            })
    }
}

export const addPriceNote = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/price/note/register/', request)
            .then(function (response) {
                dispatch(actions.getPriceNote(request))
                dispatch(Notify.newUserModal("Note Saved Successfully", true))
            })
            .catch(function (error) {
            })
    }
}

export const updatePriceNote = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.patch(Server.API + 'hotel/price/note/update/' + request.id + '/', request)
            .then(function (response) {
                dispatch(actions.getPriceNote(request));
                dispatch(Notify.newUserModal("Note Saved Successfully", true))
            })
            .catch(function (error) {
            })
    }
}

export const deletePriceNote = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.delete(Server.API + 'hotel/price/note/delete/' + request.id + '/')
            .then(function (response) {
                dispatch(actions.getPriceNote(request))
            })
            .catch(function (error) {
            })
    }
}

