import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CaledarCss from './css/calendarCss';
import moment from 'moment';
import BigCalendar from 'react-big-calendar';
import Button from '@material-ui/core/Button';
import Modals from './modal/modal';
import { Icon, Typography, CardHeader } from '@material-ui/core';
import Server from '../../config/server';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../store/actions/index';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import axios from 'axios';
import { withNamespaces } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { bulkSettingTheme } from '../muiTheme';
import DateFnsUtils from '@date-io/date-fns';
import Table from '@material-ui/core/Table';

const styles = CaledarCss;
moment.locale("es-es", { week: { dow: 7 } });
const localizer = BigCalendar.momentLocalizer(moment)
var settingJsonData = {};

class CalulatePrice extends React.Component {
  state = {
    isBooking: false,
    leavePage: false,
    settingAlert: false,
    bulkUpdateList: {
      individual: [],
      deleteIndividual: [],
      skipDate: [],
      deleteSkip: [],
    },
    isSkip: null,
    isGrey: true,
    BulkRoomId: null,
    bulkUpdateData: {
      skipName: '',
      fixedPrice: '',
      dailyAdjust: '',
      minPrice: '',
      maxPrice: '',
      avgPrice: '',
      adjVsRef: '',
    },
    bulkError: [],
    bulkStartDate: null,
    bulkEndDate: null,
    bulkWeekdays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
    bulkDateAdjModal: false,
    bulkRoomModal: false,
    price_type_value_error: false,
    popupRoomData: null,
    greySaveUpload: false,
    suggestedModalPrice: 0,
    target_occupancy: 0,
    modalWeekData: {},
    modalMonthData: {},
    weeklyModal: false,
    mothlyModal: false,
    monthlyData: [],
    weeklyData: [],
    price_type: "",
    price_type_value: "",
    loadUploadBtn: false,
    last_run_pricing_type: 1,
    pricing_result: null,
    yesterday_pricing_data: null,
    last_week_pricing_result: null,
    calendarType: 0,
    showFullYearGraph: true,
    openBulkUpdate: false,
    bulkStartDate: moment().format('YYYY-MM-DD'),
    bulkEndDate: moment().add(1, 'day').format('YYYY-MM-DD'),
    bulkUpdateType: "",
    bulkUpdateRooms: "",
    bulkUpdateField: "",
    bulkValue: "",
    openAutoUpdateConfirm: false,
    skipValidation: false,
    skipUpdate: 0,
    openUpdatePmsType: false,
    singleAdjustment: 0,
    openDateAdjustment: false,
    skipData: {},
    openSkipDate: false,
    priceNote: "",
    todayMessage: null,
    stopCachedPrice: false,
    get_pms_data: false,
    preDefinedRates: [],
    // predefined_rate: false,
    uploadLoadig: false,
    happyPricing: false,
    viewType: 'calendar', // can be calendar, table, graph
    showCsvPrice: false,
    showDownloadCsv: false,
    monthChangeDate: moment().format('YYYY-MM-DD'),
    updatePmsStart: moment().format('YYYY-MM-DD'),
    updatePmsEnd: moment().add('6', 'M').format('YYYY-MM-DD'),
    showUpdatePrice: false,
    allEvents: false,
    inginiusFiles: [],
    pms_provider: 0,
    file: [],
    is_runPricing: false,
    socketLoader: false,
    loadPercentage: 0,
    loadData: false,
    PriceModalopen: false,
    ManuallPriceOpen: false,
    showPriceBtn: false,
    start_date: '',
    end_date: '',
    selectedRoom: false,
    inventory_data: [],
    value: "0",
    days: '7',
    currency: {},
    calendarData: [],
    myEventsList: [],
    modalData: {},
    file_id: null,
    csvUrl: '',
    update_message: "",
    tableStartDate: moment().toDate(),
    daysList: [],
    error: [],
    width: window.innerWidth,
    is_update_to_pms: false,
    update_status: 0,
    updatePmsType: 7,
    types: [
      { key: 1, value: 'Week' },
      { key: 2, value: '2 Weeks' },
      { key: 3, value: 'Month' },
      { key: 4, value: '3 Months' },
      { key: 5, value: '6 Months' },
      { key: 6, value: '9 Months' },
      { key: 7, value: 'Year' },
    ],
    dateSetting: null,
    targetOccupancyModal: false,
    featuresEnabled: [],
    // programList: [],
    skipDates: [],
    performance: {
      future: [],
      past: []
    },
    isAbsolute: false,
    getCached: false,
    room_apartment_space_name: "",
  };

  componentDidMount() {

    let requestData = {
      token: this.props.hotelAuthReducer.hotelDetails.token
    };
    this.props.getHotelDetail(requestData);
    this.props.getRoomList(requestData);
    this.getCalendarSettingJson();

    let dates = [];
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');

    for (var m = moment(startOfMonth); m.isSameOrBefore(endOfMonth); m.add(1, 'days')) {
      if (m.isSameOrAfter(moment().format('YYYY-MM-DD'))) {
        dates.push(moment(m.format('YYYY-MM-DD')));
      }
    }

    var a = moment().endOf('month');
    var b = moment();
    let nextMonth = moment().add(1, 'month').startOf('month').format('YYYY-MM-DD');

    if (a.diff(b, 'days') <= 30) {
      let count = Math.abs(30 - a.diff(b, 'days'));
      let i = 0;
      for (i == 0; i < count; i++) {
        dates.push(moment(nextMonth).add(i, 'd'))
      }
    }

    this.setState({
      daysList: dates,
    })

    
  }

  componentWillReceiveProps(props) {

    if (props.commonReducer.showNavidationPop) {
      this.setState({
        leavePage: true,
        tempType: props.commonReducer.showNavidationPop
      },
        () => {
          this.props.showNavigationPopup(null)
        })
    }

    if (props.algoReducer && props.algoReducer.runAlgo) {
      this.setState({
        programList: props.algoReducer.runAlgo
      })
    }

    if (props.commonReducer.notify) {
      if (props.commonReducer.notify.message == 'Skip Date updated successfully' || props.commonReducer.notify.message == 'Single date adjustment updated successfully' || props.commonReducer.notify.message == "Single date adjustment created successfully") {
        // this.runPricingAgain();
      }
    }

    if (props.priceSettingReducer.priceSetting) {
      let skipDates = [];
      Object.keys(props.priceSettingReducer.priceSetting.dates).map((key) => {
        if (props.priceSettingReducer.priceSetting.dates[key].skip_date) {
          skipDates.push({
            ...props.priceSettingReducer.priceSetting.dates[key].skip_date,
            date: key
          })
        }
      })
      this.setState({
        skipDates
      })
    }

    if (props.priceSettingReducer.priceSetting) {
      let adjustDates = [];
      Object.keys(props.priceSettingReducer.priceSetting.dates).map((key) => {
        if (props.priceSettingReducer.priceSetting.dates[key].individual_adjustment) {
          adjustDates.push({
            ...props.priceSettingReducer.priceSetting.dates[key].individual_adjustment,
            date: key
          })
        }
      })
      this.setState({
        adjustDates
      })
    }

    if (props.hotelAuthReducer.hotelDetails) {
      this.setState({
        target_occupancy: props.hotelAuthReducer.hotelDetails.hotel.target_occupancy,
        temp_target_occupancy: props.hotelAuthReducer.hotelDetails.hotel.target_occupancy,
        // predefined_rate: props.hotelAuthReducer.hotelDetails.hotel.predefined_rate,
        pms_provider: props.hotelAuthReducer.hotelDetails.hotel.pms_provider,
      })
      if (this.props.hotelAuthReducer.hotelDetails.hotel && this.props.hotelAuthReducer.hotelDetails.hotel.room_apartment_space_name){
        this.setState({
          room_apartment_space_name: this.props.hotelAuthReducer.hotelDetails.hotel.room_apartment_space_name
        });
      }
    }

    if (props.roomList.roomList && props.hotelDetail && props.priceSettingReducer.priceSetting && !this.state.getCached) {
      this.getLattestPricing()
      this.setState({
        getCached: true,
      })
    }


    if (props.hotelDetail) {
      this.setState({
        skipUpdate: (props.hotelDetail.update_to_pms_skip_type ? props.hotelDetail.update_to_pms_skip_type : 0),
        updatePmsType: (props.hotelDetail.update_to_pms_type ? props.hotelDetail.update_to_pms_type : 7)
      })
    }

    if (props.priceSettingReducer.priceSetting) {
      this.setState({
        dateSetting: props.priceSettingReducer.priceSetting,
        isAbsolute: props.priceSettingReducer.priceSetting.hotel.adjustment_to_reference_room_is_absolute,
      })
    }

    if (props.priceSettingReducer && props.priceSettingReducer.priceSetting) {
      this.setState({
        featuresEnabled: props.priceSettingReducer.priceSetting.features
      })
    }
  }

  stopNavigation = (data) => {
    this.props.stopNavigation(data)
  }


  toggleLeavePage = (type) => {
    this.setState({
      leavePage: !this.state.leavePage,
      tempType: type ? type : '',
    })
  }

  dontSaveChange = () => {
    this.setState({
      isGrey: true,
      leavePage: false,
      bulkUpdateList: {
        individual: [],
        deleteIndividual: [],
        skipDate: [],
        deleteSkip: [],
      },
      dateSetting: settingJsonData
    },
      () => {
        this.stopNavigation(false);
        // this.changeView(this.state.tempType);
      })
  }

  saveChangesettings = () => {
    this.setState({
      isGrey: true,
      leavePage: false,
    },
      () => {
        this.saveTabledata();
        // this.changeView(this.state.tempType);
        this.stopNavigation(false);
      })
  }

  getCalendarSettingJson = () => {
    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
    axios.get(Server.API + 'hotel/pricing-settings/')
      .then(function (response) {
        settingJsonData = response.data
      });
  }

  bulkUpdateData = (name) => event => {
    this.setState({
      bulkUpdateData: {
        ...this.state.bulkUpdateData,
        [name]: event.target.value
      }
    })
  }

  handleAdjVsRef = (value) => {

    this.setState({
      bulkUpdateData: {
        ...this.state.bulkUpdateData,
        adjVsRef: value,
      }
    })

  }

  clearFixedPrice = () => {
    this.setState({
      bulkUpdateData: {
        ...this.state.bulkUpdateData,
        fixedPrice: ''
      }
    })
  }

  bulkUpdateIsSkip = (event) => {
    let value = event.target.value;
    if (value) {
      this.setState({
        isSkip: value,
        bulkUpdateData: {
          ...this.state.bulkUpdateData,
          skipName: '',
          fixedPrice: '',
        }
      })
    }
  }

  //updateSettingJson

  setDefaultValues = () => {
    let setting = this.state.dateSetting;
    let min = setting.default[this.state.BulkRoomId].min_price;
    let max = setting.default[this.state.BulkRoomId].max_price;
    this.setState({
      bulkUpdateData: {
        ...this.state.bulkUpdateData,
        minPrice: min,
        maxPrice: max,
      }
    })
  }

  setDefaultAdj = () => {
    let setting = this.state.dateSetting;
    let adjVsRef = parseFloat(setting.default[this.state.BulkRoomId].adjustment_to_reference_room).toFixed(0);

    this.setState({
      bulkUpdateData: {
        ...this.state.bulkUpdateData,
        adjVsRef: adjVsRef
      }
    },
      () => this.handleAdjVsRef(adjVsRef))
  }

  updateSettingJson = () => { //1
    this.setState({
      isGrey: false,
    },
      () => { this.stopNavigation(true) }
    )

    var a = moment(this.state.bulkStartDate).format('YYYY-MM-DD');
    var b = moment(this.state.bulkEndDate).format('YYYY-MM-DD');

    let dates = [];
    for (var m = moment(a); m.isSameOrBefore(b); m.add(1, 'days')) {
      if (this.state.bulkWeekdays.includes(m.format('dddd'))) {
        dates.push(m.format('YYYY-MM-DD'))
      }
    }
    this.setSkipDate(dates).then(() => {
      this.saveTabledata();
    })
    // this.bulkDateAdjModalToggle();
  }

  validateUpdateJson = () => {
    let bulkError = [];

    if (!this.state.bulkUpdateData.dailyAdjust) {
      bulkError.push('dailyAdjust');
    }

    if (!this.state.bulkUpdateData.skipName) {
      bulkError.push('skipName');
    }

    if (!this.state.bulkUpdateData.fixedPrice) {
      bulkError.push('fixedPrice');
    }

    this.setState({
      bulkError
    })

    return bulkError;
  }


  setSkipDate = async (data) => {
    await Promise.all(data.map(async (value) => {
      let oldSettings = settingJsonData;
      let setting = this.state.dateSetting;
      let bulkUpdateList = this.state.bulkUpdateList;

      if (this.state.isSkip == '1' || this.state.isSkip == '5') {

        let skipDates = [];
        bulkUpdateList.skipDate.map((val) => {
          if (value != val.date) {
            skipDates.push(val);
          }
        })
        bulkUpdateList.skipDate = skipDates;

        bulkUpdateList.skipDate.push({
          date: value,
          fixed_price: this.state.bulkUpdateData.fixedPrice ? parseFloat(this.state.bulkUpdateData.fixedPrice) : null,
          name: this.state.bulkUpdateData.skipName ? this.state.bulkUpdateData.skipName : 'skip'
        })

        let deleteSkips = [];
        bulkUpdateList.deleteSkip.map((val) => {
          if (value != val.date) {
            deleteSkips.push(val);
          }
        })
        bulkUpdateList.deleteSkip = deleteSkips;

        setting.dates[value] = {
          ...setting.dates[value],
          skip_date: {
            fixed_price: this.state.bulkUpdateData.fixedPrice ? parseFloat(this.state.bulkUpdateData.fixedPrice) : null,
            name: this.state.bulkUpdateData.skipName ? this.state.bulkUpdateData.skipName : 'skip'
          }
        };
      } else if (this.state.isSkip == '2') {
        if (this.state.bulkUpdateData.dailyAdjust && parseFloat(this.state.bulkUpdateData.dailyAdjust)) {

          let individuals = [];
          bulkUpdateList.individual.map((val) => {
            if (value != val.date) {
              individuals.push(val);
            }
          })
          bulkUpdateList.individual = individuals;

          bulkUpdateList.individual.push({
            name: "",
            percentage: parseFloat(this.state.bulkUpdateData.dailyAdjust),
            date: value,
            hotel: parseFloat(this.props.hotelAuthReducer.hotelDetails.hotel.id),
          })


          let deleteIndividuals = [];
          bulkUpdateList.deleteIndividual.map((val) => {
            if (value != val.date) {
              deleteIndividuals.push(val);
            }
          })
          bulkUpdateList.deleteIndividual = deleteIndividuals;

          setting.dates[value] = {
            ...setting.dates[value],
            individual_adjustment: {
              name: null,
              percentage: parseFloat(this.state.bulkUpdateData.dailyAdjust),
            },
          };
        }
      } else if (this.state.isSkip == '3') { // delete fix
        if (setting.dates[value]) {
          if (oldSettings.dates[value] && oldSettings.dates[value].skip_date && oldSettings.dates[value].skip_date.fixed_price) {
            if (!bulkUpdateList.deleteSkip.includes(value)) {
              bulkUpdateList.deleteSkip.push(value)
            }
          }

          let skipDates = [];
          bulkUpdateList.skipDate.map((val) => {
            if (value != val.date) {
              skipDates.push(val);
            }
          })

          bulkUpdateList.skipDate = skipDates;
          if (setting.dates[value].skip_date && setting.dates[value].skip_date.fixed_price) {
            delete setting.dates[value].skip_date;
          }
        }
      } else if (this.state.isSkip == '6') { // delete skip
        if (setting.dates[value]) {
          if (oldSettings.dates[value] && oldSettings.dates[value].skip_date && !oldSettings.dates[value].skip_date.fixed_price) {
            if (!bulkUpdateList.deleteSkip.includes(value)) {
              bulkUpdateList.deleteSkip.push(value)
            }
          }

          let skipDates = [];
          bulkUpdateList.skipDate.map((val) => {
            if (value != val.date) {
              skipDates.push(val);
            }
          })

          bulkUpdateList.skipDate = skipDates;

          if (setting.dates[value].skip_date && !setting.dates[value].skip_date.fixed_price) {
            delete setting.dates[value].skip_date;
          }
        }
      } else if (this.state.isSkip == '4') {
        if (setting.dates[value]) {
          if (oldSettings.dates[value] && oldSettings.dates[value].individual_adjustment) {
            if (!bulkUpdateList.deleteIndividual.includes(value)) {
              bulkUpdateList.deleteIndividual.push(value);
            }
          }

          let individuals = [];
          bulkUpdateList.individual.map((val) => {
            if (value != val.date) {
              individuals.push(val);
            }
          })
          bulkUpdateList.individual = individuals;

          delete setting.dates[value].individual_adjustment;
        }
      }

      return await this.setState({
        bulkUpdateList: bulkUpdateList,
        dateSetting: setting,
        bulkDateAdjModal: false,
      })

    }));
  }

  deleteIndividualDate = (value) => {
    let oldSettings = this.props.priceSettingReducer.priceSetting;
    let setting = this.state.dateSetting;
    let bulkUpdateList = this.state.bulkUpdateList;

    if (setting.dates[value]) {
      if (oldSettings.dates[value] && oldSettings.dates[value].individual_adjustment) {
        if (!bulkUpdateList.deleteIndividual.includes(value)) {
          bulkUpdateList.deleteIndividual.push(value);
        }
      }

      let individuals = [];
      bulkUpdateList.individual.map((val) => {
        if (value != val.date) {
          individuals.push(val);
        }
      })
      bulkUpdateList.individual = individuals;

      delete setting.dates[value].individual_adjustment;
    }

    this.setState({
      bulkUpdateList: bulkUpdateList,
      dateSetting: setting,
      bulkDateAdjModal: false,
      isGrey: false,
    },
      () => {
        // this.stopNavigation(true)
        this.saveTabledata();
      })
  }

  deleteSkipDate = (value) => {
    let oldSettings = this.props.priceSettingReducer.priceSetting;
    let setting = this.state.dateSetting;
    let bulkUpdateList = this.state.bulkUpdateList;

    if (setting.dates[value]) {
      if (oldSettings.dates[value] && oldSettings.dates[value].skip_date) {
        if (!bulkUpdateList.deleteSkip.includes(value)) {
          bulkUpdateList.deleteSkip.push(value)
        }
      }

      let skipDates = [];
      bulkUpdateList.skipDate.map((val) => {
        if (value != val.date) {
          skipDates.push(val);
        }
      })

      bulkUpdateList.skipDate = skipDates;
      delete setting.dates[value].skip_date;
    }

    this.setState({
      bulkUpdateList: bulkUpdateList,
      dateSetting: setting,
      bulkDateAdjModal: false,
      isGrey: false,
    },
      () => {
        // this.stopNavigation(true)
        this.saveTabledata();
      })
  }

  updateRoomSettingJson = () => { //2

    let validateError = this.validateRoomSettingJson();

    if (validateError.length > 0) {
      return;
    }

    this.setState({
      isGrey: false,
    },
      () => {
        this.stopNavigation(true)
      })

    var a = moment(this.state.bulkStartDate).format('YYYY-MM-DD');
    var b = moment(this.state.bulkEndDate).format('YYYY-MM-DD');

    let dates = [];
    for (var m = moment(a); m.isSameOrBefore(b); m.add(1, 'days')) {
      if (this.state.bulkWeekdays.includes(m.format('dddd'))) {
        dates.push(m.format('YYYY-MM-DD'))
      }
    }
    this.setRoomBulkData(dates).then(() => { this.saveTabledata() });

  }

  validateRoomSettingJson = () => {
    let setting = this.state.dateSetting;

    let bulkError = [];

    let min = setting.default[this.state.BulkRoomId].min_price;

    if (this.state.bulkUpdateData.minPrice) {
      min = this.state.bulkUpdateData.minPrice;
    }

    if ((this.state.bulkUpdateData.maxPrice != undefined) && ((this.state.bulkUpdateData.maxPrice === "") || (this.state.bulkUpdateData.maxPrice == Math.round(this.state.bulkUpdateData.maxPrice)))) {
    } else {
      bulkError.push('maxPrice');
    }

    if ((this.state.bulkUpdateData.minPrice != undefined) && ((this.state.bulkUpdateData.minPrice === "") || (this.state.bulkUpdateData.minPrice == Math.round(this.state.bulkUpdateData.minPrice)))) {
    } else {
      bulkError.push('minPrice');
    }

    if (this.state.bulkUpdateData.maxPrice && parseInt(this.state.bulkUpdateData.maxPrice) < parseInt(min)) {
      bulkError.push('maxPriceLow');
    }

    this.setState({
      bulkError
    })

    return bulkError;
  }

  setRoomBulkData = async (data) => {
    await Promise.all(data.map(async (value) => {
      let setting = this.state.dateSetting;

      let defaultData = setting.default[this.state.BulkRoomId];
      let min = setting.default[this.state.BulkRoomId].min_price;
      let max = setting.default[this.state.BulkRoomId].max_price;
      let adjVsRef = setting.default[this.state.BulkRoomId].adjustment_to_reference_room
      let avg = setting.default[this.state.BulkRoomId].avg_price;

      if (setting.dates[value] && setting.dates[value][this.state.BulkRoomId]) {
        min = setting.dates[value][this.state.BulkRoomId].min_price;
        max = setting.dates[value][this.state.BulkRoomId].max_price;
        adjVsRef = setting.dates[value][this.state.BulkRoomId].adjustment_to_reference_room;
      }

      if (this.state.bulkUpdateData.minPrice) {
        min = parseFloat(this.state.bulkUpdateData.minPrice);
      }
      if (this.state.bulkUpdateData.maxPrice) {
        max = parseFloat(this.state.bulkUpdateData.maxPrice);
      }
      if (this.state.bulkUpdateData.adjVsRef) {
        adjVsRef = parseFloat(this.state.bulkUpdateData.adjVsRef);
      }
      if (defaultData.min_price == min && defaultData.max_price == max && defaultData.adjustment_to_reference_room.toFixed(0) == adjVsRef) {
        if (setting.dates[value]) {
          delete setting.dates[value][this.state.BulkRoomId];
        }
      } else {
        setting.dates[value] = {
          ...setting.dates[value],
          [this.state.BulkRoomId]: {
            avg_price: avg,
            max_price: max,
            min_price: min,
            adjustment_to_reference_room: adjVsRef
          }
        };
      }

      return await this.setState({
        dateSetting: setting,
        bulkRoomModal: false
      })
    }));
  }


  bulkDateAdjModalToggle = (type) => {
    this.setState({
      isSkip: type,
      bulkUpdateData: {
        skipName: '',
        fixedPrice: '',
        dailyAdjust: '',
        minPrice: '',
        maxPrice: '',
        avgPrice: '',
        adjVsRef: '',
      },
      bulkDateAdjModal: !this.state.bulkDateAdjModal,
      bulkWeekdays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
    })
  }

  bulkRoomModalToggle = (roomId, type) => {
    this.setState({
      isSkip: type,
      bulkError: [],
      BulkRoomId: roomId ? roomId : this.state.BulkRoomId,
      bulkUpdateData: {
        skipName: '',
        fixedPrice: '',
        dailyAdjust: '',
        minPrice: '',
        maxPrice: '',
        avgPrice: roomId ? this.state.dateSetting.default[roomId].avg_price : '',
        //adjVsRef: roomId ? parseFloat(this.state.dateSetting.default[roomId].adjustment_to_reference_room.toFixed(0)) : '',
        adjVsRef: '',
      },
      bulkWeekdays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      bulkRoomModal: !this.state.bulkRoomModal
    })
  }

  saveTabledata = () => { //3
    let requestData = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      dateSetting: this.state.dateSetting,
      bulkUpdateList: this.state.bulkUpdateList
    };
    this.props.updatePricingSettings(requestData)

    setTimeout(() => {
      this.setState({
        // settingAlert: true,
        isGrey: true,
        bulkUpdateList: {
          individual: [],
          deleteIndividual: [],
          skipDate: [],
          deleteSkip: [],
        },
      })
    }, 2000);


    this.stopNavigation(false)

  }

  toggleSettingAlert = () => {
    this.setState({
      settingAlert: !this.state.settingAlert,
    })
  }

  downloadTabledata = () => {
    var content = JSON.stringify(this.props.priceSettingReducer.priceSetting);
    const element = document.createElement("a");
    const file = new Blob([content], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = "SettingJson.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  handlebulkWeekdays = (name) => event => {
    var arr = this.state.bulkWeekdays;
    if (this.state.bulkWeekdays.indexOf(name) > -1) {
      var index = arr.indexOf(name);
      if (index > -1) {
        arr.splice(index, 1);
      }
    } else {
      arr.push(name)
    }
    this.setState({
      bulkWeekdays: arr
    })
  }




  updateSetings = () => {
    let reqParams = {
      new_settings: this.dateSetting.dates,
      adjustment: this.dateSetting.adjustment
    }

    this.props.updatePricingSettings(reqParams)
  }

  toggleBulkUpdate = () => {
    this.setState({
      openBulkUpdate: !this.state.openBulkUpdate,
    })
  }


  updateBulkDate = (name) => date => {
    this.setState({
      [name]: moment(date).format('YYYY-MM-DD'),
    })
  }

  updateBulkUpdateType = () => event => {
    let value = event.target.value;
    this.setState({
      bulkUpdateType: value
    })
  }

  updatebulkUpdateRooms = () => event => {
    let value = event.target.value;
    this.setState({
      bulkUpdateRooms: value
    })
  }

  udpateBulkUpdateField = () => event => {
    let value = event.target.value;
    this.setState({
      bulkUpdateField: value
    })
  }

  updateIndividual = (date, type, value, roomId) => {
    let dateSetting = this.state.dateSetting;
    if (type == 'min_price') {
      dateSetting.dates[date][roomId][type] = value;
      this.setState({
        dateSetting: dateSetting
      })
    }
    if (type == 'max_price') {
      dateSetting.dates[date][roomId][type] = value;
      this.setState({
        dateSetting: dateSetting
      })
    }
    if (type == 'adjustment_to_reference_room') {
      dateSetting.dates[date][roomId][type] = value;
      this.setState({
        dateSetting: dateSetting
      })
    }
  }

  updateTable = () => {
    let startDate = this.state.bulkStartDate;
    let endDate = this.state.bulkEndDate;

    let dateSetting = this.state.dateSetting;

    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');

    if (this.state.bulkUpdateType == 2) {
      while (currDate.add(1, 'days').diff(lastDate) <= 0) {
        let date = currDate.format('YYYY-MM-DD');
        let roomId = this.state.bulkUpdateRooms;
        dateSetting.dates[date][roomId][this.state.bulkUpdateField] = 50;
      }
      this.setState({
        dateSetting: dateSetting
      })
    }

    if (this.state.bulkUpdateType == 1) {
      if (this.state.bulkUpdateField == 'monthly' || this.state.bulkUpdateField == 'weekday' || this.state.bulkUpdateField == 'single') {
        while (currDate.add(1, 'days').diff(lastDate) <= 0) {
          let date = currDate.format('YYYY-MM-DD');
          dateSetting.dates[date].internal_date_adjustments[this.state.bulkUpdateField] = 50;
        }
        this.setState({
          dateSetting: dateSetting
        })
      }

      if (this.state.bulkUpdateField == 'aggressiveness') {

      }

      if (this.state.bulkUpdateField == 'skip_date') {

      }

      if (this.state.bulkUpdateField == 'fixed_price') {

      }
    }
  }






  getLattestPricing = () => {

    var requestData = {}
    requestData = {
      "command": "get_cached_price",
    }

    this.setState({
      roomcount: 0,
    });

    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
    axios.get(Server.API + 'pricingalgorithm/cache-price/')
      .then((response) => {
        if (response.data) {
          var received_msg = response.data;

          if (received_msg && received_msg.currency) {
            this.setState({
              currency: received_msg.currency,
            })
          }

          if (received_msg && received_msg.data && received_msg.data.data) {
            this.setState({
              pricing_result: received_msg.data.data
            })
          }

          if (received_msg && received_msg.data && received_msg.data.performance) {
            this.setState({
              performance: received_msg.data.performance
            })
          }

          if (received_msg && received_msg.data && received_msg.data.data && !this.state.stopCachedPrice) {
            let jsx = [];
            let jsxKey = [];

            Object.keys(received_msg.data.data).map(function (key) {
              Object.keys(received_msg.data.data[key]).map(function (roomkey) {
                if (!jsxKey.includes(roomkey)) {
                  jsx[roomkey] = [];
                  jsxKey.push(roomkey);
                }
                received_msg.data.data[key][roomkey] = {
                  date: key,
                  total_inventory: received_msg.data.data[key]['property'].inventory_remaining,
                  ...received_msg.data.data[key]['property'],
                  ...received_msg.data.data[key][roomkey],
                };
                jsx[roomkey].push(received_msg.data.data[key][roomkey])
                return null;
              })
              return null;
            });
            this.setState({
              calendarData: jsx,
            },
            )
          }
        }
      })

  }


  changeBulkDate = (name) => dateValue => {
    if (name == 'bulkStartDate') {
      this.setState({
        bulkStartDate: dateValue,
        bulkEndDate: moment(dateValue).format('YYYY-MM-DD')
      })
    } else {
      this.setState({
        [name]: dateValue,
      })
    }
  }

  getNextDays = () => {
    let nextStartDate = moment(this.state.tableStartDate).add(1, 'month').format('YYYY-MM-DD');
    let daysList = [];

    const startOfMonth = moment(nextStartDate).startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment(nextStartDate).endOf('month').format('YYYY-MM-DD');

    for (var m = moment(startOfMonth); m.isSameOrBefore(endOfMonth); m.add(1, 'days')) {
      if (m.isSameOrAfter(moment().format('YYYY-MM-DD'))) {
        daysList.push(moment(m.format('YYYY-MM-DD')));
      }
    }

    var a = moment(endOfMonth);
    var b = moment();
    let nextMonth = moment(startOfMonth).add(1, 'month').startOf('month').format('YYYY-MM-DD');

    if (a.diff(b, 'days') <= 30) {
      let count = Math.abs(30 - a.diff(b, 'days'));
      let i = 0;
      for (i == 0; i < count; i++) {
        daysList.push(moment(nextMonth).add(i, 'd'))
      }
    }

    this.setState({
      daysList: daysList,
      tableStartDate: endOfMonth,
    })
  }

  getPrevDays = () => {

    let nextStartDate = moment(this.state.tableStartDate).subtract(1, 'month').format('YYYY-MM-DD');
    if (moment().isAfter(nextStartDate, 'month')) {
      return null;
    }
    let daysList = [];

    const startOfMonth = moment(nextStartDate).startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment(nextStartDate).endOf('month').format('YYYY-MM-DD');

    for (var m = moment(startOfMonth); m.isSameOrBefore(endOfMonth); m.add(1, 'days')) {
      if (m.isSameOrAfter(moment().format('YYYY-MM-DD'))) {
        daysList.push(moment(m.format('YYYY-MM-DD')));
      }
    }

    var a = moment(endOfMonth);
    var b = moment();
    let nextMonth = moment(startOfMonth).add(1, 'month').startOf('month').format('YYYY-MM-DD');

    if (a.diff(b, 'days') <= 30) {
      let count = Math.abs(30 - a.diff(b, 'days'));
      let i = 0;
      for (i == 0; i < count; i++) {
        daysList.push(moment(nextMonth).add(i, 'd'))
      }
    }

    this.setState({
      daysList: daysList,
      tableStartDate: nextStartDate,
    })
  }




  getRoomName = () => {
    if (this.props.roomList.roomList && this.state.selectedRoom) {
      let Room = this.props.roomList.roomList.filter(val => val.id == this.state.selectedRoom)
      return Room[0].name;
    }
    return ""
  }



  changeStartDate = (date) => {
    let nextStartDate = moment(date).format('YYYY-MM-DD');
    let daysList = [];

    const startOfMonth = moment(nextStartDate).startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment(nextStartDate).endOf('month').format('YYYY-MM-DD');

    for (var m = moment(startOfMonth); m.isSameOrBefore(endOfMonth); m.add(1, 'days')) {
      if (m.isSameOrAfter(moment().format('YYYY-MM-DD'))) {
        daysList.push(moment(m.format('YYYY-MM-DD')));
      }
    }

    var a = moment(endOfMonth);
    var b = moment();
    let nextMonth = moment(startOfMonth).add(1, 'month').startOf('month').format('YYYY-MM-DD');

    if (a.diff(b, 'days') <= 30) {
      let count = Math.abs(30 - a.diff(b, 'days'));
      let i = 0;
      for (i == 0; i < count; i++) {
        daysList.push(moment(nextMonth).add(i, 'd'))
      }
    }

    this.setState({
      daysList: daysList,
      tableStartDate: startOfMonth,
      tableStartDate: nextStartDate
    })
  }


  render() {

    if (!this.state.dateSetting) {
      return (
        <>
        </>
      )
    }

    const { classes } = this.props;
    const { t } = this.props;

    let calendarData = this.state.calendarData.property;
    let all_room_data = {
      'dates': [],
      'occupancy': [],
      'rooms_left': [],
      'monthly_adjust': [],
      'weekday_adjust': [],
      'daily_adjust': [],
      'skip_dates': [],
      'skip_date_name': []
    }

    this.state.daysList.map((date) => {
      all_room_data['dates'].push(
        <TableCell style={(date.day() == 6 || date.day() == 0) ? { backgroundColor: "#e8e8e8" } : { textShadow: "1px 1px #f1f1f1" }}>
          {date.format('DD')} <br /> {date.format('ddd')}
        </TableCell>
      )

      let occ = 0;

      let roomsLeft = '-';

      let row = calendarData ? calendarData.filter(val => val.date == date.format('YYYY-MM-DD'))[0] : '';

      if (row) {
        occ = row.occupancy;
        roomsLeft = row.total_inventory;
      }

      all_room_data['occupancy'].push(
        <TableCell style={{ backgroundColor: '#9FDCE4' }}>{row ? occ + '%' : '-'}</TableCell>
      )

      all_room_data['rooms_left'].push(
        <TableCell style={{ backgroundColor: '#9FDCE4' }}>{roomsLeft}</TableCell>
      )

      if (this.state.dateSetting) {
        all_room_data['monthly_adjust'].push(
          <TableCell style={{ backgroundColor: '#f2f2f2' }}>
            {this.state.dateSetting ? this.state.dateSetting.adjustment.monthly[date.format('MMMM')].standard : ""}%
                </TableCell>
        )

        all_room_data['weekday_adjust'].push(
          <TableCell style={{ backgroundColor: '#f2f2f2' }}>
            {this.state.dateSetting ? this.state.dateSetting.adjustment.weekday[date.format('dddd').toLowerCase()].standard : ""}%
                </TableCell>
        )

        let adj = '';
        if (this.state.dateSetting.dates[date.format('YYYY-MM-DD')] && this.state.dateSetting.dates[date.format('YYYY-MM-DD')].individual_adjustment) {
          adj = this.state.dateSetting.dates[date.format('YYYY-MM-DD')].individual_adjustment.percentage + "%";
        }
        all_room_data['daily_adjust'].push(
          <TableCell>
            {adj &&
              <Button className={classes.adjDeleteBtn} onClick={() => this.deleteIndividualDate(date.format('YYYY-MM-DD'))}><span>{adj}</span><Icon className={classes.adjDeleteBtnspan}>delete</Icon></Button>
            }
          </TableCell>
        )

        let skip = '';
        let skip_name = '';
        let isSkip = false;
        if (this.state.dateSetting.dates[date.format('YYYY-MM-DD')] && this.state.dateSetting.dates[date.format('YYYY-MM-DD')].skip_date) {
          skip = this.state.dateSetting.dates[date.format('YYYY-MM-DD')].skip_date.fixed_price ? <span>{this.state.currency.symbol}{this.state.dateSetting.dates[date.format('YYYY-MM-DD')].skip_date.fixed_price}</span> : <span>skip</span>;
          skip_name = this.state.dateSetting.dates[date.format('YYYY-MM-DD')].skip_date.name ? this.state.dateSetting.dates[date.format('YYYY-MM-DD')].skip_date.name : 'skip';
          isSkip = true;
        }

        all_room_data['skip_dates'].push(
          <TableCell>
            {isSkip &&
              <Button className={classes.adjDeleteBtn} onClick={() => this.deleteSkipDate(date.format('YYYY-MM-DD'))}>{skip}<Icon className={classes.adjDeleteBtnspan}>delete</Icon></Button>
            }
          </TableCell>
        )

        all_room_data['skip_date_name'].push(
          <TableCell title={skip_name}>
            {skip_name}
          </TableCell>
        )
      } else {
        all_room_data['monthly_adjust'].push(<TableCell style={{ backgroundColor: '#f2f2f2' }}>0%</TableCell>)
        all_room_data['weekday_adjust'].push(<TableCell style={{ backgroundColor: '#f2f2f2' }}>0%</TableCell>)
        all_room_data['daily_adjust'].push(<TableCell>0%</TableCell>)
        all_room_data['skip_dates'].push(<TableCell></TableCell>)
        all_room_data['skip_date_name'].push(<TableCell>0</TableCell>)
      }
    })

    let room_table_data = [];
    this.props.roomList.roomList && this.props.roomList.roomList.filter(rooms => (rooms.pms_rate || rooms.is_reference_room == true)).map((rooms, index) => {
      let data = this.state.calendarData[rooms.id];
      let table_data = {
        roomLeft: [],
        price: [],
        avg: [],
        refAdj: [],
        min: [],
        max: [],
        room: [],
      };

      if (rooms.is_reference_room) {
        table_data.title = <Typography className={classes.labelColumn} align="left" variant="h6">{t(`Reference ${this.state.room_apartment_space_name} Type:`)} {rooms.name}
          <Button variant="contained" color="primary" className={classes.bultUpdateBtn} onClick={() => this.bulkRoomModalToggle(rooms.id, 1)} >{t("Bulk Min/Max")}</Button>
        </Typography>;
      } else {
        table_data.title = <Typography className={classes.labelColumn} align="left" variant="h6">{t(`Derived ${this.state.room_apartment_space_name} Type:`)} {rooms.name}
          {(this.state.featuresEnabled.includes(6)) &&
            <Button variant="contained" color="primary" className={classes.bultUpdateBtn} onClick={() => this.bulkRoomModalToggle(rooms.id, 1)} >{t("Bulk Min/Max")}</Button>
          }
          {rooms.is_reference_room ? '' : <Button variant="contained" color="primary" className={classes.bultUpdateBtn} onClick={() => this.bulkRoomModalToggle(rooms.id, 2)}>{t("Bulk Derivation")}</Button>}
        </Typography>;
      }

      table_data.room = <TableCell style={{ borderColor: '#e0e0e0', backgroundColor: "#fff" }}><Typography variant="h6" className={classes.roomNameColumn} ></Typography></TableCell>

      this.state.daysList.map((date) => {
        let roomsLeft = '-';
        let price = '-';
        let avg = '';
        let refAdj = '';

        let row = data ? data.filter(val => val.date == date.format('YYYY-MM-DD'))[0] : '';
        if (row) {
          roomsLeft = row.inventory_remaining;
          price = row.price;
        }

        if (this.state.dateSetting) {
          avg = this.state.dateSetting.default[rooms.id] ? this.state.dateSetting.default[rooms.id].avg_price : '';
        }

        if (this.state.dateSetting.dates[date.format('YYYY-MM-DD')] && this.state.dateSetting.dates[date.format('YYYY-MM-DD')][rooms.id] && !rooms.is_reference_room) {
          refAdj = this.state.dateSetting.dates[date.format('YYYY-MM-DD')][rooms.id].adjustment_to_reference_room.toFixed(0);
        } else if (this.state.dateSetting.dates && !rooms.is_reference_room) {
          refAdj = this.state.dateSetting.default[rooms.id] ? this.state.dateSetting.default[rooms.id].adjustment_to_reference_room.toFixed(0) : '';
        } else {
          refAdj = "";
        }

        table_data.roomLeft.push(
          <TableCell style={{ backgroundColor: '#9FDCE4' }}>
            {roomsLeft}
          </TableCell>
        )

        table_data.price.push(
          <TableCell style={{ backgroundColor: '#FEE378' }}>
            {row ? this.state.currency.symbol : ''}{price}
          </TableCell>
        )

        table_data.avg.push(
          <TableCell style={{ backgroundColor: '#f2f2f2' }}>
            {this.state.currency.symbol}{avg}
          </TableCell>
        )

        if (!rooms.is_reference_room) {
          table_data.refAdj.push(
            <TableCell>
              {refAdj < 0 ? "-" : ""}
              {this.state.isAbsolute ? this.state.currency.symbol : ''}
              {Math.abs(refAdj)}
              {this.state.isAbsolute ? '' : '%'}
            </TableCell>
          )
        }

        if (this.state.featuresEnabled.includes(6) || rooms.is_reference_room) {
          if (this.state.dateSetting) {
            let max = '';
            let min = '';
            if (this.state.dateSetting.dates[date.format('YYYY-MM-DD')] && this.state.dateSetting.dates[date.format('YYYY-MM-DD')][rooms.id]) {
              min = this.state.dateSetting.dates[date.format('YYYY-MM-DD')][rooms.id].min_price;
              max = this.state.dateSetting.dates[date.format('YYYY-MM-DD')][rooms.id].max_price;
            } else if (this.state.dateSetting.dates) {
              min = this.state.dateSetting.default[rooms.id] ? this.state.dateSetting.default[rooms.id].min_price : '';
              max = this.state.dateSetting.default[rooms.id] ? this.state.dateSetting.default[rooms.id].max_price : '';
            }
            table_data.min.push(
              <TableCell>
                {this.state.currency.symbol}{min}
              </TableCell>
            )
            table_data.max.push(
              <TableCell>
                {this.state.currency.symbol}{max}
              </TableCell>
            )
          } else {
            table_data.min.push(
              <TableCell>0</TableCell>
            )
            table_data.max.push(
              <TableCell>0</TableCell>
            )
          }
        }
      })
      room_table_data.push(table_data)
    })

    return (
      <Card style={{ maxWidth: "none" }}>
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={8}>
              <Typography align="left" variant="h5" className={classes.calendarLabel} style={{ marginBottom: "15px" }}>{t("Daily/Seasonal Adjustments")}</Typography>
            </Grid>
          </Grid>
          <div className={classes.fullWidth}>
            <MuiThemeProvider theme={this.buttonTheme} >
              <Button variant="outlined" style={{ marginRight: "7px", height: "32px", padding: "0", float: "left" }} onClick={() => this.changeStartDate(moment().format('YYYY-MM-DD'))}>{t("Today")}</Button>
            </MuiThemeProvider>
            {/* <Button variant="outlined"  onClick={() => this.changeStartDate(moment().format('YYYY-MM-DD'))} style={{ fontSize: "12px", float: "left", fontWeight: "400" }}>Today</Button> */}
            <Button className={classes.navigateBtn} onClick={() => this.getPrevDays()}><Icon>navigate_before</Icon></Button>
            <Button className={classes.navigateBtn} onClick={() => this.getNextDays()}><Icon>navigate_next</Icon></Button>
            <MuiPickersUtilsProvider utils={DateFnsUtils} moment={moment()}>
              <DatePicker
                style={{ float: "left" }}
                InputProps={{
                  disableUnderline: true,
                  style: { width: "70px", color: '#000000bf', fontSize: "20px", marginLeft: "5px" }
                }}
                onChange={(event) => this.changeStartDate(moment(event).format('YYYY-MM-DD'))}
                value={moment(this.state.tableStartDate).format('YYYY-MM-DD')}
                format={"MMM yy"}
                autoOk={true}
              />
            </MuiPickersUtilsProvider>
            {/* <Button color={'secondary'} variant="contained" className={classes.navigateBtnSave} onClick={() => this.saveTabledata()} style={this.state.isGrey ? { opacity: "0.3", marginRight: "5px" } : {}}>{t('Save')}</Button> */}
            {(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) &&
              <Button color={'primary'} variant="outlined" className={classes.navigateBtnSave} onClick={() => this.downloadTabledata()}>{t('download')}</Button>
            }
          </div>
          <Grid container>
            <Grid item xs={12} sm={8}>
              {this.state.viewType != 'table' &&
                <Typography align="left" variant="h5" className={classes.calendarLabel}>{this.getRoomName()}</Typography>
              }
              {this.state.viewType == 'table' &&
                <Typography align="left" variant="h5" className={classes.calendarLabel}>{t("Daily/Seasonal Adjust")}</Typography>
              }
            </Grid>
          </Grid>
          {this.props.roomList.roomList &&
            <MuiThemeProvider theme={bulkSettingTheme}>
              <div style={{ overflowX: "auto" }} className='scrollClass'>
                {this.state.dateSetting != null &&
                  <>
                    <Typography className={classes.labelColumn} align="left" variant="h6">{t(`Adjustments: All ${this.state.room_apartment_space_name}s`)}
                      <Button variant="contained" color='primary' className={classes.bultUpdateBtn} onClick={() => this.bulkDateAdjModalToggle('2')} >{t("Bulk Adjust")}</Button>
                      <Button variant="contained" color='primary' className={classes.bultUpdateBtn} onClick={() => this.bulkDateAdjModalToggle('1')} >{t("Bulk Fix")}</Button>
                      <Button variant="contained" color='primary' className={classes.bultUpdateBtn} onClick={() => this.bulkDateAdjModalToggle('5')} >{t("Bulk Skip")}</Button>
                    </Typography>
                    <Table className={classes.table}>
                      <TableHead>
                      </TableHead>
                      <TableBody>
                        <TableRow className={classes.tableRow}>
                          <TableCell style={{ borderColor: '#e0e0e0', backgroundColor: "#fff" }}><span className={classes.tableRoomNameRow}></span></TableCell>
                          {all_room_data.dates}
                        </TableRow>

                        <TableRow className={classes.tableRow}>
                          <TableCell style={{ backgroundColor: '#9FDCE4' }}>{t("Occupancy")}</TableCell>
                          {all_room_data.occupancy}
                        </TableRow>

                        <TableRow className={classes.tableRow}>
                          <TableCell style={{ backgroundColor: '#9FDCE4' }}>{t(`${this.state.room_apartment_space_name}s Left`)}</TableCell>
                          {all_room_data.rooms_left}
                        </TableRow>

                        <TableRow className={classes.tableRow} >
                          <TableCell style={{ backgroundColor: '#f2f2f2' }}>{t("Monthly Adjust")}</TableCell>
                          {all_room_data.monthly_adjust}
                        </TableRow>

                        <TableRow className={classes.tableRow} >
                          <TableCell style={{ backgroundColor: '#f2f2f2' }}>{t("Weekday Adjust")}</TableCell>
                          {all_room_data.weekday_adjust}
                        </TableRow>

                        <TableRow className={classes.tableRow} >
                          <TableCell>{t("Daily Adjustment")}</TableCell>
                          {all_room_data.daily_adjust}
                        </TableRow>

                        <TableRow className={classes.tableRow} >
                          <TableCell style={{ minHeight: "5px" }}>{t("Fix Price / Skip")}</TableCell>
                          {all_room_data.skip_dates}
                        </TableRow>

                        <TableRow className={classes.tableRow} >
                          <TableCell style={{ minHeight: "5px" }}>{t("Name")}</TableCell>
                          {all_room_data.skip_date_name}
                        </TableRow>

                      </TableBody>
                    </Table>

                    {room_table_data.map((table_data, index) => {

                      return (<>
                        {table_data.title}
                        <Table className={classes.table}>
                          <TableHead>
                          </TableHead>
                          <TableBody>
                            <TableRow className={classes.tableRow}>
                              {table_data.room}
                              {all_room_data.dates}
                            </TableRow>

                            <TableRow className={classes.tableRow}>
                              <TableCell style={{ backgroundColor: '#9FDCE4' }}>{t(`${this.state.room_apartment_space_name}s Left`)}</TableCell>
                              {table_data.roomLeft}
                            </TableRow>

                            <TableRow className={classes.tableRow}>
                              <TableCell style={{ backgroundColor: '#FEE378' }}>{t("Recommended Price")}</TableCell>
                              {table_data.price}
                            </TableRow>

                            <TableRow className={classes.tableRow} >
                              <TableCell style={{ backgroundColor: '#f2f2f2' }}>{t("Average Price")}</TableCell>
                              {table_data.avg}
                            </TableRow>

                            {table_data.refAdj.length > 0 &&
                              <TableRow className={classes.tableRow} >
                                <TableCell>{t(`Deriv. From Ref. ${this.state.room_apartment_space_name} `)}</TableCell>
                                {table_data.refAdj}
                              </TableRow>
                            }
                            {table_data.min.length > 0 &&
                              <TableRow className={classes.tableRow} >
                                <TableCell style={{ minHeight: "5px" }}>{t("Min Price")} </TableCell>
                                {table_data.min}
                              </TableRow>
                            }
                            {table_data.max.length > 0 &&
                              <TableRow className={classes.tableRow}>
                                <TableCell style={{ minHeight: "5px" }}>{t("Max Price")} </TableCell>
                                {table_data.max}
                              </TableRow>
                            }
                          </TableBody>
                        </Table>
                      </>
                      )
                    })
                    }
                  </>
                }
                {this.state.dateSetting == null &&
                  <Table className={classes.table}>
                    <TableRow>
                      <TableCell>
                        <Typography>No Data Available!</Typography>
                      </TableCell>
                    </TableRow>
                  </Table>

                }
              </div>
              {Modals.bulkUpdate(this)}
              {Modals.bulkDateAdjustment(this)}
              {Modals.bulkRoomAdjustment(this)}
              {Modals.leavePageModal(this)}
              {Modals.rerunAlertPopup(this)}
            </MuiThemeProvider >
          }
        </CardContent>
      </Card >
    )

  }
}

CalulatePrice.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
  return {
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    commonReducer: state.commonReducer,
    userSession: state.sessionReducer,
    hotelDetail: state.hotelSettingReducer.hoteDetail,
    currencyList: state.currencyReducer.currencyList,
    hotelAuthReducer: state.hotelAuthReducer,
    inventaryReducer: state.inventaryReducer,
    roomList: state.roomListReducer,
    eventReducer: state.eventReducer,
    commonReducer: state.commonReducer,
    rateSettingReducer: state.rateSettingReducer,
    priceNoteReducer: state.priceNoteReducer,
    dailyAdjustmentReducer: state.dailyAdjustmentReducer,
    priceSettingReducer: state.priceSettingReducer,
    monthlyAdjustReducer: state.monthlyAdjustReducer,
    weeklyAdjustReducer: state.weeklyAdjustReducer,
    featuresReducer: state.featuresReducer,
    skipPriceReducer: state.skipPriceReducer,
    algoReducer: state.algoReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getInventary: (request) => { dispatch(actions.getInventary(request)) },
    updateInventary: (request) => { dispatch(actions.updateInventary(request)) },
    uploadFile: (request) => { dispatch(actions.uploadFile(request)) },
    getRoomList: (request) => { dispatch(actions.getRoomList(request)) },
    updatePricePms: (request) => { dispatch(actions.updatePricePms(request)) },
    getEventList: (request) => { dispatch(actions.getEventList(request)) },
    getHotelDetail: (request) => { dispatch(actions.getHotelDetail(request)) },
    putNotify: (request) => { dispatch(actions.putNotify(request)) },
    // defineRateList: (request) => { dispatch(actions.defineRateList(request)) },
    updateHotel: (request) => { dispatch(actions.updateHotelSetting(request)) },
    getPriceNote: (request) => { dispatch(actions.getPriceNote(request)) },
    addPriceNote: (request) => { dispatch(actions.addPriceNote(request)) },
    updatePriceNote: (request) => { dispatch(actions.updatePriceNote(request)) },
    deletePriceNote: (request) => { dispatch(actions.deletePriceNote(request)) },
    dailyAdjustCreate: (request) => { dispatch(actions.dailyAdjustCreate(request)) },
    updateDailyAdjust: (request) => { dispatch(actions.updateDailyAdjust(request)) },
    updateDailyAdjust: (request) => { dispatch(actions.updateDailyAdjust(request)) },
    skipPriceCreate: (request) => { dispatch(actions.skipPriceCreate(request)) },
    updateSkipPrice: (request) => { dispatch(actions.updateSkipPrice(request)) },
    getPricingSettings: (request) => { dispatch(actions.getPricingSettings(request)) },
    updatePricingSettings: (request) => { dispatch(actions.updatePricingSettings(request)) },
    updateMonthlyAdjust: (request) => { dispatch(actions.updateMonthlyAdjust(request)) },
    updateWeeklyAdjust: (request) => { dispatch(actions.updateWeeklyAdjust(request)) },
    updateLastPricing: (request) => { dispatch(actions.updateLastPricing(request)) },
    createAlgoProgram: (request) => { dispatch(actions.createAlgoProgram(request)) },
    getAlgoProgram: (request) => { dispatch(actions.getAlgoProgram(request)) },
    stopNavigation: (request) => { dispatch(actions.stopNavigation(request)) },
    showNavigationPopup: (request) => { dispatch(actions.showNavigationPopup(request)) },
    updatePriceJson: (request, response, type) => { dispatch(actions.updateSettingJson(request, response, type)) },
  };
};
export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(CalulatePrice));