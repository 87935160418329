import React, { Component } from 'react';
import Particles from 'react-particles-js';

class partical extends Component {
    render() {
        return (
            <div style={{background:'#f2f2f2',height:'calc(100vh - 105px)'}}>

                {/* <img src="/img/login_animation.gif" style={{width:'100%',height:'100%',display:'block'}}  /> */}
            </div>
            // <Particles
            //     params={{
            //         "particles": {
            //             "number": {
            //                 "value": 200,
            //                 "density": {
            //                     "enable": false
            //                 }
            //             },
            //             "size": {
            //                 "value": 3,
            //                 "random": true,
            //                 "anim": {
            //                     "speed": 4,
            //                     "size_min": 0.3
            //                 }
            //             },
            //             "line_linked": {
            //                 "enable": false
            //             },
            //             "move": {
            //                 "random": true,
            //                 "speed": 1,
            //                 "direction": "top",
            //                 "out_mode": "out"
            //             }
            //         },
            //         "interactivity": {
            //             "events": {
            //                 "onhover": {
            //                     "enable": true,
            //                     "mode": "bubble"
            //                 },
            //                 "onclick": {
            //                     "enable": true,
            //                     "mode": "repulse"
            //                 }
            //             },
            //             "modes": {
            //                 "bubble": {
            //                     "distance": 100,
            //                     "duration": 2,
            //                     "size": 0,
            //                     "opacity": 0
            //                 },
            //                 "repulse": {
            //                     "distance": 400,
            //                     "duration": 2
            //                 }
            //             }
            //         }
            //     }}
            //     style={{
            //         position: "fixed",
            //         // top: "0%",
            //         left: "0",
            //         width: "100%",
            //         // marginTop: "30px",
            //         backgroundColor:'#f2f2f2',
            //         backgroundImage: 'linear-gradient(to right, rgb(63, 114, 255), rgb(0, 3, 10))'
            //     }} height={"100%"} />
        );
    }
}

export default partical