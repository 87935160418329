import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import PricingReportCss from './css/pricingReportCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../store/actions/index';
import { withNamespaces } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { pricingReportTheme } from '../muiTheme';

import moment from 'moment';

const styles = PricingReportCss;

class PricingReport extends React.Component {

    state = {
        reportData: [],
        roomList: [],
        currency: null
    }

    componentDidMount() {
        let requestData = {
            token: this.props.hotelAuthReducer.hotelDetails.token,
        };
        this.props.getPricingReport(requestData)

        this.setState({
            currency: this.props.hotelAuthReducer.hotelDetails.hotel.currency
        })
    }

    componentWillReceiveProps(props) {
        if (props.priceReportReducer && props.priceReportReducer.priceReport && props.priceReportReducer.priceReport.data && this.state.reportData.length == 0) {
            let reportData = [];
            Object.keys(props.priceReportReducer.priceReport.data).filter(val => moment().diff(moment(val), 'days') <= 0).sort(function (a, b) {
                a = new Date(a)
                b = new Date(b)
                return a - b;
            }).forEach(function (key) {
                reportData.push(props.priceReportReducer.priceReport.data[key])
            })

            this.setState({
                reportData
            })
        }

        if (this.props.roomList && this.props.roomList.roomList && this.state.roomList.length == 0) {
            let roomList = [];
            this.props.roomList.roomList.map(val => {
                roomList[val.id] = val.name
            })
            this.setState({
                roomList
            })
        }
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        const { roomList } = this.state;
        return (
            <Card>
                <CardHeader
                    style={{ textAlign: 'left' }}
                    title={t('Report')}
                >
                </CardHeader>
                <CardContent>
                    <MuiThemeProvider theme={pricingReportTheme} >
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '12%' }}>{t("Date")}</TableCell>
                                    <TableCell align="center">{t("Occupancy")}</TableCell>
                                    <TableCell align="center">{t("Room Left Total")}</TableCell>
                                    <TableCell align="center">{t("Cheapest Room Available")}</TableCell>
                                    <TableCell align="center" className={classes.roomLeftCol}>{t("Room Left 0 or 1")}</TableCell>
                                    <TableCell align="center">{t("1 Day Pickup")}</TableCell>
                                    <TableCell align="right">{t("7 Day Pickup")}</TableCell>
                                    <TableCell align="right">{t("Recommendations over max price")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.reportData.map(row => (
                                    <TableRow key={row.date}>
                                        <TableCell component="th" scope="row">{row.date}</TableCell>
                                        <TableCell align="center">{row.occupancy !== null ? (row.occupancy + "%") : ""}</TableCell>
                                        <TableCell align="center">{row.total_rooms_left}</TableCell>
                                        <TableCell align="center">{row.cheapest_room_available ? roomList[row.cheapest_room_available.room] : ""} {row.cheapest_room_available ? ("(" + (this.state.currency ? this.state.currency.symbol : "")) : ""}{row.cheapest_room_available ? (row.cheapest_room_available.price + ")") : ""}</TableCell>
                                        <TableCell align="center">
                                            {row.room_left_0_or_1.map(val => {
                                                return <span className={classes.roomName}>{roomList[val]}<br /></span>
                                            })}
                                        </TableCell>
                                        <TableCell align="center">{row.one_day_pickup !== null ? (row.one_day_pickup + "%") : ""}</TableCell>
                                        <TableCell align="right">{row.one_week_pickup !== null ? (row.one_week_pickup + "%") : ""}</TableCell>
                                        <TableCell align="right">{row.recommendations_over_max_price}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </MuiThemeProvider>
                </CardContent>
            </Card >
        );
    }
}

PricingReport.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        hotelAuthReducer: state.hotelAuthReducer,
        commonReducer: state.commonReducer,
        priceReportReducer: state.priceReportReducer,
        roomList: state.roomListReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getPricingReport: (request) => { dispatch(actions.getPricingReport(request)) },
    };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(PricingReport));