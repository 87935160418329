var style = {
  headigTop: {
    textAlign: "left",
    width: 'fitContent',
    padding:"16px 16px 16px 20px"
},
divStyle: {
  float: "left", 
  marginTop: "2px"  
},
fontStyle: {
  textAlign: "left", 
  fontWeight: "500"
},
cardRoot: {
  marginTop:'-20px'
},
tableField: {
  minWidth: "150px", 
  maxWidth: "250px", 
  wordBreak: 'break-all'
},
btnDiv: {
  display:"flex"
},
btnAdd: {
  margin: "8px 6px", 
  float: "left" 
},
};
export default style;
