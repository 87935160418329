var style = {
    logCard: {
      marginTop: 30
    },
    saveBtn: {
      marginTop: "20px",  
      height: "fit-content",
      display:"flex",
      ['@media (max-width: 414px) and (min-width: 360px)']: {
      }
  },
  changelogBtn: {
    textAlign:'left !important'
  },
  divStyle: {
    float: "left", 
    marginTop: "2px"
  },
  fontStyle: {
    textAlign: "left", 
    fontWeight: "500"
  },
  pricecacheBtn: {
    marginRight:"16px"
  }
  }
  export default style;