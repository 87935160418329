import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import adminuserCss from './css/adminUserCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import MUIDataTable from "mui-datatables";
import * as actions from '../../store/actions/index';
import Button from '@material-ui/core/Button';
import Modal from './modal/modal'
import { MuiThemeProvider } from '@material-ui/core/styles';
import { resellerTheme } from '../muiTheme';
import { Icon } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import HeaderCard from "../Client/HeaderCard";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';


const styles = adminuserCss;

class RateSetting extends React.Component {

    state = {
        value: 1,
        member_count: 0,
        member_current: 1,
        memberList: [],
        allMemberList: [],
        user_count: 0,
        user_current: 1,
        userList: [],
        userName: '',
        name: '',
        newUserModal: false,
        deleteUserModal: false,
        updateResellerModal: false,
        error: [],
        newMemberModal: false,
        reseller: null,
        memberData: {
            first_name: '',
            last_name: '',
            email: '',
            password: ''
        },
        editMember: {
            email: '',
            first_name: '',
            id: '',
            last_name: '',
            sub_account_type: ''
        },
        deleteMemberModal: false,
        editMemberModal: false,
        deleteUserModal: null,
        user_loading:true,
        member_loading:true
    };

    componentWillMount() {
        let requestParams = {
            token: this.props.userSession.token
        };
        this.props.getReseller(requestParams)
        this.props.getResellerMember(requestParams)
        this.props.getResellerAll(requestParams)
        this.showSearch()
    }
    showSearch = () => {
        setTimeout(() => {
            let search = document.querySelectorAll('[title="Search"]');
            if (search.length) {

                search[0].click();
                if (search.length > 1) {
                    search[1].click();
                }
            } else {
                this.showSearch();
            }
        }, 500)
    }    
    componentWillReceiveProps(props) {
        if (props.resellerReducer.memberList) {
            this.setState({
                memberList: props.resellerReducer.memberList,
                member_loading:false,
                member_count: props.resellerReducer.memberList.count,
                current: props.resellerReducer.memberList.current,
            })
        }

        if (props.resellerReducer.list) {
            this.setState({
                userList: props.resellerReducer.list,
                user_loading:false,
                user_count: props.resellerReducer.list.count,
                user_current: props.resellerReducer.list.current,

            })
        }
        if(props.resellerReducer.allResellerList){
            this.setState({
                allMemberList:props.resellerReducer.allResellerList
            })
        }
    }

    toggleDeleteUser = (id) => {
        this.setState({
            deleteUserModal: !this.state.deleteUserModal,
            id: id,
            error:[]
        }, () => {
            this.setState({
                updateResellerModal: false,
            });
          })
    }

    toggleDeleteMember = (id, resId) => {
        this.setState({
            deleteMemberModal: !this.state.deleteMemberModal,
            memberDeleteId: id,
            memberResellerId: resId
        }, () => {
            this.setState({
                editMemberModal: false,
            });
          })
    }

    openEditMember = (value) => {
        this.setState({
            editMemberModal: !this.state.editMemberModal,
            reseller: value.reseller.id,
            editMember: {
                email: value.user.email,
                first_name: value.user.first_name,
                id: value.user.id,
                last_name: value.user.last_name,
                sub_account_type: value.user.sub_account_type
            }
        })
    }

    toggleEditMember = () => {
        this.setState({
            editMemberModal: !this.state.editMemberModal,
            error:[]
        })
    }

    validateEditMemberForm = (data) => {

        let validData = this.state.editMember;
        let error = [];
        if (validData) {

            if (!validData.email) {
                error.push('email')
            } else {
                var re = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
                if (!re.test(validData.email)) {
                    error.push('email')
                }
            }

            if (!validData.first_name) {
                error.push('first_name')
            }
            if (!validData.last_name) {
                error.push('last_name')
            }

            if (!this.state.reseller && this.props.userSession.user.is_staff) {
                error.push('reseller')
            }


            if (error) {
                this.setState({
                    error: error
                })
                return error
            } else {
                return false
            }
        } else {
            return true;
        }
    }


    editMember = () => {

        let validateError = this.validateEditMemberForm(this.state);
        if (validateError.length > 0) {
            return;
        }

        let requestParams = {
            ...this.state.editMember,
            reseller: this.state.reseller,
            token: this.props.userSession.token,
        }
        this.props.editResellerMember(requestParams)
        this.toggleEditMember();
    }

    deleteUser = () => {
        let requestParams = {
            token: this.props.userSession.token,
            id: this.state.id
        };
        this.props.deleteReseller(requestParams)
        this.toggleDeleteUser()
    }


    deleteMember = () => {
        let requestParams = {
            token: this.props.userSession.token,
            member: this.state.memberDeleteId,
            reseller: this.state.memberResellerId
        };
        this.props.deleteResellerMember(requestParams)
        this.toggleDeleteMember()
    }

    toggleAddUser = (data) => {
        this.setState({
            newUserModal: !this.state.newUserModal,
            userName: '',
            name: '',
            error: []
        })
    }

    toggleAddMember = (data) => {
        this.setState({
            newMemberModal: !this.state.newMemberModal,
            reseller: null,
            memberData: { 
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                error:[]
            }
        })
    }

    toggleUpdateUser = (data) => {
        if (!this.state.updateResellerModal) {
            this.setState({
                updateResellerModal: !this.state.updateResellerModal,
                edit_id: data.id,
                edit_userName: data.email,
                edit_name: data.name,
                error: []
            })
        } else {
            this.setState({
                updateResellerModal: !this.state.updateResellerModal
            })
        }
    }

    memberHandleChange = (name) => event => {
        this.setState({
            memberData: {
                ...this.state.memberData,
                [name]: event.target.value,
            }
        });
    }

    editMemberChange = (name) => event => {
        this.setState({
            editMember: {
                ...this.state.editMember,
                [name]: event.target.value,
            }
        });
    }

    handleChange = (name) => event => {
        this.setState({
            [name]: event.target.value,
        });
    }

    validateUserForm = (data) => {
        let error = [];
        if (data) {

            if (!this.state.name) {
                error.push('name')
            }
            if (!this.state.userName) {
                error.push('userName')
            } else {
                var re = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
                if (!re.test(this.state.userName)) {
                    error.push('userName')
                }
            }
            if (error) {
                this.setState({
                    error: error
                })
                return error
            } else {
                return false
            }
        } else {
            return true;
        }
    }

    addNewAdmin = () => {

        let validateError = this.validateUserForm(this.state);
        if (validateError.length > 0) {
            return;
        }

        let requestParams = {
            token: this.props.userSession.token,
            email: this.state.userName,
            name: this.state.name,
        };
        this.props.AddReseller(requestParams)
        this.toggleAddUser();
    }

    validateNewUser = () => {
        let validData = this.state.memberData;
        let error = [];
        if (validData) {

            if (!validData.email) {
                error.push('email')
            } else {
                var re = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
                if (!re.test(validData.email)) {
                    error.push('email')
                }
            }

            if (!validData.first_name) {
                error.push('first_name')
            }
            if (!validData.last_name) {
                error.push('last_name')
            }
            if (!validData.password) {
                error.push('password')
            }

            if (!this.state.reseller  && this.props.userSession.user.is_staff) {
                error.push('reseller')
            }


            if (error) {
                this.setState({
                    error: error
                })
                return error
            } else {
                return false
            }
        } else {
            return true;
        }
    }

    addNewMember = () => {
        let validateError = this.validateNewUser();
        if (validateError.length > 0) {
            return;
        }
        let userData = this.state.memberData;
        delete userData.error;
        
        let requestParams = {
            reseller: this.state.reseller,
            user_data: {
                ...userData
            },
            token: this.props.userSession.token,
        };
        this.props.AddResellerMember(requestParams)
        this.toggleAddMember();
    }


    validateEditUserForm = (data) => {
        let error = [];
        if (data) {

            if (!this.state.edit_name) {
                error.push('edit_name')
            }
            if (!this.state.edit_userName) {
                error.push('edit_userName')
            }

            if (error) {
                this.setState({
                    error: error
                })
                return error
            } else {
                return false
            }
        } else {
            return true;
        }
    }

    addUpdateAdmin = () => {

        let validateError = this.validateEditUserForm(this.state);
        if (validateError.length > 0) {
            return;
        }

        let requestParams = {
            token: this.props.userSession.token,
            id: this.state.edit_id,
            email: this.state.edit_userName,
            name: this.state.edit_name,
        };
        this.props.updateReseller(requestParams)
        this.toggleUpdateUser();
    }

    handleTabChange = (val) => {
        this.setState({
            value: val
        })
    }

    renderUserData = (data) => {
        let jsx = [];
        if (data && data.results) {
            data.results.map((val) => {
                jsx.push([
                    val.id,
                    val.name,
                    val.email,
                    val
                ])
                return null;
            })
        }
        return jsx;
    }

    renderUserMemberData = (data) => {
        let jsx = [];
        if (data && data.results) {
        //    let result = [].concat(data.results).sort((a, b) =>
        //       a.reseller.name > b.reseller.name ? 1:-1)
              data.results.map((val) => {
                jsx.push([
                    val.user.id,
                    val.reseller.name,
                    val.user.email,
                    val.user.first_name + ' ' + val.user.last_name,
                    val
                ])
                return null;
            })
        }
        return jsx;
    }

    user_options = () => {
        return {
            responsive: 'scroll',
            print: false,
            download: false,
            viewColumns: false,
            filter: false,
            rowsPerPageOptions: false,
            responsive: "scroll",
            selectableRows: false,
            page: (this.state.userList.results) ? (this.state.user_current - 1) : 0,
            count: this.state.user_count,
            serverSide: true,
            rowsPerPage: 20,
            search:true,
            sort: true,
            textLabels: {
                body: {
                    noMatch: this.state.user_loading ?
                        'Loading Records...' :
                        'Sorry, no matching records found.',
                },
            },
            onRowClick: (rowData) => {
                let user = this.state.userList.results.filter(val => val.id == rowData[0])
                this.toggleUpdateUser(user?user[0]:'')
            },
            onTableChange: (action, tableState) => {
                let url = 'account/reseller/list/';
                let requestParams = { token: this.props.userSession.token };
                if (action === 'changePage') {
                    let page = tableState.page + 1;
                    url += '?page=' + page;
                    requestParams.url = url;                    
                    this.props.getReseller(requestParams)        
                }
                if (action === 'search') {
                    let searchUserText = tableState.searchText ? tableState.searchText : '';
                    if (searchUserText) {
                        url += '?search=' + searchUserText;
                    }
                    requestParams.url = url
                    this.setState({
                        searchUserText: searchUserText
                    },
                        () => {
                        setTimeout(() => {  
                                if(searchUserText.length == this.state.searchUserText.length){
                                     this.props.getReseller(requestParams) 
                                }                                                          
                        }, 2000);
                    })
                }
            },
            onColumnSortChange: (changedColumn, direction) => {
                let sort_direction;
                let requestParams = { token: this.props.userSession.token };
                switch (changedColumn) {
                    case 'ID':
                        sort_direction = (direction === 'ascending') ? 'id' : '-id';
                        break;
                    case 'Name':
                        sort_direction = (direction === 'ascending') ? 'name' : '-name';
                        break;
                    case 'Email':
                        sort_direction = (direction === 'ascending') ? 'email' : '-email';
                        break;    
                    default:
                        break;    
                }
                requestParams.url='account/reseller/list/?ordering='+sort_direction+((this.state.searchUserText) ? '&search='+ this.state.searchUserText : '')
                this.props.getReseller(requestParams)
                this.setState({ user_ordering: sort_direction })
            },
            customToolbar: () => {
                const { classes, t } = this.props;
                return ( 
                    <MuiThemeProvider theme={resellerTheme}>
                        <Button onClick={this.toggleAddUser} variant="contained" color="primary" style={{marginLeft:'10px',marginRight:'-22px'}} >
                            Add New Partner
                        </Button>
                    </MuiThemeProvider>
                )
            }
        }
    };
    
    member_options = () => {
        return {
            responsive: 'scroll',
            print: false,
            download: false,
            viewColumns: false,
            filter: false,
            rowsPerPageOptions: false,
            responsive: "scroll",
            selectableRows: false,
            page: (this.state.memberList.results) ? (this.state.current - 1) : 0,
            count: this.state.member_count,
            serverSide: true,
            rowsPerPage: 20,
            search:true,
            sort: true,
            textLabels: {
                body: {
                    noMatch: this.state.user_loading ?
                        'Loading Records...' :
                        'Sorry, no matching records found.',
                },
            },
            onRowClick: (rowData) => {
                let memberUser = this.state.memberList.results.filter(val => val.user.id == rowData[0])
                this.openEditMember(memberUser?memberUser[0]:'')
            },
            onTableChange: (action, tableState) => {
                let url = 'account/reseller/member/list/';
                let requestParams = { token: this.props.userSession.token };
                if (action === 'changePage') {
                    let page = tableState.page + 1;
                    url += '?page=' + page;
                    requestParams.url = url;                    
                    this.props.getResellerMember(requestParams)        
                }
                if (action === 'search') {
                    let searchMemberText = tableState.searchText ? tableState.searchText : '';
                    if (searchMemberText) {
                        url += '?search=' + searchMemberText;
                    }
                    requestParams.url = url
                    this.setState({
                        searchMemberText: searchMemberText
                    },
                        () => {
                        setTimeout(() => {
                            if (searchMemberText.length == this.state.searchMemberText.length) {
                                this.props.getResellerMember(requestParams)
                            }
                        }, 200);
                    })
                }
            },
            onColumnSortChange: (changedColumn, direction) => {
                let sort_direction;
                let requestParams = { token: this.props.userSession.token };
                switch (changedColumn) {
                    case 'ID':
                        sort_direction = (direction === 'ascending') ? 'user__id' : '-user__id';
                        break;
                    case 'Partner':
                        sort_direction = (direction === 'ascending') ? 'reseller__first_name' : '-reseller__first_name';
                        break;
                    case 'Email':
                        sort_direction = (direction === 'ascending') ? 'user__email' : '-user__email';
                        break;    
                    case 'Name':
                        sort_direction = (direction === 'ascending') ? 'user__first_name' : '-user__first_name';
                        break;    
                }
                requestParams.url='account/reseller/member/list/?ordering='+sort_direction+((this.state.searchMemberText) ? '&search='+ this.state.searchMemberText : '')
                this.props.getResellerMember(requestParams)
                this.setState({ member_ordering: sort_direction })
            },
            customToolbar: () => {
                const { classes, t } = this.props;
                return ( 
                    <MuiThemeProvider theme={resellerTheme}>
                        <Button onClick={this.toggleAddMember}  variant="contained" color="primary" style={{marginLeft:'10px',marginRight:'-22px'}}>
                            Add New Partner User
                        </Button>
                    </MuiThemeProvider>
                )
            }
        }
    };
    


    render() {
        const { classes } = this.props;
        const columns = [
            { name: "ID" },
            { name: "Name" , options: { sortDirection: 'asc' } },
            { name: "Email" },
            {
                name: "Actions",
                options: {
                    filter: false,
                    download: false,
                    setCellProps: () => {
                        return { style: { color: '#541388', cursor: 'pointer' } }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <>
                                {/* <Button className={classes.actionIcon} color="primary" onClick={(id) => this.toggleUpdateUser(value)}><Icon>edit</Icon></Button> */}
                                <Button className={classes.actionIcon} color="secondary" onClick={(id) => this.toggleDeleteUser(value.id)}><Icon>delete</Icon></Button>
                            </>
                        );
                    }
                }
            }
        ];

        const membercolumns = [
            { name: "ID" },
            { name: "Partner" ,options: { sortDirection: 'asc' } },
            { name: "Email" },
            { name: "Name" },
            {
                name: "Actions",
                options: {
                    filter: false,
                    download: false,
                    setCellProps: () => {
                        return { style: { color: '#541388', cursor: 'pointer' } }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <>
                                {/* <Button className={classes.actionIcon} color="primary" onClick={(id) => this.openEditMember(value)}><Icon>edit</Icon></Button> */}
                                <Button className={classes.actionIcon} color="secondary" onClick={(id) => this.toggleDeleteMember(value.user.id, value.reseller.id)}><Icon>delete</Icon></Button>
                            </>

                        );
                    }
                }
            }
        ];

        return (
            <>
                {this.props.userSession.user.is_staff &&
                <>
                        <HeaderCard>
                            <Grid container>
                                <Grid item >
                                    <div className={classes.divStyle} >
                                    <Typography variant="h5" className={classes.fontStyle} >
                                        Partners
                                    </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </HeaderCard>
                    <Card>
                        <CardContent>
                            {/* <div className={classes.cardRoot} >
                                <Button onClick={this.toggleAddUser} className={classes.addMemberBtn} variant="contained" color="primary" >
                                    Add New Partner
                                </Button>
                            </div> */}

                            <MuiThemeProvider theme={resellerTheme}>
                                <MUIDataTable
                                    title={''}
                                    data={this.renderUserData(this.state.userList)}
                                    columns={columns}
                                    options={this.user_options()}
                                />
                            </MuiThemeProvider>
                        </CardContent>
                    </Card >
                    </>
                }
                <Card className={this.props.userSession.user.is_staff ? classes.cardMargin : ''}>
                    <CardHeader
                        className="headig-top"
                        title="Partner User">
                    </CardHeader>
                    <CardContent>
                        {/* <div className={classes.cardRoot} >
                            <Button onClick={this.toggleAddMember} className={classes.addMemberBtn} variant="contained" color="primary" >
                                Add New Partner User
                                </Button>
                        </div> */}
                        <MuiThemeProvider theme={resellerTheme}>
                            <MUIDataTable
                                title={''}
                                data={this.renderUserMemberData(this.state.memberList)}
                                columns={membercolumns}
                                options={this.member_options()}
                            />
                        </MuiThemeProvider>
                    </CardContent>
                </Card >
                {Modal.newResellerModal(this)}
                {Modal.deleteResellerModal(this)}
                {Modal.updateResellerModal(this)}
                {Modal.newResellerMemberModal(this)}
                {Modal.editResellerMemberModal(this)}
                {Modal.deleteResellerMemberModal(this)}
            </>
        );
    }
}

RateSetting.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        userSession: state.sessionReducer,
        resellerReducer: state.resellerReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getReseller: (request) => { dispatch(actions.getReseller(request)) },
        getResellerAll: (request) => { dispatch(actions.getResellerAll(request)) },
        AddReseller: (request) => { dispatch(actions.AddReseller(request)) },
        updateReseller: (request) => { dispatch(actions.updateReseller(request)) },
        deleteReseller: (request) => { dispatch(actions.deleteReseller(request)) },
        getResellerMember: (request) => { dispatch(actions.getResellerMember(request)) },
        deleteResellerMember: (request) => { dispatch(actions.deleteResellerMember(request)) },
        AddResellerMember: (request) => { dispatch(actions.AddResellerMember(request)) },
        editResellerMember: (request) => { dispatch(actions.editResellerMember(request)) },
    };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(RateSetting);