import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = [];

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_RESERVATION_FAILED_TASK_LIST_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.GET_RESERVATION_FAILED_TASK_LIST_SUCCEED:
            return updateObject(state, { loading: false, error: null, failedTaskList: action.state })
        case actionTypes.GET_RESERVATION_FAILED_TASK_LIST_ERROR:
            return updateObject(state, { loading: false, error: null })      
        default:
            return updateObject(state, { loading: false, error: null });
    }
}

export default reducer