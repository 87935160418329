import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = [];
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INVITE_USER_DETAIL:
            return updateObject(state, {loading: true, error: null, data: action.data})        
        default:
            return updateObject(state, {loading: false, error: null});
    }
}

export default reducer