import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = [];

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TAG_LIST_SUCCESS:
            return updateObject(state, { loading: false, error: null, tagList: action.state })
        case actionTypes.TAG_LIST_LOG_FAILED:
            return updateObject(state, { loading: false, error: null });
        default:
            return updateObject(state, { loading: false, error: null });
    }
}

export default reducer