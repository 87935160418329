import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import Notify from '../Notify'
import * as usersLog from '../UsersLog/usersLog';

//for hotel authentication

const hotelAuthStart = (data) => {
    return {
        type: actionTypes.HOTEL_AUTH_START,
        data: data
    }
}

const hotelAuthFailed = (error) => {
    return {
        type: actionTypes.HOTEL_AUTH_FAILED,
        error: error,
        errordata:error,
    }
}

const hotelAuthSucceed = (data, time) => {
    return {
        type: actionTypes.HOTEL_AUTH_SUCCEED,
        data: data,
        time: time
    }
}

const updateClientView = (data, time) => {
    return {
        type: actionTypes.UPDATE_CLIENT_VIEW,
        data: data,
    }
}

const initPusher = (data) => {
    return {
        type: actionTypes.PREPARE_PUSHER,
        data: data,
    }
}



export const hotelAuth = (request) => {
    return dispatch => {        
        dispatch(initPusher(request.id))
        axios.get(Server.API + 'account/utc-time/')
            .then((resp) => {
                axios.defaults.headers.common['Authorization'] = "Token " + request.token;
                axios.post(Server.API + 'account/auth/hotel/' + request.id + '/')
                    .then(function (response) {
                        response.data.hotel_list.sort(function (a, b) {
                            if (a.name < b.name) { return -1; }
                            if (a.name > b.name) { return 1; }
                            return 0;
                        })
                        dispatch(hotelAuthSucceed(response.data, resp.data.current_utc_time))
                        dispatch(usersLog.setUserLog(response.data))
                        dispatch(Notify.newUserModal("Authentication Successful", true))
                        if(response.data.hotel && response.data.hotel.is_client_view){
                            dispatch(updateClientView(response.data.hotel.is_client_view))
                        }
                    })
                    .catch(function (error) {
                        dispatch(Notify.newUserModal("Authentication Failed", false))
                        dispatch(hotelAuthFailed(error))                     
                    });
            });

    }
}
