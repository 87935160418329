import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../../../store/actions/index';
import Notify from '../Notify';
import { ValidateBaseSettings } from '../../../../src/App/Utils/SettingValidation/Validation';

const successPriceReport = (data) => {
    return {
        type: actionTypes.GET_PRICING_REPORT,
        data: data
    }
}

export const getPricingReport = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'pricingalgorithm/report/four-weeks/')
            .then((response) => {
                dispatch(successPriceReport(response.data))
            })
    }
}