import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ConnCss from './css/connectionsCss';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../store/actions/index';
import connectionList from './connectionList';
import Modals from './modal/modal';
import CustomTooltip from '../Utils/toolTip';
import Select, { components } from 'react-select';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialSelect from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ConnectiongetMuiTheme,  ConnectiongetMuiThemeType,  ConnectiongetMuiThemeRate, ConnectiongetMuicard } from '../muiTheme';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withNamespaces } from 'react-i18next';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import MUIDataTable from "mui-datatables";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import CreatableSelect from 'react-select/lib/Creatable';
import HeaderCard from './HeaderCard';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import moment from 'moment';
import Warning from "./warning";

const styles = ConnCss;

// APALEO = 1, MEWS = 2, BOOKING_FACTORY = 3, LITTLE_HOTELIER = 4, SITEMINDER = 5, IMPALA = 6,
// CLOCK_SYSTEM = 7, BEDS24 = 8, CHECKFRONT = 9, HOTEL_SPIDER = 10, INNGENIUS = 11,
var masters = {
  regionList: [{ label: "Americas", value: "1" }, { label: "ANZ Pacific", value: "2" }, { label: "Asia", value: "3" }, { label: "Europe", value: "4" }, { label: "RDX", value: "6" }, { label: "RDX EMEA", value: "7" }],
  integration: [{ label: 'One-Way', value: 1 }, { label: 'Two-Way', value: 2 }],
  currency: [],
  protelConn: [{ label: "onPrem", value: 1 }, { label: "pAir", value: 2 }],
  publisherList: [],
  pricingType: [{ label: "Occupancy-based Pricing", value: "occupancy price" }, { label: "Room-based Pricing", value: "fixed price" }],
  regionListRms: [{ label: "Southern Hemisphere", value: 1 }, { label: "Northern Hemisphere", value: 2 }],
  regionListStayntouch: [{ label: "North American instance", value: 1 }, { label: "European Instance", value: 2 }],
  semperList: [{ label: "Occupancy Based", value: "occupancy_based" }, { label: "Unit Based", value: "unit_based" }],
  verticalBookingList: [{ label: "Occupancy Based", value: "occupancy_based" }, { label: "Unit Based", value: "unit_based" }],
  sabeeappList: [{ label: "Occupancy Based", value: "occupancy_based" }, { label: "Unit Based", value: "unit_based" }],
  versionList: [{ label: "Other versions", value: 1 }, { label: "Version 21.5.XX or older", value: 2 }],

  guesLineRegions: [{ label: "Europe", value: "1" }, { label: "Asia", value: "2" }],
  propertyList: [],
  evPropertyList:[],
  hlsPropertyList:[],
  sabeePropertyList: [],
  multiPropertyList: [],
  agentList: [{ label: "No Travel Agent", value: "0" }],
  availability: [{ label: "Definite Available", value: 1 },{ label: "Tentative Available", value: 2 }],
  hotsoftAccountList:[],
};
const createOption = (label) => ({
  label,
  value: label,
});

class ConnectionSetting extends React.Component {
  state = {
    isSeekom: true,
    loader: false,
    pmsKey: '',
    pmsName: '',
    currentPms: '',
    integration: null,
    showPassword: true,
    featuresEnabled: [],
    currentData: {
      channel_password: null,
      id: null,
      password: null,
      pms: null,
      pms_url: null,
      primary: false, // true for active connection
      hotel_code: null,
      region: null,
      token: null,
      username: null,
      integration: null,
      use_v2_integration: false,
      extra: false,


    },
    allOldData: [],
    error: [],
    openConnectionModal: false,
    openConfirmationModal: false,
    selectedPmsKey: '',
    selectedIntegration: '',
    connectionModal: {},
    seekomLoader: false,
    typeList: [],
    createTypeModal: false,
    typeData: {},
    rateData: {
      pms_room: []
    },
    deleteRateId: null,
    deleteTypeId: null,
    error: [],
    tobe_deleted: [],
    deleted_rooms: undefined,
    deleted_rates: undefined,
    inputValue: '',
    value: [],
    restApiLoader:false,
    room_apartment_space_name: "",
    isFreetoBook: true,
    freetoBookLoader: false,
    deadRoomRateType: false,
    is_from_roomsetting: false,
    is_channel_manager:false,
    pms_channel_manager_text :'',
    reprocess_date:'',
    reprocess_date_type:1,
    loadingConnUpdate:false,
    iscloudBeds: true,
    cloudBedsLoader: false,
    minmaxOccupancyDetected: false,
    warning: false,
    warningMsg: [],
    warningMethod: null,
    isOccupancyChanged: false,
  };

  initPusher = () => {
    this.props.commonReducer.initPusher.bind('connection_update.message', (evt)=> {
      if (evt.success) {
        let requestData = {
          msg: `${this.state.pms_channel_manager_text} Connection updated Successfully`,
          is_success: true,
        }
        this.props.putNotify(requestData)
      } else {
        let requestData = {
          msg: `An error occured while updating ${this.state.pms_channel_manager_text} Connection`,
          is_success: false,
        }
        this.props.putNotify(requestData)
      }

      if(evt.success && evt.is_room_or_rate_deleted){
        console.log('dead rooms rates')
        this.deadRoomRateTypeModal()
      }
      
      if(evt.success && evt?.is_occupancy_changed){
        console.log('Change in min/max occupancy detected')
        this.minMaxOccupancyDetectModal()
      }
    })

    this.props.commonReducer.initPusher.bind('sabeeproperty.message', (evt)=> {
      if (evt.message === "success") {
        let requestParam = {
          token: this.props.hotelAuthReducer.hotelDetails.token,
          username: this.state.currentData.username,
        }
        this.props.getSabeePropertyList(requestParam, this.stopLoader)
      }
    })    
  }

  deadRoomRateTypeModal = () => {
    this.setState({
      deadRoomRateType: !this.state.deadRoomRateType
    })
  }

  minMaxOccupancyDetectModal = () => {
    this.setState({
      minmaxOccupancyDetected: !this.state.minmaxOccupancyDetected
    })
    this.props.getPricingSettings({ token: this.props.hotelAuthReducer.hotelDetails.token })
  }

  componentWillMount() {
    this.initPusher();

    let requestParam = { Authtoken: this.props.hotelAuthReducer.hotelDetails.token }
    this.props.getConnectionList(requestParam);
    let reqParams = { token: this.props.hotelAuthReducer.hotelDetails.token }
    this.props.getCurrency(reqParams);
    // this.props.getPublisherList(reqParams);
    this.props.getRoomTypeList(reqParams);
    this.props.getRoomRateList(reqParams);
    this.props.getHotelDetail(reqParams);

    let url_string = window.location.href;
    let url = new URL(url_string);
    let init = url.searchParams.get("init");
    let code = url.searchParams.get("code");
    if (init && code) {
      if (init == 'bookingsuite') {
        let codes = {
          token: this.props.hotelAuthReducer.hotelDetails.token,
          code: code,
        }
        this.props.callReservation(codes);
      }
      if (init == 'seekom') {
        let codes = {
          token: this.props.hotelAuthReducer.hotelDetails.token,
          code: code,
        }
        this.props.seekomCall(codes, this.updatePmsConnection);
        this.setState({
          seekomLoader: true,
          room_apartment_space_name: this.props.hotelAuthReducer.hotelDetails.hotel.room_apartment_space_name
        })
      }
      if (init == 'freetobook') {
        let codes = {
          token: this.props.hotelAuthReducer.hotelDetails.token,
          code: code,
        }
        this.props.freetobookCall(codes, this.updatePmsConnection);
        this.setState({
          freetoBookLoader: true,
        })
      }
      if (init == 'cloudbeds') {
        let codes = {
          token: this.props.hotelAuthReducer.hotelDetails.token,
          code: code,
        }
        this.props.oauth2Call(codes, this.updatePmsConnection);
        this.setState({
          cloudBedsLoader: true,
        })
      }
    }
  }

  componentWillReceiveProps(props) {
    if (props.priceSettingReducer && props.priceSettingReducer.priceSetting) {
      this.setState({
        featuresEnabled: props.priceSettingReducer.priceSetting.features
      })
    }    

    if (props.currencyList) {
      let currencyList = [];
      props.currencyList.map((val) => {
        currencyList.push({ label: val.name, value: val.id })
      })
      masters.currency = currencyList;
    }

    if (props.commonReducer.notify && props.commonReducer.notify.message) {
      this.setState({
        loader: false
      })
    }
    if(props.connectionReducer){
      this.setState({
        loadingConnUpdate:props.connectionReducer.loadingConnUpdate
      })
    }
    if (props.hotelAuthReducer.hotelDetails) {
      this.setState({       
        room_apartment_space_name: props.hotelAuthReducer.hotelDetails.hotel.room_apartment_space_name,
        is_channel_manager:props.hotelAuthReducer.hotelDetails.hotel.is_channel_manager,
        pms_channel_manager_text :props.hotelAuthReducer.hotelDetails.hotel.is_channel_manager ? 'Channel Manager':'PMS'
      })
      if (!this.state.integration) {
        this.setState({
          integration: props.hotelAuthReducer.hotelDetails.hotel.pms_sync,
          selectedIntegration: masters['integration'].filter(obj => { return obj.value === props.hotelAuthReducer.hotelDetails.hotel.pms_sync })
        })
      }
    }

    let url_string = window.location.href;
    let url = new URL(url_string);
    let init = url.searchParams.get("init");
    let code = url.searchParams.get("code");
    let extraArr=[];
    // if( props.connectionReducer && props.connectionReducer.connectionList ){
    //   props.connectionReducer.connectionList.filter(val=> val.provider === 25).map(val=>{
    //     if (val.extra){
    //       let extra=val.extra.split(",");
    //       extra.map((val)=>{
    //         extraArr.push({
    //           label:val,
    //           value:val
    //         })
    //       })
    //     }
    //   })    
    //   this.setState({
    //     value:extraArr
    //   })
    // }
    if (init !== 'seekom' && init !== 'freetobook' && init !== 'cloudbeds' && props.connectionReducer.connectionList && !props.connectionReducer.seekom && !props.connectionReducer.freetobook && !props.connectionReducer.oauth2_data && props.connectionReducer.connectionList != this.state.connections) {
      props.connectionReducer.connectionList.filter(row => row.primary === true).map((row) => {
        if (row.extra){
         
          let extra=row.extra.split(",");
          extra.map((val)=>{
            extraArr.push({
              label:val,
              value:val
            })
          })
        }
        if(!row.extra && row.provider == 15){
          row.extra = 1;
        }
        if(row.provider == 71 && row.configurations && row.configurations.price_type){
          row.extra = row.configurations.price_type;
        }
        this.setState({
          currentData:{
            ...this.state.currentData,
            extra:parseInt(row.extra)
          }
        })
        if(row.extra==="false"){
          row.extra=false
        }else if(row.extra==="true"){
          row.extra=true
        }
        this.setState({
          value:extraArr,
          connections: props.connectionReducer.connectionList,
          conectionId: row.id,
          currentData: row,
          pmsKey: row.provider,
          currentPms: connectionList.filter(val => val.key === row.provider)[0],
          selectedPmsKey: { value: connectionList.filter(val => val.key === row.provider)[0].value, label: connectionList.filter(val => val.key === row.provider)[0].label }
        }, () => {
          if (row.provider == 27) {
            this.getRmsProperty()
            this.getRmsAgents()
          }
          if (row.provider == 71) {
            // this.getSabeeProperty()
          }
          if (row.provider == 46) {
            this.getEvivoProperty()
          }
          if (row.provider == 49) {
            this.getHLSProperty()
          }
          if (row.provider == 70) {
            this.getMultiPropertyList()
          }
          if (row.provider == 59 && (row.hotel_code != null || row.hotel_code != "")) {
            this.getHotsoftAccountList()
          }

        })
        return null;
      })

    }

    if (props.connectionReducer.connectionList && init == 'seekom' && code && !this.state.conectionId) {
      props.connectionReducer.connectionList.filter(row => row.provider === 24).map((row) => {
        this.setState({
          connections: props.connectionReducer.connectionList,
          conectionId: row.id,
          currentData: row,
          pmsKey: 24,
          currentPms: connectionList.filter(val => val.key === 24)[0],
          selectedPmsKey: { value: connectionList.filter(val => val.key === 24)[0].value, label: connectionList.filter(val => val.key === 24)[0].label }
        })
        return null;
      })
    }

    if (props.connectionReducer.connectionList && init == 'freetobook' && code && !this.state.conectionId) {
      props.connectionReducer.connectionList.filter(row => row.provider === 52).map((row) => {
        this.setState({
          connections: props.connectionReducer.connectionList,
          conectionId: row.id,
          currentData: row,
          pmsKey: 52,
          currentPms: connectionList.filter(val => val.key === 52)[0],
          selectedPmsKey: { value: connectionList.filter(val => val.key === 52)[0].value, label: connectionList.filter(val => val.key === 52)[0].label }
        })
        return null;
      })
    }

    if (props.connectionReducer.connectionList && init == 'cloudbeds' && code && !this.state.conectionId) {
      props.connectionReducer.connectionList.filter(row => row.provider === 70).map((row) => {
        this.setState({
          connections: props.connectionReducer.connectionList,
          conectionId: row.id,
          currentData: row,
          pmsKey: 70,
          currentPms: connectionList.filter(val => val.key === 70)[0],
          selectedPmsKey: { value: connectionList.filter(val => val.key === 70)[0].value, label: connectionList.filter(val => val.key === 70)[0].label }
        })
        return null;
      })
    }


    if (props.connectionReducer.seekom && this.state.isSeekom) {
      this.setState({
        currentData: {
          ...this.state.currentData,
          token: props.connectionReducer.seekom.access_token,
          extra: props.connectionReducer.seekom.refresh_token,
        },
        isSeekom: false,
        pmsKey: 24,
        currentPms: connectionList.filter(val => val.key === 24)[0],
        selectedPmsKey: { value: 'seekom', label: 'Seekom' },
        seekomLoader: false
      })
    }

    if (props.connectionReducer.freetobook && this.state.isFreetoBook) {
      this.setState({
        currentData: {
          ...this.state.currentData,
          token: props.connectionReducer.freetobook.access_token,
          extra: props.connectionReducer.freetobook.refresh_token,
          hotel_code: props.connectionReducer.freetobook.property_id,
        },
        isFreetoBook: false,
        pmsKey: 52,
        currentPms: connectionList.filter(val => val.key === 52)[0],
        selectedPmsKey: { value: 'Freetobook', label: 'Freetobook' },
        freetoBookLoader: false
      })
    }

    if (props.connectionReducer.oauth2_data && this.state.iscloudBeds) {
      this.setState({
        currentData: {
          ...this.state.currentData,
          token: props.connectionReducer.oauth2_data.access_token,
          extra: props.connectionReducer.oauth2_data.refresh_token
        },
        iscloudBeds: false,
        pmsKey: 70,
        currentPms: connectionList.filter(val => val.key === 70)[0],
        selectedPmsKey: { value: 'Cloudbeds', label: 'Cloudbeds' },
        cloudBedsLoader: false
      })
    }

    if (props.connectionReducer.publisherList) {
      masters.publisherList = props.connectionReducer.publisherList.publisher_list
    }

    if (props.connectionReducer.propertyList) {
      let propertyList = [];
      props.connectionReducer.propertyList.map(row => {
        propertyList.push({ label: row.prop_name, value: row.prop_id })
      })
      masters.propertyList = propertyList
    }

    if (props.connectionReducer.evPropertyList) {
      masters.evPropertyList = props.connectionReducer.evPropertyList;
    }
    
    if (props.connectionReducer.hlsPropertyList) {
      let hlsPropertyList = [];
      props.connectionReducer.hlsPropertyList.map(row => {
        hlsPropertyList.push({ label: row.name, value: row.id })
      })

      masters.hlsPropertyList = hlsPropertyList;
    }

    if (props.connectionReducer.sabeePropertyList) {
      let sabeePropertyList = [];
      props.connectionReducer.sabeePropertyList.map(row => {
        sabeePropertyList.push({ label: row.name, value: row.id })
      })
      masters.sabeePropertyList = sabeePropertyList;
    }

    if (props.connectionReducer.multiPropertyList) {
      let multiPropertyList = [];
      props.connectionReducer.multiPropertyList.map(row => {
        multiPropertyList.push({ label: row.property_name +" ("+ row.property_code +")", value: row.property_code })
      })
      masters.multiPropertyList = multiPropertyList;
    }

    

    if (props.connectionReducer.agentList) {
      let agentList = [];
      agentList.push({ label: "No Travel Agent", value: "0" })
      props.connectionReducer.agentList.map(row => {
        agentList.push({ label: row.agent_name, value: row.agent_id })
      })
      masters.agentList = agentList
    }

    if(props.connectionReducer && props.connectionReducer.hotsoftaccountList){
      let hotsoftAccountList = [];
        props.connectionReducer.hotsoftaccountList.map(row => {
          hotsoftAccountList.push({ label: row.name + " (" + row.account_number + ") ", value: row.name })
        })
        masters.hotsoftAccountList = hotsoftAccountList
    }

    if (props.RoomTypeRateReducer.typeList) {
      this.setState({
        typeList: props.RoomTypeRateReducer.typeList
      })
    }

    if (props.RoomTypeRateReducer.rateList) {
      let rateList = props.RoomTypeRateReducer.rateList;

      rateList.map(val => {
        val.pms_room = [];
        if (val.room_rate) {
          val.room_rate.map(row => {
            val.pms_room.push(row.pms_room_id)
          })
        }
      })

      this.setState({
        rateList
      })
    }
    if (this.props.connectionReducer.deleted_rooms && this.props.connectionReducer.deleted_rooms.length > 0 && this.state.deleted_rooms === undefined && this.state.showConfirmRoomDelete === undefined) {
      this.setState({
        deleted_rooms: this.props.connectionReducer.deleted_rooms,
        showConfirmRoomDelete: true
      })
    }
    if (!this.props.connectionReducer.deleted_rooms && !this.props.connectionReducer.loading) {
      this.setState({
        showConfirmRoomDelete: undefined,
        deleted_rooms: undefined
      })
    }
    if ((!this.props.connectionReducer.deleted_rooms || this.props.connectionReducer.deleted_rooms.length === 0) && this.props.connectionReducer.deleted_rates && this.props.connectionReducer.deleted_rates.length > 0 && this.state.deleted_rates === undefined && this.state.showNotificationRateDelete === undefined) {
      this.setState({
        deleted_rates: this.props.connectionReducer.deleted_rates,
        showNotificationRateDelete: true
      })
    }
    if (props.connectionReducer){
      this.setState({
        restApiLoader: props.connectionReducer.restApiLoader
      })
    }
  }

  toggleWarningModal = (method) => {
    let warningMsg = [];
    let warning = false;
    let hideConfirm = false;
    const { t } = this.props;
    
    if (method == "createUpdateType") {

      let error = this.validateType();
      if(error.length === 0 && this.state.typeData.id) {
        let oldValue = this.props.RoomTypeRateReducer.typeList.find(row=> row.id == this.state.typeData.id);

        if(this.state.typeData.max_occupancy != oldValue.max_occupancy || this.state.typeData.min_occupancy != oldValue.min_occupancy){
          warning = true;
          warningMsg.push(t("We noticed that the occupancy pricing setup for the below room types changed. Your daily occupancy settings (Bulk Edit) for these room types got set back to default."));        
          this.setState({
            isOccupancyChanged: true
          })
        }else{
          this.setState({
            isOccupancyChanged: false
          })
        }
      }
      
      if (!warning) {
        this.createUpdateType()
      }
    } 

    
    
    this.setState({
      warning: warning,
      warningMethod: method,
      warningMsg: warningMsg,
      hideConfirm: hideConfirm,
    });
  };

  warningConfirm = () => {
    if (this.state.warningMethod == "createUpdateType") {
      this.createUpdateType();
    }
    this.setState({
      warning: false,
    });
  };

  closeWarningModal = () => {
    this.setState({
      warning: false,
    });
  }

  getRmsProperty = () => {
    this.setState({
      loaderRms: true
    })

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      client_id: this.state.currentData.username,
      client_password: this.state.currentData.password,
      region: this.state.currentData.region,
    }
    this.props.getRmsProperty(requestParam, this.stopLoader)
  }

  
  getEvivoProperty = () => {
    this.setState({
      loaderRms: true
    })

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      username: this.state.currentData.username,
      password: this.state.currentData.password,
    }
    this.props.getEvivProperty(requestParam, this.stopLoader)
  }

  getHLSProperty = () => {
    this.setState({
      loaderRms: true
    })

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      username: this.state.currentData.username,
      password: this.state.currentData.password,
    }
    this.props.getHLSProperty(requestParam, this.stopLoader)
  }

  getMultiPropertyList = () => {
    this.setState({
      loaderProperty: true
    })

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token
    }
    this.props.getMultiPropertyList(requestParam, this.stopLoader)
  }

  getSabeeProperty = () => {
    this.setState({
      loaderRms: true
    })

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      username: this.state.currentData.username,
    }
    this.props.getSabeeProperty(requestParam, this.stopLoader)
  }
  
  getHotsoftAccountList = () => {
    this.setState({
      loaderRms: true
    })

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      hotel_code: this.state.currentData.hotel_code
    }
    this.props.getHotsoftAccountList(requestParam, this.stopLoader)
  }
  

  stopLoader = () => {
    this.setState({
      // loader:false,
      loaderRms: false,
      loaderRmsA: false,
      loaderProperty: false,
      cloudBedsLoader: false
    })
  }

  getRmsAgents = () => {
    this.setState({
      loaderRmsA: true
    })

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      client_id: this.state.currentData.username,
      client_password: this.state.currentData.password,
      property_id: this.state.currentData.hotel_code,
      region: this.state.currentData.region,
    }
    this.props.getRmsAgents(requestParam, this.stopLoader)
  }

  CloseNotificatinRateDelete = () => {
    this.setState({
      showNotificationRateDelete: false,
      deleted_rates: []
    })
    let requestParam = { token: this.props.hotelAuthReducer.hotelDetails.token }
    this.props.removeDeadRates(requestParam);
  }

  getReservation = () => {
    let reqParams = { token: this.props.hotelAuthReducer.hotelDetails.token };
    this.props.getReservation(reqParams);
  }

  handlePmsChange = () => event => {
    let index = event.key;

    let oldData = {
      channel_password: null,
      id: null,
      password: null,
      pms: null,
      pms_url: null,
      primary: false, // true for active connection
      hotel_code: null,
      region: null,
      token: null,
      username: null,
    };
    let extraArr = [];
    this.props.connectionReducer.connectionList.filter(row => row.provider === index).map((row) => {      
      if (row.extra){
        let extra=row.extra.split(",");
        extra.map((val)=>{
          extraArr.push({
            label:val,
            value:val
          })
        })
      }
      oldData = row;
      return null;
    })
    this.setState({
      value: extraArr,
      currentPms: connectionList.filter(val => val.key === index)[0],
      currentData: oldData,
      pmsKey: index,
      error: [],
      selectedPmsKey: event
    },
      () => { this.handleFields() }
    )
  }

  handleTypeData = (name) => event => {

    this.setState({
      typeData: {
        ...this.state.typeData,
        [name]: event.target.value
      }
    })
  }

  handleRateData = (name) => event => {
    this.setState({
      rateData: {
        ...this.state.rateData,
        [name]: event.target.value
      }
    })
  }

  handleRateCheckbox = (name) => event => {
    let value = event.target.checked ? true : false;
    this.setState({
      rateData: {
        ...this.state.rateData,
        pms_room: [],
        [name]: value
      }
    })
  }

  createUpdateTypeModal = (value) => {
    this.setState({
      createUpdateType: !this.state.createUpdateType,
      typeData: value ? value : {},
      error: []
    })
  }

  createUpdateRateModal = (value) => {
    let pms_room = [];

    if ([34, 69].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider)) {
      if(value){
        value.pms_room = [];
      }
      this.state.typeList.map((row) => {
        pms_room.push(row.id);
        if(value){
          value.pms_room.push(row.id)
        }
      })
    }

    let dafaultAry = {
      rate_id: null,
      name: null,
      pms_room: [],
      description: null,
      is_for_all_room_type: false
    }

    this.setState({
      createUpdateRate: !this.state.createUpdateRate,
      rateData: value ? value : dafaultAry,
      error: []
    })
  }

  deleteTypeModal = (value) => {
    this.setState({
      deleteType: !this.state.deleteType,
      deleteTypeId: value ? value.id : null
    },()=>{
      this.setState({
        createUpdateType: false,
      });
    })
  }

  deleteRateModal = (value) => {
    this.setState({
      deleteRate: !this.state.deleteRate,
      deleteRateId: value ? value : null
    },()=>{
      this.setState({
        createUpdateRate: false,
      });
    })
  }

  CloseConfirmRoomDelete = () => {
    this.setState({
      showConfirmRoomDelete: false,
      deleted_rooms: []
    })
  }

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = this.state.deleted_rooms.map((n) => n);
      this.setState({ tobe_deleted: newSelecteds })
    } else {
      this.setState({ tobe_deleted: [] })
    }
  };

  handleSelectCheck = (event, room) => {
    if (event.target.checked) {
      this.setState({ tobe_deleted: [...this.state.tobe_deleted, room] })
    } else {
      var array = [...this.state.tobe_deleted];
      var index = array.indexOf(room);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ tobe_deleted: array });
      }
    }
  }
  handleConfirmRooms = () => {
    let requestParam = {
      client_rooms_deleted: this.state.tobe_deleted,
      Authtoken: this.props.hotelAuthReducer.hotelDetails.token,
      deleted_rooms: this.state.deleted_rooms
    }
    this.props.removeDeadRooms(requestParam);
  }

  validateType = () => {

    let error = [];

    let typeData = this.state.typeData;
    
    
    if (typeData.max_occupancy === undefined || typeData.max_occupancy === "") {
      error.push("max_occupancy")
    }else if(typeData.max_occupancy <= 0){
      error.push("max_occupancy_less_zero")
    }

    if (typeData.min_occupancy === undefined || typeData.min_occupancy === "") {
      error.push("min_occupancy")
    }else if(typeData.min_occupancy <= 0){
      error.push("min_occupancy_less_zero")
    }

    if (typeData.max_occupancy && typeData.min_occupancy && typeData.min_occupancy > 0 && typeData.max_occupancy > 0) {
      if (typeData.max_occupancy && typeData.min_occupancy && parseFloat(typeData.min_occupancy) > parseFloat(typeData.max_occupancy)) {
        error.push("max_occupancy_more")
      }
    }

    if (!typeData.name) {
      error.push("name")
    }

    if (typeData.room_id === undefined || typeData.room_id === "") {
      error.push("room_id")
    }

    this.setState({
      error
    })

    return error
  }

  createUpdateType = () => {
    let error = this.validateType();

    if (error.length == 0) {
      if (this.state.typeData.id) {
        let request = {
          token: this.props.hotelAuthReducer.hotelDetails.token,
          ...this.state.typeData,
          isOccupancyChanged: this.state.isOccupancyChanged
        }
        this.props.updateRoomType(request)
        this.createUpdateTypeModal()
      } else {
        let request = {
          token: this.props.hotelAuthReducer.hotelDetails.token,
          ...this.state.typeData
        }
        this.props.createRoomType(request)
        this.createUpdateTypeModal()
      }
    }
  }

  validateRate = () => {
    let error = [];

    let rateData = this.state.rateData;


    if (!rateData.name) {
      error.push("name")
    }


    if ((!rateData.pms_room || rateData.pms_room.length == 0) && ![15, 34, 69].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider)) {
      error.push("pms_room")
    }

    if (!rateData.rate_id) {
      error.push("rate_id")
    }

    this.setState({
      error
    })

    return error;

  }

  createUpdateRate = () => {    
    let error = this.validateRate();

    if (error.length == 0) {
      if (this.state.rateData.id) {

        let request = {
          token: this.props.hotelAuthReducer.hotelDetails.token,
          name: this.state.rateData.name,
          description: this.state.rateData.description,
          other_rate: this.state.rateData.other_rate,
          checkin: null,
          checkout: null,
          room_rate: [],
          rate_id: this.state.rateData.rate_id,
          is_for_all_room_type: false,
          pms_id: this.state.conectionId,
          id:this.state.rateData.id
        }

          this.state.rateData.pms_room.map(val => {
            let filter = [];
            if (filter = this.state.rateData.room_rate) {
              filter = this.state.rateData.room_rate.filter(row => row.pms_room_id == val);
            }
            if (filter.length > 0) {
              request.room_rate.push({
                id: filter[0].id,
                pms_room: filter[0].pms_room,
                rate_id: this.state.rateData.rate_id
              })
            } else {
              if (this.state.typeList) {
                filter = this.state.typeList.filter(row => row.id == val);
              }
              request.room_rate.push({
                id: "",
                pms_room: filter[0].room_id,
                rate_id: this.state.rateData.rate_id
              })
            }
          })
          if (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 15) {
            this.props.updateRoomRateProtel(request)
          }else{
            this.props.updateRoomRate(request)
          }

        this.createUpdateRateModal();
      } else {

        let request = {
          token: this.props.hotelAuthReducer.hotelDetails.token,
          name: this.state.rateData.name,
          description: this.state.rateData.description,
          rate_id: this.state.rateData.rate_id,
          room_types: this.state.rateData.pms_room,
        }
        
        if ([34, 69].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider)) {
          request.room_types = [];
          this.state.typeList.map((row) => {
            request.room_types.push(row.id);
          })
        } 
        if (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 15) {
          request.room_types = [];
          // this.state.typeList.map((row) => {
          //   request.room_types.push(row.id);
          // })
        } 

        this.props.createRoomRate(request);
        this.createUpdateRateModal();
      }
    }
  }

  deleteCurrentType = () => {
    let request = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      id: this.state.deleteTypeId
    }
    this.props.deleteRoomType(request);
    this.deleteTypeModal();
  }

  deleteCurrentRate = () => {
    let ids = [];

    this.state.deleteRateId.room_rate.map(val => {
      ids.push(val.id)
    })

    if(ids.length == 0 && this.state.deleteRateId && this.state.deleteRateId.room_rate){
      ids = [this.state.deleteRateId.id]
    }

    let request = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      id: ids
    }

    this.props.deleteRoomRate(request);
    this.deleteRateModal();
  }

  renderTypes = () => {
    let data = [];

    if (this.state.typeList && this.state.typeList.length > 0) {
      this.state.typeList.map((row) => {
        data.push([
          row.room_id,
          row.name,
          row.min_occupancy,
          row.max_occupancy,
          row.extra ? row.extra : null,
          row,          
          row
        ])
      })
    }

    return data;
  }

  renderRates = () => {
    let data = [];

    if (this.state.rateList && this.state.rateList.length > 0) {
      this.state.rateList.map((row) => {
        let jsx = [];

        this.state.typeList.map(opt => {
          if (row.pms_room.includes(opt.id)) {
            jsx.push(opt.name);
          }
        })
        let otherRate = row.other_rate ? row.other_rate : "-"
        let other_rate = this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 22 ? otherRate : null

        data.push([
          row.rate_id ? row.rate_id : "-",
          row.name ? row.name : "-",
          jsx.join(', '),
          row.description ? row.description : "-",
          other_rate,
          row,
          row
        ])

      })
    }
    return data;
  }

  getMuiTheme = () => ConnectiongetMuiTheme;

  getMuiThemeType = () => ConnectiongetMuiThemeType;

  getMuiThemeRate = () => ConnectiongetMuiThemeRate;


  handleChange = (value) => {
    this.setState({ value });
  };
  handleInputChange = (inputValue) => {
    this.setState({ inputValue });
  };
  handleKeyDown = (event) => {
    if (!this.state.inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        this.setState({
          inputValue: '',
          value: [...this.state.value, createOption(this.state.inputValue)],
        });
        event.preventDefault();
    }
  };

  handleFields = () => {
    let jsx = [];
    const { t } = this.props;
    const { classes } = this.props;
    const { inputValue, value } = this.state;
    if (this.state.currentPms) {
      this.state.currentPms.fields.map((field) => {

        if (field.type && field.options && field.type === 'select') {
          jsx.push(
            <FormControl margin="normal" required className={classes.formControl}>
              <InputLabel htmlFor="name" shrink={true}>{t(field.label)}</InputLabel>
              <MaterialSelect
                error={this.state.error.includes(field.key)}
                id="standard-name"
                value={this.state.currentData[field.key] ? this.state.currentData[field.key] : ''}
                onChange={this.handleValueChage(field.key)}
                className={classes.textFieldSelects}
                margin="normal"
                displayEmpty={true}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
              >
                <MenuItem disabled key={-1} value={''}>
                  {t(`Please Select ${field.label}`)}
                </MenuItem>
                {(field.type && field.options && field.type === 'select') &&
                  masters[field.options].map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                }
              </MaterialSelect>
            </FormControl>
          )
        } else if (field.type && field.type === 'evMultiSelect') {
          jsx.push(
            <FormControl margin="normal" required className={classes.formControl}>
              <InputLabel htmlFor="name" shrink={true}>{t(field.label)}</InputLabel>
              <MaterialSelect
                multiple
                error={this.state.error.includes(field.key)}
                id="standard-name"
                value={this.state.currentData[field.key] ? this.state.currentData[field.key].split(",") : []}
                onChange={this.handlemultiSelect(field.key)}
                className={classes.textFieldSelects}
                margin="normal"
                displayEmpty={true}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                renderValue={selected => {
                  let jsx = [];
                  if (selected.length == 0) {
                      return <>{t(`Select ${field.label}`)}</>;
                  }
                  if(this.state.currentData.hotel_code){
                    this.state.currentData.hotel_code.split(",").map(opt => {
                      if (this.state.currentData[field.key].split(",").includes(opt)) {
                        jsx.push(opt);
                      }
                    })
                  }
                  return jsx.join(', ');                  
                }
                }
              >   <MenuItem disabled value="">
                    {t(`Select ${field.label}`)}
                  </MenuItem>
                  {masters.evPropertyList && masters.evPropertyList.map(option => {
                    let val=this.state.currentData[field.key]!==null ? this.state.currentData[field.key].split(",").includes(option):false
                    return(
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={val} />
                      {option}
                    </MenuItem>)
                  })
                }
              </MaterialSelect>
            </FormControl>
          )
        } else if (field.type && field.type === 'multiSelectProperty') {
          jsx.push(
            <FormControl margin="normal" required className={classes.formControl}>
              <InputLabel htmlFor="name" shrink={true}>{t(field.label)}</InputLabel>
              <MaterialSelect
                multiple
                error={this.state.error.includes(field.key)}
                id="standard-name"
                value={this.state.currentData[field.key] ? this.state.currentData[field.key].split(",") : []}
                onChange={this.handlemultiSelect(field.key)}
                className={classes.textFieldSelects}
                margin="normal"
                displayEmpty={true}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                renderValue={selected => {
                  let jsx = [];
                  if (selected.length == 0) {
                      return <>{t(`Select ${field.label}`)}</>;
                  }
                  if(this.state.currentData.hotel_code){
                    this.state.currentData.hotel_code.split(",").map(opt => {
                      if (this.state.currentData[field.key].split(",").includes(opt)) {
                        jsx.push(opt);
                      }
                    })
                  }
                  return jsx.join(', ');
                }
                }
              >   <MenuItem disabled value="">
                    {t(`Select ${field.label}`)}
                  </MenuItem>
                  {masters.multiPropertyList && masters.multiPropertyList.map(option => {
                    let val=this.state.currentData[field.key]!==null ? this.state.currentData[field.key].split(",").includes(option.value):false
                    return(
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox checked={val} />
                      {option.label}
                    </MenuItem>)
                  })
                }
              </MaterialSelect>
            </FormControl>
          )
        }else if (field.type && field.type === 'multiSelect') {
          jsx.push(
            <FormControl margin="normal" required className={classes.formControl}>
              <InputLabel htmlFor="name" shrink={true}>{t(field.label)}</InputLabel>
              <MaterialSelect
                multiple
                error={this.state.error.includes(field.key)}
                id="standard-name"
                value={this.state.currentData[field.key] ? this.state.currentData[field.key].split(",") : ''}
                onChange={this.handlemultiSelect(field.key)}
                className={classes.textFieldSelects}
                margin="normal"
                displayEmpty={true}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                renderValue={selected => {
                  let jsx = [];
                  if (selected.length == 0) {
                      return <>{t(`Select ${field.label}`)}</>;
                  }
                  if(this.state.currentData.token){
                    this.state.currentData.token.split(",").map(opt => {
                      if (this.state.currentData[field.key].split(",").includes(opt)) {
                        jsx.push(opt);
                      }
                    })
                  }
                  return jsx.join(', ');                  
                }
                }
              >
                <MenuItem disabled value="">
                    {t(`Select ${field.label}`)}
                </MenuItem>
                {this.state.currentData.token &&
                  this.state.currentData.token.split(",").map(option => (
                    <MenuItem key={option} value={option}>
                      <Checkbox color="primary" checked={this.state.currentData[field.key].split(",").includes(option)} />
                      {option}
                    </MenuItem>
                  ))
                }
              </MaterialSelect>
            </FormControl>
          )
        }else if (field.type && field.type === 'multiSelectAdd') {
          jsx.push(
            <FormControl margin="normal"  className={[classes.formControl,classes.formControl2]} >
               <InputLabel  shrink={true} className={classes.inputLable} >{t(field.label)}</InputLabel>
              <CreatableSelect
                components={components}
                inputValue={this.state.inputValue}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={this.handleChange}
                onInputChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown}
                placeholder={t('Add Properties & Press enter...')}
                value={this.state.value}
                id='mname'
              />
            </FormControl>
          )
        }else if (field.btn) {
          let url = ""
            
            if(field.pms == "seekom") {
              url = process.env.REACT_APP_CONNECTION_SEEKOM + this.props.hotelAuthReducer.hotelDetails.hotel.id + "?init=seekom"
            } else if(field.pms == "freetobook") {
              url = process.env.REACT_APP_CONNECTION_FREETOBOOK + this.props.hotelAuthReducer.hotelDetails.hotel.id + "?init=freetobook"
            } else if(field.pms == "cloudbeds") {
              url = process.env.REACT_APP_CONNECTION_CLOUDBEDS + this.props.hotelAuthReducer.hotelDetails.hotel.id + "?init=cloudbeds"
              // url = "https://hotels.cloudbeds.com/api/v1.1/oauth?client_id=roompricegenie_dev_Ykgw6UyZehIzWR7P09FdvXur&redirect_uri=http://localhost:3000&response_type=code&scope=read:rate read:reservation read:room read:user read:hotel write:rate&state=http://localhost:3000/client/Connections/" + this.props.hotelAuthReducer.hotelDetails.hotel.id + "?init=cloudbeds"
            }

            jsx.push(
              <Button className={classes.seeKomBtn} href={url} variant="outlined" color="primary" >
                {t(field.label)}
              </Button>
            );

        } else if (field.type && field.type === 'btn') {
          
          if (field.key == "evPropertyList") {
            jsx.push(
              <Button onClick={this.getEvivoProperty} disabled={this.state.loaderRms} className={classes.seeKomBtn} variant="outlined" color="primary" >
                {t(field.label)}{this.state.loaderRms ? <CircularProgress color="secondary" size={24} className={classes.buttonProgress} /> : ""}
              </Button>
            )
          }else if (field.key == "propertyList") {
            jsx.push(
              <Button onClick={this.getRmsProperty} disabled={this.state.loaderRms} className={classes.seeKomBtn} variant="outlined" color="primary" >
                {t(field.label)}{this.state.loaderRms ? <CircularProgress color="primary" size={24} className={classes.buttonProgress} /> : ""}
              </Button>
            )
          }else if (field.key == "hlsPropertyList") {
            jsx.push(
              <Button onClick={this.getHLSProperty} disabled={this.state.loaderRms} className={classes.seeKomBtn} variant="outlined" color="primary" >
                {t(field.label)}{this.state.loaderRms ? <CircularProgress color="secondary" size={24} className={classes.buttonProgress} /> : ""}
              </Button>
            )
          } else if (field.key == "sabeePropertyList") {
            jsx.push(
              <Button onClick={this.getSabeeProperty} disabled={this.state.loaderRms} className={classes.seeKomBtn} variant="outlined" color="primary" >
                {t(field.label)}{this.state.loaderRms ? <CircularProgress color="secondary" size={24} className={classes.buttonProgress} /> : ""}
              </Button>
            )
          } else if (field.key == "multiPropertyList") {
            jsx.push(
              <Button onClick={this.getMultiPropertyList} disabled={this.state.loaderProperty} className={classes.seeKomBtn} variant="outlined" color="primary" >
                {t(field.label)}{this.state.loaderProperty ? <CircularProgress color="primary" size={24} className={classes.buttonProgress} /> : ""}
              </Button>
            )
          } else if (field.key == "hotsoftAccountList") {
            jsx.push(
              <Button onClick={this.getHotsoftAccountList} disabled={(this.state.currentData["hotel_code"] == null || this.state.loaderRms) ? true : false} className={classes.seeKomBtn} variant="outlined" color="primary" >
                {t(field.label)}{this.state.loaderRms ? <CircularProgress color="secondary" size={24} className={classes.buttonProgress} /> : ""}
              </Button>
            )
          } else {
            jsx.push(
              <Button onClick={this.getRmsAgents} disabled={this.state.loaderRmsA} className={classes.seeKomBtn} variant="outlined" color="primary" >
                {t(field.label)}{this.state.loaderRmsA ? <CircularProgress color="primary" size={24} className={classes.buttonProgress} /> : ""}
              </Button>
            )
          }
        }else if(field.type === 'checkbox'){
            jsx.push(
                <FormControlLabel
                  className={classes.checkbox}
                    control={
                      <Checkbox
                          checked={this.state.currentData.extra}
                          onChange={this.handleValueChage(field.key)}
                          color="primary"
                      />
                    }
                    label={<div className={classes.checkboxLable} >{field.label}</div>}
                  />
            )
        }else if(this.state.currentPms.key != 1 || !field.hideNodata || this.state.currentData["username"] || this.state.currentData["password"]){
          jsx.push(
            <FormControl margin="normal" required className={classes.formControl}>
              <TextField
                InputLabelProps={{ shrink: true }}
                type={(field.password && this.state.showPassword) ? 'password' : 'text'}
                error={this.state.error.includes(field.key)}
                id="standard-name"
                label={t(field.label)}
                placeholder={t(`Please enter `) + field.label }
                value={this.state.currentData[field.key] ? this.state.currentData[field.key] : ''}
                onChange={this.handleValueChage(field.key)}
                className={classes.textField}
                margin="normal"
                disabled={(!(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) && [25, 26, 46, 70].includes(this.state.pmsKey)) ? true : false}
                InputProps={field.password ? {
                  endAdornment: (
                    <InputAdornment className={classes.showPass} position="end" onClick={this.toggleShowPassword}>
                      {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    </InputAdornment>
                  ),
                } : ""}
              />
            </FormControl> 
          )
        } 
    
        
      })
      if([10, 24, 52].includes(this.state.currentData.provider)){
        jsx.push(
            <FormControl 
              className={classes.formControl} margin="normal" >
              <InputLabel shrink={true} htmlFor="lng">{t('ID for Iframe')}</InputLabel>
              <Input
                readOnly
                className={classes.textField}
                id="copyText"
                value={this.props.hotelAuthReducer.hotelDetails.enc_hotel_id}
                endAdornment={<IconButton onClick={this.copyTo}><Icon>content_copy</Icon></IconButton>}
              />              
              {this.state.isCopy ? <div className={classes.copyText} >Copied!</div>:""}
            </FormControl>
        )
      }      

      return jsx;
    }
  }

  copyTo = () =>{
    var copyText = document.getElementById("copyText");
    copyText.select();
    copyText.setSelectionRange(0, 99999)
    document.execCommand("copy");

    this.setState({
      isCopy: true
    })

    setTimeout(()=>{
      this.setState({
        isCopy: false
      })
    },3000)
  }
  

  toggleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  validateForm = (data) => {
    let error = [];
    if (data && this.state.currentPms.fields) {
      this.state.currentPms.fields.map((field) => {
        if (!data[field.key] && !field.btn && !field.noValidate && field.key != 'extra') {
          error.push(field.key)
        }
      })

      if (!this.state.integration || !this.state.selectedIntegration) {
        error.push('integration')
      }

      if (error) {
        this.setState({
          error: error
        })
        return error
      } else {
        return false
      }
    } else {
      if (this.state.pmsKey == "" || !this.state.pmsKey) {
        error.push('pms')
        if (error) {
          this.setState({
            error: error
          })
          return error
        }
      }
      return true;
    }
  }

  updatePmsConnectionClientView = () => {
    console.log("clientview")
    let validateError = this.validateForm(this.state.currentData);

    if (validateError.length > 0) {
      return;
    }

    let currentData =  this.state.currentData;
    let extra = this.state.currentData.extra;
    let hotel_code = this.state.currentData.hotel_code;

    if(this.state.pmsKey == 25 || this.state.pmsKey == 26){
      let multipleProperties=[];
      if(this.state.value){
        this.state.value.map(val=>{
          multipleProperties.push(val.value)
        })
        extra = multipleProperties.toString();
      }
    }

    if (this.state.pmsKey == 46 || this.state.pmsKey == 70) {
      let code = [];
      let list = [];

      if (this.state.pmsKey == 46) {
        list = masters.evPropertyList;
        list.map(val => {
          if (hotel_code.includes(val)) {
            code.push(val)
          }
        })
        hotel_code = code.toString();
      }

      if(this.state.pmsKey == 70) {
        list = masters.multiPropertyList;
        if(list.length > 0){
          list.map(val => {
            if (hotel_code.includes(val.value)) {
              code.push(val.value)
            }
          })
          hotel_code = code.toString();
        }else{
          hotel_code = hotel_code;
        }
      }
    }

    this.setState({ loader: true })

    let requestParam = {
      id:this.state.currentData.id,
      provider: this.state.pmsKey,
      hotel_code: hotel_code,
      integration: this.state.integration,
      Authtoken: this.props.hotelAuthReducer.hotelDetails.token,
      extra:extra,
    }

    if (requestParam.id) {
      this.props.connectionUpdate(requestParam, this.stopLoader)
    }
  }
  
  updatePmsConnection = () => {
    let validateError = this.validateForm(this.state.currentData);

    if (validateError.length > 0) {
      return;
    }

    let currentData =  this.state.currentData;
    let extra = this.state.currentData.extra ? this.state.currentData.extra : "";

    if(this.state.pmsKey == 25 || this.state.pmsKey == 26){
      let multipleProperties=[];
      if(this.state.value){
        this.state.value.map(val=>{
          multipleProperties.push(val.value)
        })
      extra = multipleProperties.toString();
      }
    }

    // if ([27, 46, 49].includes(this.state.pmsKey)) {    
      if (this.state.pmsKey == 46 || this.state.pmsKey == 70) {
      let hotel_code = this.state.currentData.hotel_code;
      let code = [];
      let list = [];
      
      // if (this.state.pmsKey == 27) {
      //   list = masters.propertyList;
      //   list.map(val => {
      //     if (hotel_code.includes(val.value)) {
      //       code.push(val.value)
      //     }
      //   })
      // }
      if (this.state.pmsKey == 46) {
        list = masters.evPropertyList;
        list.map(val => {
          if (hotel_code.includes(val)) {
            code.push(val)
          }
        })
        currentData.hotel_code = code.toString();
      }
      // if (this.state.pmsKey == 49) {
      //   list = masters.hlsPropertyList;
      //   list.map(val => {
      //     if (hotel_code.includes(val.value)) {
      //       code.push(val.value)
      //     }
      //   })
      // }

      if(this.state.pmsKey == 70) {
        list = masters.multiPropertyList;
        if(list.length > 0){
          list.map(val => {
            if (hotel_code.includes(val.value)) {
              code.push(val.value)
            }
          })
          currentData.hotel_code = code.toString();
        }else{
          currentData.hotel_code = hotel_code;
        }
      }
    }

    this.setState({ loader: true })

    let requestParam = {
      ...currentData,
      provider: this.state.pmsKey,
      Authtoken: this.props.hotelAuthReducer.hotelDetails.token,
      integration: this.state.integration,
      extra: this.state.currentData.extra.toString(),
      room_apartment_space_name: this.state.room_apartment_space_name
    }

    if(this.state.pmsKey == 71){      
      requestParam.configurations = {
        price_type: requestParam.extra,
      }
      requestParam.extra = ""
    }
    if(this.state.pmsKey == 69){          
      requestParam.extra = "occupancy_based"
    }
    
    if(extra &&  this.state.pmsKey == 59) {
      if(this.props.connectionReducer && this.props.connectionReducer.hotsoftaccountList){
        let res = this.props.connectionReducer.hotsoftaccountList.find(({name}) => name === extra);
        requestParam.extra = res.name
        requestParam.username = res.account_number
      }
    }
    
    delete requestParam.currency;
    if (requestParam.id) {
      if (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 52) {
        delete requestParam.extra;
        delete requestParam.token;
      }
      if (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 70) {
        delete requestParam.extra;
        delete requestParam.token;
      }
      this.props.connectionUpdate(requestParam, this.stopLoader)
    } else {
      this.props.connectionRegister(requestParam)
    }

  }

  handleValueChage = name => (event) => {
    if (name === 'integration') {

      let obj = masters.integration.find((o, i) => {
        if (o.value === event.value) {
          return true;
        }
      });

      this.setState({
        currentData: {
          ...this.state.currentData,
          integration: obj.value,
        },
        integration: obj.value,
        selectedIntegration: { value: masters.integration.filter(val => val.value === obj.value)[0].value, label: masters.integration.filter(val => val.value === obj.value)[0].label }
      })
    } else if (name === 'region') {
      let obj;
      if (this.state.pmsKey == 15) {
        obj = masters.protelConn.find((o, i) => {
          if (o.value === event.target.value) {
            return true;
          }
        });
      } else if (this.state.pmsKey == 27) {
        obj = masters.regionListRms.find(o => o.value === event.target.value);
      } else if (this.state.pmsKey == 60) {
        obj = masters.regionListStayntouch.find(o => o.value === event.target.value);
      } else if (this.state.pmsKey == 61) {
        obj = masters.versionList.find(o => o.value === event.target.value);
      } else {
        obj = masters.regionList.find(o => o.value === event.target.value);
      }

      this.setState({
        currentData: {
          ...this.state.currentData,
          region: obj.value,
        }
      })
    } else if(name === 'extra'){
      this.setState({
        currentData: {
          ...this.state.currentData,
          extra: event.target.checked ? true : false,
        }
      })
    }
    
    else {
      this.setState({
        currentData: {
          ...this.state.currentData,
          [name]: event.target.value,
        }
      })
    }
  }

  handlemultiSelect = name => (event) => {
    this.setState({
      currentData: {
        ...this.state.currentData,
        [name]: event.target.value.toString(),
      }
    })
  }

  toggleBookingConnection = () => event => {
    let value = event.target.value == 'false' ? true : false
    let reqParams = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      booking_suite_hotel_connection: value
    }
    this.props.updateHotel(reqParams)
  }

  handleProcessData = () => {
    this.setState({
      processLoader: true,
      openConfirmationModal: true
    })
  }
  handleUpdateType = reprocess_type => {
    let reprocess_update_date = '';
    let start_date = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD');
    // if(reprocess_type == 1){
    //   reprocess_update_date = moment(start_date).subtract(30, 'days').format('YYYY-MM-DD');
    // }else if(reprocess_type == 2){
    //   reprocess_update_date = moment(start_date).subtract(3, 'days').format('YYYY-MM-DD');
    // }else if(reprocess_type == 3){
    //   reprocess_update_date = moment(start_date).format('YYYY-MM-DD');
    // }

    reprocess_update_date = moment(start_date).subtract(3, 'days').format('YYYY-MM-DD');

    this.setState({
        reprocess_date_type: reprocess_type,
        reprocess_date:reprocess_update_date
    })
  }
  updateProcessData = () => {
    this.setState({
      openConfirmationModal: false,
    });

    let process_url = "hotel/reprocess_all_pms/"
    // if(this.state.pmsKey == 15){
    //   process_url = 'hotel/protel/reprocess_data/'
    // } else if(this.state.pmsKey == 22){
    //   process_url = 'hotel/guestline/process/'
    // } else if(this.state.pmsKey == 41) {
    //   process_url = 'hotel/roomraccoon/reprocess_data/'
    // } else if(this.state.pmsKey == 45) {
    //   process_url = 'hotel/d-edge/reprocess_data/'
    // } else if(this.state.pmsKey == 46) {
    //   process_url = 'hotel/eviivo/reprocess_data/'
    // } else if(this.state.pmsKey == 48) {
    //   process_url = 'hotel/onehotel/reprocess_data/'
    // } else if(this.state.pmsKey == 49) {
    //   process_url = 'hotel/hls/reprocess_data/'
    // } else if(this.state.pmsKey == 50) {
    //   process_url = 'hotel/cubilis/reprocess_data/'
    // }  else if(this.state.pmsKey == 53) {
    //   process_url = 'hotel/profitroom/reprocess_data/'
    // } else if(this.state.pmsKey == 54) {
    //   process_url = 'hotel/zimmersoftware/reprocess_data/'
    // }

    let start_date = moment.utc(this.props.hotelAuthReducer.utcTime).tz(this.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD');
    let reprocess_update_date = moment(start_date).subtract(3, 'days').format('YYYY-MM-DD');

    let requestParam = { 
      Authtoken: this.props.hotelAuthReducer.hotelDetails.token,
      process_url: process_url,
      reprocess_date: reprocess_update_date,

    }
    this.props.getProcessData(requestParam)
  }
  closeConfirmationModal = () => event => {
    this.setState({
      openConfirmationModal: false,
    });
  }
  handleProcessVBData = () => {
    let requestParam = { Authtoken: this.props.hotelAuthReducer.hotelDetails.token }
    this.setState({ processLoader: true })
    this.props.getProcessVBData(requestParam)
  }

  integrationVersion2 = () => event => {
    this.setState({
      currentData:{
        ...this.state.currentData,
        use_v2_integration: event.target.checked ? true : false
      }
    });
  }
  handleConnectionToggle = () => event => {
    if (event.target.checked) {

      this.setState({
        currentData: {
          ...this.state.currentData,
          primary: true
        }
      }, () => {
        let requestParam = {
          ...this.state.currentData,
          provider: this.state.pmsKey,
          Authtoken: this.props.hotelAuthReducer.hotelDetails.token,
          room_apartment_space_name: this.state.room_apartment_space_name
        }

        if (requestParam.id) {
          this.props.connectionUpdate(requestParam, this.stopLoader)
        } else {
          this.props.connectionRegister(requestParam)
        }

      });

    } else {
      this.setState({
        connectionModal: {
          ...this.state.connectionModal,
          primary: false
        },
        openConnectionModal: true
      });
    }
  }

  closeConnectionModal = () => event => {
    this.setState({
      openConnectionModal: false
    });
  }

  updateConnection = (data) => event => {
    this.setState({
      currentData: {
        ...this.state.currentData,
        primary: data
      },
      openConnectionModal: false
    }, () => {
      let requestParam = {
        ...this.state.currentData,
        provider: this.state.pmsKey,
        Authtoken: this.props.hotelAuthReducer.hotelDetails.token
      }
      this.props.disconnectPMS(requestParam);
    });
  }


  adjustCall = (event) => {
    if (this.props.hotelDetail && this.props.hotelDetail.client) {
      let requestParams = {
        user: this.props.hotelDetail.client,
        token: this.props.userSession.token,
        do_not_call: !this.props.hotelAuthReducer.hotelDetails.do_not_call
      }
      this.props.updateAccountCall(requestParams)
    }
  }
  restApi = () => {
    let process_url = ""
    let isGetApi = false;
    if(this.state.pmsKey == 46){
      process_url = 'hotel/eviivo/reset/';
    } else if(this.state.pmsKey == 52){
      process_url = 'hotel/freetobook/reset/';
    }else if(this.state.pmsKey == 71){
      process_url = 'hotel/pms/init-data-sync/';
      isGetApi = true;
    }

    let requestParams = {
      token:this.props.hotelAuthReducer.hotelDetails.token,
      process_url: process_url
    }

    this.props.storeRestApi(requestParams, isGetApi)
  } 
  hubspotLogic=()=>{
    window.history.pushState({},'bot_integration_page','?bot_integration_page=true')
    // window.history.pushState({},'custom_bot', '?custom_bot=integration_page');
    window.HubSpotConversations.widget.refresh({openToNewThread: true});
  }
  render() {
    const { classes } = this.props;
    const { t } = this.props;
    let is_superuser = (this.props.userSession.user.is_staff && (this.props.userSession.user.sub_account_type == null || !this.props.userSession.user.sub_account_type)  && [22, 34, 41, 45, 48, 49, 50, 53, 54, 57, 58, 67].includes(this.state.pmsKey)) ? true : false;
    // let is_admin_and_VB = (this.props.userSession.user.is_staff && this.state.pmsKey == 34) ? true : false;
    let is_admin_only=this.props.userSession.user.is_staff ? true : false;
    let roomTypeTitle = "";
    let roomRateTitle = "";
    let is_admin_reset_inv_rate_btn = (this.props.userSession.user.is_staff && [46].includes(this.state.pmsKey)) ? true : false;

    let columns = [
      {
        name: (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 69 )  ? t("InvTypeCode") : t(`${this.state.room_apartment_space_name} Id`),
        options: {
          filter: false
        }
      },
      {
        name: t("Name"),
        options: {
          filter: false
        }
      },
      {
        name: t("Min Occupancy"),
        options: {
          filter: false
        }
      },
      {
        name: t("Max Occupancy"),
        options: {
          filter: false,
        }
      },
      {
        name: (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 41) ? t("Extra") : "",
        options: {
          filter: false
        }
      },
      {
        name: t("Actions"),
        options: {
          filter: false,
          download: false,
          sort: false,
          display: [53].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) ? false : true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const { classes } = this.props;
            return <React.Fragment>
              {this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider != 35 &&
                <Button color="secondary" onClick={() => this.deleteTypeModal(value)} className={classes.actionIcon}><Icon>delete</Icon></Button>
              }
            </React.Fragment>
          }
        }
      },
      {
        name: "",
        options: {
          display:false,
          filter: false
        }
      },
    ];


    let columnsRates = [
      {
        name: (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 34 || this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 22 || this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 69)  ? t("Rate Plan Code") : t("Rate Flex Code"),
        options: {
          filter: false
        }
      },
      {
        name: this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 34 ? t("Rate Plan Name") : t("Rate Plan"),
        options: {
          filter: false
        }
      },
      {
        name: t(`Pms ${this.state.room_apartment_space_name} Code`),
        options: {
          filter: false,
          display: [34, 69].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) ? false : true
        }
      },
      {
        name: t("Description"),
        options: {
          filter: false
        }
      },
      {
        name: (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 22) ? t("Rate Flex Table") : "",
        options: {
          filter: false
        }
      },
      {
        name: t("Actions"),
        options: {
          filter: false,
          download: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const { classes } = this.props;
            return <React.Fragment>
              <Button color="secondary" onClick={() => this.deleteRateModal(value)} className={classes.actionIcon}><Icon>delete</Icon></Button>

            </React.Fragment>
          }
        }
      },
      {
        name: "",
        options: {
          display:false,
          filter: false
        }
      },
    ];



    if (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 15) {
      roomTypeTitle = `Protel ${this.state.room_apartment_space_name} Types`
    } else if (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 41) {
      roomTypeTitle = `RoomRaccoon ${this.state.room_apartment_space_name} Types`
    } else if (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 22) {
      roomTypeTitle = `Guestline ${this.state.room_apartment_space_name} Types`
    } else {
      roomTypeTitle = `${this.state.room_apartment_space_name} Types`
    }
    if (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 15 || this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 45) {
      roomRateTitle = this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 45 ? "Rate Plans" : "Protel Rates"
      columns = [
        {
          name: t("InvTypeCode"),
          options: {
            filter: false
          }
        },
        {
          name: t(`${this.state.room_apartment_space_name} Type Name`),
          options: {
            filter: false
          }
        },
        {

          name: t("Min Occupancy"),
          options: {
            filter: false
          }
        },
        {
          name: t("Max Occupancy"),
          options: {
            filter: false
          }
        },
        {
          name: (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 41) ? t("Extra") : "",
          options: {
            filter: false
          }
        },
        {
          name: t("Actions"),
          options: {
            filter: false,
            download: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              const { classes } = this.props;
              return <React.Fragment>
                {this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider != 35 &&
                  <Button color="secondary" onClick={() => this.deleteTypeModal(value)} className={classes.actionIcon}><Icon>delete</Icon></Button>
                }
              </React.Fragment>
            }
          }
        },
        {
          name: "",
          options: {
            display:false,
            filter: false
          }
        },
      ];
      columnsRates = [
        {
          name: t("Rate Plan Code"),
          options: {
            filter: false
          }
        },
        {
          name: t("Rate Plan Name"),
          options: {
            filter: false
          }
        },
        {
          name: t(`${this.state.room_apartment_space_name} Types`),
          options: {
            filter: false
          }
        },
        {
          name: t("Description"),
          options: {
            filter: false
          }
        },
        {
          name: (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 22) ? t("Rate Flex Table") : "",
          options: {
            filter: false
          }
        },
        {
          name: t("Actions"),
          options: {
            filter: false,
            download: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              const { classes } = this.props;
              return <React.Fragment>
                <Button color="secondary" onClick={() => this.deleteRateModal(value)} className={classes.actionIcon}><Icon>delete</Icon></Button>

              </React.Fragment>
            }
          }
        },
        {
          name: "",
          options: {
            display:false,
            filter: false
          }
        },
      ];

    } else if (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 41) {
      roomRateTitle = "RoomRaccoon Rates"
      columns = [
        {
          name: t(`${this.state.room_apartment_space_name} Type ID`),
          options: {
            filter: false
          }
        },
        {
          name: t(`${this.state.room_apartment_space_name} Type Name`),
          options: {
            filter: false
          }
        },
        {

          name: t("Min Occupancy"),
          options: {
            filter: false
          }
        },
        {
          name: t("Max Occupancy"),
          options: {
            filter: false
          }
        },
        {
          name: (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 41) ? t("Extra") : "",
          options: {
            filter: false
          }
        },
        {
          name: t("Actions"),
          options: {
            filter: false,
            download: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              const { classes } = this.props;
              return <React.Fragment>
                {this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider != 35 &&
                  <Button color="secondary" onClick={() => this.deleteTypeModal(value)} className={classes.actionIcon}><Icon>delete</Icon></Button>
                }
              </React.Fragment>
            }
          }
        },
        {
          name: "",
          options: {
            display:false,
            filter: false
          }
        },
      ];
      columnsRates = [
        {
          name: t("Rate Type ID"),
          options: {
            filter: false
          }
        },
        {
          name: t("Rate Type Name"),
          options: {
            filter: false
          }
        },
        {
          name: t(`${this.state.room_apartment_space_name} Types`),
          options: {
            filter: false
          }
        },
        {
          name: t("Description"),
          options: {
            filter: false
          }
        },
        {
          name: (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 22) ? t("Rate Flex Table") : "",
          options: {
            filter: false
          }
        },
        {
          name: t("Actions"),
          options: {
            filter: false,
            download: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              const { classes } = this.props;
              return <React.Fragment>
                <Button color="secondary" onClick={() => this.deleteRateModal(value)} className={classes.actionIcon}><Icon>delete</Icon></Button>

              </React.Fragment>
            }
          }
        },
        {
          name: "",
          options: {
            display:false,
            filter: false
          }
        },
      ];

    } else if (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 22) {
      roomRateTitle = "Guestline Types and Flexcodes"
    } else if (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 34) {
      roomRateTitle = "Rate Types"
    } else {
      roomRateTitle =   `${this.state.room_apartment_space_name} Rates`
    }


    const optionsRates = {
      responsive: 'scroll',
      selectableRows: false,
      search: false,
      print: false,
      download: false,
      viewColumns: false,
      filter: false,
      rowsPerPage: 10,
      onRowClick: (rowData) => {
        let value = rowData[6];
        this.createUpdateRateModal(value);
      },
    };

    const options = {
      responsive: 'scroll',
      selectableRows: false,
      search: false,
      print: false,
      download: false,
      viewColumns: false,
      filter: false,
      rowsPerPage: 10,      
      onRowClick: (rowData) => {
        let value = rowData[6];
        this.createUpdateTypeModal(value)
      },
    };

    const SelectStyle = {
      control: (base, state) => ({
        ...base,
        height: "40px",
        border: 'none',
        boxShadow: "0 !important",
        width: '100%',
        borderRadius: "4px !important",
        background: "#f2f2f2",
        textAlign: 'left',
        "&:hover": {
          border: 'none',
        },
        "&:focus-within": {
          border: 'none',
        },
        "&:active": {
          border: 'none',
        },
      })
    };

    const SelectStyleError = {
      control: (base, state) => ({
        ...base,
        height: "40px",
        border: '1px solid red',
        boxShadow: "0 !important",
        borderRadius: '3px !important',
        textAlign: 'left',
        "&:hover": {
          border: '1px solid red'
        },
        "&:focus-within": {
          border: '1px solid red'
        },
        "&:active": {
          border: '1px solid red'
        },
        ">div >div": {
          paddingLeft: "0 !important",
          color: "red !important"
        }
      })
    }
    
    let width = window.screen.width;
    const PMSComponent = (props) => (<React.Fragment>{<FormLabel className={classes.formLable} >{t(this.state.pms_channel_manager_text)}</FormLabel>}<components.Control {...props} /></React.Fragment>);
    const IntegrationComponent = (props) => (<React.Fragment>{<FormLabel className={classes.formLable} >{t('Integration')}</FormLabel>}<components.Control {...props} /></React.Fragment>);
    return (
      <React.Fragment>
        <HeaderCard >
          <Grid container>
            <Grid item xs={12} >
              <div className={classes.divStyle} >
                <Typography variant="h5" className={classes.fontStyle} >
                  <>{t("Integration")} <CustomTooltip title="" description={t(`Here you can integrate your ${this.state.pms_channel_manager_text} with RoomPriceGenie. The integrations differ a little depending on your system. Learn more in our integration docs.`)}></CustomTooltip></>
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} style={{marginTop:'20px'}} >
              {is_admin_only &&
                <Button disabled={this.state.loader} onClick={() => this.updatePmsConnection()} className={classes.saveBtn} variant="contained" color="primary" >
                  {t("Update")} {this.state.loader ? <CircularProgress color="primary" size={24} className={classes.buttonProgress} /> : ""}
                </Button>
              }
              {(!(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) && [25, 26, 46, 70].includes(this.state.pmsKey)) &&
                <Button disabled={this.state.loader} onClick={() => this.updatePmsConnectionClientView()} className={classes.saveBtn} variant="contained" color="primary" >
                  {t("Update")} {this.state.loader ? <CircularProgress color="primary" size={24} className={classes.buttonProgress} /> : ""}
                </Button>
              } 
              {is_superuser &&
                <Button color="primary" onClick={() => this.handleProcessData()}
                className={classes.saveBtn} variant="outlined">
                  {t("Reprocess data")}
                </Button>
              }
              {is_admin_only && [52, 46, 71].includes(this.state.pmsKey) &&
                  <Button disabled={this.state.restApiLoader} className={classes.saveBtn} onClick={() => this.restApi()} variant="outlined" color="primary" >
                    {t(`Request Full Sync from ${this.state.pms_channel_manager_text}`)} {this.state.restApiLoader ? <CircularProgress color="primary" size={24} className={classes.buttonProgress} /> : ""}
                  </Button>
              }
                  
                  {/* {is_admin_and_VB ?
                    <Button color="primary" onClick={() => this.handleProcessVBData()}
                      className={classes.ProceedBtn} variant="outlined">
                      {t("Process Data")}
                    </Button> : ''} */}
                <FormGroup className={[classes.btnReprocess,classes.saveBtn]}>
                <FormControlLabel 
                  control={
                    <Switch  color="primary"
                    checked={this.state.currentData.primary}
                    onClick={this.handleConnectionToggle()}
                    />
                    }
                  label= {t("Connected")} 
                />
              </FormGroup>
              {this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 1 &&
                <Button
                  type="button"
                  className={classes.saveBtn}
                  href={process.env.REACT_APP_SSO_APALEO}
                  target='_blank'
                  variant="outlined"
                  color='primary'
                  >
                  Update Connection
              </Button>
              }
              <Button onClick={()=>this.hubspotLogic()} className={classes.saveBtn} style={{color:'#707070',marginLeft:'-18px'}}> {t('Contact Support')}</Button>
              
              {is_admin_only &&
                <><FormControlLabel
                  className={[classes.btnReprocess,classes.saveBtn]}
                  control={
                      <Checkbox
                          checked={this.state.currentData.use_v2_integration}
                          onChange={this.integrationVersion2()}
                          value={''}
                          color="primary"
                      />
                  }
                  label={<><div style={{display:"flex"}}>{t('Use Integration') + ' 2.0'} <ReportProblemIcon style={{color:'#eda942',marginLeft:'6px',minWidth:'0px',fontSize:'18px'}} /><span style={{color:'#eda942',marginLeft:'5px',whiteSpace:'nowrap'}}>Contact tech</span></div>
                      </>}
              /></> 
              }
            </Grid>
          </Grid>
        </HeaderCard>
        <Grid container direction={width < 800 ? "column" : ""}>
          <Grid item xs={12} sm={6} md={6} lg={6} className={classes.fullWidth}>
          <MuiThemeProvider theme={ConnectiongetMuicard}>
            <Card>
              <CardContent className={classes.cardFixedHeight}>
                <form className={classes.formContainer} noValidate autoComplete="off">
                  {/* for hide the saved username password start */}
                  <FormControl margin="normal" className={classes.formTextfield} >
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      id="standard-name"
                      label={'username'}
                      margin="normal"
                    />
                  </FormControl>
                  <FormControl margin="normal" className={classes.formTextfield} >
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="password"
                      id="standard-name"
                      label={'password'}
                      margin="normal"
                    />
                  </FormControl>

                 
                  <FormControl margin="normal" required className={classes.formControl}>
                    <Select
                      components={{ Control: PMSComponent }}
                      value={this.state.selectedPmsKey}
                      error={this.state.error.includes("pms")}
                      label={t(this.state.pms_channel_manager_text)}
                      placeholder={t(this.state.pms_channel_manager_text)}
                      styles={this.state.error.includes("pms") ? SelectStyleError : SelectStyle}
                      onChange={this.handlePmsChange()}
                      options={connectionList}
                      endAdornment={<CustomTooltip title="" description={t(`We can connect to either your ${this.state.pms_channel_manager_text}.`)}></CustomTooltip>}
                    />
                  </FormControl>                  
                  {(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) &&
                    <FormControl margin="normal" required className={classes.formControl}>
                      <Select
                        components={{ Control: IntegrationComponent }}
                        value={this.state.selectedIntegration}
                        error={this.state.error.includes('integration')}
                        label={t("Integration")}
                        placeholder={t("Integration")}
                        styles={this.state.error.includes("integration") ? SelectStyleError : SelectStyle}
                        onChange={this.handleValueChage('integration')}
                        options={masters.integration}
                        endAdornment={<CustomTooltip title="" description={t(`Two-way integrations can do both, fetch the availabilities from your ${this.state.pms_channel_manager_text} and upload the new prices. One-way integrations can only fetch your availability. We’re working on it!`)}></CustomTooltip>}
                      />
                    </FormControl>
                  }
                  {this.handleFields()}
                </form>
                {Modals.processConfirmationModal(this)}
                {Modals.connectionToggleModal(this)}
              </CardContent>
            </Card >
            </MuiThemeProvider>
          </Grid>

        </Grid>
        {[15, 29, 41, 22, 34, 45, 53, 69].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) &&
          <React.Fragment>
            <Card className={classes.tableHeading}>

              <CardHeader title={t(roomTypeTitle)}>
              </CardHeader>
              <CardContent>
                <div className={classes.addBtnDiv}>

                  {![53, 29].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) &&
                    <div className={classes.addNewDiv}>
                      <Button className={classes.addUserBtn} variant="contained" color="primary" onClick={() => this.createUpdateTypeModal()}>
                        {t(`Add new ${this.state.room_apartment_space_name} Type`)}
                      </Button>
                    </div>
                  }
                </div>
                <div className={classes.userTableDiv}>
                  <MuiThemeProvider theme={ConnectiongetMuiThemeType}>
                    <MUIDataTable
                      title=''
                      data={this.renderTypes()}
                      columns={columns}
                      options={options}
                    />
                  </MuiThemeProvider>
                </div>
              </CardContent>
            </Card >
            {[15, 41, 22, 34, 45, 69].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) &&            
              <Card className={classes.tableHeading}>

                <CardHeader title={t(roomRateTitle)}>
                </CardHeader>
                <CardContent>
                  <div className={classes.addBtnDiv}>
                    <div className={classes.addNewDiv}>
                      <Button className={classes.addUserBtn} variant="contained" color="primary" onClick={() => this.createUpdateRateModal()}>
                        {this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 15 ? t("Add new Protel Rate") : t("Add new Rate")}
                      </Button>
                    </div>
                  </div>
                  <div className={classes.userTableDiv}>
                    <MuiThemeProvider theme={ConnectiongetMuiThemeRate}>
                      <MUIDataTable
                        data={this.renderRates()}
                        columns={columnsRates}
                        options={optionsRates}
                      />
                    </MuiThemeProvider>
                  </div>
                </CardContent>
              </Card>
            }
          </React.Fragment>
        }
        {this.state.createUpdateType &&  Modals.createRoomTypeModal(this)}
        {this.state.createUpdateRate && Modals.createRoomRateModal(this)}
        {this.state.deleteType && Modals.deleteTypeModal(this)}
        {this.state.deleteRate && Modals.deleteRateModal(this)}
        {this.state.showConfirmRoomDelete && Modals.roomDeleteConfirmationDialog(this)}
        {this.state.showNotificationRateDelete && Modals.rateDeleteNotificationDialog(this)}
        {this.state.deadRoomRateType && Modals.deadRoomRateTypeModal(this)}
        {this.state.minmaxOccupancyDetected && Modals.minMaxOccupancyDetectModal(this)}
        {Warning.warningModal(this)}

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    userSession: state.sessionReducer,
    hotelDetail: state.hotelSettingReducer.hoteDetail,
    hotelAuthReducer: state.hotelAuthReducer,
    connectionReducer: state.connectionReducer,
    commonReducer: state.commonReducer,
    currencyList: state.currencyReducer.currencyList,
    RoomTypeRateReducer: state.RoomTypeRateReducer,
    priceSettingReducer: state.priceSettingReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getConnectionList: (request) => { dispatch(actions.getConnectionList(request)) },
    connectionUpdate: (request, stopLoader) => { dispatch(actions.connectionUpdate(request, stopLoader)) },
    connectionRegister: (request) => { dispatch(actions.connectionRegister(request)) },
    disconnectPMS: (request) => { dispatch(actions.disconnectPMS(request)) },
    removeDeadRooms: (request) => { dispatch(actions.removeDeadRooms(request)) },
    removeDeadRates: (request) => { dispatch(actions.removeDeadRates(request)) },
    getCurrency: (request) => { dispatch(actions.currency(request)) },
    callReservation: (request) => { dispatch(actions.callReservation(request)) },
    seekomCall: (request) => { dispatch(actions.seekomCall(request)) },
    getReservation: (request) => { dispatch(actions.getReservation(request)) },
    updateHotel: (request) => { dispatch(actions.updateHotelSetting(request)) },
    // getPublisherList: (request) => { dispatch(actions.getPublisherList(request)) },
    getRoomTypeList: (request) => { dispatch(actions.getRoomTypeList(request)) },
    getRoomRateList: (request) => { dispatch(actions.getRoomRateList(request)) },
    createRoomType: (request) => { dispatch(actions.createRoomType(request)) },
    createRoomRate: (request) => { dispatch(actions.createRoomRate(request)) },
    updateRoomType: (request) => { dispatch(actions.updateRoomType(request)) },
    updateRoomRate: (request) => { dispatch(actions.updateRoomRate(request)) },
    updateRoomRateProtel: (request) => { dispatch(actions.updateRoomRateProtel(request)) },
    deleteRoomType: (request) => { dispatch(actions.deleteRoomType(request)) },
    deleteRoomRate: (request) => { dispatch(actions.deleteRoomRate(request)) },
    updateAccountCall: (request) => { dispatch(actions.updateAccountCall(request)) },
    getHotelDetail: (request) => { dispatch(actions.getHotelDetail(request)) },
    getRmsProperty: (request, stopLoader) => { dispatch(actions.getRmsProperty(request, stopLoader)) },
    getEvivProperty: (request, stopLoader) => { dispatch(actions.getEvivProperty(request, stopLoader)) },    
    getHLSProperty: (request, stopLoader) => { dispatch(actions.getHLSProperty(request, stopLoader)) },   
    getSabeeProperty: (request, stopLoader) => { dispatch(actions.getSabeeProperty(request, stopLoader)) },        
    getRmsAgents: (request, stopLoader) => { dispatch(actions.getRmsAgents(request, stopLoader)) },
    getProcessData: (request) => { dispatch(actions.getProcessData(request)) },
    getProcessVBData: (request) => { dispatch(actions.getProcessVBData(request)) },
    storeRestApi : (request,isGetApi) =>{dispatch(actions.storeRestApi(request,isGetApi))},
    freetobookCall : (request, callback) =>{dispatch(actions.freetobookCall(request, callback))},
    putNotify: (request) => { dispatch(actions.putNotify(request)) },
    oauth2Call : (request, callback) =>{dispatch(actions.oauth2Call(request, callback))},
    getMultiPropertyList : (request, stopLoader) =>{dispatch(actions.getMultiPropertyList(request, stopLoader))},
    getPricingSettings: (request) => { dispatch(actions.getPricingSettings(request)) },
    getSabeePropertyList: (request, stopLoader) => { dispatch(actions.getSabeePropertyList(request, stopLoader)) },    
    oauth2Call : (request, callback) =>{dispatch(actions.oauth2Call(request, callback))},
    getHotsoftAccountList: (request, stopLoader) => { dispatch(actions.getHotsoftAccountList(request, stopLoader)) },
  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(ConnectionSetting));
