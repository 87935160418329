import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import roomTypeRateCss from './css/roomTypeRateCss';
import Modals from './modal/modal';
import Icon from '@material-ui/core/Icon';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../../store/actions/index';
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { roomtyperateTheme } from '../../muiTheme';
import { withNamespaces } from 'react-i18next';


let RoomSetingList = [];
const styles = roomTypeRateCss;

class RoomSetting extends React.Component {

  state = {
    typeList: [],
    createTypeModal: false,
    typeData: {},
    rateData: {},
    deleteRateId: null,
    deleteTypeId: null,
    error: []
  };

  componentWillMount() {
    let requestParam = { token: this.props.hotelAuthReducer.hotelDetails.token }
    this.props.getRoomTypeList(requestParam);
    this.props.getRoomRateList(requestParam);
  }

  componentWillReceiveProps(props) {
    if (props.RoomTypeRateReducer.typeList) {
      this.setState({
        typeList: props.RoomTypeRateReducer.typeList
      })
    }

    if (props.RoomTypeRateReducer.rateList) {
      this.setState({
        rateList: props.RoomTypeRateReducer.rateList
      })
    }
  }

  handleTypeData = (name) => event => {
    this.setState({
      typeData: {
        ...this.state.typeData,
        [name]: event.target.value
      }
    })
  }

  handleRateData = (name) => event => {
    this.setState({
      rateData: {
        ...this.state.rateData,
        [name]: event.target.value
      }
    })
  }

  handleRateCheckbox = (name) => event => {
    let value = event.target.checked ? true : false;
    this.setState({
      rateData: {
        ...this.state.rateData,
        pms_room: null,
        [name]: value
      }
    })
  }

  createUpdateTypeModal = (value) => {
    this.setState({
      createUpdateType: !this.state.createUpdateType,
      typeData: value ? value : {},
      error: []
    })
  }

  createUpdateRateModal = (value) => {
    let dafaultAry = {
      rate_id: null,
      name: null,
      pms_room: null,
      description: null,
      is_for_all_room_type: false
    }
    this.setState({
      createUpdateRate: !this.state.createUpdateRate,
      rateData: value ? value : dafaultAry,
      error: []
    })
  }

  deleteTypeModal = (value) => {
    this.setState({
      deleteType: !this.state.deleteType,
      deleteTypeId: value ? value.id : null
    })
  }

  deleteRateModal = (value) => {
    this.setState({
      deleteRate: !this.state.deleteRate,
      deleteRateId: value ? value.id : null
    })
  }

  validateType = () => {

    let error = [];

    let typeData = this.state.typeData;

  
    if (!typeData.name) {
      error.push("name")
    }

   

    if (typeData.room_id === undefined || typeData.room_id === "") {
      error.push("room_id")
    }

    this.setState({
      error
    })

    return error
  }

  createUpdateType = () => {
    let error = this.validateType();

    if (error.length == 0) {
      if (this.state.typeData.id) {
        let request = {
          token: this.props.hotelAuthReducer.hotelDetails.token,
          ...this.state.typeData
        }
        this.props.updateRoomType(request)
        this.createUpdateTypeModal()
      } else {
        let request = {
          token: this.props.hotelAuthReducer.hotelDetails.token,
          ...this.state.typeData
        }
        this.props.createRoomType(request)
        this.createUpdateTypeModal()
      }
    }
  }

  validateRate = () => {
    let error = [];

    let rateData = this.state.rateData;

   

    if (!rateData.name) {
      error.push("name")
    }

    if ((rateData.pms_room === undefined || rateData.pms_room === "") && !rateData.is_for_all_room_type) {
      error.push("pms_room")
    }

    if (!rateData.rate_id) {
      error.push("rate_id")
    }

    this.setState({
      error
    })

    return error;

  }

  createUpdateRate = () => {
    let error = this.validateRate();
    if (error.length == 0) {
      if (this.state.rateData.id) {
        let request = {
          token: this.props.hotelAuthReducer.hotelDetails.token,
          ...this.state.rateData
        }
        this.props.updateRoomRate(request)
        this.createUpdateRateModal();
      } else {
        let request = {
          token: this.props.hotelAuthReducer.hotelDetails.token,
          ...this.state.rateData
        }
        this.props.createRoomRate(request);
        this.createUpdateRateModal();
      }
    }
  }

  deleteCurrentType = () => {
    let request = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      id: this.state.deleteTypeId
    }
    this.props.deleteRoomType(request);
    this.deleteTypeModal();
  }

  deleteCurrentRate = () => {
    let request = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      id: this.state.deleteRateId
    }
    this.props.deleteRoomRate(request);
    this.deleteRateModal();
  }

  renderTypes = () => {
    let data = [];

    if (this.state.typeList && this.state.typeList.length > 0) {
      this.state.typeList.map((row) => {
        data.push([
          row.room_id,
          row.name,
          row.max_occupancy,
          row.min_occupancy,
          row
        ])
      })
    }

    return data;
  }

  renderRates = () => {
    let data = [];

    if (this.state.rateList && this.state.rateList.length > 0) {
      this.state.rateList.map((row) => {
        data.push([
          row.rate_id ? row.rate_id : "-",
          row.name ? row.name : "-",
          row.pms_room ? row.pms_room : "-",
          row.description ? row.description : "-",
          row
        ])
      })
    }
    return data;
  }



  render() {
    const { classes } = this.props;
    const { t } = this.props;

    const columns = [
      {
        name: t("Room Id"),
        options: {
          filter: false
        }
      },
      {
        name: t("Name"),
        options: {
          filter: false
        }
      },
      {
        name: t("Max Occupancy"),
        options: {
          filter: false
        }
      },
      {
        name: t("Min Occupancy"),
        options: {
          filter: false
        }
      },
      {
        name: t("Actions"),
        options: {
          filter: false,
          download: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const { classes } = this.props;
            return <React.Fragment>
              <Button color="primary" onClick={() => this.createUpdateTypeModal(value)} className={classes.actionIcon}><Icon>edit</Icon></Button>
              <Button color="secondary" onClick={() => this.deleteTypeModal(value)} className={classes.actionIcon}><Icon>delete</Icon></Button>
            </React.Fragment>
          }
        }
      }
    ];

    const columnsRates = [
      {
        name: t("Rate Id"),
        options: {
          filter: false
        }
      },
      {
        name: t("Name"),
        options: {
          filter: false
        }
      },
      {
        name: t("Pms Room Code"),
        options: {
          filter: false
        }
      },
      {
        name: t("Description"),
        options: {
          filter: false
        }
      },
      {
        name: t("Actions"),
        options: {
          filter: false,
          download: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const { classes } = this.props;
            return <React.Fragment>
              <Button color="primary" onClick={() => this.createUpdateRateModal(value)} className={classes.actionIcon}><Icon>edit</Icon></Button>
              <Button color="secondary" onClick={() => this.deleteRateModal(value)} className={classes.actionIcon}><Icon>delete</Icon></Button>
            </React.Fragment>
          }
        }
      }
    ];

    const options = {
      responsive: 'scroll',
      selectableRows: false,
      search: false,
      print: false,
      download: false,
      viewColumns: false,
      filter: false,
      rowsPerPage: 20,
      rowsPerPageOptions: []
    };

    return (
      <React.Fragment>
        <Card className={classes.tableHeading}>
          <CardHeader
            title={t("Protel Room Types")}
          >
          </CardHeader>
          <CardContent>
            <div className={classes.addBtnDiv}>
              <div className={classes.addNewDiv}>
                <Button className={classes.addUserBtn} variant="contained" color="primary" onClick={() => this.createUpdateTypeModal()}>
                  {t("Add new Room Type")}
                </Button>
              </div>
            </div>
            <div className={classes.userTableDiv}>
              <MuiThemeProvider theme={roomtyperateTheme}>
                <MUIDataTable
                  title=''
                  data={this.renderTypes()}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
            </div>
          </CardContent>
        </Card >
        <Card className={classes.tableHeading}>
          <CardHeader
            title={t("Room Rates")}
          >
          </CardHeader>
          <CardContent>
            <div className={classes.addBtnDiv}>
              <div className={classes.addNewDiv}>
                <Button className={classes.addUserBtn} variant="contained" color="primary" onClick={() => this.createUpdateRateModal()}>
                  {t("Protel Room Rates")}
                </Button>
              </div>
            </div>
            <div className={classes.userTableDiv}>
              <MuiThemeProvider theme={roomtyperateTheme}>
                <MUIDataTable
                  title=''
                  data={this.renderRates()}
                  columns={columnsRates}
                  options={options}
                />
              </MuiThemeProvider>
            </div>
          </CardContent>
        </Card >
       
      </React.Fragment>
    );
  }
}

RoomSetting.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    userSession: state.sessionReducer,
    commonReducer: state.commonReducer,
    roomList: state.roomListReducer,
    hotelAuthReducer: state.hotelAuthReducer,
    RoomTypeRateReducer: state.RoomTypeRateReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getRoomTypeList: (request) => { dispatch(actions.getRoomTypeList(request)) },
    getRoomRateList: (request) => { dispatch(actions.getRoomRateList(request)) },
    createRoomType: (request) => { dispatch(actions.createRoomType(request)) },
    createRoomRate: (request) => { dispatch(actions.createRoomRate(request)) },
    updateRoomType: (request) => { dispatch(actions.updateRoomType(request)) },
    updateRoomRate: (request) => { dispatch(actions.updateRoomRate(request)) },
    deleteRoomType: (request) => { dispatch(actions.deleteRoomType(request)) },
    deleteRoomRate: (request) => { dispatch(actions.deleteRoomRate(request)) }
  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(RoomSetting));