import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import Notify from '../Notify'
import timezoner from 'timezoner';
import * as actions from '../../../store/actions/index';

const onDetailStart = () => {
    return {
        type: actionTypes.HOTEL_DETAIL_START
    }
}

const onDetailFailed = (error) => {
    return {
        type: actionTypes.HOTEL_DETAIL_FAILED,
        error: error
    }
}

const onDetailSucceed = (data) => {
    return {
        type: actionTypes.HOTEL_DETAIL_SUCCEED,
        state: data
    }
}

export const getHotelDetail = (request) => {
    return dispatch => {
        dispatch(onDetailStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'hotel/client/detail/')
            .then(function (response) {
                dispatch(onDetailSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onDetailFailed(error))
            });
    }
}

const onupdateHotelStart = () => {
    return {
        type: actionTypes.HOTEL_UPDATE_START
    }
}

const onupdateHotelSucceed = (data) => {
    return {
        type: actionTypes.HOTEL_UPDATE_SUCCEED,
        state: data
    }
}

const onupdateHotelFailed = (error) => {
    return {
        type: actionTypes.HOTEL_UPDATE_FAILED,
        error: error
    }
}

const hotelAuthSucceed = (data) => {
    return {
        type: actionTypes.HOTEL_AUTH_UPDATE,
        data: data
    }
}

export const updateHotelSetting = (request) => {
    return dispatch => {
        dispatch(onupdateHotelStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        if (request.lat && request.lng) {
            axios.post(Server.API + 'system/geo/timezone/', { lat: request.lat.toString(), lng: request.lng.toString() })
                .then((response) => {
                    request.timezone = response.data;
                    axios.patch(Server.API + 'hotel/update/', request)
                        .then((response) => {
                            let requestParam = { token: request.token }
                            dispatch(actions.getHotelDetail(requestParam))
                            dispatch(actions.updateSettingJson(request, response.data, 'updateHotel'))
                            dispatch(onupdateHotelSucceed(response.data))
                            dispatch(hotelAuthSucceed(response.data))
                            if (!request.hideNotification) {
                                dispatch(Notify.newUserModal("Hotel Updated successfully", true))
                            }
                        })
                        .catch((error) => {
                            dispatch(onupdateHotelFailed(error))
                            dispatch(Notify.newUserModal("An error occured while updating Hotel", false))
                        });
                }).catch((error) => {
                    axios.patch(Server.API + 'hotel/update/', request)
                        .then((response) => {
                            dispatch(actions.updateSettingJson(request, response.data, 'updateHotel'))
                            dispatch(onupdateHotelSucceed(response.data))
                            dispatch(hotelAuthSucceed(response.data))
                            if (!request.hideNotification) {
                                dispatch(Notify.newUserModal("Hotel Updated successfully", true))
                            }
                        })
                        .catch((error) => {
                            dispatch(onupdateHotelFailed(error))
                            dispatch(Notify.newUserModal("An error occured while updating Hotel", false))
                        });
                });


            // timezoner.getTimeZone(
            //     request.lat, // Latitude coordinate
            //     request.lng, // Longitude coordinate
            //     function (err, data) {
            //         if (err) {
            //             // request.timezone = ''
            //         } else {
            //             request.timezone = data.timeZoneId
            //         }
            //         axios.patch(Server.API + 'hotel/update/', request)
            //             .then(function (response) {
            //                 dispatch(actions.updateSettingJson(request, response.data, 'updateHotel'))
            //                 dispatch(onupdateHotelSucceed(response.data))
            //                 dispatch(hotelAuthSucceed(response.data))
            //                 if (!request.hideNotification) {
            //                     dispatch(Notify.newUserModal("Hotel Updated successfully", true))
            //                 }
            //             })
            //             .catch(function (error) {
            //                 dispatch(onupdateHotelFailed(error))
            //                 dispatch(Notify.newUserModal("An error occured while updating Hotel", false))
            //             });
            //     },
            //     { language: 'es', key: Server.GOOGLEAPIKEY }
            // );
        } else {
            axios.patch(Server.API + 'hotel/update/', request)
                .then(function (response) {
                    let requestParam = { token: request.token }
                    dispatch(actions.getHotelDetail(requestParam))
                    if (request.is_callback) {
                        dispatch(actions.updateSettingJson(request, response.data, 'updateHotel', 'roomBulkUpdate', [request, request.refRoomRequestParam]))
                    } else {
                        dispatch(actions.updateSettingJson(request, response.data, 'updateHotel'))
                    }
                    dispatch(onupdateHotelSucceed(response.data))
                    dispatch(hotelAuthSucceed(response.data))
                    if (!request.hideNotification) {
                        dispatch(Notify.newUserModal("Hotel Updated successfully", true))
                    }
                })
                .catch(function (error) {
                    dispatch(onupdateHotelFailed(error))
                    dispatch(Notify.newUserModal("An error occured while updating Hotel", false))
                });
        }
    }
}


export const updateHotelView = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.patch(Server.API + 'hotel/update/', { is_client_view: request.is_client_view })
            .then(function (response) {
                window.location.href = "/client/Calendar/"+request.hotel_id;
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while updating Hotel", false))
            });
    }
}



export const refreshEvents = (request) => {
    return dispatch => {
        let requestParam = {
            state: Math.abs(request.state)
        }
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/save_events_per_demand/')
            .then(function (response) {
                dispatch(Notify.newUserModal("Events Fetched Successfully", true))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while fetching evnets", false))
            });
    }
}

export const updateHotelState = (request) => {
    return dispatch => {
        let requestParam = {
            state: Math.abs(request.state)
        }
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/update/state/', requestParam)
            .then(function (response) {
                dispatch(actions.getHotelDetail(request))
                dispatch(Notify.newUserModal("Hotel Updated successfully", true))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while updating Hotel", false))
            });
    }
}

const onUpdatePmsSyncStart = () => {
    return {
        type: actionTypes.UPDATE_PMS_SYNC_START
    }
}

const onUpdatePmsSyncFailed = (error) => {
    return {
        type: actionTypes.UPDATE_PMS_SYNC_FAILED,
        error: error
    }
}

const onUpdatePmsSyncSucceed = (data) => {
    return {
        type: actionTypes.HOTEL_AUTH_UPDATE,
        data: data
    }
}


export const updatePmsSync = (request) => {
    return dispatch => {
        dispatch(onUpdatePmsSyncStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.Authtoken;
        axios.post(Server.API + 'hotel/pms-sync/update/', request)
            .then(function (response) {
                dispatch(onUpdatePmsSyncSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onUpdatePmsSyncFailed(error))
            });
    }
}

const validateBookingUrlFailed = (error) => {
    return {
        type: actionTypes.VALIDATE_BOOKING_URL_SUCCESS,
        error: error
    }
}

const validateBookingUrlSucceed = (data) => {
    return {
        type: actionTypes.VALIDATE_BOOKING_URL_SUCCESS,
        data: data
    }
}

export const validateBookingUrl = (request) => {
    return dispatch => {
        let requestData = {
            booking_ref_url: request.booking_ref_url,
            is_booking_ref_url:request.is_booking_ref_url
        };
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/check/booking_ref_url/', requestData)
            .then(function (response) {
                dispatch(validateBookingUrlSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(validateBookingUrlFailed(error))
            });
    }
}