import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import pmsSettingsCss from './css/pmsSettingsCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../store/actions/index';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';

const styles = pmsSettingsCss;

class MailSetting extends React.Component {

    state = {
        email_settings: {
            inventory_fail_email: false,
            update_fail_email: false,
            autorun_fail_email: false,
            free_trial_exp_email: false,
            pricing_run_missing_email: false
        }
    };

    componentWillMount() {
        let requestParams = {
            token: this.props.userSession.token
        };
        this.props.getEmailSettingsList(requestParams)
    }

    componentWillReceiveProps(props) {
        if (props.mailReducer.list) {
            if (props.mailReducer.list.email_settings) {
                this.setState({
                    email_settings: props.mailReducer.list.email_settings
                })
            }
        }
    }

    handleChange = (key, value) => {
        this.setState({
            email_settings: {
                ...this.state.email_settings,
                [key]: !value
            }
        })
    }

    updateList = () => {
        let req = {
            token: this.props.userSession.token,
            email_settings: this.state.email_settings
        }
        this.props.updateEmailSettingsList(req);
    }


    render() {
        const { classes } = this.props;

        const mailList = [
            { label: "Inventory Fail Email", key: "inventory_fail_email" },
            { label: "Update Fail Email", key: "update_fail_email" },
            { label: "Autorun Fail Email", key: "autorun_fail_email" },
            { label: "Free Trial Exp Mail", key: "free_trial_exp_email" },
            { label: "Pricing Run Missing Email", key: "pricing_run_missing_email" },
            { label: "Room not Mapped Email", key: "room_not_mapped_email" },
            { label: "Price Change Email", key: "price_change_email" },
            { label: "Inventory Invalid Email", key: "inventory_invalid_email" },
            { label: "Auto Upload on Offemail", key: "auto_upload_on_offemail" },            
        ]
        return (
            <Card>
                <CardHeader
                    className="headig-top"
                    title="Mail Settings">
                </CardHeader>
                <CardContent>
                    {mailList.map((row) => {
                        return (
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.state.email_settings[row.key] ? true : false}
                                        onChange={() => this.handleChange(row.key, this.state.email_settings[row.key])}
                                        value={this.state.email_settings[row.key] ? true : false}
                                        color="primary"
                                    />
                                }
                                label={row.label}
                                style={{ width: "100%" }}
                            />
                        )
                    })}
                    <Button className={classes.btnLeft} variant="contained" color="primary" onClick={() => this.updateList()}>Save</Button>
                </CardContent>
            </Card >
        );
    }
}

MailSetting.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        userSession: state.sessionReducer,
        mailReducer: state.mailReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getEmailSettingsList: (request) => { dispatch(actions.getEmailSettingsList(request)) },
        updateEmailSettingsList: (request) => { dispatch(actions.updateEmailSettingsList(request)) },
    };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(MailSetting);