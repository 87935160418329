import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = {};

const setValue = (response, state) => {
    let pmsSetting = {results:[response]};
    return pmsSetting;
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PMS_SETTING_GET_START:
            return updateObject(state, {loading: true, error: null})
        case actionTypes.PMS_SETTING_GET_SUCCEED:
            return updateObject(state, {loading: false, error: null, pmsSetting: action.state})
        case actionTypes.PMS_SETTING_GET_FAILED:
            return updateObject(state, {loading: false, error: action.error})
        case actionTypes.PMS_SETTING_UPDATE_START:
            return updateObject(state, {loading: true, error: null})
        case actionTypes.PMS_SETTING_UPDATE_SUCCEED:
            return updateObject(state, {loading: false, error: null, pmsSetting: setValue(action.state, state)})
        case actionTypes.PMS_SETTING_UPDATE_FAILED:
            return updateObject(state, {loading: false, error: action.error})
        default:
            return updateObject(state, {loading: false, error: null});
    }
}

export default reducer