import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../index';
import Notify from '../Notify';
import moment from 'moment';
import timezone from 'moment-timezone'

const onRunAlgoStart = () => {
    return {
        type: actionTypes.ALGO_START
    }
}

const onRunAlgoFailed = (error) => {
    return {
        type: actionTypes.ALGO_FAILED,
        error: error
    }
}

const onRunAlgoSucceed = (data) => {
    return {
        type: actionTypes.ALGO_SUCCEED,
        state: data
    }
}

export const getAlgoProgram = (request) => {
    return dispatch => {
        dispatch(onRunAlgoStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'pricingalgorithm/algo-program/list/')
            .then(function (response) {

                for (var i = 0; i < response.data.results.length; i++) {

                    let utcOffset = moment.tz(request.timezone).utcOffset() * -1;
                    let utcTimeString = moment(('0' + response.data.results[i].hour).slice(-2) + ':' + ('0' + response.data.results[i].minute).slice(-2), 'HH:mm');
                    let localTime = moment.tz(utcTimeString.format('HH:mm'), 'HH:mm', request.timezone).subtract(utcOffset, "minutes");

                    response.data.results[i].hour = localTime.format('HH')
                    response.data.results[i].minute = localTime.format('mm')

                }

                dispatch(onRunAlgoSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onRunAlgoFailed(error))
            });
    }
}

export const createAlgoProgram = (request) => {
    return dispatch => {

        dispatch(onRunAlgoStart())
        let utcOffset = moment.tz(request.timezone).utcOffset() * -1;
        let utcTimeString = moment(('0' + request.hour).slice(-2) + ':' + ('0' + request.minute).slice(-2), 'HH:mm');
        let localTime = moment.tz(utcTimeString.format('HH:mm'), 'HH:mm', request.timezone);

        localTime = localTime.add(utcOffset, "minutes");
        request.hour = localTime.format('HH');
        request.minute = localTime.format('mm');

        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'pricingalgorithm/algo-program/register/', request)
            .then(function (response) {
                if (!request.stopFetch) {
                    dispatch(actions.getAlgoProgram(request))
                    dispatch(Notify.newUserModal("New Pricing Schedule added successfully", true))
                    dispatch(actions.getAlgoProgramLog(request))
                }
            })
            .catch(function (error, data) {
                if (error.response && error.response.data && error.response.data.algo_program && error.response.data.algo_program.message) {
                    dispatch(Notify.newUserModal(error.response.data.algo_program.message, false))
                } else {
                    dispatch(Notify.newUserModal("Error while creating Pricing Schedule", false))
                }

            });
    }
}

export const updateAlgoProgram = (request) => {
    return dispatch => {
        dispatch(onRunAlgoStart())

        let utcOffset = moment.tz(request.timezone).utcOffset() * -1;
        let utcTimeString = moment(('0' + request.hour).slice(-2) + ':' + ('0' + request.minute).slice(-2), 'HH:mm');
        let localTime = moment.tz(utcTimeString.format('HH:mm'), 'HH:mm', request.timezone);

        localTime = localTime.add(utcOffset, "minutes");
        request.hour = localTime.format('HH');
        request.minute = localTime.format('mm');

        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.patch(Server.API + 'pricingalgorithm/algo-program/update/' + request.id + '/', request)
            .then(function (response) {
                dispatch(actions.getAlgoProgram(request))
                dispatch(Notify.newUserModal("Pricing Schedule Updated successfully", true))
                dispatch(actions.getAlgoProgramLog(request))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An Error occured while updating Pricing Schedule", false))
            });
    }
}

export const deleteAlgoProgram = (request) => {
    return dispatch => {
        dispatch(onRunAlgoStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        request.id ?
            axios.delete(Server.API + 'pricingalgorithm/algo-program/delete/' + request.id )
            .then(function (response) {
                dispatch(actions.getAlgoProgram(request))
                dispatch(Notify.newUserModal("Delete Pricing Schedule successfully", true))
                dispatch(actions.getAlgoProgramLog(request))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Error while deleting Pricing Schedule", false))
            })
            :
            axios.delete(Server.API + 'pricingalgorithm/algo-program/delete/')
            .then(function (response) {
                dispatch(actions.getAlgoProgram(request))
                dispatch(Notify.newUserModal("Delete Pricing Schedule successfully", true))
                dispatch(actions.getAlgoProgramLog(request))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Error while deleting Pricing Schedule", false))
            });
        
            
    }
}

const onRunAlgoLogStart = () => {
    return {
        type: actionTypes.ALGO_LOG_START
    }
}

const onRunAlgoLogFailed = (error) => {
    return {
        type: actionTypes.ALGO_LOG_FAILED,
        error: error
    }
}

const onRunAlgoLogSucceed = (data) => {
    return {
        type: actionTypes.ALGO_LOG_SUCCEED,
        state: data
    }
}

export const getAlgoProgramLog = (request) => {
    return dispatch => {
        dispatch(onRunAlgoLogStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;

        let url = Server.API + 'pricingalgorithm/algo-program/execute/list/?';

        if (request.url) {
            url = Server.API + request.url;
        }

        if (request.hasOwnProperty("start_date") && request.hasOwnProperty("end_date")) {
            url += 'start_date=' + request.start_date + '&end_date=' + request.end_date;
        }

        axios.get(url)
            .then(function (response) {
                dispatch(onRunAlgoLogSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onRunAlgoLogFailed(error))
            });
    }
}

export const downloadHistory = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'pricingalgorithm/algo-program/pricing-result/', request)
            .then(function (response) {
                
                var content = JSON.stringify(response.data);
                const element = document.createElement("a");
                const file = new Blob([content], { type: 'text/plain' });
                element.href = URL.createObjectURL(file);
                element.download = "Pricing History " + request.date + " " + request.name + ".txt";
                
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();

            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Error While Downloading History Json", false))
            });
    }
}