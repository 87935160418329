import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import PricechangeOver20Css from './css/PricechangeOver20Css';
import { withNamespaces } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Icon } from '@material-ui/core';
import Modal from '../modal/modal'
import * as actions from '../../../../store/actions/index';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { PriceOver20 } from "../../../muiTheme";
import MUIDataTable from "mui-datatables";

const styles = PricechangeOver20Css;

const status_types = {
  '1': 'Open',
  '2': 'In Progress',
  '3': 'Closed'
}

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#00000059",
    color: "#fff",
    padding: "4px 20px 4px 15px"
  },
  body: {
    fontSize: 14,
    padding: "4px 20px 4px 15px"
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

class PricechangeOver20 extends React.Component {

  state = {
    showComments: false,
    error: [],
    type: 'price_change',
    comments: '',
    ticket_id: '',
    status: '',
    subscribedHotelPriceList: [],
    showCommentsModal: false
  };
  componentWillReceiveProps(props) {
    if (props.subscribed_hotel_price_change_lists) {
      this.setState({
        subscribedHotelPriceList: props.subscribed_hotel_price_change_lists,
      })
    }
  }
  toggleCommentsModal = (details) => {
    this.setState({
      showCommentsModal: true,
      showComments: !this.state.showComments,
      comments: details.comments,
      status: details.status,
      ticket_id: details.ticket_id,
      hotelName:details.name

    })
  }


  handleChange = () => event => {
    this.setState({
      comments: event.target.value
    });
  }

  validateData= comments =>{
    let error = [];
    if(!comments){
      error.push("comment")
      if (error) {
        this.setState({
          error: error
        })
        return false
      } 
    }else{
      this.setState({
        error: []
      })
      return true
    }
  }
  updateComment = () => {
    let data=this.state.comments;
    if (this.validateData(data)) {
    let requestParams = {
      token: this.props.userSession.token,
      comments: this.state.comments,
      ticket_id: this.state.ticket_id,
      ticket_type: this.state.type,
      deal_owner_filter: this.props.deal_owner_filter,
      deal_owner_type: this.props.deal_owner_type,
      status_type: this.props.status_type,
      status: this.state.status
    };
    this.props.updateComment(requestParams)
    this.toggleCommentsModal({})
    }
  }

  handleChangeStatus = (event) => {
    this.setState({
      ...this.state,
      status: event.target.value
    });
  }
  options = () => {
    return {
      filter: false,
      responsive: 'scroll',
      print: false,
      download: false,
      viewColumns: false,
      responsive: "scroll",
      selectableRows: false,
      rowsPerPage: 10,     
      onCellClick: (colData, cellMeta) => {
        if (cellMeta.colIndex != 5) {
          window.open("client/Calendar/" + this.state.subscribedHotelPriceList[cellMeta.dataIndex].id, '_blank');
        }
      },
    }
  };

  renderPricechangeData = (data) => {
    let jsx = [];
    data.map((row) => {
      jsx.push([
        row.id,
        row.name + '(' + row.id + ')',
        row.price_change_on === '' ? 'n/a' : row.price_change_on,
        row.price_change_per === '' ? 'n/a' : row.price_change_per + '%',
        row.status === '' ? 'n/a' : status_types[row.status],
        row,
      ])
      return null;
    })
    return jsx;
  }
  render() {
    const { classes, t } = this.props;
    const columns = [
      {
        name: "id",
        options: {
          display: false
        }
      },
      {
        name: " Hotel",
        options: {
          filter: false,
        }
      },
      {
        name: "Price",
        options: {
          filter: false,
        }
      },
      {
        name: "Amount",
        options: {
          filter: false,
        }
      },
      {
        name: "Status",
        options: {
          filter: false,
        }
      },
      {
        name: "",
        ignoreRowClick: true,
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment><Button className={classes.buttonItems} color="primary" onClick={(id) => this.toggleCommentsModal(value)} title="Edit Ticket"><Icon>edit</Icon></Button> </React.Fragment>
            );
          }

        }
      }

    ];
    return (
      <Card className={classes.root}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="subtitle1" variant="subtitle1" align={"left"} className={classes.titleBottom}>
              Price Change Over 6% <span className={classes.tabletitle}>Total: {this.props.subscribed_hotel_price_change_lists_count}</span>
            </Typography>
            <MuiThemeProvider theme={PriceOver20}>
              <MUIDataTable
                title={''}
                data={this.renderPricechangeData(this.state.subscribedHotelPriceList)}
                columns={columns}
                options={this.options()}
              />
            </MuiThemeProvider>
          </CardContent>
          {Modal.commentsModal(this)}
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userSession: state.sessionReducer,
    hotelAuthReducer: state.hotelAuthReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateComment: (request) => { dispatch(actions.updateComment(request)) },
    closeTicket: (request) => { dispatch(actions.closeTicket(request)) }
  };
};

export default compose(withStyles(styles, {
  withTheme: true
}), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(PricechangeOver20));
