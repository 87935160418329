import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from "react-redux";
import { compose } from 'redux';
import loginCss from './css/login'
import { Typography, Button, Icon, CardContent } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { withNamespaces } from 'react-i18next';
const styles = loginCss;

class findError extends React.Component {
  componentWillMount(){
    if(window.HubSpotConversations){
      window.HubSpotConversations.widget.load();
    }
    
  }
  handleLogout = () => {
    localStorage.removeItem("__UserProps__")
    localStorage.removeItem("email")
    localStorage.removeItem("name")
    window.location = "/";
  }

  render() {

    const { classes ,t} = this.props;
   
    return (
      <>      
      <Card className={classes.errorCard} style={{boxShadow:"none !important"}} elevation={0}>
        <CardHeader
          style={{ fontWeight: "500 !important" }}
          className="headig-top"
          title={t("Oh snap! Something went wrong…")}>
        </CardHeader>
        <CardContent>
        <Typography align={"left"} style={{ fontSize: "1rem" }}>
         {t("We‘re sorry, but something went wrong. Our team already got a notification and we will investigate as soon as possible. Please find the chat button on the bottom right corner to contact us, if there‘s anything we can do to help you in the meantime.")}
        </Typography>
        <img height="200" src="/img/market.gif" style={{ marginTop: "10px" }} />
        </CardContent>
      </Card>
      <Button onClick={this.handleLogout} color={"primary"} style={{ position: "absolute", marginBottom:'49px',bottom:'0',left: "0px"}}> <Icon style={{ marginRight: "15px" }}>login</Icon>{("Logout")}</Button>
      </>
    );
  }
}

findError.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  container: PropTypes.object,
};

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(findError));