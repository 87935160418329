import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = [];

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ROOM_LIST_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.ROOM_LIST_SUCCEED:
            return updateObject(state, { loading: false, error: null, roomList: action.state.results })
        case actionTypes.ROOM_LIST_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.ADD_ROOM_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.ADD_ROOM_SUCCEED:
            return updateObject(state, { loading: false, error: null, roomList: action.state.results })
        case actionTypes.ADD_ROOM_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.UPDATE_ROOM_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.UPDATE_ROOM_SUCCEED:
            return updateObject(state, { loading: false, error: null, roomList: action.state.results })
        case actionTypes.UPDATE_ROOM_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.DELETE_ROOM_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.DELETE_ROOM_SUCCEED:
            return updateObject(state, { loading: false, error: null, roomList: action.state.results })
        case actionTypes.DELETE_ROOM_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.ROOM_BILKUPDATE_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.ROOM_BILKUPDATE_SUCCEED:
            return updateObject(state, { loading: false, error: null, roomList: action.state.results })
        case actionTypes.ROOM_BILKUPDATE_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.GET_OCCUPANCY_PRICES_SUCCESS:            
            return updateObject(state, { loading: false, occupancyPrices:action.state.data})
        case actionTypes.GET_OCCUPANCY_PRICES_ALL_SUCCESS:            
            return updateObject(state, { loading: false, occupancyPricesAll:action.state.data})  
        case actionTypes.GET_PRICE_PER_RATE_SUCCEED:            
            return updateObject(state, { loading: false, pricePerRate:action.state.data})        
        default:
            return updateObject(state, { loading: false, error: null });
    }
}

export default reducer