import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = [];

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RECOVER_START:
            return updateObject(state, { loading: true, error: null, RecoverSuccess: false })
        case actionTypes.RECOVER_SUCCEED:
            return updateObject(state, { loading: false, error: null, RecoverSuccess: action.state })
        case actionTypes.RECOVER_FAILED:
            return updateObject(state, { loading: false, error: action.error, RecoverSuccess: false })
        case actionTypes.UPDATE_PASSWORD_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.UPDATE_PASSWORD_SUCCEED:
            return updateObject(state, { loading: false, error: null })
        case actionTypes.UPDATE_PASSWORD_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.CLIENT_PASSWORD_UPDATE_START:
            return updateObject(state, { loading: true, error: null, updated:false })
        case actionTypes.CLIENT_PASSWORD_UPDATE_SUCCEED:
            return updateObject(state, { loading: false, error: null, updated:true })
        case actionTypes.CLIENT_PASSWORD_UPDATE_FAILED:
            return updateObject(state, { loading: false, error: action.error, updated:false })  
        case actionTypes.VALIDATE_RESET_TOKEN_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.VALIDATE_RESET_TOKEN_SUCCEED:
            return updateObject(state, { loading: false, error: null , isValid:action.state.status })
        case actionTypes.VALIDATE_RESET_TOKEN_FAILED:
            return updateObject(state, { loading: false, error: action.state.error , isValid:action.state.status }) 
        case actionTypes.RESET_PASSWORD_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.RESET_PASSWORD_SUCCEED:
            return updateObject(state, { loading: false, error: null, isUpdated:true})
        case actionTypes.RESET_PASSWORD_FAILED:
            return updateObject(state, { loading: false, error: action.error, isUpdated:false})  
        default:
            return updateObject(state, { loading: false, error: null });
    }
}

export default reducer