import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DateCss from './css/dateSettingCss';
import Modals from './modal/modal';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../../store/actions/index';
import moment from 'moment';
import { Icon, CardContent } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { skipDateTheme } from '../../muiTheme';
import { withNamespaces, Trans } from 'react-i18next';
import MUIDataTable from "mui-datatables";

const styles = DateCss;

class DateSetting extends React.Component {

  state = {
    avg_month_week: null,
    startDate: null,
    endDate: null,
    openAverageGraph: false,
    avg_graph: null,
    smartDailyError: [],
    smartMonthlyError: [],
    loadData: true,
    openEditSkipDateModal: false,
    openSkipDateModal: false,
    openDeleteSkipDateModal: false,
    editSkipDate: moment().format('YYYY-MM-DD'),
    checkSkipDate: null,
    editSkipId: '',
    deleteSkipDate: moment().format('YYYY-MM-DD'),
    deleteSkipId: '',
    openDailyAdjustModal: false,
    openEditDailyAdjustModal: false,
    openDeleteDailyAdjustModal: false,
    addModalData: {
      percentage: '',
      date: moment().format('YYYY-MM-DD'),
    },
    checkDailyAdjustDate: null,
    editDailyAdjustModal: {
      id: '',
      percentage: '',
      date: '',
    },
    deleteModalData: {
      id: '',
      percentage: '',
      date: '',
    },
    monthlyData: [],
    weeklyData: [],
    error: [],
    average_price: 0,
    daily_adjustment: [],
    daily_competitor_average: [],
    monthly_adjustment: [],
    monthly_competitor_average: [],
    standard_smart: 'standard_settings',
    skipDate: moment().format('YYYY-MM-DD'),
    reference_room: {},
    featuresEnabled: [],

    skipDates: [],
  };

  componentWillMount() {
   
  }

  componentWillReceiveProps(props) {
    if (props.roomList.roomList) {
      this.setState({
        reference_room: props.roomList.roomList.find(o => o.is_reference_room === true)
      })
    }

    if (props.priceSettingReducer && props.priceSettingReducer.priceSetting) {
      this.setState({
          featuresEnabled: props.priceSettingReducer.priceSetting.features
      })
}

    if (props.priceSettingReducer.priceSetting) {
      let skipDates = [];
      Object.keys(props.priceSettingReducer.priceSetting.dates).map((key) => {
        if (props.priceSettingReducer.priceSetting.dates[key].skip_date) {
          skipDates.push({
            ...props.priceSettingReducer.priceSetting.dates[key].skip_date,
            date: key
          })
        }
      })
      this.setState({
        skipDates
      })
    }
  }

  handleEndDate = () => value => {
    this.setState({
      endDate: value,
    })
  }


  // functions for skip date
  toggleSkipDateModal = () => {
    this.setState({
      skipDate: null,
      name: null,
      fixed_price: null,
      openSkipDateModal: !this.state.openSkipDateModal,
    });
  }

  toggleDeleteSkipDateModal = (val) => {
    if (val) {
      this.setState({
        deleteSkipDate: val.date ? val.date : '',
        deleteSkipId: val.id ? val.id : '',
      })
    }
    this.setState({
      openDeleteSkipDateModal: !this.state.openDeleteSkipDateModal,
    });
  }

  toggleEditSkipDateModal = (data) => {
    this.setState({
      openEditSkipDateModal: !this.state.openEditSkipDateModal,
      // editSkipId: data ? data.id : null,
      editSkipDate: data ? data.date : null,
      checkSkipDate: data ? data.date : null,
      editName: data ? data.name : null,
      editFixedPrice: data ? data.fixed_price : null,
    });
  };

  handleSkipDate = (name) => event => {
    this.setState({
      [name]: ((name === 'skipDate') ? event : event.target.value),
    })
  }

  clearFixedPrice = () => {
    this.setState({
      fixed_price: "",
      editFixedPrice: "",
    })
  }

  handleEditSkipDate = (name) => event => {
    this.setState({
      [name]: ((name === 'editSkipDate') ? event : event.target.value),
    })
  }

  validateNewRoomForm = (data) => {
    let error = [];
    if (!this.state.skipDate) {
      error.push('skipDate')
    }
    if (!this.state.name) {
      error.push('name')
    }
    if (error) {
      this.setState({
        error: error
      })
      return error
    } else {
      return false
    }

  }

  disableUsedSkipDates = (current_date) => {
    let disabled = false;

    this.props.skipPriceReducer.skipDates.map((row, index) => {

      if ((this.state.checkSkipDate === null) && (moment(current_date).format('YYYY-MM-DD') === row.date)) {
        disabled = true;
      } else if (this.state.checkSkipDate !== null && moment(current_date).format('YYYY-MM-DD') === row.date) {
        if (moment(current_date).format('YYYY-MM-DD') !== this.state.checkSkipDate) {
          disabled = true;
        }
      } else {

      }
      return null;
    })
    return disabled;
  }

  disableUsedSingleAdjustDates = (current_date) => {
    let disabled = false;

    this.props.dailyAdjustmentReducer.dailyAdjustment.map((row, index) => {

      if ((this.state.checkDailyAdjustDate === null) && (moment(current_date).format('YYYY-MM-DD') === row.date)) {
        disabled = true;
      } else if (this.state.checkDailyAdjustDate !== null && moment(current_date).format('YYYY-MM-DD') === row.date) {
        if (moment(current_date).format('YYYY-MM-DD') !== this.state.checkDailyAdjustDate) {
          disabled = true;
        }
      } else {

      }
      return null;
    })
    return disabled;
  }

  addNewSkipPricing = () => {
    let validateError = this.validateNewRoomForm(this.state.addNewModalData);
    if (validateError.length > 0) {
      return;
    }

    this.setState({
      loadData: true,
    })

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      date: moment(this.state.skipDate).format('YYYY-MM-DD'),
      fixed_price: this.state.fixed_price,
      name: this.state.name
    }
    this.props.skipPriceCreate(requestParam);
    this.toggleSkipDateModal();
  }

  validateeditSkipPricing = (data) => {
    let error = [];

    if (!this.state.editSkipDate) {
      error.push('editSkipDate')
    }

    if (!this.state.editName) {
      error.push('editName')
    }

    if (error) {
      this.setState({
        error: error
      })
      return error
    }
    else {
      return false
    }

  }

  editSkipPricing = () => {

    let validateError = this.validateeditSkipPricing(this.state);

    if (validateError.length > 0) {
      return;
    }

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      editSkipId: this.state.editSkipId,
      date: moment(this.state.editSkipDate).format('YYYY-MM-DD'),
      fixed_price: this.state.editFixedPrice,
      name: this.state.editName
    }
    this.props.updateSkipPrice(requestParam); 
    this.toggleEditSkipDateModal();
  }

  deleteSkipPricing = () => {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      date: this.state.deleteSkipDate,
      deleteSkipId: this.state.deleteSkipId
    }
    this.props.deleteSkipPrice(requestParam);
    this.toggleDeleteSkipDateModal();
  }

  renderSkipDateTable = (rows) => {
    var jsx = [];
    if (rows) {
      rows.map((row) => {
        let record = [moment(row.date).format('DD-MM-YYYY'), row.name, (row.fixed_price ? ('Fixed Price: ' + this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol + row.fixed_price) : ' Skip for Pricing'), row]
        jsx.push(record)
      })
    }
    return jsx;
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;


    const skipDateOptions = () => {
      return {
        sort: false,
        search: false,
        viewColumns: false,
        responsive: "scroll",
        selectableRows: false,
        rowsPerPage: 15,
        rowsPerPageOptions: false,
        filter: false,
        download: false,
        print: false,
        customToolbar: () => {
          return (
            <React.Fragment>
              <Button className={classes.addUserBtn} variant="contained" color="primary" onClick={this.toggleSkipDateModal}>
                {t("Add new")}
              </Button>
            </React.Fragment>
          );
        }
      }
    };

    const skipDatecols = [
      t("Date"),
      t("Name"),
      t("Action"),
      {
        name: t("Edit/Delete"),
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <Button color="primary" onClick={() => this.toggleEditSkipDateModal(value)} className={classes.actionIcon}>
                  <Icon>edit</Icon>
                </Button>
                <Button color="secondary" onClick={() => this.toggleDeleteSkipDateModal(value)} className={classes.actionIcon}>
                  <Icon>delete</Icon>
                </Button>
              </React.Fragment>
            );
          }
        }
      }
    ];

    return (
      <div className={classes.root}>
        <Card>
          <CardHeader
            title={t("Skip and Fix Prices Overview")}
            className={classes.tableHeading}
          >
          </CardHeader>
          <CardContent className={classes.tableContentSkip}>
            <MuiThemeProvider theme={skipDateTheme}>
              <MUIDataTable
                data={this.renderSkipDateTable(this.state.skipDates)}
                columns={skipDatecols}
                options={skipDateOptions()}
              />
            </MuiThemeProvider>
            {Modals.DateSkipModal(this)}
          </CardContent>
        </Card>



        {Modals.DateSkipModal(this)}
        {Modals.editDateSkipModal(this)}
        {Modals.DeleteDateSkipModal(this)}
      </div>
    );
  }
}

DateSetting.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
  return {
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    userSession: state.sessionReducer,
    hotelDetail: state.hotelSettingReducer.hoteDetail,
    currencyList: state.currencyReducer.currencyList,
    hotelAuthReducer: state.hotelAuthReducer,
    skipPriceReducer: state.skipPriceReducer,
    dailyAdjustmentReducer: state.dailyAdjustmentReducer,
    monthlyAdjustReducer: state.monthlyAdjustReducer,
    weeklyAdjustReducer: state.weeklyAdjustReducer,
    smartSettingReducer: state.smartSettingReducer,
    roomList: state.roomListReducer,
    commonReducer: state.commonReducer,
    featuresReducer: state.featuresReducer,
    priceSettingReducer: state.priceSettingReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    skipPriceCreate: (request) => { dispatch(actions.skipPriceCreate(request)) },
    updateSkipPrice: (request) => { dispatch(actions.updateSkipPrice(request)) },
    deleteSkipPrice: (request) => { dispatch(actions.deleteSkipPrice(request)) },
    updateHotel: (request) => { dispatch(actions.updateHotelSetting(request)) },
    getPricingSettings: (request) => { dispatch(actions.getPricingSettings(request)) },
  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(DateSetting));
