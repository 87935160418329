import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import Notify from '../Notify';
import * as actions from '../index';

const onGetFeatureListStart = () => {
    return {
        type: actionTypes.GET_FEATURES_START
    }
}

const onGetFeatureListFailed = (error) => {
    return {
        type: actionTypes.GET_FEATURES_FAILED,
        error: error
    }
}

const onGetFeatureListSucceed = (data) => {
    return {
        type: actionTypes.GET_FEATURES_SUCCEED,
        state: data
    }
}

export const getFeaturesList = (request) => {
    return dispatch => {
        // dispatch(onGetFeatureListStart())
        // axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        // axios.get(Server.API + 'hotel/feature/')
        //     .then(function (response) {
        //         dispatch(onGetFeatureListSucceed(response.data))
        //     })
        //     .catch(function (error) {
        //         dispatch(onGetFeatureListFailed(error))
        //     });
    }
}


const onFeatureDeleteStart = () => {
    return {
        type: actionTypes.DELETE_FEATURES_START
    }
}

const onFeatureDeleteFailed = (error) => {
    return {
        type: actionTypes.DELETE_FEATURES_FAILED,
        error: error
    }
}

const onFeatureDeleteSucceed = (data) => {
    return {
        type: actionTypes.DELETE_FEATURES_SUCCEED,
        state: data
    }
}

export const deleteFeature = (request) => {
    return dispatch => {
        dispatch(onFeatureDeleteStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.delete(Server.API + 'hotel/feature/delete/' + request.id + '/')
            .then(function (response) {
                dispatch(actions.updateSettingJson(request, response.data, 'featureFlagEnable'))
                dispatch(onFeatureDeleteSucceed(response.data))
                dispatch(Notify.newUserModal("Feature disabled successfully", true))
            })
            .catch(function (error) {
                dispatch(onFeatureDeleteFailed(error))
                dispatch(Notify.newUserModal("An error occured while disabling feature", false))
            });
    }
}




const onFeatureUpdateStart = () => {
    return {
        type: actionTypes.UPDATE_FEATURES_START
    }
}

const onFeatureUpdateFailed = (error) => {
    return {
        type: actionTypes.UPDATE_FEATURES_FAILED,
        error: error
    }
}

const onFeatureUpdateSucceed = (data) => {
    return {
        type: actionTypes.UPDATE_FEATURES_SUCCEED,
        state: data
    }
}

export const enableFeature = (request) => {
    return dispatch => {
        // dispatch(onFeatureUpdateStart())
        // axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        // axios.post(Server.API + 'hotel/feature/', request)
        //     .then(function (response) {
        //         if (request.id == 6) {
        //             axios.post(Server.API + 'hotel/room/derived/generate/max-min-price/')
        //                 .then(() => {
        //                     dispatch(actions.updateSettingJson(request, response.data, 'featureFlagDisable'))
        //                 })
        //         } else {
        //             dispatch(actions.updateSettingJson(request, response.data, 'featureFlagDisable'))
        //         }

        //         dispatch(onFeatureUpdateSucceed(response.data))
        //         dispatch(Notify.newUserModal("Feature enabled successfully", true))
        //     })
        //     .catch(function (error) {
        //         dispatch(onFeatureUpdateFailed(error))
        //         dispatch(Notify.newUserModal("An error occured while enabling feature", false))
        //     });
        
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'pricingalgorithm/default-after-365-daily-averages/')
            .then((response) => {
                request.monthly_averages = response.data.monthly_averages;
                if (request.isMinMax) {
                    axios.post(Server.API + 'hotel/room/derived/generate/max-min-price/',{update_minmax_price : request.update_minmax_price })
                        .then(() => {
                            dispatch(actions.updateSettingJson(request, [], 'featureFlagDisable'))
                        })
                } else {
                    if (request.is_calculate_no_of_rooms) {
                        axios.post(Server.API + 'hotel/calculate_no_of_rooms/', { is_calculate_no_of_rooms: true })
                            .then(() => {
                                dispatch(actions.updateSettingJson(request, [], 'featureFlagDisable'))
                            })
                    } else {
                        dispatch(actions.updateSettingJson(request, [], 'featureFlagDisable'))
                    }

                }
            })
    }
}

export const genarateDefaultValueSet = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;        
        axios.post(Server.API + 'pricingalgorithm/default-after-365-daily-averages/')
        .then((response) => {            
            dispatch(Notify.newUserModal(response.data.message, true))
        })
        .catch(function (error) {
            dispatch(Notify.newUserModal("An error occured while update defualt value", false))
        });
    }
}
