import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

export default {
    warningModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;

        const warningMsg = modalData.state.warningMsg.filter((item, index, inputArray) => {
            return inputArray.indexOf(item) == index;
        });

        return (<Dialog
            fullScreen={fullScreen}
            transitionDuration={{ enter: 500, exit: 300 }}
            maxWidth='md'
            open={modalData.state.warning}
            onClose={() => modalData.toggleWarningModal()}
            aria-labelledby="responsive-dialog-title"
            PaperProps={{
                style: { 
                    width: "950px",
                    '@media only screen and (min-device-width : 856px)' : {
                        minWidth: "800px",
                        minHeight: "216px"
                    },
                },
              }}
        >
            <DialogTitle>
                {t("Warning")}
            </DialogTitle>
            <DialogContent>
                <Grid container>
                   
                    <Grid xs={9} item>
                        <DialogContentText style={{marginLeft:'0px'}}>
                            {warningMsg.map((val, index) => (
                                <div style={{ marginTop: index == 0 ? "0px" : "30px" }}>
                                    <Icon>announcement</Icon><br />
                                    {val}
                                </div>
                            ))}
                        </DialogContentText>
                    </Grid>
                    <Hidden xsDown>
                        <Grid xs={2} item style={{ display: "flex"}}>
                            <img src={"/img/errors.png"} height={200} style={{position: "absolute",marginTop: "-5%",marginLeft: "4%"}} />
                        </Grid>
                    </Hidden>
                </Grid>

            </DialogContent>
            <DialogActions>
              
                {!modalData.state.hideConfirm &&
                    <Button disabled={modalData.state.loadingUplaod} onClick={() => modalData.warningConfirm()} className={classes.saveUploadBtn} variant="contained" color="primary" >
                        {warningMsg && warningMsg.length > 1 ? t("Confirm all") : t("Confirm")}
                    </Button>
                }
                   {!modalData.state.hideCancel &&
                    <Button onClick={() => modalData.closeWarningModal()}  className="cancelBtn" style={{ marginRight: "8px" }}>
                        {t("Cancel")}
                    </Button>
                }
            </DialogActions>
        </Dialog >)
    },
}

