import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = {
    is_independent_rate: false,
    mapped_data: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PMS_LIST_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.PMS_LIST_SUCCEED:
            return updateObject(state, { loading: false, error: null, pmsList: action.state })
        case actionTypes.PMS_LIST_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        default:
            return updateObject(state, { loading: false, error: null });
    }
}

export default reducer