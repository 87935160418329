var style = {
  root: {
    flexGrow: 1,
  },
  RadioGroup: {
    display: 'inline-block',
  },
  emailLabel: {
    marginTop: '20px'
  },
  userTable: {
    padding: '20px'
  },
  addUserBtn: {
    float: 'right'
  },
  CarHeader: {
    textAlign: 'left'
  },
  root: {
    flexGrow: 1,
  },
  dateTableDiv: {
    overflow: 'auto'
  },
  MainLoader: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    background: 'rgb(15, 51, 80, 0.3)',
    zIndex: '9',
  },
  loader: {
    position: 'absolute',
    top: '35%',
    left: '35%'
  },
  textField: {
    float: "right",
    minWidth: '50px',
    width: '100%',
    '&::before': {
      border: 'none'
    },
    '& input': {
      textAlign: 'right'
    }
  },
  textFieldBg: {
    minWidth: '80px',
    width: '100%',
    '&::before': {
      border: 'none'
    },
    background: '#f5f5f5',
    '& input': {
      textAlign: 'center'
    }
  },
  tableCellPadding: {
    padding: '4px 15px 4px 15px',
  },
  rowTable: {
    height: "48px"
  },
  fieldMargin: {
    marginTop: '5px !important',
    marginBottom: '5px !important',
  },
  saveBtn: {
    float: 'right',
    margin: '15px 0px 30px 15px'
  },
  cellWidth: {
    maxWidth: '100px',
    padding: '4px 60px 4px 24px'
  },
  weekCellWidth: {
    maxWidth: '100px'
  },
  fullWidth: {
    width: '100%'
  },
  actionIcon: {
    padding: '0',
    cursor: 'pointer',
    minWidth: '30px'
  },
  actionCell: {
    padding: '10px 15px 10px 35px'
  },
  adornment: {
    color: 'rgb(118, 118, 118)',
    paddingRight: '5px'
  },
  tableHeading: {
    
    textAlign: 'left'
  },
  subTitle: {
    textAlign: 'left',
    margin: '10px 15px'
  },
  warning: {
    display: 'flex',
    textAlign: 'left',
    margin: '0px 0px 15px 12px',
    padding: '12px',
    color: 'inherit',
    background: '#F8E9C3',
    borderRadius: '5px',
    width: 'fit-content'
  },
  warningText: {
    margin: '4px 10px'
  },
  adornment: {
    color: 'rgb(118, 118, 118)',
    paddingRight: '5px'
  },
  radioFormControl: {
    minHeight: "80px",
    '@media screen and (min-width: 1000px)': {
      paddingTop: '16px',
      marginBottom: '-10px',
      float: 'right',
      width: '370px',
      margin: 15,
    },
    ['@media (max-width: 414px) and (min-width: 360px)']: {      
      marginLeft: "20px"
    }
  },
  smartSettingTooltip: {
    marginLeft: 10,
    position: 'absolute',
    ['@media (max-width: 540px) and (min-width: 400px)']: {
      position: 'inherit'
    },
    ['@media (max-width: 399px) and (min-width: 361px)']: {
      position: 'inherit'
    },
    ['@media (max-width: 360px) and (min-width: 321px)']: {
      position: 'inherit'
    },
    ['@media (max-width: 320px)']: {
      position: 'inherit'
    }
  },
  cardMargin: {
    marginTop: 20
  },
  smartSettingSwitch: {
    '@media screen and (min-width: 1000px)': {
      marginTop: 15,
      marginBottom: 15
    }
  },
  switchFormControl: {
    float: 'left',
    marginTop: '-40px',
    '@media screen and (min-width: 1000px)': {
      marginTop: '-80px',
      
    },
  },
  formControl: {
    width: '46%',
    marginLeft: '2%',
    marginRight: '2%',
    ['@media (max-width: 540px) and (min-width: 400px)']: {
      width: '98%',
      marginLeft: '1%',
      marginRight: '1%',
    },
    ['@media (max-width: 399px) and (min-width: 361px)']: {
      width: '98%',
      marginLeft: '1%',
      marginRight: '1%',
    },
    ['@media (max-width: 360px) and (min-width: 321px)']: {
      width: '98%',
      marginLeft: '1%',
      marginRight: '1%',
    },
    ['@media (max-width: 320px)']: {
      width: '98%',
      marginLeft: '1%',
      marginRight: '1%',
    }
  },
  absoluteCard: {
    '@media screen and (min-width: 1000px)': {
      marginTop: "-80px"
    },
    marginBottom: "80px"
  },
  switchFrom: {
    '@media screen and (max-width: 800px)': {
      float: 'left',
      marginLeft: '26px'
    },
    '@media screen and (min-width: 1000px)': {
      paddingTop: '16px',
      marginBottom: '-10px',
      float: 'right',
    },
  },
  pmsPrice: {
    marginTop: '37px',
    float: 'right',
    ['@media (max-width: 414px) and (min-width: 360px)']: {   
      float: 'left',    
      marginLeft: '30px'
    }
  },
  dailyTableData: {
    maxWidth: "1300px"
  },
  tableContentSkip: {
    marginTop: "-100px"
  }
}
export default style;