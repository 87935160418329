import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import HotelSetting from './settings/hotelSetting';
import RoomSetting from './settings/roomSetting';
import UserSetting from './settings/userSetting';
import PaymentSetting from './settings/paymentSetting';
import MeanTimeSetting from './settings/meanTimeSetting'
import Calendar from './calendar';
import PerformanceDashboard from './performanceDashboard';
import RateSetting from './settings/rateSetting'
import Connections from './connections'
import InternalParam from './settings/internalParams'
import Competitors from './settings/competitors';
import ContactUsNew from './contactus';
import Notfound from './notFound'
import runAlgo from './runAlgo'
import ChangePassword from './ChangePassword';
import DeleteProperty from './deleteProperty';
import features from './features';
import DailySetting from './settings/dailySetting';
import MonthlySetting from './settings/monthlySetting';
import SkipSetting from './settings/skipSetting';
import dailySeasonalAdjustments from './bulkSetting';
import RoomTypeRates from './settings/roomTypeRate';
import PriceReport from './pricingReport';
import settingsLog from './settingsLog';
import usersLog from './usersLog';
import targetOccupancy from './targetOccupancy';
// import QuickSetup from './quickSetup';
import YieldingTag from './settings/yielding_tags'
import DayoftheWeek from './settings/DayoftheWeek';
import Deal from './settings/Deal';
// import OTAInsights from './OtaInsights';
import OTASetup from './OTASetup';
// import genieIntelligence from './genieIntelligence';
import community from './community';
// import iFrame from './iframe/iframe';
import after365days from './settings/after365days';
import apiLog from './apiLog';
import outboundApiLog from './outboundApiLog';
import reservationApilog from './reservationApilog';
import scheduleReports from './settings/scheduleReports';

import segmentation from './settings/segmentation';

class App extends Component {
    render() {
        return (
            <div className="App">
                {window.Appcues &&
                    <Router onUpdate={()=>window.Appcues.page()} />
                }
                <div>
                        <Route exact path="/client/HotelSetting/:client" component={HotelSetting} />
                        <Route exact path="/client/RoomSetting/:client" component={RoomSetting} />
                        <Route exact path="/client/UserSetting/:client" component={UserSetting} />
                        <Route exact path="/client/PaymentSetting/:client" component={PaymentSetting} />

                        <Route exact path="/client/LeadTimeAdjustments/:client" component={MeanTimeSetting} />
                        {/* <Route exact path="/client/DateSetting/:client" component={DateSetting} /> */}
                        
                        <Route exact path="/client/DailySetting/:client" component={DailySetting} />
                        <Route exact path="/client/SkipSetting/:client" component={SkipSetting} />
                        <Route exact path="/client/DayoftheWeek/:client" component={DayoftheWeek} />

                        <Route exact path="/client/MonthlySetting/:client" component={MonthlySetting} />
                        
                        <Route exact path="/client/PerformanceDashboard/:client" component={PerformanceDashboard} /> 
                        {/* <Route exact path="/client/genieIntelligence/:client" component={genieIntelligence} />  */}
                        <Route exact path="/client/Calendar/:client" component={Calendar} />
                        {/* <Route exact path="/client/OTAInsights/:client" component={OTAInsights} /> */}
                        <Route exact path="/client/RoomTypeRates/:client" component={RoomTypeRates} />
                        <Route exact path="/client/RateSetting/:client" component={RateSetting} />
                        <Route exact path="/client/Connections/:client" component={Connections} />
                        <Route exact path="/client/InternalParams/:client" component={InternalParam} />
                        <Route exact path="/client/Competitors/:client" component={Competitors} />
                        <Route exact path="/client/OTASetup/:client" component={OTASetup} />
                        {/* <Route exact path="/client/contact-us-new/:client" component={ContactUsNew} /> */}
                        <Route exact path="/client/runAlgo/:client" component={runAlgo} />
                        <Route exact path="/client/ChangePassword/:client" component={ChangePassword} />
                        <Route exact path="/client/Features/:client" component={features} />
                        <Route exact path="/client/pricingReport/:client" component={PriceReport} />
                        <Route exact path="/client/deleteProperty/:client" component={DeleteProperty} />

                        <Route exact path="/client/dailySeasonalAdjustments/:client" component={dailySeasonalAdjustments} />
                        <Route exact path="/client/SettingsLog/:client" component={settingsLog} />
                        <Route exact path="/client/UsersLog/:client" component={usersLog} />
                        <Route exact path="/client/apilog/:client" component={apiLog} />
                        <Route exact path="/client/apilog/reservation/:client" component={reservationApilog} />
                        <Route exact path="/client/outbound/:client" component={outboundApiLog} />
                        <Route exact path="/client/TargetOccupancy/:client" component={targetOccupancy} />
                        {/* <Route exact path="/client/QuickSetup/:client" component={QuickSetup} /> */}
                        <Route exact path="/client/YieldingTag/:client" component={YieldingTag} />

                        <Route exact path="/client/Deal/:client" component={Deal} />
                        <Route exact path="/client/community/:client" component={community} />
                        <Route exact path="/client/AfterYearSetting/:client" component={after365days} />
                        
                        <Route exact path="/client/segmentation/:client" component={segmentation} />
                        
                        <Route exact path="/client/scheduleReports/:client" component={scheduleReports} />
                        {/* <Route exact path="/client/iframe/:client" component={iFrame} /> */}
                        <Route component={Notfound} />
                </div>
            </div>
        );
    }
}

export default withNamespaces('translations')(App);
