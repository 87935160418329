import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = {
    restApiLoader:false
}

const updateList = (response, state) => {
    for (var i = 0; i < state.connectionList.length; i++) {
        if (state.connectionList[i].provider === response.provider) {
            state.connectionList[i].primary = response.primary;
        } else {
            state.connectionList[i].primary = false;
        }
    }
    return state.connectionList;
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PMS_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.GET_PMS_SUCCEED:
            return updateObject(state, { loading: false, error: null, connectionList: action.state })
        case actionTypes.CONNECTION_UPDATE_FAILED:
            return updateObject(state, { loadingConnUpdate: false, error: action.error })
        case actionTypes.CONNECTION_UPDATE_START:
            return updateObject(state, { loadingConnUpdate: true, error: null })
        case actionTypes.CONNECTION_UPDATE_SUCCEED:
            return updateObject(state, { loadingConnUpdate: false, error: null, deleted_rooms: action.state.deleted_rooms, deleted_rates: action.state.deleted_rates })
        case actionTypes.DISCONNECT_PMS_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.DISCONNECT_PMS_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.DISCONNECT_PMS_SUCCEED:
            return updateObject(state, { loading: false, error: null, connectionList: updateList(action.state, state) })
        case actionTypes.SEEKOM_PMS_SUCCEED:
            return updateObject(state, { loading: false, error: null, seekom: action.state })
        case actionTypes.PUBLISHER_LIST_SUCCEED:
            return updateObject(state, { loading: false, error: null, publisherList: action.state })
        case actionTypes.REMOVE_DEAD_ROOMS_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.REMOVE_DEAD_ROOMS_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.REMOVE_DEAD_ROOMS_SUCCEED:
            return updateObject(state, { loading: false, error: null, deleted_rooms: undefined })
        case actionTypes.REMOVE_DEAD_RATES_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.REMOVE_DEAD_RATES_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.REMOVE_DEAD_RATES_SUCCEED:
            return updateObject(state, { loading: false, error: null, deleted_rates: undefined, deleted_rooms: undefined })
        case actionTypes.RMS_PROPERTY_LIST:
            return updateObject(state, { loading: false, error: null, propertyList: action.state })
        case actionTypes.RMS_AGENTS_LIST:
            return updateObject(state, { loading: false, error: null, agentList: action.state })
        case actionTypes.RESET_INVENTORY_START:
            return updateObject(state, { restApiLoader: true, error: null })
        case actionTypes.RESET_INVENTORY_SUCCEED:
            return updateObject(state, { restApiLoader: false, error: null })
        case actionTypes.RESET_INVENTORY_FAILED:
            return updateObject(state, { restApiLoader: false, error: action.error })
        case actionTypes.EVVIVO_PROPERTY_LIST:
            return updateObject(state, { loading: false, error: null, evPropertyList: action.state })
        case actionTypes.HLS_PROPERTY_LIST:
                return updateObject(state, { loading: false, error: null, hlsPropertyList: action.state })
        case actionTypes.SABEE_PROPERTY_LIST:
        return updateObject(state, { loading: false, error: null, sabeePropertyList: action.state })
        case actionTypes.FREE_TO_BOOK_PMS_SUCCEED:
                return updateObject(state, { loading: false, error: null, freetobook: action.state })            
        case actionTypes.OAUTH2_PMS_SUCCEED:
            return updateObject(state, { loading: false, error: null, oauth2_data: action.state })
        case actionTypes.MULTIPROPERTY_SUCCEED:
            return updateObject(state, { loading: false, error: null, multiPropertyList: action.state })
        case actionTypes.HOTSOFT_ACCOUNT_LIST:
                return updateObject(state, { loading: false, error: null, hotsoftaccountList: action.state })            
        default:
            return updateObject(state, { loading: false, error: null });
    }
}

export default reducer
