import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ClientListCss from './css/clientListCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../store/actions/index';
import MUIDataTable from "mui-datatables";
import Button from '@material-ui/core/Button';
import Modal from './modal/modal'
import { MuiThemeProvider } from '@material-ui/core/styles';
import { scraperTheme } from '../muiTheme';
import moment from 'moment';
import HeaderCard from "../Client/HeaderCard";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';

const styles = ClientListCss;


class Scraper extends React.Component {

    state = {
        scheduleList: [],
        openModal: false,
        url: "",
        error: false
    };

    componentWillMount() {
        let requestParam = { token: this.props.userSession.token }
        this.props.getScraperList(requestParam);
    }

    componentWillReceiveProps(props) {
        if (props.scraperReducer.scrapList) {
            this.setState({
                count: props.scraperReducer.scrapList.count,
                current: props.scraperReducer.scrapList.current,
                next: props.scraperReducer.scrapList.next,
                previous: props.scraperReducer.scrapList.previous,
                scheduleList: props.scraperReducer.scrapList.results
            })
        }
    }

    handleUrl = () => event => {
        this.setState({
            url: event.target.value
        })
    }

    validate = (str) => {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol (optional)
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '\\.en-gb\\.html$' + // .en-gb.html check
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

        return !!pattern.test(str);

    }


    addScraper = () => {
        if (this.validate(this.state.url)) {
            let reqParams = {
                url: this.state.url,
                token: this.props.userSession.token
            }
            this.props.addScraper(reqParams);
            this.toggleModal()
            this.setState({
                url: "",
                error: false
            })
        } else {
            this.setState({
                error: true
            })
        }
    }

    toggleModal = () => {
        this.setState({
            openModal: !this.state.openModal
        })
    }

    renderStatus = (data) => {
        if (data == 1) {
            return 'Scheduled'
        } else if (data == 2) {
            return 'In Progress'
        } else if (data == 3) {
            return 'Done'
        } else {
            return 'failed'
        }
    }

    renderScrapData = (data) => {
        let jsx = [];
        data.map((val) => {
            jsx.push([
                val.id,
                moment(val.created).format('YYYY-MM-DD HH:mm'),
                val.url,
                this.renderStatus(val.scrape_status)
            ])
            return null;
        })
        return jsx;
    }

    options = () => {
        return {
            responsive: 'scroll',
            print: false,
            download: false,
            viewColumns: false,
            responsive: "scroll",
            selectableRows: false,
            serverSide: true,
            count: this.state.count,
            rowsPerPage: 20,
            filter: false,
            rowsPerPageOptions: false,
            search: false,
            onTableChange: (action, tableState) => {
                if (action === 'changePage') {
                    let page = tableState.page + 1;
                    let requestParams = {
                        token: this.props.userSession.token,
                        url: 'hotel/booking/scraper/list/?page=' + page,
                    };
                    this.props.getScraperList(requestParams)
                }
                if (action === 'search') {
                    let searchText = tableState.searchText ? tableState.searchText : '';
                    let requestParams = {
                        token: this.props.userSession.token,
                        url: 'hotel/booking/scraper/list/?search=' + searchText,
                    };
                    this.props.getScraperList(requestParams)
                }
            },
            textLabels: {
                body: {
                    noMatch: this.props.scraperReducer.loading ?
                        'Loading Records...' :
                        'Sorry, no matching records found.',
                },
            }
        }
    };


    render() {
        const { classes } = this.props;
        const columns = [
            { name: "Id" },
            { name: "Date" },
            { name: "Url" },
            { name: "Status" }
        ];
        return (
            <>
                <HeaderCard>
                    <Grid container>
                        <Grid item >
                            <div className={classes.divStyle} >
                            <Typography variant="h5" className={classes.fontStyle} >
                                New Hotel
                            </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </HeaderCard>
            <Card>
                <CardContent>
                    <div className={classes.btnDiv} >
                        <Button color="primary" variant="contained" onClick={this.toggleModal} className={classes.btnAdd} >Add New</Button>
                    </div>
                    <div>
                        <MuiThemeProvider theme={scraperTheme}>
                            <MUIDataTable
                                title={''}
                                data={this.renderScrapData(this.state.scheduleList)}
                                columns={columns}
                                options={this.options()}
                            />
                        </MuiThemeProvider>
                    </div>
                </CardContent>
                {Modal.addNewUrl(this)}
            </Card >
            </>
        );
    }
}

Scraper.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        userSession: state.sessionReducer,
        scraperReducer: state.scraperReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getScraperList: (request) => { dispatch(actions.getScraperList(request)) },
        addScraper: (request) => { dispatch(actions.addScraper(request)) },
    };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(Scraper);