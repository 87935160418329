import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../index';
import Notify from "../Notify";


const referralDealStart = () => {
    return {
        type: actionTypes.REFERRAL_DEAL_START
    }
}

const referralDealFailed = (error) => {
    return {
        type: actionTypes.REFERRAL_DEAL_FAILED,
        error: error
    }
}

const referralDealSucceed = (data) => {
    return {
        type: actionTypes.REFERRAL_DEAL_SUCCEED,
        state: data
    }
}

const referralDealProgramStart = () => {
    return {
        type: actionTypes.REFERRAL_DEAL_PROGRAM_START
    }
}

const referralDealProgramFailed = (error) => {
    return {
        type: actionTypes.REFERRAL_DEAL_PROGRAM_FAILED,
        error: error
    }
}

const referralDealProgramSucceed = (data) => {
    return {
        type: actionTypes.REFERRAL_DEAL_PROGRAM_SUCCEED,
        state: data
    }
}


export const referralDeal = (request) => {
    return dispatch => {
        dispatch(referralDealStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/hubspot_referral_deal/create/', request.request)
            .then(function (response) {
               dispatch(referralDealSucceed(response.data))
               dispatch(Notify.newUserModal(response.data.message, true));
               setTimeout(() => {
                dispatch(getreferralDealProgramLists(request.token))
               }, 3000);
            })
            .catch(function (error) {
                dispatch(referralDealFailed(error))
                dispatch(Notify.newUserModal("An error occured while invite Friend. ", false));
            });
    }
}

const academyStart = () => {
    return {
        type: actionTypes.ON_ACADEMY_SIGNUP_START
    }
}

const academyFailed = (error) => {
    return {
        type: actionTypes.ON_ACADEMY_SIGNUP_FAILED,
        error: error
    }
}

const academySucceed = (data) => {
    return {
        type: actionTypes.ON_ACADEMY_SIGNUP_SUCCEED,
        state: data
    }
}
export const onAcademySignup = (request) => {
    return dispatch => {
        dispatch(academyStart())
        axios.post(Server.API + 'hotel/academy/create/', request)
            .then(function (response) {
               dispatch(academySucceed(response.data))
               dispatch(Notify.newUserModal("Account Created Successfully", true));
            })
            .catch(function (error) {
                dispatch(academyFailed(error))
            });
    }
}

export const getreferralDealProgramLists = (request) => {
    return dispatch => {
        dispatch(referralDealProgramStart())
        axios.post(Server.API + 'hotel/hubspot_referral_deal/referral_lists/', request)
            .then(function (response) {
                dispatch(referralDealProgramSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(referralDealProgramFailed(error))
            });
    }
}
