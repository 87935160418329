import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import yieldingTagsCss from "./css/yieldingTagsCss";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../../../store/actions/index";
import CustomTooltip from "../../Utils/toolTip";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Modals from "./modal/modal";
import { withNamespaces } from "react-i18next";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { segmentationTheme } from "../../muiTheme";
import MUIDataTable from "mui-datatables";
import { ValidateBaseSettings } from "../../Utils/SettingValidation/Validation";
import DialogContentText from "@material-ui/core/DialogContentText";
import HeaderCard from "../HeaderCard";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";

const styles = yieldingTagsCss;
class Segmentation extends React.Component {
  state = {
    YieldingTagsList: [],
    openEditTag: false,
    segmentationList: [],
    tagIds: [],
    addNewData: {
      name: "",
      tags: [],
    },
    error: [],
    usedKeys: [],
    settings: {},
    buttonDisabled : true,
    autoRunModal:false,
  };

  componentWillMount() {
    this.initPusher();

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
    };
    this.props.getHotelDetail(requestParam);
    this.props.getTagList(requestParam);
    this.props.getPricingSettings(requestParam);
  }

  componentWillReceiveProps(props) {
    if (props.segmentationReducer && props.segmentationReducer.tagList) {
      this.setState({
        tagIds: props.segmentationReducer.tagList.unique_segment_ids,
      });
    }

    if (
      props.priceSettingReducer.priceSetting &&
      props.priceSettingReducer.priceSetting.segmentation
    ) {
      this.setState({
        segmentationList: props.priceSettingReducer.priceSetting.segmentation,
      });
    }
  }

  initPusher = () => {
    if(this.props.commonReducer.initPusher){
      this.props.commonReducer.initPusher.bind('runpricing.message', (evt)=> {
        if (!evt.error) {
          let requestData = {
            msg: 'segments pricing are updated',
            is_success: true,
          }
          this.props.putNotify(requestData);
          this.setState({
            autoRunModal: false,
          })

        }else {
          let requestData = {
            msg: 'Error while Applying the Changes',
            is_success: false,
          }
          this.props.putNotify(requestData);
          this.setState({
            autoRunModal: false,
          })

        }
      })
    }
  }
  
  validateForm = () => {
    let error = [];
    let data = this.state.addNewData;

    if (!data.name) {
      error.push("name");
    }
    if (data.tags.length == 0) {
      error.push("tags");
    }

    this.setState({
      error,
    });

    return error;
  };

  addNewSegmentation = () => {
    let error = this.validateForm();

    if (error.length === 0) {
      let setting = this.props.priceSettingReducer.priceSetting;

      if (!setting.segmentation) {
        setting.segmentation = {};
      }

      let key = this.state.addNewData.key;

      if (!key) {
        key =
          moment().format("YYYYMMDDHHmmss") +
          this.props.hotelAuthReducer.hotelDetails.hotel.id;
      }

      setting.segmentation[key] = {
        segment_tags: this.state.addNewData.tags,
        segments_settings_corrections:
          this.state.addNewData.segments_settings_corrections || false,
        segments_price_estimate:
          this.state.addNewData.segments_price_estimate || false,
        segments_pickupboost:
          this.state.addNewData.segments_pickupboost || false,
        name: this.state.addNewData.name,
      };

      let latestJson = ValidateBaseSettings({ dateSetting: setting });
      let reqParams = {
        settings: latestJson.dateSetting,
        token: this.props.hotelAuthReducer.hotelDetails.token,
      };

      this.props.storePricingSettings(reqParams);
      // let flagReq = {
      //   run_pricing: true,
      //   token: this.props.hotelAuthReducer.hotelDetails.token,
      // };
      // this.props.setRunPriceFlags(flagReq);
      this.toggleModal();
    }
  };

  changeSegmentationDemand = (segmentation, flagKey) => {
    let setting = this.props.priceSettingReducer.priceSetting;

    let key = segmentation.key;
    delete segmentation.key;

    if (!setting.segmentation) {
      setting.segmentation = {};
    }

    let checkBoxValue = !segmentation[flagKey];

    setting.segmentation[key] = {
      ...segmentation,
      [flagKey]: checkBoxValue,
    };
    if (flagKey === "segments_settings_corrections") {
      setting.segmentation[key].segments_pickupboost = checkBoxValue;
    }

    let latestJson = ValidateBaseSettings({ dateSetting: setting });

    this.setState({
      settings: latestJson.dateSetting,
      buttonDisabled:false,
      openEditTag: false,
    })
  };

  updateSegment =  () => {
    let reqParams = {
      new_segmentation_data: this.state.settings.segmentation || this.state.segmentationList,
      token: this.props.hotelAuthReducer.hotelDetails.token,
    };    
    this.props.updateSegmentationData(reqParams, this.autoRunPrice);
    this.setState({
      settings: {},
      buttonDisabled:true
    })
  }
  autoRunPrice = () => {
    this.setState({
      autoRunModal: true,
    })
  }

  toggleModal = (data) => {
    let setting = this.props.priceSettingReducer.priceSetting;
    let usedKeys = [];
    if (setting.segmentation) {
      Object.keys(setting.segmentation).map((row) => {
        if (!data || data.key != row) {
          usedKeys = [...usedKeys, ...setting.segmentation[row].segment_tags];
        }
      });
    }
    this.setState(
      {
        openEditTag: !this.state.openEditTag,
        usedKeys,
        addNewData: data
          ? { ...data, tags: data.segment_tags }
          : {
              name: "",
              tags: [],
            },
      });
  };

  handleAddNew = (name) => (event) => {
    this.setState({
      addNewData: {
        ...this.state.addNewData,
        [name]: event.target.value,
      },
    });
  };

  composeTableData = (tableData) => {
    if (Object.keys(tableData).length > 0) {
      let returnData = [];
      Object.keys(tableData).map((key, index) => {
        let allData = {
          ...tableData[key],
          key: key,
        };
        let data = [
          { ...tableData[key], key },
          tableData[key].name,
          tableData[key].segment_tags.join(", "),
          allData,
          allData,
          allData,
        ];
        returnData.push(data);
        return null;
      });
      return returnData;
    } else {
      return [];
    }
  };

  deleteSegmentation = () => {
    let setting = this.props.priceSettingReducer.priceSetting;

    if (
      this.state.deleteData &&
      setting.segmentation &&
      setting.segmentation[this.state.deleteData.key]
    ) {
      delete setting.segmentation[this.state.deleteData.key];
    }

    let latestJson = ValidateBaseSettings({ dateSetting: setting });
    let reqParams = {
      settings: latestJson.dateSetting,
      token: this.props.hotelAuthReducer.hotelDetails.token,
    };

    this.props.storePricingSettings(reqParams);
    this.setState({
      openDeleteModal: false,
    });
  };

  toggleDeleteModal = (data) => {
    this.setState(
      {
        openDeleteModal: !this.state.openDeleteModal,
        deleteData: data,
      },
      () => {
        this.setState({
          openEditTag: false,
        });
      }
    );
  };

  render() {
    const { classes, t } = this.props;
    const columns = [
      {
        name: t("id"),
        options: {
          display: false,
        },
      },
      {
        name: t("Segment Name"),
        options: {
          sort: false,
          filter: false,
        },
      },
      {
        name: t("Segment Tags"),
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div className={classes.tableValue}>{value}</div>;
          },
        },
      },
      {
        name: (
          <>
            {t("Exclude from Demand")}
            <CustomTooltip
              title=""
              description={
                <>
                  {t(
                    "Exclude from demand calculation: Bookings you receive with these tags will not affect the demand calculation for the days of booking and also for the monthly demand adjustment. This can be used for any bookings that you think are totally unrepresentative of demand for your hotel, e.g. tour operator allocations. "
                  )}
                </>
              }
            />
          </>
        ),
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value.segments_settings_corrections}
                    onChange={(event) =>
                      this.changeSegmentationDemand(
                        value,
                        "segments_settings_corrections"
                      )
                    }
                    color="primary"
                  />
                }
              />
            );
          },
        },
      },
      {
        name: (
          <>
            {t("Exclude from Supply")}
            <CustomTooltip
              title=""
              description={
                <>
                  {t(
                    "Exclude from supply reduction: As an additional measure, bookings you receive with these tags will not count towards the reduction in supply that is used in the optimisation. This effectively reduces your capacity without increasing the price. You can use this, for example, for group bookings on low season days when you know you will not fill up."
                  )}
                </>
              }
            />
          </>
        ),
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const { classes } = this.props;
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value.segments_price_estimate}
                    onChange={(event) =>
                      this.changeSegmentationDemand(
                        value,
                        "segments_price_estimate"
                      )
                    }
                    color="primary"
                  />
                }
              />
            );
          },
        },
      },
      {
        name: t("Actions"),
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const { classes } = this.props;
            return (
              <React.Fragment>
                <Button
                  color="secondary"
                  onClick={() => this.toggleDeleteModal(value)}
                  className={[classes.cellAign, classes.actionIcon]}
                >
                  <Icon>delete</Icon>
                </Button>
              </React.Fragment>
            );
          },
        },
      },
    ];
    const options = {
      responsive: "scroll",
      selectableRows: false,
      search: false,
      print: false,
      download: false,
      viewColumns: false,
      filter: false,
      rowsPerPage: 10,
      onRowClick: (rowData) => {        
        this.toggleModal(rowData[0]);
      },
      textLabels: {
        body: {
          noMatch: this.state.loadData
            ? "Loading Records..."
            : "Sorry, no matching records found.",
        },
      },
    };

    return (
      <>
        <HeaderCard>
          <Grid container>
            <Grid item container>
              <div className={classes.divStyle}>
                <Typography variant="h5" className={classes.fontStyle}>
                  {t("Segmentation")}
                </Typography>
                <DialogContentText align="left" className={classes.helpText}>
                  {t(
                    "RoomPriceGenie's Segmentation feature tells the algorithm to treat different types of bookings in different ways. You can set up your segments here, and then all bookings with the relevant codes will be placed in the correct segment. You can then choose that bookings from these segments are excluded from the demand and/or supply calculations within the algorithm."
                  )}
                </DialogContentText>
              </div>
            </Grid>
          </Grid>
        </HeaderCard>
        <Card>
          <CardContent>
            <div className={classes.addBtnDiv}>
              <Grid container>
                <Grid item >
                <div>   
                    <Button
                      variant="outlined"                        
                      color="primary"
                      className={classes.addUserBtn}
                      disabled={this.state.autoRunModal}
                      onClick={() => this.updateSegment()}
                    >{t("Recalculate Segmentation Bookings")}{this.state.autoRunModal && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}                    
                    </Button>            
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.addUserBtn}
                        onClick={() => this.toggleModal()}
                      >
                        {t("Add New Segment")}
                    </Button>                                                                       
                  </div>
                </Grid>
              </Grid>
            </div>
            <Grid item>
              <Grid sm={12}>
                <div className={classes.userTableDiv}>
                  <MuiThemeProvider theme={segmentationTheme}>
                    <MUIDataTable
                      title=""
                      data={this.composeTableData(this.state.segmentationList)}
                      columns={columns}
                      options={options}
                    />
                  </MuiThemeProvider>
                </div>               
              </Grid>
            </Grid>                        
          </CardContent>
          {this.state.openEditTag && Modals.editSegmentationModal(this)}
          {this.state.openDeleteModal && Modals.deleteSegmentationModal(this)}
          {this.state.autoRunModal && Modals.autoRunPriceSegmentationModal(this)}
        </Card>
      </>
    );
  }
}

Segmentation.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    userSession: state.sessionReducer,
    hotelAuthReducer: state.hotelAuthReducer,
    hotelDetailReducer: state.hotelSettingReducer,
    commonReducer: state.commonReducer,
    priceSettingReducer: state.priceSettingReducer,
    roomList: state.roomListReducer,
    segmentationReducer: state.segmentationReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHotelDetail: (request) => {
      dispatch(actions.getHotelDetail(request));
    },
    getPricingSettings: (request) => {
      dispatch(actions.getPricingSettings(request));
    },
    getTagList: (request) => {
      dispatch(actions.getTagList(request));
    },
    storePricingSettings: (request) => {
      dispatch(actions.storePricingSettings(request));
    },
    updateSegmentationData: (request,callback) => {
      dispatch(actions.updateSegmentationData(request,callback));
    },
    putNotify: (request) => { dispatch(actions.putNotify(request)) },
    // setRunPriceFlags: (request) => {
    //   dispatch(actions.setRunPriceFlags(request));
    // },
  };
};

export default compose(
  withStyles(styles, {
    withTheme: true,
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(withNamespaces("translations")(Segmentation));
