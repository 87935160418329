var style = {
  calenderStyle: {
    marginTop: '30px',
  },
  pricePmsBtn: {
    float: 'left',
    // marginRight: '10px',
    backgroundColor: '#d32f2f',
    color: "#fff",
    '&:hover': {
      backgroundColor: '#9f2424',
    },
     ['@media (max-width: 414px) and (min-width: 360px)']: {
      marginTop:"10px",
      width: "100%",
    }
  },
  currentPriceInput: {
    marginLeft: '10px',
    minWidth: '200px',
    width: '23%'
  },
  currentPriceInputBtn: {
    margin: '0px 0px 0px 4px',
    float: 'right'
  },
  runPriceBtn: {
    float: 'right',
    margin: '10px'
  },
  tableCell: {
    minWidth: '125px',
    padding: '10px'
  },
  cellDate: {
    float: 'right'
  },
  cellDay: {
    float: 'left'
  },
  textField: {
    marginRight: '15px',
    minWidth: '120px',
    width: "45%",
    ['@media (max-width: 414px) and (min-width: 321px)']: {
      minWidth: '100%',
      textAlign: 'left',
      '& button': { width: '100%' }
    }
  },
  table: {
    marginTop: '30px'
  },
  tableOpt: {
    marginTop: '30px'
  },
  tableRow: {
    height: "20px !important"
  },
  editBtn: {
    fontSize: '10px'
  },
  prevBtn: {
    float: 'left',
  },
  nextBtn: {
    float: 'right'
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
  },
  userDiv: {
    display: 'inherit',
    float: 'right'
  },
  plusIcon: {
    cursor: "pointer"
  },
  socketLoader: {
    marginTop: '5px',
    marginBottom: '10px'
  },
  loaderDiv: {
    textAlign: 'left',
    fontSize: '13px',
    marginTop: '-6px',
    color: "#3772FF"
  },
  fileCsv: {
    marginTop: '20px',
  },
  colorPrimary: {
    backgroundColor: '#14cc00',
  },
  barColorPrimary: {
    backgroundColor: '#0a6600',
  },
  pmsTableRow: {
    height: '26px',
  },
  pmsRow: {
    borderBottom: 'solid'
  },
  pmsRowBlankBorder: {
    borderBottom: 'none'
  },
  eventList: {
    height: '20px',
  },
  eventReadMore: {
    cursor: "pointer",
    color: '#3772FF'
  },
  eventTitle: {
    paddingLeft: '0px',
    paddingBottom: '0px'
  },
  pmsTable: {
    maxWidth: '300px'
  },
  pmsContent: {
    display: 'flex'
  },
  eventTable: {
    maxWidth: '640px',
  },
  eventDates: {
    minWidth: "125px",
    padding: '0px'
  },
  reFontsize: {
    fontSize: "30px"
  },
  addBtns: {
    // marginTop: "-10px",
    ['@media (max-width: 414px) and (min-width: 360px)']: {
      // marginTop: "-10px"
    }
  },
  swichBtn: {
    marginTop: "-4px"
  },
  addNewBtn: {
    float: "left",
    marginRight: "16px",
    // marginRight: "-4px",
    ['@media (max-width: 414px) and (min-width: 360px)']: {
      marginTop:"10px",
      width: "100%",
    }
  },
  btnDiv: {
    width: "100%",
    display: "flow-root",
    textAlign: "left",
    marginTop:"20px"
  },
  formControl: {
    width: '46%',
    marginLeft: '2%',
    textAlign: 'left',
    marginRight: '2%',
    minWidth: '240px',
    ['@media (max-width: 540px) and (min-width: 400px)']: {
      minWidth: '255px'
    },
    ['@media (max-width: 399px) and (min-width: 361px)']: {
      minWidth: '200px'
    },
    ['@media (max-width: 360px) and (min-width: 321px)']: {
      minWidth: '200px'
    },
    ['@media (max-width: 320px)']: {
      minWidth: '180px'
    }
  },
  formControlLeft: {
    width: '46%',
    marginLeft: '2%',
    marginRight: '2%',
    minWidth: '240px',
    ['@media (max-width: 540px) and (min-width: 400px)']: {
      minWidth: '255px'
    },
    ['@media (max-width: 399px) and (min-width: 361px)']: {
      minWidth: '200px'
    },
    ['@media (max-width: 360px) and (min-width: 321px)']: {
      minWidth: '200px'
    },
    ['@media (max-width: 320px)']: {
      minWidth: '180px'
    },
    textAlign: 'left',
  },
  switchDiv: {
    padding: '10px 10px'
  },
  logCard: {
    marginTop: 30
  },
  actionIcon: {
    padding: '0',
    cursor: 'pointer',
    minWidth: '30px'
  },
  filledDiv: {
    borderRadius: '10px',
    padding: '5px',
    color: '#fff',
    fontWeight: '500',
    textAlign: "center"
  },
  gridItem1: {
    textAlign: 'left',
    maxWidth: '59%',
    flexBasis: '59%',
    ['@media (max-width: 414px) and (min-width: 321px)']: {
      minWidth: '100%',
      textAlign: 'left',
      paddingLeft: '20px !important'
    }
  },
  gridItem2: {
    textAlign: 'right',
    maxWidth: '20%',
    flexBasis: '20%',
    ['@media (max-width: 414px) and (min-width: 321px)']: {
      minWidth: '100%',
      textAlign: 'left',
      marginBottom: '12px',
      '& button': { width: '100%' }
    }
  },
  gridItem3: {
    textAlign: 'right',
    maxWidth: '21%',
    flexBasis: '21%',
    ['@media (max-width: 414px) and (min-width: 321px)']: {
      minWidth: '100%',
      textAlign: 'left',
      '& button': { width: '100%' }
    }
  },
  FontStyle: {
    textAlign: "left",
    fontWeight: "500"
  },
  divStyle: {
    float: "left", 
    marginTop: "2px"
  }
}
export default style;