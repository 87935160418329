import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import withStyles from '@material-ui/core/styles/withStyles';
import CardMedia from '@material-ui/core/CardMedia';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { AcademyMoodleTheme } from './muiTheme';
import { connect } from "react-redux";
import { compose } from 'redux'
import * as actions from '../store/actions/index';
import loginCss from './css/login'
import Grid from '@material-ui/core/Grid';
import BackgroundImg from "./loginBG";
import Server from '../config/server'
import axios from 'axios';
const styles = loginCss;


class AcademyMoodle extends React.Component {

  state = {
    isValidated: this.props.sessionReducer.isValidated
  }

  componentDidMount() {

    let url_string = window.location.href;
    let url = new URL(url_string);

    const client_id = url.searchParams.get('client_id');
    const redirect_uri = url.searchParams.get('redirect_uri');

    axios.post(Server.API + 'account/oauth2/validate/', { client_id, redirect_uri })
      .then((resp) => {
        this.setState({
          isValidated: resp.data.success
        })
      })
  }

  onAccept = () => {
    this.props.moodleAuthAuthorize(this.props.userSession)
  }

  onCancel = () => {
    window.location.href = "/";
  }


  render() {
    const { classes } = this.props;
    const theme = AcademyMoodleTheme;

    let url_string = window.location.href;
    let url = new URL(url_string);
    let code = url.searchParams.get("code");

    return (
      <MuiThemeProvider theme={theme}>
        <div > <CardMedia
          className={classes.siteLogo}
          image="/img/roompricegenie_logo1.png"
          title="RoomPriceGenie"
        />
          <BackgroundImg />
          <main className={classes.mainAcademy}>
            <Grid container>
              <Grid sm={12} md={12} style={{ marginLeft: "-2px" }}>
                <form className={classes.form} style={{ padding: "40px 42px 50px" }}>
                  <FormControl margin="normal" required fullWidth>
                    <span className={classes.titleAcademy}>Sign up with your RoomPriceGenie Account</span>
                    <CardMedia
                      className={classes.siteLogoAcademyMoodle}
                      image="/img/university_genie_text.svg"
                      title="RoomPriceGenie"
                    />
                  </FormControl>
                  <span className={classes.titleAcademytext}>RoomPriceGenie Academy gains access to: <br /> contact details </span>
                  <br />
                  <br />

                  <Button
                    type="button"
                    disabled={!this.state.isValidated}
                    variant="contained"
                    color="primary"
                    className={classes.AcceptContinueBtn}
                    onClick={() => this.onAccept()}
                  >Accept & Continue</Button>
                  <Button
                    type="button"
                    variant="outlined"
                    color="primary"
                    className={classes.cancelBtnMoodle}
                    onClick={() => this.onCancel()}
                  >Cancel</Button>

                </form>

              </Grid>
            </Grid>
          </main>
        </div>
        <img src={"/img/skyline.png"} height="100%" className={classes.skyline} />


      </MuiThemeProvider >
    );
  }
}

AcademyMoodle.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  container: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    user: state.authReducer.UserData,
    accountReducer: state.accountReducer,
    sessionReducer: state.sessionReducer,
    userSession: state.sessionReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogin: (request) => { dispatch(actions.onLogin(request)) },
    recoverPassword: (request) => { dispatch(actions.recoverPassword(request)) },
    bookinAuth: (request) => { dispatch(actions.bookinAuth(request)) },
    moodleAuthAuthorize: (request) => { dispatch(actions.moodleAuthAuthorize(request)) }
  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(AcademyMoodle);
