import React from 'react';
import { connect } from "react-redux";
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import ClientListCss from '../css/clientListCss';
import HeaderCard from '../../Client/HeaderCard';
import SupportCriticalTicket from './SupportCriticalTicket';
import SupportOtherTicket from './SupportOtherTicket';
import { Card, CardContent, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import DevCriticalTicket from './DevCriticalTicket';
const styles = ClientListCss;

class ErrorTickets extends React.Component {
    state = {                       
        tabValue:1,
    };

    changeHendeTab = (val) => {    
        this.setState({
            tabValue:val
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <>
            <HeaderCard>                
                <Grid container>
                    <Grid item >
                        <div className={classes.divStyle} >
                        <Typography variant="h5" className={classes.fontStyle} >
                            Error List
                        </Typography>
                        </div>
                    </Grid> 
                    <Grid container>
                        <Grid item md={12} xs={12}>
                            <Tabs
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="off"
                                TabIndicatorProps={{ style: { background: "#0073ea" } }}
                                value={this.state.tabValue}
                                className={classes.TabsView}   
                            >
                                <Tab onClick={() => this.changeHendeTab(1)} label={"Support Critical Ticket"} value={1} />
                                <Tab onClick={() => this.changeHendeTab(2)} label={"Support Other Ticket"} value={2} />
                                <Tab onClick={() => this.changeHendeTab(3)} label={"Dev Critical Ticket"} value={3} />
                            </Tabs>
                        </Grid>
                    </Grid>                   
                </Grid>                
            </HeaderCard>
            <Grid container>
                <Grid item md={12} xs={12}>
                    <Card>
                        <CardContent>
                            { this.state.tabValue == 1 &&
                                <SupportCriticalTicket />
                            }
                            { this.state.tabValue == 2 &&
                                <SupportOtherTicket />
                            }
                            { this.state.tabValue == 3 &&
                                <DevCriticalTicket />
                            }        
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        userSession: state.sessionReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
    };
};
export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(ErrorTickets);
