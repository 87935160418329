const styles = {
	body:{
		background:'#F6EFE8'
	},
	card: {
		borderRadius:'19px !important',
		marginTop:'80px',
		float:'right',
		width:'30rem'
	},
	academyText:{
		marginTop:'80px',
		float:'left',
		padding:'30px'
	},
	siteLogo: {
        backgroundSize: "377px 72px",
        padding: "10px",
        height: '70px',
        zIndex: '1',
        margin: 'auto',
        
    },
	sccessBar: {
        padding: "0px 20px 0px 20px",
        backgroundColor: '#43a047',
    },
	warningBar: {
        padding: "0px 20px 0px 20px",
        backgroundColor: '#d32f2f'
    },
	warningIcon: {
        margin: "-9px 5px -7px -5px",
    },
	closeButtonPrice: {
		position:'absolute',
		right:'0',
		['@media (max-width: 540px)']: {
		  marginTop: "-88px",
		}
	  },
}
export default styles;