import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import Notify from '../Notify';

const onEventSucceed = (data) => {
    return {
        type: actionTypes.MAIL_SETTINGS,
        state: data
    }
}

export const getEmailSettingsList = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'account/email-settings/')
            .then(function (response) {
                dispatch(onEventSucceed(response.data))
            });
    }
}

export const updateEmailSettingsList = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'account/email-settings/', request)
            .then(function (response) {
                dispatch(onEventSucceed(response.data))
                dispatch(Notify.newUserModal("Settings Update Successfully", true))
            });
    }
}