import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../index';


const onScrapProgramStart = () => {
    return {
        type: actionTypes.GET_SCRAP_START,
        error: false
    }
}

const onScrapProgramFailed = (error) => {
    return {
        type: actionTypes.GET_SCRAP_FAILED,
        error: error
    }
}

const onScrapProgramSucceed = (data) => {
    return {
        type: actionTypes.GET_SCRAP_SUCCEED,
        state: data
    }
}

export const getScrapProgram = (request) => {
    return dispatch => {
        dispatch(onScrapProgramStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'amalgamation/scrape-program/list/')
            .then(function (response) {
                dispatch(onScrapProgramSucceed(response.data.results))
            })
            .catch(function (error) {
                dispatch(onScrapProgramFailed(error))
            });
    }
}

export const updateScrapProgram = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.patch(Server.API + 'amalgamation/scrape-program/update/' + request.id + '/', request)
            .then(function (response) {
                dispatch(actions.getScrapProgram(request))
            })
            .catch(function (error) {
                dispatch(onScrapProgramFailed(error))
            });
    }
}

export const deleteScrapProgram = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.delete(Server.API + 'amalgamation/scrape-program/delete/' + request.id + '/')
            .then(function (response) {
                dispatch(actions.getScrapProgram(request))
            })
            .catch(function (error) {
                dispatch(onScrapProgramFailed(error))
            });
    }
}

export const createScrapProgram = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'amalgamation/scrape-program/register/', request)
            .then(function (response) {
                dispatch(actions.getScrapProgram(request))
            })
            .catch(function (error) {
                dispatch(onScrapProgramFailed(error))
            });
    }
}