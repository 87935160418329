import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import Notify from '../Notify';

export const getScrapCompetitor = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/competitor/scrape/')
            .then(function (response) {
                dispatch(Notify.newUserModal("Scrape has been Started", true))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Scrape Failed", false))
            });
    }
}