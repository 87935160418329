import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Icon } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import moment from 'moment';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CustomTooltip from '../../Utils/toolTip';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import HierarchyText from '../../Utils/hierarchyText';
import { MuiThemeProvider} from '@material-ui/core/styles';
import { CalendardateTheme,  CalendargetMuiTheme,  CalendargetMuiTheme2, CalendarbuttonTheme,  CalendarTheme, CalendartooltipTheme, priceModalTheme, snackBarTheme} from '../../muiTheme';
import MUIDataTable from "mui-datatables";
import ReactJson from 'react-json-view';
import ListItemText from '@material-ui/core/ListItemText';
import MonthlyAdjustment from './monthlyAdjustment';
import WeeklyAdjustment from './weeklyAdjustment';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import { Bar } from 'react-chartjs-2';
import FormGroup from "@material-ui/core/FormGroup";
import Hidden from '@material-ui/core/Hidden';
import { Card } from '@material-ui/core';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import enLocale from "date-fns/locale/en-US";
import classNames from 'classnames';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';

export default {
    PriceModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        let roomData = {};
        let min = 0;
        let max = 0;
        let adjVsRef = 0;
        let avg_price = 0;
        let market_fector_per=0;
        let market_fector=0;
        let occupancy_fector_per=0;
        let occupancy_factor=0;
        let adjustment=0;
        let adjustment_per=0;
        let suggestedPriceWithoutRestriction='n.a.';
        let setting = modalData.state.dateSetting;
        
        if (modalData.state.selectedRoom) {
            roomData = modalData.props.roomList.roomList.filter(val => val.id == modalData.state.selectedRoom)[0];
            avg_price = setting.default[modalData.state.selectedRoom].avg_price;
            adjVsRef = setting.default[modalData.state.selectedRoom].adjustment_to_reference_room;
        }

        let is_old = moment.utc(modalData.props.hotelAuthReducer.utcTime).tz(modalData.props.hotelAuthReducer.hotelDetails.hotel.timezone).isAfter(modalData.state.modalData.date, 'day');
        if(modalData.state.modalData){
            let closed_room=modalData.state.modalData.closed_room;
            if(modalData.state.modalData.weighted_average_price != null && modalData.state.modalData.weighted_average_price ){
                market_fector_per=Math.round(((modalData.state.modalData.weighted_average_price / avg_price )-1)*100);
                market_fector=modalData.state.modalData.weighted_average_price - avg_price;
                occupancy_fector_per=Math.round(((modalData.state.modalData.suggested_price_without_restriction/modalData.state.modalData.weighted_average_price)-1)*100);
                occupancy_factor=(modalData.state.modalData.suggested_price_without_restriction-modalData.state.modalData.weighted_average_price);
            }else{
                market_fector_per=Math.round(((modalData.state.modalData.weighted_average_price_with_pubp / avg_price )-1)*100);
                market_fector=modalData.state.modalData.weighted_average_price_with_pubp - avg_price;
                occupancy_fector_per=Math.round(((modalData.state.modalData.suggested_price_without_restriction/modalData.state.modalData.weighted_average_price_with_pubp)-1)*100);
                occupancy_factor=(modalData.state.modalData.suggested_price_without_restriction-modalData.state.modalData.weighted_average_price_with_pubp);
            }
            adjustment_per=Math.round(((modalData.state.modalData.suggested_price/modalData.state.modalData.suggested_price_without_restriction)-1)*100);
            adjustment=(modalData.state.modalData.suggested_price-modalData.state.modalData.suggested_price_without_restriction);

            suggestedPriceWithoutRestriction= (modalData.state.modalData.suggested_price_without_restriction && modalData.state.modalData?.no_market_data != true ) ? modalData.state.currency.symbol +modalData.state.modalData.suggested_price_without_restriction : 'n.a.';
        }
        const columns = [
            {
                name: t('Hotel Name'),
                options: {
                    filter: false,
                    sort: true,
                    sortDirection: 'asc',
                }
            },
            {
                name: t('Best Flex'),
                options: {
                    filter: false,
                    sort: false,

                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <div>{isNaN(value) ? value : (modalData.state.currency.symbol + value)}</div>
                    }
                }
            },
            {
                name: t('Lowest Rate'),
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <div>{isNaN(value) ? value : (modalData.state.currency.symbol + value)}</div>
                    }
                }
            },
            {
                name: t(`${modalData.state.room_apartment_space_name} Name`),
            },
        ];

        let data = []
        let maidanFlexPrice = "n.a.";
        let maidanCheapPrice = "n.a.";
        let maidanFlexPricePer = "n.a.";
        let maidanCheapPricePer = "n.a.";

        if (modalData.state.competitorData) {
            Object.keys(modalData.state.competitorData).map(key => {
                if (!isNaN(key)) {
                    let comData = modalData.state.competitorData[key]
                    let comp = modalData.state.selectedCompetitors.filter(row => row.competitor_id == key)[0];

                    if (comp) {
                        data.push([
                            comp.name,
                            (comData.flexible ? (comData.flexible[modalData.state.selectedMaxOccupancy] ? (comData.flexible[modalData.state.selectedMaxOccupancy][modalData.state.selectedRoomType] ? comData.flexible[modalData.state.selectedMaxOccupancy][modalData.state.selectedRoomType].rate : "n.a.") : "n.a.") : "n.a."),
                            (comData.cheapest ? (comData.cheapest[modalData.state.selectedMaxOccupancy] ? (comData.cheapest[modalData.state.selectedMaxOccupancy][modalData.state.selectedRoomType] ? comData.cheapest[modalData.state.selectedMaxOccupancy][modalData.state.selectedRoomType].rate : "n.a.") : "n.a.") : "n.a."),
                            (comData.cheapest ? (comData.cheapest[modalData.state.selectedMaxOccupancy] ? (comData.cheapest[modalData.state.selectedMaxOccupancy][modalData.state.selectedRoomType] ? comData.cheapest[modalData.state.selectedMaxOccupancy][modalData.state.selectedRoomType].roomname : "n.a.") : "n.a.") : "n.a."),
                        ])
                    }
                } else if (key == "median_price") {
                    let comData = modalData.state.competitorData[key]
                    maidanFlexPrice = (comData.flexible ? (comData.flexible[modalData.state.selectedMaxOccupancy] ? (comData.flexible[modalData.state.selectedMaxOccupancy][modalData.state.selectedRoomType] ? comData.flexible[modalData.state.selectedMaxOccupancy][modalData.state.selectedRoomType] : "n.a.") : "n.a.") : "n.a.");
                    maidanCheapPrice = (comData.cheapest ? (comData.cheapest[modalData.state.selectedMaxOccupancy] ? (comData.cheapest[modalData.state.selectedMaxOccupancy][modalData.state.selectedRoomType] ? comData.cheapest[modalData.state.selectedMaxOccupancy][modalData.state.selectedRoomType] : "n.a.") : "n.a.") : "n.a.");
                    if (maidanFlexPrice != "n.a." && modalData.state.modalData.price) {
                        maidanFlexPricePer = Math.round(((maidanFlexPrice / modalData.state.modalData.price) - 1) * 100)
                    }

                    if (maidanCheapPrice != "n.a." && modalData.state.modalData.price) {
                        maidanCheapPricePer = Math.round(((maidanCheapPrice / modalData.state.modalData.price) - 1) * 100)
                    }

                }

            })
        }
        const options = {
            sort: true,
            filter: false,
            print: false,
            toolbar: false,
            download: false,
            responsive: false,
            viewColumns: false,
            selectableRows: false,
            serverSide: false,
            rowsPerPageOptions: false,
            pagination: false,
            search: false
        }

        let compSelected = ""
        if (modalData.state.competitor) {
            let row = modalData.state.selectedCompetitors.filter(val => modalData.state.competitor == val.competitor_id)
            if (row.length > 0) {
                compSelected = row[0].name
            }
        } else if (modalData.state.competitor == 0) {
            compSelected = "All Competitors"
        }

        let dataRooms = []
        modalData.state.roomRemainingData.map((rooms) => {
        
            dataRooms.push([
                rooms.name,
                rooms.inventory != null ? rooms.inventory : "-",
                (modalData.state.tempCloseRooms &&  modalData.state.tempCloseRooms[rooms.id]) ? modalData.state.tempCloseRooms[rooms.id] : 0,
                rooms.defaultOccupancy,
                rooms.price ? (modalData.state.currency.symbol + new Intl.NumberFormat('ja-JP').format(rooms.price)) : "-",
            ])
        })

        const columnsRooms = [
            {
                name: t(`${modalData.state.room_apartment_space_name} Name`),
                options: {
                    filter: true,
                    // sortDirection: 'desc'
                }
            },
            {
                name: t(`${modalData.state.room_apartment_space_name}s Left`),
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <div style={{ float: 'right' }}>{value}</div>
                    }
                }
            },
            
            {
                name: t(`Closed ${modalData.state.room_apartment_space_name}s`),
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <div style={{ float: 'right' }}>{value}</div>
                    }
                }
            },
            {
                name: t('Default Occupancy'),
                options: {
                    filter: false,
                    sort: true,
                    display: modalData.state.pmsProviderList.includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) ? true:false ,    
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <div style={{ float: 'right' }}>{value}</div>
                    }
                }
            },
            {   

                name: modalData.state.featuresEnabled.includes(13) ? t('Recommended BAR Level/Fix Price') :t('Recommended/Fix Price'),
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <div style={{ float: 'right' }}>{value}</div>
                    }
                }
            },
        ];

        const optionsRooms = {
            sort: true,
            toolbar: false,
            filter: false,
            print: false,
            download: false,
            viewColumns: false,
            responsive: "scroll",
            selectableRows: false,
            serverSide: false,
            rowsPerPage: 20,
            rowsPerPageOptions: false,
            pagination: false,
            search: false,
        }

        let tagName = [];
        let room_types_tag = modalData.props.priceSettingReducer.priceSetting.room_type_tags;
        if (room_types_tag !== undefined) {
            Object.keys(room_types_tag).map((key) => {
                if (room_types_tag[key].room_types.includes(modalData.state.selectedRoom)) {
                    tagName.push({
                        tag_name: room_types_tag[key].tag_name,
                        speed: room_types_tag[key].speed,
                        adj: modalData.state.modalData.yield_tag_adjustment ? modalData.state.modalData.yield_tag_adjustment[key] : ""
                    })
                }
            })
        }

        // Capitalize=(str)=>{
        //     return str.charAt(0).toUpperCase() + str.slice(1);
        // }

        // let settingJson = modalData.state.dateSetting;
        let boostPrice;
        let pickup_boost_prices = "";
        let pickupWeekDay = "";
        if(modalData.state.boostPrice && modalData.state.boostPrice[modalData.state.modalData.date] && modalData.state.boostPrice[modalData.state.modalData.date]){
            boostPrice = modalData.state.boostPrice[modalData.state.modalData.date].pickup_boost_price;
            pickup_boost_prices = modalData.state.boostPrice[modalData.state.modalData.date].arguments.roomsleft_change_vs_yesterday;
            pickupWeekDay = modalData.state.boostPrice[modalData.state.modalData.date].arguments.roomsleft_change_vs_7daysago;
        }
        let preferred_date= (modalData.props.hotelDetail && modalData.props.hotelDetail.prefered_date_format) ? modalData.props.hotelDetail.prefered_date_format.replace(', hh:mm A',''):'DD/MM/YYYY';
        
        let notes_date_format='dd/MM/yyyy';
        if(modalData.props.hotelDetail && modalData.props.hotelDetail.prefered_date_format){
            notes_date_format= modalData.props.hotelDetail.prefered_date_format.replace(', hh:mm A','');
            if(notes_date_format == 'MM/DD/YYYY'){
                notes_date_format='MM/dd/yyyy';
            }else{
                notes_date_format='dd/MM/yyyy';
            }   
        }
        let after365 = moment.utc(modalData.props.hotelAuthReducer.utcTime).tz(modalData.props.hotelAuthReducer.hotelDetails.hotel.timezone).add(365, 'day');
        let isafter365 = moment(modalData.state.modalData.date).isAfter(after365);
        let revenueValue=modalData.state.kpi_daily_total ? modalData.state.kpi_daily_total['REVENUE'][modalData.state.modalData.date] :'0'
        let adrValue=modalData.state.kpi_daily_total ? modalData.state.kpi_daily_total['ADR'][modalData.state.modalData.date] :'0'
        const theme = priceModalTheme;
        return (
            <SwipeableDrawer
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="right"
                open={modalData.state.PriceModalopen}
                onClose={modalData.handleClose}
                PaperProps={{ style: { maxWidth: "900px", padding: "10px 25px 10px 25px", overflow: "hidden" } }}
            >
                <div style={{padding: "10px 25px"}}>
                    <div className={classes.flexResponsive} style={{ float: "left", marginTop: '10px', marginBottom: '5px',maxWidth:'85%' }}>
                        <Typography variant="h5" style={{marginBottom:'12px',fontWeight: "500", width: "223px", fontSize: '1.25rem',marginRight:"10px" }}>
                            <span>{modalData.state.modalData ? t(moment(modalData.state.modalData.date).format('ddd')):""}{modalData.state.modalData ? moment(modalData.state.modalData.date).format(', '+preferred_date):""} </span>
                            <span >
                                <Button style={{ minWidth: "0px", float: "right", padding: "0px 4px 2px 2px" }} className={classes.closeButton} onClick={() => modalData.handleClickOpen({ start: moment(modalData.state.modalData.date).add(1, 'day').format('dddd, D MMMM YYYY') }, modalData.state.selectedRoom, true)}><Icon>navigate_next</Icon></Button>
                                <Button style={{ minWidth: "0px", float: "right", padding: "0px 4px 2px 2px" }} className={classes.closeButton} onClick={() => modalData.handleClickOpen({ start: moment(modalData.state.modalData.date).subtract(1, 'day').format('dddd, D MMMM YYYY') }, modalData.state.selectedRoom, true)}><Icon>navigate_before</Icon></Button>
                            </span>
                        </Typography>

                        {/* {modalData.state.popupView=='OTA' ? 
                                  <span style={{ color: "#A0A2A6", fontSize: "18px", fontWeight: "400", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{modalData.state.popupRoomData ? modalData.state.popupRoomData.name : ""}</span>: " "
                                } */}

                        {modalData.state.popupView == 'OTA' ?
                            <span style={{ maxWidth:'58%',width:"inherit",color: "#000000de",position:"relative",marginTop:"-2px", fontSize: '1.25rem' , fontWeight: "500", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }} className={classes.roomTypeName}>{modalData.state.popupRoomData ? modalData.state.popupRoomData.name : ""}, {modalData.state.selectedRoomType.charAt(0).toUpperCase() + modalData.state.selectedRoomType.slice(1)}, {modalData.state.selectedMaxOccupancy} Guest, {compSelected}</span>
                            :
                            <span style={{ maxWidth:'58%',width:"inherit",color: "#000000de",marginTop:"-2px",  fontSize: '1.25rem' , fontWeight: "500", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{modalData.state.popupRoomData ? modalData.state.popupRoomData.name : ""}</span>
                        }
                    </div>
                    {/* {modalData.state.isOTA &&
                        <div>
                            <div style={{ float: (!modalData.state.weather && modalData.state.weather.icon_code) ? "left" : "right", marginTop: '13px', paddingRight: '40px' }}>
                                <span className={classes.viewbuttons}>View</span>
                                <Button variant="outlined" className={classes.newTabBtnFirst} onClick={() => modalData.togglePopupView("OTA")} style={modalData.state.popupView == "OTA" ? { background: "#00000014", fontWeight: "400" } : { fontWeight: "400" }}>{t("OTA Insight")}</Button>
                                <Button variant="outlined" className={classes.newTabBtnLast} onClick={() => modalData.togglePopupView("price")} style={modalData.state.popupView == "price" ? { background: "#00000014", fontWeight: "400" } : { fontWeight: "400" }}>{t("RoomPriceGenie")}</Button>
                            </div>
                        </div>
                    } */}

                    


                    {/* <div style={{ marginTop: "2px", float: "right", margin: modalData.state.selectedRateType ? "-17px -16px 0px 0px" : "-17px -16px 0px 0px" }}> */}
                    <IconButton aria-label="Close" className={classes.closeButtonPrice} onClick={modalData.handleClose} >
                        <CloseIcon />
                    </IconButton>
                    {/* </div> */}
                </div>

                <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="on"
                    value={modalData.state.modalTab}
                    onChange={modalData.handleNewTabs}
                    style={{ margin: modalData.state.popupView != 'OTA' ? "10px 0px" : '21px 0 10px 0', float: "right", borderBottom: '1px solid #f0f0f0' }}
                >
                    <Tab label={t("Prices")} value={1} />
                    <Tab label={t("Edit Prices")} value={2} />
                    <Tab label={t(`${modalData.state.room_apartment_space_name} Types`)} value={3} />
                    <Tab label={t("Pricing Strategy")} value={4} />
                    {modalData.state.featuresEnabled.includes(16) &&
                        <Tab label={t("OTA Insight")} value={5} />
                    }
                </Tabs>

                <Grid container style={{ overflow: "auto", marginBottom: '50px',padding:"10px 25px" }}>
                    {modalData.state.modalTab == 1 &&
                        <Grid container>
                            <Grid item sm={12} md={5}>
                                <div className={classes.priceTableDiv} style={{ paddingLeft: "0px", marginTop: '10px' }}>
                                    {/* <Typography variant={"h6"} style={{ marginLeft: "0px" }}>{t("How it's Calculated")}</Typography> */}
                                    <div className={classes.priceTableDiv2}>
                                        <MuiThemeProvider theme={CalendargetMuiTheme}>
                                            <Table className={classes.pmsTable}>
                                                <TableHead>
                                                    <TableRow className={classes.pmsTableRow}>
                                                        <TableCell align="left" className={classes.pmsNormalRow}><Typography variant="subtitle1">{t("Base Price")}</Typography></TableCell>
                                                        <TableCell align="right" className={classes.pmsNormalRow} style={{ display: "flex" }}>
                                                            <Typography variant="subtitle1">
                                                                {modalData.state.modalData.price !== undefined ?
                                                                    <>
                                                                        {modalData.state.currency.symbol}
                                                                        {avg_price ? new Intl.NumberFormat('ja-JP').format(Math.abs(parseFloat( avg_price.toFixed(0)))): 0}
                                                                    </>
                                                                    :
                                                                    "n.a."
                                                                }
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className={classes.pmsTableRow}>
                                                        <TableCell align="left" className={classes.pmsNormalRow}><Typography variant="subtitle1">{isafter365 ? t("Month/Weekday") : t("Market Factor")}</Typography></TableCell>
                                                        <TableCell align="right" className={classes.pmsNormalRow} style={{ display: "flex" }}>
                                                            <Typography variant="subtitle1">
                                                                {(modalData.state.modalData.price !== undefined && !modalData.state.modalData.no_market_data) ?
                                                                    <>
                                                                        {market_fector ? (parseFloat(market_fector.toFixed(0)) > 0 ? "+" : "-") : ''}
                                                                        {modalData.state.currency.symbol}
                                                                        {/* {market_fector ? Math.abs(parseFloat(market_fector.toFixed(0))) : 0} */}
                                                                        {market_fector ? new Intl.NumberFormat('ja-JP').format(Math.abs(parseFloat(market_fector.toFixed(0)))) : 0}
                                                                    </>
                                                                    :
                                                                    "n.a."
                                                                }
                                                            </Typography>
                                                            <Typography variant="subtitle1" style={market_fector_per ? ((market_fector_per) > 0 ? { color: "#135138", background: "#BBF2DD", fontWeight: "500", padding: "0px 8px", borderRadius: "5px", marginRight: "3px" } : { color: "#890921", background: "#FAC6D1", fontWeight: "500", padding: "0px 8px", borderRadius: "5px", marginRight: "3px" }) : {}}>

                                                                {(modalData.state.modalData.price !== undefined && !modalData.state.modalData.no_market_data) ?
                                                                    <>
                                                                        {market_fector_per ? ((market_fector_per) > 0 ? "+" : "") + market_fector_per + '% ' : ''}
                                                                    </>
                                                                    :
                                                                    "n.a."
                                                                }
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className={classes.pmsTableRow}>
                                                        <TableCell align="left" className={classes.pmsNormalRow}><Typography variant="subtitle1">{t("Occupancy/Pickup Factor")}</Typography></TableCell>
                                                        <TableCell align="right" className={classes.pmsNormalRow} style={{ display: "flex" }}>
                                                            <Typography variant="subtitle1" >
                                                                {(modalData.state.modalData.price !== undefined && !modalData.state.modalData.no_market_data) ?
                                                                    <>
                                                                        {occupancy_factor ? (parseFloat(occupancy_factor.toFixed(0)) > 0 ? "+" : "-") : ''}
                                                                        {modalData.state.currency.symbol}
                                                                        {/* {occupancy_factor ? Math.abs(parseFloat(occupancy_factor.toFixed(0))) : 0} */}
                                                                        {occupancy_factor ? new Intl.NumberFormat('ja-JP').format(Math.abs(parseFloat(occupancy_factor.toFixed(0)))) : 0}
                                                                    </>
                                                                    :
                                                                    "n.a."
                                                                }
                                                            </Typography>
                                                            <Typography variant="subtitle1" style={occupancy_fector_per ? ((occupancy_fector_per) >= 0 ? { color: "#135138", background: "#BBF2DD", fontWeight: "500", padding: "0px 8px", borderRadius: "5px", marginRight: "3px" } : { color: "#890921", background: "#FAC6D1", fontWeight: "500", padding: "0px 8px", borderRadius: "5px", marginRight: "3px" }) : {}} >
                                                                {(modalData.state.modalData.price !== undefined && !modalData.state.modalData.no_market_data) ?
                                                                    <>
                                                                        {occupancy_fector_per ? ((occupancy_fector_per) > 0 ? "+" : "") + occupancy_fector_per + '% ' : ''}
                                                                    </>
                                                                    :
                                                                    "n.a."
                                                                }
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow className={classes.pmsTableRow}>
                                                        <TableCell align="left" className={classes.pmsRowBlankBorder} style={{ borderRadius: "10px 0px 0px 10px", paddingBottom: '25px' }}>
                                                            <Typography variant="subtitle1" style={{ color: (modalData.state.calLoader ? "#9d9d9d" : "#000") }}>

                                                                {t("Adjustments")}
                                                                <Icon onClick={() => modalData.changePricingTab()} className={classes.adjustmentIconHover} style={{ marginBottom: '-7px', cursor: 'pointer', color: '#3772ff' }}>more_horiz</Icon>



                                                                {/* <Button disabled={modalData.state.calLoader} variant={"contained"} color={"primary"} onClick={() => modalData.togglePriceDetailSubModal()} style={{ height: "20px", padding: "0px 7px", fontSize: "12px", marginLeft: "6px" }}>Show All</Button> */}
                                                                {/* <Icon onClick={() => modalData.togglePriceDetailSubModal()} style={{ marginBottom: '-7px' }}>expand_more</Icon> */}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="right" className={classes.pmsRowBlankBorder} style={{ borderRadius: "0px 10px 10px 0px", display: "flex", paddingBottom: '25px' }}>


                                                            <Typography variant="subtitle1" >
                                                                {(modalData.state.modalData.price !== undefined && !modalData.state.modalData.no_market_data) ?
                                                                    <>
                                                                        {parseFloat(adjustment) ? (parseFloat(adjustment.toFixed(0)) > 0 ? "+" : "-") : ''}
                                                                        {modalData.state.currency.symbol}
                                                                        {/* {adjustment ? Math.abs(parseFloat(adjustment.toFixed(0))) : 0} */}
                                                                        {adjustment ? new Intl.NumberFormat('ja-JP').format(Math.abs(parseFloat(adjustment.toFixed(0)))) : 0}
                                                                    </>
                                                                    :
                                                                    "n.a."
                                                                }

                                                            </Typography>
                                                            <Typography variant="subtitle1" style={adjustment ? ((adjustment) >= 0 ? { color: "#135138", background: "#BBF2DD", fontWeight: "500", padding: "0px 8px", borderRadius: "5px", marginRight: "3px" } : { color: "#890921", background: "#FAC6D1", fontWeight: "500", padding: "0px 8px", borderRadius: "5px", marginRight: "3px" }) : { color: "#135138", background: "#BBF2DD", fontWeight: "500", padding: "0px 8px", borderRadius: "5px", marginRight: "3px" }}>
                                                                {(modalData.state.modalData.price !== undefined && !modalData.state.modalData.no_market_data) ?
                                                                    <>
                                                                        {parseFloat(adjustment) ? (parseFloat(adjustment.toFixed(0)) > 0 ? "+" : "-") : ''}
                                                                        {adjustment_per ? Math.abs(adjustment_per) : '0'}%
                                                                </>
                                                                    :
                                                                    'n.a.'
                                                                }

                                                            </Typography>

                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className={classes.pmsTableRow} style={{ borderBottom: '1px solid #a0a2a663', borderTop: '1px solid #a0a2a663' }}>
                                                        <TableCell align="left" className={classes.pmsRowBlankBorder} >
                                                            <Typography variant="h6" style={{ marginLeft: '0px', marginTop: '0px', marginBottom: '0px', fontWeight: "500", color: (modalData.state.calLoader ? "#9d9d9d" : "#000") }}>
                                                                { modalData.state.featuresEnabled.includes(13) ?t("Recommended BAR Level"):t("Recommended Price")}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="right" className={classes.pmsRowBlankBorder} >
                                                            <Typography variant="h6" style={{ marginRight: '0px', marginBottom: '9px', margin: '5px 0px', fontWeight: "500", color: (modalData.state.calLoader ? "#9d9d9d" : "#000") }}>
                                                                {modalData.state.modalData.price !== undefined ?
                                                                    <>
                                                                        {modalData.state.currency.symbol}
                                                                        {modalData.state.modalData.suggested_price ? new Intl.NumberFormat('ja-JP').format(modalData.state.modalData.suggested_price) : 0}
                                                                    </>
                                                                    :
                                                                    "n.a."
                                                                }
                                                            </Typography>
                                                        </TableCell>

                                                    </TableRow>
                                                    {modalData.state.modalData.skip_price ?
                                                    <TableRow className={classes.pmsTableRow} style={{ borderBottom: '1px solid #a0a2a663', borderTop: '1px solid #a0a2a663' }}>
                                                        <TableCell align="left" className={classes.pmsRowBlankBorder} >
                                                            <Typography variant="h6" style={{ marginLeft: '0px', marginTop: '0px', marginBottom: '0px', fontWeight: "500", color: (modalData.state.calLoader ? "#9d9d9d" : "#000") }}>
                                                                {t("Fix Price")}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="right" className={classes.pmsRowBlankBorder} >
                                                            <Typography variant="h6" style={{ marginRight: '0px', marginBottom: '9px', margin: '5px 0px', fontWeight: "500", color: (modalData.state.calLoader ? "#9d9d9d" : "#000") }}>
                                                                {modalData.state.currency.symbol}
                                                                {modalData.state.modalData.skip_price ?modalData.state.modalData.skip_price.fixed_price:"" }
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>:""}
                                                    {modalData.getOldPricesChange(0)}
                                                    {modalData.getOldPricesChange(1)}
                                                    {modalData.getOldPricesChange(2)}
                                                    {/* <TableRow className={classes.pmsTableRow} style={this.getFontcolors(0)} style={{ paddingRight: "5px" }}>
                                                        <TableCell align="left" className={code == 1 ? classes.pmsRowBlankBorder : classes.pmsNormalRow} style={{ paddingTop: (code == 0) ? '25px' : '0px' }}><Typography variant="subtitle1">{title}</Typography></TableCell>
                                                        <TableCell align="right" className={code == 1 ? classes.pmsRowBlankBorder : classes.pmsNormalRow} style={{ paddingTop: (code == 0) ? '25px' : '0px' }}>
                                                            <Typography variant="subtitle1" >
                                                            <span style={{ fontWeight: '400' }}>
                                                                {this.state.currency.symbol}
                                                                {new Intl.NumberFormat('ja-JP').format(price) + ' '}
                                                            </span>
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow> */}
                                                    {modalData.props.userSession.user.is_staff && modalData.state.featuresEnabled.includes(22) &&
                                                    <TableRow className={classes.pmsTableRow} style={{ paddingRight: "5px" }}>
                                                        <TableCell align="left" className={classes.pmsRowBlankBorder} style={{ paddingTop: "0px" }}>
                                                            <Typography variant="subtitle1" >
                                                                {t("Pickup Boost Price")}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="right" className={classes.pmsRowBlankBorder} style={{ paddingTop: "0px" }}>
                                                        <Typography variant="subtitle1" style={{ paddingTop: "0px" }}>
                                                                {boostPrice !== undefined ?
                                                                    <>
                                                                        {modalData.state.currency.symbol}
                                                                        {boostPrice ? new Intl.NumberFormat('ja-JP').format(boostPrice) : 0}
                                                                    </>
                                                                    : 
                                                                    "n.a."
                                                                }
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    }
                                                    {/* {modalData.props.userSession.user.is_staff && modalData.state.featuresEnabled.includes(22) &&
                                                    <TableRow className={classes.pmsTableRow} style={{ paddingRight: "5px" }}>
                                                        <TableCell align="left" className={classes.pmsRowBlankBorder} style={{ paddingTop: "0px" }}>
                                                        <Typography variant="subtitle1" >
                                                                {t("Comp Avg")}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="right" className={classes.pmsRowBlankBorder} style={{ paddingTop: "0px" }}>
                                                        <Typography variant="subtitle1" style={{ paddingTop: "0px" }}>
                                                                {(modalData.state.modalData.weighted_average_price !== undefined && modalData.state.modalData.weighted_average_price != null) ?
                                                                    <>
                                                                        {modalData.state.currency.symbol}
                                                                        {modalData.state.modalData.weighted_average_price ? new Intl.NumberFormat('ja-JP').format(modalData.state.modalData.weighted_average_price) : 'n.a.'}
                                                                    </>
                                                                    :
                                                                    "n.a."
                                                                }
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    }
                                                    {modalData.props.userSession.user.is_staff && modalData.state.featuresEnabled.includes(22) &&
                                                    <TableRow className={classes.pmsTableRow} style={{ paddingRight: "5px" }}>
                                                        <TableCell align="left" className={classes.pmsRowBlankBorder} style={{ paddingTop: "0px" }}>
                                                        <Typography variant="subtitle1" >
                                                                {t("Comp Avg Pickup")}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="right" className={classes.pmsRowBlankBorder} style={{ paddingTop: "0px" }}>
                                                        <Typography variant="subtitle1" style={{ paddingTop: "0px" }}>
                                                                {modalData.state.modalData.weighted_average_price_with_pubp !== undefined ?
                                                                    <>
                                                                        {modalData.state.currency.symbol}
                                                                        {modalData.state.modalData.weighted_average_price_with_pubp ? new Intl.NumberFormat('ja-JP').format(modalData.state.modalData.weighted_average_price_with_pubp) : 0}
                                                                    </>
                                                                    :
                                                                    modalData.state.currency.symbol+"0"
                                                                }
                                                            </Typography>
                                                        </TableCell>

                                                    </TableRow>
                                                }

                                                {modalData.props.userSession.user.is_staff && modalData.state.featuresEnabled.includes(22) &&
                                                    <TableRow className={classes.pmsTableRow} style={{ paddingRight: "5px" }}>
                                                        <TableCell align="left" className={classes.pmsRowBlankBorder} style={{ paddingTop: "0px" }}>
                                                            <Typography variant="subtitle1" >
                                                                {t("Pickup vs Yesterday")}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="right" className={classes.pmsRowBlankBorder} style={{ paddingTop: "0px" }}>
                                                            <Typography variant="subtitle1" style={{ paddingTop: "0px" }}>
                                                                {pickup_boost_prices ?
                                                                    <>
                                                                        {pickup_boost_prices ? new Intl.NumberFormat('ja-JP').format(pickup_boost_prices) : 0}
                                                                    </>
                                                                    :
                                                                    (pickup_boost_prices == 0 ? 0 : "n.a.")
                                                                }
                                                            </Typography>
                                                        </TableCell>

                                                    </TableRow>
                                                }

                                                {modalData.props.userSession.user.is_staff && modalData.state.featuresEnabled.includes(22) &&
                                                    <TableRow className={classes.pmsTableRow} style={{ paddingRight: "5px" }}>
                                                        <TableCell align="left" className={classes.pmsRowBlankBorder} style={{ paddingTop: "0px" }}>
                                                            <Typography variant="subtitle1" >
                                                                {t("Pickup 7 days")}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="right" className={classes.pmsRowBlankBorder} style={{ paddingTop: "0px" }}>
                                                            <Typography variant="subtitle1" style={{ paddingTop: "0px" }}>
                                                                {pickupWeekDay ?
                                                                    <>
                                                                        {pickupWeekDay ? new Intl.NumberFormat('ja-JP').format(pickupWeekDay) : 0}
                                                                    </>
                                                                    :
                                                                    (pickupWeekDay == 0 ? 0 : "n.a.")
                                                                }
                                                            </Typography>
                                                        </TableCell>

                                                    </TableRow> 
                                                }*/}
                                                

                                                    {modalData.state.featuresEnabled.includes(16) &&
                                                        <>
                                                           <Typography variant="subtitle1" style={{ marginLeft: "0px", marginTop: '15px', marginBottom: '15px' }}>{t("OTA Insight")}</Typography>
                                                            <TableRow className={classes.pmsTableRow} style={modalData.getFontcolors(0)} style={{ paddingRight: "5px" }}>

                                                                <TableCell align="left" className={classes.pmsRowBlankBorder} style={{ paddingTop: "0px" }}><Typography variant="subtitle1">{t("Median Flexible Rate")}</Typography></TableCell>
                                                                <TableCell align="right" className={classes.pmsNormalRow} style={{ display: "flex", paddingTop: '0px' }}>
                                                                    <Typography variant="subtitle1">
                                                                        <span>
                                                                            {maidanFlexPrice != "n.a." ? modalData.state.currency.symbol : ""}{maidanFlexPrice}
                                                                        </span>
                                                                    </Typography>
                                                                    {maidanFlexPricePer != "n.a." &&
                                                                        <Typography variant="subtitle1" style={maidanFlexPricePer ? ((maidanFlexPricePer) > 0 ? { color: "#135138", background: "#BBF2DD", fontWeight: "500", padding: "0px 8px", borderRadius: "5px", marginRight: "6px" } : { color: "#890921", background: "#FAC6D1", fontWeight: "500", padding: "0px 8px", borderRadius: "5px", marginRight: "6px" }) : {}}>
                                                                            {maidanFlexPricePer != "n.a." ?

                                                                                (maidanFlexPricePer ? (maidanFlexPricePer > 0 ? " +" : "") : " ") + maidanFlexPricePer + "%"

                                                                                : ""}
                                                                        </Typography>
                                                                    }

                                                                </TableCell>
                                                            </TableRow></>
                                                    }
                                                    {modalData.state.featuresEnabled.includes(16) &&

                                                        <TableRow className={classes.pmsTableRow} style={modalData.getFontcolors(0)} style={{ paddingRight: "5px" }}>
                                                            {/* <TableCell align="left" className={classes.pmsRowBlankBorder}><Typography variant="subtitle1" >{t()}</Typography></TableCell> */}
                                                            <TableCell align="left" className={classes.pmsRowBlankBorder} style={{ paddingTop: "0px" }}><Typography variant="subtitle1">{t("Median Lowest Rate")}</Typography></TableCell>
                                                            <TableCell align="right" className={classes.pmsNormalRow} style={{ display: "flex", paddingTop: '0px' }}>
                                                                <Typography variant="subtitle1">
                                                                    <span>
                                                                        {maidanCheapPrice != "n.a." ? modalData.state.currency.symbol : ""}{maidanCheapPrice}
                                                                    </span>
                                                                </Typography>
                                                                {maidanCheapPricePer != "n.a." &&
                                                                    <Typography variant="subtitle1" style={maidanCheapPricePer ? ((maidanCheapPricePer) > 0 ? { color: "#135138", background: "#BBF2DD", fontWeight: "500", padding: "0px 8px", borderRadius: "5px", marginRight: "6px" } : { color: "#890921", background: "#FAC6D1", fontWeight: "500", padding: "0px 8px", borderRadius: "5px", marginRight: "6px" }) : {}} >
                                                                        {maidanCheapPricePer != "n.a." ?
                                                                            (maidanCheapPricePer ? (maidanCheapPricePer > 0 ? " +" : "") : " ") + maidanCheapPricePer + "%"
                                                                            : ""}
                                                                    </Typography>
                                                                }

                                                            </TableCell>
                                                        </TableRow>
                                                    }

                                                    {modalData.state.featuresEnabled.includes(32) && modalData.state.modalData.segmentation_bookings &&
                                                        <TableRow className={classes.pmsTableRow} style={{ paddingRight: "5px" }}>
                                                            <TableCell align="left" className={classes.pmsRowBlankBorder} style={{ paddingTop: "0px" }}>                                                            
                                                            </TableCell>
                                                            <TableCell align="right" className={classes.pmsRowBlankBorder} style={{ paddingTop: "0px" }}>                                                                
                                                            </TableCell>
                                                        </TableRow> 
                                                    }

                                                    {modalData.state.featuresEnabled.includes(32) && modalData.state.modalData.segmentation_bookings && Object.keys(modalData.state.modalData.segmentation_bookings) && Object.keys(modalData.state.modalData.segmentation_bookings).map(key =>
                                                        <TableRow className={classes.pmsTableRow} style={modalData.getFontcolors(0)} style={{ paddingRight: "5px" }}>
                                                            <TableCell align="left" className={classes.pmsRowBlankBorder} style={{ paddingTop: "0px" }}><Typography variant="subtitle1">  {t("Segment")}: {key}</Typography></TableCell>
                                                            <TableCell align="right" className={classes.pmsNormalRow} style={{ display: "flex", paddingTop: '0px' }}>
                                                                    <Typography variant="subtitle1">
                                                                        {modalData.state.modalData.segmentation_bookings[key]} {t("Room Nights")}
                                                                    </Typography>
                                                            </TableCell>

                                                        </TableRow>
                                                    )}
                                                    

                                                </TableHead>
                                            </Table>
                                        </MuiThemeProvider>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item sm={12} md={1} style={{ overflow: "auto" }}>
                            </Grid>
                            <Grid item sm={12} md={5} style={{ paddingLeft: "0px", marginTop: '10px' }}>
                                <Grid container >
                                    <Grid item >
                                        <span className={classes.pmsContent} style={{ marginLeft: "0px" }}>
                                        <HierarchyText
                                                title={
                                                <>
                                                    {modalData.state.modalData.price !== undefined ?
                                                        <>
                                                            {modalData.state.modalData.occupancy ? modalData.state.modalData.occupancy.toFixed(0) + "%" : "0%"}
                                                        </>
                                                        :
                                                        "n.a."
                                                    }
                                                </>
                                                }
                                                label={t("Occupancy")}
                                                
                                            />
                                        </span>

                                    </Grid>
                                 
                                    <Grid item >
                                        <span className={classes.pmsContent} style={{ marginLeft: "0px" }}>
                                        <HierarchyText
                                                title={<>
                                                    {modalData.state.modalData.price !== undefined ?
                                                        <>
                                                            {modalData.state.modalData.total_inventory ? modalData.state.modalData.total_inventory : 0}
                                                        </>
                                                        :
                                                        "n.a."
                                                    }
                                                </>
                                                }
                                                // label={modalData.state.modalData.total_inventory ? (modalData.state.modalData.total_inventory > 1 ? t(" Rooms Left Total") : t(" Rooms Left Total")) : t(" Rooms Left Total")}
                                                label={<>{t(`${modalData.state.room_apartment_space_name}s Left`)} <br /> {t("Total")}</>}
                                            />
                                           
                                        </span>
                                    </Grid>
                                    {(modalData.state.modalData.total_closed_room_pms !== undefined && modalData.state.modalData.total_closed_room_pms > 0) && 
                                        <Grid item >
                                            <span className={classes.pmsContent} style={{ marginLeft: "13px" }}>
                                            <HierarchyText
                                                    title={modalData.state.modalData.total_closed_room_pms}
                                                    label={<>{t(`${modalData.state.room_apartment_space_name}s Closed`)}</>}
                                                />
                                            </span>
                                        </Grid>
                                    }

                                    {/* <Grid item sm={12} md={4} lg={4}>
                                    </Grid> */}
                                   {modalData.state.featuresEnabled.includes(25) &&
                                    <>
                                    <Grid item >
                                        <span className={classes.pmsContent} style={{ marginLeft: "13px" }}>
                                        <HierarchyText
                                            title={modalData.state.currency.symbol + (adrValue ?  Intl.NumberFormat('ja-JP').format(adrValue):"0")}
                                            label={t("ADR")}
                                        />
                                        </span>
                                    </Grid>
                                    <Grid item >
                                            <span className={classes.pmsContent} style={{ marginLeft: "0px" }}>
                                            <HierarchyText
                                                title={modalData.state.currency.symbol + (revenueValue ?  new Intl.NumberFormat('ja-JP').format(revenueValue) : '0')}
                                                label={t("Total Revenue")}
                                            />
                                            </span>
                                    </Grid>
                                    </>
                                    }
                                </Grid>
                                

                                <div  className={classes.notesBorder} style={{ marginTop: '10px' }}>
                                    <TextField
                                        placeholder={t("Add note...")}
                                        multiline
                                        rows="2"
                                        value={modalData.state.priceNote}
                                        onClick={modalData.changeNotesStartEndDate()}
                                        onChange={modalData.changePriceNote()}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        className={classes.textField}
                                        style={{marginTop:'-8px'}}
                                    />
                                    {modalData.state.notesStartEndDate &&
                                    <div>
                                    <MuiThemeProvider theme={CalendardateTheme}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                                            <DatePicker
                                                style={{ width: "157px",marginRight:'15px' }}
                                                margin="normal"
                                                label={t("From Date")}
                                                value={moment(modalData.state.notesStartDate)}
                                                onChange={modalData.changeNotesDate('notesStartDate')}
                                                format={notes_date_format}
                                                autoOk={true}
                                                keyboard
                                                placeholder={preferred_date.toLowerCase()}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                            <DatePicker
                                                style={{ width: "157px" }}
                                                margin="normal"
                                                label={t("To Date")}
                                                value={moment(modalData.state.notesEndDate)}
                                                onChange={modalData.changeNotesDate('notesEndDate')}
                                                format={notes_date_format}
                                                autoOk={true}
                                                minDate={moment(modalData.state.notesStartDate)}
                                                keyboard
                                                placeholder={preferred_date.toLowerCase()}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </MuiPickersUtilsProvider>
                                        </MuiThemeProvider>
                                        <Button className={classes.notesBtn} disabled={!modalData.state.priceNote || modalData.state.loadingNotes} onClick={()=>modalData.saveNotes()}  variant="contained" color="primary">
                                            {modalData.state.loadingNotes && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                                            {t('Save')}
                                        </Button>
                                        <Button onClick={modalData.handleCloseEditNotes} style={{background:"white",marginLeft:'5px',padding:'5px'}} className={[classes.notesBtn,classes.cancelBtn]} >{t("Cancel")}</Button>
                                    </div>
                                    }
                                {/* Edit Notes */}
                                </div>
                                {  
                                    modalData.state.userNotesList && Object.keys(modalData.state.userNotesList).map(key=>{
                                        if(moment(key).isSame(modalData.state.modalData.date)){
                                            return modalData.state.userNotesList[key].map((val)=>(
                                                        <div className={modalData.state.editNotesValue.id != val.id ? classes.noteListBorder:classes.noteListBorderEdit} style={{ marginTop: '10px' }} onClick={()=>modalData.handleEditNotes(val,modalData.state.editNotesValue.id ==val.id ? true :false)}>
                                                        {(modalData.state.editNotesValue && modalData.state.editNotesValue.id ==val.id && modalData.state.noteEditEnable) ? 
                                                             <>
                                                            <TextField
                                                                required
                                                                placeholder={t("Add note...")}
                                                                multiline
                                                                rows="2"
                                                                value={modalData.state.editNotesValue.name}
                                                                onChange={modalData.editNotesData("name")}
                                                                fullWidth
                                                                InputLabelProps={{ shrink: true }}
                                                                className={classes.textField}
                                                                style={{marginTop:'-10px'}}
                                                            />
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                                                                <DatePicker
                                                                    style={{ width: "154px",marginRight:'15px' }}
                                                                    margin="normal"
                                                                    label={t("From Date")}
                                                                    value={moment(modalData.state.editNotesValue.start_date)}
                                                                    onChange={modalData.editNotesData2('start_date')}
                                                                    format={notes_date_format}
                                                                    autoOk={true}
                                                                    keyboard
                                                                    placeholder={preferred_date.toLowerCase()}
                                                                    InputLabelProps={{ shrink: true }}
                                                                />
                                                                <DatePicker
                                                                    style={{ width: "154px" }}
                                                                    margin="normal"
                                                                    label={t("To Date")}
                                                                    value={moment(modalData.state.editNotesValue.end_date)}
                                                                    onChange={modalData.editNotesData2('end_date')}
                                                                    format={notes_date_format}
                                                                    autoOk={true}
                                                                    minDate={moment(modalData.state.editNotesValue.start_date)}
                                                                    keyboard
                                                                    placeholder={preferred_date.toLowerCase()}
                                                                    InputLabelProps={{ shrink: true }}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                            <Button className={classes.notesBtn} disabled={modalData.state.loadingNotesEdit} onClick={()=>modalData.editNotes(val)}  variant="contained" color="primary">
                                                            {modalData.state.loadingNotesEdit && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                                                            
                                                                {t('Save')}
                                                            </Button>
                                                            <Button className={[classes.notesBtn,classes.dangerBtnHvr]} variant="contained" disabled={modalData.state.loadingNotesDelete} style={{marginLeft:'5px'}} onClick={()=>modalData.deleteNotes(val)} >
                                                                {modalData.state.loadingNotesDelete && <CircularProgress style={{color:'red'}} size={24} className={classes.buttonProgress} />}
                                                                {t('Delete')}
                                                            </Button>
                                                            <Button onClick={modalData.handleCloseEditNotes} style={{background:"white",marginLeft:'5px',padding:'5px'}} className={[classes.notesBtn,classes.cancelBtn]} >{t("Cancel")}</Button>
                                                    </>        
                                                        
                                                        :
                                                        <React.Fragment>
                                                            
                                                                <Grid container>
                                                                    {/* <Grid item xs={7} style={{display:"flex"}}> */}
                                                                    {/* <span className={classes.chipContent} style={{background:(val.category === "public-holidays" || val.category === "school-holidays") ? "#FFDB99" : "#D8DAE9",fontSize:"12px" ,color:(val.category === "public-holidays" || val.category === "school-holidays") ? "#3D2900" : "#2C304E"}}>{val.category}</span>
                                                                    {(val.category === "public-holidays" || val.category === "school-holidays") ? " " : <span className={classes.chipContentIcon} style={{background:"#D8DAE9"}}><LocationOnOutlinedIcon style={{color:"#2c304e",fontSize:'12px'}}/></span>} */}
                                                                    {/* </Grid> */}
                                                                    <Grid item xs={9} style={{paddingLeft:"4px",overflowWrap:'anywhere'}}>
                                                                        <Typography variant="h6" style={{fontWeight:'400',fontSize:'16px'}}>{val.name}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={3} style={{textAlign:"right"}}>
                                                                        <Typography variant="h6" style={{fontSize:"12px", paddingTop:3}} >
                                                                            {moment(val.start_date).format('DD/MM')+' - '+moment(val.end_date).format('DD/MM')}
                                                                        </Typography>
                                                                    </Grid>
                                                                   
                                                                </Grid>
                                                            
                                                        {/* <div className={classes.noteListTitle}><span >{val.name}</span></div>
                                                        <span className={classes.noteListdate}>{moment(val.start_date).format('DD/MM')+' - '+moment(val.end_date).format('DD/MM')}</span>     */}
                                                        </React.Fragment>
                                                          
                                                        }
                                                        </div>
                                                    
                                                
                                            ))
                                            
                                        }
                                    })
                                }
                               
                                {/* <div className={classes.eventListDiv}>
                                    <Typography variant="h6" >{t("Events List")}</Typography>
                                    <Table className={classes.eventTable}>
                                        <TableHead>
                                            {Object.keys(modalData.props.eventReducer.events).length > 0 ? Object.keys(modalData.props.eventReducer.events).map(key => {
                                                let events = modalData.props.eventReducer.events[key];
                                                return events.map((val, index) => {
                                                    return (<React.Fragment key={index}>
                                                        <TableRow className={classes.eventNameRow}>
                                                            <TableCell colSpan="3" className={classes.eventName}>{val.title}</TableCell>
                                                        </TableRow>
                                                        <TableRow className={classes.eventList}>
                                                            <TableCell className={classes.eventDates}>{val.category}</TableCell>
                                                            <TableCell className={classes.eventDates}>
                                                                {moment(val.start_date).format('DD-MM-YYYY') == moment(val.end_date).format('DD-MM-YYYY') ? (moment(val.start_date).format('DD-MM-YYYY')) : (moment(val.start_date).format('DD-MM-YYYY') + ' - ' + moment(val.end_date).format('DD-MM-YYYY'))}
                                                                <span style={{ color: "#d32f2f", marginLeft: "5px" }}>{val.deleted_reason}</span>
                                                            </TableCell>
                                                        </TableRow>
                                                    </React.Fragment>)
                                                })
                                            })
                                                : "No events found."}
                                        </TableHead>
                                    </Table>
                                </div> */}
                                
                                    {modalData.state.weather && modalData.state.weather.icon_code &&
                                    <Tooltip
                                        classes={{
                                            popper: "toolTipPooper",
                                            tooltip: classes.htmlTooltip,
                                        }}
                                        title={t(modalData.state.weather.description)}
                                    >
                                    <div style={{ marginTop: "22px", float: "right", display: "flex" }} className={classes.hideOnMobile}>
                                        <img src={modalData.state.weather.icon_id} style={{ height: "37px", margin: "-6px 10px 0px 10px" }} />
                                            <Typography variant="h6" style={{fontWeight:'500',fontSize:'16px'}}>
                                                {modalData.state.weather.max_temperature}
                                                {modalData.state.weather.temperature_type == "celcius" ? "°" : "°F"}
                                            </Typography>
                                            <span style={{margin:'0px 3px 0px 1px'}}>/</span>
                                            <span style={{fontSize:'14px',marginTop:'4px'}}>
                                                {modalData.state.weather.min_temperature}
                                                {modalData.state.weather.temperature_type == "celcius" ? "°" : "°F"}
                                            </span>
                                            {/* <span style={{ marginTop: "0px", color: "#A0A2A6", fontSize: "16px", fontWeight: "400", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{t(modalData.state.weather.description)}</span> */}
                                    </div> 
                                    </Tooltip>
                                }
                                <div className={classes.eventListDiv}>
                                <Typography variant="subtitle1" style={{marginBottom:"12px"}}>{t("Events List")}</Typography>
                                    {
                                        modalData.props.eventCategoryReducer.eventCatLoding == false ?
                                        Object.keys(modalData.props.eventCategoryReducer.eventCategory).length > 0 ? Object.keys(modalData.props.eventCategoryReducer.eventCategory).map(key => {

                                            let events = modalData.props.eventCategoryReducer.eventCategory[key];
                                            {events.sort((a, b) => {
                                                if (a.weighted_local_rank < b.weighted_local_rank) { return 1; }
                                                if (a.weighted_local_rank > b.weighted_local_rank) { return -1; }
                                                return 0;
                                            })}
                                            return events.map((val,index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <Card className={classes.eventListContent}>
                                                            <Grid container>
                                                                <Grid item xs={7} style={{display:"flex"}}>
                                                                    <span className={classes.chipContent} style={{background:(val.category === "public-holidays" || val.category === "school-holidays") ? "#FFDB99" : "#D8DAE9",fontSize:"12px" ,color:(val.category === "public-holidays" || val.category === "school-holidays") ? "#3D2900" : "#2C304E"}}>{val.category}</span>
                                                                    {(val.category === "public-holidays" || val.category === "school-holidays") ? " " : <span className={classes.chipContentIcon} style={{background:"#D8DAE9"}}><LocationOnOutlinedIcon style={{color:"#2c304e",fontSize:'12px'}}/></span>}
                                                                </Grid>
                                                                <Grid item xs={5} style={{textAlign:"right"}}>
                                                                    <Typography variant="h6" style={{fontSize:"12px", paddingTop:3}} >
                                                                        {moment(val.start_date).format('DD-MM-YYYY') === moment(val.end_date).format('DD-MM-YYYY') ? moment(val.start_date).format('DD/MM') : (moment(val.start_date).format('DD/MM')+ ' - ' + moment(val.end_date).format('DD/MM'))}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} style={{paddingLeft:"8px"}}>
                                                                    <Typography variant="h6" style={{fontWeight:'400',fontSize:'16px'}}>{val.title}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Card>
                                                    </React.Fragment>
                                                )
                                            })
                                            
                                        }): t("No events found.")
                                        :t("Searching for events...")
                                    }
                                    
                                </div>
                           
                            </Grid>
                        </Grid>
                    }              
                    {modalData.state.modalTab == 2 &&
                        <Grid item xs={12} style={{ marginTop: '17px' }}>
                            <div className={classes.dateNavigationDiv} >
                                <MuiThemeProvider theme={CalendarbuttonTheme} >
                                    <div className={classes.graphtextDiv}>
                                        <div>
                                            <Button variant="outlined" className={[classes.newTabBtnFirst,classes.textSecondary]} onClick={() => modalData.changeAdjTab("adjust")} style={modalData.state.adjTab == "adjust" ? { background: "#f2f2f2" } : {}}>{t("Percent Adjustment")}</Button>
                                            <Button disabled={!modalData.state.featuresEnabled.includes(1)} variant="outlined"  className={[classes.newTabBtn,classes.textSecondary,classes.hideOnMobile]} onClick={() => modalData.changeAdjTab("minmax")} style={modalData.state.adjTab == "minmax" ? { background: "#f2f2f2" } : {}}>{t("Min/Max Prices")}</Button>
                                            <Button variant="outlined" className={[classes.newTabBtnLast,classes.textSecondary]} onClick={() => modalData.changeAdjTab("fix")} style={modalData.state.adjTab == "fix" ? { background: "#f2f2f2" } : {}}>{t("Fix Prices")}</Button>
                                        </div>
                                    </div>
                                </MuiThemeProvider>
                                {modalData.state.viewType != "adjustment" && modalData.state.popupView != 'OTA' &&
                                    <Button  className={[classes.hideOnMobile,classes.textSecondary]}  style={{ marginLeft: "16px" }} onClick={() => modalData.changeView("adjustment")}>{t("Go To Bulk Edit")}
                                    {/* <Icon>arrow_right_alt</Icon> */}
                                    </Button>
                                }

                                {/* <div style={{ float: "right", marginLeft: "16px" }}>
                                    {modalData.state.calLoader ? t("Recalculating") : ""}
                                    {modalData.state.calLoader ? <CircularProgress color="primary" size={14} style={{ marginLeft: "10px" }} /> : ""}
                                </div> */}

                                {/* <Button variant={"outlined"} color="primary" style={{ marginLeft: "16px" }} onClick={() => modalData.changeView("adjustment")}>Go to Adjustment Calendar<Icon>arrow_right_alt</Icon></Button> */}

                            </div>



                            {modalData.state.adjTab == "adjust" &&

                                <Grid xs={12} item style={{ marginRight: "70px", marginTop: "70px" }}>
                                    <Table style={{ marginTop: "12px" }}>
                                        <TableHead className={classes.pmsTableRow} >
                                            <TableCell className={classes.tableCell}>{t(modalData.state.room_apartment_space_name)}</TableCell>
                                            <TableCell className={classes.tableCell}>{t("Percent Adjustment")}</TableCell>
                                            <TableCell className={classes.tableCell} style={{paddingRight:"125px"}}>
                                            { modalData.state.featuresEnabled.includes(13) ?t("Recommended BAR Level"):t("Recommended Price")}
                                            </TableCell>
                                        </TableHead>
                                        {modalData.props.roomList.roomList.sort((a, b) => b.is_reference_room - a.is_reference_room).filter(val => (!modalData.state.refRoomOnly || val.is_reference_room)).map((rooms, index) => {
                                            return (
                                                <TableRow className={[classes.pmsTableRow,classes.tableCell]}>
                                                    <TableCell  className={[classes.tableCell,classes.rowAdj]} style={{ width: "250px", padding: "22px 0px", fontWeight: rooms.is_reference_room ? "500" : "400" }}>
                                                        {rooms.name}
                                                    </TableCell >
                                                    <TableCell className={[classes.rowAdj]}>
                                                        <FormControl className={classes.formControlLeft}  margin="none" style={{ marginTop: "-16px", padding: "8px 0px" }}>
                                                            <TextField
                                                                margin="none"
                                                                className={classes.textFieldAdj}
                                                                type="number"
                                                                id="standard-name"
                                                                placeholder={t("Percent Adjustment")}
                                                                value={modalData.state.bulkUpdateData.dailyAdjust}
                                                                onChange={modalData.bulkUpdateData('dailyAdjust', modalData.state.selectedRoom, true)}
                                                                InputLabelProps={{ shrink: true }}
                                                                disabled={!rooms.is_reference_room}
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                                }}
                                                            />
                                                        </FormControl>
                                                        {modalData.state.error.includes('upload_price') ? <span class='error-message'>{t('* Please enter valid price')}</span> : ''}

                                                        {/* } */}
                                                    </TableCell>
                                                    <TableCell className={[classes.tableCell,classes.textAlignLeft]}  style={{  paddingRight:"133px"}}>
                                                        {modalData.state.currency.symbol}{modalData.state.tempUpload[rooms.id] ? new Intl.NumberFormat('ja-JP').format(modalData.state.tempUpload[rooms.id].price) : ""}
                                                    </TableCell >
                                                </TableRow>
                                            )
                                        })}
                                    </Table>

                                </Grid>
                            }
                            {modalData.state.adjTab == "minmax" &&
                                <div className={classes.priceTableDiv}>

                                    <div className={classes.priceTableDiv1}>
                                        <Grid container>
                                            {modalData.state.featuresEnabled.includes(6) && !modalData.state.refRoomOnly &&
                                                <Grid md={4}>

                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color={"primary"}
                                                                checked={modalData.state.deriveFromRef}
                                                                onChange={modalData.handlederiveFromRef}
                                                            />
                                                        }
                                                        label={t(`Prefill Derived ${modalData.state.room_apartment_space_name}s`)}
                                                    />
                                                </Grid>
                                            }

                                        </Grid>
                                        <Grid xs={12} item style={{ marginRight: "70px", marginTop: !(modalData.state.featuresEnabled.includes(6) && !modalData.state.refRoomOnly) ? "60px" : "0px" }}>
                                            <Table style={{ marginTop: "12px" }}>
                                                <TableHead className={classes.pmsTableRow}>
                                                    <TableCell>{t(`${modalData.state.room_apartment_space_name}`)}</TableCell>
                                                    {modalData.state.featuresEnabled.includes(1) &&
                                                        <TableCell style={{ textAlign: "right" }}>{t("Minimum Price")}</TableCell>
                                                    }
                                                    {modalData.state.featuresEnabled.includes(1) &&
                                                        <TableCell style={{ textAlign: "right" }}>{t("Maximum Price")}</TableCell>
                                                    }
                                                    <TableCell style={{ textAlign: "right", minWidth: "164px" }}>
                                                        { modalData.state.featuresEnabled.includes(13) ?t("Recommended BAR Level"):t("Recommended Price")}
                                                    </TableCell>

                                                </TableHead>
                                                {modalData.props.roomList.roomList.sort((a, b) => b.is_reference_room - a.is_reference_room).filter(val => (!modalData.state.refRoomOnly || val.is_reference_room)).map((rooms, index) => {
                                                    min = setting.default[rooms.id].min_price;
                                                    max = setting.default[rooms.id].max_price;
                                                    return (
                                                        <TableRow className={classes.pmsTableRow}>
                                                            <TableCell className={classes.rowAdj} style={{ width: "250px", padding: "22px 0px", fontWeight: rooms.is_reference_room ? "500" : "400" }}>
                                                                {rooms.name}
                                                            </TableCell >

                                                            {modalData.state.featuresEnabled.includes(1) &&
                                                                <TableCell className={classes.rowAdj} style={{ textAlign: "right" }}>
                                                                    {(!modalData.state.refRoomOnly || rooms.is_reference_room) && (modalData.state.featuresEnabled.includes(6) || rooms.is_reference_room) &&
                                                                        <FormControl margin="none" className={classes.formControlLeft} style={{ marginTop: "-16px", padding: "8px 0px" }}>
                                                                            <TextField
                                                                                type="number"
                                                                                disabled={modalData.state.calLoader == "minPrice"}
                                                                                className={classes.textFieldAdj}
                                                                                // error={modalData.state.bulkError.includes('minPrice')}
                                                                                placeholder={t("Minimum Price")}
                                                                                id="standard-name"
                                                                                margin="none"
                                                                                value={modalData.state.bulkUpdateData.minPrice[rooms.id]}
                                                                                onChange={modalData.bulkUpdateData('minPrice', rooms.id, true)}
                                                                                inputRef={(el) => (this.minTextField = el)}
                                                                                InputProps={{
                                                                                    startAdornment: <InputAdornment position="start" >{modalData.state.currency.symbol}</InputAdornment>,
                                                                                    endAdornment: <InputAdornment position="end" onClick={() => this.minTextField.focus()} >
                                                                                        {(!rooms.is_reference_room && modalData.state.calLoader == "minPrice") ? 
                                                                                            <div className={classes.defaultRate} style={{ display:"flex", flexDirection:"row",float: "right", marginLeft: "16px" }}>
                                                                                                {modalData.state.calLoader ? t("Recalculating") : ""}
                                                                                                {modalData.state.calLoader ? <CircularProgress color="primary" size={14} style={{ marginLeft: "4px", marginTop:"3px" }} /> : ""}
                                                                                            </div>
                                                                                            :
                                                                                            <Typography className={classes.defaultRate} onClick={this.focusTextInput} >
                                                                                                <span>{t('Default')}</span>
                                                                                                <span>{modalData.state.currency.symbol + min}</span>
                                                                                            </Typography>
                                                                                        }
                                                                                    </InputAdornment>,
                                                                                }}
                                                                            >
                                                                            </TextField>
                                                                            {modalData.state.popupError.minPrice && modalData.state.popupError.minPrice.includes(rooms.id) &&
                                                                                <span class='error-message'>{t('* Please enter valid Min Price')}</span>
                                                                            }
                                                                        </FormControl>
                                                                    }
                                                                     {!((!modalData.state.refRoomOnly || rooms.is_reference_room) && (modalData.state.featuresEnabled.includes(6) || rooms.is_reference_room)) &&
                                                                        <>                                                            
                                                                            <span style={{float:"right", marginLeft:"8px"}}>{modalData.state.currency.symbol}{modalData.state.bulkUpdateData.minPrice[rooms.id]}</span>
                                                                            {(!rooms.is_reference_room && modalData.state.calLoader == "minPrice") &&
                                                                            <div className={classes.defaultRate} style={{ display:"flex", flexDirection:"row",float: "right", marginLeft: "16px" }}>
                                                                                {modalData.state.calLoader ? t("Recalculating") : ""}
                                                                                {modalData.state.calLoader ? <CircularProgress color="primary" size={14} style={{ marginLeft: "4px", marginTop:"3px" }} /> : ""}
                                                                            </div>
                                                                            }
                                                                        </>
                                                                    }                                                                   
                                                                </TableCell >
                                                            }
                                                            {modalData.state.featuresEnabled.includes(1) &&
                                                                <TableCell className={classes.rowAdj} style={{ textAlign: "right" }}>
                                                                    {(!modalData.state.refRoomOnly || rooms.is_reference_room) && (modalData.state.featuresEnabled.includes(6) || rooms.is_reference_room) &&
                                                                        <FormControl margin="none" className={classes.formControlLeft} style={{ marginTop: "-16px", padding: "8px 0px" }}>
                                                                            <TextField
                                                                                type="number"
                                                                                className={classes.textFieldAdj}
                                                                                disabled={modalData.state.calLoader == "maxPrice"}
                                                                                placeholder={t("Maximum Price")}
                                                                                // error={modalData.state.bulkError.includes('maxPrice') || modalData.state.bulkError.includes('maxPriceLow')}
                                                                                id="standard-name"
                                                                                margin="none"
                                                                                value={modalData.state.bulkUpdateData.maxPrice[rooms.id]}
                                                                                onChange={modalData.bulkUpdateData('maxPrice', rooms.id, true)}
                                                                                inputRef={(el) => (this.maxTextField = el)}
                                                                                InputProps={{
                                                                                    startAdornment: <InputAdornment position="start">{modalData.state.currency.symbol}</InputAdornment>,
                                                                                    endAdornment: <InputAdornment position="end" onClick={() => this.maxTextField.focus()}>
                                                                                         {(!rooms.is_reference_room && modalData.state.calLoader == "maxPrice") ? 
                                                                                            <div className={classes.defaultRate} style={{ display:"flex", flexDirection:"row",float: "right", marginLeft: "16px" }}>
                                                                                                {modalData.state.calLoader ? t("Recalculating") : ""}
                                                                                                {modalData.state.calLoader ? <CircularProgress color="primary" size={14} style={{ marginLeft: "4px", marginTop:"3px" }} /> : ""}
                                                                                            </div>
                                                                                            :
                                                                                        <Typography className={classes.defaultRate} onClick={this.focusTextInput} >
                                                                                            <span>{t('Default')}</span>
                                                                                            <span>{modalData.state.currency.symbol + max}</span>
                                                                                        </Typography>
                                                                                }
                                                                                    </InputAdornment>,
                                                                                }}
                                                                            >
                                                                            </TextField>
                                                                             {/* {modalData.state.popupError.includes('maxPrice') &&
                                                                                <span class='error-message'>{t('* Please enter valid Max Price')}</span>
                                                                            } */}
                                                                            
                                                                            {modalData.state.popupError.maxPrice && modalData.state.popupError.maxPrice.includes(rooms.id) &&
                                                                                <span class='error-message'>{t('Max Price should be greater than Min Price')}</span>
                                                                            }
                                                                        </FormControl>
                                                                    }
                                                                    {!((!modalData.state.refRoomOnly || rooms.is_reference_room) && (modalData.state.featuresEnabled.includes(6) || rooms.is_reference_room)) &&
                                                                            <>                                                                                                                 
                                                                                <span style={{float:"right", marginLeft:"8px"}}>{modalData.state.currency.symbol}{modalData.state.bulkUpdateData.maxPrice[rooms.id]}</span>
                                                                                {(!rooms.is_reference_room && modalData.state.calLoader == "maxPrice") && 
                                                                                    <div className={classes.defaultRate} style={{ display:"flex", flexDirection:"row",float: "right", marginLeft: "16px" }}>
                                                                                        {modalData.state.calLoader ? t("Recalculating") : ""}
                                                                                        {modalData.state.calLoader ? <CircularProgress color="primary" size={14} style={{ marginLeft: "4px", marginTop:"3px" }} /> : ""}
                                                                                    </div>
                                                                                }  
                                                                            </>
                                                                        }
                                                                </TableCell >
                                                            }
                                                            <TableCell className={classes.rowAdj} style={{ textAlign: "right" }}>
                                                                {modalData.state.currency.symbol}{modalData.state.tempUpload[rooms.id] ? new Intl.NumberFormat('ja-JP').format(modalData.state.tempUpload[rooms.id].price) : ""}
                                                            </TableCell >
                                                        </TableRow>
                                                    )
                                                })}
                                            </Table>
                                            {/* {!modalData.state.predefined_rate && modalData.state.price_type && modalData.state.price_type == 'fix' && modalData.state.popupRoomData.is_reference_room && */}
                                        </Grid>

                                    </div>
                                </div>
                            }

                            {modalData.state.adjTab == "fix" &&
                                <>
                                    {/* <Typography variant={"h6"} style={{ marginTop: "10px" }}>{t("Adjust Price")}</Typography> */}
                                    <div className={classes.priceTableDiv}>

                                        <div className={classes.priceTableDiv1}>
                                            <Grid container>
                                                {!modalData.state.refRoomOnly &&
                                                    <Grid md={4}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    color={"primary"}
                                                                    checked={modalData.state.deriveFromRef}
                                                                    onChange={modalData.handlederiveFromRef}
                                                                />
                                                            }
                                                            style={{ width: "90%" }}
                                                            label={t(`Prefill Derived ${modalData.state.room_apartment_space_name}s`)}
                                                        />
                                                    </Grid>

                                                }
                                                <Grid md={6}>
                                                    {(modalData.state.popupRoomData && modalData.state.popupRoomData.is_reference_room) &&
                                                        <FormControl required className={classes.adjustmentTextfield}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        color="primary" 
                                                                        checked={modalData.state.isSkipDateModal}
                                                                        onChange={modalData.handleisSkipDateModal}
                                                                    />
                                                                }
                                                                label={t("Skip Date from Pricing")}
                                                            />
                                                        </FormControl>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid xs={12} item style={{ marginRight: "70px" }}>
                                                <Table style={{ marginTop: "12px" }}>
                                                    <TableHead className={classes.pmsTableRow}>
                                                        <TableCell  className={[classes.tableCell]} >{t(modalData.state.room_apartment_space_name)}</TableCell>
                                                        <TableCell className={[classes.tableCell]}>{t("Fix Prices")}</TableCell>
                                                    </TableHead>
                                                    {modalData.props.roomList.roomList.sort((a, b) => b.is_reference_room - a.is_reference_room).filter(val => (!modalData.state.refRoomOnly || val.is_reference_room)).map((rooms, index) => {
                                                        return (
                                                            <TableRow className={[classes.tableCell, classes.pmsTableRow]} >
                                                                <TableCell className={classes.rowAdj} style={{ width: "250px", padding: "22px 0px", fontWeight: rooms.is_reference_room ? "500" : "400" }}>
                                                                    {rooms.name}
                                                                </TableCell >
                                                                <TableCell className={[classes.rowAdj]} >

                                                                    {/* {modalData.state.featuresEnabled.includes(13) &&
                                                                        <FormControl fullWidth margin="none" required className={classes.formControlLeft} style={{ marginTop: "-2px", padding: "8px 0px" }}>
                                                                            <TextField
                                                                                fullWidth
                                                                                className={classes.predefined}
                                                                                id="standard-name"
                                                                                label={""}
                                                                                select
                                                                                value={modalData.state.bulkUpdateData.fixedPrice[rooms.id]}
                                                                                onChange={modalData.bulkUpdateData('fixedPrice', rooms.id, true)}
                                                                                InputLabelProps={{ shrink: true }}
                                                                                disabled={modalData.state.isSkipDateModal}
                                                                            >
                                                                                {modalData.state.preDefinedRates.map((option, index) => (
                                                                                    <MenuItem key={index} value={option}>
                                                                                        {modalData.state.currency.symbol + option}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>
                                                                        </FormControl>
                                                                    } */}

                                                                    {/* {!modalData.state.featuresEnabled.includes(13) && */}
                                                                    <Tooltip
                                                                        classes={{
                                                                            popper: "toolTipPooper",
                                                                            tooltip: classes.htmlTooltip,
                                                                        }}
                                                                        title={!roomData.is_reference_room ? t(`Please go to your Reference ${modalData.state.room_apartment_space_name} Type to edit the Fix Prices.`) : ""}
                                                                    >
                                                                        <FormControl required margin="none" className={classes.formControlLeft} style={{ marginTop: "-16px", padding: "8px 0px" }}>
                                                                            <TextField
                                                                                type="number"
                                                                                className={classes.textFieldAdj}
                                                                                disabled={is_old}
                                                                                // style={{ width: "250px" }}
                                                                                disabled={modalData.state.calLoader == "fixedPrice" || modalData.state.isSkipDateModal}
                                                                                placeholder={t("Fix Prices")}
                                                                                // error={modalData.state.error.includes('upload_price')}
                                                                                id="standard-name"
                                                                                value={modalData.state.bulkUpdateData.fixedPrice[rooms.id]}
                                                                                onChange={modalData.bulkUpdateData('fixedPrice', rooms.id, true)}                                                                                
                                                                                InputProps={{
                                                                                    startAdornment: <InputAdornment position="start">{modalData.state.currency.symbol}</InputAdornment>,
                                                                                    endAdornment: <InputAdornment position="end">
                                                                                        {(!rooms.is_reference_room && modalData.state.calLoader == "fixedPrice") ? 
                                                                                            <div className={classes.defaultRate} style={{ display:"flex", flexDirection:"row",float: "right", marginLeft: "16px" }}>
                                                                                                {modalData.state.calLoader ? t("Recalculating") : ""}
                                                                                                {modalData.state.calLoader ? <CircularProgress color="primary" size={14} style={{ marginLeft: "4px", marginTop:"3px" }} /> : ""}
                                                                                            </div>
                                                                                            :
                                                                                            <Typography className={classes.defaultRate} onClick={this.focusTextInput} >                                                                                                
                                                                                            </Typography>
                                                                                        }
                                                                                    </InputAdornment>,
                                                                                }}
                                                                            />
                                                                            {modalData.state.popupError.fixedPrice && modalData.state.popupError.fixedPrice.includes(rooms.id) ? <span class='error-message'>{t('* Please enter valid price')}</span> : ''}
                                                                        </FormControl>
                                                                    </Tooltip>
                                                                    {/* } */}
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </Table>
                                                {/* {!modalData.state.predefined_rate && modalData.state.price_type && modalData.state.price_type == 'fix' && modalData.state.popupRoomData.is_reference_room && */}
                                            </Grid>

                                        </div>
                                    </div>
                                    {/* <Grid container>
                                        {(modalData.state.popupRoomData && modalData.state.popupRoomData.is_reference_room) &&
                                            <FormControl required className={classes.adjustmentTextfield}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={modalData.state.isSkipDateModal}
                                                            onChange={modalData.handleisSkipDateModal}
                                                        />
                                                    }
                                                    label={t("Skip Date from Pricing")}
                                                />
                                            </FormControl>
                                        }
                                    </Grid> */}
                                </>
                            }

                        </Grid>
                    }

                    {modalData.state.modalTab == 3 &&
                        <Grid item xs={12} >
                            <MuiThemeProvider theme={CalendargetMuiTheme2}>
                                <MUIDataTable
                                    data={dataRooms}
                                    columns={columnsRooms}
                                    options={optionsRooms}
                                    style={{ marginTop: "-55px" }}
                                />
                            </MuiThemeProvider>
                        </Grid>
                    }

                    {modalData.state.modalTab == 4 &&
                        <Grid container>
                            <Grid item xs={12} md={5} style={{ paddingLeft: "0px", marginTop: '17px' }}>
                                {/* <Typography variant="subheading" style={{ fontWeight: '500', fontSize: '1.25rem', marginBottom: '15px' }}>{t('Date Adjustments')}</Typography> */}
                                <Table>
                                    
                                    <TableRow className={classes.pmsTableRow} style={{borderBottom:'1px solid #a0a2a663'}}>
                                        <TableCell className={classes.tooltipTableeCell} align="left" style={{ width: "250px" }}>
                                            {t('Base Price + Market Factor + Occupancy/Pickup Factor')}
                                        </TableCell>
                                        <TableCell className={classes.tooltipTableeCell} align="right" >
                                            {suggestedPriceWithoutRestriction}                                            
                                        </TableCell>
                                    </TableRow >
                                    
                                    <TableRow className={classes.pmsTableRow} style={{marginTop:'5px'}}>
                                        <TableCell className={classes.tooltipTableeCell1} align="left" style={{ width: "250px" }}>
                                            {t('Percent Adjustment')}
                                        </TableCell>
                                        <TableCell className={classes.tooltipTableeCell1} align="right" >
                                            {((modalData.state.bulkUpdateData.dailyAdjust ? (modalData.state.bulkUpdateData.dailyAdjust > 0 ? "+" : "-") : "") + Math.abs(modalData.state.bulkUpdateData.dailyAdjust))}%
                                        </TableCell>
                                    </TableRow >
                                    {!isafter365 &&
                                        <TableRow className={classes.pmsTableRow}>
                                            <TableCell className={classes.tooltipTableeCell} align="left" >
                                                {t('Day-of-the-Week')} 
                                                {/* ({modalData.state.modalData ? moment(modalData.state.modalData.date).format('ddd') : ""}) */}
                                            </TableCell>
                                            <TableCell className={classes.tooltipTableeCell} align="right" >
                                                {modalData.state.weekPercentage ? ((modalData.state.weekPercentage > 0 ? "+" : "-") + Math.abs(modalData.state.weekPercentage)) : 0}%
                                            </TableCell>
                                        </TableRow>
                                    }
                                    {!isafter365 &&
                                        <TableRow className={classes.pmsTableRow}>
                                            <TableCell className={classes.tooltipTableeCell} align="left" >
                                                {t('Monthly')} 
                                                {/* ({modalData.state.modalData ? moment(modalData.state.modalData.date).format('MMM') : ""}) */}
                                            </TableCell>
                                            <TableCell className={classes.tooltipTableeCell} align="right" >
                                                {modalData.state.monthPercentage ? ((modalData.state.monthPercentage > 0 ? "+" : "-") + Math.abs(modalData.state.monthPercentage)) : 0}%
                                            </TableCell>
                                        </TableRow>
                                    }
                                    {modalData.state.featuresEnabled.includes(7) &&
                                    <TableRow className={classes.pmsTableRow}>
                                        <TableCell className={classes.tooltipTableeCell} align="left" >
                                            {t('Lead Time Adjustments')}
                                        </TableCell>
                                        <TableCell className={classes.tooltipTableeCell} align="right" >
                                            {modalData.state.modalData.leadPer ? ((modalData.state.modalData.leadPer > 0 ? "+" : "-") + Math.abs(modalData.state.modalData.leadPer)) : 0}%
                                        </TableCell>
                                    </TableRow>
                                    }
                                    {modalData.state.featuresEnabled.includes(9) &&
                                    <>
                                        {/* <Typography align={"left"} variant="subheading" style={{ fontWeight: '500', fontSize: '18px', margin: '15px 0px 0px' }}>{t('Yielding Tags')}</Typography> */}
                                        {/* <Table> */}
                                            {tagName.length ?
                                                tagName.map((idx, elem) => {
                                                    return (
                                                        <>
                                                            <TableRow className={classes.pmsTableRow}>
                                                                <TableCell className={classes.tooltipTableeCell} align="left" >
                                                                    {idx.tag_name}
                                                                </TableCell>
                                                                <TableCell className={classes.tooltipTableeCell} align="right" >
                                                                    {idx.adj ? ((idx.adj > 0) ? "+" : "") : ""}
                                                                    {idx.adj ? idx.adj : '0'}%
                </TableCell>
                                                            </TableRow >
                                                        </>
                                                    );
                                                })
                                                : ''
                                                // <TableRow className={classes.pmsTableRow}>
                                                //     <TableCell className={classes.tooltipTableeCell} align="left" >
                                                        
                                                //     </TableCell>
                                                //     <TableCell className={classes.tooltipTableeCell} align="left" >

                                                //     </TableCell>
                                                // </TableRow >

                                            }
                                        {/* </Table> */}
                                    </>
                                }
                                    {modalData.state.featuresEnabled.includes(15) &&
                                        <TableRow className={classes.pmsTableRow}>
                                            <TableCell className={classes.tooltipTableeCell} align="left" >
                                                {t('Target Occupancy')}
                                            </TableCell>
                                            <TableCell className={classes.tooltipTableeCell} align="right" >
                                                {modalData.state.modalData.changes_to_auto_correction_perc ? ((modalData.state.modalData.changes_to_auto_correction_perc > 0 ? "+" : "-") + Math.abs(modalData.state.modalData.changes_to_auto_correction_perc)) : 0}%
                                        </TableCell>
                                        </TableRow>
                                    }
                                    {modalData.state.featuresEnabled.includes(15) &&
                                        <TableRow className={classes.pmsTableRow}>
                                            <TableCell className={classes.tooltipTableeCell} align="left" >
                                                {t('Min Stay')}
                                            </TableCell>
                                            <TableCell className={classes.tooltipTableeCell} align="right" >
                                                {modalData.state.modalData.changes_to_min_stay_perc ? ((modalData.state.modalData.changes_to_min_stay_perc > 0 ? "+" : "-") + Math.abs(modalData.state.modalData.changes_to_min_stay_perc)) : 0}%
                                        </TableCell>
                                        </TableRow>
                                    }
                                </Table>

                                {/* <Typography variant="subheading" style={{ margin: "15px 0px 17px 0px", fontWeight: '500', fontSize: '1.25rem' }}>{modalData.state.popupRoomData ? ("Room Type " + modalData.state.popupRoomData.name) : ""}</Typography> */}
                                <Table>
                                    {roomData && !roomData.is_reference_room &&
                                        <TableRow className={classes.pmsTableRow} >
                                            <TableCell className={classes.tooltipTableeCell} align="left" style={{ width: "160px" }}>
                                                {t('Derivation')}
                                            </TableCell>

                                            <TableCell className={classes.tooltipTableeCell} align="right">
                                                {modalData.state.bulkUpdateData.adjVsRef[modalData.state.selectedRoom] ? ((modalData.state.bulkUpdateData.adjVsRef[modalData.state.selectedRoom] > 0) ? "+" : "-") : ""}
                                                {modalData.state.isAbsolute ? modalData.state.currency.symbol : ""}
                                                {modalData.state.bulkUpdateData.adjVsRef ? Math.abs(modalData.state.bulkUpdateData.adjVsRef[modalData.state.selectedRoom]) : 0}
                                                {!modalData.state.isAbsolute ? "%" : ""}
                                            </TableCell>
                                        </TableRow >
                                    }
                                    {(modalData.state.featuresEnabled.includes(6) || roomData.is_reference_room) &&
                                        <TableRow className={classes.pmsTableRow}>
                                            <TableCell className={classes.tooltipTableeCell} align="left">
                                                {t('Minimum Price')}
                                            </TableCell>
                                            <TableCell className={classes.tooltipTableeCell} align="right">
                                                {/* {modalData.state.modalMinPrice > 0 ? "+" : "-"} */}
                                                {modalData.state.currency.symbol + new Intl.NumberFormat('ja-JP').format(Math.abs(modalData.state.bulkUpdateData.minPrice[modalData.state.selectedRoom]))}
                                            </TableCell>
                                        </TableRow>
                                    }
                                    {(modalData.state.featuresEnabled.includes(6) || roomData.is_reference_room) &&
                                        <TableRow className={classes.pmsTableRow} style={{borderBottom:'1px solid #a0a2a663'}}>
                                            <TableCell className={classes.tooltipTableeCell} align="left">
                                                {t('Maximum Price')}
                                            </TableCell>
                                            <TableCell className={classes.tooltipTableeCell} align="right">
                                                {/* {modalData.state.modalmaxPrice > 0 ? "+" : "-"} */}
                                                {modalData.state.currency.symbol + new Intl.NumberFormat('ja-JP').format(Math.abs(modalData.state.bulkUpdateData.maxPrice[modalData.state.selectedRoom]))}
                                            </TableCell>
                                        </TableRow>
                                    }
                                    <TableRow className={classes.pmsTableRow} >
                                            <TableCell className={classes.tooltipTableeCell1} align="left">
                                            { modalData.state.featuresEnabled.includes(13) ?t("Recommended BAR Level"):t("Recommended Price")}
                                            </TableCell>
                                            <TableCell className={classes.tooltipTableeCell1} align="right">
                                        
                                            {modalData.state.modalData.suggested_price ? modalData.state.currency.symbol+ new Intl.NumberFormat('ja-JP').format(modalData.state.modalData.suggested_price) : 'n.a.'}
                                            </TableCell>
                                    </TableRow>
                                    
                                </Table>

                                
                            </Grid>

                        </Grid>
                    }

                    {modalData.state.modalTab == 5 &&
                        <Grid container>
                            <Grid item xs={12} style={{ marginTop: "-60px" }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container style={{ height: "33px" }}>
                                            <Grid item xs={12}>
                                                <div className={classes.graphtextDiv} style={{ alignItems: "flex-end" }}>
                                                    <img src={"/img/otaLogo.png"} style={{ height: "30px", marginRight: '8px' }} />
                                                </div>
                                            </Grid>
                                            {/* <Grid item xs={6}>
                                            <div className={classes.graphtextDiv} style={{ alignItems: "flex-end" }}>
                                                <div>
                                                    <Button variant="outlined" className={classes.newTabBtnFirst} onClick={() => modalData.togglePopupView("price")} style={modalData.state.popupView == "price" ? { background: "#00000014" } : {}}>{t("OTA Insight")}</Button>
                                                    <Button variant="outlined" className={classes.newTabBtnLast} onClick={() => modalData.togglePopupView("OTA")} style={modalData.state.popupView == "OTA" ? { background: "#00000014" } : {}}>{t("RoomPriceGenie Price")}</Button>
                                                </div>
                                            </div>
                                        </Grid> */}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} style={{ paddingTop: "20px", display: "flex", marginBottom: "10px", marginTop: '18px' }}>
                                        <div>
                                            <Typography variant={"h6"} align={"left"} style={{ color: "#000000de" }}>
                                                {maidanFlexPrice != "n.a." ? modalData.state.currency.symbol : ""}
                                                {maidanFlexPrice}
                                                {maidanFlexPricePer != "n.a." ?
                                                    <span variant="subtitle1" style={maidanFlexPricePer ? ((maidanFlexPricePer) > 0 ? { color: "#135138", background: "#BBF2DD", fontWeight: "500", padding: "2px 7px", borderRadius: "5px", marginLeft: "6px", fontSize: '16px' } : { color: "#890921", background: "#FAC6D1", fontWeight: "500", padding: "2px 7px", borderRadius: "5px", marginLeft: "6px", fontSize: '16px' }) : {}}>
                                                        {/* <span style={maidanFlexPricePer > 0 ? { color: "#3dd2bf", fontWeight: "500" } : { color: "#fe87b7", fontWeight: "500" }}> */}
                                                        {(maidanFlexPricePer ? (maidanFlexPricePer > 0 ? " +" : " ") : " ") + maidanFlexPricePer + "%"}
                                                    </span>
                                                    : ""}
                                            </Typography>
                                            <Typography variant={"subTitle1"} align={"left"}>{t("Median Flexible Rate")}</Typography>
                                        </div>
                                        <div style={{ marginLeft: "16px" }}>
                                            <Typography variant={"h6"} align={"left"} style={{ color: "#000000de" }}>
                                                {maidanCheapPrice != "n.a." ? modalData.state.currency.symbol : " "}
                                                {maidanCheapPrice}
                                                {maidanCheapPricePer != "n.a." ?

                                                    <span variant="subtitle1" style={maidanCheapPricePer ? ((maidanCheapPricePer) > 0 ? { color: "#135138", background: "#BBF2DD", fontWeight: "500", padding: "2px 7px", borderRadius: "5px", marginLeft: "6px", fontSize: '16px' } : { color: "#890921", background: "#FAC6D1", fontWeight: "500", padding: "2px 7px", borderRadius: "5px", marginLeft: "6px", fontSize: '16px' }) : {}}>
                                                        {/* <span style={maidanCheapPricePer > 0 ? { color: "#3dd2bf", fontWeight: "500" } : { color: "#fe87b7", fontWeight: "500" }}> */}
                                                        {(maidanCheapPricePer ? (maidanCheapPricePer > 0 ? " +" : " ") : " ") + maidanCheapPricePer + "%"}
                                                        {/* </span> */}
                                                    </span>
                                                    : ""}
                                            </Typography>
                                            <Typography variant={"subTitle1"} align={"left"}>{t("Median Lowest Rate")}</Typography>
                                        </div>
                                        <div style={{ marginLeft: "16px" }}>
                                            <Typography variant={"h6"} align={"left"} style={{ color: "#000000de" }}>{modalData.state.modalData.price !== undefined ?
                                                <>
                                                    {modalData.state.currency.symbol}
                                                    
                                                    {modalData.state.modalData.price ? new Intl.NumberFormat('ja-JP').format(modalData.state.modalData.price) : 0}
                                                </>
                                                :
                                                "n.a."
                                            }</Typography>
                                            <Typography variant={"subTitle1"} align={"left"}>{t("RoomPriceGenie Price")}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MuiThemeProvider theme={CalendargetMuiTheme2}>
                                            <MUIDataTable
                                                data={data}
                                                columns={columns}
                                                options={options}
                                                style={{ marginTop: "-8px" }}
                                            />
                                        </MuiThemeProvider>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }


                </Grid>
                
                <div className={`${classes.buttonGroupBottom } ${classes.sidePopupFooter}`} >
                        <div >
                        {modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_sync == 2 && modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider &&
                            <Button disabled={modalData.state.disableSave || modalData.state.loadingSave || modalData.state.calLoader || modalData.state.modalData.isPastDate } onClick={() => modalData.uploadSingleModal(true)} className={classes.saveUploadBtn} variant="contained" color="primary" >
                                {t("Upload Prices")}
                            </Button>
                        }
                        <Button disabled={modalData.state.disableSave || modalData.state.loadingSave || modalData.state.calLoader || modalData.state.modalData.isPastDate} onClick={() => { modalData.saveSkipAdjustData(); }} className={[classes.saveUploadBtn]}  variant="outlined"   color="primary"  >
                            {t("Save")}{/* {(modalData.state.price_type == 'skip' || !(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_sync == 2 && modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider)) ? t("Save") : t("Save & Upload")} */}
                            {modalData.state.loadingSave && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                        </Button>

                        {modalData.state.modalTab == 2 &&
                            <Button disabled={modalData.state.modalData.isPastDate}  onClick={() => modalData.setDefaultValues()} className={classes.saveUploadBtn} variant="outlined" color="primary" >
                                {t("Remove Adjustments")}
                                {modalData.state.removeAdjustLoader && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                            </Button>
                        }
                         <Button onClick={modalData.handleClose} style={{background:"white"}} className={[classes.saveUploadBtn, "cancelBtn"]}>{t("Cancel")}</Button>
                    </div>
                </div>

            </SwipeableDrawer >)
    },
    monthlyAdjustModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        let percentAdorn = <Typography variant="body2" className={classes.adornment}>%</Typography>;

        let comp_data = [];
        let ref_data = [];
        let avg_data = [];
        let maxvalue = 0;

        if (modalData.state.averageWeekMonth.comp_adjusted_price_averages) {
            Object.keys(modalData.state.averageWeekMonth.comp_adjusted_price_averages.monthly).map((key) => {
                comp_data.push(modalData.state.averageWeekMonth.comp_adjusted_price_averages.monthly[key])
                if (modalData.state.averageWeekMonth.comp_adjusted_price_averages.monthly[key] && modalData.state.averageWeekMonth.comp_adjusted_price_averages.monthly[key] > maxvalue) {
                    maxvalue = modalData.state.averageWeekMonth.comp_adjusted_price_averages.monthly[key]
                }
            })
        }

        if (modalData.state.averageWeekMonth.pms_price_averages) {
            Object.keys(modalData.state.averageWeekMonth.pms_price_averages.monthly).map((key) => {
                avg_data.push(modalData.state.averageWeekMonth.pms_price_averages.monthly[key])
                if (modalData.state.averageWeekMonth.pms_price_averages.monthly[key] && modalData.state.averageWeekMonth.pms_price_averages.monthly[key] > maxvalue) {
                    maxvalue = modalData.state.averageWeekMonth.pms_price_averages.monthly[key]
                }
            })
        }

        if (modalData.state.monthlyData && modalData.state.averageWeekMonth.comp_adjusted_price_averages) {
            let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

            months.map((val, index) => {
                let montData = modalData.state.monthlyData[val];
                let monthvalue = 0;

                if (comp_data[index]) {
                    monthvalue = ((comp_data[index] * (1 + (montData.standard / 100))))
                }

                ref_data.push(parseFloat(monthvalue));
            })
        }

        const data = {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nove", "Dec"],
            datasets: modalData.props.userSession.user.is_staff ? [
                {
                    label: "Level of Local Market",
                    data: comp_data,
                    backgroundColor: "#541388",
                }, {
                    label: "Price Suggestions",
                    data: ref_data,
                    backgroundColor: "#51c0c0",
                },
                {
                    label: "PMS Price",
                    data: avg_data,
                    backgroundColor: "#dbdee2",
                }
            ]
                :
                [
                    {
                        label: "Level of Local Market",
                        data: comp_data,
                        backgroundColor: "#541388",
                    }, {
                        label: "Price Suggestions",
                        data: ref_data,
                        backgroundColor: "#51c0c0",
                    }
                ]
        }

        const options = {
            maintainAspectRatio: false,
            legend: {
                labels: {
                    boxWidth: 11,
                }
            },
            // responsive: true,
            title: {
                display: false,
                text: 'Chart.js Line Chart'
            },
            tooltips: {
                mode: 'label'
            },
            hover: {
                mode: 'dataset'
            },
            scales: {
                xAxes: [
                    {
                        display: false,
                        scaleLabel: {
                            show: true,
                            labelString: 'Month'
                        }
                    }
                ],
                yAxes: [
                    {
                        display: true,
                        scaleLabel: {
                            show: true,
                            labelString: 'Value'
                        },
                        ticks: {
                            suggestedMin: 0,
                            suggestedMax: (maxvalue * 0.7)
                        }
                    }
                ]
            }
        }
        const plugins= [{
            beforeInit: function(chart, args, options) {
              chart.legend.afterFit = function() {
                this.height = this.height + 20;
              }
            }
          }]
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                maxWidth={'lg'}
                open={modalData.state.mothlyModal}
                onClose={() => modalData.toggleMonthlyModal()}
                aria-labelledby="responsive-dialog-title"
                maxWidth="lg"
            >
                <DialogTitle id="responsive-dialog-title">
                    {t("Monthly Adjustments")}
                    <CustomTooltip title="" description={t("The competitor hotels that we are monitoring may have a different seasonal cycle to you. In a similar way to the Weekdays Adjustments you may think that the prices it suggests in January are too low for you.")} />
                    <div style={{ float: "right", margin: "-19px -19px 0px 0px" }}>
                        <IconButton aria-label="Close" className={classes.closeButton} onClick={modalData.toggleMonthlyModal} >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div style={{ height: "310px", paddingLeft: "45px", width: "950px" }}>
                        <Bar plugins={plugins} height={310} data={data} options={options} />
                    </div>
                    <MonthlyAdjustment modalDataProps={modalData} />
                </DialogContent>
            </Dialog>
        );
    },
    WeeklyAdjustModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;

        let comp_data = [];
        let ref_data = [];
        let avg_data = [];
        let maxvalue = 0;

        if (modalData.state.averageWeekMonth.comp_adjusted_price_averages) {
            comp_data.push(modalData.state.averageWeekMonth.comp_adjusted_price_averages.weekly[7])
            Object.keys(modalData.state.averageWeekMonth.comp_adjusted_price_averages.weekly).map((key) => {
                if (key < 7) {
                    comp_data.push(modalData.state.averageWeekMonth.comp_adjusted_price_averages.weekly[key])
                }
                if (modalData.state.averageWeekMonth.comp_adjusted_price_averages.weekly[key] && modalData.state.averageWeekMonth.comp_adjusted_price_averages.weekly[key] > maxvalue) {
                    maxvalue = modalData.state.averageWeekMonth.comp_adjusted_price_averages.weekly[key]
                }
            })
        }

        if (modalData.state.averageWeekMonth.pms_price_averages) {
            avg_data.push(modalData.state.averageWeekMonth.pms_price_averages.weekly[7])
            Object.keys(modalData.state.averageWeekMonth.pms_price_averages.weekly).map((key) => {
                if (key < 7) {
                    avg_data.push(modalData.state.averageWeekMonth.pms_price_averages.weekly[key])
                }
                if (modalData.state.averageWeekMonth.pms_price_averages.weekly[key] && modalData.state.averageWeekMonth.pms_price_averages.weekly[key] > maxvalue) {
                    maxvalue = modalData.state.averageWeekMonth.pms_price_averages.weekly[key]
                }
            })
        }

        if (modalData.state.weeklyData && modalData.state.averageWeekMonth.comp_adjusted_price_averages) {
            const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
            days.map((val, index) => {
                let weekData = modalData.state.weeklyData[val];
                let weekVal = 0;

                if (comp_data[index]) {
                    weekVal = ((comp_data[index] * (1 + (weekData.standard / 100))))
                }

                ref_data.push(parseFloat(weekVal));
            })
        }


        const data = {
            labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            datasets: modalData.props.userSession.user.is_staff ? [
                {
                    label: "Level of Local Market",
                    data: comp_data,
                    backgroundColor: "#541388",
                }, {
                    label: "Price Suggestions",
                    data: ref_data,
                    backgroundColor: "#51c0c0",
                },
                {
                    label: "PMS Price",
                    data: avg_data,
                    backgroundColor: "#dbdee2",
                }
            ]
                :
                [
                    {
                        label: "Level of Local Market",
                        data: comp_data,
                        backgroundColor: "#541388",
                    }, {
                        label: "Price Suggestions",
                        data: ref_data,
                        backgroundColor: "#51c0c0",
                    }
                ]
        }


        const options = {
            maintainAspectRatio: false,
            legend: {
                labels: {
                    boxWidth: 11,
                }
            },
            // responsive: true,
            title: {
                    display: false,
                text: 'Chart.js Line Chart'
            },
            tooltips: {
                mode: 'label'
            },
            hover: {
                mode: 'dataset'
            },
            scales: {
                xAxes: [
                    {
                        display: false,
                        scaleLabel: {
                            show: true,
                            labelString: 'Month'
                        }
                    }
                ],
                yAxes: [
                    {
                        display: true,
                        scaleLabel: {
                            show: true,
                            labelString: 'Value'
                        },
                        ticks: {
                            suggestedMin: 0,
                            suggestedMax: (maxvalue * 0.7)
                        }
                    }
                ]
            }
        }


        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                maxWidth={'lg'}
                open={modalData.state.weeklyModal}
                onClose={() => modalData.toggleWeeklyModal()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {t("Day-of-the-Week Adjustments")}
                    <CustomTooltip title="" description={t("The competitor hotels that we are monitoring may have a different weekly cycle to you. That is to say that they may have more business clients than you and so would have higher prices on a Wednesday than you but lower prices on a Saturday. Weekdays Adjustments enables you to adjust your daily prices so that they are right for your hotel.")} />
                        <div style={{ float: "right", margin: "-19px -19px 0px 0px" }}>
                        <IconButton aria-label="Close" className={classes.closeButton} onClick={modalData.toggleWeeklyModal} >
                                <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div style={{ height: "310px", paddingLeft: "70px", width: "950px" }}>
                        <Bar height={310} data={data} options={options} />
                    </div>
                    <WeeklyAdjustment modalDataProps={modalData} />
                </DialogContent>
            </Dialog>
        );
    },
    dateSkipModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        let currAdorn = <Typography variant="subtitle1" className={classes.adornment}>{modalData.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol}</Typography>;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.openSkipDate}
                onClose={() => modalData.toggleSkipDateModal()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Skip Date")}</DialogTitle>
                <DialogContent>
                    <FormControl margin="normal" className={classes.formControl}>
                        <InputLabel htmlFor="editName">{t("Name")}</InputLabel>
                        <Input
                            fullWidth
                            onChange={modalData.handleSkipDate('name')}
                            value={modalData.state.skipData.name}
                        />
                        {modalData.state.error.includes("editName") ? <span class='error-message'>{t('* Please enter Name')}</span> : ''}
                    </FormControl>
                    <FormControl margin="normal" className={classes.formControl} style={{ marginLeft: "20px" }}>
                        <InputLabel htmlFor="editFixedPrice">{t("Fixed Price")}</InputLabel>
                        <Input
                            type="number"
                            fullWidth
                            onChange={modalData.handleSkipDate('fixed_price')}
                            value={modalData.state.skipData.fixed_price}
                            startAdornment={currAdorn}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    
                    <Button onClick={modalData.updateSkipDate} variant="contained" color="primary" >
                        {t("Save & Run Pricing")}
                    </Button>
                    <Button onClick={() => modalData.toggleSkipDateModal()} className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    DailyAdjustModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={false}
                maxWidth={'xs'}
                open={modalData.state.openDateAdjustment}
                onClose={modalData.toggleDateAdjustment}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("My event factor: ")} {moment(modalData.state.modalData.date).format('dddd, D MMMM YYYY')}</DialogTitle>
                < DialogContent >
                    <FormControl margin="normal" required className={classes.fullWidth}>
                        <InputLabel htmlFor="adjustment">{t("Adjustment")}</InputLabel>
                        <Input
                            type="number"
                            fullWidth
                            onChange={modalData.handleDailyUpdate()}
                            endAdornment={'%'}
                            value={modalData.state.singleAdjustment}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                   
                    <Button onClick={modalData.updateDailyAdjustment} variant="contained" color="primary" >
                        {t("Save & Run Pricing")}
                    </Button>
                    <Button onClick={modalData.toggleDateAdjustment} className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    autoUpdateConfirm: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={false}
                maxWidth={'xs'}
                open={modalData.state.openAutoUpdateConfirm}
                onClose={modalData.toggleAutoUpdateConfirm}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{modalData.state.is_update_to_pms ? t("Stop Auto-upload") : t("Auto-upload is a great decision!")}</DialogTitle>
                < DialogContent >
                    {modalData.state.is_update_to_pms ?
                        <DialogContentText>
                            {t('This will stop RoomPriceGenie from uploading your prices automatically. To upload the latest prices, you now need to click the Upload button manually. ')}
                        </DialogContentText>
                        :
                        <>
                            <DialogContentText>
                                {t(`With auto-upload, prices will be uploaded automatically to your ${modalData.state.pms_channel_manager_text} several times a day. This unlocks your full potential and will further increase your revenue, while you get other things done.`)}
                            </DialogContentText>
                            {/* <DialogContentText style={{ marginTop: "20px" }}>
                                <b>{t('Attention')}</b>
                                {t(': if you currently have single day prices changed manually, they will be overwritten by the auto-upload. In order to avoid this, just use the "Skip Date" function in the Date Adjustments. ')}
                            </DialogContentText> */}
                        </>
                    }
                </DialogContent>
                <DialogActions>
                   
                    {!modalData.state.is_update_to_pms &&
                        <Button onClick={() => modalData.handleUpdatePMS()} variant="contained" color="primary" >
                            {t("Start")}
                        </Button>
                    }
                    {modalData.state.is_update_to_pms &&
                        <Button onClick={() => modalData.handleUpdatePMS()} variant="contained" className={classes.dangerBtnHvr} color="primary" >
                            {t("Stop")}
                        </Button>
                    }
                     <Button onClick={modalData.toggleAutoUpdateConfirm}  className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    bulkUpdate: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        let fieldList = [];

        if (modalData.state.bulkUpdateType == 1) {
            fieldList = [
                { label: "Adjustment Monthly", value: "monthly" },
                { label: "Adjustment Weekly", value: "weekday" },
                { label: "Adjustment Single", value: "single" },
                { label: "Aggressiveness", value: "aggressiveness" },
                { label: "Skip Date Name", value: "skip_date" },
                { label: "Skip Date Fixed Price", value: "fixed_price" },
            ];
        }

        if (modalData.state.bulkUpdateType == 2) {
            fieldList = [
                { label: "Min Price", value: "min_price" },
                { label: "Max Price", value: "max_price" },
                { label: `Adjustment To Ref. ${modalData.state.room_apartment_space_name}`, value: "adjustment_to_reference_room" },
                { label: `Adjustment To Ref. ${modalData.state.room_apartment_space_name} (absolute)`, value: "adjustment_to_reference_room_is_absolute" }
            ];
        }

        return (
            <Dialog
                fullScreen={false}
                open={modalData.state.openBulkUpdate}
                onClose={modalData.toggleBulkUpdate}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t('Bulk Update')}</DialogTitle>
                < DialogContent >
                <MuiThemeProvider theme={CalendardateTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            minDate={moment()}
                            margin="normal"
                            label={t("Start Date")}
                            value={moment(modalData.state.bulkStartDate)}
                            onChange={modalData.updateBulkDate('bulkStartDate')}
                            className={classes.textField}
                            format={"dd/MM/yyyy"}
                            autoOk={true}
                        />
                        <DatePicker
                            minDate={moment(modalData.state.bulkStartDate)}
                            maxDate={moment().add('365', 'd').format('YYYY-MM-DD')}
                            margin="normal"
                            label={t("End Date")}
                            value={moment(modalData.state.bulkEndDate)}
                            onChange={modalData.updateBulkDate('bulkEndDate')}
                            className={classes.textField}
                            format={"dd/MM/yyyy"}
                            autoOk={true}
                        />
                    </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                    <TextField
                        id="standard-name"
                        label="Type 1"
                        select
                        className={classes.textField}
                        value={modalData.state.bulkUpdateType}
                        onChange={modalData.updateBulkUpdateType()}
                        SelectProps={{
                            MenuProps: {
                                className: classes.menu,
                            },
                        }}
                        margin="normal"
                    >
                        <MenuItem key={1} value={1}>
                            {t('Setting Per Date')}
                        </MenuItem>
                        <MenuItem key={2} value={2}>
                            {t(`Setting Per ${modalData.state.room_apartment_space_name} Id`)}
                        </MenuItem >
                    </TextField>
                    {modalData.state.bulkUpdateType == 2 &&
                        <TextField
                            id="standard-name"
                            label={`${modalData.state.room_apartment_space_name}s`}
                            select
                            className={classes.textField}
                            value={modalData.state.bulkUpdateRooms}
                            onChange={modalData.updatebulkUpdateRooms()}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                        >
                            <MenuItem key={1} value={1}>
                               {t(`All ${modalData.state.room_apartment_space_name}s`)}
                            </MenuItem>
                            {modalData.props.roomList.roomList.map((rooms, index) => {
                                return (
                                    <MenuItem key={index} value={rooms.id}>
                                        {rooms.name}
                                    </MenuItem >
                                )
                            })
                            }
                        </TextField>
                    }
                    {modalData.state.bulkUpdateType &&
                        <TextField
                            id="standard-name"
                            label="Type 2"
                            select
                            className={classes.textField}
                            value={modalData.state.bulkUpdateField}
                            onChange={modalData.udpateBulkUpdateField()}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                        >
                            {fieldList.map((val, index) => {
                                return (
                                    <MenuItem key={index} value={val.value}>
                                        {val.label}
                                    </MenuItem>
                                )
                            })
                            }
                        </TextField>
                    }
                    <FormControl required className={classes.currentPriceInput}>
                        <TextField
                            type="number"
                            id="standard-name"
                            label="value"
                            margin="normal"
                            value={modalData.state.bulkValue}
                            onChange={modalData.updatebulkValue}
                        />
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => modalData.updateTable()} variant="contained" color="primary" >
                        {t("Update")}
                    </Button>
                    <Button onClick={modalData.toggleBulkUpdate} className="cancelBtn" >
                          {t("Cancel")}
                    </Button>

                </DialogActions>
            </Dialog>
        );
    },
    happyPricing: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;

        const DialogTitle = withStyles(theme => ({
            root: {
                margin: 0,
                padding: theme.spacing.unit * 2
            },
            closeButton: {
                position: 'absolute',
                right: theme.spacing.unit,
                top: theme.spacing.unit,
                color: theme.palette.grey[500],
                ['@media (max-width: 540px) and (min-width: 400px)']: {
                    width: '15%'
                },
                ['@media (max-width: 399px) and (min-width: 361px)']: {
                    width: '15%'
                },
                ['@media (max-width: 360px) and (min-width: 321px)']: {
                    width: '15%'
                },
                ['@media (max-width: 320px)']: {
                    width: '15%'
                }
            },
            pricingModalTitle: {
                ['@media (max-width: 540px) and (min-width: 400px)']: {
                    width: '80%'
                },
                ['@media (max-width: 399px) and (min-width: 361px)']: {
                    width: '80%'
                },
                ['@media (max-width: 360px) and (min-width: 321px)']: {
                    width: '80%'
                },
                ['@media (max-width: 320px)']: {
                    width: '80%'
                }
            }
        }))(props => {
            const { children, classes, onClose } = props;
            return (
                <MuiDialogTitle disableTypography className={classes.root}>
                    <Typography variant="h6" className={classes.pricingModalTitle}>{children}</Typography>
                    {onClose ? (
                        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
            );
        });

        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            maxWidth='sm'
            open={modalData.state.happyPricing}
            onClose={modalData.happyPricingToggle}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="customized-dialog-title" onClose={modalData.happyPricingToggle}>
                {t("Not happy with this price?")}
            </DialogTitle>
            <DialogContent>
                <Typography variant="subtitle2">{t("Good choice! Even the leading pricing experts would never fully trust an algorithm.")}</Typography>
                <div>
                    <iframe className={classes.happyPriceFrame} src={"/iframe/calendar-Issue.html?name=" + (modalData.state.modalData.room ? modalData.state.modalData.room.name : "") + " - " + (modalData.state.modalData ? moment(modalData.state.modalData.date).format('dddd, D MMMM YYYY') : "") + "&email=" + modalData.props.userSession.user.email}></iframe>
                </div>
            </DialogContent>
            <DialogActions>
            </DialogActions>
        </Dialog>)
    },
    RunManuallmodal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;

        return (<Dialog
            // fullScreen={fullScreen}
            transitionDuration={{ enter: 500, exit: 300 }}
            maxWidth='sm'
            open={modalData.state.ManuallPriceOpen}
            onClose={modalData.handleClickManualPriceClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{modalData.state.is_runPricing ? t("Run Pricing") : t("What dates would you like to run pricing for?")}
            </DialogTitle>
            <DialogContent style={{ paddingBottom: "0px" }}>
                <DialogContentText align="left" className={classes.marginBottom20}>
                    {t("Your prices are optimized multiple times a day based on your latest "+ modalData.state.pms_channel_manager_text +" data. Use this manual pricing run if you want to refresh the data.")}
                </DialogContentText>
                <div className={classes.diagnosticCheck}>

                    {(modalData.props.userSession.user.is_staff || (modalData.props.userSession.user.sub_account_type && (!modalData.props.hotelAuthReducer.hotelDetails.hotel.reseller || modalData.props.hotelAuthReducer.hotelDetails.hotel.reseller.id != 3))) &&
                        <div className={classes.cellDate}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color={'primary'}
                                        className={classes.checkbox}
                                        onChange={modalData.handleDiagnoChange('showCsvPrice')}
                                        value={modalData.state.showCsvPrice}
                                        checked={modalData.state.showCsvPrice}
                                    />
                                }
                                label={t("Diagnostics")}
                            />
                        </div>
                    }
                    {![15, 34, 22, 41, 45, 46, 48, 49, 50, 52, 53, 54, 57, 58, 63, 67, 69].includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) && (modalData.props.userSession.user.is_staff || modalData.props.userSession.user.sub_account_type) &&
                        <div className={classes.cellDate}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color={'primary'}
                                        className={classes.checkbox}
                                        onChange={modalData.changeOnlyPmsRun('get_pms_data')}
                                        value={modalData.state.get_pms_data}
                                    />
                                }
                                label={t("Get latest "+ modalData.state.pms_channel_manager_text + " data")}
                            />
                        </div>
                    }

                    {/* {modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider &&
                        <div>
                            <CustomTooltip description={t("We run the pricing many times a day for you, based on your latest inventory data. Manual runs are meant to test setting changes, for example, increasing your average price. Please only select this option, if you need the latest inventory.")}></CustomTooltip>
                        </div>
                    } */}
                    {/* <div className={classes.cellDate}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color={'primary'}
                                    className={classes.checkbox}
                                    onChange={modalData.handleDiagnoChange('onlyRunAdjustment')}
                                    value={modalData.state.onlyRunAdjustment}
                                    checked={modalData.state.onlyRunAdjustment}
                                />
                            }
                            label={t("Adjustments only")}
                        />
                    </div> */}
                </div>
                
                    <div>
                        <div style={{ marginTop: "20px" }}>{t('Run for:')}</div>
                        <RadioGroup
                            className={classes.radioGroupDates}
                            value={parseInt(modalData.state.last_run_pricing_type)}
                            onClick={(event) => modalData.handlePricingType(event.target.value)}
                        >
                            {/* {!modalData.state.featuresEnabled.includes(5) &&
                                <FormControlLabel value={0} control={<Radio color="secondary" />} label={t('Custom')} />
                            }
                            {!modalData.state.featuresEnabled.includes(5) &&
                                <FormControlLabel value={1} control={<Radio color="secondary" />} label={t('2 Weeks')} />
                            }
                            {!modalData.state.featuresEnabled.includes(5) &&
                                <FormControlLabel value={2} control={<Radio color="secondary" />} label={t('1 Month')} />
                            } */}
                            <FormControlLabel value={3} control={<Radio color="primary" />} label={t('3 Months')} />
                            <FormControlLabel value={4} control={<Radio color="primary" />} label={t('6 Months')} />
                            <FormControlLabel value={5} control={<Radio color="primary" />} label={t('1 Year')} />
                            {modalData.state.featuresEnabled.includes(23) && 
                                <FormControlLabel value={6} control={<Radio color="primary" />} label={t('18 Months')} />
                            }
                        </RadioGroup>
                    </div>
                <div>
                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            minDate={moment()}
                            margin="normal"
                            label={t("Start Date")}
                            value={moment(modalData.state.start_date)}
                            onChange={modalData.changeDate('start_date')}
                            className={classes.textField}
                            format={"dd/MM/yyyy"}
                            autoOk={true}
                            disabled={modalData.state.featuresEnabled.includes(5)}
                        />
                        <DatePicker
                            minDate={moment(modalData.state.start_date)}
                            maxDate={moment().add('365', 'd').format('YYYY-MM-DD')}
                            margin="normal"
                            label={t("End Date")}
                            value={moment(modalData.state.end_date)}
                            onChange={modalData.changeDate('end_date')}
                            className={classes.textField}
                            format={"dd/MM/yyyy"}
                            autoOk={true}
                            disabled={modalData.state.featuresEnabled.includes(5)}
                        />
                    </MuiPickersUtilsProvider> */}

                    <div className={classes.fileCsv}>
                        {(modalData.state.pms_provider == 11 || modalData.state.pms_provider == 32) &&
                            <input type="file" name="" id="" accept=".csv" onChange={modalData.handle_file} />
                        }
                    </div>
                    <div style={{ marginTop: "6px" }}>
                        <Typography variant="subtitle2" color="primary">
                            {!modalData.state.loadPercentage ? ((modalData.state.get_pms_data && modalData.state.socketLoader) ? `Fetching data from ${modalData.state.pms_channel_manager_text} ... ` : '') : ''}
                            {modalData.state.loadPercentage ? (modalData.state.onlyRunAdjustment ? "Applying Adjustments..." : 'Optimizing...') : ""}
                        </Typography>
                        {modalData.state.loadPercentage &&
                            <LinearProgress color="primary" variant="indeterminate" />
                        }

                        {/*
                        {modalData.state.loadPercentage != 0 &&
                            <LinearProgress
                                color="primary"
                                variant="determinate"
                                valueBuffer={modalData.state.loadPercentage + 5}
                                value={modalData.state.loadPercentage} />
                        } */}
                    </div>
                    {modalData.state.showPriceBtn && !modalData.state.is_runPricing &&
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableCell}>{t("Date")}</TableCell>
                                    <TableCell className={classes.tableCell} align="center">{t(`${modalData.state.room_apartment_space_name}s Remaining`)}</TableCell>
                                    <TableCell className={classes.tableCell}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    modalData.state.inventory_data.map((row, index) => {

                                        return (
                                            <TableRow className={classes.row} key={index}>
                                                <TableCell className={classes.tableCell} component="th" scope="row">
                                                    <span className={classes.cellDate}>{moment(row.date).format('dddd')}</span>
                                                    <span className={classes.cellDay}>{row.date}</span>
                                                </TableCell>
                                                <TableCell className={classes.tableCell} align="center">
                                                    <TextField
                                                        id="standard-name"
                                                        margin="normal"
                                                        onChange={modalData.chnageInventary(index)}
                                                        value={row.number_of_rooms}
                                                        className={classes.inventaryInput}
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.tableCell} align="center">
                                                    <Icon color="primary" className={classes.plusIcon} onClick={() => modalData.plusValue(index)}>add_circle</Icon>
                                                    <Icon color="primary" className={classes.plusIcon} onClick={() => modalData.minusValue(index)}>remove_circle</Icon>
                                                </TableCell>
                                            </TableRow>)
                                    })
                                }

                            </TableBody>
                        </Table>
                    }
                </div>
            </DialogContent>
            <DialogActions>
             
                {!modalData.state.showPriceBtn && !modalData.state.is_runPricing &&
                    <Button onClick={modalData.getInventary} className={classes.runPriceBtn} variant="contained" color="primary" >
                        {t("Get Availability")}
                    </Button>
                }

                {modalData.state.showPriceBtn &&
                    <Button disabled={modalData.state.loadData} onClick={modalData.startRunPricing} className={classes.runPriceBtn} variant="contained" color="primary" >
                        {t("Run Pricing")}
                    </Button>
                }
                {modalData.state.showPriceBtn && !modalData.state.is_runPricing &&
                    <Button margin='normal' onClick={modalData.getInventary} className={classes.runPriceBtn} >
                        <Icon>refresh</Icon>
                    </Button>
                }
                <Button disabled={modalData.state.loadData} onClick={modalData.handleClickManualPriceClose} className="cancelBtn"    >
                   {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>)
    },
    udpatePmsType: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            maxWidth='md'
            open={modalData.state.openUpdatePmsType}
            onClose={modalData.toggleupdatePmsType}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t("What time period do you want to auto-upload?")}</DialogTitle>
            <DialogContent className={classes.updatePmsTypeDiv}>
                <div>
                    <Typography>{t("Upload Next:")}</Typography>
                    <RadioGroup
                        className={classes.group}
                        value={modalData.state.updatePmsType}
                        onChange={(event) => modalData.handlePmsType(event)}
                    >
                        {modalData.state.types.filter((val, ind) => (!modalData.state.featuresEnabled.includes(18) || modalData.props.userSession.user.is_staff) || ind < 4).map((val) => {
                            return (<FormControlLabel key={val.key} disabled={modalData.state.skipUpdate >= val.key} style={{display:(val.key == 8 && !modalData.state.featuresEnabled.includes(23)) ? 'none':''}} value={val.key} control={<Radio color="primary" />} label={t(val.value)} />);
                        })}

                    </RadioGroup>
                </div>
                <div className={classes.skipWeekdiv}>
                    <Typography>{t("Don't Upload Next:")}</Typography>
                    <RadioGroup
                        defaultValue="0"
                        className={classes.group}
                        value={modalData.state.skipUpdate}
                        onClick={(event) => modalData.handleSkipUpdate(event)}
                    >
                        <FormControlLabel value={1} control={<Radio color="primary" />} label={t('1 Week')} />
                        <FormControlLabel value={2} control={<Radio color="primary" />} label={t('2 Weeks')} />
                        <FormControlLabel value={3} control={<Radio color="primary" />} label={t('1 Month')} />
                        <FormControlLabel value={4} control={<Radio color="primary" />} label={t('2 Months')} />
                        <FormControlLabel value={5} control={<Radio color="primary" />} label={t('3 Months')} />
                        <FormControlLabel value={0} control={<Radio color="primary" />} label={t('Upload all')}/>    
                    </RadioGroup>
                    {modalData.state.skipValidation && <Typography className={classes.skipValidation} color="error">Sorry. this needs to be shorter than time period to upload!</Typography>}
                </div>
            </DialogContent>
            <DialogActions>
              
                <Button onClick={() => modalData.updateSkipDateType()} variant="contained" color="primary" >
                    {t("Save")}
                </Button>
                <Button onClick={() => modalData.toggleupdatePmsType()}  className="cancelBtn">
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>)
    },
    RunPricemodal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        let is_admin = (modalData.props.userSession.user.is_staff) ? true : false;
        let preferred_dates=modalData.props.hotelDetail ? modalData.props.hotelDetail.prefered_date_format.replace(', hh:mm A',''):'DD/MM/YYYY';
        let preferred_date_day=preferred_dates.replace('DD','dd');
        let preferred_date=preferred_date_day.replace('YYYY','yyyy');
        modalData.state.start_monday ? enLocale.options.weekStartsOn = 1 : enLocale.options.weekStartsOn = 0

        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            maxWidth='md'
            open={modalData.state.showUpdatePrice}
            onClose={modalData.toggleUpdatePms}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t(`Upload Prices To ${modalData.state.pms_channel_manager_text}`)}</DialogTitle>
            <DialogContent>                    
                <FormGroup className={classes.radioGroupDates}>
                    <>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={modalData.state.threashold == true ? true : false}
                                    name="threshold"
                                    onChange={(event) => modalData.handleAllPricingUpload(event)}
                                />
                            }
                            label={t('Upload all prices (by default only changes will be uploaded)')}
                        />
                    </>
                </FormGroup>                    
                <RadioGroup
                    className={classes.radioGroupDates}
                    style={{ marginTop: "0px", marginBottom: "20px" }}
                    value={parseInt(modalData.state.last_run_pricing_type)}
                    onClick={(event) => modalData.handlePricingType(event.target.value)}
                >
                    <FormControlLabel value={0} control={<Radio color="primary" />} label={t('Custom')} />
                    <FormControlLabel value={1} control={<Radio color="primary" />} label={t('2 Weeks')} />
                    <FormControlLabel value={2} control={<Radio color="primary" />} label={t('1 Month')} />
                    <FormControlLabel value={3} control={<Radio color="primary" />} label={t('3 Months')} />
                    <FormControlLabel value={4} control={<Radio color="primary" />} label={t('6 Months')} />        
                    <FormControlLabel value={5} control={<Radio color="primary" />} label={t('1 Year')} />
                    {(modalData.state.featuresEnabled.includes(23)) &&
                        <FormControlLabel value={6} control={<Radio color="primary" />} label={t('18 Months')} />
                    }
                </RadioGroup>
                <MuiThemeProvider theme={CalendardateTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                    <DatePicker
                        minDate={moment.utc(modalData.props.hotelAuthReducer.utcTime).tz(modalData.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD')}
                        label={t("Start Date")}
                        value={moment(modalData.state.updatePmsStart)}
                        onChange={modalData.changeDate('updatePmsStart')}
                        className={[classes.textField, classes.textFieldResponsive]}
                        format={preferred_date}
                        InputLabelProps={{ shrink: true }}
                        autoOk={true}
                        keyboard
                        placeholder={preferred_date.toLowerCase()}
                    />
                    <DatePicker
                        maxDate={moment.utc(modalData.props.hotelAuthReducer.utcTime).tz(modalData.props.hotelAuthReducer.hotelDetails.hotel.timezone).add((modalData.state.featuresEnabled.includes(23) ? '18' : "12"), 'months').format('YYYY-MM-DD')}
                        minDate={moment(modalData.state.updatePmsStart)}
                        label={t("End Date")}
                        value={moment(modalData.state.updatePmsEnd)}
                        onChange={modalData.changeDate('updatePmsEnd')}
                        className={[classes.textField, classes.textFieldResponsive]}
                        format={preferred_date}
                        InputLabelProps={{ shrink: true }}
                        autoOk={true}
                        keyboard
                        placeholder={preferred_date.toLowerCase()}
                    />
                    
                </MuiPickersUtilsProvider>
                </MuiThemeProvider>

            </DialogContent>
            <DialogActions>               
                <Button style={(modalData.state.from_Pricing || (!modalData.state.update_status || modalData.props.hotelDetail.last_update_price_pms != null)) ? {} : { background: "#f0f4f9", color: "#414043" }} disabled={modalData.state.uploadLoadig} onClick={() => modalData.updatePricePms()} className={[classes.currentPriceInputBtn, classes.marginResponsive]} variant="contained" color="primary" >
                    {t("Upload")} {modalData.state.uploadLoadig && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                </Button>
                <Button onClick={modalData.toggleUpdatePms} className="cancelBtn" style={{marginLeft:"8px"}} >
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>)
    },
    deleteRunAlgomodal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            maxWidth='md'
            open={modalData.state.showDelete}
            onClose={modalData.closeDeleteModal}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogContent>
                {modalData.state.deleteId ?
                    t("Are you sure you want to delete this Pricing Schedule?") : t("Are you sure you want to delete all pricing schedules?")}
            </DialogContent>
            <DialogActions>
              
                <Button onClick={modalData.deleteAlgo}  variant="contained" color="secondary"  className="dangerBtnHvr">
                    {t("Delete")}
                </Button>
                <Button onClick={modalData.closeDeleteModal}  className="cancelBtn" >
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>)
    },
    updateRunAlgomodal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        // var minutes_nums  = ['00','15','30','45'];
        var hours = [];
        hours.push(<MenuItem disabled value={''}>{'Select Hour'} </MenuItem>);
        for (var i = 0; i <= 23; i++) {
            hours.push(<MenuItem key={i} value={i}>
                {('0' + i).slice(-2)}
            </MenuItem>);
        }
        // var minutes = [];
        // for (var j = 0; j < minutes_nums.length; j++) {
        //     minutes.push(<MenuItem key={minutes_nums[j]} value={minutes_nums[j]}>
        //         {minutes_nums[j]}
        //     </MenuItem>);
        // }
        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            maxWidth='sm'
            open={modalData.state.showEdit}
            onClose={modalData.closeEditModal}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t("Update Pricing Schedule")}</DialogTitle>
            <DialogContent>
                <form>
                    {/* <FormControl margin="normal" required className={classes.formControlLeft}>
                        <TextField
                            error={modalData.state.error.includes("edit_hotel")}
                            id="standard-name"
                            label="Hotel"
                            select
                            value={modalData.state.editModal.hotel}
                            onChange={modalData.updateHandle("hotel")}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                        >
                            {modalData.state.hotelList.map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        {modalData.state.error.includes("edit_hotel") ? <span class='error-message'>{t('* Please select Hotel')}</span> : ''}
                    </FormControl> */}
                    <FormControl margin="normal" required className={classes.formControl}>
                        <InputLabel shrink={true} htmlFor="pms_rate">{t("Name")}</InputLabel>
                        <TextField
                            error={modalData.state.error.includes("edit_name")}
                            id="standard-name"                
                            onChange={modalData.updateHandle("name")}
                            value={modalData.state.editModal.name}
                            placeholder={t('Please enter Name')}
                        />
                        {modalData.state.error.includes("edit_name") ? <span class='error-message'>{t('* Please enter Name')}</span> : ''}
                    </FormControl>
                    <FormControl margin="normal" required className={classes.formControlLeft}>
                        <InputLabel shrink={true} htmlFor="pms_rate">{t("Hour")}</InputLabel>
                        <Select                            
                            displayEmpty
                            error={modalData.state.error.includes("edit_hour")}
                            id="standard-name"                            
                            margin="normal"
                            onChange={modalData.updateHandle("hour")}
                            value={parseFloat(modalData.state.editModal.hour)}
                        >
                            {hours}
                        </Select>
                        {modalData.state.error.includes("edit_hour") ? <span class='error-message'>{t('* Please enter Hour')}</span> : ''}
                    </FormControl>

                    {/*<FormControl margin="normal" required className={classes.formControl}>
                        <InputLabel shrink={true} htmlFor="pms_rate">{t("Minutes")}</InputLabel>
                        <Select                            
                            displayEmpty
                            error={modalData.state.error.includes("edit_minute")}
                            id="standard-name"                            
                            margin="normal"
                            onChange={modalData.updateHandle("minute")}
                            value={parseFloat(modalData.state.editModal.minute)}
                        >
                            {minutes}
                        </Select>
                        {modalData.state.error.includes("edit_minute") ? <span class='error-message'>{t('* Please enter Minute')}</span> : ''}
                    </FormControl> */}
                    <FormControl margin="normal" required className={classes.formControlLeft}>
                        <InputLabel shrink={true} htmlFor="pms_rate">{t("Type")}</InputLabel>
                        <Select                            
                            error={modalData.state.error.includes("edit_type")}
                            id="standard-name"                            
                            displayEmpty
                            value={modalData.state.editModal.type}
                            onChange={modalData.updateHandle("type")}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                        >   
                            <MenuItem disabled value={''}>{'Select Type'}</MenuItem>
                            {modalData.state.types.map(option => (
                                <MenuItem key={option.key} value={option.key}>
                                    {option.value}
                                </MenuItem>
                            ))}
                        </Select>
                        {modalData.state.error.includes("edit_type") ? <span class='error-message'>{t('* Please select Type')}</span> : ''}
                    </FormControl>
                </form>
            </DialogContent>
            <DialogActions>
                
                <Button onClick={modalData.updateAlgo} className={classes.currentPriceInputBtn} variant="contained" color="primary" >
                    {t("Save")}
                </Button>
                <Button onClick={modalData.closeEditModal}   className="cancelBtn" >
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>)
    },
    createRunAlgomodal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        // var minutes_nums  = ['00','15','30','45'];
        var hours = [];
        hours.push(<MenuItem disabled value={''}>{'Select Hour'} </MenuItem>);
        for (var i = 0; i <= 23; i++) {
            hours.push(<MenuItem key={i} value={i}>
                {('0' + i).slice(-2)}
            </MenuItem>);
        }
        // var minutes = [];
        // for (var j = 0; j < minutes_nums.length; j++) {
        //     minutes.push(<MenuItem key={minutes_nums[j]} value={minutes_nums[j]}>
        //         {minutes_nums[j]}
        //     </MenuItem>);
        // }

        return (<Dialog
            fullScreen={fullScreen}
            transitionDuration={{ enter: 500, exit: 300 }}
            maxWidth='sm'
            open={modalData.state.showCreate}
            onClose={modalData.closeCreateModal}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t("Add New Pricing Schedule")}</DialogTitle>
            <DialogContent>
                <form>
                    {/* <FormControl margin="normal" required className={classes.formControlLeft}>
                        <TextField
                            error={modalData.state.error.includes("hotel")}
                            id="standard-name"
                            label={t("Hotel")}
                            select
                            value={modalData.state.createModal.hotel}
                            onChange={modalData.handleCreate("hotel")}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                            {modalData.state.hotelList.map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        {modalData.state.error.includes("hotel") ? <span class='error-message'>{t('* Please select Hotel')}</span> : ''}
                    </FormControl> */}
                    <FormControl margin="normal" required className={classes.formControl}>
                        <InputLabel shrink={true} htmlFor="pms_rate">{t("Name")}</InputLabel>
                        <TextField
                            error={modalData.state.error.includes("name")}
                            id="standard-name"                                        
                            onChange={modalData.handleCreate("name")}
                            value={modalData.state.createModal.name}
                            placeholder={'Please enter Name'}                            
                        />
                        {modalData.state.error.includes("name") ? <span class='error-message'>{t('* Please enter Name')}</span> : ''}
                    </FormControl>
                    <FormControl margin="normal" required className={classes.formControlLeft}>                        
                        <InputLabel shrink={true} htmlFor="pms_rate">{t("Hour")}</InputLabel>
                        <Select

                            className={classes.textfield}
                            error={modalData.state.error.includes("hour")}
                            id="standard-name"
                            label={t("Hour")}
                            margin="normal"
                            onChange={modalData.handleCreate("hour")}
                            value={modalData.state.createModal.hour}                              
                            displayEmpty                          
                        >                            
                            {hours}
                        </Select>

                        {modalData.state.error.includes("hour") ? <span class='error-message'>{t('* Please enter Hour')}</span> : ''}
                    </FormControl>

                    {/*<FormControl margin="normal" required className={classes.formControl}>
                        <InputLabel shrink={true} htmlFor="pms_rate">{t("Minutes")}</InputLabel>
                        <Select
                            displayEmpty
                            error={modalData.state.error.includes("minute")}
                            id="standard-name"                            
                            margin="normal"
                            onChange={modalData.handleCreate("minute")}
                            value={modalData.state.createModal.minute}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >                               
                            {minutes}
                        </Select>

                        {modalData.state.error.includes("minute") ? <span class='error-message'>{t("* Please enter Minute")}</span> : ''}
                    </FormControl> */}
                    <FormControl margin="normal" required className={classes.formControlLeft}>
                        <InputLabel shrink={true} htmlFor="pms_rate">{t("Type")}</InputLabel>
                        <Select
                            error={modalData.state.error.includes("type")}
                            id="standard-name"                            
                            displayEmpty
                            value={modalData.state.createModal.type}
                            onChange={modalData.handleCreate("type")}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="normal"
                        >
                            <MenuItem disabled value={''}>{'Select Type'}</MenuItem>
                            {modalData.state.types.filter(val => val.key != 8 || modalData.state.featuresEnabled.includes(23)).map(option => (
                                <MenuItem key={option.key} value={option.key}>
                                    {option.value}
                                </MenuItem>
                            ))}
                        </Select>
                        {modalData.state.error.includes("type") ? <span class='error-message'>{t('* Please select Type')}</span> : ''}
                    </FormControl>
                </form>
            </DialogContent>
            <DialogActions>
               
                <Button onClick={modalData.createAlgo}  variant="contained" color="primary" >
                    {t("Save")}
                </Button>
                <Button onClick={modalData.closeCreateModal}    className="cancelBtn" >
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>)
    },
    connectionToggleModal: function (modalData) {
        const { t } = modalData.props;
        const { fullScreen, classes } = modalData.props;
        return (
            <Dialog
                fullScreen={fullScreen}
                open={modalData.state.openConnectionModal}
                onClose={modalData.closeConnectionModal()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t(`Disconnect ${modalData.state.pms_channel_manager_text}`)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t(`RoomPriceGenie will get disconnected from your ${modalData.state.pms_channel_manager_text}, hence not be able to fetch your availabilities or upload new prices anymore.`)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                  
                    <Button onClick={modalData.updateConnection(modalData.state.connectionModal.primary)} variant="contained" color="primary" >
                        {t("Disconnect")}
                    </Button>
                    <Button onClick={modalData.closeConnectionModal()}  className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    processConfirmationModal: function (modalData) {
        const { t } = modalData.props;
        const { fullScreen, classes } = modalData.props;
        return (
            <Dialog
                fullScreen={fullScreen}
                open={modalData.state.openConfirmationModal}
                onClose={modalData.closeConfirmationModal()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Reprocess data")}</DialogTitle>
                <DialogContent>

                    <DialogContentText>
                        {t("This will reprocess all inventory and rate updates we received for this property. It's for setup purposes, for example after changing the mapping.")}
                    </DialogContentText>
                    {/* <div style={{ marginTop: "20px", marginRight:"10px" , float:"left" }}>{t('Run for:')}</div>
                    <RadioGroup
                        className={classes.radioGroupDates}
                        style={{ marginTop: "10px", marginBottom: "20px" }}
                        value={parseInt(modalData.state.reprocess_date_type)}
                        onClick={(event) => modalData.handleUpdateType(event.target.value)}
                    >
                        <FormControlLabel value={1} control={<Radio color="primary" />} label={t('30 days')} />
                        <FormControlLabel value={2} control={<Radio color="primary" />} label={t('3 days')} />
                        <FormControlLabel value={3} control={<Radio color="primary" />} label={t('today')} />
                    </RadioGroup> */}
                </DialogContent>
                <DialogActions>
                  
                    <Button onClick={modalData.updateProcessData} variant="contained" color="primary" >
                        {t("Confirm")}
                    </Button>
                    <Button onClick={modalData.closeConfirmationModal()}  className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    algoProgramLogFilterModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;

        return (<Dialog
            fullScreen={fullScreen}
            transitionDuration={{ enter: 500, exit: 300 }}
            maxWidth='md'
            open={modalData.state.openDateFilter}
            onClose={modalData.handlefilterClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t("Search")}</DialogTitle>
            <DialogContent>
            <MuiThemeProvider theme={CalendardateTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        margin="normal"
                        label={t("From Date")}
                        value={modalData.state.start_date}
                        onChange={modalData.changeDate('start_date')}
                        className={classes.textField}
                        format={"dd/MM/yyyy"}
                        autoOk={true}
                    />
                    <DatePicker
                        margin="normal"
                        label={t("To Date")}
                        value={modalData.state.end_date}
                        onChange={modalData.changeDate('end_date')}
                        className={classes.textField}
                        format={"dd/MM/yyyy"}
                        autoOk={true}
                    />
                </MuiPickersUtilsProvider>  
                </MuiThemeProvider>            
            </DialogContent>
            <DialogActions>
            <Button onClick={modalData.handlefilterAlgoLog} className={classes.currentPriceInputBtn} variant="contained" color="primary" >
                    {t("Search")}
                </Button>
                <Button onClick={modalData.handlefilterClose}  className="cancelBtn" >
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>)
    },
    apiLogFilterModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;

        return (<Dialog
            fullScreen={fullScreen}
            transitionDuration={{ enter: 500, exit: 300 }}
            maxWidth='md'
            open={modalData.state.openDateFilter}
            onClose={modalData.handlefilterClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t("Search")}</DialogTitle>
            <DialogContent>
            <MuiThemeProvider theme={CalendardateTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        margin="normal"
                        label={t("Date")}
                        value={modalData.state.date}
                        onChange={modalData.changeDate('date')}
                        className={classes.textField}
                        format={"dd/MM/yyyy"}
                        autoOk={true}
                        InputLabelProps={{ shrink: true }}
                    />
                    
                </MuiPickersUtilsProvider>      
                </MuiThemeProvider>        
            </DialogContent>
            <DialogActions>
            <Button onClick={modalData.handlefilterApiLog} className={classes.currentPriceInputBtn} variant="contained" color="primary" >
                    {t("Search")}
                </Button>
                <Button onClick={modalData.handlefilterClose}  className="cancelBtn" >
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>)
    },
    bulkDateAdjustment: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        let days=[];
        const elasticityList = [
            { id: 1, label: "Ignore Occupancy Changes", value: -1000 },
            { id: 2, label: "Very Low", value: -12 },
            { id: 3, label: "Low", value: -8 },
            { id: 4, label: "Standard", value: -5.5 },
            { id: 5, label: "High", value: -4.25 },
            { id: 6, label: "Very High", value: -3.25 },           
        ]
        const MedianOptions = [
            { id: 1, label: "Short", value: 14 },
            { id: 2, label: "Standard", value: 21 },
            { id: 3, label: "Long", value: 30 },                    
        ]
        if(modalData.state.start_monday){
            days = [ "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday","Sunday"];
        }else{
            days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        }
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        };

        let roomData = {};
        let min = 0;
        let max = 0;
        let adjVsRef = 0;
        if (modalData.state.selectedRoom) {
            roomData = modalData.props.roomList.roomList.filter(val => val.id == modalData.state.selectedRoom)[0];
        }
        let setting = modalData.state.dateSetting;
        let allPricing = modalData.props.priceSettingReducer.priceSetting.hotel.all_room_pricing;
        let preferred_dates=(modalData.props.hotelDetail && modalData.props.hotelDetail.prefered_date_format) ? modalData.props.hotelDetail.prefered_date_format.replace(', hh:mm A',''):'DD/MM/YYYY';
        let preferred_date_day=preferred_dates.replace('DD','dd');
        let preferred_date=preferred_date_day.replace('YYYY','yyyy');
        modalData.state.start_monday ? enLocale.options.weekStartsOn = 1 : enLocale.options.weekStartsOn = 0
       
        let minOccupancy;
        let maxOccupancy;
        modalData.state.pmsList.mapped_data.map((val) => {
            if (val.id == modalData.state.editModalData.pms_room) {
                minOccupancy = val.min_occupancy;
                maxOccupancy = val.max_occupancy;
            }
        }) 
         
        let avgPrice = setting.default[modalData.state.selectedRoom].avg_price;        
        return (
            <SwipeableDrawer
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="right"
                open={modalData.state.bulkDateAdjModal}
                onClose={modalData.bulkDateAdjModalToggle}
                PaperProps={{ style: { maxWidth: "725px", padding: "16px", paddingBottom: "70px", width: "725px", overflow: "hidden" } }}
            >
                <div style={{ padding:"16px 25px" }}>
                    <div style={{ float: "left" }}>
                        <Typography variant="h5" style={{ fontWeight: "500", width: "280px" }}>
                            <span>{t("Bulk Edit")}</span>
                        </Typography>
                    </div>

                    <div style={{  float: "right",marginTop:"-6px" }}>
                        <IconButton aria-label="Close" className={classes.closeButtonPrice} onClick={modalData.bulkDateAdjModalToggle} >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>

                <div style={{ overflow: "auto",padding:"10px 25px" , marginBottom : 50 }}>
                    <div>
                        <div style={{border : "1px solid #ccc", borderRadius:"15px", padding:"0px 10px" }}>
                            <MuiThemeProvider theme={CalendardateTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <DatePicker
                                            style={{ width: "157px" }}
                                            margin="normal"
                                            label={t("From Date")}
                                            value={moment(modalData.state.bulkStartDate)}
                                            onChange={modalData.changeBulkDate('bulkStartDate')}
                                            className={classes.textField}
                                            format={preferred_date}
                                            autoOk={true}
                                            minDate={moment.utc(modalData.props.hotelAuthReducer.utcTime).tz(modalData.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD')}
                                            maxDate={moment.utc(modalData.props.hotelAuthReducer.utcTime).tz(modalData.props.hotelAuthReducer.hotelDetails.hotel.timezone).add('3', 'Y').format('YYYY-MM-DD')}
                                            keyboard
                                            placeholder={preferred_date.toLowerCase()}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <DatePicker
                                            style={{ width: "157px" }}
                                            minDate={moment(modalData.state.bulkStartDate).format('YYYY-MM-DD')}
                                            maxDate={moment.utc(modalData.props.hotelAuthReducer.utcTime).tz(modalData.props.hotelAuthReducer.hotelDetails.hotel.timezone).add('3', 'Y').format('YYYY-MM-DD')}
                                            margin="normal"
                                            label={t("To Date")}
                                            value={moment(modalData.state.bulkEndDate)}
                                            onChange={modalData.changeBulkDate('bulkEndDate')}
                                            className={classes.textField}
                                            format={preferred_date}
                                            autoOk={true}
                                            keyboard
                                            placeholder={preferred_date.toLowerCase()}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                        </Grid>
                                        <Grid item xs={2} style={{ marginTop: "30px"}}>
                                            <span className={classes.pmsContent} style={{ marginLeft: "0px" }}>
                                                <HierarchyText
                                                    // title="$198"     
                                                    title={modalData.state.currency.symbol+modalData.state.bulkAveragePrice}                                           
                                                    label={t("Average Price")}
                                                />
                                            </span>
                                        </Grid>
                                        <Grid item xs={2} style={{ marginTop: "30px"}}>
                                            <span className={classes.pmsContent} style={{ marginLeft: "0px" }}>
                                                <HierarchyText
                                                    // title="$9.130"
                                                    title={modalData.state.currency.symbol+modalData.state.bulkSumOfPrice}                                              
                                                    label={t("Price for") +' '+modalData.state.bulkNumberOfDays+' '+ t("days")}
                                                />
                                            </span>
                                        </Grid>
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </MuiThemeProvider>
                            <div style={{ marginBottom: "18px" }}>
                                {days.map(name => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color={"primary"}
                                                checked={modalData.state.bulkWeekdays.indexOf(name) > -1}
                                                onChange={modalData.handlebulkWeekdays(name)}
                                            />
                                        }
                                        label={t(name.substring(0, 3))}
                                    />
                                ))}
                            </div>
                        </div>
                        
                        {(!modalData.state.featuresEnabled.includes(29) ||  modalData.state.featuresEnabled.includes(1)) &&              
                        <div style={{ marginBottom:(modalData.state.bulkEditType == 3 ||modalData.state.bulkEditType == 1 ) ? "32px":"0px" }}>
                            <FormControl margin="normal" required className={classes.fieldLeft} style={{ minWidth: "250px" }}>
                                <InputLabel shrink={true} htmlFor="pms_rate">{t("Adjustment Type")}</InputLabel>
                                <Select
                                    className={classes.textfield}
                                    value={modalData.state.bulkEditType}
                                    onChange={(event) => modalData.handleChangeBulk(event.target.value)}
                                >
                                    {modalData.state.featuresEnabled.includes(1) &&
                                        <MenuItem value={1} >{t("Percent Adjustments")}</MenuItem>
                                    }
                                    {modalData.state.featuresEnabled.includes(1) &&
                                        <MenuItem value={2} >{t("Minimum and Maximum Prices")}</MenuItem>
                                    }
                                    {modalData.state.featuresEnabled.includes(1) &&
                                        <MenuItem value={3} >{t(`${modalData.state.room_apartment_space_name} Derivation`)}</MenuItem>
                                    }
                                    {modalData.state.featuresEnabled.includes(1) &&
                                        <MenuItem value={4} >{t("Fix Prices")}</MenuItem>
                                    }
                                    {!modalData.state.featuresEnabled.includes(29) &&
                                        <MenuItem value={5} >{t(`Closed ${modalData.state.room_apartment_space_name}s`)}</MenuItem>
                                    }
                                    {modalData.state.featuresEnabled.includes(1) && modalData.state.pmsProviderList.includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) && 
                                        <MenuItem value={6} >{t(`Occupancy Pricing`)}</MenuItem>
                                    }
                                    {modalData.state.featuresEnabled.includes(35) &&
                                        <MenuItem value={7} >{t("Aggressiveness")}</MenuItem>
                                    }
                                    {modalData.state.featuresEnabled.includes(35) &&
                                        <MenuItem value={8} >{t(`Median Booking Window`)}</MenuItem>
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        }   
                        {modalData.state.bulkEditType == 1 &&
                            <Table>
                                <TableHead className={classes.pmsTableRow}>
                                    <TableCell className={classes.tableCell}>{t(modalData.state.room_apartment_space_name)}</TableCell>
                                    <TableCell className={classes.tableCell}>{t("Percent Adjustment")}</TableCell>
                                </TableHead>
                                <TableRow className={classes.pmsTableRow}>
                                    <TableCell className={classes.rowAdj}>
                                        {t(`All ${modalData.state.room_apartment_space_name} Types`)}
                                            </TableCell >
                                    <TableCell className={[classes.rowAdj]}>
                                        <FormControl margin="none" className={classes.formControlLeft} style={{ marginTop: "-16px", padding: "8px 0px" }}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                type="number"
                                                style={{ width: "215px" }}
                                                // error={modalData.state.bulkError.includes('dailyAdjust')}
                                                id="standard-name"
                                                className={classes.textField}
                                                margin="none"
                                                value={modalData.state.bulkUpdateData.dailyAdjust}
                                                onChange={modalData.bulkUpdateData('dailyAdjust')}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                            </TextField>
                                        </FormControl>
                                    </TableCell >
                                </TableRow>
                            </Table>
                        }

                        {modalData.state.bulkEditType == 2 &&
                            <div>                                
                                <FormControlLabel
                                    style={{visibility: (modalData.state.featuresEnabled.includes(6) && !modalData.state.refRoomOnly) ? "visible" : "hidden"}}
                                    control={
                                        <Checkbox
                                            color={"primary"}
                                            checked={modalData.state.deriveFromRef}
                                            onChange={modalData.handlederiveFromRef}
                                        />
                                    }
                                    label={t(`Prefill Derived ${modalData.state.room_apartment_space_name}s`)}
                                />
                                <Table>
                                    <TableHead className={classes.pmsTableRow}>
                                        <TableCell>{t(`${modalData.state.room_apartment_space_name} Types`)}</TableCell>
                                        <TableCell>{t('Minimum Price')}</TableCell>
                                        <TableCell>{t('Maximum Price')}</TableCell>
                                    </TableHead>
                                    {modalData.props.roomList.roomList.sort((a, b) => b.is_reference_room - a.is_reference_room).filter(val => (!modalData.state.refRoomOnly || val.is_reference_room)).map((rooms, index) => {
                                        min = setting.default[rooms.id].min_price;
                                        max = setting.default[rooms.id].max_price;
                                        return (
                                            <TableRow className={classes.pmsTableRow}>
                                                <TableCell className={classes.rowAdj} style={{ padding: "22px 0px", width: "250px", fontWeight: rooms.is_reference_room ? "500" : "400" }}>
                                                    {rooms.name}
                                                </TableCell >
                                                <TableCell className={classes.rowAdj}>
                                                    {(!modalData.state.refRoomOnly || rooms.is_reference_room) && (modalData.state.featuresEnabled.includes(6) || rooms.is_reference_room) &&
                                                        <FormControl fullWidth  style={{ marginTop: "-16px", padding: "8px 0px",minWidth:"160px" }}>
                                                            <TextField
                                                                type="number"
                                                                disabled={modalData.state.calLoader == "minPrice"}
                                                                // error={modalData.state.bulkError.includes('minPrice')}
                                                                id="standard-name"
                                                                style={{width:"200px"}}
                                                                fullWidth
                                                                margin="none"
                                                                value={modalData.state.bulkUpdateData.minPrice[rooms.id]}
                                                                onChange={modalData.bulkUpdateData('minPrice', rooms.id)}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">{modalData.state.currency.symbol}</InputAdornment>,
                                                                    endAdornment: <InputAdornment position="end">
                                                                        {(!rooms.is_reference_room && modalData.state.calLoader == "minPrice") ? 
                                                                            <div className={classes.defaultRate} style={{ display:"flex", flexDirection:"row",float: "right", marginLeft: "16px" }}>
                                                                                {modalData.state.calLoader ? t("Recalculating") : ""}
                                                                                {modalData.state.calLoader ? <CircularProgress color="primary" size={14} style={{ marginLeft: "4px", marginTop:"3px" }} /> : ""}
                                                                            </div>
                                                                            :
                                                                            <Typography className={classes.defaultRate} onClick={this.focusTextInput} >
                                                                                <span>{t('Default')}</span>
                                                                                <span>{modalData.state.currency.symbol + min}</span>
                                                                            </Typography>
                                                                        }
                                                                    </InputAdornment>,
                                                                }}
                                                            >
                                                            </TextField>
                                                            {modalData.state.bulkError.minPrice && modalData.state.bulkError.minPrice.includes(rooms.id) &&
                                                                <span class='error-message'>{t('* Please enter valid Min Price')}</span>
                                                            }
                                                        </FormControl>
                                                    }
                                                    {!((!modalData.state.refRoomOnly || rooms.is_reference_room) && (modalData.state.featuresEnabled.includes(6) || rooms.is_reference_room)) &&
                                                        <>                                                            
                                                            <span style={{float:"right", marginLeft:"8px"}}>{modalData.state.currency.symbol}{modalData.state.bulkUpdateData.minPrice[rooms.id]}</span>
                                                            {(!rooms.is_reference_room && modalData.state.calLoader == "minPrice") &&
                                                            <div className={classes.defaultRate} style={{ display:"flex", flexDirection:"row",float: "right", marginLeft: "16px" }}>
                                                                {modalData.state.calLoader ? t("Recalculating") : ""}
                                                                {modalData.state.calLoader ? <CircularProgress color="primary" size={14} style={{ marginLeft: "4px", marginTop:"3px" }} /> : ""}
                                                            </div>
                                                            }
                                                        </>
                                                    }
                                                </TableCell >
                                                <TableCell className={classes.rowAdj}>
                                                    {(!modalData.state.refRoomOnly || rooms.is_reference_room) && (modalData.state.featuresEnabled.includes(6) || rooms.is_reference_room) &&
                                                        <FormControl fullWidth  style={{ marginTop: "-16px", padding: "8px 0px",minWidth:"160px" }}>
                                                            <TextField
                                                                type="number"
                                                                style={{width:"200px"}}
                                                                // error={modalData.state.bulkError.includes('maxPrice') || modalData.state.bulkError.includes('maxPriceLow')}
                                                                id="standard-name"
                                                                disabled={modalData.state.calLoader == "maxPrice"}
                                                                fullWidth
                                                                margin="none"
                                                                value={modalData.state.bulkUpdateData.maxPrice[rooms.id]}
                                                                onChange={modalData.bulkUpdateData('maxPrice', rooms.id)}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">{modalData.state.currency.symbol}</InputAdornment>,
                                                                    endAdornment: <InputAdornment position="end">
                                                                        {(!rooms.is_reference_room && modalData.state.calLoader == "maxPrice") ? 
                                                                            <div className={classes.defaultRate} style={{ display:"flex", flexDirection:"row",float: "right", marginLeft: "16px" }}>
                                                                                {modalData.state.calLoader ? t("Recalculating") : ""}
                                                                                {modalData.state.calLoader ? <CircularProgress color="primary" size={14} style={{ marginLeft: "4px", marginTop:"3px" }} /> : ""}
                                                                            </div>
                                                                            :
                                                                        <Typography className={classes.defaultRate}>
                                                                            <span>{t('Default')}</span>
                                                                            <span>{modalData.state.currency.symbol + max}</span>
                                                                        </Typography>
                                                                    }
                                                                    </InputAdornment>,
                                                                }}
                                                            >
                                                            </TextField>
                                                            {/* {modalData.state.bulkError.includes('maxPrice') &&
                                                                <span class='error-message'>{t('* Please enter valid Max Price')}</span>
                                                            } */}
                                                            {modalData.state.bulkError.maxPrice && modalData.state.bulkError.maxPrice.includes(rooms.id) &&
                                                                <span class='error-message'>{t('Max Price should be greater than Min Price')}</span>
                                                            }
                                                        </FormControl>
                                                    }
                                                    {!((!modalData.state.refRoomOnly || rooms.is_reference_room) && (modalData.state.featuresEnabled.includes(6) || rooms.is_reference_room)) &&
                                                        <>                                                                                                                 
                                                            <span style={{float:"right", marginLeft:"8px"}}>{modalData.state.currency.symbol}{modalData.state.bulkUpdateData.maxPrice[rooms.id]}</span>
                                                            {(!rooms.is_reference_room && modalData.state.calLoader == "maxPrice") && 
                                                                <div className={classes.defaultRate} style={{ display:"flex", flexDirection:"row",float: "right", marginLeft: "16px" }}>
                                                                    {modalData.state.calLoader ? t("Recalculating") : ""}
                                                                    {modalData.state.calLoader ? <CircularProgress color="primary" size={14} style={{ marginLeft: "4px", marginTop:"3px" }} /> : ""}
                                                                </div>
                                                            }  
                                                        </>
                                                    }
                                                </TableCell >
                                            </TableRow>
                                        )
                                    })}
                                </Table>
                            </div>
                        }


                        {modalData.state.bulkEditType == 3 &&
                            <Table>
                                <TableHead className={classes.pmsTableRow}>
                                    <TableCell>{t(`${modalData.state.room_apartment_space_name} Types`)}</TableCell>
                                    <TableCell>{t(`Derivation from Ref. ${modalData.state.room_apartment_space_name}`)}</TableCell>
                                </TableHead>
                                {modalData.props.roomList.roomList.sort((a, b) => b.is_reference_room - a.is_reference_room).map((rooms, index) => {
                                    let avgPrice = setting.default[rooms.id].avg_price;
                                    let adjVsRef = setting.default[rooms.id].adjustment_to_reference_room;

                                    return (
                                        <TableRow className={classes.pmsTableRow}>
                                            <TableCell className={classes.rowAdj}>
                                                {rooms.name}
                                            </TableCell >
                                            <TableCell className={classes.rowAdj}>
                                                {!rooms.is_reference_room &&
                                                    <FormControl fullWidth style={{ marginTop: "-16px", padding: "8px 0px" }}>
                                                        <TextField
                                                            type="number"
                                                            id="standard-name"
                                                            style={{ width: "215px" }}
                                                            fullWidth
                                                            InputLabelProps={{ shrink: true }}
                                                            margin="none"
                                                            disabled={!modalData.state.featuresEnabled.includes(1)}
                                                            value={modalData.state.bulkUpdateData.adjVsRef[rooms.id]}
                                                            onChange={modalData.bulkUpdateData('adjVsRef', rooms.id)}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">{modalData.state.isAbsolute ? modalData.state.currency.symbol : ""}</InputAdornment>,
                                                                endAdornment: <InputAdornment><Typography className={classes.defaultRate}>
                                                                    <span>{t('Default')}</span>
                                                                    <span>{adjVsRef ? ((adjVsRef > 0) ? "+" : "-") : ""}{modalData.state.isAbsolute ? modalData.state.currency.symbol : ""}{adjVsRef ? Math.abs(parseInt(adjVsRef)) : adjVsRef}{modalData.state.isAbsolute ? "" : "%"}</span>
                                                                </Typography></InputAdornment>,
                                                            }}
                                                        >
                                                        </TextField>
                                                    </FormControl>
                                                }
                                                {rooms.is_reference_room &&
                                                    <Typography style={{ padding: "24px 0px" }}>{t('Base Price')} {modalData.state.currency.symbol}{avgPrice}</Typography>
                                                }
                                            </TableCell >
                                        </TableRow>
                                    )
                                })}
                            </Table>
                        }

                        {modalData.state.bulkEditType == 4 &&
                            <div>
                                
                                {!modalData.state.refRoomOnly &&
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                // style={{marginTop:'7px'}}
                                                color={"primary"}
                                                checked={modalData.state.deriveFromRef}
                                                onChange={modalData.handlederiveFromRef}
                                            />
                                        }
                                        label={t(`Prefill Derived ${modalData.state.room_apartment_space_name}s`)}
                                    />
                                }
                                <Table>
                                    <TableHead className={classes.pmsTableRow}>
                                        <TableCell>{t(`${modalData.state.room_apartment_space_name} Types`)}</TableCell>
                                        <TableCell>{t("Fixed Price")}</TableCell>
                                    </TableHead>
                                    {modalData.props.roomList.roomList.sort((a, b) => b.is_reference_room - a.is_reference_room).filter(val => (!modalData.state.refRoomOnly || val.is_reference_room)).map((rooms, index) => {
                                        return (
                                            <TableRow className={classes.pmsTableRow}>
                                                <TableCell className={classes.rowAdj}>
                                                    {rooms.name}
                                                </TableCell >
                                                <TableCell className={classes.rowAdj}>
                                                    <FormControl margin="none" required className={classes.formControlLeft} style={{ marginTop: "-16px", padding: "8px 0px" }}>
                                                        <TextField
                                                            type="number"
                                                            style={{ width: "215px" }}
                                                            disabled={modalData.state.calLoader == "fixedPrice"}
                                                            // error={modalData.state.bulkError.includes('fixedPrice')}
                                                            id="standard-name"
                                                            className={classes.textField}
                                                            margin="none"
                                                            value={modalData.state.bulkUpdateData.fixedPrice[rooms.id]}
                                                            onChange={modalData.bulkUpdateData('fixedPrice', rooms.id)}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">{modalData.state.currency.symbol}</InputAdornment>,
                                                                endAdornment: <InputAdornment position="end">
                                                                    {(!rooms.is_reference_room && modalData.state.calLoader == "fixedPrice") ? 
                                                                        <div className={classes.defaultRate} style={{ display:"flex", flexDirection:"row",float: "right", marginLeft: "16px" }}>
                                                                            {modalData.state.calLoader ? t("Recalculating") : ""}
                                                                            {modalData.state.calLoader ? <CircularProgress color="primary" size={14} style={{ marginLeft: "4px", marginTop:"3px" }} /> : ""}
                                                                        </div>
                                                                        :
                                                                        <Typography className={classes.defaultRate} onClick={this.focusTextInput} >                                                                                                
                                                                        </Typography>
                                                                    }
                                                                </InputAdornment>,
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        >
                                                        </TextField>
                                                    </FormControl>
                                                    {modalData.state.bulkError.fixedPrice && modalData.state.bulkError.fixedPrice.includes(rooms.id) ? <div class='error-message'>{t('* Please enter valid price')}</div> : ''}
                                                </TableCell >
                                            </TableRow>
                                        )
                                    })}
                                </Table>
                            </div>
                        }

                        { !modalData.state.featuresEnabled.includes(29) && modalData.state.bulkEditType == 5 &&
                        <>
                             <FormControlLabel
                             style={{display:'flex',alignItems:'center'}}
                             control={
                               <Checkbox
                                 color="primary"
                                 checked={modalData.state.closeAllRooms}
                                 style={{marginTop:'-1px'}}
                                 name={"close_all_rooms"}
                                 onChange={(e) => modalData.handleCloseAllRooms(e)}
                               />
                             }
                             label={t("Close All")}
                           />
                          
                            <Table>
                                <TableHead className={classes.pmsTableRow}>
                                    <TableCell>{t(`${modalData.state.room_apartment_space_name} Types`)}</TableCell>
                                    <TableCell>{t(`Closed ${modalData.state.room_apartment_space_name}s`)}</TableCell>
                                </TableHead>
                                {modalData.props.roomList.roomList.sort((a, b) => b.is_reference_room - a.is_reference_room).filter(val => (!modalData.state.refRoomOnly || val.is_reference_room)).map((rooms, index) => {
                                    return (
                                        <TableRow className={classes.pmsTableRow}>
                                            <TableCell className={classes.rowAdj}>
                                                {rooms.name}
                                            </TableCell >
                                            <TableCell className={classes.rowAdj}>
                                                <FormControl margin="none" style={{ marginTop: "-16px", padding: "8px 0px" }}>
                                                    <TextField
                                                        InputLabelProps={{ shrink: true }}
                                                        type="number"
                                                        style={{ width: "215px" }}
                                                        // error={modalData.state.bulkError.includes('closedRooms')}
                                                        id="standard-name"
                                                        className={classes.textField}
                                                        margin="none"
                                                        value={modalData.state.bulkUpdateData.closedRooms[rooms.id]}
                                                        onChange={modalData.bulkUpdateData('closedRooms', rooms.id)}
                                                    >
                                                    </TextField>
                                                </FormControl>
                                            </TableCell >
                                        </TableRow>
                                    )
                                })}
                            </Table>
                            </>
                        }

                        {modalData.state.bulkEditType == 6 &&
                            <Grid xs={12} item>
                                <FormControl margin="normal" required className={classes.fieldLeft} style={{ minWidth: "250px" }}>
                                <InputLabel shrink={true} htmlFor="pms_rate">{t(`${modalData.state.room_apartment_space_name} Type`)}</InputLabel>
                                <Select
                                    className={classes.textfield}
                                    value={modalData.state.selectedRoomBulkEdit}
                                    onChange={(event) => modalData.handleSelectRoomBulkEdit(event, event.target.value)}
                                >
                                    {modalData.props.roomList.roomList.filter(val => val.is_reference_room).map(val =>
                                  <MenuItem value={val.id} style={{ fontWeight: "500" }}>{t(`${modalData.state.room_apartment_space_name} Type`)}: {val.name} {modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 12 ? ("(" + val.extra + ")") : ""} ({(val.is_reference_room ? t("Reference") : t("Derived"))}) </MenuItem>
                                )}
                                {modalData.props.roomList.roomList.filter(val => !val.is_reference_room && (modalData.state.viewType == "adjustment" || (allPricing && (val.pms_rate || [17, 8, 35, 39, 42, 52, 67].includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider))))).map(val =>
                                  <MenuItem value={val.id}>{t(`${modalData.state.room_apartment_space_name} Type`)}:  {val.name} {modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 12 ? ("(" + val.extra + ")") : ""} ({(val.is_reference_room ? t("Reference") : t("Derived"))})</MenuItem>
                                )}
                                </Select>
                            </FormControl>
                                <div style={{ marginTop:"32px"}}>
                                <Table aria-label="simple table">
                                    <TableHead className={classes.pmsTableRow}>
                                        <TableCell>{t("Occupancy (Persons)")}</TableCell>
                                        <TableCell>{t("Price Difference from Default Occupancy Price")}</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {modalData.state.occupancyPrices[modalData.state.selectedRoomBulkEdit] && modalData.state.occupancyPrices[modalData.state.selectedRoomBulkEdit].map((val, index) => (
                                            <>                                               
                                                <TableRow>
                                                    <TableCell component="th" scope="row" style={{ marginTop: '20px', marginBottom: '20px', textAlign: 'left' }}>
                                                        {val.occupancy}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" style={{ marginBottom: '15px' }}>
                                                    {(roomData.default_occupancy == val.occupancy) ?
                                                        <span style={{fontSize:'16px'}}>{t('Base Price')}{':'} {avgPrice ? modalData.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol + avgPrice :''} </span>
                                                        :
                                                        <>
                                                        <FormControl margin="none" required className={classes.formControlLeft} style={{ marginTop: "-16px", padding: "8px 0px" }}>
                                                        <TextField
                                                            type="number"
                                                            id="occupancy_pricing"
                                                            // error={modalData.state.error.includes(index)}
                                                            className={classes.textField}
                                                            style={{width:"200px"}}
                                                            fullWidth
                                                            value={val.derivation}
                                                            onChange={(e) => modalData.handleOccupancyPricingDiff(e, val, index)}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start"><span style={{width: modalData.state.occupancy_percent_derivation ? "5px" : (modalData.state.currency.symbol.length) > 2 ? "48px" : "20px"}}>{(modalData.state.occupancy_percent_derivation ? '': modalData.state.currency.symbol)+(roomData.default_occupancy < val.occupancy ? '+ ':'- ')}</span></InputAdornment>,
                                                                endAdornment: <InputAdornment position="end">
                                                                    { modalData.state.occupancy_percent_derivation ? <span style={{borderRight:"1px solid grey",paddingRight:"2px",marginRight:"3px", color:"#595959", fontSize:"0.875rem"}}>%</span> : "" }
                                                                    <Typography className={classes.defaultRate} onClick={this.focusTextInput} >
                                                                        <span>{t('Default')}</span>
                                                                        <span>{(modalData.state.occupancy_percent_derivation ? '': modalData.state.currency.symbol)  + modalData.state.defaultOccupancy[modalData.state.selectedRoomBulkEdit].find(v => v.occupancy == val.occupancy)?.derivation }{modalData.state.occupancy_percent_derivation ? '%':''}</span>
                                                                        <span></span>
                                                                    </Typography>
                                                                </InputAdornment>
                                                            }}
                                                            // endAdornment={modalData.state.occupancy_percent_derivation ? '%':''}
                                                            // startAdornment={<Typography variant="subtitle1" className={classes.adornment} style={{width: modalData.state.occupancy_percent_derivation ? "5px" : (modalData.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol.length > 2 ? "58px": "30px")}}>{(modalData.state.occupancy_percent_derivation ? "" : modalData.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol)+(roomData.default_occupancy < val.occupancy ? '+':'-')}</Typography>}
                                                            // endAdornment={<Typography variant="subtitle1" className={classes.adornment} style={{width: modalData.state.occupancy_percent_derivation ? "5px" : (modalData.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol.length > 2 ? "58px": "30px")}}>{(modalData.state.occupancy_percent_derivation ? "" : modalData.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol)+(roomData.default_occupancy < val.occupancy ? '+':'-')}</Typography>}
                                                        /> 
                                                        </FormControl>                                                           
                                                        </>
                                                    }
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                                </div>
                            </Grid>
                        }

                        {/* For Aggressiveness values  */}
                        {modalData.state.bulkEditType === 7 &&
                        <Grid xs={12} item>                                                                                            
                            <FormControl margin="normal" className={classes.fieldLeft} style={{ minWidth: "250px" }}>                                
                                <InputLabel style={{width:"max-content"}} shrink={true} htmlFor="price">{t("Aggressiveness")} {t(`(All ${modalData.state.room_apartment_space_name} Types)`)} </InputLabel>
                                <Select
                                    inputProps={{ style: { textAlign: 'left' } }}                                                    
                                    value={modalData.state.bulkUpdateData.aggressiVeness}
                                    onChange={modalData.bulkUpdateData('aggressiVeness')}
                                    className={classes.textfield}
                                >
                                    {elasticityList.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {t(option.label)}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {modalData.state.bulkError.aggressiVeness &&                                    
                                    <span class='error-message'>{t('Please select Aggressiveness')}</span>
                                }
                            </FormControl>                                        
                        </Grid>
                        }
                        {modalData.state.bulkEditType === 8 &&
                        <Grid xs={12} item>
                            <FormControl margin="normal" className={classes.fieldLeft} style={{ minWidth: "250px" }}>
                                <InputLabel  style={{width:"max-content"}}  shrink={true} htmlFor="price">{t("Median Booking Window")} {t(`(All ${modalData.state.room_apartment_space_name} Types)`)}</InputLabel>
                                <Select                                    
                                    inputProps={{ style: { textAlign: 'left' } }}                                                    
                                    value={modalData.state.bulkUpdateData.leadTimeAdjustment}
                                    onChange={modalData.bulkUpdateData('leadTimeAdjustment')}
                                    className={classes.textfield}
                                >
                                    {MedianOptions.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {t(option.label)}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {modalData.state.bulkError.medianleadtime &&                                    
                                    <span class='error-message'>{t('Please select Median Booking Window')}</span>
                                }
                            </FormControl>                                                                                                                                        
                        </Grid>
                        }
                    </div>
                </div>
           
                <div className={`${classes.buttonGroupBottom } ${classes.sidePopupFooter}`} >
                        <div>
                        <Button disabled={modalData.state.disableSave || modalData.state.bulkLoader || modalData.state.renderDataLoad} onClick={() => modalData.updateSettingJson(false)} className={[classes.saveUploadBtn, "orangButton"]} variant="contained" color="primary">
                            {t("Save")}{modalData.state.bulkLoader && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                        </Button>
                        {(modalData.state.bulkEditType !== 1 && modalData.state.bulkEditType !== 7 && modalData.state.bulkEditType !== 8) &&
                            <Button onClick={modalData.defaultBulkData} className={classes.saveUploadBtn}  style={{background:"white"}} variant="outlined" color="primary" >
                                {modalData.state.bulkEditType == 5 || modalData.state.bulkEditType == 4 ? t("Clear") : t("Default")}
                            </Button>
                        }
                         <Button onClick={modalData.bulkDateAdjModalToggle}  style={{marginTop:'10px',background:"white"}} className="cancelBtn" >
                            {t("Cancel")}
                        </Button>
                    </div>
                </div>

            </SwipeableDrawer>)
    },
    removeAdjModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        let days=[];
        if(modalData.state.start_monday){
            days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday","Sunday"];
        }else{
            days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        }
        let preferred_dates= (modalData.props.hotelDetail && modalData.props.hotelDetail.prefered_date_format) ? modalData.props.hotelDetail.prefered_date_format.replace(', hh:mm A',''):'DD/MM/YYYY';
        let preferred_date_day=preferred_dates.replace('DD','dd');
        let preferred_date=preferred_date_day.replace('YYYY','yyyy');
        modalData.state.start_monday ? enLocale.options.weekStartsOn = 1 : enLocale.options.weekStartsOn = 0
        let allPricing = modalData.props.priceSettingReducer.priceSetting.hotel.all_room_pricing;
        return (


            <SwipeableDrawer
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="right"
                open={modalData.state.removeAdjModal}
                onClose={modalData.closeRemoveAdj}
                PaperProps={{ style: { maxWidth: "600px", padding: "16px", paddingBottom: "70px", width: "600px", overflow: "hidden" } }}
            >
                <div style={{padding:"16px 25px" }}>
                    <div style={{ float: "left" }}>
                        <Typography variant="h5" style={{ fontWeight: "500", width: "280px" }}>
                            <span>{t("Remove Adjustments")}</span>
                        </Typography>
                    </div>

                    <div style={{marginTop:"-6px" , float: "right"}}>
                        <IconButton aria-label="Close" className={classes.closeButtonPrice} onClick={modalData.closeRemoveAdj} >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>

                <div style={{ padding:"10px 25px" }}>
                <MuiThemeProvider theme={CalendardateTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                        <DatePicker
                            style={{ width: "215px" }}
                            margin="normal"
                            label={t("From Date")}
                            value={moment(modalData.state.bulkStartDate)}
                            onChange={modalData.changeBulkDate('bulkStartDate')}
                            className={classes.textField}
                            format={preferred_date}
                            autoOk={true}
                            minDate={moment.utc(modalData.props.hotelAuthReducer.utcTime).tz(modalData.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD')}
                            maxDate={moment.utc(modalData.props.hotelAuthReducer.utcTime).tz(modalData.props.hotelAuthReducer.hotelDetails.hotel.timezone).add('3', 'Y').format('YYYY-MM-DD')}
                            keyboard
                            placeholder={preferred_date.toLowerCase()}
                            InputLabelProps={{ shrink: true }}
                        />
                        <DatePicker
                            style={{ width: "215px" }}
                            minDate={moment(modalData.state.bulkStartDate).format('YYYY-MM-DD')}
                            maxDate={moment.utc(modalData.props.hotelAuthReducer.utcTime).tz(modalData.props.hotelAuthReducer.hotelDetails.hotel.timezone).add('3', 'Y').format('YYYY-MM-DD')}
                            margin="normal"
                            label={t("To Date")}
                            value={moment(modalData.state.bulkEndDate)}
                            onChange={modalData.changeBulkDate('bulkEndDate')}
                            className={classes.textField}
                            format={preferred_date}
                            autoOk={true}
                            keyboard
                            placeholder={preferred_date.toLowerCase()}
                            InputLabelProps={{ shrink: true }}
                        />
                    </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                </div>
                <div style={{overflow: "auto", padding:"10px 25px 60px" }}>
                    <div style={{ marginBottom: "18px" }}>
                        {days.map(name => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary" 
                                        checked={modalData.state.bulkWeekdays.indexOf(name) > -1}
                                        onChange={modalData.handlebulkWeekdays(name)}
                                    />
                                }
                                label={t(name.substring(0, 3))}
                            />
                        ))}
                    </div>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>{t(`All ${modalData.state.room_apartment_space_name} Types`)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel control={
                                <Checkbox
                                    color={"primary"}
                                    checked={modalData.state.deleteModalData.fix}
                                    onChange={() => modalData.chnageDeleteAdj("fix")}
                                />
                            }
                                label={t("Fix Prices")}
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color={"primary"}
                                        checked={modalData.state.deleteModalData.minMax}
                                        onChange={() => modalData.chnageDeleteAdj("minMax")}
                                    />
                                }
                                label={t("Minimum and Maximum Prices")}
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color={"primary"}
                                        checked={modalData.state.deleteModalData.perAjd}
                                        onChange={() => modalData.chnageDeleteAdj("perAjd")}
                                    />
                                }
                                label={t("Percent Adjustments")}
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color={"primary"}
                                        checked={modalData.state.deleteModalData.der}
                                        onChange={() => modalData.chnageDeleteAdj("der")}
                                    />
                                }
                                label={t(`${modalData.state.room_apartment_space_name} Derivation`)}
                            />

                        </Grid>
                        { !modalData.state.featuresEnabled.includes(29) &&
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color={"primary"}
                                        checked={modalData.state.deleteModalData.close}
                                        onChange={() => modalData.chnageDeleteAdj("close")}
                                    />
                                }
                                label={t(`Closed ${modalData.state.room_apartment_space_name}s`)}
                            />                       
                        </Grid>
                        }

                        {modalData.state.featuresEnabled.includes(1) && modalData.state.pmsProviderList.includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) && 
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color={"primary"}
                                            checked={modalData.state.deleteModalData.occupancy}
                                            onChange={() => modalData.chnageDeleteAdj("occupancy")}
                                        />
                                    }
                                    label={t(`Occupancy Pricing`)}
                                />                       
                            </Grid>
                        }
                        {modalData.state.featuresEnabled.includes(35) && 
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color={"primary"}
                                            checked={modalData.state.deleteModalData.aggressiVeness}
                                            onChange={() => modalData.chnageDeleteAdj("aggressiVeness")}
                                        />
                                    }
                                    label={t(`Aggressiveness`)}
                                />                       
                            </Grid>
                        }
                        {modalData.state.featuresEnabled.includes(35) && 
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color={"primary"}
                                            checked={modalData.state.deleteModalData.leadTimeAdjustment}
                                            onChange={() => modalData.chnageDeleteAdj("leadTimeAdjustment")}
                                        />
                                    }
                                    label={t(`Median Booking Window`)}
                                />                       
                            </Grid>
                        }
                    </Grid>
                </div>               
                <div className={`${classes.buttonGroupBottom } ${classes.sidePopupFooter}`}>
                        <div >
                        <Button disabled={modalData.state.bulkLoader} onClick={() => modalData.removeAdjustments()} className={[classes.saveUploadBtn,classes.dangerBtnHover]} variant="contained" 
                        style={{ background:modalData.state.bulkLoader?"#0000001f":"#d32f2f", color:modalData.state.bulkLoader? "#00000042":"#fff" }}>
                            {t("Remove")}{modalData.state.bulkLoader && <CircularProgress style={{ color: "#d32f2f"}}   size={24} className={classes.buttonProgress} />}
                        </Button>
                        <Button onClick={modalData.closeRemoveAdj} style={{marginTop:'11px',background:"white"}}  className="cancelBtn" >
                            {t("Cancel")}
                        </Button>
                    </div>
                </div>
            </SwipeableDrawer>
        );
    },
    targetOccupancy: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.targetOccupancyModal}
                onClose={() => modalData.targetOccupancyToggle()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Target Occupancy")}</DialogTitle>
                <DialogContent>
                    <Typography variant="subheading" style={{ color: "grey" }}>{t("The occupancy calendar shows your performance based on your target occupancy. Blue prices indicate that you have too many bookings already and that you could possibly charge higher prices. Red prices indicate that you don't have enough bookings and should consider lowering your prices.")}</Typography>

                </DialogContent>
                <DialogActions>
                   
                    <Button onClick={() => modalData.changeTargetOccupancy()} variant="contained" color="primary" >
                        {t("Save")}
                    </Button>
                    <Button onClick={() => modalData.targetOccupancyToggle()}  className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    rerunAlertPopup: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.settingAlert}
                onClose={() => modalData.toggleSettingAlert()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Re-run pricing to see your changes")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('Your new settings will be applied to all automated pricing runs. To see the changes already, please re-run the pricing (button).')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => modalData.toggleSettingAlert()} variant="outlined" color="primary" >
                        {t("Okay")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    createRoomTypeModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.createUpdateType}
                onClose={() => modalData.createUpdateTypeModal()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{modalData.state.typeData.id ? t(`Edit ${modalData.state.room_apartment_space_name} Type`) : t(`Create New ${modalData.state.room_apartment_space_name} Type`)}</DialogTitle>
                <DialogContent>
                    <FormControl className={classes.fieldLeft}>
                        <TextField
                            id="standard-name"
                            margin="normal"
                            value={modalData.state.typeData.room_id}
                            label={t("InvTypeCode")}
                            className={classes.textField}
                            onChange={modalData.handleTypeData("room_id")}
                            error={modalData.state.error.includes("room_id")}
                            required
                            disabled={modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 29}
                            InputLabelProps={{ shrink: true }}
                            placeholder={t(`Please enter InvTypeCode`)}
                        />
                        {modalData.state.error.includes("room_id") ?
                            <span class='error-message'>{t(`* Please enter ${modalData.state.room_apartment_space_name} Id`)}</span> : ''
                        }
                    </FormControl>
                    <FormControl className={classes.fieldLeft}>
                        <TextField
                            id="standard-name"
                            margin="normal"
                            value={modalData.state.typeData.name}
                            label={t(`${modalData.state.room_apartment_space_name} Type Name`)}
                            className={classes.textField}
                            onChange={modalData.handleTypeData("name")}
                            error={modalData.state.error.includes("name")}
                            required
                            InputLabelProps={{ shrink: true }}
                            placeholder={t(`Please enter ${modalData.state.room_apartment_space_name} Type Name`)}
                        />
                        {modalData.state.error.includes("name") ?
                            <span class='error-message'>{t('* Please enter Name')}</span> : ''
                        }
                    </FormControl>
                    <FormControl className={classes.fieldLeft}>
                        <TextField
                            id="standard-name"
                            margin="normal"
                            value={modalData.state.typeData.min_occupancy}
                            label={t("Min Occupancy")}
                            className={classes.textField}
                            onChange={modalData.handleTypeData("min_occupancy")}
                            error={modalData.state.error.includes("min_occupancy")}
                            InputLabelProps={{ shrink: true }}
                            placeholder={t(`Please enter Min Occupancy`)}

                        />
                        {modalData.state.error.includes("min_occupancy") ?
                            <span class='error-message'>{t('* Please enter Min Occupancy')}</span> : ''
                        }
                        {modalData.state.error.includes("min_occupancy_less_zero") ?
                            <span class='error-message'>{t('* Please enter grater then zero')}</span> : ''
                        }
                    </FormControl>
                    <FormControl className={classes.fieldLeft}>
                        <TextField
                            id="standard-name"
                            margin="normal"
                            value={modalData.state.typeData.max_occupancy}
                            label={t("Max Occupancy")}
                            className={classes.textField}
                            onChange={modalData.handleTypeData("max_occupancy")}
                            error={modalData.state.error.includes("max_occupancy") || modalData.state.error.includes("max_occupancy_more")}
                            InputLabelProps={{ shrink: true }}
                            placeholder={t(`Please enter Max Occupancy`)}

                        />
                        {modalData.state.error.includes("max_occupancy") ?
                            <span class='error-message'>{t('* Please enter Max Occupancy')}</span> : ''
                        }
                        {modalData.state.error.includes("max_occupancy_less_zero") ?
                            <span class='error-message'>{t('* Please enter grater then zero')}</span> : ''
                        }
                        {modalData.state.error.includes("max_occupancy_more") ?
                            <span class='error-message'>{t('* Max Occupancy should be more than Min')}</span> : ''
                        }
                    </FormControl>
                    {(modalData.state.currentPms.key == 41) ?
                        <FormControl className={classes.fieldLeft}>
                            <TextField
                                id="standard-name"
                                margin="normal"
                                value={modalData.state.typeData.extra}
                                label={t("Extra")}
                                className={classes.textField}
                                onChange={modalData.handleTypeData("extra")}
                                error={modalData.state.error.includes("extra")}
                                InputLabelProps={{ shrink: true }}
                                placeholder={t(`Please enter Extra`)}
                            />
                            {/* {modalData.state.error.includes("extra") ?
                            <span class='error-message'>{t('* Please enter the value')}</span> : ''
                        } */}
                        </FormControl> : ""}
                    {/* <FormControl className={classes.fieldLeft}>
                        <TextField
                            id="standard-name"
                            margin="normal"
                            value={modalData.state.typeData.number_of_rooms}
                            label={t("Number of Rooms")}
                            className={classes.textField}
                            onChange={modalData.handleTypeData("number_of_rooms")}
                            error={modalData.state.error.includes("number_of_rooms")}

                        />
                        {modalData.state.error.includes("number_of_rooms") ?
                            <span class='error-message'>{t('* Please enter Number of Rooms')}</span> : ''
                        }
                    </FormControl> */}
                </DialogContent>
                <DialogActions>
                    
                    <Button onClick={() => modalData.toggleWarningModal('createUpdateType')} variant="contained" color="primary" >
                        {modalData.state.typeData.id ? t("Save") : t("Save")}
                    </Button>
                    <Button onClick={() => modalData.createUpdateTypeModal()} className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    createRoomRateModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;

        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.createUpdateRate}
                onClose={() => modalData.createUpdateRateModal()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{modalData.state.rateData.id ? t("Edit Rate") : t("Create New Rate")}</DialogTitle>
                <DialogContent>
                    <FormControl className={classes.fieldLeft}>
                        <TextField
                            id="standard-name"
                            margin="normal"
                            value={modalData.state.rateData.rate_id}
                            label={t("Rate Plan Code")}
                            className={classes.textField}
                            onChange={modalData.handleRateData("rate_id")}
                            error={modalData.state.error.includes("rate_id")}
                            required
                            InputLabelProps={{ shrink: true }}
                            placeholder={t(`Please enter Rate Plan Code`)}
                        />
                        {modalData.state.error.includes("rate_id") ?
                            <span class='error-message'>{t('* Please enter rate Id')}</span> : ''
                        }
                    </FormControl>
                    <FormControl className={classes.fieldLeft}>
                        <TextField
                            id="standard-name"
                            margin="normal"
                            value={modalData.state.rateData.name}
                            label={t("Rate Plan Name")}
                            className={classes.textField}
                            onChange={modalData.handleRateData("name")}
                            error={modalData.state.error.includes("name")}
                            required
                            InputLabelProps={{ shrink: true }}
                            placeholder={t(`Please enter Rate Plan Name`)}
                        />
                        {modalData.state.error.includes("name") ?
                            <span class='error-message'>{t('* Please enter Rate Name')}</span> : ''
                        }
                    </FormControl>
                    {![34, 69].includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) &&
                        <FormControl className={classes.fieldLeft} margin="normal" >
                            <InputLabel shrink={true}>{t(`Pms ${modalData.state.room_apartment_space_name}`)}</InputLabel>
                            <Select
                                multiple
                                id="pms_room"
                                name="pms_room"
                                margin="normal"
                                displayEmpty
                                required
                                className={classes.textField}
                                value={modalData.state.rateData.pms_room}
                                onChange={modalData.handleRateData("pms_room")}
                                error={modalData.state.error.includes("pms_room")}                               
                                style={{maxWidth:"240px"}}
                                disabled={modalData.state.rateData.is_for_all_room_type}
                                renderValue={selected => {
                                    let jsx = [];
                                    if (selected.length == 0) {
                                        return <> {t(`Select Pms ${modalData.state.room_apartment_space_name}`)}</>;
                                    }
                                    modalData.state.typeList.map(opt => {
                                        if (modalData.state.rateData.pms_room.includes(opt.id)) {
                                            jsx.push(opt.name);
                                        }
                                    })
                                    return jsx.join(', ')
                                }}

                            >   
                                <MenuItem disabled key={-1} value={''}>
                                    {t(`Select Pms ${modalData.state.room_apartment_space_name}`)}
                                </MenuItem>
                                {modalData.state.typeList.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        <Checkbox checked={modalData.state.rateData.pms_room.includes(option.id)} />
                                        <ListItemText primary={option.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                            {modalData.state.error.includes("pms_room") ?
                                <span class='error-message'>{t('* Please enter Pms Room')}</span> : ''
                            }
                        </FormControl>
                    }
                    {/* {!modalData.state.rateData.id &&
                        <FormControl margin="normal" className={classes.fieldLeft}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={modalData.state.rateData.is_for_all_room_type} onChange={modalData.handleRateCheckbox("is_for_all_room_type")} />
                                }
                                label={t("All Room Types")}
                            />
                        </FormControl>
                    } */}
                    <FormControl className={classes.fieldLeft}>
                        <TextField
                            id="standard-name"
                            margin="normal"
                            value={modalData.state.rateData.description}
                            label={t("Description")}
                            className={classes.textField}
                            onChange={modalData.handleRateData("description")}
                            error={modalData.state.error.includes("description")}
                            InputLabelProps={{ shrink: true }}
                            placeholder={t(`Please enter Description`)}

                        />
                        {modalData.state.error.includes("description") ?
                            <span class='error-message'>{t('* Please enter Description')}</span> : ''
                        }
                    </FormControl>
                    {modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 22 &&
                        <FormControl>
                            <TextField
                                id="standard-name"
                                margin="normal"
                                value={modalData.state.rateData.other_rate}
                                label={t("Rate Flex Table")}
                                className={classes.textField}
                                onChange={modalData.handleRateData("other_rate")}
                                error={modalData.state.error.includes("other_rate")}
                                InputLabelProps={{ shrink: true }}
                                placeholder={t(`Please enter Rate Flex Table`)}
                            />
                        </FormControl>
                    }                    
                </DialogContent>
                <DialogActions>
                   
                    <Button onClick={() => modalData.createUpdateRate()} variant="contained" color="primary" >
                        {modalData.state.rateData.id ? t("Save") : t("Save")}
                    </Button>
                    <Button onClick={() => modalData.createUpdateRateModal()} className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog >
        );
    },
    deleteTypeModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.deleteType}
                onClose={() => modalData.deleteTypeModal()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t(`Delete ${modalData.state.room_apartment_space_name} Type`)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t(`Are you sure you want to delete ${modalData.state.room_apartment_space_name.toLowerCase()} type?`)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                  
                    <Button onClick={() => modalData.deleteCurrentType()} variant="contained" color="primary"  className="dangerBtnHvr" >
                        {t("Delete")}
                    </Button>
                    <Button onClick={() => modalData.deleteTypeModal()}  className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    deleteRateModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.deleteRate}
                onClose={() => modalData.deleteRateModal()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t(`Delete ${modalData.state.room_apartment_space_name} Rate`)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t(`Are you sure you want to delete ${modalData.state.room_apartment_space_name} rate?`)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                  
                    <Button onClick={() => modalData.deleteCurrentRate()} variant="contained" color="primary"  className="dangerBtnHvr" >
                        {t("Delete")}
                    </Button>
                    <Button onClick={() => modalData.deleteRateModal()} className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    viewSettingLog: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        const theme = {
            base00: '#272822',
            base01: '#383830',
            base02: '#49483e',
            base03: '#75715e',
            base04: '#a59f85',
            base05: '#f8f8f2',
            base06: '#f5f4f1',
            base07: '#f9f8f5',
            base08: '#f92672',
            base09: '#fd971f',
            base0A: '#f4bf75',
            base0B: '#a6e22e',
            base0C: '#a1efe4',
            base0D: '#66d9ef',
            base0E: '#ae81ff',
            base0F: '#cc6633'
        };
        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullWidth={true}
            maxWidth="sm"
            open={modalData.state.showLogDetail}
            onClose={modalData.CloseLogModal}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t("View Change Log")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <ReactJson src={modalData.state.logDetail} name="settings" enableClipboard={false} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={modalData.CloseLogModal} variant="contained" color="secondary" >
                    {t("Close")}
                </Button>
            </DialogActions>
        </Dialog>)
    },
    roomDeleteConfirmationDialog: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullWidth={true}
            maxWidth="sm"
            open={modalData.state.showConfirmRoomDelete}
            onClose={modalData.CloseConfirmRoomDelete}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogContent>
                {modalData.state.deleted_rooms && modalData.state.deleted_rooms.length > 0 &&
                    <Grid container spacing={3}>
                        <Grid xs={12} item >
                            <Typography variant="subtitle">
                                {t(`We noticed the following ${modalData.state.pms_channel_manager_text} ${modalData.state.room_apartment_space_name.toLowerCase()} types and rate types don't exist anymore. Please tick the box(es), if you also want to remove the ${modalData.state.room_apartment_space_name.toLowerCase()} type(s) or rate types in RoomPriceGenie. If not, please make sure to map them to a new ${modalData.state.pms_channel_manager_text} ${modalData.state.room_apartment_space_name.toLowerCase()} type or rate type.`)}
                            </Typography>
                        </Grid>
                        <Grid xs={12} item >
                            <Table aria-labelledby="tableTitle" size='small' aria-label="enhanced table" style={{ marginTop: 10 }}>
                                <TableHead>
                                    <TableRow hover>
                                        <TableCell padding="checkbox" align="left">
                                            <Checkbox
                                                color="primary" 
                                                onChange={modalData.handleSelectAllClick}
                                                checked={modalData.state.deleted_rooms && modalData.state.tobe_deleted.length === modalData.state.deleted_rooms.length}
                                            />
                                        </TableCell>
                                        <TableCell align="left">{t(`${modalData.state.room_apartment_space_name} Name`)}</TableCell>
                                        <TableCell align="left">{t(`${modalData.state.room_apartment_space_name} Type in ${modalData.state.pms_channel_manager_text}`)}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {modalData.state.deleted_rooms && modalData.state.deleted_rooms.map(option => (
                                        <TableRow hover>
                                            <TableCell padding="checkbox" align="left">
                                                <Checkbox
                                                    color="primary" 
                                                    onClick={(event) => modalData.handleSelectCheck(event, option)}
                                                    checked={modalData.state.tobe_deleted && modalData.state.tobe_deleted.indexOf(option) !== -1}
                                                />
                                            </TableCell>
                                            <TableCell align="left">{option.rpg_room_name}</TableCell>
                                            <TableCell align="left">{option.room_name_in_pms}</TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                }
                {modalData.state.deleted_rates && modalData.state.deleted_rates.length > 0 &&
                    <Grid container spacing={3} style={{ marginTop: "50px" }}>
                        <Grid xs={12} item >
                            <Typography variant="subtitle">
                                {t(`We noticed the following ${modalData.state.pms_channel_manager_text} ${modalData.state.room_apartment_space_name.toLowerCase()} types and rate types don't exist anymore. Please tick the box(es), if you also want to remove the ${modalData.state.room_apartment_space_name.toLowerCase()} type(s) or rate types in RoomPriceGenie. If not, please make sure to map them to a new ${modalData.state.pms_channel_manager_text} ${modalData.state.room_apartment_space_name.toLowerCase()} type or rate type.`)}
                            </Typography>
                        </Grid>
                        <Grid xs={12} item >
                            <Table aria-labelledby="tableTitle" size='small' aria-label="enhanced table" style={{ marginTop: 10 }}>
                                <TableHead>
                                    <TableRow hover>
                                        <TableCell padding="checkbox" align="left">
                                            <Checkbox
                                                color="primary" 
                                                onChange={modalData.handleSelectAllRateClick}
                                                checked={modalData.state.deleted_rates && modalData.state.tobe_deleted_r.length === modalData.state.deleted_rates.length}
                                            />
                                        </TableCell>
                                        {/* <TableCell align="left">{t("Room Name")}</TableCell> */}
                                        <TableCell align="left">{t("Rate type")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {modalData.state.deleted_rates && modalData.state.deleted_rates.length > 0 && modalData.state.deleted_rates.map(option => (
                                        <TableRow hover>
                                            <TableCell padding="checkbox" align="left">
                                                <Checkbox
                                                    color="primary" 
                                                    onClick={(event) => modalData.handleSelectRateCheck(event, option)}
                                                    checked={modalData.state.tobe_deleted_r && modalData.state.tobe_deleted_r.indexOf(option) !== -1}
                                                />
                                            </TableCell>
                                            {/* <TableCell align="left">{option.room_name}</TableCell> */}
                                            <TableCell align="left">{option.rate_name}</TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                }
            </DialogContent>
            <DialogActions>
                
                <Button onClick={modalData.deleteRoomsRates} style={{ background: '#d32f2f' }} variant="contained" color="secondary" >
                    {t(`Remove Ticked ${modalData.state.room_apartment_space_name}s And Rates; Unmap Non-ticked`)}
                </Button>
            </DialogActions>
        </Dialog>)
    },
    // runPricingModal: function (modalData) {
    //     const { t, classes } = modalData.props;
    //     return (<Dialog
    //         transitionDuration={{ enter: 500, exit: 300 }}
    //         fullWidth={true}
    //         maxWidth="sm"
    //         open={modalData.state.runPriceModal}
    //         onClose={modalData.toggleRunPricing}
    //         aria-labelledby="responsive-dialog-title"
    //     >
    //         <DialogTitle>
    //             {t("Apply Prices")}
    //             {/* {modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider &&
    //                 <div>
    //                     <CustomTooltip description={t("We run the pricing many times a day for you, based on your latest inventory data. Manual runs are meant to test setting changes, for example, increasing your average price. Please only select this option, if you need the latest inventory.")}></CustomTooltip>
    //                 </div>
    //             } */}
    //         </DialogTitle>
    //         <DialogContent>
    //             <DialogContentText>
    //                 {t("Did you change the PMS/ Channel manager mapping of your room types or rate types?")}
    //             </DialogContentText>

    //             {modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider && modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider != 15 && modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider != 34 && modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider != 22 &&
    //                 <div className={classes.cellDateAply}>
    //                     <FormControlLabel
    //                         control={
    //                             <Checkbox
    //                                 color={'primary'}
    //                                 className={classes.checkbox}
    //                                 onChange={modalData.handleChangePricing('get_pms_data')}
    //                                 value={modalData.state.get_pms_data}
    //                             />
    //                         }
    //                         label={t("Yes")}
    //                     // label={t("Get latest PMS data")}
    //                     />
    //                 </div>
    //             }
    //             {(modalData.props.userSession.user.is_staff || (modalData.props.userSession.user.sub_account_type && (!modalData.props.hotelAuthReducer.hotelDetails.hotel.reseller || modalData.props.hotelAuthReducer.hotelDetails.hotel.reseller.id != 3))) &&
    //                 <div className={classes.cellDateAply}>
    //                     <FormControlLabel
    //                         control={
    //                             <Checkbox
    //                                 color={'primary'}
    //                                 className={classes.checkbox}
    //                                 onChange={modalData.handleChangePricing('showCsvPrice')}
    //                                 value={modalData.state.showCsvPrice}
    //                             />
    //                         }
    //                         label={t("Diagnostics")}
    //                     />
    //                 </div>
    //             }
    //         </DialogContent>
    //         <DialogActions>
    //             <Button onClick={modalData.toggleRunPricing} variant="outlined" color="primary" >
    //                 {t("Cancel")}
    //             </Button>
    //             <Button className={"orangButton"} disabled={modalData.state.loadPricing} onClick={modalData.runPricing} variant="contained" color="primary" >
    //                 {t("Save")} {modalData.state.loadPricing && <CircularProgress color="secondary" size={24} className={classes.buttonProgress} />}
    //             </Button>
    //         </DialogActions>
    //     </Dialog>)
    // },
    rateDeleteNotificationDialog: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullWidth={true}
            maxWidth="sm"
            open={modalData.state.showNotificationRateDelete}
            onClose={modalData.CloseNotificatinRateDelete}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid xs={12} item >
                        <Typography variant="subtitle">
                            {t(`We noticed the following ${modalData.state.pms_channel_manager_text} ${modalData.state.room_apartment_space_name.toLowerCase()} types and rate types don't exist anymore. Please tick the box(es), if you also want to remove the ${modalData.state.room_apartment_space_name.toLowerCase()} type(s) or rate types in RoomPriceGenie. If not, please make sure to map them to a new PMS ${modalData.state.room_apartment_space_name.toLowerCase()} type or rate type.`)}
                        </Typography>
                    </Grid>
                    <Grid xs={12} item >
                        <Table aria-labelledby="tableTitle" size='small' aria-label="enhanced table" style={{ marginTop: 10 }}>
                            <TableHead>
                                <TableRow hover>
                                    <TableCell padding="checkbox" align="left">
                                        <Checkbox
                                            color="primary" 
                                            onChange={modalData.handleSelectAllRateClick}
                                            checked={modalData.state.deleted_rates && modalData.state.tobe_deleted.length === modalData.state.deleted_rates.length}
                                        />
                                    </TableCell>
                                    {/* <TableCell align="left">{t("Room Name")}</TableCell> */}
                                    <TableCell align="left">{t("Rate type")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {modalData.state.deleted_rates && modalData.state.deleted_rates.map(option => (
                                    <TableRow hover>
                                        <TableCell padding="checkbox" align="left">
                                            <Checkbox
                                                color="primary" 
                                                onClick={(event) => modalData.handleSelectRateCheck(event, option)}
                                                checked={modalData.state.tobe_deleted_r && modalData.state.tobe_deleted_r.indexOf(option) !== -1}
                                            />
                                        </TableCell>
                                        {/* <TableCell align="left">{option.room_name}</TableCell> */}
                                        <TableCell align="left">{option.rate_name}</TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={modalData.deleteRoomsRates} style={{ background: '#d32f2f' }} variant="contained" color="secondary" >
                    {t(`Remove Ticked ${modalData.state.room_apartment_space_name} Rate`)}
                </Button>
                <Button onClick={modalData.CloseNotificatinRateDelete}  className="cancelBtn" >
                    {t("cancel")}
                </Button>
            </DialogActions>
        </Dialog>)
    },
    autoRunModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;

        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            maxWidth='sm'
            open={modalData.state.autoRunModal}
        >
            <DialogContent>
                <Grid container style={{ maxWidth: "450px" }} >
                    {/* <Grid xs={12} md={3} lg={3} item ></Grid> */}
                    <Grid xs={12} md={12} lg={12} item style={{display:'flex',justifyContent:'center'}}>
                        <img className={classes.priceCalModal} src="/img/price_calculation_new.gif" style={{ margin: "auto" }} />
                    </Grid>
                    {/* <Grid xs={12} md={3} lg={3} item ></Grid> */}
                    <Grid xs={12} md={12} lg={12} item >
                        <Typography variant="subtitle2" color="primary" className={classes.autorunProgress}>
                            {!modalData.state.loadPercentage ? ((modalData.state.get_pms_data && modalData.state.socketLoader) ? t('Fetching your latest availabilities and rates, this may take a while...') : '') : ''}
                            {modalData.state.loadPercentage ? (modalData.state.onlyRunAdjustment ? t("Applying Adjustments...") : t("Optimizing...")) : ""}
                        </Typography>
                    </Grid>

                    <Grid xs={12} md={12} lg={12} item >
                        <LinearProgress color="primary" variant="indeterminate" className={classes.autorunProgress} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
            </DialogActions>
        </Dialog >)
    },
    uploadSingleModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        let date = modalData.state.modalData.date;
        let allPricing = modalData.props.priceSettingReducer.priceSetting.hotel.all_room_pricing;
        let listArray = []

        modalData.props.roomList.roomList.filter(val => val.is_reference_room).map((room) => {
            if (modalData.state.tempUpload[room.id]) {
                let roomData = modalData.state.tempUpload[room.id];
                if (roomData) {
                    listArray.push({
                        name: (room.name), price: roomData.price
                    })
                }
            }
        })

        modalData.props.roomList.roomList.filter(val => !val.is_reference_room  && (modalData.state.viewType == "adjustment" || (allPricing && (val.pms_rate || [17, 8, 35, 39, 42, 52, 67].includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider))))).map((room) => {
            if (modalData.state.tempUpload[room.id]) {
                let roomData = modalData.state.tempUpload[room.id];
                if (roomData) {
                    listArray.push({
                        name: (room.name), price: roomData.price
                    })
                }
            }
        })
        return (<Dialog
            fullScreen={fullScreen}
            transitionDuration={{ enter: 500, exit: 300 }}
            maxWidth='md'
            open={modalData.state.uploadSingleModal}
            onClose={modalData.uploadSingleModal}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle>
                {t("Upload Prices to PMS/CM")}
            </DialogTitle>
            <DialogContent>

                <Table className={classes.pmsTable}>
                    {listArray.map(val => {
                        return (
                            <TableRow>
                                <TableCell align="left">{val.name}</TableCell>
                                <TableCell align="right">{modalData.state.currency.symbol}{val.price}</TableCell>
                            </TableRow>
                        )
                    })}
                </Table>
            </DialogContent>
            <DialogActions>
              
                <Button disabled={modalData.state.loadingUplaod} onClick={() => { modalData.saveSkipAdjustData(true); }} className={classes.saveUploadBtn} variant="contained" color="primary" style={{marginLeft:4 }} >
                    {t("Confirm & Upload to PMS/CM")}
                    {modalData.state.loadingUplaod && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                </Button>
                <Button onClick={() => modalData.uploadSingleModal()} style={{marginTop:'10px'}}  className="cancelBtn" >
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog >)
    },
    warningPopupQuickSetup: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;

        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            maxWidth='sm'
            open={modalData.state.warningPopup}
            onClose={modalData.toggleWarningPopup}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle>
                {t("Oh snap, something went wrong")}
            </DialogTitle>
            <DialogContent>

                <Grid container>
                    {/* <Grid xs={2} item style={{ alignItems: "center", flexDirection: "column", display: "flex" }}> */}
                    <Grid xs={4} item style={{ display: "flex" }}>
                        <Hidden xsDown><img src={"/img/quickSetup.png"} height={120} /></Hidden>
                    </Grid>
                    {/* <Grid xs={1} item></Grid> */}
                    <Grid xs={8} item>
                        <Typography>
                            {modalData.state.isBookingUrl ?
                                "We’re sorry, but something went wrong setting up your property. Our team got a notification and will check this. We’ll contact you as soon as possible. Thank you!"
                                :
                                <>
                                    {(modalData.state.activeSubStep == 3 || modalData.state.activeSubStep == 4) ?
                                        t("We’re sorry, but something went wrong setting up your property. Our team got a notification and will check this. We’ll contact you as soon as possible. Thank you!") : ""
                                    }
                                    {(modalData.state.activeSubStep == 5) ?
                                        t(`We’re sorry, but something went wrong setting up your ${modalData.state.room_apartment_space_name.toLowerCase()} types. Our team got a notification and will check this. We’ll contact you as soon as possible. Thank you!`) : ""
                                    }
                                    {(modalData.state.activeSubStep == 6) ?
                                        t("We’re sorry, but something went wrong mapping your rates. Our team got a notification and will check this. We’ll contact you as soon as possible. Thank you!") : ""
                                    }
                                    {(modalData.state.activeSubStep == 7) ?
                                        t("We’re sorry, but something went wrong setting up your property. Our team got a notification and will check this. We’ll contact you as soon as possible. Thank you!") : ""
                                    }
                                </>
                            }
                        </Typography>
                    </Grid>
                </Grid>


            </DialogContent>
            <DialogActions>
                <Button onClick={() => modalData.toggleWarningPopup()} className={classes.saveUploadBtn} variant="outlined" color="primary" >
                    {t("Close")}
                </Button>
            </DialogActions>
        </Dialog >)
    },
    ReferFriendModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            maxWidth='md'
            open={modalData.state.ReferFriendModalOpen}
            onClose={modalData.ReferFriendModalOpen}
            aria-labelledby="responsive-dialog-title"
        >
            <IconButton aria-label="Close" className={classes.closeButtonPrice} onClick={modalData.closeReferFriendModal} >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                    {modalData.state.referralSuccessMsg == 'It looks like your friend is already in contact with RoomPriceGenie.' ? 
                    <HighlightOffIcon style={{fontSize:'50px',display:'flex',margin:'38px auto 0px auto',color:'red'}} />
                    :
                    <CheckCircleOutlineIcon style={{fontSize:'50px',display:'flex',margin:'38px auto 0px auto',color:'green'}} />}
                   <Typography style={{fontWeight:'500',fontSize:'20px',margin:'5px 0px 32px'}}>{modalData.state.referralSuccessMsg}</Typography>
            </DialogContent>
            {/* <DialogActions>
                <Button onClick={modalData.closeReferFriendModal} className={classes.currentPriceInputBtn} variant="outlined" color="primary" >
                    {t("Cancel")}
                </Button>
               
            </DialogActions> */}
        </Dialog>)
    },
    PriceModalIframe: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        let roomData = {};
        let min = 0;
        let max = 0;
        let adjVsRef = 0;
        let avg_price = 0;
        let market_fector_per=0;
        let market_fector=0;
        let occupancy_fector_per=0;
        let occupancy_factor=0;
        let adjustment=0;
        let adjustment_per=0;
        let suggestedPriceWithoutRestriction='n.a.';
        let setting = modalData.state.dateSetting;
        
        if (modalData.state.selectedRoom) {
            roomData = modalData.props.roomList.roomList.filter(val => val.id == modalData.state.selectedRoom)[0];
            avg_price = setting.default[modalData.state.selectedRoom].avg_price;
            adjVsRef = setting.default[modalData.state.selectedRoom].adjustment_to_reference_room;
        }

        let is_old = moment.utc(modalData.props.hotelAuthReducer.utcTime).tz(modalData.props.hotelAuthReducer.hotelDetails.hotel.timezone).isAfter(modalData.state.modalData.date, 'day');
        if(modalData.state.modalData){
            let closed_room=modalData.state.modalData.closed_room;
            market_fector_per=Math.round(((modalData.state.modalData.weighted_average_price / avg_price )-1)*100);
            market_fector=modalData.state.modalData.weighted_average_price - avg_price;
            occupancy_fector_per=Math.round(((modalData.state.modalData.suggested_price_without_restriction/modalData.state.modalData.weighted_average_price)-1)*100);
            occupancy_factor=(modalData.state.modalData.suggested_price_without_restriction-modalData.state.modalData.weighted_average_price);
            adjustment_per=Math.round(((modalData.state.modalData.suggested_price/modalData.state.modalData.suggested_price_without_restriction)-1)*100);
            adjustment=(modalData.state.modalData.suggested_price-modalData.state.modalData.suggested_price_without_restriction);
            suggestedPriceWithoutRestriction=modalData.state.modalData.suggested_price_without_restriction ? modalData.state.currency.symbol +modalData.state.modalData.suggested_price_without_restriction : 'n.a.';
        }
        const columns = [
            {
                name: t('Hotel Name'),
                options: {
                    filter: false,
                    sort: true,
                    sortDirection: 'asc',
                }
            },
            {
                name: t('Best Flex'),
                options: {
                    filter: false,
                    sort: false,

                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <div>{isNaN(value) ? value : (modalData.state.currency.symbol + value)}</div>
                    }
                }
            },
            {
                name: t('Lowest Rate'),
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <div>{isNaN(value) ? value : (modalData.state.currency.symbol + value)}</div>
                    }
                }
            },
            {
                name: t(`${modalData.state.room_apartment_space_name} Name`),
            },
        ];

        let data = []
        let maidanFlexPrice = "n.a.";
        let maidanCheapPrice = "n.a.";
        let maidanFlexPricePer = "n.a.";
        let maidanCheapPricePer = "n.a.";

        if (modalData.state.competitorData) {
            Object.keys(modalData.state.competitorData).map(key => {
                if (!isNaN(key)) {
                    let comData = modalData.state.competitorData[key]
                    let comp = modalData.state.selectedCompetitors.filter(row => row.competitor_id == key)[0];

                    if (comp) {
                        data.push([
                            comp.name,
                            (comData.flexible ? (comData.flexible[modalData.state.selectedMaxOccupancy] ? (comData.flexible[modalData.state.selectedMaxOccupancy][modalData.state.selectedRoomType] ? comData.flexible[modalData.state.selectedMaxOccupancy][modalData.state.selectedRoomType].rate : "n.a.") : "n.a.") : "n.a."),
                            (comData.cheapest ? (comData.cheapest[modalData.state.selectedMaxOccupancy] ? (comData.cheapest[modalData.state.selectedMaxOccupancy][modalData.state.selectedRoomType] ? comData.cheapest[modalData.state.selectedMaxOccupancy][modalData.state.selectedRoomType].rate : "n.a.") : "n.a.") : "n.a."),
                            (comData.cheapest ? (comData.cheapest[modalData.state.selectedMaxOccupancy] ? (comData.cheapest[modalData.state.selectedMaxOccupancy][modalData.state.selectedRoomType] ? comData.cheapest[modalData.state.selectedMaxOccupancy][modalData.state.selectedRoomType].roomname : "n.a.") : "n.a.") : "n.a."),
                        ])
                    }
                } else if (key == "median_price") {
                    let comData = modalData.state.competitorData[key]
                    maidanFlexPrice = (comData.flexible ? (comData.flexible[modalData.state.selectedMaxOccupancy] ? (comData.flexible[modalData.state.selectedMaxOccupancy][modalData.state.selectedRoomType] ? comData.flexible[modalData.state.selectedMaxOccupancy][modalData.state.selectedRoomType] : "n.a.") : "n.a.") : "n.a.");
                    maidanCheapPrice = (comData.cheapest ? (comData.cheapest[modalData.state.selectedMaxOccupancy] ? (comData.cheapest[modalData.state.selectedMaxOccupancy][modalData.state.selectedRoomType] ? comData.cheapest[modalData.state.selectedMaxOccupancy][modalData.state.selectedRoomType] : "n.a.") : "n.a.") : "n.a.");
                    if (maidanFlexPrice != "n.a." && modalData.state.modalData.price) {
                        maidanFlexPricePer = Math.round(((maidanFlexPrice / modalData.state.modalData.price) - 1) * 100)
                    }

                    if (maidanCheapPrice != "n.a." && modalData.state.modalData.price) {
                        maidanCheapPricePer = Math.round(((maidanCheapPrice / modalData.state.modalData.price) - 1) * 100)
                    }

                }

            })
        }

        const options = {
            sort: true,
            filter: false,
            print: false,
            toolbar: false,
            download: false,
            responsive: false,
            viewColumns: false,
            selectableRows: false,
            serverSide: false,
            rowsPerPageOptions: false,
            pagination: false,
            search: false
        }

        let compSelected = ""
        if (modalData.state.competitor) {
            let row = modalData.state.selectedCompetitors.filter(val => modalData.state.competitor == val.competitor_id)
            if (row.length > 0) {
                compSelected = row[0].name
            }
        } else if (modalData.state.competitor == 0) {
            compSelected = "All Competitors"
        }
        let dataRooms = []
        modalData.state.roomRemainingData.map((rooms) => {
       
            dataRooms.push([
                rooms.name,
                rooms.inventory != null ? rooms.inventory : "-",
                rooms.id == modalData.state.selectedRoom ? modalData.state.modalData.closed_rooms : 0,
                rooms.defaultOccupancy,
                rooms.price ? (modalData.state.currency.symbol + new Intl.NumberFormat('ja-JP').format(rooms.price)) : "-",
            ])
        })

        const columnsRooms = [
            {
                name: t(`${modalData.state.room_apartment_space_name} Name`),
                options: {
                    filter: true,
                    // sortDirection: 'desc'
                }
            },
            {
                name: t(`${modalData.state.room_apartment_space_name}s Left`),
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <div style={{ float: 'right' }}>{value}</div>
                    }
                }
            },
            
            {
                name: t(`Closed ${modalData.state.room_apartment_space_name}s`),
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <div style={{ float: 'right' }}>{value}</div>
                    }
                }
            },
            {
                name: t('Occupancy'),
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <div style={{ float: 'right' }}>{value}</div>
                    }
                }
            },
            {
                name: t('Recommended/Fix Price'),
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <div style={{ float: 'right' }}>{value}</div>
                    }
                }
            },
        ];

        const optionsRooms = {
            sort: true,
            toolbar: false,
            filter: false,
            print: false,
            download: false,
            viewColumns: false,
            responsive: "scroll",
            selectableRows: false,
            serverSide: false,
            rowsPerPage: 20,
            rowsPerPageOptions: false,
            pagination: false,
            search: false,
        }

        let tagName = [];
        let room_types_tag = modalData.props.priceSettingReducer.priceSetting.room_type_tags;
        if (room_types_tag !== undefined) {
            Object.keys(room_types_tag).map((key) => {
                if (room_types_tag[key].room_types.includes(modalData.state.selectedRoom)) {
                    tagName.push({
                        tag_name: room_types_tag[key].tag_name,
                        speed: room_types_tag[key].speed,
                        adj: modalData.state.modalData.yield_tag_adjustment ? modalData.state.modalData.yield_tag_adjustment[key] : ""
                    })
                }
            })
        }

        // Capitalize=(str)=>{
        //     return str.charAt(0).toUpperCase() + str.slice(1);
        // }

        let settingJson = modalData.state.dateSetting;
        return (
            <SwipeableDrawer
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="right"
                open={modalData.state.PriceModalopen}
                onClose={modalData.handleClose}
                PaperProps={{ style: { maxWidth: "900px", padding: "10px 25px 10px 25px", overflow: "hidden" } }}
            >
                <div style={{padding: "10px 25px"}}>
                    <div style={{ float: "left", marginTop: '10px', marginBottom: '5px' }}>
                        <Typography variant="h5" style={{ fontWeight: "500", width: "280px", fontSize: '1.25rem' }}>
                            <span>{modalData.state.modalData ? moment(modalData.state.modalData.date).format('ddd, D MMM YYYY') : ""}</span>
                            <span >
                                <Button style={{ minWidth: "10px", float: "right", padding: "4px 8px 6px 8px" }} className={classes.closeButton} onClick={() => modalData.handleClickOpen({ start: moment(modalData.state.modalData.date).add(1, 'day').format('dddd, D MMMM YYYY') }, modalData.state.selectedRoom)}><Icon>navigate_next</Icon></Button>
                                <Button style={{ minWidth: "10px", float: "right", padding: "4px 8px 6px 8px" }} className={classes.closeButton} onClick={() => modalData.handleClickOpen({ start: moment(modalData.state.modalData.date).subtract(1, 'day').format('dddd, D MMMM YYYY') }, modalData.state.selectedRoom)}><Icon>navigate_before</Icon></Button>
                            </span>
                        </Typography>

                        {/* {modalData.state.popupView=='OTA' ? 
                                  <span style={{ color: "#A0A2A6", fontSize: "18px", fontWeight: "400", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{modalData.state.popupRoomData ? modalData.state.popupRoomData.name : ""}</span>: " "
                                } */}

                        {modalData.state.popupView == 'OTA' ?
                            <span style={{ color: "#A0A2A6", fontSize: "16px", fontWeight: "400", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }} className={classes.roomTypeName}>{modalData.state.popupRoomData ? modalData.state.popupRoomData.name : ""}, {modalData.state.selectedRoomType.charAt(0).toUpperCase() + modalData.state.selectedRoomType.slice(1)}, {modalData.state.selectedMaxOccupancy} Guest, {compSelected}</span>
                            :
                            <span style={{ color: "#A0A2A6", fontSize: "16px", fontWeight: "400", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{modalData.state.popupRoomData ? modalData.state.popupRoomData.name : ""}</span>
                        }
                    </div>
                    {/* {modalData.state.isOTA &&
                        <div>
                            <div style={{ float: (!modalData.state.weather && modalData.state.weather.icon_code) ? "left" : "right", marginTop: '13px', paddingRight: '40px' }}>
                                <span className={classes.viewbuttons}>View</span>
                                <Button variant="outlined" className={classes.newTabBtnFirst} onClick={() => modalData.togglePopupView("OTA")} style={modalData.state.popupView == "OTA" ? { background: "#00000014", fontWeight: "400" } : { fontWeight: "400" }}>{t("OTA Insight")}</Button>
                                <Button variant="outlined" className={classes.newTabBtnLast} onClick={() => modalData.togglePopupView("price")} style={modalData.state.popupView == "price" ? { background: "#00000014", fontWeight: "400" } : { fontWeight: "400" }}>{t("RoomPriceGenie")}</Button>
                            </div>
                        </div>
                    } */}

                    {modalData.state.weather && modalData.state.weather.icon_code &&
                        <div style={{ marginTop: "12px", float: "left", display: "flex", marginLeft: "30px" }} className={classes.hideOnMobile}>
                            {modalData.state.weather.temperature}
                            {modalData.state.weather.temperature_type == "celcius" ? "°" : "°F"}
                            <img src={"/img/weather/" + modalData.state.weather.icon_code + ".png"} style={{ height: "35px", margin: "-8px 10px 0px 10px" }} />
                            <span style={{ marginTop: "0px", color: "#A0A2A6", fontSize: "16px", fontWeight: "400", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{t(modalData.state.weather.description)}</span>
                        </div>
                    }


                    {/* <div style={{ marginTop: "2px", float: "right", margin: modalData.state.selectedRateType ? "-17px -16px 0px 0px" : "-17px -16px 0px 0px" }}> */}
                    <IconButton aria-label="Close" className={classes.closeButtonPrice} onClick={modalData.handleClose} >
                        <CloseIcon />
                    </IconButton>
                    {/* </div> */}
                </div>

                <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="on"
                    value={modalData.state.modalTab}
                    onChange={modalData.handleNewTabs}
                    style={{ margin: modalData.state.popupView != 'OTA' ? "10px 0px" : '21px 0 10px 0', float: "right", borderBottom: '1px solid #f0f0f0' }}
                >
                    <Tab label={t("Overview")} value={1} />
                    <Tab label={t("Make Adjustments")} value={2} />
                    <Tab label={t(`${modalData.state.room_apartment_space_name} Overview`)} value={3} />
                    <Tab label={t("Pricing Strategy")} value={4} />
                    {modalData.state.featuresEnabled.includes(16) &&
                        <Tab label={t("OTA Insight")} value={5} />
                    }
                </Tabs>

                <Grid container style={{ overflow: "auto", marginBottom: '50px',padding:"10px 25px" }}>
                    {modalData.state.modalTab == 1 &&
                        <Grid container>
                            <Grid item sm={12} md={5}>
                                <div className={classes.priceTableDiv} style={{ paddingLeft: "0px", marginTop: '10px' }}>
                                    {/* <Typography variant={"h6"} style={{ marginLeft: "0px" }}>{t("How it's Calculated")}</Typography> */}
                                    <div className={classes.priceTableDiv2}>
                                        <MuiThemeProvider theme={CalendargetMuiTheme}>
                                            <Table className={classes.pmsTable}>
                                                <TableHead>
                                                    <TableRow className={classes.pmsTableRow}>
                                                        <TableCell align="left" className={classes.pmsNormalRow}><Typography variant="subtitle1">{t("Base Price")}</Typography></TableCell>
                                                        <TableCell align="right" className={classes.pmsNormalRow} style={{ display: "flex" }}>
                                                            <Typography variant="subtitle1">
                                                                {modalData.state.modalData.price !== undefined ?
                                                                    <>
                                                                        {modalData.state.currency.symbol}
                                                                        {avg_price ? avg_price.toFixed(0) : 0}
                                                                    </>
                                                                    :
                                                                    "n.a."
                                                                }
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className={classes.pmsTableRow}>
                                                        <TableCell align="left" className={classes.pmsNormalRow}><Typography variant="subtitle1">{t("Market Factor")}</Typography></TableCell>
                                                        <TableCell align="right" className={classes.pmsNormalRow} style={{ display: "flex" }}>
                                                            <Typography variant="subtitle1">
                                                                {(modalData.state.modalData.price !== undefined && !modalData.state.modalData.no_market_data) ?
                                                                    <>
                                                                        {modalData.state.modalData.change_competitors ? (parseFloat(modalData.state.modalData.change_competitors.toFixed(0)) > 0 ? "+" : "-") : ''}
                                                                        {modalData.state.currency.symbol}
                                                                        {/* {modalData.state.modalData.change_competitors ? Math.abs(parseFloat(modalData.state.modalData.change_competitors.toFixed(0))) : 0} */}
                                                                        {modalData.state.modalData.change_competitors ? new Intl.NumberFormat('ja-JP').format(Math.abs(parseFloat(modalData.state.modalData.change_competitors.toFixed(0)))) : 0}
                                                                    </>
                                                                    :
                                                                    "n.a."
                                                                }
                                                            </Typography>
                                                            <Typography variant="subtitle1" style={modalData.state.modalData.change_competitors_perc ? ((modalData.state.modalData.change_competitors_perc) > 0 ? { color: "#135138", background: "#BBF2DD", fontWeight: "500", padding: "0px 8px", borderRadius: "5px", marginRight: "6px" } : { color: "#890921", background: "#FAC6D1", fontWeight: "500", padding: "0px 8px", borderRadius: "5px", marginRight: "6px" }) : {}}>

                                                                {(modalData.state.modalData.price !== undefined && !modalData.state.modalData.no_market_data) ?
                                                                    <>
                                                                        {modalData.state.modalData.change_competitors_perc ? ((modalData.state.modalData.change_competitors_perc) > 0 ? "+" : "") + modalData.state.modalData.change_competitors_perc + '% ' : ''}
                                                                    </>
                                                                    :
                                                                    "n.a."
                                                                }
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className={classes.pmsTableRow}>
                                                        <TableCell align="left" className={classes.pmsNormalRow}><Typography variant="subtitle1">{t("Occupancy Factor")}</Typography></TableCell>
                                                        <TableCell align="right" className={classes.pmsNormalRow} style={{ display: "flex" }}>
                                                            <Typography variant="subtitle1" >
                                                                {(modalData.state.modalData.price !== undefined && !modalData.state.modalData.no_market_data) ?
                                                                    <>
                                                                        {modalData.state.modalData.change_occupancy ? (parseFloat(modalData.state.modalData.change_occupancy.toFixed(0)) > 0 ? "+" : "-") : ''}
                                                                        {modalData.state.currency.symbol}
                                                                        {/* {modalData.state.modalData.change_occupancy ? Math.abs(parseFloat(modalData.state.modalData.change_occupancy.toFixed(0))) : 0} */}
                                                                        {modalData.state.modalData.change_occupancy ? new Intl.NumberFormat('ja-JP').format(Math.abs(parseFloat(modalData.state.modalData.change_occupancy.toFixed(0)))) : 0}
                                                                    </>
                                                                    :
                                                                    "n.a."
                                                                }
                                                            </Typography>
                                                            <Typography variant="subtitle1" style={modalData.state.modalData.change_occupancy_perc ? ((modalData.state.modalData.change_occupancy_perc) >= 0 ? { color: "#135138", background: "#BBF2DD", fontWeight: "500", padding: "0px 8px", borderRadius: "5px", marginRight: "6px" } : { color: "#890921", background: "#FAC6D1", fontWeight: "500", padding: "0px 8px", borderRadius: "5px", marginRight: "6px" }) : {}} >
                                                                {(modalData.state.modalData.price !== undefined && !modalData.state.modalData.no_market_data) ?
                                                                    <>
                                                                        {modalData.state.modalData.change_occupancy_perc ? ((modalData.state.modalData.change_occupancy_perc) > 0 ? "+" : "") + modalData.state.modalData.change_occupancy_perc + '% ' : ''}
                                                                    </>
                                                                    :
                                                                    "n.a."
                                                                }
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow className={classes.pmsTableRow}>
                                                        <TableCell align="left" className={classes.pmsRowBlankBorder} style={{ borderRadius: "10px 0px 0px 10px", paddingBottom: '25px' }}>
                                                            <Typography variant="subtitle1" style={{ color: (modalData.state.calLoader ? "#9d9d9d" : "#000") }}>

                                                                {t("Adjustments")}
                                                                <Icon onClick={() => modalData.changePricingTab()} className={classes.adjustmentIconHover} style={{ marginBottom: '-7px', cursor: 'pointer', color: '#3772ff' }}>more_horiz</Icon>



                                                                {/* <Button disabled={modalData.state.calLoader} variant={"contained"} color={"primary"} onClick={() => modalData.togglePriceDetailSubModal()} style={{ height: "20px", padding: "0px 7px", fontSize: "12px", marginLeft: "6px" }}>Show All</Button> */}
                                                                {/* <Icon onClick={() => modalData.togglePriceDetailSubModal()} style={{ marginBottom: '-7px' }}>expand_more</Icon> */}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="right" className={classes.pmsRowBlankBorder} style={{ borderRadius: "0px 10px 10px 0px", display: "flex", paddingBottom: '25px' }}>


                                                            <Typography variant="subtitle1" >
                                                                {(modalData.state.modalData.price !== undefined && !modalData.state.modalData.no_market_data) ?
                                                                    <>
                                                                        {parseFloat(modalData.state.modalData.change_user) ? (parseFloat(modalData.state.modalData.change_user.toFixed(0)) > 0 ? "+" : "-") : ''}
                                                                        {modalData.state.currency.symbol}
                                                                        {/* {modalData.state.modalData.change_user ? Math.abs(parseFloat(modalData.state.modalData.change_user.toFixed(0))) : 0} */}
                                                                        {modalData.state.modalData.change_user ? new Intl.NumberFormat('ja-JP').format(Math.abs(parseFloat(modalData.state.modalData.change_user.toFixed(0)))) : 0}
                                                                    </>
                                                                    :
                                                                    "n.a."
                                                                }

                                                            </Typography>
                                                            <Typography variant="subtitle1" style={modalData.state.modalData.change_user ? ((modalData.state.modalData.change_user) >= 0 ? { color: "#135138", background: "#BBF2DD", fontWeight: "500", padding: "0px 8px", borderRadius: "5px", marginRight: "6px" } : { color: "#890921", background: "#FAC6D1", fontWeight: "500", padding: "0px 8px", borderRadius: "5px", marginRight: "6px" }) : { color: "#135138", background: "#BBF2DD", fontWeight: "500", padding: "0px 8px", borderRadius: "5px", marginRight: "6px" }}>
                                                                {(modalData.state.modalData.price !== undefined && !modalData.state.modalData.no_market_data) ?
                                                                    <>
                                                                        {parseFloat(modalData.state.modalData.change_user) ? (parseFloat(modalData.state.modalData.change_user.toFixed(0)) > 0 ? "+" : "-") : ''}
                                                                        {modalData.state.modalData.change_user_perc ? Math.abs(modalData.state.modalData.change_user_perc) : '0'}%
                                                                </>
                                                                    :
                                                                    'n.a.'
                                                                }

                                                            </Typography>

                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow className={classes.pmsTableRow} style={{ borderBottom: '1px solid #a0a2a663', borderTop: '1px solid #a0a2a663' }}>
                                                        <TableCell align="left" className={classes.pmsRowBlankBorder} >
                                                            <Typography variant="h6" style={{ marginLeft: '0px', marginTop: '0px', marginBottom: '0px', fontWeight: "500", color: (modalData.state.calLoader ? "#9d9d9d" : "#000") }}>
                                                                {t("Recommended Price")}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="right" className={classes.pmsRowBlankBorder} >
                                                            <Typography variant="h6" style={{ marginRight: '0px', marginBottom: '9px', margin: '5px', fontWeight: "500", color: (modalData.state.calLoader ? "#9d9d9d" : "#000") }}>
                                                                {modalData.state.modalData.price !== undefined ?
                                                                    <>
                                                                        {modalData.state.currency.symbol}
                                                                        {modalData.state.modalData.suggested_price ? new Intl.NumberFormat('ja-JP').format(modalData.state.modalData.suggested_price) : 0}
                                                                    </>
                                                                    :
                                                                    "n.a."
                                                                }
                                                            </Typography>
                                                        </TableCell>

                                                    </TableRow>
                                                    {modalData.getOldPricesChange(0)}
                                                    {/* {modalData.getOldPricesChange(1)}
                                                    {modalData.getOldPricesChange(2)} */}
                                                    {modalData.state.featuresEnabled.includes(16) &&
                                                        <>
                                                            <Typography style={{ fontSize: '1.25rem', fontWeight: '500', marginLeft: "0px", marginTop: '15px', marginBottom: '15px' }}>{t("OTA Insight")}</Typography>
                                                            <TableRow className={classes.pmsTableRow} style={modalData.getFontcolors(0)} style={{ paddingRight: "5px" }}>

                                                                <TableCell align="left" className={classes.pmsRowBlankBorder} style={{ paddingTop: "0px" }}><Typography variant="subtitle1">{t("Median Flexible Rate")}</Typography></TableCell>
                                                                <TableCell align="right" className={classes.pmsNormalRow} style={{ display: "flex", paddingTop: '0px' }}>
                                                                    <Typography variant="subtitle1">
                                                                        <span>
                                                                            {maidanFlexPrice != "n.a." ? modalData.state.currency.symbol : ""}{maidanFlexPrice}
                                                                        </span>
                                                                    </Typography>
                                                                    {maidanFlexPricePer != "n.a." &&
                                                                        <Typography variant="subtitle1" style={maidanFlexPricePer ? ((maidanFlexPricePer) > 0 ? { color: "#135138", background: "#BBF2DD", fontWeight: "500", padding: "0px 8px", borderRadius: "5px", marginRight: "6px" } : { color: "#890921", background: "#FAC6D1", fontWeight: "500", padding: "0px 8px", borderRadius: "5px", marginRight: "6px" }) : {}}>
                                                                            {maidanFlexPricePer != "n.a." ?

                                                                                (maidanFlexPricePer ? (maidanFlexPricePer > 0 ? " +" : "") : " ") + maidanFlexPricePer + "%"

                                                                                : ""}
                                                                        </Typography>
                                                                    }

                                                                </TableCell>
                                                            </TableRow></>
                                                    }
                                                    {modalData.state.featuresEnabled.includes(16) &&

                                                        <TableRow className={classes.pmsTableRow} style={modalData.getFontcolors(0)} style={{ paddingRight: "5px" }}>
                                                            {/* <TableCell align="left" className={classes.pmsRowBlankBorder}><Typography variant="subtitle1" >{t()}</Typography></TableCell> */}
                                                            <TableCell align="left" className={classes.pmsRowBlankBorder} style={{ paddingTop: "0px" }}><Typography variant="subtitle1">{t("Median Lowest Rate")}</Typography></TableCell>
                                                            <TableCell align="right" className={classes.pmsNormalRow} style={{ display: "flex", paddingTop: '0px' }}>
                                                                <Typography variant="subtitle1">
                                                                    <span>
                                                                        {maidanCheapPrice != "n.a." ? modalData.state.currency.symbol : ""}{maidanCheapPrice}
                                                                    </span>
                                                                </Typography>
                                                                {maidanCheapPricePer != "n.a." &&
                                                                    <Typography variant="subtitle1" style={maidanCheapPricePer ? ((maidanCheapPricePer) > 0 ? { color: "#135138", background: "#BBF2DD", fontWeight: "500", padding: "0px 8px", borderRadius: "5px", marginRight: "6px" } : { color: "#890921", background: "#FAC6D1", fontWeight: "500", padding: "0px 8px", borderRadius: "5px", marginRight: "6px" }) : {}} >
                                                                        {maidanCheapPricePer != "n.a." ?
                                                                            (maidanCheapPricePer ? (maidanCheapPricePer > 0 ? " +" : "") : " ") + maidanCheapPricePer + "%"
                                                                            : ""}
                                                                    </Typography>
                                                                }

                                                            </TableCell>

                                                        </TableRow>

                                                    }
                                                </TableHead>
                                            </Table>
                                        </MuiThemeProvider>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item sm={12} md={1} style={{ overflow: "auto" }}>
                            </Grid>
                            <Grid item sm={12} md={5} style={{ paddingLeft: "0px", marginTop: '10px' }}>
                                <Grid container >
                                    <Grid item sm={12} md={3} lg={3}>
                                        <span className={classes.pmsContent} style={{ marginLeft: "0px" }}>
                                            <HierarchyText
                                                title={
                                                    <>
                                                        {modalData.state.modalData.price !== undefined ?
                                                            <>
                                                                {modalData.state.modalData.total_inventory ? modalData.state.modalData.total_inventory : 0}
                                                            </>
                                                            :
                                                            "n.a."
                                                        }
                                                    </>
                                                }
                                                // label={modalData.state.modalData.total_inventory ? (modalData.state.modalData.total_inventory > 1 ? t(" Rooms Left Total") : t(" Rooms Left Total")) : t(" Rooms Left Total")}
                                                label={<>{t(`${modalData.state.room_apartment_space_name}s Left`)} <br /> {t("Total")}</>}
                                            />
                                        </span>

                                    </Grid>
                                    <Grid item sm={12} md={3} lg={3}>
                                        <span className={classes.pmsContent} style={{ marginLeft: "10px" }}>
                                            <HierarchyText
                                                title={<>
                                                    {modalData.state.modalData.price !== undefined ?
                                                        <>
                                                            {modalData.state.modalData.occupancy ? modalData.state.modalData.occupancy.toFixed(0) + "%" : "0%"}
                                                        </>
                                                        :
                                                        "n.a."
                                                    }
                                                </>
                                                }
                                                label={t("Occupancy")}
                                            />
                                        </span>
                                    </Grid>
                                </Grid>

                                <div style={{ marginTop: '20px' }}>
                                    <TextField
                                        label={t("Notes")}
                                        multiline
                                        rows="3"
                                        value={modalData.state.priceNote}
                                        onChange={modalData.changePriceNote()}
                                        // variant="outlined"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        className={classes.textField}
                                    />
                                </div>
                                {/* <div className={classes.eventListDiv}>
                                    <Typography variant="h6" >{t("Events List")}</Typography>
                                    <Table className={classes.eventTable}>
                                        <TableHead>
                                            {Object.keys(modalData.props.eventReducer.events).length > 0 ? Object.keys(modalData.props.eventReducer.events).map(key => {
                                                let events = modalData.props.eventReducer.events[key];
                                                return events.map((val, index) => {
                                                    return (<React.Fragment key={index}>
                                                        <TableRow className={classes.eventNameRow}>
                                                            <TableCell colSpan="3" className={classes.eventName}>{val.title}</TableCell>
                                                        </TableRow>
                                                        <TableRow className={classes.eventList}>
                                                            <TableCell className={classes.eventDates}>{val.category}</TableCell>
                                                            <TableCell className={classes.eventDates}>
                                                                {moment(val.start_date).format('DD-MM-YYYY') == moment(val.end_date).format('DD-MM-YYYY') ? (moment(val.start_date).format('DD-MM-YYYY')) : (moment(val.start_date).format('DD-MM-YYYY') + ' - ' + moment(val.end_date).format('DD-MM-YYYY'))}
                                                                <span style={{ color: "#d32f2f", marginLeft: "5px" }}>{val.deleted_reason}</span>
                                                            </TableCell>
                                                        </TableRow>
                                                    </React.Fragment>)
                                                })
                                            })
                                                : "No events found."}
                                        </TableHead>
                                    </Table>
                                </div> */}
                                <div className={classes.eventListDiv}>

                                    <Typography variant="h6" style={{marginBottom:"12px"}}>{t("Events List")}</Typography>
                                    {

                                        Object.keys(modalData.props.eventCategoryReducer.eventCategory).length > 0 ? Object.keys(modalData.props.eventCategoryReducer.eventCategory).map(key => {
                                            let events = modalData.props.eventCategoryReducer.eventCategory[key];
                                            {events.sort((a, b) => {
                                                if (a.weighted_local_rank < b.weighted_local_rank) { return 1; }
                                                if (a.weighted_local_rank > b.weighted_local_rank) { return -1; }
                                                return 0;
                                            })}
                                            return events.map((val,index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <Card className={classes.eventListContent}>
                                                            <Grid container>
                                                                <Grid item xs={7} style={{display:"flex"}}>
                                                                    <span className={classes.chipContent} style={{background:(val.category === "public-holidays" || val.category === "school-holidays") ? "#FFDB99" : "#D8DAE9"}}>{val.category}</span>
                                                                    <span className={classes.chipContentIcon} style={{background:"#D8DAE9"}}><LocationOnOutlinedIcon style={{color:"#fff", opacity:"0.5"}}/></span>
                                                                </Grid>
                                                                <Grid item xs={5} style={{textAlign:"right"}}>
                                                                    <Typography variant="h6" style={{fontSize:"1rem", paddingTop:3}} >
                                                                        {moment(val.start_date).format('DD-MM-YYYY') === moment(val.end_date).format('DD-MM-YYYY') ? (moment(val.start_date).format('DD/MM')) : (moment(val.start_date).format('DD/MM') + ' - ' + moment(val.end_date).format('DD/MM'))}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} style={{paddingLeft:"8px"}}>
                                                                    <Typography variant="h6" style={{fontWeight:"bold"}}>{val.title}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Card>
                                                    </React.Fragment>
                                                )
                                            })
                                            
                                        }): <Typography>{t("No events found.")}</Typography>
                                    }
                                    
                                </div>
                                
                            </Grid>
                        </Grid>
                    }
                    {modalData.state.modalTab == 2 &&
                        <Grid item xs={12} style={{ marginTop: '17px' }}>
                            <div className={classes.dateNavigationDiv} >
                                <MuiThemeProvider theme={CalendarbuttonTheme} >
                                    <div className={classes.graphtextDiv}>
                                        <div>
                                            <Button variant="outlined" className={classes.newTabBtnFirst} onClick={() => modalData.changeAdjTab("adjust")} style={modalData.state.adjTab == "adjust" ? { background: "#00000014" } : {}}>{t("Percent Adjustment")}</Button>
                                            {/* <Button disabled={!modalData.state.featuresEnabled.includes(1)} variant="outlined" className={classes.newTabBtn} onClick={() => modalData.changeAdjTab("minmax")} style={modalData.state.adjTab == "minmax" ? { background: "#00000014" } : {}}>{t("Min/Max Prices")}</Button> */}
                                            <Button variant="outlined" className={classes.newTabBtnLast} onClick={() => modalData.changeAdjTab("fix")} style={modalData.state.adjTab == "fix" ? { background: "#00000014" } : {}}>{t("Fix Prices")}</Button>
                                        </div>
                                    </div>
                                </MuiThemeProvider>
                                
                                <div style={{ float: "right", marginLeft: "16px" }}>
                                    {modalData.state.calLoader ? t("Recalculating") : ""}
                                    {modalData.state.calLoader ? <CircularProgress color="primary" size={14} style={{ marginLeft: "10px" }} /> : ""}
                                </div>
                            </div>


                            {modalData.state.adjTab == "adjust" &&

                                <Grid xs={12} item style={{ marginRight: "70px", marginTop: "70px" }}>
                                    <Table style={{ marginTop: "12px" }}>
                                        <TableHead className={classes.pmsTableRow}>
                                            <TableCell className={classes.tableCell}>{t(modalData.state.room_apartment_space_name)}</TableCell>
                                            <TableCell className={classes.tableCell} >{t("Percent Adjustment")}</TableCell>
                                            <TableCell className={classes.tableCell}style={{ textAlign: "right" }}>{t("Recommended Price")}</TableCell>
                                        </TableHead>
                                        {modalData.props.roomList.roomList.sort((a, b) => b.is_reference_room - a.is_reference_room).filter(val => (!modalData.state.refRoomOnly || val.is_reference_room)).map((rooms, index) => {
                                            return (
                                                <TableRow className={[classes.tableCell,classes.pmsTableRow]}>
                                                    <TableCell className={[classes.tableCell,classes.rowAdj]} style={{ width: "250px", padding: "22px 0px", fontWeight: rooms.is_reference_room ? "500" : "400" }}>
                                                        {rooms.name}
                                                    </TableCell >
                                                    <TableCell className={[ classes.rowAdj,classes.tableCell]}>
                                                        <FormControl fullWidth margin="none" style={{ marginTop: "-16px", padding: "8px 0px" }}>
                                                            <TextField
                                                                margin="none"
                                                                className={classes.adjustmentTextfield}
                                                                type="number"
                                                                id="standard-name"
                                                                value={modalData.state.bulkUpdateData.dailyAdjust}
                                                                onChange={modalData.bulkUpdateData('dailyAdjust', modalData.state.selectedRoom, true)}
                                                                InputLabelProps={{ shrink: true }}
                                                                disabled={!rooms.is_reference_room}
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                                }}
                                                            />
                                                        </FormControl>
                                                        {modalData.state.error.includes('upload_price') ? <span class='error-message'>{t('* Please enter valid price')}</span> : ''}

                                                        {/* } */}
                                                    </TableCell>
                                                    <TableCell className={[ classes.rowAdj,classes.tableCell]} style={{ textAlign: "right" }}>
                                                        {modalData.state.currency.symbol}{modalData.state.tempUpload[rooms.id] ? new Intl.NumberFormat('ja-JP').format(modalData.state.tempUpload[rooms.id].price) : ""}
                                                    </TableCell >
                                                </TableRow>
                                            )
                                        })}
                                    </Table>

                                </Grid>
                            }
                          


                            {modalData.state.adjTab == "fix" &&
                                <>
                                    {/* <Typography variant={"h6"} style={{ marginTop: "10px" }}>{t("Adjust Price")}</Typography> */}
                                    <div className={classes.priceTableDiv}>

                                        <div className={classes.priceTableDiv1}>
                                            <Grid container>
                                                {!modalData.state.refRoomOnly &&
                                                    <Grid md={4}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    color={"primary"}
                                                                    checked={modalData.state.deriveFromRef}
                                                                    onChange={modalData.handlederiveFromRef}
                                                                />
                                                            }
                                                            style={{ width: "90%" }}
                                                            label={t(`Prefill Derived ${modalData.state.room_apartment_space_name}s`)}
                                                        />
                                                    </Grid>

                                                }
                                                <Grid md={6}>
                                                    {(modalData.state.popupRoomData && modalData.state.popupRoomData.is_reference_room) &&
                                                        <FormControl required className={classes.adjustmentTextfield}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        color="primary" 
                                                                        checked={modalData.state.isSkipDateModal}
                                                                        onChange={modalData.handleisSkipDateModal}
                                                                    />
                                                                }
                                                                label={t("Skip Date from Pricing")}
                                                            />
                                                        </FormControl>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid xs={12} item style={{ marginRight: "70px" }}>
                                                <Table style={{ marginTop: "12px" }}>
                                                    <TableHead className={classes.pmsTableRow}>
                                                        <TableCell>{t(`${modalData.state.room_apartment_space_name}`)}</TableCell>
                                                        <TableCell>{t("Fix Prices")}</TableCell>
                                                    </TableHead>
                                                    {modalData.props.roomList.roomList.sort((a, b) => b.is_reference_room - a.is_reference_room).filter(val => (!modalData.state.refRoomOnly || val.is_reference_room)).map((rooms, index) => {
                                                        return (
                                                            <TableRow className={classes.pmsTableRow}>
                                                                <TableCell className={classes.rowAdj} style={{ width: "250px", padding: "22px 0px", fontWeight: rooms.is_reference_room ? "500" : "400" }}>
                                                                    {rooms.name}
                                                                </TableCell >
                                                                <TableCell className={classes.rowAdj}>

                                                                    {/* {modalData.state.featuresEnabled.includes(13) &&
                                                                        <FormControl fullWidth margin="none" required className={classes.formControlLeft} style={{ marginTop: "-2px", padding: "8px 0px" }}>
                                                                            <TextField
                                                                                fullWidth
                                                                                className={classes.predefined}
                                                                                id="standard-name"
                                                                                label={""}
                                                                                select
                                                                                value={modalData.state.bulkUpdateData.fixedPrice[rooms.id]}
                                                                                onChange={modalData.bulkUpdateData('fixedPrice', rooms.id, true)}
                                                                                InputLabelProps={{ shrink: true }}
                                                                                disabled={modalData.state.isSkipDateModal}
                                                                            >
                                                                                {modalData.state.preDefinedRates.map((option, index) => (
                                                                                    <MenuItem key={index} value={option}>
                                                                                        {modalData.state.currency.symbol + option}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>
                                                                        </FormControl>
                                                                    } */}

                                                                    {/* {!modalData.state.featuresEnabled.includes(13) && */}
                                                                    <Tooltip
                                                                        classes={{
                                                                            popper: "toolTipPooper",
                                                                            tooltip: classes.htmlTooltip,
                                                                        }}
                                                                        title={!roomData.is_reference_room ? t(`Please go to your Reference ${modalData.state.room_apartment_space_name} Type to edit the Fix Prices.`) : ""}
                                                                    >
                                                                        <FormControl required margin="none" className={classes.formControlLeft} style={{ marginTop: "-16px", padding: "8px 0px" }}>
                                                                            <TextField
                                                                                type="number"
                                                                                disabled={is_old}
                                                                                error={modalData.state.error.includes('upload_price')}
                                                                                id="standard-name"
                                                                                value={modalData.state.bulkUpdateData.fixedPrice[rooms.id]}
                                                                                onChange={modalData.bulkUpdateData('fixedPrice', rooms.id, true)}
                                                                                disabled={modalData.state.isSkipDateModal}
                                                                                InputProps={{
                                                                                    startAdornment: <InputAdornment position="start">{modalData.state.currency.symbol}</InputAdornment>,
                                                                                    // endAdornment: <InputAdornment position="end"><Icon className="blankInput" onClick={() => modalData.clearFixedPrice()}>close</Icon></InputAdornment>,
                                                                                }}
                                                                            />
                                                                            {modalData.state.error.includes('upload_price') ? <span class='error-message'>{t('* Please enter valid price')}</span> : ''}
                                                                        </FormControl>
                                                                    </Tooltip>
                                                                    {/* } */}
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </Table>
                                                {/* {!modalData.state.predefined_rate && modalData.state.price_type && modalData.state.price_type == 'fix' && modalData.state.popupRoomData.is_reference_room && */}
                                            </Grid>

                                        </div>
                                    </div>
                                    {/* <Grid container>
                                        {(modalData.state.popupRoomData && modalData.state.popupRoomData.is_reference_room) &&
                                            <FormControl required className={classes.adjustmentTextfield}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={modalData.state.isSkipDateModal}
                                                            onChange={modalData.handleisSkipDateModal}
                                                        />
                                                    }
                                                    label={t("Skip Date from Pricing")}
                                                />
                                            </FormControl>
                                        }
                                    </Grid> */}
                                </>
                            }

                        </Grid>
                    }

                    {modalData.state.modalTab == 3 &&
                        <Grid item xs={10} >
                            <MuiThemeProvider theme={CalendargetMuiTheme2}>
                                <MUIDataTable
                                    data={dataRooms}
                                    columns={columnsRooms}
                                    options={optionsRooms}
                                    style={{ marginTop: "-55px" }}
                                />
                            </MuiThemeProvider>
                        </Grid>
                    }

                    {modalData.state.modalTab == 4 &&
                        <Grid container>
                            <Grid item xs={12} md={5} style={{ paddingLeft: "0px", marginTop: '17px' }}>
                                {/* <Typography variant="subheading" style={{ fontWeight: '500', fontSize: '1.25rem', marginBottom: '15px' }}>{t('Date Adjustments')}</Typography> */}
                                <Table>
                                    
                                    <TableRow className={classes.pmsTableRow} style={{borderBottom:'1px solid #a0a2a663'}}>
                                        <TableCell className={classes.tooltipTableeCell} align="left" style={{ width: "250px" }}>
                                            {t('Base Price + Market Factor + Occupancy/Pickup Factor')}
                                        </TableCell>
                                        <TableCell className={classes.tooltipTableeCell} align="right" >
                                            {modalData.state.currency.symbol + suggestedPriceWithoutRestriction}                                            
                                        </TableCell>
                                    </TableRow >
                                    
                                    <TableRow className={classes.pmsTableRow} style={{marginTop:'5px'}}>
                                        <TableCell className={classes.tooltipTableeCell1} align="left" style={{ width: "250px" }}>
                                            {t('Percent Adjustment')}
                                        </TableCell>
                                        <TableCell className={classes.tooltipTableeCell1} align="right" >
                                            {((modalData.state.bulkUpdateData.dailyAdjust ? (modalData.state.bulkUpdateData.dailyAdjust > 0 ? "+" : "-") : "") + Math.abs(modalData.state.bulkUpdateData.dailyAdjust))}%
                                        </TableCell>
                                    </TableRow >
                                    <TableRow className={classes.pmsTableRow}>
                                        <TableCell className={classes.tooltipTableeCell} align="left" >
                                            {t('Day-of-the-Week')}
                                             {/* ({modalData.state.modalData ? moment(modalData.state.modalData.date).format('ddd') : ""}) */}
                                </TableCell>
                                        <TableCell className={classes.tooltipTableeCell} align="right" >
                                            {modalData.state.weekPercentage ? ((modalData.state.weekPercentage > 0 ? "+" : "-") + Math.abs(modalData.state.weekPercentage)) : 0}%
                                </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.pmsTableRow}>
                                        <TableCell className={classes.tooltipTableeCell} align="left" >
                                            {t('Monthly')} 
                                            {/* ({modalData.state.modalData ? moment(modalData.state.modalData.date).format('MMM') : ""}) */}
                                </TableCell>
                                        <TableCell className={classes.tooltipTableeCell} align="right" >
                                            {modalData.state.monthPercentage ? ((modalData.state.monthPercentage > 0 ? "+" : "-") + Math.abs(modalData.state.monthPercentage)) : 0}%
                                </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.pmsTableRow}>
                                        <TableCell className={classes.tooltipTableeCell} align="left" >
                                            {t('Lead Time')}
                                        </TableCell>
                                        <TableCell className={classes.tooltipTableeCell} align="right" >
                                            {modalData.state.modalData.leadPer ? ((modalData.state.modalData.leadPer > 0 ? "+" : "-") + Math.abs(modalData.state.modalData.leadPer)) : 0}%
                                        </TableCell>
                                    </TableRow>
                                    {modalData.state.featuresEnabled.includes(9) &&
                                    <>
                                        {/* <Typography align={"left"} variant="subheading" style={{ fontWeight: '500', fontSize: '18px', margin: '15px 0px 0px' }}>{t('Yielding Tags')}</Typography> */}
                                        {/* <Table> */}
                                            {tagName.length ?
                                                tagName.map((idx, elem) => {
                                                    return (
                                                        <>
                                                            <TableRow className={classes.pmsTableRow}>
                                                                <TableCell className={classes.tooltipTableeCell} align="left" >
                                                                    {idx.tag_name}
                                                                </TableCell>
                                                                <TableCell className={classes.tooltipTableeCell} align="right" >
                                                                    {idx.adj ? ((idx.adj > 0) ? "+" : "-") : ""}
                                                                    {idx.adj ? idx.adj : '0'}%
                                                                    </TableCell>
                                                            </TableRow >
                                                        </>
                                                    );
                                                })
                                                :
                                                <TableRow className={classes.pmsTableRow}>
                                                    <TableCell className={classes.tooltipTableeCell} align="left" >
                                                        None
                                                    </TableCell>
                                                    <TableCell className={classes.tooltipTableeCell} align="left" >

                                                    </TableCell>
                                                </TableRow >

                                            }
                                        {/* </Table> */}
                                    </>
                                }
                                    {modalData.state.featuresEnabled.includes(15) &&
                                        <TableRow className={classes.pmsTableRow}>
                                            <TableCell className={classes.tooltipTableeCell} align="left" >
                                                {t('Target Occupancy')}
                                            </TableCell>
                                            <TableCell className={classes.tooltipTableeCell} align="right" >
                                                {modalData.state.modalData.changes_to_auto_correction_perc ? ((modalData.state.modalData.changes_to_auto_correction_perc > 0 ? "+" : "-") + Math.abs(modalData.state.modalData.changes_to_auto_correction_perc)) : 0}%
                                        </TableCell>
                                        </TableRow>
                                    }
                                    {modalData.state.featuresEnabled.includes(15) &&
                                        <TableRow className={classes.pmsTableRow}>
                                            <TableCell className={classes.tooltipTableeCell} align="left" >
                                                {t('Min Stay')}
                                            </TableCell>
                                            <TableCell className={classes.tooltipTableeCell} align="right" >
                                                {modalData.state.modalData.changes_to_min_stay_perc ? ((modalData.state.modalData.changes_to_min_stay_perc > 0 ? "+" : "-") + Math.abs(modalData.state.modalData.changes_to_min_stay_perc)) : 0}%
                                        </TableCell>
                                        </TableRow>
                                    }
                                </Table>

                                {/* <Typography variant="subheading" style={{ margin: "15px 0px 17px 0px", fontWeight: '500', fontSize: '1.25rem' }}>{modalData.state.popupRoomData ? ("Room Type " + modalData.state.popupRoomData.name) : ""}</Typography> */}
                                <Table>
                                    {roomData && !roomData.is_reference_room &&
                                        <TableRow className={classes.pmsTableRow} >
                                            <TableCell className={classes.tooltipTableeCell} align="left" style={{ width: "160px" }}>
                                                {t('Derivation')}
                                            </TableCell>

                                            <TableCell className={classes.tooltipTableeCell} align="right">
                                                {modalData.state.bulkUpdateData.adjVsRef[modalData.state.selectedRoom] ? ((modalData.state.bulkUpdateData.adjVsRef[modalData.state.selectedRoom] > 0) ? "+" : "-") : ""}
                                                {modalData.state.isAbsolute ? modalData.state.currency.symbol : ""}
                                                {modalData.state.bulkUpdateData.adjVsRef ? Math.abs(modalData.state.bulkUpdateData.adjVsRef[modalData.state.selectedRoom]) : 0}
                                                {!modalData.state.isAbsolute ? "%" : ""}
                                            </TableCell>
                                        </TableRow >
                                    }
                                    {(modalData.state.featuresEnabled.includes(6) || roomData.is_reference_room) &&
                                        <TableRow className={classes.pmsTableRow}>
                                            <TableCell className={classes.tooltipTableeCell} align="left">
                                                {t('Minimum Price')}
                                            </TableCell>
                                            <TableCell className={classes.tooltipTableeCell} align="right">
                                                {/* {modalData.state.modalMinPrice > 0 ? "+" : "-"} */}
                                                {modalData.state.currency.symbol + new Intl.NumberFormat('ja-JP').format(Math.abs(modalData.state.bulkUpdateData.minPrice[modalData.state.selectedRoom]))}
                                            </TableCell>
                                        </TableRow>
                                    }
                                    {(modalData.state.featuresEnabled.includes(6) || roomData.is_reference_room) &&
                                        <TableRow className={classes.pmsTableRow} style={{borderBottom:'1px solid #a0a2a663'}}>
                                            <TableCell className={classes.tooltipTableeCell} align="left">
                                                {t('Maximum Price')}
                                            </TableCell>
                                            <TableCell className={classes.tooltipTableeCell} align="right">
                                                {/* {modalData.state.modalmaxPrice > 0 ? "+" : "-"} */}
                                                {modalData.state.currency.symbol + new Intl.NumberFormat('ja-JP').format(Math.abs(modalData.state.bulkUpdateData.maxPrice[modalData.state.selectedRoom]))}
                                            </TableCell>
                                        </TableRow>
                                    }
                                    <TableRow className={classes.pmsTableRow} >
                                            <TableCell className={classes.tooltipTableeCell1} align="left">
                                                {t('Recommended Price')}
                                            </TableCell>
                                            <TableCell className={classes.tooltipTableeCell1} align="right">
                                            {modalData.state.currency.symbol}
                                            {modalData.state.modalData.suggested_price ? new Intl.NumberFormat('ja-JP').format(modalData.state.modalData.suggested_price) : 0}
                                            </TableCell>
                                    </TableRow>
                                    
                                </Table>

                                
                            </Grid>

                        </Grid>
                    }

                    {modalData.state.modalTab == 5 &&
                        <Grid container>
                            <Grid item xs={12} style={{ marginTop: "-60px" }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container style={{ height: "33px" }}>
                                            <Grid item xs={12}>
                                                <div className={classes.graphtextDiv} style={{ alignItems: "flex-end" }}>
                                                    <img src={"/img/otaLogo.png"} style={{ height: "30px", marginRight: '8px' }} />
                                                </div>
                                            </Grid>
                                            {/* <Grid item xs={6}>
                                            <div className={classes.grapht0extDiv} style={{ alignItems: "flex-end" }}>
                                                <div>
                                                    <Button variant="outlined" className={classes.newTabBtnFirst} onClick={() => modalData.togglePopupView("price")} style={modalData.state.popupView == "price" ? { background: "#00000014" } : {}}>{t("OTA Insight")}</Button>
                                                    <Button variant="outlined" className={classes.newTabBtnLast} onClick={() => modalData.togglePopupView("OTA")} style={modalData.state.popupView == "OTA" ? { background: "#00000014" } : {}}>{t("RoomPriceGenie Price")}</Button>
                                                </div>
                                            </div>
                                        </Grid> */}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} style={{ paddingTop: "20px", display: "flex", marginBottom: "10px", marginTop: '18px' }}>
                                        <div>
                                            <Typography variant={"h6"} align={"left"} style={{ color: "#000000de" }}>
                                                {maidanFlexPrice != "n.a." ? modalData.state.currency.symbol : ""}
                                                {maidanFlexPrice}
                                                {maidanFlexPricePer != "n.a." ?
                                                    <span variant="subtitle1" style={maidanFlexPricePer ? ((maidanFlexPricePer) > 0 ? { color: "#135138", background: "#BBF2DD", fontWeight: "500", padding: "2px 7px", borderRadius: "5px", marginLeft: "6px", fontSize: '16px' } : { color: "#890921", background: "#FAC6D1", fontWeight: "500", padding: "2px 7px", borderRadius: "5px", marginLeft: "6px", fontSize: '16px' }) : {}}>
                                                        {/* <span style={maidanFlexPricePer > 0 ? { color: "#3dd2bf", fontWeight: "500" } : { color: "#fe87b7", fontWeight: "500" }}> */}
                                                        {(maidanFlexPricePer ? (maidanFlexPricePer > 0 ? " +" : " ") : " ") + maidanFlexPricePer + "%"}
                                                    </span>
                                                    : ""}
                                            </Typography>
                                            <Typography variant={"subTitle1"} align={"left"}>{t("Median Flexible Rate")}</Typography>
                                        </div>
                                        <div style={{ marginLeft: "16px" }}>
                                            <Typography variant={"h6"} align={"left"} style={{ color: "#000000de" }}>
                                                {maidanCheapPrice != "n.a." ? modalData.state.currency.symbol : " "}
                                                {maidanCheapPrice}
                                                {maidanCheapPricePer != "n.a." ?

                                                    <span variant="subtitle1" style={maidanCheapPricePer ? ((maidanCheapPricePer) > 0 ? { color: "#135138", background: "#BBF2DD", fontWeight: "500", padding: "2px 7px", borderRadius: "5px", marginLeft: "6px", fontSize: '16px' } : { color: "#890921", background: "#FAC6D1", fontWeight: "500", padding: "2px 7px", borderRadius: "5px", marginLeft: "6px", fontSize: '16px' }) : {}}>
                                                        {/* <span style={maidanCheapPricePer > 0 ? { color: "#3dd2bf", fontWeight: "500" } : { color: "#fe87b7", fontWeight: "500" }}> */}
                                                        {(maidanCheapPricePer ? (maidanCheapPricePer > 0 ? " +" : " ") : " ") + maidanCheapPricePer + "%"}
                                                        {/* </span> */}
                                                    </span>
                                                    : ""}
                                            </Typography>
                                            <Typography variant={"subTitle1"} align={"left"}>{t("Median Lowest Rate")}</Typography>
                                        </div>
                                        <div style={{ marginLeft: "16px" }}>
                                            <Typography variant={"h6"} align={"left"} style={{ color: "#000000de" }}>{modalData.state.modalData.price !== undefined ?
                                                <>
                                                    {modalData.state.currency.symbol}
                                                    {modalData.state.modalData.price ? (modalData.state.modalData.price) : 0}
                                                </>
                                                :
                                                "n.a."
                                            }</Typography>
                                            <Typography variant={"subTitle1"} align={"left"}>{t("RoomPriceGenie Price")}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MuiThemeProvider theme={CalendargetMuiTheme2}>
                                            <MUIDataTable
                                                data={data}
                                                columns={columns}
                                                options={options}
                                                style={{ marginTop: "-8px" }}
                                            />
                                        </MuiThemeProvider>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }


                </Grid>

                <div className={`${classes.buttonGroupBottom } ${classes.sidePopupFooter}`} >
                        <div >
                        {modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_sync == 2 && modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider &&
                            <Button disabled={modalData.state.loadingSave || modalData.state.calLoader} onClick={() => modalData.uploadSingleModal(true)} className={classes.saveUploadBtn} variant="contained" color="primary" >
                                {t("Upload Prices")}
                            </Button>
                        }
                        <Button disabled={modalData.state.loadingSave || modalData.state.calLoader} onClick={() => { modalData.saveSkipAdjustData(); }} className={[classes.saveUploadBtn, "orangButton"]} style={{background:"white"}}  variant="outlined" color="primary" >
                            {t("Save")}{/* {(modalData.state.price_type == 'skip' || !(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_sync == 2 && modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider)) ? t("Save") : t("Save & Upload")} */}
                            {modalData.state.loadingSave && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                        </Button>

                        {modalData.state.modalTab == 2 &&
                            <Button onClick={() => modalData.setDefaultValues()} style={{background:"white"}} className={classes.saveUploadBtn} variant="outlined" color="primary" >
                                {t("Remove Adjustments")}
                                {modalData.state.removeAdjustLoader && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                            </Button>
                        }
                         <Button  className={[classes.saveUploadBtn, "cancelBtn"]}  style={{background:"white"}} onClick={modalData.handleClose}>
                        {t("Cancel")}
                    </Button>
                    </div>
                </div>

            </SwipeableDrawer >)
    },
    PurgeReservationModal: function(modalData){
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            maxWidth='md'
            open={modalData.state.purgeReservationModal}
            onClose={modalData.updatePurgeReservationModalClose}
            aria-labelledby="responsive-dialog-title"
            style={{maxWidth:'720px',margin:'auto',display:'block'}}
        >
            <DialogTitle id="responsive-dialog-title">
                {t("Purge all reservations?")}
            </DialogTitle>
            <DialogContent>
                
                    This will remove all reservations for this property. It can take many hours to pull them again. For some PMS like Protel (which push reservations to us), you will have to ask their support to initiate another full push.
                
            </DialogContent>
            <DialogActions>
                
                <Button  onClick={() => { modalData.updatePurgeReservation() }} className={classes.saveUploadBtn} variant="contained" color="secondary" >
                    {t("Purge All Reservations")}
                </Button>
                <Button onClick={() => modalData.updatePurgeReservationModalClose()} className="cancelBtn"  >
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>)
    },
    csvFormatPopup: function(modalData){
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            maxWidth='md'
            open={modalData.state.csvFormatPopup}
            onClose={modalData.toggleCsvFormatPopup}
            aria-labelledby="responsive-dialog-title"
            PaperProps={{ style: { maxWidth: "900px"}}}
            // style={{maxWidth:'720px',margin:'auto',display:'block'}}
        >
            <DialogTitle id="responsive-dialog-title">
                {t("Download CSV")}                            
            </DialogTitle>
            <IconButton onClick={() => modalData.toggleCsvFormatPopup()} aria-label="Close" className={classes.closeButtonPrice} >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <RadioGroup
                    className={classes.radioGroupDates}
                    value={parseInt(modalData.state.csvFormat)}
                    onClick={(event) => modalData.handleFormat(event.target.value)}
                    style={{display:'flex',flexDirection:'column'}}
                >
                        <FormControlLabel value={1} control={<Radio color="primary" />} label={t(`For all ${modalData.state.room_apartment_space_name.toLowerCase()} types`)} />
                        <FormControlLabel value={2} control={<Radio color="primary" />} label={t(`For selected ${modalData.state.room_apartment_space_name.toLowerCase()} type only`)} />                 
                </RadioGroup>
            </DialogContent>
            <DialogActions style={{marginTop:'-19px'}}>
                <Button disabled={modalData.state.formatLoader} onClick={() => { modalData.downloadCsvFormat() }} className={classes.saveUploadBtn} variant="contained" color="primary" >
                    {t("Download")} {modalData.state.formatLoader ?<CircularProgress color="primary" size={24} className={classes.buttonProgress} /> : ""}
                </Button>
                <Button onClick={() => modalData.toggleCsvFormatPopup()} className="cancelBtn" style={{marginTop:"10px"}}>
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>)
    }, 
    profitReportModal :  function(modalData){  
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props; 
        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            maxWidth='md'
            open={modalData.state.profitReportSuccess}
            onClose={modalData.closePdfModal}
            aria-labelledby="responsive-dialog-title"
            style={{maxWidth:'720px',margin:'auto',display:'block'}}
        >
            
            <DialogTitle id="responsive-dialog-title">
                {t('Profit Report')}
            </DialogTitle>
            <DialogContent style={{ paddingBottom: "0px" }}>
            <DialogContentText align="left" className={classes.marginBottom20}>
                {t(modalData.state.profitReportMessage)}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => modalData.closePdfModal()}  variant="contained" color="primary" >
                    {t("Close")}
                </Button>
            </DialogActions>
        </Dialog>)
    },

    deadRoomRateTypeModal: function (modalData) {
        let hotel_id = modalData.props.hotelAuthReducer.hotelDetails.hotel.id
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.deadRoomRateType}
                onClose={() => modalData.deadRoomRateTypeModal()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t(`Warning`)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t(`One or more room types and rates mapped before do not exist anymore. Please go to room setup and check your mapping. Please do not hesitate to contact us if you need help.`)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                  
                    {!modalData.state.is_from_roomsetting &&
                        <Button onClick={() => { modalData.props.history.push('/client/RoomSetting/'+ hotel_id) }} variant="contained" color="primary" >
                            {t("Go to Room Setup")}
                        </Button>
                    }
                    <Button onClick={() => modalData.deadRoomRateTypeModal()}  className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    revenueTable: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        const data = [];
        var daysInMonth = [];
        let closedRooms
        let startOfMonth = moment(modalData.state.monthChangeDate).startOf('month');
        // let endOfMonth = moment(modalData.state.monthChangeDate).endOf('month').format('YYYY-MM-DD');
        for(let i=0;i<startOfMonth.daysInMonth();i++){
            let newDay=startOfMonth.clone().add(i,'days');
            daysInMonth.push(newDay.format('YYYY-MM-DD')); 
        }
        let sumOfClosedRoom=0;
        daysInMonth.map((date) => {
            let pmsOccupany = 0;
            let closedRooms = 0 ;
            if (modalData.state.dateSetting.dates && modalData.state.dateSetting.dates[date] && modalData.state.dateSetting.dates[date][modalData.state.selectedRoom] && modalData.state.dateSetting.dates[date][modalData.state.selectedRoom].closed_rooms) {
                closedRooms = modalData.state.dateSetting.dates[date][modalData.state.selectedRoom].closed_rooms;
                sumOfClosedRoom+=closedRooms
            }
            if(modalData.state.pricing_result[date] && modalData.state.pricing_result[date].property){
                pmsOccupany = modalData.state.pricing_result[date].property.occupancy
            }
            let dashboardOccupancy=modalData.state.kpi_daily_total['OCCUPANCY']?.[date]?modalData.state.kpi_daily_total['OCCUPANCY'][date]:0;
            let diff = parseInt(pmsOccupany)-parseInt(dashboardOccupancy);
            data.push([
                date,
                closedRooms, 
                pmsOccupany+'%',
                dashboardOccupancy+'%',
                diff + '%'                
            ])
        })
        const columns = [
            {
              name: t("Date"),
              options: {
                filter: false,
                sort:true,
                setCellProps: () => ({style: {whiteSpace:'nowrap'}})
              }
            },
            {
              name: t("Closed Room in RoomPriceGenie"),
              options: {
                filter: false,
                sort:false,
                setCellProps: () => ({style: {whiteSpace:'nowrap'}})
              }
            },
            {
              name: t(`${modalData.state.pms_channel_manager_text} Occupancy`),
              options: {
                filter: false,
                sort:false,
                setCellProps: () => ({style: {whiteSpace:'nowrap'}})
              }
            },
            {
              name: t("Dashboard Occupancy"),
              options: {
                filter: false,
                sort:false,
              }
            },
            {
              name: t("Difference"),
              options: {
                filter: false,
                sort: false
              }
            },
            
          ];
      
        const options = {
            sort: true,
            toolbar: false,
            filter: false,
            print: false,
            download: false,
            viewColumns: false,
            responsive: "scroll",
            selectableRows: false,
            serverSide: false,
            rowsPerPage: 20,
            rowsPerPageOptions: false,
            pagination: false,
            search: false,
            fixedHeader: true,
            fixedSelectColumn: true,
            tableBodyHeight: '400px',
            responsive: 'standard',

        }
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                maxWidth='lg'
                open={modalData.state.opentRevenueTableModal}
                onClose={() => modalData.closeRevenueTable()}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{ style: { maxWidth: "500px", margin: "10px", minWidth: '75%',overflow:'unset',padding:'0px !important',margin:'0px 30px !important' } }}
            >
                <DialogTitle id="responsive-dialog-title">{t('Occupancy Comparison - Availabilities vs. Reservations')}
                    <Grid item xs={12} >
                    <div className={classNames(classes.dateNavigationDiv2, classes.dateNavigationDivResponsive,(modalData.state.showFullYearGraph && modalData.state.viewType == 'priceGraph') ? classes.hidediv:'')} style={{ float: "left" }}>
                        <Typography className={classes.textSecondary} variant="h6" style={{ width: "95px",marginLeft:'0px', color: (modalData.state.showFullYearGraph && modalData.state.viewType == 'priceGraph') ? "#00000042" : '#000000bf', textAlign: "left", fontWeight: "400" }}>
                        {t(moment(modalData.state.monthChangeDate).format('MMM'))}{moment(modalData.state.monthChangeDate).format(' YYYY')}
                            </Typography>
                        <Button
                            disabled={(modalData.state.showFullYearGraph && modalData.state.viewType == 'priceGraph')}
                            className={[classes.calendarNaviBtn, classes.textSecondary]}
                            onClick={() => modalData.navigateMonth(moment(modalData.state.monthChangeDate).subtract(1, 'months').format('YYYY-MM-DD'))}>
                            <Icon>navigate_before</Icon>
                        </Button>
                        <Button className={[classes.calendarNaviBtn, classes.textSecondary]}
                            disabled={(modalData.state.showFullYearGraph && modalData.state.viewType == 'priceGraph')}
                            onClick={() => modalData.navigateMonth(moment(modalData.state.monthChangeDate).add(1, 'months').format('YYYY-MM-DD'))}>
                            <Icon>navigate_next</Icon>
                        </Button>
                        <MuiThemeProvider theme={CalendarbuttonTheme} >
                            <Button
                            className={[classes.fullWidthResp, classes.textSecondary]}
                            disabled={(modalData.state.showFullYearGraph && modalData.state.viewType == 'priceGraph')}
                            variant="outlined"
                            style={{ margin: "0px 10px" }}
                            onClick={() => modalData.navigateMonth(moment.utc(modalData.props.hotelAuthReducer.utcTime).tz(modalData.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'))}>
                            {t("Current Month")}
                            </Button>
                        </MuiThemeProvider>
                        <div style={{ marginLeft: "14px",color:'#707070',fontSize:'18px'}} className={classes.hideOnMobile}>
                          <span className={classes.boldFont} >
                            {sumOfClosedRoom}  
                          </span>
                          <span style={{fontWeight:'300',fontSize:'16px'}}>
                          {" "+t('Closed Rooms')}
                          </span>
                        </div>
                        <div style={{ marginLeft: "16px",color:'#707070',fontSize:'18px'}} className={classes.hideOnMobile}>
                          <span className={classes.boldFont} >
                            {modalData.getExpectedOccupancy().actual != null ? modalData.getExpectedOccupancy().actual : "n.a."}
                          </span>
                          <span style={{fontWeight:'300',fontSize:'16px'}}>
                          {" "+t(`${modalData.state.pms_channel_manager_text} Occupancy` )}
                          </span>
                        </div>
                        <div style={{ marginLeft: "16px",color:'#707070',fontSize:'18px'}} className={classes.hideOnMobile}>
                          <span className={classes.boldFont} >
                            {modalData.state.kpi_monthly_total['OCCUPANCY']?.[moment(modalData.state.monthChangeDate).format('YYYY-MM')] ? modalData.state.kpi_monthly_total['OCCUPANCY'][moment(modalData.state.monthChangeDate).format('YYYY-MM')]+'%':"n.a."}  
                          </span>
                          <span style={{fontWeight:'300',fontSize:'16px'}}>
                          {" "+t('Dashboard Occupancy')}
                          </span>
                        </div>
                    </div>
                    </Grid>
                </DialogTitle>                
                <DialogContent style={{margin:'0px 23px',padding:'0px'}}>
                    <DialogContentText>
                    <Grid item xs={12} >
                        <MuiThemeProvider theme={CalendargetMuiTheme2}>
                            <MUIDataTable
                                data={data}
                                columns={columns}
                                options={options}
                            />
                        </MuiThemeProvider>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => modalData.closeRevenueTable()}  className="cancelBtn" >
                    {t("Cancel")}
                </Button>
                </DialogActions>
            </Dialog>
        );
    },
    chargebeeNotification:function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        let message =""
        let success=true
        let veriant='warning'
        if(modalData.state.chargebeeSubscription && modalData.state.chargebeeSubscription.payment_info.invoice.status != 'paid'){
            if(modalData.state.chargebeeSubscription.payment_info.invoice.status == "not_paid"){
                message='Important subscription information: Your account has an overdue invoice and is at risk of being locked. Please check your open invoices (link to Billing History) or update your payment method (link to Payment Method) to setup an automated payment process. If you need help, please contact our support via the chat.'
                success=false
                veriant='error'
            } 
            if(modalData.state.chargebeeSubscription.payment_info.invoice.status == "payment_due"){
                if(modalData.state.chargebeeSubscription.payment_info.payment_method.type == "card"){
                    message ="Subscription information: We are sorry but we could not charge your credit card. Please update your Payment Method (link) and contact us via the chat in case you need support."
                }else if(modalData.state.chargebeeSubscription.payment_info.payment_method.type == "direct_debit"){
                    message="Subscription information: We are sorry but we could not process your payment with the direct debit payment method you set up. Please update your Payment Method (link) and contact us via the chat in case you need support."
                }else{
                    message="Subscription information: We are sorry but it looks like you have an overdue invoice. Please check your open invoices (link to Billing History) or update your payment method (link to Payment Method) to setup an automated payment process. If you need help, please contact our support."
                }
            }
        }
        return (
            <MuiThemeProvider theme={snackBarTheme}>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                // onClose={modalData.closeChargebeeModal}
                open={modalData.state.chargebeeModal}
                TransitionComponent={Fade}
                ContentProps={{
                  classes: {
                    root:success ? classes.sccessBar : classes.warningBar
                  }
                }}
                style={{width:'100%'}}
                message={<span style={{width:'90%' }}>
                    <Icon className={classes.warningIcon}>{(success) ? 'warning' : 'error'}</Icon> 
                    {t(message)}</span>}
                variant={veriant}
                action={[
                    <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      className={classes.close}
                      onClick={modalData.closeChargebeeModal}
                    >
                      <CloseIcon className={classes.icon} />
                    </IconButton>
                  ]}
              />
              </MuiThemeProvider>
            )
    },
     importPMSDataModal:function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
         return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                maxWidth='md'
                open={modalData.state.importPMSDataModalopen}
                onClose={() => modalData.closeImportPMSDataModal()}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{ style: { maxWidth: "500px", margin: "10px", minWidth: '25%',overflow:'unset',padding:'0px !important',margin:'0px 30px !important' } }}
            >
                <DialogTitle id="responsive-dialog-title">
                    {t('Import PMS Data')}
                </DialogTitle>
                <DialogContent style={{ paddingBottom: "0px" }}>
                    <div className={classes.diagnosticCheck}>
                        <FormControl>
                            <input type="file" name="" id="" accept=".json" onChange={modalData.handle_PMS_data_json_file} />
                            {modalData.state.importPMSDataFileRequire && 
                                <span style={{color:"red"}}>{modalData.state.importPMSDataFileValidationMessage}</span>
                            }    
                            
                        </FormControl>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={modalData.state.importPMSDataFileRequire} onClick={() => modalData.saveImportPMSDataJson()} className={[classes.saveUploadBtn, "orangButton"]} variant="contained" color="primary">
                            {t("Import")}{modalData.state.importPMSDataLoader && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                        </Button>   
                    <Button disabled={modalData.state.importPMSDataLoader} onClick={modalData.closeImportPMSDataModal} className={[classes.saveUploadBtn, "cancelBtn"]}    >
                    {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
         )
     },
    
     detectClosedRoomsPMSModal:function(modalData){
        
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
         return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                maxWidth='md'
                open={modalData.state.detectclosedroomspms}
                // onClose={modalData.closedetectClosedRoomsPMS}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{ style: { maxWidth: "750px", margin: "10px", minWidth: '25%',overflow:'unset',padding:'0px !important',margin:'0px 30px !important' } }}
            >
                <DialogTitle id="responsive-dialog-title">
                    {t("New Feature: Automatically detect closed rooms in the PMS")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("We have some great news 🎉 For")}
                        {` `+ t(`${modalData.state.pms_provider_obj.label}`)+ ` `}
                        {t("customers we are now able to automatically detect rooms that you have closed in the PMS. This means you will no longer need to close rooms yourself on RoomPriceGenie.")}
                    </DialogContentText>
                </DialogContent>

                {/* <DialogContent style={{ paddingBottom: "0px" }}>
                    <div className={classes.diagnosticCheck}>
                        
                    </div>
                </DialogContent> */}
                <DialogActions>
                    <Button onClick={() => modalData.EnableDetection()} className={[classes.saveUploadBtn, "orangButton"]} variant="contained" color="primary">
                            {t("Enable Detection")}{modalData.state.detectclosedroomspmsLoader && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                        </Button>   
                    <Button disabled={modalData.state.detectclosedroomspmsLoader} onClick={modalData.closedetectClosedRoomsPMS} className={[classes.saveUploadBtn, "cancelBtn"]}    >
                    {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
         )

     },
     
     minMaxOccupancyDetectModal: function (modalData) {
                
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.minmaxOccupancyDetected}
                onClose={() => modalData.minMaxOccupancyDetectModal()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t(`Change in min/max occupancy detected`)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t(`We noticed that the min/max occupancy of one or more of your room types changed and reset your daily occupancy pricing overrides (bulk edits). Please get in touch with our support if you need help. Thank you!`)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>                              
                    <Button onClick={() => modalData.minMaxOccupancyDetectModal()} className={[classes.saveUploadBtn, "orangButton"]} variant="contained" color="primary">
                        {t("Close")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },

    clientAccessModal :function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        let defaultDate = moment().add(14,'days').format('dd/MM/yyyy')
        return(
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.clientAccessModal}
                onClose={() => modalData.closeEnableClientAccessModal()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t(`Set End of Free Trial`)}</DialogTitle>
                <DialogContent>
                <MuiThemeProvider theme={modalData.dateTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            margin="normal"
                            label={t("End of Free Trial")}
                            defaultValue={defaultDate}
                            value={modalData.state.trialDate}
                            onChange={modalData.changeDate('trialDate')}
                            className={classes.textField}
                            format={"dd/MM/yyyy"}
                            autoOk={true}
                            InputLabelProps={{ shrink: true }}
                        />
                    </MuiPickersUtilsProvider>      
                </MuiThemeProvider>
                </DialogContent>
                <DialogActions>  
                    <Button onClick={() => modalData.enableClientAccess()} variant="contained" color="primary" >
                        {t("Save")}
                    </Button>
                    
                    <Button onClick={() => modalData.closeEnableClientAccessModal()}  className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

