var style = (theme) => ({
    root: {
        flexGrow: 1,
      },
  autoDiv: {
    float: "right",
    width: "100%",
  },
  subtitle:{
    fontSize: "22px !important",
    fontWeight: '',
    marginBottom:"15px",
  },
  addressDiv:{
    maxWidth: "250px", 
    color: "#A0A2A6", 
    marginLeft: '10px',
    fontSize:"12px"
  },
  tableCellRoot:{
    padding: '4px 10px 4px 10px',
    textAligh:'left'
  },
  pullLeft:{
    width: '20%',
    minWidth: '280px'
  },
  userTable: {
    padding: '20px',
    width:'30% !important'
  },
  userTableDiv: {
    overflow: 'auto',
    marginTop: '70px',
  },
  customCheckbox: {
    marginTop: "22px",
    marginLeft: "20px",
    ['@media (max-width: 414px) and (min-width: 360px)']: {
      width: "100%",
      marginLeft: '-15px',
      marginTop:0
    }
  },
  subtitle1:{
    fontSize:'18px',
    marginBottom:"15px",

  },
  CheckboxLabel:{
    padding:"7px 8px 4px 13px"
  },
  saveBtn: {
    // minWidth: '100px',
    float: 'left'
  },
  checkBoxContainers:{
    ['@media (max-width: 414px) and (min-width: 360px)']: {      
      flexDirection: 'column'
    }
  },btnDiv:{
    margin:"30px 0px 75px 0px",
    width:"100%"
  },
  pricePmsBtn: {
    float: 'left',
    marginLeft: '20px',
    '@media screen and (max-width: 800px)': {
      marginTop: '10px',
      marginLeft:"68px"
    },
  },
  diagnosticBtn: {
    ['@media (max-width: 540px)']: {
      width: '100%',
      marginTop: '10px',
      paddingTop: '0px'
    },
  },
  rpgIcon:{
    '@media screen and (max-width: 800px)': {
      display: 'none',
    },
  },
  helpText:{
    color:"#707070"
  },
  noteText: {
    paddingRight:'36px'
  },
  msgError: {
    textAlign: "left"
  },
  formControl2: {
    float: "left", 
    whiteSpace: "pre-line"
  },
  fromGroup: {
    marginTop:"7px"
  },
  divStyle: {
    float: "left", 
    marginTop: "2px"
  },
  fontStyle: {
    textAlign: "left", 
    fontWeight: "500"
  },
  gridStyle: {
    textAlign: "left", 
    margin: "-4px 0 20px 0"
  }
});
export default style;
