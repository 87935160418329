import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AdminMenuList from '../config/adminMenu';
import {Dialog,DialogActions,DialogContent,DialogTitle} from '@material-ui/core';
import {FormControl,TextField,InputAdornment,Button,} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AdminRoutes from './Admin/route';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import {adminSidebarlistTheme, adminSidebarTheme, adminSidebarThemeBtn } from  "./muiTheme"
import CardMedia from '@material-ui/core/CardMedia';
import sideBarCss from './css/sidebarCss';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Icon } from '@material-ui/core';
import { connect } from "react-redux";
import { compose } from 'redux'
import * as actions from '../store/actions/index';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import Divider from '@material-ui/core/Divider';

const styles = sideBarCss;

class AdminSidebar extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      mobileOpen: false,
      childIndex: 0,
      auth: true,
      anchorEl: null,
      anchorEh: null,
      isResetPassword: false,
      openAccount: false,
      snackbarMsg: "",
      snackbarSuccess: false,
      account: {
        first_name: '',
        last_name: '',
      },
      resetPassword: {
        old_password: '',
        new_password: '',
        email: '',
      },
      error: [],
    };
  }

  componentWillMount() {
    this.props.updateTempProp("")
  }

  componentWillReceiveProps(props) {
    if (props.commonReducer.notify) {
      if (props.commonReducer.notify.message) {
        this.setState({
          snackbarMsg: props.commonReducer.notify.message,
          snackbarSuccess: props.commonReducer.notify.is_success,
          warning: true,
        },
          () => {
            setTimeout(() => {
              this.setState({
                warning: false
              })
            }, 3000);
            this.props.cleanNotify()
          })

      }
    }
    if (props.userSession.user) {
      this.setState({
        account: {
          first_name: props.userSession.user.first_name,
          last_name: props.userSession.user.last_name,
          email: props.userSession.user.email
        },
      })
    }
  }
  togglePassword = () => {
    this.setState({
      isResetPassword: !this.state.isResetPassword,
      anchorEl: null,
      error: []
    })
  }
   handleUserProfile = () => {
    this.setState({
      openAccount: !this.state.openAccount,
      anchorEl: null,
    })
  }
  validateForm = (data) => {
    let error = [];
    if (data) {

      if (!this.state.resetPassword.old_password) {
        error.push('old_password')
      }
      if (!this.state.resetPassword.new_password) {
        error.push('new_password')
      }

      if (error) {
        this.setState({
          error: error
        })
        return error
      } else {
        return false
      }
    } else {
      return true;
    }
  }

  chnagePassword = () => {

    let validateError = this.validateForm(this.state.resetPassword);
    if (validateError.length > 0) {
      return;
    }

    let requestParams = {
      ...this.state.resetPassword,
      token: this.props.userSession.token,
    }
    this.props.updatePassword(requestParams)
    this.setState({
      isResetPassword: false,
      resetPassword: {
        old_password:'',
        new_password:''
      }
    })
  }

  handleLogout = () => {
    localStorage.removeItem("__UserProps__")
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    window.location = "/";
  }
  handlePassword = (name) => event => {
    this.setState({
      resetPassword: {
        ...this.state.resetPassword,
        [name]: event.target.value
      }
    })
  }

  handleAccount = (name) => event => {
    this.setState({
      account: {
        ...this.state.account,
        [name]: event.target.value
      }
    })
  }

  validateProfileForm = (data) => {
    let error = [];
    if (data) {

      if (!this.state.account.first_name) {
        error.push('first_name')
      }
      if (!this.state.account.last_name) {
        error.push('last_name')
      }
      if (!this.state.account.email) {
        error.push('email')
      }

      if (error) {
        this.setState({
          error: error
        })
        return error
      } else {
        return false
      }
    } else {
      return true;
    }
  }
  chnageAccount = () => {

    let validateError = this.validateProfileForm(this.state.account);
    if (validateError.length > 0) {
      return;
    }

    let requestParams = {
      data: this.state.account,
      storeData: this.props.userSession
    }
    this.props.updateAccount(requestParams)
    this.setState({
      openAccount: false,
    })
  }
  showPassword = (name) => {
    this.setState({
      [name]: !this.state[name]
    })
  }
  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };

  handleHotel = event => {
    this.setState({ anchorEh: event.currentTarget });
  };

  handleClosehotel = () => {
    this.setState({ anchorEh: null });
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };


  closeDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: false }));
  };

  toggleWarning = () => {
    this.setState({
      warning: !this.state.warning,
    })
  }

  handleParentMenu = () => {
    var parentMenu = [];
    const { classes } = this.props;
    let pathname = window.location.pathname;

    parentMenu.push(
      <div key={1}>
        <div className={classes.toolbar} style={{marginTop: 0, background:"#fff"}} >
          <CardMedia
            className={classes.siteLogo}
            title="Kanhasoft"
            image="/img/roompricegenie_logo_new.svg"
            style={{backgroundSize:"100%", margin:0, width:"90%"}}
          />
        </div>
        <MuiThemeProvider theme={adminSidebarlistTheme}>
          <List>
            {/* sub_account_type
          props.userSession.user.is_staff */}
            {AdminMenuList.filter(opt => opt.reseller == true || this.props.userSession.user.is_staff).map((val, index) => (
              <div key={index}>

                {val.to &&
                  <div
                    tabIndex={0}
                    role="button"
                    onClick={this.closeDrawerToggle}
                    onKeyDown={this.closeDrawerToggle}
                  >
                    <Link to={val.to} className={classes.pageUrl}>
                      <ListItem button className={pathname == (val.to) ? classes.active : ""} onClick={() => { this.setState({ childIndex: index }) }}>
                        <Icon>{val.icon}</Icon>
                        <ListItemText inset primary={val.label} />
                        {val.children && (this.state.childIndex === index ? <ExpandLess /> : <ExpandMore />)}
                      </ListItem>
                    </Link>
                  </div>
                }

                {!val.to &&
                  <ListItem button onClick={() => { this.state.childIndex === index ? this.setState({ childIndex: 0 }) : this.setState({ childIndex: index }) }}>
                    <Icon>{val.icon}</Icon>
                    <ListItemText inset primary={val.label} />
                    {val.children && (this.state.childIndex === index ? <ExpandLess /> : <ExpandMore />)}
                  </ListItem>}

                {this.handleChildMenu(val.children, index)}
              </div>
            ))}
          </List>
        </MuiThemeProvider>
      </div>
    );
    return parentMenu;
  }

  handleChildMenu = (children, parent_index) => {
    var jsx = [];
    let pathname = window.location.pathname;
    const { classes } = this.props;
    if (children) {
      children.map((child, index) => (
        jsx.push(
          <Collapse in={this.state.childIndex === parent_index} timeout="auto" unmountOnExit key={index}>
            <List component="div" disablePadding>
              <div
                tabIndex={0}
                role="button"
                onClick={this.closeDrawerToggle}
                onKeyDown={this.closeDrawerToggle}
              >
                <Link to={child.to} className={classes.pageUrl}>
                  <ListItem button className={pathname == (child.to) ? classes.active : ""} style={{ marginLeft: '20px' }}>
                    <Icon >{child.icon}</Icon>
                    <ListItemText inset primary={child.label} />
                  </ListItem>
                </Link>
              </div>
            </List>
          </Collapse>
        )
      ))
    }
    return jsx;
  };

  render() {

    const { classes } = this.props;
    const { fullScreen } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const theme = adminSidebarTheme;
    const MUIIconBtn = adminSidebarThemeBtn;
    return (
      <MuiThemeProvider theme={adminSidebarTheme}>
        <Router>
          <div className="mainConentBody">
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.handleDrawerToggle}
                  className={classes.menuButton}
                  >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" noWrap>
                </Typography>


                {/* <Grid container className={classes.gridContainer}>
                  <Grid item lg={9} className={classes.gridHotelSelect}>                    
                  </Grid>

                  <Grid item lg={3} className={classes.gridLng}>
                    <Typography
                      className={classes.emailText}
                      variant="subtitle2"
                      color="inherit"
                      noWrap
                    >
                      {this.props.userSession.user.email}
                    </Typography>
                  </Grid>
                </Grid> */}

                <div className={classes.profileBtn} style={{ display: "flex" }}>
                  <Typography
                    className={classes.emailText}
                    variant="subtitle2"
                    color="inherit"
                    noWrap
                  >
                    {this.props.userSession.user.email}
                  </Typography>
                  <MuiThemeProvider theme={adminSidebarThemeBtn}>
                  <IconButton
                    aria-owns={open ? 'menu-appbar' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                    classes={classes.userIconColor}
                  >
                    <AccountCircle />
                  </IconButton>
                  </MuiThemeProvider>
                  <Menu
                    style={{ float: 'right' }}
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={open}
                    onClose={this.handleCloseMenu}
                  >
                    {/* {this.props.userSession.user.is_staff &&  */}
                      <>
                        <MenuItem onClick={this.handleUserProfile}>My Account</MenuItem>
                        <MenuItem onClick={this.togglePassword}>Change Password</MenuItem>
                      </>
                    {/* } */}
                    <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                  </Menu>
                </div>
              </Toolbar>
            </AppBar>
            <nav className={classes.drawer}>
              <Hidden smUp implementation="css">
                <SwipeableDrawer
                  container={this.props.container}
                  variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                  open={this.state.mobileOpen}
                  onClose={this.handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                >
                  {this.handleParentMenu()}
                </SwipeableDrawer>
              </Hidden>
              <Hidden xsDown implementation="css">
                <SwipeableDrawer
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  variant="permanent"
                  open
                >
                  <div style={{ paddingBottom: "92px" }}>
                    {this.handleParentMenu()}
                  </div>
                  <div className={classes.referBtndiv} style={{ height: "50px" }}>
                    <Divider />
                    <div style={{ display: "flex" }}>
                      <IconButton
                        aria-owns={open ? 'menu-appbar' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleMenu}
                        className={[classes.userIcon,classes.userIconColor]}
                        disableRipple
                        >
                        <AccountCircle />
                      </IconButton>
                      <Typography
                        className={classes.emailText}
                        variant="body1"
                        onClick={this.handleMenu}
                        style={{ cursor: "pointer" }}
                        noWrap
                      >
                        {this.state.account.email}
                      </Typography>
                    </div>
                    {/* <Button color="primary" onClick={() => this.referFriend()} className={classes.referBtn}>
                      {("Refer a Friend")}
                    </Button> */}
                  </div>
                </SwipeableDrawer>
              </Hidden>
            </nav>
            <main className={classes.content}>
              <AdminRoutes></AdminRoutes>
            </main>
            <Dialog
              fullScreen={fullScreen}
              maxWidth='md'
              open={this.state.openAccount}
              onClose={this.handleUserProfile}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">My Account</DialogTitle>
              <IconButton aria-label="Close" onClick={this.handleUserProfile} style={{ position: "absolute", right: "0" }}>
                <CloseIcon />
              </IconButton>
              <DialogContent>
                <div>
                  <FormControl margin="normal" required className={classes.TextField}>
                    <TextField
                      id="standard-name"
                      label="First Name"
                      onChange={this.handleAccount('first_name')}
                      value={this.state.account.first_name}
                      error={this.state.error.includes("first_name")}
                    />
                    {this.state.error.includes("first_name") ? <span class='error-message'>{'* Please enter first name'}</span> : ''}
                  </FormControl>
                  <FormControl margin="normal" required className={classes.TextField}>
                    <TextField
                      id="standard-name"
                      label="Last Name"
                      onChange={this.handleAccount('last_name')}
                      value={this.state.account.last_name}
                      error={this.state.error.includes("last_name")}
                    />
                    {this.state.error.includes("last_name") ? <span class='error-message'>{'* Please enter last name'}</span> : ''}
                  </FormControl>
                  <FormControl margin="normal" required className={classes.TextField}>
                    <TextField
                      id="standard-name"
                      label="Email"
                      onChange={this.handleAccount('email')}
                      value={this.state.account.email}
                      error={this.state.error.includes("email")}
                    />
                    {this.state.error.includes("email") ? <span class='error-message'>{'* Please enter email address'}</span> : ''}
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>               
                <Button onClick={this.chnageAccount} variant="contained" color="primary" >
                  Save
                </Button>
                <Button onClick={this.handleUserProfile} className="cancelBtn">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              fullScreen={fullScreen}
              maxWidth='sm'
              open={this.state.isResetPassword}
              onClose={this.togglePassword}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">Change Password</DialogTitle>
              <IconButton aria-label="Close" onClick={this.togglePassword} style={{ position: "absolute", right: "0" }}>
                <CloseIcon />
              </IconButton>
              <DialogContent>
                <FormControl margin="normal" required className={classes.TextField}>
                  <TextField
                    type={this.state.showOld ? "text" : "password"}
                    id="standard-name"
                    label="Old Password"                    
                    onChange={this.handlePassword('old_password')}
                    value={this.state.resetPassword.old_password}
                    error={this.state.error.includes("old_password")}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end" style={{ color: "#A0A2A6" }} onClick={() => this.showPassword("showOld")}><Icon>{this.state.showOld ? "visibility" : "visibility_off"}</Icon></InputAdornment>,
                    }}

                  />
                  {this.state.error.includes("old_password") ? <span class='error-message'>{'* Please enter old password'}</span> : ''}
                </FormControl>
                <FormControl margin="normal" required className={classes.TextField}>
                  <TextField
                    type={this.state.showNew ? "text" : "password"}
                    id="standard-name"
                    label="New Password"
                    onChange={this.handlePassword('new_password')}
                    value={this.state.resetPassword.new_password}
                    className={classes.TextField}
                    error={this.state.error.includes("new_password")}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end" style={{ color: "#A0A2A6" }} onClick={() => this.showPassword("showNew")}><Icon>{this.state.showNew ? "visibility" : "visibility_off"}</Icon></InputAdornment>,
                    }}
                  />
                  {this.state.error.includes("new_password") ? <span class='error-message'>{'* Please enter new password'}</span> : ''}
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.chnagePassword()} variant="contained" color="primary" autoFocus>
                  Reset Password
                </Button>
                <Button onClick={this.togglePassword} className="cancelBtn" autoFocus>
                  Cancel
                </Button>                
              </DialogActions>
            </Dialog>
            {this.state.warning &&
              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={this.toggleWarning}
                open={this.state.warning}
                TransitionComponent={Fade}
                ContentProps={{
                  classes: {
                    root: this.state.snackbarSuccess ? classes.sccessBar : classes.warningBar
                  }
                }}
                message={<span><Icon className={classes.warningIcon}>{(this.state.snackbarSuccess) ? 'check_circle' : 'error'}</Icon> <span className={"alert-snackbar"}>{this.state.snackbarMsg}</span></span>}
                variant="success"
              />
            }
          </div>
        </Router>
      </MuiThemeProvider>
    );
  }
}

AdminSidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    userSession: state.sessionReducer,
    hotelAuthReducer: state.hotelAuthReducer,
    commonReducer: state.commonReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hotelAuth: (request) => { dispatch(actions.hotelAuth(request)) },
    cleanNotify: () => { dispatch(actions.cleanNotify()) },
    updateTempProp: (request) => { dispatch(actions.updateTempProp(request)) },
    updatePassword: (request) => { dispatch(actions.updatePassword(request)) },
    updateAccount: (request) => { dispatch(actions.updateAccount(request)) },
  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(AdminSidebar);