import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = [];

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ALGO_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.ALGO_SUCCEED:
            return updateObject(state, { loading: false, error: null, runAlgo: action.state.results })
        case actionTypes.ALGO_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.ALGO_LOG_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.ALGO_LOG_FAILED:
            return updateObject(state, { loading: false, error: null })
        case actionTypes.ALGO_LOG_SUCCEED:
            return updateObject(state, { loading: false, error: action.error, runAlgoLog: action.state })
        default:
            return updateObject(state, { loading: false, error: null });
    }
}

export default reducer