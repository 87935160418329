import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import Notify from '../Notify';
import * as actions from '../index';


// get

const onClientAdminGetStart = () => {
    return {
        type: actionTypes.CLINET_ADMIN_GET_START
    }
}

const onClientAdminGetFailed = (error) => {
    return {
        type: actionTypes.CLINET_ADMIN_GET_FAILED,
        error: error
    }
}

const onClientAdminGetSucceed = (data) => {
    return {
        type: actionTypes.CLINET_ADMIN_GET_SUCCEED,
        state: data
    }
}

export const getClientAdminList = (request) => {
    return dispatch => {
        dispatch(onClientAdminGetStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'account/client/admin/assignment/')
            .then(function (response) {
                dispatch(onClientAdminGetSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onClientAdminGetFailed(error))
            });
    }
}


//create 
const assignMultipleHotel = (resp, req) => {
    let selectedHotelList = req.selectedHotelList;
    selectedHotelList.map((opt) => {
        axios.defaults.headers.common['Authorization'] = "Token " + req.LoginUserToken;
        axios.post(Server.API + 'account/auth/hotel/' + opt + '/')
            .then(function (response) {
                assignHotel(response.data, resp.id);
            })
        return null;
    })
}

const assignHotel = (resp, user_id) => {
    let requestParam = { user: user_id }
    axios.defaults.headers.common['Authorization'] = "Token " + resp.token;
    axios.post(Server.API + 'account/client/admin/assignment/', requestParam)
        .then(function (response) {
        })
}

export const clientAdminCreate = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'account/client/admin/register/', request)
            .then(function (response) {
                let selectedHotelsList = [];
                for (var i = 0; i < request.hotelList.length; i++) {
                    for (var j = 0; j < request.selectedHotelList.length; j++) {
                        if (request.hotelList[i].id === request.selectedHotelList[j]) {
                            selectedHotelsList.push(request.hotelList[i]);
                        }
                    }
                }
                response.assigned_hotels = selectedHotelsList;
                // assign user to other hotels
                assignMultipleHotel(response.data, request)
                dispatch(Notify.newUserModal("Admin created successfully", true))
                dispatch(actions.getClientAdminList(request))
            })
            .catch(function (error) {
                if (error.response.data) {
                    Object.keys(error.response.data).map(function (key) {
                        if (error.response.data[key].length > 0) {
                            dispatch(Notify.newUserModal(error.response.data[key][0], false))
                        } else {
                            dispatch(Notify.newUserModal("An error occured while creating  Admin", false))
                        }
                    })
                } else {
                    dispatch(Notify.newUserModal("An error occured while creating  Admin", false))
                }
            });
    }
}

// update

const deleteUpdateMultiHotel = (assignId, token, hotelId, dispatch) => {
    axios.defaults.headers.common['Authorization'] = "Token " + token;
    axios.post(Server.API + 'account/auth/hotel/' + hotelId + '/')
        .then(function (response) {
            deleteAssignmentHotel(response.data, assignId, token, dispatch);
        })
}

const deleteAssignmentHotel = (resp, assignId, oldToken, dispatch) => {
    axios.defaults.headers.common['Authorization'] = "Token " + resp.token;
    axios.delete(Server.API + 'account/client/admin/assignment/' + assignId + '/')
        .then(function (response) {
            dispatch(actions.getClientAdminList({ token: oldToken }))
            dispatch(actions.getManagerList({ token: oldToken }))
            dispatch(actions.getStaffList({ token: oldToken }))
        })
        .catch(function (error) {
        });
}

const assignUpdateMultiHotel = (user_id, hotelId, token, dispatch) => {
    axios.defaults.headers.common['Authorization'] = "Token " + token;
    axios.post(Server.API + 'account/auth/hotel/' + hotelId + '/')
        .then(function (response) {
            assignUpdateHotel(response.data, user_id, token, dispatch);
        })
}

const assignUpdateHotel = (resp, user_id, oldToken, dispatch) => {
    let requestParam = { user: user_id }
    axios.defaults.headers.common['Authorization'] = "Token " + resp.token;
    axios.post(Server.API + 'account/client/admin/assignment/', requestParam)
        .then(function (response) {
            dispatch(actions.getClientAdminList({ token: oldToken }))
            dispatch(actions.getManagerList({ token: oldToken }))
            dispatch(actions.getStaffList({ token: oldToken }))
        })
}

export const updateClientAdmin = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        let updateData = { first_name: request.first_name, last_name: request.last_name, email: request.username, language: request.language };
        axios.patch(Server.API + 'account/client/admin/update/' + request.userId + '/', updateData)
            .then(function (response) {
                request.selectedHotelList.map((row) => {
                    if (!request.oldHotelList.includes(row)) {
                        assignUpdateMultiHotel(request.userId, row, request.token, dispatch)
                    }
                    return null;
                })
                request.deleteArray.map((row) => {
                    deleteUpdateMultiHotel(row.id, request.token, row.hotel.id, dispatch)
                    return null;
                })
                dispatch(Notify.newUserModal("Admin updated successfully", true))
                dispatch(actions.getClientAdminList(request))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while updating Admin", false))
            });
    }
}

export const updateClientAllAdmin = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        let updateData = { first_name: request.first_name, last_name: request.last_name, email: request.username };
       
        axios.patch(Server.API + 'account/owner/update/', updateData)
            .then(function (response) {
                // request.selectedHotelList.map((row) => {
                //     if (!request.oldHotelList.includes(row)) {
                //         assignUpdateMultiHotel(request.userId, row, request.token, dispatch)
                //     }
                //     return null;
                // })
                // request.deleteArray.map((row) => {
                //     deleteUpdateMultiHotel(row.id, request.token, row.hotel.id, dispatch)
                //     return null;
                // })
                if(response.data.is_updated_in_hs){
                    dispatch(Notify.newUserModal("Admin updated successfully", true))
                } else {
                    dispatch(Notify.newUserModal("Details updated Successfully. Warning: CRM could not be updated.", true))
                }

                dispatch(actions.getOwner(request))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while updating Admin", false))
            });

    }
}

export const deleteClientAdminUser = (request) => {
    return dispatch => {
        if (request.isAssigned == 0) {
            axios.defaults.headers.common['Authorization'] = "Token " + request.LoginUserToken;
            axios.post(Server.API + 'account/auth/hotel/' + request.hotelId + '/')
                .then(function (response) {
                    axios.defaults.headers.common['Authorization'] = "Token " + response.data.token;
                    axios.delete(Server.API + 'account/client/admin/delete/' + request.id + '/')
                        .then(function (response) {
                            dispatch(actions.getClientAdminList(request))
                            dispatch(Notify.newUserModal("Admin deleted successfully", true))
                        })
                        .catch(function (error) {
                            dispatch(Notify.newUserModal("An error occured while deleting Admin", false))
                        });
                })
        } else {
            axios.defaults.headers.common['Authorization'] = "Token " + request.token;
            axios.delete(Server.API + 'account/client/admin/delete/' + request.id + '/')
                .then(function (response) {
                    dispatch(actions.getClientAdminList(request))
                    dispatch(Notify.newUserModal("Admin deleted successfully", true))
                })
                .catch(function (error) {
                    dispatch(Notify.newUserModal("An error occured while deleting Admin", false))
                });
        }

    }
}

export const changeRole = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.patch(Server.API + 'account/client/manager/assignment/' + request.id + '/')
            .then(function (response) {
                alert();
                // dispatch(actions.getClientAdminList(request))
                // dispatch(Notify.newUserModal("Admin deleted successfully", true))
            })
            .catch(function (error) {
                // dispatch(Notify.newUserModal("An error occured while deleting Admin", false))
            });
    }
}

const onClientOwner = (data) => {
    return {
        type: actionTypes.CLINET_ADMIN_OWNER,
        state: data
    }
}

export const getOwner = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'account/owner/detail/')
            .then(function (response) {
                dispatch(onClientOwner(response.data))
            })
            .catch(function (error) {
            });
    }
}



// const inviteHotelUSerSuccess = (data) => {
//     return {
//         type: actionTypes.CLINET_ADMIN_OWNER,
//         state: data
//     }
// }
///https://devreapp.roompricegenie.com/account/verify-invited-user/?email_token=0698fa21-3b49-4d72-8614-9d2c22079c37

export const inviteHotelUSer = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        let requestData = {
            email: request.email,
            user_type: request.user_type,
            property_list: request.property_list,
            language:request.language,
        };
        axios.post(Server.API + 'account/invited-user-detail/',requestData)
            .then(function (response) {
                // dispatch(inviteHotelUSerSuccess(response.data))
                if(response.data && response.data.success){
                    dispatch(Notify.newUserModal("User Invited Successfully.", true))
                    dispatch(pandingUsers(request))
                }else{
                    dispatch(Notify.newUserModal("User with that email already exists.", false))
                }
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Something went wrong while inviting user.", false))
            });
    }
}
const pandingUsersSuccess = (data) => {
    return {
        type: actionTypes.PANDING_USERS_SUCCESS,
        state: data
    }
}
export const pandingUsers=(request)=>{
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'account/pending-invited-users-lists/')
            .then(function (response) {
                dispatch(pandingUsersSuccess(response.data))
            })
            .catch(function (error) {
            });
    }
}

