import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = [];

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AGGRESIVE_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.AGGRESIVE_SUCCEED:
            return updateObject(state, { loading: false, error: null, aggresiveList: action.state.results })
        case actionTypes.AGGRESIVE_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        default:
            return updateObject(state, { loading: false, error: null });
    }
}

export default reducer