import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import internalPrameterCss from './css/internalPrameterCss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../../store/actions/index';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import { withNamespaces, Trans } from 'react-i18next';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { meaTimeTheme } from '../../muiTheme';
import purple from '@material-ui/core/colors/purple';
import moment from 'moment';
import InputLabel from '@material-ui/core/InputLabel';
import { Typography } from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import Server from '../../../config/server';
import { ValidateBaseSettings } from '../../Utils/SettingValidation/Validation';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CustomTooltip from '../../Utils/toolTip';
import Warning from '../warning';
import HeaderCard from "../HeaderCard";
import Grid from "@material-ui/core/Grid";

const styles = internalPrameterCss;

class InternalParamerter extends React.Component {

  state = {
    bookingProp: null,    
    leadTime: {
      six_months_plus:0,
      three_months_to_six_months: 0,
      three_to_six_months: 0,
      one_half_months_to_three_months: 0,
      four_weeks_to_six_weeks: 0,
      one_week_to_two_weeks: 0,
      four_days_to_seven_days: 0,
      two_days_to_three_days: 0,
      last_day: 0
    },
    dataModal: false,
    error: [],
    customWeekday: false,
    customweekend: false,
    dateSetting: null,
    median_lead_time: 8,
    featuresEnabled: [],
    isUpdate: true,
    warning: false,
    warningMsg: [],
    warningMethod: null,
    errorMsg: "",
    customLeadTime: false
  }


  componentWillMount() {
    if (this.props.priceSettingReducer.priceSetting && this.state.isUpdate) {
      this.setState({
        leadTime: this.props.priceSettingReducer.priceSetting.adjustment.lead_time,
        median_lead_time: this.props.priceSettingReducer.priceSetting.rpg_arguments.median_lead_time,
        isUpdate: false
      })
      if (![14, 21, 30].includes(this.props.priceSettingReducer.priceSetting.rpg_arguments.median_lead_time)) {
        this.setState({
          customLeadTime: true
        })
      }
    }
    if (this.props.priceSettingReducer && this.props.priceSettingReducer.priceSetting) {
      this.setState({
        featuresEnabled: this.props.priceSettingReducer.priceSetting.features
      })
    }
  }

  componentWillReceiveProps(props) {

    if (props.priceSettingReducer && props.priceSettingReducer.priceSetting) {
      this.setState({
        featuresEnabled: props.priceSettingReducer.priceSetting.features
      })
    }

    if (props.priceSettingReducer.priceSetting && this.state.isUpdate) {
      this.setState({
        leadTime: props.priceSettingReducer.priceSetting.adjustment.lead_time,
        median_lead_time: props.priceSettingReducer.priceSetting.rpg_arguments.median_lead_time,
        isUpdate: false
      })
      if (![14, 21, 30].includes(props.priceSettingReducer.priceSetting.rpg_arguments.median_lead_time)) {
        this.setState({
          customLeadTime: true
        })
      }
    }



    if (props.dataModalReducer.dataModalList && props.priceSettingReducer.priceSetting) {
      let adjustJson = props.priceSettingReducer.priceSetting.rpg_arguments;
      let dataModalList = props.dataModalReducer.dataModalList;
      dataModalList.elasticity_weekdays = adjustJson.PELL_weekday;
      dataModalList.elasticity_weekend = adjustJson.PELL_weekend;
      if (this.props.timeBookingReducer.elasticityList) {
        let weekday = this.props.timeBookingReducer.elasticityList.filter(val => val.value == adjustJson.PELL_weekday);
        let weekend = this.props.timeBookingReducer.elasticityList.filter(val => val.value == adjustJson.PELL_weekend);
        if (weekday.length == 0) {
          this.setState({
            customWeekday: true,
          })
        }
        if (weekend.length == 0) {
          this.setState({
            customweekend: true,
          })
        }
      }
      this.setState({
        dataModal: props.dataModalReducer.dataModalList
      })
    }
  }



  toggleWarningModal = (method) => {
    let warningMsg = [];
    let warning = false;
    let errorMsg = "";
    const { t } = this.props;

    if (method == "updateTimeToBooking") {
      let error = [];
      let rows = this.state.leadTime;

      let keys = [
        "six_months_plus",
        "three_months_to_six_months",
        "one_half_months_to_three_months",
        "four_weeks_to_six_weeks",
        "one_week_to_two_weeks",
        "four_days_to_seven_days",
        "two_days_to_three_days",
        "last_day",
        "two_weeks_to_four_weeks"
      ];

      keys.map(key => {
        if (rows[key] === "" || rows[key] > 25) {
          error.push(key)
        }
        if (rows[key] > 25) {
          errorMsg = 'Please enter a value between -25% and 25%';
        }
      })

      this.setState({
        error
      })

      if (error.length == 0) {
        keys.map(key => {
          if (rows[key] > 15) {
            warning = true;
            warningMsg.push(t("This lead time is unusually high. Are you sure?"))
          }
          if (rows[key] < -15) {
            warning = true;
            warningMsg.push(t("This lead time is unusually low. Are you sure?"))
          }
        })

        if (!warning) {
          this.updateTimeToBooking()
        }
      }

    }

    this.setState({
      warning: warning,
      warningMethod: method,
      warningMsg: warningMsg,
      errorMsg: errorMsg
    })
  }

  closeWarningModal = (method) => {
    this.setState({
      warning: false,
    })
  }

  warningConfirm = () => {
    if (this.state.warningMethod == "updateTimeToBooking") {
      this.updateTimeToBooking()
    }
    this.setState({
      warning: false,
    })
  }

  toggleCustomWeekday = () => {
    this.setState({
      customWeekday: !this.state.customWeekday
    })
  }

  toggleCustomWeekends = () => {
    this.setState({
      customweekend: !this.state.customweekend
    })
  }

  updateTimeToBooking = () => {
    let rows = this.state.leadTime;

    let setting = this.props.priceSettingReducer.priceSetting;

    setting.adjustment.lead_time = rows;

    setting.rpg_arguments.median_lead_time = this.state.median_lead_time;


  //   let flagReq = {
  //     run_pricing: true,
  //     token: this.props.hotelAuthReducer.hotelDetails.token,
  // }
  // this.props.setRunPriceFlags(flagReq);

    let latestJson = ValidateBaseSettings({ dateSetting: setting });

    let reqParams = {
      settings: JSON.stringify(latestJson.dateSetting),
    };

    this.setState({
      loadData: true
    })

    axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
    axios.post(Server.API + 'hotel/pricing-settings/', reqParams)
      .then((response) => {
        this.props.getPricingSettings({ token: this.props.hotelAuthReducer.hotelDetails.token })

       

        let requestData = {
          msg: 'Settings Updated Successfully',
          is_success: true,
        }

        this.props.putNotify(requestData)

        this.setState({
          loadData: false
        })

       
      }).catch(() => {
        let requestData = {
          msg: 'Error while Applying the Changes',
          is_success: false,
        }
        this.props.putNotify(requestData)
        this.setState({
          loadData: false
        })
      })
   
  }


  handledataModal = (name) => event => {
    this.setState({
      dataModal: {
        ...this.state.dataModal,
        [name]: event.target.value,
      }
    });
  }

  handledataModalunit = (name) => event => {
    this.setState({
      dataModal: {
        ...this.state.dataModal,
        round_price_to_nearest_unit: !this.state.dataModal.round_price_to_nearest_unit,
      }
    });
  }

  handleLeadTimeProps = (name) => event => {
    let value = event.target.value;

    this.setState({
      leadTime: {
        ...this.state.leadTime,
        [name]: value ? parseFloat(value) : value,
      }
    });
  }




  toggleCustomLeadTime = () => {
    this.setState({
      customLeadTime: !this.state.customLeadTime
    })
  }

  handleState = (name) => event => {
    this.setState({
      [name]: event.target.value,
    });
  }


  render() {
    const { classes } = this.props;
    const { t } = this.props;
    return (
    <>
            <HeaderCard>
                <Grid container>
                    <Grid item >
                        <div className={classes.divStyle}>
                        <Typography variant="h5" className={classes.fontStyle} >
                          {t("Lead Time Adjustments")}
                          <CustomTooltip title="" description={t("The Lead Time Adjustment adjusts your prices with how long there is until check-in. For example, if you set 2-4 weeks to -5% then we will adjust our recommended price 5% lower when check in is between 14 and 28 days away. Note that these adjustments do not override pre-set maximums or minimums.")} />
                        </Typography>
                        <DialogContentText align="left" className={classes.helpText}>
                          {t("These are rolling adjustments that are determined by lead time to check-in. For example, you may want to reduce prices additionally 2-4 weeks before guest arrival to get more bookings in that window. These settings directly influence the RoomPriceGenie price suggestions.")}
                        </DialogContentText>
                        </div>
                    </Grid>
                </Grid>
            </HeaderCard>
      <Card>
        <CardContent>
          <MuiThemeProvider theme={meaTimeTheme}>
              {this.state.leadTime && (this.state.featuresEnabled.includes(7) || this.props.userSession.user.is_staff) &&
                <div className={classes.dateTableDiv}>
                  <Table className={classes.userTable}>
                    <TableHead>
                      <TableRow > 
                        <TableCell className={classes.tableField}></TableCell>
                        
                        
                        <TableCell className={classes.tableField} align="right">{`6 ${t('months')} +`}</TableCell>
                        <TableCell className={classes.tableField} align="right">{`3 ${t('months')} +`}</TableCell>
                        <TableCell className={classes.tableField} align="right">{`1.5-3 ${t('months')}`}</TableCell>
                        <TableCell className={classes.tableField} align="right">{`4-6 ${t('weeks')}`}</TableCell>
                        <TableCell className={classes.tableField} align="right">{`2-4 ${t('weeks')}`}</TableCell   >
                        <TableCell className={classes.tableField} align="right">{`1-2 ${t('weeks')}`}</TableCell>
                        <TableCell className={classes.tableField} align="right">{`4-7 ${t('days')}`}</TableCell>
                        <TableCell className={classes.tableField} align="right">{`2-3 ${t('days')}`}</TableCell>
                        <TableCell className={classes.tableField} align="right">{t(`Last day`)}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow >
                        <TableCell className={[classes.tableField,classes.tableField2]} >{t("Adjust")}<br />{t("Price by")}</TableCell>
                        <TableCell className={classes.tableField} align="center" >
                          <FormControl  required>
                            <Input
                              onChange={this.handleLeadTimeProps('six_months_plus')}
                              error={this.state.error.includes("six_months_plus")}
                              inputProps={{ type: 'number' }}
                              
                              className={classes.tableTextFields}
                              value={this.state.leadTime.six_months_plus}
                              endAdornment={'%'}
                            />
                          </FormControl>
                        </TableCell>
                        <TableCell className={classes.tableField} align="center" >
                          <FormControl  required>
                            <Input
                              onChange={this.handleLeadTimeProps('three_months_to_six_months')}
                              error={this.state.error.includes("three_months_to_six_months")}
                              inputProps={{ type: 'number' }}
                              className={classes.tableTextFields}
                              value={this.state.leadTime.three_months_to_six_months}
                              endAdornment={'%'}
                            />
                          </FormControl>
                        </TableCell>
                        <TableCell className={classes.tableField} align="center">
                          <FormControl required>
                            <Input
                              onChange={this.handleLeadTimeProps('one_half_months_to_three_months')}
                              error={this.state.error.includes("one_half_months_to_three_months")}
                              inputProps={{ type: 'number' }}
                              className={classes.tableTextFields}
                              value={this.state.leadTime.one_half_months_to_three_months}
                              
                              endAdornment={'%'}
                            />
                          </FormControl>
                        </TableCell>
                        <TableCell className={classes.tableField} align="center">
                          <FormControl required>
                            <Input
                              onChange={this.handleLeadTimeProps('four_weeks_to_six_weeks')}
                              error={this.state.error.includes("four_weeks_to_six_weeks")}
                              inputProps={{ type: 'number' }}
                              className={classes.tableTextFields}
                              value={this.state.leadTime.four_weeks_to_six_weeks}
                              
                              endAdornment={'%'}
                            />
                          </FormControl>
                        </TableCell>
                        <TableCell className={classes.tableField} align="center">
                          <FormControl  required>
                            <Input onChange={this.handleLeadTimeProps('two_weeks_to_four_weeks')}
                              error={this.state.error.includes("two_weeks_to_four_weeks")}
                              inputProps={{ type: 'number' }}
                              className={classes.tableTextFields}
                              value={this.state.leadTime.two_weeks_to_four_weeks}
                              
                              endAdornment={'%'}
                            />
                          </FormControl>
                        </TableCell>
                        <TableCell className={classes.tableField} align="center">
                          <FormControl  required>
                            <Input
                              onChange={this.handleLeadTimeProps('one_week_to_two_weeks')}
                              error={this.state.error.includes("one_week_to_two_weeks")}
                              inputProps={{ type: 'number' }}
                              className={classes.tableTextFields}
                              value={this.state.leadTime.one_week_to_two_weeks}
                              
                              endAdornment={'%'}
                            />
                          </FormControl>
                        </TableCell>
                        <TableCell className={classes.tableField} align="center">
                          <FormControl  required>
                            <Input
                              onChange={this.handleLeadTimeProps('four_days_to_seven_days')}
                              error={this.state.error.includes("four_days_to_seven_days")}
                              inputProps={{ type: 'number' }}
                              className={classes.tableTextFields}
                              value={this.state.leadTime.four_days_to_seven_days}
                              
                              endAdornment={'%'}
                            />
                          </FormControl>
                        </TableCell>
                        <TableCell className={classes.tableField} align="center">
                          <FormControl  required>
                            <Input
                              onChange={this.handleLeadTimeProps('two_days_to_three_days')}
                              error={this.state.error.includes("two_days_to_three_days")}
                              inputProps={{ type: 'number' }}
                              className={classes.tableTextFields}
                              value={this.state.leadTime.two_days_to_three_days}
                              
                              endAdornment={'%'}
                            />
                          </FormControl>
                        </TableCell>
                        <TableCell className={classes.tableField} align="center">
                          <FormControl  required>
                            <Input
                              onChange={this.handleLeadTimeProps('last_day')}
                              error={this.state.error.includes("last_day")}
                              inputProps={{ type: 'number' }}
                              className={classes.tableTextFields}
                              value={this.state.leadTime.last_day}
                              
                              endAdornment={'%'}
                            />
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <span class="error-message">{this.state.errorMsg}</span>
                </div>
              } 
              <div>
                 <Button disabled={this.state.loadData} onClick={() => this.toggleWarningModal("updateTimeToBooking")} className={["orangButton", classes.saveBtn]} variant="contained" color="primary" >
                {t("Save")}{this.state.loadData && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
              </Button>
            </div>
          </MuiThemeProvider>
        </CardContent>
        {Warning.warningModal(this)}
      </Card >
      </>
    );
  }
}

InternalParamerter.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
  return {
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    userSession: state.sessionReducer,
    hotelAuthReducer: state.hotelAuthReducer,
    aggresiveList: state.aggresiveReducer,
    timeBookingReducer: state.timeBookingReducer,
    adjustmentReducer: state.adjustmentReducer,
    dataModalReducer: state.dataModalReducer,
    priceSettingReducer: state.priceSettingReducer,
    featuresReducer: state.featuresReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    adjustmentUpdate: (request) => { dispatch(actions.adjustmentUpdate(request)) },
    getPricingSettings: (request) => { dispatch(actions.getPricingSettings(request)) },
    putNotify: (request) => { dispatch(actions.putNotify(request)) },
    // setRunPriceFlags: (request) => { dispatch(actions.setRunPriceFlags(request)) },
  };
};


export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(InternalParamerter));