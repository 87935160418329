import * as actionTypes from "../actionTypes";
import Server from "../../../config/server";
import axios from "axios";
import Notify from "../Notify";
import * as actions from "../../../store/actions/index";

const onOTAInsightsCompetitorCsvStart = () => {
  return {
    type: actionTypes.OTAINSIGHTS_COMPETITOR_CSV_START,
  };
};

const onOTAInsightsCompetitorCsvFailed = (error) => {
  return {
    type: actionTypes.OTAINSIGHTS_COMPETITOR_CSV_FAILED,
    error: error,
  };
};

const onOTAInsightsCompetitorCsvSucceed = (data) => {
  return {
    type: actionTypes.OTAINSIGHTS_COMPETITOR_CSV_SUCCEED,
    state: data,
  };
};

export const selectedCompetitorsCsv = (request) => {
  return (dispatch) => {
    dispatch(onOTAInsightsCompetitorCsvStart());
    axios.defaults.headers.common["Authorization"] = "Token " + request.token;
    axios
      .post(Server.API + "hotel/ota_insights/selected-competitors-csv/")
      .then(function (response) {
        dispatch(onOTAInsightsCompetitorCsvSucceed(response));
        dispatch(Notify.newUserModal(response.data.message, true));
      })
      .catch(function (error) {
        dispatch(onOTAInsightsCompetitorCsvFailed(error));
      });
  };
};
const onOTACompetitorRateStart = () => {
  return {
    type: actionTypes.OTA_COMPETITOR_RATE_START,
  };
};

const onOTACompetitorRateFailed = (error) => {
  return {
    type: actionTypes.OTA_COMPETITOR_RATE_FAILED,
    error: error,
  };
};

const onOTACompetitorRateSucceed = (data) => {
  return {
    type: actionTypes.OTA_COMPETITOR_RATE_SUCCEED,
    state: data,
  };
};

export const storeOtaCompetitorRates = (request) => {
  return (dispatch) => {
    dispatch(onOTACompetitorRateStart());
    axios.defaults.headers.common["Authorization"] = "Token " + request.token;
    axios
      .post(Server.API + "hotel/ota_insights/competitors/rates/")
      .then(function (response) {
        dispatch(onOTACompetitorRateSucceed(response));
        dispatch(Notify.newUserModal(response.data.message, true));
      })
      .catch(function (error) {
        dispatch(onOTACompetitorRateFailed(error));
      });
  };
};
const onGetOtaCompetitorRateStart = () => {
  return {
    type: actionTypes.GET_OTA_COMPETITOR_RATE_START,
  };
};

const onGetOtaCompetitorRateFailed = (error) => {
  return {
    type: actionTypes.GET_OTA_COMPETITOR_RATE_FAILED,
    error: error,
  };
};

const onGetOtaCompetitorRateSucceed = (data) => {
  return {
    type: actionTypes.GET_OTA_COMPETITOR_RATE_SUCCEED,
    state: data,
  };
};
export const getOtaCompetitorRates = (request) => {
  return (dispatch) => {
    dispatch(onGetOtaCompetitorRateStart());
    axios.defaults.headers.common["Authorization"] = "Token " + request.token;
    axios
      .post(Server.API + "hotel/ota_insights/get-competitors-rates/")
      .then((response) => {
        if(response.data.success){
        dispatch(onGetOtaCompetitorRateSucceed(response))
        dispatch(storeOtaCompetitorRates(request));
        dispatch(Notify.newUserModal(response.data.message, true));
        }else{
          dispatch(Notify.newUserModal(response.data.message, false));
        }
      })
      .catch((error) => {
        dispatch(onGetOtaCompetitorRateFailed(error));
      });
  };
};
const onGetOtaDropdownDataStart = () => {
  return {
    type: actionTypes.GET_OTA_DROPDOWN_DATA_START,
  };
};

const onGetOtaDropdownDataFailed = (error) => {
  return {
    type: actionTypes.GET_OTA_DROPDOWN_DATA_FAILED,
    error: error,
  };
};

const onGetOtaDropdownDataSucceed = (data) => {
  return {
    type: actionTypes.GET_OTA_DROPDOWN_DATA_SUCCEED,
    state: data,
  };
};
export const getOtaDropdownData = (request) => {
  return (dispatch) => {
    dispatch(onGetOtaDropdownDataStart());
    axios.defaults.headers.common["Authorization"] = "Token " + request.token;
    axios
      .get(Server.API + "hotel/ota-insight-dropdown-data/get/")
      .then((response) => {
        dispatch(onGetOtaDropdownDataSucceed(response))
      })
      .catch((error) => {
        dispatch(onGetOtaDropdownDataFailed(error));
      });
  };
};

const onstoreOtaDropdownDataStart = () => {
  return {
    type: actionTypes.STORE_OTA_DROPDOWN_DATA_START,
  };
};

const onstoreOtaDropdownDataFailed = (error) => {
  return {
    type: actionTypes.STORE_OTA_DROPDOWN_DATA_FAILED,
    error: error,
  };
};

const onstoreOtaDropdownDataSucceed = (data) => {
  return {
    type: actionTypes.STORE_OTA_DROPDOWN_DATA_SUCCEED,
    state: data,
  };
};
export const storeOtaDropdownData = (request) => {
  return (dispatch) => {
    dispatch(onstoreOtaDropdownDataStart());
    axios.defaults.headers.common["Authorization"] = "Token " + request.token;
    axios
      .post(Server.API + "hotel/ota-insight-dropdown-data/create/", request)
      .then((response) => {
        dispatch(actions.getOtaDropdownData(request))
        dispatch(onstoreOtaDropdownDataSucceed(response))
      })
      .catch((error) => {
        dispatch(onstoreOtaDropdownDataFailed(error));
      });
  };
};