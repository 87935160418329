import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import usersLogCss from './css/usersLogCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../store/actions/index';
import MUIDataTable from "mui-datatables";
import {  MuiThemeProvider } from '@material-ui/core/styles';
import { OutboundTheme } from '../muiTheme';
import { withNamespaces, Trans } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import HeaderCard from './HeaderCard';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import {  Icon } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FilterListIcon from '@material-ui/icons/FilterList';
import Cached from '@material-ui/icons/Cached';
import modal from './modal/modal';
import Tooltip from '@material-ui/core/Tooltip';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Server from '../../config/server'

const styles = usersLogCss;
const requestTypeArr = [
    {key:1,value:'1.Upload Price'},
    {key:2,value:'2.Inventory'},
    {key:3,value:'3.Price'},
    {key:4,value:'4.Room Rate'},
    {key:5,value:'5.Reservation'},
    {key:6,value:'6.Authentication'},                     
]
class OutboundApiLog extends React.Component {

    state = {
        error:false,
        count: 0,
        current: 1,
    };

    componentWillMount() {
        let requestParam = {token: this.props.hotelAuthReducer.hotelDetails.token};
        this.props.getOutboundApiList(requestParam);
    }

    componentWillReceiveProps(props) {
        if (props.apiLogReducer && props.apiLogReducer.outboundApiLog && props.apiLogReducer.outboundApiLog.results) {
          this.setState({
            count: props.apiLogReducer.outboundApiLog.count,
            current: props.apiLogReducer.outboundApiLog.current,
            apiLog: props.apiLogReducer.outboundApiLog.results
            })
        }
    }

    renderApiLogData = (data) => {
        let jsx = [];
        data.map((val) => {
            jsx.push([
                val.created ? moment(val.created).format('YYYY-MM-DD hh:mm:ss A'):"",
                val.request_type?val.request_type:"-",
                val.unique_log_id ? val.unique_log_id:"-" ,
                val.unique_log_id ? val.unique_log_id:"-" ,
            ])
            return null;
        })
        return jsx;
    }
    handlefilterOpen = () => {
        this.setState({ openDateFilter: true });
    };
    handlefilterClose = () => {
        this.setState({ openDateFilter: false });
    };
    changeDate = (name) => dateValue => {
        this.setState({
            [name]: dateValue
        })
    }
    handlefilterApiLog = () => {
        let requestParams = {
            token: this.props.hotelAuthReducer.hotelDetails.token,
            url:'hotel/get-outboud-logs/?date='+moment(this.state.date).format('YYYY-MM-DD')+((this.state.page) ? '&page='+this.state.page:'')+((this.state.searchText) ? '&search=' + this.state.searchText : '') + ((this.state.ordering) ? '&ordering=' + this.state.ordering : '')+((this.state.request_type) ? '&request_type=' + this.state.request_type[0].key : '')
        }
        this.props.getOutboundApiList(requestParams)
        this.handlefilterClose();
        this.setState({ filtered: true })

    }
    handleResetfilterApiLog = (reload) => {
        if(reload){
            let url = 'hotel/get-outboud-logs/'+ ((this.state.date) ? '?date='+ moment(this.state.date).format('YYYY-MM-DD'):"")+((this.state.searchText) ? '?search=' + this.state.searchText : '')+((this.state.request_type) ? '?request_type=' + this.state.request_type[0].key : '');
            let requestParams = { 
                token: this.props.hotelAuthReducer.hotelDetails.token,
                url:url,
                updateApi:true 
            }
            this.props.getOutboundApiList(requestParams)
        }else{
            let requestParams = { token: this.props.hotelAuthReducer.hotelDetails.token,updateApi:true }
            this.props.getOutboundApiList(requestParams)
            this.setState({ filtered: false,date:null,searchText:null })
        }
        
    };

    downloadRequestResponse = (request_type, unique_log_id) => {
                
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: "Token " + this.props.hotelAuthReducer.hotelDetails.token },
            body: JSON.stringify({ request_type: request_type, unique_log_id:unique_log_id })
        };
        fetch(Server.API + `hotel/download-outboud-logs/`, requestOptions)
        .then((response) => response.blob())
        .then((blob) => {
            
            let _extension_type = null
            if(blob.type == "application/xml") {
                _extension_type = ".xml"
            } else {
                _extension_type = ".json"
            }
            
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                request_type+"_"+unique_log_id+_extension_type,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        });
    }

    options = () => {
        return {
            responsive: 'scroll',
            print: false,
            download: false,
            viewColumns: false,
            selectableRows: false,
            serverSide: true,
            page: (this.props.apiLogReducer.outboundApiLog) ? (this.props.apiLogReducer.outboundApiLog.current - 1) : 0,
            count: this.state.count,
            filter:true,
            rowsPerPageOptions : false,
            count: this.state.count,
            rowsPerPage: 20,
            search:true,
            sort: true,
            openDateFilter: false,

            textLabels: {
                body: {
                noMatch: this.props.apiLogReducer.loading ?
                    'Loading Records...' :
                    'Sorry, no matching records found.',
                },
            },
            onTableChange: (action, tableState) => {
                let url='hotel/get-outboud-logs/?'
                let requestParams= { token: this.props.hotelAuthReducer.hotelDetails.token }
                if (action === 'changePage') {
                    let page = tableState.page + 1;
                    url += 'page=' + page + ((this.state.searchText) ? '&search=' + this.state.searchText : '') + ((this.state.date) ? '&date='+ moment(this.state.date).format('YYYY-MM-DD'):"") + ((this.state.ordering) ? '&ordering=' + this.state.ordering : '')+((this.state.request_type ? '&request_type=' + this.state.request_type[0].key : '')+'&')
                    requestParams.url = url 
                    this.setState({
                        page: tableState.page + 1
                    })
                    this.props.getOutboundApiList(requestParams)
                }
                
                if (action === 'search') {
                    let searchText = tableState.searchText ? tableState.searchText : '';
                    if (tableState.searchText) {
                        url += 'search=' + searchText + ((this.state.ordering) ? '&ordering=' + this.state.ordering : '')+ ((this.state.date) ? '&date='+ moment(this.state.date).format('YYYY-MM-DD'):"")+'&';
                    }
                    requestParams.url = url
                    this.setState({
                        searchText: searchText
                    },
                        // () => {
                        // setTimeout(() => {
                        //     if (searchText.length == this.state.searchText.length) {
                        //         this.props.getOutboundApiList(requestParams)
                        //     }
                        // }, 1000);
                    // }
                    )
                    this.props.getOutboundApiList(requestParams)
                }
                let filter = tableState.filterList.filter(val => val.length > 0);
                if (filter && filter.length) {
                    if (tableState.filterList[1].length > 0) {
                        let request_type=requestTypeArr.filter(row => row.value == tableState.filterList[1][0]);
                        url += 'request_type=' + request_type[0].key 
                        requestParams.url = url 
                        this.setState({
                            request_type: request_type
                        })
                        this.props.getOutboundApiList(requestParams)
                    }
                }else{
                    this.setState({request_type:null,ordering:null })
                }
                if (action != 'onSearchOpen' && action != 'search' && action != 'sort' && action != 'filter') {
                    this.props.getOutboundApiList(requestParams)
                }
            },
            onColumnSortChange: (changedColumn, direction) => {
                let sort_direction;
                let requestParams= { token: this.props.hotelAuthReducer.hotelDetails.token }
                switch (changedColumn) {
                    case 'Received at':
                        sort_direction = (direction === 'ascending') ? 'created' : '-created';
                        break;
                    case 'Request Type':
                        sort_direction = (direction === 'ascending') ? 'request_type' : '-request_type';
                        break;
                    case 'Unique Log':
                        sort_direction = (direction === 'ascending') ? 'unique_log_id' : '-unique_log_id';
                        break;    
                    default:
                        sort_direction = (direction === 'ascending') ? 'created' : '-created';
                        break;    
                }
                requestParams.url='hotel/get-outboud-logs/?ordering='+sort_direction+((this.state.searchText) ? '&search='+ this.state.searchText : '')+((this.state.date) ? '&date='+ moment(this.state.date).format('YYYY-MM-DD'):"")
                this.props.getOutboundApiList(requestParams)
                this.setState({ ordering: sort_direction })
            },
            customToolbar: () => {
                const { classes, t } = this.props;

                return (
                    <React.Fragment>
                        <Tooltip classes={{popper: "toolTipPooper",tooltip: classes.htmlTooltip}} title={'Reload'}>
                            <Button onClick={() => this.handleResetfilterApiLog(true)}  ><Cached style={{color:'#757575'}}/></Button>
                        </Tooltip>
                        {(this.state.filtered && this.state.apiLog) &&
                        <Button onClick={() => this.handleResetfilterApiLog(false)} >{t("Reset")}</Button>
                        }
                        <Tooltip classes={{popper: "toolTipPooper",tooltip: classes.htmlTooltip}} title={'Date Filter'}>
                        <Button onClick={this.handlefilterOpen}  >
                            <DateRangeIcon style={{color:'#757575'}} />
                        </Button>
                        </Tooltip>
                    </React.Fragment>
                )
            }
        };
    }


    render() {
        const { classes, t } = this.props;
        const columns = [
            {
                name: "Received at",
                options: {
                    filter: false,
                    sort: true
                }
            },
            { 
                name: "Request Type",
                options: {
                    filter: true,
                    filterOptions: requestTypeArr.map(row => { return row.value }),
                    sort: true
                }
            },
            { 
                name: "Unique Log",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: "Download",
                options: {
                  filter: false,
                  sort: false,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        // value?<Button style={{zIndex:(this.state.searchText||this.state.date) ?'9999':''}} className={classes.buttonItems} color="primary" href={value.url}  title="Download"><Icon>downloadIcon</Icon></Button>:'-', 
                        value ? <React.Fragment>
                            <Button style={{zIndex:(this.state.searchText||this.state.date) ?'9999':''}} className={classes.buttonItems} color="primary" title="Download Request" onClick={(id) => this.downloadRequestResponse(1, value)}><Icon>inputIcon</Icon></Button>
                            <Button style={{zIndex:(this.state.searchText||this.state.date) ?'9999':''}} className={classes.buttonItems} color="inherit" title="Download Response" onClick={(id) => this.downloadRequestResponse(2, value)}><Icon>outputIcon</Icon></Button>
                        </React.Fragment> : "-"
                    );
                  }
              }
            },
        ];
    
        return (
            <>
            <HeaderCard >
                <Grid container>
                    <Grid item xs={12} >
                    <div className={classes.divStyle} >
                        <Typography variant="h5" className={classes.fontStyle} >
                          Outbound API log
                        </Typography>
                    </div>
                    </Grid>
                </Grid>
            </HeaderCard>
            <Card className={classes.cardPadding}>
                <MuiThemeProvider theme={OutboundTheme}>   
                    <CardContent>                
                        <MUIDataTable
                            title={''}
                            data={(this.state.apiLog) ? this.renderApiLogData(this.state.apiLog):[]}
                            columns={columns}
                            options={this.options()}
                        />
                    </CardContent>
                </MuiThemeProvider>
            </Card>
            {modal.apiLogFilterModal(this)}

            </>
        );
    }
}

OutboundApiLog.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        loading: state.apiLogReducer.loading,
        error: state.apiLogReducer.error,
        apiLogReducer: state.apiLogReducer,
        hotelAuthReducer: state.hotelAuthReducer,
        userSession: state.sessionReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getOutboundApiList: (request) => { dispatch(actions.getOutboundApiList(request)) },
    };
};

export default compose(withStyles(styles, {
    withTheme: true
}), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(OutboundApiLog));
