var style = theme => ({
    textField: {
    },
    textFieldSelect: {
    },
    pullLeft: {
        float: 'left'
    },
    menu: {
        width: 200,
    },
   
    mapDiv: {
        height: '380px',
        width: '100%',
        paddingLeft: '20px',
        marginTop: '30px',
        ['@media (max-width: 600px)']: {
            display: "none !important"
        },
    },
    MainLoader: {
        position: 'fixed',
        height: '100%',
        width: '80%',
        background: 'rgb(15, 51, 80, 0.3)',
        zIndex: '9',
    },
    loader: {
        position: 'absolute',
        top: '35%',
        left: '35%'
    },
    buttonProgress: {
        position: 'absolute'
    },
    formControl: {
        width: "100%",
        textAlign: 'left',
    },
    formControlLeft: {
        minWidth: '46%',
        marginRight: '2%',
        ['@media (min-width:320px)']: {
            minWidth: '240px'
        },
        minWidth: '300px',
        textAlign: 'left'
    },
    formControlSelect: {
        width: '100%',
        textAlign: 'left',
    },
    formControlLeftSelect: {
        width: '44%',
        marginRight: '2%',
        ['@media (min-width:320px)']: {
            minWidth: '240px'
        },
        minWidth: '300px',
        textAlign: 'left',
        marginTop: '7px'
    },
    pullLeft: {
        float: 'left',
    },
    fullWidth: {
        width: '100%',
    },
    btnDiv: {
        textAlign: 'right',
        width: '90%'
    },
    saveBtnContainer: {
        float: "right",
        display: "block",
        width: "100%",
        ['@media (max-width: 414px) and (min-width: 360px)']: {
            display: 'flex',
            float: "none",
            width: "100%",
            marginBottom: '30px',
            marginBottom: '-50px'
        },
    },
    saveBtn: {
        margin: "-49px 16px 0px 0px",
        float: 'right',
        height: "fit-content",
        ['@media (max-width: 414px) and (min-width: 360px)']: {
        }
    },
    mailToggle: {
        margin: "-6px 0px 0px 0px",
        width: "150px",
            float: "left"
    },
    gridField: {
        margin: "16px 0px"
    },
    gridPadding: {
        paddingRight: "20px",
        ['@media (max-width: 414px) and (min-width: 360px)']: {
            paddingRight: "0px",
        }
    },
    gridPadding2: {
        paddingLeft: "20px",
        ['@media (max-width: 414px) and (min-width: 360px)']: {
            paddingLeft: "0px",
        }
    },
    fieldlabel: {
        fontSize: "14px",
        marginBottom: "4px",
        marginLeft: "4px"
    },
    closeButtonPrice: {
        position:'absolute',
        right:'0',
        ['@media (max-width: 540px)']: {
          marginTop: "10px",
        }
      },
      DivStyle: {
        float: "left",
        marginTop: "2px"
      },
      savBtn: {
        margin:"-16px 0px 20px 22px",
        float:"left"
      },
      cardContent: {
        marginTop:"-20px",
      },
      formLable: {
        fontSize: "14px", 
        fontWeight: "500", 
        marginLeft: "1px", 
        color: "#494949"
      },
      divStyle: {
        float: "left", 
        marginTop: "2px", 
        width: "100%"
      },
      fontstyle: {
        textAlign: "left", 
        fontWeight: "500"
      },
      tabView: {
        marginTop: "16px", 
        borderBottom: '1px solid #f0f0f0'
      },
      btnFetch: {
        float:"left", 
        marginBottom:"22px"
      },
      btnSave: {
        margin:"18px 0",
        float:"left"
      }
});

export default style;
