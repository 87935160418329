var style = {
    textField: {
        marginTop:'0px',
        width: '50%',
        ['@media (max-width: 414px) and (min-width: 360px)']: {            
            width: "90%",
            marginLeft: '15px'
        }
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    formControl:{
        marginTop:'-6px',
        ['@media (max-width: 414px) and (min-width: 360px)']: {            
            width: "100%",
            marginRight: '15px'
        }
    },
    saveBtn: {
        "&:hover": {
            background:'#a93939',
            color:'white',    
        },
        background:'#d32f2f',
        color:'white',
        float: 'left',
        ['@media (max-width: 414px) and (min-width: 360px)']: {
            width: '100%'
        }
    },
    btnDiv: {
        width: '50%',
        textAlign: 'left',
        ['@media (max-width: 414px) and (min-width: 360px)']: {
            width: '90%',
            marginLeft: '15px'
        }
    },
    textType:{
        fontWeight:'500',
        color:'#A0A2A6',
        textAlign:'left',
        fontSize:'17px'
    },
    divStyle: {
        float: "left", 
        marginTop: "2px"
    },
    Fontstyle: {
        textAlign: "left", 
        fontWeight: "500"
    },
 
}
export default style;