import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';

const onEventSucceed = (data) => {
    return {
        type: actionTypes.EVENT_SUCCEED,
        state: data
    }
}

export const getEventList = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/events_category/', request)
            .then(function (response) {
                dispatch(onEventSucceed(response.data.data))
            });
    }
}