import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import CardMedia from '@material-ui/core/CardMedia';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { PasswordRecoveryTheme } from './muiTheme';
import { connect } from "react-redux";
import { compose } from 'redux'
import * as actions from '../store/actions/index';
import loginCss from './css/login'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Icon } from '@material-ui/core';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import { withNamespaces, Trans } from 'react-i18next';

const styles = loginCss;

class PasswordRecovery extends React.Component {
  state = {
    error: [],
    password: '',
    confirm_password: ''
  };

  componentDidMount() {
    let requestData = {
      token: this.props.match.params.resetToken
    };
    this.props.validateResetToken(requestData);
  }

  componentWillReceiveProps(props) {
    if (props.accountReducer.isUpdated) {
      this.setState({
        password: '',
        confirm_password: '',
      })
    }
  }


  validateForm = (data) => {
    let error = [];
    if (data) {

      if (!this.state.password) {
        error.push('password')
      }
      if (!this.state.confirm_password) {
        error.push('confirm_password')
      }
      if (this.state.confirm_password && (this.state.password !== this.state.confirm_password)) {
        error.push('confirm_password_match')
      }

      if (error) {
        this.setState({
          error: error
        })
        return error
      } else {
        return false
      }
    } else {
      return true;
    }
  }


  updatePassword = () => {

    let validateError = this.validateForm(this.state);
    if (validateError.length > 0) {
      return;
    }

    let requestData = {
      token: this.props.match.params.resetToken,
      new_password: this.state.confirm_password
    };
    this.props.resetPassword(requestData);
  }

  handleChange = field => (event) => {
    this.setState({
      [field]: event.target.value,
    })
  }

  toggleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }
  toggleConfirmPassword = () => {
    this.setState({
      confirmPassword: !this.state.confirmPassword
    })
  }

  render() {
    const { classes, t } = this.props;
    const theme = PasswordRecoveryTheme;
    return (
      <MuiThemeProvider theme={PasswordRecoveryTheme}>
      <Grid container className={classes.pageContainer} style={{height:'100vh'}}>
    <Grid  lg={6} sm={12} className={classes.loginLogoCard}>
        <CardMedia
          className={classes.siteLogo1}
          image="/img/roompricegenie_logo_new.svg"
          title="Kanhasoft"
        />
        {/* <div style={{height:'calc(100vh - 105px)',position:'absolute'}}>
          <img src="/img/login_animation.gif" style={{width:'100%',height:'100%',display:'block'}}  />
        </div> */}
    </Grid>
    <Grid  lg={6} sm={12} className={classes.loginFormCard}>
      {/* <Grid sm={12} md={12}> */}
      <div className={classes.cardFormDiv}>

          <main>

            <form className={classes.formContainer} noValidate autoComplete="off" style={{ padding: "10px 16px 16px 16px" }}>
              <Typography style={{fontSize:'1.5rem',textAlign:'left',fontWeight:'500',fontFamily:'roboto'}}>
                Set new password
              </Typography>
              {(this.props.accountReducer.isValid !== undefined && (this.props.accountReducer.isValid && !this.props.accountReducer.isUpdated)) &&
                <React.Fragment>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="password" shrink={true}>Password</InputLabel>
                    <Input type={this.state.showPassword ? "text" : 'password'} id="password" name="password" error={this.state.error.includes("password")} onChange={this.handleChange('password')} placeholder="Password" autoComplete="email" autoFocus
                      endAdornment={this.state.showPassword ? <VisibilityOff onClick={this.toggleShowPassword} style={{ color: "#8b8b8b" }} /> : <Visibility onClick={this.toggleShowPassword} style={{ color: "#8b8b8b" }} />}
                    />


                    {this.state.error.includes("password") ? <span class='error-message'>{'* Please enter password'}</span> : ''}
                  </FormControl>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="confirm_password" shrink={true}>Confirm Password</InputLabel>
                    <Input type={this.state.confirmPassword ? "text" : 'password'} id="email" name="confirm_password" placeholder="Confirm Password" error={this.state.error.includes("confirm_password")} onChange={this.handleChange('confirm_password')} autoComplete="confirm_password"
                      endAdornment={this.state.confirmPassword ? <VisibilityOff onClick={this.toggleConfirmPassword} style={{ color: "#8b8b8b" }} /> : <Visibility onClick={this.toggleConfirmPassword} style={{ color: "#8b8b8b" }} />}
                    />
                    {this.state.error.includes("confirm_password") ? <span class='error-message'>{'* Please confirm password'}</span> : ''}
                    {this.state.error.includes("confirm_password_match") ? <span class='error-message'>{'* Confirm password does not match with password'}</span> : ''}
                  </FormControl>
                  <Button onClick={() => this.updatePassword()} className={classes.LoginBtn} variant="contained" color="primary" fullWidth>
                    Change password
                  </Button>
                </React.Fragment>
              }
              {(this.props.accountReducer.isValid !== undefined && !(this.props.accountReducer.isValid)) &&
                <div className={classes.warning}>
                  <Icon style={{ fontSize: '40px' }}>warning</Icon>
                  <Typography variant="body2" gutterBottom className={classes.warningText}>
                    {t("Your link to reset your password has expired. Please try again.")}.
                  </Typography>
                </div>
              }
              {this.props.accountReducer.isUpdated &&
                <div className={classes.success}>
                  <Icon style={{ marginTop: '10px' }}>check_circle</Icon>
                  <Typography variant="body2" gutterBottom className={classes.warningText}>
                    {t("Your password has been changed successfully, please login using your new password.")}
                  </Typography>

                </div>
              }
              <Grid container>
                <Button
                  type="button"
                  color="primary"
                  style={{marginTop:"16px"}}
                  className={["cancelBtn"]}
                  // className={classes.extraBtn}
                  href="/login"
                  fullWidth
                >
                 Go Back to Login
                </Button>
                {/* <Button
                  href="https://roompricegenie.com/sign-up"
                  type="button"
                  style={{ marginTop: "16px" }}
                  color="primary"
                // className={classes.extraBtn}
                >
                  Register Now!
                </Button> */}
              </Grid>
            </form>
          </main>

        {/* <img src={"/img/skyline.png"} height="100%" className={classes.skyline} /> */}
        </div></Grid></Grid>
      </MuiThemeProvider >
    );
  }
}

PasswordRecovery.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  container: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    accountReducer: state.accountReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    validateResetToken: (request) => { dispatch(actions.validateResetToken(request)) },
    resetPassword: (request) => { dispatch(actions.resetPassword(request)) }
  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(PasswordRecovery));