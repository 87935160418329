import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DeletePropertyCss from './css/deletePropertyCSS';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../store/actions/index';
import FormControl from '@material-ui/core/FormControl';
import { withNamespaces } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { Typography } from "@material-ui/core";
import HeaderCard from './HeaderCard';

const styles = DeletePropertyCss;

class DeleteProperty extends React.Component {
  state = {
    error: [],
    delete_text:''
  };

  componentWillReceiveProps(props) {
   
  }
  handleValue = field => (event) => { 
    this.setState({
      [field]: event.target.value,
    })
  }
  validateForm = (data) => {
    let error = [];
    if (data) {
      if (!this.state.delete_text || this.state.delete_text != "DELETE PROPERTY" ) {
        error.push('delete_text')
      }else{
        error=[]  
      }

      if (error) {
        this.setState({
          error: error
        })
        return error
      } else {
        return false
      }
    } else {
      return true;
    }
  }

  deleteProperty =()=>{
    let validateError = this.validateForm(this.state);
    if (validateError.length > 0) {
      return;
    }
    let requestParam = {
        token: this.props.hotelAuthReducer.hotelDetails.token,
      }
      this.props.deleteProperty(requestParam);
  }
  render() {
    const { classes } = this.props;
    const { t } = this.props;

    let width = window.screen.width;


    return (
      <>
      <HeaderCard >
        <Grid container>
          <Grid item xs={12} >
            <div className={classes.divStyle} >
                <Typography className={classes.Fontstyle} variant="h5" >
                  Delete Property
                </Typography>
            </div>
          </Grid>
        </Grid>
      </HeaderCard>
        <Grid container direction={width < 800 ? "column" : ""}>
        <Grid item lg={6} md={12} sm={12} className={classes.fullWidth}>
          <Card>
            <CardContent>
              <form className={classes.formContainer} noValidate autoComplete="off">
                  
                <FormControl margin="normal" required className={classes.formControl} >
                    <Typography className={classes.textType}>
                        Type in "DELETE PROPERTY"
                    </Typography>
                  <TextField
                    id="standard-name"
                 
                    onChange={this.handleValue('delete_text')}
                    className={classes.textField}
                    margin="normal"
                    error={this.state.error.includes("delete_text")}
                    placeholder={t('Please enter "DELETE PROPERTY"')}
                  />
                {this.state.error.includes("delete_text") ? <span class='error-message' >{'* Please enter Valid text'}</span> : ''}                  
                </FormControl>
                <div className={classes.btnDiv}>
                  <Button className={classes.saveBtn} onClick={() => this.deleteProperty()} variant="contained" >
                    {t('Delete Property')}
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card >
        </Grid>
      </Grid>
     </>
    );
  }
}


const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        userSession: state.sessionReducer,
        hotelDetail: state.hotelSettingReducer.hoteDetail,
        hotelAuthReducer: state.hotelAuthReducer,
        accountReducer: state.accountReducer
      };
};

const mapDispatchToProps = dispatch => {
  return{
    deleteProperty: (request) => { dispatch(actions.deleteProperty(request)) },
  }
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(DeleteProperty));