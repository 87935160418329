import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import yieldingTagsCss from "./css/yieldingTagsCss";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../../../store/actions/index";
import CustomTooltip from "../../Utils/toolTip";
import Server from "../../../config/server";
import axios from "axios";
import { Icon } from "@material-ui/core";
import Modals from "./modal/modal";
import moment from "moment";
import { withNamespaces } from "react-i18next";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { yeldTagTheme } from "../../muiTheme";
import MUIDataTable from "mui-datatables";
import classnames from "classnames";
import Warning from "../warning";
import DialogContentText from "@material-ui/core/DialogContentText";
import HeaderCard from "../HeaderCard";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const styles = yieldingTagsCss;

let UserList = [];
class YieldingTags extends React.Component {
  state = {
    openAddModal: false,
    addModal: {
      name: "",
      report_type: "",
      mode: "",
      frequency: "",
      accounting_mode: "",
      // pickup_tab_options: ["yesterday"],
      // financials_tab_options: ["REVENUE"],
      receipient_user_ids: [],
    },
    error: [],
    userList: [],
    scheduleReportList: [],
  };

  componentWillMount() {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
    };
    this.props.getHotelDetail(requestParam);
    this.props.scheduleReportList(requestParam);
    this._getUserData();
  }

  _getUserData = () => {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
    };
    this.props.getClientAdminList(requestParam);
    this.props.getManagerList(requestParam);
    this.props.getStaffList(requestParam);
    this.props.getOwner(requestParam);
  };

  componentDidMount() {}

  componentWillReceiveProps(props) {
    if (props.commonReducer.notify && props.commonReducer.notify.message) {
      this.setState({
        openAddModal: false,
        openDeleteModal: false,
      });
    }

    if (
      props.scheduleReportReducer &&
      props.scheduleReportReducer.list &&
      props.scheduleReportReducer.list.results
    ) {
      this.setState({
        scheduleReportList: props.scheduleReportReducer.list.results,
      });
    }
    if (
      props.clientAdminListReducer.clientAdminList &&
      props.managerListReducer.managerList &&
      props.staffListReducer.staffList
    ) {
      UserList = [];
      if (props.clientAdminListReducer.owner) {
        let client = props.clientAdminListReducer.owner;
        UserList.push({
          name: client.first_name + " " + client.last_name,
          user_id: client.id,
        });
      }

      let MasterAdmin = [];
      let MasterManager = [];
      let MasterStaff = [];
      if (props.clientAdminListReducer.clientAdminList.assigned) {
        props.clientAdminListReducer.clientAdminList.assigned.map(
          (row, index) => {
            MasterAdmin.push(row.user);
            let user = {
              name: row.user.first_name + " " + row.user.last_name,
              user_id: row.user.id,
            };
            UserList.push(user);
            return "";
          }
        );
      }

      if (props.clientAdminListReducer.clientAdminList.unassigned) {
        props.clientAdminListReducer.clientAdminList.unassigned.map(
          (row, index) => {
            if (row.assigned_hotels.length > 0) {
              if (row.assigned_hotels[0].level == 3) {
                MasterAdmin.push(row);
                let user = {
                  user_id: row.id,
                  name: row.first_name + " " + row.last_name,
                };
                UserList.push(user);
                return "";
              }

              if (row.assigned_hotels[0].level == 1) {
                MasterManager.push(row);
                let user = {
                  user_id: row.id,
                  name: row.first_name + " " + row.last_name,
                };
                UserList.push(user);
                return "";
              }

              if (row.assigned_hotels[0].level == 2) {
                MasterStaff.push(row);
                let user = {
                  user_id: row.id,
                  name: row.first_name + " " + row.last_name,
                };
                UserList.push(user);
                return "";
              }
            }
          }
        );
      }

      if (props.managerListReducer.managerList.assigned) {
        props.managerListReducer.managerList.assigned.map((row, index) => {
          MasterManager.push(row.user);
          let user = {
            user_id: row.user.id,
            name: row.user.first_name + " " + row.user.last_name,
          };
          UserList.push(user);
          return "";
        });
      }

      if (props.staffListReducer.staffList.assigned) {
        props.staffListReducer.staffList.assigned.map((row, index) => {
          MasterStaff.push(row.user);
          let user = {
            user_id: row.user.id,
            name: row.user.first_name + " " + row.user.last_name,
          };
          UserList.push(user);
          return "";
        });
      }
      console.log("UserList", UserList);
      this.setState({
        userList: UserList,
      });
    }
  }

  composeTableData = (tableData) => {
    if (this.state.scheduleReportList.length > 0) {
      let data = [];
      this.state.scheduleReportList.map((val) => {
        data.push([
          val.id,
          val.name,
          val.report_type,
          val.frequency,
          val.accounting_mode,
          val.receipient_user_ids,
          val,
        ]);
      });
      return data;
    }
    return [];
  };

  handleChange = (name, e) => {
    this.setState({
      addModal: {
        ...this.state.addModal,
        [name]: e.target.value,
      },
    });
  };

  toggleAddModal = (id) => {
    if (id) {
      let data = this.state.scheduleReportList.find((val) => val.id == id);
      if (data) {
        this.setState({
          addModal: data,
        });
      }
    } else {
      this.setState({
        addModal: {
          name: "",
          report_type: "",
          mode: "",
          frequency: "",
          accounting_mode: "",
          receipient_user_ids: [],
        },
      });
    }
    this.setState({
      openAddModal: !this.state.openAddModal,
    });
  };

  toggleDeleteModal = (id) => {
    this.setState(
      {
        openDeleteModal: !this.state.openDeleteModal,
        deleteId: id,
      },
      () => this.setState({ openAddModal: false })
    );
  };

  deleteScheduleReport = () => {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      id: this.state.deleteId,
    };

    this.props.deleteScheduleReport(requestParam);
  };

  createNewScheduleReport = () => {
    let error = [];

    if (!this.state.addModal.name) {
      error.push("name");
    }

    if (!this.state.addModal.report_type) {
      error.push("report_type");
    }


    if (!this.state.addModal.frequency) {
      error.push("frequency");
    }
    if (!this.state.addModal.accounting_mode) {
      error.push("accounting_mode");
    }

    if (this.state.addModal.receipient_user_ids.length === 0) {
      error.push("receipient_user_ids");
    }

    this.setState({
      error,
    });

    if (error.length === 0) {
      let requestParam = {
        token: this.props.hotelAuthReducer.hotelDetails.token,
        ...this.state.addModal,
        hotel: this.props.hotelAuthReducer.hotelDetails.hotel.id,
        mode:"YEARLY"
      };

      if (this.state.addModal.id) {
        this.props.updateScheduleReport(requestParam);
      } else {
        this.props.createNewScheduleReport(requestParam);
      }
    }
  };

  render() {
    const { classes, t } = this.props;
    const columns = [
      {
        name: t("id"),
        options: {
          display: false,
        },
      },
      {
        name: t("Report Name"),
        options: {
          display: true,
        },
      },
      {
        name: t("Report Type"),
        options: {
          filter: false,
        },
      },
      {
        name: t("Frequency"),
        options: {
          filter: false,
        },
      },
      {
        name: t("Accounting Mode"),
        options: {
          filter: false,
        },
      },
      {
        name: t("Receipients"),
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            let names = "";

            value.map(val=>{
              let find = this.state.userList.find(row=>row.user_id === val)
              if(find){
                names = names+ find.name +", " ;
              }              
            })
            return names;
          },
        },
      },
      {
        name: t("Actions"),
        options: {
          filter: false,
          download: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const { classes } = this.props;
            return (
              <React.Fragment>
                <Button
                  color="secondary"
                  onClick={() => this.toggleDeleteModal(value.id)}
                  className={classes.actionIcon}
                >
                  <Icon>delete</Icon>
                </Button>
              </React.Fragment>
            );
          },
        },
      },
    ];
    const options = {
      responsive: "scroll",
      selectableRows: false,
      search: false,
      print: false,
      download: false,
      viewColumns: false,
      filter: false,
      rowsPerPage: 10,
      onRowClick: (rowData, e) => {
        this.toggleAddModal(rowData[0]);
      },
      setRowProps: (row) => {
        return {
          className: classnames({
            [this.props.classes.refRommRow]: row[1] === "Reference YieldingTag",
          }),
        };
      },
      textLabels: {
        body: {
          noMatch: this.state.loadData
            ? "Loading Records..."
            : "Sorry, no matching records found.",
        },
      },
    };

    return (
      <>
        <HeaderCard>
          <Grid container>
            <Grid item>
              <div className={classes.divStyle}>
                <Typography variant="h5" className={classes.fontStyle}>
                  {t("Schedule Reports")}
                  <CustomTooltip
                    title=""
                    description={t(
                      "Set up scheduled reports and keep your colleagues and other stackeholders informed about your property's performance."
                    )}
                  />
                </Typography>
                <DialogContentText align="left" className={classes.helpText}>
                  {t(
                    "Set up scheduled reports and keep your colleagues and other stackeholders informed about your property's performance."
                  )}
                </DialogContentText>
              </div>
            </Grid>
          </Grid>
        </HeaderCard>
        <Card>
          <CardContent>
            <div className={classes.addBtnDiv}>
              <div className={classes.addNewDiv}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={()=>this.toggleAddModal()}
                >
                  {t("Add new Schedule Report")}
                </Button>
              </div>
            </div>
            <div className={classes.userTableDiv}>
              <MuiThemeProvider theme={yeldTagTheme}>
                <MUIDataTable
                  title=""
                  data={this.composeTableData([])}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
            </div>
          </CardContent>
        </Card>
        {this.state.openAddModal && Modals.addNewScheduleReport(this)}
        {this.state.openDeleteModal && Modals.deleteScheduleReportmodal(this)}
      </>
    );
  }
}

YieldingTags.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    hotelAuthReducer: state.hotelAuthReducer,
    scheduleReportReducer: state.scheduleReportReducer,
    managerListReducer: state.managerListReducer,
    staffListReducer: state.staffListReducer,
    clientAdminListReducer: state.clientAdminListReducer,
    commonReducer: state.commonReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClientAdminList: (request) => {
      dispatch(actions.getClientAdminList(request));
    },
    getManagerList: (request) => {
      dispatch(actions.getManagerList(request));
    },
    getStaffList: (request) => {
      dispatch(actions.getStaffList(request));
    },
    getOwner: (request) => {
      dispatch(actions.getOwner(request));
    },
    getHotelDetail: (request) => {
      dispatch(actions.getHotelDetail(request));
    },
    getPricingSettings: (request) => {
      dispatch(actions.getPricingSettings(request));
    },
    updatePricingSettings: (request) => {
      dispatch(actions.updatePricingSettings(request, "yelding"));
    },
    putNotify: (request) => {
      dispatch(actions.putNotify(request));
    },
    scheduleReportList: (request) => {
      dispatch(actions.scheduleReportList(request));
    },
    createNewScheduleReport: (request) => {
      dispatch(actions.createNewScheduleReport(request));
    },
    updateScheduleReport: (request) => {
      dispatch(actions.updateScheduleReport(request));
    },
    deleteScheduleReport: (request) => {
      dispatch(actions.deleteScheduleReport(request));
    },
  };
};

export default compose(
  withStyles(styles, {
    withTheme: true,
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(withNamespaces("translations")(YieldingTags));
