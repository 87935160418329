import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import Notify from '../Notify'


const onPmsSettingGetStart = () => {
    return {
        type: actionTypes.PMS_SETTING_GET_START
    }
}

const onPmsSettingGetFailed = (error) => {
    return {
        type: actionTypes.PMS_SETTING_GET_FAILED,
        error: error
    }
}

const onPmsSettingGetSucceed = (data) => {
    return {
        type: actionTypes.PMS_SETTING_GET_SUCCEED,
        state: data
    }
}

export const getPmsSetting = (request) => {
    return dispatch => {
        dispatch(onPmsSettingGetStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'system/settings/list/')
            .then(function (response) {
                dispatch(onPmsSettingGetSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onPmsSettingGetFailed(error))
            });
    }
}

const onupdatePmsSettingStart = () => {
    return {
        type: actionTypes.PMS_SETTING_UPDATE_START
    }
}

const onupdatePmsSettingSucceed = (data) => {
    return {
        type: actionTypes.PMS_SETTING_UPDATE_SUCCEED,
        state: data
    }
}

const onupdatePmsSettingFailed = (error) => {
    return {
        type: actionTypes.PMS_SETTING_UPDATE_FAILED,
        error: error
    }
}

export const updatePmsSetting = (request) => {
    return dispatch => {
        dispatch(onupdatePmsSettingStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.patch(Server.API + 'system/settings/update/'+request.id+'/', request)
            .then(function (response) {
                dispatch(onupdatePmsSettingSucceed(response.data))
                dispatch(Notify.newUserModal("Setting Updated successfully", true))
            })
            .catch(function (error) {
                dispatch(onupdatePmsSettingFailed(error))
                dispatch(Notify.newUserModal("An error occured while updating Setting", false))
            });
    }
}

export const saveImportPMSDataJson = (request) => {

    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/amenitiz/inventory-and-rate-restriction/',request.file, {
            headers:{
                'content-type':'multipart/form-data'
            }
        })
        .then(function (response) {
            dispatch(Notify.newUserModal("PMS Data Import successfully", true))
        })
        .catch(function (error) {
            dispatch(Notify.newUserModal("An error occured while updating Setting", false))
        });
    }
}