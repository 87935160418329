import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../index';


const onScrapProgramStart = () => {
    return {
        type: actionTypes.GET_SCRAP_SCHEDULE_START,
        error: null
    }
}

const onScrapProgramFailed = (error) => {
    return {
        type: actionTypes.GET_SCRAP_SCHEDULE_FAILED,
        error: error
    }
}

const onScrapProgramSucceed = (data) => {
    return {
        type: actionTypes.GET_SCRAP_SCHEDULE_SUCCEED,
        state: data
    }
}

export const getScrapSchedule = (request) => {
    return dispatch => {
        dispatch(onScrapProgramStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        let url = (request.url ? (Server.API + request.url) : (Server.API + 'amalgamation/scrape-task/list/'));
        axios.get(url)
            .then(function (response) {
                dispatch(onScrapProgramSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onScrapProgramFailed(error))
            });
    }
}


