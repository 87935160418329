import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../index';
import Notify from '../Notify'

const onScraperListFailed = (error) => {
    return {
        type: actionTypes.GET_SCRAPER_LIST_FAILED,
        error: error
    }
}

const onScraperListSucceed = (data) => {
    return {
        type: actionTypes.GET_SCRAPER_LIST_SUCCEED,
        state: data
    }
}

export const getScraperList = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        let url = (request.url ? (Server.API + request.url) : (Server.API + 'hotel/booking/scraper/list/'));
        axios.get(url)
            .then(function (response) {
                dispatch(onScraperListSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onScraperListFailed(error))
            });
    }
}

export const addScraper = (request) => {
    return dispatch => {
        let reqParams = {
            hotel_url: request.url
        }
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/booking/scraper/', reqParams)
            .then(function (response) {
                let req = { token: request.token };
                dispatch(actions.getScraperList(req))
                // dispatch(onScraperListSucceed(response.data))
                dispatch(Notify.newUserModal("Scraper Added Successfully", true))
            })
            .catch(function (error) {
                if (error.response.data.success == 'False') {
                    dispatch(Notify.newUserModal("This Url Already exist", false))
                } else {
                    dispatch(Notify.newUserModal("Error While Adding scraper", false))
                }
                dispatch(onScraperListFailed(error))
            });
    }
}