import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import Notify from '../Notify'
import * as actions from '../index';

const onRecoverStart = () => {
    return {
        type: actionTypes.RECOVER_START
    }
}

const onRecoverFailed = (error) => {
    return {
        type: actionTypes.RECOVER_FAILED,
        error: error
    }
}

const onRecoverSucceed = (data) => {
    return {
        type: actionTypes.RECOVER_SUCCEED,
        state: data
    }
}

export const recoverPassword = (request) => {
    return dispatch => {
        dispatch(onRecoverStart())
        axios.post(Server.API + 'account/password/recovery/', request)
            .then(function (response) {
                dispatch(onRecoverSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onRecoverFailed(error))
            });
    }
}


const onUpdatePasswordStart = () => {
    return {
        type: actionTypes.UPDATE_PASSWORD_START
    }
}

const onUpdatePasswordFailed = (error) => {
    return {
        type: actionTypes.UPDATE_PASSWORD_FAILED,
        error: error
    }
}

const onUpdatePasswordSucceed = (data) => {
    return {
        type: actionTypes.UPDATE_PASSWORD_SUCCEED,
        state: data
    }
}

const updateClientView = (data, time) => {
    return {
        type: actionTypes.UPDATE_CLIENT_VIEW,
        data: data,
    }
}
export const updatePassword = (request) => {
    return dispatch => {
        dispatch(onUpdatePasswordStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'account/password/reset/', request)
            .then(function (response) {
                dispatch(onUpdatePasswordSucceed(response.data))
                dispatch(Notify.newUserModal("Password has been updated successfully!", true))
            })
            .catch(function (error) {
                dispatch(onUpdatePasswordFailed(error))
                dispatch(Notify.newUserModal("An error occured while updating password", false))
            });
    }
}

export const updateAccount = (request) => {
    return dispatch => {
        let requestData = {
            first_name: request.data.first_name,
            last_name: request.data.last_name,
            email: request.data.email,
            language: request.data.language
        }

        request.storeData.user.first_name = request.data.first_name;
        request.storeData.user.last_name = request.data.last_name;
        request.storeData.user.language = request.data.language;

        dispatch(onUpdatePasswordStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.storeData.token;
        axios.patch(Server.API + 'account/update/', requestData)
            .then(function (response) {
                dispatch(actions.updateStorageData(request.storeData))
                dispatch(onUpdatePasswordSucceed(response.data))
                dispatch(Notify.newUserModal("Account Info has been updated successfully!", true))
                if(request.storeData.user && request.storeData.user.isClientView){
                    dispatch(updateClientView(request.storeData.user.isClientView))
                }
            })
            .catch(function (error) {
                dispatch(onUpdatePasswordFailed(error))
                dispatch(Notify.newUserModal("An error occured while updating Account Info", false))
            });
    }
}

export const updateSidebarView = (request) => {
    return dispatch => {
        
        let requestData = {
            is_collapsed_sidebar_enable: request.data.is_collapsed_sidebar_enable
        }

        request.storeData.user.is_collapsed_sidebar_enable = request.data.is_collapsed_sidebar_enable;

        axios.defaults.headers.common['Authorization'] = "Token " + request.storeData.token;
        axios.patch(Server.API + 'account/update/', requestData)
            .then(function (response) {
                dispatch(actions.updateStorageData(request.storeData))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while updating Account Info", false))
            });
    }
}

export const updateAccountCall = (request) => {
    return dispatch => {
        let requestData = {
            do_not_call: request.do_not_call,
        }
        dispatch(onUpdatePasswordStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.patch(Server.API + 'account/client/update/' + request.user + '/', requestData)
            .then(function (response) {
                dispatch(Notify.newUserModal("Account Info has been updated successfully!", true))
                window.location.reload();
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while updating Account Info", false))
            });
    }
}

const onValidateResetTokenStart = () => {
    return {
        type: actionTypes.VALIDATE_RESET_TOKEN_START
    }
}

const onValidateResetTokenFailed = (error) => {
    return {
        type: actionTypes.VALIDATE_RESET_TOKEN_FAILED,
        state: error
    }
}

const onValidateResetTokenSucceed = (data) => {
    return {
        type: actionTypes.VALIDATE_RESET_TOKEN_SUCCEED,
        state: data
    }
}

export const validateResetToken = (request) => {
    return dispatch => {
        dispatch(onValidateResetTokenStart())
        axios.get(Server.API + 'account/password/recovery/session/?token='+request.token,request)
        .then(function (response) {
            if(response.status === 200){
                dispatch(onValidateResetTokenSucceed({status:true}))
            }else{
                dispatch(onValidateResetTokenFailed('invalid-token'))
            }
        })
        .catch(function (error) {
            dispatch(onValidateResetTokenFailed({status:false,error:error.response.data.recovery_session.message}))
        });
    }
}



const onresetPasswordStart = () => {
    return {
        type: actionTypes.RESET_PASSWORD_START
    }
}

const onresetPasswordFailed = (error) => {
    return {
        type: actionTypes.RESET_PASSWORD_FAILED,
        error: error
    }
}

const onresetPasswordSucceed = (data) => {
    return {
        type: actionTypes.RESET_PASSWORD_SUCCEED,
        state: data
    }
}


export const resetPassword = (request) => {
    return dispatch => {
        dispatch(onresetPasswordStart())
        axios.post(Server.API + 'account/password/recovery/session/',request)
        .then(function (response) {
            if(response.status === 200){
                dispatch(onresetPasswordSucceed(response))
                dispatch(Notify.newUserModal("Password has been updated successfully!", true))
            }else{
                dispatch(Notify.newUserModal("An error occured while updating password", false))
            }
        })
        .catch(function (error) {
            dispatch(onValidateResetTokenFailed(error))
            dispatch(Notify.newUserModal("An error occured while updating password", false))
        });
    }
}