import React, { Component } from 'react';
import { connect } from "react-redux";
import { compose } from 'redux';
import ClientMenuList from '../../config/clientMenu';

class Notfound extends Component {

    componentWillMount() {
        let pathname = this.props.location.pathname;
        let checkClientPath = false;
        let alias = this.props.hotelAuthReducer.hotelDetails.hotel.id;
        let clientAccPath = "/client/Calendar/" + alias;
        ClientMenuList.map((val, index) => {
            var toVal = val.to + '/' + alias;
            if (toVal === pathname) {
                return checkClientPath = true;
            }

            if (val.children && !checkClientPath) {
                val.children.map((res) => {
                    var childToVal = res.to + '/' + alias;
                    if (childToVal == pathname) {
                        return checkClientPath = true;
                    }
                })
            }
            
            if ('/client/Community' + '/' + alias === pathname) {
                return checkClientPath = true;
            }
        })
        if (!checkClientPath) {
            this.props.history.push(clientAccPath);
        }
    }
    render() {

        return (
            <div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        userSession: state.sessionReducer,
        hotelAuthReducer: state.hotelAuthReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Notfound);
