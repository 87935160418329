import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../index';
import Notify from '../Notify';
import { ValidateBaseSettings } from '../../../App/Utils/SettingValidation/Validation';

const getgenieIntelligenceStart = (data) => {
    return {
        type: actionTypes.GET_GENIEINTELLIGENCE_START
    }
}

const getgenieIntelligenceSuccess = (data) => {
    return {
        type: actionTypes.GET_GENIEINTELLIGENCE_SUCCESS,
        state: data
    }
}

const getgenieIntelligenceFailed = (error) => {
    return {
        type: actionTypes.GET_GENIEINTELLIGENCE_FAILED,
        error: error
    }
}

export const getGenieIntelligenceReport = (request) => {
    return dispatch => {
        dispatch(getgenieIntelligenceStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/get-genieintelligence-report/', request)
            .then((response) => {
                if(response.data) {
                    dispatch(getgenieIntelligenceSuccess(response.data))
                }
            })
            .catch(function (error) {
                dispatch(getgenieIntelligenceFailed(error))
            });
    }
}
