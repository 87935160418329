import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../index';
import Notify from "../Notify";

const getUserdetailSuccess = (data) => {
    return {
        type: actionTypes.GET_INVITE_USER_DETAIL,
        data: data
    }
}
export const getInviteUserDetails = (request) => {
    return dispatch => {
        axios.get(Server.API + 'account/verify-invited-user/?email_token=' + request.code)
        .then((response) => {
            if (response.data && response.data.success) {
                dispatch(getUserdetailSuccess(response.data.data))
            }else{
                dispatch(Notify.newUserModal("Your link is expired.", false))
            }
        })
        .catch((error) => {
            dispatch(Notify.newUserModal("Your link is expired.", false))
        });

    }
}

export const createInviteUser = (request, code) => {
    return dispatch => {
        axios.post(Server.API + 'account/create-invited-user/?email_token=' + code, request)
        .then((response) => {            
            dispatch(Notify.newUserModal("User Created Successfully.", true))
            setTimeout(()=>{
                window.location.href = "/";
            },2000)
        })
        .catch((error) => {
            
            dispatch(Notify.newUserModal("Error while creating user.", false))
        });

    }
}

