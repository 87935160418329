var style = {
  root: {
    display: "flex",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    borderRadius: "5px",
    minWidth: "150px",
    margin: "15px",
    background: "#5045451c",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    textAlign: "center",
  },
  content: {
    flex: "1 0 auto",
  },
  h5font: {
    fontSize: "22px",
    marginTop: "20px",
  },
  captionfont: {
    fontSize: "1rem",
  },
  boldFont: {
    fontWeight: "500",
  },
};
export default style;
