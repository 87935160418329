import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import Notify from '../Notify';

const geterrorTicketsListStart = (data) => {
    return {
        type: actionTypes.GET_ERROR_TICKETS_START
    }
}

const geterrorTicketsListSuccess = (data) => {
    return {
        type: actionTypes.GET_ERROR_TICKETS_SUCCESS,
        state: data
    }
}

const geterrorTicketsListFailed = (error) => {
    return {
        type: actionTypes.GET_ERROR_TICKETS_FAILED,
        error: error
    }
}

const getreservationErrorTicketsListSuccess = (data) => {
    return {
        type: actionTypes.GET_RESERVATION_ERROR_TICKETS_SUCCESS,
        state: data
    }
}

const getothersErrorTicketsListSuccess = (data) => {
    return {
        type: actionTypes.GET_OTHERS_ERROR_TICKETS_SUCCESS,
        state: data
    }
}

const getcriticalErrorTicketsListSuccess = (data) => {
    return {
        type: actionTypes.GET_CRITICAL_ERROR_TICKETS_SUCCESS,
        state: data
    }
}
const getTagsListStart = (data) => {
    return {
        type: actionTypes.GET_ERROR_TAGS_LIST_START
    }
}
const getTagsListSuccess = (data) => {
    return {
        type: actionTypes.GET_ERROR_TAGS_LIST_SUCCESS,
        state: data
    }
}
const getTagsListFailed = (error) => {
    return {
        type: actionTypes.GET_ERROR_TAGS_LIST_FAILED,
        error: error
    }
}

export const geterrorTicketsList = (request, error_tag_type) => {
    return dispatch => {
        dispatch(geterrorTicketsListStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        let url = (request.url ? (Server.API + request.url) : (Server.API + 'errors/error/?tags_type='+(request.tags_type)+'&ticket_status='+ ((request.ticket_status   !== '' && request.ticket_status  !== undefined) ? request.ticket_status  : 1) + ((request.page  !== '' && request.page  !== null && request.page  !== undefined) ? '&page=' + request.page : "") + ((request.developer !== '' && request.developer !== null && request.developer !== undefined) ? '&developer=' + request.developer : "") + ((request.pms !== '' && request.pms !== null && request.pms !== undefined) ? '&pms=' + request.pms : "") + ((request.tags !== '' && request.tags !== null && request.tags !== undefined) ? '&tags=' + request.tags : "")));
        axios.get(url)
            .then((response) => {
                if(error_tag_type == "dashboard"){
                    dispatch(getreservationErrorTicketsListSuccess(response.data))
                } else if(error_tag_type == "others"){
                    dispatch(getothersErrorTicketsListSuccess(response.data))
                } else if(error_tag_type == "critical"){
                    dispatch(getcriticalErrorTicketsListSuccess(response.data))
                } else {
                    dispatch(geterrorTicketsListSuccess(response.data))
                }
            })
            .catch(function (error) {
                dispatch(geterrorTicketsListFailed(error))
            });
    }
}

const updateSingleErrorTicketStart = (data) => {
    return {
        type: actionTypes.UPDATE_SINGLE_ERROR_TICKETS_START
    }
}

const updateSingleErrorTicketSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_SINGLE_ERROR_TICKETS_SUCCESS,
        state: data
    }
}

const updateSingleErrorTicketFailed = (error) => {
    return {
        type: actionTypes.UPDATE_SINGLE_ERROR_TICKETS_FAILED,
        error: error
    }
}

export const updateSingleErrorTicket = (request, request_reload, error_ticket_type) => {
    return dispatch => {
        dispatch(updateSingleErrorTicketStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.patch(Server.API + 'errors/error-ticket/' + request.id + '/', request)
            .then((response) => {
                dispatch(updateSingleErrorTicketSuccess(response.data))
                dispatch(getNewErrorTicketsList(request_reload, error_ticket_type))
                dispatch(Notify.newUserModal("Error Ticket Updated Successfully.", true));
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Error occured while updating Error Ticket", false))
                dispatch(updateSingleErrorTicketFailed(error))
            });
    }
}


const updateMultipleErrorTicketStart = (data) => {
    return {
        type: actionTypes.UPDATE_MULTIPLE_ERROR_TICKETS_START
    }
}

const updateMultipleErrorTicketSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_MULTIPLE_ERROR_TICKETS_SUCCESS,
        state: data
    }
}

const updateMultipleErrorTicketFailed = (error) => {
    return {
        type: actionTypes.UPDATE_MULTIPLE_ERROR_TICKETS_FAILED,
        error: error
    }
}

export const updateMultipleErrorTicket = (request, request_reload, error_ticket_type) => {
    console.log("FFFFFFFF12",request)
    return dispatch => {
        dispatch(updateMultipleErrorTicketStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.patch(Server.API + 'errors/error-ticket/update-multiple/', request)
            .then((response) => {
                dispatch(updateMultipleErrorTicketSuccess(response.data))
                dispatch(getNewErrorTicketsList(request_reload, error_ticket_type))
                dispatch(Notify.newUserModal("Error Ticket's Updated Successfully.", true));
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Error occured while updating Error Ticket's", false))
                dispatch(updateMultipleErrorTicketFailed(error))
            });
    }
}
export const getNewErrorTicketsList = (request, error_tag_type) => {
    console.log("FFFFFFFF",request)
    return dispatch => {
        dispatch(geterrorTicketsListStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        // let url = Server.API + request.url + '?' + request.ticket_type
        let url = (request.url ? (Server.API + request.url)
        :
        (Server.API + 'errors/error-ticket/?ticket_type='+ request.ticket_type + 
        // '&ticket_status=' + ((request.ticket_status   !== '' && request.ticket_status  !== undefined) ? request.ticket_status  : 1 ) + 
        ((request.page  !== '' && request.page  !== null && request.page  !== undefined) ? '&page=' + request.page : "") + 
        ((request.developer !== '' && request.developer !== null && request.developer !== undefined) ? '&developer=' + request.developer : "") + 
        ((request.pms !== '' && request.pms !== null && request.pms !== undefined) ? '&pms=' + request.pms : "") + 
        ((request.tags !== '' && request.tags !== null && request.tags !== undefined) ? '&ticket_tag=' + request.tags : "")));

        axios.get(url)
            .then((response) => {                
                if(error_tag_type == "dashboard"){
                    dispatch(getreservationErrorTicketsListSuccess(response.data))
                } else if(error_tag_type == 3 || error_tag_type == 2 ){
                    dispatch(getothersErrorTicketsListSuccess(response.data))
                } else if(error_tag_type ==1){
                    dispatch(getcriticalErrorTicketsListSuccess(response.data))
                } else {
                    dispatch(geterrorTicketsListSuccess(response.data))
                }           
            })
            .catch(function (error) {
                dispatch(geterrorTicketsListFailed(error))
            });
    }
}
export const getTagsList = (request) => {
    return dispatch => {
        dispatch(getTagsListStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;       
        let url = Server.API + 'errors/error-ticket/tag-list/?ticket_type='+ request.ticket_type;
        axios.get(url)
            .then((response) => {                     
                dispatch(getTagsListSuccess(response.data))
            })
            .catch(function (error) {
                dispatch(getTagsListFailed())
            });
    }
}