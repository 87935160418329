import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';

const onPmsListStart = () => {
    return {
        type: actionTypes.PMS_LIST_START
    }
}

const onPmsListFailed = (error) => {
    return {
        type: actionTypes.PMS_LIST_FAILED,
        error: error
    }
}

const onPmsListSucceed = (data) => {
    return {
        type: actionTypes.PMS_LIST_SUCCEED,
        state: data
    }
}

export const getPmsList = (request) => {
    return dispatch => {
        dispatch(onPmsListStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'hotel/pms/data/map/')
            .then(function (response) {
                dispatch(onPmsListSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onPmsListFailed(error))
            });
    }
}