import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../index';
import Notify from '../Notify';

// const onDefineRateListStart = () => {
//     return {
//         type: actionTypes.PREDEFINE_RATE_START
//     }
// }

// const onDefineRateListFailed = (error) => {
//     return {
//         type: actionTypes.PREDEFINE_RATE_FAILED,
//         error: error
//     }
// }

// const onDefineRateListSucceed = (data) => {
//     return {
//         type: actionTypes.PREDEFINE_RATE_SUCCEED,
//         state: data
//     }
// }

export const defineRateList = (request) => {
    return dispatch => {
        // dispatch(onDefineRateListStart())
        // axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        // axios.get(Server.API + 'hotel/predefined-rate/list/')
        //     .then(function (response) {
        //         dispatch(onDefineRateListSucceed(response.data))
        //     })
        //     .catch(function (error) {
        //         dispatch(onDefineRateListFailed(error))                
        //     });
    }
}

// const onDefineRateCreateStart = () => {
//     return {
//         type: actionTypes.PREDEFINE_RATE_CREATE_START
//     }
// }

// const onDefineRateCreateFailed = (error) => {
//     return {
//         type: actionTypes.PREDEFINE_RATE_CREATE_FAILED,
//         error: error
//     }
// }

// const onDefineRateCreateSucceed = (data) => {
//     return {
//         type: actionTypes.PREDEFINE_RATE_CREATE_SUCCEED,
//         state: data
//     }
// }

export const defineRateCreate = (request) => {
    return dispatch => {
        // let requestParam = { name: request.name, price: request.price }
        // dispatch(onDefineRateCreateStart())
        // axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        // axios.post(Server.API + 'hotel/predefined-rate/register/', requestParam)
        //     .then(function (response) {
                dispatch(actions.updateSettingJson(request, [], 'createRate'))
                // dispatch(actions.defineRateList(request))
            //     dispatch(onDefineRateCreateSucceed(response.data))
            //     dispatch(Notify.newUserModal("Rate created successfully", true))
            // })
            // .catch(function (error) {
            //     dispatch(onDefineRateCreateFailed(error))
            //     dispatch(Notify.newUserModal("An error occured while creating Rate", false))
            // });
    }
}

// const onUpdateDefineRateStart = () => {
//     return {
//         type: actionTypes.PREDEFINE_RATE_UPDATE_START
//     }
// }

// const onUpdateDefineRateFailed = (error) => {
//     return {
//         type: actionTypes.PREDEFINE_RATE_UPDATE_FAILED,
//         error: error
//     }
// }

// const onUpdateDefineRateSucceed = (data) => {
//     return {
//         type: actionTypes.PREDEFINE_RATE_UPDATE_SUCCEED,
//         state: data
//     }
// }

export const updateDefineRate = (request) => {
    return dispatch => {
        // let requestParam = { name: request.name, price: request.price };
        // dispatch(onUpdateDefineRateStart())
        // axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        // axios.patch(Server.API + 'hotel/predefined-rate/update/' + request.id + '/', requestParam)
        //     .then(function (response) {
                dispatch(actions.updateSettingJson(request, [], 'updateRate'))
                // dispatch(actions.defineRateList(request))
            //     dispatch(onUpdateDefineRateSucceed(response.data))
            //     dispatch(Notify.newUserModal("Rate updated successfully", true))
            // })
            // .catch(function (error) {
            //     dispatch(onUpdateDefineRateFailed(error))
            //     dispatch(Notify.newUserModal("An error occured while updating Rate", false))
            // });
    }
}



// const onDeleteDefineRateStart = () => {
//     return {
//         type: actionTypes.PREDEFINE_RATE_DELETE_START
//     }
// }

// const onDeleteDefineRateFailed = (error) => {
//     return {
//         type: actionTypes.PREDEFINE_RATE_DELETE_FAILED,
//         error: error
//     }
// }

// const onDeleteDefineRateSucceed = (data) => {
//     return {
//         type: actionTypes.PREDEFINE_RATE_DELETE_SUCCEED,
//         state: data
//     }
// }

export const deleteDefineRate = (request) => {
    return dispatch => {
        // let requestParam = { name: request.name, price: request.price };
        // dispatch(onDeleteDefineRateStart())
        // axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        // axios.delete(Server.API + 'hotel/predefined-rate/delete/' + request.id + '/', requestParam)
        //     .then(function (response) {
                dispatch(actions.updateSettingJson(request, [], 'deleteRate'))
                // dispatch(actions.defineRateList(request))
            //     dispatch(onDeleteDefineRateSucceed(response.data))
            //     dispatch(Notify.newUserModal("Rate deleted successfully", true))
            // })
            // .catch(function (error) {
            //     dispatch(onDeleteDefineRateFailed(error))
            //      dispatch(Notify.newUserModal("An error occured while deleting Rate", false))
            // });
    }
}


// const onDefineRateMoveupStart = () => {
//     return {
//         type: actionTypes.PREDEFINE_RATE_MOVEUP_START
//     }
// }

// const onDefineRateMoveupFailed = (error) => {
//     return {
//         type: actionTypes.PREDEFINE_RATE_MOVEUP_FAILED,
//         error: error
//     }
// }

// const onDefineRateMoveupSucceed = (data) => {
//     return {
//         type: actionTypes.PREDEFINE_RATE_MOVEUP_SUCCEED,
//         state: data
//     }
// }

// export const defineRateMoveup = (request) => {
//     return dispatch => {
//         dispatch(onDefineRateMoveupStart())
//         axios.defaults.headers.common['Authorization'] = "Token " + request.token;
//         axios.post(Server.API + 'hotel/predefined-rate/move-up/' + request.id + '/')
//             .then(function (response) {
//                 // dispatch(actions.defineRateList(request))
//                 dispatch(onDefineRateMoveupSucceed(response.data))
//             })
//             .catch(function (error) {
//                 dispatch(onDefineRateMoveupFailed(error))
//             });
//     }
// }


// const onDefineRateMovedownStart = () => {
//     return {
//         type: actionTypes.PREDEFINE_RATE_MOVEDOWN_START
//     }
// }

// const onDefineRateMovedownFailed = (error) => {
//     return {
//         type: actionTypes.PREDEFINE_RATE_MOVEDOWN_FAILED,
//         error: error
//     }
// }

// const onDefineRateMovedownSucceed = (data) => {
//     return {
//         type: actionTypes.PREDEFINE_RATE_MOVEDOWN_SUCCEED,
//         state: data
//     }
// }

// export const defineRateMovedown = (request) => {
//     return dispatch => {
//         dispatch(onDefineRateMovedownStart())
//         axios.defaults.headers.common['Authorization'] = "Token " + request.token;
//         axios.post(Server.API + 'hotel/predefined-rate/move-down/' + request.id + '/')
//             .then(function (response) {
//                 // dispatch(actions.defineRateList(request))
//                 dispatch(onDefineRateMovedownSucceed(response.data))
//             })
//             .catch(function (error) {
//                 dispatch(onDefineRateMovedownFailed(error))
//             });
//     }
// }


