import { red } from "@material-ui/core/colors";

var style = {
    dangerText: {
        color: red,
    },
    addUserBtn: {
        float: 'right',
        margin: '2%'
    },
    currentPriceInput: {
        margin: '0 2% 0 0',
        width: '46%',
        ['@media (max-width: 540px) and (min-width: 400px)']: {
            minWidth: '250px'
        },
        ['@media (max-width: 399px) and (min-width: 361px)']: {
            minWidth: '220px'
        },
        ['@media (max-width: 360px) and (min-width: 321px)']: {
            minWidth: '200px'
        },
        ['@media (max-width: 320px)']: {
            minWidth: '170px'
        }
    },
    addMemberBtn: {
        float: 'left',
        display: 'flow-root',
        margin:"8px 2px"
    },
    cardMargin: {
        marginTop: "35px"
    },
    divStyle: {
        float: "left", 
        marginTop: "2px"
    },
    fontStyle: {
        textAlign: "left", 
        fontWeight: "500"
    },
    cardRoot: {
        display:"flex"
    }
}
export default style;