var style = {
	root: {
	  flexGrow: 1,
	},
	RadioGroup: {
	  display: 'inline-block',
	},
	emailLabel: {
	  marginTop: '20px'
	},
	userTable: {
	  padding: '20px'
	},
	addUserBtn: {
	  float: 'right'
	},
	CarHeader: {
	  textAlign: 'left'
	},
	root: {
	  flexGrow: 1,
	},
	dateTableDiv: {
	  overflow: 'auto'
	},
	MainLoader: {
	  position: 'fixed',
	  height: '100%',
	  width: '100%',
	  background: 'rgb(15, 51, 80, 0.3)',
	  zIndex: '9',
	},
	loader: {
	  position: 'absolute',
	  top: '35%',
	  left: '35%'
	},
	textField: {
	  float: "right",
	  minWidth: '50px',
	  width: '100%',
	  '&::before': {
		border: 'none'
	  },
	  '& input': {
		textAlign: 'right'
	  }
	},
	textFieldBg: {
	  minWidth: '80px',
	  width: '100%',
	  '&::before': {
		border: 'none'
	  },
	  background: '#f5f5f5',
	  '& input': {
		textAlign: 'center'
	  }
	},
	tableCellPadding: {
	  padding: '4px 15px 4px 15px',
	},
	rowTable: {
	  height: "30px"
	},
	fieldMargin: {
	  marginTop: '5px !important',
	  marginBottom: '5px !important',
	  marginLeft: '5px !important',
	},
	cellWidth: {
	  maxWidth: '100px',
	  padding: '0px'
	},
	weekCellWidth: {
	  maxWidth: '100px'
	},
	fullWidth: {
	  width: '100%'
	},
	actionIcon: {
	  padding: '0',
	  cursor: 'pointer',
	  minWidth: '30px'
	},
	actionCell: {
	  padding: '10px 15px 10px 35px'
	},
	adornment: {
	  color: 'rgb(118, 118, 118)',
	},
	tableHeading: {
	  textAlign: 'left'
	},
	subTitle: {
	  textAlign: 'left',
	  margin: '10px 15px'
	},
	warning: {
	  display: 'flex',
	  textAlign: 'left',
	  margin: '0px 0px 15px 12px',
	  padding: '12px',
	  color: 'inherit',
	  background: '#F8E9C3',
	  borderRadius: '5px',
	  width: 'fit-content'
	},
	warningText: {
	  margin: '4px 10px'
	},
	adornment: {
	  color: 'rgb(118, 118, 118)',
	},
	radioFormControl: {
	  minHeight: "80px",
	  '@media screen and (min-width: 1000px)': {
		paddingTop: '16px',
		marginBottom: '-10px',
		float: 'right',
		width: '370px',
		margin: 15,
	  },
	  ['@media (max-width: 414px) and (min-width: 360px)']: {
		marginLeft: "20px"
	  }
	},
	smartSettingTooltip: {
	  marginLeft: 10,
	  position: 'absolute',
	  ['@media (max-width: 540px) and (min-width: 400px)']: {
		position: 'inherit'
	  },
	  ['@media (max-width: 399px) and (min-width: 361px)']: {
		position: 'inherit'
	  },
	  ['@media (max-width: 360px) and (min-width: 321px)']: {
		position: 'inherit'
	  },
	  ['@media (max-width: 320px)']: {
		position: 'inherit'
	  }
	},
	cardMargin: {
	  marginTop: 20
	},
	smartSettingSwitch: {
	  '@media screen and (min-width: 1000px)': {
		marginTop: 15,
		marginBottom: 15
	  }
	},
	switchFormControl: {
	  float: 'left',
	  marginTop: '-40px',
	  '@media screen and (min-width: 1000px)': {
		marginTop: '-80px',
	  },
	},
	formControl: {
	  width: '46%',
	  marginLeft: '2%',
	  marginRight: '2%',
	  ['@media (max-width: 540px) and (min-width: 400px)']: {
		width: '98%',
		marginLeft: '1%',
		marginRight: '1%',
	  },
	  ['@media (max-width: 399px) and (min-width: 361px)']: {
		width: '98%',
		marginLeft: '1%',
		marginRight: '1%',
	  },
	  ['@media (max-width: 360px) and (min-width: 321px)']: {
		width: '98%',
		marginLeft: '1%',
		marginRight: '1%',
	  },
	  ['@media (max-width: 320px)']: {
		width: '98%',
		marginLeft: '1%',
		marginRight: '1%',
	  }
	},
	absoluteCard: {
	  '@media screen and (min-width: 1000px)': {
		marginTop: "-80px"
	  },
	  marginBottom: "80px"
	},
	switchFrom: {
	  '@media screen and (max-width: 800px)': {
		float: 'left',
		marginLeft: '26px'
	  },
	  '@media screen and (min-width: 1000px)': {
		paddingTop: '16px',
		marginBottom: '-10px',
		float: 'right',
	  },
	},
	pmsPrice: {
	  marginTop: '37px',
	  float: 'right',
	  ['@media (max-width: 414px) and (min-width: 360px)']: {
		float: 'left',
		marginLeft: '30px'
	  }
	},
	dailyTableData: {
	  maxWidth: "1300px"
	},
	tableContentSkip: {
	  marginTop: "-100px"
	},
	settingFields: {
	  float: "left",
	  width: "40%",
	  marginRight: "5%",
	  marginBottom: "20px",
	  marginTop: "10px"
	},
	settingFieldsTextArea: {
	  width: "85%",	
	  float: "left",
	  marginRight: "5%",
	  marginBottom: "20px",
	  marginTop: "20px"
	},
	questionDiv: {
	  margin: "24px 0px 24px",
	  fontSize:"1rem"
	},
	adjustmentTextfield: {
	  float: "left",
	  marginTop: "20px",
	  width: "100%"
	},
	quickText: {
	  width: "205px",
	  marginTop:"23px"
	},
	quickText: {
	  width: "60%",
	  marginTop:"23px"
	},
	queTag: {
	  marginBottom: "12px"
	},
	buttonProgress: {
	  position: "absolute"
	},
	olAlign: {
	  paddingLeft: "18px",
	  margin: "0px"
	},
	imgRadioGroup: {
	  flexDirection: "row",
	  marginBottom: "10px"
	},
	imgRadioLabel: {
	  display: "-webkit-box",
	  textAlign: "left"
	},
	imgRadioSpan: {
	  display: "inline-flex",
	  flexDirection: "column",
	  padding: "10px",
	  marginLeft:"6px",
	  width: "145px",
	  textAlign: "center",
	  fontSize: "16px",
	  border: "1px solid #e7e7e7",
	  height:"160px",
	  fontSize:"1rem",
	  borderRadius:"5px"
	},
	imgRadioSpanActive: {
	  display: "inline-flex",
	  flexDirection: "column",
	  padding: "10px",
	  marginLeft:"6px",
	  width: "145px",
	  textAlign: "center",
	  fontSize: "16px",
	  border: "1px solid #3772ff",
	  height:"160px",
	  fontSize:"1rem",
	  borderRadius:"5px"
	},
	imgRadioImg: {
	  width: "70px",
	  height: "70px",
	  margin:"20px"
	},
	halfSlideImg: {
	  width: "100%",
	  marginTop: "48px"
	},
	twolineCheckbox: {
	  display: "block",
	  paddingTop: "10px",
	  textAlign: "left",
	},
	radioLabel: {
	  height: "40px",
	  border: "1px solid #e7e7e7",
	  margin: "5px 0px",
	  padding: "10px",
	  borderRadius:"5px"
	},
	radioLabelActive: {
	  height: "40px",
	  border: "1px solid #3772ff",
	  margin: "5px 0px",
	  padding: "10px",
	  borderRadius:"5px"
	},
	questionDivText: {
	  margin: "24px 0px 24px",
	  display: "flex",
	  flexDirection: "column",
	  alignItems: "center",
	  fontSize:"1rem"
	},
	ActionBtns:{
	  marginTop:"30px"
	},
	helpText:{
	  maxWidth: "950px", 
	  textAlign: "justify",
	  margin: "25px 0px 25px 0px",
		color:"#707070"
	},
	helpText2:{
	  maxWidth: "950px", 
	  textAlign: "justify",
	  color:'black',
		color:"#707070"
	},
	leadtimeTitle:{
	  marginBottom:"24px"
	},
	saveBtn: {
	  margin: "0px 0px 27px 0px",
	  float: 'left',
	  height: "fit-content",
	  ['@media (max-width: 414px) and (min-width: 360px)']: {
	  }
	},
	referralForm: {
		marginBottom: "20px"
	},
	titleText: {
		fontSize: "20px",
		fontWeight: "500",
	},
	root: {
		width: '100%',
	},
	listStyle:{
		listStyle: "disc",
		marginLeft: "40px"
	},
	listtStyle: {
		display: "list-item"
	},
	inviteBtn: {
		float: "left",
		marginTop: "0%"
	},
	gridPadding:{
		marginLeft:'55px'
	},
	arraowIcon:{
		color:'#c0c0c0'
	},
	subtext:{
		display:'flex',
		padding:'7px'
	},
	closeButtonPrice: {
		position:'absolute',
		right:'0',
		['@media (max-width: 540px)']: {
		  marginTop: "-88px",
		}
	},
	referralDeal: {
		width: '100%',
		'@media screen and (min-width: 1000px)': {
			width: '40%',
		},
	},
	referralDealDetail: {
		border: '2.5px #d8d8d8 solid',
		marginTop: '25px',
		borderRadius: '15px',
	},
	referralFriend: {
		marginTop: '2%',
		marginLeft: '7%',
		marginBottom: '3%',
		color: '#000',
	},
	
	alternativeLabel: {
		top: 22,
	},
	active: {
		'& $line': {
			backgroundImage:
			'linear-gradient( 136deg, rgb(16 24 64) 25%, rgb(16 24 64) 50%)',
		},
		'& $iconContainer': {
			backgroundColor: 'red',
		}
	},
	completed: {
		'& $line': {
			backgroundImage:
			'linear-gradient( 136deg, rgb(16 24 64) 25%, rgb(16 24 64) 50%)',
		},
		'& $iconContainer': {
			backgroundColor: 'red',
		}
	},
	line: {
		height: 4,
		border: 0,
		backgroundColor: '#eaeaf0',
		borderRadius: 1,
	},

	root1: {
		backgroundColor: '#ccc',
		zIndex: 1,
		color: '#fff',
		width: 50,
		height: 50,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	active1: {
		backgroundImage:
			'linear-gradient( 136deg, rgb(16 24 64) 25%, rgb(16 24 64) 50%)',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	},
	completed1: {
		backgroundImage:
			'linear-gradient( 136deg, rgb(16 24 64) 25%, rgb(16 24 64) 50%)',
	},
	activeIconTab: {
		'& > span:first-child': {
			backgroundImage: 'linear-gradient( 136deg, rgb(16 24 64) 25%, rgb(16 24 64) 50%)'
		}
	},
}
export default style;
