import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import freeTrialBlockCss from './css/freeTrialBlockCss';
import { withNamespaces } from 'react-i18next';

const styles = freeTrialBlockCss;

class FreetrialRecords extends React.Component {
  render() {
    const { classes, t } = this.props;
    return (
      <Card className={classes.root}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="subtitle1" variant="subtitle1" align={"left"}>
              # of Trial Clients
          </Typography>
            <Typography component="h5" variant="h5" className={classes.h5font} align={"left"}>
              {this.props.free_trial_count}
            </Typography>
            <Typography variant="caption" color="caption" align={"left"}>
              <span className={classes.boldFont}>+ {this.props.free_trial_last_one_week_count} </span> In Last 7 Days
          </Typography>
          </CardContent>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userSession: state.sessionReducer,
    hotelAuthReducer: state.hotelAuthReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default compose(withStyles(styles, {
  withTheme: true
}), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(FreetrialRecords));
