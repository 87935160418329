import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../../../store/actions/index';
import Notify from '../Notify';
import { ValidateBaseSettings } from '../../../../src/App/Utils/SettingValidation/Validation';
import moment from 'moment';

export const updatePricePms = (request) => {
    return dispatch => {
        let requestParams = {
            date: request.date,
            room: request.room,
            price: request.price,
        };
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'pricingalgorithm/price/update/', requestParams)
            .then(function (response) {
            })
    }
}

const successPrice = (data) => {
    return {
        type: actionTypes.GET_TABLE_SETTING,
        data: data
    }
}

export const getPricingSettings = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'hotel/pricing-settings/')
            .then(function (response) {
                dispatch(successPrice(response.data));
                dispatch(actions.getRunPriceFlags(request));
            })
            .catch(function (error) {

            });
    }
}

const changePricingSchedule = (request) => {

    axios.get(Server.API + 'pricingalgorithm/algo-program/list/')
        .then(function (response) {
            if (response.data.results) {
                let nightData = response.data.results.filter(val => val.name == "night run");
                if (nightData.length != 0) {
                    nightData[0].type = request ? 8 : 7;
                    axios.patch(Server.API + 'pricingalgorithm/algo-program/update/' + nightData[0].id + '/', nightData[0])
                        .then(function (response) {
                        })
                }
            }

        })
        .catch(function (error) {

        });
}

const bulkUpdateList = (request, dispatch) => {
    let dates = [];

    let callApplyApi = false;

    request.bulkUpdateList.deleteIndividual.map((val) => {
        if (!dates.includes(val)) {
            dates.push(val)
        }
    })
    request.bulkUpdateList.deleteSkip.map((val) => {
        callApplyApi = true;
        if (!dates.includes(val)) {
            dates.push(val)
        }
    })
    request.bulkUpdateList.individual.map((val) => {
        if (!dates.includes(val.date)) {
            dates.push(val.date)
        }
    })
    request.bulkUpdateList.skipDate.map((val) => {
        callApplyApi = true;
        if (!dates.includes(val.date)) {
            dates.push(val.date)
        }
    })

    if (callApplyApi) {
        let requestParam = { dates: JSON.stringify(dates) };
        axios.post(Server.API + 'hotel/skip-pricing/apply/', requestParam)
    }

}

export const updatePricingSettings = (request, type) => {
    return dispatch => {
        let latestJson = ValidateBaseSettings(request);

        let reqParams = {
            settings: JSON.stringify(latestJson.dateSetting),
        };
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/pricing-settings/', reqParams)
            .then(function (response) {
                if (type && type == "yelding") {
                    dispatch(Notify.newUserModal("Yielding tag Updated successfully", true))
                } else {
                    dispatch(Notify.newUserModal("Settings Updated Successfully", true))
                }
                dispatch(actions.getPricingSettings(request))
                dispatch(bulkUpdateList(request, dispatch))
            })
            .catch(function (error) {
            });
    }
}


export const updateSettingJson = (request, response, updateType, callback, callbackRequest) => {

    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'hotel/pricing-settings/')
            .then(function (res) {
                let jsonData = res.data;

                if (updateType == 'pubpWeight') {
                    jsonData.rpg_arguments.pubp_today_weight = request.value;
                    jsonData.rpg_arguments.pubp_365_day_weight = request.value;
                }

                if (updateType == 'airDnaWeight') {
                    jsonData.model_weights = request.value;

                    // let flagReq = {
                    //     run_pricing: true,
                    //     token: request.token
                    // }
                    
                    // dispatch(actions.setRunPriceFlags(flagReq))
                }                

                if (updateType == 'skipCreate' || updateType == 'skipUpdate') {
                    if (!jsonData.dates[response.date]) {
                        jsonData.dates[response.date] = {};
                    }
                    jsonData.dates[response.date].skip_date = {
                        fixed_price: response.fixed_price,
                        name: response.name
                    }
                }

                if (updateType == 'skipDelete') {
                    delete jsonData.dates[request.date].skip_date
                }

                if (updateType == 'adjustCreate' || updateType == 'adjustUpdate') {
                    if (!jsonData.dates[response.date]) {
                        jsonData.dates[response.date] = {};
                    }
                    jsonData.dates[response.date].individual_adjustment = {
                        name: response.name,
                        percentage: response.percentage
                    }
                }

                if (updateType == 'adjustDelete') {
                    delete jsonData.dates[request.date].individual_adjustment
                }

                // min value reset all min price for all Date
                if(updateType == 'roomUpdate' && request.is_changed_min != null && request.is_changed_min_type){
                    let room_id = request.is_changed_min;
                    Object.keys(jsonData.dates).map(date => {
                        if(jsonData.dates[date][room_id] != undefined){
                            let obj = null;
                            if(jsonData.dates[date][room_id].min_price != undefined ){
                                obj = jsonData.dates[date][room_id];
                                obj.hasOwnProperty('min_price') // true
                                delete obj['min_price']
                            }else{
                                obj = jsonData.dates[date][room_id];
                            }  
                            if(Object.keys(obj).length > 0){
                                jsonData.dates[date][room_id] = obj; 
                            }else{                                
                               let objRoom = jsonData.dates[date];
                                objRoom.hasOwnProperty(room_id) // true
                                delete objRoom[room_id];                                
                                jsonData.dates[date] = objRoom;                        
                            }                                                                               
                        }                        
                    }) 
                }
                // max value reset all max price for all Date
                if(updateType == 'roomUpdate' && request.is_changed_max != null && request.is_changed_max_type ){
                    let room_id = request.is_changed_max;
                    Object.keys(jsonData.dates).map(date => {
                        if(jsonData.dates[date][room_id] != undefined){
                            let obj = null;
                            if(jsonData.dates[date][room_id].max_price != undefined ){
                                obj = jsonData.dates[date][room_id];
                                obj.hasOwnProperty('max_price') // true
                                delete obj['max_price']
                            }else{
                                obj = jsonData.dates[date][room_id];
                            }  
                            if(Object.keys(obj).length > 0){
                                jsonData.dates[date][room_id] = obj; 
                            }else{                                
                               let objRoom = jsonData.dates[date];
                                objRoom.hasOwnProperty(room_id) // true
                                delete objRoom[room_id];                                
                                jsonData.dates[date] = objRoom;                        
                            }                                                                               
                        }                        
                    }) 
                }

                if (updateType == 'roomUpdate' || updateType == 'createRoom') {

                    jsonData.default[response.id] = {
                        adjustment_to_reference_room: Math.round(request.handleRate),
                        avg_price: Math.round(request.avg_price),
                        max_price: request.max_price ? Math.round(request.max_price) : 9999,
                        min_price: request.min_price ? Math.round(request.min_price) : 0,
                    }

                    if (response.is_reference_room) {
                        jsonData.rooms.reference = {
                            id: parseFloat(response.id),
                            category_tag: null,
                            max_occupancy: request.max_occupancy ? parseFloat(request.max_occupancy) : 2,
                            name: response.name,
                            number_of_rooms: request.number_of_rooms,
                            virtual_room_type:request.virtual_room_type,
                            variable_cost_per_room: request.variable_cost_per_room ? parseFloat(request.variable_cost_per_room) : 0,
                        }

                        if (request.hotelData) {
                            jsonData.hotel.round_price_setting = request.hotelData.round_price_setting
                        }

                        if (request.hotelData && request.hotelData.adjustment_to_reference_room_is_locked) {
                            let handleRate = request.handleRate;
                            let avg_price = request.avg_price;

                            Object.keys(jsonData.default).map(key => {
                                if (response.id != key) {
                                    handleRate = jsonData.default[key].adjustment_to_reference_room;
                                    if (jsonData.hotel.adjustment_to_reference_room_is_absolute) {
                                        jsonData.default[key] = {
                                            ...jsonData.default[key],
                                            avg_price: Math.round(avg_price + handleRate)
                                        }
                                    } else {
                                        jsonData.default[key] = {
                                            ...jsonData.default[key],
                                            avg_price: Math.round(avg_price + ((avg_price * handleRate) / 100))
                                        }
                                    }
                                }
                            })
                        } else {
                            let avg_price = request.avg_price;

                            Object.keys(jsonData.default).map(key => {
                                if (response.id != key) {
                                    if (jsonData.hotel.adjustment_to_reference_room_is_absolute) {
                                        jsonData.default[key] = {
                                            ...jsonData.default[key],
                                            adjustment_to_reference_room: Math.round((jsonData.default[key].avg_price - avg_price)),
                                        }
                                    } else {
                                        jsonData.default[key] = {
                                            ...jsonData.default[key],
                                            adjustment_to_reference_room: Math.round(((jsonData.default[key].avg_price * 100) / avg_price) - 100)
                                        }
                                    }
                                }
                            })
                        }
                    } else {
                        jsonData.rooms.derived[response.id] = {
                            category_tag: null,
                            max_occupancy: request.max_occupancy ? parseFloat(request.max_occupancy) : 2,
                            name: response.name,
                            number_of_rooms: request.number_of_rooms,
                            virtual_room_type:request.virtual_room_type,
                            variable_cost_per_room: request.variable_cost_per_room ? parseFloat(request.variable_cost_per_room) : 0,
                        }
                    }
                    if (request.hotelData) {
                        jsonData.hotel = {
                            ...jsonData.hotel,
                            all_room_pricing: request.hotelData.all_room_pricing,
                            adjustment_to_reference_room_is_locked: request.hotelData.adjustment_to_reference_room_is_locked
                        }
                    }




                    // if (request.fetch_inventary || updateType == 'createRoom') {
                    //     jsonData.fetch_inventary = true;
                    // }
                }


                // if (updateType == "updateRefRoomQuickSetup") {

                //     // jsonData.default[request.id] = {
                //     //     adjustment_to_reference_room: 0,
                //     //     avg_price: parseFloat(request.avg_price),
                //     //     max_price: request.max_price ? parseFloat(request.max_price) : 9999,
                //     //     min_price: request.min_price ? parseFloat(request.min_price) : 0,
                //     // }

                //     jsonData.rooms.reference.variable_cost_per_room = parseFloat(request.variable_cost_per_room)
                // }


                if (updateType == "deleteRoom") {
                    delete jsonData.rooms.derived[request.id];
                    delete jsonData.default[request.id];
                }

                if (updateType == "deleteDeadRoom") {
                    for (var room_id in jsonData.rooms.derived) {
                        let room_object_found = response.filter(c_r => c_r.id == room_id);
                        if (room_object_found.length === 0) {
                            delete jsonData.rooms.derived[room_id];
                            delete jsonData.default[room_id];
                        }
                    }
                }


                if (updateType == "featureFlagEnable" || updateType == "featureFlagDisable") {

                    if (jsonData.features != request.features && (jsonData.features.includes(23) || request.features.includes(23))) {
                        if (jsonData.features.includes(23) && !request.features.includes(23)) {
                            changePricingSchedule(false)
                        } else if (!jsonData.features.includes(23) && request.features.includes(23)) {
                            changePricingSchedule(true)
                        }
                    }



                    jsonData.features = request.features;
                    jsonData.genieIntelligence_email_date = request.genieIntelligence_email_date;

                    if (request.features.includes(21) && !jsonData.rooms_sold_to_groups) {
                        jsonData.rooms_sold_to_groups = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 }
                    }

                    if (request.is18months) {
                        if (!jsonData.after_365_daily_averages) {
                            jsonData.after_365_daily_averages = {};
                        }
                        jsonData.after_365_daily_averages.monthly_averages = request.monthly_averages;
                    }

                }

                if (updateType == "updateNumberOfRoom") {
                    jsonData.hotel = {
                        ...jsonData.hotel,
                        number_of_rooms: request.number_of_rooms,
                        adjustment_to_reference_room_is_absolute: request.absoluteAdjustment,
                    }
                    let defaultData = {}
                    Object.keys(jsonData.default).map(key => {
                        if (request.absoluteAdjustment) {
                            defaultData[key] = {
                                ...jsonData.default[key],
                                adjustment_to_reference_room: Math.round(jsonData.default[key].avg_price - request.refRoomAverage)
                            }
                        } else {
                            defaultData[key] = {
                                ...jsonData.default[key],
                                adjustment_to_reference_room: Math.round((jsonData.default[key].avg_price * 100) / request.refRoomAverage) - 100
                            }
                        }
                    })
                    jsonData.default = defaultData;
                }


                if (updateType == "updateNumberOfRoomOnly") {
                    jsonData.hotel = {
                        ...jsonData.hotel,
                        number_of_rooms: request.number_of_rooms,
                    }
                    if (request.PELL_weekday && request.PELL_weekend) {
                        jsonData.rpg_arguments.PELL_weekday = request.PELL_weekday;
                        jsonData.rpg_arguments.PELL_weekend = request.PELL_weekend;
                    }
                }


                if (updateType == "updateHotel") {
                    jsonData.hotel = {
                        ...jsonData.hotel,
                        // adjustment_to_reference_room_is_absolute: (typeof request.absoluteAdjustment !== 'undefined') ? request.absoluteAdjustment : jsonData.hotel.adjustment_to_reference_room_is_absolute,
                        // all_room_pricing: response.all_room_pricing,
                        id: response.id,
                        min_price: response.min_price,
                        // number_of_rooms: response.number_of_rooms,
                        predefined_rate: response.predefined_rate,
                        // target_occupancy: response.target_occupancy
                    }

                    if (request.adjustment_to_reference_room_is_locked != undefined) {
                        jsonData.hotel.adjustment_to_reference_room_is_locked = request.adjustment_to_reference_room_is_locked;
                    }

                    if (request.timezone != undefined) {
                        jsonData.hotel.timezone = request.timezone;
                    }
                }

                // if (updateType == "autoSetup") {

                //     jsonData.fetch_inventary = true;
                // }

                if (["deleteDeadRoom", "updateHotel", "roomUpdate", "createRoom", "deleteRoom", "roomUpdateAll", "updateHotel", "autoSetup"].includes(updateType)) {
                    let roomIds = Object.keys(jsonData.default);
                    if (jsonData.room_type_tags) {
                        Object.keys(jsonData.room_type_tags).map(key => {
                            if (jsonData.room_type_tags[key] && jsonData.room_type_tags[key].room_types) {
                                jsonData.room_type_tags[key].room_types = jsonData.room_type_tags[key].room_types.filter(row => roomIds.includes(row.toString()))
                            }
                        })
                    }
                }


                if (updateType == "monthlyAdjust") {
                    if (request.allData) {
                        jsonData.adjustment.monthly = request.allData;
                    }
                }

                if (updateType == "singleMonthlyAdjust") {
                    jsonData.adjustment.monthly[request.month] = {
                        standard: parseInt(request.standard),
                        hidden: 0
                    }
                }

                if (updateType == "weeklyAdjust") {
                    if (request.allData) {
                        jsonData.adjustment.weekday = request.allData;
                    }
                }

                if (updateType == "singleweeklyAdjust") {
                    jsonData.adjustment.weekday[request.day] = {
                        standard: parseInt(request.standard),
                        hidden: 0
                    }
                }


                if (updateType == "timeBookingUpdate") {
                    // jsonData.rpg_arguments.booking_prop = {
                    //     four_days_to_seven_days: request.four_days_to_seven_days,
                    //     four_weeks_to_six_weeks: request.four_weeks_to_six_weeks,
                    //     last_day: request.last_day,
                    //     one_half_months_to_three_months: request.one_half_months_to_three_months,
                    //     one_week_to_two_weeks: request.one_week_to_two_weeks,
                    //     three_months_plus: request.three_months_plus,
                    //     two_days_to_three_days: request.two_days_to_three_days,
                    //     two_weeks_to_four_weeks: request.two_weeks_to_four_weeks,
                    // }

                    jsonData.rpg_arguments.PELL_weekday = request.dataModal.elasticity_weekdays;
                    jsonData.rpg_arguments.PELL_weekend = request.dataModal.elasticity_weekend;
                    jsonData.rpg_arguments.median_lead_time = request.median_lead_time;
                    jsonData.rpg_arguments.do_not_reduce_last_day = request.do_not_reduce_last_day;

                    if (request.lead_time) {
                        jsonData.adjustment.lead_time = {
                            four_days_to_seven_days: parseFloat(request.lead_time.four_days_to_seven_days),
                            four_weeks_to_six_weeks: parseFloat(request.lead_time.four_weeks_to_six_weeks),
                            last_day: parseFloat(request.lead_time.last_day),
                            one_half_months_to_three_months: parseFloat(request.lead_time.one_half_months_to_three_months),
                            one_week_to_two_weeks: parseFloat(request.lead_time.one_week_to_two_weeks),
                            three_months_to_six_months: parseFloat(request.lead_time.three_months_to_six_months),
                            six_months_plus: parseFloat(request.lead_time.six_months_plus),
                            two_days_to_three_days: parseFloat(request.lead_time.two_days_to_three_days),
                            two_weeks_to_four_weeks: parseFloat(request.lead_time.two_weeks_to_four_weeks),
                        }
                    }
                }


                if (updateType == "createRate") {
                    jsonData.predefined_rates = request.rates
                }


                if (updateType == "targetOccupancy") {
                    jsonData.monthly_target_occupancy = request.monthly_target_occupancy;
                    if (request.target_occupancy != undefined) {
                        jsonData.hotel.target_occupancy = request.target_occupancy;
                    }
                }
                
                if (updateType == "roomUpdate" && request.isOccupancyChanged) {
                    Object.keys(jsonData.dates).map(date => {
                        Object.keys(jsonData.dates[date]).map(roomKey => {
                            if(request.id == roomKey && jsonData.dates[date][roomKey].occupancy_pricing != null){
                                delete jsonData.dates[date][roomKey].occupancy_pricing
                                if(Object.keys(jsonData.dates[date][roomKey]).length === 0){
                                    delete jsonData.dates[date][roomKey]
                                }
                            }
                            if(Object.keys(jsonData.dates[date]).length === 0){
                                delete jsonData.dates[date][roomKey]
                            }
                        })
                    })
                }

                if(updateType == "deleteAllOccupancy"){
                    Object.keys(jsonData.dates).map(date => {
                        if(jsonData.dates[date] != undefined){
                            Object.keys(jsonData.dates[date]).map(roomKey => {
                            if(jsonData.dates[date][roomKey] && jsonData.dates[date][roomKey].occupancy_pricing){
                                
                                delete jsonData.dates[date][roomKey].occupancy_pricing            
                            }
                        })        
                        }
                    })                    
                }
                
                // if (["updateNumberOfRoom", "timeBookingUpdate", 'deleteRoom'].includes(updateType)) {
                //     // jsonData.run_pricing = true;
                //     let flagReq = {
                //         run_pricing: true,
                //         token: request.token
                //     }
                //     dispatch(actions.setRunPriceFlags(flagReq))
                // }

                // if (["roomUpdate"].includes(updateType)) {
                //     // jsonData.run_pricing = true;
                //     let flagReq = {
                //         run_pricing: true,
                //         token: request.token
                //     }
                //     if (request.fetch_inventary) {
                //         flagReq = {
                //             run_pricing: true,
                //             fetch_inventory: true,
                //             token: request.token
                //         }
                //     }

                //     dispatch(actions.setRunPriceFlags(flagReq))
                // }



                // if (["createRoom", "autoSetup"].includes(updateType)) {
                //     // jsonData.run_pricing = true;
                //     let flagReq = {
                //         run_pricing: true,
                //         fetch_inventory: true,
                //         token: request.token
                //     }
                //     dispatch(actions.setRunPriceFlags(flagReq))
                // }



                // if (["updateRunPrice"].includes(updateType)) {
                //     // jsonData.run_pricing = true;
                //     // jsonData.fetch_inventary = false;

                //     let flagReq = {
                //         run_pricing: true,
                //         fetch_inventory: false,
                //         token: request.token
                //     }
                //     dispatch(actions.setRunPriceFlags(flagReq))
                // }

                // if (["turnOffPricing", "updateNumberOfRoomOnly"].includes(updateType)) {
                //     // jsonData.run_pricing = false;
                //     // jsonData.fetch_inventary = false;
                //     let flagReq = {
                //         run_pricing: false,
                //         fetch_inventory: false,
                //         token: request.token
                //     }
                //     dispatch(actions.setRunPriceFlags(flagReq))
                // }


                let roomIds = [jsonData.rooms.reference.id];
                if(jsonData.rooms.derived){
                    Object.keys(jsonData.rooms.derived).map(roomKey => {
                        roomIds.push(parseInt(roomKey))
                    })
                }else{
                    jsonData.rooms.derived = {};
                }
                Object.keys(jsonData.default).map(roomKey => {
                    if (!roomIds.includes(parseInt(roomKey))) {
                        delete jsonData.default[roomKey]
                    }
                })
                // jsonData.adjustment.monthly.February =  {standard: 0, hidden: 0};

                // jsonData.run_pricing = false;
                //     jsonData.fetch_inventary = false;

                let latestJson = ValidateBaseSettings({ dateSetting: jsonData });

                let reqParams = {
                    settings: JSON.stringify(latestJson.dateSetting),
                };
                axios.defaults.headers.common['Authorization'] = "Token " + request.token;
                axios.post(Server.API + 'hotel/pricing-settings/', reqParams)
                    .then(function (resp) {
                        if (callback && callbackRequest) {
                            dispatch(actions[callback](
                                callbackRequest[0],
                                callbackRequest[1]
                            ));
                        }

                        if ((updateType == "autoSetup" || updateType == 'roomUpdate' || updateType == 'createRoom' || updateType == 'deleteRoom') && jsonData.features.includes(6)) {
                            axios.post(Server.API + 'hotel/room/derived/generate/max-min-price/')
                                .then(() => {
                                    if (updateType == "autoSetup") {
                                        axios.post(Server.API + 'hotel/calculate_no_of_rooms/', { is_calculate_no_of_rooms: true })
                                            .then(() => {
                                                dispatch(actions.getPricingSettings(request))
                                            })
                                    } else {
                                        dispatch(actions.getPricingSettings(request))
                                    }
                                })
                        } else if (updateType == "autoSetup") {
                            axios.post(Server.API + 'hotel/calculate_no_of_rooms/', { is_calculate_no_of_rooms: true })
                                .then(() => {
                                    dispatch(actions.getPricingSettings(request))
                                })
                        } else {
                            dispatch(actions.getPricingSettings(request))
                        }

                        if (["updateNumberOfRoom", "timeBookingUpdate", "targetOccupancy"].includes(updateType)) {
                            dispatch(Notify.newUserModal("Settings Updated Successfully", true))
                        }

                        if (["roomUpdate"].includes(updateType)) {
                            dispatch(Notify.newUserModal("Room Type updated successfully", true))
                        }

                        if (["pubpWeight", "airDnaWeight"].includes(updateType)) {
                            dispatch(Notify.newUserModal("Weight Updated Successfully", true))
                        }

                        if (["createRoom"].includes(updateType)) {
                            dispatch(Notify.newUserModal("New room added successfully", true))
                        }

                        if (["deleteRoom"].includes(updateType)) {
                            dispatch(Notify.newUserModal("Room Type Deleted Successfully", true))
                        }

                        if (["createRate"].includes(updateType)) {
                            dispatch(Notify.newUserModal("BAR Levels Updated Successfully", true))
                        }

                        if (["featureFlagDisable"].includes(updateType)) {
                            dispatch(Notify.newUserModal("Feature Updated successfully", true))
                        }
                        if (["deleteAllOccupancy"].includes(updateType)) {
                            dispatch(Notify.newUserModal("Removed all daily occupancy pricing values", true))
                        }


                        // if (updateType == "updateRefRoomQuickSetup") {
                        //     axios.post(Server.API + 'hotel/monthly-weekly-self-assessment-settings/', request.requestMonthly)
                        //     .then(()=>{
                        //         response.runPricing()
                        //     })
                        // }


                    })
                    .catch(function (error) {
                        if (["updateNumberOfRoom", "featureFlagDisable","deleteAllOccupancy"].includes(updateType)) {
                            dispatch(Notify.newUserModal("An error occured while updating Settings", false))
                        }
                    });
                ///////// Disabled Client access for freetobook
                if(request.disabled_client_access){ // if disable client access : true
                    axios.defaults.headers.common['Authorization'] = "Token " + request.token;
                    axios.get(Server.API + 'hotel/freetobook/auto-upload-disable-client-access/')
                        .then(function (resp) {

                        }).catch(function (error) {
                            dispatch(Notify.newUserModal("An error occured while disabled client access", false))
                    });
                }
            })
            .catch(function (error) {
                // debugger
            });
    }
}


export const setRunPriceFlags = (request, type) => {
    return dispatch => {

        // let reqParams = {
        //     fetch_inventory: request.fetch_inventory,
        //     run_pricing: request.run_pricing
        // };
        // axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        // axios.post(Server.API + 'pricingalgorithm/run-pricing-and-fetch-inventory-status/', reqParams)
        //     .then(function (response) {
        //         dispatch(actions.getRunPriceFlags(request))
        //     })
        //     .catch(function (error) {
        //     });
    }
}

const successRunFlag = (data) => {
    return {
        type: actionTypes.GET_RUN_FLAG,
        data: data
    }
}

export const getRunPriceFlags = (request, type) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'pricingalgorithm/run-pricing-and-fetch-inventory-status/')
            .then(function (response) {
                dispatch(successRunFlag(response.data));
            })
            .catch(function (error) {
            });
    }
}

const onPricingSettingsStart = () => {
    return {
        type: actionTypes.PRICING_SETTINGS_START,
    };
};

const onPricingSettingsFailed = (error) => {
    return {
        type: actionTypes.PRICING_SETTINGS_FAILED,
        error: error,
    };
};

const onPricingSettingsSucceed = (data) => {
    return {
        type: actionTypes.PRICING_SETTINGS_SUCCEED,
        state: data,
    };
};
export const storePricingSettings = (request) => {
    let reqParams = {
        settings: JSON.stringify(request.settings)
    };
    return dispatch => {
        dispatch(onPricingSettingsStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/pricing-settings/', reqParams)
            .then((response) => {
                dispatch(actions.getPricingSettings({ token: request.token }))
                dispatch(Notify.newUserModal('Settings Updated Successfully', true))
                dispatch(onPricingSettingsSucceed(response.data))
            }).catch((error) => {
                dispatch(Notify.newUserModal('Error while Applying the Changes', false))
                dispatch(onPricingSettingsFailed(error))
            })
    }
}
const userNotesSucceed = (data) => {
    return {
        type: actionTypes.USER_NOTES_SUCCEED,
        state: data,
    };
};
export const getUserNotes = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/pricing-settings/user-notes/')
            .then((response) => {
                dispatch(userNotesSucceed(response.data))
            }).catch((error) => {
                console.log(error)
            })
    }
}