import PropTypes from 'prop-types';

function arrowGenerator(color) {
    return {
        opacity: "1",
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.95em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${color} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.95em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${color} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.95em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${color} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.95em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${color}`,
            },
        },
    };
}
var styles = theme => ({
        lightTooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
        arrowPopper: arrowGenerator(theme.palette.grey[700]),
        arrow: {
            position: 'absolute',
            fontSize: 6,
            width: '3em',
            height: '3em',
            '&::before': {
                content: '""',
                margin: 'auto',
                display: 'block',
                width: 0,
                height: 0,
                borderStyle: 'solid',
            },
        },
        bootstrapPopper: arrowGenerator(theme.palette.common.black),
        bootstrapTooltip: {
            backgroundColor: theme.palette.common.black,
        },
        bootstrapPlacementLeft: {
            margin: '0 8px',
        },
        bootstrapPlacementRight: {
            margin: '0 8px',
        },
        bootstrapPlacementTop: {
            margin: '8px 0',
        },
        bootstrapPlacementBottom: {
            margin: '8px 0',
        },
        htmlPopper: arrowGenerator('#1976d2'),
        htmlTooltip: {
            backgroundColor: '#323338',
            color: 'white',
            maxWidth: 300,
            fontSize: theme.typography.pxToRem(14),
            // border: '1px solid #1976d2',
            '& b': {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        title: {
            color: 'white',
        },
        icons: {
            fontSize: 20,
        }
    });

styles.propTypes = {
    theme: PropTypes.object.isRequired,
};

export default styles;