import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../../actions/index';
import Notify from '../Notify'

const onResellerSucceed = (data) => {
    return {
        type: actionTypes.RESELLER_USER_SUCCEED,
        state: data
    }
}

export const getReseller = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
         let url = (request.url ? (Server.API + request.url) : (Server.API + 'account/reseller/list/?page=1'));
        axios.get(url)
            .then(function (response) {
                dispatch(onResellerSucceed(response.data))
            })
            .catch(function (error) {
            });
    }
}


export const deleteReseller = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.delete(Server.API + 'account/reseller/delete/' + request.id + '/')
            .then(function (response) {
                dispatch(Notify.newUserModal("Delete Reseller Successfully", true))
                dispatch(actions.getReseller(request))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Error while deleting Reseller", false))
            });
    }
}

export const AddReseller = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'account/reseller/register/', request)
            .then(function (response) {
                dispatch(Notify.newUserModal("Reseller Created Successfully", true))
                dispatch(actions.getReseller(request))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Error while creating Reseller", false))
            });
    }
}

export const updateReseller = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.patch(Server.API + 'account/reseller/update/' + request.id + '/', request)
            .then(function (response) {
                dispatch(Notify.newUserModal("Reseller Updated Successfully", true))
                dispatch(actions.getReseller(request))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Error while Updating Reseller", false))
            });
    }
}

const onResellerMmberSucceed = (data) => {
    return {
        type: actionTypes.RESELLER_MEMBER_USER_SUCCEED,
        state: data
    }
}

export const getResellerMember = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        let url = (request.url ? (Server.API + request.url) : (Server.API + 'account/reseller/member/list/?page=1'));
        axios.get(url)
            .then(function (response) {
                dispatch(onResellerMmberSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Error while Getting Reseller User", false))
            });
    }
}

export const AddResellerMember = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'account/reseller/member/', request)
            .then(function (response) {
                dispatch(Notify.newUserModal("Reseller User Created Successfully", true))
                dispatch(actions.getResellerMember(request))
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.user) {
                    dispatch(Notify.newUserModal(error.response.data.user.message, false))
                } else {
                    dispatch(Notify.newUserModal("Error while creating Reseller User", false))
                }
            });
    }
}

export const deleteResellerMember = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.patch(Server.API + 'account/reseller/member/', request)
            .then(function (response) {
                dispatch(Notify.newUserModal("Reseller User Deleted Successfully", true))
                dispatch(actions.getResellerMember(request))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Error while Deleting Reseller User", false))
            });
    }
}

const onResellerMmberAll = (data) => {
    return {
        type: actionTypes.RESELLER_MEMBER_All,
        state: data
    }
}

export const getResellerMemberAll = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'account/reseller/member/')
            .then(function (response) {
                dispatch(onResellerMmberAll(response.data))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Error while Getting Reseller User", false))
            });
    }
}

const onResellerAll = (data) => {
    return {
        type: actionTypes.RESELLER_All,
        state: data
    }
}

export const getResellerAll = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'account/reseller/all/')
            .then(function (response) {
                dispatch(onResellerAll(response.data))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Error while Getting Reseller User", false))
            });
    }
}


export const editResellerMember = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.patch(Server.API + 'account/reseller/member/update/' + request.id + '/', request)
            .then(function (response) {
                dispatch(Notify.newUserModal("Reseller Updated Successfully", true))
                dispatch(actions.getResellerMember(request))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Error while Updating Reseller User", false))
            });
    }
}