import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../index';
import Notify from '../Notify';

const performanceReservationStart = () => {
    return {
      type: actionTypes.PERFORMANCE_RESERVATION_START,
    };
  };
  
  const performanceReservationFailed = (error) => {
    return {
      type: actionTypes.PERFORMANCE_RESERVATION_FAILED,
      error: error,
    };
  };
  
  const performanceReservationSucceed = (data) => {
    return {
      type: actionTypes.PERFORMANCE_RESERVATION_SUCCESS,
      state: data,
    };
  };
  
  export const getPerformanceReservation = (request) => {
    return (dispatch) => {
      dispatch(performanceReservationStart());
      axios.defaults.headers.common["Authorization"] = "Token " + request.token;
      axios.get(Server.API + "performancedashboard/reservation-kpi/?date_range="+request.date_range+'&is_calendar='+request.is_calendar)
        .then(function (response) {
          dispatch(performanceReservationSucceed(response));
        })
        .catch(function (error) {
          dispatch(performanceReservationFailed(error));
        });
    };
  };
  const updateReservationStart = () => {
    return {
      type: actionTypes.UPDATE_RESERVATION_START,
    };
  };
  
  const updateReservationFailed = (error) => {
    return {
      type: actionTypes.UPDATE_RESERVATION_FAILED,
      error: error,
    };
  };
  
  const updateReservationSucceed = (data) => {
    return {
      type: actionTypes.UPDATE_RESERVATION_SUCCESS,
      state: data,
    };
  };
  
  export const getLatestReservation = (request) => {
    return (dispatch) => {
      dispatch(updateReservationStart());
      axios.defaults.headers.common["Authorization"] = "Token " + request.token;
      axios
        .get(Server.API + "dashboard/latest-reservation/")
        .then(function (response) {
          dispatch(updateReservationSucceed(response));
          dispatch(Notify.newUserModal("Latest Reservation updated Successfully", true));
        })
        .catch(function (error) {
          dispatch(updateReservationFailed(error));
        });
    };
  };
  const getProcessAllReservationStart = () => {
    return {
      type: actionTypes.GET_PROCESS_ALL_RESERVATION_START,
    };
  };
  
  const getProcessAllReservationFailed = (error) => {
    return {
      type: actionTypes.GET_PROCESS_ALL_RESERVATION_FAILED,
      error: error,
    };
  };
  
  const getProcessAllReservationSucceed = (data) => {
    return {
      type: actionTypes.GET_PROCESS_ALL_RESERVATION_SUCCESS,
      state: data,
    };
  };
  
  export const getProcessAllReservation = (request) => {
    return (dispatch) => {
      dispatch(getProcessAllReservationStart());
      axios.defaults.headers.common["Authorization"] = "Token " + request.token;
      axios
        .get(Server.API + "dashboard/process-all-reservation/")
        .then(function (response) {
          dispatch(getProcessAllReservationSucceed(response));
          dispatch(Notify.newUserModal("Full Recalculation Started. This Might Take a While. Please Refresh Later.", true));
        })
        .catch(function (error) {
          dispatch(getProcessAllReservationFailed(error));
        });
    };
  }; 

  const getHourlyExecutionFailed = (error) => {
    return {
      type: actionTypes.GET_HOURLY_EXCUTION_FAILED,
      error: error,
    };
  };
  export const getHourlyExecution = (request) => {
    let reqestParams = {
      include_in_hourly_execution:request.include_in_hourly_execution
    }
    return (dispatch) => {
      axios.defaults.headers.common["Authorization"] = "Token " + request.token;
      axios
        .patch(Server.API + "hotel/update/",reqestParams)
        .then(function (response) {
          if(!request.is_feature){
            dispatch(Notify.newUserModal("Hourly Excecution Updated Successfully", true));
          }
        })
        .catch(function (error) {
          dispatch(getHourlyExecutionFailed(error));
        });
    };
  }

  const updatePurgeReservationStart = () => {
    return {
      type: actionTypes.UPDATE_PURGE_RESERVATION_START,
    };
  };
  
  const updatePurgeReservationFailed = (error) => {
    return {
      type: actionTypes.UPDATE_PURGE_RESERVATION_FAILED,
      error: error,
    };
  };
  
  const updatePurgeReservationSucceed = (data) => {
    return {
      type: actionTypes.UPDATE_PURGE_RESERVATION_SUCCESS,
      state: data,
    };
  };
  
  export const getPurgeReservation = (request) => {
    return (dispatch) => {
      dispatch(updatePurgeReservationStart());
      axios.defaults.headers.common["Authorization"] = "Token " + request.token;
      axios
        .get(Server.API + "dashboard/purge-reservations/")
        .then(function (response) {
          dispatch(updatePurgeReservationSucceed(response));
          dispatch(Notify.newUserModal("Purging All Reservations Started. This Might Take A While.", true));
        })
        .catch(function (error) {
          dispatch(updatePurgeReservationFailed(error));
        });
    };
  };
  const performancePickupStart = () => {
    return {
      type: actionTypes.PERFORMANCE_PICKUP_START,
    };
  };
  const performancePickupSucceed = (data) => {
    return {
      type: actionTypes.PERFORMANCE_PICKUP_SUCCESS,
      state:data
    };
  };
  
  const performancePickupFailed = (error) => {
    return {
      type: actionTypes.PERFORMANCE_PICKUP_FAILED,
      error: error,
    };
  };

  export const getPerformanceReservationPickup = (request) => {
    return (dispatch) => {
      dispatch(performancePickupStart())
      axios.defaults.headers.common["Authorization"] = "Token " + request.token;
      axios.get(Server.API + "performancedashboard/pickup/")
        .then(function (response) {
          dispatch(performancePickupSucceed(response));
        })
        .catch(function (error) {
          dispatch(performancePickupFailed(error));
        });
    };
  };
  const dashboardDefaultBudgetSucceed = (data) => {
    return {
      type: actionTypes.DASHBOARD_DEFAULT_BUDGET_SUCCEED,
      state:data
    };
  };
  export const dashboardDefaultBudget = (request) => {
    return (dispatch) => {
      axios.defaults.headers.common["Authorization"] = "Token " + request.token;
      axios.get(Server.API + "hotel/dashboard-default-budget/")
        .then(function (response) {
          dispatch(dashboardDefaultBudgetSucceed(response));
        })
        .catch(function (error) {
          console.log(error)
        });
    };
  };
  const analyticsDataSuccess = (data) => {
    return {
      type: actionTypes.ANALYTICS_DATA_SUCCESS,
      state:data
    };
  };
  export const getAnalyticsData=(request)=>{
    return (dispatch) => {
      axios.defaults.headers.common["Authorization"] = "Token " + request.token;
      axios.get(Server.API+'performancedashboard/get-analytics-dashboard-cache')
      .then(function (response) {
        dispatch(analyticsDataSuccess(response));
      })
      .catch(function (error) {
        console.log(error)
      });
    }
  }
  export const removePostgres = (request) => {
    
    return (dispatch) => {
      axios.defaults.headers.common["Authorization"] = "Token " + request.token;
      axios.post(Server.API + "performancedashboard/delete-snowflake-and-postgres-data/")
        .then(function (response) {
          dispatch(Notify.newUserModal(response.data.Message, true));
        })
        .catch(function (error) {
          debugger
          console.log(error)
        });
    };
  }

  const forecastDataSuccess = (data) => {
    return {
      type: actionTypes.FORECAST_DATA_SUCCESS,
      state: data
    };
  };
  export const getForecastData=(request)=>{
    return (dispatch) => {
      axios.defaults.headers.common["Authorization"] = "Token " + request.token;
      axios.get(Server.API+'performancedashboard/get-forecasting-cache/')
      .then(function (response) {
        dispatch(forecastDataSuccess(response.data));
      })
      .catch(function (error) {
        console.log(error)
      });
    }
  }

  export const updateForecastData=(request)=>{
    return (dispatch) => {
      axios.defaults.headers.common["Authorization"] = "Token " + request.token;
      axios.post(Server.API+'performancedashboard/update-forecasting-cache/')
      .then(function (response) {
        dispatch(getForecastData(request));
      })
      .catch(function (error) {
        console.log(error)
      });
    }
  }
  
  
  