import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../index';


const onDemandScheduleStart = () => {
    return {
        type: actionTypes.GET_ON_DEMAND_SCHEDULE_START,
        error: null
    }
}

const onDemandScheduleFailed = (error) => {
    return {
        type: actionTypes.GET_ON_DEMAND_SCHEDULE_FAILED,
        error: error
    }
}

const onDemandScheduleSucceed = (data) => {
    return {
        type: actionTypes.GET_ON_DEMAND_SCHEDULE_SUCCEED,
        state: data
    }
}

export const getDemandSchedule = (request) => {
    return dispatch => {
        dispatch(onDemandScheduleStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        let url = (request.url ? (Server.API + request.url) : (Server.API + 'amalgamation/scrape/history/ondemand/'));
        axios.get(url)
            .then(function (response) {
                dispatch(onDemandScheduleSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onDemandScheduleFailed(error))
            });
    }
}

