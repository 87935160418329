import * as actionTypes from "../actionTypes";
import Server from "../../../config/server";
import axios from "axios";
import Notify from "../Notify";
import * as actions from "../../../store/actions/index";

const onOTAInsightsCompetitorSetupStart = () => {
    return {
      type: actionTypes.OTAINSIGHTS_COMPETITOR_SETUP_START,
    };
  };
  
  const onOTAInsightsCompetitorSetupFailed = (error) => {
    return {
      type: actionTypes.OTAINSIGHTS_COMPETITOR_SETUP_FAILED,
      error: error,
    };
  };
  
  const onOTAInsightsCompetitorSetupSucceed = (data) => {
    return {
      type: actionTypes.OTAINSIGHTS_COMPETITOR_SETUP_SUCCEED,
      state: data,
    };
  };
  
  export const storeOtaCompetitorSetup = (request) => {
    return (dispatch) => {
        let reqestParams = {
            ota_subscription_id:parseInt(request.otaCompetitorsSetup.ota_subscription_id), 
            ota_competitors:request.otaCompetitorsSetup.ota_competitors,
            ota_room_types:request.otaCompetitorsSetup.ota_room_types,
            ota_meal_type:request.otaCompetitorsSetup.ota_meal_type,
            ota_max_occupancy:request.otaCompetitorsSetup.ota_max_occupancy    
        };
        dispatch(onOTAInsightsCompetitorSetupStart());
        axios.defaults.headers.common["Authorization"] = "Token " + request.token;
        axios
        .post(Server.API + "hotel/ota_insights/competitors/setup/",reqestParams)
        .then(function (response) {
          if(response.data.success){
            dispatch(onOTAInsightsCompetitorSetupSucceed(response));
            dispatch(actions.onGetOtaCompetitor(request));
            dispatch(Notify.newUserModal(response.data.message, true));
          }else{
            dispatch(Notify.newUserModal(response.data.message, false));
          }
            
        })
        .catch(function (error) {
          dispatch(onOTAInsightsCompetitorSetupFailed(error));
        });
    };
  };
 
  const onGetOtaCompetitorStart = () => {
    return {
      type: actionTypes.GET_OTA_COMPETITOR_START,
    };
  };
  
  const onGetOtaCompetitorFailed = (error) => {
    return {
      type: actionTypes.GET_OTA_COMPETITOR_FAILED,
      error: error,
    };
  };
  
  const onGetOtaCompetitorSucceed = (data) => {
    return {
      type: actionTypes.GET_OTA_COMPETITOR_SUCCEED,
      state: data,
    };
  };
  
  export const onGetOtaCompetitor = (request) => {
    return (dispatch) => {
      let reqestParams = {
        is_from_ota_setup:request.is_from_ota_setup
      }
      dispatch(onGetOtaCompetitorStart());
      axios.defaults.headers.common["Authorization"] = "Token " + request.token;
      axios
        .post(Server.API + "hotel/ota_insights/get-competitors-setup-data/",reqestParams)
        .then((response) =>{          
          // if(response.data.success){
            dispatch(onGetOtaCompetitorSucceed(response.data));            
          // }   
        })
        .catch( (error) => {
          
          dispatch(onGetOtaCompetitorFailed(error));
        });
    };
  };
