import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../../../store/actions/index';
import Notify from '../Notify';

const getScheduleReportListStart = (data) => {
    return {
        type: actionTypes.GET_SCHEDULE_REPORT
    }
}

const getScheduleReportListSuccess = (data) => {
    return {
        type: actionTypes.GET_SCHEDULE_REPORT_SUCCESS,
        state: data
    }
}

const getScheduleReportListFailed = (error) => {
    return {
        type: actionTypes.GET_SCHEDULE_REPORT_FAILED,
        error: error
    }
}

export const scheduleReportList = (request) => {
    return dispatch => {
        dispatch(getScheduleReportListStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'performancedashboard/scheduled-reports/',{page: (request.page || 1), page_size: (request.pageSize || 10)})
            .then((response) => {
                dispatch(getScheduleReportListSuccess(response.data))
            })
            .catch(function (error) {
                dispatch(getScheduleReportListFailed(error))
            });
    }
}

export const createNewScheduleReport = (request) => {
    return dispatch => {
        dispatch(getScheduleReportListStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'performancedashboard/scheduled-reports/',request)
            .then((response) => {
                dispatch(actions.scheduleReportList(request))
                dispatch(Notify.newUserModal("Schedule Report Created Successfully", true))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while creating report", false))
            });
    }
}

export const updateScheduleReport = (request) => {
    return dispatch => {
        dispatch(getScheduleReportListStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.patch(Server.API + 'performancedashboard/scheduled-reports/'+request.id+"/",request)
            .then((response) => {
                dispatch(actions.scheduleReportList(request))
                dispatch(Notify.newUserModal("Schedule Report Updated Successfully", true))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while updating report", false))
            });
    }
}

export const deleteScheduleReport = (request) => {
    return dispatch => {
        dispatch(getScheduleReportListStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.delete(Server.API + 'performancedashboard/scheduled-reports/'+request.id+"/")
            .then((response) => {
                dispatch(actions.scheduleReportList(request))
                dispatch(Notify.newUserModal("Schedule Report Deleted Successfully", true))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while deleting report", false))
            });
    }
}

