var style = {
    textField: {
        width: '50%',
        marginTop:"-6px",
        ['@media (max-width: 414px) and (min-width: 360px)']: {            
            width: "90%",
            marginLeft: '15px'
        }
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    formControl:{
        marginTop:"0px",
        ['@media (max-width: 414px) and (min-width: 360px)']: {            
            width: "100%",
            marginRight: '15px'
        }
    },
    saveBtn: {
        float: 'left',
        marginTop: '20px',
        ['@media (max-width: 414px) and (min-width: 360px)']: {
            width: '100%'
        }
    },
    btnDiv: {
        width: '50%',
        textAlign: 'left',
        ['@media (max-width: 414px) and (min-width: 360px)']: {
            width: '90%',
            marginLeft: '15px'
        }
    },
    divStyle: {
        float: "left", 
        marginTop: "2px"
    },
    fontStyle: {
        textAlign: "left", 
        fontWeight: "500"
    },
    spanError: {
        marginLeft: '30px',
    }
}
export default style;