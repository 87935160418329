import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import adjustmentCss from '../css/modal/adjustmentCss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../../store/actions/index';
import moment from 'moment';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { dayofweekTheme } from '../../muiTheme';
import { withNamespaces, Trans } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { ValidateBaseSettings } from '../../Utils/SettingValidation/Validation';
import Server from '../../../config/server';
import CustomTooltip from '../../Utils/toolTip';
import Warning from '../warning';
import HeaderCard from "../HeaderCard";
import Grid from "@material-ui/core/Grid";

const styles = adjustmentCss;

class MonthlyAdjustment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loadData: false,
            openDailyAdjustModal: false,
            monthlyData: null,
            weeklyData: null,
            error: [],
            monthly_adjustment: [],
            settingAlert: false,
            averageWeekMonth: {},
            warning: false,
            warningMsg: [],
            warningMethod: null,
            start_monday:false,
            boostDay: {},
            featuresEnabled: [],
            is_channel_manager:false,
            pms_channel_manager_text :'',
        };
    }


    componentWillMount() {
       
        this.getLattestPricing();
        let requestData = {
            token: this.props.hotelAuthReducer.hotelDetails.token
          };
          this.props.getHotelDetail(requestData);

    }

    componentWillReceiveProps(props) {
        if(props.priceSettingReducer){
            this.setState({
                loadData:props.priceSettingReducer.loadData
            })
        }
        if(props.hotelAuthReducer && props.hotelAuthReducer.hotelDetails){
            console.log("ttttttttttttt",props.hotelAuthReducer.hotelDetails.hotel.is_channel_manager)
            this.setState({
                is_channel_manager:props.hotelAuthReducer.hotelDetails.hotel.is_channel_manager,
                pms_channel_manager_text :props.hotelAuthReducer.hotelDetails.hotel.is_channel_manager ? 'Channel Manager':'PMS'
            })
        }
        if(props.hotelDetail){
            this.setState({
                start_monday:props.hotelDetail.starts_monday
            })
        }
        if (props.priceSettingReducer.priceSetting) {
            let monthlyData = JSON.stringify(props.priceSettingReducer.priceSetting.adjustment.monthly);
            let weeklyData = JSON.stringify(props.priceSettingReducer.priceSetting.adjustment.weekday);

            this.setState({
                monthlyData: JSON.parse(monthlyData),
                weeklyData: JSON.parse(weeklyData),
                featuresEnabled: props.priceSettingReducer.priceSetting.features
            })
        }
    }

    toggleWarningModal = (method) => {
        const { t } = this.props;
        let warningMsg = [];
        let warning = false;
        let errorMsg = "";
        if (method == "updateWeeklyAdjust") {

            let error = [];
            let weeklyData = this.state.weeklyData;
            var daysInWeek=[];
            if(this.state.start_monday){
                daysInWeek = [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday','sunday'];
            }else{
                daysInWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
            }
            

            let allAdj = [];


            daysInWeek.map((row) => {
                if ((!weeklyData[row].standard || weeklyData[row].standard === '') && weeklyData[row].standard !== 0) {
                    error.push("per_" + row);
                }
                allAdj.push(weeklyData[row].standard)
            })


            this.setState({
                error: error
            })

            if (error.length == 0) {
                let less = false;
                let more = false;
                let weekdirection = true;
                let endDirection = true;
                let max = false;

                allAdj.map((val, index) => {
                    if (val < 0.1 && !less) {
                        less = true;
                    }

                    if (val > -0.5 && !more) {
                        more = true;
                    }

                    if (Math.abs(val) > 25 && !max) {
                        max = true;
                    }
                })

                if (!(allAdj[1] >= 0 && allAdj[2] >= 0 && allAdj[3] >= 0 && allAdj[4] >= 0) && !(allAdj[1] <= 0 && allAdj[2] <= 0 && allAdj[3] <= 0 && allAdj[4] <= 0)) {
                    weekdirection = false;
                }

                if (!(allAdj[5] >= 0 && allAdj[6] >= 0) && !(allAdj[5] <= 0 && allAdj[6] <= 0)) {
                    endDirection = false;
                }

                if (!less) {
                    warning = true;
                    warningMsg.push(t("When you have set all daily adjustments to  positive it means your base price may be too low. You can call us for consultation on this."))
                }
                if (!more) {
                    warning = true;
                    warningMsg.push(t("When you have set all daily adjustments to  negative it means your base price may be too high. You can call us for consultation on this."))
                }
                if (!weekdirection) {
                    warning = true;
                    warningMsg.push(t("Typically Monday to Thursday adjustments will have the same direction, e.g. either all positive or all negative. Are you sure about these?"))
                }
                if (!endDirection) {
                    warning = true;
                    warningMsg.push(t("Typically Friday and Saturday adjustments will have the same direction, e.g. either both positive or both negative. Are you sure about these?"))
                }

                if (max) {
                    warning = true;
                    warningMsg.push(t("This is an unusually high/low adjustment. Please contact us if you are unsure."))
                }



                if (!max && less && more && weekdirection && endDirection) {
                    this.updateWeeklyAdjust()
                }
            }
        }

        this.setState({
            warning: warning,
            warningMethod: method,
            warningMsg: warningMsg,
            errorMsg: errorMsg
        })
    }

    closeWarningModal = (method) => {
        this.setState({
            warning: false,
        })
    }

    warningConfirm = () => {
        if (this.state.warningMethod == "updateWeeklyAdjust") {
            this.updateWeeklyAdjust()
        }
        this.setState({
            warning: false,
        })
    }


    getLattestPricing = () => {       
        axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
        axios.get(Server.API + 'pricingalgorithm/cache-price/')
            .then((response) => {
                if (response.data) {
                    var received_msg = response.data;

                    if (received_msg && received_msg.prices && received_msg.prices.data && !this.state.stopCachedPrice) {
                        if (received_msg.prices.average_price_week_month) {
                            this.setState({
                                averageWeekMonth: received_msg.prices.average_price_week_month
                            })
                        }
                    }

                    if(received_msg && received_msg.prices && received_msg.prices.average_price_week_month.pickupboostprice_averages){
                        this.setState({
                            boostDay: received_msg.prices.average_price_week_month.pickupboostprice_averages.quarter_weekdays,
                        })
                    }
                }
            }).catch((err) => {
                console.log(err)
            })
    }


    validateMonthlyAdjust = (data) => {
        let error = [];
        let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let monthData = this.state.monthlyData;
        monthNames.map((row) => {
            if ((!monthData[row].standard || monthData[row].standard === '') && monthData[row].standard !== 0) {
                error.push("mon_" + row);
            }
            return null;
        })
        if (error) {
            this.setState({
                error: error
            })
            return error
        }
        else {
            return false
        }
    }

    updateMonthlyAdjust = () => {
        let requestParams = {
            token: this.props.hotelAuthReducer.hotelDetails.token,
            allData: this.state.monthlyData
        }

        let setting = this.props.priceSettingReducer.priceSetting;
        setting.adjustment.monthly = requestParams.allData;
        // let flagReq = {
        //     run_pricing: true,
        //     token: this.props.hotelAuthReducer.hotelDetails.token,
        // }
        // this.props.setRunPriceFlags(flagReq);


        let latestJson = ValidateBaseSettings({ dateSetting: setting });

        let reqParams = {
            settings: latestJson.dateSetting,
            token:this.props.hotelAuthReducer.hotelDetails.token
        };
        this.props.storePricingSettings(reqParams)
    }



    updateWeeklyAdjust = () => {

        let rows = this.state.weeklyData;

        let requestParams = {
            token: this.props.hotelAuthReducer.hotelDetails.token,
            allData: rows,
        }

        let setting = this.props.priceSettingReducer.priceSetting;
        setting.adjustment.weekday = requestParams.allData;



        // let flagReq = {
        //     run_pricing: true,
        //     token: this.props.hotelAuthReducer.hotelDetails.token,
        // }
        // this.props.setRunPriceFlags(flagReq);


        let latestJson = ValidateBaseSettings({ dateSetting: setting });

        let reqParams = {
            settings: latestJson.dateSetting,
            token:this.props.hotelAuthReducer.hotelDetails.token
        };
        this.props.storePricingSettings(reqParams)

    }

    handleMonthly = (row) => event => {
        let monthlyData = this.state.monthlyData;
        if (event.target.value != "" && event.target.value != "-") {
            monthlyData[row].standard = parseFloat(event.target.value);
        } else {
            monthlyData[row].standard = (event.target.value);
        }

        this.setState({
            monthlyData
        })
    }

    renderMonthlyTable = (limit) => {
        var jsx = [];
        const { classes } = this.props;
        const { t } = this.props;
        let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let percentAdorn = <Typography variant="body2" className={classes.adornment}>%</Typography>;
        let monthData = this.state.monthlyData;
        {
            monthData &&
                monthNames.map((row, index) => {
                    if (index < (limit + 6) && (index >= limit)) {
                        return jsx.push(
                            <TableCell align="right">
                                <FormControl fullWidth margin="normal" required className={classes.fieldMargin}>
                                    <Input
                                        margin="normal"
                                        label={t("Value")}
                                        type="number"
                                        error={this.state.error.includes("mon_" + row)}
                                        className={classes.textField}
                                        onChange={this.handleMonthly(row)}
                                        value={monthData[row].standard}
                                        endAdornment={percentAdorn}
                                    />
                                    {this.state.error.includes("mon_" + row) ? <FormHelperText><span class="error-message">{t('* Please enter the value')}</span></FormHelperText> : ''}
                                </FormControl>
                            </TableCell>
                        );
                    }
                    return null;
                });
        }
        return jsx;
    }

    handleWeekly = (row) => event => {
        let weekData = [];
        weekData = this.state.weeklyData;

        if (event.target.value != "" && event.target.value != "-") {
            weekData[row].standard = parseFloat(event.target.value);
        } else {
            weekData[row].standard = (event.target.value);
        }
        this.setState({
            weeklyData: weekData
        })
    }

    renderWeeklyTable = () => {
        const { t } = this.props;
        var jsx = [];
        var rows = [];
        const { classes } = this.props;
        let percentAdorn = <Typography variant="body2" className={classes.adornment}>%</Typography>;

        let weekData = this.state.weeklyData;
        var daysInWeek=[];
        if(this.state.start_monday){
             daysInWeek = [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday','sunday'];    
        }else{
             daysInWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        }

        {
            weekData && daysInWeek.map((row, index) => {
                let shortName =  row.slice(0, 3) // mon,tue
                return jsx.push(
                    <TableCell key={index} align="right">
                        <FormControl margin="normal" required className={classes.fieldMargin}>
                            <Input
                                type="number"
                                margin="normal"
                                error={this.state.error.includes("per_" + row)}
                                className={classes.textField}
                                placeholder={t(shortName[0].toUpperCase() + shortName.slice(1))}
                                onChange={this.handleWeekly(row)}
                                value={weekData[row].standard}
                                endAdornment={percentAdorn}
                            />
                            {this.state.error.includes("per_" + row) ? <FormHelperText><span class="error-message">{t('* Please enter the value')}</span></FormHelperText> : ''}
                        </FormControl>
                    </TableCell>
                )
            })
        }
        return jsx;
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;

        //weekly

        let week_comp_data = [];
        let week_ref_data = [];
        let week_avg_data = [];
        let maxvalue = 0;
        let minvalue = 0;
        if (this.state.averageWeekMonth.comp_adjusted_price_averages) {
            if(!this.state.start_monday){
            week_comp_data.push(this.state.averageWeekMonth.comp_adjusted_price_averages.quarter_weekdays[7])
        }
            Object.keys(this.state.averageWeekMonth.comp_adjusted_price_averages.quarter_weekdays).map((key) => {
                if (key < 7 || this.state.start_monday) {
                    week_comp_data.push(Math.round(this.state.averageWeekMonth.comp_adjusted_price_averages.quarter_weekdays[key]))
                }
                if (this.state.averageWeekMonth.comp_adjusted_price_averages.quarter_weekdays[key] && this.state.averageWeekMonth.comp_adjusted_price_averages.quarter_weekdays[key] > maxvalue) {
                    maxvalue = this.state.averageWeekMonth.comp_adjusted_price_averages.quarter_weekdays[key]
                }
                if (this.state.averageWeekMonth.comp_adjusted_price_averages.quarter_weekdays[key] && (!minvalue || this.state.averageWeekMonth.comp_adjusted_price_averages.quarter_weekdays[key] < minvalue)) {
                    minvalue = this.state.averageWeekMonth.comp_adjusted_price_averages.quarter_weekdays[key]
                }
            })
        }

        if (this.state.averageWeekMonth.pms_price_averages) {

            if(!this.state.start_monday){
                week_avg_data.push(this.state.averageWeekMonth.pms_price_averages.quarter_weekdays[7])
            }
            Object.keys(this.state.averageWeekMonth.pms_price_averages.quarter_weekdays).map((key) => {
                if (key < 7 || this.state.start_monday) {
                    week_avg_data.push(Math.round(this.state.averageWeekMonth.pms_price_averages.quarter_weekdays[key]))
                }
                if (this.state.averageWeekMonth.pms_price_averages.quarter_weekdays[key] && this.state.averageWeekMonth.pms_price_averages.quarter_weekdays[key] > maxvalue) {
                    maxvalue = this.state.averageWeekMonth.pms_price_averages.quarter_weekdays[key]
                }
                if (this.state.averageWeekMonth.pms_price_averages.quarter_weekdays[key] && (!minvalue || this.state.averageWeekMonth.pms_price_averages.quarter_weekdays[key] < minvalue)) {
                    minvalue = this.state.averageWeekMonth.comp_adjusted_price_averages.quarter_weekdays[key]
                }
            })
        }

        if (this.state.weeklyData && this.state.averageWeekMonth.comp_adjusted_price_averages) {
            let days=[];
            if(this.state.start_monday){
                days = [ "monday", "tuesday", "wednesday", "thursday", "friday", "saturday","sunday"];
            }else{
             days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
            }
            
            days.map((val, index) => {
                    let weekData = this.state.weeklyData[val];
                    let weekVal = 0;
    
                    if (week_comp_data[index]) {
                        weekVal = Math.round((week_comp_data[index] * (1 + (weekData.standard / 100))))
                    }
    
                    week_ref_data.push(parseFloat(weekVal));
        })
        }
        let days=[];
        if(this.state.start_monday){
            days = [ "Mon", "Tue", "Wed", "Thu", "Fri", "Sat","Sun"];
        }else{
         days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        }
        const week_data = {
            labels:days,
            datasets: (this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) ? [
                {
                    type: 'line',
                    borderWidth: 1,
                    fill: false,
                    lineTension: 0,
                    label: t("Level of Local Market"),
                    data: week_comp_data,
                    backgroundColor: "#541388",
                    borderColor: '#541388',
                }, {
                    label: t("Average Base Price Next 3 Months"),
                    data: week_ref_data,
                    backgroundColor: "#51c0c0",
                },
                {
                    label: t(`${this.state.pms_channel_manager_text} Price`),
                    data: week_avg_data,
                    backgroundColor: "#dbdee2",
                }
            ]
                :
                [
                    {
                        type: 'line',
                        borderWidth: 1,
                        fill: false,
                        lineTension: 0,
                        label: t("Level of Local Market"),
                        data: week_comp_data,
                        backgroundColor: "#541388",
                        borderColor: '#541388',
                    }, {
                        label: t("Average Base Price Next 3 Months"),
                        data: week_ref_data,
                        backgroundColor: "#51c0c0",
                    }
                ]
        }


        const week_options = {
            maintainAspectRatio: false,
            legend: {
                labels: {
                    boxWidth: 11,
                }
            },
            title: {
                display: false,
                text: 'Chart.js Line Chart'
            },
            tooltips: {
                enabled: true,
                mode: 'label',
                callbacks: {
                  label: (tooltipItems, data) => {
                    if (tooltipItems.datasetIndex == 2) {
                        return data.datasets[2].label+': ' + this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol+ new Intl.NumberFormat('ja-JP').format(parseInt(data.datasets[2].data[tooltipItems.index]));
                      }else if (tooltipItems.datasetIndex == 0) {
                        return data.datasets[0].label +': '+ this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol+ new Intl.NumberFormat('ja-JP').format(parseInt(data.datasets[0].data[tooltipItems.index]));
                      }else if (tooltipItems.datasetIndex == 1) {
                        return data.datasets[1].label +': '+ this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol+ new Intl.NumberFormat('ja-JP').format(parseInt(data.datasets[1].data[tooltipItems.index]));
                      }
                    }
                }
            },
            hover: {
                mode: 'dataset'
            },
            scales: {
                xAxes: [
                    {
                        barThickness: 35,  
                        maxBarThickness: 60,
                        display: false,
                        scaleLabel: {
                            show: true,
                            labelString: 'Month'
                        },
                        ticks: {
                         
                            callback: (label, index, labels) => {
                                return  t(label);
                            }
                        }
                    }
                ],
                yAxes: [
                    {
                        display: true,
                        scaleLabel: {
                            show: true,
                            labelString: 'Value'
                        },
                        ticks: {
                            suggestedMin: 0,
                            suggestedMax: (maxvalue * 0.7),
                            callback: (label, index, labels) => {
                                return (this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol) + label;
                            }
                        }
                    }
                ]
            }
        }

        let keys = ["7", "1", "2", "3", "4", "5", "6"]
        if (this.state.start_monday) {
            keys = ["1", "2", "3", "4", "5", "6", "7"]
        }
        const plugins= [{
            beforeInit: function(chart, args, options) {
              chart.legend.afterFit = function() {
                this.height = this.height + 30;
              }
            }
          }]
        return (
            <>
            <HeaderCard>
                <Grid container>
                    <Grid item >
                        <div className={classes.divStyle} >
                        <Typography variant="h5" className={classes.fontStyle} >
                          {t("Day-of-the-Week Adjustments")}
                          <CustomTooltip title="" description={t("The Day-of-Week Adjustment is a price-change that is applied for every single e.g. Monday. If you set Monday to -5% we will adjust our recommended price 5% lower for every Monday. It enables you to set your weekly cycle versus your market set. Note that these adjustments do not override pre-set maximums or minimums.")} />
                        </Typography>
                        <DialogContentText align="left" className={classes.helpText}>
                            {t("We check the rates of other properties that represent your local 'market'. Next, we estimate what they would charge if they had the same base price as you. Below, we show these 'market' prices for the different weekdays. However, every property is different, use Day-of-the-week Adjustments to adjust price recommendations to suit your property.")}
                        </DialogContentText>
                        </div>
                    </Grid>
                </Grid>
            </HeaderCard>
                <Card >
                    <CardContent>
                        <div className={classes.mainDiv} >
                        <div className={classes.root}>
                            <div className={classes.graphStyle}>
                                <Bar plugins={plugins} height={200}  data={week_data} options={week_options} />
                            </div>
                            <div className={classes.dateTableDiv}>
                                <MuiThemeProvider theme={dayofweekTheme}>
                                    <Table className={classes.userTable}>
                                        <TableHead>
                                            
                                                {this.state.start_monday ?
                                            <TableRow className={classes.rowTable}>
                                            <TableCell align="right"></TableCell>
                                                <TableCell align="right"><span className={classes.weekCellWidth}>{t("Mon")}</span></TableCell>
                                                <TableCell align="right"><span className={classes.weekCellWidth}>{t("Tue")}</span></TableCell>
                                                <TableCell align="right"><span className={classes.weekCellWidth}>{t("Wed")}</span></TableCell>
                                                <TableCell align="right"><span className={classes.weekCellWidth}>{t("Thu")}</span></TableCell>
                                                <TableCell align="right"><span className={classes.weekCellWidth}>{t("Fri")}</span></TableCell>
                                                <TableCell align="right"><span className={classes.weekCellWidth}>{t("Sat")}</span></TableCell>
                                                <TableCell align="right"><span className={classes.weekCellWidth}>{t("Sun")}</span></TableCell>
                                                </TableRow>
                                                :
                                                <TableRow className={classes.rowTable}>
                                                <TableCell align="right"></TableCell>
                                                <TableCell align="right"><span className={classes.weekCellWidth}>{t("Sun")}</span></TableCell>
                                                <TableCell align="right"><span className={classes.weekCellWidth}>{t("Mon")}</span></TableCell>
                                                <TableCell align="right"><span className={classes.weekCellWidth}>{t("Tue")}</span></TableCell>
                                                <TableCell align="right"><span className={classes.weekCellWidth}>{t("Wed")}</span></TableCell>
                                                <TableCell align="right"><span className={classes.weekCellWidth}>{t("Thu")}</span></TableCell>
                                                <TableCell align="right"><span className={classes.weekCellWidth}>{t("Fri")}</span></TableCell>
                                                <TableCell align="right"><span className={classes.weekCellWidth}>{t("Sat")}</span></TableCell>
                                                </TableRow>
                                                }
                                            
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={'s1'} className={classes.rowTable}>
                                                <TableCell>{t("Adjust")}<br />{t("Price by")}</TableCell>
                                                {this.renderWeeklyTable()}
                                            </TableRow>
                                            
                                            
                                            <TableRow key={'s1'} className={[classes.rowTable,classes.rowTable2]} >                                             
                                                <TableCell>{t("Average Base Price")}<br />{t("Next 3 Months")}</TableCell>
                                                {week_ref_data && week_ref_data.map((val, index) => (
                                                    <TableCell  align="left" className={classes.tableCell} >
                                                        <FormControl margin="normal" required className={classes.fieldMargin}>
                                                            <Typography >
                                                                {this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol+val }
                                                        </Typography>    
                                                    </FormControl>
                                                </TableCell>
                                                ))}
                                                
                                            </TableRow>
                                            {(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) && this.state.featuresEnabled.includes(22) &&
                                            <TableRow key={'s1'} className={classes.rowTable} className={[classes.rowTable,classes.rowTable2]} >
                                                    <TableCell>{t("Average Pickup Boost")}</TableCell>
                                                    {this.state.boostDay && keys.map((val, index) => (
                                                        <TableCell align="left">
                                                            <FormControl margin="normal" required className={classes.fieldMargin1}>
                                                                <Typography >
                                                                    {this.state.boostDay[val] ? (this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol + this.state.boostDay[val]) : ""}
                                                                </Typography>
                                                            </FormControl>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            }
                                        </TableBody>
                                        
                                    </Table>
                                    
                                </MuiThemeProvider>
                            </div>
                        </div>
                        </div>
                        <div className={classes.btnSave} >
                            <Button disabled={this.state.loadData} className={"orangButton"} variant="contained" color="primary" onClick={() => this.toggleWarningModal("updateWeeklyAdjust")}>
                                {t("Save")}{this.state.loadData && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                            </Button>
                        </div>
                        {Warning.warningModal(this)}
                    </CardContent>
                </Card>
            </>
        );
    }
}

MonthlyAdjustment.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        userSession: state.sessionReducer,
        hotelDetail: state.hotelSettingReducer.hoteDetail,
        currencyList: state.currencyReducer.currencyList,
        hotelAuthReducer: state.hotelAuthReducer,
        monthlyAdjustReducer: state.monthlyAdjustReducer,
        roomList: state.roomListReducer,
        commonReducer: state.commonReducer,
        priceSettingReducer: state.priceSettingReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getHotelDetail: (request) => { dispatch(actions.getHotelDetail(request)) },
        updateMonthlyAdjust: (request) => { dispatch(actions.updateMonthlyAdjust(request)) },
        getPricingSettings: (request) => { dispatch(actions.getPricingSettings(request)) },
        putNotify: (request) => { dispatch(actions.putNotify(request)) },
        // setRunPriceFlags: (request) => { dispatch(actions.setRunPriceFlags(request)) },
        storePricingSettings:(request) => {dispatch(actions.storePricingSettings(request))}
    };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(MonthlyAdjustment));
