import { Button } from '@material-ui/core';
import React from 'react';
import { NamespacesConsumer } from 'react-i18next';
var menu = [];

// menu[0] = {
//   label: <NamespacesConsumer>{t => <span>{t('GenieIntelligence')}</span>}</NamespacesConsumer>,
//   icon: 'email',
//   to: '/client/genieIntelligence',
//   client: true,
//   name: "GenieIntelligence in Sidebar Clicked / GenieIntelligence",
// }
menu[1] = {
  label: <NamespacesConsumer>{t => <span>{t('Dashboard')}<Button style={{float:'right',color:'white',padding:'2px 5px',minWidth:'0px',textTransform: 'capitalize',boxShadow:'0 0 white',background:'#eda942'}} variant="contained"  size="small">Free Trial</Button></span>}</NamespacesConsumer>,
  // label: <NamespacesConsumer>{t => <span>{t('Dashboard')}</span>}</NamespacesConsumer>,
  // label: <NamespacesConsumer>{t => <span>{t('Dashboard')}</span>}</NamespacesConsumer>,
  icon: 'analytics',
  to: '/client/PerformanceDashboard',
  client: true,
  name: "Dashboard in Sidebar Clicked / Dashboard",
}
menu[2] = {
  label: <NamespacesConsumer>{t => <span>{t('Calendar')}</span>}</NamespacesConsumer>,
  icon: 'date_range',
  to: '/client/Calendar',
  client: true,
  type: "calendar",
  name: "Price Calendar in Sidebar Clicked / Price Calendar",
}
menu[3] = {
  label: <NamespacesConsumer>{t => <span>{t('Chart')}</span>}</NamespacesConsumer>,
  icon: 'timeline',
  to: '/client/Calendar',
  client: true,
  type: "priceGraph",
  name: "Price Chart",
}
// menu[4] = {
//   label: <NamespacesConsumer>{t => <span>{t('Adjustment Calendar')}</span>}</NamespacesConsumer>,
//   icon: 'event',
//   to: '/client/Calendar',
//   client: true,
//   type: "adjustment",
//   name: "Adjustment Calendar in Sidebar Clicked / Adjustment Calendar",
// }
// menu[5] = {
  // label: <NamespacesConsumer>{t => <span>{t('OTA Insights')}<Button style={{ float: 'right', color: 'white !important', padding: '2px 8px', minWidth: '0px', textTransform: 'capitalize', marginRight: "-25px" }} variant="contained" color="primary" size="small">beta</Button></span>}</NamespacesConsumer>,
//   label: <NamespacesConsumer>{t => <span>{t('OTA Insight')}</span>}</NamespacesConsumer>,
//   icon: 'today',
//   to: '/client/OTAInsights',
//   client: true,
//   name: "OTA Insight in Sidebar Clicked / OTA Insight",
// }
// menu[4] = { // 2
//   label: <NamespacesConsumer>{t => <span>{t('Report')}</span>}</NamespacesConsumer>,
//   icon: 'bar_chart',
//   to: '/client/pricingReport',
//   client: true,
// }
menu[6] = {
  label: <NamespacesConsumer>{t => <span>{t('Pricing Strategy')}</span>}</NamespacesConsumer>,
  icon: 'tune',
  to: '',
  client: true,
  name: "Pricing Strategy in Sidebar Clicked / Pricing Strategy",
  children: [
    {
      label: <NamespacesConsumer>{t => <span>{t('Room Setup')}</span>}</NamespacesConsumer>,
      icon: 'vpn_key',
      to: '/client/RoomSetting',
      client: true,
      name: "Room Setup in Pricing Strategy Clicked / Pricing Strategy",
      is_room_setup: true,
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('Day-of-the-Week Adjustments')}</span>}</NamespacesConsumer>,
      icon: 'view_week',
      to: '/client/DayoftheWeek',
      client: true,
      name: "Day-of-the-Week Adjustments in Pricing Strategy Clicked / Pricing Strategy",
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('Monthly Adjustments')}</span>}</NamespacesConsumer>,
      icon: 'view_week',
      to: '/client/MonthlySetting',
      client: true,
      name: "Monthly Adjustments in Pricing Strategy Clicked / Pricing Strategy",
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('Occupancy Strategy')}</span>}</NamespacesConsumer>,
      icon: 'bar_chart',
      to: '/client/TargetOccupancy',
      client: true,
      name: "Occupancy Strategy in Pricing Strategy Clicked / Pricing Strategy",
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('After 365 Days')}</span>}</NamespacesConsumer>,
      icon: 'view_week',
      to: '/client/AfterYearSetting',
      client: true,
      name: "After 365 Days",
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('Yielding Tags')}</span>}</NamespacesConsumer>,
      icon: 'attach_file',
      to: '/client/YieldingTag',
      client: true,
      name: "Yielding Tags in Pricing Strategy Clicked / Pricing Strategy",
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('Lead Time')}</span>}</NamespacesConsumer>,
      icon: 'trending_flat',
      to: '/client/LeadTimeAdjustments',
      client: true,
      name: "Lead Time in Pricing Strategy Clicked / Pricing Strategy",
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('BAR Levels')}</span>}</NamespacesConsumer>,
      icon: 'style',
      to: '/client/RateSetting',
      client: true,
      name: "BAR Levels in Pricing Strategy Clicked / Pricing Strategy",
    },
    // {
    //   label: <NamespacesConsumer>{t => <span>{t('Aggressiveness')}</span>}</NamespacesConsumer>,
    //   icon: 'subject',
    //   to: '/client/InternalParams',
    //   client: false,
    // },
    {
      label: <NamespacesConsumer>{t => <span>{t('Market Intelligence')}</span>}</NamespacesConsumer>,
      icon: 'location_city',
      to: '/client/Competitors',
      client: false,
      name: "Market Intelligence in Pricing Strategy Clicked / Pricing Strategy",
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('OTA Setup')}</span>}</NamespacesConsumer>,
      icon: 'event_note',
      to: '/client/OTASetup',
      client: true,
      name: "OTA Setup in Pricing Strategy Clicked / Pricing Strategy",
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('Segmentation')}</span>}</NamespacesConsumer>,
      icon: 'tag',
      to: '/client/segmentation',
      client: true,
      name: "Segmentation",
    },
    // {
    //   label: <NamespacesConsumer>{t => <span>{t('Daily/Seasonal')}</span>}</NamespacesConsumer>,
    //   icon: 'timeline',
    //   to: '/client/dailySeasonalAdjustments',
    //   client: true,
    // },
    // {
    //   label: <NamespacesConsumer>{t => <span>{t('Daily Adjustments')}</span>}</NamespacesConsumer>,
    //   icon: 'timeline',
    //   to: '/client/DailySetting',
    //   client: true,
    // },
    // {
    //   label: <NamespacesConsumer>{t => <span>{t('Skip and Fix Prices')}</span>}</NamespacesConsumer>,
    //   icon: 'lock',
    //   to: '/client/SkipSetting',
    //   client: true,
    // },

  ]
}
menu[7] = {
  label: <NamespacesConsumer>{t => <span>{t('Account Settings')}</span>}</NamespacesConsumer>,
  icon: 'settings',
  to: '',
  client: true,
  children: [
    {
      label: <NamespacesConsumer>{t => <span>{t('Property')}</span>}</NamespacesConsumer>,
      icon: 'home',
      to: '/client/HotelSetting',
      client: true,
      name: "Property in Account Settings clicked / Account Settings",
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('Subscription')}</span>}</NamespacesConsumer>,
      icon: 'credit_card',
      to: '/client/PaymentSetting',
      client: true,
      name: "Subscription  in Account Settings clicked / Account Settings",
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('Users')}</span>}</NamespacesConsumer>,
      icon: 'people',
      to: '/client/UserSetting',
      client: true,
      name: "Users  in Account Settings clicked / Account Settings",
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('Integration')}</span>}</NamespacesConsumer>,
      icon: 'settings_input_antenna',
      to: '/client/Connections',
      client: true,
      name: "Integration  in Account Settings clicked / Account Settings",
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('Schedule Report')}</span>}</NamespacesConsumer>,
      icon: 'more_time',
      to: '/client/scheduleReports',
      client: true,
      name: "Schedule Report",
    },
    // {
    //   label: <NamespacesConsumer>{t => <span>{t('BAR Levels')}</span>}</NamespacesConsumer>,
    //   icon: 'style',
    //   to: '/client/RateSetting',
    //   client: true,
    // },
    // {
    //   label: <NamespacesConsumer>{t => <span>{t('Room Type & Rate')}</span>}</NamespacesConsumer>,
    //   icon: 'list',
    //   to: '/client/RoomTypeRates',
    //   client: false,
    //   onlyProtel: true
    // },    
    // {
    //   label: <NamespacesConsumer>{t => <span>{t('Competitors')}</span>}</NamespacesConsumer>,
    //   icon: 'location_city',
    //   to: '/client/Competitors',
    //   client: false,
    // },
    // {
    //   label: <NamespacesConsumer>{t => <span>{t('Internal Parameters')}</span>}</NamespacesConsumer>,
    //   icon: 'subject',
    //   to: '/client/InternalParams',
    //   client: false,
    // },    
  ]
}
// menu[6] = {
//   label: <NamespacesConsumer>{t => <span>{t('Contact Us')}</span>}</NamespacesConsumer>,
//   icon: 'contact_support',
//   to: '/client/contact-us-new',
//   client: true,
// }

menu[8] = {
  label: <NamespacesConsumer>{t => <span>{t('Admin')}</span>}</NamespacesConsumer>,
  icon: 'how_to_reg',
  to: '',
  client: false,
  children: [
    {
      label: <NamespacesConsumer>{t => <span>{t('Deal')}</span>}</NamespacesConsumer>,
      icon: 'account_tree',
      to: '/client/Deal',
      client: false,
      name: "Deal in Admin clicked / Admin",
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('Pricing Schedule')}</span>}</NamespacesConsumer>,
      icon: 'access_time',
      to: '/client/runAlgo',
      client: false,
      name: "Pricing Schedule in Admin clicked / Admin",
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('Features')}</span>}</NamespacesConsumer>,
      icon: 'dashboard',
      to: '/client/Features',
      client: false,
      name: "Features in Admin clicked / Admin",
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('Settings Log')}</span>}</NamespacesConsumer>,
      icon: 'tune',
      to: '/client/SettingsLog',
      client: false,
      name: "Settings Log in Admin clicked / Admin",
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('Users Log')}</span>}</NamespacesConsumer>,
      icon: 'directions_run',
      to: '/client/UsersLog',
      client: false,
      name: "Users Log in Admin clicked / Admin",
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('Inbound API log')}</span>}</NamespacesConsumer>,
      icon: 'logo_dev',
      to: '/client/apilog',
      client: false,
      name: "Users Log in Admin clicked / Admin",
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('Reservation API log')}</span>}</NamespacesConsumer>,
      icon: 'logo_dev',
      to: '/client/apilog/reservation',
      client: false,
      name: "Users Log in Admin clicked / Admin",
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('Outbound API log')}</span>}</NamespacesConsumer>,
      icon: 'all_out',
      to: '/client/outbound',
      client: false,
      name: "Users Log in Admin clicked / Admin",
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('Change Password')}</span>}</NamespacesConsumer>,
      icon: 'security',
      to: '/client/ChangePassword',
      client: false,
      name: "Change Password in Admin clicked / Admin",
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('Delete Property')}</span>}</NamespacesConsumer>,
      icon: 'delete',
      to: '/client/deleteProperty',
      client: false,
      name: "Delete Property in Admin clicked / Admin",
    }
  ]
}
// menu[9] = {
//   label: <NamespacesConsumer>{t => <span>{t('Quick Setup')}</span>}</NamespacesConsumer>,
//   icon: 'fast_forward',
//   to: '/client/QuickSetup',
//   client: false,
//   name: "Quick Setup in sidebar clicked / Quick Setup",
// }
// menu[10] = {
//   label: <NamespacesConsumer>{t => <span>iframe</span>}</NamespacesConsumer>,
//   icon: 'fast_forward',
//   to: '/client/iframe',
//   client: false,
//   name: "Quick Setup in sidebar clicked / Quick Setup",
// }

export default menu;
