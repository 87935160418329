import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import HeaderCardCss from './css/headerCardCss';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = HeaderCardCss;

class HeaderCard extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Card className={classes.CardHeader} style={this.props.style}>
                <CardContent>
                    {this.props.children}
                </CardContent>
            </Card >
        );
    }
}

HeaderCard.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles, { withTheme: true })(HeaderCard);