import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ScrapListCss from './css/scrapProgramCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../store/actions/index';

const styles = ScrapListCss;


class RateSetting extends React.Component {

    state = {

    };

    componentWillMount() {
        let requestParam = { token: this.props.userSession.token }
        this.props.getAlgoProgram(requestParam);
    }

    componentWillReceiveProps(props) {

    }


    render() {
        const { classes } = this.props;
        return (
            <Card>
                <CardHeader
                    className="headig-top"
                    title="Run Algorithm">
                </CardHeader>
                <CardContent>

                </CardContent>
            </Card >
        );
    }
}

RateSetting.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        userSession: state.sessionReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAlgoProgram: (request) => { dispatch(actions.getAlgoProgram(request)) },
    };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(RateSetting);