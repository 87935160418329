var style = {
  RadioGroup: {
    display: 'inline-block',
  },
  emailLabel: {
    marginTop: '20px'
  },
  userTable: {
    padding: '20px'
  },
  addUserBtn: {
    textAlign: 'right',
    // marginTop:"16px",
    display:"flex"
  },
  userTableDiv: {
    overflow: 'auto',
    marginTop: '70px'
  },
  textField: {
    width: '50%',
    minWidth: "250px"
  },
  fieldLeft: {
    width: '46%',
    marginLeft: '2%',
    marginRight: '2%',
    minWidth: '250px',
    ['@media (max-width: 540px) and (min-width: 400px)']:{
        minWidth: '265px',
        marginLeft: '0',
        marginRight: '0',
    },
    ['@media (max-width: 399px) and (min-width: 361px)']:{
        minWidth: '230px',
        marginLeft: '0',
        marginRight: '0',
    },
    ['@media (max-width: 360px) and (min-width: 321px)']:{
        minWidth: '220px',
        marginLeft: '0',
        marginRight: '0',
    },
    ['@media (max-width: 320px)']:{
        minWidth: '180px',
        marginLeft: '0',
        marginRight: '0',
    }
  },
  radioGroup: {
    display: 'initial'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: '5px',
  },
  multiselect: {
    width: '520px'
  },
  actionBtn: {
    cursor: 'pointer'
  },
  btndiv: {
    display: 'inline-flex'
  },
  editBtn: {
    color: "#3772FF"
  },
  deleteBtn: {
    color: '#541388'
  },
  actionIcon: {
    padding: '0',
    cursor: 'pointer',
    minWidth: '30px'
  },
  accessSelect: {
    width: '46%',
    minWidth: '250px',
    ['@media (max-width: 540px) and (min-width: 400px)']:{
        minWidth: '265px',
        marginLeft: '0',
        marginRight: '0',
    },
    ['@media (max-width: 399px) and (min-width: 361px)']:{
        minWidth: '230px',
        marginLeft: '0',
        marginRight: '0',
    },
    ['@media (max-width: 360px) and (min-width: 321px)']:{
        minWidth: '220px',
        marginLeft: '0',
        marginRight: '0',
    },
    ['@media (max-width: 320px)']:{
        minWidth: '180px',
        marginLeft: '0',
        marginRight: '0',
    }
  },
  userFields: {
    display: "-webkit-inline-box"
  },
  divStyle: {
    float: "left", 
    marginTop: "2px"
  },
  fontStyle: {
    textAlign: "left", 
    fontWeight: "500"
  }
}
export default style;