import PropTypes from 'prop-types';
const drawerWidth = 240;
const styles = theme => ({
    main: {
        position: "fixed",
        top: "310px",
        left: "calc(50% - 10em)",
        width: "44em",
        minHeight: "20em",
        height:"auto",
        marginTop: "-10em",
        marginLeft: "-11em",
        border: "1px solid #ccc",
        backgroundColor: "#fff",
        borderRadius: "10px",
        zIndex: "99",
        paddingBottom:"16px",
        ['@media (max-width: 960px)']: {
            width: "20em",
            minHeight: "26em",  
            height:"auto",
            marginLeft: "-8em",
            left: "calc(50% - 2em)",
            height: "31em",
        },        
    },
    mainError: {
        position: 'absolute',
        width: '300px',
        height: 'auto',
        zIndex: '15',
        top: '34%',
        left: '45%',
        margin: '-100px 0 0 -150px',
        borderRadius: '5px',
        background: 'white',
        padding: '20px',
        opacity: '0.9',
        borderRadius: "15px",
        boxShadow: 'none'
    },
    siteLogo1: {
        // backgroundSize: "116px 85px",
        margin:'auto',
        padding: "10px",
        height: '245px',
        width:'347px',
        zIndex: '1',
        margin: 'auto',
        ['@media (max-width: 768px)']: {
            width:'100px',
            height: '70px',
            margin:'10px'
        }  
    },
    pageContainer:{
        ['@media (max-width: 768px)']: {
            display:'block'
        }
    },
    siteLogo: {
        backgroundSize: "116px 85px",
        
        padding: "10px",
        height: '85px',
        zIndex: '1',
        margin: 'auto',
        
    },
    warningBar: {
        backgroundColor: '#d32f2f',
        textAlign: 'left'
    },
    sccessBar: {
        backgroundColor: '#43a047',
        textAlign: 'left'
    },
    warningIcon: {
        margin: "-9px 5px -8px -5px"
    },
    extraBtn: {
        cursor: "pointer",
        marginRight: "5px",
        marginTop: "59px",
        position:"absolute",
        // padding: "5px",
        fontSize: "0.75rem",
        fontWeight: "500",
        '&:hover': {
            background: "#e8f0fe",
            borderRadius: "5px",
        },
    },
    iframeStyle: {
        height: '50px',
        border: 'none',
        width: '230px'
    },
    submit: {
        margin: 10 ,
        ['@media (max-width: 768px)']: {
            margin: "10px",
            marginLeft: "0px",
            width:"90px"
        },
    },
    LoginBtn: {
        marginTop: 10,
        // float:"left"
    },
    heading: {
        textAlign: 'left',
        marginTop: 16
    },
    warning: {
        display: 'flex',
        textAlign: 'center',
        margin: '20px 0px',
        padding: '15px',
        color: 'inherit',
        background: '#F8E9C3',
        borderRadius: '5px',
        width: '90%',
        flexDirection:"column",
        alignItems: "center"
    },
    warningText: {
        margin: '4px 10px'
    },
    success: {
        display: 'flex',
        textAlign: 'left',
        margin: '20px 0px',
        padding: '15px',
        color: 'inherit',
        background: '#cdffdd',
        borderRadius: '5px',
        width: 'fit-content'
    },
    radioLabel: {
        fontSize: "16px",
        paddingTop: "20px",
        paddingRight: "15px",
        minWidth: "100%"
    },
    warningbooking: {
        color: "red",
        width: "100%",
        margin: "16px 0px",
        float: "left",
        textAlign: "left"
    },
    bookingBtn: {
        marginLeft: "16px",
        float: "right"
    },
    buttonProgress: {
        position: "absolute"
    },
    extraAccBtn:{
        // display: "flex" NewDesign,
        width: "100%",
        paddingTop: "10px"
    },
    accountBtn:{
        cursor: "pointer",
        marginRight: "5px",
        float: "left",
        padding: "5px",
        fontSize: "0.75rem",
        fontWeight: "500",
        '&:hover': {
            background: "#e8f0fe",
            borderRadius: "5px",
        },
    },
    forgetBtn: {
        cursor: "pointer",
        marginRight: "5px",
        float: "right",
        padding: "5px",
        fontSize: "0.75rem",
        fontWeight: "500",
        '&:hover': {
            background: "#e8f0fe",
            borderRadius: "5px",
        },
    },
    skyline: {
        position: "fixed",
        width: "100%",
        left: "0",
        opacity: "0.1",
    },
    iframeDiv: {
        float: "left",
        width: "100%",
        marginTop: "40px"
    },
    mainError: {
        padding:'20px',
        margin:'0 auto',
        left: "50%",
        width: "22em",
        height: "22em",
        border: "1px solid #ccc",
        backgroundColor: "#fff",
        borderRadius: "10px",
    },
    errorCard:{
        ['@media (max-width: 768px)']: {
            width:'auto',
            height:'auto'
        },
        width:'40%',
        borderRadius:'10px',
        position:'absolute',
        display:'inline-block',
        top:'0',
        right:'0',
        left:'0',
        bottom:'0',
        margin:'auto',
        height:'400px',
        border:'1px solid white'
    },
    columnData:{
        display:"flex"
    },
    columnDataDiv:{
        width:"50%"
    },
    preparedAcCard:{
        ['@media (max-width: 768px)']: {
            width:'auto',
            height:'auto'
        },
        width:'60%',
        borderRadius:'10px',
        margin:'auto',
        height:'auto',
        border:'1px solid white',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#f5f6f8'
    },
    hotelspiderIframe: {
        width: "100%",
        height: "800px",
        border: 'none',
    },
    mainAcademy: {
        position: "fixed",
        top: "310px",
        left: "calc(50% - 11em)",
        width: "44em",
        marginTop: "-10em",
        marginLeft: "-11em",
        border: "1px solid #ccc",
        backgroundColor: "#fff",
        borderRadius: "10px",
        zIndex: "99",
    },
    siteLogoAcademy: {
        backgroundSize: "120px 108px",
        padding: "25px",
        height: '85px',
        zIndex: '1',
    },
    titleAcademy: {
        fontSize: "22px",
        paddingBottom: "20px",
        fontWeight: "500",
    },
    titleAcademytext: {
        fontSize: "16px",
        paddingBottom: "20px",        
    },
    AcceptContinueBtn: {
        marginTop: "20px",
        // fontSize: "20px",
        // width: "40%",
        // border: "2.5px solid #3772FF",
    },
    cancelBtn: {
        marginTop: "20px",
        marginRight: "16px",
        // fontSize: "20px",
        color: "#3772FF",
        // width: "40%",
    },

    boxContainer:{
        width:"100%",
        maxWidth: "600px",
        textAlign:'left',
        padding:"24px",
        borderRadius:"10px",
        background:"#fff",
        // margin:"45px auto 0",
    },
    //NewDesign
    seekomBtn:{
        color: "#84bc37",
        fontSize: "15px",
        ['@media (max-width: 960px)']: {
            marginTop: "24px",
            // marginLeft:"-37px"
        },
        // marginTop: "85px",
        fontWeight: "400",
        textTransform: "inherit", 
        // height: "45px", 
        borderRadius: "4px",
        // width:'95%',
        // marginLeft:'121px'
        // padding: "3px 16px",
        // width: "200px",
        // marginLeft: "-10px"
    },
    loginLogoCard:{
        background:"#5b48ee",display:'flex',alignItems:'center',justifyContent:'center',
        
    },
    loginFormCard:{background:'#f2f2f2',display:'flex',alignItems:'center',justifyContent:'center'},
    cardFormDiv:{
        padding:'40px 20px 40px 20px',
        border:'1px solid #f2f2f2',
        borderRadius:'10px',
        background:'white',
        width:'70%',
        ['@media (max-width: 768px)']: {
            width:'100%',
            borderRadius:'0px',
            padding:'20px 0px 40px 15px',
        }
    },
    loginanimation:{
        height:'calc(100vh - 105px)',position:'absolute',
        ['@media (max-width: 768px)']: {
            display:'none'
        }
    },
    otpTitle: {
        ['@media (max-width: 768px)']: {
            fontSize:'1.2rem',
        },
        fontSize:'1.5rem',
        textAlign:'left',
        fontWeight:'500',
        fontFamily:'roboto'
    },
    otpForm: {
        ['@media (max-width: 768px)']: {
            padding: "0px 0px 16px", 
            margin: "15px"
        },
        padding: "0px 42px 16px", 
        marginBottom:"-8px"
    },
    otpEmailText:{
        ['@media (max-width: 768px)']: {
            fontSize:'15px',
        },
        fontSize:'18px', 
        fontWeight:'300', 
        textAlign:'left',
        fontFamily:'roboto', 
        marginTop:'6px', 
        marginBottom:'6px'
    },
    drawerPaper: {
        background:'#292F4C ',
        width: drawerWidth,
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    selectHotel: {
        cursor: "pointer",
        // color: "#6E6F72",
        color:'#D8DAE5',
        
        marginTop: '2px',
        padding: "0px 10px 10px 10px",
        width: "200px",
        ['@media (min-width:411px)']: {
            marginTop: '4px',
            marginLeft: '10px'
        },
        
    },
    hotelTitleList: {
        display: "flex",
        color: "#6E6F72",
        paddingTop:'9px',
        '&:hover': {
            background:'#3e72ff63',
            color:'',
        }
    },
    toolbar: {
        ...theme.mixins.toolbar,
        // minHeight: "55px",
        margin:'15px 0px 15px 0px',
        ['@media (min-width: 600px)']: {
            minHeight: "55px",
        },
    },
    cancelBtnMoodle: {
        marginTop: "20px",
        marginLeft: "10px",
        // fontSize: "20px",
        color: "#3772FF",
        // width: "40%",
    },
    siteLogoAcademyMoodle: {
        backgroundSize: "370px 108px",
        padding: "25px",
        height: '85px',
        zIndex: '1',
    },
    hotelDrop: {
        
        cursor: "pointer",
        color:'#D8DAE5',
        paddingTop: "5px",
        paddingRight:"12px",
        // marginLeft: "-11px",
        // marginRight: "5px"
    },

});

styles.propTypes = {
    theme: PropTypes.object.isRequired,
};

export default styles;
