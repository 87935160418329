import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = [];

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADMIN_USER_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.ADMIN_USER_SUCCEED:
            return updateObject(state, { loading: false, error: null, adminUserList: action.state })
        case actionTypes.ADMIN_USER_FAILED:
            return updateObject(state, { loading: false, error: action.error })
            case actionTypes.GET_RESPONSIBLE_PERSON_START:
                return updateObject(state, { loading: true, error: null })
            case actionTypes.GET_RESPONSIBLE_PERSON_SUCCEED:
                return updateObject(state, { loading: false, error: null, responsiblePersonList: action.state })
            case actionTypes.GET_RESPONSIBLE_PERSON_FAILED:
                return updateObject(state, { loading: false, error: action.error })
            case actionTypes.GET_SUBSCRIBED_CLIENT_DATA_START:
                return updateObject(state, { loading: true, error: null })
            case actionTypes.GET_SUBSCRIBED_CLIENT_DATA_SUCCEED:
                return updateObject(state, { loading: false, error: null, subscribedClientData: action.state })
            case actionTypes.GET_SUBSCRIBED_CLIENT_DATA_FAILED:
                return updateObject(state, { loading: false, error: action.error })
            case actionTypes.GET_FREE_TRIAL_CLIENT_DATA_START:
                return updateObject(state, { loading: true, error: null })
            case actionTypes.GET_FREE_TRIAL_CLIENT_DATA_SUCCEED:
                return updateObject(state, { loading: false, error: null, freeTrialClientData: action.state })
            case actionTypes.GET_FREE_TRIAL_CLIENT_DATA_FAILED:
                return updateObject(state, { loading: false, error: action.error })
            case actionTypes.GET_OPEN_TICKETS_START:
                return updateObject(state, { loading: true, error: null })
            case actionTypes.GET_OPEN_TICKETS_SUCCEED:
                return updateObject(state, { loading: false, error: null, openTicketData: action.state })
            case actionTypes.GET_OPEN_TICKETS_FAILED:
                return updateObject(state, { loading: false, error: action.error })
        default:
            return updateObject(state, { loading: false, error: null });
    }
}

export default reducer