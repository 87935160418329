import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import Notify from '../Notify';
import * as actions from '../index';

// get

const onStaffGetStart = () => {
    return {
        type: actionTypes.STAFF_GET_START
    }
}

const onStaffGetFailed = (error) => {
    return {
        type: actionTypes.STAFF_GET_FAILED,
        error: error
    }
}

const onStaffGetSucceed = (data) => {
    return {
        type: actionTypes.STAFF_GET_SUCCEED,
        state: data
    }
}

export const getStaffList = (request) => {
    return dispatch => {
        dispatch(onStaffGetStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'account/client/staff/assignment/')
            .then(function (response) {
                dispatch(onStaffGetSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onStaffGetFailed(error))
            });
    }
}


//create 

const assignMultipleHotel = (resp, req) => {
    let selectedHotelList = req.selectedHotelList;
    selectedHotelList.map((opt) => {
        axios.defaults.headers.common['Authorization'] = "Token " + req.LoginUserToken;
        axios.post(Server.API + 'account/auth/hotel/' + opt + '/')
            .then(function (response) {
                assignHotel(response.data, resp.id);
            })
        return null;
    })
}

const assignHotel = (resp, user_id) => {
    let requestParam = { user: user_id }
    axios.defaults.headers.common['Authorization'] = "Token " + resp.token;
    axios.post(Server.API + 'account/client/staff/assignment/', requestParam)
        .then(function (response) {
        })
}

export const staffCreate = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'account/client/staff/register/', request)
            .then(function (response) {
                let selectedHotelsList = [];
                for (var i = 0; i < request.hotelList.length; i++) {
                    for (var j = 0; j < request.selectedHotelList.length; j++) {
                        if (request.hotelList[i].id === request.selectedHotelList[j]) {
                            selectedHotelsList.push(request.hotelList[i]);
                        }
                    }
                }
                response.assigned_hotels = selectedHotelsList;
                // assign user to other hotels
                assignMultipleHotel(response.data, request)
                dispatch(Notify.newUserModal("Create created successfully", true))
                dispatch(actions.getStaffList(request))
            })
            .catch(function (error) {
                if (error.response.data) {
                    Object.keys(error.response.data).map(function (key) {
                        if (error.response.data[key].length > 0) {
                            dispatch(Notify.newUserModal(error.response.data[key][0], false))
                        } else {
                            dispatch(Notify.newUserModal("An error occured while creating Staff", false))
                        }
                    })
                } else {
                    dispatch(Notify.newUserModal("An error occured while creating Staff", false))
                }
            });
    }
}

// update


const deleteUpdateMultiHotel = (assignId, token, hotelId, dispatch) => {
    axios.defaults.headers.common['Authorization'] = "Token " + token;
    axios.post(Server.API + 'account/auth/hotel/' + hotelId + '/')
        .then(function (response) {
            deleteAssignmentHotel(response.data, assignId, dispatch, token);
        })
}

const deleteAssignmentHotel = (resp, assignId, dispatch, oldToken) => {
    axios.defaults.headers.common['Authorization'] = "Token " + resp.token;
    axios.delete(Server.API + 'account/client/staff/assignment/' + assignId + '/')
        .then(function (response) {
            dispatch(actions.getClientAdminList({ token: oldToken }))
            dispatch(actions.getManagerList({ token: oldToken }))
            dispatch(actions.getStaffList({ token: oldToken }))
        })
        .catch(function (error) {
        });
}

const assignUpdateMultiHotel = (user_id, hotelId, token, dispatch) => {
    axios.defaults.headers.common['Authorization'] = "Token " + token;
    axios.post(Server.API + 'account/auth/hotel/' + hotelId + '/')
        .then(function (response) {
            assignUpdateHotel(response.data, user_id, dispatch, token);
        })
}

const assignUpdateHotel = (resp, user_id, dispatch, oldToken) => {
    let requestParam = { user: user_id }
    axios.defaults.headers.common['Authorization'] = "Token " + resp.token;
    axios.post(Server.API + 'account/client/staff/assignment/', requestParam)
        .then(function (response) {
            dispatch(actions.getClientAdminList({ token: oldToken }))
            dispatch(actions.getManagerList({ token: oldToken }))
            dispatch(actions.getStaffList({ token: oldToken }))
        })
}

export const updateStaff = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        let updateData = { first_name: request.first_name, last_name: request.last_name, email: request.username, language: request.language };
        axios.patch(Server.API + 'account/client/staff/update/' + request.userId + '/', updateData)
            .then(function (response) {
                request.selectedHotelList.map((row) => {
                    if (!request.oldHotelList.includes(row)) {
                        assignUpdateMultiHotel(request.userId, row, request.token, dispatch)
                    }
                    return null;
                })

                request.deleteArray.map((row) => {
                    deleteUpdateMultiHotel(row.id, request.token, row.hotel.id, dispatch)
                    return null;
                })
                dispatch(Notify.newUserModal("Staff updated successfully", true))
                dispatch(actions.getStaffList(request))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("An error occured while updating Manager", false))
            });

    }
}

// delete

export const deleteStaffUser = (request) => {
    return dispatch => {
        if (request.isAssigned == 0) {
            axios.defaults.headers.common['Authorization'] = "Token " + request.LoginUserToken;
            axios.post(Server.API + 'account/auth/hotel/' + request.hotelId + '/')
                .then(function (response) {
                    axios.defaults.headers.common['Authorization'] = "Token " + response.data.token;
                    axios.delete(Server.API + 'account/client/staff/delete/' + request.id + '/')
                        .then(function (response) {
                            dispatch(Notify.newUserModal("Staff deleted successfully", true))
                            dispatch(actions.getStaffList(request))
                        })
                        .catch(function (error) {
                            dispatch(Notify.newUserModal("An error occured while deleting Staff", false))
                        });
                })
        } else {
            axios.defaults.headers.common['Authorization'] = "Token " + request.token;
            axios.delete(Server.API + 'account/client/staff/delete/' + request.id + '/')
                .then(function (response) {
                    dispatch(Notify.newUserModal("Staff deleted successfully", true))
                    dispatch(actions.getStaffList(request))
                })
                .catch(function (error) {
                    dispatch(Notify.newUserModal("An error occured while deleting Staff", false))
                });
        }

    }
}