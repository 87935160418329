import React from 'react';
import { NamespacesConsumer } from 'react-i18next';
var menu = [];
// menu[0]= {
//   label : <NamespacesConsumer>{t => <span>{t('Calculate Prices')}</span>}</NamespacesConsumer>,
//   icon : 'fa fa-line-chart',
//   to : '/client/CalculatePrice',
//   admin : false
// }
menu[0] = {
  label: <NamespacesConsumer>{t => <span>{t('Login to Client')}</span>}</NamespacesConsumer>,
  icon: 'account_circle',
  to: '/Admin/Clients',
  admin: false,
  reseller: true,
}
// menu[1] = {
//   label: <NamespacesConsumer>{t => <span>{t('Client Dashboard')}</span>}</NamespacesConsumer>,
//   icon: 'card_membership_icon',
//   to: '/Admin/SubscribedClients',
//   admin: true,
//   reseller: true,
// }
// menu[2] = {
//   label: <NamespacesConsumer>{t => <span>{t('Free Trial Clients')}</span>}</NamespacesConsumer>,
//   icon: 'pending_icon',
//   to: '/Admin/FreeTrialClients',
//   admin: true,
//   reseller: true,
// }
menu[3] = {
  label: <NamespacesConsumer>{t => <span>{t('Create Property')}</span>}</NamespacesConsumer>,
  icon: 'group_add',
  to: '/Admin/ClientRegistration',
  admin: false,
  reseller: true,
}
menu[4] = {
  label: <NamespacesConsumer>{t => <span>{t('Scrape')}</span>}</NamespacesConsumer>,
  icon: 'perm_data_setting',
  to: '',
  admin: false,
  reseller: false,
  children: [
    // {
    //   label: <NamespacesConsumer>{t => <span>{t('Scrape Program')}</span>}</NamespacesConsumer>,
    //   icon: 'chrome_reader_mode',
    //   to: '/Admin/ScrapeProgram',
    //   admin: false,
    // },
    // {
    //   label: <NamespacesConsumer>{t => <span>{t('Scrape Schedule')}</span>}</NamespacesConsumer>,
    //   icon: 'schedule',
    //   to: '/Admin/ScrapeSchedule',
    //   admin: false,
    // },
    {
      label: <NamespacesConsumer>{t => <span>{t('Scrape List')}</span>}</NamespacesConsumer>,
      icon: 'format_list_numbered',
      to: '/Admin/ScrapeList',
      admin: false,
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('New Hotel')}</span>}</NamespacesConsumer>,
      icon: 'pageview',
      to: '/Admin/Scraper',
      admin: false,
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('On Demand Schedule')}</span>}</NamespacesConsumer>,
      icon: 'pageview',
      to: '/Admin/OnDemand',
      admin: false,
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('Schedule History')}</span>}</NamespacesConsumer>,
      icon: 'pageview',
      to: '/Admin/ScheduleHistory',
      admin: false,
    },
  ]
}
menu[5] = {

  label: <NamespacesConsumer>{t => <span>{t('Partner Users')}</span>}</NamespacesConsumer>,
  icon: 'supervised_user_circle',
  to: '/Admin/resellers',
  admin: null,
  reseller: true,
}
menu[6] = {

  label: <NamespacesConsumer>{t => <span>{t('RPG Users')}</span>}</NamespacesConsumer>,
  icon: 'contacts',
  to: '/Admin/adminUsers',
  admin: null,
  reseller: false,
}
// menu[7] = {

//   label: <NamespacesConsumer>{t => <span>{t('Mail Settings')}</span>}</NamespacesConsumer>,
//   icon: 'mail',
//   to: '/Admin/mailSettings',
//   admin: null,
// }
menu[8] = {

  label: <NamespacesConsumer>{t => <span>{t('Error Tickets')}</span>}</NamespacesConsumer>,
  icon: 'error',
  to: '',
  admin: false,
  reseller: false,
  children: [
    {
      label: <NamespacesConsumer>{t => <span>{t('Critical')}</span>}</NamespacesConsumer>,
      icon: 'report',
      to: '/Admin/criticalErrorTickets',
      admin: false,
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('Dashboard')}</span>}</NamespacesConsumer>,
      icon: 'report',
      to: '/Admin/dashboard-error-tickets',
      admin: false,
    },
    {
      label: <NamespacesConsumer>{t => <span>{t('Others')}</span>}</NamespacesConsumer>,
      icon: 'report',
      to: '/Admin/othersErrorTickets',
      admin: false,
    },
  ]
}
menu[9] = {

  label: <NamespacesConsumer>{t => <span>{t('Reservation Failed Tasks')}</span>}</NamespacesConsumer>,
  icon: 'running_with_errors',
  to: '/Admin/reservationFailedTasks',
  admin: null,
}
menu[10] = {

  label: <NamespacesConsumer>{t => <span>{t('New Error Ticket')}</span>}</NamespacesConsumer>,
  icon: 'error',
  to: '/Admin/newErrorTickets',
  admin: null,
}
// menu[4] = {
//   label: <NamespacesConsumer>{t => <span>{t('Run Algorithm')}</span>}</NamespacesConsumer>,
//   icon: 'exposure',
//   to: '/Admin/runAlgo',
//   admin: null,
// }

export default menu;
