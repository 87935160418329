import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import after365DaysCss from '../css/modal/after365DaysCss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../../store/actions/index';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { after365Theme } from '../../muiTheme';
import { withNamespaces, Trans } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { ValidateBaseSettings } from '../../Utils/SettingValidation/Validation';
import Server from '../../../config/server';
import CustomTooltip from '../../Utils/toolTip';
import Warning from '../warning';
import HeaderCard from "../HeaderCard";
import Grid from "@material-ui/core/Grid";

const styles = after365DaysCss;

class after365days extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            featuresEnabled: [],
            loadData: false,
            openDailyAdjustModal: false,
            monthlyData: null,
            weeklyData: null,
            error: [],
            monthly_adjustment: [],
            settingAlert: false,
            averageWeekMonth: {},
            warning: false,
            warningMsg: [],
            warningMethod: null,
            start_monday: false,
            currencySymbol: ""
        };
    }


    componentWillMount() {
        let requestParam = { token: this.props.hotelAuthReducer.hotelDetails.token }
        // this.getLattestPricing();
        this.props.getPricingSettings(requestParam);
        this.props.getDefaultAfterYear(requestParam);
        this.props.getHotelDetail(requestParam);

        this.setState({
            currencySymbol: this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol,
        });

    }

    componentWillReceiveProps(props) {
        if(props.priceSettingReducer){
            this.setState({
                loadData:props.priceSettingReducer.loadData
            })
        }
        if (props.hotelDetail) {
            this.setState({
                start_monday: props.hotelDetail.starts_monday
            })
        }

        if (props.priceSettingReducer.priceSetting) {
            this.setState({
                featuresEnabled: props.priceSettingReducer.priceSetting.features
            })
        }

        if (props.priceSettingReducer.priceSetting.after_365_daily_averages && props.priceSettingReducer.priceSetting.after_365_daily_averages.monthly_averages) {
            this.setState({
                monthlyData: props.priceSettingReducer.priceSetting.after_365_daily_averages.monthly_averages,
            })
        } else if (props.commonReducer.defaultYearlyData) {
            let monthly = {};
            Object.keys(props.commonReducer.defaultYearlyData.monthly_averages).map(key => {
                monthly[key] = parseInt(props.commonReducer.defaultYearlyData.monthly_averages[key]);
            })
            this.setState({
                monthlyData: monthly,
            })
        }


        if (props.commonReducer.defaultYearlyData) {
            let weekdays = {};
            props.commonReducer.defaultYearlyData.weekdays_percentage && Object.keys(props.commonReducer.defaultYearlyData.weekdays_percentage).map(key => {
                weekdays[key] = parseInt(props.commonReducer.defaultYearlyData.weekdays_percentage[key]);
            })

            let monthly = {};
            props.commonReducer.defaultYearlyData.monthly_averages && Object.keys(props.commonReducer.defaultYearlyData.monthly_averages).map(key => {
                monthly[key] = parseInt(props.commonReducer.defaultYearlyData.monthly_averages[key]);
            })
            this.setState({
                defaultMonthlyData: JSON.stringify(monthly),
                defaultWeeklyData: JSON.stringify(weekdays),
            })
        }

        if (props.commonReducer.notify) {
            if (props.commonReducer.notify.message) {
                this.setState({
                    loadDataMonth:false
                })
            }
        }          
    }

    setDefaultValues = () => {
        this.setState({
            monthlyData: JSON.parse(this.state.defaultMonthlyData),
        })
    }

    closeWarningModal = (method) => {
        this.setState({
            warning: false,
        })
    }

    warningConfirm = () => {
        if (this.state.warningMethod == "updateMonthlyAdjust") {
            this.updateMonthlyAdjust()
        }
        this.setState({
            warning: false,
        })
    }

    getLattestPricing = () => {
        axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
        axios.get(Server.API + 'pricingalgorithm/cache-price/')
            .then((response) => {
                if (response.data) {
                    var received_msg = response.data;
                    if (received_msg && received_msg.prices && received_msg.prices.data && !this.state.stopCachedPrice) {
                        if (received_msg.prices.average_price_week_month) {
                            this.setState({
                                averageWeekMonth: received_msg.prices.average_price_week_month
                            })
                        }
                    }
                }
            }).catch((err) => {
                console.log(err)
            })
    }




    updateMonthlyAdjust = () => {

        let setting = this.props.priceSettingReducer.priceSetting;

        if (!setting.after_365_daily_averages) {
            setting.after_365_daily_averages = {}
        }

        setting.after_365_daily_averages.monthly_averages = this.state.monthlyData;
        setting.after_365_daily_averages.weekdays_percentage = this.state.weeklyData;

        let latestJson = ValidateBaseSettings({ dateSetting: setting });
        let reqParams = {
            settings: latestJson.dateSetting,
            token:this.props.hotelAuthReducer.hotelDetails.token
        };

        this.setState({
            loadDataMonth: true
        });

        this.props.storePricingSettings(reqParams)      
        
        // let flagReq = {
        //     run_pricing: true,
        //     token: this.props.hotelAuthReducer.hotelDetails.token,
        // }
        // this.props.setRunPriceFlags(flagReq);

    }

    validateWeeklyAdjust = (data) => {
        let error = [];
        let weeklyData = this.state.weeklyData;
        var daysInWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        daysInWeek.map((row) => {
            if ((!weeklyData[row].standard || weeklyData[row].standard === '') && weeklyData[row].standard !== 0) {
                error.push("per_" + row);
            }
            return null;
        })
        if (error) {
            this.setState({
                error: error
            })
            return error
        }
        else {
            return false
        }

    }


    updateWeeklyAdjust = () => {

        let validateError = this.validateWeeklyAdjust();

        if (validateError.length > 0) {
            return;
        }

        let rows = this.state.weeklyData;

        let requestParams = {
            token: this.props.hotelAuthReducer.hotelDetails.token,
            allData: rows,
        }

        let setting = this.props.priceSettingReducer.priceSetting;
        setting.adjustment.weekday = requestParams.allData;


        setting.run_pricing = true;
        setting.fetch_inventary = false;


        let latestJson = ValidateBaseSettings({ dateSetting: setting });
        let reqParams = {
            settings: latestJson.dateSetting,
            token:this.props.hotelAuthReducer.hotelDetails.token
        };
        this.props.storePricingSettings(reqParams)
    }

    handleMonthly = (row) => event => {
        let monthlyData = this.state.monthlyData;


        if (event.target.value != "" && event.target.value != "-") {
            monthlyData[row] = parseFloat(event.target.value);
        } else {
            monthlyData[row] = (event.target.value);
        }

        this.setState({
            monthlyData
        })
    }

    renderMonthlyTable = (limit) => {
        var jsx = [];
        const { classes } = this.props;
        const { t } = this.props;
        let monthlyData = this.state.monthlyData;
        let months = [];

        for (let i = 0; i < 9; i++) {
            months.push(moment().add(i, 'months').format('M'));
        }

        if(monthlyData){
            
            months.map((row, index) => {
                let i = 12 + index
                if (monthlyData[row] === undefined) {
                    monthlyData[row] = "";
                }
                let ShortName = t(moment().add(i, 'months').format('MMM')) + "`" + moment().add(i, 'months').format('YY');
                return jsx.push(
                    <TableCell align="right">
                        <FormControl fullWidth margin="normal" required className={classes.fieldMargin1}>
                            <Input
                                margin="normal"
                                label={t("Value")}
                                type="number"
                                className={classes.textField}
                                onChange={this.handleMonthly(row)}
                                value={monthlyData[row]}
                                placeholder={ShortName}
                                startAdornment={<Typography variant="subtitle1" className={classes.adornment}>{this.state.currencySymbol}</Typography>}
                            />
                        </FormControl>
                    </TableCell>
                );
            });
        }
        return jsx;
    }

    handleWeekly = (row) => event => {
        let weekData = [];
        weekData = this.state.weeklyData;


        if (event.target.value != "" && event.target.value != "-") {
            weekData[row] = parseFloat(event.target.value);
        } else {
            weekData[row] = (event.target.value);
        }

        this.setState({
            weeklyData: weekData
        })
    }

    renderWeeklyTable = () => {
        const { t } = this.props;
        var jsx = [];
        const { classes } = this.props;
        let percentAdorn = <Typography variant="body2" className={classes.adornment}>%</Typography>;
        let keys = ["7", "1", "2", "3", "4", "5", "6"]
        if (this.state.start_monday) {
            keys = ["1", "2", "3", "4", "5", "6", "7"]
        }
        {
            this.state.weeklyData && keys.map((row, index) => {
                return jsx.push(
                    <TableCell key={index} align="right">
                        <FormControl margin="normal" required className={classes.fieldMargin1}>
                            <Input
                                type="number"
                                margin="normal"
                                className={classes.textField}
                                onChange={this.handleWeekly(row)}
                                value={this.state.weeklyData[row]}
                                endAdornment={percentAdorn}
                            />
                            {this.state.error.includes("per_" + row) ? <FormHelperText><span class="error-message">{t('* Please enter the value')}</span></FormHelperText> : ''}
                        </FormControl>
                    </TableCell>
                )
            })
        }
        return jsx;
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;

        
        let allowedMonths = [];

        for (let i = 12; i < 21; i++) {            
            allowedMonths.push(<TableCell className={classes.cellTitle}>{t(moment().add(i, 'months').format('MMM')) + "`" + moment().add(i, 'months').format('YY')}</TableCell>);
        }

        return (
            <>
            <HeaderCard>
                <Grid container>
                    <Grid item >
                        <div className={classes.divStyle} >
                        <Typography variant="h5" className={classes.fontStyle} >
                          {t("After 365 Days")}
                          <CustomTooltip title="" description={t("The Monthly Adjustment is a price-change that is applied for every single e.g. January. If you set January to -5% we will adjust our recommended price 5% lower for every day in January. It enables you to set your seasonal cycle versus your market set. Note that these adjustments do not override pre-set maximums or minimums.")} />
                        </Typography>
                        <DialogContentText align="left" className={classes.helpText}>
                            {t("As we look more than a year into the future, using market prices is less helpful as the data is unreliable. But we still want to give you the possibility to set prices further out. As a solution, we give you the opportunity to set the 'market' prices yourself. These will be replaced by real market data when reliable competitor prices become available.")}<br/>
                            {t("To set your own starting prices, first choose your average price for each month.")}<br/>
                        </DialogContentText>
                        </div>
                    </Grid>
                </Grid>
            </HeaderCard>
                <Card>
                    <CardContent>
                        <div className={classes.divRoot} >
                            <div className={classes.rootMonth} >
                                <div className={classes.dateTableDiv}>
                                    <MuiThemeProvider theme={after365Theme}>
                                        <Typography align={"left"} variant={"h6"} style={{marginBottom:'20px'}}>{t("Monthly Averages")}</Typography>
                                        <Table className={classes.userTable}>
                                            <TableHead>
                                                <TableRow className={classes.rowTable}>
                                                    {allowedMonths}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow key={1} className={classes.rowTable}>
                                                    {this.renderMonthlyTable()}
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </MuiThemeProvider>
                                </div>
                                <div className={classes.adjustmentBtn}>
                                    <Button disabled={this.state.loadDataMonth} className={[classes.saveBtnView, "orangButton"]} variant="contained" color="primary" onClick={() => this.updateMonthlyAdjust()}>
                                        {t("Save")}{this.state.loadDataMonth && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                                     </Button>
                                    <Button disabled={this.state.loadDataMonth} className={[classes.saveBtnView, "orangButton"]} variant="outlined" color="primary" onClick={() => this.setDefaultValues()}>
                                        {t("Set Default")}
                                    </Button>
                                </div>
                                {Warning.warningModal(this)}
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </>
        );
    }
}

after365days.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        userSession: state.sessionReducer,
        hotelDetail: state.hotelSettingReducer.hoteDetail,
        currencyList: state.currencyReducer.currencyList,
        hotelAuthReducer: state.hotelAuthReducer,
        monthlyAdjustReducer: state.monthlyAdjustReducer,
        roomList: state.roomListReducer,
        commonReducer: state.commonReducer,
        priceSettingReducer: state.priceSettingReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getHotelDetail: (request) => { dispatch(actions.getHotelDetail(request)) },
        getDefaultAfterYear: (request) => { dispatch(actions.getDefaultAfterYear(request)) },
        updateMonthlyAdjust: (request) => { dispatch(actions.updateMonthlyAdjust(request)) },
        getPricingSettings: (request) => { dispatch(actions.getPricingSettings(request)) },
        putNotify: (request) => { dispatch(actions.putNotify(request)) },
        storePricingSettings:(request) => {dispatch(actions.storePricingSettings(request))},
        // setRunPriceFlags: (request) => { dispatch(actions.setRunPriceFlags(request)) },

    };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(after365days));
