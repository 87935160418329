var style = {
  RadioGroup: {
    display: "inline-block",
  },
  emailLabel: {
    marginTop: "20px",
  },
  userTable: {
    padding: "20px",
  },
  addUserBtn: {
    float: "left",
    marginBottom: "15px",
    // marginLeft: "20px",
    marginRight: "20px",
    ["@media (max-width: 450px) and (min-width: 360px)"]: {
      margin: "10px 0px 0px 0px",
      float: "left",
      textAlign:'left',
      width: "auto",
    },
  },
  userTableDiv: {
    // marginTop: "20px",
  },
  actionIcon: {
    cursor: "pointer",
    minWidth: "30px",
  },
  textField: {
    minWidth: "240px",
    maxWidth:"400px",
    ["@media (max-width: 540px) and (min-width: 400px)"]: {
      minWidth: "240px",
    },
    ["@media (max-width: 399px) and (min-width: 361px)"]: {
      minWidth: "230px",
    },
    ["@media (max-width: 360px) and (min-width: 321px)"]: {
      minWidth: "215px",
    },
    ["@media (max-width: 320px)"]: {
      minWidth: "170px",
    },
  },
  popupDiv: {
    maxWidth: "750px",
    display: "flex",
    flexDirection: "inherit",
    overflow: "auto",
    marginBottom:"65px"
  },
  MainLoader: {
    position: "fixed",
    height: "100%",
    width: "100%",
    background: "rgb(15, 51, 80, 0.3)",
    zIndex: "9",
  },
  loader: {
    position: "absolute",
    top: "35%",
    left: "35%",
  },
  fieldLeft: {
    ["@media (max-width: 540px) and (min-width: 400px)"]: {
      paddingRight: "0px",
    },
    ["@media (max-width: 399px) and (min-width: 361px)"]: {
      paddingRight: "0px",
      "& label": {
        fontSize: "14px",
      },
      ">div": {
        "& label": { fontSize: "14px" },
      },
      width: "230px",
    },
    ["@media (max-width: 360px) and (min-width: 321px)"]: {
      paddingRight: "0px",
      "& label": {
        fontSize: "14px",
      },
      ">div": {
        "& label": { fontSize: "14px" },
      },
    },
    ["@media (max-width: 320px)"]: {
      paddingRight: "0px",
      "& label": {
        fontSize: "14px",
      },
      ">div": {
        "& label": { fontSize: "14px" },
      },
    },
  },
  refRoomOnly: {
    float: "left",
  },
  paddingTen: {
    padding: "10px 15px 10px 15px",
    textAlign: "center",
  },
  actionCell: {
    padding: "10px 15px 10px 15px",
    textAlign: "left",
  },
  gridContainer: {
    textAlign: "left",
  },
  adornment: {
    color: "rgb(118, 118, 118)",
    paddingRight: "5px",
  },
  halfWidth: {
    width: "50%",
  },
  refRommRow: {
    "& td": { fontWeight: "600 !important" },
  },
  minPriceFC: {
    marginTop: 0,
    width: "150px",
    marginLeft: "50px",
  },
  tableHeading: {
    textAlign: "left",
    marginBottom: "15px",
  },
  updateBtn: {
    margin: "14px 24px 0px",
  },
  addBtnDiv: {
    display: "flow-root",
  },
  lockIcon: {
    cursor: "pointer",
  },
  endadrom: {
    display: "contents",
  },
  endadromspan: {
    width: "20px",
    textAlign: "right",
    cursor: "pointer",
    fontSize: "24px",
    color: "#541388",
  },
  addNewDiv: {
    marginTop: "-70px",
    "@media screen and (max-width: 900px)": {
      minWidth: "100%",
      display: "-webkit-inline-box",
    },
    ["@media (max-width: 414px) and (min-width: 360px)"]: {
      marginTop: "0px",
      display: "inherit",
    },
  },
  minPriceDiv: {
    "@media screen and (max-width: 900px)": {
      minWidth: "100%",
    },
    width: "65%",
  },
  refRoomDiv: {
    width: "100%",
    marginTop: "30px",
    ["@media (max-width: 414px) and (min-width: 360px)"]: {
      marginTop: "15px",
    },
  },
  basicSetupCheck: {
    width: "100%",
    marginTop: "15px",
  },
  settingFields: {
    width: "190px",
    marginRight: "24px",
    minWidth: "190px",
    float: "right",
    maxWidth: "190px",
    ["@media (max-width: 414px) and (min-width: 360px)"]: {
      width: "100%",
      maxWidth: "100%",
    },
  },
  settingFieldsParent: {
    textAlign: 'left',
    ["@media (max-width: 414px) and (min-width: 360px)"]: {
      "& div:nth-child(2)": {
        marginTop: "14px",
        marginLeft: "0px !important",
      },
      "& div": {
        width: "100%",
      },
    },
  },
  htmlTooltip: {
    backgroundColor: "#323338 !important",
    color: "white",
    maxWidth: "250px",
    // border: "1px solid #1976d2",
    opacity: "1",
    filter: "alpha(opacity=100)",
  },
  eventDates: {
    textAlign: "left",
    padding: "5px",
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  eventNameRow: {
    height: "20px",
  },
  eventList: {
    height: "20px",
  },
  eventName: {
    textAlign: "center",

    border: "1px solid rgba(224, 224, 224, 1)",
    backgroundColor: "rgba(224, 224, 224, 1)",
  },
  buttonProgress: {
    position: "absolute",
  },
  cellDateAply: {
    float: "left",
  },
  cellDate: {
    float: "right",
    "& label": {
      ["@media (max-width: 540px) and (min-width: 400px)"]: {
        marginRight: "14px",
      },
      ["@media (max-width: 360px) and (min-width: 321px)"]: {
        marginRight: "14px",
      },
      ["@media (max-width: 320px)"]: {
        marginRight: "14px",
      },
    },
  },
  fullWidth: {
    ["@media (max-width: 414px) and (min-width: 360px)"]: {
      width: "100%",
    },
  },
  absoluteCheckbox: {
    marginLeft: "16px",
    ["@media (max-width: 768px) "]: {
      marginLeft: "0px",
    },
  },
  absoluteCheckbox2: {
    marginLeft: "0px",
    ["@media (max-width: 414px) and (min-width: 360px)"]: {
      marginLeft: "0px",
    },
  },
  settingFieldsParentGrid: {
    // marginBottom: "70px",
    marginTop:'20px',
    ["@media (max-width: 414px) and (min-width: 360px)"]: {
      // marginBottom: "15px",
    },
  },
  absCheckbox: {
    marginTop: "10px",
    "@media (max-width: 547px)": {
        marginRight: '17px'
    }
    
  },
  
  drawerPaper: {
    "@media (max-width: 540px)": {
      width: "100% !important",
      padding: '0px !important'
    },
    "@media (min-width: 540px)": {
      width: "100% !important",
      padding: '0px !important'
    },
    '&:hover':{
      // overflowY:'auto !important'
    },
    padding: "10px 25px 10px 25px",
  },
  buttonGroupBottom: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "20px",
    "@media (max-width: 540px)": {
      float: "left",
    },
    "@media (min-width: 550px)": {
      bottom: 0,
      left: 0,
      position: "absolute",
      marginTop: "10px",
      marginRight: "105px",
      marginBottom: "10px",
    },
  },
  derivedBtn:{
    "@media (max-width: 650px)": {
     margin:"10px"
    }
  },
  derivedCont:{
    paddingBottom: "10px", 
    // marginTop: "1px",
    // marginBottom: "20px", 
    borderBottom: "1px solid #DDD"
  },
  sidePopupFooter:{
    background: "#f1f1f1",
    width: "100%",
    border: "#d6d6d6 1px solid",
    margin: "0px -25px !important",
    paddingLeft: "24px",    
    paddingBottom: "10px",
    "@media (max-width: 540px)": {
      width: "100% !important",
      padding: '10px 25px !important',
      margin: "0px !important",
    },
    "@media (min-width: 540px)": {
      width: "100% !important",
      padding: '10px 25px !important',
      margin: "0px !important",
    },
  },
  closeButtonPrice: {
    position:'absolute',
    right:'0',
    ['@media (max-width: 540px)']: {
      marginTop: "10px",
    }
  },
  btnSecondary:{
    color:'#707070'
  },
  saveSettingBtn:{
    marginTop:"12px"
  },
  divStyle: {
    float: "left", 
    marginTop: "2px"
  },
  fontStyle: {
    textAlign: "left", 
    fontWeight: "500"
  },
  settingLable: {
    marginTop: "-5px"
  },
  settingInputLable: {
    whiteSpace:'nowrap'
  },
  settingInput: {
    width: "188px",
  },
  inputSpan: {
    fontSize: "16px", 
    color: "#a0a2a6", 
    width: "129px", 
    float: "left", 
    marginTop: "2px", 
    whiteSpace: 'nowrap'
  },
  addUserBtn2: {
    whiteSpace:'nowrap' 
  },
  spanMap: {
    color: "#d32f2f"
  },
  cellAign: {
    textAlign: "right"
  },
  cellAign2: {
    textAlign: ""
  },
  yieldingTagsValue: {
    fontWeight: "400",
    margin: "2px",
    padding: "7px 14px 7px 14px",
    color: "#fff",
    maxWidth: "95px",
    display: "inline-block",
    borderRadius: "25px",
    textAlign: "",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }
};
export default style;
