import React, { Component } from 'react';
import { connect } from "react-redux";

var contactForm = null;
class ContactUsNew extends Component {
  alertOptions = {
    offset: 50,
    position: 'top center',
    theme: 'dark',
    time: 0,
    transition: 'scale'
  }
  constructor(props) {
    super(props)
    this.state = {
      InRequest: false,
      InPostRequest: false,
      full_name: ((this.props.userSession.user.first_name) ? this.props.userSession.user.first_name : '') + ' ' + ((this.props.userSession.user.last_name) ? this.props.userSession.user.last_name : ''),
      email: ((this.props.userSession.user.email) ? this.props.userSession.user.email : ''),
    }
    window.scrollTo(0, 0);
  }

  componentDidMount() {

    contactForm = window.hbspt.forms.create({
      portalId: "5641770",
      formId: "67e91aba-55a0-4ac9-9676-48c6a81f11bc"
    })
    contactForm.onReady(() => {      
      var elem = document.getElementsByClassName("hbspt-form")[0];
      elem.style.marginLeft = "260px";
      elem.style.marginTop = "-90px";
      elem.style.padding = "14px 14px 0px 14px";
      elem.style.background = "white";
      elem.style.borderRadius = "16px";
      elem.style.marginRight = "20px";
      
      contactForm.setFieldValue("email", this.props.userSession.user.email)
      contactForm.setFieldValue("firstname", this.props.userSession.user.first_name)
      contactForm.setFieldValue("lastname", this.props.userSession.user.last_name)
    })
  }

  componentWillUnmount() {
    var elem = document.getElementsByClassName("hbspt-form")[0];
    elem.parentNode.removeChild(elem);
  }

  render() {
    return (
      <div className="animated fadeIn">
        <div className="row">
          <div id="contactForm" className="col-md-12 card" style={{ textAlign: 'left' }}>
            {/* <iframe name="contactUs" title="contact us" style={{ 'border': 'none' }} src={"/iframe/contact_form.html?name=" + this.state.full_name + "&email=" + this.state.email} width="100%" height="500px"></iframe> */}
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    userSession: state.sessionReducer,
    hotelDetail: state.hotelSettingReducer.hoteDetail,
    hotelAuthReducer: state.hotelAuthReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    RunRedux: (data) => {
      dispatch(data);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsNew);