var style = {
  logCard: {
    marginTop: 30,
  },
  gridContainer: {
    marginLeft: "20px",
  },
  filter: {
    textAlign: "right",
    marginRight: "20px",
  },
  formControl: {
    marginLeft: "15px",
  },
  divStyle: {
    float: "left", 
    marginTop: "2px"
  },
  fontStyle: {
    textAlign: "left", 
    fontWeight: "500"
  },
  btnUpdate: {
    margin:"12px", 
    fontWeight:"500"
  }
};
export default style;
