import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import internalPrameterCss from './css/internalPrameterCss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../../store/actions/index';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withNamespaces, Trans } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import purple from '@material-ui/core/colors/purple';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogContentText from '@material-ui/core/DialogContentText';

const styles = internalPrameterCss;

const elasticityList = [
    { id: 1, label: "Ignore Occupancy Changes", value: -1000 },
    { id: 2, label: "Very Low", value: -12 },
    { id: 3, label: "Low", value: -8 },
    { id: 4, label: "Standard", value: -5.5 },
    { id: 5, label: "High", value: -4.25 },
    { id: 6, label: "Very High", value: -3.25 },
    // { id: 7, label: "Super High", value: -2 }
]

class InternalParamerter extends React.Component {

    state = {
        dataModal: false,
        error: [],
        customWeekday: false,
        customweekend: false,
        dateSetting: null,
        median_lead_time: 8,
        featuresEnabled: [],
        customLeadTime: false,
        reduceLastDay:false,
        room_apartment_space_name: "",
        isLoad:true
    }
    componentWillMount(){
        if (this.props.priceSettingReducer.priceSetting) {
            this.setState({ reduceLastDay: this.props.priceSettingReducer.priceSetting.rpg_arguments.do_not_reduce_last_day})
        }
        if (this.props.priceSettingReducer && this.props.priceSettingReducer.priceSetting){
            if(this.state.isLoad) {
            let adjustJson = this.props.priceSettingReducer.priceSetting.rpg_arguments;
            let dataModalList = {};
            dataModalList.elasticity_weekdays = adjustJson.PELL_weekday;
            dataModalList.elasticity_weekend = adjustJson.PELL_weekend;
            let weekday = elasticityList.filter(val => val.value == adjustJson.PELL_weekday);
            let weekend = elasticityList.filter(val => val.value == adjustJson.PELL_weekend);
            this.setState({
                dataModal: dataModalList,
                isLoad:false,
                featuresEnabled: this.props.priceSettingReducer.priceSetting.features,
                median_lead_time: this.props.priceSettingReducer.priceSetting.rpg_arguments.median_lead_time,
                customweekend: weekend.length == 0 ? true:false,
                customWeekday: weekday.length == 0 ? true : false,
                })
            }
            // if (this.props.priceSettingReducer.priceSetting.rpg_arguments.do_not_reduce_last_day !== this.props.priceSettingReducer.priceSetting.rpg_arguments.do_not_reduce_last_day ){
                this.setState({ reduceLastDay: this.props.priceSettingReducer.priceSetting.rpg_arguments.do_not_reduce_last_day })
                // }
                if(this.props.hotelAuthReducer.hotelDetails.hotel){          
                    this.setState({               
                        room_apartment_space_name: this.props.hotelAuthReducer.hotelDetails.hotel.room_apartment_space_name
                    })
                }
            }
    }
    componentWillReceiveProps(props) {
        if (props.commonReducer.notify) {
            if (props.commonReducer.notify.message) {
                this.setState({
                    loadData: false
                })
                this.props.updateFromAggress(false)
            }
        }
    }

    toggleCustomWeekday = () => {
        this.setState({
            customWeekday: !this.state.customWeekday
        })
    }

    toggleCustomWeekends = () => {
        this.setState({
            customweekend: !this.state.customweekend
        })
    }

    toggleReduceLastDay= (e) => {        
        this.setState({
            reduceLastDay: e.target.checked})
    }
    validateForm = () => {

        let error = [];

        if (this.state.dataModal.elasticity_weekdays == null || this.state.dataModal.elasticity_weekdays == '') {
            error.push('elasticity_weekdays')
        }

        if (this.state.dataModal.elasticity_weekend == null || this.state.dataModal.elasticity_weekend == '') {
            error.push('elasticity_weekend')
        }


        if (error) {
            this.setState({
                error: error
            })
            return error
        }
        else {
            return false
        }
    }

    updateTimeToBooking = () => {

        let validateError = this.validateForm();

        if (validateError.length > 0) {
            return;
        }

        this.setState({
            loadData: true
        })
        this.props.updateFromAggress(true)
        this.updateAdjustment()

    }

    updateAdjustment = () => {
        let requestParam = {
            token: this.props.hotelAuthReducer.hotelDetails.token,
            median_lead_time: this.state.median_lead_time,
            do_not_reduce_last_day: this.state.reduceLastDay,
            dataModal: {
                ...this.state.dataModal
            },
        }
        this.props.adjustmentUpdate(requestParam);
    }

    handledataModal = (name) => event => {
        this.setState({
            dataModal: {
                ...this.state.dataModal,
                [name]: event.target.value,
            }
        });
    }

    handledataModalunit = (name) => event => {
        this.setState({
            dataModal: {
                ...this.state.dataModal,
                round_price_to_nearest_unit: !this.state.dataModal.round_price_to_nearest_unit,
            }
        });
    }

    handleState = (name) => event => {
        this.setState({
            [name]: event.target.value,
        });
    }
    componentDidUpdate(prevProps){
    if(this.props.isUpdateAggress !==prevProps.isUpdateAggress){
        if(this.props.isUpdateAggress){
            this.updateTimeToBooking()
            }
        }
    }


    render() {
        const { classes } = this.props;
        const { t } = this.props;
        let width = window.screen.width;
        return (
        <>
        {this.state.dataModal &&
                <div className={classes.fieldContainers}>
                    <DialogContentText align="left" className={classes.helpText}>
                        {t("The aggressiveness allows you to define how strongly the system should react in case of unusual demand changes. When you receive many bookings for an individual day, a High aggressiveness causes prices to increase more than a Low aggressiveness.")} <br />
                    </DialogContentText>

                    <div className={this.state.featuresEnabled.includes(2) ? classes.textFieldDiv : classes.textFieldDivUp}>
                        {this.state.customWeekday ?
                            <FormControl margin="normal" required>
                                <InputLabel shrink={true} htmlFor="price">{t("Aggressiveness Weekdays")}</InputLabel>
                                <Input
                                    style={{ textAlign: 'center', }}
                                    className={classes.textFieldSelect}
                                    value={this.state.dataModal.elasticity_weekdays}
                                    onChange={this.handledataModal('elasticity_weekdays')}
                                    disabled={!(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type)}
                                />
                            </FormControl>
                            :
                            <FormControl margin="normal" required>
                                <InputLabel shrink={true} htmlFor="price">{t("Aggressiveness Weekdays")}</InputLabel>
                                <Select
                                    inputProps={{ style: { textAlign: 'left' } }}
                                    error={this.state.error.includes("elasticity_weekdays")}
                                    value={this.state.dataModal.elasticity_weekdays}
                                    onChange={this.handledataModal('elasticity_weekdays')}
                                    className={classes.textFieldSelect}
                                >
                                    {elasticityList.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {t(option.label)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        }
                        {(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) &&
                            <FormControlLabel
                                className={classes.customCheckbox}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={this.state.customWeekday}
                                        onChange={() => this.toggleCustomWeekday()}
                                    />
                                }
                                label={t("Custom Value")}
                            />
                        }
                    </div>
                    <div className={classes.textFieldDiv}>
                        {this.state.customweekend ?
                            <FormControl margin="normal" required>
                                <InputLabel shrink={true} htmlFor="price">{t("Aggressiveness Weekends")}</InputLabel>
                                <Input
                                    style={{ textAlign: 'center', }}
                                    className={classes.textFieldSelect}
                                    value={this.state.dataModal.elasticity_weekend}
                                    onChange={this.handledataModal('elasticity_weekend')}
                                    disabled={!(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type)}
                                />
                            </FormControl>
                            :
                            <FormControl margin="normal" required>
                                <InputLabel shrink={true} htmlFor="price">{t("Aggressiveness Weekends")}</InputLabel>
                                <Select
                                    inputProps={{ style: { textAlign: 'left' } }}
                                    error={this.state.error.includes("elasticity_weekend")}
                                    className={classes.textFieldSelect}
                                    onChange={this.handledataModal('elasticity_weekend')}
                                    value={this.state.dataModal.elasticity_weekend}
                                >
                                    {elasticityList.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                             {t(option.label)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        }

                        {(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) &&
                            <FormControlLabel
                                className={classes.customCheckbox}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={this.state.customweekend}
                                        onChange={() => this.toggleCustomWeekends()}
                                    />
                                }
                                label={t("Custom Value")}
                            />
                        }
                    </div>
                    <br />
                    <DialogContentText align="left" className={classes.helpText2}>
                        {t(`As default setting, RoomPriceGenie will be reducing prices on the last day as the hours count down. The closer it gets to end-of-day the more we will reduce as the time is shorter. The idea is to try to sell those last ${this.state.room_apartment_space_name.toLowerCase()}s to last minute bookers. Some clients do not wish to do this, and if you turn it off, we will stop the reduction with one day remaining.`)}
                    </DialogContentText>
                    <div className={classes.textFieldDiv}>
                    <FormControlLabel 
                    style={{margin:"0px 0px 25px -14px"}}
                        className={classes.checkbox}
                        control={
                            <Checkbox
                                color="primary"
                                checked={this.state.reduceLastDay}
                                onChange={(e) => this.toggleReduceLastDay(e)}
                            />
                        }
                        label={t("Do not keep reducing prices on the last day")}
                    />
                    </div>
                    </div>

            }
        </>
        );
    }
}

InternalParamerter.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        userSession: state.sessionReducer,
        hotelAuthReducer: state.hotelAuthReducer,
        aggresiveList: state.aggresiveReducer,
        timeBookingReducer: state.timeBookingReducer,
        adjustmentReducer: state.adjustmentReducer,
        dataModalReducer: state.dataModalReducer,
        priceSettingReducer: state.priceSettingReducer,
        featuresReducer: state.featuresReducer,
        commonReducer: state.commonReducer,
        hotelDetailReducer: state.hotelSettingReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        adjustmentUpdate: (request) => { dispatch(actions.adjustmentUpdate(request)) },
        getPricingSettings: (request) => { dispatch(actions.getPricingSettings(request)) },
    };
};


export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(InternalParamerter));