import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = [];

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ERROR_TICKETS_START:
            return updateObject(state, { loading: true, error: null });
        case actionTypes.GET_ERROR_TICKETS_SUCCESS:
            return updateObject(state, { loading: false, error: null, errorTickets: action.state })
        case actionTypes.GET_ERROR_TICKETS_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.GET_RESERVATION_ERROR_TICKETS_SUCCESS:
            return updateObject(state, { loading: false, error: null, reservationErrorTickets: action.state })
        case actionTypes.GET_OTHERS_ERROR_TICKETS_SUCCESS:
            return updateObject(state, { loading: false, error: null, othersErrorTickets: action.state })
        case actionTypes.GET_CRITICAL_ERROR_TICKETS_SUCCESS:
            return updateObject(state, { loading: false, error: null, criticalErrorTickets: action.state })
        default:
            return updateObject(state, { loading: false, error: null });
    }
}

export default reducer
