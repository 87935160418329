import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = {
    token: false,
    hotel_list: [],
    user: {
        do_not_call: null,
        email: null,
        first_name: null,
        id: null,
        is_staff: null,
        last_name: null,
        notes: null,
        payment_account_id: null,
    }
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.LOGOUT:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.LOGIN_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.LOGIN_SUCCEED:
            
            return updateObject(state, { loading: false, error: null, UserData: action.state, is_otp_required: action.is_otp_required })
        case actionTypes.VERIFIED_OTP_SUCCEED:
            return updateObject(state, { loading: false, error: null, VerifyOTP: action.state })
        case actionTypes.LOGIN_FAILED:
            let error = "";
            if(action.error && action.error.response && action.error.response.data && action.error.response.data.non_field_errors){
                error = action.error.response.data.non_field_errors[0];
            }
            return updateObject(state, { loading: false, error: error })
        case actionTypes.BOOKINGSUIT_TEST_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.BOOKINGSUIT_TEST_SUCCEED:
            window.location.reload()
            return updateObject(state, { loading: false, error: null, UserData: action.state })
        case actionTypes.BOOKINGSUIT_TEST_FAILED:
            return updateObject(state, { loading: false, error: 'Invalid Credentials' })
        default:
            return state;
    }
}

export default reducer
