import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import UserCss from './css/userSettingCss';
import Modals from './modal/modal';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../../store/actions/index';
import MUIDataTable from "mui-datatables";
import { Icon } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { UserSettingTheme } from '../../muiTheme';
import Divider from '@material-ui/core/Divider';
import { withNamespaces, Trans } from 'react-i18next';
import HeaderCard from '../HeaderCard';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = UserCss;

let UserList = [];
class UserSetting extends React.Component {
    state = {
        inviteUserModal: false,
        page: 0,
        userLevel: null,
        managerList: [],
        adminList: [],
        staffList: [],
        open: false,
        editUserModal: false,
        showEdit: false,
        showDelete: false,
        addNewUser: false,
        deleteUserModalOpen: false,
        addNewModal: {
            username: "",
            password: "",
            first_name: "",
            last_name: "",
            level: "",
            selectedHotelList: [],
            hotelList: this.props.hotelAuthReducer.hotelDetails.hotel_list ? this.props.hotelAuthReducer.hotelDetails.hotel_list : [],
            LoginUserToken: this.props.userSession.token,
            language:"en"
        },
        updateUserModal: {
            username: "",
            first_name: "",
            last_name: "",
            level: "",
            selectedHotelList: [],
            oldHotelList: [],
            userId: '',
            hotelList: this.props.hotelAuthReducer.hotelDetails.hotel_list ? this.props.hotelAuthReducer.hotelDetails.hotel_list : [],
            LoginUserToken: this.props.userSession.token,
            language: null
        },
        error: [],
        pandingUsersList:[]
    };

    resetAddModel = () => {
        this.setState({
            isAllAdmin: false,
            addNewUser: {
                username: "",
                password: "",
                first_name: "",
                last_name: "",
                level: "",
                selectedHotelList: [],
                hotelList: this.props.hotelAuthReducer.hotelDetails.hotel_list ? this.props.hotelAuthReducer.hotelDetails.hotel_list : [],
                LoginUserToken: this.props.userSession.token
            }
        });
    }

    toggleDeleteUser = (user) => {
        let a = {
            token: this.props.hotelAuthReducer.hotelDetails.token,
            id: user.user_id
        }

        this.props.changeRole(a);
        let isAssigned = 1;
        let hotelId = 0;
        if (user) {
            isAssigned = user.assigned_hotels.filter(val => val.hotel.id == this.props.hotelAuthReducer.hotelDetails.hotel.id).length;
            hotelId = user.assigned_hotels[0].hotel.id;
        }
        this.setState({
            deleteUserModalOpen: !this.state.deleteUserModalOpen,
            userId: (user) ? user.user_id : '',
            user_type: (user) ? user.type : '',
            title: (user) ? user.user_name : '',
            isAssigned: isAssigned,
            deleteAssigned: hotelId,
            showDelete: true,
        }, () => {
            this.setState({
                showEdit: false
            })
        })
    }
    closeDeleteModel = () => {
        this.setState({
            deleteUserModalOpen: false
        })
    }
    deleteUser = () => {

        let requestParams = {
            token: this.props.hotelAuthReducer.hotelDetails.token,
            id: this.state.userId,
            isAssigned: this.state.isAssigned,
            LoginUserToken: this.props.userSession.token,
            hotelId: this.state.deleteAssigned
        };

        if (this.state.user_type === 'Admin') {
            this.props.deleteClientAdminUser(requestParams)
            this.setState({ deleteUserModalOpen: !this.state.deleteUserModalOpen });
        } else if (this.state.user_type === 'Manager') {
            this.props.deleteManagerUser(requestParams)
            this.setState({ deleteUserModalOpen: !this.state.deleteUserModalOpen });
        } else {
            this.props.deleteStaffUser(requestParams)
            this.setState({ deleteUserModalOpen: !this.state.deleteUserModalOpen });
        }

    }

    _getUserData = () => {
        let requestParam = { token: this.props.hotelAuthReducer.hotelDetails.token }
        this.props.getClientAdminList(requestParam);
        this.props.getManagerList(requestParam);
        this.props.getStaffList(requestParam);
        this.props.getOwner(requestParam);
        this.props.pandingUsers(requestParam)
    }

    toggleAddNewUserModal = () => {
        if (this.state.addNewUser) {
            this.resetAddModel();
        }
        this.setState({
            addNewUser: !this.state.addNewUser,
            isAllAdmin: false,
            addNewModal: {
                username: "",
                password: "",
                first_name: "",
                last_name: "",
                level: "",
                selectedHotelList: [],
                hotelList: this.props.hotelAuthReducer.hotelDetails.hotel_list ? this.props.hotelAuthReducer.hotelDetails.hotel_list : [],
                LoginUserToken: this.props.userSession.token,
                language:""
            }
        });
    }

    componentWillMount() {
        this._getUserData();
    }

    componentWillReceiveProps(props) {

        if (props.clientAdminListReducer.clientAdminList && props.managerListReducer.managerList && props.staffListReducer.staffList) {

            UserList = [];
            if (props.clientAdminListReducer.owner) {
                let client = props.clientAdminListReducer.owner;
                UserList.push([client.first_name + ' ' + client.last_name, client.email, 'Admin', client.assigned_hotels, { email: client.email, type: 'Admin', user_id: client.id, user_name: client.first_name + ' ' + client.last_name, assigned_hotels: client.assigned_hotels }]);
            }

            let MasterAdmin = [];
            let MasterManager = []
            let MasterStaff = [];
            if (props.clientAdminListReducer.clientAdminList.assigned) {
                props.clientAdminListReducer.clientAdminList.assigned.map((row, index) => {
                    MasterAdmin.push(row.user);
                    let user = [row.user.first_name + ' ' + row.user.last_name, row.user.email, 'Admin', row.user.assigned_hotels, { email: row.user.email, type: 'Admin', user_id: row.user.id, user_name: row.user.first_name + ' ' + row.user.last_name, assigned_hotels: row.user.assigned_hotels }];
                    UserList.push(user);
                    return ('');
                })
            }

            if (props.clientAdminListReducer.clientAdminList.unassigned) {
                props.clientAdminListReducer.clientAdminList.unassigned.map((row, index) => {
                    if (row.assigned_hotels.length > 0) {
                        if (row.assigned_hotels[0].level == 3) {
                            MasterAdmin.push(row);
                            let user = [row.first_name + ' ' + row.last_name, row.email, 'Admin', row.assigned_hotels, { email: row.email, type: 'Admin', user_id: row.id, user_name: row.first_name + ' ' + row.last_name, assigned_hotels: row.assigned_hotels, }];
                            UserList.push(user);
                            return ('');
                        }

                        if (row.assigned_hotels[0].level == 1) {
                            MasterManager.push(row)
                            let user = [row.first_name + ' ' + row.last_name, row.email, 'Manager', row.assigned_hotels, { email: row.email, type: 'Manager', user_id: row.id, user_name: row.first_name + ' ' + row.last_name, assigned_hotels: row.assigned_hotels }];
                            UserList.push(user);
                            return ('')
                        }

                        if (row.assigned_hotels[0].level == 2) {
                            MasterStaff.push(row)
                            let user = [row.first_name + ' ' + row.last_name, row.email, 'Staff', row.assigned_hotels, { email: row.email, type: 'Staff', user_id: row.id, user_name: row.first_name + ' ' + row.last_name, assigned_hotels: row.assigned_hotels }];
                            UserList.push(user);
                            return ('')
                        }
                    }
                })
            }



            if (props.managerListReducer.managerList.assigned) {
                props.managerListReducer.managerList.assigned.map((row, index) => {
                    MasterManager.push(row.user)
                    let user = [row.user.first_name + ' ' + row.user.last_name, row.user.email, 'Manager', row.user.assigned_hotels, { email: row.user.email, type: 'Manager', user_id: row.user.id, user_name: row.user.first_name + ' ' + row.user.last_name, assigned_hotels: row.user.assigned_hotels }];
                    UserList.push(user);
                    return ('')
                })
            }

            if (props.staffListReducer.staffList.assigned) {
                props.staffListReducer.staffList.assigned.map((row, index) => {
                    MasterStaff.push(row.user)
                    let user = [row.user.first_name + ' ' + row.user.last_name, row.user.email, 'Staff', row.user.assigned_hotels, { email: row.user.email, type: 'Staff', user_id: row.user.id, user_name: row.user.first_name + ' ' + row.user.last_name, assigned_hotels: row.user.assigned_hotels }];
                    UserList.push(user);
                    return ('')
                })
            }

            this.setState({
                adminList: MasterAdmin,
                managerList: MasterManager,
                staffList: MasterStaff
            })

        }

        if (props.clientAdminListReducer.created || props.managerListReducer.created || props.staffListReducer.created) {
            this.setState({
                page: 0
            })
        }

        if (props.clientAdminListReducer.deleted || props.managerListReducer.deleted || props.staffListReducer.deleted) {
            for (var i = 0; i < UserList.length; i++) {
                if (UserList[i][4].user_id === this.state.userId) {
                    UserList.splice(i, 1);
                }
            }
        }

        if (props.clientAdminListReducer.updated || props.managerListReducer.updated || props.staffListReducer.updated) {

            for (var i = 0; i < UserList.length; i++) {
                if (UserList[i][4].user_id === this.state.updateUserModal.userId) {
                    UserList[i][0] = this.state.updateUserModal.first_name + ' ' + this.state.updateUserModal.last_name;
                    UserList[i][1] = this.state.updateUserModal.username;

                    let selectedHotelsList = [];
                    for (var k = 0; k < this.state.updateUserModal.hotelList.length; k++) {
                        for (var j = 0; j < this.state.updateUserModal.selectedHotelList.length; j++) {
                            if (this.state.updateUserModal.hotelList[k].id === this.state.updateUserModal.selectedHotelList[j]) {
                                let hotel = { id: 0, level: this.state.updateUserModal.level, hotel: this.state.updateUserModal.hotelList[k] }
                                selectedHotelsList.push(hotel);
                            }
                        }
                    }

                    UserList[i][3] = selectedHotelsList;
                }
            }

        }

        if (props.hotelAuthReducer.hotelDetails && (props.hotelAuthReducer.hotelDetails.access_level !== this.state.userLevel)) {
            this.setState({
                userLevel: props.hotelAuthReducer.hotelDetails.access_level,
            })
        }
        if(props.clientAdminListReducer && props.clientAdminListReducer.pandingUsers){
            this.setState({
                pandingUsersList:props.clientAdminListReducer.pandingUsers
            })
        }
    }

    handleAddNew = (name) => event => {
        this.setState({
            addNewModal: {
                ...this.state.addNewModal,
                [name]: event.target.value,
            }
        });
    }

    handleHotelSelect = (checked, id) => {

        let jsx = this.state.addNewModal.selectedHotelList;
        if (checked) {
            jsx.push(id)
        }
        else {
            jsx = jsx.filter(val => val !== id);
        }
        this.setState({
            addNewModal: {
                ...this.state.addNewModal,
                selectedHotelList: jsx,
            }
        });
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    closeUpdateModal = () => {
        this.setState({
            editUserModal: false,
        })
    }

    handleUpdateModal = (name) => event => {
        this.setState({
            updateUserModal: {
                ...this.state.updateUserModal,
                [name]: event.target.value,
            }
        })
    }

    handleUpdateModalHotels = (checked, id) => {
        let jsx = this.state.updateUserModal.selectedHotelList;
        if (checked) {
            jsx.push(id);
        } else {
            jsx = jsx.filter(val => val !== id);
        }

        this.setState({
            updateUserModal: {
                ...this.state.updateUserModal,
                selectedHotelList: jsx,
            }
        })
    }


    validateEditUserForm = (data) => {
        let error = [];
        if (data) {

            if (!this.state.isAllAdmin && (!this.state.updateUserModal.level || this.state.updateUserModal.level === '')) {
                error.push('edit_level')
            }
            if (!this.state.updateUserModal.username || this.state.updateUserModal.username === '') {
                error.push('edit_username')
            }
            if (this.state.updateUserModal.username) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!re.test(String(this.state.updateUserModal.username).toLowerCase())) {
                    error.push('edit_username')
                }
            }
            if (!this.state.updateUserModal.first_name || this.state.updateUserModal.first_name === '') {
                error.push('edit_first_name')
            }
            if (!this.state.updateUserModal.last_name || this.state.updateUserModal.last_name === '') {
                error.push('edit_last_name')
            }
            if (!this.state.updateUserModal.language || this.state.updateUserModal.language === '') {
                error.push('language')
            }
            if (!this.state.isAllAdmin && (!this.state.updateUserModal.selectedHotelList || this.state.updateUserModal.selectedHotelList.length === 0)) {
                error.push('edit_selectedHotelList')
            }

            if (error) {
                this.setState({
                    error: error
                })
                return error
            } else {
                return false
            }
        } else {
            return true;
        }
    }

    UpdateHotel = () => {

        let validateError = this.validateEditUserForm(this.state.updateUserModal);
        if (validateError.length > 0) {
            return;
        }

        let requestParam = {
            ...this.state.updateUserModal,
            token: this.props.hotelAuthReducer.hotelDetails.token
        }
        if (requestParam.level === "1") {
            let deleteArray = [];
            requestParam.oldHotelList.map((row) => {
                if (!requestParam.selectedHotelList.includes(row)) {
                    let userData = this.state.managerList.filter(list => list.id === requestParam.userId);
                    let deleteData = userData[0].assigned_hotels.filter(val => val.hotel.id === row)
                    deleteArray.push(deleteData[0])
                }
                return ('')
            })
            requestParam = {
                ...requestParam,
                deleteArray: deleteArray
            }

            this.props.updateManager(requestParam)
        } else if (requestParam.level === "2") {
            let deleteArray = [];
            requestParam.oldHotelList.map((row) => {
                if (!requestParam.selectedHotelList.includes(row)) {
                    let userData = this.state.staffList.filter(list => list.id === requestParam.userId);
                    let deleteData = userData[0].assigned_hotels.filter(val => val.hotel.id === row)
                    deleteArray.push(deleteData[0])
                }
                return null;
            })
            requestParam = {
                ...requestParam,
                deleteArray: deleteArray
            }

            this.props.updateStaff(requestParam)
        } else if (requestParam.level === "3") {
            let deleteArray = [];
            requestParam.oldHotelList.map((row) => {
                if (!requestParam.selectedHotelList.includes(row)) {
                    let userData = this.state.adminList.filter(list => list.id === requestParam.userId);
                    let deleteData = userData[0].assigned_hotels.filter(val => val.hotel.id === row)
                    deleteArray.push(deleteData[0])
                }
                return null;
            })
            requestParam = {
                ...requestParam,
                deleteArray: deleteArray,
                token: this.props.hotelAuthReducer.hotelDetails.token
            }
            if (this.state.isAllAdmin) {
                this.props.updateClientAllAdmin(requestParam)
            } else {
                this.props.updateClientAdmin(requestParam)
            }

        }

        this.setState({
            editUserModal: false
        })
    }

    toggleUpdate = (email, type) => {
        let updateData = {};
        let level = '';
        let accountOwner = {};

        if (type === 'Manager') {
            updateData = this.state.managerList.filter(row => row.email === email);
            level = "1";
        } else if (type === 'Admin') {
            updateData = this.state.adminList.filter(row => row.email === email);
            level = "3";
            if (updateData.length == 0) {
                accountOwner = this.props.clientAdminListReducer.owner
            }
        } else if (type === 'Staff') {
            updateData = this.state.staffList.filter(row => row.email === email);
            level = "2";
        }

        if (accountOwner.email) {
            this.setState({
                updateUserModal: {
                    ...this.state.updateUserModal,
                    username: accountOwner.email,
                    first_name: accountOwner.first_name,
                    last_name: accountOwner.last_name,
                    language: accountOwner.language,
                    level: "3",
                    selectedHotelList: [],
                    oldHotelList: [],
                    userId: accountOwner.id,
                    title: accountOwner.first_name + ' ' + accountOwner.last_name,
                },
                editUserModal: true,
                showEdit: true,
                isAllAdmin: true
            })
        } else {
            updateData = updateData[0];
            let hotels = [];
            updateData.assigned_hotels.map((val) => {
                hotels.push(val.hotel.id);
                return null;
            })

            let old_hotels = [];
            updateData.assigned_hotels.map((val) => {
                old_hotels.push(val.hotel.id);
                return null;
            })
            this.setState({
                updateUserModal: {
                    ...this.state.updateUserModal,
                    username: updateData.email,
                    first_name: updateData.first_name,
                    language: updateData.language,
                    last_name: updateData.last_name,
                    level: level,
                    selectedHotelList: hotels,
                    oldHotelList: hotels,
                    userId: updateData.id,
                    title: updateData.first_name + ' ' + updateData.last_name,

                },
                editUserModal: true,
                showEdit: true,
                isAllAdmin: false
            })

        }
    }

    validateUserForm = (data) => {
        let error = [];
        if (data) {

            if (!this.state.addNewModal.level || this.state.addNewModal.level === '') {
                error.push('level')
            }
            if (!this.state.addNewModal.username || this.state.addNewModal.username === '') {
                error.push('username')
            }
            if (this.state.addNewModal.username) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!re.test(String(this.state.addNewModal.username).toLowerCase())) {
                    error.push('username')
                }
            }
            if (!this.state.addNewModal.password || this.state.addNewModal.password === '') {
                error.push('password')
            }
            if (!this.state.addNewModal.first_name || this.state.addNewModal.first_name === '') {
                error.push('first_name')
            }
            if (!this.state.addNewModal.last_name || this.state.addNewModal.last_name === '') {
                error.push('last_name')
            }
            if (!this.state.addNewModal.selectedHotelList || this.state.addNewModal.selectedHotelList.length === 0) {
                error.push('selectedHotelList')
            }

            if (error) {
                this.setState({
                    error: error
                })
                return error
            } else {
                return false
            }
        } else {
            return true;
        }
    }

    saveNewUser = () => {

        let validateError = this.validateUserForm(this.state.addNewModal);
        if (validateError.length > 0) {
            return;
        }


        let requestParam = {
            ...this.state.addNewModal,
            token: this.props.hotelAuthReducer.hotelDetails.token
        }
        if (this.state.addNewModal.level === "1") {
            this.props.managerCreate(requestParam)
        } else if (this.state.addNewModal.level === "2") {
            this.props.staffCreate(requestParam)
        } else if (this.state.addNewModal.level === "3") {
            this.props.clientAdminCreate(requestParam)
        }

        this.toggleAddNewUserModal();
        setTimeout(() => { this._getUserData(); }, 3000);
    }

    _changePage = (pageState) => {
        this.setState({
            page: pageState.page
        })
    };

    toggleInviteUser = () => {
        this.setState({
            inviteUserModal: !this.state.inviteUserModal
        })
    }

    inviteUser = () => {
        let error = [];

        
        if (!this.state.addNewModal.username) {
            error.push('username')
        }

        if (this.state.addNewModal.username) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(String(this.state.addNewModal.username).toLowerCase())) {
                error.push('username')
            }
        }

        if (!this.state.addNewModal.selectedHotelList || this.state.addNewModal.selectedHotelList.length === 0) {
            error.push('selectedHotelList')
        }

        if ((!this.state.addNewModal.level || this.state.addNewModal.level === '' ) && (this.state.userLevel == 1)) {
            error.push('level')
        }
        if (!this.state.addNewModal.language || this.state.addNewModal.language === '') {
            error.push('language')
        }
        let hotelList = {};
        this.state.addNewModal.hotelList.map(val=>{
            if(this.state.addNewModal.selectedHotelList.includes(val.id)){
                hotelList[val.id] = val.name;
            }
        })
        if (error.length == 0) {
            let request = {
                email: this.state.addNewModal.username,
                user_type: this.state.userLevel == 2 ? 2 : this.state.addNewModal.level,
                language: this.state.addNewModal.language,
                property_list: hotelList,
                token: this.props.hotelAuthReducer.hotelDetails.token,
            }
            
                
            this.props.inviteHotelUSer(request)
            this.setState({
                inviteUserModal: false,
                addNewModal: {
                    username: "",
                    password: "",
                    first_name: "",
                    last_name: "",
                    level: "",
                    selectedHotelList: [],
                    hotelList: this.props.hotelAuthReducer.hotelDetails.hotel_list ? this.props.hotelAuthReducer.hotelDetails.hotel_list : [],
                    LoginUserToken: this.props.userSession.token,
                    language:""
                }
            })
        }

        this.setState({
            error
        })
    }
    pandingUsersData=()=>{
        let data=this.state.pandingUsersList.length > 0 ? this.state.pandingUsersList :[]
        let jsx = [];
        this.state.pandingUsersList.length > 0 && data.map((val) => {
            jsx.push([
                val.email,
                val.user_type == 1 ?'Manager': val.user_type==2?'Staff':'Admin',
                val.property_list,
                val
            ])
            return null;
        })
        return jsx;
    }
    toggleResendInvitation = (val) => {

        let request = {
            email: val.email,
            user_type: val.user_type,
            language: val.language,
            property_list: val.property_list,
            token: this.props.hotelAuthReducer.hotelDetails.token,
        }            
        this.props.inviteHotelUSer(request)
        
    }
    render() {
        const { classes } = this.props;
        const { t } = this.props;

        const columns = [
            {
                name: t("Name"),
                options: {
                    filter: true,
                    sortDirection: 'asc'
                }
            },
            {
                name: t("Email"),
                options: {
                    filter: true
                }
            },
            {
                name: t("Role"),
                options: {
                    filter: true
                }
            },
            {
                name: t("Access to Hotels"),
                options: {
                    filter: false,
                    download: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let hotels = [];
                        if (!value) {
                            return 'All Group Hotels'
                        }
                        for (var i = 0; i < value.length; i++) {
                            hotels.push(value[i].hotel.name);
                        }
                        return <span> {hotels.join(", ")} </span>
                    }
                }
            },
            {
                name: t("Delete"),
                options: {
                    filter: false,
                    download: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const { classes } = this.props;
                        if (value.assigned_hotels) {
                            return <React.Fragment>
                                <Button className={classes.actionIcon} color="secondary" onClick={() => this.toggleDeleteUser(value)}><Icon>delete</Icon></Button> </React.Fragment>
                        } else {
                            return ' - '
                        }
                    }
                }
            }
        ];
        const options = {
            page: this.state.page,
            responsive: 'scroll',
            selectableRows: false,
            search: false,
            print: false,
            download: false,
            viewColumns: false,
            filter: false,
            onRowClick: (rowData) => {
                this.toggleUpdate(rowData[1], rowData[2])
            },
            onTableChange: (action, tableState) => {
                switch (action) {
                    case 'changePage':
                        this._changePage(tableState);
                    default:
                        break;
                }
            },
            textLabels: {
                body: {
                    noMatch: (this.props.clientAdminListReducer.loading || this.props.managerListReducer.loading || this.props.staffListReducer.loading) ?
                        'Loading Records...' :
                        'Sorry, no matching records found.',
                },
            }
        };
        let pandingUserColumns = [
            {
                name: t("Email"),
                options: {
                    filter: true,
                    sortDirection: 'asc',
                }
            },
            {
                name: t("Role"),
                options: {
                    filter: true,
                    sortDirection: 'asc',
                }
            },
            {
                name: t("Access to Hotels"),
                options: {
                    filter: false,
                    download: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let hotels = [];
                        if (!value) {
                            return 'All Group Hotels'
                        }
                        let values=[]
                        Object.keys(value).map(k=>{
                            values.push(value[k])
                        }).join(",");
                        return <span>{values.toString()}</span>
                    }
                }
            },
            {
                name: t("Resend invitation "),
                options: {
                    filter: false,
                    download: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const { classes } = this.props;
                            return  <Button className={classes.actionIcon} color="secondary" onClick={() => this.toggleResendInvitation(value)}><Icon>refresh</Icon></Button> 
                    }
                }
            },
        ];
        let pandingUserOptions = {
            responsive: 'scroll',
            selectableRows: false,
            search: false,
            print: false,
            download: false,
            viewColumns: false,
            filter: false,
            textLabels: {
                body: {
                noMatch: this.props.clientAdminListReducer.loading?
                    t('Loading Records...') :
                    t('No pending invitations'),
                },
            }
        }
        return (
            <>
                <HeaderCard >
                    <Grid container>
                        <Grid item xs={12} >
                            <div className={classes.divStyle} >
                                <Typography variant="h5" className={classes.fontStyle} >
                                    {t("Users")}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </HeaderCard>
                <Card>
                    <CardContent>
                        <Grid item xs={12} >
                            {/* <div className={classes.addUserBtn}>
                                <Button variant="contained" color="primary" onClick={this.toggleAddNewUserModal}>
                                    {t('Add new User')}
                                </Button>
                            </div> */}
                            <div className={classes.addUserBtn}>
                                <Button variant="contained" color="primary" onClick={this.toggleInviteUser}>
                                    {t('Invite User')}
                                </Button>
                            </div>
                        </Grid>
                        <div>
                            <MuiThemeProvider theme={UserSettingTheme}>
                                <MUIDataTable
                                    title=''
                                    data={UserList}
                                    columns={columns}
                                    options={options}
                                />
                            </MuiThemeProvider>
                        </div>
                        {Modals.addNewUser(this)}
                        {this.state.showEdit ? Modals.updateUser(this) : ""}
                        {this.state.showDelete ? Modals.DeleteUserModal(this) : ""}
                        {this.state.inviteUserModal ? Modals.inviteUserModal(this) : ""}

                    </CardContent>
                </Card>
                <Card style={{marginTop:'15px'}}>
                    <CardContent>
                        <div>
                            <MuiThemeProvider theme={UserSettingTheme}>
                                <MUIDataTable
                                    title={t('Pending Invitations')}
                                    data={this.state.pandingUsersList.length > 0 ? this.pandingUsersData(this.state.pandingUsersList) :[] }
                                    columns={pandingUserColumns}
                                    options={pandingUserOptions}
                                />
                            </MuiThemeProvider>
                        </div>
                    </CardContent>
                </Card>
            </>
        );
    }
}

UserSetting.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        userSession: state.sessionReducer,
        managerListReducer: state.managerListReducer,
        staffListReducer: state.staffListReducer,
        clientAdminListReducer: state.clientAdminListReducer,
        hotelAuthReducer: state.hotelAuthReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getClientAdminList: (request) => { dispatch(actions.getClientAdminList(request)) },
        clientAdminCreate: (request) => { dispatch(actions.clientAdminCreate(request)) },
        updateClientAdmin: (request) => { dispatch(actions.updateClientAdmin(request)) },
        deleteClientAdminUser: (request) => { dispatch(actions.deleteClientAdminUser(request)) },
        updateClientAllAdmin: (request) => { dispatch(actions.updateClientAllAdmin(request)) },


        getManagerList: (request) => { dispatch(actions.getManagerList(request)) },
        managerCreate: (request) => { dispatch(actions.managerCreate(request)) },
        updateManager: (request) => { dispatch(actions.updateManager(request)) },
        deleteManagerUser: (request) => { dispatch(actions.deleteManagerUser(request)) },

        getStaffList: (request) => { dispatch(actions.getStaffList(request)) },
        staffCreate: (request) => { dispatch(actions.staffCreate(request)) },
        updateStaff: (request) => { dispatch(actions.updateStaff(request)) },
        deleteStaffUser: (request) => { dispatch(actions.deleteStaffUser(request)) },

        changeRole: (request) => { dispatch(actions.changeRole(request)) },
        getOwner: (request) => { dispatch(actions.getOwner(request)) },
        inviteHotelUSer: (request) => { dispatch(actions.inviteHotelUSer(request)) },
        pandingUsers: (request) => { dispatch(actions.pandingUsers(request)) },

    };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(UserSetting));