var style = theme => ({
  disabledInput:{
    marginLeft:"16px"
  },
   disabledInput1:{
    marginLeft:"16px",
    marginTop: "0px",
    float:'left',
  },
  disabledInputWidth:{
    width:"30%",
    resize: "vertical",
    ['@media (max-width: 958px) and (min-width: 720px)']: {
      width:"50%",
    },
    ['@media (max-width: 719px)']: {
       width:"90%",
    },
  },
  
  findModal:{
    minHeight: "350px",
    minWidth: "500px",
  },
  savebtnWight:{
    height: "36px",
    marginTop: "20px",
    marginLeft: "16px"
  },
  savebtnWight1:{
    height: "36px",
    marginTop: "20px",
    marginLeft: "16px",
    float:'left'
  },
  settingInputLable: {
    whiteSpace:'nowrap'
  },
  pubpSelect: {
    float: "right",
    width: "200px",
    marginTop:"20px",
    textAlign:"left"
  },
  airDnaSelect:{
    float: "right",
    width: "200px",
    // marginTop:"20px",
    marginRight:"16px",
    textAlign:"left"
  },
  pubpSelectDiv: {
    display: "flex",
    marginBottom: "32px"
  },
  airDnaSelectDiv: {
    display: "flex",
    marginTop: "16px"
  },
  airDnaSelectDivTab:{
    display: "flex",
  },
  userTable: {
    padding: '20px'
  },
  userTableDiv: {
    overflow: 'auto',
    marginTop: '70px'
  },
  textField: {
    width: "47%",
    minWidth: '200px',
    marginLeft: '10px'
  },
  tableField: {
    padding: '0px',
  },

  tableTextFields: {
    width: '95%',
    border : 'none !important',
    textAlign: 'center',
    background: '#f5f5f5',
  },
  tableIndexTextFields: {
    width: '80%',
    border : 'none !important',
    background: '#f5f5f5',
  },
  pullLeft:{
    width: '20%',
    minWidth: '280px'
  },
  button: {
    marginBottom: theme.spacing.unit*2,
    marginRight: theme.spacing.unit,
    float:'right',
    whiteSpace: "nowrap",
  },
  buttonBoost:{
    marginBottom: theme.spacing.unit*2,
    marginRight: theme.spacing.unit,
    float:'left'
  },
  textSecondary:{
    color:"#707070 !important",
    '&:hover': {
      backgroundColor :'#f2f2f2 !important',
    }
  },
  tableCellRoot:{
    padding: '10px 10px 4px 10px',
    textAligh:'left'
  },
  tabDiv:{
    width: '100%',
    overflow: 'auto',
  },
  dateTableDiv: {
    width: '100%',
    overflow: 'auto',
    marginTop:"10px"
  },
  addressDiv:{
    maxWidth: "250px", 
    color: "#A0A2A6", 
    marginLeft: '10px',
    fontSize:"12px"
  },
  autoDiv:{
    float: "left",
  },
  buttonContainer:{
    flexDirection: 'column-reverse',
    maxWidth: '900px',
    float:"right",
    marginLeft:"220px",
    // ['@media (max-width: 414px) and (min-width: 360px)']: {            
    //   display: 'inline-flex',
    //   marginTop:"-15px"
    // },
    // ['@media (max-width: 1409px) and (min-width: 360px)']: {            
    //   marginTop:"-28px",
    // },
    marginTop:'-68px'
  },
  tablerow:{
    textAlign:"left"
  },
  tablerowValue:{
    textAlign:"right"
  },
  searchBtn:{
    marginTop:"10px",
    marginLeft:"20px"
  },
  bookingDiv:{
    marginTop:"30px",
  },
  sorryMsg:{
    marginTop:"20px"
  },
  bookingText:{
    float: "left",
    marginTop: "15px"
  },
  boxGroup:{
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // border: "1px solid #ddd",
    borderRadius: "10px",
    padding: "15px 0 0 0",
    // margin: "15px 0 0px"
  },
  boxItem:{
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  btnAction:{
    margin: "0",
  },
  CompetitorsScraped:{
    display: "flex",
    float:"left",
    alignItems: "flex-end",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom:"32px"
  },
  right:{
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  btnScrapes:{
    margin: "0px 15px 0px 0px",
  },
  compQue:{
    border:'none',
    margin: "0px 15px 0px 10px",
    hover:{
      // border:'1px solid '
      background:'#e6e6e6',
    }
  }, 
  buttonProgress: {
    position: 'absolute'
  },
  roomIcon: {
    color:"#fff"
  },
  tableCellRoot2: {
    minWidth: "140px"
  },
  tableRoot: {
    width: "176px"
  },
  tableRoot2: {
    width: "160px"
  },
  baseInput: {
    textAlign: 'left'
  },
  pickupDiv: {
    // height :"550px"
  },
  downloadAnchorElem: {
    display:"none"
  },
  chartdiv: {
    height :"400px",
    marginTop:"15px",
  },
  divMap: {
    height: '60vh', 
    width: '100%'
  },
  divSyle: {
    marginTop: "2px"
  },
  fontSyle: {
    textAlign: "left", 
    fontWeight: "500"
  },
  gridStyle: {
    textAlign:"right",
    float:"left",
    marginBottom: "16px"
  },
  lableInput: {
    marginRight: "15px"
  },
  arrowIcon: {
    fontStyle: "normal", 
    fontSize: "22px", 
    margin: "0 15px"
  },
  spanLable: {
    maxWidth: "150px", 
    minWidth: "150px", 
    width: "100%", 
    textAlign: "center"
  },
  btnScrore: {
    marginRight: "0"
  },
  lable: {
    marginLeft: "20px",
    marginRight:"15px"
  },
  tabView: {
    margin: "10px 0px 14px 0px",
    borderBottom: '1px solid #f0f0f0'
  },
  autoCalcDiv: {
    marginTop:'15px !important',
    marginBottom:'15px !important'
  },
  textSecondary:{
    color:"#707070 !important"
  },
  airDnaGrid:{
    display:"flex"
  },
  toggleGrid: {
    marginLeft:'16px',
    marginRight:'9px',
    marginTop:"1.5em",
    display: "flex",
  },
  calendarNaviBtn: {
    minWidth: "35px",
    padding: "0px",
    marginTop: "1px",    
  },
  newTabBtnFirstGraph: {
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    padding: "5px 10px",
    float:'left',
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.875rem",
    fontWeight: 400,
  },
  newTabBtnLastGraph: {
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    float:'left',
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.875rem",
    fontWeight: 400,
    padding: "5px 10px",
  },
  fullWidthResp: {
    ['@media (max-width: 600px)']: {
      // width: "100%",
      maxWidth: "100%",
      margin: "10px 0px !important",
    }
  },

// style={{cursor:'pointer',color: "#3772FF", fontSize: "15px", whiteSpace: "noWrap",border:'none'}}
});
export default style;