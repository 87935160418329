import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../index';
import Notify from '../Notify';

const onAdjustmentStart = () => {
    return {
        type: actionTypes.ADJUSTMENT_START
    }
}

const onAdjustmentFailed = (error) => {
    return {
        type: actionTypes.ADJUSTMENT_FAILED,
        error: error
    }
}

const onAdjustmentSucceed = (data) => {
    return {
        type: actionTypes.ADJUSTMENT_SUCCEED,
        state: data
    }
}

export const getAdjustment = (request) => {
    return dispatch => {
        // dispatch(onAdjustmentStart())
        // axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        // axios.get(Server.API + 'hotel/booking-prop/list/')
        //     .then(function (response) {
        //         dispatch(onAdjustmentSucceed(response.data))
        //     })
        //     .catch(function (error) {
        //         dispatch(onAdjustmentFailed(error))
        //     });
    }
}


// const onAdjustmentUpdateStart = () => {
//     return {
//         type: actionTypes.TIME_BOOKING_UPDATE_START
//     }
// }

// const onAdjustmentUpdateFailed = (error) => {
//     return {
//         type: actionTypes.ADJUSTMENT_UPDATE_FAILED,
//         error: error
//     }
// }

// const onAdjustmentUpdateSucceed = (data) => {
//     return {
//         type: actionTypes.ADJUSTMENT_UPDATE_SUCCEED,
//         state: data
//     }
// }

export const adjustmentUpdate = (request) => {
    return dispatch => {
        // dispatch(onAdjustmentUpdateStart())
        // axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        // axios.patch(Server.API + 'hotel/booking-prop/update/' + request.id + '/', request)
        //     .then(function (response) {
        //         dispatch(Notify.newUserModal("Settings Updated Successfully", true))
                dispatch(actions.updateSettingJson(request, [], 'timeBookingUpdate'))
            //     dispatch(onAdjustmentUpdateSucceed(response.data))
            // })
            // .catch(function (error) {
            //     dispatch(onAdjustmentUpdateFailed(error))
            // });
    }
}