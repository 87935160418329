import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = {
    events: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.EVENT_SUCCEED:
            return updateObject(state, { events: action.state })
        default:
            return state;
    }
}

export default reducer