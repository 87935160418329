var style = {
  donut: {
      display: 'flex',
      marginTop: '10px',
      textAlign: 'left',
      marginBottom:"50px"
    },
    donutcharts:{
      width:"100%",
      maxWidth: "470px"
    }
}
export default style;