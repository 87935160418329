import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = [];

const manageList = (data, state) => {
    state.dailyAdjustment.push(data);
    return state.dailyAdjustment;
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DAILY_ADJUSTMENT_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.DAILY_ADJUSTMENT_SUCCEED:
            return updateObject(state, { loading: false, error: null, dailyAdjustment: action.state })
        case actionTypes.DAILY_ADJUSTMENT_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.DAILY_ADJUSTMENT_CREATE_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.DAILY_ADJUSTMENT_CREATE_SUCCEED:
            return updateObject(state, { loading: false, error: null, dailyAdjustment: manageList(action.state, state) })
        case actionTypes.DAILY_ADJUSTMENT_CREATE_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.DAILY_ADJUSTMENT_UPDATE_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.DAILY_ADJUSTMENT_UPDATE_SUCCEED:
            return updateObject(state, { loading: false, error: null })
        case actionTypes.DAILY_ADJUSTMENT_UPDATE_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.DAILY_ADJUSTMENT_DELETE_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.DAILY_ADJUSTMENT_DELETE_SUCCEED:
            return updateObject(state, { loading: false, error: null, dailyAdjustment: action.state })
        case actionTypes.DAILY_ADJUSTMENT_DELETE_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        default:
            return updateObject(state, { loading: false, error: null });
    }
}

export default reducer