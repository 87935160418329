import React, { Component } from 'react';
import { connect } from "react-redux";
import { compose } from 'redux';
import AdminMenuList from '../../config/adminMenu';

class Notfound extends Component {

    componentWillMount() {
        let pathname = this.props.location.pathname;
        let checkAdminPath = false;
        let alias = this.props.hotelAuthReducer.hotelDetails.hotel.id;
        let clientAccPath = "/Admin/Clients";
        AdminMenuList.map((val, index) => {
            var toVal = val.to;
            if (toVal === pathname) {
                return checkAdminPath = true;
            }

            if (val.children && !checkAdminPath) {
                val.children.map((res) => {
                    var childToVal = res.to;
                    if (childToVal == pathname) {
                        return checkAdminPath = true;
                    }
                })
            }

        })
        
        if (!checkAdminPath) {
            this.props.history.push(clientAccPath);
        }
    }
    render() {

        return (
            <div>

            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        userSession: state.sessionReducer,
        hotelAuthReducer: state.hotelAuthReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Notfound);
