import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ClientListCss from './css/clientListCss';
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../store/actions/index';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { clientListTheme } from '../muiTheme';
import { Typography,CircularProgress } from '@material-ui/core';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import Server from '../../config/server';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import classnames from 'classnames';
import { Grid } from '@material-ui/core';
import HeaderCard from "../Client/HeaderCard";
import pmsDataArray from '../PMSList';
const styles = ClientListCss;

// this is array data of pms connections i set this depending on key from BE

const stateDataArray = [
    { label: 'free trial', value: 0 },
    { label: 'subscribed', value: 1 },
    { label: 'cancelled', value: 2 },
    { label: 'free trial expired', value: 3 },
    { label: 'suspended', value: 4 },
    { label: 'subscribed(P)', value: 6 },
    { label: 'unsubscribed(P)', value: 7 },
    { label: 'demo Hotel', value: 8 },
    { label: 'subscription paused', value: 9 },    
]

const plans = [
    { label: 'GenieIntelligence', value: 1 },
    { label: 'Basic', value: 2 },
    { label: 'Flexible', value: 3 },
    { label: 'Pro', value: 4 },
    { label: 'Small Business', value: 5 }
]

let CountryList = [];
let CountryFilterList = [];
let columns = []

class RateSetting extends React.Component {

    state = {
        pms_provider: 0,
        count: 0,
        current: 1,
        clientList: [],
        responsiblePersonList: [],
        openTicketData: {},
        downLoadCSVLoader:false,
    };

    componentWillMount() {
        this.initPusher();
        let requestParams = {
            token: this.props.userSession.token,
            url: 'hotel/client/list/?ordering=-id'
        };
        

        setTimeout(() => {
            this.props.getClientList(requestParams)
        }, 500)
        this.props.getCountry(requestParams);
        if (this.props.userSession.user.is_staff) {
            this.props.getResponsiblePerson(requestParams);
        }
        this.showSearch();
    }

    showSearch = () => {
        setTimeout(() => {
            let search = document.querySelectorAll('[title="Search"]');
            if (search.length) {
                search[0].click();
            } else {
                this.showSearch();
            }
        }, 500)
    }    

    componentWillReceiveProps(props) {
        const { classes } = this.props;
        if (props.adminUserReducer.responsiblePersonList) {
            this.setState({
                responsiblePersonList: props.adminUserReducer.responsiblePersonList
            })
        }
        if (props.adminUserReducer.openTicketData) {
            this.setState({
                openTicketData: props.adminUserReducer.openTicketData
            })
        }

        if (CountryList.length === 0 && ((this.state.responsiblePersonList && this.state.responsiblePersonList.length > 0) || !this.props.userSession.user.is_staff)) {
            if (this.props.countryReducer) {
                CountryList = [];
                CountryFilterList = [];
                this.props.countryReducer.map(option => (
                    CountryList.push({ value: option.id, label: option.name + ' (' + option.abbreviation + ')' })
                ))
                this.props.countryReducer.map(option => (
                    CountryFilterList.push({ value: option.id, label: option.name })
                ))
                columns = [
                    {
                        name: "ID",
                        options: {
                            filter: false,
                            sortDirection: 'desc'
                        }
                    },
                    {
                        name: "Hotel",
                        options: {
                            filter: false,
                            sort: true,
                        }
                    },
                    {
                        name: "Email",
                        options: {
                            filter: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return <div style={{ minWidth: "150px", maxWidth: "250px", wordBreak: 'break-all' }}>{value}</div>
                            }
                        }
                    },
                    {
                        name: "Country",
                        options: {
                            filter: true,
                            filterOptions: CountryFilterList.map(row => { return row.label }),
                        }
                    },
                    {
                        name: "Address",
                        options: {
                            filter: false
                        }
                    },
                    {
                        name: "Plans",
                        options: {
                            filter: true,
                            filterOptions: plans.map(row => { return row.label }),
                            customBodyRender: (value, tableMeta, updateValue) => {
                                if (value) {
                                    let plan = plans.filter(val => val.value == value)[0]
                                    if (plan) {
                                        return plan.label;
                                    }
                                    return "-"
                                } else {
                                    return "-"
                                }

                            }
                        }
                    },
                    {
                        name: "State",
                        options: {
                            filter: true,
                            filterOptions: stateDataArray.map(row => { return row.label }),
                            setCellProps: (value) => {
                                return {
                                    className: classnames(
                                        {
                                            ['color-primary']: (value === "free trial" || value === "subscribed" || value === "subscribed(P)"),
                                            ['color-secondary']: (value === "unsubscribed(P)" || value === "cancelled" || value === "free trial expired" || value === "suspended" || value === "subscription paused"),
                                        })
                                };
                            },
                            customBodyRender: (value, tableMeta, updateValue) => {
                                
                                if (value == 0) {
                                    return 'free trial'
                                } else if (value === 1) {
                                    return 'subscribed'
                                } else if (value === 2) {
                                    return 'cancelled'
                                } else if (value === 3) {
                                    return 'free trial expired'
                                } else if (value === 4) {
                                    return 'suspended'
                                } else if (value === 5) {
                                    return 'suspended'
                                } else if (value === 6) {
                                    return 'subscribed(P)'
                                } else if (value === 7) {
                                    return 'unsubscribed(P)'
                                } else if (value === 8) {
                                    return 'Demo Hotel'
                                } else if (value === 9) {
                                    return 'subscription paused'
                                }                                
                            }
                        }
                    },
                    {
                        name: "End Of Free Trial",
                        options: {
                            filter: false
                        }
                    },
                    {
                        name: "PMS/CM",
                        options: {
                            filter: true,
                            filterOptions: pmsDataArray.map(row => { return row.label })
                        }
                    },
                    {
                        name: "Auto Upload",
                        options: {
                            filter: false
                        }
                    },
                    {
                        name: "Last Upload",
                        options: {
                            filter: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                let returnValue = value.last_update_price_pms ? moment(value.last_update_price_pms).format('DD/MM/YYYY HH:mm') : "-";
                                if (!value.last_update_price_pms_status && value.last_update_price_pms != null) {
                                    returnValue = "Failed";
                                }
                                return returnValue;
                            }
                        }
                    },
                    {
                        name: "Last Login",
                        options: {
                            filter: false
                        }
                    },
                    {
                        name: "Deal Owner",
                        options: {
                            display: this.props.userSession.user.is_staff,
                            filter: this.props.userSession.user.is_staff ? true : false,
                            filterOptions: this.state.responsiblePersonList.map(row => { return row.name })
                        }
                    },
                ];
            }
        }
        if (props.clientListReducer.clientList && (props.clientListReducer.clientList.results !== this.state.clientList)) {
            this.setState({
                count: props.clientListReducer.clientList.count,
                current: props.clientListReducer.clientList.current,
                clientList: props.clientListReducer.clientList.results,
            })
        }
    }

    initPusher = () => {
        let channel = {};
        const user_id = this.props.userSession.user.id.toString(); 
        channel = this.props.commonReducer.pusher.connectionPusher.subscribe(user_id);        
        channel.bind('downloadhotellist.message', (evt)=> {
            if(evt.download_url){
                  const link = document.createElement("a");
                    link.href = evt.download_url;
                    link.click();
                    this.setState({ downLoadCSVLoader:false })
            }

            if (evt.error){
                this.setState({ downLoadCSVLoader:false })
            }  
        });
    }

    downLoadCSV = () =>{
        this.setState({ downLoadCSVLoader:true })
        let request = {
            url: Server.API + 'hotel/client/list/export/?token=' + this.props.userSession.token
        }
        this.props.downloadCsvClientList(request)        
    }

    componentDidMount() {
        document.title = "RoomPriceGenie"
    }
    renderDataTable = (data) => {
        if (data.length > 0) {
            let returnData = [];
            data.map((val) => {
                let provider = "";
                if (val.pms_provider) {
                    let connection = pmsDataArray.filter(row => row.value == val.pms_provider);
                    if (connection.length > 0) {
                        provider = connection[0].label;
                    }
                }

                let hotelData = [
                    val.id,
                    val.name,
                    val.email,
                    val.country.name,
                    val.address,
                    val.plan,
                    val.state,
                    val.free_trial_end ? moment(val.free_trial_end).format('DD/MM/YYYY') : "-",
                    provider,
                    val.is_update_to_pms ? "ON" : "OFF",
                    val,
                    val.last_login ? moment(val.last_login).format('DD/MM/YYYY HH:mm') : "-",
                    val.responsible_person ? (val.responsible_person.sub_account_type == 2 ? (val.reseller ? val.reseller.name : (val.responsible_person.first_name + ' ' + val.responsible_person.last_name)) : (val.responsible_person.first_name + ' ' + val.responsible_person.last_name)) : "",
                ];
                returnData.push(hotelData)
                return null;
            })
            return returnData;
        } else {
            return [];
        }
    }

    options = () => {
        return {
            sort: true,
            filter: true,
            print: false,
            download: false,
            viewColumns: false,
            responsive: "scroll",
            selectableRows: false,
            serverSide: true,
            page: (this.props.clientListReducer.clientList) ? (this.props.clientListReducer.clientList.current - 1) : 0,
            count: this.state.count,
            rowsPerPage: 20,
            rowsPerPageOptions: false,
            onRowClick: (rowData) => {
                let id = rowData[0];
                window.open("client/Calendar/" + id, '_blank');
            },
            onTableChange: (action, tableState) => {
                let url = 'hotel/client/list/?ordering=-id';
                let requestParams = { token: this.props.userSession.token };

                if (action === 'changePage') {
                    let page = tableState.page + 1;
                    url += '&page=' + page + ((this.state.searchText) ? '&search=' + this.state.searchText : '') + ((this.state.ordering) ? '&ordering=' + this.state.ordering : '') + ((this.state.pms_provider) ? '&pms_provider=' + this.state.pms_provider : '')
                    requestParams.url = url
                }
                if (action === 'search') {
                    let searchText = tableState.searchText ? tableState.searchText : '';
                    if (tableState.searchText) {
                        url += '&search=' + searchText + ((this.state.ordering) ? '&ordering=' + this.state.ordering : '');
                    }
                    requestParams.url = url
                    this.setState({
                        searchText: searchText
                    },
                        () => {
                            setTimeout(() => {
                                if (searchText.length == this.state.searchText.length) {
                                    this.props.getClientList(requestParams)
                                }
                            }, 2000);
                        })
                }
                let filter = tableState.filterList.filter(val => val.length > 0);

                if (filter && filter.length) {
                    if (tableState.filterList[3].length > 0) {
                        let country = CountryFilterList.filter(row => row.label == tableState.filterList[3])[0].value;
                        url += '&country_filter=' + country;
                        this.setState({
                            country: country
                        })
                    }

                    if (tableState.filterList[5].length > 0) {
                        let plan = plans.filter(row => row.label == tableState.filterList[5])[0].value;
                        url += '&plan=' + plan;
                        this.setState({
                            plan: plan
                        })
                    }

                    if (tableState.filterList[6].length > 0) {
                        let state = stateDataArray.filter(row => row.label == tableState.filterList[6])[0].value;
                        url += '&state=' + state;
                        this.setState({
                            state: state
                        })
                    }

                    if (tableState.filterList[8].length > 0) {
                        let pmsKey = pmsDataArray.filter(row => row.label == tableState.filterList[8])[0].value;
                        url += '&pms_provider=' + pmsKey;
                        this.setState({
                            pmsKey: pmsKey
                        })
                        this.setState({ pms_provider: pmsKey })
                    }

                    if (tableState.filterList[12].length > 0) {
                        let responsible_person_id = this.state.responsiblePersonList.filter(row => row.name == tableState.filterList[12])[0].id;
                        let responsible_person_type = this.state.responsiblePersonList.filter(row => row.name == tableState.filterList[12])[0].type;
                        url += '&deal_owner_filter=' + responsible_person_id + '&deal_owner_type=' + responsible_person_type;
                        this.setState({
                            responsible_person_id: responsible_person_id,
                            responsible_person_type: responsible_person_type
                        })
                    }
                    requestParams.url = url
                } else {
                    this.setState({ pms_provider: null, state: null, plan: null, country: null, responsible_person_id: null, responsible_person_type: null })
                }
                if (action != 'onSearchOpen' && action != 'search' && action != 'sort') {
                    this.props.getClientList(requestParams)
                }
            },
            textLabels: {
                body: {
                    noMatch: this.props.clientListReducer.loading ?
                        'Loading Records...' :
                        'Sorry, no matching records found.',
                },
            },
            onColumnSortChange: (changedColumn, direction) => {
                let sort_direction;
                let requestParams = { token: this.props.userSession.token };

                switch (changedColumn) {
                    case 'ID':
                        sort_direction = (direction === 'ascending') ? 'id' : '-id';
                        break;
                    case 'Hotel':
                        sort_direction = (direction === 'ascending') ? 'name' : '-name';
                        break;
                    case 'Address':
                        sort_direction = (direction === 'ascending') ? 'address' : '-address';
                        break;
                    case 'Email':
                        sort_direction = (direction === 'ascending') ? 'client__email' : '-client__email';
                        break;
                    case 'End Of Free Trial':
                        sort_direction = (direction === 'ascending') ? 'client__free_trial_end' : '-client__free_trial_end';
                        break;
                    case 'Country':
                        sort_direction = (direction === 'ascending') ? 'country__name' : '-country__name';
                        break;
                    case 'State':
                        sort_direction = (direction === 'ascending') ? 'state' : '-state';
                        break;
                    case 'PMS/CM':
                        sort_direction = (direction === 'ascending') ? 'pms__provider' : '-pms__provider';
                        break;
                    case 'Auto Upload':
                        sort_direction = (direction === 'ascending') ? 'is_update_to_pms' : '-is_update_to_pms';
                        break;
                    case 'Last Upload':
                        sort_direction = (direction === 'ascending') ? 'last_update_price_pms' : '-last_update_price_pms';
                        break;
                    case 'Last Login':
                        sort_direction = (direction === 'ascending') ? 'last_login' : '-last_login';
                        break;
                    default:
                        sort_direction = (direction === 'ascending') ? 'id' : '-id';
                        break;
                }

                requestParams.url = 'hotel/client/list/?ordering=' + sort_direction + ((this.state.searchText) ? '&search=' + this.state.searchText : '') + ((this.state.pms_provider) ? '&pms_provider=' + this.state.pms_provider : '') + ((this.state.country) ? '&country_filter=' + this.state.country : '') + ((this.state.state) ? '&state=' + this.state.state : '') + ((this.state.plan) ? '&plan=' + this.state.plan : '') + ((this.state.responsible_person_id) ? '&deal_owner_filter=' + this.state.responsible_person_id : '') + ((this.state.responsible_person_type) ? '&deal_owner_type=' + this.state.responsible_person_type : '');
                this.props.getClientList(requestParams)
                this.setState({ ordering: sort_direction })
            },
            customSort: (data, colIndex, order) => {
                return data;
            },
            customToolbar: () => {
                return (
                    <React.Fragment>
                        <Tooltip title={"Download CSV"}>
                        { this.state.downLoadCSVLoader ?
                            <a href="javascript:void(0);">
                                    <IconButton>
                                    <CircularProgress color="primary" size={24}  />                             
                                </IconButton>
                            </a> :
                            <a href="javascript:void(0);" onClick={() => this.downLoadCSV()}>
                                    <IconButton>
                                    <DownloadIcon />      
                                </IconButton>
                            </a>
                        }
                        </Tooltip>                    
                    </React.Fragment>
                );
            }
        }
    };

    

    handleChange = () => event => {
        this.setState({
            pms_provider: event.target.value
        })
    }

    render() {
        const { classes } = this.props;
        // const theme = clientListTheme;
        return (
            <>
                <HeaderCard>
                    <Grid container>
                        <Grid item >
                            <div className={classes.divStyle} >
                            <Typography className={classes.fontStyle} variant="h5" >
                                Hotel List
                            </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </HeaderCard>
            <Card>
                <CardContent className={classes.cardRoot} >
                    <div>
                        <MuiThemeProvider theme={clientListTheme}>
                            {columns.length > 0 &&
                                <MUIDataTable
                                    title={""}
                                    data={this.state.clientList && this.renderDataTable(this.state.clientList)}
                                    columns={columns}
                                    options={this.options()}
                                />
                            }
                        </MuiThemeProvider>
                    </div>
                </CardContent>
            </Card >
            </>

        );
    }
}

RateSetting.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        userSession: state.sessionReducer,
        clientListReducer: state.clientListReducer,
        countryReducer: state.countryReducer.countryList,
        adminUserReducer: state.adminUserReducer,
        commonReducer: state.commonReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getClientList: (request) => { dispatch(actions.getClientList(request)) },
        getCountry: (request) => { dispatch(actions.getCountry(request)) },
        getResponsiblePerson: (request) => { dispatch(actions.getResponsiblePerson(request)) },
        downloadCsvClientList:(request) =>{dispatch(actions.downloadCsvClientList(request))},        
    };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(RateSetting);
