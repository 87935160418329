import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ClientListCss from './css/clientListCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../store/actions/index';
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { scrapeScheduleTheme } from '../muiTheme';
import moment from 'moment';

const styles = ClientListCss;

class RateSetting extends React.Component {

    state = {
        scheduleList: []
    };

    componentWillMount() {
        let requestParam = { token: this.props.userSession.token }
        this.props.getScrapSchedule(requestParam);
    }

    componentWillReceiveProps(props) {
        if (props.scrapScheduleReducer.scrapList) {
            this.setState({
                count: props.scrapScheduleReducer.scrapList.count,
                current: props.scrapScheduleReducer.scrapList.current,
                next: props.scrapScheduleReducer.scrapList.next,
                previous: props.scrapScheduleReducer.scrapList.previous,
                scheduleList: props.scrapScheduleReducer.scrapList.results
            })
        }
    }
    renderScrapData = (data) => {
        let jsx = [];
        data.map((val) => {
            jsx.push([
                val.program.name,
                moment(val.date).format('YYYY-MM-DD HH:mm'),
                val.status
            ])
            return null;
        })
        return jsx;
    }

    options = () => {
        return {
            responsive: 'scroll',
            print: false,
            download: false,
            viewColumns: false,
            responsive: "scroll",
            selectableRows: false,
            serverSide: true,
            count: this.state.count,
            rowsPerPage: 20,
            filter:false,
            rowsPerPageOptions : false,
            search:false,
            onTableChange: (action, tableState) => {
                if (action === 'changePage') {
                    let page = tableState.page + 1;
                    let requestParams = {
                        token: this.props.userSession.token,
                        url: 'amalgamation/scrape-task/list/?page=' + page,
                    };
                    this.props.getScrapSchedule(requestParams)
                }
                if (action === 'search') {
                    let searchText = tableState.searchText ? tableState.searchText : '';
                    let requestParams = {
                        token: this.props.userSession.token,
                        url: 'amalgamation/scrape-task/list/?search=' + searchText,
                    };
                    this.props.getScrapSchedule(requestParams)
                }
            },
            textLabels: {
                body: {
                noMatch: this.props.scrapScheduleReducer.loading ?
                    'Loading Records...' :
                    'Sorry, no matching records found.',
                },
            }
        }
    };


    render() {
        const { classes } = this.props;

        const columns = [
            { name: "Name" },
            { name: "Executed at" },
            { name: "Status" }
        ];
        return (
            <Card>
                <CardHeader
                    className="headig-top"
                    title="Scrape Schedule">
                </CardHeader>
                <CardContent>
                <MuiThemeProvider theme={scrapeScheduleTheme}>
                    <MUIDataTable
                        title={''}
                        data={this.renderScrapData(this.state.scheduleList)}
                        columns={columns}
                        options={this.options()}
                    />
                </MuiThemeProvider>
                </CardContent>
            </Card >
        );
    }
}

RateSetting.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        userSession: state.sessionReducer,
        scrapScheduleReducer: state.scrapScheduleReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getScrapSchedule: (request) => { dispatch(actions.getScrapSchedule(request)) },
    };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(RateSetting);