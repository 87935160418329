import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import Notify from '../Notify';

const onResetClientPasswordStart = () => {
    return {
        type: actionTypes.CLIENT_PASSWORD_UPDATE_START
    }
}

const onResetClientPasswordFailed = (error) => {
    return {
        type: actionTypes.CLIENT_PASSWORD_UPDATE_FAILED,
        error: error
    }
}

const onResetClientPasswordSucceed = (data) => {
    return {
        type: actionTypes.CLIENT_PASSWORD_UPDATE_SUCCEED,
        state: data
    }
}

export const resetClientPassword = (request) => {
    return dispatch => {
        dispatch(onResetClientPasswordStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'account/admin/password/reset/', request)
            .then(function (response) {
                dispatch(onResetClientPasswordSucceed(response.data))
                dispatch(Notify.newUserModal("Password has been changed successfully.", true))
            })
            .catch(function (error) {
                dispatch(onResetClientPasswordFailed(error))
                dispatch(Notify.newUserModal("An error occured while changing password.", false))
            });
    }
}