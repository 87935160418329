import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = [];

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OTAINSIGHTS_COMPETITOR_CSV_SUCCEED:
            return updateObject(state, {loading: false,error: null,OTACompetitorCSV: action.state.data});
        case actionTypes.OTA_COMPETITOR_RATE_SUCCEED:
            return updateObject(state, {loading: false,error: null,OTACompetitorRate: action.state.data});    
        case actionTypes.GET_OTA_DROPDOWN_DATA_SUCCEED:
            return updateObject(state, {loading: false,error: null,OTADropdownData: action.state.data});    
        case actionTypes.GET_OTA_COMPETITOR_RATE_START:
            return updateObject(state, {loading: true,error: null});        
        case actionTypes.GET_OTA_COMPETITOR_RATE_SUCCEED:    
            return updateObject(state, {loading: false,error: null,OTACompetitorRate:action.state.data});        
        default:
            return updateObject(state, { loading: false, error: null });    
    }
}
export default reducer