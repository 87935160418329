import { updateObject } from '../../../shared/utility';
import fingerprint from 'fingerprintjs'
import jwt from 'jsonwebtoken';
import * as actionTypes from '../../actions/actionTypes';

let initialState = {};


const varifyUserdata = () => {
    var credential = localStorage.getItem("__UserProps__");
    if (credential) {
        try {
            if (window.location.pathname.includes("reset-password")) {
                localStorage.clear();
            }
            let finderprint = new fingerprint().get();
            credential = jwt.verify(credential, finderprint.toString());
        } catch {
            localStorage.clear();
            if (!window.location.pathname.includes("reset-password") && !window.location.pathname.includes("account/verify-invited-user")) {
                if (window.location.pathname != "/" && window.location.pathname != "/OAuth2Authorize") {
                    window.location.href = "/"
                }
            }
        }
    } else {        
        // localStorage.clear();
        if (!window.location.pathname.includes("reset-password") && !window.location.pathname.includes("booksuit-login")  && !window.location.pathname.includes("academy-signup") && !window.location.pathname.includes("account/verify-invited-user")) {
            if (window.location.pathname != "/" && window.location.pathname != "/OAuth2Authorize") {
                window.location.href = "/"
            }
        }
    }
    return credential;
}

var credential = localStorage.getItem("__UserProps__")
if (credential) {
    try {
        if (window.location.pathname.includes("reset-password")) {
            localStorage.clear();
        }
        let finderprint = new fingerprint().get();
        credential = jwt.verify(credential, finderprint.toString());
    } catch {
        localStorage.clear();
        if (!window.location.pathname.includes("reset-password")  && !window.location.pathname.includes("account/verify-invited-user")) {
            if (window.location.pathname != "/" && window.location.pathname != "/OAuth2Authorize") {
                window.location.href = "/"
            }
        }
    }
} else {
    // localStorage.clear();
    if (!window.location.pathname.includes("reset-password") && !window.location.pathname.includes("booksuit-login") && !window.location.pathname.includes("academy-signup") && !window.location.pathname.includes("account/verify-invited-user")) {
        if (window.location.pathname != "/" && window.location.pathname != "/OAuth2Authorize") {
            window.location.href = "/"
        }
    }
}
initialState = updateObject({}, credential);

if(initialState.user){
    initialState.user.isClientView = false;
}

const reducer = (state = initialState, action) => {
    let varifySession;
    switch (action.type) {
        case actionTypes.UPDATE_CLIENT_VIEW:
            if(state.user.is_staff || state.user.sub_account_type){
                state.user.isClientView = true;
            }
            state.user.is_staff = false;
            state.user.sub_account_type = null;
            return updateObject(state, state)
        case actionTypes.LOGIN_SUCCEED:
            varifySession = varifyUserdata();
            if (varifySession) {
                return updateObject(state, varifySession)
            } else {
                return state;
            }
        case actionTypes.VERIFIED_OTP_SUCCEED:
            varifySession = varifyUserdata();
            if (varifySession) {
                return updateObject(state, varifySession)
            } else {
                return state;
            }
        case actionTypes.BOOKINGSUIT_TEST_SUCCEED:
            varifySession = varifyUserdata();
            if (varifySession) {
                return updateObject(state, varifySession)
            } else {
                return state;
            }
        default:
            return state;
    }
}

export default reducer
