import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../index';
import Notify from '../Notify';

const ontPmsListStart = () => {
    return {
        type: actionTypes.GET_PMS_START
    }
}

const ontPmsListFailed = (error) => {
    return {
        type: actionTypes.GET_PMS_FAILED,
        error: error
    }
}

const ontPmsListSucceed = (data) => {
    return {
        type: actionTypes.GET_PMS_SUCCEED,
        state: data
    }
}

export const getConnectionList = (request) => {
    return dispatch => {
        dispatch(ontPmsListStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.Authtoken;
        axios.get(Server.API + 'hotel/pms/list/')
            .then(function (response) {
                dispatch(ontPmsListSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(ontPmsListFailed(error))
            });
    }
}


const onConnectionUpdateStart = () => {
    return {
        type: actionTypes.CONNECTION_UPDATE_START
    }
}

const onConnectionUpdateFailed = (error) => {
    return {
        type: actionTypes.CONNECTION_UPDATE_FAILED,
        error: error
    }
}

const onConnectionUpdateSucceed = (data) => {
    return {
        type: actionTypes.CONNECTION_UPDATE_SUCCEED,
        state: data
    }
}

export const connectionUpdate = (request, stopLoader) => {
    return dispatch => {
        dispatch(onConnectionUpdateStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.Authtoken;
        axios.patch(Server.API + 'hotel/pms/update/' + request.id + '/', request)
            .then(function (response) {
                // stopLoader()
                dispatch(actions.getConnectionList(request))
                dispatch(onConnectionUpdateSucceed(response.data))
                let requestParam = {
                    Authtoken: request.Authtoken,
                    pms_sync: request.integration
                }
                dispatch(actions.updatePmsSync(requestParam))
                if (request.fromRoomSetting) {
                    let req = {
                        token: request.Authtoken,
                    }
                    dispatch(actions.getPmsList(req))
                    // dispatch(Notify.newUserModal("Rooms/Rates Updated Successfully", true))
                } else {
                    // dispatch(Notify.newUserModal("PMS Connection updated Successfully", true))
                }

                if (response.data.provider == 70){
                    let requestParam_property = {
                        token: request.Authtoken
                    }
                    dispatch(getMultiPropertyList(requestParam_property, stopLoader))
                }
            })
            .catch(function (error) {
                // stopLoader()
                dispatch(onConnectionUpdateFailed(error))
                dispatch(Notify.newUserModal(error.response.data.message, false))
            });
    }
}

export const connectionRegister = (request) => {
    return dispatch => {
        dispatch(onConnectionUpdateStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.Authtoken;
        axios.post(Server.API + 'hotel/pms/register/', request)
            .then(function (response) {
                let requestParam = {
                    Authtoken: request.Authtoken,
                    pms_sync: request.integration
                }
                dispatch(actions.updatePmsSync(requestParam))
                dispatch(actions.getConnectionList(request))
                dispatch(onConnectionUpdateSucceed(response.data))
                dispatch(Notify.newUserModal("PMS Connection created successfully", true))
            })
            .catch(function (error) {
                dispatch(actions.getConnectionList(request))
                dispatch(onConnectionUpdateFailed(error))
                dispatch(Notify.newUserModal("An error occured while creating PMS Connection", false))
            });
    }
}


const onDisconnectPmsStart = () => {
    return {
        type: actionTypes.DISCONNECT_PMS_START
    }
}

const onDisconnectPmsFailed = (error) => {
    return {
        type: actionTypes.DISCONNECT_PMS_FAILED,
        error: error
    }
}

const onDisconnectPmsSucceed = (data) => {
    return {
        type: actionTypes.DISCONNECT_PMS_SUCCEED,
        state: data
    }
}


export const disconnectPMS = (request) => {
    return dispatch => {
        dispatch(onDisconnectPmsStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.Authtoken;
        axios.delete(Server.API + 'hotel/pms/disconnect/', request)
            .then(function (response) {                
                dispatch(actions.getHotelDetail({token: request.Authtoken}))
                dispatch(onDisconnectPmsSucceed(request))
                dispatch(Notify.newUserModal("PMS Connection updated successfully", true))
            })
            .catch(function (error) {
                dispatch(onDisconnectPmsFailed(error))
                dispatch(Notify.newUserModal("An error occured while updating PMS Connection", false))
            });
    }
}

const onremoveDeadRoomsStart = () => {
    return {
        type: actionTypes.REMOVE_DEAD_ROOMS_START
    }
}

const onremoveDeadRoomsFailed = (error) => {
    return {
        type: actionTypes.REMOVE_DEAD_ROOMS_FAILED,
        error: error
    }
}

const onremoveDeadRoomsSucceed = (data) => {
    return {
        type: actionTypes.REMOVE_DEAD_ROOMS_SUCCEED,
        state: data
    }
}

export const removeDeadRooms = (request) => {
    return dispatch => {
        dispatch(onremoveDeadRoomsStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.Authtoken;
        axios.post(Server.API + 'hotel/pms/roomtype/removedeadroomtype/', request)
            .then(function (response) {
                dispatch(onremoveDeadRoomsSucceed(request))
                let requestParam = { token: request.Authtoken }
                dispatch(actions.getRoomList(requestParam))
                dispatch(actions.getPmsList(requestParam))
                dispatch(actions.updateSettingJson(requestParam, response.data, 'deleteDeadRoom'))
                dispatch(Notify.newUserModal("Rooms updated successfully", true))
            })
            .catch(function (error) {
                dispatch(onremoveDeadRoomsFailed(error))
                dispatch(Notify.newUserModal("An error occured while updating Rooms", false))
            });
    }
}

const onremoveDeadRatesStart = () => {
    return {
        type: actionTypes.REMOVE_DEAD_RATES_START
    }
}

const onremoveDeadRatesSucceed = (data) => {
    return {
        type: actionTypes.REMOVE_DEAD_RATES_SUCCEED,
        state: data
    }
}

export const removeDeadRates = (rateData, roomData) => {
    return dispatch => {
        dispatch(onremoveDeadRatesStart())
        axios.defaults.headers.common['Authorization'] = "Token " + rateData.token;
        axios.post(Server.API + 'hotel/pms/roomrate/removedeadroomrate/', rateData)
            .then(function (response) {
                if (roomData.deleted_rooms && roomData.deleted_rooms.length > 0) {
                    dispatch(actions.removeDeadRooms(roomData))
                }
                dispatch(onremoveDeadRatesSucceed(rateData))
                dispatch(actions.getPmsList(rateData))
            })
            .catch(function (error) {

            });

    }
}

export const callReservation = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/bookingsuite/link/hotel/', { code: request.code })
            .then(function (response) {
            })
            .catch(function (error) {

            });
    }
}

const seekomCallSucceed = (data) => {
    return {
        type: actionTypes.SEEKOM_PMS_SUCCEED,
        state: data
    }
}

export const seekomCall = (request, callback) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'account/seekom/auth/connection/', { code: request.code })
            .then(function (response) {
                dispatch(seekomCallSucceed(response.data))
                callback();
            }) 
            .catch(function (error) {

            });
    }
}


const freetobookCallSucceed = (data) => {
    return {
        type: actionTypes.FREE_TO_BOOK_PMS_SUCCEED,
        state: data
    }
}

export const freetobookCall = (request, callback) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'account/freetobook/auth/connection/', { code: request.code })
            .then(function (response) {
                dispatch(freetobookCallSucceed(response.data));
                callback();
            })
            .catch(function (error) {

            });
    }
}
const oauth2CallSucceed = (data) => {
    return {
        type: actionTypes.OAUTH2_PMS_SUCCEED,
        state: data
    }
}

export const oauth2Call = (request, callback) => {
    
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'account/integrations/oauth2/', { code: request.code })
            .then(function (response) {
                dispatch(oauth2CallSucceed(response.data));
                callback();
            })
            .catch(function (error) {
                
            });
    }
}

const multiPropertySucceed = (data) => {
    return {
        type: actionTypes.MULTIPROPERTY_SUCCEED,
        state: data
    }
}

export const getMultiPropertyList = (request, stopLoader) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'account/integrations/properties/')
            .then(function (response) {
                stopLoader()
                dispatch(multiPropertySucceed(response.data.hotel_list))
            })
            .catch(function (error) {
                stopLoader()
                dispatch(Notify.newUserModal("An error occured while getting property list.", false))
            });
    }
}

export const getReservation = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'hotel/bookingsuite/get/reservation/')
            .then(function (response) {
                if (response.data && response.data.download_id) {
                    window.open(
                        Server.API + 'hotel/reservation/download/' + response.data.download_id + '/?token=' + request.token,
                        '_blank'
                    );
                }
            })
            .catch(function (error) {
            });
    }
}

const publisherListSucceed = (data) => {
    return {
        type: actionTypes.PUBLISHER_LIST_SUCCEED,
        state: data
    }
}

// export const getPublisherList = (request) => {
//     return dispatch => {
//         axios.defaults.headers.common['Authorization'] = "Token " + request.token;
//         axios.get(Server.API + 'hotel/smx/publisher/list/')
//             .then(function (response) {
//                 dispatch(publisherListSucceed(response.data))
//             })
//             .catch(function (error) {
//             });
//     }
// }

const pgetRmsPropertySucceed = (data) => {
    return {
        type: actionTypes.RMS_PROPERTY_LIST,
        state: data
    }
}

export const getRmsProperty = (request, stopLoader) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/rms/get-properties/', request)
            .then(function (response) {
                stopLoader()
                dispatch(pgetRmsPropertySucceed(response.data))
            })
            .catch(function (error) {
            });
    }
}

const getRmsAgentsSucceed = (data) => {
    return {
        type: actionTypes.RMS_AGENTS_LIST,
        state: data
    }
}

export const getRmsAgents = (request, stopLoader) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/rms/get-online-agents/', request)
            .then(function (response) {
                stopLoader()
                dispatch(getRmsAgentsSucceed(response.data))
            })
            .catch(function (error) {
            });
    }
}
const processDataStart = () => {
    return {
        type: actionTypes.PROCESS_DATA_START,
    }
}
const processDataSucceed = (data) => {

    return {
        type: actionTypes.PROCESS_DATA_SUCCEED,
        state: data
    }
}
const processDataFailed = (error) => {
    return {
        type: actionTypes.PROCESS_DATA_FAILED,
        error: error
    }
}
export const getProcessData = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.Authtoken;
        dispatch(processDataStart())
        axios.get(Server.API + request.process_url +`?reprocess_date=${request.reprocess_date}`)
            .then(function (response) {
                if (response.data.error == false) {
                    dispatch(processDataSucceed(response.data))
                    dispatch(Notify.newUserModal(response.data.message, true))
                } else {
                    dispatch(Notify.newUserModal(response.data.message, false))
                }

            })
            .catch(function (error) {
                dispatch(processDataFailed(error))
            });
    }
}

export const getProcessVBData = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.Authtoken;
        dispatch(processDataStart())
        axios.get(Server.API + 'hotel/verticalbooking/process/')
            .then(function (response) {
                dispatch(processDataSucceed(response.data))
                dispatch(Notify.newUserModal(response.data.message, true))
            })
            .catch(function (error) {
                dispatch(processDataFailed(error))
            });
    }
}

export const storeOccupancyDerivation  = (request) =>  { 
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.Authtoken;
        axios.patch(Server.API + 'hotel/pms/occupancy/update/' + request.id + '/', request)
            .then(function (response) { 
                dispatch(actions.getConnectionList(request))
             })
            .catch(function (error) {                
            });
    }
}      
const onResetInventoryStart = () => {
    return {
        type: actionTypes.RESET_INVENTORY_START
    }
}
const onResetInventorySucceed = (data) => {
    return {
        type: actionTypes.RESET_INVENTORY_SUCCEED,
        state: data
    }
}
const onResetInventoryFailed = (error) => {
    return {
        type: actionTypes.RESET_INVENTORY_FAILED,
        error: error
    }
}
export const storeRestApi = (request, isGetApi) => {
  return (dispatch) => {
    axios.defaults.headers.common["Authorization"] = "Token " + request.token;
    dispatch(onResetInventoryStart());
    if (isGetApi) {
      axios
        .get(Server.API + request.process_url)
        .then(function (response) {
          dispatch(onResetInventorySucceed(response.data));
          dispatch(
            Notify.newUserModal("Inventory and Rates Reset Started", true)
          );
        })
        .catch(function (error) {
          dispatch(onResetInventoryFailed(error));
        });
    } else {
      axios
        .post(Server.API + request.process_url)
        .then(function (response) {
          dispatch(onResetInventorySucceed(response.data));
          dispatch(
            Notify.newUserModal("Inventory and Rates Reset Started", true)
          );
        })
        .catch(function (error) {
          dispatch(onResetInventoryFailed(error));
        });
    }
  };
};

const evvivoPropertySucceed = (data) => {
    return {
        type: actionTypes.EVVIVO_PROPERTY_LIST,
        state: data
    }
}

export const getEvivProperty = (request, stopLoader) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/eviivo/get-properties/', request)
            .then(function (response) {
                stopLoader()
                dispatch(evvivoPropertySucceed(response.data))
            })
            .catch(function (error) {
                stopLoader()
                dispatch(Notify.newUserModal("An error occured while getting property list.", false))
            });
    }
}



const hlsPropertySucceed = (data) => {
    return {
        type: actionTypes.HLS_PROPERTY_LIST,
        state: data
    }
}

export const getHLSProperty = (request, stopLoader) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/hls/get-properties/', request)
            .then(function (response) {
                stopLoader()
                dispatch(hlsPropertySucceed(response.data))
            })
            .catch(function (error) {
                stopLoader()
                dispatch(Notify.newUserModal("An error occured while getting property list.", false))
            });
    }
}


const sabeePropertySucceed = (data) => {
    return {
        type: actionTypes.SABEE_PROPERTY_LIST,
        state: data
    }
}
export const getSabeeProperty = (request, stopLoader) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/sabeapp/get_properties/', {username: request.username})
            .then(function (response) {
            })
            .catch(function (error) {
                stopLoader()
                dispatch(Notify.newUserModal("An error occured while getting property list.", false))
            });
    }
}

export const getSabeePropertyList = (request, stopLoader) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/sabeapp/list_properties/', {username: request.username})
            .then(function (response) {
                stopLoader()
                dispatch(sabeePropertySucceed(response.data))
            })
            .catch(function (error) {
                stopLoader()
                dispatch(Notify.newUserModal("An error occured while getting property list.", false))
            });
    }
}

const hotsoftAccountListSucceed = (data) => {
    return {
        type: actionTypes.HOTSOFT_ACCOUNT_LIST,
        state: data
    }
}

export const getHotsoftAccountList = (request, stopLoader) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/hotsoft/get-all-accounts/', request)
            .then(function (response) {
                stopLoader()
                if(response.data.success) {
                    dispatch(hotsoftAccountListSucceed(response.data.data));
                } else {
                    dispatch(Notify.newUserModal(response.data.message, false))    
                }
            })
            .catch(function (error) {
                stopLoader()
                dispatch(Notify.newUserModal("An error occured while getting account list.", false))
            });
    }
}
