import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk"
import authReducer from './reducers/Authentication/Auth';
import hotelAuthReducer from './reducers/Authentication/hotelAuth';
import currencyReducer from './reducers/MasterData/Currency';
import PmsListReducer from './reducers/MasterData/PmsData';
import roomListReducer from './reducers/roomSetting/RoomSetting';
import hotelSettingReducer from './reducers/hotelSetting/HotelSetting';
import clientAdminListReducer from './reducers/users/ClientAdmin';
import managerListReducer from './reducers/users/Manager';
import staffListReducer from './reducers/users/Staff';
import sessionReducer from './reducers/Authentication/sessionCheck';
import skipPriceReducer from './reducers/skipPricing/SkipPricing';
import dailyAdjustmentReducer from './reducers/dailyAdjustment/DailyAdjustment';
import rateSettingReducer from './reducers/rateSetting/RateSetting';
import monthlyAdjustReducer from './reducers/monthlyAdjustment/MonthlyAdjustment'
import weeklyAdjustReducer from './reducers/weeklyAdjustment/WeeklyAdjustment';
import AggresiveReducer from './reducers/MasterData/AggresiveList';
import timeBookingReducer from './reducers/internalParam/TimeBooking';
import adjustmentReducer from './reducers/internalParam/Adjustment';
import competitorReducer from './reducers/competitor/Competitor';
import dataModalReducer from './reducers/internalParam/DataModal';
import connectionReducer from './reducers/connection/Connection';
import clientListReducer from './reducers/clientList/ClientList';
import countryReducer from './reducers/MasterData/CountryList';
import adminUserReducer from './reducers/adminUser/AdminUser';
import hotelListReducer from './reducers/MasterData/HotelList';
import accountReducer from './reducers/account/Account';
import inventaryReducer from './reducers/availibility/Availibility';
import allClientsReducer from './reducers/users/allClients';
import commonReducer from './reducers/common/Common';
import scrapReducer from './reducers/scrap/Scrap';
import scrapListReducer from './reducers/scrap/ScrapList';
import scrapScheduleReducer from './reducers/scrap/ScrapSchedule';
import demandScheduleReducer from './reducers/scrap/onDemandSchedule';
import scheduleHistoryReducer from './reducers/scrap/scheduleHistory';
import pmsSettingReducer from './reducers/PmsSetting/pmsSetting';
import eventReducer from './reducers/event/Event';
import eventCategoryReducer from './reducers/eventCategory/eventCategory';
import algoReducer from './reducers/runAlgo/RunAlgo';
import smartSettingReducer from './reducers/smartSetting/SmartSetting';
import priceNoteReducer from './reducers/priceNote/PriceNote';
import priceSettingReducer from './reducers/price/Price';
import scraperReducer from './reducers/scraper/Scraper';
import featuresReducer from './reducers/features/Features';
import resellerReducer from './reducers/Reseller/reseller';
import mailReducer from './reducers/mailSetting/MailSetting';
import RoomTypeRateReducer from './reducers/roomTypeRate/RoomTypeRate';
import PriceReportReducer from './reducers/price/pricingReport';
import settingsLogReducer from './reducers/SettingsLog/SettingsLogList';
import usersLogReducer from './reducers/UsersLog/UsersLogList';
import apiLogReducer from './reducers/ApiLog/apiLog';
import adminChartReducer from './reducers/adminUser/AdminCharts';
import OTASetupReducer from './reducers/OTASetup/OTASetup';
import OTAInsightsReducer from './reducers/OTAInsights/OTAInsights';
import ErrorTicketsReducer from './reducers/errorTickets/errorTickets';
import PerformanceDashboardReducer from './reducers/PerformanceDashboard/performanceDashboard';
import GenieIntelligenceReducer from './reducers/genieIntelligence/genieIntelligence';
// import scheduleHistory from "../App/Admin/scheduleHistory";
import ResarvationTaskReducer from './reducers/Reservation/taskFailed';
import UserIviteReducer from './reducers/userInvite/userInvite';
import segmentationReducer from './reducers/Segmentation/segmentation';
import popupMessageReducer from './reducers/PopupMessage/PopupMessage';
import scheduleReportReducer from './reducers/scheduleReports/ScheduleReports';
import TagsList from './reducers/tagsList/tagsList';
// const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;
const composeEnhancers = null || compose;
const rootReducer = combineReducers({
  authReducer: authReducer,
  sessionReducer: sessionReducer,
  hotelSettingReducer: hotelSettingReducer,
  currencyReducer: currencyReducer,
  roomListReducer: roomListReducer,
  PmsListReducer: PmsListReducer,
  clientAdminListReducer: clientAdminListReducer,
  managerListReducer: managerListReducer,
  staffListReducer: staffListReducer,
  hotelAuthReducer: hotelAuthReducer,
  skipPriceReducer: skipPriceReducer,
  dailyAdjustmentReducer: dailyAdjustmentReducer,
  rateSettingReducer: rateSettingReducer,
  monthlyAdjustReducer: monthlyAdjustReducer,
  weeklyAdjustReducer: weeklyAdjustReducer,
  aggresiveReducer: AggresiveReducer,
  timeBookingReducer: timeBookingReducer,
  adjustmentReducer: adjustmentReducer,
  competitorReducer: competitorReducer,
  dataModalReducer: dataModalReducer,
  connectionReducer: connectionReducer,
  clientListReducer: clientListReducer,
  countryReducer: countryReducer,
  adminUserReducer: adminUserReducer,
  hotelListReducer: hotelListReducer,
  accountReducer: accountReducer,
  inventaryReducer: inventaryReducer,
  allClientsReducer: allClientsReducer,
  commonReducer: commonReducer,
  scrapReducer: scrapReducer,
  scrapScheduleReducer: scrapScheduleReducer,
  demandScheduleReducer: demandScheduleReducer,
  scheduleHistoryReducer: scheduleHistoryReducer,
  scrapListReducer: scrapListReducer,
  pmsSettingReducer: pmsSettingReducer,
  eventReducer: eventReducer,
  eventCategoryReducer:eventCategoryReducer,
  algoReducer: algoReducer,
  smartSettingReducer: smartSettingReducer,
  priceNoteReducer: priceNoteReducer,
  priceSettingReducer: priceSettingReducer,
  scraperReducer: scraperReducer,
  featuresReducer: featuresReducer,
  resellerReducer: resellerReducer,
  mailReducer: mailReducer,
  RoomTypeRateReducer: RoomTypeRateReducer,
  priceReportReducer: PriceReportReducer,
  settingsLogReducer: settingsLogReducer,
  usersLogReducer: usersLogReducer,
  apiLogReducer: apiLogReducer,
  adminChartReducer: adminChartReducer,
  OTAInsightsReducer:OTAInsightsReducer,
  OTASetupReducer:OTASetupReducer,
  ErrorTicketsReducer:ErrorTicketsReducer,
  PerformanceDashboardReducer:PerformanceDashboardReducer,
  GenieIntelligenceReducer:GenieIntelligenceReducer,
  ResarvationTaskReducer:ResarvationTaskReducer,
  UserIviteReducer: UserIviteReducer,
  popupMessageReducer:popupMessageReducer,
  scheduleReportReducer:scheduleReportReducer,
  segmentationReducer: segmentationReducer,
  TagsList:TagsList,
})

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk))
);

export default store