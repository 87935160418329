import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import Notify from '../Notify';
import * as actions from '../index';

const onMonthlyAdjustStart = () => {
    return {
        type: actionTypes.MONTHLY_ADJUST_START
    }
}

const onMonthlyAdjustFailed = (error) => {
    return {
        type: actionTypes.MONTHLY_ADJUST_FAILED,
        error: error
    }
}

const onMonthlyAdjustSucceed = (data) => {
    return {
        type: actionTypes.MONTHLY_ADJUST_SUCCEED,
        state: data
    }
}

export const getMonthlyAdjust = (request) => {
    return dispatch => {
        // dispatch(onMonthlyAdjustStart())
        // axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        // axios.get(Server.API + 'hotel/adjustment/monthly/list/')
        //     .then(function (response) {
        //         dispatch(onMonthlyAdjustSucceed(response.data))
        //     })
        //     .catch(function (error) {
        //         dispatch(onMonthlyAdjustFailed(error))
        //     });
    }
}


const onUpdateMonthlyAdjustStart = () => {
    return {
        type: actionTypes.CURRENCY_START
    }
}

const onUpdateMonthlyAdjustFailed = (error) => {
    return {
        type: actionTypes.CURRENCY_FAILED,
        error: error
    }
}

const onUpdateMonthlyAdjustSucceed = (data) => {
    return {
        type: actionTypes.CURRENCY_SUCCEED,
        state: data
    }
}

export const updateMonthlyAdjust = (request) => {
    return dispatch => {
        // let requestParams = {
        //     average_occupancy: request.average_occupancy,
        //     average_price: request.average_price,
        //     daily_average: request.daily_average,
        //     percentage: request.percentage,
        // }
        // dispatch(onUpdateMonthlyAdjustStart())
        // axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        // axios.patch(Server.API + '/hotel/adjustment/monthly/update/' + request.id + '/', requestParams)
        //     .then(function (response) {
        if (request.allData) {
            dispatch(actions.updateSettingJson(request, [], 'monthlyAdjust'))
        } else {
            dispatch(actions.updateSettingJson(request, [], 'singleMonthlyAdjust'))
        }
        // dispatch(actions.getMonthlyAdjust(request))
        // dispatch(actions.getSmartSetting(request))
        dispatch(Notify.newUserModal("Monthly Adjustment updated successfully", true))
        //     dispatch(onUpdateMonthlyAdjustFailed(response.data))
        // })
        // .catch(function (error) {
        //     dispatch(onUpdateMonthlyAdjustSucceed(error))
        //     dispatch(Notify.newUserModal("An error occured while updating Monthly Adjustment", false))
        // });
    }
}