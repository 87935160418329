import * as actionTypes from '../../actions/actionTypes';
import fingerprint from 'fingerprintjs'
import jwt from 'jsonwebtoken';

const varifySucceed = (data) => {
    return {
        type: actionTypes.SESSION_CHECK,
        state: data
    }
}

const varifyUserdata = () => {
    var credential = localStorage.getItem("__UserProps__")
    let finderprint = new fingerprint().get();
    credential = jwt.verify(credential, finderprint.toString());
    return credential;
}

export const varifySession = (request) => {
    return dispatch => {
        let varifySession = varifyUserdata();
        dispatch(varifySucceed(varifySession))
    }
}