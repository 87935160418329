import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../../../store/actions/index';
import Notify from '../Notify';


export const quickSetupStepOne = (request) => {
    return dispatch => {
        
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/property_type_set/', request)
            .then(function (response) {
                
            })
            .catch(function (error) {
            })
    }
}


export const quickSetupStepFour = (request) => {
    return dispatch => {
        
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/monthly-weekly-self-assessment-settings/', request)
            .then(function (response) {
                
            })
            .catch(function (error) {
            })
    }
}


const successgetBasePrice = (data) => {
    return {
        type: actionTypes.GET_PRICE_NOTE,
        data: data
    }
}
