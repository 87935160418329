import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../../actions/index';
import Notify from '../Notify'

const onAdminUserStart = () => {
    return {
        type: actionTypes.ADMIN_USER_START
    }
}

const onAdminUserFailed = (error) => {
    return {
        type: actionTypes.ADMIN_USER_FAILED,
        error: error
    }
}

const onAdminUserSucceed = (data) => {
    return {
        type: actionTypes.ADMIN_USER_SUCCEED,
        state: data
    }
}

export const getAdminUser = (request) => {
    return dispatch => {
        dispatch(onAdminUserStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'account/admin/list/')
            .then(function (response) {
                dispatch(onAdminUserSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onAdminUserFailed(error))
            });
    }
}

export const getAdminSales = (request) => {
    return dispatch => {
        dispatch(onAdminUserStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'account/sales/list/')
            .then(function (response) {
                dispatch(onAdminUserSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onAdminUserFailed(error))
            });
    }
}



export const deleteAdminUser = (request) => {
    return dispatch => {
        dispatch(onAdminUserStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.delete(Server.API + 'account/admin/delete/' + request.id + '/')
            .then(function (response) {
                dispatch(Notify.newUserModal("Delete user Successfully", true))
                dispatch(actions.getAdminUser(request))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Error while deleting user", false))
                dispatch(onAdminUserFailed(error))
            });
    }
}

export const AddAdminUser = (request) => {
    return dispatch => {
        dispatch(onAdminUserStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'account/admin/register/', request)
            .then(function (response) {
                dispatch(Notify.newUserModal("User Created Successfully", true))
                dispatch(actions.getAdminUser(request))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Error while creating user", false))
                dispatch(onAdminUserFailed(error))
            });
    }
}

export const updateAdminUser = (request) => {
    return dispatch => {
        dispatch(onAdminUserStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.patch(Server.API + 'account/admin/update/' + request.id + '/', request)
            .then(function (response) {
                dispatch(Notify.newUserModal("User Updated Successfully", true))
                dispatch(actions.getAdminUser(request))
            })
            .catch(function (error) {
                dispatch(Notify.newUserModal("Error while creating user", false))
                dispatch(onAdminUserFailed(error))
            });
    }
}

const onGetResponsiblePersonStart = () => {
    return {
        type: actionTypes.GET_RESPONSIBLE_PERSON_START
    }
}

const onGetResponsiblePersonFailed = (error) => {
    return {
        type: actionTypes.GET_RESPONSIBLE_PERSON_FAILED,
        error: error
    }
}

const onGetResponsiblePersonSucceed = (data) => {
    return {
        type: actionTypes.GET_RESPONSIBLE_PERSON_SUCCEED,
        state: data
    }
}

export const getResponsiblePerson = (request) => {
    return dispatch => {
        dispatch(onGetResponsiblePersonStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'account/dealowner/list/')
            .then(function (response) {
                dispatch(onGetResponsiblePersonSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onGetResponsiblePersonFailed(error))
            });
    }
}

const ongetSubscribedClientDataStart = () => {
    return {
        type: actionTypes.GET_SUBSCRIBED_CLIENT_DATA_START
    }
}

const ongetSubscribedClientDataFailed = (error) => {
    return {
        type: actionTypes.GET_SUBSCRIBED_CLIENT_DATA_FAILED,
        error: error
    }
}

const ongetSubscribedClientDataSucceed = (data) => {
    return {
        type: actionTypes.GET_SUBSCRIBED_CLIENT_DATA_SUCCEED,
        state: data
    }
}

export const getSubscribedClientData = (request) => {
    return dispatch => {
        dispatch(ongetSubscribedClientDataStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/client_dashboard/subscribed-clients/?deal_owner_filter='+request.deal_owner_filter+'&deal_owner_type='+request.deal_owner_type+'&status_type='+request.status_type)
            .then(function (response) {
                dispatch(ongetSubscribedClientDataSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(ongetSubscribedClientDataFailed(error))
            });
    }
}

const ongetFreeTrialClientDataStart = () => {
    return {
        type: actionTypes.GET_FREE_TRIAL_CLIENT_DATA_START
    }
}

const ongetFreeTrialClientDataFailed = (error) => {
    return {
        type: actionTypes.GET_FREE_TRIAL_CLIENT_DATA_FAILED,
        error: error
    }
}

const ongetFreeTrialClientDataSucceed = (data) => {
    return {
        type: actionTypes.GET_FREE_TRIAL_CLIENT_DATA_SUCCEED,
        state: data
    }
}

export const getFreeTrialClientData = (request) => {
    return dispatch => {
        dispatch(ongetFreeTrialClientDataStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/client_dashboard/free-trial-clients/?deal_owner_filter='+request.deal_owner_filter+'&deal_owner_type='+request.deal_owner_type+'&status_type='+request.status_type)
            .then(function (response) {
                dispatch(ongetFreeTrialClientDataSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(ongetFreeTrialClientDataFailed(error))
            });
    }
}

const onCloseTicketStart = () => {
    return {
        type: actionTypes.CLOSE_TICKET_START
    }
}

const onCloseTicketFailed = (error) => {
    return {
        type: actionTypes.CLOSE_TICKET_FAILED,
        error: error
    }
}

const onCloseTicketSucceed = (data) => {
    return {
        type: actionTypes.CLOSE_TICKET_SUCCEED,
        state: data
    }
}

export const closeTicket = (request) => {
    return dispatch => {
        dispatch(onCloseTicketStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/client_dashboard/close-ticket/', request)
            .then(function (response) {
                dispatch(onCloseTicketSucceed(response.data))
                dispatch(getSubscribedClientData(request)) 
                dispatch(getFreeTrialClientData(request)) 
            })
            .catch(function (error) {
                dispatch(onCloseTicketFailed(error))
            });
    }
}

const onuUpdateCommentStart = () => {
    return {
        type: actionTypes.UPDATE_TICKET_COMMENT_START
    }
}

const onuUpdateCommentFailed = (error) => {
    return {
        type: actionTypes.UPDATE_TICKET_COMMENT_FAILED,
        error: error
    }
}

const onuUpdateCommentSucceed = (data) => {
    return {
        type: actionTypes.UPDATE_TICKET_COMMENT_SUCCEED,
        state: data
    }
}

export const updateComment = (request) => {
    return dispatch => {
        dispatch(onuUpdateCommentStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/client_dashboard/add-comment/', request)
            .then(function (response) {
                dispatch(onuUpdateCommentSucceed(response.data))
                dispatch(getSubscribedClientData(request))
                dispatch(getFreeTrialClientData(request))                 
            })
            .catch(function (error) {
                dispatch(onuUpdateCommentFailed(error))
            });
    }
}

const ongetPmsChartDataStart = () => {
    return {
        type: actionTypes.GET_PMS_CHART_DATA_START
    }
}

const ongetPmsChartDataFailed = (error) => {
    return {
        type: actionTypes.GET_PMS_CHART_DATA_FAILED,
        error: error
    }
}

const ongetPmsChartDataSucceed = (data) => {
    return {
        type: actionTypes.GET_PMS_CHART_DATA_SUCCEED,
        state: data
    }
}

export const getPmsChartData = (request) => {
    return dispatch => {
        dispatch(ongetPmsChartDataStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/client_dashboard/pms-chart-view/?deal_owner_filter='+request.deal_owner_filter+'&deal_owner_type='+request.deal_owner_type)
            .then(function (response) {
                dispatch(ongetPmsChartDataSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(ongetPmsChartDataFailed(error))
            });
    }
}

const ongetDealOwnerChartDataStart = () => {
    return {
        type: actionTypes.GET_DEAL_OWNER_CHART_DATA_START
    }
}

const ongetDealOwnerChartDataFailed = (error) => {
    return {
        type: actionTypes.GET_DEAL_OWNER_CHART_DATA_FAILED,
        error: error
    }
}

const ongetDealOwnerChartDataSucceed = (data) => {
    return {
        type: actionTypes.GET_DEAL_OWNER_CHART_DATA_SUCCEED,
        state: data
    }
}

export const getDealOwnerChartData = (request) => {
    return dispatch => {
        dispatch(ongetDealOwnerChartDataStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/client_dashboard/dealowner-chart-view/?deal_owner_filter='+request.deal_owner_filter+'&deal_owner_type='+request.deal_owner_type)
            .then(function (response) {
                dispatch(ongetDealOwnerChartDataSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(ongetDealOwnerChartDataFailed(error))
            });
    }
}
