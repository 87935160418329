import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Grid } from '@material-ui/core';
import FreetrialRecords from './components/FreetrialRecords';
import FreeTrialRunningOut from './components/FreeTrialRunningOut';
import FreeTrialExpired from './components/FreeTrialExpired';
import FreeTrialExpiredLastLogin from './components/FreeTrialExpiredLastLogin';
import * as actions from '../../../store/actions/index';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import OpenCases from './components/OpenCases';
import FreeTrialAutoUploadOff from './components/FreeTrialAutoUploadOff';
const styles = {
  gridContainer: {
    marginLeft:"20px"
  },
  filter:{
    textAlign:'right',
    marginRight:"20px"
  },
  formControl:{
    marginLeft:'30px'
  }
};

class trialClients extends React.Component {
    state = {
      freeTrialClientData:{},
      deal_owner:"all|all",
      deal_owner_filter:'all',
      deal_owner_type:'all',
      status_type:'all',
      error: []
    };

    handleChange = (event) => {
      const name = event.target.name;
      let deal_owner_list = event.target.value.split("|");
      this.setState({
        ...this.state,
        [name]: event.target.value,
        deal_owner_filter: deal_owner_list[0],
        deal_owner_type: deal_owner_list[1]
      });
      let requestParams = {
        token: this.props.userSession.token,
        deal_owner_filter: deal_owner_list[0],
        deal_owner_type: deal_owner_list[1],
        status_type: this.state.status_type
      };
        this.props.getFreeTrialClientData(requestParams);
    };
    
    handleChangeStatus = (event) => {
      const name = event.target.name;
      this.setState({
        ...this.state,
        [name]:  event.target.value
      });
      let requestParams = {
        token: this.props.userSession.token,
        deal_owner_filter: this.state.deal_owner_filter,
        deal_owner_type: this.state.deal_owner_type,
        status_type: event.target.value
      };
        this.props.getFreeTrialClientData(requestParams);
    };

    componentWillMount() {
        let requestParams = {
          token: this.props.userSession.token,
          deal_owner_filter: 'all',
          deal_owner_type: 'all',
          status_type: 'all'
        };
          this.props.getResponsiblePerson(requestParams);
          this.props.getFreeTrialClientData(requestParams);
    }

    componentWillReceiveProps(props) {
      if (props.adminUserReducer.responsiblePersonList) {
          this.setState({
              responsiblePersonList: props.adminUserReducer.responsiblePersonList
          })
      }
      if (props.adminUserReducer.freeTrialClientData) {
          this.setState({
            freeTrialClientData: props.adminUserReducer.freeTrialClientData
          })
      }
    }

    render() {
      const { classes } = this.props;
        return (
          <Card>
            <CardHeader className="headig-top" title="Free Trial Clients"></CardHeader>
            <CardContent>
            <Typography variant="subtitle1" gutterBottom align="left"  className={classes.filter} >
              <FormControl className={classes.formControl}>
                <NativeSelect
                  className={classes.selectEmpty}
                  value={this.state.deal_owner}
                  name="deal_owner"
                  onChange={this.handleChange}
                  inputProps={{ 'aria-label': 'age' }}
                >
                  <option value="all|all">All Users</option>
                  {this.state.responsiblePersonList && this.state.responsiblePersonList.map((e, key) => {
                      return <option key={key} value={e.id+"|"+e.type}>{e.name}</option>;
                  })}   
                </NativeSelect>
                <FormHelperText>Deal Owner</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <NativeSelect
                  className={classes.selectEmpty}
                  value={this.state.status}
                  name="status_type"
                  onChange={this.handleChangeStatus}
                  inputProps={{ 'aria-label': 'Status' }}
                >
                  <option value="all">Open/In progress</option>
                  <option value="open">Open</option>
                  <option value="in_progress">In Progress</option>
                  <option value="closed">Closed</option> 
                </NativeSelect>
                <FormHelperText>Status</FormHelperText>
              </FormControl>
            </Typography>
            <Grid container spacing={4} >
              <Grid item lg={4} md={6} xl={4} xs={12} >
                <FreetrialRecords free_trial_count={this.state.freeTrialClientData.free_trial_count} free_trial_last_one_week_count ={this.state.freeTrialClientData.free_trial_last_one_week_count} deal_owner_filter={this.state.deal_owner_filter} deal_owner_type={this.state.deal_owner_type} status_type={this.state.status_type} />
              </Grid>
              <Grid item lg={4} md={6} xl={4} xs={12} >  
                <OpenCases open_cases_count={this.state.freeTrialClientData.open_cases_count} open_cases_count_last_one_week ={this.state.freeTrialClientData.open_cases_count_last_one_week} deal_owner_filter={this.state.deal_owner_filter} deal_owner_type={this.state.deal_owner_type} />
              </Grid>
              <Grid item lg={6} md={6} xl={6} xs={12} >
                <FreeTrialRunningOut free_trial_running_out_count={this.state.freeTrialClientData.free_trial_running_out_count} free_trial_running_out={this.state.freeTrialClientData.free_trial_running_out} deal_owner_filter={this.state.deal_owner_filter} deal_owner_type={this.state.deal_owner_type} status_type={this.state.status_type} />
              </Grid>
              <Grid item lg={6} md={12} xl={6} xs={12} >
                <FreeTrialExpired free_trial_expired_count={this.state.freeTrialClientData.free_trial_expired_count} free_trial_expired={this.state.freeTrialClientData.free_trial_expired} deal_owner_filter={this.state.deal_owner_filter} deal_owner_type={this.state.deal_owner_type} status_type={this.state.status_type} />
              </Grid>
              <Grid item lg={6} md={6} xl={6} xs={12} >
                <FreeTrialExpiredLastLogin free_trial_last_login_count={this.state.freeTrialClientData.free_trial_last_login_count} free_trial_last_login={this.state.freeTrialClientData.free_trial_last_login} deal_owner_filter={this.state.deal_owner_filter} deal_owner_type={this.state.deal_owner_type} status_type={this.state.status_type} />
              </Grid>
              <Grid item lg={6} md={6} xl={6} xs={12} >
                <FreeTrialAutoUploadOff free_trial_hotel_auto_upload_off={this.state.freeTrialClientData.free_trial_hotel_auto_upload_off} free_trial_hotel_auto_upload_off_count={this.state.freeTrialClientData.free_trial_hotel_auto_upload_off_count} deal_owner_filter={this.state.deal_owner_filter} deal_owner_type={this.state.deal_owner_type} status_type={this.state.status_type} />
              </Grid>
            </Grid>
            </CardContent>
          </Card >
        );
    }
}

const mapStateToProps = state => {
  return {
      loading: state.authReducer.loading,
      error: state.authReducer.error,
      userSession: state.sessionReducer,
      adminUserReducer: state.adminUserReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
      getClientList: (request) => { dispatch(actions.getClientList(request)) },
      getCountry: (request) => { dispatch(actions.getCountry(request)) },
      getResponsiblePerson: (request) => { dispatch(actions.getResponsiblePerson(request)) },
      getFreeTrialClientData: (request) => { dispatch(actions.getFreeTrialClientData(request)) },
  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(trialClients);
