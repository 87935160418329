import React, { useState, useEffect } from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import occupancyCss from './css/communityCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import CustomTooltip from '../Utils/toolTip';
import { withNamespaces } from 'react-i18next';
import * as actions from '../../store/actions/index';
import InputLabel from '@material-ui/core/InputLabel';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import {  MuiThemeProvider } from '@material-ui/core/styles';
import { CommunityTheme } from '../muiTheme';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Modals from './modal/modal';
import TableCell from '@material-ui/core/TableCell';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import EmailIcon from '@material-ui/icons/Email';
import CallIcon from '@material-ui/icons/Call';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import StepConnector from '@material-ui/core/StepConnector';
import HeaderCard from "./HeaderCard";

  
function getSteps() {
    return ['Select campaign settings', 'Create an ad group', 'Create an ad'];
}

const styles = occupancyCss;  
class Community extends React.Component {

    state = {
        error: [],
        errorMsg: "",
        tab: "0",
        expanded:'',
        loading:false,
        ReferFriendModalOpen:false,
        referralSuccessMsg:'',
        activeStep: 0,
        isActiveOne: false,
        isActiveTwo: false,
        referralDealData: [],
        dealEarnedCash: 0,
        dealEarnedSubscription: 0,
        message:'Hi friend, I have been using RoomPriceGenie to automate my room pricing for quite some time already. It has not only saved me time but also increased my revenue. Start a free trial with them and see what they can do for you!',
        language:'en',
        room_apartment_space_name:""
    };

    componentWillMount() {
        let requestParams = {
            token: this.props.userSession.token
        };
        this.props.getreferralDealProgramLists(requestParams)
        if(this.props.hotelAuthReducer.hotelDetails.hotel){
            this.setState({room_apartment_space_name:this.props.hotelAuthReducer.hotelDetails.hotel.room_apartment_space_name})
        }
    }

    componentDidMount(){
       
        // if(![1857, 1800, 1819, 1371,1167,985,812,1480,1462,1669,1740,398, 766, 789, 1730 ,935].includes(this.props.hotelAuthReducer.hotelDetails.hotel.id)){
            this.setState({
                tab: "0"
            })
        // }
    }

    componentWillReceiveProps(props) {
        if(props.userSession.user.language=='de'){
            this.setState({
                message:'Hallo, ich verwende schon seit einiger Zeit RoomPriceGenie. Das ist eine Softwarelösung, mir der ich die Preisgestaltung meiner Zimmer schnell und einfach automatisieren konnte. Damit habe ich nicht nur viel Zeit eingespart, sondern auch meinen Umsatz deutlich gesteigert. Starten Sie jetzt Ihren kostenlosen Probezeitraum und sehen Sie, was RoomPriceGenie auch für Sie tun kann!',
            })
        }else{
            const { t } = this.props;
            this.setState({
                message:t(`Hi friend, I have been using RoomPriceGenie to automate my ${this.state.room_apartment_space_name.toLowerCase()} pricing for quite some time already. It has not only saved me time but also increased my revenue. Start a free trial with them and see what they can do for you!`,)
            })
        }
        if(props.commonReducer.referralSuccess && props.commonReducer.referralSuccess != this.props.commonReducer.referralSuccess){
            this.setState({referralSuccessMsg:props.commonReducer.referralSuccess.message,ReferFriendModalOpen:true})
        }
        this.setState({
            loading:props.commonReducer.loading
        })
        if(props.commonReducer.referralDealProgram &&  props.commonReducer.referralDealProgram.data) {
            this.setState({
                referralDealData: props.commonReducer.referralDealProgram.data.results,
                dealEarnedCash: props.commonReducer.referralDealProgram.referral_earned.deal_earned_referral_cash,
                dealEarnedSubscription: props.commonReducer.referralDealProgram.referral_earned.deal_earned_referral_subscriptions,
            })
        }
        if(props.hotelAuthReducer.hotelDetails.hotel){
            this.setState({room_apartment_space_name: props.hotelAuthReducer.hotelDetails.hotel.room_apartment_space_name})
        }
    }

    handleTab = (tab) => {
        this.setState({
            tab
        })
    }

    handleOccChange = (name) => event => {
        this.setState({
            [name]:event.target.value,
        })
    }


    validateForm = () => {
        let error = [];

        if (!this.state.friend_first_name) {
            error.push('friend_first_name')
        }
        if (!this.state.friend_last_name) {
            error.push('friend_last_name')
        }
        if (!this.state.friend_email) {
            error.push('friend_email')
        }
        if (!this.state.first_name) {
            error.push('first_name')
        }
        if (!this.state.last_name) {
            error.push('last_name')
        }
        if (!this.state.friend_property_name) {
            error.push('friend_property_name')
        }
        if (!this.state.message) {
            error.push('message')
        }
        if (error) {
            this.setState({
                error: error
            })
            return error
        }
        else {
            return false
        }
    }


    referFriendModalOpen = () =>{
        this.setState({
            ReferFriendModalOpen:true
        })
    }
    closeReferFriendModal = () =>{
        this.setState({
            ReferFriendModalOpen:false,
            tab: '1'
        })
    }

    submitOccupancy = () => {
        let validateError = this.validateForm();
        if (validateError.length > 0) {
            return;
        }
        let requestParam = {
            token: this.props.hotelAuthReducer.hotelDetails.token ,
            request:{
                friend_first_name:this.state.friend_first_name,
                friend_last_name:this.state.friend_last_name,
                friend_email:this.state.friend_email,
                first_name:this.state.first_name,
                last_name:this.state.last_name,
                friend_property_name:this.state.friend_property_name,
                message:this.state.message
            }
        }
        this.props.referralDeal(requestParam);
    }
    handleChangeExpansion = panel => (event, expanded) => {
        this.setState({
          expanded: expanded ? panel : false,
        });
    };
    
    loginToAcademy = () =>{
        window.open('https://academy.roompricegenie.com/login/index.php?roompricegenie_client','_blank')
    }
      
    handleStepperReferralDeal = () => {
        var jsx = [];
        const { classes } = this.props;
        const { t } = this.props;
        const steps = getSteps();
        const { activeStep } = this.state;
        const { active, completed } = this.props;
        let is_setup_call_done_active = false;
        let is_subscribed_active = false;

        const connector = (
            <StepConnector
              classes={{
                active: classes.active,
                completed: classes.completed,
                line: classes.line,
              }}
            />
        );
        
        if(this.state.referralDealData && this.state.referralDealData !== "" && this.state.referralDealData.length > 0) {
            {
                this.state.referralDealData.map((referral_data) => {
                    let activeStepp = 0
                    // 458916                               - 1. Sign-up Stage                 - Invitation sent Stage
                    // 509fac1a-67cc-468e-b700-ad7c2beb1373 - 2. Demo Done & Integration Stage - Invitation sent Stage
                    // a5e37723-aab5-49d0-b567-c11b7386fdee - 3. Setup Stage                   - Invitation sent Stage
                    // 6993540                              - 3.1 Waiting for Setup Call       - Invitation sent Stage
                    // 1067670                              - 4. Trial Stage I (Auto OFF)      - Setup Call Done
                    // 1067671                              - 5. Trial Stage II (Auto ON)      - Setup Call Done
                    // ea888517-0003-4ebf-8139-eb88b3291081 - Won Deal                         - Won Deal

                    if(referral_data.properties.dealstage && (referral_data.properties.dealstage == '458916' || referral_data.properties.dealstage == '509fac1a-67cc-468e-b700-ad7c2beb1373' || referral_data.properties.dealstage == 'a5e37723-aab5-49d0-b567-c11b7386fdee' || referral_data.properties.dealstage == '6993540')) {
                        is_setup_call_done_active = false
                        is_subscribed_active = false
                    } else if(referral_data.properties.dealstage && (referral_data.properties.dealstage == '1067670' || referral_data.properties.dealstage == '1067671')) {
                        is_setup_call_done_active = true
                        is_subscribed_active = false
                        activeStepp = activeStepp + 1
                    } else if(referral_data.properties.dealstage && referral_data.properties.dealstage == 'ea888517-0003-4ebf-8139-eb88b3291081') {
                        is_setup_call_done_active = true
                        is_subscribed_active = true
                        activeStepp = activeStepp + 2
                    }

                    jsx.push(
                        <div className={classes.referralDeal}>
                            <div className={classes.referralDealDetail}>
                                <DialogContentText align="left" className={classes.referralFriend}>
                                {t(referral_data.properties.dealname)} <br />
                                </DialogContentText>
                                
                                <Stepper alternativeLabel activeStep={activeStepp} connector={connector}>
                                    <Step key={1}>
                                        <StepLabel icon={<EmailIcon />} className={classes.activeIconTab}>{t("Invitation sent")}</StepLabel>
                                    </Step>
                                    <Step key={2}>
                                        <StepLabel icon={<CallIcon />} className={is_setup_call_done_active ? classes.activeIconTab : ''}>{t("Setup Call Done")}</StepLabel>
                                    </Step>
                                    <Step key={3}>
                                        <StepLabel icon={<MonetizationOnIcon />} className={is_subscribed_active ? classes.activeIconTab : ''}>{t("Subscribed")}</StepLabel>
                                    </Step>
                                </Stepper>
                            </div>
                        </div>
                    )
                })
            }
        } else {
            jsx.push(
                <div className={classes.referralDeal}>
                    <div className={classes.referralDealDetail}>
                        <DialogContentText align="left" className={classes.referralFriend}>
                        {t('Your first referral is waiting')} <br />
                        </DialogContentText>
                        
                        <Stepper alternativeLabel activeStep={activeStep} connector={connector} >
                            <Step key={1}>
                                <StepLabel icon={<EmailIcon />} active={false} >{t("Invitation sent")}</StepLabel>
                            </Step>
                            <Step key={2}>
                                <StepLabel icon={<CallIcon />} >{t("Setup Call Done")}</StepLabel>
                            </Step>
                            <Step key={3}>
                                <StepLabel icon={<MonetizationOnIcon />} >{t("Subscribed")}</StepLabel>
                            </Step>
                        </Stepper>
                    </div>
                </div>
            )
        }

        return jsx;
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        const steps = getSteps();
        const { activeStep } = this.state;
        const { active, completed } = this.props;
        const connector = (
            <StepConnector
              classes={{
                active: classes.active,
                completed: classes.completed,
                line: classes.line,
              }}
            />
          );

        return (
            <>
                <HeaderCard>
                <Grid container>
                    <Grid item >
                        <div style={{ float: "left", marginTop: "2px" }}>
                        <Typography variant="h5" style={{ textAlign: "left", fontWeight: "500" }}>
                          {t("RoomPriceGenie Referral Program")}
                        </Typography>
                        <Tabs
                                indicatorColor="primary"
                                textColor="primary"
                                scrollButtons="on"
                                value={this.state.tab}
                                onChange={(evt, newtab) => this.handleTab(newtab)}
                                style={{ borderBottom: '1px solid #f0f0f0', margin: "10px 0px 0px 0px", }}
                            >
                                {/* {[1857, 1800, 1819, 1371,1167,985,812,1480,1462,1669,1740,398, 766, 789, 1730 ,935].includes(this.props.hotelAuthReducer.hotelDetails.hotel.id) && */}
                                    <Tab value="0" label={t("Refer A Friend")} wrapped />
                                {/* } */}
                                {/* {[1857, 1800, 1819, 1371,1167,985,812,1480,1462,1669,1740,398, 766, 789, 1730 ,935].includes(this.props.hotelAuthReducer.hotelDetails.hotel.id) && */}
                                    <Tab value="1" label={t("Track Your Referrals")}/>
                                {/* } */}
                                    {/* <Tab value="2" label={t("RoomPriceGenie Academy")} /> */}
                                
                            </Tabs>
                        </div>
                    </Grid>
                </Grid>
            </HeaderCard>
            <MuiThemeProvider theme={CommunityTheme}>
                <div className={classes.root}>
                    <Card>
                        <CardContent>
                            {this.state.tab == "0" &&
                                <>
                                    {/* <div>
                                        <Button disabled={this.state.loadData} className={["orangButton", classes.saveBtn]} variant="contained" color="primary" onClick={() => this.toggleWarningModal("saveCommunity")}>
                                            {t("Save")}{this.state.loadData && <CircularProgress color="secondary" size={24} className={classes.buttonProgress} />}
                                        </Button>
                                    </div> */}

                                    <DialogContentText align="left" className={classes.helpText}>
                                    {t("As a growing company, we really appreciate your support. And the referrals we get from happy customers are one of the most fulfilling ways we keep growing. They make us smile a lot!")} <br />
                                    {t("All you need to do is fill in the form here, with details of someone you think would appreciate RoomPriceGenie, and we will contact them with your message.")}<br />
                                    {t("As a small sign of our gratitude, we would like to offer you a $50 Amazon voucher for anyone you refer that has a trial with us, plus one month free if they subscribe.")}<br />
                                    {t("Thank you in advance for the smiles.")}

                                    </DialogContentText>

                                    <div>
                                        <div className={classes.dateTableDiv}>
                                            <FormControl  required margin="none" className={classes.settingFields}>
                                                <InputLabel htmlFor="friend_first_name" style={{ marginTop: '-3px' }} shrink={true}>{t("Friend's First Name")}</InputLabel>
                                                <Input
                                                    error={this.state.error.includes("friend_first_name")}
                                                    margin="none"
                                                    id="friend_first_name"
                                                    name="friend_first_name"
                                                    onChange={this.handleOccChange('friend_first_name')}
                                                    placeholder={t("Please enter Friend Friend's Name")}
                                                />
                                                {this.state.error.includes("friend_first_name") &&
                                                    <span class='error-message'>{t("* Please enter Friend's First Name")}</span>
                                                }
                                            </FormControl>

                                            <FormControl required margin="none" className={classes.settingFields}>
                                                <InputLabel htmlFor="friend_last_name" style={{ marginTop: '-3px' }} shrink={true}>{t("Friend's Last Name")}</InputLabel>
                                                <Input
                                                    error={this.state.error.includes("friend_last_name")}
                                                    margin="none"
                                                    id="friend_last_name"
                                                    name="friend_last_name"
                                                    onChange={this.handleOccChange('friend_last_name')}
                                                    placeholder={t("Please enter Friend's Last Name")}
                                                />
                                                {this.state.error.includes("friend_last_name") &&
                                                    <span class='error-message'>{t("* Please enter Friend's Last Name")}</span>
                                                }
                                            </FormControl>
                                        </div>
                                        <div className={classes.dateTableDiv}>
                                            <FormControl required margin="none" className={classes.settingFields}>
                                                <InputLabel htmlFor="friend_email" style={{ marginTop: '-3px' }} shrink={true}>{t("Friend's Email")}</InputLabel>
                                                <Input
                                                    error={this.state.error.includes("friend_email")}
                                                    margin="none"
                                                    id="friend_email"
                                                    name="friend_email"
                                                    onChange={this.handleOccChange('friend_email')}
                                                    placeholder={t("Please enter Friend's Email")}
                                                />
                                                {this.state.error.includes("friend_email") &&
                                                    <span class='error-message'>{t("* Please enter Friend's Email")}</span>
                                                }
                                            </FormControl>
                                            <FormControl required margin="none" className={classes.settingFields}>
                                                <InputLabel htmlFor="friend_property_name" style={{ marginTop: '-3px' }} shrink={true}>{t("Friend's Property Name")}</InputLabel>
                                                <Input
                                                    error={this.state.error.includes("friend_property_name")}
                                                    margin="none"
                                                    id="friend_property_name"
                                                    name="friend_property_name"
                                                    onChange={this.handleOccChange('friend_property_name')}
                                                    placeholder={t("Please enter Friend's Property Name")}
                                                />
                                                {this.state.error.includes("friend_property_name") &&
                                                    <span class='error-message'>{t("* Please enter Friend's Property Name")}</span>
                                                }
                                            </FormControl>
                                        </div>
                                        <div className={classes.dateTableDiv}>
                                            <FormControl required margin="none" className={classes.settingFields}>
                                                <InputLabel htmlFor="first_name" style={{ marginTop: '-3px' }} shrink={true}>{t("Your First Name")}</InputLabel>
                                                <Input
                                                    
                                                    error={this.state.error.includes("first_name")}
                                                    margin="none"
                                                    id="first_name"
                                                    name="first_name"
                                                    onChange={this.handleOccChange('first_name')}
                                                    placeholder={t('Please enter Your First Name')}
                                                />
                                                {this.state.error.includes("first_name") &&
                                                    <span class='error-message'>{t("* Please enter First Name")}</span>
                                                }
                                            </FormControl>
                                            <FormControl required margin="none" className={classes.settingFields}>
                                                <InputLabel htmlFor="last_name" style={{ marginTop: '-3px' }} shrink={true}>{t("Your Last Name")}</InputLabel>
                                                <Input
                                                    
                                                    error={this.state.error.includes("last_name")}
                                                    margin="none"
                                                    id="last_name"
                                                    name="last_name"
                                                    onChange={this.handleOccChange('last_name')}
                                                    placeholder={t('Please enter Your Last Name')}
                                                />
                                                {this.state.error.includes("last_name") &&
                                                    <span class='error-message'>{t("* Please enter Last Name")}</span>
                                                }
                                            </FormControl>
                                        </div>
                                        <div className={classes.dateTableDiv}>
                                            <FormControl required margin="none" className={classes.settingFieldsTextArea}>
                                                <InputLabel htmlFor="message" style={{ marginTop: '-20px' }} shrink={true}>{t("Your Personal Message")}</InputLabel>
                                                <TextField
                                                   
                                                    error={this.state.error.includes("message")}
                                                    margin="none"
                                                    id="message"
                                                    name="message"
                                                    multiline
                                                    rows="5"
                                                    value={this.state.message}
                                                    fullWidth
                                                    onChange={this.handleOccChange('message')}
                                                    placeholder={t('Please enter Your Personal Message')}
                                                />
                                                {this.state.error.includes("message") &&
                                                   <span class='error-message'>{t("* Please enter Message")}</span>
                                                }
                                            </FormControl>
                                        </div>
                                        <div className={classes.inviteBtn}>
                                        <Button disabled={this.state.loading} className={classes.saveBtn} onClick={this.submitOccupancy} variant="contained" color="primary" >
                                            {t('Invite Friend')}{this.state.loading ? <CircularProgress color="primary" size={24} className={classes.buttonProgress} /> : ""}
                                        </Button>
                                        </div>
                                    <span class="error-message">{this.state.errorMsg}</span>
                                    </div>
                                </>
                            }

                            {this.state.tab == "1" &&
                                <div>
                                    <DialogContentText align="left" className={classes.helpText}>
                                    {t("We are determined to warmly welcome your referrals to the RoomPriceGenie journey. You can check on the success of your referrals below.")} <br />
                                    </DialogContentText>

                                    <TableCell align="left">
                                        <table>
                                            <tr>
                                                <td className={classes.tablerow} style={{paddingRight:'50px'}}>${this.state.dealEarnedCash} {t("Amazon Vouchers")} </td> 
                                                <td className={classes.tablerow}>{this.state.dealEarnedSubscription} {t("Months RoomPriceGenie Coupons")} </td>
                                            </tr>
                                        </table>
                                    </TableCell>
                                    {this.handleStepperReferralDeal()}
                                </div>
                            }

                            {this.state.tab == "2" &&
                                <>
                                    <DialogContentText align="left" className={classes.helpText}>
                                        {t("Would you like to learn more about revenue management and how to use RoomPriceGenie better? Perhaps you would like to learn about how other hotel technology can help you to run your property more efficiently. We've had really great feedback on our academy, and we'd like to share it with you free of charge.")} <br /><br />
                                        <Button  className={classes.saveBtn} onClick={this.loginToAcademy} variant="contained" color="primary" >
                                            {t('Login Academy')}
                                        </Button>
                                    </DialogContentText>
                                    {/* <Button disabled={this.state.loadData} className={["orangButton", classes.saveBtn]} variant="contained" color="primary"
                                        onClick={() => this.state.tab == 0 ? this.toggleWarningModal("saveCommunity") : (this.state.tab == 1 ? this.updateTimeToBooking() : "")}>
                                        {t("Login to Academy")}{this.state.loadData && <CircularProgress color="secondary" size={24} className={classes.buttonProgress} />}
                                    </Button> */}
                                    <form noValidate autoComplete="off">
                                        <Grid container spacing={0} style={{background:'#f7f7f7',padding:'25px',borderRadius:'12px'}}>
                                            <Grid xs={12} sm={5} md={5} item className={classes.gridPadding2}>
                                                <div className={classes.mapDiv}>
                                                    <div align="left" className={classes.titleText} style={{marginBottom:'12px'}}>{t('Learning Objectives')}</div>
                                                    <DialogContentText align="left" className={classes.helpText2}>
                                                        {t("This is a hands-on course giving actionable insights to increase profitability using the latest hotel technology. After completing it, along with the accompanying checklist, you should feel more comfortable with revenue management and technology and see a significant increase in profitability. You will gain:")} <br /><br />
                                                    </DialogContentText>
                                                    <List className={classes.listStyle}>
                                                        <ListItem className={classes.listtStyle}>
                                                            {t('A self-sufficiency with the vast array of hotel software and a knowledge of the most suitable for your hotel')}
                                                        </ListItem>
                                                        <ListItem className={classes.listtStyle}>
                                                            {t('A better understanding and feeling for revenue management')}
                                                        </ListItem>
                                                        <ListItem className={classes.listtStyle}>
                                                            {t('Multiple tips to increase your revenue further')}
                                                        </ListItem>
                                                        <ListItem className={classes.listtStyle}>
                                                            {t('An appreciation of the benefits and control you have with automation of pricing')}
                                                        </ListItem>                                                                                                                                                                                                                                                                                                                                                                                                                             
                                                        <ListItem className={classes.listtStyle}>
                                                            {t('A good level at branding and digital marketing as it applies to hotels')}
                                                        </ListItem>
                                                        <ListItem className={classes.listtStyle}>
                                                            {t('Optional expert courses, for example in advanced revenue management, or Google My Business sufficiency.')}
                                                        </ListItem>
                                                        <ListItem className={classes.listtStyle}>
                                                            {t('Noticeable increase in revenue versus your peer group')}
                                                        </ListItem>
                                                    </List>
    
                                                </div>

                                                <div className={classes.mapDiv}>
                                                    <div align="left" className={classes.titleText}>{t('Recommended Know-How')}</div>
                                                    <List className={classes.listStyle}>
                                                        <ListItem className={classes.listtStyle}>
                                                            {t('No previous knowledge required')}
                                                        </ListItem>
                                                        <ListItem className={classes.listtStyle}                                                                                                                                                                >
                                                            {t('This course takes you from beginner to advanced level')}
                                                        </ListItem>
                                                    </List>
                                                </div>
                                            </Grid>
                                            <Grid xs={12} sm={5} md={5} item className={classes.gridPadding}>
                                                <div className={classes.mapDiv}>
                                                    <div className={classes.root}>
                                                        <div align="left" className={classes.titleText}>{t('Course Sections')}</div>
                                                        <div>
                                                            <ExpansionPanel square expanded={this.state.expanded === 'panel1'} onChange={this.handleChangeExpansion('panel1')}>
                                                                <ExpansionPanelSummary style={{background:'#f7f7f7'}}>
                                                                    <Typography style={{display:'flex',alignItems:'center'}}>
                                                                        {this.state.expanded === 'panel1' ? <KeyboardArrowDownIcon className={classes.arraowIcon}/>: <ChevronRightIcon className={classes.arraowIcon}/>}
                                                                        {t('Introduction to Hotel Digitalization')}
                                                                    </Typography>
                                                                </ExpansionPanelSummary>
                                                                <ExpansionPanelDetails>
                                                                    <Typography className={classes.subtext}>
                                                                        <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} /> {t('Welcome to the RoomPriceGenie Academy')}
                                                                    </Typography>
                                                                    <Typography className={classes.subtext}>
                                                                        <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} /> {t('Why Hotel Technology')}
                                                                    </Typography>
                                                                    <Typography className={classes.subtext}>
                                                                        <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} /> {t('Common mistakes')}
                                                                    </Typography>
                                                                  
                                                                </ExpansionPanelDetails>
                                                            </ExpansionPanel>
                                                            <ExpansionPanel square expanded={this.state.expanded === 'panel2'} onChange={this.handleChangeExpansion('panel2')}>
                                                                <ExpansionPanelSummary style={{background:'#f7f7f7'}}>
                                                                    <Typography style={{display:'flex',alignItems:'center'}}>
                                                                        {this.state.expanded === 'panel2' ? <KeyboardArrowDownIcon className={classes.arraowIcon}/>: <ChevronRightIcon className={classes.arraowIcon}/>}
                                                                        {t('Revenue Management System')}
                                                                    </Typography>
                                                                </ExpansionPanelSummary>
                                                                <ExpansionPanelDetails>
                                                                    <Typography className={classes.subtext}>
                                                                        
                                                                    <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} /> {t('Basics of Revenue Management')}

                                                                    </Typography>

                                                                    <Typography className={classes.subtext}> 
                                                                    <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} /> {t('Why is Revenue Management important?')}

                                                                    </Typography >

                                                                    <Typography className={classes.subtext}>
                                                                    <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} />  {t('Strategy vs. Tactics')}

                                                                    </Typography>

                                                                    <Typography className={classes.subtext}>
                                                                    <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} /> {t('Prediction and Optimization')}

                                                                    </Typography>

                                                                    <Typography className={classes.subtext}>
                                                                    <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} />  {t('Demo RoomPriceGenie')}

                                                                    </Typography>

                                                                    <Typography className={classes.subtext}>
                                                                       <FormatListBulletedIcon style={{marginRight:'5px'}} /> {t('Self-Check: Basics of Revenue Management Systems')}
    
                                                                    </Typography>
                                                                    
                                                                </ExpansionPanelDetails>
                                                            </ExpansionPanel>
                                                            <ExpansionPanel square expanded={this.state.expanded === 'panel3'} onChange={this.handleChangeExpansion('panel3')} >
                                                                <ExpansionPanelSummary style={{background:'#f7f7f7'}}>
                                                                    <Typography style={{display:'flex',alignItems:'center'}}>
                                                                        {this.state.expanded === 'panel3' ? <KeyboardArrowDownIcon className={classes.arraowIcon}/>: <ChevronRightIcon className={classes.arraowIcon}/>}
                                                                       {t('Hotel Revenue Management')}
                                                                    </Typography>
                                                                </ExpansionPanelSummary>
                                                                <ExpansionPanelDetails>
                                                                    <Typography className={classes.subtext}>
                                                                    <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} /> {t('Weekly and Seasonal Cycles')}

                                                                    </Typography>
                                                                    <Typography className={classes.subtext}>
                                                                    <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} /> {t('Charging Too Little')}

                                                                    </Typography>
                                                                    <Typography className={classes.subtext}>
                                                                    <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} /> {t('OTA Discounts')}

                                                                    </Typography>
                                                                    <Typography className={classes.subtext}>
                                                                    <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} /> {t(`${this.state.room_apartment_space_name} & Rate Structure`)}

                                                                    </Typography>
                                                                    <Typography className={classes.subtext}>
                                                                    <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} /> {t(`Gaps between ${this.state.room_apartment_space_name}s`)}

                                                                    </Typography>
                                                                    <Typography className={classes.subtext}>
                                                                    <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} />{t('Upgrades')}
                                                                    </Typography>
                                                                    <Typography className={classes.subtext}>
                                                                    <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} /> {t('Minimum Stays')}

                                                                  </Typography>
                                                                  <Typography className={classes.subtext}>
                                                                  <FormatListBulletedIcon style={{marginRight:'5px'}} />{t('Self-Check: Revenue Management')}


                                                                  </Typography>
                                                                </ExpansionPanelDetails>
                                                            </ExpansionPanel>
                                                            <ExpansionPanel square expanded={this.state.expanded === 'panel4'} onChange={this.handleChangeExpansion('panel4')} >
                                                                <ExpansionPanelSummary style={{background:'#f7f7f7'}}>
                                                                    <Typography style={{display:'flex',alignItems:'center'}}>
                                                                        {this.state.expanded === 'panel4' ? <KeyboardArrowDownIcon className={classes.arraowIcon}/>: <ChevronRightIcon className={classes.arraowIcon}/>}
                                                                       {t('Hotel Marketing')}
                                                                    </Typography>
                                                                </ExpansionPanelSummary>
                                                                <ExpansionPanelDetails>
                                                                    <Typography className={classes.subtext}>
                                                                    <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} />{t('Branding')}

                                                                    </Typography>
                                                                    <Typography className={classes.subtext}>
                                                                    <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} />{t('Direct Bookings')}
                                                                   </Typography>
                                                                   <Typography className={classes.subtext}>
                                                                   <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} /> {t('Website')}
                                                                   </Typography>
                                                                   <Typography className={classes.subtext}>
                                                                   <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} />{t('OTA Profile')}
                                                                   </Typography>
                                                                   <Typography className={classes.subtext}>
                                                                   <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} />{t('Content Marketing')}

                                                                   </Typography>
                                                                   <Typography className={classes.subtext}>
                                                                   <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} />{t('Online Touchpoints')}

                                                                   </Typography>
                                                                   <Typography className={classes.subtext}>
                                                                   <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} />{t('Review Management')}

                                                                   </Typography>
                                                                   <Typography className={classes.subtext}>
                                                                   <FormatListBulletedIcon style={{marginRight:'5px'}} />{t('Self-Check: Digital Hotel Marketing')}

                                                                   </Typography>
                                                                </ExpansionPanelDetails>
                                                            </ExpansionPanel>

                                                            <ExpansionPanel square expanded={this.state.expanded === 'panel7'} onChange={this.handleChangeExpansion('panel7')} >
                                                                <ExpansionPanelSummary style={{background:'#f7f7f7'}}>
                                                                    <Typography style={{display:'flex',alignItems:'center'}}>
                                                                        {this.state.expanded === 'panel7' ? <KeyboardArrowDownIcon className={classes.arraowIcon}/>: <ChevronRightIcon className={classes.arraowIcon}/>}
                                                                       {t('Cloud Technology, Distribution & more')}
                                                                    </Typography>
                                                                </ExpansionPanelSummary>
                                                                <ExpansionPanelDetails>
                                                                    {/* <Typography className={classes.subtext}>
                                                                    <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} />{t('Intro, Overview and Learning Objectives')}

                                                                    </Typography> */}
                                                                    <Typography className={classes.subtext}>
                                                                    <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} />{t('Cloud PMS')}
                                                                   </Typography>
                                                                   <Typography className={classes.subtext}>
                                                                   <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} /> {t('Distribution')}
                                                                   </Typography>
                                                                   <Typography className={classes.subtext}>
                                                                   <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} />{t('OTA Handling')}
                                                                   </Typography>
                                                                   <Typography className={classes.subtext}>
                                                                   <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} />{t('Upselling and Cross-Selling')}

                                                                   </Typography>
                                                                   <Typography className={classes.subtext}>
                                                                   <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} />{t('Meta-Sites')}

                                                                   </Typography>
                                                                   <Typography className={classes.subtext}>
                                                                   <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} />{t('Benchmarking')}

                                                                   </Typography>
                                                                   <Typography className={classes.subtext}>
                                                                   <FormatListBulletedIcon style={{marginRight:'5px'}} />{t('Self-Check: Other Technology')}

                                                                   </Typography>
                                                                </ExpansionPanelDetails>
                                                            </ExpansionPanel>


                                                            <ExpansionPanel square expanded={this.state.expanded === 'panel5'} onChange={this.handleChangeExpansion('panel5')} >
                                                                <ExpansionPanelSummary style={{background:'#f7f7f7'}}>
                                                                    <Typography style={{display:'flex',alignItems:'center'}}>
                                                                        {this.state.expanded === 'panel5' ? <KeyboardArrowDownIcon className={classes.arraowIcon}/>: <ChevronRightIcon className={classes.arraowIcon}/>}
                                                                       {t('Going more in Depth')}
                                                                    </Typography>
                                                                </ExpansionPanelSummary>
                                                                <ExpansionPanelDetails>
                                                                    <Typography className={classes.subtext}>
                                                                    <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} />{t('Video Series: Advanced Revenue Management with Scott Dahl')}
                                                                    </Typography>
                                                                    <Typography className={classes.subtext}>
                                                                    <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} />{t('Video Series: Direct Bookings (CRO) with Thea Prpic')}

                                                                   </Typography>
                                                                   <Typography className={classes.subtext}>
                                                                   <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} />{t('Video Series: Google My Business with Thea Prpic')}

                                                                   </Typography>
                                                                   <Typography className={classes.subtext}>
                                                                   <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} />{t('Video Series: Channel Management with Elisha Schopping')}

                                                                   </Typography>
                                                                   <Typography className={classes.subtext}>
                                                                   <VideoLibraryIcon style={{marginRight:'5px',color:'#101840'}} />{t('Video Series: Reading an STR report with Scott Dahl')}

                                                                   </Typography>
                                                                  
                                                                </ExpansionPanelDetails>
                                                            </ExpansionPanel>
                                                            <ExpansionPanel square expanded={this.state.expanded === 'panel6'} onChange={this.handleChangeExpansion('panel6')} >
                                                                <ExpansionPanelSummary style={{background:'#f7f7f7'}}>
                                                                    <Typography style={{display:'flex',alignItems:'center'}}>
                                                                        {this.state.expanded === 'panel6' ? <KeyboardArrowDownIcon className={classes.arraowIcon}/>: <ChevronRightIcon className={classes.arraowIcon}/>}
                                                                       {t('The Checklist')}
                                                                    </Typography>
                                                                </ExpansionPanelSummary>
                                                                <ExpansionPanelDetails>
                                                                    <Typography className={classes.subtext}>
                                                                    <ListAltIcon style={{marginRight:'5px'}} />  {t('Checklist: System')}

                                                                    </Typography>
                                                                    <Typography className={classes.subtext}>
                                                                    <ListAltIcon style={{marginRight:'5px'}} /> {t('Checklist: Set Pricing – Annual')}

                                                                   </Typography>
                                                                   <Typography className={classes.subtext}>
                                                                   <ListAltIcon style={{marginRight:'5px'}} /> {t('Checklist: Set Pricing – Daily / Weekly Basis')}

                                                                   </Typography>
                                                                   <Typography className={classes.subtext}>
                                                                   <ListAltIcon style={{marginRight:'5px'}} /> {t('Checklist: OTA Online Presence')}

                                                                   </Typography>
                                                                   <Typography className={classes.subtext}>
                                                                   <ListAltIcon style={{marginRight:'5px'}} /> {t('Checklist: OTA Managment')}

                                                                   </Typography>
                                                                   <Typography className={classes.subtext}>
                                                                   <ListAltIcon style={{marginRight:'5px'}} /> {t('Checklist: Guest Experience')}
                                                                   </Typography>
                                                                   
                                                                   <Typography className={classes.subtext}>
                                                                   <ListAltIcon style={{marginRight:'5px'}} /> {t('Checklist: Website')}

                                                                   </Typography>
                                                                </ExpansionPanelDetails>
                                                            </ExpansionPanel>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </>
                            }
                            {this.state.ReferFriendModalOpen && Modals.ReferFriendModal(this)}
                        </CardContent>
                    </Card>
                </div>
            </MuiThemeProvider>
            </> 
        );
    }
}

Community.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        hotelAuthReducer: state.hotelAuthReducer,
        userSession: state.sessionReducer,
        roomList: state.roomListReducer,
        commonReducer: state.commonReducer,
        featuresReducer: state.featuresReducer,
        priceSettingReducer: state.priceSettingReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getPricingSettings: (request) => { dispatch(actions.getPricingSettings(request)) },  
        referralDeal: (request) => { dispatch(actions.referralDeal(request)) },
        getHotelList: (request) => { dispatch(actions.getHotelList(request)) },
        getreferralDealProgramLists: (request) => { dispatch(actions.getreferralDealProgramLists(request)) },
    };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(Community));
