import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';

// get
const onClientUserGetStart = () => {
    return {
        type: actionTypes.CLIENT_GET_START
    }
}

const onClientUserGetFailed = (error) => {
    return {
        type: actionTypes.CLIENT_GET_FAILED,
        error: error
    }
}

const onClientUserGetSucceed = (data) => {
    return {
        type: actionTypes.CLIENT_GET_SUCCEED,
        state: data
    }
}

export const getClientUserList = (request) => {
    return dispatch => {
        dispatch(onClientUserGetStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + request.url)
            .then(function (response) {
                dispatch(onClientUserGetSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onClientUserGetFailed(error))
            });
    }
}