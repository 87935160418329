import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import Notify from '../Notify';

const onEventCategoryStart = () => {
    return {
        type:actionTypes.EVENT_CATEGORY_START
    }
}

const onEventCategorySucceed = (data) => {
    return {
        type: actionTypes.EVENT_CATEGORY_SUCCEED,
        state: data
    }
}
const onEventCategoryFailed = (error) => {
    return {
        type: actionTypes.EVENT_CATEGORY_FAILED,
        error: error
    }
}

export const getEventCategory = (request) => {
    return dispatch => {
        dispatch(onEventCategoryStart());
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/new_events_category/', request)
            .then(function (response) {
                dispatch(onEventCategorySucceed(response.data.data))
            })
            .catch(function (error){
                dispatch(Notify.newUserModal("An Error Occurred While Loading Events", false))
                dispatch(onEventCategoryFailed(error))
            });
    }
}

const impEventSucceed = (data) => {
    return {
        type: actionTypes.IMPORTANT_EVENT_SUCCEED,
        state: data
    }
}

export const importantEvents = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/important_events/', request)
            .then(function (response) {
                dispatch(impEventSucceed(response.data.data))
            })
            .catch(function (error){
                // dispatch(Notify.newUserModal("An error occured while updating Event list Connection", false))
            });
    }
}

