var style = {
  RadioGroup: {
    display: 'inline-block',
  },
  emailLabel: {
    marginTop: '20px'
  },
  userTable: {
    padding: '20px'
  },
  addUserBtn: {
    float: 'right',
    marginBottom: '15px',
    marginLeft: "20px",
    ['@media (max-width: 414px) and (min-width: 360px)']: {            
      marginLeft: "0px",
      width:"100%"
    }
  },
  userTableDiv: {
    marginTop: "20px",
    '& table > tfoot > tr > td:last-child':{
      padding: '0px'
    }
  },
  actionIcon: {
    padding: '0',
    cursor: 'pointer',
    minWidth: '30px',
    float: "left"
  },
  textField: {
    minWidth: '240px',
    ['@media (max-width: 540px) and (min-width: 400px)']: {
      minWidth: '240px'
    },
    ['@media (max-width: 399px) and (min-width: 361px)']: {
      minWidth: '230px'
    },
    ['@media (max-width: 360px) and (min-width: 321px)']: {
      minWidth: '215px'
    },
    ['@media (max-width: 320px)']: {
      minWidth: '170px'
    }
  },
  popupDiv: {
    display: "flex",
    flexDirection: 'inherit',
    overflow: 'auto'
  },
  MainLoader: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    background: 'rgb(15, 51, 80, 0.3)',
    zIndex: '9',
  },
  loader: {
    position: 'absolute',
    top: '35%',
    left: '35%'
  },
  fieldLeft: {
    marginRight: '20px',
    ['@media (max-width: 540px) and (min-width: 400px)']: {
      width:'100%',
      marginRight: '0px'
    },
    ['@media (max-width: 399px) and (min-width: 361px)']: {
      width:'100%',
      marginRight: '0px',
      "& label": {
        fontSize: "14px"
      },
      ">div": {
        "& label": { fontSize: "14px" }
      }
    },
    ['@media (max-width: 360px) and (min-width: 321px)']: {
      width:'100%',
      marginRight: '0px',
      "& label": {
        fontSize: "13px"
      },
      ">div": {
        "& label": { fontSize: "13px" }
      }
    },
    ['@media (max-width: 320px)']: {
      width:'100%',
      marginRight: '0px',
      "& label": {
        fontSize: "10px"
      },
      ">div": {
        "& label": { fontSize: "10px" }
      }
    }
  },
  refRoomOnly: {
    float: 'left'
  },
  paddingTen: {
    padding: '10px 15px 10px 15px',
    textAlign: 'center'
  },
  actionCell: {
    padding: '10px 15px 10px 15px',
    textAlign: 'left'
  },
  gridContainer: {
    textAlign: 'left'
  },
  adornment: {
    color: 'rgb(118, 118, 118)',
    paddingRight: '5px'
  },
  halfWidth: {
    width: '50%'
  },
  refRommRow: {
    '& td': { fontWeight: "600" }
  },
  minPriceFC: {
    marginTop: 0,
    width: "150px",
    marginLeft: "50px"
  },
  tableHeading: {
    textAlign: 'left',
    marginBottom: '15px'
  },
  updateBtn: {
    margin: '14px 24px 0px'
  },
  addBtnDiv: {
    display: "flow-root"
  },
  lockIcon: {
    cursor: "pointer"
  },
  endadrom: {
    display: 'contents'
  },
  endadromspan: {
    width: '20px',
    textAlign: 'right',
    cursor: "pointer",
    fontSize: "24px",
    color: '#541388'
  },
  addNewDiv: {
    '@media screen and (max-width: 900px)': {
      minWidth: "100%",
      display: "-webkit-inline-box"
    },
  },
  minPriceDiv: {
    '@media screen and (max-width: 900px)': {
      minWidth: "100%",
    },
    width: "65%",
  },
  refRoomDiv: {
    width: "100%",
    marginTop: "30px"
  },
  basicSetupCheck: {
    width: "100%",
    marginTop: "15px"
  },
  settingFields: {
    width: '170px',
    marginRight: '5%',
    minWidth: '170px',
    maxWidth: '170px'
  },
  htmlTooltip: {
    backgroundColor: '#323338 !important',
    color: 'white',
    maxWidth: '250px',
    // border: '1px solid #1976d2',
    opacity: '1',
    filter: 'alpha(opacity=100)'
  },

}
export default style;