import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import RoomCss from "./css/roomSettingCss";
import Modals from "./modal/modal";
import Warning from "../warning";
import SettingsModals from "../modal/modal";
import Icon from "@material-ui/core/Icon";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../../../store/actions/index";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { roomSettingThme } from "../../muiTheme";
import classnames from "classnames";
import { withNamespaces } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import CustomTooltip from "../../Utils/toolTip";
import Typography from "@material-ui/core/Typography";
import YieldingTags from "./yielding_tags";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TableCell from "@material-ui/core/TableCell";
import moment, { min } from "moment";
import axios from "axios";
import Server from "../../../config/server";
import HeaderCard from "../HeaderCard";
import ClientModals from "../modal/modal";

let RoomSetingList = [];
const styles = RoomCss;

class RoomSetting extends React.Component {
  state = {
    autoLoad: false,
    openAverageGraph: false,
    refRoomOnly: false,
    refRoomAverage: 0,
    loadData: true,
    open: false,
    roomList: [],
    openAutosetup: false,
    pmsList: {
      is_independent_rate: false,
      mapped_data: [],
    },
    openNewRoom: false,
    openEditRoom: false,
    openDeleteRoom: false,
    individualRatePrice: [],
    addNewModalData: {
      name: "",
      max_occupancy: 2,
      avg_price: 0,
      adjustment_to_reference_room_is_absolute: false,
      pms_room: "",
      pms_rate: "",
      temp_rate_list: [],
      tempOccList: [],
      handleRate: 0,
      max_price: 0,
      min_price: 0,
      discount_per_person: 0,
      extra_child:0,
      temp_pmsList: [],
      number_of_rooms: null,
      default_occupancy: null,
      extra: "",
      minOccupancy: "",
      maxOccupancy: "",
    },
    editModalData: {
      id: null,
      name: "",
      max_occupancy: 2,
      avg_price: 0,
      adjustment_to_reference_room_is_absolute: false,
      pms_room: "",
      pms_rate: "",
      temp_rate_list: [],
      tempOccList: [],
      temp_pms_list: [],
      handleRate: 0,
      hotel: 38,
      max_price: 0,
      min_price: 0,
      is_reference_room: false,
      temp_pmsList: [],
      number_of_rooms: null,
      default_occupancy: null,
      extra: "",
      discount_per_person: null,
      extra_child:null,
    },
    deleteModalData: {
      id: null,
      name: "",
      max_occupancy: 2,
      avg_price: 0,
      adjustment_to_reference_room_is_absolute: false,
      pms_room: "",
      pms_rate: "",
      temp_rate_list: [],
      tempOccList: [],
      temp_pms_list: [],
    },
    occupancyList: [],
    error: [],
    minPrice: null,
    minPriceError: false,
    number_of_rooms: null,
    number_of_roomsError: false,
    avg_graph: null,
    absoluteAdjustment: false,
    featuresEnabled: [],
    reference_room: null,
    adjustment_to_reference_room_is_locked: false,
    isAbsolute: false,
    settingAlert: false,
    loaderConn: false,
    tobe_deleted: [],
    tobe_deleted_r: [],
    deleted_rooms: [],
    deleted_rates: [],
    autoSetupPms: null,
    all_room_pricing: false,
    yieldingTagsList: [],
    runPriceModal: false,
    get_pms_data: false,
    showCsvPrice: false,
    rowNumbers: 20,
    warning: false,
    allOccupancyDeleteSettins: false,
    warningMsg: [],
    warningMethod: null,
    pricingSettingJson: "",
    hideConfirm: false,
    round_price_setting: 0,
    tab: 1,
    newRoomTab: 0,
    minPricingOccupancy: 0,
    maxPricingOccupancy: 0,
    pmsProviderList: [10, 15, 29, 34, 38, 40, 23, 44, 47, 28, 53, 22, 39, 45, 50, 58, 60, 61, 35 , 63, 71, 69],
    occupancyPrices: [{ occupancy: "", derivation: 0, room:""}],
    newOccupancyPrices: [{ occupancy: "", derivation: 0, room:""}],
    occupancy_percent_derivation:false,
    room_apartment_space_name: "",
    deadRoomRateType: false,
    is_from_roomsetting: false,

    is_channel_manager:false,
    pms_channel_manager_text :'',
    setDefaultMin: '',
    setDefaultMax: '',
    setCostValue: '',

    is_changed_min:null,
    is_changed_max:null,
    is_changed_min_type:null,
    is_changed_max_type:null,
    changed_min_warning_modal:false,
    changed_max_warning_modal:false,
    virtual_room_type: false,
    calculate_average:{
      message:null,      
    },
    priceGraphSaveButtonLoader:0,
    minmaxOccupancyDetected:false,
    allOccupancyDeleteSettins:false,
    ignore_upload_count:false,
    isOccupancyChanged: false,
  };

  initPusher = () => {
    this.props.commonReducer.initPusher.bind('connection_update.message', (evt)=> {
      if (evt.success) {
        let requestData = {
          msg: `${this.state.pms_channel_manager_text} Connection updated Successfully`,
          is_success: true,
        }
        this.props.putNotify(requestData)
      } else {
        let requestData = {
          msg: `An error occured while updating ${this.state.pms_channel_manager_text} Connection`,
          is_success: false,
        }
        this.props.putNotify(requestData)
      }

      if(evt.success && evt.is_room_or_rate_deleted){
        console.log('dead rooms rates')
        this.deadRoomRateTypeModal()
      }

      if(evt.success && evt?.is_occupancy_changed){
        console.log('Change in min/max occupancy detected')
        this.minMaxOccupancyDetectModal()
      }

    })

    this.props.commonReducer.initPusher.bind('calculate_average.message', (evt)=> {
      if (!evt.error) {        
        let requestParam = {
          token: this.props.hotelAuthReducer.hotelDetails.token,
          room_id: this.state.editModalData.id,
        };
        this.props.updateAveragePrice(requestParam);
      } else {
        let msg = `Something went Wrong While Fetching Values from ${this.state.pms_channel_manager_text}`;
          if (evt && evt.message) {
            msg = evt.message;
          }
          let requestData = {
            msg: msg,
            is_success: false,
          }
          this.props.putNotify(requestData);
          this.setState({
            openAverageGraph: false,
            avg_graph: null,
          });                
      }
    })
  }

  deadRoomRateTypeModal = () => {
    this.setState({
      deadRoomRateType: !this.state.deadRoomRateType,
      is_from_roomsetting: true,
    })
  }

  minMaxOccupancyDetectModal = () => {
    this.setState({
      minmaxOccupancyDetected: !this.state.minmaxOccupancyDetected
    })
  }

  componentWillMount() {
    this.initPusher();

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
    };
    this.props.getRoomList(requestParam);
    this.props.getPmsList(requestParam);
    this.props.getHotelDetail(requestParam);
    let req = { Authtoken: this.props.hotelAuthReducer.hotelDetails.token };
    this.props.getConnectionList(req);

    this.setState({
      minPrice: this.props.hotelAuthReducer.hotelDetails.hotel.min_price,
      room_apartment_space_name: this.props.hotelAuthReducer.hotelDetails.hotel.room_apartment_space_name
    });
  }

  componentWillReceiveProps(props) {
    if (props.connectionReducer.connectionList && !props.connectionReducer.seekom) {
      props.connectionReducer.connectionList.filter(row => row.primary === true).map((row) => {
        if(this.state.currentData != row){
          this.setState({
            currentData: row,
            pmsKey: row.provider,
            occupancy_percent_derivation:row.occupancy_percent_derivation,
          })
          if(row.extra == 'occupancy price'){
            let pmsProvider=[...this.state.pmsProviderList,35]
            let occupancyArray=pmsProvider.filter((item,
              index) => pmsProvider.indexOf(item) === index);
            this.setState({
              pmsProviderList:occupancyArray
            })
          }else{
            let pmsProvider=this.state.pmsProviderList.filter(function(item) {return item !== 35})
              this.setState({
                pmsProviderList:pmsProvider
              })
          }
        }
        return null;
      })
    }

    if (props.commonReducer.notify && props.commonReducer.notify.message) {
      this.setState({
        loaderConn: false,
        saveLoader: false,
        openEditRoom: false,
        saveRoomLoader: false,
        priceGraphSaveButtonLoader:0,
        openNewRoom: false,
        openDeleteRoom: false,
        autoLoad: false,
        calNumbers: false,
        openAverageGraph: false,
        avg_graph: null,
      });
    }

    if (props.priceSettingReducer && props.priceSettingReducer.priceSetting) {
      this.setState({
        featuresEnabled: props.priceSettingReducer.priceSetting.features,
      });
      this.setState({
        jsonSettingOld: JSON.stringify(props.priceSettingReducer.priceSetting),
      });
    }

    if (props.commonReducer.avg_graph) {
      this.setState({
        avg_graph: props.commonReducer.avg_graph,
      });

      if (
        props.commonReducer.avg_graph.average_price &&
        props.commonReducer.avg_graph.average_price[
          this.state.editModalData.id
        ] &&
        this.state.openAverageGraph &&
        props.commonReducer.avg_graph.average_price[
          this.state.editModalData.id
        ] != this.state.editModalData.avg_price
      ) {
        let price;
        let handleRate;
        let fieldPrice = parseInt(
          props.commonReducer.avg_graph.average_price[
            this.state.editModalData.id
          ]
        );
        price = fieldPrice;
        if (this.state.isAbsolute) {
          if (fieldPrice && fieldPrice !== "") {
            handleRate =
              parseFloat(fieldPrice) - parseFloat(this.state.refRoomAverage);
          } else {
            handleRate = parseFloat(this.state.editModalData.handleRate) - 1;
          }
        } else {
          if (fieldPrice && fieldPrice !== "" && fieldPrice !== "0") {
            handleRate =
              ((fieldPrice * 100) / this.state.refRoomAverage).toFixed(0) - 100;
          } else {
            handleRate = -100;
          }
        }
        handleRate = parseInt(handleRate);       
        this.setState({
          editModalData: {
            ...this.state.editModalData,
            avg_price: price,
            handleRate: handleRate,
          },
        });
      }
    }

    if(this.state.editModalData.is_reference_room || this.state.featuresEnabled.includes(6)){
      let avg_price = this.state.editModalData.avg_price;
      let default_min = '';
      let default_max = '';
      let cost_value = '';      
      default_min = Math.round(0.7 * avg_price);
      default_max = Math.round(2 * avg_price );
      cost_value = Math.round(0.7 * 0.5 * avg_price)
      this.setState({
            setDefaultMin: default_min,
            setDefaultMax: default_max,
            setCostValue: cost_value,
      });
    }
    
    if (props.hotelDetailReducer.hoteDetail) {
      this.setState({
        currencySymbol: props.hotelDetailReducer.hoteDetail.currency.symbol,
        is_channel_manager:props.hotelDetailReducer.hoteDetail.is_channel_manager,
        pms_channel_manager_text :props.hotelDetailReducer.hoteDetail.is_channel_manager ? 'Channel Manager':'PMS'
      });
    }

    if (props.roomList.roomList && props.priceSettingReducer.priceSetting) {
      this.setState({
        roomList: props.roomList.roomList,
        loadData: false,
      });
      this.renderRoomTable();
    } else {
      this.setState({
        loadData: true,
      });
    }
    if (props.pmsList) {
      // if (props.pmsList.is_independent_rate === true) {
      //   let pmsList = [];
      //   props.pmsList.pms_rooms.map((val) => {
      //     let pms = val;
      //     pms.rates = props.pmsList.pms_rates;
      //     pmsList.push(pms);
      //     return null;
      //   });
      //   this.setState({
      //     pmsList: {
      //       ...this.state.pmsList,
      //       is_independent_rate: true,
      //       mapped_data: pmsList,
      //     },
      //   },() => this.renderRoomTable() );
      // } else {
        if (
          props.hotelAuthReducer.hotelDetails.hotel.pms_provider &&
          (props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 8 ||
            props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 17)
        ) {
          let pmsList = props.pmsList;
          let newPmsList = {
            is_independent_rate: pmsList.is_independent_rate,
            mapped_data: [],
          };
          this.setState({
            occupancyList: newPmsList,
            pmsList: props.pmsList,
          },() => this.renderRoomTable() );
        } else {
          this.setState({
            pmsList: props.pmsList,
          },() => this.renderRoomTable() );
        }
      // }
    }

    if (props.priceSettingReducer.priceSetting && !this.state.isUpdate) {      
      this.setState({
        reference_room: props.priceSettingReducer.priceSetting.rooms.reference.id,
        isAbsolute: props.priceSettingReducer.priceSetting.hotel.adjustment_to_reference_room_is_absolute,
        number_of_rooms: props.priceSettingReducer.priceSetting.hotel.number_of_rooms,
        refRoomAverage: props.priceSettingReducer.priceSetting.default[props.priceSettingReducer.priceSetting.rooms.reference.id].avg_price,
        absoluteAdjustment: props.priceSettingReducer.priceSetting.hotel.adjustment_to_reference_room_is_absolute,
        refRoomOnly: props.priceSettingReducer.priceSetting.hotel.all_room_pricing,
        pricingSettingJson: JSON.stringify(props.priceSettingReducer.priceSetting),
        isUpdate: true
      })
    }

    
    if (props.priceSettingReducer.priceSetting) {
      this.setState({
        refRoomAverage: props.priceSettingReducer.priceSetting.default[props.priceSettingReducer.priceSetting.rooms.reference.id].avg_price,
      })
    }

    if (props.priceSettingReducer.priceSetting && !this.state.openEditRoom) {
      this.setState({
        round_price_setting: props.priceSettingReducer.priceSetting.hotel
          .round_price_setting
          ? props.priceSettingReducer.priceSetting.hotel.round_price_setting
          : 0,
        all_room_pricing:
          props.priceSettingReducer.priceSetting.hotel.all_room_pricing,
        adjustment_to_reference_room_is_locked:
          props.priceSettingReducer.priceSetting.hotel
            .adjustment_to_reference_room_is_locked,        
      });
    }

    if (
      props.connectionReducer.deleted_rooms &&
      props.connectionReducer.deleted_rooms != this.state.deleted_rooms &&
      props.connectionReducer.deleted_rooms.length > 0
    ) {
      this.setState({
        deleted_rooms: props.connectionReducer.deleted_rooms,
        showConfirmRoomDelete:
          props.connectionReducer.deleted_rooms.length > 0 ? true : false,
      });
    }
    if (
      props.connectionReducer.deleted_rates &&
      props.connectionReducer.deleted_rates != this.state.deleted_rates &&
      props.connectionReducer.deleted_rates.length > 0
    ) {
      this.setState({
        deleted_rates: props.connectionReducer.deleted_rates,
        showConfirmRoomDelete:
          props.connectionReducer.deleted_rates.length > 0 ? true : false,
      });
    }

    if (
      props.priceSettingReducer.priceSetting &&
      props.priceSettingReducer.priceSetting.room_type_tags
    ) {
      this.setState({
        yieldingTagsList: props.priceSettingReducer.priceSetting.room_type_tags,
      });
    }

    if (props.roomList.occupancyPrices != this.props.roomList.occupancyPrices) {
      let occArr=[];
      let minOccupancy;
      let maxOccupancy;
      this.state.pmsList.mapped_data.map((val) => {
        if (val.id == this.state.editModalData.pms_room) {
          minOccupancy = val.min_occupancy;
          maxOccupancy = val.max_occupancy;
        }
      })
      if (props.roomList.occupancyPrices && Object.keys(props.roomList.occupancyPrices).length > 0 ) {      
        for (var i = minOccupancy; i <= maxOccupancy; i++) {
          let data = props.roomList?.occupancyPrices.filter(val => val.occupancy == i) || [];
          if(data.length > 0){
            occArr.push({ occupancy: i, derivation: data[0].derivation, room: this.state.editModalData.id });
          }else{
            occArr.push({ occupancy: i, derivation: "", room: this.state.editModalData.id });
          }
        }
      }else{      
        for (var i = minOccupancy; i <= maxOccupancy; i++) {
          occArr.push({ occupancy: i, derivation: "", room: this.state.editModalData.id });

        }
      }    
      this.setState({
        minPricingOccupancy: minOccupancy,
        maxPricingOccupancy: maxOccupancy,
        occupancyPrices: occArr,     
      })
    }
    
    let pricePerRate = [];
    if (props.roomList && props.roomList.pricePerRate && props.roomList.pricePerRate != this.props.roomList.pricePerRate) {
      if (props.roomList.pricePerRate.length > 0) {
        props.roomList.pricePerRate.map((val) => {
          pricePerRate.push(val);
        });

        if (pricePerRate.length < 12) {
          let rates = 12 - pricePerRate.length;
          for (let i = 0; i < rates; i++) {
            pricePerRate.push({
              derivation: 0,
              pms_rate: null,
              pms_room: null,
              is_absolute: true,
            });
          }
        }
      } else {
        pricePerRate = [
          { derivation: 0, pms_rate: null, pms_room: null, is_absolute: true },
          { derivation: 0, pms_rate: null, pms_room: null, is_absolute: true },
          { derivation: 0, pms_rate: null, pms_room: null, is_absolute: true },
          { derivation: 0, pms_rate: null, pms_room: null, is_absolute: true },
          { derivation: 0, pms_rate: null, pms_room: null, is_absolute: true },
          { derivation: 0, pms_rate: null, pms_room: null, is_absolute: true },
          { derivation: 0, pms_rate: null, pms_room: null, is_absolute: true },
          { derivation: 0, pms_rate: null, pms_room: null, is_absolute: true },
          { derivation: 0, pms_rate: null, pms_room: null, is_absolute: true },
          { derivation: 0, pms_rate: null, pms_room: null, is_absolute: true },
          { derivation: 0, pms_rate: null, pms_room: null, is_absolute: true },
          { derivation: 0, pms_rate: null, pms_room: null, is_absolute: true },
        ];
      }
      this.setState({
        pricePerRate: pricePerRate,
      });
    }    
    
  }

  dailyMinMaxDeleteWarningModal = (method) => {

    if(this.state.is_changed_max != null){
      this.setState({
        changed_max_warning_modal: true,
      });
    } 
    if(this.state.is_changed_min != null){
      this.setState({
        changed_min_warning_modal: true,
      });
    }       
    if(this.state.is_changed_max == null && this.state.is_changed_min == null){
      this.saveEditRoom()
    }
  }

  handleDefaultMinMax = (method,is_type_min_max,is_set_true_false) => {
    if(is_type_min_max == 'is_max'){
      this.setState({
        changed_max_warning_modal: false,
        is_changed_max_type:is_set_true_false,
      }, () => this.checkWarningPopupOpen(method));
    }
    if(is_type_min_max == 'is_min'){  
      this.setState({
        changed_min_warning_modal: false,
        is_changed_min_type:is_set_true_false,
      }, () => this.checkWarningPopupOpen(method));
    }    
  }
  
  checkWarningPopupOpen = (method) => {
    if(this.state.is_changed_max != null && this.state.is_changed_min != null && !this.state.changed_min_warning_modal && !this.state.changed_max_warning_modal ){             
      this.saveEditRoom()
    }else if(this.state.is_changed_min != null  && this.state.is_changed_max == null && !this.state.changed_min_warning_modal){            
      this.saveEditRoom()
    }else if(this.state.is_changed_max != null  && this.state.is_changed_min == null && !this.state.changed_max_warning_modal){            
      this.saveEditRoom()      
    }
  }

  toggleWarningModal = (method) => {
    let warningMsg = [];
    let warning = false;
    let hideConfirm = false;
    const { t } = this.props;
    if (method == "updateRoomsNumber" && this.state.totalNumberRoom != this.state.number_of_rooms) {
      this.setState({
        number_of_rooms_Error: true,
      });
      warning = true;
      if (this.state.totalNumberRoom > this.state.number_of_rooms) {
        warningMsg.push(t(`The number of ${this.state.room_apartment_space_name}s you entered is lower than the number of ${this.state.room_apartment_space_name}s we can see (Sum). Are any ${this.state.room_apartment_space_name}s double counted? Please call us if these numbers don't match as it is important for correct pricing.`))
      } else if (this.state.totalNumberRoom < this.state.number_of_rooms) {
        warningMsg.push(t(`The number of ${this.state.room_apartment_space_name}s you entered is higher than the number of ${this.state.room_apartment_space_name}s we can see (Sum). Are all your ${this.state.room_apartment_space_name}s set up and visible to us? Please call us if these numbers don't match as it is important for correct pricing.`))
      } else {
        warningMsg.push(t(`The total of the individual ${this.state.room_apartment_space_name}  numbers listed is `) + this.state.totalNumberRoom + t(", but you have put ") + this.state.number_of_rooms + t(` here. Please check your individual ${this.state.room_apartment_space_name}  numbers or the ${this.state.room_apartment_space_name}  type yielding will be incorrect.`))
      } 
      if(this.state.occupancy_percent_derivation){
        warningMsg.push(t(`This will remove all daily occupancy pricing settings.`))
      }     
    } else if (method == "updateRoomsNumber") {        
      this.updateRoomsNumber()   
    }

    if (method == "saveEditRoom") {
      let data = this.state.editModalData;
      let error = [];
      if (data) {
        if (data.is_reference_room || this.state.featuresEnabled.includes(6)) {
          if (data.min_price === "") {
            error.push("min_price");
          }
          if (data.max_price === "") {
            error.push("max_price");
          }
          if (data.variable_cost_per_room === "" && data.is_reference_room) {
            error.push("variable_cost_per_room");
          } else if (data.is_reference_room && data.min_price != "" && data.variable_cost_per_room > data.min_price * 0.8) {
            error.push("variable_cost_per_room_max");
          }
          if (data.min_price != "" && data.max_price != "" && parseInt(data.min_price) > parseInt(data.max_price)) {
            error.push("max_price_error");
          }
        }

        if (!data.name) {
          error.push("edit_name");
        }
        if (!data.avg_price && data.avg_price !== 0) {
          error.push("edit_avg_price");
        }

        if ([24].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider)) {
          if (data.discount_per_person < 0) {
            error.push("discount_per_person");
          }
        }
        if (this.state.pmsProviderList.includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider)) {
          if (!this.state.editModalData.default_occupancy) {
            error.push("default_occupancy");
          } else if (this.state.editModalData.default_occupancy < this.state.minPricingOccupancy ||this.state.editModalData.default_occupancy > this.state.maxPricingOccupancy) {
            error.push("default_occupancy_min_max");
          }

        }

        if (!this.state.editModalData.avg_price && this.state.editModalData.avg_price !== 0) {
          error.push("edit_avg_price");
        }

        if (error.length > 0) {
          this.setState({
            error: error,
          });
          if (error.includes("edit_name")) {
            this.setState({ tab: 0 });
          }
          if (
            error.includes("edit_avg_price") ||
            error.includes("min_price") ||
            error.includes("max_price") ||
            error.includes("variable_cost_per_room") ||
            error.includes("variable_cost_per_room_max") ||
            error.includes("max_price_error") ||
            error.includes("discount_per_person")
          ) {
            this.setState({ tab: 1 });
          }
          if (
            error.includes("default_occupancy") ||
            error.includes("default_occupancy_min_max")
          ) {
            if((this.state.editModalData.pms_extra != 'fixed price' && this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider !== 71 ) || (this.state.currentData?.configurations?.price_type === 'occupancy_based' && this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 71 ))
              this.setState({ tab: 2 });
            }else{
              this.setState({ tab: 0 });
            }
        } else {
          let oldJson = JSON.parse(this.state.pricingSettingJson);
          let refRoomData = oldJson.default[oldJson.rooms.reference.id];       
          const  dataId=data.id
          let oldRoomData = this.props.roomList.roomList.find(row=>row.id == dataId);
          if (oldJson.default[dataId] && oldJson.default[dataId].avg_price != data.avg_price) {
            warning = true;
            warningMsg.push(t("We recommend NOT changing the base price unless you are totally sure you know how it affects prices. Changing the base price has the largest effect on your pricing of any setting. Call us if you are not sure, or just use the monthly settings to change shorter periods."));

            let per = Math.abs(
              100 - (data.avg_price * 100) / oldJson.default[data.id].avg_price
            );

            if (per > 10) {
              warning = true;
              warningMsg.push(t("This change of the base price is a VERY SIGNIFICANT change to your pricing. Please do not do this unless you are completely sure of the effect. Message us if you would like a call.")
              );
            }
          }

          if (data.is_reference_room) {
            if (data.variable_cost_per_room < data.min_price * 0.3) {
              warning = true;
              warningMsg.push(t("The entered variable cost is unusually low and can result in lower prices than optimal. Normally we set this to around 50% of the minimum price."));
            }
          }

          if (data.is_reference_room || this.state.featuresEnabled.includes(6)) {
            if (data.min_price < data.avg_price * 0.5 && data.min_price >= data.avg_price * 0.3) {
              warning = true;
              warningMsg.push(t("This is unusually low for a minimum price - normally we would set this to around 60% of the base price."));
            }
            if (data.min_price < data.avg_price * 0.3) {
              warning = true;
              warningMsg.push(t("We would strongly recommend not setting a minimum price this much lower than your base price. Are you sure?"));
            }
            if (data.min_price > data.avg_price * 0.8 && data.min_price <= data.avg_price * 0.9) {
              warning = true;
              warningMsg.push(t("This is unusually high for a minimum price, compared to your base price. We would recommend going lower to allow more bookings in low-demand times."));
            }
            if (data.min_price > data.avg_price * 0.9) {
              warning = true;
              warningMsg.push(t("We would strongly recommend a lower minimum price when you have this base price set. If you are unsure of pricing please call us for a consultation."));
            }
            if (data.min_price > data.avg_price) {
              warning = true;
              warningMsg.push(t("The minimum price can’t be greater than the base price"));
              hideConfirm = true;
            }

            if (!data.is_reference_room && data.min_price > refRoomData.min_price * 1.15) {
              warning = true;
              warningMsg.push(t(`This is a high minimum vs the reference ${this.state.room_apartment_space_name}, are you sure?`))
            }

            if (!data.is_reference_room && data.min_price < refRoomData.min_price * 0.85) {
              warning = true;
              warningMsg.push(t(`This is a high minimum vs the reference ${this.state.room_apartment_space_name}, are you sure?`))
            }

            if (data.max_price < data.avg_price * 1.2) {
              warning = true;
              warningMsg.push(t("We would strongly recommend a higher maximum price when you have this base price set. If you are unsure of pricing please call us for a consultation."));
            }
            if (data.max_price < data.avg_price * 1.4 && data.max_price >= data.avg_price * 1.2) {
              warning = true;
              warningMsg.push(t("This is unusually low for a maximum price, compared to your base price. We would recommend going higher to allow more profit in busy times."));
            }
            if (data.max_price > data.avg_price * 10) {
              warning = true;
              warningMsg.push(t("This is unusually high for a maximum price and may lead to unhappy clients. Could you try a lower maximum but put a minimum stay restriction in instead?"));
            }
            if (data.max_price < data.avg_price) {
              warning = true;
              warningMsg.push(t("The maximum price has to be greater than the base price."));
              hideConfirm = true;
            }

            if (!data.is_reference_room && data.max_price > refRoomData.max_price * 1.15) {
              warning = true;
              warningMsg.push(t(`This is a high maximum vs the reference ${this.state.room_apartment_space_name.toUpperCase()}, are you sure?`))
            }

            if (!data.is_reference_room && data.max_price < refRoomData.max_price * 0.85) {
              warning = true;
              warningMsg.push(t(`This is a low maximum vs the reference ${this.state.room_apartment_space_name.toUpperCase()}, are you sure?`))
            }

            if ([24].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider)) {
              if (data.discount_per_person > data.min_price * 0.4) {
                warning = true;
                warningMsg.push(t("This is an unusually large discount per person. Are you sure?"));
              }
            }
          }

          if(oldRoomData.default_occupancy != data.default_occupancy){
                warning = true;
                warningMsg.push(t("We noticed that the occupancy pricing setup for the below room types changed. Your daily occupancy settings (Bulk Edit) for these room types got set back to default."));              
                this.setState({
                  isOccupancyChanged: true
                })
          }else{
            this.setState({
              isOccupancyChanged: false
            })
          }
          
          if (!warning) {

            //this.saveEditRoom();
            this.dailyMinMaxDeleteWarningModal(method)

          }
        }
      }
    }
    
    this.setState({
      warning: warning,
      warningMethod: method,
      warningMsg: warningMsg,
      hideConfirm: hideConfirm,
    });
  };

  closeWarningModal = (method) => {
    this.setState({
      warning: false,
    });

    if (this.state.warningMethod == "saveEditRoom") {
      this.openEditRoomModal(
        this.state.roomList.filter(
          (val) => val.id == this.state.editModalData.id
        )[0]
      );
    }
    if (this.state.warningMethod == "updateRoomsNumber") {
      let setting = JSON.parse(this.state.jsonSettingOld);
      this.setState({
        number_of_rooms: setting.hotel.number_of_rooms,
      });
    }
  };

  warningConfirm = () => {
    if (this.state.warningMethod == "updateRoomsNumber") { 
        this.updateRoomsNumber();
    }
    if (this.state.warningMethod == "saveEditRoom") {

      // this.saveEditRoom();
      this.dailyMinMaxDeleteWarningModal()

    }
    this.setState({
      warning: false,
    });
  };

  toggleAutoSetup = () => {
    this.setState({
      openAutosetup: !this.state.openAutosetup,
    });
  };

  CloseConfirmRoomDelete = () => {
    this.setState({
      showConfirmRoomDelete: false,
    });
  };

  deleteRoomsRates = () => {
    let roomData = {
      client_rooms_deleted: this.state.tobe_deleted,
      Authtoken: this.props.hotelAuthReducer.hotelDetails.token,
      deleted_rooms: this.state.deleted_rooms,
    };

    let rateData = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      client_rates_deleted: this.state.tobe_deleted_r,
    };

    if (this.state.tobe_deleted_r.length == 0) {
      this.props.removeDeadRooms(roomData);
    } else {
      this.props.removeDeadRates(rateData, roomData);
    }
    this.setState({
      showConfirmRoomDelete: false,
    });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = this.state.deleted_rooms.map((n) => n);
      this.setState({ tobe_deleted: newSelecteds });
    } else {
      this.setState({ tobe_deleted: [] });
    }
  };

  handleSelectCheck = (event, room) => {
    if (event.target.checked) {
      this.setState({ tobe_deleted: [...this.state.tobe_deleted, room] });
    } else {
      var array = [...this.state.tobe_deleted];
      var index = array.indexOf(room);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ tobe_deleted: array });
      }
    }
  };

  handleSelectAllRateClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = this.state.deleted_rates.map((n) => n);
      this.setState({ tobe_deleted_r: newSelecteds });
    } else {
      this.setState({ tobe_deleted_r: [] });
    }
  };

  handleSelectRateCheck = (event, room) => {
    if (event.target.checked) {
      this.setState({ tobe_deleted_r: [...this.state.tobe_deleted_r, room] });
    } else {
      var array = [...this.state.tobe_deleted_r];
      var index = array.indexOf(room);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ tobe_deleted_r: array });
      }
    }
  };

  handleConfirmRooms = () => {
    let requestParam = {
      Authtoken: this.props.hotelAuthReducer.hotelDetails.token,
      deleted_rooms: this.state.tobe_deleted,
    };

    this.setState({
      showConfirmRoomDelete: false,
    });
  };

  toggleSettingAlert = () => {
    this.setState({
      settingAlert: !this.state.settingAlert,
    });
  };

  handleChangeAuto = (name) => (event) => {
    this.setState({
      [name]: parseFloat(event.target.value),
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleChangePricing = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
    });
  };

  toggleAverageGraph = () => {
    this.setState({
      openAverageGraph: !this.state.openAverageGraph,
      avg_graph: null,
      priceGraphSaveButtonLoader:0,
    },() => {
        if(!this.state.openAverageGraph){
          this.openEditRoomModal(
            this.state.roomList.filter(
              (val) => val.id == this.state.editModalData.id
            )[0]
          );
        }
    });
  };

  saveOnlyBasePrice = () => {
    
    this.setState({
        priceGraphSaveButtonLoader:2,
        editModalData: {
          ...this.state.editModalData,           
        },
      }, () => {
        this.toggleWarningModal("saveEditRoom")
      });    
  }
  saveSetDefaults = () => {
        
      this.setState({
        priceGraphSaveButtonLoader:1,
          editModalData: {
            ...this.state.editModalData,
            min_price: (this.state.editModalData.is_reference_room || this.state.featuresEnabled.includes(6)) ? this.state.setDefaultMin : '0',
            max_price:  (this.state.editModalData.is_reference_room || this.state.featuresEnabled.includes(6)) ? this.state.setDefaultMax : '0',
            variable_cost_per_room:  (this.state.editModalData.is_reference_room || this.state.featuresEnabled.includes(6)) ? this.state.setCostValue : '0',
          },
        }, () => {                  
        this.toggleWarningModal("saveEditRoom") });        
  }

  handleAddNewRoom = (name) => (event) => {
    if (name == "pms_rate") {
      this.setState({
        addNewModalData: {
          ...this.state.addNewModalData,
          [name]: event.value,
        },
      });
    } else {
      this.setState({
        addNewModalData: {
          ...this.state.addNewModalData,
          [name]: event.target.value,
        },
      });
    }
  };
  
  handleMinMaxPopup = (name, value) => {  

        let min_price = this.props.priceSettingReducer.priceSetting.default[this.state.editModalData.id].min_price;
        let max_price = this.props.priceSettingReducer.priceSetting.default[this.state.editModalData.id].max_price;    
        
        let daily_min_price = 0;
        let daily_max_price = 0;
        let dates = this.props.priceSettingReducer.priceSetting.dates;
        Object.keys(dates).filter((date) => {
          if(dates[date] != undefined){
            if(dates[date][this.state.editModalData.id] != undefined){
              if(dates[date][this.state.editModalData.id].min_price != undefined ){
                daily_min_price = 1
              }
               if(dates[date][this.state.editModalData.id].max_price != undefined ){
                daily_max_price = 1
              }
            }
          }
        })
        if(name == 'min_price' && daily_min_price > 0){
            this.setState({          
              is_changed_min : (name == 'min_price' && min_price != value) ? this.state.editModalData.id : null ,       
            })
        }
        if(name == 'max_price' && daily_max_price > 0){
          this.setState({          
            is_changed_max : (name == 'max_price' && max_price != value) ? this.state.editModalData.id : null ,       
          })
        }
  }

  handleEditRoom = (name) => (event) => {
    console.log('TTTTT',this.state.editModalData)
    if (name == "pms_rate") {
      this.setState({
        editModalData: {
          ...this.state.editModalData,
          fetch_inventary: true,
          [name]: event.value,
        },
      });
    } else {      

      this.handleMinMaxPopup( name, event.target.value);

      this.setState({
        editModalData: {
          ...this.state.editModalData,
          [name]: event.target.value,
        },               
      });      
      if(name == "number_of_rooms"){        
        this.setState({
          virtual_room_type: (event.target.value == '0' || event.target.value == 0) && event.target.value != ''  ? true : false, 
        })
      }
    }
  };
  handleDerivedRates = (name, index) => (event) => {
    let pricePerRate = this.state.pricePerRate;
    if (pricePerRate.filter((val, ind) => ind == index)) {
      if (name == "pms_rate") {
        pricePerRate[index].pms_rate = event.value ? event.value : null;
        if(this.state.openNewRoom){
          pricePerRate[index].pms_room = this.state.addNewModalData.pms_room ? this.state.addNewModalData.pms_room : null;
        }else{
          pricePerRate[index].pms_room = this.state.editModalData.pms_room ? this.state.editModalData.pms_room : null;
        }
      }else if(name == 'derivation_price'){
        var v = event.target.value
        if(v != ""){   
          if (v.match(/^-?[0-9]+$/) || v=== "-") {
            pricePerRate[index].derivation = v
          }   
      } else{
        pricePerRate[index].derivation = v
      }      
      }else if(name == 'derivation_percent'){
        pricePerRate[index].is_absolute = !event.target.checked
        if(event.target.checked){
          pricePerRate[index].is_product_derivation =false
        }
      }else if(name == 'is_product_derivation'){
        pricePerRate[index].is_product_derivation = event.target.checked ? event.target.checked : false
      }
    }
    this.setState({
      pricePerRate: pricePerRate,
    });
  };
  handleNewRoomOccPricingDiff = (e, occ, occIndex) => {
    let prices = [];
    this.state.newOccupancyPrices.map((val) => {
      if (val.occupancy == occ.occupancy) {
        prices.push({
          occupancy: val.occupancy,
          derivation: e.target.value != null ? parseInt(e.target.value) :0,
          room: occ.room
        })
      }else {
        prices.push(val);
      }
    });
    this.setState({
      newOccupancyPrices: prices,
    });
  };
  handleOccupancyPricingDiff = (e, occ, occIndex) => {
    let prices = [];
    this.state.occupancyPrices.map((val) => {
      if (val.occupancy == occ.occupancy) {
        prices.push({
          occupancy: val.occupancy,
          derivation: e.target.value ? parseInt(e.target.value) :null,
          room: occ.room
        })
      } else {
        prices.push(val);
      }
    });
    this.setState({
      occupancyPrices: prices,
    });
  };

  handleEditRoomCheckbox = (name) => (event) => {
    let handleRate;
    if (!this.state.editModalData.adjustment_to_reference_room_is_absolute) {
      handleRate =
        this.state.editModalData.avg_price - this.state.refRoomAverage;
    } else {
      handleRate =
        (
          (this.state.editModalData.avg_price * 100) /
          this.state.refRoomAverage
        ).toFixed(0) - 100;
    }

    this.setState({
      editModalData: {
        ...this.state.editModalData,
        adjustment_to_reference_room_is_absolute:
          !this.state.editModalData.adjustment_to_reference_room_is_absolute,
        handleRate: handleRate,
      },
    });
  };

  handleAddRoomCheckbox = (name) => (event) => {
    this.setState({
      addNewModalData: {
        ...this.state.addNewModalData,
        adjustment_to_reference_room_is_absolute:
          !this.state.addNewModalData.adjustment_to_reference_room_is_absolute,
        avg_price: this.state.refRoomAverage,
        handleRate: 0,
      },
    });
  };

  handleEditRoomPrice = (name) => (event) => {
    let price;
    let handleRate;
    let fieldPrice = parseInt(event.target.value);
    if (event.target.name === "handleRate") {
      if (fieldPrice && fieldPrice !== "") {
        handleRate = fieldPrice;
        if (this.state.isAbsolute) {
          price =
            parseFloat(this.state.refRoomAverage) + parseFloat(fieldPrice);
        } else {
          price = (
            (fieldPrice * this.state.refRoomAverage +
              100 * this.state.refRoomAverage) /
            100
          ).toFixed(0);
        }
        handleRate = parseInt(handleRate);
      } else {
        price = this.state.refRoomAverage;
      }
    } else {
      price = fieldPrice;
      if (this.state.isAbsolute) {
        if (fieldPrice && fieldPrice !== "") {
          handleRate =
            parseFloat(fieldPrice) - parseFloat(this.state.refRoomAverage);
        } else {
          handleRate = parseFloat(this.state.editModalData.handleRate) - 1;
        }
      } else {
        if (fieldPrice && fieldPrice !== "" && fieldPrice !== "0") {
          handleRate =
            ((fieldPrice * 100) / this.state.refRoomAverage).toFixed(0) - 100;
        } else {
          handleRate = -100;
        }
      }
      handleRate = parseInt(handleRate);
    }
    this.setState({
      editModalData: {
        ...this.state.editModalData,
        avg_price: price,
        handleRate: handleRate,
      },
    });
  };

  chnageuse_prices_from = () => (event) => {
    let value = event.target.value ? event.target.value : 0;
    this.setState({
      addNewModalData: {
        ...this.state.addNewModalData,
        use_prices_from: value,
      },
    });
  };

  updateuse_prices_from = () => (event) => {
    let value = event.target.value ? event.target.value : 0;
    this.setState({
      editModalData: {
        ...this.state.editModalData,
        use_prices_from: value,
      },
    });
  };

  handleroundSetting = () => (event) => {
    this.setState({
      round_price_setting: event.target.value,
    });
  };

  handleAddRoomPrice = (name) => (event) => {
    let price;
    let handleRate;
    let fieldPrice = parseInt(event.target.value);
    if (event.target.name === "handleRate") {
      if (fieldPrice && fieldPrice !== "") {
        handleRate = fieldPrice;
        if (this.state.isAbsolute) {
          price =
            parseFloat(this.state.refRoomAverage) +
            parseFloat(event.target.value);
        } else {
          price = (
            (fieldPrice * this.state.refRoomAverage +
              100 * this.state.refRoomAverage) /
            100
          ).toFixed(0);
        }
        handleRate = parseInt(handleRate);
      } else {
        price = this.state.refRoomAverage;
      }
    } else {
      price = fieldPrice;
      if (this.state.isAbsolute) {
        if (fieldPrice && fieldPrice !== "") {
          handleRate =
            parseFloat(fieldPrice) - parseFloat(this.state.refRoomAverage);
        } else {
          handleRate = parseFloat(this.state.addNewModalData.handleRate) - 1;
        }
      } else {
        if (fieldPrice && fieldPrice !== "" && fieldPrice !== "0") {
          handleRate =
            ((fieldPrice * 100) / this.state.refRoomAverage).toFixed(0) - 100;
        } else {
          handleRate = -100;
        }
      }
      handleRate = parseInt(handleRate);
    }

    this.setState({
      addNewModalData: {
        ...this.state.addNewModalData,
        avg_price: price,
        handleRate: handleRate,
      },
    });
  };

  handlePmsRoomChange = () => (event) => {
    let temp_rate_list = [];
    let tempOccList = [];
    this.state.pmsList.mapped_data
      .filter((option) => option.id === event.target.value)
      .map((option) => {
        option.rates.map((rate) => {
          if (
            rate.connected_room == null ||
            rate.connected_room.id == this.state.addNewModalData.id
          ) {
            temp_rate_list.push(rate);
          }
          return "";
        });
        if (option.applicable_occupancy) {
          option.applicable_occupancy.map((occRow) => {
            if (
              occRow.connected_room == null ||
              occRow.connected_room.id == this.state.addNewModalData.id
            ) {
              tempOccList.push(occRow);
            }
            return "";
          });
        }
        return "";
      });
    let newOccArr = [];
    let newMinOccupancy;
    let newMaxOccupancy;

    this.state.pmsList.mapped_data.map((val) => {
      if (val.id == event.target.value) {
        newMinOccupancy = val.min_occupancy;
        newMaxOccupancy = val.max_occupancy;
      }
    });
    for (var i = newMinOccupancy; i <= newMaxOccupancy; i++) {
      newOccArr.push({ occupancy: i, derivation: "", room:  "" });
    }


    this.setState({
      newOccupancyPrices: newOccArr,
    });

    this.state.pmsList.mapped_data
      .filter((option) => option.id === event.target.value)
      .map((row) => {
        this.setState({
          addNewModalData: {
            ...this.state.addNewModalData,
            tempOccList: tempOccList,
            pms_room: event.target.value,
            temp_rate_list: temp_rate_list,
            pms_rate: "",
            name: row.name,
            default_occupancy: newMaxOccupancy,
            minOccupancy: newMinOccupancy,
            maxOccupancy: newMaxOccupancy,
          },
        });
      });
  };

  handleEditPmsRoomChange = () => (event) => {
    let temp_rate_list = [];
    let tempOccList = [];
    this.state.pmsList.mapped_data
      .filter((option) => option.id === event.target.value)
      .map((option) => {
        option.rates.map((rate) => {
          if (
            rate.connected_room == null ||
            rate.connected_room.id == this.state.editModalData.id
          ) {
            temp_rate_list.push(rate);
          }
          return "";
        });
        if (option.applicable_occupancy) {
          option.applicable_occupancy.map((occRow) => {
            if (
              occRow.connected_room == null ||
              occRow.connected_room.id == this.state.addNewModalData.id
            ) {
              tempOccList.push(occRow);
            }
            return "";
          });
        }
        return "";
      });

    let occArr = [];
    let minOccupancy;
    let maxOccupancy;

    this.state.pmsList.mapped_data.map((val) => {
      if (val.id == event.target.value) {
        minOccupancy = val.min_occupancy;
        maxOccupancy = val.max_occupancy;
      }
    });
    for (var i = minOccupancy; i <= maxOccupancy; i++) {
      occArr.push({ occupancy: i, derivation: "", room:  "" });
    }
    this.setState({
      occupancyPrices: occArr,
      minPricingOccupancy: minOccupancy,
      maxPricingOccupancy: maxOccupancy,
    });

    this.setState({
      editModalData: {
        ...this.state.editModalData,
        pms_room: event.target.value,
        tempOccList: tempOccList,
        temp_rate_list: temp_rate_list,
        pms_rate: "",
        fetch_inventary: true,
      },
    });
  };

  validateNewRoomForm = (data) => {
    let error = [];
    if (data) {
      if (this.state.featuresEnabled.includes(6)) {
        if (this.state.addNewModalData.min_price === "") {
          error.push("min_price");
        }
        if (this.state.addNewModalData.max_price === "") {
          error.push("max_price");
        }
        if (this.state.addNewModalData.variable_cost_per_room === "") {
          error.push("variable_cost_per_room");
        }
        if (
          this.state.addNewModalData.min_price != "" &&
          this.state.addNewModalData.max_price != "" &&
          parseInt(this.state.addNewModalData.min_price) >
            parseInt(this.state.addNewModalData.max_price)
        ) {
          error.push("max_price_error");
        }
      }

      if (!this.state.addNewModalData.name) {
        error.push("name");
      }

      if (
        !this.state.addNewModalData.avg_price &&
        this.state.addNewModalData.avg_price !== 0
      ) {
        error.push("avg_price");
      }

      if (
        !this.state.addNewModalData.handleRate &&
        this.state.addNewModalData.handleRate !== 0
      ) {
        error.push("handleRate");
      }
      if (
        this.state.pmsProviderList.includes(
          this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider
        )
      ) {
        if (!this.state.addNewModalData.default_occupancy) {
          error.push("default_occupancy");
        } else if (
          this.state.addNewModalData.default_occupancy <
            this.state.addNewModalData.minOccupancy ||
          this.state.addNewModalData.default_occupancy >
            this.state.addNewModalData.maxOccupancy
        ) {
          error.push("default_occupancy_min_max");
        }
      }
      if (error) {
        this.setState({
          error: error,
        });
        return error;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  validateEditRoomForm = (data) => {
    let error = [];
    if (data) {
      if (
        this.state.editModalData.is_reference_room ||
        this.state.featuresEnabled.includes(6)
      ) {
        if (this.state.editModalData.min_price === "") {
          error.push("min_price");
        }
        if (this.state.editModalData.max_price === "") {
          error.push("max_price");
        }
        if (
          this.state.editModalData.variable_cost_per_room === "" &&
          this.state.editModalData.is_reference_room
        ) {
          error.push("variable_cost_per_room");
        } else if (
          this.state.editModalData.is_reference_room &&
          this.state.editModalData.min_price != "" &&
          this.state.editModalData.variable_cost_per_room >
            this.state.editModalData.min_price * 0.8
        ) {
          error.push("variable_cost_per_room_max");
        }
        if (
          this.state.editModalData.min_price != "" &&
          this.state.editModalData.max_price != "" &&
          parseInt(this.state.editModalData.min_price) >
            parseInt(this.state.editModalData.max_price)
        ) {
          error.push("max_price_error");
        }
      }

      if (error) {
        this.setState({
          error: error,
        });
        if (
          error.includes("default_occupancy") ||
          error.includes("default_occupancy_min_max")
        ) {
          this.setState({ tab: 0 });
        }
        return error;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  saveNewRoom = () => {
    let validateError = this.validateNewRoomForm(this.state.addNewModalData);
    if (validateError.length > 0) {
      return;
    }
    this.setState({
      loadData: true,
    });
    let prices = [];

    this.state.newOccupancyPrices.map((val) => {
      prices.push({
        occupancy: val.occupancy,
        derivation: val.derivation ? parseInt(val.derivation) : parseInt(0),
        room: this.state.addNewModalData.pms_room
      })
    })
   
    this.setState({
      occupancyPrices: prices,
    });

    let pricePerRate = this.state.pricePerRate;
    pricePerRate = pricePerRate
      ? pricePerRate.filter((val) => val.pms_rate)
      : [];

    let requestParam = {
      ...this.state.addNewModalData,
      adjustment_to_reference_room_is_absolute: this.state.isAbsolute,
      token: this.props.hotelAuthReducer.hotelDetails.token,
      occupancyPrices: prices,
      pricePerRate: pricePerRate,
      ignore_upload_count:this.state.ignore_upload_count,
    };
    if(requestParam.pms_room){
      this.props.storePricePerRate(requestParam);
    }
    this.props.addNewRoom(requestParam);

    this.setState({
      saveRoomLoader: true,
    });
  };

  saveEditRoom = () => {
    let validateError = this.validateEditRoomForm(this.state.editModalData);

    
    if (validateError.length > 0) {
      return;
    }
    let prices = [];
    this.state.occupancyPrices.map((val) => {
      if (val.occupancy == this.state.editModalData.default_occupancy) {
        prices.push({
          occupancy: val.occupancy,
          derivation: val.derivation ? parseInt(val.derivation) : parseInt(0),
          room: val.room
        })
      }else{
        prices.push({
          occupancy: val.occupancy,
          derivation: val.derivation ? parseInt(val.derivation) : parseInt(0),
          room: val.room
        })
      }
    });
    this.setState({
      occupancyPrices: prices,
    });
    let editData = this.state.editModalData;
    if (
      this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider &&
      (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 8 ||
        this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 17)
    ) {
      editData.pms_price_applicable_occupancy =
        editData.pms_price_applicable_occupancy;
    }
    editData.virtual_room_type = this.state.virtual_room_type;
    let pricePerRate =this.state.pricePerRate;
    pricePerRate = pricePerRate ? pricePerRate.filter(val => val.pms_rate) : [];
    let requestParam = {
      ...editData,
      token: this.props.hotelAuthReducer.hotelDetails.token,
      hotelData: {
        adjustment_to_reference_room_is_locked:
          this.state.adjustment_to_reference_room_is_locked,
        all_room_pricing: this.state.refRoomOnly,
        round_price_setting: this.state.round_price_setting
          ? this.state.round_price_setting
          : null,        
      },
      occupancyPrices: prices,
      room_id: this.state.editModalData.id,
      pricePerRate:pricePerRate,

      is_changed_min: this.state.is_changed_min,
      is_changed_max: this.state.is_changed_max,
      is_changed_min_type: this.state.is_changed_min_type,
      is_changed_max_type: this.state.is_changed_max_type,
      isOccupancyChanged: this.state.isOccupancyChanged,
      ignore_upload_count:this.state.ignore_upload_count,
    }

    if(requestParam.pms_room){
      this.props.storePricePerRate(requestParam);
    }
    console.log('requestParam',requestParam)
    this.props.editRoom(requestParam)

    if (
      this.state.pmsProviderList.includes(
        this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider
      ) &&
      ((this.state.editModalData.pms_extra != "fixed price" &&
        this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider !== 71) ||
        (this.state.currentData?.configurations?.price_type ===
          "occupancy_based" &&
          this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 71))
    ) {
      this.props.storeOccupancyPrices(requestParam);
    }

    
    this.setState({
      saveRoomLoader: true,
      is_changed_min:null,
      is_changed_max:null,
      is_changed_min_type:null,
      is_changed_max_type:null,
    });
  };

  deleteRoom = () => {
    let requestParam = {
      ...this.state.deleteModalData,
      token: this.props.hotelAuthReducer.hotelDetails.token,
    };

    this.props.deleteRoom(requestParam);
    this.setState({
      saveRoomLoader: true,
    });
  };

  handleSettingChange = (name) => (event) => {
    this.setState({
      hotelDetail: {
        ...this.state.hotelDetail,
        [name]: event.target.value,
      },
    });
  };

  toggleNewRoomModal = () => {
    let temp_pmsList = [];
    let tempOccList = [];

    this.state.pmsList.mapped_data.map((option) => {
      if (
        this.state.roomList.filter(
          (val) => val.use_prices_from == option.room_id
        ).length == 0
      ) {
        temp_pmsList.push(option);
      }
      return "";
    });

    let pms_values = {};
    if (this.props.connectionReducer.connectionList) {
      this.props.connectionReducer.connectionList.map((row) => {
        if (row.primary === true) {
          pms_values = row;
        }
      });
    }

    let min_price = 0;
    let max_price = 0;
    let refRoom = this.props?.roomList?.roomList.filter((val) => val.is_reference_room == true) || [];
    if(refRoom.length > 0){
      let refId = refRoom[0].id;
      min_price = parseFloat(
        this.props.priceSettingReducer.priceSetting.default[refId].min_price
      );
      max_price = parseFloat(
        this.props.priceSettingReducer.priceSetting.default[refId].max_price
      );
    }
    

    this.setState({
      addNewModalData: {
        name: "",
        max_occupancy: 2,
        avg_price: this.state.refRoomAverage,
        adjustment_to_reference_room_is_absolute: false,
        pms_room: "",
        pms_rate: "",
        temp_rate_list: [],
        tempOccList: tempOccList,
        handleRate: 0,
        currencySymbol: this.state.currencySymbol,
        temp_pmsList: temp_pmsList,
        default_occupancy: null,
        extra: "",
        pms_extra: pms_values.extra,
        min_price: min_price,
        max_price: max_price,
      },
      pricePerRate:[
        { derivation: 0, pms_rate: null, pms_room: null, is_absolute: true },
        { derivation: 0, pms_rate: null, pms_room: null, is_absolute: true },
        { derivation: 0, pms_rate: null, pms_room: null, is_absolute: true },
        { derivation: 0, pms_rate: null, pms_room: null, is_absolute: true },
        { derivation: 0, pms_rate: null, pms_room: null, is_absolute: true },
        { derivation: 0, pms_rate: null, pms_room: null, is_absolute: true },
        { derivation: 0, pms_rate: null, pms_room: null, is_absolute: true },
        { derivation: 0, pms_rate: null, pms_room: null, is_absolute: true },
      ],
      newRoomTab: 0,
      ignore_upload_count:false,
      error: [],
    });

    this.setState({ openNewRoom: !this.state.openNewRoom });
  };

  closeEditRoomModal = () => {
    this.setState({
      openEditRoom: false,
    });
  };

  closeDeleteRoomModal = () => {
    this.setState({
      openDeleteRoom: false,
    });
  };

  openEditRoomModal = (roomData) => {
    console.log('ROOM',roomData)

    let temp_pms_list = [];
    let temp_rate_list = [];
    let individualRatePrice = [];

    this.state.pmsList.mapped_data.map((option) => {
      if (option.connected_room !== null) {
        if (option.id === roomData.pms_room) {
          temp_pms_list.push(option);
        }
      }
      if (option.connected_room === null) {
        temp_pms_list.push(option);
      }
      return "";
    });

    let temp_pmsList = [];
    let tempOccList = [];

    this.state.pmsList.mapped_data.map((option) => {
      if (
        this.state.roomList.filter(
          (val) => val.use_prices_from == option.room_id
        ).length == 0
      ) {
        temp_pmsList.push(option);
      } else {
        if (roomData.use_prices_from == option.room_id) {
          temp_pmsList.push(option);
        }
      }
      return "";
    });

    if (roomData.pms_room) {
      this.state.pmsList.mapped_data
        .filter((option) => option.id === roomData.pms_room)
        .map((option) => {
          let requestParam = {
            token: this.props.hotelAuthReducer.hotelDetails.token,
            pms_room_id: roomData.pms_room,
          };
          if (roomData.pms_room) {
            this.props.getPricePerRate(requestParam); 
          }
          option.rates.map((rate) => {
            if (
              rate.connected_room == null ||
              rate.connected_room.id == roomData.id
            ) {
              temp_rate_list.push(rate);
            }
            individualRatePrice.push({
              name: rate.name,
              id: rate.id,
              price: "",
              isPercent: false,
            });
            return "";
          });
          if (option.applicable_occupancy) {
            option.applicable_occupancy.map((occRow) => {
              if (
                occRow.connected_room == null ||
                occRow.connected_room.id == this.state.addNewModalData.id
              ) {
                tempOccList.push(occRow);
              }
              return "";
            });
          }
          return "";
        });
    }

    let pmsrate = roomData.pms_rate;
    let number_of_rooms = 0;
    let virtual_room_type = false;
    let max_occupancy = 0;
    let variable_cost_per_room = 0;
    let adjustment_to_reference_room = 0;
    let avg_price = 0;
    let max_price = 0;
    let min_price = 0;

    if (roomData.is_reference_room) {
      number_of_rooms =
        this.props.priceSettingReducer.priceSetting.rooms.reference
          .number_of_rooms;
      virtual_room_type =
        this.props.priceSettingReducer.priceSetting.rooms.reference
          ?.virtual_room_type;
      max_occupancy =
        this.props.priceSettingReducer.priceSetting.rooms.reference
          .max_occupancy;
      variable_cost_per_room =
        this.props.priceSettingReducer.priceSetting.rooms.reference
          .variable_cost_per_room;

      adjustment_to_reference_room = parseFloat(
        this.props.priceSettingReducer.priceSetting.default[
          roomData.id
        ].adjustment_to_reference_room.toFixed(2)
      );
      avg_price = parseFloat(
        this.props.priceSettingReducer.priceSetting.default[
          roomData.id
        ].avg_price.toFixed(2)
      );
      max_price = parseFloat(
        this.props.priceSettingReducer.priceSetting.default[
          roomData.id
        ].max_price.toFixed(2)
      );
      min_price = parseFloat(
        this.props.priceSettingReducer.priceSetting.default[
          roomData.id
        ].min_price.toFixed(2)
      );
    } else {
      number_of_rooms = this.props.priceSettingReducer.priceSetting.rooms
        .derived[roomData.id]
        ? this.props.priceSettingReducer.priceSetting.rooms.derived[roomData.id]
            .number_of_rooms
        : 0;

      virtual_room_type = this.props.priceSettingReducer.priceSetting.rooms
        .derived[roomData.id]?.virtual_room_type || false;
      max_occupancy = this.props.priceSettingReducer.priceSetting.rooms.derived[
        roomData.id
      ]
        ? this.props.priceSettingReducer.priceSetting.rooms.derived[roomData.id]
            .max_occupancy
        : 0;
      variable_cost_per_room = this.props.priceSettingReducer.priceSetting.rooms
        .derived[roomData.id]
        ? this.props.priceSettingReducer.priceSetting.rooms.derived[roomData.id]
            .variable_cost_per_room
        : 0;

      adjustment_to_reference_room = parseFloat(
        this.props.priceSettingReducer.priceSetting.default[
          roomData.id
        ].adjustment_to_reference_room.toFixed(2)
      );
      avg_price = parseFloat(
        this.props.priceSettingReducer.priceSetting.default[
          roomData.id
        ].avg_price.toFixed(2)
      );
      max_price = parseFloat(
        this.props.priceSettingReducer.priceSetting.default[
          roomData.id
        ].max_price.toFixed(2)
      );
      min_price = parseFloat(
        this.props.priceSettingReducer.priceSetting.default[
          roomData.id
        ].min_price.toFixed(2)
      );
    }

    let pms_values = {};
    if (this.props.connectionReducer.connectionList) {
      this.props.connectionReducer.connectionList.map((row) => {
        if (row.primary === true) {
          pms_values = row;
        }
      });
    }

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      room_id: roomData.id,
    };
    this.props.getOccupancyPrices(requestParam);
    this.setState({
      individualRatePrice: individualRatePrice,
      tab: this.state.all_room_pricing || roomData.is_reference_room ? 1 : 0,
      editModalData: {
        id: roomData.id,
        name: roomData.name,
        max_occupancy: max_occupancy,
        avg_price: avg_price,
        adjustment_to_reference_room_is_absolute: adjustment_to_reference_room,
        pms_room: roomData.pms_room,
        pms_rate: pmsrate,
        temp_rate_list: temp_rate_list,
        temp_pms_list: temp_pms_list,
        handleRate: adjustment_to_reference_room,
        hotel: roomData.hotel,
        max_price: max_price,
        min_price: min_price,
        is_reference_room: roomData.is_reference_room,
        currencySymbol: this.state.currencySymbol,
        variable_cost_per_room: variable_cost_per_room,
        is_staff:
          this.props.userSession.user.is_staff ||
          this.props.userSession.user.sub_account_type,
        title: roomData.name,
        discount_per_person: roomData.discount_per_person,
        extra_child:roomData.extra_child,
        temp_pmsList: temp_pmsList,
        tempOccList: tempOccList,
        pms_price_applicable_occupancy: roomData.pms_price_applicable_occupancy,
        use_prices_from: roomData.use_prices_from,
        number_of_rooms: number_of_rooms,
        default_occupancy: roomData.default_occupancy,
        extra: roomData.extra,
        pms_extra: pms_values.extra,
      },
      all_room_pricing:this.props.priceSettingReducer.priceSetting.hotel.all_room_pricing,
      adjustment_to_reference_room_is_locked:this.props.priceSettingReducer.priceSetting.hotel.adjustment_to_reference_room_is_locked,
      openEditRoom: true,
      error: [],
      is_changed_min:null,
      is_changed_max:null,
      changed_min_warning_modal:false,
      changed_max_warning_modal:false,
      virtual_room_type:virtual_room_type,
      ignore_upload_count:roomData.ignore_upload_count,
    });
  };

  calculateAbsolute = (data) => {
    if (data.is_reference_room) {
      return "0";
    }
    let refRoomAverage = this.state.refRoomAverage;
    if (this.state.isAbsolute) {
      return (data.avg_price - refRoomAverage).toFixed(0);
    } else {
      return (
        ((data.avg_price - refRoomAverage) / refRoomAverage) *
        100
      ).toFixed(0);
    }
  };

  openDeleteRoomModal = (roomData) => {
    let temp_pms_list = [];
    let temp_rate_list = [];

    this.state.pmsList.mapped_data.map((option) => {
      if (option.connected_room !== null) {
        if (option.id === roomData.pms_room) {
          temp_pms_list.push(option);
        }
      }
      if (option.connected_room === null) {
        temp_pms_list.push(option);
      }
      return "";
    });

    if (roomData.pms_room) {
      this.state.pmsList.mapped_data
        .filter((option) => option.id === roomData.pms_room)
        .map((option) => {
          option.rates.map((rate) => {
            if (
              rate.connected_room == null ||
              rate.connected_room.id == roomData.id
            ) {
              temp_rate_list.push(rate);
            }
            return "";
          });
          return "";
        });
    }

    this.setState(
      {
        deleteModalData: {
          id: roomData.id,
          name: roomData.name,
          max_occupancy: roomData.max_occupancy,
          avg_price: roomData.avg_price,
          adjustment_to_reference_room_is_absolute:
            roomData.adjustment_to_reference_room_is_absolute,
          pms_room: roomData.pms_room,
          pms_rate: roomData.pms_rate,
          temp_rate_list: temp_rate_list,
          temp_pms_list: temp_pms_list,
        },
        openDeleteRoom: true,
      },
      () => {
        this.setState({
          openEditRoom: false,
        });
      }
    );
  };

  getRateName = (option, id) => {
    let rate = "";
    option.rates
      .filter((rates) => rates.id === id)
      .map((rates) => {
        rate = rates.name;
        return null;
      });
    return rate;
  };

  getPmsName = (row) => {
    let pmsName = "";
    let rateName = "";
    let pmsRate = "";
    this.state.pmsList.mapped_data
      .filter((option) => option.id === row.pms_room)
      .map((option) => {
        pmsName = option.name;
        pmsRate = row.pms_rate;
        rateName = this.getRateName(option, pmsRate);
        return null;
      });
    return { pms: pmsName, rate: rateName };
  };

  getOccupancyName = (row) => {
    if (
      this.state.pmsList.mapped_data &&
      this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider &&
      (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 8 ||
        this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 17 ||
        this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 25 ||
        this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 26)
    ) {
      let rate = "";
      this.state.pmsList.mapped_data
        .filter((option) => option.id === row.pms_room)
        .map((option) => {
          option.applicable_occupancy
            .filter((occ) => occ.id === row.pms_price_applicable_occupancy)
            .map((occ) => {
              rate = occ.applicable_guest;
            });
        });
      return rate;
    } else {
      return "";
    }
  };

  getYeldingTags = (id) => {
    let room_types_tag = this.state.yieldingTagsList;
    let tags = [];
    Object.keys(room_types_tag).map((key) => {
      if (room_types_tag[key].room_types.includes(id)) {
        tags.push(room_types_tag[key]);
      }
    });
    return tags;
  };

  renderRoomTable = () => {
    const { classes } = this.props;
    const { t } = this.props;
    RoomSetingList = [];
    let totalNumberRoom = 0;
    this.state.roomList.map((row, index) => {
      let pmsData = this.getPmsName(row);
      let pms_price_applicable_occupancy = this.getOccupancyName(row);
      let absolute = this.calculateAbsolute(row);

      let yeldingTags = this.getYeldingTags(row.id);

      let adjRef = 0;

      if (
        !row.is_reference_room &&
        this.props.priceSettingReducer.priceSetting &&
        this.props.priceSettingReducer.priceSetting.default[row.id]
      ) {
        adjRef = Math.round(
          this.props.priceSettingReducer.priceSetting.default[row.id]
            .adjustment_to_reference_room
        );
      }
      let number_of_rooms = 0;
      if (row.is_reference_room) {
        number_of_rooms =
          this.props.priceSettingReducer.priceSetting.rooms.reference
            .number_of_rooms;
      } else {
        number_of_rooms = this.props.priceSettingReducer.priceSetting.rooms
          .derived[row.id]
          ? this.props.priceSettingReducer.priceSetting.rooms.derived[row.id]
              .number_of_rooms
          : 0;
      }
      totalNumberRoom += number_of_rooms;

      if (
        this.props.priceSettingReducer.priceSetting &&
        this.props.priceSettingReducer.priceSetting.default[row.id]
      ) {
        let roomSetting = [
          row.id,
          row.name,
          row.extra,

          this.state.pmsList.mapped_data.length > 0 ? (
            pmsData.pms ? (
              pmsData.pms
            ) : (
              <span className={classes.spanMap} >{t("Please Map")}</span>
            )
          ) : (
            ""
          ),
          this.state.pmsList.mapped_data.length > 0
            ? pmsData.rate
              ? pmsData.rate
              : t("Not Priced")
            : "",

          number_of_rooms,
          row.is_reference_room ? t("Reference") : t("Derived"),
          pms_price_applicable_occupancy,
          row.use_prices_from ? row.use_prices_from : "",
          this.props.priceSettingReducer.priceSetting &&
          (this.state.all_room_pricing || row.is_reference_room)
            ? this.props.priceSettingReducer.priceSetting.default[row.id].avg_price
            : "",
          <>
            <span>
              {row.is_reference_room
                ? "-"
                : this.state.isAbsolute
                ? (adjRef > 0 ? "+" : adjRef < 0 ? "-" : "") +
                  this.state.currencySymbol +
                  new Intl.NumberFormat("ja-JP").format(Math.abs(adjRef))
                : (adjRef > 0 ? "+" : adjRef < 0 ? "-" : "") +
                  Math.abs(adjRef) +
                  "%"}
              {!row.is_reference_room &&
                this.state.adjustment_to_reference_room_is_locked && (
                  <Icon className={classes.lockIcon} style={{fontSize:'18px', marginBottom:"-2px"}} >
                    lock
                  </Icon>
                )}
            </span>
          </>,
          this.props.priceSettingReducer.priceSetting &&
          (this.state.all_room_pricing || row.is_reference_room)
            ? this.props.priceSettingReducer.priceSetting.default[row.id].min_price
            : "-",
          this.props.priceSettingReducer.priceSetting &&
          (this.state.all_room_pricing || row.is_reference_room)
            ? this.props.priceSettingReducer.priceSetting.default[row.id].max_price
            : "-",
          yeldingTags,
          row,
        ];
        RoomSetingList.push(roomSetting);
      }
    });
    this.setState({
      totalNumberRoom,
    });
    return null;
  };

  handleRefRoom = (event) => {
    if (!this.state.refRoomOnly) {
      this.toggleSettingAlert();
    }
    this.setState({
      refRoomOnly: !this.state.refRoomOnly,
    });
  };
  handleDontCountUpload = (event) => {
    
    this.setState({
      ignore_upload_count: !this.state.ignore_upload_count,
    });
  };

  handleVirtualRoomType = (event) => {
    this.setState({
      virtual_room_type: !this.state.virtual_room_type,
    });
    if (event.target.checked) {
      this.setState({
        editModalData: {
          ...this.state.editModalData,
          number_of_rooms: 0,
        }
      })
    }
  };

  handleAbsoluteAdjustment = (event) => {
    let value = !(event.target.value == "false" ? false : true);
    this.setState({
      absoluteAdjustment: value
    })
  }
  handleOccupancyAbsoluteAdjustment = (event) => {
    let value = !(event.target.value == "false" ? false : true)
    this.setState({
      occupancy_percent_derivation: value,
      allOccupancyDeleteSettins:true,
    })
  }
  handlerefLocked = (event) => {
    let value = !(event.target.value == "false" ? false : true);
    this.setState({
      adjustment_to_reference_room_is_locked: value,
    });
  };

  changeMinPrice = () => (event) => {
    this.setState({
      minPrice: event.target.value,
    });
  };

  changeNoOfRooms = () => (event) => {
    this.setState({
      number_of_rooms: event.target.value,
    });
  };

  handleMinPrice = (event) => {
    if (this.state.totalNumberRoom != this.state.number_of_rooms) {
      this.setState({
        number_of_rooms_Error: true,
      });
      return null;
    } else {
      this.setState({
        number_of_rooms_Error: false,
      });
    }

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      number_of_rooms: this.state.number_of_rooms,
      reference_room: this.state.reference_room,
      absoluteAdjustment: this.state.absoluteAdjustment,
      all_room_pricing: this.state.refRoomOnly,
      adjustment_to_reference_room_is_locked:
        this.state.adjustment_to_reference_room_is_locked,
    };

    let roomAdjustment = [];

    let refRoom = this.state.roomList.find(
      (room) => room.id === this.state.reference_room
    );

    this.state.roomList.map((row, index) => {
      let handleRate;
      if (this.state.absoluteAdjustment) {
        handleRate = row.avg_price - refRoom.avg_price;
      } else {
        handleRate =
          ((row.avg_price * 100) / refRoom.avg_price).toFixed(0) - 100;
      }

      let roomData = {
        id: row.id,
        adjustment_to_reference_room_is_absolute: this.state.absoluteAdjustment,
        name: row.name,
        pms_price_applicable_occupancy_id: row.pms_price_applicable_occupancy,
        pms_rate_id: row.pms_rate,
        pms_room_id: row.pms_room,
        discount_per_person: row.discount_per_person
          ? row.discount_per_person
          : 0,
        use_prices_from: row.use_prices_from,
      };

      roomAdjustment.push(roomData);
    });

    requestParam.roomAdjustment = roomAdjustment;

    let refRoomRequestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      room: this.state.reference_room,
    };

    requestParam.refRoomRequestParam = refRoomRequestParam;
    requestParam.is_callback = true;
    this.props.updateHotel(requestParam);
  };

  updateRoomsNumber = () => {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      number_of_rooms: this.state.number_of_rooms,
      absoluteAdjustment: this.state.absoluteAdjustment,
      refRoomAverage: this.state.refRoomAverage,

    }

    let param={
      id: this.state.currentData.id,
      occupancy_percent_derivation: this.state.occupancy_percent_derivation,
      Authtoken: this.props.hotelAuthReducer.hotelDetails.token,
    }

    if (this.state.currentData.id) {
      this.props.storeOccupancyDerivation(param)
    }
    this.setState({
      saveLoader: true,
    });

    this.props.updatePriceJson(requestParam, [], "updateNumberOfRoom");
  };

  deleteAllOccupancySetings = () => {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
    }
    this.props.updatePriceJson(requestParam, [], "deleteAllOccupancy");    
    this.setState({allOccupancyDeleteSettins:false})
  }
  deleteAllOccupancySetingsCloseModal = () => {
    this.setState({allOccupancyDeleteSettins:false})
  }

  updateAveragePrice = () => {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      room_id: this.state.editModalData.id,
    };
    this.props.updateAveragePrice(requestParam);
    this.toggleAverageGraph();
  };

  handleTabs = (event, newValue) => {
    this.setState({
      tab: newValue,
    });
  };
  handleNewTabs = (event, newValue) => {
    this.setState({
      newRoomTab: newValue,
    });
  };

  setDefaultMin = () => {
    
    let refRoom = this.props?.roomList?.roomList.filter((val) => val.is_reference_room == true) || [];
    let min = 0;
    let max = 0;

    if(refRoom.length > 0){
      let refId = refRoom[0].id;
      let min = parseFloat(
        this.props.priceSettingReducer.priceSetting.default[refId].min_price
      );
      let max = parseFloat(
        this.props.priceSettingReducer.priceSetting.default[refId].max_price
      );
    }
  
    if (this.state.isAbsolute) {
      min = parseFloat(this.state.editModalData.handleRate) + parseFloat(min);
      max = parseFloat(this.state.editModalData.handleRate) + parseFloat(max);
    } else {
      min = (min + (min * this.state.editModalData.handleRate) / 100).toFixed(
        0
      );
      max = (max + (max * this.state.editModalData.handleRate) / 100).toFixed(
        0
      );
    }

    this.setState({
      editModalData: {
        ...this.state.editModalData,
        min_price: min,
        max_price: max,
      },
      addNewModalData: {
        ...this.state.addNewModalData,
        min_price: min,
        max_price: max,
      },
    } , () => {
        this.handleMinMaxPopup( 'min_price', min);
        this.handleMinMaxPopup( 'max_price', max);
      });
  };

  connectRoom = () => {
    let room = this.state.roomList.filter((val) => val.is_reference_room)[0];
    let pmsDetails = this.state.pmsList.mapped_data.filter(
      (val) => val.id == room.pms_room
    )[0];
    if (room && room.pms_room) {
      let requestParam = {
        token: this.props.hotelAuthReducer.hotelDetails.token,
        selected_pms_ref_room_id: room.pms_room,
        selected_pms_ref_room_name: pmsDetails.name,
      };
      this.props.connectRooms(requestParam);
      this.setState({
        autoLoad: true,
      });
    } else if (room) {
      this.toggleAutoSetup();
    }
  };

  setAutoSetup = () => {
    let pmsDetails = this.state.pmsList.mapped_data.filter(
      (val) => val.id == this.state.autoSetupPms
    )[0];
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      selected_pms_ref_room_id: this.state.autoSetupPms,
      selected_pms_ref_room_name: pmsDetails.name,
    };
    this.props.connectRooms(requestParam);
    this.setState({
      autoLoad: true,
    });
    this.toggleAutoSetup();
  };

  refRoomChange = () => (event) => {
    this.setState({
      reference_room: event.target.value,
    });
  };

  getLatestRooms = () => {
    let requestParam = {
      ...this.state.currentData,
      provider: this.state.pmsKey,
      Authtoken: this.props.hotelAuthReducer.hotelDetails.token,
      integration: this.props.hotelAuthReducer.hotelDetails.hotel.pms_sync,
      fromRoomSetting: true,
    };

    delete requestParam.currency;

    if (requestParam.id) {
      this.props.connectionUpdate(requestParam);
      this.setState({
        loaderConn: true,
      });
    }
  };

  setupNumberRooms = () => {
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
    };
    this.props.setupNumberRooms(requestParam);

    this.setState({
      calNumbers: true,
    });
  };

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    const columns = [
      {
        name: t("id"),
        options: {
          display: false,
        },
      },
      {
        name: t("Name"),
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "Property",
        options: {
          display:
            this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 12
              ? true
              : false,
        },
      },
      {
        name: t(`${this.state.room_apartment_space_name} in ${this.state.pms_channel_manager_text}`),
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div className={classes.cellAign2}>{value}</div>;
          },
        },
      },
      {
        name:
          this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider &&
          (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 25 ||
            this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 26)
            ? t("Daily Price")
            : t(`Rate in ${this.state.pms_channel_manager_text}`),
        options: {
          display:
            [8, 17, 35, 39, 42, 52,67].includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider)
              ? false
              : true,
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div className={classes.cellAign2}>{value}</div>;
          },
        },
      },

      {
        name: t(`Number of ${this.state.room_apartment_space_name}s`),
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div className={classes.cellAign}>
                {value}
              </div>
            );
          },
        },
      },
      {
        name: t("Reference/Derived"),
        options: {
          filter: false,
          sortDirection: "desc",
        },
      },
      {
        name:
          this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider &&
          (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 8 ||
            this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 17 ||
            this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 25 ||
            this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 26)
            ? t(`${this.state.room_apartment_space_name} Occupancy for Pricing`)
            : "",
        options: {
          filter: false,
          display:
            this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider &&
            (this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 8 ||
              this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider ==
                17 ||
              this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider ==
                25 ||
              this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 26)
              ? true
              : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div className={classes.cellAign2}>{value}</div>;
          },
        },
      },
      {
        name: t("Category"),
        options: {
          download: false,
          sort: false,
          display:
            this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 8 ||
            this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 17
              ? true
              : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
              if (
                this.props.pmsList &&
                this.props.pmsList.mapped_data &&
                this.props.pmsList.mapped_data.length > 0
              ) {
                let roomData = this.props.pmsList.mapped_data.filter(
                  (val) => val.room_id == value
                );
                if (roomData[0]) {
                  return (
                    <div className={classes.cellAign2}>{roomData[0].name}</div>
                  );
                }
              }
              return <div className={classes.cellAign2}>-</div>;
            } else {
              return <div className={classes.cellAign2}>-</div>;
            }
          },
        },
      },
      {
        name: t("Base Price"),
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {         
            return (
            <div className={classes.cellAign}>               
            { value !==""  ?
              this.state.currencySymbol + new Intl.NumberFormat("ja-JP").format(Math.round(value))
              : "-"}
              </div>
            );
          },
        },
      },
      {
        name: t(`Derivation from Reference ${this.state.room_apartment_space_name}`),
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (this.state.all_room_pricing) {
              return <div className={classes.cellAign} >{value}</div>;
            } else {
              return (
                <div className={classes.cellAign}>
                  -
                </div>
              );
            }
          },
        },
      },
      {
        name: t("Default Minimum Price"), 
        options: {
          filter: false,
          display: this.state.featuresEnabled.includes(6) ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div className={classes.cellAign}>
             { this.state.currencySymbol}{value}
              </div>
            );
          },
        },
      },
      {
        name: t("Default Maximum Price"),
        options: {
          filter: false,
          display: this.state.featuresEnabled.includes(6) ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div className={classes.cellAign}>
             { this.state.currencySymbol}{value}
              </div>
            );
          },
        },
      },
      {
        name: t("Yielding Tags"),
        options: {
          filter: false,
          display: this.state.featuresEnabled.includes(9) ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return value.map((tag) => (
              <div className={classes.yieldingTagsValue} style={{ background: tag.color}}>
                {tag.tag_name}
              </div>
            ));
          },
        },
      },
      {
        name: t("Delete"),
        options: {
          filter: false,
          download: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const { classes } = this.props;
            if (value.is_reference_room === false) {
              return (
                <React.Fragment>
                  <Button
                    color="secondary"
                    onClick={() => this.openDeleteRoomModal(value)}
                    className={[classes.cellAign, classes.actionIcon]}
                  >
                    <Icon>delete</Icon>
                  </Button>
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment>
                </React.Fragment>
              );
            }
          },
        },
      },
    ];
    const options = {
      responsive: "scroll",
      selectableRows: false,
      search: false,
      print: false,
      download: false,
      viewColumns: false,
      filter: false,
      rowsPerPage: this.state.rowNumbers,
      rowsPerPageOptions: [5, 10, 20, 50, 100],
      onRowClick: (rowData) => {
        let roomSetting = this.state.roomList.filter(
          (val) => val.id == rowData[0]
        );
        this.openEditRoomModal(roomSetting[0]);
      },
      setRowProps: (row) => {
        return {
          className: classnames({
            [this.props.classes.refRommRow]: row[6] === "Reference",
          }),
        };
      },
      onTableChange: (action, tableState) => {
        if (action == "changeRowsPerPage") {
          this.setState({
            rowNumbers: tableState.rowsPerPage,
          });
        }
      },
      textLabels: {
        body: {
          noMatch: this.state.loadData
            ? "Loading Records..."
            : "Sorry, no matching records found.",
        },
      },
    };

    let currencyAdorn = (
      <Typography variant="body2" className={classes.adornment}>
        {this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol}
      </Typography>
    );


    return (
      <React.Fragment>
        <HeaderCard>              
          <Grid container>
            <Grid item >
              <div className={classes.divStyle} >
                  <Typography variant="h5" className={classes.fontStyle} >
                  {t(`${this.state.room_apartment_space_name}s Setup`)}
                      <CustomTooltip title="" description={t(
                    `On this page you can set up your ${this.state.room_apartment_space_name.toLowerCase()}s. This means first ‘mapping’ them to the ${this.state.room_apartment_space_name.toLowerCase()}s and rates in your ${this.state.pms_channel_manager_text}. In this way the ${this.state.room_apartment_space_name.toLowerCase()}s in RoomPriceGenie can talk to the ${this.state.room_apartment_space_name.toLowerCase()}s in your system. The next step is setting default values for the most important settings: Base Price, Maximum and Minimum Prices. For more on ${this.state.room_apartment_space_name.toLowerCase()} setup please consult the Help Center.`
                  )} />
                  </Typography>
                <Grid item xs={12} className={classes.settingFieldsParentGrid}>
                  <div className={classes.settingFieldsParent} >
                    <FormControl margin="none" className={classes.settingLable} >
                      <InputLabel shrink={true} className={classes.settingInputLable} htmlFor="name" >{t(`Total Number of ${this.state.room_apartment_space_name}s`)}</InputLabel>
                      <Input
                        type="number"
                        error={this.state.number_of_roomsError}
                        id="standard-number"
                        name="standard-number"
                        onChange={this.changeNoOfRooms()}
                        value={this.state.number_of_rooms}
                        // placeholder={t(`${this.state.room_apartment_space_name}s`)}
                        className={classes.settingInput}
                        endAdornment={<><span className={classes.inputSpan} >{t('Sum')}: {this.state.totalNumberRoom}</span><CustomTooltip title="" description={t(`This is the total number of ${this.state.room_apartment_space_name.toLowerCase()}s in the property that you can sell to customers. We need this to judge how full you are. If you have a ${this.state.pms_channel_manager_text} integration, you need to make sure that all ${this.state.room_apartment_space_name.toLowerCase()} types are connected so that the total number of ${this.state.room_apartment_space_name.toLowerCase()}s we can read from your ${this.state.pms_channel_manager_text} is the same as the total number shown here.`)}></CustomTooltip></>}
                      />
                    </FormControl>

                    <Tooltip
                      classes={{
                        popper: "toolTipPooper",
                        tooltip: classes.htmlTooltip,
                      }}
                      title={t(
                        `Switches the derivation of your derived ${this.state.room_apartment_space_name.toLowerCase()} types from percentages to absolute values. For example, you may want your single ${this.state.room_apartment_space_name.toLowerCase()} always to be $20 cheaper than your reference ${this.state.room_apartment_space_name.toLowerCase()}.`
                      )}
                    >
                      <FormControlLabel
                        className={classes.absCheckbox}
                        control={
                          <Checkbox
                            checked={this.state.absoluteAdjustment}
                            value={
                              typeof this.state.absoluteAdjustment !==
                                "undefined" && this.state.absoluteAdjustment
                                ? "true"
                                : "false"
                            }
                            onChange={(event) =>
                              this.handleAbsoluteAdjustment(event)
                            }
                            color="primary"
                            className={classes.absoluteCheckbox}
                          />
                        }
                        label={t(`Absolute ${this.state.room_apartment_space_name} Derivation (default is %)`)}
                      />
                    </Tooltip>
                    {this.state.pmsProviderList.includes(this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) && 
                    
                      <FormControlLabel
                        className={classes.absCheckbox}
                        control={
                          <Checkbox
                            checked={this.state.occupancy_percent_derivation}
                            value={this.state.occupancy_percent_derivation}
                            onChange={(event) => this.handleOccupancyAbsoluteAdjustment(event)}
                            color="primary"
                            className={classes.absoluteCheckbox2}
                          />
                        }
                        label={t("% Occupancy Derivation (default is absolute)")}
                      />
                    
                    }
                    <Button className={classes.saveSettingBtn} disabled={this.state.saveLoader} variant="contained" color="primary" onClick={() => this.toggleWarningModal("updateRoomsNumber")} >
                      {t("Save")} {this.state.saveLoader && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                    </Button>
                  </div>
                </Grid>
          </div>
          </Grid>
          </Grid>
        </HeaderCard>
        <Card className={classes.tableHeading}>
          <CardContent>
            <div className={classes.addBtnDiv}>
              <Grid container>
                <Grid item xs={12}>
                  <div>
                    <Button className={classes.addUserBtn} variant="contained" color="primary" o onClick={this.toggleNewRoomModal}>
                    {t(`Add New ${this.state.room_apartment_space_name} Type`)}
                    </Button>
                    {this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider &&
                      this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider !== 15 &&
                      this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider !== 22 && (
                        <Button disabled={this.state.loaderConn}  className={[classes.addUserBtn,classes.addUserBtn2]} color="primary" onClick={this.getLatestRooms}>
                          {t(`Refresh ${this.state.room_apartment_space_name}s/Rates from ${this.state.pms_channel_manager_text}`)}
                          {this.state.loaderConn ? (<CircularProgress color="primary" size={24} className={classes.buttonProgress}/>) : ("")}
                        </Button>
                      )}
                    {(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) && (
                        <Button disabled={this.state.pmsList.mapped_data.length == 0 ||this.state.calNumbers}  className={[classes.addUserBtn,classes.btnSecondary]}  onClick={this.setupNumberRooms}>
                          {t(`Set Number of ${this.state.room_apartment_space_name}s`)}{" "}
                        {this.state.calNumbers ? (<CircularProgress color="primary" size={24} className={classes.buttonProgress}/>) : ("")}
                      </Button>
                    )}
                    {(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) && (
                      <Button disabled={this.state.pmsList.mapped_data.length == 0 ||this.state.autoLoad} className={[classes.addUserBtn,classes.btnSecondary]}  onClick={this.connectRoom}>
                          {t(`Set Up All ${this.state.room_apartment_space_name}s`)}{" "}
                        {this.state.autoLoad ? (<CircularProgress color="primary" size={24} className={classes.buttonProgress} />) : ("")}
                        </Button>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={classes.userTableDiv}>
              <MuiThemeProvider theme={roomSettingThme}>
                <MUIDataTable
                  title=""
                  data={RoomSetingList}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
            </div>
            <>
              {this.state.openNewRoom ? Modals.addNewRoomModal(this) : ""}
              {this.state.openEditRoom ? Modals.editRoomModal(this) : ""}
              {this.state.openDeleteRoom ? Modals.DeleteRoomModal(this) : ""}
              {this.state.changed_min_warning_modal || this.state.changed_max_warning_modal ? Modals.dailyminmaxWarningModal(this) : ""}
              {Modals.AverageGraph(this)}
              {Modals.roomSetupAlert(this)}
              {Modals.autoSetupModal(this)}
              {ClientModals.deadRoomRateTypeModal(this)}
              {ClientModals.minMaxOccupancyDetectModal(this)}
              {SettingsModals.roomDeleteConfirmationDialog(this)}
              {SettingsModals.rateDeleteNotificationDialog(this)}
              {Warning.warningModal(this)}
              {Modals.deleteAllOccupancySettingsModal(this)}
            </>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}

RoomSetting.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    userSession: state.sessionReducer,
    roomList: state.roomListReducer,
    pmsList: state.PmsListReducer.pmsList,
    hotelAuthReducer: state.hotelAuthReducer,
    hotelDetailReducer: state.hotelSettingReducer,
    commonReducer: state.commonReducer,
    priceSettingReducer: state.priceSettingReducer,
    featuresReducer: state.featuresReducer,
    connectionReducer: state.connectionReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRoomList: (request) => { dispatch(actions.getRoomList(request)) },
    addNewRoom: (request) => { dispatch(actions.addNewRoom(request)) },
    getPmsList: (request) => { dispatch(actions.getPmsList(request)) },
    editRoom: (request) => { dispatch(actions.editRoom(request)) },
    deleteRoom: (request) => { dispatch(actions.deleteRoom(request)) },
    updateHotel: (request) => { dispatch(actions.updateHotelSetting(request)) },
    getHotelDetail: (request) => { dispatch(actions.getHotelDetail(request)) },
    updateAveragePrice: (request) => { dispatch(actions.updateAveragePrice(request)) },
    connectRooms: (request) => { dispatch(actions.connectRooms(request)) },
    getPricingSettings: (request) => { dispatch(actions.getPricingSettings(request)) },
    updatePriceJson: (request, response, type) => { dispatch(actions.updateSettingJson(request, response, type)) },
    roomBulkUpdate: (request, ref) => { dispatch(actions.roomBulkUpdate(request, ref)) },
    AddRefRoom: (request) => { dispatch(actions.AddRefRoom(request)) },
    connectionUpdate: (request) => { dispatch(actions.connectionUpdate(request)) },
    getConnectionList: (request) => { dispatch(actions.getConnectionList(request)) },
    removeDeadRooms: (request) => { dispatch(actions.removeDeadRooms(request)) },
    removeDeadRates: (rateData, roomData) => { dispatch(actions.removeDeadRates(rateData, roomData)) },
    putNotify: (request) => { dispatch(actions.putNotify(request)) },
    storeOccupancyPrices: (request) => { dispatch(actions.storeOccupancyPrices(request)) },
    getOccupancyPrices: (request) => { dispatch(actions.getOccupancyPrices(request)) },
    setupNumberRooms: (request) => { dispatch(actions.setupNumberRooms(request)) },
    storeOccupancyDerivation: (request) => { dispatch(actions.storeOccupancyDerivation(request)) } ,     
    getPricePerRate: (request) => { dispatch(actions.getPricePerRate(request)); },    
    storePricePerRate: (request) => { dispatch(actions.storePricePerRate(request)); },
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps)
)(withNamespaces("translations")(RoomSetting));
