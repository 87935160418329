import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../../actions/index';


const onInventaryStart = () => {
    return {
        type: actionTypes.INVENTARY_START
    }
}

const onInventaryFailed = (error) => {
    return {
        type: actionTypes.INVENTARY_FAILED,
        error: error
    }
}

const onInventarySucceed = (data) => {
    return {
        type: actionTypes.INVENTARY_SUCCEED,
        state: data
    }
}

export const getInventary = (request) => {
    return dispatch => {
        dispatch(onInventaryStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'hotel/inventory/list/?start_date=' + request.start_date + '&end_date=' + request.end_date)
            .then(function (response) {
                dispatch(onInventarySucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onInventaryFailed(error))
            });
    }
}

export const uploadFile = (request) => {
    return dispatch => {
        var data = new FormData();
        data.append('inventory_file', request.file, request.file.name);
        
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/inventory/inngenius/upload/', data)
            .then(function (response) {
                dispatch(onInventarySucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onInventaryFailed(error))
            });
    }
}

export const updateInventary = (request) => {
    return dispatch => {
        let requestParam = {
            inventory_data: request.inventory_data
        }
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.post(Server.API + 'hotel/inventory/update/', requestParam)
            .then(function (response) {
                dispatch(actions.getInventary(request))
            })
            .catch(function (error) {
            });
    }
}