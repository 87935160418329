import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import errorTicketCss from '../css/errorTicketCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../../store/actions/index';
import MUIDataTable from "mui-datatables";
import Button from '@material-ui/core/Button';
import Modal from '../modal/modal'
import { MuiThemeProvider } from '@material-ui/core/styles';
import { otherErrorTickets } from '../../muiTheme';
import { Icon, Tooltip } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import moment from 'moment';
import DateRangeIcon from '@material-ui/icons/DateRange';
import developerDataArray from "../../developersList";

const styles = errorTicketCss;

const statusDataArray = [
    { label: 'Open', value: 1 },
    { label: 'Closed', value: 2 },
]

class DevCriticalErrorTickets extends React.Component {

    state = {
        error:false,
        count: 0,
        current: 1,
        ticket_status: 1,
        developer: '',
        ticket_id: '',
        pms: '',
        selectedIndex: [],
        showerrorTicket: false,
        tags: '',
        status: 1,
        total_count:0,
        developer_modal:'',
        error_message:{},
        is_multiple_update: false,
        show_multiple_update_modal: false,
        ml_ticket_ids: '',
        ml_status: 1,
        ml_developer: 'notassigned',
        page: '',
        tag_ml: '',
        tagsDataArray:[],
        dateFilter:false,
        start_date: moment(),
        end_date: moment(),
        code:"",
        create_date:moment(),
        datadog_url:""
    };
    
    toggleErrorTicketModal = (data) => {
        var duration = moment.duration((moment(new Date())).diff(moment(data.modified_at).format('YYYY-MM-DD HH:mm')));
        this.setState({
            hotel_name:data.hotel_name,
            hotel_id:data.hotel,
            code:data.code,
            create_date:moment(data.created_at).format('YYYY-MM-DD HH:mm'),
            datadog_url:data.datadog_url,
            responsible_person:data.responsible_person,
            reseller:((data.reseller != undefined) ? data.reseller : ""),
            pms_name:((data.pms_name != undefined) ? data.pms_name : ""),
            ticket_id:data.id,
            showerrorTicket: !this.state.showerrorTicket,
            status:data.ticket_status,
            developer_modal:((data.developer != undefined) ? data.developer : ""),
            tag_ml:((data.tag != "") ? data.tag : ""),
            total_count:data.count,
            modified_date:data.modified_at,
            time_difference: (duration.days()) + " days " + (duration.hours()) + " hours " + (duration.minutes()) + " minutes ago",
            error_message:  data.message ? JSON.stringify(data.message) :"No Message",
        })
    }

    toggleMultipleErrorTicketModal = (ticket_ids,selectedIndices, data={}) => {
        this.setState({
            is_multiple_update: true,
            selectedIndex: selectedIndices ? selectedIndices : [],
            show_multiple_update_modal: !this.state.show_multiple_update_modal,
            ml_ticket_ids: ticket_ids,
            tags:((data.tags != "") ? this.state.tags : ""),
            developer: ((data.developer != "") ? this.state.developer : ""),
            pms: ((data.pms != "") ? this.state.pms : ""),
            page: ((data.page != "") ? this.state.page : ""),
        });
    }
    changeDate = (name) => dateValue => {
        this.setState({
            [name]: dateValue
        })
    }
    handlefilterClose = () => {
        this.setState({ dateFilter: false });
    };
    handlefilterDate = () => {
        let start_date= moment(this.state.start_date).format('YYYY-MM-DD');
        let end_date= moment(this.state.end_date).format('YYYY-MM-DD');
        let requestParams = {
            token: this.props.userSession.token,
            url:`errors/error-ticket/?ticket_type=3&start_date=${start_date}&end_date=${end_date}`
           
        };

        this.props.getNewErrorTicketsList(requestParams, 3);
        this.handlefilterClose();
        this.setState({ filtered: true })
    };
    handleChangeTicketStatus = (event) => {
        const name = event.target.name;
        this.setState({
          ...this.state,
          [name]:  event.target.value
        });
        let requestParams = {
          token: this.props.userSession.token,
          url:'errors/error-ticket/?ticket_type=3&ticket_status='+ event.target.value + ((this.state.developer !== "") ? '&developer=' + this.state.developer : "") + ((this.state.pms !== "") ? '&pms=' + this.state.pms : "")  + ((this.state.tags !== "") ? '&tags=' + this.state.tags : "")
        };
        this.props.getNewErrorTicketsList(requestParams, 3);
    };

    handleChangeTicketStatusModal = (event) => {
        this.setState({
          ...this.state,
          status: event.target.value
        });
    };
    
    handleChangeTicketStatusModalMultiple = (event) => {
        this.setState({
          ...this.state,
          ml_status: event.target.value
        });
    };

    handleChangeDeveloper = (event) => {
        const name = event.target.name;
        this.setState({
          ...this.state,
          [name]:  event.target.value
        });
        let requestParams = {
            token: this.props.userSession.token,
            url:'errors/error-ticket/?ticket_type=3&ticket_status='+ this.state.ticket_status + ((event.target.value !== "") ? '&developer=' + event.target.value : "") + ((this.state.pms !== "") ? '&pms=' + this.state.pms : "")  + ((this.state.tags !== "") ? '&tags=' + this.state.tags : "")
        };
        this.props.getNewErrorTicketsList(requestParams, 3);
    };
    
    handleChangeTags = (event) => {
        const name = event.target.name;
        this.setState({
          ...this.state,
          [name]:  event.target.value
        });
        let requestParams = {
            token: this.props.userSession.token,
            url:'errors/error-ticket/?ticket_type=3&ticket_status='+ this.state.ticket_status + ((this.state.developer !== "") ? '&developer=' + this.state.developer : "") + ((this.state.pms !== "") ? '&pms=' + this.state.pms : "") + ((event.target.value !== "") ? '&tags=' + event.target.value : "")
        };
        this.props.getNewErrorTicketsList(requestParams, 3);
    };
    
    handleChangePMSCM = (event) => {
        const name = event.target.name;
        this.setState({
          ...this.state,
          [name]:  event.target.value
        });
        let requestParams = {
            token: this.props.userSession.token,
            url:'errors/error-ticket/?ticket_type=3&ticket_status='+ this.state.ticket_status + ((this.state.developer !== "") ? '&developer=' + this.state.developer : "") + ((event.target.value !== "") ? '&pms=' + event.target.value : "") + ((this.state.tags !== "") ? '&tags=' + this.state.tags : "")
        };
        this.props.getNewErrorTicketsList(requestParams, 3);
    };
    
    handleChangeDeveloperModal = (event) => {
        this.setState({
          ...this.state,
          developer_modal:  event.target.value
        });
    };
    
    handleChangeDeveloperModalMultiple = (event) => {
        this.setState({
          ...this.state,
          ml_developer:  event.target.value
        });
    };

    updateSingleErrorTicket = () => {
        let requestParams = {
            token: this.props.userSession.token,
            id: this.state.ticket_id,
            developer_name: this.state.developer_modal || "notassigned",
            status: this.state.status ||1
        };
        let requestParamsReload = {
            token: this.props.userSession.token,
            status: this.state.ticket_status ? this.state.ticket_status : 1,   
            developer_name: "",
            tags: this.state.tags ? this.state.tags : "",
            pms: this.state.pms ? this.state.pms : "",
            page: this.state.page ? this.state.page : "",
			ticket_type:3
        };
        this.props.updateSingleErrorTicket(requestParams, requestParamsReload, 3)
        this.toggleErrorTicketModal(requestParamsReload)
    }

    updateMultipleErrorTicket = () => {
        let requestParams = {
            token: this.props.userSession.token,
            ids: this.state.ml_ticket_ids,
            developer_name: this.state.ml_developer,
            status: this.state.ml_status || 1
        };
        let requestParamsReload = {
            token: this.props.userSession.token,
            status: 1,
            developer_name: this.state.developer ? this.state.developer : "",
            tags: this.state.tags ? this.state.tags : "",
            pms: this.state.pms ? this.state.pms : "",
            page: this.state.page ? this.state.page : "",
			ticket_type:3
        };
        this.props.updateMultipleErrorTicket(requestParams, requestParamsReload, 3)
        this.toggleMultipleErrorTicketModal(this.state.ml_ticket_ids, requestParams)
    }

    componentWillMount() {
        let requestParam = {
            token: this.props.userSession.token,
            ticket_status: 1,
            developer: this.state.developer ? this.state.developer : "",
            tags: this.state.tags ? this.state.tags : "",
            pms: this.state.pms ? this.state.pms : "",
            page: this.state.page ? this.state.page : "",
			ticket_type:3,
        };

        this.props.getNewErrorTicketsList(requestParam, 3);
        let req = {
            token: this.props.userSession.token,
			ticket_type:3,
        };
        this.props.getTagsList(req);
    }

    componentWillReceiveProps(props) {
        if (props.ErrorTicketsReducer.othersErrorTickets) {
            this.setState({
                otherserrorTickets: props.ErrorTicketsReducer.othersErrorTickets,
                count: props.ErrorTicketsReducer.othersErrorTickets.count
            })
        }
        if(props.TagsList.tagsList && props.TagsList.tagsList.results){
            this.setState({
                tagsDataArray: props.TagsList.tagsList.results
            })                 
        }
    }

    rendererrorTicketsData = (data) => {
        let jsx = [];
        data.results.map((val) => {
            let er_ticket_status = "";
            if (val.status) {
                let estatus = statusDataArray.filter(row => row.value == val.status);
                if (estatus.length > 0) {
                    er_ticket_status = estatus[0].label;
                }
            }
            jsx.push([
                val.hotel,
                val.hotel_name,
                val.tag,
                val.count,
                val.pms_name,
                val.responsible_person,
                val.reseller,
                val.developer_name,
                val.modified_at ? moment(val.modified_at).format('YYYY-MM-DD HH:mm') : "-",
                er_ticket_status,
                val.id,
                val,
            ])
            return null;
        })
        return jsx;
    }

    options = () => {
        const { classes } = this.props;
        return {
            sort: true,
            filter:true,
            print: false,
            download: false,
            viewColumns: false,
            responsive: "scroll",
            selectableRows: "multiple",
            filterType: "dropdown",
            serverSide: true,
            page: (this.props.ErrorTicketsReducer.othersErrorTickets && this.props.ErrorTicketsReducer.othersErrorTickets.current) ? (this.props.ErrorTicketsReducer.othersErrorTickets.current - 1) : 0,
            count: this.state.count,
            rowsPerPage: 20,
            rowsPerPageOptions: false,
            rowsSelected:this.state.selectedIndex.length > 0 ? this.state.selectedIndex : [],
            selectableRowsOnClick: true,
            customToolbarSelect: (selectedRows, displayData) => {
                let ticket_ids = selectedRows.data.map(
                    index => displayData.filter(i => i.dataIndex === index.dataIndex)[0].data[10]
                );
                const selectedIndices = selectedRows.data.map(index => index.dataIndex);
                return <React.Fragment>
                        <Button className={[classes.buttonItems,classes.btnUpdate]} onClick={(id) => this.toggleMultipleErrorTicketModal(ticket_ids, selectedIndices)} title="Update Error Ticket" variant="contained" color="primary" >Update</Button>
                    </React.Fragment>
            },
            onTableChange: (action, tableState) => {
                let url = 'errors/error-ticket/?ticket_type=3';
                let requestParams = { token: this.props.userSession.token };

                if (action === 'changePage') {
                    let page = tableState.page + 1;
                    url += '&page=' + page + ((this.state.developer !== '' && this.state.developer !== null && this.state.developer !== undefined) ? '&developer=' + this.state.developer : "") + ((this.state.pms !== '' && this.state.pms !== null && this.state.pms !== undefined) ? '&pms=' + this.state.pms : "")  + ((this.state.tags !== '' && this.state.tags !== null && this.state.tags !== undefined) ? '&tags=' + this.state.tags : "")
                    requestParams.url = url
                    this.setState({
                        page: tableState.page + 1
                    })
                    this.props.getNewErrorTicketsList(requestParams, 3)
                }
                if (action === 'search') {
                    let searchText = tableState.searchText ? tableState.searchText : '';
                    let searchHotelid = '';
                    if(parseInt(searchText) > 0) {
                        searchHotelid = searchText
                        url += '&search=' + searchText + ((this.state.ordering) ? '&ordering=' + this.state.ordering : '');
                    } else if (tableState.searchText) {
                        url += '&search=' + searchText + ((this.state.ordering) ? '&ordering=' + this.state.ordering : '');
                    }
                    requestParams.url = url
                    this.setState({
                        searchText: searchText,
                        searchHotelid: searchHotelid
                    },
                        () => {
                            setTimeout(() => {
                                if (searchText.length == this.state.searchText.length) {
                                    this.props.getNewErrorTicketsList(requestParams, 3)
                                }
                            }, 2000);
                        })
                }

                if(action == 'filterChange' || action == 'resetFilters') {
                    let _filter = tableState.filterList.filter(val => val.length > 0)
                    if (_filter && _filter.length) {
                        if (tableState.filterList[2].length > 0) {
                            let tags = this.state.tagsDataArray.filter(row => row.name == tableState.filterList[2][0])[0].id;
                            url += '&ticket_tag=' + tags;
                            this.setState({ tags: tags })
                        }
                        if (tableState.filterList[9].length > 0) {
                            let ticket_status = statusDataArray.filter(row => row.label == tableState.filterList[9])[0].value;
                            url += '&ticket_status=' + ticket_status;
                            this.setState({ ticket_status: ticket_status })
                        }
                        if (tableState.filterList[7].length > 0) {
                            let developer = developerDataArray.filter(row => row.label == tableState.filterList[7])[0].value;
                            url += '&developer=' + developer;
                            this.setState({ developer: developer })
                        }
                        requestParams.url = url
                        this.props.getNewErrorTicketsList(requestParams, 3)
                        
                    } else {
                        this.setState({ tags: null, pms: null, developer: null})
                        setTimeout(() => {
                            if(action === 'filterChange') {
                                url += ((this.state.page !== "" && this.state.page !== null) ? '&page=' + this.state.page : "") + ((this.state.developer !== "" && this.state.developer !== null) ? '&developer=' + this.state.developer : "") + ((this.state.pms !== "" && this.state.pms !== null) ? '&pms=' + this.state.pms : "")  + ((this.state.tags !== "" && this.state.tags !== null) ? '&tags=' + this.state.tags : "")
                                requestParams.url = url
                                this.props.getNewErrorTicketsList(requestParams, 3)
                            }
                        },1000);
                    }
                }
            },
            onColumnSortChange: (changedColumn, direction) => {
                let sort_direction;
                let requestParams = { token: this.props.userSession.token };
                switch (changedColumn) {
                    case 'ID':
                        sort_direction = (direction === 'ascending') ? 'hotel' : '-hotel';
                        break;
                    case 'Hotel':
                        sort_direction = (direction === 'ascending') ? 'hotel_name' : '-hotel_name';
                        break;
                    case 'Tag':
                        sort_direction = (direction === 'ascending') ? 'tags' : '-tags';
                        break;
                    case 'PMS/CM':
                        sort_direction = (direction === 'ascending') ? 'pms_name' : '-pms_name';
                        break;
                    case 'Responsible Person':
                        sort_direction = (direction === 'ascending') ? 'responsible_person' : '-responsible_person';
                        break;
                    case 'Reseller':
                        sort_direction = (direction === 'ascending') ? 'reseller' : '-reseller';
                        break;
                    case 'Developer':
                        sort_direction = (direction === 'ascending') ? 'developer' : '-developer';
                        break;
                    case 'Date Modified':
                        sort_direction = (direction === 'ascending') ? 'modified_date' : '-modified_date';
                        break;
                    case 'Status':
                        sort_direction = (direction === 'ascending') ? 'ticket_status' : '-ticket_status';
                        break;
                    default:
                        sort_direction = (direction === 'ascending') ? 'hotel' : '-hotel';
                        break;
                }

                requestParams.url = 'errors/error-ticket/?ticket_type=3&ordering=' + sort_direction + ((this.state.searchText) ? '&search=' + this.state.searchText : '') + ((this.state.developer !== "" && this.state.developer !== null && this.state.developer !== undefined) ? '&developer=' + this.state.developer : "") + ((this.state.pms !== "" && this.state.pms !== null && this.state.pms !== undefined) ? '&pms=' + this.state.pms : "")  + ((this.state.tags !== "" && this.state.tags !== null && this.state.tags !== undefined) ? '&tags=' + this.state.tags : "") + ((this.state.ticket_status !== "") ? '&ticket_status=' + this.state.ticket_status : "");
                this.props.getNewErrorTicketsList(requestParams, 3)
                this.setState({ ordering: sort_direction })
            },
            customToolbar: () => {

                return (
                    <React.Fragment>
                       
                     
                        <Tooltip classes={{ popper: "toolTipPooper", tooltip: classes.htmlTooltip }} title={'Date Filter'}>
                            <Button  onClick={()=>{
                                this.setState({
                                    dateFilter:true
                                })
                            }} >
                                <DateRangeIcon style={{ color: '#757575' }} />
                            </Button>
                        </Tooltip>
                    </React.Fragment>
                )
            },
            textLabels: {
                body: {
                noMatch: this.props.ErrorTicketsReducer.loading ?
                    'Loading Records...' :
                    'Sorry, no matching records found.',
                },
            }
        }
    };
   

    render() {
        const { classes, t } = this.props;
        const columns = [
            {
                name: "ID",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            { 
                name: "Hotel",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "Tag",
                options: {
                    filter: true,
                    filterOptions: this.state.tagsDataArray.map(row => { return row.name }) || [],
                }
            },
            {
                name: "Count",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "PMS/CM",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "Responsible Person",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "Reseller",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "Developer",
                options: {
                    filter: true,
                    filterOptions: developerDataArray.map(row => { return row.label }),
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if(value != null) {
                            if(value == "notassigned"){
                                return 'Not Assigned'                               
                            }else{
                                return value[0].toUpperCase() + value.slice(1);
                            }
                            
                        } else {
                            return "Notassigned";
                        }
                    }
                }
            },
            {
                name: "Date Modified",
                options: {
                    filter: false,
                    sort: true,
                    sortDirection: 'desc'
                }
            },
            {
                name: "Status",
                options: {
                    filter: true,
                    filterOptions: statusDataArray.map(row => { return row.label })
                }
            },
            {
                name: "Ticket ID",
                options: {
                    filter: false,
                    display: 'false',
                }
            },
            {
                name: "Actions",
                options: {
                  filter: false,
                  cellStyle:{
                    width: 100,
                    maxWidth: 100
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    var jira_ticket_url = "https://roompricegenie.atlassian.net/secure/CreateIssueDetails!init.jspa?issuetype=10009&pid=10015"+"&summary=Hotel Id : " + value.hotel + " - " + value.pms_name + " - " + "&description=" + "Hotel ID : " + value.hotel + "\\Hotel Name : " + value.hotel_name + "\\Responsible Person : " + value.responsible_person + "\\Reseller : " + value.reseller + "\\PMS/CM Name : " + value.pms_name
                    return (
                        <React.Fragment>
                            <Button className={classes.buttonItems} color="primary" onClick={(id) => this.toggleErrorTicketModal(value)} title="Edit Error Ticket"><Icon>edit</Icon></Button>
                            <Button href={jira_ticket_url} className={classes.buttonItems} target="_blank" color="secondary" title="Create Jira Ticket"><Icon>add_box_rounded</Icon></Button>
                        </React.Fragment>
                    );
                  }
                }
            },
        ];
        return (
            <>                            
                <div>
                    <MuiThemeProvider theme={otherErrorTickets}>   
                        <MUIDataTable
                            title={''}
                            data={(this.state.otherserrorTickets) ? this.rendererrorTicketsData(this.state.otherserrorTickets):[]}
                            columns={columns}
                            options={this.options()}
                        />
                    </MuiThemeProvider>
                    {this.state.showerrorTicket ? Modal.errorTicketsModal(this) :""}
                    {this.state.is_multiple_update ? Modal.errorTicketsMultipleUpdateModal(this) : ""}
                    {Modal.dateFilterRangeModal(this)}
                </div>                
            </>
        );
    }
}

DevCriticalErrorTickets.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        loading: state.ErrorTicketsReducer.loading,
        error: state.ErrorTicketsReducer.error,
        ErrorTicketsReducer: state.ErrorTicketsReducer,
        userSession: state.sessionReducer,
        TagsList:state.TagsList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getNewErrorTicketsList: (request, error_tag_type) => { dispatch(actions.getNewErrorTicketsList(request, error_tag_type)) },
        getTagsList: (request, error_tag_type) => { dispatch(actions.getTagsList(request, error_tag_type)) },
        updateSingleErrorTicket: (request, request2, error_tag_type) => { dispatch(actions.updateSingleErrorTicket(request, request2, error_tag_type)) },
        updateMultipleErrorTicket: (request, request2, error_tag_type) => { dispatch(actions.updateMultipleErrorTicket(request, request2, error_tag_type)) },
    };
};

export default compose(withStyles(styles, {
    withTheme: true
}), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(DevCriticalErrorTickets));
