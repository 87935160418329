import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';

export default {
    commentsModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        return (<Dialog
            maxWidth='md'
            open={modalData.state.showComments}
            onClose={modalData.toggleCommentsModal}
            aria-labelledby="responsive-dialog-title"
            >
            <DialogTitle id="responsive-dialog-title">
                Edit Ticket
                <Typography>{modalData.state.hotelName}</Typography>

                <FormControl style={{float: "right"}}>
                    
                    <NativeSelect
                    className={classes.selectEmpty}
                    value={modalData.state.status}
                    name="status"
                    onChange={modalData.handleChangeStatus}
                    inputProps={{ 'aria-label': 'Status' }}
                    >
                    <option value="1">Open</option>
                    <option value="2">In Progress</option>
                    <option value="3">Closed</option> 
                    </NativeSelect>
                    <FormHelperText>Status</FormHelperText>
                </FormControl>
              </DialogTitle>
            <DialogContent>
                <FormControl margin="normal" required  style={{width: "350px"}}>
                    <TextField
                        error={modalData.state.error.includes("comment")}
                        id="comment"
                        label="comment"
                        margin="normal"
                        value={modalData.state.comments}
                        onChange={modalData.handleChange('comment')}
                        multiline
                        rows={4}
                        defaultValue={modalData.state.comment}
                        variant="outlined"
                        required
                    />
                    {modalData.state.error.includes("comment") ? <span class='error-message'>{t('* Please enter the Comment')}</span> : ''}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={modalData.updateComment} variant="contained" color="primary" >
                    Save
                </Button>
                <Button onClick={modalData.toggleCommentsModal}   className="cancelBtn" color="primary" >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>)
    },
    confirmModal: function (modalData) {
        const { fullScreen } = modalData.props;
        return (<Dialog
            fullScreen={fullScreen}
            maxWidth='md'
            open={modalData.state.closeTicket}
            onClose={modalData.confirmCloseTicket}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">Close Ticket</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to close the ticket?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={modalData.closeTicket} color="secondary" autoFocus>
                    Close Ticket
                </Button>
                <Button onClick={modalData.confirmCloseTicket}  className="cancelBtn" >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>)
    }
}