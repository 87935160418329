var style = {
  userTable: {
    padding: '20px'
  },
  userTableDiv: {
    overflow: 'auto',
    marginTop: '70px'
  },
  textField: {
    width: "30%",
    minWidth: '200px',
    float: 'left',
    '&::before': {
      border: 'none'
    }
  },
  textFieldSelect: {
    width: "30%",
    minWidth: '300px',
    float: 'left',
    ['@media (max-width: 414px) and (min-width: 360px)']: {            
      width: "95%",
    }
  },
  tableField: {
    padding: '5px 0px 5px 5px !important',
  },
  textFieldDivUp: {
    width: '100%',
    display: '-webkit-box',
    marginTop: '30px',
    ['@media (max-width: 414px) and (min-width: 360px)']: {      
      marginTop: '10px',
      display: 'flex',
      flexDirection: 'column-reverse'
    }
  },
  textFieldDiv: {
    width: '100%',
    display: '-webkit-box',
    ['@media (max-width: 414px) and (min-width: 360px)']: {            
      display: 'flex',
      flexDirection: 'column-reverse'
    }
  },
  tableTextFields: {
    textAlign: 'center',
    ['@media (max-width: 768px)']: {
      minWidth: '100px',
      margin: '0px 5px 0px 5px'
    },
  },
  saveBtn: {
    float: 'left',
    margin: '20px 0px',
    ['@media (max-width: 414px) and (min-width: 300px)']: {
      margin: '20px 0px',
      // width:"100%"
    }
  },
  saveBtnAgg: {
    float: 'right',
    margin: "-130px 0px 0px 0px",
    ['@media (max-width: 414px) and (min-width: 300px)']: {
      marginTop:"-20px",
      marginBottom:"20px",
      width:"100%"
    }
  },
  swichBtn: {
    float: 'left'
  },
  dateTableDiv: {
    overflow: 'auto',
    float:"left",
    marginBottom:"24px",
    width: "100%",
    paddingBottom: "5px",
    borderBottom:"1px solid #e7e7e7"
  },
  proDiv: {
    marginTop: "45px"
  },
  customCheckbox: {
    marginTop: "22px",
    marginLeft: "20px",
    ['@media (max-width: 540px) and (min-width: 400px)']: {
      float:"left",
      marginTop: "0px",
    },
    ['@media (max-width: 399px) and (min-width: 361px)']: {
      float:"left",
      marginTop: "0px",
    },
    ['@media (max-width: 360px) and (min-width: 321px)']: {
      float:"left",
      marginTop: "0px",
    },
    ['@media (max-width: 320px)']: {
      float:"left",
      marginTop: "0px",
    },
  },
  maiden: {
    float: "left",
    marginBottom: "50px",
    ['@media (max-width: 414px) and (min-width: 360px)']: {            
      width: "100%",
      marginBottom: '0px'
    }
  },
  fieldContainers:{

  },
  buttonProgress: {
    position: "absolute"
  },
  leadTimeDiv:{
    float:"left",
    margin:"4px 0px 20px",
    ['@media (max-width: 414px) and (min-width: 360px)']: {
      marginTop:"-40px",
      "& div": {        
        width:"100%"
      }
    }
  },
  helpText:{
    // maxWidth: "950px",
    float:"left",
    textAlign: "justify",
    margin: "25px 0px 13px 0px",
    color:"#707070",
    maxWidth: "950px",
  },
  fullWidth:{
    ['@media (max-width: 414px) and (min-width: 360px)']: {
      maxWidth:"100%"
    }
  },
  leadtimeTitle:{
    marginBottom:"24px"
  },
  helpText2: {
    maxWidth: "950px",
    float: "left",
    textAlign: "justify",
    margin: "12px 0px 8px 0px",
    color:"#707070"
  },
  checkbox:{
    float: "left",
  },
  divStyle: {
    float: "left", 
    marginTop: "2px"
  },
  fontStyle: {
    textAlign: "left", 
    fontWeight: "500"
  },
  tableField2: {
    minWidth: "75px!important" ,
    border:""
  }
}
export default style;