import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import adjustmentCss from '../css/modal/adjustmentCss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../../store/actions/index';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { monthlyAdjustTheme } from '../../muiTheme';
import { withNamespaces } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = adjustmentCss;

class MonthlyAdjustment extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loadData: true,
      openDailyAdjustModal: false,
      monthlyData: null,
      weeklyData: null,
      error: [],
      monthly_adjustment: [],
      settingAlert: false
    };
  }


  componentWillMount() {
    let requestParam = { token: this.props.hotelAuthReducer.hotelDetails.token }

  }

  componentWillReceiveProps(props) {

    if (props.priceSettingReducer.priceSetting) {
      let monthlyData = props.priceSettingReducer.priceSetting.adjustment.monthly;
      let weeklyData = props.priceSettingReducer.priceSetting.adjustment.weekday;

      this.setState({
        monthlyData: monthlyData,
        weeklyData: weeklyData
      })
    }

    if (props.monthlyAdjustReducer.monthlyList) {
      this.setState({
        loadData: false
      })
    } else {
      this.setState({
        loadData: true
      })
    }
  }


  validateMonthlyAdjust = (data) => {
    let error = [];
    let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let monthData = this.state.monthlyData;
    monthNames.map((row) => {
      if ((!monthData[row].standard || monthData[row].standard === '') && monthData[row].standard !== 0) {
        error.push("mon_" + row);
      }
      return null;
    })
    if (error) {
      this.setState({
        error: error
      })
      return error
    }
    else {
      return false
    }
  }

  updateMonthlyAdjust = () => {

    let validateError = this.validateMonthlyAdjust(this.state.monthlyData);

    if (validateError.length > 0) {
      return;
    }

    let requestParams = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      allData: this.state.monthlyData
    }

    this.props.modalDataProps.saveMonthlyData(requestParams)

  }

  handleMonthly = (row) => event => {
    let monthlyData = this.state.monthlyData;
    if (event.target.value != "" && event.target.value != "-") {
      monthlyData[row].standard = parseFloat(event.target.value);
    } else {
      monthlyData[row].standard = (event.target.value);
    }

    this.setState({
      monthlyData
    }, () => this.props.modalDataProps.forceUpdate())
  }

  renderMonthlyTable = (limit) => {
    var jsx = [];
    const { classes } = this.props;
    const { t } = this.props;
    let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let percentAdorn = <Typography variant="body2" className={classes.adornment}>%</Typography>;
    let monthData = this.state.monthlyData;
    {
      monthData &&
        monthNames.map((row, index) => {
          if (index < (limit + 6) && (index >= limit)) {
            return jsx.push(
              <TableCell align="right">
                <FormControl margin="normal" required className={classes.fieldMargin}>
                  <Input
                    margin="normal"
                    label={t("Value")}
                    type="number"
                    error={this.state.error.includes("mon_" + row)}
                    className={classes.textField}
                    onChange={this.handleMonthly(row)}
                    value={monthData[row].standard}
                    endAdornment={percentAdorn}
                  />
                  {this.state.error.includes("mon_" + row) ? <FormHelperText><span class="error-message">{t('* Please enter the value')}</span></FormHelperText> : ''}
                </FormControl>
              </TableCell>
            );
          }
          return null;
        });
    }
    return jsx;
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.dateTableDiv}>
          <MuiThemeProvider theme={monthlyAdjustTheme}>
            <Table className={classes.userTable}>
              <TableHead>
                <TableRow className={classes.rowTable}>
                  <TableCell align="right"> </TableCell>
                  <TableCell align="right">{t("Jan")}</TableCell>
                  <TableCell align="right">{t("Feb")}</TableCell>
                  <TableCell align="right">{t("Mar")}</TableCell>
                  <TableCell align="right">{t("Apr")}</TableCell>
                  <TableCell align="right">{t("May")}</TableCell>
                  <TableCell align="right">{t("Jun")}</TableCell>
                  <TableCell align="right">{t("Jul")}</TableCell>
                  <TableCell align="right">{t("Aug")}</TableCell>
                  <TableCell align="right">{t("Sep")}</TableCell>
                  <TableCell align="right">{t("Oct")}</TableCell>
                  <TableCell align="right">{t("Nov")}</TableCell>
                  <TableCell align="right">{t("Dec")}</TableCell>
                  <TableCell align="right" style={{width:"25px !important"}}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={1} className={classes.rowTable}>
                  <TableCell>{t("Adjust")}<br />{t("Price by")}</TableCell>
                  {this.renderMonthlyTable(0)}
                  {this.renderMonthlyTable(6)}
                  <TableCell align="right" style={{width:"25px !important"}}></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </MuiThemeProvider>
        </div>
        <div className={classes.adjustmentBtnPopup}>
          <Button className={"orangButton"} disabled={this.props.modalDataProps.state.monthWeekLoader} variant="contained" color="primary" onClick={this.updateMonthlyAdjust}>
            {t("Save")}{this.props.modalDataProps.state.monthWeekLoader && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
          </Button>
          <Button style={{ marginRight: "8px" }} className="cancelBtn" onClick={() => this.props.modalDataProps.toggleMonthlyModal()}>
            {t("Cancel")} 
          </Button>
        </div>
      </div>
    );
  }
}

MonthlyAdjustment.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
  return {
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    userSession: state.sessionReducer,
    hotelDetail: state.hotelSettingReducer.hoteDetail,
    currencyList: state.currencyReducer.currencyList,
    hotelAuthReducer: state.hotelAuthReducer,
    monthlyAdjustReducer: state.monthlyAdjustReducer,
    roomList: state.roomListReducer,
    commonReducer: state.commonReducer,
    priceSettingReducer: state.priceSettingReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateMonthlyAdjust: (request) => { dispatch(actions.updateMonthlyAdjust(request)) },
    getPricingSettings: (request) => { dispatch(actions.getPricingSettings(request)) },
  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(MonthlyAdjustment));
