import React from 'react';
import { connect } from "react-redux";
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import PmsChartCss from './css/PmsChartCss';
import { withNamespaces } from 'react-i18next';
import Chart from 'react-apexcharts'
import * as actions from '../../../../store/actions/index';
import pmsDataArray from '../../../PMSList';
const styles = PmsChartCss;


class PmsChart extends React.Component {

  state = {
    series: [],
    options: {
      colors: ['#FF6633', '#FFB399',  '#00B3E6', 
		  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
		  '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', 
		  '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
		  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', 
		  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
		  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', 
		  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
		  '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', 
		  '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'],
      title: {
        text: 'PMS',
        align: 'left',
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          fontFamily: undefined,
          color: '#263238'
        },
      },
      labels: [],
      chart: {
        type: 'donut',
      },
      dataLabels: {
        enabled: false
      }
    }
  }

  getChartData(deal_owner_filter, deal_owner_type) {
    let requestParams = {
      token: this.props.userSession.token,
      deal_owner_filter: deal_owner_filter,
      deal_owner_type: deal_owner_type
    };
    this.props.getPmsChartData(requestParams);
  }

  componentWillMount() {
    let requestParams = { token: this.props.userSession.token };
      this.getChartData(this.props.deal_owner_filter, this.props.deal_owner_type)
  }

  componentWillReceiveProps(props) {
    if (this.props.deal_owner_filter !== props.deal_owner_filter) {
      this.getChartData(props.deal_owner_filter, props.deal_owner_type)
    }

    if (props.adminChartReducer.pmsChartData && (props.adminChartReducer.pmsChartData !== this.props.adminChartReducer.pmsChartData)) {
      let labels = []
      let series = []
      props.adminChartReducer.pmsChartData.map((chardObj, key) => {
        pmsDataArray.map((pmsObj, key) => {
          if (chardObj.provider === pmsObj.value) {
            labels.push(pmsObj.label + " (" + chardObj.count + ")")
            series.push(chardObj.count)
          }
        })
      })
      this.setState({
        options: {
          ...this.state.options,
          labels: labels
        },
        series: series
      })
    }
  }

  render() {
    const { classes, t } = this.props;
    return (
      <div className={classes.donut}>
        <Chart height="200" style={{minWidth:"80%"}} options={this.state.options} series={this.state.series} type="donut" className={classes.donutcharts} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userSession: state.sessionReducer,
    hotelAuthReducer: state.hotelAuthReducer,
    adminChartReducer: state.adminChartReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPmsChartData: (request) => { dispatch(actions.getPmsChartData(request)) },
  };
};

export default compose(withStyles(styles, {
  withTheme: true
}), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(PmsChart));
