
var pmsDataArray = [
    {
        label: 'Apaleo',
        value: 'apaleo',
        key: 1,
        fields: [
            {
                type: 'text',
                label: 'Property ID',
                key: 'hotel_code',
                password: false
            },
            {
                type: 'text',
                label: 'Client ID',
                key: 'username',
                password: false,
                hideNodata: true,
                noValidate: true
            },
            {
                type: 'text',
                label: 'Client Secret',
                key: 'password',
                password: true,
                hideNodata: true,
                noValidate: true
            }
        ],
    },
    {
        label: 'Mews',
        value: 'mews',
        key: 2,
        fields: [
            {
                type: 'text',
                label: 'Token',
                key: 'token',
                password: false
            },
        ],
    },
    {
        label: 'Booking Factory',
        value: 'bookingfactory',
        key: 3,
        fields: [
            {
                type: 'text',
                label: 'Public Key From Developer Keys',
                key: 'username',
                password: false
            },
            {
                type: 'text',
                label: 'Secret Key From Developer Keys',
                key: 'password',
                password: true
            },
            {
                type: 'text',
                label: 'API Key',
                key: 'token',
                password: false
            },
            {
                type: 'text',
                label: 'Property ID',
                key: 'hotel_code',
                password: false
            },
        ],
    },
    {
        label: 'Little Hotelier',
        value: 'littlehotelier',
        key: 4,
        fields: [
            {
                type: 'text',
                label: 'Little Hotelier Username',
                key: 'username',
                password: false
            },
            {
                type: 'text',
                label: 'Little Hotelier Password',
                key: 'password',
                password: true
            },
            {
                type: 'text',
                label: 'Property ID',
                key: 'hotel_code',
                password: false,
                noValidate: true
            },
            {
                type: 'select',
                options: 'regionList',
                label: 'Region',
                key: 'region',
                password: false
            },
        ],
    },
    {
        label: 'SiteMinder',
        value: 'siteminder',
        key: 5,
        fields: [
            {
                type: 'text',
                label: 'SiteMinder Username',
                key: 'username',
                password: false
            },
            {
                type: 'text',
                label: 'SiteMinder Password',
                key: 'password',
                password: true
            },
            {
                type: 'text',
                label: 'Property ID',
                key: 'hotel_code',
                password: false,
                noValidate: true
            },
            {
                type: 'select',
                options: 'regionList',
                label: 'Region',
                key: 'region',
                password: false
            },
        ],
    },
    {
        label: 'Clock System',
        value: 'clock',
        key: 7,
        fields: [
            {
                type: 'text',
                label: 'Clock System Username',
                key: 'username'
            },
            {
                type: 'text',
                label: 'API Access Key',
                key: 'token'
            },
            {
                type: 'text',
                label: 'PMS URL',
                key: 'pms_url',
                password: false
            },
        ],
    },
    {
        label: 'Beds24(old)',
        value: 'beds24',
        key: 8,
        fields: [
            {
                type: 'text',
                label: 'Property ID',
                key: 'hotel_code',
                password: false
            },
            {
                type: 'text',
                label: 'Beds24 Username',
                key: 'username',
                password: false
            },
            {
                type: 'text',
                label: 'Beds24 Password',
                key: 'password',
                password: true
            },
            {
                type: 'text',
                label: 'Channel Password',
                key: 'channel_password',
                password: false
            },
            {
                label: 'PropKey',
                key: 'extra',
                password: false
            },
            {
                label: 'API Key',
                key: 'token',
                password: false
            },

        ],
    },
    {
        label: 'Hotel Spider',
        value: 'hotelspider',
        key: 10,
        fields: [
            {
                type: 'text',
                label: 'Hotel Code',
                key: 'hotel_code',
                password: false
            },
            // {
            //     type: 'text',
            //     label: 'Channel Dedicated Username',
            //     key: 'username',
            //     password: false
            // },
            // {
            //     type: 'text',
            //     label: 'Channel Dedicated Password',
            //     key: 'password',
            //     password: true
            // },
        ],
    },

    {
        label: 'Cover',
        value: 'cover',
        key: 14,
        fields: [
            {
                type: 'text',
                label: 'Public Key From Developer Keys',
                key: 'username',
                password: false
            },
            {
                type: 'text',
                label: 'Secret Key From Developer Keys',
                key: 'password',
                password: true
            },
            {
                type: 'text',
                label: 'API Key',
                key: 'token',
                password: false
            },
            {
                type: 'text',
                label: 'Property ID',
                key: 'hotel_code',
                password: false
            },
        ],
    },
    {
        label: 'Protel',
        value: 'protel',
        key: 15,
        fields: [
            {
                type: 'text',
                label: 'Property ID',
                key: 'hotel_code',
                password: false
            },
            {
                type: 'select',
                options: 'protelConn',
                label: 'Protel Type',
                key: 'region',
                password: false
            },
            {
                label: 'Token',
                key: 'token',
                password: false
            },
            {
                type: 'select',
                options: 'availability',
                label: 'Availability Count Type',
                key: 'extra',
                password: false
            },
        ],
    },
    // {
    //     label: 'SMX',
    //     value: 'smx',
    //     key: 16,
    //     fields: [
    //         {
    //             type: 'text',
    //             label: 'Property ID',
    //             key: 'channel_password',
    //             password: false
    //         },
    //         {
    //             type: 'select',
    //             options: 'currency',
    //             defaultValue: 1,
    //             label: 'Currency',
    //             key: 'currency_id',
    //             password: false
    //         },
    //         {
    //             type: 'select',
    //             options: 'publisherList',
    //             label: 'Publisher Code',
    //             key: 'extra',
    //             password: false
    //         },
    //         {
    //             type: 'text',
    //             label: 'Username',
    //             key: 'username',
    //             password: false
    //         },
    //         {
    //             type: 'text',
    //             label: 'Password',
    //             key: 'password',
    //             password: true
    //         },
    //         {
    //             type: 'select',
    //             options: 'regionList',
    //             label: 'Region',
    //             key: 'region',
    //             password: false
    //         },
    //     ],
    // },
    {
        label: 'Booking Automation',
        value: 'bookingautomation',
        key: 17,
        fields: [
            {
                type: 'text',
                label: 'Property ID',
                key: 'hotel_code',
                password: false
            },
            {
                type: 'text',
                label: 'Booking Automation Username',
                key: 'username',
                password: false
            },
            {
                type: 'text',
                label: 'Booking Automation Password',
                key: 'password',
                password: true
            },
            {
                type: 'text',
                label: 'Channel Password',
                key: 'channel_password',
                password: false
            },
            {
                label: 'PropKey',
                key: 'extra',
                password: false
            },
            {
                label: 'API Key',
                key: 'token',
                password: false
            },

        ],
    },
    {
        label: 'Channex',
        value: 'channex',
        key: 20,
        fields: [
            // {
            //     type: 'text',
            //     label: 'Username',
            //     key: 'username',
            //     password: false
            // },
            // {
            //     type: 'text',
            //     label: 'Password',
            //     key: 'password',
            //     password: true
            // },
            // {
            //     type: 'text',
            //     label: 'Property ID',
            //     key: 'hotel_code',
            //     password: false,
            //     noValidate: true
            // },
            // {
            //     type: 'text',
            //     label: 'Group ID',
            //     key: 'extra',
            //     password: false,
            //     noValidate: true
            // },
            {
                label: 'API Key',
                key: 'token',
                password: false
            },
        ],
    },
    {
        label: 'GlobeRes',
        value: 'globeres',
        key: 21,
        fields: [
            {
                type: 'text',
                label: 'Username',
                key: 'username',
                password: false
            },
            // {
            //     type: 'text',
            //     label: 'Password',
            //     key: 'password',
            //     password: true
            // },
            // {
            //     type: 'text',
            //     label: 'Property ID',
            //     key: 'hotel_code',
            //     password: false
            // },
        ],
    },
    {
        label: 'Guestline',
        value: 'guestline',
        key: 22,
        fields: [
            {
                type: 'text',
                label: 'HotelID',
                key: 'hotel_code',
                password: false
            },
            {
                type: 'select',
                options: 'guesLineRegions',
                label: 'Region',
                key: 'region',
                password: false
            },
        ],
    },
    {
        label: 'Bocco',
        value: 'bocco',
        key: 23,
        fields: [
            {
                type: 'text',
                label: 'Hotel Code',
                key: 'hotel_code',
                password: false
            },
            // {
            //     type: 'text',
            //     label: 'Channel Dedicated Username',
            //     key: 'username',
            //     password: false
            // },
            // {
            //     type: 'text',
            //     label: 'Channel Dedicated Password',
            //     key: 'password',
            //     password: true
            // },
        ],
    },
    {
        label: 'Seekom',
        value: 'seekom',
        key: 24,
        fields: [
            {
                label: 'connect with seekom',
                url: false,
                btn: true,
                pms: 'seekom'
            },
            {
                label: 'API Key',
                key: 'token',
                password: false
            },
            {
                label: 'Refresh Key',
                key: 'extra',
                password: false
            },
            {
                type: 'text',
                label: 'Property ID',
                key: 'hotel_code',
                password: false
            },
        ],
    },
    {
        label: 'Beds24(new)',
        value: 'beds24new',
        key: 25,
        fields: [
            {
                type: 'text',
                label: 'Property ID',
                key: 'hotel_code',
                password: false
            },
            {
                label: 'API Key',
                key: 'token',
                password: false
            },
            {
                type: 'multiSelectAdd',
                // options: 'multiple_properties',
                label: 'Multiple Properties',
                key: 'extra',
                password: false
            },
        ],
    },
    {
        label: 'Booking Automation Partner',
        value: 'bookingautomationnew',
        key: 26,
        fields: [
            {
                type: 'text',
                label: 'Property ID',
                key: 'hotel_code',
                password: false
            },
            {
                label: 'API Key',
                key: 'token',
                password: false
            },
            {
                type: 'multiSelectAdd',
                // options: 'multiple_properties',
                label: 'Multiple Properties',
                key: 'extra',
                password: false
            },

        ],
    },
    {
        label: 'RMS',
        value: 'rms',
        key: 27,
        fields: [
            {
                type: 'text',
                label: 'ClientID',
                key: 'username',
                password: false
            },
            {
                type: 'text',
                label: 'Webservice Password',
                key: 'password',
                password: true
            },
            {
                type: 'select',
                options: 'regionListRms',
                label: 'Region',
                key: 'region',
                password: false
            },
            {
                type: 'btn',
                label: 'Get Property List',
                key: 'propertyList',
                password: false,
                noValidate: true
            },
            {
                type: 'select',
                label: 'PropertyID',
                options: 'propertyList',
                key: 'hotel_code',
                password: false
            },
            {
                type: 'btn',
                label: 'Get Agent List',
                key: 'agentList',
                password: false,
                noValidate: true
            },
            {
                type: 'select',
                label: 'AgentID',
                options: 'agentList',
                key: 'extra',
                password: false,
                noValidate: true
            }
        ],
    },
    {
        label: 'Web Hotelier',
        value: 'webhotelier',
        key: 28,
        fields: [
            {
                type: 'text',
                label: 'Property Code',
                key: 'hotel_code',
                password: false
            },
            {
                label: 'Password',
                key: 'token',
                password: false
            },
            {
                type: 'checkbox',
                label: 'Children Pricing',
                key: 'extra',
                password: false
               
            },
        ],
    },
    {
        label: 'Kognitiv',
        value: 'kognitiv',
        key: 29,
        fields: [
            {
                type: 'text',
                label: 'Property ID',
                key: 'hotel_code',
                password: false
            },
        ],
    },
    {
        label: '5stelle',
        value: '5stelle',
        key: 33,
        fields: [
            {
                type: 'text',
                label: 'Property ID',
                key: 'hotel_code',
                password: false
            },
            {
                type: 'text',
                label: 'Token',
                key: 'token',
                password: false
            },
        ],
    },
    {
        label: 'Vertical Booking',
        value: 'vertical_booking',
        key: 34,
        fields: [
            {
                type: 'text',
                label: 'Username',
                key: 'username',
                password: false
            },
            {
                type: 'text',
                label: 'Password',
                key: 'password',
                password: true
            },
            {
                type: 'text',
                label: 'Property ID',
                key: 'hotel_code',
                password: false
            },
            {
                type: 'select',
                options: 'verticalBookingList',
                label: 'Price Type',
                key: 'extra',
                password: false
            },
        ]
    },
    {
        label: 'CultSwitch',
        value: 'cultswitch',
        key: 35,
        fields: [
            {
                type: 'text',
                label: 'Property ID',
                key: 'hotel_code',
                password: false
            },
            {
                type: 'select',
                options: 'currency',
                label: 'Currency',
                key: 'currency_id',
                password: false
            },
            {
                type: 'select',
                options: 'pricingType',
                label: 'Occupancy- or Room-Based Pricing',
                key: 'extra',
                password: false
            },
        ],
    },
    {
        label: 'SiteMinder RMS API',
        value: 'siteMinder_RMS_API',
        key: 37,
        fields: [
            {
                type: 'text',
                label: 'RMS property ID',
                key: 'channel_password',
                password: false
            },
            {
                type: 'select',
                options: 'currency',
                label: 'Currency',
                key: 'currency_id',
                password: false
            },
            {
                type: 'text',
                label: 'Username',
                key: 'username',
                password: false
            },
            {
                type: 'text',
                label: 'Password',
                key: 'password',
                password: true
            },
            {
                type: 'text',
                label: 'Siteminder Property ID',
                key: 'hotel_code',
                password: false,
                noValidate: true
            },
            {
                type: 'select',
                options: 'regionList',
                label: 'Region',
                key: 'region',
                password: false
            },
        ],
    },
    {
        label: 'Semper',
        value: 'semper',
        key: 38,
        fields: [
            {
                type: 'text',
                label: 'VenueID',
                key: 'hotel_code',
                password: false
            },
        ],
    },
    {
        label: 'MappingMaster',
        value: 'mappingMaster',
        key: 39,
        fields: [
            {
                type: 'text',
                label: 'Property ID',
                key: 'hotel_code',
                password: false
            },
            {
                type: 'select',
                options: 'currency',
                label: 'Currency',
                key: 'currency_id',
                password: false
            },
            {
                type: 'select',
                options: 'pricingType',
                label: 'Occupancy or Room-Based Pricing',
                key: 'extra',
                password: false
            },
        ],
    },
    {
        label: 'ibelsa',
        value: 'ibelsa',
        key: 40,
        fields: [
            {
                type: 'text',
                label: 'Token',
                key: 'token',
                password: false
            },
            {
                type: 'text',
                label: 'Username (for optional dashboard)',
                key: 'username',
                password: false,
                noValidate: true
            },
            {
                type: 'text',
                label: 'Password (for optional dashboard)',
                key: 'password',
                password: true,
                noValidate: true
            },
            {
                type: 'select',
                options: 'currency',
                label: 'Currency',
                key: 'currency_id',
                password: false
            }
        ],
    },
    {
        label: 'RoomRaccoon',
        value: 'RoomRaccoon',
        key: 41,
        fields: [
            {
                type: 'text',
                label: 'Property ID',
                key: 'hotel_code',
                password: false
            },
        ],
    },
    {
        label: 'Guesty',
        value: 'Guesty',
        key: 42,
        fields: [
            // {
            //     type: 'text',
            //     label: 'Api Key',
            //     key: 'username',
            //     password: false
            // }, {
            //     type: 'text',
            //     label: 'Secret',
            //     key: 'password',
            //     password: true
            // },
            {
                type: 'text',
                label: 'Integration Token',
                key: 'extra',
                password: false
            },
        ],
    },
    {
        label: 'Booking.com',
        value: 'booking.com',
        key: 43,
        fields: [
            {
                type: 'text',
                label: 'HotelID',
                key: 'hotel_code',
                password: false
            },
        ],
    },
    {
        label: 'Reconline',
        value: 'Reconline',
        key: 44,
        fields: [
            {
                type: 'text',
                label: 'Hotel Code',
                key: 'hotel_code',
                password: false
            },
        ],

    },

    {
        label: 'D-edge',
        value: 'dedge',
        key: 45,
        fields: [
            {
                type: 'text',
                label: 'Property ID',
                key: 'hotel_code',
                password: false
            },
            {
                type: 'text',
                label: 'Client ID',
                key: 'username',
                password: false
            },
            {
                type: 'text',
                label: 'Client Secret',
                key: 'password',
                password: true
            },
        ],
    },
    {
        label: 'eviivo',
        value: 'eviivo',
        key: 46,
        fields: [
            {
                type: 'text',
                label: 'Client ID',
                key: 'username',
                password: false
            },
            {
                type: 'text',
                label: 'Client Secret',
                key: 'password',
                password: true
            },
            // {
            //     type: 'text',
            //     label: 'Property Code',
            //     key: 'hotel_code',
            //     password: false
            // },
            {
                type: 'btn',
                label: 'Get Property List',
                key: 'evPropertyList',
                password: false,
                noValidate: true
            },
            {
                type: 'evMultiSelect',
                label: 'PropertyID',
                options: 'evPropertyList',
                key: 'hotel_code',
                password: false
            },
        ],
    },
    {
        label: 'Ghix',
        value: 'Ghix',
        key: 47,
        fields: [
            {
                type: 'text',
                label: 'Hotel Code',
                key: 'hotel_code',
                password: false
            },
            // {
            //     type: 'text',
            //     label: 'Channel Dedicated Username',
            //     key: 'username',
            //     password: false
            // },
            // {
            //     type: 'text',
            //     label: 'Channel Dedicated Password',
            //     key: 'password',
            //     password: true
            // },
        ],
    },
    {
        label: 'OneHotel',
        value: 'OneHotel',
        key: 48,
        fields: [
            {
                type: 'text',
                label: 'Hotel Code',
                key: 'hotel_code',
                password: false
            },
        ],
    },
    {
        label: 'HLS',
        value: 'HLS',
        key: 49,
        fields: [
            {
                type: 'text',
                label: 'Client ID',
                key: 'username',
                password: false
            }, {
                type: 'text',
                label: 'Client Secret',
                key: 'password',
                password: true
            }, {
                type: 'btn',
                label: 'Get Property List',
                key: 'hlsPropertyList',
                password: false,
                noValidate: true
            }, {
                type: 'select',
                options: 'hlsPropertyList',
                label: 'PropertyID',
                key: 'hotel_code',
                password: false
            },
        ],
    },
    {
        label: 'Cubilis',
        value: 'Cubilis',
        key: 50,
        fields: [
            {
                type: 'text',
                label: 'Username',
                key: 'username',
                password: false
            }, {
                type: 'text',
                label: 'Password',
                key: 'password',
                password: true
            }
            // ,{
            //     type: 'text',
            //     label: 'Hotel Code',
            //     key: 'hotel_code',
            //     password: false
            // },
        ],
    },
    {
        label: 'Roomcloud',
        value: 'Roomcloud',
        key: 51,
        fields: [
            // {
            //     type: 'text',
            //     label: 'Username',
            //     key: 'username',
            //     password: false
            // },{
            //     type: 'text',
            //     label: 'Password',
            //     key: 'password',
            //     password: true
            // },
            {
                type: 'text',
                label: 'Hotel Code',
                key: 'hotel_code',
                password: false
            },
        ],
    },
    {
        label: 'Freetobook',
        value: 'Freetobook',
        key: 52,
        fields: [
            {
                label: 'connect with freetobook',
                url: false,
                btn: true,
                pms: 'freetobook'
            },
            // {
            //     label: 'API Key',
            //     key: 'token',
            //     password: false
            // },
            // {
            //     label: 'Refresh Key',
            //     key: 'extra',
            //     password: false
            // },
            {
                type: 'text',
                label: 'Hotel Code',
                key: 'hotel_code',
                password: false
            },
        ]
    },
    {
        label: 'Profitroom',
        value: 'Profitroom',
        key: 53,
        fields: [
            {
                type: 'text',
                label: 'Username',
                key: 'username',
                password: false
            }, {
                type: 'text',
                label: 'Password',
                key: 'password',
                password: true
            }, {
                type: 'text',
                label: 'Hotel Code',
                key: 'hotel_code',
                password: false
            }, {
                type: 'select',
                options: 'pricingType',
                label: 'Occupancy- or Room-Based Pricing',
                key: 'extra',
                password: false
            },
        ],
    },
    {
        label: 'ZimmerSoftware',
        value: 'Zimmersoftware',
        key: 54,
        fields: [
            {
                type: 'text',
                label: 'Username',
                key: 'username',
                password: false
            }, {
                type: 'text',
                label: 'Password',
                key: 'password',
                password: true
            }
        ],
    },
    {
        label: 'HotelConnect',
        value: 'HotelConnect',
        key: 57,
        fields: [
            {
                type: 'text',
                label: 'Username',
                key: 'username',
                password: false
            }, {
                type: 'text',
                label: 'Password',
                key: 'password',
                password: true
            }, {
                type: 'text',
                label: 'Property ID',
                key: 'hotel_code',
                password: false
            }
        ],
    },
    {
        label: 'Dirs21',
        value: 'Dirs21',
        key: 58,
        fields: [
            // {
            //     type: 'text',
            //     label: 'Provider Name',
            //     key: 'username',
            //     password: false
            // },{
            //     type: 'text',
            //     label: 'Provider Password',
            //     key: 'password',
            //     password: true
            // },
            {
                type: 'text',
                label: 'Property ID',
                key: 'hotel_code',
                password: false
            },
        ],
    },
    {

        label: 'HotSoft',
        value: 'HotSoft',
        key: 59,
        fields: [
            {
                type: 'text',
                label: 'Hotel ID',
                key: 'hotel_code',
                password: false
            },
            {
                type: 'btn',
                label: 'Get Account List',
                key: 'hotsoftAccountList',
                password: false,
                noValidate: true
            }, {
                type: 'select',
                options: 'hotsoftAccountList',
                label: 'Account',
                key: 'extra',
                password: false
            },
        ]
    },
    {
        label: 'Stayntouch',
        value: 'Stayntouch',
        key: 60,
        fields: [
            {
                type: 'text',
                label: 'Hotel Code',
                key: 'hotel_code',
                password: false
            },{
                type: 'text',
                label: 'Client ID',
                key: 'username',
                password: false
            },{
                type: 'text',
                label: 'Client Secret',
                key: 'password',
                password: false
            },{
                type: 'select',
                options: 'regionListStayntouch',
                label: 'Region',
                key: 'region',
                password: false
            },
        ],
    },    
    {
        label: 'OperaCloud',
        value: 'OperaCloud',
        key: 61,
        fields: [
            {
                type: 'text',
                label: 'Username',
                key: 'username',
                password: false
            },{
                type: 'text',
                label: 'Password',
                key: 'password',
                password: true
            },{
                type: 'text',
                label: 'Hotel Code',
                key: 'hotel_code',
                password: false
            },{
                label: 'Client ID',
                key: 'extra',
                password: false
            },{
                type: 'text',
                label: 'Client Secret',
                key: 'channel_password',
                password: false
            },
            {
                type: 'text',
                label: 'PMS URL',
                key: 'pms_url',
                password: false
            }, {
                type: 'select',
                options: 'versionList',
                label: 'Version',
                key: 'region',
                password: false
            },
        ],
    },
    {
        label: 'Hotelavailabilities',
        value: 'Hotelavailabilities',
        key: 62,
        fields: [
            {
                type: 'text',
                label: 'Hotel ID',
                key: 'hotel_code',
                password: false
            },
            // {
            //     type: 'text',
            //     label: 'Token',
            //     key: 'token',
            //     password: false
            // }
        ],
    },
    {
        label: 'RateTiger',
        value: 'RateTiger',
        key: 63,
        fields: [
            {
                type: 'text',
                label: 'Hotel ID',
                key: 'hotel_code',
                password: false
            },{
                type: 'text',
                label: 'Username',
                key: 'username',
                password: false
            },{
                type: 'text',
                label: 'Password',
                key: 'password',
                password: true
            },{
                type: 'text',
                label: 'Hotel Name',
                key: 'extra',
                password: false
            }
        ],
    },
    {
        label: 'SiteMinder New',
        value: 'siteminder New',
        key: 64,
        fields: [
            {
                type: 'text',
                label: 'SiteMinder Username',
                key: 'username',
                password: false
            },
            {
                type: 'text',
                label: 'SiteMinder Password',
                key: 'password',
                password: true
            },
            {
                type: 'text',
                label: 'Property ID',
                key: 'hotel_code',
                password: false,
                noValidate: true
            },
            {
                type: 'select',
                options: 'regionList',
                label: 'Region',
                key: 'region',
                password: false
            },
        ],
    },
    {
        label: 'SiteMinder RMS API New',
        value: 'siteMinder_RMS_API_New',
        key: 65,
        fields: [
            {
                type: 'text',
                label: 'RMS property ID',
                key: 'channel_password',
                password: false
            },
            {
                type: 'select',
                options: 'currency',
                label: 'Currency',
                key: 'currency_id',
                password: false
            },
            {
                type: 'text',
                label: 'Username',
                key: 'username',
                password: false
            },
            {
                type: 'text',
                label: 'Password',
                key: 'password',
                password: true
            },
            {
                type: 'text',
                label: 'Siteminder Property ID',
                key: 'hotel_code',
                password: false,
                noValidate: true
            },
            {
                type: 'select',
                options: 'regionList',
                label: 'Region',
                key: 'region',
                password: false
            },
        ],
    },
    {
        label: 'Thais',
        value: 'thais',
        key: 66,
        fields: [           
            {
                type: 'text',
                label: 'Username',
                key: 'username',
                password: false
            },
            {
                type: 'text',
                label: 'Password',
                key: 'password',
                password: true
            },
            {
                type: 'text',
                label: 'PMS URL',
                key: 'pms_url',
                password: false
            },            
        ],
    },
    {
        label: 'Update247',
        value: 'update247',
        key: 67,
        fields: [            
            {
                type: 'text',
                label: 'Username',
                key: 'username',
                password: false
            },
            {
                type: 'text',
                label: 'Password',
                key: 'password',
                password: true
            },
            {
                type: 'text',
                label: 'Update247 Property ID',
                key: 'hotel_code',
                password: false,
            },            
        ],
    },
    // {
    //     label: 'Amenitiz',
    //     value: 'amenitiz',
    //     key: 56,
    //     fields: [
    //         {
    //             type: 'text',
    //             label: 'Property ID',
    //             key: 'hotel_code',
    //             password: false
    //         },
    //     ]
    // },
    {
        label: 'Resly',
        value: 'resly',
        key: 68,
        fields: [           
            {
                type: 'text',
                label: 'Property ID',
                key: 'hotel_code',
                password: false
            },
            {
                type: 'text',
                label: 'Token',
                key: 'token',
                password: false
            },            
        ],
    },
    {
        label: 'Wincloud',
        value: 'wincloud',
        key: 69,
        fields: [
            // {
            //     type: 'select',
            //     options: 'sabeeappList',
            //     label: 'Price Type',
            //     key: 'extra',
            //     password: false
            // },    
            {
                type: 'text',
                label: 'User Name',
                key: 'username',
                password: false,
            },
            {
                type: 'text',
                label: 'Password',
                key: 'password',
                password: true,
            },
            {
                type: 'text',
                label: 'Hotel Code',
                key: 'hotel_code',
                password: false,
            },             
        ],
    },
    {
        label: 'SabeeApp',
        value: 'sabeeapp',
        key: 71,
        fields: [
            // {
            //     type: 'text',
            //     label: 'Api Key',
            //     key: 'username',
            //     password: false
            // },
            {
                type: 'select',
                options: 'sabeeappList',
                label: 'Price Type',
                key: 'extra',
                password: false
            },    
            {
                type: 'text',
                label: 'Property ID',
                key: 'hotel_code',
                password: false
            },
            // {
            //     type: 'btn',
            //     label: 'Get Property List',
            //     key: 'sabeePropertyList',
            //     password: false,
            //     noValidate: true
            // }, {
            //     type: 'select',
            //     options: 'sabeePropertyList',
            //     label: 'PropertyID',
            //     key: 'hotel_code',
            //     password: false
            // },    
        ],
    },
    {
        label: 'Cloudbeds',
        value: 'Cloudbeds',
        key: 70,
        fields: [
            {
                label: 'connect with cloudbeds',
                url: false,
                btn: true,
                pms: 'cloudbeds'
            },
            // {
            //     label: 'API Key',
            //     key: 'token',
            //     password: false
            // },
            // {
            //     label: 'Refresh Key',
            //     key: 'extra',
            //     password: false
            // },
            // {
            //     type: 'text',
            //     label: 'Hotel Code',
            //     key: 'hotel_code',
            //     password: false
            // },
            {
                type: 'btn',
                label: 'Get Property List',
                key: 'multiPropertyList',
                password: false,
                noValidate: true
            }, {
                type: 'multiSelectProperty',
                label: 'PropertyID',
                options: 'multiPropertyList',
                key: 'hotel_code',
                password: false
            },
        ]
    },
];

pmsDataArray.sort(function (a, b) {
    var x = a.label;
    var y = b.label;

    if (typeof x == "string") {
        x = ("" + x).toLowerCase();
    }
    if (typeof y == "string") {
        y = ("" + y).toLowerCase();
    }

    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
});
export default pmsDataArray;
