import PropTypes from 'prop-types';
const drawerWidth = 240;
const styles = theme => ({
    drawer: {
        zIndex:'999',
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
        ['@media (min-width: 600px)']: {
            display: "none"
        },
    },
    appBarBorder: {
        borderBottom: "1px solid #A0A2A6",
        marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    menuButton: {
        color:'#d8dae5',
        marginRight: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: {
        ...theme.mixins.toolbar,
        // minHeight: "55px",
        margin:'15px 0px 15px 0px',
        ['@media (min-width: 600px)']: {
            minHeight: "55px",
        },
    },
    drawerPaper: {
        background:'#292F4C ',
        width: drawerWidth,
    },
    content: {
        marginTop: '15px',
        flexGrow: 1,
        background:"#F5F6F8",
        width: "calc(100% - 240px)",
        padding: "5px 24px 100px 24px",
        ['@media (max-width: 600px)']: {
            marginTop: '60px',
            minWidth: "100%"
        },
    },
    pageUrl: {
        textDecoration: 'none',
        color: '#6E6F72',
    },
    siteLogo: {
        width:'99px',
        height:'74px',
        margin:'0 auto',
        // width: "216px",
        // height: "70px",
        // margin: "9px 0px 9px 10px",
        // margin: "9px 0px 9px 0px",

    },
    siteLogo1: {
        
        width: "216px",
        height: "38px",
        margin: "16px 0px 9px 10px",

    },
    profileBtn: {
        position: 'absolute',
        right: '20px',
        color: "#6E6F72",
        width:"63%"
    },
    hotelDrop: {
        // paddingTop: "5px",
        // marginRight: "5px",
        // marginLeft: "-11px",
        cursor: "pointer",
        color:'#D8DAE5'
    },
    hotelDropWithOnlyIcon: {
        marginLeft: "-5px",
        cursor: "pointer",
        color:'#D8DAE5'
    },
    selectHotel: {
        cursor: "pointer",
        // color: "#6E6F72",
        color:'#D8DAE5',
        
        marginTop: '2px',
        padding: "0px 10px 10px 10px",
        width: "200px",
        ['@media (min-width:411px)']: {
            marginTop: '4px',
            marginLeft: '10px'
        },
        
    },
    MainLoader: {
        position: 'fixed',
        height: '100%',
        width: '100%',
        zIndex: '101',
    },
    loader: {
        position: 'absolute',
        top: '48%',
        left: '48%'
    },
    TextField: {
        width: '100%'
    },
    active: {
        borderLeft: "solid 7px #3772FF",
        background:'#3e72ff63 !important',
        //  background: "#1B2247  !important",
        '&:hover': {
            // background: "#1B2247 !important",
            background:'#3e72ff63 !important'
        }
    },
    listItem1: {
        // marginTop: "5px",
    },
    listItem: {
        padding: "12px 12px 12px 12px",
        color: "#78909c",
        // borderLeft: "solid 3px #fff",
        '&:hover': {
            background: "#3e72ff63 !important",
            // background:'#1B2247'
        },
        '&:focus': {
            background: "#3e72ff63 !important",
            // background:'#1B2247'

        }
    },
    label: {
        fontSize: '12px',
    },
    warningBar: {
        padding: "0px 20px 0px 20px",
        backgroundColor: '#d32f2f'
    },
    sccessBar: {
        padding: "0px 20px 0px 20px",
        backgroundColor: '#43a047',
    },
    warningIcon: {
        margin: "-9px 5px -7px -5px",
    },
    emailText: {
        // color: "#000000de",
        color:'#D8DAE5',
        fontSize: "14px",
        padding: '12px 20px 12px 0px',
        margin: '0px 11px',
        ['@media (max-width: 600px)']: {
            margin: '0px -13px',
        }
    },
    gridHotelSelect: {
        textAlign: 'left'
    },
    list: {
        overflowX: "hidden"
    },
    exppand: {
        // color: "#777777"
        color:'#D8DAE5'
    },
    gridLng: {
        display: '-webkit-inline-box',
    },
    gridLngmenu: {
        display: 'flex',
        flexDirection: 'row-reverse',
    },
    gridContainer: {
        ['@media (max-width: 540px) and (min-width: 400px)']: {
            marginTop: '0px',
            display: 'unset'
        },
        ['@media (max-width: 399px) and (min-width: 361px)']: {
            marginTop: '0px'
        },
        ['@media (max-width: 360px) and (min-width: 321px)']: {
            marginTop: '0px'
        },
        ['@media (max-width: 320px)']: {
            marginTop: '0px'
        }
    },
    refRoomFont: {
        '& >span': {
            fontWeight: 500
        }
    },
    hotelLists: {
        
    },
    languageSelect: {
        height: "240px",
        paddingLeft: "10px",
        '&:hover': {
            background: "#fff !important",
        },
        '&:focus': {
            outlineWidth: "0"
        }
    },
    referBtn: {
        marginTop: "-8px",
        paddingRight: "40px"
    },
    referBtndiv: {
        display: "flex",
        flexDirection: "column",
        borderRight: "1px solid #e7e7e7",
        // height: "147px",
        width: "241px",
        position: "fixed",
        bottom: "0",
        // backgroundColor: "#fff"
        background:'#292F4C'
    },
    referBtndiv2: {
        display: "flex",
        flexDirection: "column",
        borderRight: "1px solid #e7e7e7",
        // height: "107px",
        width: "241px",
        position: "fixed",
        bottom: "0",
        backgroundColor: "#292F4C"
    },
    subscribeDiv: {
        marginLeft: "8px",
        padding: "7px 0px",
        textAlign: "left",
        marginLeft: "18px",
        marginTop: '-7px'
    },
    subscribeBtn: {
        backgroundColor: "#d8dae5",
        color: "#292F4C",
        "&:hover": {
            backgroundColor: "#989baf",
            color: "#101840"
        },
    },
    userIcon: {
        
        marginLeft: "6px",
        padding: "10px 6px",
        '&:hover': {
            // background: "#f0f4f8 !important",
        }
    },
    userIconColor: {
        // color: "#A0A2A6"
        color:'#d8dae5 !important'
    },
    hotelTitleList: {
        display: "flex",
        color: "#6E6F72",
        paddingTop:'9px',
        '&:hover': {
            background:'#3e72ff63',
            color:'',
        }
    },
    languageMenu: {
        marginRight: "0px",
        paddingRight: "16px",
        '&:hover': {
            background: "#00000014 !important",
            marginRight: "0px",
            paddingRight: "16px"
        }
    },
    helpIconDiv: {
        display: "flex",
        marginBottom: "10px",
        '&:hover': {
            background: "#3e72ff63 !important",
            // background: "#1B2247 !important",
            cursor: "pointer"
        }
    },
    alertSnackbar: {
        textTransform: "lowercase",
        '&::first-letter': {
            fontWeight: "bold",
            color: "red"
        }
    },
    autoFocusOff: {
        '&:focus': {
            outlineOffset: '0px',
            border: '0px',
            outline: '0px'
        },
    },
    
    helpCard:{
        // margin:'21px',
        border:'1px solid #b4b4b4 !important',
        width:'60%',
        // height:'240px',
        height:'80px',
       display:'flex',
       alignItem:'center',
       justifyContent:'center',
        '&:hover': {
            border:'2px solid #0073ea!important',
            cursor:'pointer'
            
        },
        ['@media (max-width: 960px) and (min-width: 316px)']: {
            marginBottom:'15px'
        }
    },
    iconHover :{
        display:'block',
        textAlign:'center',
        // marginLeft:'51px',
        // width:'155px',
        // '&:hover': {
            // marginLeft:'51px',
            // background:'#f1f1f1',
            // borderRadius:'70px',
            // cursor:'pointer',
            // padding:'0px 40px 0px 40px',
            // whiteSpace:'nowrap'
        //     width:'155px',
        // }
    },
    goIconHover:{
        '&:hover': {
            borderRadius:'10%',
            transition:'none !important'
        }
    },
    genieImg:{
        width:'auto',
        margin: 'auto', 
        display: 'block',
        ['@media (max-width: 959px) and (min-width: 316px)']: {
            width:'auto'
        },
        ['@media (min-width: 2500px)']: {
            width:'auto'
        }
    },
    cancelBtn:{
        background:'lightgray',
        border:'none'
    },
    whatsNew: {
        paddingLeft: '16px',
        paddingRight: '16px',
        color: '#D8DAE5',
        fontSize: '0.8571428571428571rem',
        fontWeight: '400',
        lineHeight: '1.5',
        letterSpacing: '0.00938em',

    },
    buttonProgress: {
        position: "absolute",
    },
    mainGrid:{
        justifyContent: 'center',
        display:'flex',
        '&:hover': {
            background:'#f1f1f1',
            borderRadius:'10px' 
        }
    },
    cardGrid1:{justifyContent: 'right',display:'flex',
    ['@media (max-width: 959px) and (min-width: 316px)']: {
        justifyContent: 'center',display:'flex'
    }
    },
    cardGrid2:{justifyContent: 'center',display:'flex'},
    cardGrid3:{justifyContent: 'left',display:'flex',
    ['@media (max-width: 959px) and (min-width: 316px)']: {
        justifyContent: 'center',display:'flex'
    }
    },
    siteLogoHotelLiers:{
        width:'217px',
        height:'52px',
        margin:'0 auto',
    },
    hideOnMobile:{
        ['@media (max-width: 1000px)']: {
            display: "none !important"
          },
    },
    walkthroughsText:{
        width:'50%',
        ['@media (max-width: 1000px)']: {
            width:'100%',
        }
    },
    logoLeft:{
        position: "fixed", 
        display: "flex",
        flexDirection: "column"
    },
    slideIcon:{
        zIndex: "1000",
        color: "rgb(255, 255, 255)",
        margin: "31px 0px -5px 6px",
        background: "#f5f6f8",
        // background:"#292f4c",
        height: "25px",
        // height:'29px',
        width: "25px",
        position: "fixed",
        left: "218px",
        // left:'222px',
        border:"1px solid #b7b7b7",
        '&:hover': {
            // height: "30px",
            // width: "26px",
            // background:"#292f4c",
            background:'#e3e3e3'
        }
    },
    slideLeftIcon:{
        marginRight:'-9',
        color:"#A0A2A6",
        // color:"white",
        fontSize:"20px",
        '&:hover': {
            // color:'#292f4c'
            color:"#A0A2A6",

        }
    },
    hideOnMobileIcon: {
        ['@media (max-width: 600px)']: {
          display: "none !important"
        },
      },
    smallSidebarIcon: {
        fontSize: "24px",
        marginLeft: "10px",
        color: "#D8DAE5",
        marginBottom: "10px"
    }
});

styles.propTypes = {
    theme: PropTypes.object.isRequired,
};

export default styles;
