import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../index';
import Notify from '../Notify';
import { ValidateBaseSettings } from '../../../../src/App/Utils/SettingValidation/Validation';

// const onSkipPriceListStart = () => {
//     return {
//         type: actionTypes.SKIP_PRICE_START
//     }
// }

// const onSkipPriceListFailed = (error) => {
//     return {
//         type: actionTypes.SKIP_PRICE_FAILED,
//         error: error
//     }
// }

// const onSkipPriceListSucceed = (data) => {
//     return {
//         type: actionTypes.SKIP_PRICE_SUCCEED,
//         state: data
//     }
// }

// export const skipPriceList = (request) => {
//     return dispatch => {
//         dispatch(onSkipPriceListStart())
//         axios.defaults.headers.common['Authorization'] = "Token " + request.token;
//         axios.get(Server.API + 'hotel/skip-pricing/list/')
//             .then(function (response) {
//                 dispatch(onSkipPriceListSucceed(response.data))
//             })
//             .catch(function (error) {
//                 dispatch(onSkipPriceListFailed(error))
//             });
//     }
// }


const onSkipPriceCreateFailed = (error) => {
    return {
        type: actionTypes.SKIP_PRICE_CREATE_FAILED,
        error: error
    }
}

const runPriceOnskip = (jsonSetting, date, dispatch) => {
   
    let requestParam = {
        json_settings: jsonSetting,
        is_write_to_cache: true,
        start_date: date,
        end_date: date
    }

    axios.post(Server.API + 'hotel/adjustment/individual/apply/', requestParam)
        .then((response) => {
            let err = 0;
            Object.keys(response.data.data).map(function (key) {
                if (response.data.data[key].error) {
                    err = response.data.data[key].reason;
                }
            })

            if (err != 0) {                
                dispatch(Notify.newUserModal(err, false))
            }

        })
        .catch((error) => {
            dispatch(Notify.newUserModal('Error while Applying the Changes', false))
        });
}

export const skipPriceCreate = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'hotel/pricing-settings/')
            .then(function (res) {
                let jsonData = res.data;

                if (!jsonData.dates[request.date]) {
                    jsonData.dates[request.date] = {};
                }
                jsonData.dates[request.date].skip_date = {
                    fixed_price: ((request.fixed_price === '') ? null : request.fixed_price),
                    name: request.name
                }

                let latestJson = ValidateBaseSettings({ dateSetting: jsonData });
                let reqParams = {
                    settings: JSON.stringify(latestJson.dateSetting),
                };
                axios.defaults.headers.common['Authorization'] = "Token " + request.token;
                axios.post(Server.API + 'hotel/pricing-settings/', reqParams)
                    .then(function (response) {
                        dispatch(actions.getPricingSettings(request))
                        dispatch(Notify.newUserModal("Skip Date created successfully", true))

                        // let requestParam = { dates: JSON.stringify([request.date]) };
                        // axios.post(Server.API + '/hotel/skip-pricing/apply/', requestParam)
                        
                        runPriceOnskip(reqParams.settings, request.date, dispatch)

                    })
                    .catch(function (error) {
                        dispatch(onSkipPriceCreateFailed(error))
                        dispatch(Notify.newUserModal("An error occured while creating Skip Date.", false))
                    });
            })

    }
}

const onUpdateSkipPriceFailed = (error) => {
    return {
        type: actionTypes.SKIP_PRICE_UPDATE_FAILED,
        error: error
    }
}

export const updateSkipPrice = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'hotel/pricing-settings/')
            .then(function (res) {
                let jsonData = res.data;

                if (!jsonData.dates[request.date]) {
                    jsonData.dates[request.date] = {};
                }
                jsonData.dates[request.date].skip_date = {
                    fixed_price: ((request.fixed_price === '') ? null : request.fixed_price),
                    name: request.name
                }

                let latestJson = ValidateBaseSettings({ dateSetting: jsonData });
                let reqParams = {
                    settings: JSON.stringify(latestJson.dateSetting),
                };

                axios.defaults.headers.common['Authorization'] = "Token " + request.token;
                axios.post(Server.API + 'hotel/pricing-settings/', reqParams)
                    .then(function (response) {
                        dispatch(actions.getPricingSettings(request))
                        dispatch(Notify.newUserModal("Skip Date updated successfully", true))

                        runPriceOnskip(reqParams.settings, request.date, dispatch)
                        // let requestParam = { dates: JSON.stringify([request.date]) };
                        // axios.post(Server.API + '/hotel/skip-pricing/apply/', requestParam)
                    })
                    .catch(function (error) {
                        dispatch(onUpdateSkipPriceFailed(error))
                        dispatch(Notify.newUserModal("An error occured while updating Skip Date.", false))
                    });
            })

    }
}



const onDeleteSkipPriceFailed = (error) => {
    return {
        type: actionTypes.SKIP_PRICE_DELETE_FAILED,
        error: error
    }
}

export const deleteSkipPrice = (request) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'hotel/pricing-settings/')
            .then(function (res) {
                let jsonData = res.data;
                if (jsonData.dates[request.date].skip_date) {
                    delete jsonData.dates[request.date].skip_date
                }
                let latestJson = ValidateBaseSettings({ dateSetting: jsonData });
                let reqParams = {
                    settings: JSON.stringify(latestJson.dateSetting),
                };
                axios.defaults.headers.common['Authorization'] = "Token " + request.token;
                axios.post(Server.API + 'hotel/pricing-settings/', reqParams)
                    .then(function (response) {
                        dispatch(actions.getPricingSettings(request))
                        dispatch(Notify.newUserModal("Skip Date deleted successfully", true))

                        runPriceOnskip(reqParams.settings, request.date, dispatch)
                        // let requestParam = { dates: JSON.stringify([request.date]) };
                        // axios.post(Server.API + '/hotel/skip-pricing/apply/', requestParam)
                    })
                    .catch(function (error) {
                        dispatch(onDeleteSkipPriceFailed(error))
                        dispatch(Notify.newUserModal("An error occured while deleting Skip Date.", false))
                    });
            })
    }
}


