import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = [];

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CLINET_ADMIN_GET_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.CLINET_ADMIN_GET_SUCCEED:
            return updateObject(state, { loading: false, error: null, clientAdminList: action.state })
        case actionTypes.CLINET_ADMIN_GET_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.CLINET_ADMIN_CREATE_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.CLINET_ADMIN_CREATE_SUCCEED:
            return updateObject(state, { loading: false, error: null, created: true })
        case actionTypes.CLINET_ADMIN_CREATE_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.CLINET_ADMIN_UPDATE_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.CLINET_ADMIN_UPDATE_SUCCEED:
            return updateObject(state, { loading: false, error: null, clientAdminList: action.state, updated: true })
        case actionTypes.CLINET_ADMIN_UPDATE_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.CLINET_ADMIN_DELETE_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.CLINET_ADMIN_DELETE_SUCCEED:
            return updateObject(state, { loading: false, error: null, clientAdminList: action.state, deleted: true })
        case actionTypes.CLINET_ADMIN_DELETE_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.CLINET_ADMIN_OWNER:
            return updateObject(state, { loading: false, error: action.error, owner: action.state })
        case actionTypes.PANDING_USERS_SUCCESS:
            return updateObject(state, { loading: false, error: action.error, pandingUsers: action.state.data })
        default:
            return updateObject(state, { loading: false, error: null });
    }
}

export default reducer