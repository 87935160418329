import React from 'react'
import Confetti from 'react-confetti'
import useWindowSize from 'react-use-window-size'

const ConfettiJoy = () => {
  const { width, height } = useWindowSize()

  return (
    <Confetti
      width={width}
      height={height}
      style={{zIndex:'80'}}
    />
  )
}
export default ConfettiJoy
