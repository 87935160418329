import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../shared/utility";
const initialState = {
    defaultBudget:{}
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PERFORMANCE_RESERVATION_START:
            return updateObject(state, {reservationKpiLoading: true,error: null});
        case actionTypes.PERFORMANCE_RESERVATION_SUCCESS:
            return updateObject(state, {reservationKpiLoading: false,error: null,performanceReservation: action.state.data});
        case actionTypes.PERFORMANCE_RESERVATION_FAILED:
            return updateObject(state, {reservationKpiLoading: false,error: state.error});    
        case actionTypes.UPDATE_RESERVATION_START:
            return updateObject(state, {loading: true,error: null});
        case actionTypes.UPDATE_RESERVATION_SUCCESS:
            return updateObject(state, {loading: false,error: null});
        case actionTypes.GET_PROCESS_ALL_RESERVATION_START:
            return updateObject(state, {allReservationLoading: true,error: null});
        case actionTypes.GET_PROCESS_ALL_RESERVATION_SUCCESS:
            return updateObject(state, {allReservationLoading: false,error: null});
        case actionTypes.UPDATE_PURGE_RESERVATION_START:
            return updateObject(state, {reservationLoading: true,error: null});
        case actionTypes.UPDATE_PURGE_RESERVATION_SUCCESS:
            return updateObject(state, {reservationLoading: false,error: null});
        case actionTypes.PERFORMANCE_PICKUP_START:
            return updateObject(state, {pickupLoading: true,error: null});
        case actionTypes.PERFORMANCE_PICKUP_SUCCESS:
            return updateObject(state, {pickupLoading: false,error: null,performancePickup: action.state.data});
        case actionTypes.PERFORMANCE_PICKUP_FAILED:
            return updateObject(state, {pickupLoading: false,error: state.error});   
        case actionTypes.DASHBOARD_DEFAULT_BUDGET_SUCCEED:
            return updateObject(state, {loading:false,defaultBudget:action.state.data});
        case actionTypes.ANALYTICS_DATA_SUCCESS:
            return updateObject(state, {loading:false,analyticsData:action.state.data});       
        case actionTypes.FORECAST_DATA_SUCCESS:
            return updateObject(state, {loading:false,forecastData:action.state});
        default:
            return updateObject(state, { loading: false, error: null });    
    }
};
export default reducer