import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import pmsSettingsCss from './css/pmsSettingsCss';
import Switch from '@material-ui/core/Switch';
import { connect } from "react-redux";
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import * as actions from '../../store/actions/index';

const styles = pmsSettingsCss;

class PMSSetting extends React.Component {

  state = {
    loadData: true,
    open: false,
    error: [],
    PMSsetting:{}
  };

  componentWillMount() {
    let requestParam = { token: this.props.userSession.token, }
    this.props.getPmsSetting(requestParam);
  }

  componentWillReceiveProps(props) {
    if (props.pmsSetting.pmsSetting && (this.state.PMSsetting.value !== props.pmsSetting.pmsSetting.results[0].value)) {
      this.setState({
        PMSsetting: props.pmsSetting.pmsSetting.results[0]
      })
    }
  }
  
  handlePMSSetting(event){
    let value = !(event.target.value === "0" ? 0 : 1)
    let requestParam = {
      token: this.props.userSession.token,
      id: this.state.PMSsetting.id,
      value: (value ? 1 : 0)
    }
    this.props.updatePmsSetting(requestParam)
    this.setState({...this.state.PMSsetting, value: (value ? "1" : "0")});
  }
  
  render() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader
          className="headig-top"
          title="PMS Settings">
        </CardHeader>
        <CardContent>
            <Grid container  className={classes.gridContainer}>      
            <Grid item lg={12}>
                PMS Live end point
                <Switch
                  checked={(this.state.PMSsetting.value === "1") ? true : false }
                  value={this.state.PMSsetting.value}
                  onClick={(event) => this.handlePMSSetting(event)}
                  color="primary"
                />      
            </Grid>
            </Grid>
        </CardContent>
      </Card >
    );
  }
}

PMSSetting.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        userSession: state.sessionReducer,
        pmsSetting:state.pmsSettingReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getPmsSetting: (request) => { dispatch(actions.getPmsSetting(request))},
        updatePmsSetting: (request) => { dispatch(actions.updatePmsSetting(request))}
    };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(PMSSetting);