import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import * as actions from '../../../store/actions/index';
import Notify from '../Notify';
import { ValidateBaseSettings } from '../../../../src/App/Utils/SettingValidation/Validation';

const getsettingsLogListStart = (data) => {
    return {
        type: actionTypes.GET_SETTINGS_LOG_START
    }
}

const getsettingsLogListSuccess = (data) => {
    return {
        type: actionTypes.GET_SETTINGS_LOG_SUCCESS,
        state: data
    }
}

const getsettingsLogListFailed = (error) => {
    return {
        type: actionTypes.GET_SETTINGS_LOG_FAILED,
        error: error
    }
}

export const getsettingsLogList = (request) => {
    return dispatch => {
        dispatch(getsettingsLogListStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        axios.get(Server.API + 'hotel/settings-logs/')
            .then((response) => {
                dispatch(getsettingsLogListSuccess(response.data))
            })
            .catch(function (error) {
                dispatch(getsettingsLogListFailed(error))
            });
    }
}