import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = {};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.HOTEL_DETAIL_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.HOTEL_DETAIL_SUCCEED:
            return updateObject(state, { loading: false, error: null, hoteDetail: action.state })
        case actionTypes.HOTEL_DETAIL_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.HOTEL_UPDATE_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.HOTEL_UPDATE_SUCCEED:
            return updateObject(state, { loading: false, error: null, hoteDetail: action.state })
        case actionTypes.HOTEL_UPDATE_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.UPDATE_PMS_SYNC_START:
            return updateObject(state, { loading: true, error: null })
        case actionTypes.UPDATE_PMS_SYNC_FAILED:
            return updateObject(state, { loading: false, error: action.error })
        case actionTypes.UPDATE_PMS_SYNC_SUCCEED:
            return updateObject(state, { loading: false, error: null,hoteDetail: action.state})
        case actionTypes.VALIDATE_BOOKING_URL_SUCCESS:
            return updateObject(state, { loading: false, error: true,validateBookingUrl: action.data})
        default:
            return updateObject(state, { loading: false, error: null });
    }
}

export default reducer