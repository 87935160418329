import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles';
import { Icon } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import toolTipCss from './css/toolTipCss';

class CustomTooltip extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      arrowRef: null,
    };
  }

  handleArrowRef = node => {
    this.setState({
        arrowRef: node,
    });
  };

  render() {
    const { classes, style } = this.props;
    return (
        <Tooltip
            classes={{
                popper: classes.htmlPopper,
                tooltip: classes.htmlTooltip,
            }}
            PopperProps={{
                popperOptions: {
                modifiers: {
                    arrow: {
                        enabled: Boolean(this.state.arrowRef),
                        element: this.state.arrowRef,
                    },
                },
                },
            }}
            title={
                <React.Fragment>
                    <Typography className={classes.title}>{this.props.title}</Typography>
                    {this.props.description}
                    <span className={classes.arrow} ref={this.handleArrowRef} />
                </React.Fragment>
            }
        >
            <Icon style={{color:"#A0A2A6", marginBottom:"-2px", marginLeft:"10px", ...style}} className={classes.icons}>help</Icon>
        </Tooltip>
    );
  }
}

CustomTooltip.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};  

export default compose(withStyles(toolTipCss), connect(mapStateToProps, mapDispatchToProps))(CustomTooltip);