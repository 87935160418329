import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import usersLogCss from './css/usersLogCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../store/actions/index';
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { userLogTheme } from '../muiTheme';
import { withNamespaces, Trans } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import HeaderCard from './HeaderCard';
import Grid from '@material-ui/core/Grid';

const styles = usersLogCss;

class usersLog extends React.Component {

    state = {
        error:false
    };

    componentWillMount() {
        let requestParam = {token: this.props.hotelAuthReducer.hotelDetails.token};
        this.props.getusersLogList(requestParam);
    }

    componentWillReceiveProps(props) {
        if (props.usersLogReducer.usersLog) {
            this.setState({
                usersLog: props.usersLogReducer.usersLog
            })
        }
    }

    renderusersLogData = (data) => {
        let jsx = [];
        data.map((val) => {
            jsx.push([
                val.username,
                val.email,
                val.in_datetime
            ])
            return null;
        })
        return jsx;
    }

    options = () => {
        return {
            responsive: 'scroll',
            print: false,
            download: false,
            viewColumns: false,
            responsive: "scroll",
            selectableRows: false,
            serverSide: false,
            filter:false,
            rowsPerPageOptions : false,
            count: this.state.count,
            rowsPerPage: 20,
            search:false,
            textLabels: {
                body: {
                noMatch: this.props.usersLogReducer.loading ?
                    'Loading Records...' :
                    'Sorry, no matching records found.',
                },
            }
        }
    };


    render() {
        const { classes, t } = this.props;
        const columns = [
            {
                name: "User Name",
                options: {
                    filter: false,
                    sort: true
                }
            },
            { 
                name: "Email",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: "DateTime",
                options: {
                    filter: false,
                    sort: true,
                    sortDirection: 'desc'
                }
            },
        ];
    
        return (
            <>
            <HeaderCard >
                <Grid container>
                    <Grid item xs={12} >
                    <div className={classes.divStyle} >
                        <Typography variant="h5" className={classes.fontStyle} >
                            Users Log
                        </Typography>
                    </div>
                    </Grid>
                </Grid>
            </HeaderCard>
            <Card>                
                <MuiThemeProvider theme={userLogTheme}>   
                    <CardContent>                
                        <MUIDataTable
                            title={''}
                            data={(this.state.usersLog) ? this.renderusersLogData(this.state.usersLog.lists):[]}
                            columns={columns}
                            options={this.options()}
                        />
                    </CardContent>
                </MuiThemeProvider>
            </Card>
            </>
        );
    }
}

usersLog.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        loading: state.usersLogReducer.loading,
        error: state.usersLogReducer.error,
        usersLogReducer: state.usersLogReducer,
        hotelAuthReducer: state.hotelAuthReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getusersLogList: (request) => { dispatch(actions.getusersLogList(request)) },
    };
};

export default compose(withStyles(styles, {
    withTheme: true
}), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(usersLog));
