import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import CardMedia from '@material-ui/core/CardMedia';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { LoginTheme } from './muiTheme';
import { connect } from "react-redux";
import { compose } from 'redux'
import * as actions from '../store/actions/index';
import loginCss from './css/login'
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import ErrorIcon from '@material-ui/icons/Error';
import Grid from '@material-ui/core/Grid';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from 'axios';
import Server from '../config/server';
import Fade from '@material-ui/core/Fade';
import { Typography } from '@material-ui/core';
import OtpInput from 'react-otp-input';



const styles = loginCss;

function App() {
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);

  useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60 * 5);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      console.log("expired");
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const togglerTimer = () => setRunTimer((t) => !t);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  return (
    <div className="App">
      <div>
        Time: {minutes}:{seconds}
      </div>

      <button type="button" onClick={togglerTimer}>
        {runTimer ? "Stop" : "Start"}
      </button>
    </div>
  );
}

class SignIn extends React.Component {

  state = {
    email: '',
    password: '',
    formLogin: true,
    warning: false,
    isReset: false,
    resetMail: '',
    showForgotMsg: false,
    bookingData: {},
    selectedHotel: null,
    otp: '',
    is_rpg_user_or_partner: false,
    isDisableResendBtn: true,
    is_valid_otp: true,
    otp_error_message: '',
    freetoBookLoader:false,
    is_apaleo_connect: false,
    is_cloudbeds_connect: false
  }

  componentDidMount() {
    document.title = "Kanhasoft App Login"
    var url_string = window.location.href;
    var url = new URL(url_string);
    var code = url.searchParams.get("code");
    var init = url.searchParams.get("init");
    var state = url.searchParams.get("state"); // for apaleo

    var scope = url.searchParams.get("state"); // for apaleo
    // var session_state = url.searchParams.get("state"); // for apaleo

    if (scope && state == "apaleo") {

      this.setState({
        bookingLoad: true
      })

      axios.post(Server.API + 'account/apaleo/properties/', { code: code })
        .then((response) => {
          this.setState({
            bookingData: response.data,
            seekomToken: response.data.token,
            bookingLoad: false,
            showBooking: true,
            is_apaleo_connect: true
          })
        })
        .catch((error) => {
          this.setState({
            bookingLoad: false,
            bookingError: true,
            bookingErrorMsg: "Sorry, We can not connect to your booking properties!",
            is_apaleo_connect: true
          })
        });

    } else if (code && init == "seekom") {

      this.setState({
        bookingLoad: true
      })
      axios.post(Server.API + 'account/seekom/properties/', { code: code })
        .then((response) => {
          this.setState({
            bookingData: response.data,
            seekomToken: response.data.token,
            bookingLoad: false,
            showBooking: true
          })
        })
        .catch((error) => {
          this.setState({
            bookingLoad: false,
            bookingError: true,
            bookingErrorMsg: "Sorry, We can not connect to your booking properties!"
          })
        });

    }else if(code && state.includes("freetobook")) {
      // debugger
      this.setState({
        bookingLoad: true,
        freetoBookLoader:true
      })
      axios.post(Server.API + 'account/freetobook/auth/', { code: code })
        .then((response) => {          
          this.props.freeToBookLogin(response);
          this.setState({
            freetoBookLoader:false,
            // bookingData: response.data,
            // freetobookToken: response.data.token,
            // bookingLoad: false,
            // showBooking: false
          })

        })
        .catch((error) => {
          this.setState({
            // bookingLoad: false,
            bookingError: true,
            bookingErrorMsg: "Sorry, We can not connect to your Freetobook properties!",
            freetoBookLoader:false,
          })
        });
    }else if(code && state.includes("cloudbeds")) {
      console.log('code: ', code)
      // debugger
      this.setState({
        bookingLoad: true,
      })
      axios.post(Server.API + 'account/integrations/oauth2/', { code: code, provider:70 })
        .then((response) => {
          console.log("response: ", response)
          this.setState({
            bookingData: response.data,
            seekomToken: response.data.token,
            bookingLoad: false,
            showBooking: true,
            is_cloudbeds_connect: true
          })

          console.log("resss", this.state.bookingData.property_list)
        })
        .catch((error) => {
          this.setState({
            bookingLoad: false,
            bookingError: true,
            bookingErrorMsg: "Sorry, We can not connect to your booking properties!",
            is_cloudbeds_connect: true
          })
        });
    }
  }

  toggleBookingError = () => {
    this.setState({
      bookingError: false,
      bookingErrorMsg: ""
    })
  }


  // bookingData: {
  //   "user_existing_hotel_id": [
  //     "4199719",
  //     "41997120",
  //     "4199721"
  //   ],
  //   "create_hotel_id": [
  //     "4199718",
  //     "4199725",
  //     "4199726",
  //     "4199727"
  //   ],
  //   "client": 811
  // }

  componentWillReceiveProps(props) {
    if (props.otpStatus.VerifyOTP && this.state.otp != "") {
      this.setState({
        is_valid_otp: props.otpStatus.VerifyOTP.success,
        otp_error_message: props.otpStatus.VerifyOTP.message
      })
    }
    

    // if (props.user && (props.user.user.is_staff || props.user.user.is_superuser) && props.user.is_otp_required) {
    if (props.user && (props.user.user.is_staff || props.user.user.is_superuser) && props.otpStatus.is_otp_required) {
      this.setState({
        is_rpg_user_or_partner: true,
        token: props.user.token
      })
    }
    // if (props.accountReducer.RecoverSuccess) {
    //   this.toggleWarning();
    //   // this.setState({
    //   //   isReset: false,
    //   // })

    //   // this.setState({
    //   //   showForgotMsg: false
    //   // })
    // }
    if (!props.accountReducer.RecoverSuccess && props.accountReducer.error) {
      this.setState({
        recoveryError: true
      })
    } else {
      this.setState({
        recoveryError: false
      })
    }

  }

  loginWithBooking = () => {
    if (this.state.selectedHotel) {
      let data = {
        hotel_id: this.state.selectedHotel,
        client: this.state.bookingData.client
      }
      this.props.bookinAuth(data)
      this.setState({
        errorBooking: false
      })
    } else {
      this.setState({
        errorBooking: true
      })
    }
  }

  loginWithBookingDefault = () => {
    if (this.state.bookingData.user_existing_hotel_id && this.state.bookingData.user_existing_hotel_id[0]) {
      let data = {
        hotel_id: this.state.bookingData.user_existing_hotel_id[0],
        client: this.state.bookingData.client
      }
      this.props.bookinAuth(data)
    }
  }


  loginWithSeekom = () => {
    if (this.state.selectedHotel) {
      let data = {
        token: this.state.seekomToken,
        property_id: this.state.selectedHotel
      }

      this.props.seekomAuth(data);
      this.setState({
        errorBooking: false
      })
    } else {
      this.setState({
        errorBooking: true
      })
    }
  }

  loginWithApaleo = () => {
    if (this.state.selectedHotel) {
      let data = {
        token: this.state.seekomToken,
        property_id: this.state.selectedHotel,
        refresh_token: this.state.bookingData.refresh_token
      }

      if (this.state.is_apaleo_connect){
        this.props.apaleoAuth(data);
      } else if(this.state.is_cloudbeds_connect){
        data["provider"] = 70
        this.props.oAuth2Call(data);
      }

      this.setState({
        errorBooking: false
      })
    } else {
      this.setState({
        errorBooking: true
      })
    }
  }

  doLogin = () => {

    this.toggleWarning();
    // let request = { email: 'tukunang.hendrik@gmail.com', password: '1234567' };
    let request = { email: this.state.email, password: this.state.password };
    this.props.onLogin(request);
    setTimeout(() => {
      this.setState({
        isDisableResendBtn: false,
      })
    }, 30000)
  }

  clearOtp = () => {
    this.setState({ otp: '', is_valid_otp:true });
  };

  resendOTP = () => {
    this.toggleWarning();
    let request = { token: this.state.token };
    this.props.resendOTP(request);
    this.clearOtp();
    this.setState({
      isDisableResendBtn: true,
    })
    setTimeout(() => {
      this.setState({
        isDisableResendBtn: false,
        is_valid_otp:true
      })
    }, 30000)
  }

  toggleForm = () => {
    this.setState({
      formLogin: !this.state.formLogin,
    })
  }

  toggleWarning = () => {
    this.setState({
      warning: !this.state.warning,
    })
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  resetPasword = () => {
    this.setState({
      isReset: !this.state.isReset
    })
  }
  reset = () => {
    let requestParams = { email: this.state.resetMail }
    this.props.recoverPassword(requestParams);
    this.setState({
      showForgotMsg: true
    })
  }
  connectWithPms = () =>{
   this.setState({
     connectPms:true
   })   
  }
  backToLogin = () =>{
    this.setState({
      connectPms:false
    })   
   }


  handleOtpChange = (otp) => {
    this.setState({
      otp: otp
    });
    let request = { token: this.state.token, otp: otp.toUpperCase() };
    if (otp.length == 6) {
      this.setState({
        is_valid_otp: true
      })
      this.props.verifyOTP(request);
    }
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter' && !this.props.loading && !this.state.bookingLoad){
      this.doLogin()
    }
  }

  handleKeyPressReset = (event) => {
    if(event.key === 'Enter' && !this.props.loading && !this.state.bookingLoad){
      this.reset()
    }
  }

  render() {
    const { classes } = this.props;

    let url_string = window.location.href;
    let url = new URL(url_string);
    let code = url.searchParams.get("code");
    var init = url.searchParams.get("init");
    var state = url.searchParams.get("state"); // for apaleo
    var scope = url.searchParams.get("state"); // for apaleo

    if (scope && this.state.showBooking && (state == "apaleo" || state.includes("cloudbeds"))) {
      return (
        <MuiThemeProvider theme={LoginTheme}>
          <Grid container style={{height:'100vh'}}>
            <Grid  lg={6} sm={12} className={classes.loginLogoCard}>
              <CardMedia
                className={classes.siteLogo1}
                image="/img/roompricegenie_logo_new.svg"
                title="Kanhasoft"
                style={{backgroundSize:"100%"}}
              />
            </Grid>
          
            <Grid  lg={6} sm={12} className={classes.loginFormCard}>
                {/* <Grid sm={12} md={12}> */}
                <div style={{padding:'40px 25px 40px 25px',border:'1px solid #f2f2f2',borderRadius:'10px',background:'white',width:'80%'}}>
              <main className={[classes.main2, classes.boxContainer]}>
              <Typography variant="h5" align={"left"} style={{ fontWeight: "500", marginTop: "24px"}}>Hi, there!</Typography>
              <br />
              <Typography align={"left"} style={{ fontSize: "1rem", marginBottom: 12 }}>
                We noticed that you have multiple properties. Please select the property you want to set up now. If you want to set up more properties, please use the SSO button again and set them up one by one. Thank you very much!
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup aria-label="gender" name="gender1" value={this.state.selectedHotel} onChange={this.handleChange("selectedHotel")}>
                  {this.state.bookingData.property_list && this.state.bookingData.property_list.map((val) => {
                    return (
                      <FormControlLabel value={val.property_code} control={<Radio color={"primary"} />} label={<Typography align={"left"} style={{ fontSize: "1rem" }}>{val.property_name}</Typography>} />
                    )
                  })}
                </RadioGroup>
              </FormControl>
              <div >
                <Typography className={classes.warningbooking}>{this.state.errorBooking ? ("Please select a property") : ""}</Typography>
              </div>
              <div style={{ textAlign: "right" }}>
                <Button
                  type="button"
                  disabled={this.props.loading}
                  variant="contained"
                  color="primary"
                  className={classes.bookingBtn}
                  onClick={() => this.loginWithApaleo()}
                  style={{ float: "unset" }}
                >
                  Create Property
                  {this.props.loading && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                </Button>
              </div>
            </main>
          {/* </div> */}
          </div>
          </Grid>
        </Grid>
        </MuiThemeProvider >

      )
    } else if (code && this.state.showBooking && init != "seekom") {
      return (
        <MuiThemeProvider theme={LoginTheme}>
          <Grid container style={{height:'100vh'}}>
            <Grid  lg={6} sm={12} className={classes.loginLogoCard}>
              <CardMedia
                className={classes.siteLogo1}
                image="/img/roompricegenie_logo_new.svg"
                title="Kanhasoft"
                style={{backgroundSize:"100%"}}
              />
            </Grid>
          
            <Grid  lg={6} sm={12} className={classes.loginFormCard}>
              <div style={{padding:'40px 25px 40px 25px',border:'1px solid #f2f2f2',borderRadius:'10px',background:'white',width:'80%'}}>
                <main className={[classes.main2, classes.boxContainer]}>
              <Typography variant="h5" align={"left"} style={{ fontWeight: "500", marginTop: "24px" }}>Hi, there!</Typography>
              <br />
              <Typography align={"left"} style={{ fontSize: "1rem", marginBottom: 12 }}>
                We noticed that you have multiple properties. Please select the property you want to set up now. If you want to set up more properties, please use the SSO button again and set them up one by one. Thank you very much!
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup aria-label="gender" name="gender1" value={this.state.selectedHotel} onChange={this.handleChange("selectedHotel")}>
                  {this.state.bookingData.create_hotel_id && this.state.bookingData.create_hotel_id.map((val) => {
                    return (
                      <FormControlLabel value={val} control={<Radio color={"primary"} />} label={<Typography align={"left"} style={{ fontSize: "1rem" }}>{val}</Typography>} />
                    )
                  })}
                </RadioGroup>
              </FormControl>
              <div >
                <Typography className={classes.warningbooking}>{this.state.errorBooking ? ("Please select a property") : ""}</Typography>
              </div>
              <div style={{ textAlign: "right" }}>

                <Button
                  type="button"
                  disabled={this.props.loading}
                  variant="contained"
                  color="primary"
                  className={classes.bookingBtn}
                  onClick={() => this.loginWithBooking()}
                  style={{ float: "unset" }}
                >
                  Create Property
                  {this.props.loading && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                </Button>
                {this.state.bookingData && this.state.bookingData.user_existing_hotel_id && this.state.bookingData.user_existing_hotel_id.length > 0 &&
                  <Button
                    type="button"
                    disabled={this.props.loading}
                    variant="outlined"
                    color="primary"
                    className={classes.bookingBtn}
                    onClick={() => this.loginWithBookingDefault()}
                    style={{ float: "unset" }}
                  >
                    Skip and go to app
                  </Button>
                }
              </div>
            </main>
          </div>
          </Grid>
        </Grid>
        </MuiThemeProvider >
      )
    } else if (code && this.state.showBooking && init == "seekom") {
      return (
        <MuiThemeProvider theme={LoginTheme}>
          <Grid container style={{height:'100vh'}}>
            <Grid  lg={6} sm={12} className={classes.loginLogoCard}>
              <CardMedia
                className={classes.siteLogo1}
                image="/img/roompricegenie_logo_new.svg"
                title="Kanhasoft"
                style={{backgroundSize:"100%"}}
              />
            </Grid>
          
            <Grid  lg={6} sm={12} className={classes.loginFormCard}>
              <div style={{padding:'40px 25px 40px 25px',border:'1px solid #f2f2f2',borderRadius:'10px',background:'white',width:'80%'}}>
            <main className={[classes.main2, classes.boxContainer]}>
              <Typography variant="h5" align={"left"} style={{ fontWeight: "500", marginTop: "24px" }}>Hi, there!</Typography>
              <br />
              <Typography align={"left"} style={{ fontSize: "1rem", marginBottom: 12 }}>
                We noticed that you have multiple properties. Please select the property you want to set up now. If you want to set up more properties, please use the SSO button again and set them up one by one. Thank you very much!
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup aria-label="gender" name="gender1" value={this.state.selectedHotel} onChange={this.handleChange("selectedHotel")}>
                  {this.state.bookingData.property_list && this.state.bookingData.property_list.map((val) => {
                    return (
                      <FormControlLabel value={val.property_code} control={<Radio color={"primary"} />} label={<Typography align={"left"} style={{ fontSize: "1rem" }}>{val.property_name}</Typography>} />
                    )
                  })}
                </RadioGroup>
              </FormControl>
              <div >
                <Typography className={classes.warningbooking}>{this.state.errorBooking ? ("Please select a property") : ""}</Typography>
              </div>
              <div style={{ textAlign: "right" }}>
                <Button
                  type="button"
                  disabled={this.props.loading}
                  variant="contained"
                  color="primary"
                  className={classes.bookingBtn}
                  onClick={() => this.loginWithSeekom()}
                  style={{ float: "unset" }}
                >
                  Create Property
                  {this.props.loading && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                </Button>
              </div>
            </main>
          </div>
        </Grid>
        </Grid>
        </MuiThemeProvider >
      )
    }
    return (
      <MuiThemeProvider theme={LoginTheme}>
        <Grid container className={classes.pageContainer} style={{height:'100vh'}}>
              <Grid  lg={6} sm={12} className={classes.loginLogoCard}>
                  <CardMedia
                    className={classes.siteLogo1}
                    image="/img/roompricegenie_logo_new.svg"
                    title="Kanhasoft"
                    style={{backgroundSize:"100%"}}
                  />
                  {/* <div style={{height:'calc(100vh - 105px)',position:'absolute'}}>
                    <img src="/img/login_animation.gif" style={{width:'100%',height:'100%',display:'block'}}  />
                  </div> */}
              </Grid>
              <Grid  lg={6} sm={12} className={classes.loginFormCard}>
                {/* <Grid sm={12} md={12}> */}
                <div className={classes.cardFormDiv}>
                {!this.state.isReset &&  !this.state.connectPms && !this.state.is_rpg_user_or_partner &&
                  <form className={classes.form} style={{ padding: "0px 42px 16px", marginBottom:"-8px" }}>
                    <Typography style={{fontSize:'1.5rem',textAlign:'left',fontWeight:'500',fontFamily:'roboto'}}>
                    Log In
                    </Typography>
                    <FormControl margin="normal" required fullWidth>
                      <InputLabel shrink={true} htmlFor="email">Email</InputLabel>
                      <Input id="email" name="email" onKeyPress={this.handleKeyPress}  onChange={this.handleChange('email')} autoComplete="email" autoFocus />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                      <InputLabel shrink={true} htmlFor="password">Password</InputLabel>
                      <Input name="password" type="password" onKeyPress={this.handleKeyPress} onChange={this.handleChange('password')} id="password" autoComplete="current-password" />
                    </FormControl>
                    {/* <Typography className={classes.extraBtn} color="primary" align={"right"}>
                      <a
                        href="https://roompricegenie.com/sign-up"
                        style={{ textDecoration: "none", color: "#3f72ff" }}
                      >
                        Register Now!
                      </a>
                  </Typography> */}
 
                {/* <Typography

                    <Typography
                      color="primary"
                      className={classes.forgetBtn}
                      onClick={() => this.resetPasword()}
                      align={"right"}
                    >
                      Need a new password?
                    </Typography>
                    {/* <Typography
                  color="primary"
                  className={classes.forgetBtn}
                  
                  align={"right"}
                >
                  <a
                    href="https://roompricegenie.com/sign-up"
                    style={{ textDecoration: "none", color: "#3f72ff" }}
                  >
                    Don't have an account?
                  </a>
                </Typography> */}
                <div className={classes.extraAccBtn}>
                    <Typography
                    color="primary"
                    className={classes.forgetBtn}
                    onClick={() => this.resetPasword()}
                    align={"left"}
                    >
                      Need a new password?
                    </Typography>

                      <a
                        href="https://roompricegenie.com/sign-up/"
                      >
                        <Typography
                          color="primary"
                          className={classes.accountBtn}
                          align={"right"}
                        >
                          {/* <Typography className={classes.extraBtn} color="primary" align={"right"}> */}
                          {/* <a
                        href="https://roompricegenie.com/sign-up"
                        style={{ textDecoration: "none", color: "#3f72ff" }}
                      > */}
                          Don't have an account?
                          {/* </a> */}
                        </Typography>
                      </a>
                    </div>
                  <Button
                      type="button"
                      disabled={this.props.loading || this.state.bookingLoad || this.state.freetoBookLoader}
                      variant="contained"
                      color="primary"
                      className={classes.LoginBtn}
                      // style={{marginTop:'28px'}}
                      onClick={() => this.doLogin()}
                      fullWidth
                    >
                    {this.state.freetoBookLoader && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                     Log In
                  </Button>
                  
                   <Button
                   type="button"
                   disabled={this.props.loading || this.state.bookingLoad}
                   variant="outlined"
                   className={[classes.LoginBtn]}
                   style={{ background: "#363941", color: "#fff", fontSize: "15px", fontWeight: "400", textTransform: "inherit",  borderRadius: "4px" }}
                   onClick={()=>this.connectWithPms()}
                   fullWidth 
                 >
                   Connect with <b style={{ marginLeft: "4px" }}>SSO</b>
                 </Button> 
                  
                
                    
                    {this.props.error && <Snackbar
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                      onClose={this.toggleWarning}
                      open={this.state.warning}
                      TransitionComponent={Fade}
                      ContentProps={{
                        classes: {
                          root: classes.warningBar
                        }
                      }}
                      message={<span><ErrorIcon className={classes.warningIcon}></ErrorIcon> {this.props.error}</span>}
                      variant="error"
                    />}

                  </form>
                }
                {this.state.isReset && !(this.props.accountReducer.RecoverSuccess && this.state.showForgotMsg) && !this.state.is_rpg_user_or_partner &&
                  <div style={{ padding: "10px 16px 16px 16px" }}>
                    <Typography style={{fontSize:'1.5rem',textAlign:'left',fontWeight:'500',fontFamily:'roboto'}}>
                      {(this.props.accountReducer.RecoverSuccess && this.state.showForgotMsg) ? "Check your inbox" : "Need a new password?"}  
                    </Typography>
                    <Typography align={"left"} style={{marginTop:'10px'}}>
                      Please provide your email address and we'll send you instructions on how to change your password.
                    </Typography>
                    <FormControl margin="normal" required fullWidth>
                      <InputLabel htmlFor="email" shrink={true}>Email Address</InputLabel>
                      <Input id="email" name="email" onKeyPress={this.handleKeyPressReset} onChange={this.handleChange('resetMail')} autoComplete="email" autoFocus />
                    </FormControl>

                    
                    {this.state.recoveryError &&
                      <Typography align={"left"} style={{ color: "red" }}>An account with this email doesn't exist.</Typography>
                    }


                    {!(this.props.accountReducer.RecoverSuccess && this.state.showForgotMsg) &&
                      
                    <div>
                      <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
                        <Grid container item spacing={3} xs={12} sm={4} md={4} lg={6}
                          style={{margin:"5px 0px", maxWidth:"155px"}} >
                          <Button
                            type="button"
                            // className={classes.submit}
                            onClick={() => this.reset()}
                            variant="contained"
                            color="primary"
                            style={{float:"left",width:"150px"}}
                          >
                            Reset Password
                          </Button>
                        </Grid>
                        <Grid container item spacing={3} xs={12} sm={4} md={4} lg={6}
                          style={{margin:"5px 0px", maxWidth:"155px"}} >
                          <Button
                            className={["cancelBtn"]}
                            
                            onClick={() => this.resetPasword()}
                            variant="outlined"
                            type="button"
                            color="primary"
                            style={{float:"left",width:"150px"}}
                          >
                            Go Back to Login
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                    }
                    


                    {this.props.accountReducer.error && <Snackbar
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                      onClose={this.toggleWarning}
                      open={this.state.warning}
                      TransitionComponent={Fade}
                      ContentProps={{
                        classes: {
                          root: classes.warningBar
                        }
                      }}
                      message={<span><ErrorIcon className={classes.warningIcon}></ErrorIcon>Invalid Email</span>}
                      variant="error"
                    />}
                  </div>
                }
                {this.props.accountReducer.RecoverSuccess && this.state.showForgotMsg && this.state.isReset &&
                  <>
                  <Typography align={"left"} style={{ padding: "16px" }}>Thank you, an email with further instructions was sent to <b>{this.state.resetMail}</b>. If you don’t receive a message in 5 minutes, please check the junk folder. If you are still experiencing any problems, contact us using the chat button at the bottom right.</Typography>
                  <Button
                          className={["cancelBtn"]}
                          
                          onClick={() => this.resetPasword()}
                          variant="outlined"
                          type="button"
                          color="primary"
                          style={{float:"left",marginLeft:'17px'}}
                        >
                          Go Back to Login
                    </Button></>
                }



                {this.props.accountReducer.RecoverSuccess && this.state.showForgotMsg && <Snackbar
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  onClose={this.toggleWarning}
                  open={this.state.warning}
                  TransitionComponent={Fade}
                  ContentProps={{
                    classes: {
                      root: classes.sccessBar
                    }
                  }}
                  message={
                    <span style={{ display: 'inline-flex' }}>
                      <div><CheckCircle className={classes.warningIcon}></CheckCircle></div>
                      <div>An email has been sent to <strong>{this.state.resetMail}</strong>.<br /> If you don't see it in a few minutes please check your Junk mailbox.</div>
                    </span>}
                />}
                {this.state.connectPms &&
                <>
                  <Typography style={{fontSize:'1.5rem',textAlign:'left',fontWeight:'500',fontFamily:'roboto'}}>
                      Connect with SSO
                  </Typography>
                  <Button
                    type="button"
                    disabled={this.props.loading || this.state.bookingLoad}
                    variant="contained"
                    className={classes.LoginBtn}
                    href={process.env.REACT_APP_SSO_FREETOBOOK}
                    fullWidth
                    style={{ background: "#f4a631",border:'1px solid #f4a631',color: "#fff", fontSize: "15px", fontWeight: "400", textTransform: "inherit", borderRadius: "4px" }}
                  >
                    Connect with <b style={{ marginLeft: "4px" }}>freetobook</b>
                  </Button>
                  <Button
                    type="button"
                    disabled={this.props.loading || this.state.bookingLoad}
                    variant="contained"
                    className={classes.LoginBtn}
                    style={{ background: "#84bc37", color: "#fff",border:'1px solid #84bc37', fontSize: "15px", fontWeight: "400", textTransform: "inherit",  borderRadius: "4px"}}
                    href={process.env.REACT_APP_SSO_SEEKOM}
                    fullWidth 
                  > 
                    Connect with <b style={{ marginLeft: "4px" }}>Seekom</b>
                  </Button>
                  <Button
                    type="button"
                    disabled={this.props.loading || this.state.bookingLoad}
                    variant="contained"
                    className={classes.LoginBtn}
                    href={process.env.REACT_APP_SSO_APALEO}
                    style={{ background: "#363941", color: "#fff",border:'1px solid #363941', fontSize: "15px", fontWeight: "400", textTransform: "inherit",  borderRadius: "4px"}}
                    fullWidth>
                    Connect with <b style={{ marginLeft: "4px" }}>apaleo</b>
                  </Button>
                  <Button
                    type="button"
                    disabled={this.props.loading || this.state.bookingLoad}
                    variant="contained"
                    className={classes.LoginBtn}
                    href={process.env.REACT_APP_SSO_CLOUDBEDS}
                    // href="https://hotels.cloudbeds.com/api/v1.1/oauth?client_id=roompricegenie_dev_Ykgw6UyZehIzWR7P09FdvXur&redirect_uri=http://localhost:3000&response_type=code&scope=read:rate read:reservation read:room read:user read:hotel write:rate&state=http://localhost:3000/?init=cloudbeds"
                    style={{ background: "#08c", color: "#fff",border:'1px solid #08c', fontSize: "15px", fontWeight: "400", textTransform: "inherit",  borderRadius: "4px"}}
                    fullWidth>
                    Connect with <b style={{ marginLeft: "4px" }}>Cloudbeds</b>
                  </Button>
                  
                  <div className={classes.extraAccBtn}>
                    <Button
                    className={["cancelBtn"]}
                    color="primary"
                    variant='outlined'
                    style={{marginTop:'20px'}}
                    onClick={() => this.backToLogin()}
                    align={"left"}
                    fullWidth
                  >
                      Go Back to Login
                </Button>
                </div>
                  </>
                  }
                
                {this.state.is_rpg_user_or_partner &&
                  <Grid>
                    <form className={classes.otpForm}>
                      <Typography className={classes.otpTitle}>
                        Please enter the verification code
                      </Typography>
                      <Typography shrink={true} htmlFor="otp" className={classes.otpEmailText} style={{}}>An email with a verification code was sent to your email <a href="#">{this.state.email}</a></Typography>
                      <FormControl margin="normal" required fullWidth style={{ marginLeft: '-10px' }}>
                        <OtpInput
                          className='otp-input'
                          value={this.state.otp.toUpperCase()}
                          onChange={this.handleOtpChange}
                          numInputs={6}
                          separator={
                            <span>
                              <strong>-</strong>
                            </span>
                          }
                          focusStyle={{
                            borderRadius: 0,
                            border: "2px solid rgba(0,0,0,0.3)"
                          }}
                          inputStyle={{
                            width: "2.5rem",
                            height: "2.5rem",
                            margin: "0px 0.2rem",
                            fontSize: "1.2rem",
                            borderRadius: 0,
                            border: "2px solid rgba(0,0,0,0.3)"
                          }}
                          hasErrored={this.state.is_valid_otp ? false : true}
                          errorStyle={{
                            border: "2px solid red"
                          }}
                        />
                      </FormControl>

                      {/* <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          className={classes.LoginBtn}
                          onClick={() => this.doLogin()}
                          style={{marginRight: "20px"}}
                        >
                        Verify
                      </Button> */}
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        className={classes.LoginBtn}
                        onClick={() => this.resendOTP()}
                        disabled={this.state.isDisableResendBtn}
                        style={{float:'left', marginTop:'12px'}}
                      >
                        Resend Email
                      </Button>
                    </form>
                  </Grid>
                }
                </div>
              {/* </Grid> */}

              </Grid>
              
        </Grid>
        

      </MuiThemeProvider >
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  container: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    user: state.authReducer.UserData,
    otpStatus: state.authReducer,
    accountReducer: state.accountReducer,
    commonReducer: state.commonReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogin: (request) => { dispatch(actions.onLogin(request)) },
    resendOTP: (request) => { dispatch(actions.resendOTP(request)) },
    verifyOTP: (request) => { dispatch(actions.verifyOTP(request)) },
    recoverPassword: (request) => { dispatch(actions.recoverPassword(request)) },
    bookinAuth: (request) => { dispatch(actions.bookinAuth(request)) },
    seekomAuth: (request) => { dispatch(actions.seekomAuth(request)) },
    apaleoAuth: (request) => { dispatch(actions.apaleoAuth(request)) },
    oAuth2Call: (request) => { dispatch(actions.oAuth2Call(request)) },
    freeToBookLogin: (request) => { dispatch(actions.freeToBookLogin(request)) },
  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(SignIn);
