import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';

const onClientListStart = () => {
    return {
        type: actionTypes.CLIENT_LIST_START
    }
}

const onClientListFailed = (error) => {
    return {
        type: actionTypes.CLIENT_LIST_FAILED,
        error: error
    }
}

const onClientListSucceed = (data) => {
    return {
        type: actionTypes.CLIENT_LIST_SUCCEED,
        state: data
    }
}

export const getClientList = (request) => {
    return dispatch => {
        dispatch(onClientListStart())
        axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        let url = (request.url ? (Server.API + request.url) : (Server.API + 'hotel/client/list/?page=1'));
        axios.get(url)
            .then(function (response) {
                dispatch(onClientListSucceed(response.data))
            })
            .catch(function (error) {
                dispatch(onClientListFailed(error))
            });
    }
}

export const downloadCsvClientList = (request) => {
    return dispatch => {
        axios.get(request.url)
    }
    
}