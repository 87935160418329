import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import freeTrialRunningOutCss from './css/freeTrialRunningOutCss';
import { withNamespaces } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { freeTrialExpiredOutTheme } from "../../../muiTheme";
import MUIDataTable from "mui-datatables";
const styles = freeTrialRunningOutCss;

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#00000059",
    color: "#fff",
    padding: "4px 20px 4px 15px"
  },
  body: {
    fontSize: 14,
    padding: "4px 20px 4px 15px"
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

class FreetrialRecords extends React.Component {
  state={
    freetrialRunningOutList:[],
  }
  componentWillReceiveProps(props) {
    if(props.free_trial_running_out){
      this.setState({
        freetrialRunningOutList:props.free_trial_running_out,
      })
    }
  }

  options = () => {
    return {
        filter: false,
        responsive: 'scroll',
        print: false,
        download: false,
        viewColumns: false,
        responsive: "scroll",
        selectableRows: false,
        rowsPerPage: 10,
        onRowClick: (rowData) => {
          let id = rowData[0];
          window.open("client/Calendar/" + id,'_blank');
      },
    }
  };

  renderFreeTrialRunningOutData=(data)=>{
    let jsx = [];
        data.map((row) => {
            jsx.push([
              row.id,
              row.name+'('+row.id+')' ,
              row.client__free_trial_end === '' || row.client__free_trial_end === null ? 'n/a' : row.client__free_trial_end,
              row.in_days === '' || row.in_days === null ? 'n/a' : row.in_days+'d',
              
            ])
            return null;
        })
        return jsx;
  }
  render() {
    const { classes, t } = this.props;  
    const columns = [
      {
        name: "id" ,
        options: {
          display:false
        }
      },
      {
        name: "Hotel" ,
      },
      {
        name: "End Date" ,
      },
      {
        name: "Ending in days" ,
      },
    ]  
  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="subtitle1" variant="subtitle1" align={"left"} className={classes.titleBottom}>
            Trial Running Out <span className={classes.tabletitle}>Total: {this.props.free_trial_running_out_count}</span>
          </Typography>
          <MuiThemeProvider theme={freeTrialExpiredOutTheme}>
              <MUIDataTable
                  title={''}
                  data={this.renderFreeTrialRunningOutData(this.state.freetrialRunningOutList)}
                  columns={columns}
                  options={this.options()}
              />
            </MuiThemeProvider>
        </CardContent>
      </div>
    </Card>
  );

  }
}

const mapStateToProps = (state) => {
  return {
    userSession: state.sessionReducer,
    hotelAuthReducer: state.hotelAuthReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default compose(withStyles(styles, {
  withTheme: true
}), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(FreetrialRecords));
