import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import adjustmentCss from '../css/modal/adjustmentCss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../../store/actions/index';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { monthlySettingTheme } from '../../muiTheme';
import { withNamespaces, Trans } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { ValidateBaseSettings } from '../../Utils/SettingValidation/Validation';
import Server from '../../../config/server';
import CustomTooltip from '../../Utils/toolTip';
import Warning from '../warning';
import HeaderCard from "../HeaderCard";
import Grid from "@material-ui/core/Grid";

const styles = adjustmentCss;

class MonthlyAdjustment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            featuresEnabled: [],
            loadData: false,
            openDailyAdjustModal: false,
            monthlyData: null,
            weeklyData: null,
            error: [],
            monthly_adjustment: [],
            settingAlert: false,
            averageWeekMonth: {},
            warning: false,
            warningMsg: [],
            warningMethod: null,
            boostMonth:{},
            loadDataMonth:false
        };
    }


    componentWillMount() {
        let requestParam = { token: this.props.hotelAuthReducer.hotelDetails.token }
        this.getLattestPricing();
        this.props.getPricingSettings(requestParam);
    }

    componentWillReceiveProps(props) {
        if(props.priceSettingReducer){
            this.setState({
                loadDataMonth:props.priceSettingReducer.loadData
            })
        }
        if(props.hotelAuthReducer && props.hotelAuthReducer.hotelDetails){
            this.setState({
                is_channel_manager:props.hotelAuthReducer.hotelDetails.hotel.is_channel_manager,
                pms_channel_manager_text :props.hotelAuthReducer.hotelDetails.hotel.is_channel_manager ? 'Channel Manager':'PMS'
            })
        }
        if (props.priceSettingReducer.priceSetting && props.priceSettingReducer.priceSetting.adjustment.monthly != this.props.priceSettingReducer.priceSetting.adjustment.monthly) {
            let monthlyData = JSON.stringify(props.priceSettingReducer.priceSetting.adjustment.monthly);
            let weeklyData = JSON.stringify(props.priceSettingReducer.priceSetting.adjustment.weekday);

            this.setState({
                monthlyData: JSON.parse(monthlyData),
                weeklyData: JSON.parse(weeklyData),
                featuresEnabled: props.priceSettingReducer.priceSetting.features
            })
        }

    }



    toggleWarningModal = (method) => {
        const { t } = this.props;
        let warningMsg = [];
        let warning = false;
        let errorMsg = "";
        if (method == "updateMonthlyAdjust") {

            let error = [];
            let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            let monthData = this.state.monthlyData;

            let allAdj = [];

            monthNames.map((row) => {
                if ((!monthData[row].standard || monthData[row].standard === '') && monthData[row].standard !== 0) {
                    error.push("mon_" + row);
                }
                allAdj.push(monthData[row].standard)
            })


            this.setState({
                error: error
            })

            if (error.length == 0) {
                let less = false;
                let more = false;
                let weekdirection = true;
                let endDirection = true;
                let max = false;

                allAdj.map((val, index) => {
                    if (val < 0.1 && !less) {
                        less = true;
                    }

                    if (val > -0.5 && !more) {
                        more = true;
                    }

                    if (Math.abs(val) > 25 && !max) {
                        max = true;
                    }
                })

                if (!(allAdj[5] >= 0 && allAdj[6] >= 0 && allAdj[7] >= 0 && allAdj[8] >= 0) && !(allAdj[5] <= 0 && allAdj[6] <= 0 && allAdj[7] <= 0 && allAdj[8] <= 0)) {
                    weekdirection = false;
                }

                if (!(allAdj[0] >= 0 && allAdj[1] >= 0 && allAdj[10] >= 0 && allAdj[11] >= 0) && !(allAdj[0] <= 0 && allAdj[1] <= 0 && allAdj[10] <= 0 && allAdj[11] <= 0)) {
                    endDirection = false;
                }

                if (!less) {
                    warning = true;
                    warningMsg.push(t("When you have set all monthly adjustments to  positive it means your base price may be too low. You can call us for consultation on this."))
                }
                if (!more) {
                    warning = true;
                    warningMsg.push(t("When you have set all monthly adjustments to  negative it means your base price may be too high. You can call us for consultation on this."))
                }
                if (!weekdirection) {
                    warning = true;
                    warningMsg.push(t("Typically June to September adjustments will have the same direction, eg either all positive or all negative. Are you sure about these?"))
                }
                if (!endDirection) {
                    warning = true;
                    warningMsg.push(t("Typically November to February adjustments will have the same direction, eg either all positive or all negative. Are you sure about these?"))
                }

                if (max) {
                    warning = true;
                    warningMsg.push(t("An adjustment greater then 25% / smaller than -25% is an unusually high adjustment. Please contact us if you are unsure."))
                }



                if (!max && less && more && weekdirection && endDirection) {
                    this.updateMonthlyAdjust()
                }
            }
        }

        this.setState({
            warning: warning,
            warningMethod: method,
            warningMsg: warningMsg,
            errorMsg: errorMsg
        })
    }

    closeWarningModal = (method) => {
        this.setState({
            warning: false,
        })
    }

    warningConfirm = () => {
        if (this.state.warningMethod == "updateMonthlyAdjust") {
            this.updateMonthlyAdjust()
        }
        this.setState({
            warning: false,
        })
    }

    getLattestPricing = () => {
       axios.defaults.headers.common['Authorization'] = "Token " + this.props.hotelAuthReducer.hotelDetails.token;
        axios.get(Server.API + 'pricingalgorithm/cache-price/')
            .then((response) => {
                if (response.data) {
                    var received_msg = response.data;
                    if (received_msg && received_msg.prices && received_msg.prices.data && !this.state.stopCachedPrice) {
                        if (received_msg.prices.average_price_week_month) {
                            this.setState({
                                averageWeekMonth: received_msg.prices.average_price_week_month
                            })
                        }
                    }
                    if(received_msg && received_msg.prices && received_msg.prices.average_price_week_month.pickupboostprice_averages){
                        this.setState({
                            boostMonth: received_msg.prices.average_price_week_month.pickupboostprice_averages.monthly,
                        })
                    }
                }
            }).catch((err) => {
                console.log(err)
            })
    }




    updateMonthlyAdjust = () => {

        let requestParams = {
            token: this.props.hotelAuthReducer.hotelDetails.token,
            allData: this.state.monthlyData
        }

        let setting = this.props.priceSettingReducer.priceSetting;
        setting.adjustment.monthly = requestParams.allData;



        // let flagReq = {
        //     run_pricing: true,
        //     token: this.props.hotelAuthReducer.hotelDetails.token,
        // }
        // this.props.setRunPriceFlags(flagReq);


        let latestJson = ValidateBaseSettings({ dateSetting: setting });
        let reqParams = {
            settings: latestJson.dateSetting,
            token:this.props.hotelAuthReducer.hotelDetails.token
        };
        this.props.storePricingSettings(reqParams)
        
    }

    validateWeeklyAdjust = (data) => {
        let error = [];
        let weeklyData = this.state.weeklyData;
        var daysInWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        daysInWeek.map((row) => {
            if ((!weeklyData[row].standard || weeklyData[row].standard === '') && weeklyData[row].standard !== 0) {
                error.push("per_" + row);
            }
            return null;
        })
        if (error) {
            this.setState({
                error: error
            })
            return error
        }
        else {
            return false
        }

    }


    updateWeeklyAdjust = () => {

        let validateError = this.validateWeeklyAdjust();

        if (validateError.length > 0) {
            return;
        }

        let rows = this.state.weeklyData;

        let requestParams = {
            token: this.props.hotelAuthReducer.hotelDetails.token,
            allData: rows,
        }

        let setting = this.props.priceSettingReducer.priceSetting;
        setting.adjustment.weekday = requestParams.allData;

        // let flagReq = {
        //     run_pricing: true,
        //     token: this.props.hotelAuthReducer.hotelDetails.token,
        // }
        // this.props.setRunPriceFlags(flagReq);

        let latestJson = ValidateBaseSettings({ dateSetting: setting });

        let reqParams = {
            settings: latestJson.dateSetting,
            token:this.props.hotelAuthReducer.hotelDetails.token
        };
        this.props.storePricingSettings(reqParams)
    }

    handleMonthly = (row) => event => {
        let monthlyData = this.state.monthlyData;
        if (event.target.value != "" && event.target.value != "-") {
            monthlyData[row].standard = parseFloat(event.target.value);
        } else {
            monthlyData[row].standard = (event.target.value);
        }

        this.setState({
            monthlyData
        })
    }

    renderMonthlyTable = (limit) => {
        var jsx = [];
        const { classes } = this.props;
        const { t } = this.props;
        let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let percentAdorn = <Typography variant="body2" className={classes.adornment}>%</Typography>;
        let monthData = this.state.monthlyData;
        let allowedMonths = monthNames;
        if (this.state.featuresEnabled.includes(18) && (!this.props.userSession.user.is_staff || !this.props.userSession.user.sub_account_type)) {
            allowedMonths = [
                moment().format("MMMM"),
                moment().add(1, "M").format("MMMM"),
                moment().add(2, "M").format("MMMM"),
                moment().add(3, "M").format("MMMM"),
            ];
        }

        monthData && allowedMonths.map((row, index) => {
            if (index < (limit + 6) && (index >= limit)) {
                let shortMonth =  row.slice(0, 3) // Jan,Feb
                return jsx.push(
                    <TableCell align="right">
                        <FormControl fullWidth margin="normal" required className={classes.fieldMargin1}>
                            <Input
                                margin="normal"
                                label={t("Value")}
                                type="number"
                                error={this.state.error.includes("mon_" + row)}
                                className={classes.textField}
                                onChange={this.handleMonthly(row)}
                                value={monthData[row].standard}
                                placeholder={t(shortMonth[0].toUpperCase() + shortMonth.slice(1))}
                                endAdornment={percentAdorn}
                            />
                        </FormControl>
                    </TableCell>
                );
            }
            return null;
        });

        return jsx;
    }

    handleWeekly = (row) => event => {
        let weekData = [];
        weekData = this.state.weeklyData;

        if (event.target.value != "" && event.target.value != "-") {
            weekData[row].standard = parseFloat(event.target.value);
        } else {
            weekData[row].standard = (event.target.value);
        }
        this.setState({
            weeklyData: weekData
        })
    }

    renderWeeklyTable = () => {
        const { t } = this.props;
        var jsx = [];
        var rows = [];
        const { classes } = this.props;
        let percentAdorn = <Typography variant="body2" className={classes.adornment}>%</Typography>;

        let weekData = this.state.weeklyData;
        var daysInWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        {
            weekData && daysInWeek.map((row, index) => {
                return jsx.push(
                    <TableCell key={index} align="right">
                        <FormControl margin="normal" required className={classes.fieldMargin1}>
                            <Input
                                type="number"
                                margin="normal"
                                error={this.state.error.includes("per_" + row)}
                                className={classes.textField}
                                onChange={this.handleWeekly(row)}
                                value={weekData[row].standard}
                                endAdornment={percentAdorn}
                            />
                            {this.state.error.includes("per_" + row) ? <FormHelperText><span class="error-message">{t('* Please enter the value')}</span></FormHelperText> : ''}
                        </FormControl>
                    </TableCell>
                )
            })
        }
        return jsx;
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;

        let allowedMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let allowedMonthsNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

        if (this.state.featuresEnabled.includes(18) && (!this.props.userSession.user.is_staff || !this.props.userSession.user.sub_account_type)) {
            allowedMonths = [
                moment().format("MMMM"),
                moment().add(1, "M").format("MMMM"),
                moment().add(2, "M").format("MMMM"),
                moment().add(3, "M").format("MMMM"),
            ];

            allowedMonthsNumber = [
                moment().format("M"),
                moment().add(1, "M").format("M"),
                moment().add(2, "M").format("M"),
                moment().add(3, "M").format("M"),
            ];
        }

        let comp_data = [];
        let ref_data = [];
        let avg_data = [];
        let maxvalue = 0;
        let minvalue = 0;

        if (this.state.averageWeekMonth.comp_adjusted_price_averages) {
            allowedMonthsNumber.map(key => {
                if (this.state.averageWeekMonth.comp_adjusted_price_averages.monthly[key]) {
                    comp_data.push(Math.round(this.state.averageWeekMonth.comp_adjusted_price_averages.monthly[key]))
                    if (this.state.averageWeekMonth.comp_adjusted_price_averages.monthly[key] && this.state.averageWeekMonth.comp_adjusted_price_averages.monthly[key] > maxvalue) {
                        maxvalue = this.state.averageWeekMonth.comp_adjusted_price_averages.monthly[key]
                    }
                    if (this.state.averageWeekMonth.comp_adjusted_price_averages.monthly[key] && (!minvalue || this.state.averageWeekMonth.comp_adjusted_price_averages.monthly[key] < minvalue)) {
                        minvalue = this.state.averageWeekMonth.comp_adjusted_price_averages.monthly[key]
                    }
                } else {
                    comp_data.push(Math.round(""))
                }
            })
        }

        if (this.state.averageWeekMonth.pms_price_averages && (this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type)) {
            allowedMonthsNumber.map(key => {
                if (this.state.averageWeekMonth.pms_price_averages.monthly[key]) {
                    avg_data.push(Math.round(this.state.averageWeekMonth.pms_price_averages.monthly[key]))
                    if (this.state.averageWeekMonth.pms_price_averages.monthly[key] && this.state.averageWeekMonth.pms_price_averages.monthly[key] > maxvalue) {
                        maxvalue = this.state.averageWeekMonth.pms_price_averages.monthly[key]
                    }
                    if (this.state.averageWeekMonth.pms_price_averages.monthly[key] && (!minvalue || this.state.averageWeekMonth.pms_price_averages.monthly[key] < minvalue)) {
                        minvalue = this.state.averageWeekMonth.comp_adjusted_price_averages.monthly[key]
                    }
                } else {
                    avg_data.push(Math.round(""))
                }
            })
        }


        if (this.state.monthlyData && this.state.averageWeekMonth.comp_adjusted_price_averages) {

            allowedMonths.map((val, index) => {
                let montData = this.state.monthlyData[val];
                let monthvalue = 0;

                if (comp_data[index]) {
                    monthvalue = ((comp_data[index] * (1 + (montData.standard / 100))))
                }

                ref_data.push(Math.round(monthvalue));
            })
        }

        const data = {
            labels: allowedMonths,
            datasets: (this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) ? [
                {
                    type: 'line',
                    borderWidth: 1,
                    fill: false,
                    lineTension: 0,
                    borderColor: '#541388',
                    label: t("Level of Local Market"),
                    data: comp_data,
                    backgroundColor: "#541388",
                }, {
                    label: t("Base Price for the Month"),
                    data: ref_data,
                    backgroundColor: "#51c0c0",
                },
                {
                    label: t(`${this.state.pms_channel_manager_text} Price`),
                    data: avg_data,
                    backgroundColor: "#dbdee2",
                }
            ]
                :
                [
                    {
                        type: 'line',
                        borderWidth: 1,
                        fill: false,
                        lineTension: 0,
                        borderColor: '#541388',
                        label: t("Level of Local Market"),
                        data: comp_data,
                        backgroundColor: "#541388",
                    }, {
                        label: t("Base Price for the Month"),
                        data: ref_data,
                        backgroundColor: "#51c0c0",
                    },
                ]
        }

        const options = {
            maintainAspectRatio: false,
            legend: {
                labels: {
                    boxWidth: 11,
                }
            },
            title: {
                display: false,
                text: 'Chart.js Line Chart'
            },
            tooltips: {
                enabled: true,
                mode: 'label',
                callbacks: {
                  label: (tooltipItems, data) => {
                    if (tooltipItems.datasetIndex == 2) {
                        return data.datasets[2].label+': ' + this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol+ new Intl.NumberFormat('ja-JP').format(parseInt(data.datasets[2].data[tooltipItems.index]));
                      }else if (tooltipItems.datasetIndex == 0) {
                        return data.datasets[0].label +': '+ this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol+ new Intl.NumberFormat('ja-JP').format(parseInt(data.datasets[0].data[tooltipItems.index]));
                      }else if (tooltipItems.datasetIndex == 1) {
                        return data.datasets[1].label +': '+ this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol+ new Intl.NumberFormat('ja-JP').format(parseInt(data.datasets[1].data[tooltipItems.index]));
                      }
                    }
                }
            },
            hover: {
                mode: 'dataset'
            },
            scales: {
                xAxes: [
                    {
                        barThickness: 35,
                        maxBarThickness: 60,
                        gridLines: {
                            offsetGridLines: true,
                        },
                        display: false,
                        scaleLabel: {
                            show: true,
                            labelString: 'Month'
                        },
                        ticks: {
                            
                            callback: (label, index, labels) => {
                                return t(label);
                            }
                    }}
                ],
                yAxes: [
                    {
                        display: true,
                        scaleLabel: {
                            show: true,
                            labelString: 'Value'
                        },
                        ticks: {
                            suggestedMin: 0,
                            suggestedMax: (maxvalue * 0.7),
                            callback: (label, index, labels) => {
                                return (this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol) + label;
                            }
                        }
                    }
                ]
            }
        }
        const plugins= [{
            beforeInit: function(chart, args, options) {
              chart.legend.afterFit = function() {
                this.height = this.height + 20;
              }
            }
          }]
        return (
            <>
            <HeaderCard>
                <Grid container>
                    <Grid item >
                        <div className={classes.divStyle} >
                        <Typography variant="h5" className={classes.fontStyle}>
                          {t("Monthly Adjustments")}
                          <CustomTooltip title="" description={t("The Monthly Adjustment is a price-change that is applied for every single e.g. January. If you set January to -5% we will adjust our recommended price 5% lower for every day in January. It enables you to set your seasonal cycle versus your market set. Note that these adjustments do not override pre-set maximums or minimums.")} />
                        </Typography>
                        <DialogContentText align="left" className={classes.helpText}>
                            {t("We check the rates of other properties that represent your local 'market'. Next, we estimate what they would charge if they had the same base price as you. Below, we show these 'market' prices for the different months. However, every property is different; use Monthly Adjustments to adjust price recommendations to suit your property.")}
                        </DialogContentText>
                        </div>
                    </Grid>
                </Grid>
            </HeaderCard>
                <Card>
                    <CardContent>
                        <div className={classes.mainDiv} >
                            <div className={classes.rootMonth} >
                                <div className={classes.graphStyleMonth} >
                                    <Bar height={200} data={data} options={options} plugins={plugins} />
                                </div>
                                <div className={classes.dateTableDiv}>
                                    <MuiThemeProvider theme={monthlySettingTheme}>
                                        <Table className={classes.userTable}>
                                            <TableHead>
                                                <TableRow className={classes.rowTable}>
                                                    <TableCell align="right"> </TableCell>
                                                    {allowedMonths.map((row, index) => (
                                                        <TableCell className={classes.cellTitle}>{this.state.featuresEnabled.includes(18) ? t(row) : t(row.substring(0, 3))}</TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow key={1} className={classes.rowTable}>
                                                    <TableCell>{t("Adjust")}<br />{t("Price by")}</TableCell>
                                                    {this.renderMonthlyTable(0)}
                                                    {this.renderMonthlyTable(6)}
                                                </TableRow>
                                                <TableRow key={'s1'} className={classes.rowTable,classes.rowBorder}>
                                                    <TableCell>{t("Base Price ")}<br />{t("for the Month")}</TableCell>
                                                    {ref_data && ref_data.map((val, index) => (
                                                        <TableCell align="left">
                                                            <FormControl margin="normal" required className={classes.fieldMargin1}>
                                                                <Typography >
                                                                    {this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol + val}
                                                                </Typography>
                                                            </FormControl>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                                
                                            {(this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) && this.state.featuresEnabled.includes(22) &&
                                                <TableRow key={'s1'} className={[classes.rowTable,classes.rowBorder]}>
                                                    <TableCell>{t("Average Pickup Boost")}</TableCell>
                                                    {this.state.boostMonth && Object.keys(this.state.boostMonth).map((val, index) => (
                                                        <TableCell align="left">
                                                            <FormControl margin="normal" required className={classes.fieldMargin1}>
                                                                <Typography >
                                                                    {this.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol + this.state.boostMonth[val]}
                                                                </Typography>
                                                            </FormControl>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            }
                                            </TableBody>
                                        </Table>
                                    </MuiThemeProvider>
                                </div>
                                <div className={classes.btnSave}>
                                    <Button disabled={this.state.loadDataMonth} className={"orangButton"} variant="contained" color="primary" onClick={() => this.toggleWarningModal("updateMonthlyAdjust")}>
                                        {t("Save")}{this.state.loadDataMonth && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                                    </Button>
                                </div>
                                {Warning.warningModal(this)}
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </>
        );
    }
}

MonthlyAdjustment.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        userSession: state.sessionReducer,
        hotelDetail: state.hotelSettingReducer.hoteDetail,
        currencyList: state.currencyReducer.currencyList,
        hotelAuthReducer: state.hotelAuthReducer,
        monthlyAdjustReducer: state.monthlyAdjustReducer,
        roomList: state.roomListReducer,
        commonReducer: state.commonReducer,
        priceSettingReducer: state.priceSettingReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateMonthlyAdjust: (request) => { dispatch(actions.updateMonthlyAdjust(request)) },
        getPricingSettings: (request) => { dispatch(actions.getPricingSettings(request)) },
        putNotify: (request) => { dispatch(actions.putNotify(request)) },
        // setRunPriceFlags: (request) => { dispatch(actions.setRunPriceFlags(request)) },
        storePricingSettings:(request) => {dispatch(actions.storePricingSettings(request))}

    };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(MonthlyAdjustment));
