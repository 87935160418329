import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = [];

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESELLER_USER_SUCCEED:
            return updateObject(state, { loading: true, error: null, list: action.state })
        case actionTypes.RESELLER_MEMBER_USER_SUCCEED:
            return updateObject(state, { loading: true, error: null, memberList: action.state })
        case actionTypes.RESELLER_MEMBER_All:
            return updateObject(state, { loading: true, error: null, resellerAllMemberList: action.state })
        case actionTypes.RESELLER_All:
            return updateObject(state, { loading: true, error: null, allResellerList: action.state })
        default:
            return updateObject(state, { loading: false, error: null });
    }
}

export default reducer