import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { MuiThemeProvider ,createMuiTheme} from '@material-ui/core/styles';
import { Grid, Icon, Typography} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { performanceDashboardTheme, ButtonTheme } from '../../muiTheme';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import CustomTooltip from '../../Utils/toolTip';
import { Bar, Line } from 'react-chartjs-2';
import { MuiPickersUtilsProvider, DatePicker, InlineDatePicker  } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from "date-fns/locale/en-US";
import classNames from 'classnames';
import { monthPickerStyle } from '../../muiTheme';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

export default {
    setBudget: function (modalData) {
        var CurrencyFormat = require("react-currency-format");

        let { allMonths,performanceCurrent,defaultBudget,currency,kpi_yearly_total,adrValues,budgetObj,revenueBudgetVal } = modalData.state;
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        const data = [];
        let average=0;
        let yearlyOTB=0;
        let monthlyOTB=0;
        let totalYearlyBudget=0;
        let totalMonthlyBudget=0;
        let currentMonth=moment(modalData.state.graphMonthChangeDate).format('YYYY-MM');
        let currentYear=moment(modalData.state.yearChangeDate).format('YYYY');
        
        let key = modalData.getServiceKey();
        allMonths.map(val=>{
            if(modalData.state.revenueBudgetVal[key] && modalData.state.revenueBudgetVal[key][val] && modalData.state.revenueBudgetVal[key][val] > 0){
              totalYearlyBudget+=modalData.state.revenueBudgetVal[key][val]
            }
            // else{
            //   let defaultBudgetVal=defaultBudget?.[val]?.dashboard_default_budget;
            //   totalYearlyBudget+=(defaultBudgetVal?defaultBudgetVal:0)
            // }      
        })
       
        if(kpi_yearly_total && performanceCurrent){
            average= Math.floor(totalYearlyBudget/12)
            yearlyOTB=kpi_yearly_total['REVENUE']?.[currentYear];
        }
        
      let budgetKey = 'REVENUE';

      if(modalData.state.dashboardServices == 0){
        budgetKey = 'REVENUE';
      }else if(modalData.state.dashboardServices == 1){
        budgetKey = 'REVENUE_INCL_SERVICES';
      }else if(modalData.state.dashboardServices == 2){
        budgetKey = 'REVENUE_NET_INCL_SERVICES';
      }else if(modalData.state.dashboardServices == 3){
        budgetKey = 'REVENUE_NET';
      }

        budgetObj && Object.keys(budgetObj).map(val=>{
            data.push([
                moment(val).format('MMM YY'),
                budgetObj[val].daysInMonth,
                currency.symbol+new Intl.NumberFormat('ja-JP').format(performanceCurrent?.[budgetKey]?.[val]??0),
                currency.symbol+new Intl.NumberFormat('ja-JP').format(modalData.state.budgetObj[val]?.lastYearRevenue??0),
                val,
                budgetObj[val].adr
            ])
        })
        const columns = [
            {
              name: t("Month"),
              options: {
                filter: false,
                sort:false,
                setCellProps: () => ({style: {whiteSpace:'nowrap'}})
              }
            },
            {
              name: t("Days"),
              options: {
                filter: false,
                sort:false,
              }
            },
            {
              name: t("OTB Revenue"),
              options: {
                filter: false,
                sort:false,
                setCellProps: () => ({style: {whiteSpace:'nowrap'}})
              }
            },
            {
                name: t("LY Revenue"),
                options: {
                  filter: false,
                  sort:false,
                  setCellProps: () => ({style: {whiteSpace:'nowrap'}})
                }
            },
            {
              name: <>{t("Revenue Budget")}
              <CustomTooltip style={{marginBottom:"-6px"}} title="" description={t("Please define your monthly budget for the room revenue (gross including all taxes, excluding extra services). The budget indication displayed is a very basic estimation of your monthly budget based on your target occupancy, the base price for the month and your room type derivations.")} />
              </>,
              options: {
                filter: false,
                sort:false,
                setCellProps: () => ({style: {whiteSpace:'nowrap'}}),
                customBodyRender: (value, tableMeta, updateValue) => {
                    
                    return (                        
                            <FormControl>
                                <Input
                                    type='number'
                                    style={{ margin: '10px 0px', width: '280px' }}
                                    className={classes.textField}
                                    onChange={e => modalData.handleRevenueBudget(e, value)}
                                    value={modalData.state.budgetObj[value]?.revenueBudget}
                                    // value={revenueBudgetVal[value]}
                                    startAdornment={<Typography variant="subtitle1" className={classes.adornment}>{currency.symbol}</Typography>}
                                    
                                />
                            </FormControl>
                    )
                }
              },
            },
            {
              name: t("ADR Budget"),
              options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span>{value ? currency.symbol+Math.round(value) : currency.symbol+0}</span>
                    )
                }
              }
            },
            
        ];
        const options = {
            sort: true,
            toolbar: false,
            filter: false,
            print: false,
            download: false,
            viewColumns: false,
            responsive: "scroll",
            selectableRows: false,
            serverSide: false,
            rowsPerPage: 20,
            rowsPerPageOptions: false,
            pagination: false,
            search: false,
            fixedHeader: true,
            fixedSelectColumn: true,
            tableBodyHeight: '400px',
            responsive: 'standard',

        }
        let is_admin= modalData.props.userSession.user.is_staff? true : false
        return (
        <Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            maxWidth='md'
            open={modalData.state.openBudgetModal}
            onClose={modalData.closeBudgetModal}
            aria-labelledby="responsive-dialog-title"
            PaperProps={{ style: { maxWidth: "500px", margin: "10px", minWidth: '80%',overflow:'unset',padding:'0px !important',margin:'0px 30px !important' } }}
        >
            <DialogTitle id="responsive-dialog-title">
                {t("Set Budget")}
                <Grid item xs={12}>
                    <div className={classNames(classes.dateNavigationDivforModal, classes.dateNavigationDivResponsive)}>
                        <Typography variant="h6" style={{marginLeft:'-14px', width: "57px" ,color: '#707070',  fontWeight: "400" }}>{currentYear}</Typography>
                        <Button
                        disabled={(modalData.state.startYear == moment(modalData.state.yearChangeDate).format('YYYY')) ? true : false} className={[classes.calendarNaviBtn,classes.changeIcon]}
                        onClick={() => modalData.navigateYear(moment(modalData.state.yearChangeDate).subtract(1, 'year').format('YYYY'))}>
                        <Icon>navigate_before</Icon>
                        </Button>
                        <Button className={[classes.calendarNaviBtn,classes.changeIcon]}
                        disabled={(modalData.state.tillYear == moment(modalData.state.yearChangeDate).format('YYYY')) ? true : false} onClick={() => modalData.navigateYear(moment(modalData.state.yearChangeDate).add(1, 'year').format('YYYY'))}>
                        <Icon>navigate_next</Icon>
                        </Button>
                        <MuiThemeProvider theme={ButtonTheme} >
                        <Button
                            className={classes.fullWidthResp}
                            variant="outlined"
                            onClick={() => modalData.navigateYear(moment.utc(modalData.props.hotelAuthReducer.utcTime).tz(modalData.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY'))}>
                            {t("Current Year")}
                        </Button>
                        </MuiThemeProvider>

                        <FormControl variant="outlined" className={classes.formControlVs}>
                          <Select
                            inputProps={{
                              classes: {
                                root: classes.textSecondary,
                                icon: classes.textSecondary,
                              },
                            }}
                            // className={classes.fullWidthResp}
                            value={modalData.state.dashboardServices}
                            onChange={(event) => modalData.changeServices(event.target.value)}
                            input={<OutlinedInput id="outlined-age-simple" />}
                            style={{ borderRadius: "10px", height: "36px",color:'#707070 !important' }}
                            SelectDisplayProps={{
                              style: { height: "100%", padding: "5px 25px 5px 10px", borderRadius: "10px", fontSize: "14px",color:'#707070', fontWeight: "400" }
                            }}
                          >
                            <MenuItem value={0}>{t("Gross excl. Extra Services")}</MenuItem>
                            <MenuItem value={3}>{t("Net excl. Extra Services")}</MenuItem>
                            {is_admin && <MenuItem value={1}>{t("Gross incl. Extra Services")}</MenuItem>}
                            {is_admin && <MenuItem value={2}>{t("Net incl. Extra Services")}</MenuItem>}
                          </Select>
                        </FormControl>
              
                        <div style={{marginLeft: "16px", color:'#707070', fontSize:'18px'}} className={classes.hideOnMobile}>
                            <span className={classes.boldFont} >
                                {yearlyOTB? (currency.symbol + new Intl.NumberFormat('ja-JP').format(yearlyOTB)):"n.a."}
                            </span>
                            <span style={{fontWeight:'300',fontSize:'16px',whiteSpace:'nowrap'}}>
                                {" "+t('OTB Revenue')}
                            </span>
                        </div>
                        <div style={{ marginLeft: "16px",color:'#707070',fontSize:'18px'}} className={classes.hideOnMobile}>
                            <span className={classes.boldFont} >
                                {totalYearlyBudget? (currency.symbol+new Intl.NumberFormat('ja-JP').format(totalYearlyBudget)):'n.a.'}
                            </span>
                            <span style={{fontWeight:'300',fontSize:'16px'}}>
                            {" "+t('Budget')}
                            </span>
                        </div>
                        <div style={{ marginLeft: "16px",color:'#707070',fontSize:'18px'}} className={classes.hideOnMobile}>
                            <span className={classes.boldFont} >
                                {  average? (currency.symbol + new Intl.NumberFormat('ja-JP').format(average)):'n.a.'}
                                
                            </span>
                            <span style={{fontWeight:'300',fontSize:'16px',whiteSpace:'nowrap'}}>
                            {" "+t('Average Budget')}
                            </span>
                        </div>
                        
                        {/* <span style={{marginLeft:'15px' ,fontSize:'20px',  fontWeight: "400" }}>{currency.symbol+new Intl.NumberFormat('ja-JP').format(kpi_yearly_total?kpi_yearly_total['REVENUE'][currentYear]:0)}</span>
                        <span style={{marginLeft:'5px' ,fontSize:'16px',  fontWeight: "400" }}>{t('Average')}</span>
                        <span style={{marginLeft:'5px' ,fontSize:'17px',  fontWeight: "400"}}>{currency.symbol+new Intl.NumberFormat('ja-JP').format(average)}</span> */}
                    </div>
                </Grid>
            </DialogTitle>
            <DialogContent>
            <Grid item xs={12} >
                <MuiThemeProvider theme={performanceDashboardTheme} >
                    <MUIDataTable
                        data={data}
                        columns={columns}   
                        options={options}
                    />
                </MuiThemeProvider>
            </Grid>
            </DialogContent>
            <DialogActions>
            <Button  onClick={() => modalData.handleSetBudget()} className={classes.saveUploadBtn} variant="contained" color="primary" >
                    {t("Save")}
                </Button>
                <Button onClick={() => modalData.closeBudgetModal()} className="cancelBtn"  >
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>)
    },
    PurgeReservationModal: function(modalData){
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            maxWidth='md'
            open={modalData.state.purgeReservationModal}
            onClose={modalData.updatePurgeReservationModalClose}
            aria-labelledby="responsive-dialog-title"
            style={{maxWidth:'720px',margin:'auto',display:'block'}}
        >
            <DialogTitle id="responsive-dialog-title">
                {t("Purge all reservations?")}
            </DialogTitle>
            <DialogContent>
                
                    This will remove all reservations for this property. It can take many hours to pull them again. For some PMS like Protel (which push reservations to us), you will have to ask their support to initiate another full push.
                
            </DialogContent>
            <DialogActions>
                
                <Button  onClick={() => { modalData.updatePurgeReservation() }} className={classes.saveUploadBtn} variant="contained" color="secondary" >
                    {t("Purge All Reservations")}
                </Button>
                <Button onClick={() => modalData.updatePurgeReservationModalClose()} className="cancelBtn"  >
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>
        )
    },
    bookingCurveModal:function(modalData){
        let monthDate=moment(modalData.state.graphMonthChangeDate).format('YYYY-MM')
        let month=moment(modalData.state.graphMonthChangeDate).format('MMMM YYYY')
        let stlyMonth=moment(modalData.state.graphMonthChangeDate).subtract(1, 'years').format('YYYY-MM')
        const {totalBooking,stlyBooking} = modalData.state
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        const arr1=[]
        for (let i = -90; i<= 0;i++){
            arr1.push(i);
        }
        let maxvalue=0
        let graphData = {
            total: [],
            stly:[],
            graphDate:[],
            labal:[],
        }
        let graphLabal=[]
        totalBooking[monthDate] && arr1.map((key)=>{
            graphData.total.push(totalBooking[monthDate][key])
        })
        stlyBooking[stlyMonth] && arr1.map((key)=>{
            graphData.stly.push(stlyBooking[stlyMonth][key])
        })
        const options = {
            legend: {
              labels: {
                boxWidth: 11,
              }
            },
            responsive: true,
            scales: {
              xAxes: [{
                barThickness: 35,
                maxBarThickness: 60,
                gridLines: {
                  color: '#ffffff',
                  borderDash: [1, 3],
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Lead Time in Days',
                  align: "end"
              },
              }],
             
              yAxes: [
                {
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: 'Occupancy in %'
                    },
                    ticks: {
                        suggestedMin: 0,
                        suggestedMax: 100,
                        callback: (label, index, labels) => {
                          return label;
                        }
                    }
                }
            ]
            },
            tooltips: {
              enabled: true,
              mode: 'index',
              position: 'nearest',
              // callbacks: {
              // label: (tooltipItems, data) => {
              //   console.log('ttttttttt',data)
              // }}
            }
          } 
          let graphArr=[
            {
              type: 'line',
              label: t('Total'),
              backgroundColor: '#7dd5de',
              borderColor: '#7dd5de',
              borderWidth: 1,
              fill: true,
              lineTension: 0,
              data: graphData.total,
              order: 1,
              pointRadius:'0',
              pointBackgroundColor: '#7dd5de',
              pointHoverBackgroundColor: '#7dd5de',
              pointBorderColor: '#7dd5de',
              pointHoverBorderColor: '#7dd5de',
            },
            {
                type: 'line',
                label: t('Same Time Last Year'),
                backgroundColor: '#f9a61e',
                borderColor: '#f9a61e',
                borderWidth: 1,
                fill: false,
                lineTension: 0,
                data: graphData.stly,
                order: 2,
                pointRadius:'0',

              },
          ]
          const genData = () => ({
            labels:arr1,
            datasets:graphArr
          })
        const data = genData()

        return (
        <Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            maxWidth='md'
            open={modalData.state.bookingCurveModalOpen}
            onClose={modalData.bookingCurveModalClose}
            aria-labelledby="responsive-dialog-title"
            style={{maxWidth:'1500px',margin:'auto',display:'block'}}
        >
            <DialogTitle id="responsive-dialog-title">
                {t("Booking Curve")}{'  '}
                <>
                  {/* <Typography variant="h6" className={classes.filterDatePro}>{moment(this.state.graphMonthChangeDate).format('MMM YYYY')}</Typography> */}
                  <MuiThemeProvider theme={monthPickerStyle}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                    <InlineDatePicker
                          style={{ width: "97px",marginTop:'2px',marginLeft:"10px", color: (modalData.state.showFullYearGraph && modalData.state.viewType == 'priceGraph') ? "#00000042" : '#00000042', fontWeight: "400" }}
                          views={["year","month"]}
                          value={moment(modalData.state.graphMonthChangeDate)}
                          onChange={modalData.changeCalendarMonthYear()}
                          format={'MMM yyyy'}
                          autoOk={true}
                          KeyboardButtonProps={{ disabled: false, style: { display: 'none' } }}
                          InputProps={{ readOnly: true }}
                          InputLabelProps={{ shrink: true }}                              
                          maxDate={moment('01-' + modalData.state.graphEndDate).format('YYYY-DD-MM')}      
                          minDate={moment('01-' + modalData.state.graphStartDate).format('YYYY-DD-MM')}
                          minDateMessage={""}
                          maxDateMessage={""}
                        />
                  </MuiPickersUtilsProvider>
                </MuiThemeProvider>
                  <Button
                    disabled={(modalData.state.graphStartDate == moment(modalData.state.graphMonthChangeDate).format('MM-YYYY')) ? true : false}
                    className={[classes.calendarNaviBtn, classes.changeIcon]}
                    onClick={() => modalData.graphNavigateMonth(moment(modalData.state.graphMonthChangeDate).subtract(1, 'months').format('YYYY-MM-DD'))}>
                    <Icon >navigate_before</Icon>
                  </Button>
                  <Button className={[classes.calendarNaviBtn, classes.changeIcon]}
                    disabled={(modalData.state.bookingCurveGraphEndDate == moment(modalData.state.graphMonthChangeDate).format('MM-YYYY')) ? true : false} onClick={() => modalData.graphNavigateMonth(moment(modalData.state.graphMonthChangeDate).add(1, 'months').format('YYYY-MM-DD'))}>
                    <Icon >navigate_next</Icon>
                  </Button>
                  <MuiThemeProvider theme={ButtonTheme} >
                    <Button
                      className={classes.fullWidthResp}
                      variant="outlined"
                      onClick={() => modalData.graphNavigateMonth(moment.utc(modalData.props.hotelAuthReducer.utcTime).tz(modalData.props.hotelAuthReducer.hotelDetails.hotel.timezone).format('YYYY-MM-DD'))}>
                      {t("Current Month")}
                    </Button>
                  </MuiThemeProvider>
                </>
                <div style={{ float: "right", margin: "-19px -19px 0px 0px" }}>
                  <IconButton aria-label="Close" className={classes.closeButton} onClick={modalData.bookingCurveModalClose} >
                      <CloseIcon />
                  </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
            <div className={classes.graphView} >
                <Line height={100} data={data} options={options}  />
                </div>
            </DialogContent>
            {/* <DialogActions>
                <Button onClick={() => modalData.bookingCurveModalClose()} className="cancelBtn"  >
                    {t("Cancel")}
                </Button>
            </DialogActions> */}
        </Dialog>
        )
    }
}