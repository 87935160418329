import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import DialogContentText from '@material-ui/core/DialogContentText';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { Icon, LinearProgress } from '@material-ui/core';
import CustomTooltip from '../../../Utils/toolTip';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Line } from 'react-chartjs-2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ReactSelect, { components } from 'react-select';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AsyncSelect from 'react-select/lib/Async';

export default {
    DateSkipModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        let currAdorn = <Typography variant="subtitle1" className={classes.adornment}>{modalData.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol}</Typography>;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.openSkipDateModal}
                onClose={modalData.toggleSkipDateModal}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Add new skip date")}</DialogTitle>
                <DialogContent>
                    <FormControl margin="normal" className={classes.formControl}>
                        <InputLabel htmlFor="name">{t("Name")}</InputLabel>
                        <Input
                            fullWidth
                            onChange={modalData.handleSkipDate('name')}
                            value={modalData.state.name}
                            error={modalData.state.error.includes("name")}
                        />
                        {modalData.state.error.includes("name") ? <span class='error-message'>{t('* Please enter Name')}</span> : ''}
                    </FormControl>
                    <FormControl margin="normal" required className={classes.formControl}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                label="Date"
                                minDate={moment()}
                                error={modalData.state.error.includes("skipDate")}
                                value={modalData.state.skipDate}
                                onChange={modalData.handleSkipDate('skipDate')}
                                format={"dd/MM/yyyy"}
                                InputLabelProps={{ shrink: true }}
                                autoOk={true}
                                shouldDisableDate={modalData.disableUsedSkipDates}
                            />
                        </MuiPickersUtilsProvider>
                        {modalData.state.error.includes("skipDate") ? <span class='error-message'>{t('* Please enter Date')}</span> : ''}
                    </FormControl>
                    <FormControl margin="normal" className={classes.formControl}>
                        <InputLabel htmlFor="fixed_price">{t("Fixed Price")}</InputLabel>
                        <Input
                            type="number"
                            fullWidth
                            onChange={modalData.handleSkipDate('fixed_price')}
                            value={modalData.state.fixed_price}
                            startAdornment={currAdorn}
                            endAdornment={<Icon className="blankInput" onClick={() => modalData.clearFixedPrice()}>close</Icon>}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                   
                    <Button onClick={modalData.addNewSkipPricing} variant="contained" color="primary" >
                        {t("Save")}
                    </Button>
                    <Button onClick={modalData.toggleSkipDateModal} className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    editDateSkipModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        let currAdorn = <Typography variant="subtitle1" className={classes.adornment}>{modalData.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol}</Typography>
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.openEditSkipDateModal}
                onClose={() => modalData.toggleEditSkipDateModal()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Edit skip date")}</DialogTitle>
                <DialogContent>
                    <FormControl margin="normal" className={classes.formControl}>
                        <InputLabel htmlFor="editName">{t("Name")}</InputLabel>
                        <Input
                            fullWidth
                            onChange={modalData.handleSkipDate('editName')}
                            value={modalData.state.editName}
                            error={modalData.state.error.includes("editName")}
                        />
                        {modalData.state.error.includes("editName") ? <span class='error-message'>{t('* Please enter Name')}</span> : ''}
                    </FormControl>
                    <FormControl margin="normal" required className={classes.formControl}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                label="Date"
                                minDate={moment()}
                                error={modalData.state.error.includes("editSkipDate")}
                                value={modalData.state.editSkipDate}
                                onChange={modalData.handleEditSkipDate('editSkipDate')}
                                format={"dd/MM/yyyy"}
                                InputLabelProps={{ shrink: true }}
                                autoOk={true}
                                shouldDisableDate={modalData.disableUsedSkipDates}
                            />
                        </MuiPickersUtilsProvider>
                        {modalData.state.error.includes("editSkipDate") ? <span class="error-message">{t('* Please enter Date')}</span> : ''}
                    </FormControl>
                    <FormControl margin="normal" className={classes.formControl}>
                        <InputLabel htmlFor="editFixedPrice">{t("Fixed Price")}</InputLabel>
                        <Input
                            type="number"
                            fullWidth
                            onChange={modalData.handleSkipDate('editFixedPrice')}
                            value={modalData.state.editFixedPrice}
                            startAdornment={currAdorn}
                            endAdornment={<Icon className="blankInput" onClick={() => modalData.clearFixedPrice()}>close</Icon>}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                   
                    <Button onClick={modalData.editSkipPricing} variant="contained" color="primary" >
                        {t("Save")}
                    </Button>
                    <Button onClick={() => modalData.toggleEditSkipDateModal()} className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    DeleteDateSkipModal: function (modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.openDeleteSkipDateModal}
                onClose={() => modalData.toggleDeleteSkipDateModal()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Delete skip date")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("Are you sure you want to delete this date?")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                   
                    <Button onClick={modalData.deleteSkipPricing} color="Primary" variant="contained" autoFocus  className="dangerBtnHvr">
                        {t("Delete")}
                    </Button>
                    <Button onClick={modalData.toggleDeleteSkipDateModal} className="cancelBtn" autoFocus>
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    DailyAdjustModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={false}
                maxWidth={'xs'}
                open={modalData.state.openDailyAdjustModal}
                onClose={modalData.toggleDailyAdjustModal}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Add new single date adjustment")}</DialogTitle>
                < DialogContent >
                    <FormControl margin="normal" required className={classes.fullWidth}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                minDate={moment()}
                                error={modalData.state.error.includes("date")}
                                value={modalData.state.addModalData.date}
                                onChange={modalData.handleDailyAdjustModal("date")}
                                format={"dd/MM/yyyy"}
                                InputLabelProps={{ shrink: true }}
                                autoOk={true}
                                shouldDisableDate={modalData.disableUsedSingleAdjustDates}
                            />
                        </MuiPickersUtilsProvider>
                        {modalData.state.error.includes("date") ? <span class="error-message">{'* Please enter Date'}</span> : ''}
                    </FormControl>
                    <FormControl margin="normal" required className={classes.fullWidth}>
                        <InputLabel htmlFor="adjustment">{t("Daily Adjustment")}</InputLabel>
                        <Input
                            type="number"
                            fullWidth
                            error={modalData.state.error.includes("percentage")}
                            onChange={modalData.handleDailyAdjustModal('percentage')}
                            endAdornment={'%'}
                            value={modalData.state.addModalData.percentage}
                        />
                        {modalData.state.error.includes("percentage") ? <span class='error-message'>{t('* Please enter Percentage')}</span> : ''}
                    </FormControl>
                </DialogContent>
                <DialogActions>
                   
                    <Button onClick={modalData.addNewDailyAdjust} variant="contained" color="primary" >
                        {t("Save")}
                    </Button>
                    <Button onClick={modalData.toggleDailyAdjustModal} className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    editDailyAdjustModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.openEditDailyAdjustModal}
                onClose={() => modalData.toggleEditDailyAdjust()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Edit single date adjustment")}</DialogTitle>
                <DialogContent>
                    <FormControl margin="normal" required className={classes.fullWidth}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                minDate={moment()}
                                error={modalData.state.error.includes("edit_date")}
                                value={modalData.state.editDailyAdjustModal.date}
                                onChange={modalData.handleEditDailyAdjustModal('date')}
                                format={"dd/MM/yyyy"}
                                InputLabelProps={{ shrink: true }}
                                autoOk={true}
                                shouldDisableDate={modalData.disableUsedSingleAdjustDates}
                                disabled={true}
                            />
                        </MuiPickersUtilsProvider>
                        {modalData.state.error.includes("edit_date") ? <span class="error-message">{t('* Please enter Date')}</span> : ''}
                    </FormControl>
                    <FormControl margin="normal" required className={classes.fullWidth}>
                        <InputLabel htmlFor="adjustment">{t("Daily Adjustment")}</InputLabel>
                        <Input
                            error={modalData.state.error.includes("edit_percentage")}
                            id="standard-name"
                            type="number"
                            margin="normal"
                            value={modalData.state.editDailyAdjustModal.percentage}
                            onChange={modalData.handleEditDailyAdjustModal('percentage')}
                            fullWidth
                            endAdornment={'%'}
                        />
                        {modalData.state.error.includes("edit_percentage") ? <span class='error-message'>{t('* Please enter Percentage')}</span> : ''}
                    </FormControl>

                </DialogContent>
                <DialogActions>
                   
                    <Button onClick={modalData.editDailyAdjust} variant="contained" color="primary" >
                        {t("Save")}
                    </Button>
                    <Button onClick={() => modalData.toggleEditDailyAdjust()} className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    DeleteDailyAdjustModal: function (modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.openDeleteDailyAdjustModal}
                onClose={() => modalData.toggleDeleteDailyAdjust()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Delete single date adjustment")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("Are you sure you want to delete this date?")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={modalData.deleteDailyAdjust} color="secondary" autoFocus  className="dangerBtnHvr">
                        {t("Delete")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    UserDetailmodal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            open={modalData.state.open}
            onClose={modalData.handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t("Add new Contact Person")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <TextField
                        id="standard-name"
                        label={t("Hotel Name")}
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        id="standard-name"
                        label={t("Hotel Name")}
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        id="standard-name"
                        label={t("Hotel Name")}
                        margin="normal"
                        fullWidth
                    />
                    <FormLabel className={classes.emailLabel} component="legend">Email Notification</FormLabel>
                    <RadioGroup
                        aria-label={t("Level")}
                        name="level1"
                        className={classes.RadioGroup}
                        value={modalData.state.value}
                        onChange={modalData.handleChange}
                    >
                        <FormControlLabel value="female" control={<Radio color="primary"/>} label="Daily" />
                        <FormControlLabel value="male" control={<Radio color="primary"/>} label="Weekly" />
                        <FormControlLabel value="other" control={<Radio color="primary"/>} label="No Thanks" />
                    </RadioGroup>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
               
                <Button onClick={modalData.handleClose} variant="contained" color="primary" >
                    {t("Save")}
                </Button>
                <Button onClick={modalData.handleClose} className="cancelBtn" >
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>)
    },
    addNewRoomModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        let currAdorn = <Typography variant="subtitle1" className={classes.adornment}>{modalData.state.addNewModalData.currencySymbol}</Typography>;
        let absoluteAdorm = <Typography variant="subtitle1" className={classes.adornment}>{(modalData.state.isAbsolute ? modalData.state.addNewModalData.currencySymbol : '%')}</Typography>;

        let rateList = [{ value: "", label: "Not Priced" }];
        let ratevalue = { value: "", label: "Not Priced" };
        let defaultOcc="-"
        const RateComponent = (props) => (<React.Fragment>{<FormLabel style={{ fontSize: "14px", fontWeight: "500", marginLeft: "1px", color: "#494949" }}>{(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider && (modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 25 || modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 26)) ? t("Daily Price") : t(`Base Rate in ${modalData.state.pms_channel_manager_text}`)}</FormLabel>}<components.Control {...props} /></React.Fragment>);

        if (modalData.state.addNewModalData.temp_rate_list && modalData.state.addNewModalData.temp_rate_list.length > 0) {
            modalData.state.addNewModalData.temp_rate_list.sort((a, b) => {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            }).map(option => {

                if (modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 20) {
                    rateList.push({ value: option.id, label: (option.name) })
                } else {
                    rateList.push({ value: option.id, label: (option.name + " " + (option.rate_id ? "(" + option.rate_id + ")" : "")) })
                }
                if (option.id == modalData.state.addNewModalData.pms_rate) {
                    defaultOcc=option.default_occupancy
                    ratevalue = { value: option.id, label: (option.name + " " + (option.rate_id ? "(" + option.rate_id + ")" : "")) };
                }
            })
        }

        let rateListDerivation = [{ value: "", label: "Not Priced" }];
        let pmsRates = [];
        let temp_rate_list = [];
        if(modalData.state.pricePerRate){
            modalData.state.pricePerRate.map(val=>pmsRates.push(val.pms_rate));
        }
        if (modalData.state.addNewModalData.temp_rate_list && modalData.state.addNewModalData.temp_rate_list.length > 0) {
            modalData.state.addNewModalData.temp_rate_list.sort((a, b) => {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            }).map(option => {
                if(ratevalue.value != option.id){
                    temp_rate_list.push(option)
                    rateListDerivation.push({ value: option.id, label: (option.name + " " + (option.rate_id ? "(" + option.rate_id + ")" : "")) })
                }
            })
        }
        const DerivedRateComponent = (props) => (<> {<FormLabel style={{ fontSize: "14px", fontWeight: "500", marginLeft: "1px", color: "#494949" }}>{props.selectProps.indexDisplay}{". "}{t(`Derived Rate in ${modalData.state.pms_channel_manager_text}`)}</FormLabel>}<components.Control {...props} /></>);
        
        

        const SelectStyle = {
            control: (base, state) => ({
                ...base,
                // border: "0",
                height: "40px",
                border: 'none',
                boxShadow: "0 !important",
                width: '100%',
                borderRadius: "4px !important",
                background: "#f2f2f2",
                textAlign: 'left',
                marginTop:"2px",
                "&:hover": {
                    border: 'none',
                },
                "&:focus-within": {
                    border: 'none',
                },
                "&:active": {
                    border: 'none',
                },
            })
        };
        
        let minOccupancy;
        let maxOccupancy;
        
        modalData.state.pmsList.mapped_data.map((val) => {
            if (val.id == modalData.state.addNewModalData.pms_room) {
                minOccupancy = val.min_occupancy;
                maxOccupancy = val.max_occupancy;
            }
        })
        const stateProvider= modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider
        return (

            <SwipeableDrawer
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="right"
                // variant="permanent"
                open
                onOpen={() => false}
                onClose={modalData.toggleNewRoomModal}
                PaperProps={{ style: { maxWidth: "690px", padding: "10px 25px 10px 25px", overflow: "hidden" } }}

            >
                <div style={{ marginTop: '10px', padding:"6px 25px" }}>
                    <span style={{ fontSize: '1.25rem', fontWeight: '500' }} id="responsive-dialog-title">{t(`Add New ${modalData.state.room_apartment_space_name}`)}</span>
                </div>
                <IconButton aria-label="Close" className={classes.closeButtonPrice} onClick={modalData.toggleNewRoomModal} style={{ position: "absolute", right: "0",marginTop:"10px" }}>
                    <CloseIcon />
                </IconButton>

                <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="on"
                    value={modalData.state.newRoomTab}
                    onChange={modalData.handleNewTabs}
                    style={{  margin: "10px 0px 14px 0px", float: "right", borderBottom: '1px solid #f0f0f0' }}
                >
                    <Tab label={t(`${modalData.state.pms_channel_manager_text} Mapping`)} value={0} />
                    {(modalData.state.all_room_pricing || modalData.state.addNewModalData.is_reference_room) &&
                        <Tab label={t("Pricing Setup")} value={1} />
                    }
                    {modalData.state.pmsProviderList.includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) &&
                     ((modalData.state.editModalData.pms_extra != 'fixed price' && modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider !== 71 ) || (modalData.state.currentData?.configurations?.price_type === 'occupancy_based' && modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 71 ) ) &&
                        <Tab label={t("Occupancy Pricing")} value={2} />
                    }
                    {[46, 1, 40, 54, 58, 59, 60, 63].includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) &&
                        <Tab label={t("Derived Rates")} value={3} />
                    }
                </Tabs>
                <div className={classes.popupDiv} style={{ marginBottom: '65px',padding:"10px 25px", }}>
                    <Grid container>
                        {modalData.state.newRoomTab == 0 &&
                            <Grid xs={10} md={10} item style={{ paddingRight: "0px" }}>
                                <Grid xs={12} item>
                                    <FormControl fullWidth margin="normal" required className={classes.fieldLeft}>
                                        <InputLabel htmlFor="name" shrink={true}>{t(`${modalData.state.room_apartment_space_name} in ${modalData.state.pms_channel_manager_text}`)}</InputLabel>
                                        <Select
                                            error={modalData.state.error.includes("pms_room")}
                                            displayEmpty
                                            id="pms_room"
                                            name="pms_room"
                                            className={classes.textField}
                                            value={modalData.state.addNewModalData.pms_room}
                                            onChange={modalData.handlePmsRoomChange()}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            style={(modalData.state.addNewModalData.pms_room) ? {} : { color: "#d32f2f" }}
                                            endAdornment={<CustomTooltip title="" description={t(`Please select the ${modalData.state.room_apartment_space_name} from your ${modalData.state.pms_channel_manager_text} or channel manager that you would like to map to this ${modalData.state.room_apartment_space_name.toLowerCase()} in RoomPriceGenie.`)}></CustomTooltip>}
                                        >
                                            <MenuItem key={-1} value='' style={{ color: "#d32f2f" }}>{t("Please Map")}</MenuItem>
                                            {modalData.state.pmsList.mapped_data && modalData.state.pmsList.mapped_data.filter(option => option.connected_room === null).sort((a, b) => {
                                                if (a.name < b.name) { return -1; }
                                                if (a.name > b.name) { return 1; }
                                                return 0;
                                            }).map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                   {option.room_id ? ' ('+option.room_id+')' :""}
                                                   {option.property_code ? ' ('+option.property_code+')':"" }
                                                   { [70, 46, 25, 26].includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) && option.extra ? ' ('+option.extra+')':"" }
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {modalData.state.error.includes("pms_room") ? <span class='error-message'>{t(`* ${modalData.state.room_apartment_space_name} in ${modalData.state.pms_channel_manager_text}`)}</span> : ''}
                                    </FormControl>
                                </Grid>
                                {!([8, 17, 35, 39, 42, 52, 67].includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider)) &&
                                    <Grid xs={12} item>
                                        <FormControl fullWidth margin="normal" required className={classes.fieldLeft}>
                                            <ReactSelect
                                                components={{ Control: RateComponent }}
                                                value={ratevalue}
                                                styles={SelectStyle}
                                                className={classes.textField}
                                                onChange={modalData.handleAddNewRoom('pms_rate')}
                                                options={rateList}
                                            />
                                        </FormControl>

                                    </Grid>
                                }
                                {(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider && (modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 8 || modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 17 || modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 25 || modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 26)) &&
                                    <Grid xs={12} item>
                                        <FormControl fullWidth margin="normal" required className={classes.fieldLeft}>
                                            <InputLabel shrink={true} htmlFor="pms_rate">{t(`${modalData.state.room_apartment_space_name} Occupancy for Pricing`)}</InputLabel>
                                            <Select
                                                error={modalData.state.error.includes("pms_price_applicable_occupancy")}
                                                id="pms_price_applicable_occupancy"
                                                name="pms_price_applicable_occupancy"
                                                className={classes.textField}
                                                value={modalData.state.addNewModalData.pms_price_applicable_occupancy}
                                                onChange={modalData.handleAddNewRoom('pms_price_applicable_occupancy')}
                                                SelectProps={{
                                                    MenuProps: {
                                                        className: classes.menu,
                                                    },
                                                }}
                                            // endAdornment={<CustomTooltip title="" description={t("The rate that we will read from your system, and the rate that we will adjust when we put prices into PMS. Please mind the note below.")}></CustomTooltip>}
                                            >   <MenuItem key={-1} value=''>-</MenuItem>
                                                {modalData.state.addNewModalData.tempOccList.map(option => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.applicable_guest}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {/* {modalData.state.error.includes("pms_rate") ? <span class='error-message'>{t('* Please select your currency')}</span> : ''} */}
                                        </FormControl>
                                    </Grid>
                                }
                                {(modalData.state.currentData?.configurations?.price_type !== 'occupancy_based' && modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 71 ) &&
                                    <Grid xs={12} item>
                                        <FormControl fullWidth  className={classes.fieldLeft} margin="normal">
                                            <InputLabel shrink={true} htmlFor="name">{t("Default Occupancy")}</InputLabel>
                                            <Input
                                                id="standard-name"
                                                error={modalData.state.error.includes("default_occupancy")}
                                                className={classes.textField}
                                                onChange={modalData.handleAddNewRoom('default_occupancy')}
                                                value={modalData.state.addNewModalData.default_occupancy}
                                                margin="normal"
                                            />
                                            {modalData.state.error.includes("default_occupancy") ? <span class='error-message'>{t('* Please enter Default Occupancy')}</span> : ''}
                                        </FormControl>
                                    </Grid>        
                                }
                                {(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider && (modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 46)) && 
                                    <Grid xs={12} item>
                                        <FormControl fullWidth  className={classes.fieldLeft} margin="normal">
                                            <InputLabel shrink={true} htmlFor="name">{t("Reference Occupancy")}</InputLabel>
                                            <Input
                                                id="standard-name"
                                                disabled={true}
                                                className={classes.textField}
                                                value={defaultOcc}
                                                margin="normal"
                                            />
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid xs={12} item>
                                    <FormControl fullWidth margin="normal" required className={classes.fieldLeft}>
                                        <InputLabel shrink={true} htmlFor="name">{t("Name")}</InputLabel>
                                        <Input
                                            error={modalData.state.error.includes("name")}
                                            id="name"
                                            name="name"
                                            className={classes.textField}
                                            onChange={modalData.handleAddNewRoom('name')}
                                            value={modalData.state.addNewModalData.name}
                                            endAdornment={<CustomTooltip title="" description={t(`Please give this ${modalData.state.room_apartment_space_name.toLowerCase()} a name in RoomPriceGenie. For simplicity we recommend the same name as in your ${modalData.state.pms_channel_manager_text}.`)}></CustomTooltip>}
                                            placeholder={t(`Please enter ${modalData.state.room_apartment_space_name} Name` )}
                                        />
                                        {modalData.state.error.includes("name") ? <span class='error-message'>{t(`* Please enter  ${modalData.state.room_apartment_space_name.toLowerCase()} name`)}</span> : ''}
                                    </FormControl>
                                </Grid>
                                {/* {(modalData.state.featuresEnabled.includes(9) || modalData.state.featuresEnabled.includes(8)) && */}
                                <Grid xs={12} item>
                                    <FormControl fullWidth margin="normal" required className={classes.fieldLeft}>
                                        <InputLabel shrink={true} htmlFor="name">{t(`Number of ${modalData.state.room_apartment_space_name}s`)}</InputLabel>
                                        <Input
                                            // disabled={modalData.state.virtual_room_type}
                                            type='number'
                                            error={modalData.state.error.includes("number_of_rooms")}
                                            id="number_of_rooms"
                                            name="number_of_rooms"
                                            placeholder={t(`Please enter Number of ${modalData.state.room_apartment_space_name.toLowerCase()}` )}
                                            className={classes.textField}
                                            onChange={modalData.handleAddNewRoom('number_of_rooms')}
                                            value={modalData.state.addNewModalData.number_of_rooms}
                                            endAdornment={<CustomTooltip title="" description={t(`How many of this ${modalData.state.room_apartment_space_name} Type do you have in your hotel? The total of all the ${modalData.state.room_apartment_space_name.toLowerCase()} types should add up to the total number of ${modalData.state.room_apartment_space_name.toLowerCase()} in the hotel.`)}></CustomTooltip>}
                                        />
                                        {modalData.state.error.includes("number_of_rooms") ? <span class='error-message'>{t(`* Please enter Number of ${modalData.state.room_apartment_space_name}s`)}</span> : ''}
                                    </FormControl>
                                </Grid>
                                {!modalData.state.editModalData.is_reference_room &&
                                <Grid xs={12} item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={modalData.state.virtual_room_type}                        
                                                onChange={(event) => modalData.handleVirtualRoomType(event)}
                                                color="primary"
                                            />
                                        }
                                        label={t(`Virtual ${modalData.state.room_apartment_space_name} Type`)}
                                        style={{ marginRight: '5%' }}
                                    />
                                </Grid>
                                }
                                {modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 38 &&
                                    <Grid xs={12} item>
                                        <FormControl fullWidth margin="normal" required className={classes.fieldLeft}>
                                            <InputLabel shrink={true} htmlFor="name">{t("Price Type")}</InputLabel>
                                            <Select
                                                className={classes.textField}
                                                value={modalData.state.addNewModalData.extra ? modalData.state.addNewModalData.extra : "occupancy_based"}
                                                onChange={modalData.handleAddNewRoom('extra')}
                                                SelectProps={{
                                                    MenuProps: {
                                                        className: classes.menu,
                                                    },
                                                }}
                                            >
                                                <MenuItem key={1} value='occupancy_based'>Occupancy Based</MenuItem>
                                                <MenuItem key={2} value='unit_based'>Unit Based</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }

                                {/* {((modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 38 && modalData.state.addNewModalData.extra == "occupancy_based") || (modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 34 && modalData.state.addNewModalData.pms_extra == "occupancy_based") || (modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 40)) &&
                                    <Grid xs={12} item>
                                        <FormControl fullWidth margin="normal" className={classes.fieldLeft}>
                                            <InputLabel shrink={true} htmlFor="name">{t("Default Occupancy")}</InputLabel>
                                            <Input
                                                type="number"
                                                id="standard-name"
                                                className={classes.textField}
                                                onChange={modalData.handleAddNewRoom('default_occupancy')}
                                                value={modalData.state.addNewModalData.default_occupancy}
                                                margin="normal"
                                                
                                            />
                                        </FormControl>
                                    </Grid>
                                } */}
                            </Grid>
                        }
                        {modalData.state.newRoomTab == 1 && (modalData.state.all_room_pricing) &&
                            <Grid xs={10} md={10} item style={{ paddingLeft: "0px" }} >
                                <Grid xs={12} item>
                                    <FormControl fullWidth required margin="normal" className={classes.fieldLeft}>
                                        <InputLabel shrink={true} htmlFor="name">{t("Base Price")}</InputLabel>
                                        <Input
                                            type="number"
                                            error={modalData.state.error.includes("avg_price")}
                                            id="avg_price"
                                            name="avg_price"
                                            placeholder={t(`Please enter Base Price`)}
                                            className={classes.textField}
                                            onChange={modalData.handleAddRoomPrice('avg_price')}
                                            value={modalData.state.addNewModalData.avg_price}
                                            startAdornment={currAdorn}
                                            endAdornment={<CustomTooltip title="" description={t(`The Base Price is the most important number in your system. You can think of it as the average price for this ${modalData.state.room_apartment_space_name.toLowerCase()} and rate over the whole year. It’s not your ADR but more an average BAR.  In general, if you move it up by $50 then all your prices will go up by an average of around $50. Please be very careful changing it. You can read more in the Help Center.`)}></CustomTooltip>}
                                        />
                                        {modalData.state.error.includes("avg_price") ? <span class='error-message'>{t('* Please enter Base Price')}</span> : ''}
                                    </FormControl>

                                    {modalData.state.featuresEnabled.includes(6) &&
                                        <Grid xs={12} item>
                                            <FormControl fullWidth required className={classes.fieldLeft} margin="normal">
                                                <InputLabel shrink={true} htmlFor="name">{t("Default Minimum Price")}</InputLabel>
                                                <Input
                                                    type="number"
                                                    id="standard-name"
                                                    placeholder={t(`Please enter Default Minimum Price`)}
                                                    className={classes.textField}
                                                    onChange={modalData.handleAddNewRoom('min_price')}
                                                    value={modalData.state.addNewModalData.min_price}
                                                    margin="normal"
                                                    startAdornment={currAdorn}
                                                    endAdornment={<CustomTooltip title="" description={t(`The Default Minimum Price is the price that we will not sell this ${modalData.state.room_apartment_space_name.toLowerCase()} below. For example, if you set a minimum of $99 and we will never set this ${modalData.state.room_apartment_space_name.toLowerCase()} and rate below $99 unless you specifically over-ride it. To over-ride the Default Minimum, you can either use ‘Fix Price’ to fix the price below. Or you can change the minimum (up or down) on an individual day basis, using the Adjustment View. Find out more about minimum and maximum prices in our Help Center.`)}></CustomTooltip>}
                                                />
                                                {modalData.state.error.includes("min_price") ? <span class='error-message'>{t('* Please enter Min Price')}</span> : ''}
                                            </FormControl>
                                        </Grid>
                                    }

                                    {modalData.state.featuresEnabled.includes(6) &&
                                        <Grid xs={12} item>
                                            <FormControl fullWidth required margin="normal" className={classes.fieldLeft}>
                                                <InputLabel shrink={true} htmlFor="name">{t("Default Maximum Price")}</InputLabel>
                                                <Input
                                                    type="number"
                                                    id="standard-name"
                                                    className={classes.textField}
                                                    onChange={modalData.handleAddNewRoom('max_price')}
                                                    value={modalData.state.addNewModalData.max_price}
                                                     placeholder={t(`Please enter Default Maximum Price`)}
                                                    margin="normal"
                                                    startAdornment={currAdorn}
                                                    endAdornment={<CustomTooltip title="" description={t(`The Default Maximum Price is the price that we will not sell this ${modalData.state.room_apartment_space_name.toLowerCase()} above. For example, if you set a maximum of $299 and we will never set this ${modalData.state.room_apartment_space_name.toLowerCase()} and rate above $299 unless you specifically over-ride it. To over-ride the Default Maximum, you can either use ‘Fix Price’ to fix the price higher. Or you can change the maximum (up or down) on an individual day basis, using the Adjustment View. Find out more about minimum and maximum prices in our Help Center.`)}></CustomTooltip>}
                                                />
                                                {modalData.state.error.includes("max_price") ? <span class='error-message'>{t('* Please enter Max Price')}</span> : ''}
                                                {modalData.state.error.includes("max_price_error") ? <span class='error-message'>{t('Max Price Should Be Greater Than Min Price')}</span> : ''}
                                            </FormControl>
                                        </Grid>
                                    }
                                </Grid>

                                {([24, 60, 61].includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider)) &&
                                    <Grid xs={12} item>
                                        <FormControl fullWidth margin="normal" required className={classes.fieldLeft}>
                                            <InputLabel shrink={true} htmlFor="name">{t("Additional Price Per Person")}</InputLabel>
                                            <Input
                                                error={modalData.state.error.includes("discount_per_person")}
                                                id="discount_per_person"
                                                name="discount_per_person"
                                                className={classes.textField}
                                                onChange={modalData.handleAddNewRoom('discount_per_person')}
                                                value={modalData.state.addNewModalData.discount_per_person}
                                                placeholder={t(`Please enter Price per Person`)}
                                                startAdornment={currAdorn}
                                            />
                                            {modalData.state.error.includes("discount_per_person") ? <span class='error-message'>{t('* Please enter a value > 0')}</span> : ''}
                                        </FormControl>
                                    </Grid>
                                }

                                {(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 8 || modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 17) &&
                                    <Grid xs={12} item>
                                        <FormControl fullWidth margin="normal" required className={classes.fieldLeft}>
                                            <InputLabel shrink={true} htmlFor="name">{t("Category")}</InputLabel>
                                            <Select
                                                id="pms_room"
                                                name="pms_room"
                                                className={classes.textField}
                                                value={modalData.state.addNewModalData.use_prices_from ? modalData.state.addNewModalData.use_prices_from : ''}
                                                onChange={modalData.chnageuse_prices_from()}
                                                SelectProps={{
                                                    MenuProps: {
                                                        className: classes.menu,
                                                    },
                                                }}
                                                endAdornment={<CustomTooltip title="" description={t(`Select the name of this ${modalData.state.room_apartment_space_name.toLowerCase()} type in your ${modalData.state.pms_channel_manager_text}.`)}></CustomTooltip>}
                                            >
                                                <MenuItem key={-1} value=''>-</MenuItem>
                                                {modalData.state.addNewModalData.temp_pmsList.map(option => (
                                                    <MenuItem key={option.id} value={option.room_id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid xs={12} item>
                                    <FormControl fullWidth margin="normal" required className={classes.fieldLeft} style={{ width: "100%" }}>
                                        <InputLabel shrink={true} htmlFor="name">{t(`Derivation from Reference ${modalData.state.room_apartment_space_name}`)}</InputLabel>
                                        <Input
                                            type="number"
                                            error={modalData.state.error.includes("handleRate")}
                                            id="handleRate"
                                            name="handleRate"
                                            className={[classes.textField]}
                                            onChange={modalData.handleAddRoomPrice('handleRate')}
                                            value={modalData.state.addNewModalData.handleRate}
                                            placeholder={t(`Please enter Derivation from Reference ${modalData.state.room_apartment_space_name}`)}
                                            startAdornment={absoluteAdorm}
                                        />
                                        {modalData.state.error.includes("handleRate") ? <span class='error-message'>{t("* Please enter rate")}</span> : ''}
                                    </FormControl>
                                </Grid>
                                {(modalData.props.userSession.user.is_staff || modalData.props.userSession.user.sub_account_type) &&
                                    <Grid xs={12} item>                                            
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={modalData.state.ignore_upload_count}
                                                    value={modalData.state.ignore_upload_count}
                                                    onChange={(event) => modalData.handleDontCountUpload(event)}
                                                    color="primary"
                                                />
                                            }
                                            label={t(`Don't count uploads`)}
                                            style={{ marginRight: '5%' }}
                                        />                                            
                                    </Grid>
                                }
                            </Grid>
                        }
                        {modalData.state.newRoomTab == 2 &&
                            <Grid xs={10} md={10} item style={{ paddingLeft: "0px" }}>
                                    {/* <Typography style={{ fontWeight: "500", fontSize: "16px", color: "#000000de" }} align="left" >{t("Pricing Setup")}</Typography> */}
                                    <Grid xs={12} item>
                                        <FormControl fullWidth margin="normal" className={classes.fieldLeft} >
                                            <InputLabel shrink={true} style={{ whiteSpace: 'nowrap' }} htmlFor="name">{t("Default Occupancy")}</InputLabel>
                                            <Input
                                                type="number"
                                                error={modalData.state.pmsProviderList.includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) ? modalData.state.error.includes("default_occupancy") : ''}
                                                id="default_occupancy"
                                                name="defaultOccupancy"
                                                className={classes.textField}
                                                onChange={modalData.handleAddNewRoom('default_occupancy')}
                                                value={modalData.state.addNewModalData.default_occupancy}
                                                placeholder={t(`Please enter Default Occupancy`)}
                                                margin="normal"
                                                endAdornment={<CustomTooltip title="" description={t("The calendar displays prices for your default occupancy.")}></CustomTooltip>}
                                            />

                                            {modalData.state.pmsProviderList.includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) ? modalData.state.error.includes("default_occupancy") ? <span class='error-message'>{t('* Please enter Default Occupancy')}</span> : '' : ''}
                                            {modalData.state.pmsProviderList.includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) ? modalData.state.error.includes("default_occupancy_min_max") ? <span class='error-message'>{t('* Please enter value between Occupancy ' + minOccupancy + ' and ' + maxOccupancy)}</span> : '' : ''}
                                        </FormControl>
                                    </Grid>
                                    
                                    {minOccupancy > 0 &&
                                        <Grid xs={12} item>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>{t("Occupancy (Persons)")}</TableCell>
                                                        <TableCell>{t("Price Difference from Default Occupancy Price")}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {modalData.state.newOccupancyPrices.map((val, index) => (
                                                        <>
                                                            {/* {(modalData.state.editModalData.default_occupancy != val.occupancy) && */}
                                                                <TableRow>
                                                                    <TableCell component="th" scope="row" style={{ marginTop: '20px', marginBottom: '20px', textAlign: 'right' }}>
                                                                        {val.occupancy}
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" style={{ marginBottom: '15px' }}>
                                                                    {(modalData.state.addNewModalData.default_occupancy == val.occupancy ) ?
                                                                        <span style={{fontSize:'16px'}}>{t('Base Price:') + modalData.state.addNewModalData.avg_price ? modalData.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol +modalData.state.addNewModalData.avg_price :''} </span>
                                                                        :
                                                                        <>
                                                                        <Input
                                                                            type="number"
                                                                            id="occupancy_pricing"
                                                                            error={modalData.state.error.includes(index)}
                                                                            className={classes.textField}
                                                                            style={{ marginBottom: '15px' }}
                                                                            value={val.derivation}
                                                                            onChange={(e) => modalData.handleNewRoomOccPricingDiff(e, val, index)}
                                                                            startAdornment={(modalData.state.addNewModalData.default_occupancy < val.occupancy) ? currAdorn : <span className={classes.adornment} style={{whiteSpace:'nowrap'}}>{(modalData.state.addNewModalData.currencySymbol +" "+" - "+" " )}</span>}
                                                                        />
                                                                        {/* {modalData.state.error.includes(index) ? <span class='error-message'>{t('* Please enter Price Difference')}</span> : ''} */}
                                                                        </>}

                                                                    </TableCell>
                                                                </TableRow>
                                                            {/* } */}
                                                        </>
                                                    ))}
                                                    
                                                </TableBody>
                                            </Table>
                                            {console.log('MMM',modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider)}
                                            {([28].includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider)) &&
                                                <Grid xs={12} item>
                                                    <FormControl fullWidth margin="normal" required className={classes.fieldLeft}>
                                                        <InputLabel shrink={true} htmlFor="name">{ t("Extra Adult") }</InputLabel>
                                                        <Input
                                                            error={modalData.state.error.includes("discount_per_person")}
                                                            id="discount_per_person"
                                                            name="discount_per_person"
                                                            className={classes.textField}
                                                            onChange={modalData.handleAddNewRoom('discount_per_person')}
                                                            value={modalData.state.addNewModalData.discount_per_person}
                                                            placeholder={t(`Please enter Extra Adult`)}
                                                            startAdornment={currAdorn}
                                                        />
                                                        {modalData.state.error.includes("discount_per_person") ? <span class='error-message'>{t('* Please enter a value > 0')}</span> : ''}
                                                    </FormControl>
                                                    <FormControl fullWidth margin="normal" required className={classes.fieldLeft}>
                                                        <InputLabel shrink={true} htmlFor="name">{ t("Extra Child") }</InputLabel>
                                                        <Input
                                                            error={modalData.state.error.includes("extra_child")}
                                                            id="extra_child"
                                                            name="extra_child"
                                                            className={classes.textField}
                                                            onChange={modalData.handleAddNewRoom('extra_child')}
                                                            value={modalData.state.addNewModalData.extra_child}
                                                            startAdornment={currAdorn}
                                                        />
                                                        {modalData.state.error.includes("extra_child") ? <span class='error-message'>{t('* Please enter a value > 0')}</span> : ''}
                                                    </FormControl>
                                                </Grid>
                                            }
                                        </Grid>
                                    }
                                    {modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 44 && modalData.state.addNewModalData.maxOccupancy > 1 &&
                                        <Grid xs={12} item>
                                            <FormControl fullWidth margin="normal" className={classes.fieldLeft} >
                                                <InputLabel shrink={true} style={{ whiteSpace: 'nowrap' }} htmlFor="name">{t("+1 child additional fee (2 adults)")}</InputLabel>
                                                <Input
                                                    type="number"
                                                    id="discount_per_person"
                                                    // name="defaultOccupancy"
                                                    className={classes.textField}
                                                    onChange={modalData.handleAddNewRoom('discount_per_person')}
                                                    placeholder={t(`Please enter +1 child additional fee (2 adults)`)}
                                                    margin="normal"
                                                    startAdornment={<Typography variant="subtitle1" className={classes.adornment}>{(modalData.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol)}</Typography>}
                                                />  
                                            </FormControl>
                                        </Grid>
                                    }
                                </Grid>
                        }
                        {modalData.state.newRoomTab == 3 &&
                            <>
                                {modalData.state.pricePerRate && modalData.state.pricePerRate.map((val,index) => {
                                    return (    
                                        <Grid container className={classes.derivedCont} alignItems="flex-end">
                                            {/* <Grid item xs={12}>
                                                <> */}
                                                <Grid xs={10} item>
                                                    <FormControl fullWidth margin="normal" className={classes.fieldLeft} >
                                                        <ReactSelect
                                                            components={{ Control: DerivedRateComponent }}
                                                            value={val.pms_rate ?  rateListDerivation.filter(v=>v.value == val.pms_rate)[0] :  null}
                                                            styles={SelectStyle}
                                                            className={classes.textField}
                                                            onChange={modalData.handleDerivedRates('pms_rate',index)}
                                                            indexDisplay={index + 1 }
                                                            options={rateListDerivation.filter(option=>(!pmsRates.includes(option.value) || val.pms_rate == option.value))}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                   {(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider && (modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 46)) && 
                                                        <Grid xs={10} item>
                                                            <FormControl fullWidth  className={classes.fieldLeft} margin="normal">
                                                                <InputLabel shrink={true} htmlFor="name">{t("Reference Occupancy")}</InputLabel>
                                                                <Input
                                                                    id="standard-name"
                                                                    disabled={true}
                                                                    className={classes.textField}
                                                                    value={val.pms_rate ? ( temp_rate_list.filter(v=>v.id == val.pms_rate)[0] ? temp_rate_list.filter(v=>v.id == val.pms_rate)[0] .default_occupancy:"-" ):  "-"}
                                                                    placeholder={t(`Please enter Reference Occupancy`)}
                                                                    margin="normal"
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    }
                                                <Grid item xs={10}>
                                                    <FormControl fullWidth margin="normal" className={classes.fieldLeft} >
                                                        <InputLabel shrink={true} htmlFor="name">{t("Derivation")}</InputLabel>
                                                        <Input                                                    
                                                            type="text"                                      
                                                                // error={modalData.state.error.includes("derivation_price")}
                                                            id="derivation"
                                                            name="derivation"
                                                            className={classes.textField}
                                                            onChange={modalData.handleDerivedRates('derivation_price',index)}
                                                            value={val.derivation}
                                                            placeholder={t(`Please enter Derivation`)}
                                                            margin="normal"
                                                            startAdornment={val.is_absolute == true ? currAdorn : ''}
                                                            endAdornment={
                                                                <>
                                                                <span>
                                                                    {val.is_absolute == true ? ' ' : "%"}
                                                                </span>
                                                                <CustomTooltip title="" description={t("The Derivation Price is the most important number in your system. You can think of it as the average price for this room and rate over the whole year. It’s not your ADR but more an average BAR. In general, if you move it up by $50 then all your prices will go up by an average of around $50. Please be very careful changing it. You can read more in the Help Center.")}></CustomTooltip></>
                                                            }
                                                        />
                                                        {/* {modalData.state.error.includes("derivation_price") ? <span class='error-message'>{t('* Please enter Derivation Price')}</span> : ''} */}
                                                    </FormControl>
                                                {/* </Grid>
                                                </> */}
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <FormControlLabel
                                                        control={
                                                        <Checkbox
                                                        checked={val.is_absolute == true ? false:true}
                                                        color="primary"
                                                        onChange={ modalData.handleDerivedRates('derivation_percent',index)}
                                                        // value={index}
                                                        />
                                                        }
                                                        label={t("Percent Derivation")}
                                                    />
                                                    {[40, 60].includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) &&
                                                        <Tooltip
                                                            disableTouchListener={val.is_absolute}
                                                            disableFocusListener={val.is_absolute}
                                                            classes={{
                                                                popper: "toolTipPooper",
                                                                tooltip: classes.htmlTooltip,
                                                            }}
                                                            disableHoverListener={val.is_absolute}
                                                            title={t("Please turn off Percent Derivation to use Product Derivation")}
                                                        >
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                            disabled={!val.is_absolute}
                                                            checked={val.is_product_derivation}
                                                            color="primary"
                                                            onChange={ modalData.handleDerivedRates('is_product_derivation',index)}
                                                            // value={index}
                                                            />
                                                            }
                                                            label={t("Product Derivation")}
                                                        />
                                                        </Tooltip>
                                                        }
                                                </Grid>  
                                        </Grid>
                                    )})
                                }
                            </>
                        }   
                    </Grid>
                </div>
                <div className={`${classes.buttonGroupBottom } ${classes.sidePopupFooter}`} >
                    <div style={{ float: 'right' }} >                     

                        <Button disabled={modalData.state.saveRoomLoader} style={{ marginLeft: '5px'}} onClick={modalData.saveNewRoom} variant="contained" color="primary" >
                            {t("Save")} {modalData.state.saveRoomLoader && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                        </Button>
                        {modalData.state.tab == 1 && modalData.state.featuresEnabled.includes(6) && modalData.props.priceSettingReducer.priceSetting.hotel.all_room_pricing &&
                            <Button color="primary" variant="outlined" style={{ marginLeft: '5px',background:"white" }} onClick={modalData.setDefaultMin} >
                                {t("Derived Min/Max")}
                            </Button>
                        }
                        <Button  onClick={modalData.toggleNewRoomModal}  className="cancelBtn" style={{ marginLeft: '5px',background:"white" }}>
                                {t("Cancel")}
                            </Button> 
                    </div></div>

            </SwipeableDrawer>
        )
    },
    editRoomModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        let currAdorn = <Typography variant="subtitle1" className={classes.adornment}>{modalData.state.editModalData.currencySymbol}</Typography>;
        let absoluteAdorm = <Typography variant="subtitle1" className={classes.adornment}>{(modalData.state.isAbsolute ? modalData.state.editModalData.currencySymbol : '%')}</Typography>;
        const SelectStyle = {
            control: (base, state) => ({
                ...base,
                // border: "0",
                height: "40px",
                border: 'none',
                boxShadow: "0 !important",
                width: '100%',
                borderRadius: "4px !important",
                background: "#f2f2f2",
                textAlign: 'left',
                marginTop:"2px",
                "&:hover": {
                    border: 'none',
                },
                "&:focus-within": {
                    border: 'none',
                },
                "&:active": {
                    border: 'none',
                },
            }),
            menu: (base, state) => ({
                ...base,
                "& > div" :{
                    maxHeight:'250px'
                }
            }),  
        };
        let rateList = [{ value: "", label: "Not Priced" }];
        let ratevalue = { value: "", label: "Not Priced" };
        let defaultOcc="-"
        let id=""
        if (modalData.state.editModalData.temp_rate_list && modalData.state.editModalData.temp_rate_list.length > 0) {
            modalData.state.editModalData.temp_rate_list.sort((a, b) => {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            }).map(option => {
                rateList.push({ value: option.id, label: (option.name + " " + (option.rate_id ? "(" + option.rate_id + ")" : "")) })
                if (option.id == modalData.state.editModalData.pms_rate) {
                    id=option.id
                    defaultOcc=option.default_occupancy
                    ratevalue = { value: option.id, label: (option.name + " " + (option.rate_id ? "(" + option.rate_id + ")" : "")) };           
                }
            })
        }
        let rateListDerivation = [{ value: "", label: "Not Priced" }];
        let pmsRates = [];
        let temp_rate_list = [];
        if(modalData.state.pricePerRate){
            modalData.state.pricePerRate.map(val=>pmsRates.push(val.pms_rate));
        }
        if (modalData.state.editModalData.temp_rate_list && modalData.state.editModalData.temp_rate_list.length > 0) {
            modalData.state.editModalData.temp_rate_list.sort((a, b) => {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            }).map(option => {
                if(ratevalue.value != option.id){
                    temp_rate_list.push(option)                    
                    rateListDerivation.push({ value: option.id, label: (option.name + " " + (option.rate_id ? "(" + option.rate_id + ")" : "")) })
                }
            })
        }

        const DerivedRateComponent = (props) => (<>{<FormLabel style={{ fontSize: "14px", fontWeight: "500", marginLeft: "1px", color: "#494949" }}>{props.selectProps.indexDisplay}{". "}{t(`Derived Rate in ${modalData.state.pms_channel_manager_text}`)}</FormLabel>}<components.Control {...props} /></>);
        const RateComponent = (props) => (<React.Fragment>{<FormLabel style={{ fontSize: "14px", fontWeight: "500", marginLeft: "1px", color: "#494949" }}>{(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider && (modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 25 || modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 26)) ? t("Daily Price") : t(`Base Rate in ${modalData.state.pms_channel_manager_text}`)}</FormLabel>}<components.Control {...props} /></React.Fragment>);
        let minOccupancy;
        let maxOccupancy;
        modalData.state.pmsList.mapped_data.map((val) => {
            if (val.id == modalData.state.editModalData.pms_room) {
                minOccupancy = val.min_occupancy;
                maxOccupancy = val.max_occupancy;
            }
        }) 
        const stateProvider=modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider;     
  
        return (
            <>
                {/* <React.Fragment> */}
                <SwipeableDrawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    anchor="right"
                    open
                    onOpen={() => false}
                    onClose={modalData.closeEditRoomModal}
                    PaperProps={{ style: { maxWidth: "750px", padding: "10px 25px 10px 25px", overflow: "hidden" } }}

                >
                    <div style={{ padding:"6px 25px",marginTop: '10px',width:'inherit',textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap',minHeight:'40px',maxWidth:'85%' }}>
                        <span style={{ fontSize: '1.25rem', fontWeight: '500' }} id="responsive-dialog-title">{t("Edit")} {modalData.state.editModalData.title}</span>
                    </div>
                    <IconButton aria-label="Close" onClick={modalData.closeEditRoomModal} className={classes.closeButtonPrice} style={{ position: "absolute", right: "0" ,marginTop:"10px"}}>
                        <CloseIcon />
                    </IconButton>

                    <Tabs
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="on"
                        value={modalData.state.tab}
                        onChange={modalData.handleTabs}
                        style={{ float: "right", borderBottom: '1px solid #f0f0f0',marginTop:'26px' }}
                    >
                        
                        {(modalData.state.all_room_pricing || modalData.state.editModalData.is_reference_room) &&
                            <Tab label={t("Pricing Setup")} value={1} />
                        }

                        {modalData.state.pmsProviderList.includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) && 
                         ((modalData.state.editModalData.pms_extra != 'fixed price' && modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider !== 71 ) || (modalData.state.currentData?.configurations?.price_type === 'occupancy_based' && modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 71 ) ) &&
                            <Tab label={t("Occupancy Pricing")} value={2} />
                        }
                        <Tab label={t(`${modalData.state.pms_channel_manager_text} Mapping`)} value={0} />
                        {[46, 1, 40, 54, 58, 59, 60, 63].includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) &&
                            <Tab label={t("Derived Rates")} value={3} />
                        } 
                    </Tabs>
                    <div className={classes.popupDiv} style={{ padding:"10px 25px", }}>
                        <Grid container>
                            {modalData.state.tab == 0 &&
                                <Grid xs={10} md={10} item>
                                    {/* <Typography style={{ fontWeight: "500", fontSize: "16px", color: "#000000de" }} align="left">{t("PMS/Channel Manager Mapping")}</Typography> */}
                                    <Grid xs={12} item>
                                        <FormControl fullWidth required className={classes.fieldLeft} margin="normal">
                                            <InputLabel htmlFor="name" shrink={true}>{t(`${modalData.state.room_apartment_space_name} in ${modalData.state.pms_channel_manager_text}`)}</InputLabel>
                                            <Select
                                                id="standard-name"
                                                displayEmpty
                                                select
                                                className={classes.textField}
                                                value={(modalData.state.editModalData.pms_room) ? modalData.state.editModalData.pms_room : ''}
                                                onChange={modalData.handleEditPmsRoomChange()}
                                                SelectProps={{
                                                    MenuProps: {
                                                        className: classes.menu,
                                                    },
                                                }}
                                                // SelectDisplayProps={{ style: { maxWidth: "216px" } }}
                                                style={(modalData.state.editModalData.pms_room) ? {} : { color: "#d32f2f" }}
                                                margin="normal"
                                                endAdornment={<CustomTooltip title="" description={t(`Please select the ${modalData.state.room_apartment_space_name} from your ${modalData.state.pms_channel_manager_text} that you would like to map to this ${modalData.state.room_apartment_space_name.toLowerCase()} in RoomPriceGenie.`)}></CustomTooltip>}
                                            >
                                                <MenuItem key={-1} value='' style={{ color: "#d32f2f" }}>{t("Please Map")}</MenuItem>
                                                {modalData.state.pmsList.mapped_data.filter(option => (option.connected_room === null || (option.connected_room ? option.connected_room.id : option.connected_room) === modalData.state.editModalData.id)).sort((a, b) => {
                                                    if (a.name < b.name) { return -1; }
                                                    if (a.name > b.name) { return 1; }
                                                    return 0;
                                                }).map(option => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.name}
                                                        {option.room_id ? ' ('+option.room_id+')' :""}
                                                        {option.property_code ? ' ('+option.property_code+')':"" }
                                                        {[70, 46, 25, 26].includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) && option.extra ? ' ('+option.extra+')':"" }
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider && (modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 8 || modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 17 || modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 25 || modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 26)) &&
                                        <Grid xs={12} item>
                                            <FormControl fullWidth margin="normal" className={classes.fieldLeft} >
                                                <InputLabel shrink={true} htmlFor="name">{t(`${modalData.state.room_apartment_space_name} Occupancy for Pricing`)}</InputLabel>
                                                <Select
                                                    id="standard-name"
                                                    select
                                                    className={classes.textField}
                                                    value={modalData.state.editModalData.pms_price_applicable_occupancy}
                                                    onChange={modalData.handleEditRoom('pms_price_applicable_occupancy')}
                                                    SelectProps={{
                                                        MenuProps: {
                                                            className: classes.menu,
                                                        },
                                                    }}
                                                    margin="normal"
                                                // endAdornment={<CustomTooltip title="" description={t("The rate that we will read from your system, and the rate that we will adjust when we put prices into PMS. Please mind the note below.")}></CustomTooltip>}
                                                >
                                                    <MenuItem key={-1} value=''>-</MenuItem>
                                                    {modalData.state.editModalData.tempOccList.map(option => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.applicable_guest}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    }
                                    {!([8, 17, 35, 39, 42, 52, 67].includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider)) &&
                                        <Grid xs={12} item>
                                            <FormControl fullWidth margin="normal" className={classes.fieldLeft} >
                                                <ReactSelect
                                                    components={{ Control: RateComponent }}
                                                    value={ratevalue}
                                                    styles={SelectStyle}
                                                    className={classes.textField}
                                                    onChange={modalData.handleEditRoom('pms_rate')}
                                                    options={rateList}
                                                />
                                            </FormControl>
                                        </Grid>
                                    }  
                                    {(modalData.state.currentData?.configurations?.price_type !== 'occupancy_based' && modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 71 ) &&
                                        <Grid xs={12} item>
                                            <FormControl fullWidth  className={classes.fieldLeft} margin="normal">
                                                <InputLabel shrink={true} htmlFor="name">{t("Default Occupancy")}</InputLabel>
                                                <Input
                                                    id="default_occupancy"
                                                    error={modalData.state.error.includes("default_occupancy")}
                                                    className={classes.textField}
                                                    onChange={modalData.handleEditRoom('default_occupancy')}
                                                    value={modalData.state.editModalData.default_occupancy}
                                                    margin="normal"
                                                />
                                                {modalData.state.error.includes("default_occupancy") ? <span class='error-message'>{t('* Please enter Default Occupancy')}</span> : ''}
                                                {modalData.state.pmsProviderList.includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) ? modalData.state.error.includes("default_occupancy_min_max") ? <span class='error-message'>{t('* Please enter value between Occupancy ' + minOccupancy + ' and ' + maxOccupancy)}</span> : '' : ''}
                                            </FormControl>
                                        </Grid>        
                                    }
                                    {(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider && (modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 46)) && 
                                        <Grid xs={12} item>
                                            <FormControl fullWidth  className={classes.fieldLeft} margin="normal">
                                                <InputLabel shrink={true} htmlFor="name">{t("Reference Occupancy")}</InputLabel>
                                                <Input
                                                    id="standard-name"
                                                    disabled={true}
                                                    className={classes.textField}
                                                    value={defaultOcc}
                                                    placeholder={t(`Please enter Rference Occupancy`)}
                                                    margin="normal"
                                                />
                                            </FormControl>
                                        </Grid>
                                    }          
                                    <Grid xs={12} item>
                                        <FormControl fullWidth required className={classes.fieldLeft} margin="normal" >
                                            <InputLabel shrink={true} htmlFor="name">{t("Name")}</InputLabel>
                                            <Input
                                                error={modalData.state.error.includes("edit_name")}
                                                id="standard-name"
                                                className={classes.textField}
                                                onChange={modalData.handleEditRoom('name')}
                                                value={modalData.state.editModalData.name}
                                                placeholder={t(`Please enter Name`)}
                                                margin="normal"
                                                endAdornment={<CustomTooltip title="" description={t(`Please give this ${modalData.state.room_apartment_space_name.toLowerCase()} a name in RoomPriceGenie. For simplicity we recommend the same name as in your ${modalData.state.pms_channel_manager_text}.`)}></CustomTooltip>}
                                            />
                                            {modalData.state.error.includes("edit_name") ? <span class='error-message'>{t("* Please enter Hotel name")}</span> : ''}
                                        </FormControl>
                                    </Grid>
                                    {/* {(modalData.state.featuresEnabled.includes(9) || modalData.state.featuresEnabled.includes(8)) && */}
                                    <Grid xs={12} item>
                                        <FormControl fullWidth margin="normal" required className={classes.fieldLeft}>
                                            <InputLabel shrink={true} htmlFor="name">{t(`Number of ${modalData.state.room_apartment_space_name}s`)}</InputLabel>
                                            <Input
                                                type='number'
                                                // disabled={modalData.state.virtual_room_type}
                                                error={modalData.state.error.includes("number_of_rooms")}
                                                id="number_of_rooms"
                                                name="number_of_rooms"
                                                placeholder={t(`Please enter Number of ${modalData.state.room_apartment_space_name}s`)}
                                                className={classes.textField}
                                                onChange={modalData.handleEditRoom('number_of_rooms')}
                                                value={modalData.state.editModalData.number_of_rooms}
                                                endAdornment={<CustomTooltip title="" description={t(`How many of this ${modalData.state.room_apartment_space_name} Type do you have in your hotel? The total of all the ${modalData.state.room_apartment_space_name.toLowerCase()} types should add up to the total number of ${modalData.state.room_apartment_space_name.toLowerCase()}s in the hotel.`)}></CustomTooltip>}
                                            />
                                            {modalData.state.error.includes("number_of_rooms") ? <span class='error-message'>{t(`* Please enter Number of ${modalData.state.room_apartment_space_name}s`)}</span> : ''}
                                        </FormControl>
                                    </Grid>
                                     {!modalData.state.editModalData.is_reference_room &&
                                        <Grid xs={12} item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={modalData.state.virtual_room_type}                                                        
                                                        onChange={(event) => modalData.handleVirtualRoomType(event)}
                                                        color="primary"
                                                    />
                                                }
                                                label={t(`Virtual ${modalData.state.room_apartment_space_name} Type`)}
                                                style={{ marginRight: '5%' }}
                                            />
                                        </Grid>
                                    }
                                    {/* } */}


                                    {modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 38 &&
                                        <Grid xs={12} item>
                                            <FormControl fullWidth margin="normal" required className={classes.fieldLeft}>
                                                <InputLabel shrink={true} htmlFor="name">{t("Price Type")}</InputLabel>
                                                <Select
                                                    id="standard-name"
                                                    select
                                                    className={classes.textField}
                                                    value={modalData.state.editModalData.extra}
                                                    onChange={modalData.handleEditRoom('extra')}
                                                    SelectProps={{
                                                        MenuProps: {
                                                            className: classes.menu,
                                                        },
                                                    }}
                                                >
                                                    <MenuItem key={1} value='occupancy_based'>{t('Occupancy Based')}</MenuItem>
                                                    <MenuItem key={2} value='unit_based'>{t('Unit Based')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    }

                                    {((modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 35 && modalData.state.currentData.extra ==  "fixed price") || (modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 38 && modalData.state.editModalData.extra == "occupancy_based") || (modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 34 && modalData.state.editModalData.pms_extra == "occupancy_based") || (modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 40)) &&
                                        <Grid xs={12} item>
                                            <FormControl fullWidth required className={classes.fieldLeft} margin="normal">
                                                <InputLabel shrink={true} htmlFor="name">{t("Default Occupancy")}</InputLabel>
                                                <Input
                                                    type="number"
                                                    id="standard-name"
                                                    error={modalData.state.error.includes("default_occupancy")}
                                                    className={classes.textField}
                                                    onChange={modalData.handleEditRoom('default_occupancy')}
                                                    value={modalData.state.editModalData.default_occupancy}
                                                    placeholder={t(`Please enter Default Occupancy`)}
                                                    margin="normal"
                                                />
                                            </FormControl>
                                        </Grid>
                                    }
                                    {modalData.state.editModalData.is_reference_room &&
                                        <Grid xs={12} item>
                                            <Tooltip classes={{ popper: "toolTipPooper", tooltip: classes.htmlTooltip }} title={t(`If this is ticked, we will only price your reference ${modalData.state.room_apartment_space_name.toLowerCase()}. All other ${modalData.state.room_apartment_space_name.toLowerCase()} prices must be derived from this rate in your ${modalData.state.pms_channel_manager_text}.`)}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={!modalData.state.refRoomOnly}
                                                            value={!modalData.state.refRoomOnly}
                                                            onChange={(event) => modalData.handleRefRoom(event)}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={t(`Price Reference ${modalData.state.room_apartment_space_name} Only`)}
                                                    style={{ marginRight: '5%' }}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    }
                                    {/* {(modalData.state.editModalData.is_staff || modalData.props.userSession.user.sub_account_type) &&
                                        <Grid xs={12} item>
                                            <FormControl fullWidth required className={classes.fieldLeft} margin="normal">
                                                <InputLabel shrink={true} htmlFor="name">{t("Hotel ID")}</InputLabel>
                                                <Input
                                                    id="standard-name"
                                                    disabled={true}
                                                    className={classes.textField}
                                                    value={modalData.state.editModalData.hotel}
                                                    placeholder={t(`Please enter Hotal ID`)}
                                                    margin="normal"
                                                />
                                            </FormControl>
                                        </Grid>
                                    } */}
                                    {(modalData.state.editModalData.is_staff || modalData.props.userSession.user.sub_account_type) &&
                                        <Grid xs={12} item>
                                            <FormControl fullWidth margin="normal" className={classes.fieldLeft}>
                                                <InputLabel shrink={true} htmlFor="name">{t(`${modalData.state.room_apartment_space_name} ID`)}</InputLabel>
                                                <Input
                                                    id="standard-name"
                                                    disabled={true}
                                                    className={classes.textField}
                                                    placeholder={t(`Please enter ${modalData.state.room_apartment_space_name} ID`)}
                                                    value={modalData.state.editModalData.id}
                                                    margin="normal"
                                                />
                                            </FormControl>
                                        </Grid>
                                    }
                                </Grid>
                            }

                            {modalData.state.tab == 1 && (modalData.state.all_room_pricing || modalData.state.editModalData.is_reference_room) &&
                                <Grid xs={10} md={10} item >
                                    {/* <Typography style={{ fontWeight: "500", fontSize: "16px", color: "#000000de" }} align="left" >{t("Pricing Setup")}</Typography> */}
                                    <Grid xs={12} item>
                                        <FormControl fullWidth required margin="normal" className={classes.fieldLeft} >
                                            <InputLabel shrink={true} htmlFor="name">{t("Base Price")}</InputLabel>
                                            <Input
                                                type="number"
                                                error={modalData.state.error.includes("edit_avg_price")}
                                                id="avg_price"
                                                name="avg_price"
                                                className={classes.textField}
                                                onChange={modalData.handleEditRoomPrice('avg_price')}
                                                value={modalData.state.editModalData.avg_price}
                                                placeholder={t(`Please enter Base Price`)}
                                                margin="normal"
                                                startAdornment={currAdorn}
                                                endAdornment={<CustomTooltip title="" description={t(`The Base Price is the most important number in your system. You can think of it as the average price for this ${modalData.state.room_apartment_space_name.toLowerCase()} and rate over the whole year. It’s not your ADR but more an average BAR.  In general, if you move it up by $50 then all your prices will go up by an average of around $50. Please be very careful changing it. You can read more in the Help Center.`)}></CustomTooltip>}
                                            />
                                            {modalData.state.error.includes("edit_avg_price") ? <span class='error-message'>{t('* Please enter Base Price')}</span> : ''}
                                        </FormControl>
                                    </Grid>
                                    {!modalData.state.editModalData.is_reference_room && !(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 35 && modalData.state.currentData.extra ==  "fixed price") &&
                                        <Grid xs={12} item>
                                            <FormControl fullWidth margin="normal" className={classes.fieldLeft}>
                                                <InputLabel shrink={true} htmlFor="name">{t(`Derivation from Reference ${modalData.state.room_apartment_space_name}`)}</InputLabel>
                                                <Input
                                                    type="number"
                                                    id="handleRate"
                                                    name="handleRate"
                                                    label={(modalData.state.isAbsolute ? 'Adjust Price in absolute Value' : 'Adjust Price in percentage value (%)')}
                                                    className={classes.textField}
                                                    placeholder={t(`Please enter Derivation from Reference ${modalData.state.room_apartment_space_name}`)}
                                                    onChange={modalData.handleEditRoomPrice('handleRate')}
                                                    value={modalData.state.editModalData.handleRate}
                                                    startAdornment={absoluteAdorm}
                                                // endAdornment={endAdorm}
                                                />
                                            </FormControl>
                                        </Grid>
                                    }


                                    {(modalData.state.featuresEnabled.includes(6) || modalData.state.editModalData.is_reference_room) &&
                                        <Grid xs={12} item>
                                            <FormControl fullWidth required className={classes.fieldLeft} margin="normal">
                                                <InputLabel shrink={true} htmlFor="name">{t("Default Minimum Price")}</InputLabel>
                                                <Input
                                                    type="number"
                                                    id="standard-name"
                                                    className={classes.textField}
                                                    onChange={modalData.handleEditRoom('min_price')}
                                                    value={modalData.state.editModalData.min_price}
                                                     placeholder={t(`Please enter Default Minimum Price`)}
                                                    margin="normal"
                                                    startAdornment={currAdorn}
                                                    endAdornment={<CustomTooltip title="" description={t(`The Default Minimum Price is the price that we will not sell this ${modalData.state.room_apartment_space_name.toLowerCase()} below. For example, if you set a minimum of $99 and we will never set this ${modalData.state.room_apartment_space_name.toLowerCase()} and rate below $99 unless you specifically over-ride it. To over-ride the Default Minimum, you can either use ‘Fix Price’ to fix the price below. Or you can change the minimum (up or down) on an individual day basis, using the Adjustment View. Find out more about minimum and maximum prices in our Help Center.`)}></CustomTooltip>}
                                                />
                                                {modalData.state.error.includes("min_price") ? <span class='error-message'>{t('* Please enter Min Price')}</span> : ''}
                                            </FormControl>
                                        </Grid>
                                    }
                                    {(modalData.state.featuresEnabled.includes(6) || modalData.state.editModalData.is_reference_room) &&
                                        <Grid xs={12} item>
                                            <FormControl fullWidth required margin="normal" className={classes.fieldLeft}>
                                                <InputLabel shrink={true} htmlFor="name">{t("Default Maximum Price")}</InputLabel>
                                                <Input
                                                    type="number"
                                                    id="standard-name"
                                                    className={classes.textField}
                                                    onChange={modalData.handleEditRoom('max_price')}
                                                    value={modalData.state.editModalData.max_price}
                                                    placeholder={t(`Please enter Default Maximum Price`)}
                                                    margin="normal"
                                                    startAdornment={currAdorn}
                                                    endAdornment={<CustomTooltip title="" description={t(`The Default Maximum Price is the price that we will not sell this ${modalData.state.room_apartment_space_name.toLowerCase()} above. For example, if you set a maximum of $299 and we will never set this ${modalData.state.room_apartment_space_name.toLowerCase()} and rate above $299 unless you specifically over-ride it. To over-ride the Default Maximum, you can either use ‘Fix Price’ to fix the price higher. Or you can change the maximum (up or down) on an individual day basis, using the Adjustment View. Find out more about minimum and maximum prices in our Help Center.`)}></CustomTooltip>}
                                                />
                                                {modalData.state.error.includes("max_price") ? <span class='error-message'>{t('* Please enter Max Price')}</span> : ''}
                                            </FormControl>
                                                {modalData.state.error.includes("max_price_error") ? <span class='error-message'>{t('Max price should be greater than min price')}</span> : ''}
                                        </Grid>
                                    }
                                    {modalData.state.editModalData.is_reference_room &&
                                        <Grid xs={12} item>
                                            <FormControl fullWidth required className={classes.fieldLeft} margin="normal">
                                                <InputLabel shrink={true} htmlFor="name">{t("Variable Cost")}</InputLabel>
                                                <Input
                                                    type="number"
                                                    id="standard-name"
                                                    error={modalData.state.error.includes("variable_cost_per_room")}
                                                    className={classes.textField}
                                                    onChange={modalData.handleEditRoom('variable_cost_per_room')}
                                                    value={modalData.state.editModalData.variable_cost_per_room}
                                                    placeholder={t(`Please enter Variable Cost`)}
                                                    margin="normal"
                                                    startAdornment={currAdorn}
                                                    endAdornment={<CustomTooltip title="" description={t(`The Variable Costs are the extra costs associated with having a guest in the ${modalData.state.room_apartment_space_name.toLowerCase()} rather than having an empty ${modalData.state.room_apartment_space_name.toLowerCase()}. These should take into account what it costs you to clean or maintain a ${modalData.state.room_apartment_space_name.toLowerCase()}, the cost of breakfast, utilities etc.  They should also include any OTA commissions you are paying. When optimising your revenue, we want to make sure that these costs are taken into account and you make enough profit on each ${modalData.state.room_apartment_space_name.toLowerCase()} sold. For most properties, we recommend setting this at least at 50% of the Minimum Price. If your prices are going too low for your preference, you can raise it to up-to 80% of your Minimum price.`)} ></CustomTooltip>}
                                                />
                                                {modalData.state.error.includes("variable_cost_per_room") ? <span class='error-message'>{t('* Please enter variable cost')}</span> : ''}
                                                {modalData.state.error.includes("variable_cost_per_room_max") ? <span class='error-message'>{t('Variable costs are the extra costs you incur when a guest stays. Variable costs must be lower than 80% of minimum price, as your minimum price needs to cover these costs.')}</span> : ''}
                                            </FormControl>
                                        </Grid>
                                    }

                                    {(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 8 || modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 17) &&
                                        <Grid xs={12} item>
                                            <FormControl fullWidth margin="normal" required className={classes.fieldLeft}>
                                                <InputLabel shrink={true} htmlFor="name">{t("Category")}</InputLabel>
                                                <Select
                                                    id="pms_room"
                                                    name="pms_room"
                                                    className={classes.textField}
                                                    value={modalData.state.editModalData.use_prices_from ? modalData.state.editModalData.use_prices_from : ''}
                                                    onChange={modalData.updateuse_prices_from()}
                                                    SelectProps={{
                                                        MenuProps: {
                                                            className: classes.menu,
                                                        },
                                                    }}
                                                    endAdornment={<CustomTooltip title="" description={t(`Select the name of this ${modalData.state.room_apartment_space_name.toLowerCase()} type in your ${modalData.state.pms_channel_manager_text}.`)}></CustomTooltip>}
                                                >
                                                    <MenuItem key={-1} value=''>-</MenuItem>
                                                    {modalData.state.editModalData.temp_pmsList.map(option => (
                                                        <MenuItem key={option.id} value={option.room_id}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    }
                                    {([24, 61].includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider)) &&
                                        <Grid xs={12} item>
                                            <FormControl fullWidth margin="normal" required className={classes.fieldLeft}>
                                                <InputLabel shrink={true} htmlFor="name">{t("Additional Price Per Person")}</InputLabel>
                                                <Input
                                                    id="discount_per_person"
                                                    name="discount_per_person"
                                                    error={modalData.state.error.includes("discount_per_person")}
                                                    className={classes.textField}
                                                    onChange={modalData.handleEditRoom('discount_per_person')}
                                                    value={modalData.state.editModalData.discount_per_person}
                                                    placeholder={t(`Please enter Additional Price Per Person`)}
                                                    startAdornment={currAdorn}
                                                />
                                                {modalData.state.error.includes("discount_per_person") ? <span class='error-message'>{t('Please enter a value > 0')}</span> : ''}
                                            </FormControl>
                                        </Grid>
                                    }
                                    {modalData.state.editModalData.is_reference_room &&
                                        <Grid xs={12} item>
                                            <FormControl fullWidth margin="normal" required className={classes.fieldLeft}>
                                                <InputLabel placeholder={"No rounding"} htmlFor="name" shrink={true}>{t(`Round Prices (applied to all ${modalData.state.room_apartment_space_name.toLowerCase()}s)`)}</InputLabel>
                                                <Select
                                                    id="round_price_setting"
                                                    name="round_price_setting"
                                                    className={classes.textField}
                                                    value={modalData.state.round_price_setting}
                                                    onChange={modalData.handleroundSetting()}
                                                    SelectProps={{
                                                        MenuProps: {
                                                            className: classes.menu,
                                                        },
                                                    }}
                                                >
                                                    <MenuItem key={-1} value={0}>{t('No Rounding')}</MenuItem>
                                                    <MenuItem key={0} value={5}>{t('Round to nearest 5')}</MenuItem>
                                                    <MenuItem key={1} value={9}>{t('Round to nearest 9')}</MenuItem>
                                                    <MenuItem key={2} value={10}>{t('Round to nearest 10')}</MenuItem>
                                                    <MenuItem key={3} value={20}>{t('Round to nearest 20')}</MenuItem>
                                                    <MenuItem key={4} value={50}>{t('Round to nearest 50')}</MenuItem>
                                                    <MenuItem key={5} value={100}>{t('Round to nearest 100')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    }
                                    {modalData.state.editModalData.is_reference_room &&
                                        <Grid xs={12} item>
                                            <Tooltip shrink={true} classes={{ popper: "toolTipPooper", tooltip: classes.htmlTooltip }} title={t(`If the ‘Lock Derivation’ is ticked then all derived Base, Minimum and Maximum prices will change when you change these settings. For example, if you move the Minimum up from $200 to $250 for this ${modalData.state.room_apartment_space_name.toLowerCase()}, then a derived ${modalData.state.room_apartment_space_name.toLowerCase()} with minimum $400 would move up to $450 (if Absolute Derivation – up $50) or $500 (if Percentage Derivation -  up 20%). If it is unticked, only this ${modalData.state.room_apartment_space_name.toLowerCase()} is affected.`)}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={modalData.state.adjustment_to_reference_room_is_locked}
                                                            value={modalData.state.adjustment_to_reference_room_is_locked}
                                                            onChange={(event) => modalData.handlerefLocked(event)}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={<>{t(`Lock Derivation When Changing Base Price of Reference ${modalData.state.room_apartment_space_name}`)}</>}
                                                    style={{ marginRight: '5%' }}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    }
                                    {(modalData.state.editModalData.is_staff || modalData.props.userSession.user.sub_account_type) &&
                                        <Grid xs={12} item>                                            
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={modalData.state.ignore_upload_count}
                                                        value={modalData.state.ignore_upload_count}
                                                        onChange={(event) => modalData.handleDontCountUpload(event)}
                                                        color="primary"
                                                    />
                                                }
                                                label={t(`Don't count uploads`)}
                                                style={{ marginRight: '5%' }}
                                            />                                            
                                        </Grid>
                                    }
                                </Grid>
                            }

                            {modalData.state.tab == 2 &&
                                <Grid xs={10} md={10} item >
                                    {/* <Typography style={{ fontWeight: "500", fontSize: "16px", color: "#000000de" }} align="left" >{t("Pricing Setup")}</Typography> */}
                                    <Grid xs={12} item>
                                        <FormControl fullWidth margin="normal" className={classes.fieldLeft} >
                                            <InputLabel shrink={true} style={{ whiteSpace: 'nowrap' }} htmlFor="name">{t("Default Occupancy")}</InputLabel>
                                            <Input
                                                type="number"
                                                error={modalData.state.pmsProviderList.includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) ? modalData.state.error.includes("default_occupancy") : ''}
                                                id="default_occupancy"
                                                // name="defaultOccupancy"
                                                className={classes.textField}
                                                onChange={modalData.handleEditRoom('default_occupancy')}
                                                value={modalData.state.editModalData.default_occupancy}
                                                placeholder={t(`Please enter Default Occupancy`)}
                                                margin="normal"
                                                endAdornment={<CustomTooltip title="" description={t("The calendar displays prices for your default occupancy.")}></CustomTooltip>}
                                            />

                                            {modalData.state.pmsProviderList.includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) ? modalData.state.error.includes("default_occupancy") ? <span class='error-message'>{t('* Please enter Default Occupancy')}</span> : '' : ''}
                                            {modalData.state.pmsProviderList.includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider) ? modalData.state.error.includes("default_occupancy_min_max") ? <span class='error-message'>{t('* Please enter value between Occupancy ' + minOccupancy + ' and ' + maxOccupancy)}</span> : '' : ''}
                                        </FormControl>
                                    </Grid>
                                    
                                    {minOccupancy > 0 &&
                                        <Grid xs={12} item>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>{t("Occupancy (Persons)")}</TableCell>
                                                        <TableCell>{t("Price Difference from Default Occupancy Price")}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {modalData.state.occupancyPrices.map((val, index) => (
                                                        <>
                                                            {/* {(modalData.state.editModalData.default_occupancy != val.occupancy) && */}
                                                                <TableRow>
                                                                    <TableCell component="th" scope="row" style={{ marginTop: '20px', marginBottom: '20px', textAlign: 'right' }}>
                                                                        {val.occupancy}
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" style={{ marginBottom: '15px' }}>
                                                                    {(modalData.state.editModalData.default_occupancy == val.occupancy) ?
                                                                        <span style={{fontSize:'16px'}}>{t('Base Price:')} {modalData.state.editModalData.avg_price ? modalData.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol +modalData.state.editModalData.avg_price :''} </span>
                                                                        :
                                                                        <>
                                                                        <Input
                                                                            type="number"
                                                                            id="occupancy_pricing"
                                                                            // error={modalData.state.error.includes(index)}
                                                                            className={classes.textField}
                                                                            style={{ marginBottom: '15px' }}
                                                                            value={val.derivation}
                                                                            onChange={(e) => modalData.handleOccupancyPricingDiff(e, val, index)}
                                                                            endAdornment={<span style={{fontSize:"0.875rem"}}>{modalData.state.occupancy_percent_derivation == true ? '%':''} </span>}                                                                            
                                                                            startAdornment={<Typography variant="subtitle1" className={classes.adornment} style={{width: modalData.state.occupancy_percent_derivation == true ? "8px" : (modalData.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol.length > 2 ? "64px": "30px")}}>{(modalData.state.occupancy_percent_derivation == true ? '' : modalData.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol)+(modalData.state.editModalData.default_occupancy < val.occupancy ? '+ ':'-')}</Typography>}
                                                                        />
                                                                        {/* {modalData.state.error.includes(index) ? <span class='error-message'>{t('* Please enter Price Difference')}</span> : ''} */}
                                                                        </>
                                                                    }

                                                                    </TableCell>
                                                                </TableRow>
                                                            {/* } */}
                                                        </>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    }
                                    {modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 44 &&  modalData.state.maxPricingOccupancy > 1 &&
                                    <Grid xs={12} item>
                                        <FormControl fullWidth margin="normal" className={classes.fieldLeft} >
                                            <InputLabel shrink={true} style={{ whiteSpace: 'nowrap' }} htmlFor="name">{t("+1 child additional fee (2 adults)")}</InputLabel>
                                            <Input
                                                type="number"
                                                id="discount_per_person"
                                                // name="defaultOccupancy"
                                                className={classes.textField}
                                                onChange={modalData.handleEditRoom('discount_per_person')}
                                                value={modalData.state.editModalData.discount_per_person}
                                                placeholder={t(`Please enter +1 child additional fee (2 adults)`)}
                                                margin="normal"
                                                endAdornment={modalData.state.occupancy_percent_derivation == true ? '%':''}
                                                // startAdornment={<span style={{width: modalData.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol.length > 2 ? "60px": "30px"}}>{(modalData.state.occupancy_percent_derivation == true ? '' : modalData.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol)+(modalData.state.editModalData.default_occupancy < val.occupancy ? ' ':'-')}</span>}
                                                startAdornment={modalData.state.occupancy_percent_derivation == true ? "" : <Typography variant="subtitle1" className={classes.adornment}>{(modalData.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol)}</Typography>}
                                                />  
                                        </FormControl>
                                    </Grid>
                                    }
                                     {console.log('MMM1',modalData.props.hotelAuthReducer.hotelDetails.hotel)}
                                     {([28].includes(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider)) &&
                                        <Grid xs={12} item>
                                            <FormControl fullWidth margin="normal" required className={classes.fieldLeft}>
                                                <InputLabel shrink={true} htmlFor="name">{ t("Extra Adult")}</InputLabel>
                                                <Input
                                                    id="discount_per_person"
                                                    name="discount_per_person"
                                                    error={modalData.state.error.includes("discount_per_person")}
                                                    className={classes.textField}
                                                    onChange={modalData.handleEditRoom('discount_per_person')}
                                                    value={modalData.state.editModalData.discount_per_person}
                                                    placeholder={t(`Please enter Extra Adult`)}
                                                    startAdornment={currAdorn}
                                                />
                                                {modalData.state.error.includes("discount_per_person") ? <span class='error-message'>{t('Please enter a value > 0')}</span> : ''}
                                            </FormControl>
                                            <FormControl fullWidth margin="normal" required className={classes.fieldLeft}>
                                                <InputLabel shrink={true} htmlFor="name">{ t("Extra Child")}</InputLabel>
                                                <Input
                                                    id="extra_child"
                                                    name="extra_child"
                                                    error={modalData.state.error.includes("extra_child")}
                                                    className={classes.textField}
                                                    onChange={modalData.handleEditRoom('extra_child')}
                                                    value={modalData.state.editModalData.extra_child}
                                                    startAdornment={currAdorn}
                                                />
                                                {modalData.state.error.includes("extra_child") ? <span class='error-message'>{t('Please enter a value > 0')}</span> : ''}
                                            </FormControl>
                                        </Grid>
                                    }
                                    
                                </Grid>
                            }
                            {
                                modalData.state.tab == 3 &&
                                <>
                                    {modalData.state.pricePerRate && modalData.state.pricePerRate.map((val,index) => {
                                        return (    
                                            <Grid xs={12} md={12} className={classes.derivedCont} alignItems="flex-end" item >
                                            {/* <Grid container className={classes.derivedCont} alignItems="flex-end"> */}
                                                {/* <Grid item xs={12}>
                                                    <> */}
                                                    <Grid xs={10} item>
                                                        <FormControl fullWidth margin="normal" className={classes.fieldLeft} >
                                                       
                                                            <ReactSelect
                                                                components={{ Control:DerivedRateComponent }}
                                                                value={val.pms_rate ?  rateListDerivation.filter(v=>v.value == val.pms_rate)[0] :  null}
                                                                styles={SelectStyle}
                                                                indexDisplay={index+1}
                                                                className={classes.textField}
                                                                onChange={modalData.handleDerivedRates('pms_rate',index)}
                                                                options={rateListDerivation.filter(option=>(!pmsRates.includes(option.value) || val.pms_rate == option.value))}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                       {(modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider && (modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 46)) && 
                                                        <Grid xs={10} item>
                                                            <FormControl fullWidth  className={classes.fieldLeft} margin="normal">
                                                                <InputLabel shrink={true} htmlFor="name">{t("Reference Occupancy")}</InputLabel>
                                                                <Input
                                                                    id="standard-name"
                                                                    disabled={true}
                                                                    className={classes.textField}
                                                                    value={val.pms_rate ? ( temp_rate_list.filter(v=>v.id == val.pms_rate)[0] ? temp_rate_list.filter(v=>v.id == val.pms_rate)[0] .default_occupancy:"-" ):  "-"}
                                                                    placeholder={t(`Please enter Reference Occupancy`)}
                                                                    margin="normal"
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    }
                                                    <Grid item xs={10}>
                                                        <FormControl fullWidth margin="normal" className={classes.fieldLeft} >
                                                            <InputLabel shrink={true} htmlFor="name">{t("Derivation")}</InputLabel>
                                                            <Input
                                                                // error={modalData.state.error.includes("derivation_price")}
                                                                id="derivation"
                                                                type="text"
                                                                name="derivation"
                                                                className={classes.textField}
                                                                onChange={modalData.handleDerivedRates('derivation_price',index)}
                                                                value={val.derivation}
                                                                placeholder={t(`Please enter Derivation`)}
                                                                margin="normal"
                                                                startAdornment={val.is_absolute == true ? currAdorn : ''}
                                                                endAdornment={
                                                                    <>
                                                                    <span>
                                                                        {val.is_absolute == true ? '' : "%"}
                                                                    </span>
                                                                    <CustomTooltip title="" description={t("The price of your derived rate will be calculated by adding this amount or percentage on your base rate. You can also put negative values.")}></CustomTooltip></>
                                                                }
                                                            />
                                                            {/* {modalData.state.error.includes("derivation_price") ? <span class='error-message'>{t('* Please enter Derivation Price')}</span> : ''} */}
                                                        </FormControl>
                                                    {/* </Grid>
                                                    </> */}
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                            checked={val.is_absolute == true ? false:true}
                                                            color="primary"
                                                            onChange={ modalData.handleDerivedRates('derivation_percent',index)}
                                                            // value={index}
                                                            />
                                                            }
                                                            label={t("Percent Derivation")}
                                                        />
                                                        
                                                        <Tooltip
                                                            disableTouchListener={val.is_absolute}
                                                            disableFocusListener={val.is_absolute}
                                                            classes={{
                                                                popper: "toolTipPooper",
                                                                tooltip: classes.htmlTooltip,
                                                            }}
                                                            disableHoverListener={val.is_absolute}
                                                            title={t("Please turn off Percent Derivation to use Product Derivation")}
                                                        >
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                            disabled={!val.is_absolute}
                                                            checked={val.is_product_derivation}
                                                            color="primary"
                                                            onChange={ modalData.handleDerivedRates('is_product_derivation',index)}
                                                            // value={index}
                                                            />
                                                            }
                                                            label={t("Product Derivation")}
                                                        />
                                                        </Tooltip>                                                    
                                                    </Grid>  
                                            </Grid>
                                        )})
                                    }
                                </>
                            }   
                        </Grid>
                    </div>


                    <div  className={`${classes.buttonGroupBottom } ${classes.sidePopupFooter}`}  >
                        <div >
                            <Button disabled={modalData.state.saveRoomLoader} style={{ marginLeft: '5px' }} onClick={() => modalData.toggleWarningModal("saveEditRoom")} variant="contained" color="primary">
                                {t("Save")} {modalData.state.saveRoomLoader && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                            </Button>
                            {modalData.state.tab == 1 && modalData.state.featuresEnabled.includes(6) && !modalData.state.editModalData.is_reference_room && modalData.state.all_room_pricing &&
                                <Button color="secondary" variant="outlined" className={classes.derivedBtn} style={{ marginLeft: '5px',background:"white" }} onClick={modalData.setDefaultMin} > {t("Derived Min/Max")} </Button>
                            }
                            {modalData.state.tab == 1 && (modalData.state.editModalData.is_staff || modalData.props.userSession.user.sub_account_type) && modalData.props.hotelAuthReducer.hotelDetails.hotel.pms_provider != 15 &&
                            <Button variant="outlined" color="primary" style={{ marginLeft: '5px',background:"white" }} onClick={() => modalData.updateAveragePrice()}>
                                    {t("Get Current Average")}
                                </Button>
                                }
                            <Button onClick={modalData.closeEditRoomModal} className="cancelBtn" style={{ marginLeft: '5px',background:"white" }}>
                                {t("Cancel")}
                            </Button> 
                        </div>
                    </div>
                </SwipeableDrawer>
            </>
        )
    },
    DeleteRoomModal: function (modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        const { classes } = modalData.props;
        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            open={modalData.state.openDeleteRoom}
            onClose={modalData.closeDeleteRoomModal}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t("Delete")} {modalData.state.deleteModalData.name}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("Are you sure you want to delete ") + modalData.state.deleteModalData.name + ' ?'}
                </DialogContentText>
            </DialogContent>
            <DialogActions>               
                <Button disabled={modalData.state.saveRoomLoader} onClick={modalData.deleteRoom} variant="contained" color="primary"  className="dangerBtnHvr" >
                    {t("Delete")} {modalData.state.saveRoomLoader && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                </Button>
                <Button onClick={modalData.closeDeleteRoomModal} className="cancelBtn" >
                    {t("Cancel")}
                </Button>              
            </DialogActions>
        </Dialog>)
    },
    AverageGraph: function (modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        const { classes } = modalData.props;
        let currencySymbol = modalData.state.editModalData.currencySymbol;
        let graphData = modalData.state.avg_graph;
        let grapgValue = [];
        let graphOptions = [];
        let grapgDate = [];
        let data = [];
        let message = modalData.state.calculate_average.message;
        let preferred_date;
        if(modalData.props.hotelDetailReducer && modalData.props.hotelDetailReducer.hoteDetail){
            preferred_date=modalData.props.hotelDetailReducer ? modalData.props.hotelDetailReducer.hoteDetail.prefered_date_format.replace(', hh:mm A',''):'DD/MM/YYYY';
        }else{
            preferred_date='DD/MM/YYYY'
        }
        
        if (graphData) {
            if (modalData.state.avg_graph.daily_price && modalData.state.avg_graph.daily_price[modalData.state.editModalData.id]) {
                message = null;
                Object.keys(modalData.state.avg_graph.daily_price[modalData.state.editModalData.id]).map((key) => {                    
                    grapgDate.push(moment(key).format(preferred_date));
                    grapgValue.push(modalData.state.avg_graph.daily_price[modalData.state.editModalData.id][key]);
                })
                data = {
                    // labels: moment(grapgDate).format(preferred_date),
                    labels:grapgDate,
                    datasets: [
                        {
                            label: t('Price in '+modalData.state.pms_channel_manager_text),
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(75,192,192,1)',
                            borderColor: 'rgba(75,192,192,1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(75,192,192,1)',
                            pointBackgroundColor: 'rgba(75,192,192,1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                            pointHoverBorderColor: 'rgba(220,220,220,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: grapgValue,
                        },
                    ]
                };
                graphOptions = {
                    legend: {
                        labels: {
                            boxWidth: 11,
                        }
                    },
                    responsive: true,
                    tooltips: {
                        enabled: true,
                        mode: 'single',
                        callbacks: {
                            label: (tooltipItems, data) => {
                                // let date=moment(tooltipItems.label).format(preferred_date);
                                return tooltipItems.label + ': ' + tooltipItems.value;
                            }
                        }
                    },

                };
            } else {
                message = "Data Not Found";
            }
        }
        return (<Dialog
            maxWidth={'md'}
            fullWidth
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            open={modalData.state.openAverageGraph}
            onClose={modalData.toggleAverageGraph}
            aria-labelledby="responsive-dialog-title"
        >
            <IconButton aria-label="Close" className={classes.closeButtonPrice} onClick={modalData.toggleAverageGraph} >
                <CloseIcon />
            </IconButton>

            <DialogTitle id="responsive-dialog-title">{t("Base Price Year Chart")}
                {graphData &&
                    <DialogContentText>
                        <span>{ t('New base price')}: {currencySymbol +''+ modalData.state.editModalData.avg_price}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                        {(modalData.state.featuresEnabled.includes(6) || modalData.state.editModalData.is_reference_room) &&
                            <>
                            <span>{ t('Default Min')}: {currencySymbol +''+ modalData.state.setDefaultMin}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                            <span>{ t('Default Max')}: {currencySymbol +''+ modalData.state.setDefaultMax}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                            </>
                        }
                        { modalData.state.editModalData.is_reference_room &&
                            <span>{ t('Variable Cost')}: {currencySymbol +''+ modalData.state.setCostValue}</span>
                        }
                    </DialogContentText>
            }
            </DialogTitle>            
            <DialogContent>                
                <DialogContentText>{message}</DialogContentText>
                {!graphData && <CircularProgress style={{marginLeft:"50%"}} />}
                {!message && graphData &&
                    <Line height="500px" width="800px" options={graphOptions} ref="chart" data={data} />
                }
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={modalData.toggleAverageGraph} variant={"outlined"} color={"primary"} autoFocus>
                    {t("Close")}
                </Button> */}
                { (modalData.state.editModalData.is_staff || modalData.props.userSession.user.sub_account_type) && graphData &&
                    <>
                    <Button disabled={modalData.state.priceGraphSaveButtonLoader == 1 && modalData.state.saveRoomLoader} onClick={modalData.saveSetDefaults} style={{ marginLeft: '5px'}} variant={"contained"} color={"primary"} autoFocus>
                        {t("Save & Set Defaults")} { modalData.state.priceGraphSaveButtonLoader == 1 && modalData.state.saveRoomLoader && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                    </Button>
                    <Button disabled={modalData.state.priceGraphSaveButtonLoader == 2 && modalData.state.saveRoomLoader} onClick={modalData.saveOnlyBasePrice} style={{ marginLeft: '5px'}} variant={"outlined"} color={"primary"} autoFocus>
                        {t("Save Base Price Only")} {modalData.state.priceGraphSaveButtonLoader == 2 && modalData.state.saveRoomLoader && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                    </Button>
                    </>
                }
            </DialogActions>
        </Dialog>)
    },    
    inviteUserModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
                
        let languages = [{ value: "en", label: "English" }, { value: "de", label: "German" }, { value: "fr", label: "French" }, { value: "es-ES", label: "Spanish" }, { value: "it", label: "Italian" }]
        //const {fullScreen} = modalData.props;
        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            open={modalData.state.inviteUserModal}
            onClose={modalData.toggleInviteUser}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t("Invite User")}</DialogTitle>
            <DialogContent>
                <FormControl margin="normal" required style={{ marginLeft: '2%' }}>
                    <RadioGroup
                        aria-label="Level"
                        name="level"
                        className={classes.radioGroup}
                        value={modalData.state.addNewModal.level}
                        onChange={modalData.handleAddNew('level')}
                    >
                        {(modalData.state.userLevel != 1 && modalData.state.userLevel != 2) &&
                            <FormControlLabel value="3" control={<Radio color="primary" />} label={t("Admin")} />
                        }
                        {(modalData.state.userLevel != 2) &&
                            < FormControlLabel value="1" control={<Radio color="primary"/>} label={t("Manager")} />
                        }
                        {(modalData.state.userLevel != 2) &&
                            <FormControlLabel value="2" control={<Radio color="primary"/>} label={t("Staff")} />
                        }
                    </RadioGroup>
                    {modalData.state.error.includes("level") ? <span class='error-message'>{t("* Please select user type")}</span> : ''}
                </FormControl>
                <FormControl margin="normal" required className={classes.fieldLeft}>
                    <InputLabel htmlFor="name" shrink={true}>{t("User Email")}</InputLabel>
                    <Input
                        error={modalData.state.error.includes("username")}
                        id="username"
                        name="username"
                        onChange={modalData.handleAddNew('username')}
                        value={modalData.state.addNewModal.username}
                        placeholder={t(`Please enter User Email`)}
                    />
                    {modalData.state.error.includes("username") ? <span class='error-message'>{t('* Please enter valid Email')}</span> : ''}
                </FormControl>                
                <FormControl margin="normal" required className={classes.fieldLeft}>
                    <InputLabel shrink={true} htmlFor="select-multiple-checkbox">{t("Property Access")}</InputLabel>
                    <Select
                        className={classes.accessSelect}
                        multiple
                        displayEmpty
                        value={modalData.state.addNewModal.selectedHotelList}
                        onChange={modalData.handleAddNew('selectedHotelList')}
                        input={<Input id="select-multiple-checkbox" />}
                        renderValue={selected => {
                            let jsx = [];
                            if (selected.length == 0) {
                                return <>{t(`Select Property Access`)}</>;
                            }
                            modalData.state.addNewModal.hotelList.map(opt => {
                                if (modalData.state.addNewModal.selectedHotelList.includes(opt.id)) {
                                    jsx.push(opt.name);
                                }
                            })
                            return jsx.join(', ')
                        }
                        }
                    >
                        <MenuItem disabled value="">
                            <>{t(`Select Property Access`)} </>
                        </MenuItem>
                        {modalData.state.addNewModal.hotelList.map(opt => (
                            <MenuItem key={opt.id} value={opt.id}>
                                <Checkbox color="primary" checked={modalData.state.addNewModal.selectedHotelList.includes(opt.id)} />
                                <ListItemText primary={opt.name} />
                            </MenuItem>
                        ))}
                    </Select>
                    {modalData.state.error.includes("selectedHotelList") ? <span class='error-message'>{t('* Please assign hotel')}</span> : ''}
                </FormControl>
                <FormControl shrink={true} margin="normal" required className={classes.fieldLeft}>
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink={true} htmlFor="select-multiple-checkbox">{t("Language")}</InputLabel>
                        <Select
                            value={modalData.state.addNewModal.language}
                            onChange={modalData.handleAddNew('language')}
                            // input={<Input id="select-multiple-checkbox" />}                               
                        >
                            {languages.map(opt => (
                                <MenuItem key={opt.value} value={opt.value}>
                                    <ListItemText primary={opt.label} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {modalData.state.error.includes("language") ? <span class='error-message'>{t('* Please select Language')}</span> : ''}
                </FormControl>

            </DialogContent>
            <DialogActions>
              
                <Button onClick={() => modalData.inviteUser()} variant="contained" color="primary" >
                    {t("Send Invite")}
                </Button>
                <Button onClick={modalData.toggleInviteUser} className="cancelBtn" >
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog >)
    },
    addNewUser: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        //const {fullScreen} = modalData.props;
        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            open={modalData.state.addNewUser}
            onClose={modalData.toggleAddNewUserModal}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t("Add New User")}</DialogTitle>
            <DialogContent>
                <FormControl margin="normal" required style={{ marginLeft: '2%' }}>
                    <RadioGroup
                        aria-label="Level"
                        name="level"
                        className={classes.radioGroup}
                        value={modalData.state.addNewModal.level}
                        onChange={modalData.handleAddNew('level')}
                    >
                        {(modalData.state.userLevel != 1 && modalData.state.userLevel != 2) &&
                            <FormControlLabel value="3" control={<Radio color="primary" />} label={t("Admin")} />
                        }
                        {(modalData.state.userLevel != 2) &&
                            < FormControlLabel value="1" control={<Radio color="primary"/>} label={t("Manager")} />
                        }
                        {(modalData.state.userLevel != 2) &&
                            <FormControlLabel value="2" control={<Radio color="primary"/>} label={t("Staff")} />
                        }
                    </RadioGroup>
                    {modalData.state.error.includes("level") ? <span class='error-message'>{t("* Please select user type")}</span> : ''}
                </FormControl>
                <FormControl margin="normal" required className={classes.fieldLeft}>
                    <InputLabel htmlFor="name" shrink={true}>{t("User Email")}</InputLabel>
                    <Input
                        error={modalData.state.error.includes("username")}
                        id="username"
                        name="username"
                        onChange={modalData.handleAddNew('username')}
                        value={modalData.state.addNewModal.username}
                    />
                    {modalData.state.error.includes("username") ? <span class='error-message'>{t('* Please enter valid Email')}</span> : ''}
                </FormControl>
                <FormControl margin="normal" required className={classes.fieldLeft}>
                    <InputLabel htmlFor="name" shrink={true}>{t("Password")}</InputLabel>
                    <Input
                        error={modalData.state.error.includes("password")}
                        type="password"
                        id="password"
                        name="password"
                        onChange={modalData.handleAddNew('password')}
                        value={modalData.state.addNewModal.password}
                    />
                    {modalData.state.error.includes("password") ? <span class='error-message'>{t('* Please enter password')}</span> : ''}
                </FormControl>
                <FormControl margin="normal" required className={classes.fieldLeft}>
                    <InputLabel htmlFor="name" shrink={true}>{t("First Name")}</InputLabel>
                    <Input
                        error={modalData.state.error.includes("first_name")}
                        id="first_name"
                        name="first_name"
                        onChange={modalData.handleAddNew('first_name')}
                        value={modalData.state.addNewModal.first_name}
                    />
                    {modalData.state.error.includes("first_name") ? <span class='error-message'>{t('* Please enter First name')}</span> : ''}
                </FormControl>
                <FormControl margin="normal" required className={classes.fieldLeft}>
                    <InputLabel htmlFor="name" shrink={true}>{t("Last Name")}</InputLabel>
                    <Input
                        error={modalData.state.error.includes("last_name")}
                        id="last_name"
                        name="last_name"
                        onChange={modalData.handleAddNew('last_name')}
                        value={modalData.state.addNewModal.last_name}
                    />
                    {modalData.state.error.includes("last_name") ? <span class='error-message'>{t('* Please enter Last name')}</span> : ''}
                </FormControl>
                <FormControl margin="normal" required className={classes.fieldLeft}>
                    <InputLabel shrink={true} htmlFor="select-multiple-checkbox">{t("Property Access")}</InputLabel>
                    <Select
                        className={classes.accessSelect}
                        multiple
                        displayEmpty
                        value={modalData.state.addNewModal.selectedHotelList}
                        onChange={modalData.handleAddNew('selectedHotelList')}
                        input={<Input id="select-multiple-checkbox" />}
                        renderValue={selected => {
                            let jsx = [];
                            if (selected.length == 0) {
                                return <>{t(`Select Property Access`)}</>;
                            }
                            modalData.state.addNewModal.hotelList.map(opt => {
                                if (modalData.state.addNewModal.selectedHotelList.includes(opt.id)) {
                                    jsx.push(opt.name);
                                }
                            })
                            return jsx.join(', ')
                        }
                        }
                    >   
                        <MenuItem disabled value="">
                            <>{t(`Select Property Access`)} </>
                        </MenuItem>
                        {modalData.state.addNewModal.hotelList.map(opt => (
                            <MenuItem key={opt.id} value={opt.id}>
                                <Checkbox color="primary" checked={modalData.state.addNewModal.selectedHotelList.includes(opt.id)} />
                                <ListItemText primary={opt.name} />
                            </MenuItem>
                        ))}
                    </Select>
                    {modalData.state.error.includes("selectedHotelList") ? <span class='error-message'>{t('* Please assign hotel')}</span> : ''}
                </FormControl>

            </DialogContent>
            <DialogActions>
              
                <Button onClick={() => modalData.saveNewUser()} variant="contained" color="primary" >
                    {t("Save")}
                </Button>
                <Button onClick={modalData.toggleAddNewUserModal} className="cancelBtn" >
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog >)
    },
    updateUser: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        let languages = [{ value: "en", label: "English" }, { value: "de", label: "German" }, { value: "fr", label: "French" }, { value: "es-ES", label: "Spanish" }, { value: "it", label: "Italian" }]
        //const {fullScreen} = modalData.props;
        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            open={modalData.state.editUserModal}
            onClose={modalData.closeUpdateModal}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t("Edit")} {modalData.state.updateUserModal.title}</DialogTitle>
            <DialogContent>
                {!modalData.state.isAllAdmin &&
                    <FormControl margin="normal" required style={{ marginLeft: '2%' }}>
                        <RadioGroup
                            aria-label={t("Level")}
                            name="level"
                            className={classes.radioGroup}
                            value={modalData.state.updateUserModal.level}
                            onChange={modalData.handleUpdateModal('level')}
                        >
                            <FormControlLabel disabled={true} value="3" control={<Radio color="primary"/>} label={t("Admin")} />
                            <FormControlLabel disabled={true} value="1" control={<Radio color="primary"/>} label={t("Manager")} />
                            <FormControlLabel disabled={true} value="2" control={<Radio color="primary"/>} label={t("Staff")} />
                        </RadioGroup>
                        {modalData.state.error.includes("edit_level") ? <span class='error-message'>{t('* Please select user type')}</span> : ''}
                    </FormControl>
                }
                <FormControl margin="normal" required className={classes.fieldLeft}>
                    <InputLabel shrink={true} htmlFor="name">{t("User Email")}</InputLabel>
                    <Input
                        error={modalData.state.error.includes("edit_username")}
                        onChange={modalData.handleUpdateModal('username')}
                        value={modalData.state.updateUserModal.username}
                    />
                    {modalData.state.error.includes("edit_username") ? <span class='error-message'>{t('* Please enter valid Email')}</span> : ''}
                </FormControl>
                <FormControl margin="normal" required className={classes.fieldLeft}>
                    <InputLabel shrink={true} htmlFor="name">{t("First Name")}</InputLabel>
                    <Input
                        error={modalData.state.error.includes("edit_first_name")}
                        onChange={modalData.handleUpdateModal('first_name')}
                        value={modalData.state.updateUserModal.first_name}
                    />
                    {modalData.state.error.includes("edit_first_name") ? <span class='error-message'>{t('* Please enter First name')}</span> : ''}
                </FormControl>
                <FormControl margin="normal" required className={classes.fieldLeft}>
                    <InputLabel shrink={true} htmlFor="name">{t("Last Name")}</InputLabel>
                    <Input
                        error={modalData.state.error.includes("edit_last_name")}
                        onChange={modalData.handleUpdateModal('last_name')}
                        value={modalData.state.updateUserModal.last_name}
                    />
                    {modalData.state.error.includes("edit_last_name") ? <span class='error-message'>{t('* Please enter Last name')}</span> : ''}
                </FormControl>
    
                {!modalData.state.isAllAdmin &&
                    <FormControl shrink={true} margin="normal" required className={classes.fieldLeft}>
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink={true} htmlFor="select-multiple-checkbox">{t("Property Access")}</InputLabel>
                            <Select
                                multiple
                                displayEmpty
                                value={modalData.state.updateUserModal.selectedHotelList}
                                onChange={modalData.handleUpdateModal('selectedHotelList')}
                                input={<Input id="select-multiple-checkbox" />}
                                renderValue={selected => {
                                    let jsx = [];
                                    if (selected.length == 0) {
                                        return <>{t(`Select Property Access`)}</>;
                                    }
                                    modalData.state.updateUserModal.hotelList.map(opt => {
                                        if (modalData.state.updateUserModal.selectedHotelList.includes(opt.id)) {
                                            jsx.push(opt.name);
                                        }
                                    })
                                    return jsx.join(', ')
                                }
                                }
                            >   <MenuItem disabled value="">
                                    <>{t(`Select Property Access`)} </>
                                </MenuItem>
                                {modalData.state.updateUserModal.hotelList.map(opt => (
                                    <MenuItem key={opt.id} value={opt.id}>
                                        <Checkbox color="primary" checked={modalData.state.updateUserModal.selectedHotelList.includes(opt.id)} />
                                        <ListItemText primary={opt.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {modalData.state.error.includes("edit_selectedHotelList") ? <span class='error-message'>{t('* Please assign hotel')}</span> : ''}
                    </FormControl>
                }
                <FormControl shrink={true} margin="normal" required className={classes.fieldLeft}>
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink={true} htmlFor="select-multiple-checkbox">{t("Language")}</InputLabel>
                            <Select
                                error={modalData.state.error.includes("language")} 
                                value={modalData.state.updateUserModal.language}
                                onChange={modalData.handleUpdateModal('language')}
                                margin="normal"
                                displayEmpty
                                // input={<Input id="select-multiple-checkbox" />}                               
                            >   <MenuItem disabled value={''}>{t('Select Language')}</MenuItem>
                                {languages.map(opt => (
                                    <MenuItem key={opt.value} value={opt.value}>
                                        <ListItemText primary={opt.label} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {modalData.state.error.includes("language") ? <span class='error-message'>{t('* Please assign hotel')}</span> : ''}
                    </FormControl>
            </DialogContent>
            <DialogActions>
               
                <Button disabled={modalData.state.saveRoomLoader} onClick={() => modalData.UpdateHotel()} variant="contained" color="primary" >
                    {t("Save")}{modalData.state.saveRoomLoader && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                </Button>
                <Button onClick={modalData.closeUpdateModal} className="cancelBtn" >
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>)
    },
    addNewRate: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            open={modalData.state.addNewRateModal}
            onClose={modalData.toggleAddNewRateModal}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t("Add New BAR Level")}</DialogTitle>
            <DialogContent>

                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="name">{t("Price")}</InputLabel>
                    <Input
                        type="number"
                        error={modalData.state.error.includes("price")}
                        id="standard-name"
                        onChange={modalData.handleNewRate('price')}
                        value={modalData.state.addNewRateData.price}
                        placeholder={t(` Please enter Price`)}
                        margin="normal"
                        required
                        startAdornment={<span style={{marginRight:'5px'}}>{modalData.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol}</span>}
                        
                    />
                    {modalData.state.error.includes("price") ? <span class='error-message'>{'* Please enter Price'}</span> : ''}
                </FormControl>
            </DialogContent>
            <DialogActions>
                
                <Button onClick={() => modalData.createNewRate()} variant="contained" color="primary" >
                    {t("Save")}
                </Button>
                <Button onClick={modalData.toggleAddNewRateModal} className="cancelBtn" >
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>)
    },
    editNewRate: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            open={modalData.state.editRateModal}
            onClose={modalData.toggleEditRateModal}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t("Edit BAR Level")} {modalData.state.title}</DialogTitle>
            <DialogContent>

                <Input
                    type="number"
                    error={modalData.state.error.includes("edit_price")}
                    id="standard-name"
                    label={t("Price")}
                    // className={classes.textField}
                    onChange={modalData.handleEditRate('price')}
                    value={modalData.state.editRateData.price}
                    placeholder={t(` Please enter Price`)}
                    margin="normal"
                    startAdornment={modalData.props.hotelAuthReducer.hotelDetails.hotel.currency.symbol}
                />
            </DialogContent>
            <DialogActions>
               
                <Button onClick={() => modalData.editRate()} variant="contained" color="primary" >
                    {t("Save")}
                </Button>
                <Button onClick={modalData.toggleEditRateModal} className="cancelBtn" >
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>)
    },
    DeleteRateModal: function (modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.deleteRateModal}
                onClose={() => modalData.toggleDeleteRateModal()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Delete")} {modalData.state.title}</DialogTitle>
                <DialogContent>
                    {t('Are you sure you want to delete ') + '?'}
                </DialogContent>
                <DialogActions>
                    
                    <Button onClick={() => modalData.deleteRate()} variant="contained" color="primary" autoFocus  className="dangerBtnHvr">
                        {t("Delete")}
                    </Button>
                    <Button onClick={() => modalData.toggleDeleteRateModal()} className="cancelBtn" autoFocus>
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    noRateModal: function (modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.isNoDataPopup}
                onClose={() => modalData.noDataModal()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("No BAR Levels Defined.")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('Please create a couple of BAR levels before turning it on.')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                   
                    <Button onClick={() => modalData.addNewWithNodata()} color="primary" variant="contained" autoFocus>
                        {t("Create BAR levels")}
                    </Button>
                    <Button onClick={() => modalData.noDataModal()} className="cancelBtn" autoFocus>
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    editClientRate: function (modalData) {
        const { fullScreen } = modalData.props;
        const { classes } = modalData.props;
        const { t } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.openEdit}
                onClose={() => modalData.toggleEditRate()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Edit Monthly Fee")}</DialogTitle>
                <DialogContent>
                    <FormControl required>
                        <TextField
                            error={modalData.state.error.includes("rate")}
                            type="number"
                            inputProps={{ min: 0 }}
                            id="standard-name"
                            label={t("Monthly Fee")}
                            className={classes.textField}
                            onChange={modalData.handleEditRate}
                            value={modalData.state.rate}
                        />
                        {modalData.state.error.includes("rate") ? <span class='error-message'>{t('* Please enter monthly fee')}</span> : ''}
                    </FormControl>
                </DialogContent>
                <DialogActions>
                   
                    <Button onClick={() => modalData.updateRate()} variant="contained" color="primary" >
                        {t("Save")}
                    </Button>
                    <Button onClick={() => modalData.toggleEditRate() } className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    updateFreeTrial: function (modalData) {
        const { fullScreen } = modalData.props;
        const { classes } = modalData.props;
        const { t } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.openDate}
                onClose={() => modalData.closeEditDate()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Edit Trial End date")}</DialogTitle>
                <DialogContent>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            minDate={moment()}
                            value={modalData.state.trialDate}
                            onChange={(event) => modalData.handleDate(event)}
                            className={classes.textField}
                            format={"dd/MM/yyyy"}
                            InputLabelProps={{ shrink: true }}
                            autoOk={true}
                        />
                    </MuiPickersUtilsProvider>
                </DialogContent>
                <DialogActions>
                  
                    <Button onClick={() => modalData.updateFreeTrial()} variant="contained" color="primary" >
                        {t("Save")}
                    </Button>
                    <Button onClick={() => modalData.closeEditDate()} className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    DeleteUserModal: function (modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.deleteUserModalOpen}
                onClose={() => modalData.toggleDeleteUser()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t('Delete') + ' ' + modalData.state.title}</DialogTitle>
                <DialogContent>
                    {t("Are you sure you want to delete ") + modalData.state.title + '?'}
                </DialogContent>
                <DialogActions>
                   
                    <Button onClick={() => modalData.deleteUser()} variant="contained" color="primary"  className="dangerBtnHvr" >
                        {t("Delete")}
                    </Button>
                    <Button onClick={() => modalData.closeDeleteModel()} className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    scrapeConfirmModal: function (modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.openconfirmScrapeModal}
                onClose={() => modalData.confirmScrape()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Start Scrape")}</DialogTitle>
                <DialogContent>
                    {t('Are you sure you want to proceed?')}
                </DialogContent>
                <DialogActions>
                  
                    <Button onClick={() => modalData.getScrap()} variant="contained" color="primary" >
                        {t("Scrape")}
                    </Button>
                    <Button onClick={() => modalData.confirmScrape()} className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    ClearSmartDailyConfirmModal: function (modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.ClearSmartDailyModalOpen}
                onClose={() => modalData.ClearSmartDailyConfirm()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Confirmation")}</DialogTitle>
                <DialogContent>
                    {'If you clear you will set all weekly adjustments to zero. Do you wish to proceed?'}
                </DialogContent>
                <DialogActions>
                   
                    <Button onClick={() => modalData.ClearSmartDaily()} variant="contained" color="primary" >
                        {t("Ok")}
                    </Button>
                    <Button onClick={() => modalData.ClearSmartDailyConfirm()} className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    ClearSmartMonthlyConfirmModal: function (modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.ClearSmartMonthlyOpen}
                onClose={() => modalData.ClearSmartMonthlyConfirm()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Confirmation")}</DialogTitle>
                <DialogContent>
                    {'If you clear you will set all monthly adjustments to zero. Do you wish to proceed?'}
                </DialogContent>
                <DialogActions>
                    
                    <Button onClick={() => modalData.ClearSmartMonthly()} variant="contained" color="primary" >
                        {t("Ok")}
                    </Button>
                    <Button onClick={() => modalData.ClearSmartMonthlyConfirm()} className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    addHotelScrap: function (modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        const { classes } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                maxWidth='lg'
                open={modalData.state.addNewHotel}
                onClose={() => modalData.toggleAddNewHotel()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Scrape New Hotel")}</DialogTitle>
                <DialogContent>

                    <FormControl required>
                        <TextField
                            id="standard-name"
                            error={modalData.state.errorUrl}
                            label={t("Booking URL")}
                            className={classes.textField}
                            onChange={modalData.handleChangeUrl()}
                            value={modalData.state.url}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{minWidth:"400px"}}
                        />
                        {modalData.state.errorUrl ? <span class='error-message'>{t('* Please enter valid url')}</span> : ''}
                    </FormControl>

                </DialogContent>
                <DialogActions>
                   
                    <Button onClick={modalData.addNewScraper} variant="contained" color="primary" >
                        {t("Scrape")}
                    </Button>
                    <Button onClick={modalData.toggleAddNewHotel} className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    resetConfirmModal: function (modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        const { classes } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                maxWidth='lg'
                open={modalData.state.confirmReset}
                onClose={() => modalData.toggleConfirmReset()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Confirm Reset")}</DialogTitle>
                <DialogContent>
                    This will reset the pickup boost model.
                </DialogContent>
                <DialogActions>
                   
                    <Button onClick={modalData.state.intent == "price" ? modalData.resetPmsPrice : modalData.resetPmsPriceOccupancy } variant="contained" color="primary" >
                        {t("Reset")}
                    </Button>
                    <Button onClick={modalData.toggleConfirmReset} className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    AverageGraphDateSetting: function (modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        const { classes } = modalData.props;

        let graphData = modalData.state.avg_graph;
        let grapgValue = [];
        let graphOptions = [];
        let grapgDate = [];
        let data = [];
        let message = null;
        if (graphData) {
            if (modalData.state.avg_graph.daily_price[modalData.state.reference_room.id]) {
                message = null;
                Object.keys(modalData.state.avg_graph.daily_price[modalData.state.reference_room.id]).map((key) => {
                    grapgDate.push(key);
                    grapgValue.push(modalData.state.avg_graph.daily_price[modalData.state.reference_room.id][key]);
                })
                data = {
                    labels: grapgDate,
                    datasets: [
                        {
                            label: t('Price in '+modalData.state.pms_channel_manager_text),
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(75,192,192,1)',
                            borderColor: 'rgba(75,192,192,1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(75,192,192,1)',
                            pointBackgroundColor: '#fff',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                            pointHoverBorderColor: 'rgba(220,220,220,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: grapgValue,
                        },
                    ]
                };
                graphOptions = {
                    legend: {
                        labels: {
                            boxWidth: 11,
                        }
                    },
                    responsive: true,
                    tooltips: {
                        enabled: true,
                        mode: 'single',
                        callbacks: {
                            label: (tooltipItems, data) => {
                                return tooltipItems.label + ': ' + tooltipItems.value;
                            }
                        }
                    },

                };
            } else {
                message = "Data Not Found";
            }
        }

        var daysInWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return (<Dialog
            maxWidth={'md'}
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            open={modalData.state.openAverageGraph}
            onClose={() => modalData.closeGraphModal()}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t("Base Price Year Chart")}</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
                {!graphData && <CircularProgress style={{marginLeft:"35%"}} />}
                {!message && graphData && !modalData.state.avg_month_week &&
                    <Line height="300px" width="800px" options={graphOptions} ref="chart" data={data} />
                }
                {!message && graphData &&
                    <FormControl margin="normal" required className={classes.formControl}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                label="End Date"
                                minDate={moment()}
                                value={modalData.state.endDate}
                                format={"dd/MM/yyyy"}
                                InputLabelProps={{ shrink: true }}
                                autoOk={true}
                                onChange={modalData.handleEndDate()}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                }
                {modalData.state.avg_month_week && modalData.state.avg_month_week.monthly_average &&

                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {monthNames.map((val) => (
                                    <TableCell className={classes.tableCellPadding}>{val}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {modalData.state.avg_month_week.monthly_average.map((val) => (
                                    <TableCell component="th" scope="row" className={classes.tableCellPadding}>
                                        {val.percentage}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableBody>
                    </Table>
                }

                {modalData.state.avg_month_week && modalData.state.avg_month_week.weekday_average &&
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {daysInWeek.map((val) => (
                                    <TableCell>{val}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {modalData.state.avg_month_week.weekday_average.map((val) => (
                                    <TableCell component="th" scope="row">
                                        {val.percentage}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableBody>
                    </Table>
                }
            </DialogContent>
            <DialogActions>
                {!modalData.state.avg_month_week &&
                    <Button onClick={() => modalData.getAverageMonthWeek()} color="primary" autoFocus variant="contained">
                        {t("Get Monthly and Weekly Data")}
                    </Button>
                }
                {modalData.state.avg_month_week &&
                    <Button onClick={() => modalData.updateNewAdjustments()} color="primary" autoFocus variant="contained">
                        {t("Update New Adjustments")}
                    </Button>
                }
                <Button onClick={() => modalData.closeGraphModal()} color="primary" autoFocus>
                    {t("Close")}
                </Button>
            </DialogActions>
        </Dialog>)
    },
    rerunAlertPopup: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.settingAlert}
                onClose={() => modalData.toggleSettingAlert()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Re-run pricing to see your changes")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('Your new settings will be applied to all automated pricing runs. To see the changes already, please re-run the pricing (button).')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => modalData.toggleSettingAlert()} variant="outlined" color="primary" >
                        {t("Okay")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    roomSetupAlert: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.settingAlert}
                onClose={() => modalData.toggleSettingAlert()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Re-run pricing to see your changes")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t(`This turns on pricing for all of your ${modalData.state.room_apartment_space_name.toLowerCase()}s. If your ${modalData.state.room_apartment_space_name.toLowerCase()}s are not set up correctly (base price/derivation), then your pricing will be wrong.`)}
                        <br />
                        {t(`Please re-run the pricing and check all prices of all ${modalData.state.room_apartment_space_name.toLowerCase()}s before uploading again.`)}
                        <br /><br />
                        {t('Please don‘t hesitate to contact us if you have questions, we’re always happy to help!')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                  
                    <Button onClick={() => modalData.toggleSettingAlert()} variant="contained" color="primary" >
                        {t("Okay")}
                    </Button>
                    <Button onClick={() => modalData.toggleSettingAlert()} className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    autoSetupModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.openAutosetup}
                onClose={() => modalData.toggleAutoSetup()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t(`Please select your Reference ${modalData.state.room_apartment_space_name} Type`)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormControl fullWidth required className={classes.fieldLeft} margin="normal">
                            <InputLabel htmlFor="name" shrink={true}>{t(`${modalData.state.room_apartment_space_name} in ${modalData.state.pms_channel_manager_text}`)}</InputLabel>
                            <Select
                                id="standard-name"
                                select
                                className={classes.textField}
                                value={modalData.state.autoSetupPms}
                                onChange={modalData.handleChangeAuto("autoSetupPms")}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                margin="normal"
                            >
                                {modalData.state.pmsList.mapped_data.filter(option => (option.connected_room === null || (option.connected_room ? option.connected_room.id : option.connected_room) === modalData.state.editModalData.id)).map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                   
                    <Button onClick={() => modalData.setAutoSetup()} variant="contained" color="primary" >
                        {t("Save")}
                    </Button>
                    <Button onClick={() => modalData.toggleAutoSetup()} className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    editYieldingTagModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;

        if (modalData.state.editModalData) {
            return (<Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.openEditTag}
                onClose={modalData.closeEditYieldingTagModal}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Edit")+" "}{modalData.state.editModalData.tag_name}</DialogTitle>
                <DialogContent className={classes.popupDiv}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <FormControl required margin="normal" className={classes.fieldLeft}>
                                <InputLabel shrink={true} htmlFor="name">{t("Tag Name")}</InputLabel>
                                <Input
                                    error={modalData.state.editModalData.error.includes("tag_name")}
                                    id="standard-name"
                                    className={classes.textField}
                                    onChange={modalData.handleEditYieldingTag('tag_name')}
                                    value={modalData.state.editModalData.tag_name}
                                    placeholder={t(`Please enter Tag Name`)}
                                    margin="normal"
                                />
                                {modalData.state.editModalData.error.includes("tag_name") ? <span class='error-message'>{t("* Please enter Tag name")}</span> : ''}
                            </FormControl>

                            <FormControl margin="normal" required className={classes.fieldLeft}>
                                <InputLabel shrink={true} htmlFor="select-multiple-checkbox">{t(`${modalData.state.room_apartment_space_name} Types`)}</InputLabel>
                                <Select
                                    error={modalData.state.editModalData.error.includes("room_types")}
                                    className={classes.textField}
                                    multiple
                                    displayEmpty
                                    value={modalData.state.editModalData.selected_room_types}
                                    onChange={modalData.handleEditYieldingTag('selected_room_types')}
                                    input={<Input id="select-multiple-checkbox" />}
                                    renderValue={selected => {
                                        let jsx = [];
                                        if (selected.length == 0) {
                                            return <>{t(`Select Types`)}</>;
                                        }
                                        modalData.props.roomList.roomList.map(opt => {
                                            if (modalData.state.editModalData.selected_room_types.includes(opt.id)) {
                                                jsx.push(opt.name);
                                            }
                                        })
                                        return jsx.join(', ')
                                    }
                                    }
                                >    
                                    <MenuItem disabled value="">
                                        <>{t(`Select Types`)} </>
                                    </MenuItem>                                   
                                    {modalData.props.roomList.roomList.map(opt => (
                                        <MenuItem key={opt.id} value={opt.id}>
                                            <Checkbox color="primary" checked={modalData.state.editModalData.selected_room_types.includes(opt.id)} />
                                            <ListItemText primary={opt.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                {modalData.state.editModalData.error.includes("room_types") ? <span class='error-message'>{t('* Please select Room Type')}</span> : ''}
                            </FormControl>
                            <FormControl required margin="normal" className={classes.fieldLeft}>
                                <InputLabel shrink={true} htmlFor="name">{t("Colour")}</InputLabel>
                                <Select
                                    error={modalData.state.editModalData.error.includes("color")}
                                    id="standard-name"
                                    className={classes.textField}
                                    onChange={modalData.handleEditYieldingTag('color')}
                                    value={modalData.state.editModalData.color}                                    
                                    margin="normal"
                                    displayEmpty
                                >             
                                    <MenuItem disabled key={1} value={''}>
                                        {t('Select Color')}
                                    </MenuItem>                       
                                    {modalData.state.colorArray.map(val => (
                                        <MenuItem key={val} value={val}>
                                            <div style={{ height: "14px", width: "94%", background: val, display: "inline-block", marginRight: "10px" }}></div>
                                        </MenuItem>
                                    ))}
                                </Select>
                                {modalData.state.editModalData.error.includes("color") ? <span class='error-message'>{t("* Please enter color")}</span> : ''}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl required margin="normal" className={classes.fieldLeft} style={{ marginLeft: "25px" }}>
                                <InputLabel shrink={true} htmlFor="name">{t("Min Adjustment")}</InputLabel>
                                <Input
                                    error={modalData.state.editModalData.error.includes("min_adjustment")}
                                    id="standard-name"
                                    className={classes.textField}
                                    onChange={modalData.handleEditYieldingTag('min_adjustment')}
                                    value={modalData.state.editModalData.min_adjustment}
                                    placeholder={t(`Please enter Min Adjustment`)}
                                    margin="normal"
                                    type="number"
                                    endAdornment={"%"}
                                />
                                {modalData.state.editModalData.error.includes("min_adjustment") ? <span class='error-message'>{t("* Please enter Min Adjustment")}</span> : ''}
                                {modalData.state.editModalData.error.includes("more_than_min") ? <span class='error-message'>{t("* Min must be more than -20")}</span> : ''}
                                {modalData.state.editModalData.error.includes("min_zero") ? <span class='error-message'>{t("* Min must be less than or equal to 0")}</span> : ''}
                            </FormControl>
                            <FormControl required margin="normal" className={classes.fieldLeft} style={{ marginLeft: "25px" }}>
                                <InputLabel shrink={true} htmlFor="name">{t("Max Adjustment")}</InputLabel>
                                <Input
                                    error={modalData.state.editModalData.error.includes("max_adjustment") || modalData.state.editModalData.error.includes("max_min_adjustment")}
                                    id="standard-name"
                                    className={classes.textField}
                                    onChange={modalData.handleEditYieldingTag('max_adjustment')}
                                    value={modalData.state.editModalData.max_adjustment}
                                    placeholder={t(`Please enter Max Adjustment`)}
                                    margin="normal"
                                    type="number"
                                    endAdornment={"%"}
                                />
                                {modalData.state.editModalData.error.includes("max_adjustment") ? <span class='error-message'>{t("* Please enter Max Adjustment")}</span> : ''}
                                {modalData.state.editModalData.error.includes("max_min_adjustment") ? <span class='error-message'>{t("* Max must be greater than Min")}</span> : ''}
                                {modalData.state.editModalData.error.includes("more_than_max") ? <span class='error-message'>{t("* Max must be less than 20")}</span> : ''}
                                {modalData.state.editModalData.error.includes("max_zero") ? <span class='error-message'>{t("* Max must be more than or equal to 0")}</span> : ''}
                            </FormControl>
                            <FormControl required margin="normal" className={classes.fieldLeft} style={{ marginLeft: "25px" }}>
                                <InputLabel shrink={true} htmlFor="name">{t("Aggressiveness")}</InputLabel>
                                <Select
                                    error={modalData.state.editModalData.error.includes("speed")}
                                    id="standard-name"
                                    className={classes.textField}
                                    onChange={modalData.handleEditYieldingTag('speed')}
                                    value={modalData.state.editModalData.speed}
                                    placeholder={t(`Please select Aggressiveness`)}
                                    margin="normal"
                                    displayEmpty
                                >
                                    <MenuItem disabled key={-1} value={''}>{t("Select Aggressiveness")}</MenuItem>
                                    <MenuItem key={1} value={0.5}>Low</MenuItem>
                                    <MenuItem key={1} value={1}>Medium</MenuItem>
                                    <MenuItem key={1} value={1.5}>High</MenuItem>
                                    <MenuItem key={1} value={2}>Super High</MenuItem>
                                </Select>
                                {modalData.state.editModalData.error.includes("speed") ? <span class='error-message'>{t("* Please enter Aggressiveness")}</span> : ''}
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                  
                    <Button onClick={() => modalData.toggleWarningModal("updateYieldingTags")} variant="contained" color="primary" >
                        {t("Save")}
                    </Button>
                    <Button onClick={modalData.closeEditYieldingTagModal} className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>)
        }
    },
    DeleteYieldingTagModal: function (modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            open={modalData.state.openDeleteTag}
            onClose={modalData.closeDeleteYieldingTagModal}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t("Delete")} {modalData.state.deleteModalData.tag_name}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("Are you sure you want to delete ") + modalData.state.deleteModalData.tag_name + ' ?'}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
               
                <Button onClick={modalData.deleteYieldingTag} variant="contained" color="primary"  className="dangerBtnHvr">
                    {t("Delete")}
                </Button>
                <Button onClick={modalData.closeDeleteYieldingTagModal} className="cancelBtn" >
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>)
    },
    CreateYieldingTagModal: function (modalData) {

        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;

        if (modalData.state.editModalData) {
            return (<Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.openCreateTag}
                onClose={modalData.closeCreateYieldingTagModal}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Add New Yielding Tag")}</DialogTitle>
                <DialogContent className={classes.popupDiv}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <FormControl required margin="normal" className={classes.fieldLeft}>
                                <InputLabel shrink={true} htmlFor="name">{t("Tag Name")}</InputLabel>
                                <Input
                                    error={modalData.state.CreateModalData.error.includes("tag_name")}
                                    id="standard-name"
                                    className={classes.textField}
                                    onChange={modalData.handleCreateYieldingTag('tag_name')}
                                    value={modalData.state.CreateModalData.tag_name}
                                    placeholder={t(`Please enter Tag name`)}    
                                    margin="normal"
                                />
                                {modalData.state.CreateModalData.error.includes("tag_name") ? <span class='error-message'>{t("* Please enter Tag name")}</span> : ''}
                            </FormControl>
                            <FormControl margin="normal" required className={classes.fieldLeft}>
                                <InputLabel shrink={true} htmlFor="select-multiple-checkbox">{t(`${modalData.state.room_apartment_space_name} Types`)}</InputLabel>
                                <Select
                                    error={modalData.state.CreateModalData.error.includes("room_types")}
                                    className={classes.textField}
                                    multiple
                                    displayEmpty
                                    value={modalData.state.CreateModalData.selected_room_types} 

                                    onChange={modalData.handleCreateYieldingTag('selected_room_types')}
                                    input={<Input id="select-multiple-checkbox" />}
                                    renderValue={selected => {
                                        let jsx = [];                                        
                                        if (selected.length == 0) {
                                            return <>{t(`Select Types`)} </>;
                                        }
                                        modalData.props.roomList.roomList.map(opt => {
                                            if (modalData.state.CreateModalData.selected_room_types.includes(opt.id)) {
                                                jsx.push(opt.name);
                                            }
                                        })
                                        return jsx.join(', ')
                                    }
                                    }
                                >          
                                    <MenuItem disabled value="">
                                        <>{t(`Select Types`)} </>
                                    </MenuItem>          
                                    {modalData.props.roomList.roomList.map(opt => (
                                        <MenuItem key={opt.id} value={opt.id}>
                                            <Checkbox color="primary" checked={modalData.state.CreateModalData.selected_room_types.includes(opt.id)} />
                                            <ListItemText primary={opt.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                {modalData.state.CreateModalData.error.includes("room_types") ? <span class='error-message'>{t('* Please select Room Type')}</span> : ''}
                            </FormControl>

                            <FormControl required margin="normal" className={classes.fieldLeft}>
                                <InputLabel shrink={true} htmlFor="name">{t("Colour")}</InputLabel>
                                <Select
                                    error={modalData.state.CreateModalData.error.includes("color")}
                                    id="standard-name"
                                    className={classes.textField}
                                    onChange={modalData.handleCreateYieldingTag('color')}
                                    value={modalData.state.CreateModalData.color}
                                    displayEmpty
                                    margin="normal"
                                >   
                                    <MenuItem disabled key={1} value={''}>
                                        {t('Select Color')}
                                    </MenuItem>
                                    {modalData.state.colorArray.map(val => (
                                        <MenuItem key={val} value={val}>
                                            <div style={{ height: "14px", width: "94%", background: val, display: "inline-block", marginRight: "10px",overflow:'initial' }}></div>
                                        </MenuItem>
                                    ))}
                                </Select>
                                {modalData.state.CreateModalData.error.includes("color") ? <span class='error-message'>{t("* Please enter color")}</span> : ''}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl required margin="normal" className={classes.fieldLeft}>
                                <InputLabel shrink={true} htmlFor="name">{t("Min Adjustment")}</InputLabel>
                                <Input
                                    error={modalData.state.CreateModalData.error.includes("min_adjustment")}
                                    id="standard-name"
                                    className={classes.textField}
                                    onChange={modalData.handleCreateYieldingTag('min_adjustment')}
                                    value={modalData.state.CreateModalData.min_adjustment}
                                    placeholder={t(`Please enter Min Adjustment`)}
                                    margin="normal"
                                    endAdornment={<Typography variant="subtitle1" className={classes.adornment}>{'%'}</Typography>}
                                    type="number"
                                />
                                {modalData.state.CreateModalData.error.includes("min_adjustment") ? <span class='error-message'>{t("* Please enter Min Adjustment")}</span> : ''}
                                {modalData.state.CreateModalData.error.includes("more_than_min") ? <span class='error-message'>{t("* Min must be more than -20")}</span> : ''}
                                {modalData.state.CreateModalData.error.includes("min_zero") ? <span class='error-message'>{t("* Please enter a value <0")}</span> : ''}
                            </FormControl>
                            <FormControl required margin="normal" className={classes.fieldLeft} >
                                <InputLabel shrink={true} htmlFor="name">{t("Max Adjustment")}</InputLabel>
                                <Input
                                    error={modalData.state.CreateModalData.error.includes("max_adjustment") || modalData.state.CreateModalData.error.includes("max_min_adjustment")}
                                    id="standard-name"
                                    className={classes.textField}
                                    onChange={modalData.handleCreateYieldingTag('max_adjustment')}
                                    value={modalData.state.CreateModalData.max_adjustment}
                                    placeholder={t(`Please enter Max Adjustment`)}
                                    margin="normal"
                                    type="number"
                                    endAdornment={"%"}
                                />
                                {modalData.state.CreateModalData.error.includes("max_adjustment") ? <span class='error-message'>{t("* Please enter Max Adjustment")}</span> : ''}
                                {modalData.state.CreateModalData.error.includes("max_min_adjustment") ? <span class='error-message'>{t("* Max must be greater than Min")}</span> : ''}
                                {modalData.state.CreateModalData.error.includes("more_than_max") ? <span class='error-message'>{t("* Max must be less than 20")}</span> : ''}
                                {modalData.state.CreateModalData.error.includes("max_zero") ? <span class='error-message'>{t("* Please enter a value >0")}</span> : ''}
                            </FormControl>
                            <FormControl required margin="normal" className={classes.fieldLeft} >
                                <InputLabel shrink={true} htmlFor="name">{t("Aggressiveness")}</InputLabel>
                                <Select
                                    error={modalData.state.CreateModalData.error.includes("speed")}
                                    id="standard-name"
                                    className={classes.textField}
                                    onChange={modalData.handleCreateYieldingTag('speed')}
                                    value={modalData.state.CreateModalData.speed}
                                    margin="normal"
                                    displayEmpty
                                >   
                                    <MenuItem disabled key={-1} value={''}>{t("Select Aggressiveness")}</MenuItem>
,                                    <MenuItem key={1} value={0.5}>Low</MenuItem>
                                    <MenuItem key={1} value={1}>Medium</MenuItem>
                                    <MenuItem key={1} value={1.5}>High</MenuItem>
                                    <MenuItem key={1} value={2}>Super High</MenuItem>
                                </Select>
                                {modalData.state.CreateModalData.error.includes("speed") ? <span class='error-message'>{t("* Please enter Aggressiveness")}</span> : ''}
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                   
                    <Button onClick={modalData.createYieldingTags} variant="contained" color="primary" >
                        {t("Save")}
                    </Button>
                    <Button onClick={modalData.closeCreateYieldingTagModal} className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>)
        }
    },
    graphDetails: function (modalData) {
        if (modalData.state.graphCompetitor) {
            var competitorName = modalData.state.graphCompetitor.competitor.hotel.name;
        }
        if (modalData.state.graphData) {
            var title = modalData.state.graphData.datasets[0].label.toUpperCase();
        }

        const options = {
            legend: {
                display: false,
            },
            responsive: true,
            title: {
                display: false,
                text: 'Chart.js Line Chart'
            },
            tooltips: {
                mode: 'label'
            },
            // hover: {
            //     mode: 'dataset'
            // },
            scales: {
                xAxes: [
                    {
                        type: 'time',
                        time: {
                            parser: "ddd, D MMM YY",
                            unit: 'month',
                            unitStepSize: 1,
                        },
                        gridLines: {
                            color: '#ffffff',
                        },
                    }
                ],
                yAxes: [
                    {
                        display: true,
                        scaleLabel: {
                            show: true,
                            labelString: 'Value'
                        },
                        ticks: {
                            suggestedMin: modalData.state.min,
                            suggestedMax: modalData.state.max
                        }
                    }
                ]
            },
            tooltips: {
                enabled: true,
                mode: 'single',
                callbacks: {
                    label: (tooltipItems, data) => {
                        return (data.datasets[0].extra[tooltipItems.index] ? (data.datasets[0].extra[tooltipItems.index]) : "-") + ", Value:" + tooltipItems.value;
                    }
                }
            },
        }
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        return (<Dialog
            fullScreen={fullScreen}
            maxWidth='lg'
            open={modalData.state.showGraphToggle}
            onClose={modalData.closeWarningModal}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t(title + ' ' + 'GRAPH                       ')}
                <div>{competitorName}</div>
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    <Line height="400px" width="1200px" data={modalData.state.graphData} options={options} />
                </DialogContentText>

            </DialogContent>
            <DialogActions>
                <Button onClick={modalData.closeWarningModal} className="cancelBtn" >
                    Cancel
                </Button>

            </DialogActions>
        </Dialog>)
    },
    contactUsModal: function (modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        const { classes } = modalData.props;
        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            open={modalData.state.openContactModal}
            onClose={modalData.closeContactModal}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">Moving to {modalData.state.selectedPlanName}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <span>
                        Moving to a lower plan may change your price recommendations because certain feature won’t be available anymore. We’ll have a look and let you know as soon as possible. Please fill out the form below. Thank you very much!
                </span>

                </DialogContentText>
                <iframe style={{ 'border': 'none' }} src="https://static.roompricegenie.com/paymentSetting.html" height="280px" width="100%"></iframe>
            </DialogContent>
            <DialogActions>
                <Button onClick={modalData.closeContactModal} className="cancelBtn">
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>)
    },
    sortByScoreConfirmModal: function (modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.opensortByScoreConfirmModal}
                onClose={() => modalData.closeSortByScoreModal()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Score")}</DialogTitle>
                <DialogContent>
                    {'Would you like the competitors to be sorted after scoring?'}
                </DialogContent>
                <DialogActions>
                    <Button onClick={modalData.confirmSortAndScore} variant="contained" color="primary" >
                        {t("Score and Sort")}
                    </Button>
                    <Button onClick={modalData.confirmOnlyScore} variant="contained" color="secondary" >
                        {t("Only Score")}
                    </Button>
                    <Button onClick={modalData.closeSortByScoreModal} className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    FindAirDnaModal: function(modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        const { classes } = modalData.props;

        const SelectStyle = {
            menu: (provided, state) => ({
                ...provided,
                textAlign: "left",
                zIndex:"9999"
            }),
            control: (base, state) => ({
                ...base,
                zIndex:"9999",
                marginTop: "20px",
                height: "40px",
                border: 'none',
                boxShadow: "0 !important",
                width: '100%',
                minWidth: "450px",
                borderRadius: "4px !important",
                background: "#f2f2f2",
                textAlign: 'left',
                "&:hover": {
                    border: 'none',
                },
                "&:focus-within": {
                    border: 'none',
                },
                "&:active": {
                    border: 'none',
                },
            })
        };
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.findAirDnaCity}
                onClose={() => modalData.closeFindAirDnaCity()}
                aria-labelledby="responsive-dialog-title"                
            >
                <DialogTitle id="responsive-dialog-title">{t("Manual Search Airbnb city/region")}</DialogTitle>
                <DialogContent className={classes.findModal}>
                    <Grid xs={12} md={12} item>
                        <FormControl margin="none" className={classes.settingLable} >
                            <InputLabel shrink={true} className={classes.settingInputLable} htmlFor="name" >{t(`Airbnb city or region`)}</InputLabel>
                            <AsyncSelect
                                cacheOptions
                                onChange={modalData.handleMarketCode}
                                value={modalData.state.selectedTerm}
                                loadOptions={modalData.promiseOptions}
                                styles={SelectStyle}
                                placeholder="Enter city or region"
                                error={modalData.state.airDnaError}
                                required={true}
                            />
                            {modalData.state.airDnaError &&
                                <span class='error-message'>{'* Please Enter city or region.'}</span>
                            }
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={modalData.state.airDnaweightLoader} onClick={modalData.saveMarketCode} variant="contained" color="primary">
                        {t("Save")}
                        {modalData.state.airDnaweightLoader && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
                    </Button>
                    <Button onClick={modalData.closeFindAirDnaCity} className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    top20CompDistanceModel:function (modalData){
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        const { classes } = modalData.props;
        
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.getTop20Model}
                onClose={() => modalData.closeTop20Modal()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{t("Competitor Distance")}</DialogTitle>
                <DialogContent>
                    {'How close to the client should the competitor suggestions be?'}
                    <Grid xs={12} md={12} item>
                        <FormControl component="fieldset"  className={classes.fieldLeft} style={{float:'left',marginTop:'31px'}} margin="normal">
                        {/* <InputLabel shrink={true} required htmlFor="name" style={{marginTop:'-23px'}}>{("")}</InputLabel> */}
                        {/* <FormLabel component="legend" style={{float:'left'}}>Gender</FormLabel> */}
                        <RadioGroup aria-label="comp_distance" value={modalData.state.comp_distance} name="comp_distance" onChange={modalData.handleData('comp_distance')}  >
                            <FormControlLabel value="10"  control={<Radio  color="primary" />} label="Much Closer" />
                            <FormControlLabel value="30"  control={<Radio color="primary"  />} label="Closer" />
                            <FormControlLabel value="80"  control={<Radio color="primary" />} label="Standard" />
                            <FormControlLabel value="120" control={<Radio color="primary" />} label="Further Away" />
                            <FormControlLabel value="180" control={<Radio color="primary" />} label="Much Further Away" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>

                    {/* <Grid xs={12} md={12} item>
                        <FormControl component="fieldset"  className={classes.fieldLeft} style={{float:'left',marginTop:'31px'}} margin="normal">
                        <Select
                            value={modalData.state.comp_distance}
                            onChange={(event) => modalData.handleData(event.target.value)}
                            input={<OutlinedInput id="outlined-age-simple" />}
                            style={{ borderRadius: "10px", height: "36px" }}
                            SelectDisplayProps={{
                            style: {
                                height: "100%",
                                padding: "5px 25px 6px 10px",
                                borderRadius: "10px",
                                fontSize: "14px",
                                maxWidth: "500px",
                                minWidth: "80px",
                                textAlign: "left",
                                textTransform: 'capitalize'
                            },
                            }}
                        >
                            <MenuItem value='10' key='MuchCloser' style={{ textTransform: 'capitalize' }}>{t('Much Closer')}</MenuItem>
                            <MenuItem value='30' key='Closer' style={{ textTransform: 'capitalize' }}>{t('Closer')}</MenuItem>
                            <MenuItem value='80' key='standard' style={{ textTransform: 'capitalize' }}>{t('Standard')}</MenuItem>
                            <MenuItem value='120' key='furtherAway' style={{ textTransform: 'capitalize' }}>{t('Further Away')}</MenuItem>
                            <MenuItem value='180' key='muchfurtherAway' style={{ textTransform: 'capitalize' }}>{t('Much Further Away')}</MenuItem>    
                        </Select>
                        </FormControl>
                    </Grid> */}
                </DialogContent>
                <DialogActions>                  
                    <Button onClick={modalData.getTopCompetitors} variant="contained" color="primary" >
                        {t("Get Top 20")}
                    </Button>
                    <Button onClick={modalData.closeTop20Modal} className="cancelBtn" >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    connectChargebeeSubscription:function (modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        const { classes } = modalData.props;
        return (
            <Dialog
                transitionDuration={{ enter: 500, exit: 300 }}
                fullScreen={fullScreen}
                open={modalData.state.connectChargebeeModel}
                onClose={() => modalData.closeChargebeeModel()}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{ style: { width: "527px" } }}
            >
                <DialogTitle id="responsive-dialog-title">{t("Connect A Chargebee Subscription")}</DialogTitle>
                <DialogContent>
                    {/* <Grid xs={12} md={12} item> */}
                        <FormControl required  className={classes.fieldLeft}  style={{width:'100%'}} >
                            <InputLabel shrink={true} htmlFor="name">{t("Chargebee Subscription ID")}</InputLabel>
                            <Input
                                placeholder='Please enter a Chargebee subscription ID'
                                onChange={(e)=>modalData.chargebeeSubscription(e)}
                                name='subscription_id'
                                id="standard-name"
                                className={classes.textField}
                               value={modalData.state.subscription_id}
                            />
                        </FormControl>
                    {/* </Grid> */}
                    </DialogContent>
                <DialogActions>                  
                <Button onClick={modalData.connectChargebeeSubscription} variant="contained" color="primary" >
                    {t("connect")}
                </Button>
                <Button onClick={modalData.closeChargebeeModel} className="cancelBtn" >
                    {t("Cancel")}
                </Button>
                </DialogActions>
            </Dialog>
        );
    },

    dailyminmaxWarningModal:function (modalData) {

        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        const { classes } = modalData.props;

        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            open={(modalData.state.changed_max_warning_modal || modalData.state.changed_min_warning_modal) ? true : false}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{ modalData.state.changed_max_warning_modal ? t("Do you want to keep your daily max prices?") : t("Do you want to keep your daily min prices?")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    { modalData.state.changed_max_warning_modal ? t("You changed the default max price for this room type. Do you want to keep your daily max prices or do you want to reset them?") : t("You changed the default min price for this room type. Do you want to keep your daily min prices or do you want to reset them?")}                    
                </DialogContentText>
            </DialogContent>
            <DialogActions>               
                <Button onClick={() => modalData.handleDefaultMinMax('saveEditRoom',modalData.state.changed_max_warning_modal ? 'is_max' : 'is_min' ,false)} variant="contained" color="primary" >
                    { modalData.state.changed_max_warning_modal ? t("Keep Daily Max Prices") : t("Keep Daily Min Prices")}
                </Button>

                <Button onClick={() => modalData.handleDefaultMinMax('saveEditRoom',modalData.state.changed_max_warning_modal ? 'is_max' : 'is_min',true)} className="cancelBtn">
                    {/* #d3d3d3 */}
                    { modalData.state.changed_max_warning_modal ? t("Reset all daily max prices") : 'Reset all daily min prices'}
                </Button>            
            </DialogActions>
        </Dialog>)
    },
    setDefaultMinMaxModal:function (modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        const { classes } = modalData.props;
       return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            open={modalData.state.setDefaultModal}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t("Set default values for min/max?")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("Do you want to set the default min/max prices for the derived room types, which are derived from the min/max of the reference room?")}                    
                </DialogContentText>
            </DialogContent>
            <DialogActions>               
                <Button onClick={() => modalData.handleDefaultMinMaxModal(true)} variant="contained" color="primary" >
                    {t("Set default")}
                </Button>
                <Button onClick={() => modalData.handleDefaultMinMaxModal(false)} className="cancelBtn">
                    { t("No default, set myself")}
                    </Button>
            </DialogActions>
        </Dialog>)
    },
    addNewScheduleReport:function (modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        const { classes } = modalData.props;
        
    return (
      <Dialog
        transitionDuration={{ enter: 500, exit: 300 }}
        fullScreen={fullScreen}
        open={modalData.state.openAddModal}
        aria-labelledby="responsive-dialog-title"
        onClose={()=>modalData.toggleAddModal()}
      >
        <DialogTitle id="responsive-dialog-title">
          {modalData.state.addModal.id ? t("Edit Schedule Report") : t("Add new Schedule Report")} 
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid xs={6} item>
              <FormControl
                required
                margin="normal"
                className={classes.fieldLeft}
                style={{ width: "100%" }}
              >
                <InputLabel shrink={true} htmlFor="name">
                  {t("Report Name")}
                </InputLabel>
                <Input
                  error={modalData.state.error.includes("name")}
                  onChange={(e) => modalData.handleChange("name", e)}
                  value={modalData.state.addModal.name}
                  name="subscription_id"
                  id="standard-name"
                  className={classes.textField}
                />
                {modalData.state.error.includes("name") ? <span class='error-message'>{t("* Please enter Name")}</span> : ''}
              </FormControl>
            </Grid>
            <Grid xs={6} item>
              <FormControl
                required
                margin="normal"
                className={classes.fieldLeft}
              >
                <InputLabel shrink={true} htmlFor="name">
                  {t("Accounting Mode")}
                </InputLabel>
                <Select
                  error={modalData.state.error.includes("accounting_mode")}
                  id="standard-name"
                  className={classes.textField}
                  onChange={(e) => modalData.handleChange("accounting_mode", e)}
                  value={modalData.state.addModal.accounting_mode}
                  margin="normal"
                >
                  <MenuItem key={1} value={"NET"}>
                    NET
                  </MenuItem>
                  <MenuItem key={1} value={"GROSS"}>
                    GROSS
                  </MenuItem>
                </Select>
                {modalData.state.error.includes("accounting_mode") ? <span class='error-message'>{t("* Please select Accounting Mode")}</span> : ''}
              </FormControl>
            </Grid>
            <Grid xs={6} item>
              <FormControl
                required
                margin="normal"
                className={classes.fieldLeft}
              >
                <InputLabel shrink={true} htmlFor="name">
                  {t("Report Type")}
                </InputLabel>
                <Select
                  error={modalData.state.error.includes("report_type")}
                  id="standard-name"
                  className={classes.textField}
                  onChange={(e) => modalData.handleChange("report_type", e)}
                  value={modalData.state.addModal.report_type}
                  margin="normal"
                >
                  <MenuItem key={1} value={"PERFORMANCE"}>
                    PERFORMANCE
                  </MenuItem>
                </Select>
                {modalData.state.error.includes("report_type") ? <span class='error-message'>{t("* Please select Report Type")}</span> : ''}
              </FormControl>
            </Grid>
            <Grid xs={6} item>
              <FormControl
                required
                margin="normal"
                className={classes.fieldLeft}
                style={{width:"240px"}}
              >
                <InputLabel shrink={true} htmlFor="name">
                  {t("Recipients")}
                </InputLabel>
                <Select
                  className={classes.accessSelect}
                  error={modalData.state.error.includes("receipient_user_ids")}
                  multiple
                  onChange={(e) =>
                    modalData.handleChange("receipient_user_ids", e)
                  }
                  value={modalData.state.addModal.receipient_user_ids}
                  input={<Input id="select-multiple-checkbox" />}
                  renderValue={(selected) => {
                    let jsx = [];
                    modalData.state.userList.map((opt) => {
                      if (
                        modalData.state.addModal.receipient_user_ids.includes(
                          opt.user_id
                        )
                      ) {
                        jsx.push(opt.name);
                      }
                    });
                    return jsx.join(", ");
                  }}
                >
                  {modalData.state.userList.map((opt) => (
                    <MenuItem key={opt.user_id} value={opt.user_id}>
                      <Checkbox
                        color="primary"
                        checked={modalData.state.addModal.receipient_user_ids.includes(
                          opt.user_id
                        )}
                      />
                      <ListItemText primary={opt.name} />
                    </MenuItem>
                  ))}
                </Select>

                {modalData.state.error.includes("receipient_user_ids") ? <span class='error-message'>{t("* Please select Recipients")}</span> : ''}
              </FormControl>
            </Grid>

            <Grid xs={6} item>
              <FormControl
                required
                margin="normal"
                className={classes.fieldLeft}
              >
                <InputLabel shrink={true} htmlFor="name">
                  {t("Frequency")}
                </InputLabel>
                <Select
                  error={modalData.state.error.includes("frequency")}
                  id="standard-name"
                  className={classes.textField}
                  onChange={(e) => modalData.handleChange("frequency", e)}
                  value={modalData.state.addModal.frequency}
                  margin="normal"
                >
                  <MenuItem key={1} value={"DAILY"}>
                    DAILY
                  </MenuItem>
                  <MenuItem key={2} value={"WEEKLY"}>
                    WEEKLY
                  </MenuItem>
                  <MenuItem key={3} value={"MONTHLY"}>
                    MONTHLY
                  </MenuItem>
                </Select>
                {modalData.state.error.includes("frequency") ? <span class='error-message'>{t("* Please select Frequency")}</span> : ''}
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={modalData.createNewScheduleReport}
            variant="contained"
            color="primary"
          >
            {t("Save")}
          </Button>
          <Button onClick={() => modalData.toggleAddModal()} className="cancelBtn">
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
    },
    deleteScheduleReportmodal:function (modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        const { classes } = modalData.props;
       return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            open={modalData.state.openDeleteModal}
            onClose={() => modalData.toggleDeleteModal()}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t("Delete Schedule Report")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("Are you sure you want to delete this Schedule Report?")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            
                <Button onClick={modalData.deleteScheduleReport} color="Primary" variant="contained" autoFocus  className="dangerBtnHvr" style={{color:"#fff"}}>
                    {t("Delete")}
                </Button>
                <Button onClick={() => modalData.toggleDeleteModal()} className="cancelBtn" autoFocus>
                {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>)
    },    
    editSegmentationModal:function (modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        const { classes } = modalData.props;
       return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            fullScreen={fullScreen}
            open={modalData.state.openEditTag}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{modalData.state.addNewData.key ? t("Edit Segment") : t("Add new Segment")}</DialogTitle>
            <DialogContent style={{ display: 'flex', flexDirection: 'column'}}>                
                <FormControl margin="normal" className={classes.formControl}>
                    <InputLabel shrink={true} htmlFor="postcode">{t('Segment Name')}</InputLabel>
                    <Input
                        id="SegmentName"
                        name="name"
                        error={modalData.state.error.includes("name")}
                        className={classes.textField}
                        value={modalData.state.addNewData.name}
                        onChange={modalData.handleAddNew('name')}
                        required
                    />
                    {modalData.state.error.includes("name") ? <span class='error-message'>{t('* Please enter Name')}</span> : ''}
                </FormControl>
                
                <FormControl margin="normal" required className={classes.fieldLeft}>
                    <InputLabel shrink={true} htmlFor="select-multiple-checkbox">{t("Tags")}</InputLabel>
                    <Select
                        error={modalData.state.error.includes("tags")}
                        className={classes.accessSelect}
                        multiple
                        value={modalData.state.addNewData.tags}
                        onChange={modalData.handleAddNew('tags')}
                        input={<Input id="select-multiple-checkbox" />}
                        renderValue={selected => {
                            let jsx = [];
                            modalData.state.tagIds.map(opt => {
                                if (modalData.state.addNewData.tags.includes(opt)) {
                                    jsx.push(opt);
                                }
                            })
                            return jsx.join(', ')
                        }
                        }
                        style={{width:"240px"}}
                    >
                        {modalData.state.tagIds.sort().map(opt => (
                            <MenuItem disabled={modalData.state.usedKeys.includes(opt)} key={opt} value={opt}>
                                <Checkbox color="primary" checked={modalData.state.addNewData.tags.includes(opt)} />
                                <ListItemText primary={opt} />
                            </MenuItem>
                        ))}
                    </Select>
                    
                    {modalData.state.error.includes("tags") ? <span class='error-message'>{t('* Please select any Tag')}</span> : ''}
                </FormControl>
            </DialogContent>
            <DialogActions>               
                <Button onClick={() => modalData.addNewSegmentation()} variant="contained" color="primary" >
                   { t("Save")}
                </Button>
                <Button onClick={() => modalData.toggleModal()} className="cancelBtn">
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>)
    },
    deleteSegmentationModal:function (modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        const { classes } = modalData.props;
        
       return (<Dialog
        transitionDuration={{ enter: 500, exit: 300 }}
        fullScreen={fullScreen}
        open={modalData.state.openDeleteModal}
        aria-labelledby="responsive-dialog-title"
    >
        <DialogTitle id="responsive-dialog-title">{t("Delete Segment")}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {t("Are you sure you want to delete this segment?")}                    
            </DialogContentText>
        </DialogContent>
        <DialogActions>     
                      
            <Button onClick={() => modalData.deleteSegmentation()} variant="contained" color="primary" className="dangerBtnHvr">
                { t("Delete")}
            </Button>
            <Button onClick={() => modalData.toggleDeleteModal()} className="cancelBtn">
                {t("Cancel")}
            </Button>
        </DialogActions>
    </Dialog>)
    },
    deleteAllOccupancySettingsModal:function (modalData) {
        const { fullScreen } = modalData.props;
        const { t } = modalData.props;
        const { classes } = modalData.props;
        
       return (<Dialog
        transitionDuration={{ enter: 500, exit: 300 }}
        fullScreen={fullScreen}
        open={modalData.state.allOccupancyDeleteSettins}
        aria-labelledby="responsive-dialog-title"
    >
        <DialogTitle id="responsive-dialog-title">{t("Changing Occupancy Derivation")}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {t("This will remove all daily occupancy pricing settings.")}                    
            </DialogContentText>
        </DialogContent>
        <DialogActions>     
                      
            <Button onClick={() => modalData.deleteAllOccupancySetings()} variant="contained" color="primary">
                { t("Remove all daily occupancy pricing settings")}
            </Button>
            <Button onClick={() => modalData.deleteAllOccupancySetingsCloseModal()} className="cancelBtn">
                {t("Cancel")}
            </Button>
        </DialogActions>
    </Dialog>)
    },
    autoRunPriceSegmentationModal: function (modalData) {
        const { classes } = modalData.props;
        const { t } = modalData.props;
        const { fullScreen } = modalData.props;

        return (<Dialog
            transitionDuration={{ enter: 500, exit: 300 }}
            maxWidth='sm'
            open={modalData.state.autoRunModal}
        >
            <DialogContent>
                <Grid container style={{ maxWidth: "450px" }} >
                    {/* <Grid xs={12} md={3} lg={3} item ></Grid> */}
                    <Grid xs={12} md={12} lg={12} item style={{display:'flex',justifyContent:'center'}}>
                        <img className={classes.priceCalModal} src="/img/price_calculation_new.gif" style={{ margin: "auto" }} />
                    </Grid>
                    {/* <Grid xs={12} md={3} lg={3} item ></Grid> */}
                    <Grid xs={12} md={12} lg={12} item >
                        <Typography variant="subtitle2" color="primary" className={classes.autorunProgress}>                            
                            {t("Applying segments to your prices…")}
                        </Typography>
                    </Grid>

                    <Grid xs={12} md={12} lg={12} item >                        
                        <LinearProgress color="primary" variant="indeterminate" className={classes.autorunProgress} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
            </DialogActions>
        </Dialog >)
    },
    
}
