import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ChangesPasswordCss from './css/ChangesPasswordCss';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../store/actions/index';
import FormControl from '@material-ui/core/FormControl';
import { withNamespaces } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import HeaderCard from './HeaderCard';
import Typography from '@material-ui/core/Typography';

const styles = ChangesPasswordCss;

class ChangePassword extends React.Component {
  state = {
    error: [],
    new_password: ''
  };

  componentWillReceiveProps(props) {
    if (props.accountReducer.updated) {
      this.setState({
        new_password: '',
      })
    }
  }


  validateForm = (data) => {
    let error = [];
    if (data) {

      if (!this.state.new_password) {
        error.push('new_password')
      }

      if (error) {
        this.setState({
          error: error
        })
        return error
      } else {
        return false
      }
    } else {
      return true;
    }
  }


  updatePassword = () => {

    let validateError = this.validateForm(this.state);
    if (validateError.length > 0) {
      return;
    }

    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      user: this.props.hotelAuthReducer.hotelDetails.hotel.client,
      new_password: this.state.new_password
    }
    this.props.ResetClientPassword(requestParam);
  }

  handleValue = field => (event) => {
    this.setState({
      [field]: event.target.value,
    })
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    let width = window.screen.width;


    return (
      <>
      <HeaderCard >
          <Grid container>
            <Grid item xs={12} >
              <div className={classes.divStyle} >
                  <Typography variant="h5" className={classes.fontStyle} >
                    Change Password
                  </Typography>
              </div>
            </Grid>
          </Grid>
        </HeaderCard>
      <Grid container direction={width < 800 ? "column" : ""}>
        <Grid item lg={6} md={12} sm={12} className={classes.fullWidth}>
          <Card>
            <CardContent>
              <form className={classes.formContainer} noValidate autoComplete="off">
                <FormControl required className={classes.formControl} >
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="standard-name"
                    label={t('New password')}
                    value={this.state.new_password}
                    onChange={this.handleValue('new_password')}
                    className={classes.textField}
                    error={this.state.error.includes("new_password")}
                    placeholder={'Please enter New Password'}
                  />
                  {this.state.error.includes("new_password") ? <span class='error-message' className={classes.spanError} >{'* Please enter password'}</span> : ''}
                </FormControl>
                <div className={classes.btnDiv}>
                  <Button onClick={() => this.updatePassword()} className={classes.saveBtn} variant="contained" color="primary" >
                    {t('Change password')}
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card >
        </Grid>
      </Grid>
      </>
    );
  }
}


const mapStateToProps = state => {
  return {
    loading: state.authReducer.loading,
    error: state.authReducer.error,
    userSession: state.sessionReducer,
    hotelDetail: state.hotelSettingReducer.hoteDetail,
    hotelAuthReducer: state.hotelAuthReducer,
    accountReducer: state.accountReducer
  };
};

const mapDispatchToProps = dispatch => {
  return { ResetClientPassword: (request) => { dispatch(actions.resetClientPassword(request)) } };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(ChangePassword));