import * as actionTypes from '../actionTypes';
import Server from '../../../config/server'
import axios from 'axios';
import Notify from '../Notify';
import * as actions from '../index';

const onWeeklyAdjustStart = () => {
    return {
        type: actionTypes.WEEKLY_ADJUST_START
    }
}

const onWeeklyAdjustFailed = (error) => {
    return {
        type: actionTypes.WEEKLY_ADJUST_FAILED,
        error: error
    }
}

const onWeeklyAdjustSucceed = (data) => {
    return {
        type: actionTypes.WEEKLY_ADJUST_SUCCEED,
        state: data
    }
}

export const getWeeklyAdjust = (request) => {
    return dispatch => {
        // dispatch(onWeeklyAdjustStart())
        // axios.defaults.headers.common['Authorization'] = "Token " + request.token;
        // axios.get(Server.API + 'hotel/adjustment/daily/list/')
        //     .then(function (response) {
        //         dispatch(onWeeklyAdjustSucceed(response.data))
        //     })
        //     .catch(function (error) {
        //         dispatch(onWeeklyAdjustFailed(error))
        //     });
    }
}

export const updateWeeklyAdjust = (request) => {
    return dispatch => {        
        if (request.allData) {
            dispatch(actions.updateSettingJson(request, [], 'weeklyAdjust'))
        } else {
            dispatch(actions.updateSettingJson(request, [], 'singleweeklyAdjust'))
        }
        // dispatch(actions.getSmartSetting(request))
        dispatch(Notify.newUserModal("Weekdays Adjustment updated successfully", true))       
    }
}