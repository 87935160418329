import React from 'react';
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from "../../store/actions";
import moment from "moment";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { onDemenadScheduleTheme } from "../muiTheme";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import MUIDataTable from "mui-datatables";
import CardContent from "@material-ui/core/CardContent";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from '@material-ui/icons/Clear';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import HeaderCard from "../Client/HeaderCard";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import ClientListCss from './css/clientListCss';
import { withStyles } from '@material-ui/core/styles';

const styles = ClientListCss;

const statusDataArray = [
    { label: 'Scheduled', value: 1},
    { label: 'In Progress', value: 2},
    { label: 'Done', value: 3},
]

class RateSetting extends React.Component {

    state = {
        scheduleList: [],
        error: false,
        searchTextbox:false,
    };

    componentWillMount() {
        let requestParam = { token: this.props.userSession.token }
        this.props.getDemandSchedule(requestParam);
    }

    componentWillReceiveProps(props) {
        if (props.demandScheduleReducer.demandScheduleList) {
            this.setState({
                count: props.demandScheduleReducer.demandScheduleList.count,
                current: props.demandScheduleReducer.demandScheduleList.current,
                next: props.demandScheduleReducer.demandScheduleList.next,
                previous: props.demandScheduleReducer.demandScheduleList.previous,
                scheduleList: props.demandScheduleReducer.demandScheduleList.results
            })
        }
    }
    handleClick= ()=> {
        this.setState({
            searchTextbox:true,
        })    
    }
    handleCloseSerch= ()=> {
        this.setState({
            searchTextbox:false,
        })
        let requestParams = {
            token: this.props.userSession.token,
            url: 'amalgamation/scrape/history/ondemand/',
        };
        this.props.getDemandSchedule(requestParams)    
    }
    handleChange = (name) => event => {
        this.setState({
            [name]: event.target.value,
        });
        
        let requestParams = {
            token: this.props.userSession.token,
            url: 'amalgamation/scrape/history/ondemand/?hotel_name=' + event.target.value   ,
        };
        this.props.getDemandSchedule(requestParams)
    }
    renderStatus = (data) => {
        if (data == 1) {
            return 'Scheduled'
        } else if (data == 2) {
            return 'In Progress'
        } else if (data == 3) {
            return 'Done'
        } else {
            return 'failed'
        }
    }
    renderScrapData = (data) => {
        let jsx = [];
        data.map((val) => {
            jsx.push([
                val.hotel_list,
                val.hotel_name,
                val.scraper_version,
                val.record_scraped?val.record_scraped:0,
                val.client_hotel_id,
                moment(val.start_time).format('YYYY-MM-DD HH:mm'),
                moment(val.end_time).format('YYYY-MM-DD HH:mm'),
                this.renderStatus(val.status)
            ])
            return null;
        })
        return jsx;
    }

    options = () => {
        return {
            filter: true,
            responsive: 'scroll',
            print: false,
            download: false,
            viewColumns: false,
            responsive: "scroll",
            selectableRows: false,
            serverSide: true,
            count: this.state.count,
            rowsPerPage: 20,
            rowsPerPageOptions: false,
            search: true,
            onTableChange: (action, tableState) => {
                if (action === 'changePage') {
                    let page = tableState.page + 1;
                    let requestParams = {
                        token: this.props.userSession.token,
                        url: 'amalgamation/scrape/history/ondemand/?page=' + page,
                    };
                    this.props.getDemandSchedule(requestParams)
                }
                if (action === 'search') {
                    let searchText = tableState.searchText ? tableState.searchText : '';
                    let requestParams = {
                        token: this.props.userSession.token,
                        url: 'amalgamation/scrape/history/ondemand/?search=' + searchText,
                    };
                    this.props.getDemandSchedule(requestParams)
                }
                let filter = tableState.filterList.filter(val => val.length > 0)
                if (filter && filter.length) {
                    if (tableState.filterList[5].length > 0) {
                        let status = statusDataArray.filter(row => row.label == tableState.filterList[5])[0].value;
                        let requestParams = {
                            token: this.props.userSession.token,
                            url :'amalgamation/scrape/history/ondemand/?status='  + status
                        }; 
                        this.props.getDemandSchedule(requestParams)

                    }
                }else{
                    let requestParams = {
                        token: this.props.userSession.token,
                        url :'amalgamation/scrape/history/ondemand/'
                    }; 
                    this.props.getDemandSchedule(requestParams)
                }
            },
           
            
            textLabels: {
                body: {
                    noMatch: this.props.demandScheduleReducer.loading ?
                        'Loading Records...' :
                        'Sorry, no matching records found.',
                },
            },
        }
    };


    render() {
        const { classes } = this.props;

        const columns = [
            {
                name: " Hotel Id" ,
                options: {
                    filter: false
                }
            },
            { 
                name: "Name",
                options: {
                    filter: false
                }
            },
            { 
                name: "Scraper Version",
                options: {
                    filter: false
                }
            },
            {
                name:"Record Scraped",
                options:{
                    filter:false
                }
            },
            {
                name:"Client Hotel Id",
                options:{
                    filter:false
                }
            },
            { 
                name: "Start Date" ,
                options: {
                    filter: false
                }
            },
            { 
                name: "End Date" ,
                options: {
                    filter: false
                }
            },
            { 
                name: "Status" ,
                options: {
                    filter: true,
                    filterOptions: statusDataArray.map(row => { return row.label }),
                }
            }
        ];
        return (
            <>
                <HeaderCard>
                    <Grid container>
                        <Grid item >
                            <div className={classes.divStyle} >
                            <Typography variant="h5" className={classes.fontStyle} >
                                On Demand Schedule
                            </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </HeaderCard>
            <Card>  
                <CardContent>
                <MuiThemeProvider theme={onDemenadScheduleTheme}>
                    <MUIDataTable
                        title={''}
                        data={this.renderScrapData(this.state.scheduleList)}
                        columns={columns}
                        options={this.options()}
                    />
                </MuiThemeProvider>
               
                </CardContent>
            </Card >
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        userSession: state.sessionReducer,
        demandScheduleReducer: state.demandScheduleReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getDemandSchedule: (request) => { dispatch(actions.getDemandSchedule(request)) },
    };
};

export default compose(withStyles(styles, { withTheme: true }) ,connect(mapStateToProps, mapDispatchToProps))(RateSetting);