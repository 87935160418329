import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { compose } from 'redux'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import loginCss from '../css/login'
import Divider from '@material-ui/core/Divider';
import { Typography, Button, Icon, CardContent } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { withNamespaces } from 'react-i18next';
import CardMedia from '@material-ui/core/CardMedia';
import * as actions from '../../store/actions/index';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CloseIcon from '@material-ui/icons/Close';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import ListItemText from '@material-ui/core/ListItemText';

const styles = loginCss;

const listTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontSize: 12,
    color: "red"
  },
  overrides: {
    MuiListItem:{
      button:{
        '&:hover': {
          background:'#3e72ff63 !important'
        }
      }
    },
    MuiDialogActions: {
      root: {
        justifyContent: "flex-start",
        margin: "8px 20px",
      }
    },
    MuiSelect: {
      icon: {
        color: "#000000bf"
      },
      select: {
        '&:focus-within': {
          background: "none"
        }
      }
    },
    MuiButton: {
      // containedPrimary:{
      //   background:'#0073EA !important',
      // },
      outlinedPrimary: {
        color: '#0073EA !important',
        border: '1px solid #0073EA',
        background: 'white',
        '&:hover': {
          background: '#f2f2f2'
        }
      },
      root: {
        borderRadius: "4px",
      },
    },
    MuiIcon: {
      root: {
        color: '#D8DAE5'
        // color: "#A0A2A6"
      }
    },
    MuiListItemText: {
      primary: {
        // color: "#000000de"
        color: '#D8DAE5'
      }
    },
    MuiInputBase: {
      inputMultiline: {
        padding: '6px 0 7px'
      }
    },
    MuiInput: {
      error: {
        background: "#ffe4e4",
        border: "1px solid #ffa4a4",
      },
      disabled: {
        background: "#d5d5d5"
      },
      root: {
        background: "#f2f2f2",
        border: "none",
        borderRadius: "4px",
        marginTop: "20px !important",
        padding: "4px 4px 4px 8px",
        '&:hover': {
          background: "#f2f2f2", //535454
          border: "none",
          borderRadius: "4px",
          "&&&:before": {
            borderBottom: "none"
          },
        },
        '&:focus-within': {
          background: "#e9efff",
          boxShadow: "0px 0px 5px -2px #e9efff",
          border: "none",
          borderRadius: "4px",
          "&&&:before": {
            borderBottom: "none"
          },
        },
      },
      underline: {
        "&&&:before": {
          borderBottom: "none"
        },
        "&&:after": {
          borderBottom: "none"
        }
      },
    },
    MuiInputLabel: {
      root: {
        fontWeight: "400",
        fontSize: "19px",
        fontWeight: "500",
        marginLeft: "1px",
        color: "#4b4b4b !important",
      }
    }
  },
});

class PreparingAccount extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEh: null,
    }
  }
  componentWillReceiveProps(props) {
  }
  handleLogout = () => {
    localStorage.removeItem("__UserProps__")
    localStorage.removeItem("email")
    localStorage.removeItem("name")
    localStorage.removeItem("isClientView");
    window.location = "/";
  }

  toggleClientView = (value) => {
    console.log(this.props)
    let requestParam = {
      token: this.props.hotelAuthReducer.hotelDetails.token,
      is_client_view: value
    }
    this.props.updateHotelView(requestParam);
    window.location.reload();
  }

  handleHotel = event => {
    this.setState({ anchorEh: event.currentTarget });
  };

  changeHotel = (index) => {
    window.location = "/client/Calendar/" + this.props.hotelAuthReducer.hotelDetails.hotel_list[index].id;
  }

  handleClosehotel = () => {
    this.setState({ anchorEh: null });
  }

  render() {
    const { anchorEh } = this.state;
    const Hotelopen = Boolean(anchorEh);
    const { classes, t } = this.props;
    const theme = createMuiTheme({
      palette: {
        primary: { main: "#0073EA" }, //1098F7 2196f3
        secondary: { main: "#541388" }, //564787
        background: {
          default: '#f0f4f9'
        }
      },
      typography: {
        useNextVariants: true,
        fontSize: 14,
      },
      overrides: {
        MuiDialogActions: {
          root: {
            justifyContent: "flex-start",
            margin: "8px 20px",
          }
        },

        MuiPaper: {
          root: {
            // boxShadow: 'none !important',
          }
        },
        MuiSelect: {
          icon: {
            color: "#000000bf"
          },
          select: {
            '&:focus-within': {
              background: "none"
            }
          }

        },
        MuiSwitch: {
          bar: {
            opacity: "0.26"
          },
          colorPrimary: {
            "&$checked": {
              color: '#0073ea'
            }
          }
        },
        MuiPrivateTabScrollButton: {
          root: {
            width: "30px",
            '@media screen and (min-width: 800px)': {
              width: "0px "
            }
          }
        },
        MuiTooltip: {
          tooltip: {
            fontSize: 14
          }
        },
        MuiCardHeader: {
          root: {
            padding: "16px 16px 16px 20px"
          },
          title: {
            fontWeight: "500"
          }
        },
        MuiDialog: {
          paper: {
            borderRadius: "15px",
            boxShadow: "none"
          }
        },
        MuiAppBar: {
          colorPrimary: {
            backgroundColor: "#292f4c",
            color: "#6E6F72",
            boxShadow: "none"
          }
        },
        MuiCardContent: {
          root: {
            padding: "20px",
          }
        },
        MuiDrawer: {
          paper: {
            '&:hover': {
              overflowY: 'auto'
            },
            overflowY: 'hidden'
            // borderRight: "none !important"
          }
        },
        MuiCard: {
          root: {
            maxWidth: "1300px",
            borderRadius: "15px",
            boxShadow: 'none',
            border: "1px solid #e5e8ec",
          }
        },
        MuiButton: {
          // outlined:{
          //   padding: '7px 16px'
          // },
          contained: {
            boxShadow: 'none',
            textTransform: 'capitalize'
          },
          root: {
            // padding: '7px 16px',
            borderRadius: "4px",
            textTransform: 'capitalize',
            boxShadow: 'none'
          }
        },
        MuiStepper: {
          root: {
            padding: "0px 0px 24px 0px"
          }
        },
        MuiTab: {
          textColorPrimary: {
            color: "#A0A2A6",
            selected: {
              color: "#0073ea",
            }
          },
          root: {
            textTransform: 'capitalize',
          }
        },
        MuiPrivateNotchedOutline: {
          root: {
            borderRadius: "4px"
          }
        },
        MuiOutlinedInput: {
          borderRadius: "10px",
          notchedOutline: {
            border: "1px solid rgba(0, 0, 0, 0.23) !important"
          }
        },
        MuiInput: {
          error: {
            background: "#ffe4e4",
            border: "1px solid #ffa4a4",
          },
          disabled: {
            background: "#d5d5d5"
          },
          root: {
            background: "#f2f2f2",
            border: "none",
            borderRadius: "4px",
            marginTop: "20px !important",
            padding: "4px 4px 4px 8px",
            '&:hover': {
              background: "#f2f2f2", //535454
              border: "none",
              borderRadius: "4px",
              "&&&:before": {
                borderBottom: "none"
              },
            },
            '&:focus-within': {
              background: "#e9efff",
              boxShadow: "0px 0px 5px -2px #e9efff",
              border: "none",
              borderRadius: "4px",
              "&&&:before": {
                borderBottom: "none"
              },
            },
          },
          underline: {
            "&&&:before": {
              borderBottom: "none"
            },
            "&&:after": {
              borderBottom: "none"
            }
          },
        },
        MuiInputLabel: {
          root: {
            fontWeight: "400",
            fontSize: "19px",
            fontWeight: "500",
            marginLeft: "1px",
            color: "#4b4b4b !important",
          }
        }
        // .MuiFormLabel-root-10725.MuiFormLabel-focused-10726
        // .MuiOutlinedInput-root-10071 .MuiOutlinedInput-notchedOutline-10078

        //.MuiOutlinedInput-root-11266 .MuiOutlinedInput-notchedOutline-11273

      },
      MuiTableRow: {
        root: {
          background: "#F5F5F5",//test-table
          '&:hover': {
            background: "#00000014 !important",//test-table 
          }
        },
        footer: {
          background: "#FFFF",//test-table 
          '&:hover': {
            background: "#FFFF !important",//test-table 
          }
        }
      },
    });

    let is_admin = (this.props.userSession.user.is_staff || this.props.userSession.user.sub_account_type) ? true : false;

    let ClientId = this.props.hotelAuthReducer.hotelDetails.hotel.id;

    let reseller = this.props.hotelAuthReducer.hotelDetails.hotel.reseller;

    let siteLogo = "";

    if (ClientId && reseller && reseller.id == 9) {
      siteLogo = "/img/Hoteliers Inspiration logo.png";
    } else if (ClientId && reseller && reseller.id == 35) {
      siteLogo = "/img/revparlogo.png";
    } else if (ClientId) {
      siteLogo = "/img/roompricegenie_logo_new.svg";
    }

    return (
      <>
        <MuiThemeProvider theme={theme}>
          <div className="mainConentBody">
            {/* <AppBar position="fixed" className={classes.appBar} >
              <Toolbar>

              </Toolbar>
            </AppBar> */}
            <nav className={classes.drawer}>
              <SwipeableDrawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
                onOpen={() => false}
              >
                <div key={1}>
                  <div className={classes.toolbar} >
                    <a href={is_admin ? '/Admin/Clients' : ('/client/Calendar/' + ClientId)}>
                      <CardMedia
                        className={(ClientId && reseller && reseller.id == 35) ? classes.siteLogo1 : ((ClientId && reseller && reseller.id == 9) ? classes.siteLogoHotelLiers : classes.siteLogo)}
                        image={siteLogo}
                        title="Kanhasoft"
                      />
                    </a>
                  </div>
                  {this.props.hotelAuthReducer.hotelDetails.hotel_list &&
                    <Menu
                      id="menu-appbar1"
                      anchorEl={anchorEh}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      open={Hotelopen}
                      onClose={this.handleClosehotel}
                    >
                      {this.props.hotelAuthReducer.hotelDetails.hotel_list.sort((a, b) => {
                        if (a.name < b.name) { return -1; }
                        if (a.name > b.name) { return 1; }
                        return 0;
                      }).map((val, index) => (
                        <MenuItem className={classes.hotelLists} key={index} onClick={() => this.changeHotel(index)} selected={(this.props.hotelAuthReducer.hotelDetails.hotel.id === val.id)}>{val.name}</MenuItem>
                      ))}
                    </Menu>
                  }
                  <Divider style={{background:'#ffffff2b'}}/>
                  <div className={classes.hotelTitleList} >
                    <Typography
                      className={classes.selectHotel}
                      variant="subtitle2"
                      color="inherit"
                      onClick={this.handleHotel}
                      noWrap
                    >
                      {this.props.hotelAuthReducer.hotelDetails.hotel.name ? this.props.hotelAuthReducer.hotelDetails.hotel.name : '-'}
                    </Typography>
                    <Icon onClick={this.handleHotel} className={classes.hotelDrop}>arrow_drop_down</Icon>
                  </div>
                  <Divider style={{background:'#ffffff2b'}}/>
                  <MuiThemeProvider theme={listTheme}>
                    <List className={classes.list}>
                      <div key={1}>
                        <div
                          tabIndex={0}
                          role="button"
                          onClick={this.closeDrawerToggle}
                          onKeyDown={this.closeDrawerToggle}
                        >
                          {this.props.userSession.user.isClientView &&
                            <ListItem className={classes.active} button onClick={() => this.toggleClientView(false)} >
                              <Icon>person</Icon>
                              <ListItemText inset primary={"Admin View"} />
                            </ListItem>
                          }
                          <ListItem className={classes.active} button onClick={this.handleLogout}>
                            <Icon>login</Icon>
                            <ListItemText inset primary={t('Logout')} />
                          </ListItem>

                        </div>
                      </div>
                    </List>
                  </MuiThemeProvider>
                </div>
              </SwipeableDrawer>
            </nav>
          </div>
          <div >
            <Card style={{ boxShadow: "none !important", marginLeft: "266px", marginTop: "16px" }} elevation={0}>
              <div className={this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 10 ? classes.columnData : ""} style={{ background: 'white' }}>
                <div className={this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 10 ? classes.columnDataDiv : ""}>
                  {/* <img src="/img/newLogo.png" style={{ marginTop: "10px", height: "85px" }} /> */}
                  <CardHeader
                    style={{ fontWeight: "500 !important", margin: '0 auto', fontWeight: '500',paddingTop:'50px' }}
                    className="headig-top"
                    title={t("We're almost there!")}>
                  </CardHeader>
                  <CardContent style={{ display: 'flex', justifyContent: 'center' }}>
                    <div>
                      <Typography align={"center"} style={{ fontSize: "1rem", padding: '0 0px', width: 'auto', maxWidth: '700px' }}>
                        {this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 10 ?
                          t("Your dynamic pricing recommendations are almost ready for you to see, but we need to check a few things with you first. If you do not already have a meeting booked with our revenue manager, please book a call here:")
                          :
                          t("We're just putting the finishing touches to your account and can't wait to show it to you on the setup call. Please use the chat if you have any questions in the mean time.")}
                      </Typography>
                    </div>
                  </CardContent>

                </div>
                <div className={this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 10 ? classes.columnDataDiv : ""}>
                  {this.props.hotelAuthReducer.hotelDetails.hotel.pms_provider == 10 ?
                    <iframe src={"https://static.roompricegenie.com/hotel_spider_lock_screen.html?country=" + this.props.hotelAuthReducer.hotelDetails.hotel.country.name} className={classes.hotelspiderIframe} />
                    :
                    <img height="250" src="/img/market.gif" style={{ marginTop: "10px" }} />
                  }</div>
              </div>

            </Card>

          </div>
        </MuiThemeProvider>
      </>
    );
  }
}

PreparingAccount.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  container: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    userSession: state.sessionReducer,
    hotelAuthReducer: state.hotelAuthReducer,
    hotelDetailReducer: state.hotelSettingReducer,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    hotelAuth: (request) => { dispatch(actions.hotelAuth(request)) },
    updateHotelView: (request) => { dispatch(actions.updateHotelView(request)) },
    getHotelDetail: (request) => { dispatch(actions.getHotelDetail(request)); },

  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(withNamespaces('translations')(PreparingAccount));