import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = [];

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TABLE_SETTING:
            return updateObject(state, { priceSetting: action.data })
        case actionTypes.GET_RUN_FLAG:
            return updateObject(state, { priceFlag : action.data })  
        case actionTypes.PRICING_SETTINGS_START:
            return updateObject(state, { loadData:true,error: null })                        
        case actionTypes.PRICING_SETTINGS_SUCCEED:
            return updateObject(state, { loadData:false,error: null,storePricingSeting:action.data })   
        case actionTypes.PRICING_SETTINGS_FAILED:
            return updateObject(state, { loadData:false,error: action.error })    
        case actionTypes.USER_NOTES_SUCCEED:
            return updateObject(state, { loadData:false,error: null,userNotesList:action.state })                                   
        default:
            return updateObject(state, { loading: false, error: null });
    }
}

export default reducer