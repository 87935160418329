const developerDataArray = [
    { label: 'Not Assigned', value: 'notassigned' },
    { label: 'Aji', value: 'aji' },
    { label: 'Bagas', value: 'bagas' },
    { label: 'Chirag', value: 'chirag' },
    { label: 'Dipak', value: 'dipak' },
    { label: 'Hendrik', value: 'hendrik' },
    { label: 'Mumtoz', value: 'mumtoz' },
    { label: 'Nilay', value: 'nilay' },
    { label: 'Niam', value: 'niam' },
    { label: 'Parth', value: 'parth' },
    { label: 'Rajesh', value: 'rajesh' },
    { label: 'Wahid', value: 'wahid' },
  ];
  
  export default developerDataArray