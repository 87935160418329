var style = {
  root: {
    flexGrow: 1,
  },
  cellTitle:{
    paddingLeft: '10px !important'
  },
  RadioGroup: {
    display: 'inline-block',
  },
  emailLabel: {
    marginTop: '20px'
  },
  userTable: {
    padding: '20px',
    minWidth: "600px"
  },
  addUserBtn: {
    float: 'right'
  },
  CarHeader: {
    textAlign: 'left'
  },
  root: {
    flexGrow: 1,
    minWidth: "800px"
  },
  rootMonth: {
    flexGrow: 1,
    minWidth: "900px"
  },
  dateTableDiv: {
    overflow: 'none',
    marginRight: "20px"
  },
  MainLoader: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    background: 'rgb(15, 51, 80, 0.3)',
    zIndex: '9',
  },
  loader: {
    position: 'absolute',
    top: '35%',
    left: '35%'
  },
  textField: {
    '&::before': {
      border: 'none'
    },
    '& input': {
      textAlign: 'left'
    }
  },
  textFieldBg: {
    minWidth: '80px',
    width: '100%',
    '&::before': {
      border: 'none'
    },
    background: '#f5f5f5',
    '& input': {
      textAlign: 'center'
    }
  },
  tableCellPadding: {
    padding: '4px 15px 4px 15px',
  },
  rowTable: {
    height: "30px"
  },
  fieldMargin1: {
    marginTop: '5px !important',
    marginLeft: '5px !important',
    marginBottom: '5px !important',
  },
  fieldMargin: {
    padding:"5px 0px 5px 5px !important",
    marginTop: '5px !important',
    marginLeft: '20px !important',
    marginBottom: '5px !important',
    ['@media (min-width: 1350px)']: {
      marginLeft: '40px !important',
    },
    // ['@media (max-width: 1349px) and (min-width: 1150px)']: {
    //   marginLeft: '40px !important',
    // },
  },
  saveBtn: {
    float: 'right',
    margin: '15px 0px 5px 15px'
  },
  saveBtnView: {
    float: 'right',
    margin: '15px -4px 5px 15px'
  },
  cellWidth: {
    maxWidth: '100px',
    padding: '4px 60px 4px 24px'
  },
  weekCellWidth: {
    // maxWidth: '100px'
    marginLeft: '25px',
    ['@media (min-width: 1350px)']: {
      marginLeft: '40px'
    },
    // ['@media (max-width: 1349px) and (min-width: 1150px)']: {
    //   marginLeft: '25px'
    // },
  },
  fullWidth: {
    width: '100%'
  },
  actionIcon: {
    padding: '0',
    cursor: 'pointer',
    minWidth: '30px'
  },
  actionCell: {
    padding: '10px 15px 10px 35px'
  },
  adornment: {
    color: 'rgb(118, 118, 118)',
    // paddingRight: '5px'
  },
  tableHeading: {
    // fontSize: '18px',
    textAlign: 'left'
  },
  subTitle: {
    textAlign: 'left',
    margin: '10px 15px'
  },
  warning: {
    display: 'flex',
    textAlign: 'left',
    margin: '0px 0px 15px 12px',
    padding: '12px',
    color: 'inherit',
    background: '#F8E9C3',
    borderRadius: '5px',
    width: 'fit-content'
  },
  warningText: {
    margin: '4px 10px'
  },
  adornment: {
    color: 'rgb(118, 118, 118)',
  },
  radioFormControl: {
    minHeight: "80px",
    '@media screen and (min-width: 1000px)': {
      paddingTop: '16px',
      marginBottom: '-10px',
      float: 'right',
      width: '370px',
      margin: 15,
    },
    ['@media (max-width: 414px) and (min-width: 360px)']: {
      marginLeft: "20px"
    }
  },
  smartSettingTooltip: {
    marginLeft: 10,
    position: 'absolute',
    ['@media (max-width: 540px) and (min-width: 400px)']: {
      position: 'inherit'
    },
    ['@media (max-width: 399px) and (min-width: 361px)']: {
      position: 'inherit'
    },
    ['@media (max-width: 360px) and (min-width: 321px)']: {
      position: 'inherit'
    },
    ['@media (max-width: 320px)']: {
      position: 'inherit'
    }
  },
  cardMargin: {
    marginTop: 20
  },
  smartSettingSwitch: {
    '@media screen and (min-width: 1000px)': {
      marginTop: 15,
      marginBottom: 15
    }
  },
  switchFormControl: {
    float: 'left',
    marginTop: '-40px',
    '@media screen and (min-width: 1000px)': {
      marginTop: '-80px',
    },
  },
  formControl: {
    width: '46%',
    marginLeft: '2%',
    marginRight: '2%',
    ['@media (max-width: 540px) and (min-width: 400px)']: {
      width: '98%',
      marginLeft: '1%',
      marginRight: '1%',
    },
    ['@media (max-width: 399px) and (min-width: 361px)']: {
      width: '98%',
      marginLeft: '1%',
      marginRight: '1%',
    },
    ['@media (max-width: 360px) and (min-width: 321px)']: {
      width: '98%',
      marginLeft: '1%',
      marginRight: '1%',
    },
    ['@media (max-width: 320px)']: {
      width: '98%',
      marginLeft: '1%',
      marginRight: '1%',
    }
  },
  absoluteCard: {
    '@media screen and (min-width: 1000px)': {
      marginTop: "-80px"
    },
    marginBottom: "80px"
  },
  switchFrom: {
    '@media screen and (max-width: 800px)': {
      float: 'left',
      marginLeft: '26px'
    },
    '@media screen and (min-width: 1000px)': {
      paddingTop: '16px',
      marginBottom: '-10px',
      float: 'right',
    },
  },
  pmsPrice: {
    marginTop: '37px',
    float: 'right',
    ['@media (max-width: 414px) and (min-width: 360px)']: {
      float: 'left',
      marginLeft: '30px'
    }
  },
  dailyTableData: {
    maxWidth: "1300px"
  },
  tableContentSkip: {
    marginTop: "-100px"
  },
  adjustmentBtn: {
    display: "flex",
    flexDirection: "row-reverse",
    marginTop: "-85px",
    ['@media (max-width: 414px) and (min-width: 360px)']: {
      marginTop: "-40px",
      "& button": {
        width: "100%",
        margin: "15px 0px 5px 0px"
      }
    }
  },
  adjustmentBtnPopup: {
    display: "flex",
    flexDirection: "row-reverse",
    marginBottom: "-14px",
    marginRight: "-16px",
  },
  buttonProgress: {
    position: "absolute"
  },
  helpText: {
    maxWidth: "950px",
    textAlign: "justify",
    margin: "15px 0px 0px 0px",
    color:"#707070"
  },
  graphStyle: {
    marginRight: "50px",
    marginLeft: '40px',
    '@media (max-width: 1440px) ': {
      marginRight: "45px",
      marginLeft: '45px',
    },
    // ['@media (max-width: 2590px) and (min-width: 2561px)']: {
    //   maxWidth:'1048px',
    //   marginLeft:'80px'
    // },
    // ['@media (max-width: 1798px) and (min-width: 1279px)']: {
    //   maxWidth:'1184px',
    //   marginLeft:'80px'
    // },
  },
  graphStyleMonth: {
    marginRight: "45px",
    marginLeft: '50px',
    '@media (max-width: 1440px) ': {
      marginLeft: '60px',
      marginRight: "35px",
    },
  },
  dangerBtnHover : {
    '&:hover': {
      background:'white !important',
      color:'black'
    }
  },
  divStyle: {
    float: "left", 
    marginTop: "2px"
  },
  fontStyle: {
    textAlign: "left", 
    fontWeight: "500"
  },
  mainDiv: {
    overflow:"auto"
  },
  rowTable2: {
    borderTop:'1px solid #e7e7e7',
    height:'45px'
  },
  btnSave: {
    display: "flex",
    marginTop: "20px"
  },
  tableCell: {
    marginTop:'15px'
  },
  rowBorder: {
    borderTop: '1px solid #e7e7e7'
  }
}
export default style;